import {
  Box,
  Grid,
  Paper,
  Table,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AdminUtils from "../../utils";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import EditIcon from "@mui/icons-material/Edit";
import { useModal } from "_hooks";
import SourceSystemsForm from "./SourceSystemsForm";

const SourceSystemsTable = ({
  systemSourcesData,
  selectedSystemSource,
  setSelectedSystemSource,
  setSystemSourcesData,
  selectedCustomer,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(1);
  const sourceSystemModal = useModal();

  const onOpenSourceSystemDialog = (source) => {
    sourceSystemModal?.setData({
      source,
      systemSourcesData,
      setSystemSourcesData,
      setSelectedSystemSource,
      selectedCustomer,
    });
    sourceSystemModal?.openModal();
  };

  const handleEdit = (source) => {
    setSelectedSystemSource(source);
    onOpenSourceSystemDialog(source);
  };
  useEffect(() => {
    setPageCount(calculatePageCount(systemSourcesData));
  }, [systemSourcesData]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      systemSourcesData?.length > 0
        ? Math.round(Math.ceil(systemSourcesData.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  return (
    <Grid>
      <Grid
        // style={{
        //   display: "flex",
        //   marginBottom: "5px",
        //   alignItems: "center",
        // }}
        container
      >
        <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {langConstant.SOURCE_SYSTEMS}
          </Typography>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className="data-table" aria-label="Source System List">
          <TableHead>
            <TableRow>
              {AdminUtils.sourceSystemColumns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                >
                  {item.headerName}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? systemSourcesData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : systemSourcesData
            ).map((row, index) => {
              return (
                <TableRow
                  key={index + row[AdminUtils.sourceSystemColumns[0].field]}
                >
                  {AdminUtils.sourceSystemColumns.map((column, index) => {
                    const value = row[column.field];
                    return (
                      <TableCell
                        component="td"
                        scope="row"
                        key={
                          "column_" +
                          index +
                          row[AdminUtils.sourceSystemColumns[0].field]
                        }
                        style={{
                          wordBreak: "break-word",
                          wordWrap: "break-word",
                        }}
                      >
                        {value ? value : "-"}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <IconButton onClick={() => handleEdit(row)}>
                      <EditIcon
                        sx={{
                          color: "green",
                        }}
                        size="small"
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}

            {systemSourcesData && systemSourcesData.length == 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={AdminUtils.sourceSystemColumns.length + 1}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid container justify="flex-end">
          <SelectPageRange
            selectedRange={rowsPerPage}
            handleChange={handleSelectPageRange}
          />
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Box>
      {sourceSystemModal?.isOpen && (
        <SourceSystemsForm modal={sourceSystemModal} />
      )}
    </Grid>
  );
};

SourceSystemsTable.propTypes = {
  systemSourcesData: PropTypes.array,
  selectedSystemSource: PropTypes.any,
  setSelectedSystemSource: PropTypes.func,
  setSystemSourcesData: PropTypes.func,
  selectedCustomer: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};

export default SourceSystemsTable;
