/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import { debounce } from "lodash";
import AttachEvidence from "./AttachEvidence/index";
import PrepareSummaryAnswer from "./prepare-summary-answer/index";
import PrepareDecisionAction from "./prepare-decision-action/index";
import View from "./view/index";
import { useModal } from "_hooks";
import planActionsServices from "../services";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  TextField,
  Box,
  InputAdornment,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import langConstant from "_lang";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import FilterDropdown from "_components/FilterDropdown";
import sessionService from "_services/session.service";
import _ from "lodash";
import { ActionIcon } from "_components/ActionIcon";
import { ActionMenu } from "_components/ActionMenu";
const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
}));

const AppDataTable = (props) => {
  const classes = useStyles();
  const columns = props.columns;
  const QuestionList = props.QuestionList;

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const tableRef = useRef(null);
  const [planActionsData, setPlanActionsData] = useState([]);
  const customerID = sessionService.getCustomerId();
  const [hasMore, setHasMore] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const submissionID = props.submissionId;
  const attachmentModal = useModal();
  const editModal = useModal();
  const prepareActionModal = useModal();
  const viewModal = useModal();
  const setPlanActionsDataLength = props.setPlanActionsDataLength;
  const [filteredRows, setFilteredRows] = useState([]);
  const initialFilterObj = {
    REGULATORS_CONTROL_GROUP: "",
    ITEM_TYPE: "",
    ACTION_TYPE: "",
    ACTION_STATUS: "",
  };
  const [filterBy, setFilterBy] = useState(initialFilterObj);
  const [searchText, setSearchText] = useState(null);
  const [regulatorControlGroup, setRegulatorControlGroup] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [actionStatus, setActionStatus] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);
  const dispatch = useDispatch();
  const TopTabsArray = props.TopTabsArray;
  const observationList = props.observationList;
  const identifier = props.identifier;
  const RfiitemsList = props.RfiitemsList;

  const onOpenAttachmentDialog = () => {
    attachmentModal?.setData({
      selectedRow,
      submissionID,
    });
    attachmentModal?.openModal();
  };
  const onEditDialog = () => {
    const selRow = selectedRow.data;

    editModal?.setData({
      selRow,
      filteredRows,
    });
    editModal?.openModal();
  };

  const onOpenViewDialog = () => {
    const selRow = selectedRow.data;

    viewModal?.setData({
      selRow,
      TopTabsArray,
      filteredRows,
      observationList,
      identifier,
      RfiitemsList,
      setPlanActionsDataLength,
    });
    viewModal?.openModal();
  };

  const onOpenPrepareActionDialog = () => {
    const selRow = selectedRow.data;

    prepareActionModal?.setData({
      selRow,
      setPlanActionsDataLength,
      filteredRows,
      setFilteredRows,
    });
    prepareActionModal?.openModal();
  };

  const loadMoreRows = async () => {
    if (!hasMore || loading) return; // Don't load if there's no more data or it's already loading
    setLoading(true);

    try {
      const response = await planActionsServices.GetAssessmentPlanActions(
        submissionID,
        customerID,
        page,
        rowsPerPage,
        searchText,
        regulatorControlGroup,
        actionType,
        actionStatus,
        itemType
      );

      if (response?.status !== 200) throw new Error("Unable to fetch data");
      const newRows = response?.["#result-set-1"] || [];

      if (newRows.length < rowsPerPage) {
        setHasMore(false); // No more data available
      }

      setPlanActionsData((prevData) => [...prevData, ...newRows]);

      setPage(page + 1); // Increment the page for next load
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (tableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        // Trigger the next page load when near bottom
        loadMoreRows();
      }
    }
  };

  const QuestionClick = async (event, element) => {
    let questionStatus = null;
    if (element) {
      const index = _.findIndex(QuestionList, (e) => {
        return e.QUESTION_ID == element;
      });

      if (index > -1) {
        props.setPage(index);
        questionStatus = QuestionList[index].QUESTION_STATUS;
        props.setSelectedQuestionFromPlan(QuestionList[index]);
      } else {
        try {
          const response =
            await planActionsServices.GetSubmissionQuestionDetails(
              element,
              submissionID
            );
          if (response?.status !== 200) throw new Error("Unable to fetch data");
          if (response && response?.["#result-set-1"][0]) {
            const tempSelectedQuestion = response?.["#result-set-1"][0];
            props.setSelectedQuestionFromPlan({
              ...tempSelectedQuestion,
              SUBMISSION_ID: submissionID,
            });
            questionStatus = tempSelectedQuestion.QUESTION_STATUS;
          }
        } catch (error) {
          console.error("error", error);
        }
      }
      if (
        questionStatus &&
        (questionStatus.trim() === "COMPLETED" ||
          questionStatus.trim() === "COMPLETED_AND_LOCKED")
      ) {
        props.onTopTabChange(event, 4);
      } else {
        props.onTopTabChange(event, 3);
      }
    }
  };

  const onActionClick = (event, data, path, disableLink) => {
    event?.preventDefault();
    setSelectedRow({ data, path, disableLink });
    setAnchorMenuEl(openMenu ? null : event.currentTarget);
  };

  const onFilterChange = (fieldName, e) => {
    const filterValue = e.target.value;
    const filterObj = { ...filterBy, [fieldName]: filterValue };
    setFilterBy(filterObj);
    setPage(1);

    switch (fieldName) {
      case "REGULATORS_CONTROL_GROUP":
        setRegulatorControlGroup(e.target.value);
        break;
      case "ITEM_TYPE":
        setItemType(e.target.value);
        break;
      case "ACTION_TYPE":
        setActionType(e.target.value);
        break;
      case "ACTION_STATUS":
        setActionStatus(e.target.value);
        break;
    }
  };
  const resetFilter = () => {
    setSearchText(null);
    setRegulatorControlGroup(null);
    setItemType(null);
    setActionType(null);
    setActionStatus(null);
    setPage(1);
    setPlanActionsData([]);
    setFilterBy(initialFilterObj);
    setLoading(true);
  };

  const getAssessmentPlanActionsData = async () => {
    if (isNaN(submissionID)) return;
    try {
      const response = await planActionsServices.GetAssessmentPlanActions(
        submissionID,
        customerID,
        page,
        rowsPerPage,
        searchText,
        regulatorControlGroup,
        actionType,
        actionStatus,
        itemType
      );
      if (response?.status !== 200) throw new Error("Unable to fetch data");
      setPlanActionsData(response?.["#result-set-1"]);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(async () => {
    try {
      const response = await planActionsServices.GetAssessmentPlanActionsFilter(
        submissionID,
        customerID
      );
      if (response?.status !== 200) throw new Error("Unable to fetch data");
      setFilterData(response?.["#result-set-1"]);
      if (
        response?.["#result-set-1"] &&
        response?.["#result-set-1"].length > 0
      ) {
        response?.["#result-set-1"].forEach((item) => {
          if (item.ACTION_TYPE == "Collect Evidence")
            props.setKpiData((prev) => ({
              ...prev,
              evidenceToCollect: prev.evidenceToCollect + 1,
            }));
          else if (item.ACTION_TYPE == "Prepare Summary Answer")
            props.setKpiData((prev) => ({
              ...prev,
              answersToPrepare: prev.answersToPrepare + 1,
            }));
          else if (item.ACTION_TYPE == "Prepare Decision")
            props.setKpiData((prev) => ({
              ...prev,
              questionsToComplete: prev.questionsToComplete + 1,
            }));
        });
      }
    } catch (error) {
      console.error("error", error);
    }
    try {
      const response = await planActionsServices.GetRFIStatusForSubmission(
        submissionID
      );
      if (response?.status !== 200) throw new Error("Unable to fetch data");
      let tempRfiOpen = 0;
      response?.["#result-set-1"].forEach((item) => {
        if (item.RFI_STATUS != "RFI_COMPLETED")
          tempRfiOpen = tempRfiOpen + item.RFI_COUNT;
      });
      props.setRfiOpen(tempRfiOpen);
    } catch (error) {
      console.error("error", error);
    }
  }, []);

  useEffect(() => {
    getAssessmentPlanActionsData();
  }, [searchText, regulatorControlGroup, actionType, actionStatus, itemType]);

  useEffect(() => {
    const intervalID = setTimeout(() => setLoading(false), 2000);
    const updateRows =
      planActionsData.length > 0
        ? planActionsData.map((item) => {
            let data = item;
            let path = "";
            let disableLink = true;

            data["Status_label"] = item.STATUS ? (
              <span className={item.QUESTION_STATUS + " status-badge"}>
                {item.STATUS}
              </span>
            ) : (
              item.STATUS
            );

            data["action"] = (
              <ActionIcon
                onClick={(e) => onActionClick(e, item, path, disableLink)}
              />
            );

            return data;
          })
        : [];

    setFilteredRows(updateRows);
    return () => clearTimeout(intervalID);
  }, [planActionsData]);

  const createFilterDropdown = (fieldName, label) => {
    let options = _.reject(
      _.uniq(_.map(filterData, fieldName)).sort(),
      _.isNull
    );
    if (options.length > 0 && options[0]) {
      options = options.map((item) => {
        return { value: item, name: item };
      });
    }
    if (options.length > 0) {
      return (
        <FilterDropdown
          options={options}
          label={label}
          fieldName={fieldName}
          value={filterBy[fieldName]}
          onChange={onFilterChange}
          icon={true}
        />
      );
    }
  };

  return (
    <div>
      {openMenu && (
        <ActionMenu
          anchorMenuEl={anchorMenuEl}
          onClose={onActionClick}
          isOpen={openMenu}
          menuItems={[
            {
              tooltipLabel: langConstant.VIEW,
              icon: VisibilityIcon,
              isDisabled: !selectedRow?.disableLink,
              onClick: onOpenViewDialog,
            },
            {
              tooltipLabel: langConstant.UPLOAD_EVIDENCE,
              icon: AttachFileIcon,
              isDisabled: selectedRow?.data.ACTION_TYPE != "Collect Evidence",
              onClick: onOpenAttachmentDialog,
              color: "#007bff",
            },
            {
              tooltipLabel: langConstant.PREPARE_SUMMARY_ANSWER,
              icon: EditIcon,
              isDisabled:
                selectedRow?.data.ACTION_TYPE != "Prepare Summary Answer",
              onClick: onEditDialog,
              color: "#0e9439",
            },
            {
              tooltipLabel: langConstant.COMPLETE_CONTROL_ASSESSMENT,
              icon: CheckCircleIcon,
              isDisabled: selectedRow?.data.ACTION_TYPE != "Prepare Decision",
              color: "#C13636",
              onClick: onOpenPrepareActionDialog,
            },
          ]}
        />
      )}
      <Box mb={2} mt={3}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="searchText"
                  label=""
                  name="searchText"
                  placeholder="Filter Table"
                  value={searchText}
                  onChange={debounce((e) => {
                    setSearchText(e.target.value);
                  }, 2000)}
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {createFilterDropdown(
                  "REGULATORS_CONTROL_GROUP",
                  "REGULATORS CONTROL GROUP"
                )}
              </Grid>
              <Grid item xs={12} md={2}>
                {createFilterDropdown("ITEM_TYPE", "ITEM TYPE")}
              </Grid>
              <Grid item xs={12} md={2}>
                {createFilterDropdown("ACTION_TYPE", "ACTION TYPE")}
              </Grid>
              <Grid item xs={12} md={2}>
                {createFilterDropdown("ACTION_STATUS", "ACTION STATUS")}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={3} style={{ alignItems: "center" }}>
            <Grid item md={6} xs={6}>
              <Box mx={1}>
                {(searchText ||
                  filterBy["REGULATORS_CONTROL_GROUP"] ||
                  filterBy["ITEM_TYPE"] ||
                  filterBy["ACTION_TYPE"] ||
                  filterBy["ACTION_STATUS"]) && (
                  <span onClick={resetFilter} className="linkIcon">
                    {langConstant.RESET_ALL}
                  </span>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <TableContainer
        style={{ height: "80vh", overflow: "auto" }}
        component={Paper}
        ref={tableRef}
        onScroll={handleScroll}
      >
        {" "}
        <Table
          stickyHeader
          className={props.className}
          aria-label="Question List"
        >
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={item.sticky && classes.stickyColl}
                  style={{ minWidth: item.minWidth, maxWidth: item.maxWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.length > 0 &&
              filteredRows.map((row, index) => (
                <TableRow key={index + row[columns[0].field]}>
                  {columns.map((column, index) => {
                    const value = row[column.field];
                    return (
                      <TableCell
                        component="td"
                        scope="row"
                        key={"column_" + index + row[columns[0].field]}
                        className={column.sticky && classes.stickyColl}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                        }}
                      >
                        {column.field === "REGULATION_REFERENCE" ? (
                          <IconButton
                            color="primary"
                            style={{
                              fontSize: "14px",
                              cursor: "pointer",
                              width: "auto",
                              padding: "0px 0px",
                            }}
                            onClick={(event) => {
                              // props.onTopTabChange(event, 3);
                              QuestionClick(event, row[column.field]);
                            }}
                          >
                            {value}
                          </IconButton>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}

            {loading && (
              <TableRow style={{ height: 450 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}

            {!loading && filteredRows.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  No record found
                </TableCell>
              </TableRow>
            )}

            {attachmentModal?.isOpen && (
              <AttachEvidence modal={attachmentModal} />
            )}
            {editModal?.isOpen && <PrepareSummaryAnswer modal={editModal} />}
            {prepareActionModal?.isOpen && (
              <PrepareDecisionAction modal={prepareActionModal} />
            )}
            {viewModal?.isOpen && <View modal={viewModal} />}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

AppDataTable.propTypes = {
  submissionId: propTypes.oneOfType([propTypes.number, propTypes.string]),
  observationList: propTypes.array,
  identifier: propTypes.string,
  RfiitemsList: propTypes.array,
  columns: propTypes.array.isRequired,
  className: propTypes.string,
  QuestionList: propTypes.array,
  TopTabsArray: propTypes.array,
  setSelectedQuestionFromPlan: propTypes.func,
  setTopSelectedTab: propTypes.func,
  onTopTabChange: propTypes.func,
  setPage: propTypes.func,
  setKpiData: propTypes.func,
  setRfiOpen: propTypes.func,
  setPlanActionsDataLength: propTypes.func,
};

export default AppDataTable;
