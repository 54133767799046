import React, { useState } from "react";

import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CalendarView from "_components/CalendarView/CalendarView";



const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Calendar = (props) => {
  const [filterList, setFilterList] = useState([
    { name: 'rfi', value: true, label: 'RFI', id: '1' },
    { name: 'targetComplitionDate', value: false, label: 'Target Completion Date', id: '2' },
    { name: 'submissionDeadlineDate', value: false, label: 'Submission Deadline Date', id: '3' },
    { name: 'scheduledMeetings', value: false, label: 'Scheduled Meetings', id: '4' }
  ]);

  const handleCheckboxChange = (event) => {
    filterList.forEach(item => {
      if (event.target.name === item.name) {
        item.value = event.target.checked
      }
    });
    setFilterList([...filterList]);
  };

  return (
    <>
      <FormGroup row>
        {filterList && filterList.length && filterList.map(filter => (<FormControlLabel key={filter.id}
          style={{ marginRight: '3rem' }}
          control={<GreenCheckbox checked={filter.value} onChange={handleCheckboxChange} name={filter.name} />}
          label={filter.label}
        />))}
      </FormGroup>
      <CalendarView entryType={filterList} onPopup={false} ></CalendarView>
    </>
  );
}

export default Calendar;