import { makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import ProgressBarLarge from "_components/ProgressBarLarge";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import langConstant from "_lang";

const useStyles = makeStyles(() => ({
  loaderContent: {
    paddingBottom: "10px",
  },
  progressContent: {
    fontSize: "12px",
    paddingBottom: "10px",
    fontWeight: "bold",
  },
  closeIconWrap: {
    textAlign: "right",
    width: "calc(100% - 150px)",
  },
  logsContent: {
    textAlign: "left",
  },
  progressLogsWrap: {
    paddingBottom: "10px",
    paddingLeft: "35px",
  },
  headerContent: {
    textAlign: "left",
    paddingLeft: "10px",
  },
  headerInnerContent: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  headerContentWrapper: {
    borderBottom: "1px solid #dfdfdf",
    width: "100%",
    display: "flex",
    marginBottom: "15px",
    paddingBottom: "13px",
  },
}));

const CompareProgress = ({ progressLogsData, setShow }) => {
  const handleClose = () => setShow(false);
  const classes = useStyles();

  const [compareProgressData, setCompareProgressData] = useState([]);
  const [loaderPercentage, setLoaderPercentage] = useState(0);

  useEffect(() => {
    let tempCompareProgressData = [];
    progressLogsData.map((item) => {
      const logString = item.LOG_VALUE.split("_");
      if (logString.length > 2) {
        if (
          logString[1] === "comparison" &&
          logString[2] !== "insert" &&
          !logString[2].startsWith("insert") &&
          !logString[2].startsWith("comparison")
        ) {
          const progressString = logString[2].split(" ");
          if (
            progressString.length &&
            progressString[progressString.length - 1] &&
            progressString[progressString.length - 1].includes("/")
          ) {
            const progress =
              progressString[progressString.length - 1].split("/");
            if (progress.length == 2) {
              const progressPercentage = parseInt(
                (parseInt(progress[0]) / parseInt(progress[1])) * 100
              );
              const newProgressPercentage = (progressPercentage * 70) / 100;
              setLoaderPercentage(newProgressPercentage);
              tempCompareProgressData.unshift({
                log: logString[2],
                time: moment(item.CREATE_DATE, "YYYY-MM-DD HH:mm:ss").format(
                  "h:mm a"
                ),
              });
            }
          } else {
            tempCompareProgressData.unshift({
              log: logString[2],
              time: moment(item.CREATE_DATE, "YYYY-MM-DD HH:mm:ss").format(
                "h:mm a"
              ),
            });
          }
        } else {
          if (logString[2].trim() == "insert started") {
            setLoaderPercentage(85);
          } else if (logString[2].trim() == "comparison success") {
            setLoaderPercentage(100);
          }
          tempCompareProgressData.unshift({
            log: logString[2],
            time: moment(item.CREATE_DATE, "YYYY-MM-DD HH:mm:ss").format(
              "h:mm a"
            ),
          });
        }
      }
    });
    setCompareProgressData([...tempCompareProgressData]);
  }, [progressLogsData]);

  return (
    <Grid className={classes.progressLogsWrap}>
      <Grid>
        <Grid container className={classes.headerContentWrapper}>
          <Grid className={classes.headerContent} item md={11}>
            <Typography className={classes.headerInnerContent}>
              {langConstant.COMPARISON_STATUS}
            </Typography>
          </Grid>
          <Grid className={classes.closeIconWrap} item md={1}>
            <CloseIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Typography className={classes.loaderContent}>
          {/* <Typography style={{fontWeight: "bold", paddingBottom: "10px"}}>{langConstant.COMPARISON_PROGRESS} :</Typography> */}
          <ProgressBarLarge percentage={loaderPercentage} size="small" />
        </Typography>
      </Grid>
      {compareProgressData.map((item, index) => {
        return (
          <Grid key={index} className={classes.logsContent}>
            <Grid style={{ display: "flex" }} container>
              <Grid item md={9} style={{ paddingRight: "5px" }}>
                <Typography className={classes.progressContent} key={index}>
                  {item.log}
                </Typography>
              </Grid>
              <Grid item md={3}>
                <span style={{ textAlign: "right" }}>{item.time}</span>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

CompareProgress.propTypes = {
  setShow: PropTypes.func,
  progressLogsData: PropTypes.array,
};

export default CompareProgress;
