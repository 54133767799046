import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip } from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons";
import langConstant from "_lang";
import CalendarView from "_components/CalendarView/CalendarView";




const OpenCalendarPopup = (props) => {
    const [open, setOpen] = useState(false);
   
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };
    return (
        
            <Box ml={1} alignSelf="center">
                <Tooltip title={langConstant.OPEN_CALENDAR}>
                    <IconButton
                        style={{
                            backgroundColor: "#007BFF",
                            borderRadius: "50%",
                            width: props.width,
                            height: props.height,
                            color: "white",
                            fontSize: "18px",
                        }}
                        onClick={handleClickOpen}
                    >
                        <CalendarTodayOutlined color="inherit" fontSize="inherit" />
                    </IconButton>
                </Tooltip>
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={open}
                    onClose={handleClickClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent>
                        <DialogContentText>
                         <CalendarView submissionId={props.submissionId} entryType={'1,2,3,4'}  onPopup={true}></CalendarView>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
    );
}
OpenCalendarPopup.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    submissionId: PropTypes.any
  };
export default OpenCalendarPopup;