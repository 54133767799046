import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { Grid, TextField, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import SelectRegulation from "../components/SelectRegulation";
import langConstant from "_lang";
import useStyles from "../../../styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AppPagination from "_components/AppPagination";
import SelectPageRange from "_components/SelectPageRange";
import { useSelector } from "react-redux";
import { Search } from "@mui/icons-material";

import _ from "lodash";
import Graph from "./components/Graph";

const ContentView = ({ regulationID, contentData, panelID, isGraphView }) => {
  const classes = useStyles();
  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );

  const [rows, setRows] = useState(contentData);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(
    expandedPanelID === panelID ? 10 : 5
  );

  const columns = [
    {
      field: "DOCUMENT_REQUIRED",
      headerName: langConstant.DOCUMENT_REQUIRED,
    },
    {
      field: "POLICY_AVAILABLE",
      headerName: langConstant.POLICY_AVAILABLE,
      format: function formatData(value) {
        return _.isNull(value["POLICY_AVAILABLE"]) ? (
          <CancelIcon color="error" fontSize="small" />
        ) : (
          <CheckCircleIcon color="success" fontSize="small" />
        );
      },
    },
  ];

  useEffect(() => {
    setRowsPerPage(expandedPanelID === panelID ? 10 : 5);
  }, [expandedPanelID]);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPageCount(calculatePageCount(rows));
  }, []);

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range?.value);
    const totalPageCount =
      rows?.length > 0 ? Math.round(Math.ceil(rows?.length / range?.value)) : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
  };

  const onSearch = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    const tempFilteredRows = contentData?.filter((item) =>
      item?.DOCUMENT_REQUIRED?.toUpperCase()?.includes(
        searchValue?.toUpperCase()
      )
    );

    setRows(tempFilteredRows);
  };

  const resetFilters = () => {
    setSearch("");
    setRows(contentData);
  };

  const [nullGroup, nonNullGroup] = _.partition(contentData, (item) =>
    _.isNull(item["POLICY_AVAILABLE"])
  );
  const data = [
    { name: "Documents Required", value: nullGroup.length },
    { name: "Documents Provided", value: nonNullGroup.length },
  ];

  return (
    <Grid style={{ maxHeight: "30vh", gap: 2 }}>
      {regulationID && regulationID.REGULATION_ID ? (
        <Grid container spacing={2} mt={1}>
          <Grid item md={expandedPanelID === panelID ? 8 : 12}>
            <Tooltip title={regulationID.REGULATION_NAME}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  maxWidth: expandedPanelID === panelID ? "auto" : "300px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {regulationID.REGULATION_NAME}
              </Typography>
            </Tooltip>
            {expandedPanelID === panelID && (
              <Box sx={{ display: "flex", gap: "inherit", mt: 2, mb: 1 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="searchText"
                  label="Search Documents"
                  name="searchText"
                  placeholder="Filter Table"
                  value={search}
                  onChange={onSearch}
                  sx={{ mr: 1 }}
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={resetFilters}
                >
                  Clear
                </Button>
              </Box>
            )}
            <TableContainer component={Paper}>
              <Table className="data-table">
                <TableHead>
                  <TableRow>
                    {columns.map((item, index) => (
                      <TableCell
                        component="th"
                        scope="row"
                        key={"column_" + index + item.field}
                        style={{ minWidth: item.minWidth }}
                      >
                        {item.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows.length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        return (
                          <TableRow key={index}>
                            {columns.map((column, index) => {
                              const value = item[column.field];
                              return (
                                <Tooltip title={value} key={"column_" + index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    style={{
                                      maxWidth: "200px",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {column.format
                                      ? column.format(item)
                                      : value}
                                  </TableCell>
                                </Tooltip>
                              );
                            })}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} component="td" scope="row">
                        <Typography className={classes.noData}>
                          {langConstant.NO_DATA_FOUND}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {pageCount > 1 && (
              <Box my={2}>
                <Grid container justifyContent="end">
                  {expandedPanelID === panelID && (
                    <SelectPageRange
                      selectedRange={rowsPerPage}
                      handleChange={handleSelectPageRange}
                      disabled={!rows?.length || rows?.length <= 5}
                    />
                  )}
                  <AppPagination
                    colSpan={3}
                    count={pageCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Grid>
              </Box>
            )}
          </Grid>
          {expandedPanelID === panelID && (
            <Grid item md={4}>
              {/* <ResponsiveContainer aspect={1}>
                <PieChart width={400} height={400}>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={expandedPanelID === panelID ? 100 : 80}
                    fill="#8884d8"
                    dataKey="value"
                    label
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend />
                  <ReTooltip />
                </PieChart>
              </ResponsiveContainer> */}
              <Graph
                expandedPanelID={expandedPanelID}
                panelID={panelID}
                data={data}
                contentData={contentData}
                nonNullGroup={nonNullGroup}
                isGraphView={isGraphView}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <SelectRegulation />
      )}
    </Grid>
  );
};

ContentView.propTypes = {
  regulationID: PropTypes.object,
  contentData: PropTypes.array,
  panelID: PropTypes.string,
  isGraphView: PropTypes.bool,
};

export default ContentView;
