import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  insBoxLR: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "start",
    width: "500px",
    maxWidth: "100%",
    marginBottom: "15px",
  },

  comParaWrapper: {
    width: "500px",
    maxWidth: "100%",
  },
  insBoxLRLabel: {
    fontWeight: "500",
    fontSize: "18px",
  },
  insBoxLRField: {
    width: "100%",
  },
  insBoxLRField50: {
    width: "calc(100% - 220px)",
    paddingLeft: "15px",
    wordBreak: "break-all",
  },
  insBoxLRFieldFile: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "5px",
    padding: "6px 10px",
  },
  comParaTitle: {
    width: "100%",
  },
  policyUploadmodalWrapper: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  insBoxLRError: {
    color: "red",
    marginTop: "4px",
    display: "block",
    width: "100%",
  },
  distanceNote: {
    background: "#ebedef",
    padding: "10px",
    fontSize: "12px",
    marginTop: "10px;",
    fontWeight: "500",
    marginLeft: "220px",
  },
  borderNone: {
    border: "none",
  },
  compareBtn: {
    margin: "20px 0",
  },
  policyTitle: {
    borderBottom: "1px solid #d5c7c7",
    marginBottom: "15px",
  },
  modalTitleWrapper: {
    borderBottom: "1px solid #dfdfdf",
    paddingBottom: "13px",
    marginBottom: "20px",
    justifyContent: "space-between",
    width: "100%",
  },
  flex: {
    display: "flex",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  marginLeft: {
    marginLeft: "auto !important",
  },
  displayContent: {
    display: "contents",
    fontSize: "20px",
    fontWeight: "bold",
  },
  displayLanguage: {
    display: "inline-block",
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "right",
    paddingRight: "12px",
  },
  loaderWrap: {
    textAlign: "center",

    "&:before": {
      content: "''",
      width: "calc(100% - 24%)",
      height: "100%",
      background: "black",
      opacity: "0.3",
      position: "absolute",
      top: "0px",
      left: "-24px",
    },
  },
  loaderInnerWrap: {
    position: "absolute",
    top: "50%",
    left: "calc(50% - 15%)",
    transform: "translate(-50%, -50%)",
  },
  showProgress: {
    "&>div": {
      left: "50%",
    },
    "&:before": {
      width: "100%",
      left: "0",
    },
  },
  headerContent: {
    textAlign: "left",
    paddingLeft: "10px",
  },
  headerInnerContent: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  headerContentWrapper: {
    borderBottom: "1px solid #dfdfdf",
    width: "100%",
    display: "flex",
    // marginBottom: "15px",
    paddingBottom: "20px",
  },
}));

export default useStyles;
