import ChatBotServices from './services';

export const checkChunkingAvailability = async (policyID) => {
    const response = await ChatBotServices.getPolicyChunkingAvailable(policyID)
    if(response.status === 200){
        if(response['DOC_ID_Exists']){
            return {
                isChunking: true,
                isSplitted: true
            }
        } else {
            return {
                isChunking: true,
                isSplitted: false
            }
        }
    } else {
        return {
            isChunking: false
        }
    }
    
}