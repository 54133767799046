import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Checkbox,
} from "@mui/material";
import { UseModalPropType, useModal } from "_hooks";
import { Typography, Button, Box, makeStyles } from "@material-ui/core";
import sessionService from "_services/session.service";
import langConstant from "_lang";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import CustomRichTextEditor from "./CustomEditor";
import AdminServices from "../../services";
import Confirmation from "./ConfirmationDialog";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import { alertActions } from "_actions";
import moment from "moment";

const useStyles = makeStyles(() => ({
  width100: {
    width: "100%",
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "15px",
  },
}));

const CreateMessage = ({ modal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const confirmationModal = useModal();
  const filteredUser = modal.data.filteredUser ? modal.data.filteredUser : [];

  const onOpenConfirmationDialog = (values) => {
    if (!values.IN_APP && !values.EMAIL) {
      const message = langConstant.ATLEAST_SELECT_ONE_OPTION;
      confirmationModal?.setData({
        message,
      });
      confirmationModal?.openModal();
    }
  };

  const initialValues = {
    CUSTOMER_LIST: modal.data ? modal.data.customerList : [],
    DESCRIPTION: "",
    IN_APP: true,
    EMAIL: true,
  };

  const handleEditorCancel = (setFieldValue, data) => {
    setFieldValue("DESCRIPTION", data);
  };

  const validationSchema = yup
    .object({
      DESCRIPTION: yup.string().trim().required("Message Body is required"),
      IN_APP: yup.boolean(),
      EMAIL: yup.boolean(),
    })
    .test(
      "at-least-one",
      "At least one of the option In App or Email must be selected",
      function (values) {
        return values.IN_APP || values.EMAIL;
      }
    );

  const removeHtmlTags = (str) => {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = str;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const onSubmit = async (values) => {
    let tempTotal =
      values.IN_APP && values.EMAIL
        ? filteredUser.length * 2
        : filteredUser.length;

    onOpenConfirmationDialog(values);

    if (values.IN_APP || values.EMAIL) {
      dispatch(
        confirmationDialogActions.open({
          message: langConstant.CONFIRMATION_MESSAGE,

          onConfirm: () => {
            const mesg = "Regulativ Support Message";
            const plainDesc = removeHtmlTags(`${values.DESCRIPTION}`);
            const htmlDesc = `${values.DESCRIPTION}`;

            modal.data.setProgressView ? modal.data.setProgressView(true) : "";

            let tempCount = 0;
            let index = 0;
            const tempFailedEmailUsers = [];
            const tempFailedMsgUsers = [];

            filteredUser.forEach(async (item) => {
              index = ++index;
              const fullName = item.FIRST_NAME + " " + item.LAST_NAME;

              if (values.EMAIL) {
                tempCount = tempCount + 1;
                const emailTo = item.EMAIL_ADDRESS;
                //Send Email
                const emailResponse = await AdminServices.sendEmail(
                  "",
                  "",
                  htmlDesc,
                  mesg,
                  emailTo
                );
                if (
                  emailResponse &&
                  emailResponse.status &&
                  emailResponse.status === 200
                ) {
                  const tempPercentage = (tempCount / tempTotal) * 100;
                  if (tempPercentage >= 100) {
                    modal?.data.setDisablePage(false);
                  }
                  modal?.data.setPercentage((prev) => tempPercentage);
                } else {
                  tempFailedEmailUsers.unshift(item);
                  modal.data.setFailedEmailUser
                    ? modal.data.setFailedEmailUser(tempFailedEmailUsers)
                    : "";
                }
                if (
                  filteredUser.length == index &&
                  tempFailedEmailUsers.length > 0
                ) {
                  modal.data.setDisablePage
                    ? modal.data.setDisablePage(false)
                    : "";
                }
              }
              if (values.IN_APP) {
                //1 Call  getNotificationType [GetNotificationType]  - USERID, CUSTOMER_ID, Notification_Type = 'New Support Request"
                tempCount = tempCount + 1;

                AdminServices.getNotificationType(
                  item.USER_ID,
                  item.CUSTOMER_ID,
                  "New Support Request"
                ).then((res1) => {
                  const notificationTemplate = JSON.parse(
                    res1["#result-set-1"][0]["NOTIFICATION_TEMPLATE"]
                  );
                  const notificationID =
                    res1["#result-set-1"][0]["NOTIFICATION_ID"];
                  const dateTime = moment(new Date()).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );

                  let tempNotificationTemplate = notificationTemplate;
                  let notificationMessage =
                    notificationTemplate["Notificaton Message"];

                  // notificationMessage = notificationMessage.toString();
                  notificationMessage = notificationMessage.replace(
                    /@messageDatetime/g,
                    dateTime
                  );
                  notificationMessage = notificationMessage.replace(
                    /@userName/g,
                    fullName
                  );
                  notificationMessage = notificationMessage.replace(
                    /@bodyMessage/g,
                    plainDesc
                  );

                  tempNotificationTemplate["Notificaton Message"] =
                    notificationMessage;

                  //2 Call GetUserNotificationPreferencesAdmin with user_id

                  AdminServices.getUserNotificationPreferencesAdmin(
                    item.USER_ID
                  ).then((res2) => {
                    const preference = res2["#result-set-1"]
                      ? res2["#result-set-1"].find(
                          (item) => item.NOTIFICATION_ID == notificationID
                        )
                      : null;

                    // 3 Call PutNotificationMessage
                    if (preference && preference.NOTIFICATION_PREFERENCE_ID) {
                      AdminServices.putNotificationMessage(
                        item.USER_ID,
                        preference.NOTIFICATION_PREFERENCE_ID,
                        JSON.stringify(tempNotificationTemplate)
                      ).then((resSendMessage) => {
                        if (
                          resSendMessage &&
                          resSendMessage.status &&
                          resSendMessage.status === 200
                        ) {
                          const tempPercentage = (tempCount / tempTotal) * 100;
                          if (tempPercentage >= 100) {
                            modal?.data.setDisablePage(false);
                          }
                          modal?.data.setPercentage((prev) => tempPercentage);
                        }
                      });
                    } else {
                      tempFailedMsgUsers.unshift(item);
                      if (filteredUser.length == index) {
                       
                        modal?.data?.setDisablePage(false);
                        modal?.data?.setFailedMessageUser([
                          ...tempFailedMsgUsers,
                        ]);
                      }
                    }
                  });
                });
              }
            });

            modal?.closeModal();
          },
          title: "",
          option1: langConstant.YES,
          option2: langConstant.NO,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={modal?.closeModal}
        open={modal?.isOpen}
        disableEscapeKeyDown
        disableEnforceFocus
        style={{
          backdropFilter: "blur(2px) brightness(0.8)",
        }}
      >
        {/* <Grid p={1.5} display="inline-flex" flex="auto"> */}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <Grid container p={1.5}>
                <Grid item md={8} sm={8}>
                  <DialogTitle
                    sx={{
                      m: 0,
                      p: 0,
                      flex: 1,
                      color: "#9d9d9d",
                      textAlign: "center",
                    }}
                  >
                    {langConstant.ENTER_MESSAGE_BODY}
                  </DialogTitle>
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={3}
                  justify="flex-start"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    textAlign: "right",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    name="IN_APP"
                    checked={values.IN_APP}
                    onChange={(e) => setFieldValue("IN_APP", e.target.checked)}
                    sx={{
                      "&.Mui-checked": {
                        color: "#124571",
                      },
                      display: "inline",
                    }}
                    size="small"
                  />
                  <span style={{ fontWeight: "bold", cursor: "pointer" }}>
                    {langConstant.IN_APP}
                  </span>

                  <Checkbox
                    name="EMAIL"
                    checked={values.EMAIL}
                    onChange={(e) => setFieldValue("EMAIL", e.target.checked)}
                    sx={{
                      "&.Mui-checked": {
                        color: "#124571",
                      },
                      display: "inline",
                    }}
                    size="small"
                  />
                  <span style={{ fontWeight: "bold", cursor: "pointer" }}>
                    {langConstant.EMAIL}
                  </span>
                </Grid>
                {touched.IN_APP && touched.EMAIL && errors["at-least-one"] && (
                  <div>{errors["at-least-one"]}</div>
                )}
              </Grid>

              <DialogContent>
                <Grid>
                  <Box>
                    <Grid
                      container
                      className={classes.AddressGrid}
                      style={{ alignItems: "center" }}
                    >
                      <Field name="DESCRIPTION">
                        {({ field: { value } }) => (
                          <CustomRichTextEditor
                            onChangeHandler={(e) =>
                              setFieldValue("DESCRIPTION", e)
                            }
                            data={value}
                            onCancelClick={() => {
                              handleEditorCancel(
                                setFieldValue,
                                values.DESCRIPTION
                              );
                            }}
                          />
                        )}
                      </Field>

                      {errors && errors["DESCRIPTION"] && (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors["DESCRIPTION"]}
                        </Typography>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <DialogActions sx={{ p: 1.5, justifyContent: "right" }}>
                  <Button
                    type="button"
                    onClick={() => {
                      modal?.data.setDisablePage(false);
                      modal?.closeModal();
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    {langConstant.CANCEL}
                  </Button>

                  <Button type="submit" variant="contained" color="primary">
                    {langConstant.SEND_TO_SELECTED_CLIENTS}
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
      {confirmationModal?.isOpen && <Confirmation modal={confirmationModal} />}
    </React.Fragment>
  );
};

CreateMessage.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default CreateMessage;
