import DialogBox from "_components/DialogBox";
import React from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { Form, Formik, Field } from "formik";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import ClassificationUtils from "../utils";
import FormTextField from "_components/FormTextField";
import useStyles from "../styles";
import FormDateField from "_components/FormDateField";
import { useDispatch } from "react-redux";
import ClassificationServices from "../services";

const QuestionDialog = ({
  open,
  setOpen,
  selectedQueClassification,
  questionClassification,
  setQuestionClassification,
  selectedCustomer,
  setSelectedQueClassification,
}) => {
  const handleClose = () => {
    setOpen(false);
    setSelectedQueClassification(null);
  };
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(
      ClassificationServices.putQuestionClassification(
        selectedCustomer.value,
        values,
        questionClassification,
        setQuestionClassification,
        handleClose
      )
    );
  };

  return (
    <DialogBox
      maxWidth={"sm"}
      title={langConstant.QUESTION_CLASSIFICATION}
      open={open}
      handleClose={handleClose}
    >
      <Formik
        initialValues={
          selectedQueClassification
            ? selectedQueClassification
            : ClassificationUtils.queClassificationInitialValues
        }
        validationSchema={ClassificationUtils.queClassificationValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Grid container>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.CLASSIFICATION_NAME}
                  value={values.QUESTION_CLASSIFICATION_NAME}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="QUESTION_CLASSIFICATION_NAME"
                  errors={errors}
                />
              </Grid>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.ACTIVE}
                </Typography>
                <Field style={{ width: "100%" }} name="ACTIVE">
                  {() => (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={values["ACTIVE"]}
                      onChange={(e) => setFieldValue("ACTIVE", e.target.value)}
                      style={{
                        display: "inline",
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  )}
                </Field>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.START_DATE}
                </Typography>
                <FormDateField
                  value={values.START_DATE}
                  setFieldValue={setFieldValue}
                  fieldName="START_DATE"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.END_DATE}
                </Typography>
                <FormDateField
                  value={values.END_DATE}
                  setFieldValue={setFieldValue}
                  fieldName="END_DATE"
                  errors={errors}
                />
              </Grid>
            </Grid>
            <Grid style={{ textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                {langConstant.UPDATE}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogBox>
  );
};

QuestionDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  selectedQueClassification: PropTypes.oneOfType([
    () => null,
    PropTypes.object,
  ]),
  questionClassification: PropTypes.oneOfType([() => null, PropTypes.array]),
  setQuestionClassification: PropTypes.func,
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
  setSelectedQueClassification: PropTypes.func,
};

export default QuestionDialog;
