import langConstant from "_lang";

export default {
  dataType: [
    { value: "INTEGER", name: langConstant.INTEGER },
    { value: "TEXT", name: langConstant.TEXT },
    { value: "DATE", name: langConstant.DATE },
    { value: "BOOLEAN", name: langConstant.BOOLEAN },
  ],
  comparisonOperator: [
    { value: "EQ", name: langConstant.RULES_CONDITION.IS_EQUAL_TO },
    {
      value: "NE",
      name: langConstant.RULES_CONDITION.IS_NOT_EQUAL_TO,
    },
    {
      value: "GT",
      name: langConstant.RULES_CONDITION.IS_GREATER_THAN,
    },
    { value: "LT", name: langConstant.RULES_CONDITION.IS_LESS_THAN },
    { value: "SW", name: langConstant.RULES_CONDITION.STARTS_WITH },
    { value: "EW", name: langConstant.RULES_CONDITION.ENDS_WITH },
    { value: "CNT", name: langConstant.RULES_CONDITION.CONTAINS },
    {
      value: "NCNT",
      name: langConstant.RULES_CONDITION.DOESNT_CONTAIN,
    },
    { value: "IE", name: langConstant.RULES_CONDITION.IS_EMPTY },
    { value: "INE", name: langConstant.RULES_CONDITION.IS_NOT_EMPTY },
  ],
};
