// OP#3052 - UJC5.1 - Assign Assessment - Extension Features
// Services file for the Assign-assessment screen

import React from "react";
import apiService from "_services/api.service";
import { alertActions, loadingActions } from "_actions";

export const GetRegulationByUser = (userID) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/GetRegulationByUser", { i_USER_ID: userID })
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );

}

export const GetCustomerLegalEntityByUser = (userID) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/GetCustomerLegalEntityByUser", { i_USER_ID: userID })
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );


}

export const GetQuestionsForRegulation = (regulationID) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/GetQuestionsForRegulation", { i_REGULATION_ID: regulationID })
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );

}

export const PutAssessments = (Obj) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/PutAssessments", Obj)
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );

}

export const UpdateAssignedAssessments = (Obj) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/UpdateAssignedAssessments", Obj)
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );

}

export const GetAssessmentOriginator = (customerID) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/GetAssessmentOriginator", { i_CUSTOMER_ID: customerID })
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );

}

export const PutMessageInLog = (obj) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/PutMessageInLog", obj)
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );

}

export const GetAssignedAssessments = (sumissionID) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/GetAssignedAssessments", { i_SUBMISSION_ID: sumissionID })
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );
}

export const GetEmailNotificationTemplateForUseCase = (useCase, useCaseName) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/GetEmailNotificationTemplateForUseCase", { i_UseCase: useCase, i_UseCaseName: useCaseName })
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );

}

export const GetUsersByCustomer = (customerID) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/GetUsersByCustomer", { i_CUSTOMER_ID: customerID })
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                response['status'] = 200
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );
}


export const PutAssessmentExtraApprovers = (data) => {
    return apiService
        .apiCall("dbapi")
        .send("/v1/api/proc/DRegulativDB/PutAssessmentExtraApprovers", data)
        .then(apiService.handleResponse)
        .then((response) => {
            if (response) {
                response['status'] = 200
                return response
            }
        },
            (error) => {
                if (error.response) {
                    return error.response
                }
            }
        );
}

