import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  marginTop: {
    marginTop: "15px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  loadMore: {
    textAlign: "center",
    padding: "20px 0 !important",
    borderTop: "0 !important",
    "& span": {
      backgroundColor: "rgb(18, 69, 113)",
      color: "#fff",
      padding: "7px 15px",
      borderRadius: "5px",
      display: "inline-block",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#02335d",
      },
    },
  },
  tableBody: {
    "& tr": {
      "& td": {
        borderTop: "1px solid rgba(224,224,224,1)",
        borderBottom: "0",
      },
    },
  },
  countWrapper: {
    background: "#c13636",
    color: "white",
    borderRadius: "5px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    top: "-10px",
    width: "max-content",
    padding: "2px 5px",
    fontSize: "12px",
  },
  recordLength: {
    marginLeft: "20px",
    padding: "2px 5px",
    backgroundColor: ({ actionButtonColor }) => actionButtonColor,
    color: "white",
    borderRadius: "5px",
  },
  contentContexMenu: {
    padding: "5px 0px 0px 0px",
    "& .MuiListItemIcon-root": {
      minWidth: "unset !important",
    },
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  actionButton: {
    color: "#fff",
    fontSize: "18px !important",
    margin: "0 auto",
  },
}));

export default useStyles;
