import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import propTypes from "prop-types";
import { TabContext, TabPanel } from "@material-ui/lab";
import { Assignment, Cancel } from "@material-ui/icons";
import "../../../../App.css";
import { GetActionsByListId } from "../services";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";

const priorityChipStyles = {
  M: { backgroundColor: "#fff6c8", color: "#c3a306" },
  H: { backgroundColor: "#ffdddd", color: "#ff3131" },
  L: { backgroundColor: "#dfffe1", color: "#4fcd00" },
};

export const TabMenu = ({ taskList, maxVisibleActions = 5 }) => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState([]);
  const classes = {};

  const getActionsById = async (actionsListId) => {
    if (!actionsListId) return;
    setIsLoading(true);
    await GetActionsByListId(actionsListId)
      .then((response) => {
        if (response?.status === 200) {
          let list = response?.["#result-set-1"] || [];
          setActions(list?.slice(0, maxVisibleActions));
        } else {
          dispatch(
            alertActions.error(
              response?.data?.message?.map((item, index) => {
                return <span key={index}>{item}</span>;
              }) ?? response?.toString()
            )
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(
          alertActions.error(
            err?.data?.message?.map((item, index) => {
              return <span key={index}>{item}</span>;
            }) ?? err?.toString()
          )
        );
      })
      .finally(() => setIsLoading(false));
  };

  const onTabChange = (e, value) => {
    setCurrentTab(value);
  };

  const getPriorityChipLabel = (priority) => {
    switch (priority) {
      case "H":
        return "High";
      case "M":
        return "Medium";
      case "L":
        return "Low";
      default:
        return "-";
    }
  };

  useEffect(() => {
    const actionListId = taskList?.[currentTab]?.ACTIONS_LIST_ID;
    if (!actionListId) return;
    getActionsById(taskList?.[currentTab]?.ACTIONS_LIST_ID);
  }, [currentTab]);
  return (
    <TabContext value={currentTab?.toString()}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={onTabChange}
        aria-label="task list tabs"
        TabIndicatorProps={{
          style: {
            height: 3,
            borderRadius: "4px",
            backgroundColor: "#007BFF",
          },
        }}
      >
        {taskList?.map((item, index) => {
          let label =
            item?.ACTIONS_LIST_NAME?.length > 12
              ? item?.ACTIONS_LIST_NAME?.slice(0, 12) + "..."
              : item?.ACTIONS_LIST_NAME;
          return (
            <Tab
              key={index}
              label={label}
              style={{
                minWidth: "60px",
                fontSize: "12px",
              }}
            />
          );
        })}
      </Tabs>
      <TabPanel value={currentTab?.toString()}>
        <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          {isLoading ? (
            <Box
              alignItems="center"
              margin="auto"
              minHeight="6rem"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {actions?.length ? (
                actions?.map((action) => (
                  <Box
                    p=".75rem"
                    key={action?.ACTIONS_ID}
                    display="flex"
                    borderBottom="1px solid #0000001a"
                    style={{ gap: 8 }}
                  >
                    <Assignment color="primary" fontSize="large" />
                    <Typography
                      style={{
                        alignSelf: "center",
                        textTransform: "capitalize",
                        flex: 1,
                      }}
                    >
                      {action?.ACTIONS_SHORT_DESCRIPTION}
                    </Typography>
                    {action?.ACTIONS_PRIORITY && (
                      <Chip
                        label={getPriorityChipLabel(action?.ACTIONS_PRIORITY)}
                        size="small"
                        style={{
                          fontSize: 10,
                          alignSelf: "center",
                          padding: "0rem .25rem",
                          ...priorityChipStyles[action?.ACTIONS_PRIORITY],
                        }}
                      />
                    )}
                  </Box>
                ))
              ) : (
                <Box
                  alignItems="center"
                  margin="auto"
                  minHeight="6rem"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Cancel
                    fontSize="large"
                    color="error"
                    style={{ margin: "1rem", opacity: 0.5 }}
                  />
                  <Typography variant="caption">
                    No actions available
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </TabPanel>
    </TabContext>
  );
};
TabMenu.propTypes = {
  taskList: propTypes.array,
  maxVisibleActions: propTypes.number,
};
