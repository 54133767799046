import { Grid, IconButton, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ClassificationServices from "../services";
import { useState } from "react";
import ClassificationUtils from "../utils";
import TableComponent from "./TableComponent";
import AddBoxIcon from "@mui/icons-material/AddBox";
import OrgDialog from "./OrgDialog";
import langConstant from "_lang";
import { countriesListActions } from "_actions";
import useStyles from "../styles";

const OrgManagement = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [orgManagementData, setOrgManagementData] = useState(null);
  const [orgDialog, setOrgDialog] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const countries = useSelector((state) => state.countriesList);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(
        ClassificationServices.getCustomerOrgUnits(
          selectedCustomer.value,
          setOrgManagementData
        )
      );
      dispatch(countriesListActions.getCountriesList());
    }
  }, [selectedCustomer]);

  return (
    <Grid className={classes.classificationFormWrapper}>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className={classes.sectionHeader}>
          {langConstant.ORGANIZATION_MANAGEMENT}
        </Typography>
        {selectedCustomer && selectedCustomer.value && (
          <IconButton
            type="button"
            color="primary"
            onClick={() => setOrgDialog(true)}
          >
            <AddBoxIcon />
          </IconButton>
        )}
      </Grid>
      {selectedCustomer && selectedCustomer.value ? (
        <TableComponent
          columns={ClassificationUtils.OrgUnitsColumns}
          rows={orgManagementData}
          setSelectedRow={setSelectedOrg}
          setDialog={setOrgDialog}
          countries={countries}
        />
      ) : (
        <Typography className={classes.selectCustText}>
          {langConstant.PLEASE_SELECT_CUSTOMER_FIRST}
        </Typography>
      )}
      {orgDialog && (
        <OrgDialog
          open={orgDialog}
          setOpen={setOrgDialog}
          selectedCustomer={selectedCustomer}
          selectedOrg={selectedOrg}
          setSelectedOrg={setSelectedOrg}
          orgManagementData={orgManagementData}
          setOrgManagementData={setOrgManagementData}
        />
      )}
    </Grid>
  );
};

OrgManagement.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default OrgManagement;
