import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TableauServices from "_services/tableau.service";
import TableauReport from "tableau-react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import langConstant from "_lang";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { alertActions } from "_actions";
import sessionService from "_services/session.service";

const InsightGraph = () => {
  const dispatch = useDispatch();

  const [tableauToken, setTableauToken] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [tabData, setTabData] = useState([
    {
      label: langConstant.COMPARISON_SUMMARY,
      value: 0,
      url: "",
      slug: "POLICY_MANAGEMENT/COMPARISON_SUMMARY",
      tableauParameters: { iid: 4 },
    },
    {
      label: langConstant.SCATTER_VIEW,
      value: 1,
      url: "",
      slug: "POLICY_MANAGEMENT/SCATTER_GRAPH",
      tableauParameters: { iid: 4 },
    },
    {
      label: langConstant.STACKED_VIEW,
      value: 2,
      url: "",
      slug: "POLICY_MANAGEMENT/STACKED_GRAPH",
      tableauParameters: { iid: 4 },
    },
    {
      label: langConstant.HEATMAP,
      value: 3,
      url: "",
      slug: "POLICY_MANAGEMENT/HEATMAP",
      tableauParameters: { iid: 4 },
    },
    {
      label: langConstant.DECISION_HEATMAP,
      value: 4,
      url: "",
      slug: "POLICY_MANAGEMENT/DECISION_HEATMAP",
      tableauParameters: { iid: 4 },
    },
  ]);

  const username = useSelector(
    (state) => state.authentication.detailedInfo?.USER_NAME
  );
  const reportingUrl = useSelector(
    (state) => state?.authentication?.detailedInfo?.REPORTING_URL
  );
  const TableauDefaultObjArray = useSelector(
    (state) => state?.authentication?.detailedInfo?.TABLEAU_DEFAULT_DASHBOARDS
  );

  const userId = sessionService.getUserId();
  const customerId = sessionService.getCustomerId();

  const { id } = useParams();

  const options = {
    height: 800,
    width: "100%",
  };

  useEffect(() => {
    updateTableauToken();
  }, [username]);

  const updateTableauToken = async () => {
    setTableauToken(null);
    await dispatch(TableauServices.getTableauToken(setTableauToken));
  };

  const handleChange = async (event, newValue) => {
    await updateTableauToken();
    setTabValue(newValue);
  };

  useEffect(() => {
    if (TableauDefaultObjArray?.length > 0) {
      let tempTabData = [];
      tabData.forEach((element) => {
        const Urlobj = TableauDefaultObjArray.find(
          (el) => el.ENTITLEMENT_NAME.trim() === element.slug
        );
        if (Urlobj !== undefined && Urlobj.DASHBOARD_URL) {
          element["url"] = Urlobj["DASHBOARD_URL"].trim();
          if (Urlobj.Parameter) {
            if (Urlobj.Parameter.includes("@USER_ID")) {
              element["tableauParameters"]["UserId"] = userId;
            }
            if (Urlobj.Parameter.includes("@CUSTOMER_ID")) {
              element["tableauParameters"]["CustomerId"] = customerId;
            }
            if (Urlobj.Parameter.includes("@POLICYCOMPARISONID")) {
              element["tableauParameters"]["policycomparisonid"] = id;
            }
          }
          tempTabData.push(element);
        } else {
          dispatch(
            alertActions.error(
              "Tableau Default Url Error, URL = " + Urlobj?.DASHBOARD_URL
            )
          );
        }
      });
      setTabData(tempTabData);
    }
  }, [TableauDefaultObjArray]);

  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        TabIndicatorProps={{ style: { padding: 2 } }}
        orientation="horizontal"
        variant="scrollable"
      >
        {tabData.map((tab, tabIndex) => (
          <Tab aria-orientation="horizontal" label={tab.label} key={tabIndex} />
        ))}
      </Tabs>
      {tabData.map((item, itemIndex) => (
        <Grid key={itemIndex}>
          {tableauToken && tabValue === item.value && (
            <TableauReport
              url={reportingUrl + item.url}
              token={tableauToken}
              parameters={item.tableauParameters}
              options={options}
              query="?:embed=yes&:comments=no&:toolbar=top&:refresh=yes"
            />
          )}
        </Grid>
      ))}
    </Box>
  );
};

export default InsightGraph;
