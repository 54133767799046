import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import PropTypes from "prop-types";
import AdminUtils from "../../utils";
import { scrollToTop } from "_helpers/utils";
import langConstant from "_lang";
import Select from "react-select";
import OptionComponent from "containers/manage-regulation/NewRegulation/ManageQuestions/component/OptionComponent";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { useDispatch } from "react-redux";
import AdminServices from "../../services";
import { alertActions } from "_actions";
import RegulatorEditPopUp from "./RegulatorEditPopUp";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

const Regulators = ({
  regulatorsList,
  setRegulatorsList,
  allRegulatorsList,
  selectedCustomer,
  regulationsList,
  selectedRegulator,
  setSelectedRegulator,
  setRegulationsList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [regulatorPopUp, setRegulatorPopUp] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mode, setMode] = useState("create");

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (regulatorsList) {
      setPageCount(calculatePageCount(regulatorsList));
    }
  }, [regulatorsList]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      regulatorsList?.length > 0
        ? Math.round(Math.ceil(regulatorsList.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const updateRegulatorMapping = (mode, selectedRegulator) => {
    if (selectedRegulator) {
      if (mode === "create") {
        if (
          regulatorsList.some(
            (regulator) =>
              regulator.REGULATOR_ID == selectedRegulator.REGULATOR_ID
          )
        ) {
          dispatch(
            alertActions.error("Regulator already assigned to Customer")
          );
          setMode("edit");
        } else {
          setMode("create");
        }
      } else {
        setMode("edit");
      }
      setSelectedRegulator(selectedRegulator);
    } else {
      dispatch(alertActions.error("Please Select Regulator"));
    }
  };

  const updateRegulatorsDetails = () => {
    if (mode === "create") {
      dispatch(
        AdminServices.updateCustomerRegulatorMapping(
          selectedRegulator,
          selectedCustomer.value,
          regulatorsList,
          setRegulatorsList,
          mode
        )
      );
      setMode("edit");
    } else {
      dispatch(
        AdminServices.updateCustomerRegulatorMapping(
          selectedRegulator,
          selectedCustomer.value,
          regulatorsList,
          setRegulatorsList,
          mode
        )
      );
    }
  };

  return (
    <Grid
      className={classes.customerFormWrapper}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: " 10px",
      }}
    >
      <Grid
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <Typography style={{ marginRight: "10px", fontWeight: "bold" }}>
          {langConstant.REGULATOR}
        </Typography>
        <Select
          className={classes.select100Width}
          options={allRegulatorsList ? allRegulatorsList : []}
          components={{ Option: OptionComponent }}
          closeMenuOnSelect={true}
          close
          hideSelectedOptions={false}
          value={selectedRegulator}
          placeholder={langConstant.SELECT_REGULATOR}
          onChange={(regulator) => {
            updateRegulatorMapping("create", regulator);
            // setSelectedRegulator(regulator);
            // setMode("create");
          }}
        />
        <Tooltip
          title={
            mode === "create"
              ? langConstant.MAP_REGULATOR
              : langConstant.EDIT_REGULATOR
          }
        >
          <IconButton
            type="button"
            color="primary"
            onClick={() => setRegulatorPopUp(true)}
          >
            {mode === "create" ? (
              <AddBoxRoundedIcon fontSize="medium" />
            ) : (
              <EditRoundedIcon fontSize="medium" />
            )}
          </IconButton>
        </Tooltip>
      </Grid>
      <TableContainer component={Paper}>
        <Table className="data-table" aria-label="Regulator List">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {AdminUtils.regulatorsColumns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {regulatorsList &&
              (rowsPerPage > 0
                ? regulatorsList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : regulatorsList
              ).map((row, index) => {
                return (
                  <TableRow
                    key={index + row[AdminUtils.regulatorsColumns[0].field]}
                  >
                    <TableCell>
                      <Checkbox
                        checked={
                          row.REGULATOR_ID == selectedRegulator?.REGULATOR_ID
                        }
                        onClick={() => {
                          setSelectedRegulator(row);
                          setMode("edit");
                        }}
                      />
                    </TableCell>
                    {AdminUtils.regulatorsColumns.map((column, index) => {
                      const value = row[column.field];
                      return (
                        <TableCell
                          component="td"
                          scope="row"
                          key={
                            "column_" +
                            index +
                            row[AdminUtils.regulatorsColumns[0].field]
                          }
                          style={{
                            wordBreak: "break-word",
                            wordWrap: "break-word",
                          }}
                        >
                          {column.format ? column.format(row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {regulatorsList?.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={AdminUtils.regulatorsColumns.length + 1}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid container justify="flex-end">
          <SelectPageRange
            selectedRange={rowsPerPage}
            handleChange={handleSelectPageRange}
          />
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Box>
      {regulatorPopUp && selectedRegulator && (
        <RegulatorEditPopUp
          show={regulatorPopUp}
          setShow={setRegulatorPopUp}
          selectedRegulator={selectedRegulator}
          setSelectedRegulator={setSelectedRegulator}
          mode={mode}
          setMode={setMode}
          updateRegulatorsDetails={updateRegulatorsDetails}
          regulationsList={regulationsList}
          setRegulationsList={setRegulationsList}
          selectedCustomer={selectedCustomer}
        />
      )}
    </Grid>
  );
};

Regulators.propTypes = {
  regulatorsList: PropTypes.oneOfType([() => null, PropTypes.array]),
  setRegulatorsList: PropTypes.func,
  allRegulatorsList: PropTypes.oneOfType([() => null, PropTypes.array]),
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
  regulationsList: PropTypes.oneOfType([() => null, PropTypes.array]),
  setRegulationsList: PropTypes.func,
  selectedRegulator: PropTypes.oneOfType([() => null, PropTypes.object]),
  setSelectedRegulator: PropTypes.func,
};

export default Regulators;
