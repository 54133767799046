import {
  Button,
  Box,
  Grid,
  Paper,
  Table,
  Dialog,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useState, useEffect } from "react";
import AdminUtils from "../../utils";
import { useModal } from "_hooks";
import useStyles from "../../styles";
import langConstant from "_lang";
import AddAttribute from "./AddAttributeDialog";
import PropTypes from "prop-types";
import AdminServices from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { UseModalPropType } from "_hooks";
import AddBoxIcon from "@mui/icons-material/AddBox";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SourceSystemsForm = ({ modal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeDetails = useSelector(getThemeDetails);
  const [value, setValue] = React.useState(0);
  const [attributes, setAttributes] = useState(
    modal?.data.source.DATA_ATTRIBUTES_EXPECTED_IN_SOURCE_FILE
      ? modal.data.source.DATA_ATTRIBUTES_EXPECTED_IN_SOURCE_FILE.split(";")
      : []
  );
  const addAttributeModal = useModal();

  const [items, setItems] = useState(null);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  };

  const getItems = (attributeList) => {
    return attributeList.map((item, index) => ({
      id: `${index}`,
      content: `${item}`,
    }));
  };
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 2;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "2px",
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging
      ? themeDetails?.ACTIONBUTTON_BGROUND_COLOUR
      : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightpurple" : "lightgrey",
    padding: grid,
    width: "100%",
    alignItems: "center",
  });

  const initialValues = modal?.data.source
    ? modal?.data.systemSourcesData.find(
        (source) =>
          source.SOURCE_SYSTEM_ID == modal?.data.source.SOURCE_SYSTEM_ID
      )
    : AdminUtils.initialSourceSytemValues;

  useEffect(() => {
    setItems(getItems(attributes));
  }, [attributes]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveSourceSystem = (values) => {
    values["CUSTOMER_ID"] = modal?.data.selectedCustomer.value;
    values["DATA_ATTRIBUTES_EXPECTED_IN_SOURCE_FILE"] = attributes.join(";");

    dispatch(
      AdminServices.putCustomerSourceSystem(
        values,
        modal?.data.systemSourcesData,
        modal?.data.setSystemSourcesData,
        modal?.data.setSelectedSystemSource
      )
    );
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //   const removeEntitlementLabel = () => {
  //     setRemoveDialog(false);
  //     dispatch(
  //       AdminServices.removeEntitlementLabel(
  //         source.ENTITLEMENT_LABEL_ID,
  //         modal?.data.systemSourcesData,
  //         setSystemSourcesData,
  //         modal?.data.setSelectedSystemSource,
  //         modal?.data.setIsEditView
  //       )
  //     );
  //   };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={modal?.closeModal}
        open={modal?.isOpen}
        disableEscapeKeyDown
        disableEnforceFocus
        style={{
          backdropFilter: "blur(2px) brightness(0.8)",
        }}
      >
        {" "}
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={AdminUtils.sourceSystemValidationSchema}
          validateOnChange={true}
          onSubmit={saveSourceSystem}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  marginBottom: "10px",
                }}
              >
                <Tabs value={value} onChange={handleChange}>
                  <Tab
                    label={langConstant.SOURCE_DATA_DETAILS}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={langConstant.DATA_ATTRIBUTE_DETAILS}
                    {...a11yProps(1)}
                  />
                </Tabs>

                {/* Tab Panels */}
                <TabPanel
                  value={value}
                  index={0}
                  style={{ height: "calc(100% - 48px)" }}
                >
                  <Grid
                    container
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      styles={{ marginTop: "25px" }}
                    >
                      <TableContainer component={Paper}>
                        <Table
                          className="data-table"
                          aria-label="Source System Details"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    {" "}
                                    {langConstant.DATA_FILE_DETAILS}
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    {langConstant.DATA_FILE_VALUES}
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    <Typography
                                      className={classes.customerFormLabel}
                                    >
                                      {langConstant.NAME_OF_SOURCE}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <Field
                                      style={{ width: "100%", margin: "10px" }}
                                      name="SOURCE_SYSTEM_NAME"
                                    >
                                      {() => (
                                        <TextField
                                          style={{
                                            width: "80%",
                                            paddingBottom: "10px",
                                          }}
                                          value={values.SOURCE_SYSTEM_NAME}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "NAME_OF_SOURCE",
                                              e.target.value
                                            )
                                          }
                                          placeholder={
                                            langConstant.NAME_OF_SOURCE
                                          }
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputField,
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>

                                  <ErrorMessage name="SOURCE_SYSTEM_NAME">
                                    {(msg) => (
                                      <span className={classes.errorTxt}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    {" "}
                                    <Typography
                                      className={classes.customerFormLabel}
                                    >
                                      {langConstant.EXPECTED_FILE_FORMAT}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <Field
                                      style={{ width: "100%", margin: "10px" }}
                                      name="FILE_FORMAT"
                                    >
                                      {() => (
                                        <TextField
                                          style={{
                                            width: "80%",
                                            paddingBottom: "10px",
                                          }}
                                          value={values.FILE_FORMAT}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "FILE_FORMAT",
                                              e.target.value
                                            )
                                          }
                                          placeholder={
                                            langConstant.EXPECTED_FILE_FORMAT
                                          }
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputField,
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>

                                  <ErrorMessage name="FILE_FORMAT">
                                    {(msg) => (
                                      <span className={classes.errorTxt}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    {" "}
                                    <Typography
                                      className={classes.customerFormLabel}
                                    >
                                      {langConstant.FREQUENCY_OF_FILE}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <Field
                                      style={{ width: "100%", margin: "10px" }}
                                      name="FREQUENCY"
                                    >
                                      {() => (
                                        <TextField
                                          style={{
                                            width: "80%",
                                            paddingBottom: "10px",
                                          }}
                                          value={values.FREQUENCY}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "FREQUENCY",
                                              e.target.value
                                            )
                                          }
                                          placeholder={
                                            langConstant.FREQUENCY_OF_FILE
                                          }
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputField,
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>

                                  <ErrorMessage name="FREQUENCY">
                                    {(msg) => (
                                      <span className={classes.errorTxt}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    <Typography
                                      className={classes.customerFormLabel}
                                    >
                                      {langConstant.DELIVERY_METHOD}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <Field
                                      style={{ width: "100%", margin: "10px" }}
                                      name="DELIVERY_METHOD"
                                    >
                                      {() => (
                                        <TextField
                                          style={{
                                            width: "80%",
                                            paddingBottom: "10px",
                                          }}
                                          value={values.DELIVERY_METHOD}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "DELIVERY_METHOD",
                                              e.target.value
                                            )
                                          }
                                          placeholder={
                                            langConstant.DELIVERY_METHOD
                                          }
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputField,
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>

                                  <ErrorMessage name="DELIVERY_METHOD">
                                    {(msg) => (
                                      <span className={classes.errorTxt}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    <Typography
                                      className={classes.customerFormLabel}
                                    >
                                      {langConstant.DESTINATION_FOLDER}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <Field
                                      style={{ width: "100%", margin: "10px" }}
                                      name="DEDICATED_BUCKET_FOLDER_NAME"
                                    >
                                      {() => (
                                        <TextField
                                          style={{
                                            width: "80%",
                                            paddingBottom: "10px",
                                          }}
                                          value={
                                            values.DEDICATED_BUCKET_FOLDER_NAME
                                          }
                                          onChange={(e) =>
                                            setFieldValue(
                                              "DEDICATED_BUCKET_FOLDER_NAME",
                                              e.target.value
                                            )
                                          }
                                          placeholder={
                                            langConstant.DESTINATION_FOLDER
                                          }
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputField,
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <ErrorMessage name="DEDICATED_BUCKET_FOLDER_NAME">
                                    {(msg) => (
                                      <span className={classes.errorTxt}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    <Typography
                                      className={classes.customerFormLabel}
                                    >
                                      {langConstant.FILE_SYNTAX}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <Field
                                      style={{ width: "100%", margin: "10px" }}
                                      name="FILE_NAME_SYNTAX"
                                    >
                                      {() => (
                                        <TextField
                                          style={{
                                            width: "80%",
                                            paddingBottom: "10px",
                                          }}
                                          value={values.FILE_NAME_SYNTAX}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "FILE_NAME_SYNTAX",
                                              e.target.value
                                            )
                                          }
                                          placeholder={langConstant.FILE_SYNTAX}
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputField,
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>

                                  <ErrorMessage name="FILE_NAME_SYNTAX">
                                    {(msg) => (
                                      <span className={classes.errorTxt}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    <Typography
                                      className={classes.customerFormLabel}
                                    >
                                      {langConstant.SCHEDULE}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <Field
                                      style={{ width: "100%", margin: "10px" }}
                                      name="SCHEDULING_PERIOD"
                                    >
                                      {() => (
                                        <TextField
                                          style={{
                                            width: "80%",
                                            paddingBottom: "10px",
                                          }}
                                          value={values.SCHEDULING_PERIOD}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "SCHEDULING_PERIOD",
                                              e.target.value
                                            )
                                          }
                                          placeholder={langConstant.SCHEDULE}
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputField,
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>

                                  <ErrorMessage name="SCHEDULING_PERIOD">
                                    {(msg) => (
                                      <span className={classes.errorTxt}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    <Typography
                                      className={classes.customerFormLabel}
                                    >
                                      {langConstant.DATA_OWNER}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <Field
                                      style={{ width: "100%", margin: "10px" }}
                                      name="SOURCE_SYSTEM_OWNER"
                                    >
                                      {() => (
                                        <TextField
                                          style={{
                                            width: "80%",
                                            paddingBottom: "10px",
                                          }}
                                          value={values.SOURCE_SYSTEM_OWNER}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "SOURCE_SYSTEM_OWNER",
                                              e.target.value
                                            )
                                          }
                                          placeholder={langConstant.DATA_OWNER}
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputField,
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>

                                  <ErrorMessage name="SOURCE_SYSTEM_OWNER">
                                    {(msg) => (
                                      <span className={classes.errorTxt}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="td" scope="row">
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item md={4} xs={12}>
                                    <Typography
                                      className={classes.customerFormLabel}
                                    >
                                      {langConstant.DATA_OWNER_EMAIL_ADDRESS}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} xs={12}>
                                    <Field
                                      style={{ width: "100%", margin: "10px" }}
                                      name="SOURCE_SYSTEM_OWNER_EMAIL_ADDRESS"
                                    >
                                      {() => (
                                        <TextField
                                          style={{
                                            width: "80%",
                                            paddingBottom: "10px",
                                          }}
                                          value={
                                            values.SOURCE_SYSTEM_OWNER_EMAIL_ADDRESS
                                          }
                                          onChange={(e) =>
                                            setFieldValue(
                                              "SOURCE_SYSTEM_OWNER_EMAIL_ADDRESS",
                                              e.target.value
                                            )
                                          }
                                          placeholder={
                                            langConstant.DATA_OWNER_EMAIL_ADDRESS
                                          }
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputField,
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </Grid>

                                  <ErrorMessage name="SOURCE_SYSTEM_OWNER_EMAIL_ADDRESS">
                                    {(msg) => (
                                      <span className={classes.errorTxt}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel
                  value={value}
                  index={1}
                  style={{ minHeight: "500px", overflowY: "auto" }}
                >
                  <Grid
                    container
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table
                        className="data-table"
                        aria-label="Source System Details"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell component="td" scope="row">
                              <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                  {" "}
                                  {
                                    langConstant.DATA_ATTRIBUTES_EXPECTED_IN_SOURCE_FILE
                                  }
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <Grid
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <Tooltip
                              title={langConstant.ADD_NEW_ATTRIBUTE}
                              placement="top"
                            >
                              <IconButton
                                onClick={() => {
                                  addAttributeModal.openModal();
                                  addAttributeModal.setData(attributes);
                                }}
                              >
                                <AddBoxIcon
                                  sx={{
                                    color:
                                      themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                                    fontSize: "32px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid
                            style={{
                              padding: "5px",
                              alignItems: "center",
                            }}
                          >
                            {attributes && attributes.length > 0 && (
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={getListStyle(
                                        snapshot.isDraggingOver
                                      )}
                                    >
                                      {items.map((item, index) => (
                                        <Draggable
                                          key={item.id}
                                          draggableId={item.id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                              {item.content}
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </Grid>
                          {attributes && attributes.length == 0 && (
                            <Grid
                              style={{
                                padding: "20px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                {"    "}
                                {langConstant.NO_ATTRIBUTE_FOR_THE_SOURCE_FILE}
                              </Typography>
                            </Grid>
                          )}

                          {addAttributeModal?.isOpen && (
                            <AddAttribute
                              addAttributeModal={addAttributeModal}
                              setAttributes={setAttributes}
                            />
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </TabPanel>
                <Box mt="2%" display="flex" gap={2} justifyContent="right">
                  {" "}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px" }}
                  >
                    {langConstant.SAVE}
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={modal?.closeModal}
                  >
                    {langConstant.CANCEL}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

SourceSystemsForm.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default SourceSystemsForm;
