import { Avatar, Box, Grid } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";
import PropTypes from "prop-types";
import AssessmentTableList from "./AssessmentTableList";
import Tab from '@mui/material/Tab';
import langConstant from "_lang";
import ApproversList from "./ApproversList";

const AssessmentTags = ({ list, selectedTeamMember, approversDataList, setApproversDataList, approversForm }) => {

    const imgstr = "data:image/png;base64," + window.atob(selectedTeamMember.AVATAR_IMAGE ? selectedTeamMember.AVATAR_IMAGE : "");

    const [currentTab, setCurrentTab] = useState("assessment_table")

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <TabContext value={currentTab}>
            <Grid container>
                <Grid item md={10} xs={10}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab 
                                value="assessment_table" 
                                icon={<Avatar alt={selectedTeamMember.FIRST_NAME} src={imgstr} />}
                                iconPosition="start"
                                style={{fontSize: "12px"}} 
                                label={<span><strong>{selectedTeamMember.FIRST_NAME} {selectedTeamMember.LAST_NAME}</strong> {langConstant.IS_CURRENTLY_WORKING_ON}</span>}
                            />
                            <Tab label={langConstant.APPROVERS} value="approvers" style={{fontSize: "16px", fontWeight: "bold"}} />
                        </TabList>
                    </Box>
                </Grid>
            </Grid>
            <TabPanel value="assessment_table" style={{padding: "0"}}>
                <AssessmentTableList
                    list={list}
                    selectedTeamMember={selectedTeamMember}
                />
            </TabPanel>
            <TabPanel value="approvers" style={{padding: "0"}}>
                <ApproversList 
                    approversDataList={approversDataList}
                    setApproversDataList={setApproversDataList}
                    approversForm={approversForm}
                />
            </TabPanel>
        </TabContext>
    )
}

AssessmentTags.propTypes = {
    list: PropTypes.array,
    selectedTeamMember: PropTypes.object,
    approversDataList: PropTypes.array,
    setApproversDataList: PropTypes.func,
    approversForm: PropTypes.any,
};

export default AssessmentTags;

