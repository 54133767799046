import React from "react";
import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";

const getSanctionKeywords = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetSanctionKeywords", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putChatAuditLog = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutChatAuditLog", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getChatBotAnswer = (data) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/getChatBotAnswer", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
  // return await axios.post("https://ml-api.internal.regulativ.ai/getChatBotAnswer", data)
};

const performSplits = (data) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/splitFile", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
  // return await axios.post("https://ml-api.internal.regulativ.ai/splitFile", data)
};

const getPolicyChunkingAvailable = (policyID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyChunkingAvailable", {
      i_POLICY_ID: policyID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getChatAuditLogs = (policyID, setChatHistory) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetChatAuditLogs", {
        i_CONTEXT_ID: policyID,
        i_USER_ID: "",
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const chatHistory = [
            { messageBy: "chatBot", message: "Hello How Can I Help You ?" },
          ];
          if (response?.["#result-set-1"]?.length > 0) {
            const reversed = response["#result-set-1"].reverse();
            const updatedChatHistory = reversed.reduce(
              (history, chat) => {
                history.push({ messageBy: "you", message: chat.USER_INPUT });
                if (chat.ASSISTANT_RESPONSE) {
                  history.push({
                    messageBy: "chatBot",
                    message: chat.ASSISTANT_RESPONSE,
                  });
                }
                return history;
              },
              [...chatHistory]
            );
            setChatHistory(updatedChatHistory);
          } else {
            setChatHistory(chatHistory);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getSplitterDetails = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetSplitterDetails")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  getSanctionKeywords,
  getChatBotAnswer,
  putChatAuditLog,
  performSplits,
  getPolicyChunkingAvailable,
  getChatAuditLogs,
  getSplitterDetails,
};
