import React from "react";
import { alertActions } from "_actions";
import apiService from "_services/api.service";

const getRegulationDocsRequiredStatus = (
  setGraphData,
  setLoading,
  i_CUSTOMER_ID
) => {
  return (dispatch) => {
    setLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRegulationDocsRequiredStatus", {
        i_CUSTOMER_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const updatedGraphData = response["#result-set-1"].map((item) => ({
            REGULATION_SHORT_NAME: item.REGULATION_SHORT_NAME.replaceAll(
              "_",
              " "
            ),
            COMPLETE:
              item.DOCUMENT_COUNT == 0
                ? 0
                : parseInt((item.POLICY_COUNT * 100) / item.DOCUMENT_COUNT) >
                  100
                ? 100
                : parseInt((item.POLICY_COUNT * 100) / item.DOCUMENT_COUNT),
            "DOCS PROVIDED": item.POLICY_COUNT,
            "DOC REQUIRED": item.DOCUMENT_COUNT,
          }));
          setGraphData(updatedGraphData);
        },
        (error) => {
          setGraphData([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
};

const getRegulationDocsRequired = (
  setContentData,
  setLoading,
  i_CUSTOMER_ID,
  i_REGULATION_ID
) => {
  return (dispatch) => {
    setLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRegulationDocsRequired", {
        i_CUSTOMER_ID,
        i_REGULATION_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setContentData(response["#result-set-1"]);
        },
        (error) => {
          setContentData([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
};

export default { getRegulationDocsRequiredStatus, getRegulationDocsRequired };
