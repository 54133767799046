import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import langConstant from "_lang";
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Box, Grid } from '@material-ui/core';
import useStyles from './styles';
import classNames from 'classnames';
import AppPagination from '_components/AppPagination';


const SearchByTextDataTable = ({data, columns, type}) => {

    const count = type =="tag" ? 20 : 10
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(count);
    const [filteredRows, setFilteredRows] = useState(data)

    const calculatePageCount = (rows) => {
        return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
    };
    const [pageCount, setPageCount] = useState(calculatePageCount(data));

    useEffect(() => {
        setFilteredRows(data.slice(page * rowsPerPage, (page + 1) * rowsPerPage))
        setPageCount(calculatePageCount(data))
    },[page, data])

    const handleChangePage = (event) => {
        setPage(event);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, count));
        setPage(0);
    };

    const classes = useStyles();

    return (
        <>
            {pageCount > 1 && (
                <Box>
                    <Grid container justify="flex-end">
                        <AppPagination
                            colSpan={3}
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Box>
            )}
            <TableContainer component={Paper} className={classes.marginTop}>
                <Table className="data-table">
                    <TableHead>
                        <TableRow>
                            {columns.map((item, index) => (
                                <TableCell
                                    component="th"
                                    scope="row"
                                    key={"column_" + index + item.field}
                                    className={index === 0 ? classNames(classes.stickyColl, classes.cursorPointer) : classes.stickyColl}
                                    style={{ minWidth: item.minWidth }}
                                >
                                    {item.headerName}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.map((row, rowIndex) => (
                            <TableRow key={"row_" + rowIndex}>
                                {columns.map((column, index) => {
                                    const value = row[column.field];
                                    return (
                                        <TableCell
                                            component="td"
                                            scope="row"
                                            key={"column_" + index + row[columns[0].field]}
                                        >
                                            {column.format ? column.format(value) : value}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                        {data.length === 0 &&
                            <TableRow style={{ height: 53 }}>
                                <TableCell
                                    style={{ textAlign: "center" }}
                                    colSpan={columns.length + 1}
                                >
                                    {langConstant.NO_RECORD_FOUND}
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

SearchByTextDataTable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    type: PropTypes.string,
};

export default SearchByTextDataTable;