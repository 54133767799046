import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { GetActionListDetails, GetTeamDetails } from "../../services";
import { ListCard } from "./ListCard";
import { Add, Edit } from "@mui/icons-material";
import sessionService from "_services/session.service";
import { LeftPanel } from "./LeftPanel";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import { listDetailsStyles } from "./styles";
import { scrollToTop } from "_helpers/utils";
import { useModal } from "_hooks";
import { AddActionDialog } from "./AddActionDialog";
import { AddActionListDialog } from "../AddActionListDialog";
import { ACTION_STATUS_LIST } from "../../constants";

const groupDataByActionsPriority = (data) => {
  const groupedData = {};

  // Iterate through the data
  data?.forEach((item) => {
    const actionPriority = item?.ACTIONS_PRIORITY;

    // If the actionsListId doesn't exist in groupedData, create an entry
    if (!groupedData[actionPriority]) {
      groupedData[actionPriority] = {
        ACTIONS_PRIORITY: item?.ACTIONS_PRIORITY,
        ACTIONS_LIST_NAME: item?.ACTIONS_LIST_NAME,
        EMAIL_ADDRESS: item?.EMAIL_ADDRESS,
        [ACTION_STATUS_LIST[0].value]: [],
        [ACTION_STATUS_LIST[1].value]: [],
        [ACTION_STATUS_LIST[2].value]: [],
        ACTION_COUNT: 0,
      };
    }

    if (ACTION_STATUS_LIST?.some((i) => i.value == item?.ACTIONS_STATUS))
      groupedData[actionPriority].ACTION_COUNT++;
    // Categorize the item based on ACTIONS_STATUS
    switch (item?.ACTIONS_STATUS) {
      case ACTION_STATUS_LIST[0].value:
        groupedData[actionPriority][ACTION_STATUS_LIST[0].value].push(item);
        break;
      case ACTION_STATUS_LIST[1].value:
        groupedData[actionPriority][ACTION_STATUS_LIST[1].value].push(item);
        break;
      case ACTION_STATUS_LIST[2].value:
        groupedData[actionPriority][ACTION_STATUS_LIST[2].value].push(item);
        break;
      default:
        break;
    }
  });

  return Object.values(groupedData);
};

const Index = () => {
  const classes = listDetailsStyles();
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const { ACTIONS_LIST_ID } = useParams();
  const actionListDetails = useSelector(
    (state) => state?.auditActions?.actionListDetails
  );
  const userList = useSelector((state) => state?.auditActions?.userList);
  const selectedActionList =
    useSelector((state) => state?.auditActions?.actionList)?.find(
      (i) => i?.ACTIONS_LIST_ID == ACTIONS_LIST_ID
    ) || actionListDetails?.find((i) => i?.ACTIONS_LIST_ID == ACTIONS_LIST_ID);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };
  const [pageCount, setPageCount] = useState(0);
  const teamDetails = {
    me: {
      ...userList?.find((i) => userID == i?.USER_ID),
      ASSIGNED_ACTIONS: getActionCountByUserId(userID),
    },
    team: userList?.filter((i) => {
      let ASSIGNED_ACTIONS = getActionCountByUserId(i?.USER_ID);
      if (
        i?.USER_ID != userID &&
        actionListDetails?.some((list) => list?.ASSIGNED_TO == i?.USER_ID)
      ) {
        i["ASSIGNED_ACTIONS"] = ASSIGNED_ACTIONS;
        return i;
      }
    }),
  };
  const [filterdActionList, setFilteredActionList] = useState([]);
  const groupedData = groupDataByActionsPriority(filterdActionList);
  const addActionModal = useModal(),
    editActionListModal = useModal();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getActionCountByUserId(userId) {
    return (
      actionListDetails?.filter(
        (action) =>
          ACTION_STATUS_LIST?.some((i) => i.value == action?.ACTIONS_STATUS) &&
          action?.ASSIGNED_TO == userId
      )?.length ?? 0
    );
  }

  // filter list based on assiged user
  const onUserClick = (userId, reset) => {
    setFilteredActionList(
      reset
        ? actionListDetails
        : actionListDetails?.filter((i) => i?.ASSIGNED_TO == userId)
    );
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      groupedData?.length > 0
        ? Math.round(Math.ceil(groupedData.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const getActionsById = () => {
    if (!ACTIONS_LIST_ID) return;
    dispatch(GetActionListDetails(ACTIONS_LIST_ID));
  };

  useEffect(() => {
    if (!ACTIONS_LIST_ID) return;
    getActionsById();
    dispatch(GetTeamDetails(userID));
  }, []);

  useEffect(() => {
    if (!actionListDetails?.length) return;
    setPageCount(calculatePageCount(actionListDetails));
    setFilteredActionList(actionListDetails);
  }, [actionListDetails]);

  return (
    <>
      {addActionModal?.isOpen && (
        <AddActionDialog
          addActionModal={addActionModal}
          successCallback={getActionsById}
          actionList={selectedActionList}
        />
      )}
      {editActionListModal?.isOpen && (
        <AddActionListDialog
          addActionListModal={editActionListModal}
          mode="edit"
          actionList={selectedActionList}
        />
      )}
      <Grid className={classes.mainGridContainer} container>
        <LeftPanel userList={teamDetails} onUserClick={onUserClick} />
        <Grid className={classes.actionListWrapper} item>
          <Grid container wrap="wrap" className={classes.addActionBtnWrapper}>
            <Grid
              style={{ display: "flex", gap: 8, justifyContent: "flex-end" }}
              item
              xs={12}
              md={12}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Add />}
                onClick={addActionModal?.openModal}
                // component={Link}
              >
                Add Action
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Edit />}
                onClick={editActionListModal?.openModal}
                disabled={!selectedActionList}
                // component={Link}
              >
                Edit Action List
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.listCardWrapper}>
            {groupedData?.length ? (
              groupedData?.map((actionList, index) => (
                <ListCard
                  data={actionList}
                  key={actionList?.ACTIONS_LIST_ID || index}
                />
              ))
            ) : (
              <Typography
                color="textSecondary"
                variant="subtitle1"
                style={{ margin: "auto" }}
              >
                No records found
              </Typography>
            )}
          </Grid>
          <Box my={2}>
            <Grid container justify="flex-end">
              {groupedData?.length > 5 && (
                <SelectPageRange
                  selectedRange={rowsPerPage}
                  handleChange={handleSelectPageRange}
                />
              )}

              {pageCount > 1 && (
                <AppPagination
                  colSpan={3}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Index;
