/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React from "react";
import { alertActions, loadingActions } from "../../_actions";
import apiService from "../../_services/api.service";
import { constants } from "./constants.js";
import { constants as evidenceConstant} from "containers/cyber-assessment/AssessmentEvidence/constants.js";
import langConstant from "_lang";

const getEvidenceBySubmissionId = (questionId, submissionId) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetSubmissionEvidenceFromEvidenceLog", {i_QUESTION_ID: questionId, i_SUBMISSION_ID: submissionId  })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            dispatch({
              type: constants.getEvidence_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch(alertActions.error(langConstant.EVIDENCE_NOT_FOUND));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
            dispatch({
              type: constants.getEvience_FAILURE,
              data: null,
            });
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
const getEvidence = (questionId,submissionId) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetEvidenceFromEvidenceLog", { i_QUESTION_ID: questionId, i_SUBMISSION_ID: submissionId })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            dispatch({
              type: constants.getEvidence_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch(alertActions.error(langConstant.EVIDENCE_NOT_FOUND));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
            dispatch({
              type: constants.getEvidence_FAILURE,
              data: null,
            });
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateEvidenceStatus = (evidenceLogID, status, userId) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateEvidenceStatus", {
        i_EVIDENCE_LOG_ID: evidenceLogID,
        i_DECISION_TYPE_ID: status,
        i_USER_ID: userId
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            dispatch(alertActions.success(langConstant.EVIDENCE_STATUS_UPDATED));
          }
          else{
            dispatch(alertActions.error(langConstant.EVIDENCE_NOT_FOUND));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  }
}

const getEvidenceComments = (submissionId, questionId, evidenceLogID) => {
  return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetEvidenceComments", {
        i_SUBMISSION_ID: submissionId,
        i_QUESTION_ID: questionId,
        i_EVIDENCE_LOG_ID: evidenceLogID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            return response["#result-set-1"]
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            return error.response.data.message
          } else {
            return error
          }
        }
      );
}

const updateEvidenceComment = (submissionId, questionId, evidenceLogID, userId, commentText) => {
  return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateEvidenceComments", {
        i_SUBMISSION_ID: submissionId,
        i_QUESTION_ID: questionId,
        i_EVIDENCE_LOG_ID: evidenceLogID,
        i_USER_ID: userId,
        i_COMMENT: commentText,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            return response
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            return error.response.data.message
          } else {
            return error
          }
        }
      );
}

const updateEvidenceLog = (questionId, evidenceLogID, keyWords, comments, setEditTagsData, type) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateEvidenceLog", {
        i_EVIDENCE_LOG_ID: evidenceLogID,
        i_QUESTION_ID: questionId,
        i_COMMENTS: comments,
        i_KEYWORDS: keyWords,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            const payload = {
              evidenceLogId: evidenceLogID,
              questionId: questionId,
              keyWords: keyWords,
              comments: comments,
            }
            dispatch({
              type: type === "assessment" ? evidenceConstant.updateEvidenceTags_SUCCESS : constants.update_EvidenceTags_SUCCESS,
              data: payload,
            });
            setEditTagsData({})
            dispatch(
              alertActions.success(
                  langConstant.EVIDENCE_TAGS_UPDATED
              )
          );
          } else {
            dispatch(alertActions.error(langConstant.EVIDENCE_NOT_FOUND));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};


 
export default {
  getEvidence,
  updateEvidenceStatus,
  getEvidenceComments,
  updateEvidenceComment,
  updateEvidenceLog,
  getEvidenceBySubmissionId
};
