/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userActions from "./actions";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import VpnKey from "@material-ui/icons/VpnKey";
import { useFormik } from "formik";
import * as yup from "yup";
import { logoData } from "../../_assets/logoFile";
import styles from "./styles";
import TwoFAPage from "./2FAPage";
import QRCodePage from "./QRCodePage";
import langConstant from "_lang";

const LoginPage = () => {
  const validationSchema = yup.object({
    username: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });
  const classes = styles();
  const [isLoginView, setIsLoginView] = useState(true);
  const [userID, setUserID] = useState(0);
  const [currentScreen, setCurrentScreen] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const alert = useSelector((state) => state.alert);
  useEffect(() => {
    // dispatch(userActions.logout());
  }, [alert]);

  const redirectTo = (path) => {
    console.log(path);
    const { from } = location.state || { from: { pathname: path } };
    history.push(from.pathname);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        userActions.login(
          values.username,
          values.password,
          redirectTo,
          setIsLoginView,
          setUserID,
          setCurrentScreen
        )
      );
    },
  });

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.wrapper}
      style={{ background: logoData[window.REACT_APP_LOGIN_ENV]["bgColor"] }}
    >
      <Grid item>
        <Card className={classes.card}>
          <CardContent>
            <Box align="center">
              <img
                src={logoData[window.REACT_APP_LOGIN_ENV]["logo"]}
                style={{ width: "100%" }}
              />
            </Box>
            {isLoginView ? (
              <form
                autoComplete="off"
                className={classes.form}
                onSubmit={formik.handleSubmit}
              >
                <TextField
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    autoComplete: "off",
                    className: classes.input,
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{
                    className: classes.errorHelper,
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="username"
                  label=""
                  name="username"
                  placeholder="Username"
                  autoFocus
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                />
                <TextField
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    autoComplete: "off",
                    className: classes.input,

                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <VpnKey />
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{
                    className: classes.errorHelper,
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label=""
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                {/* <Typography align="right">
                  <Link to="/forgot-password" className={classes.link}>
                    {langConstant.FORGOT_PASSWORD}
                  </Link>
                </Typography> */}

                <Button
                  type="submit"
                  color="primary"
                  fullWidth
                  variant="contained"
                  size="large"
                  className={classes.submit}
                >
                  {langConstant.SIGN_IN}
                </Button>
              </form>
            ) : (
              <>
                {currentScreen == "QR" && (
                  <QRCodePage
                    redirectTo={redirectTo}
                    userID={userID}
                    setIsLoginView={setIsLoginView}
                  />
                )}
                {currentScreen == "EMAIL OTP" && (
                  <TwoFAPage
                    setIsLoginView={setIsLoginView}
                    redirectTo={redirectTo}
                    userID={userID}
                    username={formik.values.username}
                    password={formik.values.password}
                  />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
