import { makeStyles } from "@material-ui/core";

const leftPanelStyles = makeStyles(() => ({
  avatarImg: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  userCardContainer: {
    display: "flex",
    gap: 12,
    alignItems: "center",
  },
  leftPanelContainer: {
    borderRadius: "4px",
    boxShadow: "0px 0px 4px 2px #E5EAF2",
    backgroundColor: "#f5f5f5",
    transition: "all ease-in-out 0.3s",
    padding: ".5rem",
    width: ({ isOpen }) => (isOpen ? "20%" : "4%"),
  },
  teamWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    gap: 8,
    padding: ".25rem 1.25rem",
  },
  drawerIconContainer: {
    display: "flex",
    justifyContent: ({ isOpen }) => (isOpen ? "end" : "center"),
  },
  drawerIcon: {
    transition: "all ease-in-out 0.3s",
    transform: ({ isOpen }) => `rotate(${isOpen ? "90" : "270"}deg)`,
  },
  avatarContainer: {
    display: "flex",
    gap: 12,
    justifyContent: ({ isOpen }) => !isOpen && "center",
    alignItems: "center",
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  boldText: {
    fontWeight: "bold",
  },
  hr: { width: "100%" },
}));

const listDetailsStyles = makeStyles(() => ({
  mainGridContainer: { margin: "1rem 0rem", gap: "8px" },
  actionListWrapper: { flex: 1 },
  addActionBtnWrapper: {
    display: "inline-flex",
    justifyContent: "flex-end",
    padding: "1rem",
    textAlign: "end",
  },
  listCardWrapper: { padding: "2rem", display: "flex", gap: 32 },
}));

export { leftPanelStyles, listDetailsStyles };
