import React from "react";
import langConstant from "_lang";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment/moment";
import * as Yup from "yup";

const topicInitialValues = {
  ID: null,
  TOPIC_GROUP: "",
  TOPIC_NAME: "",
  TOPIC_CONTENT: "",
  TOPIC_URL: "",
  TYPE: "",
};

const topicValidationSchema = Yup.object().shape({
  TOPIC_GROUP: Yup.string().trim().required("Topic Group Is Required"),
  TOPIC_NAME: Yup.string().trim().required("Topic Name Is Required"),
  TOPIC_CONTENT: Yup.string().trim().required("Topic Content Is Required"),
  TYPE: Yup.string().trim().required("Type Is Required"),
});

export default {
  topicInitialValues,
  topicValidationSchema,
};
