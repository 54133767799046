import {
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import langConstant from "_lang";
import useStyles from "../styles";
import AdminUtils from "../utils";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import AdminServices from "../services";
import OptionComponent from "containers/manage-regulation/NewRegulation/ManageQuestions/component/OptionComponent";
import Select from "react-select";
import CountryDropdown from "_components/CountryListDropDown";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import classNames from "classnames";
import CloseIcon from "@mui/icons-material/Close";

const LegalEntityForm = ({
  show,
  setShow,
  legalEntityData,
  setLegalEntityData,
  legalEntityTypes,
  selectedLegalEntity,
  selectedCustomer,
}) => {
  const handleClose = () => setShow(false);
  const classes = useStyles();

  const dispatch = useDispatch();

  const initialValues = selectedLegalEntity
    ? legalEntityData.find(
        (entity) => entity.LEGAL_ENTITY_ID == selectedLegalEntity
      )
    : AdminUtils.initialLegalEntityValues;

  const submitFormData = (values) => {
    values["CUSTOMER_ID"] = selectedCustomer.CUSTOMER_ID;
    if (selectedLegalEntity) {
      dispatch(
        AdminServices.updateCustomerLE(
          values,
          setLegalEntityData,
          legalEntityData,
          handleClose
        )
      );
    } else {
      dispatch(
        AdminServices.putNewCustomerLE(values, setLegalEntityData, handleClose)
      );
    }
  };

  return (
    <Dialog open={show} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
      <Grid style={{ padding: "20px" }}>
        <Grid className={classes.entityPopupHeader}>
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            {langConstant.LEGAL_ENTITY_FORM}
          </Typography>
          <CloseIcon onClick={handleClose} />
        </Grid>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={AdminUtils.legalEntityValidationSchema}
          validateOnChange={true}
          onSubmit={submitFormData}
        >
          {({ values, setFieldValue, touched, errors }) => (
            <Form>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.LEGAL_ENTITY_NAME}
                  </Typography>
                  <Field
                    style={{ width: "100%", margin: "10px" }}
                    name="LEGAL_ENTITY_NAME"
                  >
                    {() => (
                      <TextField
                        style={{ width: "80%", paddingBottom: "10px" }}
                        value={values.LEGAL_ENTITY_NAME}
                        onChange={(e) =>
                          setFieldValue("LEGAL_ENTITY_NAME", e.target.value)
                        }
                        placeholder={langConstant.LEGAL_ENTITY_NAME}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            input: classes.inputField,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="LEGAL_ENTITY_NAME">
                    {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                  </ErrorMessage>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.LEGAL_ENTITY_TYPE}
                  </Typography>
                  <Field
                    style={{ width: "100%", margin: "10px" }}
                    name="LE_TYPE_ID"
                  >
                    {() => (
                      <Select
                        className={classes.customerSelectForm}
                        options={legalEntityTypes}
                        components={{ Option: OptionComponent }}
                        closeMenuOnSelect={false}
                        close
                        hideSelectedOptions={false}
                        value={legalEntityTypes.find(
                          (type) => type.value == values.LE_TYPE_ID
                        )}
                        placeholder={langConstant.LEGAL_ENTITY_TYPE}
                        onChange={(type) =>
                          setFieldValue("LE_TYPE_ID", type.value)
                        }
                      />
                    )}
                  </Field>
                  <ErrorMessage name="LE_TYPE_ID">
                    {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                  </ErrorMessage>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.LEGAL_ENTITY_ADDRESS}
                  </Typography>
                  <Field
                    style={{ width: "100%", margin: "10px" }}
                    name="LEGAL_ENTITY_ADDRESS"
                  >
                    {() => (
                      <TextField
                        style={{ width: "80%", paddingBottom: "10px" }}
                        value={values.LEGAL_ENTITY_ADDRESS}
                        onChange={(e) =>
                          setFieldValue("LEGAL_ENTITY_ADDRESS", e.target.value)
                        }
                        placeholder={langConstant.LEGAL_ENTITY_ADDRESS}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            input: classes.inputField,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="LEGAL_ENTITY_ADDRESS">
                    {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                  </ErrorMessage>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.RESPONSIBLE_DEPARTMENT}
                  </Typography>
                  <Field
                    style={{ width: "100%", margin: "10px" }}
                    name="RESPONSIBLE_DEPARTMENT"
                  >
                    {() => (
                      <TextField
                        style={{ width: "80%", paddingBottom: "10px" }}
                        value={values.RESPONSIBLE_DEPARTMENT}
                        onChange={(e) =>
                          setFieldValue(
                            "RESPONSIBLE_DEPARTMENT",
                            e.target.value
                          )
                        }
                        placeholder={langConstant.RESPONSIBLE_DEPARTMENT}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            input: classes.inputField,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="RESPONSIBLE_DEPARTMENT">
                    {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                  </ErrorMessage>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.CONTRACT_DURATION}
                  </Typography>
                  <Field
                    style={{ width: "100%", margin: "10px" }}
                    name="CONTRACT_DURATION"
                  >
                    {() => (
                      <TextField
                        style={{ width: "80%", paddingBottom: "10px" }}
                        value={values.CONTRACT_DURATION}
                        onChange={(e) =>
                          setFieldValue("CONTRACT_DURATION", e.target.value)
                        }
                        placeholder={langConstant.CONTRACT_DURATION}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            input: classes.inputField,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="CONTRACT_DURATION">
                    {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                  </ErrorMessage>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.PRODUCT_SERVICE_PROVIDED}
                  </Typography>
                  <Field
                    style={{ width: "100%", margin: "10px" }}
                    name="PRODUCT_SERVICE_PROVIDED"
                  >
                    {() => (
                      <TextField
                        style={{ width: "80%", paddingBottom: "10px" }}
                        value={values.PRODUCT_SERVICE_PROVIDED}
                        onChange={(e) =>
                          setFieldValue(
                            "PRODUCT_SERVICE_PROVIDED",
                            e.target.value
                          )
                        }
                        placeholder={langConstant.PRODUCT_SERVICE_PROVIDED}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            input: classes.inputField,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="PRODUCT_SERVICE_PROVIDED">
                    {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                  </ErrorMessage>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.COUNTRY_CODE}
                  </Typography>
                  <Field name="COUNTRY_CODE">
                    {() => (
                      <Grid style={{ width: "80%" }}>
                        <CountryDropdown
                          value={values.COUNTRY_CODE}
                          onChange={(e) =>
                            setFieldValue("COUNTRY_CODE", e.value)
                          }
                          placeholder="Select Country"
                        />
                      </Grid>
                    )}
                  </Field>
                  <ErrorMessage name="COUNTRY_CODE">
                    {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                  </ErrorMessage>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.ACTIVE}
                  </Typography>
                  <Field style={{ width: "100%" }} name="ACTIVE">
                    {() => (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={JSON.parse(values.ACTIVE)}
                        onChange={(e) =>
                          setFieldValue("ACTIVE", e.target.value)
                        }
                        style={{
                          display: "inline",
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.START_DATE}
                  </Typography>
                  <Field name="START_DATE">
                    {() => (
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        style={{ width: "100%" }}
                      >
                        <KeyboardDatePicker
                          style={{ marginRight: "1em", width: "80%" }}
                          inputVariant="outlined"
                          variant="inline"
                          format="dd-MMM-yy"
                          autoOk
                          minDate={values.START_DATE}
                          disablePast
                          value={values.START_DATE}
                          onChange={(val) => {
                            setFieldValue("START_DATE", val);
                          }}
                          InputProps={{ readOnly: true }}
                          className={classNames(
                            classes.input,
                            classes.dateInput
                          )}
                          error={
                            touched.START_DATE && Boolean(errors.START_DATE)
                          }
                          helperText={touched.START_DATE && errors.START_DATE}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </Field>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.END_DATE}
                  </Typography>
                  <Field name="END_DATE">
                    {() => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ marginRight: "1em", width: "80%" }}
                          inputVariant="outlined"
                          variant="inline"
                          format="dd-MMM-yy"
                          autoOk
                          minDate={values.START_DATE}
                          disablePast
                          value={values.END_DATE}
                          onChange={(val) => {
                            setFieldValue("END_DATE", val);
                          }}
                          InputProps={{ readOnly: true }}
                          className={classNames(
                            classes.input,
                            classes.dateInput
                          )}
                          error={touched.END_DATE && Boolean(errors.END_DATE)}
                          helperText={touched.END_DATE && errors.END_DATE}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid style={{ marginRight: "15px", marginTop: "15px" }}>
                <Button type="submit" variant="contained" color="primary">
                  {selectedLegalEntity
                    ? langConstant.UPDATE_LEGAL_ENTITY
                    : langConstant.CREATE_LEGAL_ENTITY}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  );
};

LegalEntityForm.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  legalEntityData: PropTypes.array,
  setLegalEntityData: PropTypes.func,
  legalEntityTypes: PropTypes.array,
  selectedLegalEntity: PropTypes.number,
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default LegalEntityForm;
