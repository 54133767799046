import React, { useState } from "react";
import propTypes from "prop-types";
import {
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { MoreVert as ActionIcon } from "@mui/icons-material";
import { AssignListDialog } from "./AssignListDialog";
import { SetStatusDialog } from "./SetStatusDialog";
import { SetPriorityDialog } from "./SetPriorityDialog";
import { AddNoteDialog } from "./AddNoteDialog";
import { AddClosureDateDialog } from "./AddClosureDateDialog";
import { CommentsDialog } from "./CommentsDialog";
import { AddActionDialog } from "./AddActionDialog";
import { Tooltip } from "@mui/material";
import { listOfActions } from "../../constants";
import { useDispatch } from "react-redux";
import { GetActionListDetails } from "../../services";

export const ActionCard = ({ action, isLastChild }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDialog, setCurrentDialog] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const modal = {
    closeModal: () => setCurrentDialog(null),
    isOpen: currentDialog !== null,
  };

  const onMenuItemClick = (type) => {
    handleClose();
    setCurrentDialog(type);
  };

  const _renderDialog = () => {
    const commonProps = {
      modal,
      action,
      submitCallback: () =>
        dispatch(GetActionListDetails(action?.ACTIONS_LIST_ID)),
    };
    switch (currentDialog) {
      case listOfActions[0].label:
        return (
          <AddActionDialog
            addActionModal={modal}
            actionList={action}
            isViewMode
          />
        );
      case listOfActions[1].label:
        return <AssignListDialog {...commonProps} />;
      case listOfActions[2].label:
        return <SetStatusDialog {...commonProps} />;
      case listOfActions[3].label:
        return <SetPriorityDialog {...commonProps} />;
      // case listOfActions[3].label:
      //   return <CommentsDialog modal={modal} action={action} />;
      case listOfActions[4].label:
        return <AddNoteDialog {...commonProps} />;
      case listOfActions[5].label:
        return <AddClosureDateDialog {...commonProps} />;
      default:
        return <></>;
    }
  };

  return (
    <React.Fragment>
      {_renderDialog()}
      <Box
        borderBottom={isLastChild ? "none" : "1px solid #E5EAF2"}
        justifyItems="center"
        alignItems="center"
        display="flex"
        flex="auto"
        paddingX="1.5rem"
        style={{ gap: 32 }}
      >
        <Tooltip title="Short Description">
          <Typography
            style={{
              textTransform: "uppercase",
              alignItems: "center",
            }}
            variant="body2"
            color="textPrimary"
          >
            {action?.ACTIONS_SHORT_DESCRIPTION}
          </Typography>
        </Tooltip>
        <Tooltip title="Assigned To">
          <Typography
            style={{
              textTransform: "uppercase",
              alignItems: "center",
            }}
            variant="body2"
            color="textPrimary"
          >
            {action?.FIRST_NAME} {action?.LAST_NAME}
          </Typography>
        </Tooltip>
        {action?.DUE_DATE && (
          <Tooltip title="Due Date">
            <Typography
              style={{
                alignItems: "center",
              }}
              variant="body2"
              color="textPrimary"
            >
              {new Date(action?.DUE_DATE).toLocaleDateString()}
            </Typography>
          </Tooltip>
        )}
        <Typography
          style={{
            flex: 1,
            textTransform: "capitalize",
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
          variant="body2"
          color="textPrimary"
        >
          <Tooltip title="Full Description">
            {action?.ACTIONS_FULL_DESCRIPTION}
          </Tooltip>
        </Typography>
        <IconButton aria-label="action" onClick={handleClick}>
          <ActionIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {listOfActions.map((actionItem) => {
            const Icon = actionItem?.icon;
            return (
              <MenuItem
                key={actionItem.label}
                onClick={() => onMenuItemClick(actionItem.label)}
                disabled={actionItem?.isDisabled}
              >
                <Box display="flex" justifyItems="center" style={{ gap: 16 }}>
                  <Icon />
                  <Typography style={{ alignSelf: "center" }} variant="body2">
                    {actionItem.label}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </React.Fragment>
  );
};

ActionCard.propTypes = {
  action: propTypes.object,
  isLastChild: propTypes.bool,
};
