import { ReviewManagerAssessmentConstants } from "./constants"

const INITIAL_STATE = {
    QuestionDetails: [],
    ObservationDetails: [],
    TrustScoreForAllQuestion: []
}

export default function reviewManagerReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ReviewManagerAssessmentConstants.SET_REVIEW_MANAGER_QUESTIONS:
            return {
                ...state,
                QuestionDetails: action.payload
            };
        case ReviewManagerAssessmentConstants.SET_REVIEW_MANAGER_OBSERVATIONS:
            return {
                ...state,
                ObservationDetails: action.payload
            };
        case ReviewManagerAssessmentConstants.SET_REVIEW_MANAGER_TRUST_SCORE_ALL_QUESTIONS:
            return {
                ...state,
                TrustScoreForAllQuestion: action.payload,
            };
        case ReviewManagerAssessmentConstants.REVIEW_MANAGER_CLEANUP:
            return {
                ...state,
                ObservationDetails: [],
                QuestionDetails: [],
                TrustScoreForAllQuestion: []
            };
        default:
            return state;
    }
}