import React from "react";
import CardMedia from "@mui/material/CardMedia";
import DocumentViewer from "_components/DocumentViewer";
import PropTypes from "prop-types";
import useStyles from "../styles";
import { Typography } from "@material-ui/core";
import DialogBox from "_components/DialogBox";

const DisplayPopup = ({ open, onClose, url, contentType }) => {
  const classes = useStyles();

  return (
    <DialogBox maxWidth="lg" open={open} handleClose={onClose}>
      {contentType == "document" && (
        <DocumentViewer urlData={url} height="620px" width="600px" />
      )}
      {contentType == "video" && (
        <video width="100%" height="400px" controls>
          <source src={url[0]["uri"]} type="video/mp4" />
        </video>
      )}
      {contentType == "image" && (
        <CardMedia
          component="img"
          height="400px"
          width="400px"
          image={url[0]["uri"]}
          alt="Image"
        />
      )}
      {contentType == "unknown" && <Typography>{url}</Typography>}
    </DialogBox>
  );
};

DisplayPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.array.isRequired,
  contentType: PropTypes.string.isRequired,
};
export default DisplayPopup;
