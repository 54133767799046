import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import taskListStyles from "./styles";
import { Task } from "@mui/icons-material";
import { Popup } from "./components/Popup";
import { GetActionsByListId, GetUsersTaskList } from "./services";
import sessionService from "_services/session.service";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { uniqBy } from "lodash";

// const taskList = [
//   {
//     actionListId: 1,
//     actionListName: "Action List 1",
//     actions: [
//       { actionId: 1, actionName: "action 1" },
//       { actionId: 1, actionName: "action 2" },
//       { actionId: 1, actionName: "action 3" },
//       { actionId: 1, actionName: "action 4" },
//       { actionId: 1, actionName: "action 5" },
//       { actionId: 1, actionName: "action 6" },
//       { actionId: 1, actionName: "action 7" },
//       { actionId: 1, actionName: "action 8" },
//     ],
//   },
//   {
//     actionListId: 2,
//     actionListName: "Action List 2",
//     actions: [
//       { actionId: 1, actionName: "action 1" },
//       { actionId: 1, actionName: "action 7" },
//       { actionId: 1, actionName: "action 8" },
//     ],
//   },
//   {
//     actionListId: 3,
//     actionListName: "Action List 3",
//     actions: [
//       { actionId: 1, actionName: "action 1" },
//       { actionId: 1, actionName: "action 2" },
//       { actionId: 1, actionName: "action 3" },
//       { actionId: 1, actionName: "action 4" },
//     ],
//   },
//   {
//     actionListId: 4,
//     actionListName: "Action List 4",
//     actions: [
//       { actionId: 1, actionName: "action 1" },
//       { actionId: 1, actionName: "action 2" },
//     ],
//   },
//   {
//     actionListId: 5,
//     actionListName: "Action List 6",
//     actions: [],
//   },
//   {
//     actionListId: 6,
//     actionListName: "Action List 6",
//     actions: [
//       { actionId: 1, actionName: "action 1" },
//       { actionId: 1, actionName: "action 2" },
//     ],
//   },
// ];

const TaskListPopup = () => {
  const dispatch = useDispatch();
  const fontColor = window.THEME_FONT_COLOR;
  const userId = sessionService.getUserId();
  const unreadTaskCount = 0;
  const classes = taskListStyles({ fontColor });
  const [anchorEl, setAnchorEl] = useState(null);
  const [taskList, setTaskList] = useState([]);
  const isPopupOpen = Boolean(anchorEl);

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const showError = (message) => {
    dispatch(alertActions.error(message || "Something went wrong"));
  };

  const getTaskList = () => {
    GetUsersTaskList(userId)
      .then((response) => {
        if (response?.status === 200) {
          const list = uniqBy(response?.["#result-set-1"], "ACTIONS_LIST_ID");
          setTaskList(list);
        } else {
          showError(
            response?.data?.message?.map((item, index) => {
              return <span key={index}>{item}</span>;
            })
          );
        }
      })
      .catch((err) => {
        showError(
          err?.data?.message?.map((item, index) => {
            return <span key={index}>{item}</span>;
          }) ?? err?.toString()
        );
        console.error("error", err);
      });
  };

  useEffect(() => {
    if (!userId) return;
    getTaskList();
  }, []);

  return (
    <React.Fragment>
      <IconButton
        classes={{ root: classes.notificationBell }}
        aria-label="show 17 new notifications"
        onClick={onClick}
        className={classes.badgeWrap}
      >
        <Task />
        {unreadTaskCount !== 0 && (
          <span className={classes.countWrapper}>{unreadTaskCount}</span>
        )}
      </IconButton>
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        taskList={taskList}
      />
    </React.Fragment>
  );
};

export default TaskListPopup;
