import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import manageQuestionsAction from "../actions";
import { useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router";


const useStyles = makeStyles(() => ({
    inputProfileField: {
        padding: "7px 0px !important",
        width: "50%",
        color: "#000",
    },
    header: {
        fontWeight: "bold",
        paddingBottom: "15px"
    }
}))

const NewQuestionDialog = ({open , setOpen, regulatorId, countryCode, customerID}) => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const classes = useStyles()
    const [error, setError] = useState({isError: false, text: ""})
    const [noOfQuestion, setNoOfQuestion] = useState("")
    const handleClose = () => setOpen(false)

    const putNewQuestion = () => {
        if(!noOfQuestion || (parseInt(noOfQuestion) <= 0 && parseInt(noOfQuestion) > 1000)){
            setError({isError: true, text: langConstant.ADD_NO_BETWEEN_1_TO_1000})
        } else {
            const payload = {
                i_NO_OF_QUESTIONS: noOfQuestion,
                i_REGULATOR_ID: regulatorId,
                i_REGULATION_ID: id,
                i_COUNTRY_CODE: countryCode,
                i_CUSTOMER_ID: customerID
            }
            dispatch(manageQuestionsAction.putNewQuestion(payload))
            handleClose();
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <Grid container>
                    <Grid item md={11} xs={9}>
                        <Typography className={classes.header}>{langConstant.ENTER_NUMBER_OF_QUESTIONS_TO_ADD}</Typography>
                    </Grid>
                    <Grid container item md={1} xs={3} justify="flex-end">
                        <CloseIcon style={{cursor: "pointer", fontWeight: "bold"}} onClick={handleClose} />
                    </Grid>
                </Grid>
                <TextField
                    className={classes.inputProfileField}
                    type="number"
                    value={noOfQuestion ? noOfQuestion : ""}
                    onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                            if(e.target.value === "" || (parseInt(e.target.value) <= 0 || parseInt(e.target.value) > 1000)){
                                setError({isError: true, text: langConstant.ADD_NO_BETWEEN_1_TO_1000})
                            } else {
                                setError({isError: false, text: ""})
                            }
                            setNoOfQuestion(e.target.value)
                        }
                    }}
                    variant="outlined"
                />
                {error.isError && 
                    <Typography style={{color: "red"}}>{error.text}</Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained" 
                    color="primary"
                    style={{marginRight: "10px"}} 
                    onClick={putNewQuestion}
                >{langConstant.SAVE}</Button>
                <Button color="primary" variant="outlined" onClick={handleClose} >
                    {langConstant.CANCEL}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

NewQuestionDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    regulatorId: PropTypes.number, 
    countryCode: PropTypes.string, 
    customerID: PropTypes.string
};

export default NewQuestionDialog;