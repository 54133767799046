import React from "react";

export const panelSectorOptions = [
  { id: "Panel1", label: "Company Data And Information" },
  { id: "Panel2", label: "Risk Management" },
  { id: "Panel3", label: "RAID" },
  { id: "Panel4", label: "Compliance Actions" },
  { id: "Panel5", label: "Threats and Vulnerabilities" },
  { id: "Panel6", label: "Policy and Documentation" },
  { id: "Panel7", label: "Third Party Risk" },
  { id: "Panel8", label: "Breaches and Incidents" },
  { id: "Panel9", label: "Assessment Summary Status" },
];

export const applyGroupByColumn = (data, labelColumn, valueColumn) => {
  let headers = [];
  let values = {};
  let headerWithLabel = {};
  let graphData = [];

  data.forEach((item) => {
    if (headers.some((header) => header["value"] === item[valueColumn])) {
      values[item[valueColumn].toString()].push(item);
    } else {
      headers.push({
        label: item[labelColumn],
        value: item[valueColumn].toString(),
      });
      headerWithLabel[item[valueColumn].toString()] = item[labelColumn];
      values[item[valueColumn].toString()] = [item];
    }
  });

  if (valueColumn === "ACTIONS_LIST_ID") {
    Object.keys(values).forEach((item) => {
      let graphSubData = {
        ACTION_LIST: headerWithLabel[item],
        IN_PROGRESS: 0,
        TO_DO: 0,
        DONE: 0,
      };
      values[item].forEach((subItem) => {
        if (subItem.ACTIONS_STATUS === "IN_PROGRESS") {
          graphSubData = {
            ...graphSubData,
            IN_PROGRESS: graphSubData.IN_PROGRESS + 1,
          };
        }
        if (subItem.ACTIONS_STATUS === "TO_DO") {
          graphSubData = {
            ...graphSubData,
            TO_DO: graphSubData.TO_DO + 1,
          };
        }
        if (subItem.ACTIONS_STATUS === "DONE") {
          graphSubData = {
            ...graphSubData,
            DONE: graphSubData.DONE + 1,
          };
        }
      });
      graphData.push(graphSubData);
    });
  }

  return {
    headers,
    values,
    graphData,
  };
};
