import React from "react";
import langConstant from "_lang";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment/moment";
import * as Yup from "yup";

const OrgUnitsColumns = [
  { field: "ORG_UNIT_ID", headerName: langConstant.ID },
  { field: "ORG_UNIT_NAME", headerName: langConstant.ORG_UNIT_NAME },
  { field: "ORG_UNIT_PURPOSE", headerName: langConstant.ORG_UNIT_PURPOSE },
  { field: "ORG_UNIT_LEVEL", headerName: langConstant.ORG_UNIT_LEVEL },
  { field: "ORG_UNIT_OWNER", headerName: langConstant.ORG_UNIT_OWNER },
  { field: "PARENT_ORG_UNIT_ID", headerName: langConstant.PARENT_ORG_UNIT_ID },
  { field: "ORG_UNIT_REFERENCE", headerName: langConstant.ORG_UNIT_REFERENCE },
  {
    field: "ORG_UNIT_COST_CENTER",
    headerName: langConstant.ORG_UNIT_COST_CENTER,
  },
  {
    field: "HOME_COUNTRY_ISO_CODE",
    headerName: langConstant.COUNTRY,
    format: function formatData(value, countries) {
      if (value["HOME_COUNTRY_ISO_CODE"]) {
        const country = countries.find(
          (country) => country.COUNTRY_CODE == value["HOME_COUNTRY_ISO_CODE"]
        );
        return country.COUNTRY_NAME;
      } else {
        return "No Data";
      }
    },
  },
  {
    field: "ACTIVE",
    headerName: langConstant.ACTIVE,
    format: function formatData(value) {
      if (value["ACTIVE"]) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
];

const DocumentClassificationColumns = [
  { field: "ID", headerName: langConstant.ID },
  {
    field: "DOCUMENT_CLASSIFICATION_NAME",
    headerName: langConstant.DOCUMENT_CLASSIFICATION_NAME,
  },
  {
    field: "ACTIVE",
    headerName: langConstant.ACTIVE,
    format: function formatData(value) {
      if (value["ACTIVE"] && value["ACTIVE"] == 1) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
];

const QuestionClassificationColumns = [
  {
    field: "QUESTION_CLASSIFICATION_ID",
    headerName: langConstant.ID,
  },
  {
    field: "QUESTION_CLASSIFICATION_NAME",
    headerName: langConstant.QUESTION_CLASSIFICATION_NAME,
  },
  {
    field: "ACTIVE",
    headerName: langConstant.ACTIVE,
    format: function formatData(value) {
      if (value["ACTIVE"]) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
];

const SanctionKeywordsColumns = [
  { field: "SANCTION_KEYWORDS_ID", headerName: langConstant.ID },
  {
    field: "KEYWORD",
    headerName: langConstant.KEYWORD,
  },
  {
    field: "ACTIVE",
    headerName: "Active",
    format: function formatData(value) {
      if (value["ACTIVE"] && value["ACTIVE"] == 1) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
];

const orgUnitsInitialValues = {
  ORG_UNIT_ID: null,
  CISO_CONTROL_ID: null,
  ORG_UNIT_NAME: "",
  ORG_UNIT_PURPOSE: "",
  ORG_UNIT_LEVEL: 1,
  ORG_UNIT_OWNER: "",
  PARENT_ORG_UNIT_ID: null,
  ORG_UNIT_REFERENCE: null,
  HOME_COUNTRY_ISO_CODE: null,
  ORG_UNIT_COST_CENTER: "",
  ACTIVE: true,
  UPDATE_DATE: moment(),
  START_DATE: moment(),
  END_DATE: moment(),
};

const orgUnitsValidationSchema = Yup.object().shape({
  CISO_CONTROL_ID: Yup.number().required("CISO Controler Is Required"),
  ORG_UNIT_NAME: Yup.string().trim().required("ORG Unit Name Is Required"),
  ORG_UNIT_PURPOSE: Yup.string()
    .trim()
    .required("ORG Unit Purpose Is Required"),
  ORG_UNIT_LEVEL: Yup.number().required("ORG Unit Level Is Required"),
  ORG_UNIT_OWNER: Yup.string().trim().required("ORG Unit Owner Is Required"),
  HOME_COUNTRY_ISO_CODE: Yup.string().required("Home Country Is Required"),
  //   ORG_UNIT_COST_CENTER: Yup.string()
  //     .trim()
  //     .required("ORG Unit Cost Center Is Required"),
});

const sanctionKeywordInitialValues = {
  ACTIVE: 1,
  KEYWORD: "",
  SANCTION_KEYWORDS_ID: null,
};

const sanctionKeywordValidationSchema = Yup.object().shape({
  KEYWORD: Yup.string().trim().required("Sanction Keyword Is Required"),
});

const docClassificationInitialValues = {
  ID: null,
  DOCUMENT_CLASSIFICATION_NAME: "",
  ACTIVE: 1,
};

const docClassificationValidationSchema = Yup.object().shape({
  DOCUMENT_CLASSIFICATION_NAME: Yup.string()
    .trim()
    .required("Document Classification Name Is Required"),
});

const queClassificationInitialValues = {
  QUESTION_CLASSIFICATION_ID: null,
  QUESTION_CLASSIFICATION_NAME: "",
  ACTIVE: true,
  START_DATE: moment(),
  END_DATE: moment(),
};

const queClassificationValidationSchema = Yup.object().shape({
  QUESTION_CLASSIFICATION_NAME: Yup.string()
    .trim()
    .required("Question Classification Name Is Required"),
});

export default {
  OrgUnitsColumns,
  DocumentClassificationColumns,
  QuestionClassificationColumns,
  SanctionKeywordsColumns,
  orgUnitsInitialValues,
  orgUnitsValidationSchema,
  sanctionKeywordInitialValues,
  sanctionKeywordValidationSchema,
  docClassificationInitialValues,
  docClassificationValidationSchema,
  queClassificationInitialValues,
  queClassificationValidationSchema,
};
