import { Field } from "formik";
import React from "react";
import PropTypes from "prop-types";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import classNames from "classnames";
import moment from "moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    "& input.MuiInputBase-input": {
      padding: "10px",
      //   width: "80%",
    },
    "& .Mui-disabled": {
      color: "gray",
    },
  },
  dateInput: {
    "& dateInput .MuiFormControl-root": {
      marginRight: "2em",
    },
  },
}));

const FormDateField = ({ value, setFieldValue, fieldName, errors }) => {
  const classes = useStyles();
  return (
    <Field name="START_DATE">
      {() => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: "100%" }}>
          <KeyboardDatePicker
            style={{ width: "60%" }}
            inputVariant="outlined"
            variant="inline"
            format="dd-MMM-yy"
            autoOk
            minDate={moment()}
            disablePast
            value={value}
            onChange={(val) => {
              setFieldValue(fieldName, val);
            }}
            InputProps={{ readOnly: true }}
            className={classNames(classes.input, classes.dateInput)}
            error={Boolean(errors[fieldName])}
            helperText={errors[fieldName]}
          />
        </MuiPickersUtilsProvider>
      )}
    </Field>
  );
};

FormDateField.propTypes = {
  value: PropTypes.oneOfType([() => null, PropTypes.string]),
  setFieldValue: PropTypes.func,
  fieldName: PropTypes.string,
  errors: PropTypes.object,
};

export default FormDateField;
