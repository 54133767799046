/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { confirmationDialogActions } from "../_actions/confirmationDialog.action";
import rootStyles from "../rootStyles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" align="center">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    justifyContent: "center",
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ConfirmationDialog = (props) => {
  const rootClasses = rootStyles();
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setOpen(props.state);
  }, [props.state]);

  const handleClose = () => {
    setOpen(false);
    dispatch(confirmationDialogActions.close());
  };

  const handleConfirm = () => {
    props.onConfirm();
    setOpen(false);
    dispatch(confirmationDialogActions.close());
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          style={{ margin: "0 30px 0 30px" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {props.title ? props.title : "Confirm"}
        </DialogTitle>
        <DialogContent>
          <Typography
            style={{
              minWidth: "400px",
              minHeight: "60px",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 30px 0 30px",
              overflowWrap: "break-word",
            }}
            align="center"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: props.message,
            }}
          ></Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirm}
            color="primary"
            size="medium"
            autoFocus
          >
            {props.option1 ? props.option1 : "Confirm"}
          </Button>
          <Button onClick={handleClose} color="primary" size="medium">
            {props.option2 ? props.option2 : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmationDialog.propTypes = {
  state: PropTypes.bool,
  onConfirm: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  option1: PropTypes.string,
  option2: PropTypes.string,
};
export default ConfirmationDialog;
