// OP#3052 - UJC5.1 - Assign Assessment - Extension Features
// step2 screen for the assign assessment journey

import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Grid, makeStyles, MenuItem } from '@material-ui/core';
import TextField from "@mui/material/TextField";
import { Formik, Field, ErrorMessage, Form, useFormik, } from "formik";
import Divider from '@mui/material/Divider';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListSubheader from "@mui/material/ListSubheader";
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import { Fragment } from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import { blue } from '@mui/material/colors';
import DoneIcon from '@mui/icons-material/Done';
import sessionService from "_services/session.service";
import actions from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import langConstant from "_lang";
import * as yup from "yup";
import EditIcon from '@mui/icons-material/Edit';
import EmailDialog from "./components/EmailDialog";
import moment from "moment";
import { PutAssessments, GetAssessmentOriginator, PutMessageInLog, UpdateAssignedAssessments, GetEmailNotificationTemplateForUseCase, PutAssessmentExtraApprovers } from "../../services"
import { alertActions } from "_actions";
import { useHistory } from "react-router-dom";
import AwsEmailService from "_services/AwsEmailService"
import AssessmentTags from "./components/AssessmentTabs";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px"
  },
  bottomGridContainer: {
    marginTop: "20px",
    height: "calc(100vh - 230px)",
    maxHeight: "calc(100vh - 230px)",
  },
  btnWidth: {
    width: "100%",
    height: "40px"
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  margintop: {
    marginTop: "8px"
  },
  marginTopBottomNull: {
    marginTop: 0,
    marginBottom: 0
  }


}));

const Step2 = ({ finalQuestionsArray, EntityAndRegulationData, mode, setMode, setActivePage, FinalDomainAndQuestionList, isEdit, approversDataList, setApproversDataList }) => {
  const userID = sessionService.getUserId();
  const customerID = useSelector((state) => state.authentication.detailedInfo.CUSTOMER_ID)

  let AWSAccessKey = useSelector((state) => state.authentication.detailedInfo?.REACT_APP_AWS_ACCESS_KEY_ID);
  let AWSSecretKey = useSelector((state) => state.authentication.detailedInfo?.REACT_APP_AWS_SECRET_ACCESS_KEY);

  AWSAccessKey = window.atob(AWSAccessKey ? AWSAccessKey : "")
  AWSSecretKey = window.atob(AWSSecretKey ? AWSSecretKey : "")

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const initialValues2 = {
    PRIORITY: "",
    TEAM_MEMBERS: null,
    SUBMISSION_DEADLINE: null,
    TARGET_COMPLETION_DATE: null,
    ASSESSMENT_SOURCE: [],
  };

  const initialValues = {
    LEGAL_ENTITY: "",
    REGULATION: "",
  };

  const [formInitialData, setFormInitialData] = useState(initialValues);
  const [form2InitialData, setForm2InitialData] = useState(initialValues2);
  const [questionListArray, setquestionListArray] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [AssessmentSourceArray, setAssessmentSourceArray] = useState([]);
  const [FinalSourceArray, setFinalSourceArray] = useState([]);
  const [EditEmailBox, setEditEmailBox] = useState({
    visible: false,
    data: {
      EMAIL_TO: "",
      EMAIL_CC: [],
      EMAIL_BCC: [],
      EMAIL_SUBJECT: "",
      EMAIL_CLASSIFICATION: {},
      EMAIL_BODY: "",
    }
  });

  //approvers validation schema
  const approversValidationSchema = Yup.object().shape({
    approvers: Yup.array()
        .min(1)
        .of(
            Yup.object().shape({
                role: Yup.string().required("Role Required"),
                person: Yup.string().required("Person Required"),
            })
        )
  })

  //approvers formik
  
  const approversForm = useFormik({
    initialValues: {approvers: approversDataList.length > 0 ? [...approversDataList] : [{ id: 0, role: '', person: '', delegateTo: '', approveStatus: false }]},
    enableReinitialize: true,
    validationSchema: approversValidationSchema,
    validateOnBlur:false,
    validateOnChange:false,
    onSubmit: values => {
      return true
    }
  })

  const TeammembersArray = useSelector((state) => state.manageAssessment.list);

  const filterDataForList = () => {
    const temp = []
    const DomainNames = finalQuestionsArray.map((el) => el.REGULATOR_CONTROL_GROUP);
    const finalset = new Set([...DomainNames]);
    const finalArray = [...finalset];
    finalArray.forEach((el) => {
      let questionsOfDomain = finalQuestionsArray.filter((ques) => ques.REGULATOR_CONTROL_GROUP === el);
      temp.push({ group: el, questions: questionsOfDomain })
    });
    setquestionListArray(temp)
  }

  const filterDataForRegulationAndEntity = () => {
    const entitydata = [...EntityAndRegulationData.entity].map((el) => el.LEGAL_ENTITY_NAME).join(",")

    const InitialData = {
      LEGAL_ENTITY: entitydata,
      REGULATION: EntityAndRegulationData.regulation.REGULATION_SHORT_NAME
    }
    setFormInitialData(InitialData);

  }

  const getSourceOfAssessment = async () => {
    const data = await GetAssessmentOriginator(customerID);

    if (data["#result-set-1"]) {
      setAssessmentSourceArray(data["#result-set-1"])
    }
  }

  useEffect(() => {
    if (customerID !== undefined) {
      getSourceOfAssessment();
    }
  }, [customerID]);

  const priorityArr = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ]


  useEffect(() => {
    if (isEdit === true) {

      if (Object.keys(EntityAndRegulationData.regulation).length > 0) {
        const InitialData = {
          PRIORITY: priorityArr.find((el) => el.label[0] === EntityAndRegulationData.regulation.PRIORITY).value,
          TEAM_MEMBERS: { USER_ID: EntityAndRegulationData.regulation.USER_ID, FIRST_NAME: EntityAndRegulationData.regulation.FIRST_NAME, LAST_NAME: EntityAndRegulationData.regulation.LAST_NAME },
          SUBMISSION_DEADLINE: EntityAndRegulationData.regulation.REGULATOR_SUBMISSION_DATE,
          TARGET_COMPLETION_DATE: EntityAndRegulationData.regulation.REGULATION_COMPLETION_DATE,
          ASSESSMENT_SOURCE: [],
        }
        setForm2InitialData(InitialData);
        setSelectedTeamMember({ USER_ID: EntityAndRegulationData.regulation.USER_ID, FIRST_NAME: EntityAndRegulationData.regulation.FIRST_NAME, LAST_NAME: EntityAndRegulationData.regulation.LAST_NAME, AVATAR_IMAGE: EntityAndRegulationData.regulation.ANALYST_AVATAR })
      }
    }
  }, [EntityAndRegulationData])

  useEffect(() => {
    filterDataForList();
    filterDataForRegulationAndEntity();
    dispatch(actions.getTeamsAssessments(userID));

  }, [finalQuestionsArray, EntityAndRegulationData]);

  useEffect(() => {
    const sortedArray = _.orderBy(_.uniqBy(TeammembersArray, "USER_ID"), "FIRST_NAME");
    setTeamOptions(sortedArray)
  }, [TeammembersArray]);

  const ImgFinderFunction = (imgName) => {
    if (imgName) {
      let element = teamOptions.find((option) => option.FIRST_NAME + " " + option.LAST_NAME === imgName);
      if (!element) {
        return "";
      }
      return "data:image/png;base64," + window.atob(element.AVATAR_IMAGE);
    }
  };

  const QuestionList = () => {
    return (

      <List
        dense
        sx={{ width: "100%", bgcolor: "background.paper" }}
      >
        {questionListArray.sort((a, b) => a.group.trim().localeCompare(b.group.trim())).map((el) => {
          return (
            <Fragment key={`${el.group}-Frag`}>
              <ListSubheader disableSticky style={{ zIndex: 0 }}><span style={{ fontWeight: "800" }}>{el.group}</span></ListSubheader>
              {el.questions.map((ques) => {
                const labelId = `checkbox-list-secondary-label-${ques.QUESTION_ID}`;
                return (
                  <ListItem
                    key={ques.QUESTION_ID}
                    disablePadding
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <ListItemButton>
                      <ListItemText
                        id={labelId}
                        primary={ques.QUESTION_ID}
                        style={{ maxWidth: "100px", minWidth: "100px", marginRight: "10px" }}
                      />
                      <ListItemText
                        id={labelId}
                        primary={ques.QUESTION_TEXT}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </Fragment>
          );
        })}
      </List>
    )
  }

  const validation2Schema = yup.object({
    PRIORITY: yup.string().required("required"),
    TEAM_MEMBERS: yup.object().nullable().required("required"),
    SUBMISSION_DEADLINE: yup.string().nullable().required("required"),
    TARGET_COMPLETION_DATE: yup
      .date()
      .nullable()
      .required("required")
      .when("SUBMISSION_DEADLINE", (endDate) => {
        return endDate
          ? yup
            .date()
            .max(
              endDate,
              langConstant.ERROR_TARGET_COMPLETION_DATE_MUST_BE_EQUAL_OR_LESS_THAN_SUBMISSION_DEADLINE
            )
            .nullable()
            .required("required")
          : yup
            .date()
            .nullable()
            .required("required")
      }),
    ASSESSMENT_SOURCE: yup.array().nullable(),
  });

  const checkApproveFormIsValid = (values) => {
    let isValidFlag = true
    if(values['approvers'].length === 1 && values['approvers'][0]['role'] === "" && values['approvers'][0]['person'] === ""){
      isValidFlag = true
    } else {
      for(let i=0; i< values['approvers'].length; i++){
        if(values['approvers'][i]['role'] === "" || values['approvers'][i]['person'] === "" ){
          isValidFlag = false;
          break;
        }
      }
    }
    return isValidFlag
  }

  const SubmitFormData = async (values, { resetForm }) => {
    let approversDetails = approversForm.values.approvers
    if(approversForm.values['approvers'].length === 1 && approversForm.values['approvers'][0]['role'] === "" && approversForm.values['approvers'][0]['person'] === ""){
      approversDetails = []
    } else {
      approversForm.handleSubmit()
    }
    const isValidForm = checkApproveFormIsValid(approversForm.values)
    if(isValidForm){
      if (sendEmail === true) {
        if (EditEmailBox.data.EMAIL_SUBJECT.length === 0 || EditEmailBox.data.EMAIL_BODY.length === 0 || Object.keys(EditEmailBox.data.EMAIL_CLASSIFICATION).length === 0) {
          return dispatch(alertActions.error("Email Template cannot be empty"));
        }
      }

      if (isEdit === false) {

        const removeArr = [...finalQuestionsArray];
        const finalarr = [...FinalDomainAndQuestionList.QuestionList].filter((el) => !removeArr.find((remove) => el.QUESTION_ID === remove.QUESTION_ID)).map((el) => ({ id: el.QUESTION_ID }));
        const stringifydata = JSON.stringify(finalarr);

        await Promise.all(
          [...EntityAndRegulationData.entity].map(async (el) => {
            let dataobj = {
              i_REGULATION_ID: EntityAndRegulationData.regulation.REGULATION_ID,
              i_LEGAL_ENTITY_ID: el.LEGAL_ENTITY_ID,
              i_ASSIGNED_TO: values.TEAM_MEMBERS.USER_ID,
              i_ASSIGNED_BY: userID,
              i_QUESTION_ID: stringifydata,
              i_SUBMISSION_DUE_DATE: moment(values.SUBMISSION_DEADLINE).format("YYYY-MM-DD HH:mm:ss"),
              i_COMPLETION_DATE: moment(values.TARGET_COMPLETION_DATE).format("YYYY-MM-DD HH:mm:ss"),
              i_PRIORITY: values.PRIORITY.slice(0, 1),
              i_ASSESSMENT_TAG: EntityAndRegulationData.assessmentTag,
            };

            const data = await PutAssessments(dataobj);
            // THIS SP WILL RETURN THE SUBMISSION ID OF THE ASSIGNED-ASSESSMENTS SO STORE IT FOR LATER

            if (data.status !== 200 && !data["#result-set-1"]) {
              dispatch(
                alertActions.error(
                  data.data.message.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })
                )
              );
            } else {
              if(approversDetails.length > 0){
                const assessmentId = data['#result-set-1'][0]['rect']
                let userId = ""
                let roleId = ""
                let delegateUserId = ""
                let sequenceId = ""
                approversDetails.map((item, index) => {
                  roleId = roleId + item['role'] + ","
                  userId = userId + item['person'] + ","
                  delegateUserId = item['delegateTo'] ? delegateUserId + item['delegateTo'] + "," : delegateUserId + 0 + ","
                  sequenceId = sequenceId + (index + 1) + ","
                })
                const approversPayload = {
                  i_SUBMISSION_ID: assessmentId,
                  i_USER_ID: userId.substring(0, userId.length - 1),
                  i_ROLE_ID: roleId.substring(0, roleId.length - 1),
                  i_DELEGATE_USER_ID: delegateUserId.substring(0, delegateUserId.length - 1),
                  i_SEQUENCEID: sequenceId.substring(0, sequenceId.length - 1),
                }
                await PutAssessmentExtraApprovers(approversPayload)
              }
              dispatch(alertActions.success("Assessmnet assigned successfully"));
              history.push("/manage-assignments");
            }


          })
        );
      } else {
        if (isEdit === true) {

          const assessmentId = finalQuestionsArray[0].SUBMISSION_ID
          let userId = ""
          let roleId = ""
          let delegateUserId = ""
          let sequenceId = ""
          approversDetails.map((item, index) => {
            roleId = roleId + item['role'] + ","
            userId = userId + item['person'] + ","
            delegateUserId = item['delegateTo'] ? delegateUserId + item['delegateTo'] + "," : delegateUserId + 0 + ","
            sequenceId = sequenceId + (index + 1) + ","
          })
          const approversPayload = {
            i_SUBMISSION_ID: assessmentId,
            i_USER_ID: userId.substring(0, userId.length - 1),
            i_ROLE_ID: roleId.substring(0, roleId.length - 1),
            i_DELEGATE_USER_ID: delegateUserId.substring(0, delegateUserId.length - 1),
            i_SEQUENCEID: sequenceId.substring(0, sequenceId.length - 1),
          }
          await PutAssessmentExtraApprovers(approversPayload)

          const obj = {
            i_SUBMISSION_ID: finalQuestionsArray[0].SUBMISSION_ID,
            i_ASSIGNED_TO: values.TEAM_MEMBERS.USER_ID,
            i_SUBMISSION_DUE_DATE: moment(values.SUBMISSION_DEADLINE).format("YYYY-MM-DD HH:mm:ss"),
            i_COMPLETION_DATE: moment(values.TARGET_COMPLETION_DATE).format("YYYY-MM-DD HH:mm:ss"),
            i_PRIORITY: values.PRIORITY.slice(0, 1),
          }

          const response = await UpdateAssignedAssessments(obj);

          if (response.status !== 200 && !response["#update-count-1"]) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.success("Assessmnet re-assigned successfully"));
            history.push("/manage-assignments");

          }


        }
      }

      if (sendEmail === true) {

        const EmailTitle = EditEmailBox.data.EMAIL_SUBJECT;
        const EmailBody = EditEmailBox.data.EMAIL_BODY;
        const EmailClassificationID = EditEmailBox.data.EMAIL_CLASSIFICATION.value;
        const EmailCC = [...EditEmailBox.data.EMAIL_CC].join(",");
        const EmailBCC = [...EditEmailBox.data.EMAIL_BCC].join(",");
        const EmailDateTime = moment().format("YYYY-MM-DD HH:mm:ss");

        await Promise.all(
          [...FinalSourceArray].map(async (el) => {
            let Obj = {
              i_ORIGINATOR_ID: el.ASSESSMENT_ORIGINATOR_ID,
              i_RFI_ID: null,
              i_SUBMISSION_ID: null,
              i_MESSAGE_TITLE: EmailTitle,
              i_MESSAGE_BODY: EmailBody,
              i_MESSAGE_TO: el.EMAIL_ADDRESS,
              i_MESSAGE_CC: EmailCC,
              i_MESSAGE_BCC: EmailBCC,
              i_MESSAGE_SENT_DATETIME: EmailDateTime,
              i_MESSAGE_CLASSIFICATION_ID: EmailClassificationID,
            }

            const res = await PutMessageInLog(Obj);

            if (res.status !== 200 && !res["#update-count-1"]) {
              dispatch(
                alertActions.error(
                  res.data.message.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })
                )
              );
            }

          })

        );

        const response = await GetEmailNotificationTemplateForUseCase(2, "Assessment_Assigned");

        if (!response["#result-set-1"] || response["#result-set-1"].length === 0) {
          dispatch(alertActions.error("Email Template Error"));
        } else {

          let templateData = JSON.parse(response["#result-set-1"][0].Notification_Template);
          let templateName = response["#result-set-1"][0].Notification_type;
          let sourceEmailAddress = response["#result-set-1"][0].OUTBOUND_EMAIL;

          await Promise.all(
            [...FinalSourceArray].map(async (el) => {

              let EmailObj = {
                EmailTo: [el.EMAIL_ADDRESS],
                EmailTitle,
                EmailBody,
                EmailCC: [...EditEmailBox.data.EMAIL_CC],
                EmailBCC: [...EditEmailBox.data.EMAIL_BCC],
                EmailSourceAddress: sourceEmailAddress
              }

              let userObj = {
                UserName: el.NAME
              }

              

            await AwsEmailService(AWSSecretKey, AWSAccessKey, EmailObj, userObj, templateData, templateName, dispatch);
          }));

        }
      }
    }
  }

  return (
    <Box >
      <Grid container={true} style={{ height: "100%" }}>
        <Grid item={true} xs={5} >
          <Formik
            enableReinitialize={true}
            initialValues={formInitialData}
          >
            {({ values, setFieldValue, resetForm, errors }) => (
              <Form>
                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      LEGAL ENTITY
                    </Typography>

                    <Field name="LEGAL_ENTITY">
                      {({ field: { value }, form: { errors } }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={value}
                          className={classes.width100}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      REGULATION
                    </Typography>

                    <Field name="REGULATION">
                      {({ field: { value }, form: { errors } }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={value}
                          className={classes.width100}
                          inputProps={
                            { readOnly: true, }
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      SCOPE
                    </Typography>
                    <Card variant="outlined" style={{ height: "50vh" }}>
                      <PerfectScrollbar >
                        <CardContent style={{ padding: "0px" }}>
                          {QuestionList()}
                        </CardContent>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            )}


          </Formik>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item={true} xs={6} >
          <Formik
            validateOnChange={true}
            initialValues={form2InitialData}
            enableReinitialize={true}
            validationSchema={validation2Schema}
            onSubmit={SubmitFormData}
          >
            {({ values, setFieldValue, resetForm, touched, errors, handleSubmit }) => (
              <Form>
                <Grid container className={classes.AddressGrid} justify="space-around">
                  <Grid item xs={4}>
                    <Typography className={classes.filedTitle}>
                      Select Priority
                    </Typography>

                    <Field name="PRIORITY">
                      {({ field: { value }, form: { errors } }) => (
                        <Select
                          value={value}
                          onChange={(e) => setFieldValue("PRIORITY", e.target.value)}
                          renderValue={(selected) => {
                            if (selected === undefined || selected === null || selected === "") {
                              return <span style={{ color: "#BBB6C4" }}>Select</span>;
                            }
                            return selected;
                          }}
                          displayEmpty
                          MenuProps={MenuProps}
                          className={`${classes.width100} ${classes.blurnevnt}`}
                          sx={{ height: 40 }}
                          error={touched.PRIORITY && Boolean(errors.PRIORITY)}
                        >
                          {priorityArr.map((el) => (
                            <MenuItem key={el.label} value={el.value}>
                              <ListItemText primary={el.label} />
                              <Checkbox checked={value.indexOf(el.value) > -1} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                    <ErrorMessage name="PRIORITY">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className={classes.filedTitle}>
                      Select Team Member to assign
                    </Typography>
                    <Field name="TEAM_MEMBERS">
                      {({ field: { value }, form: { errors } }) => (
                        <Autocomplete
                          size="small"
                          // style={{height:36}}
                          value={value}
                          onChange={(e, val) => { setSelectedTeamMember(val); setFieldValue("TEAM_MEMBERS", val) }}
                          isOptionEqualToValue={(option, value) => option.USER_ID === value.USER_ID}
                          autoHighlight
                          openOnFocus
                          id="checkboxes-tags-demo"
                          options={teamOptions}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.FIRST_NAME + " " + option.LAST_NAME}
                          renderOption={(props, option, { selected }) => (
                            <ListItem
                              {...props}
                              key={option.USER_ID}
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  checked={selected}
                                  inputProps={{ "aria-labelledby": option.USER_ID }}
                                />
                              }
                              style={{ padding: "0px" }}
                            >
                              <ListItemButton
                                sx={{ "&:hover": { backgroundColor: "transparent" } }}
                                disableRipple
                                disableTouchRipple
                              >
                                <ListItemAvatar>
                                  <Avatar alt={option.FIRST_NAME} src={"data:image/png;base64," + window.atob(option.AVATAR_IMAGE)} />
                                </ListItemAvatar>
                                <ListItemText id={option.USER_ID} primary={option.FIRST_NAME + " " + option.LAST_NAME} />
                              </ListItemButton>
                            </ListItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              placeholder="Select"
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <Avatar
                                    alt={params.inputProps.value}
                                    src={ImgFinderFunction(params.inputProps.value)}
                                    style={{ marginLeft: "15px", marginRight: "10px" }}
                                    sx={{ width: 30, height: 30, bgcolor: blue[800] }}
                                  />
                                ),
                              }}
                              error={touched.TEAM_MEMBERS && Boolean(errors.TEAM_MEMBERS)}
                            />
                          )}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="TEAM_MEMBERS">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid} justify="space-around">
                  <Grid item xs={4}>
                    <Typography className={classes.filedTitle}>
                      Select Target Completion Date
                    </Typography>

                    <Field name="TARGET_COMPLETION_DATE">
                      {({ field: { value }, form: { errors } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            size="small"
                            disableToolbar
                            inputVariant="outlined"
                            variant="inline"
                            format="dd MMM yyyy"
                            margin="normal"
                            autoOk
                            disablePast
                            value={value}
                            onChange={(val) => {
                              setFieldValue("TARGET_COMPLETION_DATE", val);
                            }}
                            InputProps={{ readOnly: true }}
                            className={`dueDate_error ${classes.width100} ${classes.marginTopBottomNull}`}
                            error={touched.TARGET_COMPLETION_DATE && Boolean(errors.TARGET_COMPLETION_DATE)}
                            helperText={null}
                          />
                        </MuiPickersUtilsProvider>

                      )}
                    </Field>
                    <ErrorMessage name="TARGET_COMPLETION_DATE">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className={classes.filedTitle}>
                      Select Submission Deadline
                    </Typography>

                    <Field name="SUBMISSION_DEADLINE">
                      {({ field: { value }, form: { errors } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                          <KeyboardDatePicker
                            size="small"
                            disableToolbar
                            inputVariant="outlined"
                            variant="inline"
                            format="dd MMM yyyy"
                            margin="normal"
                            autoOk
                            disablePast
                            value={value}
                            onChange={(val) => {
                              setFieldValue("SUBMISSION_DEADLINE", val);
                            }}
                            InputProps={{ readOnly: true }}
                            className={`dueDate_error ${classes.width100} ${classes.marginTopBottomNull}`}
                            error={touched.SUBMISSION_DEADLINE && Boolean(errors.SUBMISSION_DEADLINE)}
                            helperText={null}
                          />
                        </MuiPickersUtilsProvider>

                      )}
                    </Field>
                    <ErrorMessage name="SUBMISSION_DEADLINE">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>


                </Grid>

                <Grid container className={classes.AddressGrid} justify="space-around" >
                  <Grid item xs={4}>
                    <Typography className={classes.filedTitle}>
                      Source of assessment request
                    </Typography>

                    <Field name="ASSESSMENT_SOURCE">
                      {({ field: { value }, form: { errors } }) => (
                        <Select
                          multiple
                          value={value}
                          onChange={(e) => {
                            setFieldValue("ASSESSMENT_SOURCE", e.target.value);
                            setFinalSourceArray(e.target.value);

                            if (e.target.value.length === 0) {
                              setSendEmail(false)
                            } else {
                              const EmailTO = e.target.value.map((el) => el.EMAIL_ADDRESS).join("; ");
                              setEditEmailBox((prev) => ({ ...prev, data: { ...prev.data, EMAIL_TO: EmailTO } }))
                            }
                          }}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <span style={{ color: "#BBB6C4" }}>Select</span>;
                            }

                            return selected.map((el) => el.NAME).join(", ");
                          }}
                          displayEmpty
                          MenuProps={MenuProps}
                          className={`${classes.width100} ${classes.blurnevnt}`}
                          sx={{ height: 40 }}
                          error={touched.ASSESSMENT_SOURCE && Boolean(errors.ASSESSMENT_SOURCE)}
                        >
                          {AssessmentSourceArray.map((el) => (
                            <MenuItem key={el.ASSESSMENT_ORIGINATOR_ID} value={el}>
                              <ListItemText primary={`${el.NAME} - ${el.DESCRIPTION}`} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                    <ErrorMessage name="ASSESSMENT_SOURCE">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={4} />
                </Grid>

                <Grid container className={classes.AddressGrid} justify="space-around">
                  <Grid item xs={4} md={4} style={{ display: "flex", alignItems: "center" }}>
                    {
                      FinalSourceArray.length > 0 && (
                        <>
                          <Checkbox
                            edge="start"
                            onChange={(e, val) => { setSendEmail(val) }}
                            inputProps={{ "aria-labelledby": "id" }}

                          />
                          <Typography >
                            Send source a notification email
                          </Typography>
                        </>

                      )
                    }
                  </Grid>
                  <Grid item xs={4} md={4} style={{ display: "flex", alignItems: "center" }}>
                    {
                      sendEmail && (
                        <>
                          <EditIcon style={{ marginRight: "5px", fontSize: "28px", cursor: "pointer" }} color="success" onClick={() => { setEditEmailBox((prev) => ({ ...prev, visible: true })) }} />
                          <Typography >
                            Edit email to source
                          </Typography>
                        </>
                      )
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.AddressGrid} justify="center">
                  <Grid item xs={6} md={10} >                    
                    {
                      selectedTeamMember && (
                        <AssessmentTags 
                          list={TeammembersArray}
                          selectedTeamMember={selectedTeamMember}
                          approversDataList={approversDataList}
                          setApproversDataList={setApproversDataList}
                          approversForm={approversForm}
                        />
                      )
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.AddressGrid} justify="space-around" >
                  <Grid item xs={4} />
                  <Grid item xs={4} style={{textAlign: "right"}}>
                    <Button 
                      color="primary" 
                      type="submit"
                      style={{height: "40px"}} 
                      variant="contained" 
                      startIcon={ <DoneIcon style={{ fontSize: "30px" }}/> }
                    >
                      {
                        isEdit === false ? "ASSIGN ASSESSMENT" : "RE-ASSIGN ASSESSMENT"
                      }
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={1} md={1} style={{ alignSelf: "flex-end" }}>
          {
            isEdit === false && (
              <Button color="primary" onClick={() => { setActivePage(0); setMode("backAdd") }} className={classes.btnWidth} variant="contained" startIcon={<ArrowBackIcon style={{ fontSize: "30px" }} />}>
                BACK
              </Button>
            )
          }
        </Grid>
      </Grid>
      <EmailDialog FinalSourceArray={FinalSourceArray} EditEmailBox={EditEmailBox} setEditEmailBox={setEditEmailBox} userID={userID} />
    </Box>
  )
}


Step2.propTypes = {
  finalQuestionsArray: PropTypes.array,
  EntityAndRegulationData: PropTypes.object,
  mode: PropTypes.string,
  setMode: PropTypes.func,
  setActivePage: PropTypes.func,
  FinalDomainAndQuestionList: PropTypes.object,
  isEdit: PropTypes.bool,
  approversDataList: PropTypes.array,
  setApproversDataList: PropTypes.func,
}

export default Step2