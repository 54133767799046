// OP#2830 - UJA8 - UI Development - Manage Teams Profile
// reducer for Team Member

import constant from "./constants";

const initialState = { teamMembers: [] };

const updateTeamMember = (state, data) => {
    let teamMembers = [...state.teamMembers]

    return {
        teamMembers: teamMembers,
    }
}

export default function manageTeamMember(state = initialState, action) {
  switch (action.type) {
    case constant.GET_TEAM_MEMBERS:
      return {
        teamMembers: action.payload,
      };
    case constant.UPDATE_TEAM_MEMBERS:
      return updateTeamMember(state, action.payload);
    default:
      return state;
  }
}