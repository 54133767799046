import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "_components/DialogBox";
import moment from "moment";
import EvidenceTable from "_components/EvidenceLocker/component/EvidenceTable";
import evidenceAction from "_components/EvidenceLocker/actions";
import PropTypes from "prop-types";
import langConstant from "_lang";
import assessmentEvidenceAction from "../../AssessmentEvidence/actions";


const EvidenceLockerDialogBox = ({
  dialogOpen,
  submissionId,
  questionId,
  handleDialogClose,
  identifier,
  regulationId
}) => {
  const dispatch = useDispatch();
  const evidenceRowsWithQID = useSelector((state) => state.evidenceLocker.evidence);
  const evidenceRowsWithoutQID = useSelector(
    (state) => state.assessmentEvidenceLocker.evidence
  );

  const evidenceColumns = {
    'view_evidence': [
      {
        field: "Version_Label",
        headerName: langConstant.VERSION,
        maxWidth: 50,
      },
      { field: "EVIDENCE_SHORTNAME", headerName: "EVIDENCE TYPE" },
      {
        field: "DESCRIPTION",
        headerName: "Classification",
        // eslint-disable-next-line react/display-name
        format: (value) => value || <span style={{ color: "red" }}>No Data</span>,
      },
      { field: "EVIDENCE_STATUS", headerName: "Evidence Status" },

      {
        field: "EVIDENCE_WEIGHTAGE",
        headerName: "Weightage (In %)",
        format: function formatData(value) {
          if (value) {
            return value
          } else {
            return <Grid style={{ color: "red" }}>No Data</Grid>
          }
        }
      },
      {
        field: "Attachment_Label",
        headerName: langConstant.ATTACHMENTS,
      },
      {
        field: "Evidence_WordCloud",
        headerName: "Wordcloud",
        format: function formatData(value) {
          if (value) {
            return value
          } else {
            return <Grid style={{ color: "red" }}>No Data</Grid>
          }
        }
      },
      {
        field: "UPLOAD_DATE",
        headerName: langConstant.UPLOAD_DATE_AND_TIME,
        format: (value) =>
          value ? moment(value).format("DD-MMM-YY h:mm A") : "-",
      },
      {
        field: "EXPIRY_DATE",
        headerName: langConstant.EXPIRY_DATE,
        format: (value) =>
          value ? moment(value).format("DD-MMM-YY h:mm A") : "-",
      },
      {
        field: "Uploaded_By",
        headerName: langConstant.UPLOADED_BY,
      },
      {
        field: "METADATA",
        headerName: "Accuracy Score",
        format: function formatData(value) {
          if (value && value.length >= 1) {
            return value[0]
          } else {
            return <Grid style={{ color: "red" }}>No Data</Grid>
          }
        }
      },
      {
        field: "METADATA",
        headerName: "Suitability Score",
        format: function formatData(value) {
          if (value && value.length >= 2) {
            return value[1]
          } else {
            return <Grid style={{ color: "red" }}>No Data</Grid>
          }
        }
      },
    ],
    'tags': [
      {
        field: "Version_Label",
        headerName: "Version",
      },
      { field: "EVIDENCE_SHORTNAME", headerName: "Evidence Type", minWidth: 140 },
      {
        field: "COMMENTS_INPUT",
        headerName: "Comments",
        minWidth: 100,
      },
      {
        field: "Tags_Label",
        headerName: "TAGS",
        minWidth: 100,
      },
    ]
  }

  const fetchDataWithQID = () => {
    dispatch(evidenceAction.getEvidenceBySubmissionId(questionId, submissionId));
  };

  const fetchDataWithOutQID = () => {
    dispatch(assessmentEvidenceAction.getAssessmentEvidence(regulationId, "notvendor", submissionId));
  }

  useEffect(() => {

    if (identifier) {
      fetchDataWithQID();
    }

    if (!identifier) {
      fetchDataWithOutQID()
    }
  }, [submissionId, questionId, regulationId])

  return (
    <DialogBox
      maxWidth={"lg"}
      title={langConstant.EVIDENCE_LOCKER_TITLE}
      open={dialogOpen}
      handleClose={handleDialogClose}
      style={{ paddingTop: "0px", }}
    >
      <EvidenceTable
        rows={identifier ? evidenceRowsWithQID : evidenceRowsWithoutQID}
        columns={evidenceColumns}
        isAccordion={false}
        className="data-table"
        type="evidencePopup"
      />
    </DialogBox>

  );
};

EvidenceLockerDialogBox.propTypes = {
  questionId: PropTypes.string,
  submissionId: PropTypes.number,
  dialogOpen: PropTypes.bool,
  identifier: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  regulationId: PropTypes.number
};

export default EvidenceLockerDialogBox;
