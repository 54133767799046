import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import AppPagination from "_components/AppPagination";
import SelectPageRange from "_components/SelectPageRange";
import langConstant from "_lang";
import { useState } from "react";
import { scrollToTop } from "_helpers/utils";
import { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

const TableComponent = ({
  columns,
  rows,
  setSelectedRow,
  setDialog,
  countries = [],
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (rows && rows.length > 0) {
      setPageCount(calculatePageCount(rows));
    }
  }, [rows]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      rows?.length > 0 ? Math.round(Math.ceil(rows.length / range.value)) : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  return (
    <Grid>
      <TableContainer component={Paper}>
        <Table className="data-table" aria-label="Entitlement List">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                >
                  {item.headerName}
                </TableCell>
              ))}
              <TableCell>{langConstant.ACTIONS}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows ? (
              <React.Fragment>
                {(rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row, index) => {
                  return (
                    <TableRow key={index + row[columns[0].field]}>
                      {columns.map((column, index) => {
                        const value = row[column.field];
                        return (
                          <TableCell
                            component="td"
                            scope="row"
                            key={"column_" + index + row[columns[0].field]}
                            style={{
                              wordBreak: "break-word",
                              wordWrap: "break-word",
                            }}
                          >
                            {column.format
                              ? column.format(row, countries)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <VisibilityIcon
                          fontSize="small"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedRow(row);
                            setDialog(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}

                {rows && rows.length == 0 && (
                  <TableRow style={{ height: 53 }}>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={columns.length + 1}
                    >
                      {langConstant.NO_RECORD_FOUND}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid
          container
          justify="flex-end"
          style={{ display: "flex", alignItems: "center" }}
        >
          <SelectPageRange
            selectedRange={rowsPerPage}
            handleChange={handleSelectPageRange}
          />
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              size="small"
            />
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  setSelectedRow: PropTypes.func,
  setDialog: PropTypes.func,
  countries: PropTypes.array,
};

export default TableComponent;
