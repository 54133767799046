import { Button, Box, Dialog, DialogContent, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import langConstant from "_lang";
import Select from "react-select";
import VendorActions from "../actions";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import sessionService from "_services/session.service";
import classNames from 'classnames';
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import AssignAssessmentDataTable from "./AssignAssessmentDataTable";
import CloseIcon from "@mui/icons-material/Close";
import { loadingActions } from "_actions";


const useStyles = makeStyles(()=> ({
    filedTitleAssign:{
        marginBottom:"10px",
        fontWeight: "500",
        padding:"0px"
    },
    filedTitleHeader:{
        marginBottom:"10px",
        fontWeight: "600",
      
    },
    filedPointer:{
      cursor:"pointer",
    },
    filedDateAssign:{
        marginTop:"0",
        padding:"9.5px 1px",
    },
    input: {
        marginTop:"0px",
        marginBottom:"10px",
        width:"100%",
        "& input.MuiInputBase-input": {
            padding: '9.5px 8px',
            fontSize:"16px",
        },
        "& .Mui-disabled": {
            color: "gray"
        }
    },
}))

const AssignAssessmentDialog = ({show, setShow}) => {
    const userID = sessionService.getUserId();

    const [questionnaires, setQuestionnaires] = useState([])
    const [allSupplierInfo, setAllSupplierInfo] = useState([])
    const [supplier, setSupplier] = useState([])
    const [supplierSPOC, setSupplierSPOC] = useState([])
    const [assignedAssessmentsData, setAssignedAssessmentsData] = useState([])

    const dispatch = useDispatch()

    const formInitialValues = {
        QUESTIONNAIRES: "",
        SUPPLIER_ASSIGN: "",
        PRIORITY: "",
        SUPPLIER_SPOC_ASSIGN: "",
        SUBMISSION_DATE: moment().format("YYYY-MM-DD"),
        USERID: userID,
    }

    const [assignAssessmentFormData, setAssignAssessmentFormData] = useState(formInitialValues)
    const [assignAssessmentFormError, setAssignAssessmentFormError] = useState({
        QUESTIONNAIRES: null,
        SUPPLIER_ASSIGN: null,
        PRIORITY: null,
        SUPPLIER_SPOC_ASSIGN: null,
        SUBMISSION_DATE: null,
    })


    const classes = useStyles()

    const priority = [
        {value: 'high', label: 'High'},
        {value: 'medium', label: 'Medium'},
        {value: 'low', label: 'Low'},
    ]


    useEffect(() => {
        (async() => {
            const response = await VendorActions.getTPSRQuestionnaires();
            if(response.status === 200 && response['#result-set-1'].length){
                setQuestionnaires(response['#result-set-1'].map((item) => { return {value: item.REGULATION_ID, label: item.REGULATION_NAME} }))
            }
            const responseSPOC = await VendorActions.getSuppliersAndSpocs(userID);
            if(responseSPOC.status === 200){
                const uniqueArray = []
                setAllSupplierInfo(responseSPOC['#result-set-1'])
                const supplierData = responseSPOC['#result-set-1'].filter((item)=> {
                    if(!uniqueArray.includes(item.SUPPLIER_ID)){
                        uniqueArray.push(item.SUPPLIER_ID)
                        return item
                    }
                })
                setSupplier(supplierData.map((item) => { return {value: item.SUPPLIER_ID, label: item.SUPPLIER_NAME}}))
            }
        })();
    },[])

    const handleClose = () => {
        setShow(false)
    }

    const onSupplierChange = async(supplierId) => {
        dispatch(loadingActions.start())
        const assignedAssessmentResponse = await VendorActions.getAssignedAssessmentForSupplier(supplierId)
        dispatch(loadingActions.end())
        if(assignedAssessmentResponse.status === 200){
            setAssignedAssessmentsData(assignedAssessmentResponse['#result-set-1'])
        }
        const supplierSPOCData = allSupplierInfo.filter((item)=>item.SUPPLIER_ID===supplierId)
        setSupplierSPOC(supplierSPOCData.map((item) => { return {value: item.SUPPLIER_CONTACT_ID, label: item.SPOC_FIRSTNAME}}))
        setAssignAssessmentFormData((prevState) => ({...prevState, SUPPLIER_SPOC_ASSIGN: ''}))
    }

    const FormFields = [
        {
            fieldName: "QUESTIONNAIRES",
            label: langConstant.SELECT_QUESTIONNAIRES,
            tag: function selectAssessment(handleChange){
                return <Box className={classes.filedTitleAssign}>
                    <Select 
                        options={questionnaires}
                        placeholder={langConstant.SELECT}
                        value={questionnaires.find((item)=>item.value===assignAssessmentFormData.QUESTIONNAIRES)}
                        onChange={(questionnaire) => handleChange(questionnaire.value)}
                    />
                    {assignAssessmentFormError.QUESTIONNAIRES && 
                        <span style={{color:"red",fontSize:"12px"}}>
                            {assignAssessmentFormError.QUESTIONNAIRES}
                        </span>
                    }
                </Box>
            },
            errorLabel: langConstant.QUESTIONNAIRES_REQUIRED
        },
        {
            fieldName: "SUPPLIER_ASSIGN",
            label: langConstant.SELECT_SUPPLIER_TO_ASSIGN_TO,
            tag: function selectSupplierAssign(handleChange){
                return <Box className={classes.filedTitleAssign}>
                    <Select 
                        options={supplier}
                        placeholder={langConstant.SELECT}
                        value={supplier.find((item)=>item.value===assignAssessmentFormData.SUPPLIER_ASSIGN)}
                        onChange={(supplierAssign)=>{
                            handleChange(supplierAssign.value);
                            onSupplierChange(supplierAssign.value)
                        }}
                    />
                    {assignAssessmentFormError.SUPPLIER_ASSIGN && 
                        <span style={{color:"red",fontSize:"12px"}}>
                            {assignAssessmentFormError.SUPPLIER_ASSIGN}
                        </span>
                    }
                </Box>
            },
            errorLabel: langConstant.SUPPLIER_ASSIGN_REQUIRED
        },
        {
            fieldName: "SUPPLIER_SPOC_ASSIGN",
            label: langConstant.SELECT_SUPPLIER_SPOC_TO_ASSIGN_TO,
            tag: function selectSupplierSPOCAssign(handleChange){
                return <Box className={classes.filedTitleAssign}>
                    <Select 
                        options={supplierSPOC}
                        placeholder={langConstant.SELECT}
                        value={supplierSPOC.find((item)=>item.value===assignAssessmentFormData.SUPPLIER_SPOC_ASSIGN)}
                        onChange={(spoc)=>handleChange(spoc.value)}
                    />
                    {assignAssessmentFormError.SUPPLIER_SPOC_ASSIGN && 
                        <span style={{color:"red",fontSize:"12px"}}>
                            {assignAssessmentFormError.SUPPLIER_SPOC_ASSIGN}
                        </span>
                    }
                </Box>
            },
            errorLabel: langConstant.SUPPLIER_SPOC_ASSIGN_REQUIRED
        },
        {
            fieldName: "PRIORITY",
            label: langConstant.SELECT_PRIORITY,
            tag: function selectPriority(handleChange){
                return <Box className={classes.filedTitleAssign}>
                    <Select 
                        options={priority}
                        placeholder={langConstant.SELECT}
                        value={priority.find((item)=>item.value===assignAssessmentFormData.PRIORITY)}
                        onChange={(priorityData)=>handleChange(priorityData.value)}
                    />
                    {assignAssessmentFormError.PRIORITY && 
                        <span style={{color:"red",fontSize:"12px"}}>
                            {assignAssessmentFormError.PRIORITY}
                        </span>
                    }
                </Box>
            },
            errorLabel: langConstant.PRIORITY_REQUIRED
        },
        {
            fieldName: "SUBMISSION_DATE",
            label: langConstant.SELECT_SUBMISSION_DEADLINE_DATE,
            tag: function selectSubmissionDate(handleChange){
                return  <MuiPickersUtilsProvider className={classes.filedDateAssign} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        inputVariant="outlined"
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        autoOk
                        disablePast
                        value={assignAssessmentFormData.SUBMISSION_DATE}
                        onChange={(date) => handleChange(date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate())}
                        InputProps={{ readOnly: true }}
                        className={classNames(classes.input, classes.dateInput)}
                    />
                </MuiPickersUtilsProvider>
            },
            errorLabel: langConstant.SUBMISSION_DATE_REQUIRED
        },
    ]

    const AssignedAssessmentColumnData = [
        {field: "SPOC_NAME", headerName: "Contact Name", minWidth: 50},
        {field: "PRIORITY", headerName: "Priority", minWidth: 150},
        {
            field: "LEGAL_ENTITY_NAME",
            headerName: "Legal_Entity + Questionnaire Name",
            minWidth: 100,
            format: (row) =>
                row['LEGAL_ENTITY_NAME'] + ', ' + row['Questionnaire'],
        },
        {
            field: "STATUS",
            headerName: "Status",
            format: function formatData(row) {
                return <Grid style={{backgroundColor: "#7bff8b", padding: "5px 7px", fontWeight: '700', borderRadius: '4px'}}>{row['STATUS']}</Grid>
            },
            minWidth: 100
        },
        {
            field: "SUBMISSION_DATE", 
            headerName: "Deadline Date", 
            format: (row) =>
                row['SUBMISSION_DATE'] ? moment(row['SUBMISSION_DATE']).format("DD-MMM-YY") : "",
            minWidth: 100
        },
    ]

    const assignVendorAssessment = async() => {
        const errorData = Object.keys(assignAssessmentFormData).filter((item) => assignAssessmentFormData[item] === "")
        if(errorData.length){
            errorData.forEach((key) => setAssignAssessmentFormError((prevState) => ({...prevState, [key]: langConstant[key+'_REQUIRED']})))
        } else {
            dispatch(loadingActions.start())
            const response = await VendorActions.putAssignTPSRQuestionnaire(assignAssessmentFormData)
            dispatch(loadingActions.end())
            if(response && response.status === 200)
            {
                dispatch(alertActions.success(langConstant.ASSIGN_ASSESSMENT_SUCCESS))
                handleClose()
            }

        }
    }

    return (
        <Dialog
            open={show}
            fullWidth={true}
            BackdropProps={{invisible: true}}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                style: {
                  maxWidth: assignedAssessmentsData.length !== 0 ? "1200px" : "500px"
                },
              }}
        >
            <DialogContent style={{minHeight: '550px'}}>
                <Box>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography className={classes.filedTitleHeader}>
                                {langConstant.ASSIGN_VENDOR_ASSESSMENT}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} style={{textAlign: 'right'}}>
                            <CloseIcon className={classes.filedPointer} onClick={handleClose}/>
                        </Grid>
                    </Grid>
                    <Grid container>
                    <Grid  item xs={assignedAssessmentsData.length !== 0 ? 3 : 12}>
                        {FormFields.map((fieldColumn,index) => {
                            const handleChange = (fieldValue) => {
                                setAssignAssessmentFormData((prevState) => ({...prevState, [fieldColumn.fieldName]: fieldValue}))
                                if(fieldValue){
                                    setAssignAssessmentFormError((prevState) => ({...prevState, [fieldColumn.fieldName]: null}))
                                } else {
                                    setAssignAssessmentFormError((prevState) => ({...prevState, [fieldColumn.fieldName]: fieldColumn.errorLabel}))
                                }
                            }
                            return (
                                <Grid key={index}>
                                    
                                    <Typography>
                                        {fieldColumn.label}
                                    </Typography>
                                        {fieldColumn.tag(handleChange)}
                                   
                                </Grid>
                            )
                        })}
                        <Button 
                            color="primary"
                            style={{ marginTop:"10px"}}
                            variant="contained"
                            type="button"
                            size='small'
                            startIcon={<CheckIcon />}
                            onClick={()=>assignVendorAssessment()}
                        > {langConstant.ASSIGN_ASSESSMENT_CAP} </Button>
                    </Grid>
                        {assignedAssessmentsData.length !== 0 &&
                            <Grid item xs={9}>
                                <AssignAssessmentDataTable rows={assignedAssessmentsData} columns={AssignedAssessmentColumnData} />              
                            </Grid>
                        }
                    </Grid>
                   
                </Box>
            </DialogContent>
        </Dialog>
    )
}

AssignAssessmentDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
}

export default AssignAssessmentDialog;