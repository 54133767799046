import { generateBrightRandomColor } from "_helpers/utils";
import ExtractionService from "../../PolicyExplorer/component/NewPolicyUpload/services";
import { alertActions } from "_actions";

const getNodesData = (
  basePolicyId,
  targetPolicyId,
  setOptionsData,
  setSelectedOption,
  setNodes,
  setEdges
) => {
  return async (dispatch) => {
    const nodeData = [];
    const edgesData = [];
    if (basePolicyId) {
      const basePolicyResponse =
        await ExtractionService.getPolicyExtractionDetailsByPage({
          policyId: basePolicyId,
          pageNo: null,
        });
      if (basePolicyResponse.status == 200) {
        const tempBaseOptions = [];
        basePolicyResponse["#result-set-1"].forEach((element) => {
          let colorCode = generateBrightRandomColor();
          nodeData.push({
            id: element.POLICY_EXTRACTION_DETAILS_ID,
            title: element.NAME + " (b)",
            label: element.NAME + " (b)",
            shape: "circle",
            color: colorCode,
            policyType: "base",
            level: element.LEVEL,
            text: element.NAME,
          });
          const sentences = JSON.parse(element.SENTENCES);
          sentences.forEach((sentence, sentenceIndex) => {
            nodeData.push({
              id: element.POLICY_EXTRACTION_DETAILS_ID + "_" + sentenceIndex,
              title: sentence,
              label: "",
              shape: "triangle",
              color: colorCode,
              policyType: "base",
              level: "T",
              text: sentence,
            });
            edgesData.push({
              from: element.POLICY_EXTRACTION_DETAILS_ID,
              to: element.POLICY_EXTRACTION_DETAILS_ID + "_" + sentenceIndex,
              type: "base",
            });
          });
          tempBaseOptions.push({
            value: element.POLICY_EXTRACTION_DETAILS_ID,
            label: element.NAME,
            level: element.LEVEL,
          });
        });
        setOptionsData((prevState) => ({
          ...prevState,
          base: tempBaseOptions,
        }));
        setSelectedOption((prevState) => ({
          ...prevState,
          base: tempBaseOptions.length > 0 ? [tempBaseOptions[0]] : [],
        }));
      } else {
        dispatch(alertActions.error("Policy Extraction Details SP Failed."));
      }
    }
    if (targetPolicyId) {
      const targetPolicyResponse =
        await ExtractionService.getPolicyExtractionDetailsByPage({
          policyId: targetPolicyId,
          pageNo: null,
        });
      if (targetPolicyResponse.status == 200) {
        const tempTargetOptions = [];
        targetPolicyResponse["#result-set-1"].forEach((element) => {
          let colorCode = generateBrightRandomColor();
          nodeData.push({
            id: element.POLICY_EXTRACTION_DETAILS_ID,
            title: element.NAME + " (t)",
            label: element.NAME + " (t)",
            shape: "circle",
            color: colorCode,
            policyType: "target",
            level: element.LEVEL,
            text: element.NAME,
          });
          tempTargetOptions.push({
            value: element.POLICY_EXTRACTION_DETAILS_ID,
            label: element.NAME,
            level: element.LEVEL,
          });
          const sentences = JSON.parse(element.SENTENCES);
          sentences.forEach((sentence, sentenceIndex) => {
            nodeData.push({
              id: element.POLICY_EXTRACTION_DETAILS_ID + "_" + sentenceIndex,
              title: sentence,
              label: "",
              shape: "triangle",
              color: colorCode,
              policyType: "target",
              level: "T",
              text: sentence,
            });
            edgesData.push({
              from: element.POLICY_EXTRACTION_DETAILS_ID,
              to: element.POLICY_EXTRACTION_DETAILS_ID + "_" + sentenceIndex,
              type: "target",
            });
          });
        });
        setOptionsData((prevState) => ({
          ...prevState,
          target: tempTargetOptions,
        }));
        setSelectedOption((prevState) => ({
          ...prevState,
          target: tempTargetOptions.length > 0 ? [tempTargetOptions[0]] : [],
        }));
      } else {
        dispatch(alertActions.error("Policy Extraction Details SP Failed."));
      }
    }
    setNodes(nodeData);
    setEdges(edgesData);
  };
};

const filterNodes = (
  nodes,
  graphView,
  selectedOption,
  setFilteredNodes,
  optionsData
) => {
  let tempNodes = [...nodes];
  if (!graphView.base) {
    tempNodes = tempNodes.filter((node) => node.policyType !== "base");
  }
  if (!graphView.target) {
    tempNodes = tempNodes.filter((node) => node.policyType !== "target");
  }
  tempNodes = tempNodes.filter((node) => {
    const isBaseExit =
      selectedOption.base.length > 0
        ? selectedOption.base.some((option) => {
            const nodeID = node.id.toString().split("_");
            if (option.value == nodeID[0]) {
              return true;
            }
          })
        : optionsData.base.some((option) => {
            const nodeID = node.id.toString().split("_");
            if (option.value == nodeID[0]) {
              return true;
            }
          });
    const isTargetExit =
      selectedOption.target.length > 0
        ? selectedOption.target.some((option) => {
            const nodeID = node.id.toString().split("_");
            if (option.value == nodeID[0]) {
              return true;
            }
          })
        : optionsData.target.some((option) => {
            const nodeID = node.id.toString().split("_");
            if (option.value == nodeID[0]) {
              return true;
            }
          });
    if (isBaseExit || isTargetExit) {
      return node;
    }
  });
  setFilteredNodes(tempNodes);
  let baseHeaders =
    selectedOption.base.length > 0
      ? selectedOption.base
          .map(function (item) {
            return item.label;
          })
          .join(",")
      : optionsData.base
          .map(function (item) {
            return item.label;
          })
          .join(",");
  let targetHeaders =
    selectedOption.target.length > 0
      ? selectedOption.target
          .map(function (item) {
            return item.label;
          })
          .join(",")
      : optionsData.target
          .map(function (item) {
            return item.label;
          })
          .join(",");
  return { baseHeaders, targetHeaders };
};

export default {
  getNodesData,
  filterNodes,
};
