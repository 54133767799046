import moment from "moment";
import langConstant from "_lang";

export const normalViewColumns = [
    { field: "POLICYCOMPARISONID", headerName: "ID", minWidth: 50 },
    { field: "COMPARISON_NAME", headerName: langConstant.COMPARISON_NAME, minWidth: 50 },
    { field: "BASE_POLICY_NAME", headerName: langConstant.BASE_POLICY, minWidth: 50 },
    { field: "BASE_POLICY_ID", headerName: langConstant.BASE_ID, minWidth: 50 },
    { field: "TARGET_POLICY_NAME", headerName: langConstant.TARGET_POLICY, minWidth: 50 },
    { field: "TARGET_POLICY_ID", headerName: langConstant.TARGET_ID, minWidth: 50 },
    { field: "Comparison_status_Icon", headerName: langConstant.STATUS, minWidth: 50 },
    { field: "NAME", headerName: langConstant.UPLOADED_BY, minWidth: 50 },
    { field: "CURRENT_STATUS", headerName: langConstant.CURRENT_STATUS, minWidth: 50 },
    { field: "PC_COUNT", headerName: langConstant.COMPARISON_COUNT, minWidth: 50 },
    { field: "ARCHIVED_STATUS", headerName: langConstant.ARCHIVED, minWidth: 50 },
    {
        field: "CREATE_DATETIME",
        headerName: langConstant.COMPARISON_DATE_TIME,
        minWidth: 50,
        format: (value) =>
            value ? moment(value).format("DD-MMM-YY h:mm A") : "",
    },
    { field: "ACTION", headerName: langConstant.ACTIONS, minWidth: 50, sticky: true },
]

export const baseDocumentViewColumns = [
    { field: "POLICYCOMPARISONID", headerName: "ID", minWidth: 50 },
    { field: "COMPARISON_NAME", headerName: langConstant.COMPARISON_NAME, minWidth: 50 },
    { field: "TARGET_POLICY_NAME", headerName: langConstant.TARGET_POLICY, minWidth: 50 },
    { field: "TARGET_POLICY_ID", headerName: langConstant.TARGET_ID, minWidth: 50 },
    { field: "Comparison_status_Icon", headerName: langConstant.STATUS, minWidth: 50 },
    { field: "NAME", headerName: langConstant.UPLOADED_BY, minWidth: 50 },
    { field: "CURRENT_STATUS", headerName: langConstant.CURRENT_STATUS, minWidth: 50 },
    { field: "PC_COUNT", headerName: langConstant.COMPARISON_COUNT, minWidth: 50 },
    { field: "ARCHIVED_STATUS", headerName: langConstant.ARCHIVED, minWidth: 50 },
    {
        field: "CREATE_DATETIME",
        headerName: langConstant.COMPARISON_DATE_TIME,
        minWidth: 50,
        format: (value) =>
            value ? moment(value).format("DD-MMM-YY h:mm A") : "",
    },
    { field: "ACTION", headerName: langConstant.ACTIONS, minWidth: 50, sticky: true },
]

export const targetDocumentViewColumns = [
    { field: "POLICYCOMPARISONID", headerName: "ID", minWidth: 50 },
    { field: "COMPARISON_NAME", headerName: langConstant.COMPARISON_NAME, minWidth: 50 },
    { field: "BASE_POLICY_NAME", headerName: langConstant.BASE_POLICY, minWidth: 50 },
    { field: "BASE_POLICY_ID", headerName: langConstant.BASE_ID, minWidth: 50 },
    { field: "Comparison_status_Icon", headerName: langConstant.STATUS, minWidth: 50 },
    { field: "NAME", headerName: langConstant.UPLOADED_BY, minWidth: 50 },
    { field: "CURRENT_STATUS", headerName: langConstant.CURRENT_STATUS, minWidth: 50 },
    { field: "PC_COUNT", headerName: langConstant.COMPARISON_COUNT, minWidth: 50 },
    { field: "ARCHIVED_STATUS", headerName: langConstant.ARCHIVED, minWidth: 50 },
    {
        field: "CREATE_DATETIME",
        headerName: langConstant.COMPARISON_DATE_TIME,
        minWidth: 50,
        format: (value) =>
            value ? moment(value).format("DD-MMM-YY h:mm A") : "",
    },
    { field: "ACTION", headerName: langConstant.ACTIONS, minWidth: 50, sticky: true },
]

export const uploadedByViewColumns = [
    { field: "POLICYCOMPARISONID", headerName: "ID", minWidth: 50 },
    { field: "COMPARISON_NAME", headerName: langConstant.COMPARISON_NAME, minWidth: 50 },
    { field: "BASE_POLICY_NAME", headerName: langConstant.BASE_POLICY, minWidth: 50 },
    { field: "BASE_POLICY_ID", headerName: langConstant.BASE_ID, minWidth: 50 },
    { field: "TARGET_POLICY_NAME", headerName: langConstant.TARGET_POLICY, minWidth: 50 },
    { field: "TARGET_POLICY_ID", headerName: langConstant.TARGET_ID, minWidth: 50 },
    { field: "Comparison_status_Icon", headerName: langConstant.STATUS, minWidth: 50 },
    { field: "CURRENT_STATUS", headerName: langConstant.CURRENT_STATUS, minWidth: 50 },
    { field: "PC_COUNT", headerName: langConstant.COMPARISON_COUNT, minWidth: 50 },
    { field: "ARCHIVED_STATUS", headerName: langConstant.ARCHIVED, minWidth: 50 },
    {
        field: "CREATE_DATETIME",
        headerName: langConstant.COMPARISON_DATE_TIME,
        minWidth: 50,
        format: (value) =>
            value ? moment(value).format("DD-MMM-YY h:mm A") : "",
    },
    { field: "ACTION", headerName: langConstant.ACTIONS, minWidth: 50, sticky: true },
]

export const currentStatusViewColumns = [
    { field: "POLICYCOMPARISONID", headerName: "ID", minWidth: 50 },
    { field: "COMPARISON_NAME", headerName: langConstant.COMPARISON_NAME, minWidth: 50 },
    { field: "BASE_POLICY_NAME", headerName: langConstant.BASE_POLICY, minWidth: 50 },
    { field: "BASE_POLICY_ID", headerName: langConstant.BASE_ID, minWidth: 50 },
    { field: "TARGET_POLICY_NAME", headerName: langConstant.TARGET_POLICY, minWidth: 50 },
    { field: "TARGET_POLICY_ID", headerName: langConstant.TARGET_ID, minWidth: 50 },
    { field: "Comparison_status_Icon", headerName: langConstant.STATUS, minWidth: 50 },
    { field: "NAME", headerName: langConstant.UPLOADED_BY, minWidth: 50 },
    { field: "PC_COUNT", headerName: langConstant.COMPARISON_COUNT, minWidth: 50 },
    { field: "ARCHIVED_STATUS", headerName: langConstant.ARCHIVED, minWidth: 50 },
    {
        field: "CREATE_DATETIME",
        headerName: langConstant.COMPARISON_DATE_TIME,
        minWidth: 50,
        format: (value) =>
            value ? moment(value).format("DD-MMM-YY h:mm A") : "",
    },
    { field: "ACTION", headerName: langConstant.ACTIONS, minWidth: 50, sticky: true },
]