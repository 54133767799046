import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { UseModalPropType, useModal } from "_hooks";
import { alertActions } from "_actions";
import { Button } from "@material-ui/core";
import sessionService from "_services/session.service";
import langConstant from "_lang";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import LoupeIcon from "@material-ui/icons/Loupe";
import DeleteIcon from "@mui/icons-material/Delete";
import RegulatorServices from "../../../Regulators/services";
import Select from "react-select";
import { useDispatch } from "react-redux";
import QuestionServices from "../../IndividualQuestion/services";

const ResponseTypeWizardDialog = ({ modal }) => {
  const dispatch = useDispatch();
  const customerID = sessionService.getCustomerId();

  let initialValues = {
    responseList: [],
    RESPONSE_TYPE_ID: "",
  };

  const [responseTypes, setResponseTypes] = useState([]);

  const validationSchema = yup.object().shape({
    RESPONSE_TYPE_ID: yup.string().trim().required("Response Type Is Required"),
    responseList: yup.array().of(
      yup.object().shape({
        v_response_option: yup
          .string()
          .required("Header is a required field.")
          .matches(/^[^,]*$/, "Header cannot contain the ',' character."),
        v_response_value: yup
          .string()
          .required("Description is a required field.")
          .matches(/^[^,]*$/, "Description cannot contain the ',' character."),
      })
    ),
  });

  const onSubmit = async (values) => {
    const responseOptionIdList = [];
    const responseOptionList = [];
    const responseValueList = [];

    values.responseList.forEach((response, index) => {
      responseOptionIdList.push(index + 1);
      responseOptionList.push(response.v_response_option);
      responseValueList.push(response.v_response_value);
    });

    const payload = {
      i_RESPONSE_OPTION_ID: responseOptionIdList.join(","),
      i_RESPONSE_OPTION: responseOptionList.join(","),
      i_RESPONSE_VALUE: responseValueList.join(","),
      i_RESPONSE_TYPE_ID: values.RESPONSE_TYPE_ID,
      i_REGULATION_ID: parseInt(modal?.data, 10),
      i_CUSTOMER_ID: parseInt(customerID, 10),
    };

    dispatch(QuestionServices.putDefaultQuestionResponses(payload));
  };

  useEffect(() => {
    (async () => {
      const responseTypes = await RegulatorServices.getResponseType();
      if (responseTypes.status === 200) {
        setResponseTypes(
          responseTypes["#result-set-1"].map((item) => {
            return {
              value: item.RESPONSE_TYPE_ID,
              label: item.RESPONSE_TYPE_VALUE,
            };
          })
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Dialog
        maxWidth="lg"
        fullWidth
        onClose={modal?.closeModal}
        open={modal?.isOpen}
        disableEscapeKeyDown
        disableEnforceFocus
        sx={{
          backdropFilter: "blur(2px) brightness(0.8)",
        }}
      >
        {/* <Grid p={1.5} display="inline-flex" flex="auto"> */}
        <Grid p={1.5}>
          <DialogTitle sx={{ m: 0, p: 0, flex: 1, color: "#9d9d9d" }}>
            Select Control Response Options to apply across all controls in the
            Rule Book.
          </DialogTitle>
        </Grid>

        <DialogContent dividers>
          <Grid>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => {
                onSubmit(values);
                modal?.closeModal();
              }}
              validationSchema={validationSchema}
              render={({ values, setFieldValue, handleChange }) => {
                return (
                  <Form>
                    <Grid
                      container
                      display="flex"
                      style={{
                        marginTop: "5px",
                        paddingTop: "5px",
                      }}
                    >
                      <Grid item md={6} xs={12}>
                        <Typography> {langConstant.RESPONSE_TYPE}</Typography>
                        <Field name="RESPONSE_TYPE_ID">
                          {({ field: { value } }) => (
                            <Select
                              options={responseTypes}
                              placeholder="Select Response Type"
                              value={responseTypes.find(
                                (type) => type.value === value
                              )}
                              menuPortalTarget={document.body}
                              onChange={(type) =>
                                setFieldValue("RESPONSE_TYPE_ID", type.value)
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage name="RESPONSE_TYPE_ID">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                      <FieldArray
                        name="responseList"
                        render={({ remove, push }) => (
                          <>
                            <Grid item md={6} xs={12}>
                              <Button
                                type="button"
                                onClick={() => {
                                  push({
                                    v_response_option: "",
                                    v_response_value: "",
                                  });
                                }}
                                style={{
                                  color:
                                    values.responseList.length == 4
                                      ? "gray"
                                      : "#007BFF",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  margin: "20px 0 0 0px",
                                }}
                                disabled={values.responseList.length == 4}
                              >
                                <LoupeIcon style={{ marginRight: "10px" }} />
                                {langConstant.ADD_MORE_RESPONSE_CRITERIA}
                              </Button>
                            </Grid>

                            <Grid
                              container
                              style={{
                                marginTop: "5px",
                                paddingTop: "5px",
                              }}
                            >
                              {values.responseList &&
                                values.responseList.map((item, index) => (
                                  <Grid
                                    key={index}
                                    display="flex"
                                    style={{
                                      marginTop: "10px",
                                      width: "100%",
                                    }}
                                  >
                                    <Grid item md={6} xs={12}>
                                      <Typography
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {langConstant.ADD_HEADER}
                                      </Typography>
                                      <TextField
                                        name={`responseList.${index}.v_response_option`}
                                        size="medium"
                                        variant="outlined"
                                        fullWidth
                                        value={item.v_response_option}
                                        onChange={(e) => {
                                          if (
                                            e.target.value.match(
                                              /^[a-zA-Z0-9\s]*$/
                                            )
                                          ) {
                                            handleChange(e);
                                          }
                                        }}
                                        onPaste={(e) => e.preventDefault()}
                                      ></TextField>
                                      <ErrorMessage
                                        name={`responseList[${index}].v_response_option`}
                                      >
                                        {(msg) => (
                                          <div style={{ color: "red" }}>
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </Grid>
                                    <Grid
                                      item
                                      md={6}
                                      xs={12}
                                      style={{
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Typography
                                        style={{ marginBottom: "10px" }}
                                      >
                                        {langConstant.ADD_DESCRIPTION}
                                      </Typography>
                                      <TextField
                                        name={`responseList.${index}.v_response_value`}
                                        size="medium"
                                        variant="outlined"
                                        fullWidth
                                        value={item.v_response_value}
                                        onChange={(e) => {
                                          if (
                                            e.target.value.match(
                                              /^[a-zA-Z0-9\s.'"]*$/
                                            )
                                          ) {
                                            handleChange(e);
                                          }
                                        }}
                                        onPaste={(e) => e.preventDefault()}
                                      ></TextField>
                                      <ErrorMessage
                                        name={`responseList[${index}].v_response_value`}
                                      >
                                        {(msg) => (
                                          <div style={{ color: "red" }}>
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </Grid>

                                    <Grid
                                      style={{
                                        display: "flex",
                                        alignItems: "end",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="secondary"
                                        style={{
                                          minWidth: "30px",
                                          lineHeight: "18px",
                                        }}
                                        onClick={() => remove(index)}
                                      >
                                        <DeleteIcon
                                          style={{
                                            color: "#FF7070",
                                            fontSize: "28px",
                                          }}
                                        />{" "}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ))}
                              {/* {isDataPresent < 0 && (
                                <Typography>
                                  {langConstant.NO_RESPONSE_MAPPED_TO_QUESTION}
                                </Typography>
                              )} */}
                            </Grid>
                          </>
                        )}
                      />
                    </Grid>

                    <Grid mt={3} display="flex">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="medium"
                        startIcon={<SaveOutlinedIcon />}
                        disabled={values.responseList.length == 0}
                      >
                        {langConstant.SAVE}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={modal?.closeModal}
                        style={{ marginLeft: "30px" }}
                      >
                        {langConstant.CANCEL}
                      </Button>
                    </Grid>
                  </Form>
                );
              }}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

ResponseTypeWizardDialog.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default ResponseTypeWizardDialog;
