/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { Grid, Box, Button, Stepper, Step, StepLabel } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";

import { alertActions } from "_actions/alert.action";
import sessionService from "_services/session.service";
import langConstant from "_lang";

import ResolveRfiStep from "./components/ResolveRfiStep/index";
import ConfirmationStep from "./components/ConfirmationStep/index";
import manageRfiActions from "./actions";

const ManageRfiProcess = () => {
  const { assessmentId } = useParams();
  const steps = ["Resolve RFI", "Confirmation"];

  const dispatch = useDispatch();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);

  const rfiNarratives = useSelector(
    (state) => state.rfiListForAssessment.rfiNarratives
  );

  const rfiDataAttributes = useSelector(
    (state) => state.rfiListForAssessment.rfiDataAttributes
  );

  const rfiObjects = useSelector((state) => state.rfiListForAssessment.rfiList);

  const getCompletedPercentage = () => {
    if (rfiObjects?.length !== 0) {
      const resolvedRfisNumber = rfiObjects.filter(
        (item) => item.RFI_STATUS === "RFI_RESOLVED"
      ).length;

      return parseFloat(
        ((resolvedRfisNumber / rfiObjects.length) * 100).toFixed()
      );
    } else return 0;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const goStepBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const saveToDraft = () => {
    dispatch(manageRfiActions.updateRfi(rfiNarratives, rfiDataAttributes));
  };

  const goToManageRfis = (saveDraft) => {
    saveDraft && saveToDraft();
    history.push("/manage-rfis");
  };

  const goToManageRfisFromConfirmation = () => {
    goToManageRfis();
    dispatch(
      alertActions.success(langConstant.RFIS_SUCCESSFULLY_SENT_FOR_APPROVAL)
    );
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 1:
        return <ConfirmationStep />;
      default:
        return (
          <ResolveRfiStep
            progressPercentage={getCompletedPercentage()}
            onPageChange={saveToDraft}
          />
        );
    }
  };

  useEffect(() => {
    dispatch(
      manageRfiActions.getRfisForUserAssessment(
        sessionService.getUserId(),
        assessmentId
      )
    );
  }, []);

  return (
    <Box>
      <div>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className="custom-stepper"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <Box mb={8}>{getStepContent(activeStep)}</Box>
          <Box className="fixed-action-bar" p={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item style={{ width: "80px" }}></Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={getCompletedPercentage() != "100"}
                  startIcon={<SubdirectoryArrowRightIcon />}
                  onClick={
                    activeStep == 1
                      ? goToManageRfisFromConfirmation
                      : handleNext
                  }
                >
                  {activeStep == 1
                    ? langConstant.SEND_FOR_REVIEW
                    : langConstant.CONTINUE}
                </Button>
              </Grid>
              <Grid item>
                {activeStep < 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={saveToDraft}
                  >
                    Save As Draft
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    activeStep === 0 ? goToManageRfis(true) : goStepBack();
                  }}
                >
                  {langConstant.GO_BACK}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default ManageRfiProcess;
