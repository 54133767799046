import { InitiateMyAssessmentConstants } from "./constant"

const INITIAL_STATE = {
    MyAssessmentDetails: [],
    ObservationDetails: [],
    TrustScoreForAllQuestion: [],
    RfiItems: [],
    RfiRecipeints: [],
    resultSet2: [],
    ActionList: null,
    AssessmentKpiData : null,
    ApproversList: [],
    RegulatorsList: []
}

export default function MyAssessmnetReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case InitiateMyAssessmentConstants.SET_MY_ASSESSMENT_QUESTIONS:
            return {
                ...state,
                MyAssessmentDetails: action.payload
            };
        case InitiateMyAssessmentConstants.SET_MY_ASSESSMENT_OBSERVATIONS:
            return {
                ...state,
                ObservationDetails: action.payload
            };
        case InitiateMyAssessmentConstants.SET_MY_ASSESSMENT_TRUST_SCORE_ALL_QUESTIONS:
            return {
                ...state,
                TrustScoreForAllQuestion: action.payload,
            };
        case InitiateMyAssessmentConstants.MY_ASSESSMENT_CLEANUP:
            return {
                ...state,
                ObservationDetails: [],
                MyAssessmentDetails: [],
                TrustScoreForAllQuestion: [],
                RfiItems: [],
                RfiRecipeints: [],
                resultSet2 : []
            };

        case InitiateMyAssessmentConstants.MY_ASSESSMENT_RFI_ITEMS:
            return {
                ...state,
                RfiItems: action.payload,
            };

        case InitiateMyAssessmentConstants.SET_RFI_RECIPIENTS:
            return {
                ...state,
                RfiRecipeints: action.payload,
            };

        case InitiateMyAssessmentConstants.SET_RESULT_SET_2:
            return {
                ...state,
                resultSet2: action.payload,
            };
        case InitiateMyAssessmentConstants.SET_ACTION_LIST:
                return {
                    ...state,
                    ActionList: action.payload,
                };
        case InitiateMyAssessmentConstants.SET_ASSESMENT_KPI_DATA:
                return {
                    ...state,
                    AssessmentKpiData: action.payload,
                };
        case InitiateMyAssessmentConstants.SET_APPROVERS_LIST:
                return {
                    ...state,
                    ApproversList: action.payload,
                };
        case InitiateMyAssessmentConstants.SET_REGULATORS_LIST:
                return {
                    ...state,
                    RegulatorsList: action.payload,
                };
        default:
            return state;
    }
}