import React from "react";
import {
  Button,
  TextField,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import propTypes from "prop-types";
import { Save } from "@mui/icons-material";
import rootStyles from "rootStyles";
import { Formik, Form } from "formik";
import sessionService from "_services/session.service";
import { Box, CircularProgress } from "@mui/material";
import {
  ACTION_STATUS_LIST,
  priorities,
} from "../../containers/cyber-assessment/AuditActions/constants";
import Select from "react-select";
import { OptionLabelTemplate } from "./OptionLabelTemplate";
import { commonDialogStyles } from "./styles";

const inputProps = {
  style: { color: "#000" },
};

export const AddActionForm = ({
  intialValues,
  validationSchema,
  onSubmit,
  onClose,
  userList,
  isViewMode,
}) => {
  const userID = sessionService.getUserId();
  const dialogStyles = commonDialogStyles();
  const rootClasses = rootStyles();

  const options = userList?.map((user) => {
    return {
      value: user?.USER_ID,
      label: (
        <OptionLabelTemplate
          user={user}
          isCurrentUser={user?.USER_ID == userID}
        />
      ),
      payload: user,
    };
  });

  const filterUsers = (option, searchText) => {
    let username = `${option?.data?.payload?.FIRST_NAME} ${option?.data?.payload?.LAST_NAME}`;
    if (username?.toLowerCase().includes(searchText?.toLowerCase())) {
      return true;
    }
    return false;
  };

  const Label = ({ label, htmlFor, ...rest }) => (
    <FormLabel required={!isViewMode} htmlFor={htmlFor} {...rest}>
      {label}
    </FormLabel>
  );

  Label.propTypes = {
    label: propTypes.string,
    htmlFor: propTypes.string,
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={intialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          isSubmitting,
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form autoComplete="off" className={dialogStyles.form}>
            <Box className={dialogStyles.formElementWrapper}>
              <Label
                htmlFor="actionShortDesc"
                label="Short Description"
                error={
                  touched?.actionShortDesc && Boolean(errors?.actionShortDesc)
                }
              />
              <TextField
                id="actionShortDesc"
                name="actionShortDesc"
                variant="outlined"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.actionShortDesc}
                error={
                  touched?.actionShortDesc && Boolean(errors?.actionShortDesc)
                }
                disabled={isViewMode}
                InputProps={inputProps}
              />
              {Boolean(errors?.actionShortDesc) && (
                <Typography variant="caption" color="error">
                  {errors?.actionShortDesc}
                </Typography>
              )}
            </Box>
            <Box className={dialogStyles.formElementWrapper}>
              <Label
                htmlFor="actionFullDesc"
                label="Full Description"
                error={
                  touched?.actionFullDesc && Boolean(errors?.actionFullDesc)
                }
              />
              <TextField
                id="actionFullDesc"
                name="actionFullDesc"
                multiline
                rows={4}
                variant="outlined"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.actionFullDesc}
                error={
                  touched?.actionFullDesc && Boolean(errors?.actionFullDesc)
                }
                disabled={isViewMode}
                InputProps={inputProps}
              />
              {Boolean(errors?.actionFullDesc) && (
                <Typography variant="caption" color="error">
                  {errors?.actionFullDesc}
                </Typography>
              )}
            </Box>
            <Box className={dialogStyles.inputAndRadioWrapper}>
              <Box className={dialogStyles.formElementWrapper}>
                <Label
                  error={touched?.priority && Boolean(errors?.priority)}
                  htmlFor="priority"
                  label="Select Priority"
                />
                <RadioGroup
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.priority}
                  row
                  name="priority"
                >
                  {priorities.map((priority) => (
                    <FormControlLabel
                      key={priority}
                      control={
                        <Radio
                          className={rootClasses.greenCheckbox}
                          color="default"
                          name="priority"
                          size="small"
                        />
                      }
                      value={priority}
                      label={priority}
                      disabled={isViewMode && priority !== values?.priority}
                    />
                  ))}
                </RadioGroup>
              </Box>
              <Box className={dialogStyles.formElementWrapper}>
                <Label
                  error={touched?.status && Boolean(errors?.status)}
                  htmlFor="status"
                  label="Select Status"
                />
                <RadioGroup
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.status}
                  row
                  name="status"
                >
                  {ACTION_STATUS_LIST.map((item) => (
                    <FormControlLabel
                      disabled={isViewMode && item?.value !== values?.status}
                      key={item.value}
                      control={
                        <Radio
                          className={rootClasses.greenCheckbox}
                          color="default"
                          name="status"
                          size="small"
                        />
                      }
                      value={item.value}
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Box>
            <Box className={dialogStyles.inputAndRadioWrapper}>
              <Box
                flex="1 !important"
                className={dialogStyles.formElementWrapper}
              >
                <Label
                  htmlFor="assignedTo"
                  label="Assigned To"
                  error={touched?.assignedTo && Boolean(errors?.assignedTo)}
                />
                <Select
                  isDisabled={isViewMode}
                  placeholder="Select"
                  options={options}
                  isLoading={!options}
                  filterOption={filterUsers}
                  value={values?.assignedTo}
                  onChange={(e) => setFieldValue("assignedTo", e)}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      borderColor:
                        !state?.isFocused &&
                        touched?.assignedTo &&
                        Boolean(errors?.assignedTo)
                          ? "red"
                          : base?.borderColor,
                    }),
                  }}
                  isSearchable
                  isClearable
                />
              </Box>
              <Box
                flex="1 !important"
                className={dialogStyles.formElementWrapper}
              >
                <Label label="Assigned By" />
                <Box
                  padding="0.475rem"
                  justifyItems="center"
                  style={{
                    cursor: "default",
                    opacity: 0.6,
                    backgroundColor: "#f1f1f1",
                  }}
                  borderRadius={4}
                >
                  <OptionLabelTemplate
                    user={userList?.find((user) => user?.USER_ID == userID)}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={dialogStyles.inputAndRadioWrapper}>
              <Box className={dialogStyles.formElementWrapper}>
                <Label
                  label="Closure Date"
                  error={touched?.closureDate && Boolean(errors?.closureDate)}
                  htmlFor="closureDate"
                  required={false}
                />

                <TextField
                  size="small"
                  id="closureDate"
                  variant="outlined"
                  type="date"
                  inputProps={{
                    min: new Date().toISOString().split("T")[0],
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.closureDate}
                  error={touched?.closureDate && Boolean(errors?.closureDate)}
                  disabled={isViewMode}
                  InputProps={inputProps}
                />
              </Box>
              <Box className={dialogStyles.formElementWrapper}>
                <Label
                  label="Due Date"
                  error={touched?.dueDate && Boolean(errors?.dueDate)}
                  htmlFor="dueDate"
                />
                <TextField
                  size="small"
                  id="dueDate"
                  variant="outlined"
                  type="date"
                  inputProps={{
                    min: new Date().toISOString().split("T")[0],
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.dueDate}
                  error={touched?.dueDate && Boolean(errors?.dueDate)}
                  disabled={isViewMode}
                  InputProps={inputProps}
                />
              </Box>
            </Box>

            <Box gap={2} display="flex" py={1} justifyContent="flex-end">
              {!isViewMode && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={
                    isSubmitting ? <CircularProgress size={16} /> : <Save />
                  }
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              )}
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

AddActionForm.propTypes = {
  //formik
  intialValues: propTypes.object,
  validationSchema: propTypes.object,
  onSubmit: propTypes.func,
  onClose: propTypes.func,
  //data
  userList: propTypes.array,
  isViewMode: propTypes.bool,
};
