import apiService from "_services/api.service";

const getWhatsNewEntryforUser = (data) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetWhatsNewEntryforUser",data)
      .then(apiService.handleResponse)
      .then((response) => {
        if(response)
        {
          response['status'] = 200
          return response
        }
      },
      (error) => {
        if(error.response) {
          return error.response
        }
      }
    );
};

const putWhatsNewLogEntry = (data) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutWhatsNewLogEntry",data)
      .then(apiService.handleResponse)
      .then((response) => {
        if(response)
        {
          response['status'] = 200
          return response
        }
      },
      (error) => {
        if(error.response) {
          return error.response
        }
      }
    );
};

export default {
    getWhatsNewEntryforUser,
    putWhatsNewLogEntry
}