import React, { useState } from "react";
import { Grid, Avatar, Typography, IconButton } from "@material-ui/core";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import PersonAvatar from "@mui/icons-material/Person";
import propTypes from "prop-types";
import { leftPanelStyles } from "./styles";

const UserCard = ({ user, onClick, isActive }) => {
  const classes = leftPanelStyles();
  return (
    <div className={classes.userCardContainer} onClick={onClick}>
      {user?.AVATAR_IMAGE ? (
        <Avatar
          className={classes.avatarImg}
          alt="user_avatar"
          src={"data:image/png;base64," + window.atob(user?.AVATAR_IMAGE)}
        />
      ) : (
        <Avatar className={classes.avatarImg}>
          {user?.FIRST_NAME?.[0].toUpperCase()}
        </Avatar>
      )}
      <Typography
        style={{ flex: 1, fontWeight: isActive ? "bold" : "500" }}
        variant="caption"
        color={isActive ? "primary" : "secondary"}
      >
        {`${user?.FIRST_NAME} ${user?.LAST_NAME}`}
      </Typography>
      <Typography
        variant="caption"
        style={{ fontWeight: isActive ? "bold" : "500" }}
        color={isActive ? "primary" : "secondary"}
      >
        {user?.ASSIGNED_ACTIONS}
      </Typography>
    </div>
  );
};

export const LeftPanel = ({ userList, onUserClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const handleDrawer = () => setIsOpen(!isOpen);
  const classes = leftPanelStyles({ isOpen });

  const onSelectUser = (user) => {
    let shouldReset = selectedUser?.USER_ID === user?.USER_ID;
    setSelectedUser(shouldReset ? null : user);
    onUserClick(user?.USER_ID, shouldReset);
  };
  return (
    <>
      <Grid className={classes.leftPanelContainer} container>
        <Grid item md={12}>
          <div className={classes.drawerIconContainer}>
            <IconButton onClick={handleDrawer}>
              <ExpandCircleDownIcon
                color="inherit"
                aria-label="open drawer"
                className={classes.drawerIcon}
              />
            </IconButton>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 12,
            }}
          >
            <div className={classes.avatarContainer}>
              <PersonAvatar />
              {isOpen && (
                <Typography
                  className={classes.boldText}
                  variant="caption"
                  color="primary"
                >
                  ME
                </Typography>
              )}
            </div>
            {isOpen && userList?.me?.USER_ID && (
              <div className={classes.teamWrapper}>
                <UserCard
                  user={userList?.me}
                  onClick={() => onSelectUser(userList?.me)}
                  isActive={selectedUser?.USER_ID == userList?.me?.USER_ID}
                />
              </div>
            )}
            <hr className={classes.hr} />
            <div className={classes.avatarContainer}>
              <PersonAvatar />
              {isOpen && (
                <Typography
                  className={classes.boldText}
                  variant="caption"
                  color="primary"
                >
                  MY TEAM
                </Typography>
              )}
            </div>
            {isOpen && (
              <div className={classes.teamWrapper}>
                {userList?.team?.map((user) => (
                  <UserCard
                    key={user?.USER_ID}
                    user={user}
                    onClick={() => onSelectUser(user)}
                    isActive={selectedUser?.USER_ID == user?.USER_ID}
                  />
                ))}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

LeftPanel.propTypes = {
  userList: propTypes.object,
  onUserClick: propTypes.func,
};

UserCard.propTypes = {
  user: propTypes.object,
  onClick: propTypes.func,
  isActive: propTypes.bool,
};
