// OP#2830 - UJA8 - UI Development - Manage Teams Profile
// Unlock User Pop-up

import { Dialog, Button, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
    unloadUserModaltitle:{
        textAlign:"center"
    },
    unloadUserDECtext:{
        textAlign:"center",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        minHeight:"200px",
        width:"300px",
        maxWidth:"100%",
        margin:"0px auto"
    },
    unloadUserbtnWrapper:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },
    unloadUserModalCloseBTN:{
        position:"absolute",
        top:"10px",
        right:"10px",
        fill:"#000"
    }
}))

const UnlockUser = ({show, setShow, userName, updateAccountStatus}) => {
    const classes = useStyles();

    const handleClose = () => {
        setShow(false)
    }
    return (
        <Dialog
            open={show}
            maxWidth={"md"}
            fullWidth={true}
            onClose={handleClose}
        >
            
            <DialogTitle className={classes.unloadUserModaltitle}>
                {langConstant.UNLOCK_USER}
                <CloseIcon className={classes.unloadUserModalCloseBTN} />
            </DialogTitle>
            <DialogContent className={classes.unloadUserDECtext}>
                <Typography>
                    Are you sure you want to <strong>UNLOCK USER</strong> with user ID <strong>{userName}</strong> ?
                </Typography>
            </DialogContent>
            <DialogActions className={classes.unloadUserbtnWrapper}>
                <Button color="primary" variant="text" onClick={updateAccountStatus}>Yes Please!</Button>
                <Button color="primary" variant="text" onClick={handleClose}>{langConstant.CANCEL}</Button>
            </DialogActions>
        </Dialog>
    )
}

UnlockUser.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    userName: PropTypes.string,
    updateAccountStatus: PropTypes.func,
};

export default UnlockUser;