// OP#2883 - UJA11 & UJA12 - UI Development : Manage Regulators & Regulations List
// regulator form component

import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import SaveIcon from "@mui/icons-material/Save";
import langConstant from "_lang";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LinkIcon from "@mui/icons-material/Link";
import DnsIcon from "@mui/icons-material/Dns";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import BadgeIcon from "@mui/icons-material/Badge";
import GradingIcon from "@mui/icons-material/Grading";
import TypeSpecimenIcon from "@mui/icons-material/TypeSpecimen";
import RegulationServices from "../../services";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import moment from "moment";
import AttractionsIcon from "@mui/icons-material/Attractions";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles(() => ({
  borderNone: {
    border: "none",
  },
  inputProfileField: {
    padding: "5px 10px",
    color: "#000000",
  },
  filedTitle: {
    color: "#a9a8a8",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 10px",
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  width100: {
    width: "100%",
  },
  cyberAndoraLabel: {
    paddingLeft: "10px",
  },
}));

const RegulationForm = ({
  setAddRegulationDialog,
  selectedRegulation,
  regulationList,
  setRegulationList,
}) => {
  const classes = useStyles();

  const [isEditMode, setIsEditMode] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [typeData, setTypeData] = useState([]);

  const dispatch = useDispatch();
  const customerId = useSelector(
    (state) => state.authentication.detailedInfo?.CUSTOMER_ID
  );
  const Entitlements = useSelector(
    (state) => state?.authentication?.userData?.allowedNavItems
  );

  useEffect(() => {
    (async () => {
      const countryResponse = await RegulationServices.getCountryDetails();
      const domainResponse = await RegulationServices.getRegulatoryDomains();
      const statusAndTypeResponse =
        await RegulationServices.getRegulatoryStatusAndType();

      if (countryResponse && countryResponse.status === 200) {
        setCountryData(
          countryResponse["#result-set-1"].map((country) => {
            return {
              value: country.COUNTRY_CODE,
              label: country.COUNTRY_SHORT_NAME,
            };
          })
        );
      }
      if (domainResponse && domainResponse.status === 200) {
        setDomainData(
          domainResponse["#result-set-1"].map((domain) => {
            return {
              value: domain.REGULATORY_DOMAIN_ID,
              label: domain.REGULATION_DOMAIN,
            };
          })
        );
      }
      if (statusAndTypeResponse && statusAndTypeResponse.status === 200) {
        setStatusData(
          statusAndTypeResponse["#result-set-1"].map((status) => {
            return {
              value: status.ID,
              label: status.STATUS_DESCRIPTION,
            };
          })
        );
        setTypeData(
          statusAndTypeResponse["#result-set-2"].map((type) => {
            return {
              value: type.ID,
              label: type.STATUS_DESCRIPTION,
            };
          })
        );
      }
    })();
  }, []);

  const validationSchema = Yup.object().shape({
    REGULATION_NAME: Yup.string().required("Regulation Name is Required"),
    REGULATION_WEB_URL: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Regulation Url is Required"),
    REGULATION_STORAGE_FOLDER_URL: Yup.string().required(
      "Storage Folder is Required"
    ),
    REGULATORY_DOMAIN_ID: Yup.string().required(
      "Regulation Domain is Required"
    ),
    REGULATION_SHORT_NAME: Yup.string().required(
      "Regulation Short Name is Required"
    ),
    STATUS_ID: Yup.string().required("Status is Required"),
    REGULATION_TYPE: Yup.string().required("Type is Required"),
    COUNTRY_CODE: Yup.string().required("Country is Required"),
  });

  const addRegulation = async (value) => {
    if (value.REGULATION_ID) {
      const response = await RegulationServices.updateRegulationInformation(
        value
      );
      if (
        response.status === 200 &&
        response["#result-set-1"][0]["pass"] === "pass"
      ) {
        dispatch(
          alertActions.success(langConstant.REGULATION_SUCCESSFULLY_UPDATED)
        );
        const tempRegulationList = [...regulationList];
        const regulationIndex = tempRegulationList.findIndex(
          (item) => item.REGULATION_ID === value.REGULATION_ID
        );
        tempRegulationList[regulationIndex] = value;
        setRegulationList(tempRegulationList);
        setIsEditMode(false);
        setAddRegulationDialog(false);
      } else {
        const error = response;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(
            alertActions.error(
              error.response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      }
    } else {
      value["CUSTOMER_ID"] = customerId;
      value["STATUS_ID"] = 7;
      value["UPLOAD_DATE"] = moment().format("YYYY-MM-DD HH:mm:ss");
      value["START_DATE"] = moment().format("YYYY-MM-DD HH:mm:ss");
      value["END_DATE"] = null;
      const response = await RegulationServices.putRegulationInformation(value);
      if (
        response.status === 200 &&
        response["#result-set-1"][0]["pass"] === "pass"
      ) {
        value["REGULATION_ID"] = response["#result-set-1"][0]["new_id"];
        dispatch(
          alertActions.success(langConstant.REGULATION_SUCCESSFULLY_ADDED)
        );
        const tempRegulationList = [...regulationList, value];
        setRegulationList(tempRegulationList);
        setIsEditMode(false);
        setAddRegulationDialog(false);
      } else {
        const error = response;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(
            alertActions.error(
              error.response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      }
    }
  };

  const formFields = [
    {
      fieldName: "REGULATION_NAME",
      label: "Regulation Name",
      icon: <BadgeIcon />,
    },
    {
      fieldName: "REGULATION_SHORT_NAME",
      label: "Regulation Short Name",
      icon: <BadgeIcon />,
    },
    {
      fieldName: "STATUS_ID",
      label: "Status",
      icon: <GradingIcon />,
      format: function statusChange(value, handleChange) {
        const statusName = statusData.find((status) => status.value === value);
        return !isEditMode &&
          statusName &&
          statusName.label &&
          selectedRegulation.REGULATION_ID ? (
          <Typography className={classes.cyberAndoraLabel}>
            {statusName.label}
          </Typography>
        ) : (
          <Select
            options={statusData}
            placeholder="Select Status"
            value={statusData.find((status) => status.value === value)}
            onChange={handleChange}
          />
        );
      },
    },
    {
      fieldName: "REGULATION_TYPE",
      label: "Type",
      icon: <TypeSpecimenIcon />,
      format: function typeChange(value, handleChange) {
        const typeName = typeData.find((type) => type.value === value);
        return !isEditMode &&
          typeName &&
          typeName.label &&
          selectedRegulation.REGULATION_ID ? (
          <Typography className={classes.cyberAndoraLabel}>
            {typeName.label}
          </Typography>
        ) : (
          <Select
            options={typeData}
            placeholder="Select Type"
            value={typeData.find((type) => type.value === value)}
            onChange={handleChange}
          />
        );
      },
    },
    {
      fieldName: "REGULATION_WEB_URL",
      label: "Regulation URL",
      icon: <LinkIcon />,
    },
    {
      fieldName: "REGULATION_STORAGE_FOLDER_URL",
      label: "Storage Folder",
      icon: <FolderOpenIcon />,
    },
    {
      fieldName: "REGULATORY_DOMAIN_ID",
      label: "Regulation Domain",
      icon: <DnsIcon />,
      format: function domainChange(value, handleChange) {
        const domainName = domainData.find((domain) => domain.value === value);
        return !isEditMode &&
          domainName &&
          domainName.label &&
          selectedRegulation.REGULATION_ID ? (
          <Typography className={classes.cyberAndoraLabel}>
            {domainName.label}
          </Typography>
        ) : (
          <Select
            options={domainData}
            placeholder="Select Domain"
            value={domainData.find((domain) => domain.value === value)}
            onChange={handleChange}
          />
        );
      },
    },
    {
      fieldName: "COUNTRY_CODE",
      label: "Country",
      icon: <FlagOutlinedIcon />,
      format: function countryTag(value, handleChange) {
        const countryName = countryData.find(
          (country) => country.value === value
        );
        return !isEditMode &&
          countryName &&
          countryName.label &&
          selectedRegulation.REGULATION_ID ? (
          <Typography className={classes.cyberAndoraLabel}>
            {countryName.label}
          </Typography>
        ) : (
          <Select
            options={countryData}
            placeholder="Select Country"
            value={countryData.find((country) => country.value === value)}
            onChange={handleChange}
          />
        );
      },
    },
  ];

  return (
    <Box style={{ padding: "10px 20px 20px 20px" }}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedRegulation}
        validationSchema={validationSchema}
        onSubmit={(value) => addRegulation(value)}
      >
        {({ setFieldValue, resetForm, errors }) => (
          <Form>
            <Grid container style={{ marginBottom: "20px" }}>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => setAddRegulationDialog(false)}
                >
                  {langConstant.GO_BACK}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                {selectedRegulation.REGULATION_NAME ? (
                  isEditMode ? (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        size="small"
                        startIcon={<SaveIcon />}
                      >
                        {" "}
                        {langConstant.SAVE}{" "}
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        variant="text"
                        onClick={() => {
                          resetForm();
                          setIsEditMode(false);
                        }}
                      >
                        {" "}
                        {langConstant.CANCEL}{" "}
                      </Button>
                    </>
                  ) : (
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        startIcon={<EditOutlinedIcon />}
                        onClick={() => setIsEditMode(true)}
                      >
                        {langConstant.EDIT}
                      </Button>
                    </Grid>
                  )
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon />}
                  >
                    {" "}
                    {langConstant.SAVE}{" "}
                  </Button>
                )}
              </Grid>
            </Grid>
            {!selectedRegulation.REGULATION_NAME && (
              <Typography
                className={classes.filedTitle}
                style={{ padding: "0px 0px 10px 0px" }}
              >
                {langConstant.NEW_REGULATION}
              </Typography>
            )}
            {formFields.map((formField, index) => {
              const handlechange = (data) =>
                setFieldValue(formField.fieldName, data.value);
              return (
                <Grid container key={index}>
                  <Grid item xs={12} md={12}>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        {formField.icon}
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {formField.label}
                        </Typography>
                        <Field name={formField.fieldName}>
                          {({ field: { value } }) =>
                            formField.format ? (
                              formField.format(value, handlechange)
                            ) : (
                              <TextField
                                value={value}
                                disabled={
                                  selectedRegulation.REGULATION_NAME
                                    ? !isEditMode
                                    : false
                                }
                                className={classes.width100}
                                variant="outlined"
                                onChange={(e) =>
                                  setFieldValue(
                                    formField.fieldName,
                                    e.target.value
                                  )
                                }
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline:
                                      selectedRegulation.REGULATION_NAME
                                        ? isEditMode
                                          ? ""
                                          : classes.borderNone
                                        : "",
                                  },
                                }}
                              />
                            )
                          }
                        </Field>
                        {errors && errors[formField.fieldName] && (
                          <Typography style={{ color: "red" }}>
                            {errors[formField.fieldName]}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid container className={classes.AddressGrid}>
              <Grid item xs={1}>
                <AttractionsIcon />
              </Grid>
              <Grid
                item
                xs={11}
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <Typography className={classes.filedTitle}>
                  Horizon Scan
                </Typography>
                <Field name="HORIZON_SCAN">
                  {({ field: { value } }) => (
                    <Checkbox
                      style={{ padding: 0, marginLeft: 6 }}
                      checked={value}
                      color="success"
                      inputProps={{ "aria-label": "Mark as Reviewed" }}
                      onChange={(e) =>
                        setFieldValue("HORIZON_SCAN", e.target.checked)
                      }
                      disabled={
                        !Entitlements.includes(
                          "MANAGE_REGULATIONS/HORIZON_SCANNING"
                        ) ||
                        (selectedRegulation.REGULATION_NAME
                          ? !isEditMode
                          : false)
                      }
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

RegulationForm.propTypes = {
  setAddRegulationDialog: PropTypes.func,
  selectedRegulation: PropTypes.object,
  regulationList: PropTypes.array,
  setRegulationList: PropTypes.func,
};

export default RegulationForm;
