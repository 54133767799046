import { Grid, Popover, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import EditIcon from "@mui/icons-material/Edit";
import useStyles from "../styles";
import { useEffect } from "react";

const ColorPicker = ({
  hexColor,
  fieldLabel,
  field,
  setFieldValue,
  errors,
}) => {
  const classes = useStyles();
  const [color, setColor] = useState(hexColor ? hexColor : "#ffffff");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (hexColor) {
      setColor(hexColor);
    }
  }, [hexColor]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onColorChange = (color) => {
    setColor(color.hex);
    setFieldValue(field, color.hex);
  };

  return (
    <Grid>
      <Grid style={{ display: "flex", padding: "10px 0" }}>
        <Typography className={classes.formThemeFieldLabel}>
          {fieldLabel}
        </Typography>
        <Grid style={{ paddingLeft: "10px", display: "flex" }}>
          <Grid
            style={{
              backgroundColor: color,
              width: "40px",
              height: "20px",
              border: "1px solid black",
              borderRadius: "5px",
            }}
          ></Grid>
          <EditIcon
            style={{ marginLeft: "20px", cursor: "pointer" }}
            fontSize="small"
            onClick={handleClick}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <SketchPicker color={color} onChange={onColorChange} />
          </Popover>
        </Grid>
      </Grid>
      {errors && errors[field] && (
        <Typography
          style={{ color: "red", fontSize: "12px", textAlign: "right" }}
        >
          {errors[field]}
        </Typography>
      )}
    </Grid>
  );
};

ColorPicker.propTypes = {
  hexColor: PropTypes.string,
  fieldLabel: PropTypes.string,
  field: PropTypes.string,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
};

export default ColorPicker;
