
export const constants = {
  CALENDAR_ENTRIES_SUCCESS: "CALENDAR_ENTRIES_SUCCESS",
  CALENDAR_ENTRIES_FAILURE: "CALENDAR_ENTRIES_FAILURE",
  SET_SELECTED_EVENT: "SET_SELECTED_EVENT",
  REMOVE_SELECTED_EVENT: "REMOVE_SELECTED_EVENT",
  CALENDAR_ENTRIES_INITIATING: 'CALENDAR_ENTRIES_INITIATING',
  RFI: 'RFI',
  MEETING: 'Meeting',
  RFI_SENT: 'RFI_SENT',
  RFI_PENDING: 'RFI_PENDING',
  RFI_OVERDUE: 'RFI_OVERDUE',
  RFI_RESOLVED: 'RFI_RESOLVED',
  HIGH: 'H',
  MEDIUM: 'M',
  LOW: 'L'
};