import React, { useState } from "react";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Slide,
} from "@material-ui/core";
import SplittedDataList from "containers/manage-regulation/Policy/PolicyExplorer/component/PolicyDataTable/ChatBot/splittedDataList";
import { color } from "@amcharts/amcharts4/core";

const Refrences = ({ datalist, setRefPopUp }) => {
  const handleClose = () => {
    setRefPopUp(false);
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <Box sx={{ padding: "100px" }}>
      <Dialog
        open={open}
        maxWidth={"md"}
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: { padding: "15px" },
        }}
      >
        <DialogActions>
          <Grid container>
            <Grid item md={11} xs={8}>
              <div style={{ fontWeight: "bold", fontSize: "25px" }}>
                References
              </div>
            </Grid>
            <Button color="primary" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </DialogActions>
        {datalist.length > 0 ? (
          <Grid>
            <SplittedDataList splittedDataList={datalist} />
          </Grid>
        ) : (
          <div style={{ color: "red", textAlign: "center", padding: "10px" }}>
            Data Not Available
          </div>
        )}
      </Dialog>
    </Box>
  );
};

Refrences.propTypes = {
  datalist: PropTypes.array,
  setRefPopUp: PropTypes.func,
};

export default Refrences;
