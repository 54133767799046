import { Grid } from '@material-ui/core';
import React from 'react';
import ExtractionEditor from './components/extractionEditor';
import PropTypes from "prop-types";


const Step3 = ({stepperClose}) => {

    return (
        <Grid container>
            <Grid item md={12}>
                <ExtractionEditor stepperClose={stepperClose} />                
            </Grid>
        </Grid>
    )
}

Step3.propTypes = {
    stepperClose: PropTypes.func,
};

export default Step3;