import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid, Tooltip, Typography } from "@mui/material";
import useStyles from "../styles";
import langConstant from "_lang";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const AssessmentApproverGraph = ({ block }) => {
  const classes = useStyles();
  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );

  const colors = ["#f00", "#00a83e", "#ffc000", "#3365be"];

  const [secGraphData, setSecGraphData] = useState([
    {
      ACTION_LIST: "Rejected",
      COUNT: 51,
    },
    {
      ACTION_LIST: "Approved",
      COUNT: 80,
    },
    {
      ACTION_LIST: "Pending",
      COUNT: 75,
    },
    {
      ACTION_LIST: "Required",
      COUNT: 26,
    },
  ]);

  return (
    <Grid
      style={{
        backgroundColor: "#fff",
        borderRadius: "4px",
        height: "100%",
      }}
    >
      {expandedPanelID == block.id && (
        <Typography className={classes.chartLabel}>
          {langConstant.ASSESSMENT_APPROVERS_STATUS}
        </Typography>
      )}
      <ResponsiveContainer width="99%" height="99%" aspect={2}>
        <BarChart
          data={secGraphData}
          layout="vertical"
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            style={{
              fontSize: expandedPanelID == block.id ? "12px" : "10px",
              fontWeight: "bold",
            }}
          />
          <YAxis
            dataKey="ACTION_LIST"
            type="category"
            style={{
              fontSize: expandedPanelID == block.id ? "12px" : "10px",
              fontWeight: "bold",
            }}
          />
          <Tooltip />
          {expandedPanelID == block.id && (
            <Legend
              payload={secGraphData.map((item, index) => ({
                id: item.ACTION_LIST,
                type: "square",
                value: `${item.ACTION_LIST}`,
                color: colors[index],
              }))}
            />
          )}
          <Bar
            barSize={expandedPanelID == block.id ? 20 : 10}
            dataKey={"COUNT"}
            opacity={0.8}
            label={{
              position: "right",
              fontSize: expandedPanelID == block.id ? "12px" : "8px",
            }}
          >
            {secGraphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
};

AssessmentApproverGraph.propTypes = {
  block: PropTypes.object,
};

export default AssessmentApproverGraph;
