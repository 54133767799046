import apiService from "_services/api.service";

const GetSubmissionQuestionDetails = (i_QUESTION_ID, i_SUBMISSION_ID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetSubmissionQuestionDetails", {
      i_QUESTION_ID,
      i_SUBMISSION_ID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetAssessmentPlanActions = (
  i_SUBMISSION_ID,
  i_CUSTOMER_ID,
  page = 1,
  rowsPerPage = 15,
  searchText,
  regulatorControlGroup,
  actionType,
  actionStatus,
  itemType
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetAssessmentPlanActions", {
      i_SUBMISSION_ID,
      i_CUSTOMER_ID,
      i_PAGE_NO: page,
      i_PAGE_SIZE: rowsPerPage,
      i_SEARCH: searchText ? searchText : null,
      i_FILTER_REGULATORS_GROUP: regulatorControlGroup
        ? regulatorControlGroup
        : null,
      i_FILTER_ACTION_TYPE: actionType ? actionType : null,
      i_FILTER_ACTION_STATUS: actionStatus ? actionStatus : null,
      i_FILTER_ITEM_TYPE: itemType ? itemType : null,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetAssessmentPlanActionsFilter = (i_SUBMISSION_ID, i_CUSTOMER_ID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetAssessmentPlanActionsFilter", {
      i_SUBMISSION_ID,
      i_CUSTOMER_ID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetRFIStatusForSubmission = (submissionID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRFIStatusForSubmission", {
      i_ASSESSMENT_ID: submissionID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  GetAssessmentPlanActions,
  GetAssessmentPlanActionsFilter,
  GetRFIStatusForSubmission,
  GetSubmissionQuestionDetails,
};
