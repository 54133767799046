import { Box, Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import ProgressBar from "../components/ProgressBar";
import PanelHeader from "../components/PanelHeader";
import SelectRegulation from "../components/SelectRegulation";
import { useSelector } from "react-redux";
import langConstant from "_lang";

const Panel3 = ({ refObj, block, columnID, panel }) => {
  const [isGraphView, setIsGraphView] = useState(false);
  const [loading, setLoading] = useState(false);

  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );
  const regulationID = useSelector(
    (state) => state.complianceReducer.selectedRegulation
  );

  return (
    <>
      {loading ? (
        <ProgressBar />
      ) : (
        <Box>
          <PanelHeader
            refObj={refObj}
            expandedPanelID={expandedPanelID}
            block={block}
            panel={panel}
            columnID={columnID}
            isGraphView={isGraphView}
            setIsGraphView={setIsGraphView}
            panelName={langConstant.RAID}
          />
          {regulationID && regulationID.value ? (
            <Grid></Grid>
          ) : (
            <SelectRegulation />
          )}
        </Box>
      )}
    </>
  );
};

Panel3.propTypes = {
  refObj: PropTypes.any,
  block: PropTypes.object,
  columnID: PropTypes.string,
  panel: PropTypes.object,
};

export default Panel3;
