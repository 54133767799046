import apiService from "_services/api.service";

const GetRFIStatusForSubmission = (i_ASSESSMENT_ID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRFIStatusForSubmission", {
      i_ASSESSMENT_ID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetEvidenceStatusForSubmission = (i_SUBMISSION_ID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetEvidenceStatusForSubmission", {
      i_SUBMISSION_ID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export { GetRFIStatusForSubmission, GetEvidenceStatusForSubmission };
