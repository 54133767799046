/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
} from "@material-ui/core";

import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import SaveIcon from "@material-ui/icons/Save";
import rootStyles from "../../../../rootStyles";
import SelectQuestionsStep from "./component/SelectQuestionsStep";
import EditQuestionsStep from "./component/EditQuestionsStep";
import AssignNarrativesStep from "./component/AssignNarrativesStep";
import ConfirmationStep from "./component/ConfirmationStep";
import styles from "./styles";
import questionMappingAction from "./actions";

const QuestionsMapping = () => {
  const classes = styles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isOneOfChecked, setIsOneOfChecked] = React.useState(false);
  const [list, setList] = React.useState([]);
  const questionDraft = useSelector((state) => state.questionDraft.draft);
  const activeStepState = useSelector(
    (state) => state.questionDraft.activeStep
  );
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const steps = [
    "SELECT QUESTIONS",
    "EDIT/REVIEW SELECTION",
    "ASSIGN NARRATIVES",
    "CONFIRMATION",
  ];

  const updateIsOneOfChecked = (value) => {
    setIsOneOfChecked(value);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    saveToDraft(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const goBack = () => {
    history.goBack();
  };
  const submitForReview = () => {
    dispatch(
      questionMappingAction.updateRegulationStatus(
        id,
        "PENDING_FOR_REVIEW",
        goBack
      )
    );
  };
  const saveToDraft = (step) => {
    dispatch(questionMappingAction.updateDraft(id, list, step));
  };
  const editDeleteQuestions = (list) => {
    dispatch(questionMappingAction.updateDraft(id, list, activeStep));
  };
  const getPercentage = (data) => {
    const selectedQuestions = _.filter(data, { selected: true });
    const questionCompleted = data.filter((item) => {
      return item.selected && item.narratives;
    });
    if (questionCompleted.length > 0 || selectedQuestions.length > 0) {
      let count = (questionCompleted.length * 100) / selectedQuestions.length;
      count =
        count === 100 || count === 0 ? count : parseFloat(count.toFixed(2));
      return count;
    } else {
      return 0;
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <SelectQuestionsStep
            questionDraft={questionDraft}
            updateIsOneOfChecked={updateIsOneOfChecked}
          />
        );
      case 1:
        return (
          <EditQuestionsStep
            questionDraft={questionDraft}
            editDeleteQuestions={editDeleteQuestions}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <AssignNarrativesStep
            questionDraft={questionDraft}
            percentage={completedPercentage}
            handleBack={handleBack}
          />
        );
      default:
        return <ConfirmationStep questionDraft={questionDraft} />;
    }
  };
  const goBackOnError = () => {
    history.push("/new-regulation");
  };
  useEffect(() => {
    dispatch(questionMappingAction.getDraft(id, goBackOnError));
  }, []);
  useEffect(() => {
    if (questionDraft.length > 0) {
      setList(questionDraft);
      setCompletedPercentage(getPercentage(questionDraft));
    }
  }, [questionDraft]);
  useEffect(() => {
    if (activeStepState > 0) {
      setIsOneOfChecked(true);
    }
    setActiveStep(activeStepState);
  }, [activeStepState]);
  if (list.length == 0) {
    return <></>;
  }
  return (
    <Box>
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className="custom-stepper"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <Box mb={8}>{getStepContent(activeStep)}</Box>
          <Box className="fixed-action-bar" p={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item style={{ width: "80px" }}></Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={
                    (activeStep > 1 && completedPercentage != "100") ||
                    !isOneOfChecked
                  }
                  startIcon={<SubdirectoryArrowRightIcon />}
                  onClick={() => {
                    activeStep == 3 ? submitForReview() : handleNext();
                  }}
                >
                  {activeStep == 3 ? "Submit for Review" : "Continue"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    saveToDraft(activeStep);
                  }}
                >
                  Save As Draft
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="small"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Go Back
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </Box>
  );
};
export default QuestionsMapping;
