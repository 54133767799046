/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

const setItem = (name, value) => {
  localStorage.setItem(name, value);
};

const getItem = (name) => {
  if (name) {
    return localStorage.getItem(name);
  }
  return null;
};

const removeItem = (name) => {
  if (name) {
    return localStorage.removeItem(name);
  }
  return null;
};

const clear = () => {
  localStorage.clear();
};
const getUserId = () => {
  const auth = getItem("authData");
  if (auth) {
    const data = JSON.parse(auth);
    if (data && data.userID) {
      return data.userID;
    }
  }
  return null;
};

const getCustomerId = () => {
  const customerId = getItem("customerId");
  if (customerId) {
    return customerId
  }
  return null;
};

const getUserName = () => {
  const auth = getItem("userData");
  if (auth) {
    const data = JSON.parse(auth);
    if (data && data.userName) {
      return data.userName;
    }
  }
  return null;
};
const getUserRole = () => {
  const userData = getItem("userData");

  if (userData) {
    const data = JSON.parse(userData);
    if (data && data.userRole) {
      return data.userRole;
    }
  }
  return null;
};
const getUserRoleId = () => {
  const userData = getItem("userData");

  if (userData) {
    const data = JSON.parse(userData);
    if (data && data.userRoleId) {
      return data.userRoleId;
    }
  }
  return null;
};

const getTempLogin = () => {
  const tempLogin = getItem("tempLogin");
  if(tempLogin) {
    const data = JSON.parse(tempLogin);
    if (data) {
      return data.tempLogin;
    }
  }
  return false;
};


export default {
  setItem,
  getItem,
  removeItem,
  clear,
  getUserId,
  getUserName,
  getUserRole,
  getUserRoleId,
  getTempLogin,
  getCustomerId,
};
