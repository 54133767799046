/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED.

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import ClearAllOutlinedIcon from "@material-ui/icons/ClearAllOutlined";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import EventIcon from "@material-ui/icons/Event";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import Tiles from "_components/Tiles";
import langConstant from "_lang";
import _ from "lodash";
import moment from "moment";

const Statistics = ({ data = [] }) => {
  let TOTAL_SUBMITTED = 0;
  let TOTAL_EFFORT_LOGGED = 0;
  let PASSED_DUE_DATE = 0;
  let userIdArray = [];
  let NEARING_DUE_DATE = 0;
  let TOTAL_ASSESSMENT = 0;
  data.forEach((item) => {
    if (item.STATUS == "SUBMITTED_TO_REGULATOR") {
      TOTAL_SUBMITTED = TOTAL_SUBMITTED + 1;
    }
    if (userIdArray.indexOf(item.USER_ID) == -1) {
      userIdArray.push(item.USER_ID);
    }
    //item.EFFORT_LOGGED = null;

    TOTAL_EFFORT_LOGGED = !isNaN(parseFloat(item.EFFORT_LOGGED))
      ? TOTAL_EFFORT_LOGGED + parseFloat(item.EFFORT_LOGGED)
      : TOTAL_EFFORT_LOGGED;

    if (
      moment(item.END_DATE).isBefore(new Date(), "day") &&
      item.STATUS != "SUBMITTED_TO_REGULATOR"
    ) {
      PASSED_DUE_DATE = PASSED_DUE_DATE + 1;
    }
    if (
      moment(new Date()).isBetween(
        moment(item.END_DATE).subtract(1, "month"),
        moment(item.END_DATE)
      ) &&
      item.STATUS != "SUBMITTED_TO_REGULATOR"
    ) {
      NEARING_DUE_DATE = NEARING_DUE_DATE + 1;
    }
    if (item.REGULATION_ID) {
      TOTAL_ASSESSMENT = TOTAL_ASSESSMENT + 1;
    }
  });
  const MY_TEAM = userIdArray.length;

  // SUBMISSION_DATE  2021-04-13 11:23:31

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item md={2}>
        <Tiles
          label={langConstant.MY_TEAM}
          count={MY_TEAM}
          icon={<PeopleOutlineIcon />}
          color="71, 145, 255"
        />
      </Grid>
      <Grid item md={2}>
        <Tiles
          label={langConstant.TOTAL_ASSESSMENT}
          count={TOTAL_ASSESSMENT}
          icon={<AssignmentOutlinedIcon />}
          color="213, 173, 29"
        />
      </Grid>
      <Grid item md={2}>
        <Tiles
          label={langConstant.TOTAL_SUBMITTED}
          count={TOTAL_SUBMITTED}
          icon={<ClearAllOutlinedIcon />}
          color="2, 188, 119"
        />
      </Grid>
      <Grid item md={2}>
        <Tiles
          label={langConstant.PASSED_DUE_DATE}
          count={PASSED_DUE_DATE}
          icon={<EventBusyIcon />}
          color="255, 35, 102"
        />
      </Grid>
      <Grid item md={2}>
        <Tiles
          label={langConstant.NEARING_DUE_DATE}
          count={NEARING_DUE_DATE}
          icon={<EventIcon />}
          color="235, 106, 0"
        />
      </Grid>
      <Grid item md={2}>
        <Tiles
          label={langConstant.TOTAL_EFFORT_LOGGED}
          count={TOTAL_EFFORT_LOGGED}
          icon={<AvTimerIcon />}
          color="239, 52, 208"
        />
      </Grid>
    </Grid>
  );
};
Statistics.propTypes = {
  data: PropTypes.array,
};
export default Statistics;
