import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { Button } from "@material-ui/core";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import langConstant from "_lang";
import { Box } from "@mui/material";
import { Save, Close } from "@mui/icons-material";

const styles = {
  wrapperStyle: {
    border: "1px solid #969696",
  },
  editorContainerStyle: {
    height: "24vh",
    overflowY: "auto",
  },
  editorStyle: {
    padding: "0.5rem",
    minHeight: "100%",
  },
};

const RichTextEditor = (props) => {
  const { data, onCancelClick, onSaveClick } = props;
  const contentBlock = htmlToDraft(data);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  const [editedData, setEditedData] = useState(
    draftToHtml(
      convertToRaw(
        EditorState.createWithContent(contentState).getCurrentContent()
      )
    )
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setEditedData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useEffect(() => {
    if (!data) return;
    setEditorState(EditorState.createWithContent(contentState));
  }, [data]);

  return (
    <>
      <div style={{ marginTop: "1%" }}>
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          wrapperStyle={styles.wrapperStyle}
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "history",
            ],
          }}
          editorStyle={styles.editorContainerStyle} // Apply scrollable style
        />
      </div>
      <Box mt="2%" display="flex" gap={2}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => onCancelClick(false)}
          startIcon={<Close />}
        >
          {langConstant.CANCEL}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => onSaveClick(editedData)}
          startIcon={<Save />}
        >
          {langConstant.SAVE}
        </Button>
        {props?.customButtons}
      </Box>
    </>
  );
};

RichTextEditor.propTypes = {
  data: PropTypes.string,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  customButtons: PropTypes.any,
};

export default RichTextEditor;
