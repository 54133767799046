import { makeStyles } from "@material-ui/core";
const taskListStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#F0142F",
  },
  notificationBell: {
    alignSelf: "center",
  },
  countWrapper: {
    background: "#c13636",
    color: "white",
    borderRadius: "5px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "max-content",
    padding: "2px 5px",
    fontSize: "12px",
    position: "absolute",
    top: "-1px",
    right: "0",
  },
  badgeWrap: (props) => ({
    position: "relative",
    color: props.fontColor,
  }),
  sectionDesktop: {
    alignItems: "center",
  },
}));

export default taskListStyles;
