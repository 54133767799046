// OP#2883 - UJA11 & UJA12 - UI Development : Manage Regulators & Regulations List
// regulation List Drawer box component

import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import langConstant from "_lang";
import RegulationForm from "../RegulationForm";
import classNames from "classnames";
import RegulationServices from "../../services";
import { useSelector } from "react-redux";
import { Drawer } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import IconButton from "@mui/material/IconButton";
import RegulationDocumentDialog from "../RegulationDocumentDialog";
import ArticleIcon from "@mui/icons-material/Article";
const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  regulationACMOdalOne: {
    right: "0px",
    width: "600px",
    maxWidth: "100%",
    bottom: "0px",
    position: "fixed",
    border: "1px solid rgb(230,230,230)",
    height: "calc(100% - 65px)",
    backgroundColor: "#fff",
    padding: "0px",
    overflowY: "auto",
    "& .MuiDialogTitle-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
    },
    "& .MuiDialogContent-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  regulationACMHeader: {
    padding: "10px 20px 20px 20px",
  },
  regulationACMtablespace: {
    padding: "15px 20px !important",
    fontWeight: "bold",
    cursor: "pointer",
  },
  regulationACMtablespaceHead: {
    padding: "5px 20px !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const RegulationListDialog = ({
  show,
  setShow,
  regulatorId,
  selectedRegulatorInfo,
}) => {
  const classes = useStyles();
  const customerId = useSelector(
    (state) => state?.authentication?.detailedInfo?.CUSTOMER_ID
  );
  const [addRegulationDialog, setAddRegulationDialog] = useState(false);

  const regulationInitialValues = {
    REGULATOR_ID: regulatorId,
    REGULATION_NAME: "",
    REGULATION_SHORT_NAME: "",
    REGULATION_STATUS: "",
    REGULATION_TYPE: "",
    REGULATION_WEB_URL: "",
    COUNTRY_CODE: "",
    REGULATORY_DOMAIN_ID: "",
    REGULATION_STORAGE_FOLDER_URL: "",
    HORIZON_SCAN: false,
  };
  const [selectedRegulation, setSelectedRegulation] = useState(
    regulationInitialValues
  );

  const [regulationList, setRegulationList] = useState([]);
  const [regulationDocumentsModal, setRegulationDocumentsModal] =
    useState(false);
  const [selectedRegulationID, setSelectedRegulationID] = useState();

  useEffect(() => {
    (async () => {
      if (customerId && regulatorId) {
        const response = await RegulationServices.getRegulationsForRequlator(
          regulatorId,
          customerId
        );

        if (response && response.status === 200) {
          if (response["#result-set-1"] && response["#result-set-1"].length) {
            setRegulationList(response["#result-set-1"]);
          }
        }
      }
    })();
  }, [customerId]);

  const columns = [
    { field: "REGULATION_ID", headerName: "REGULATION ID", minWidth: 50 },
    { field: "REGULATION_NAME", headerName: "REGULATION NAME", minWidth: 50 },
  ];

  const handleClose = () => {
    setAddRegulationDialog(false);
    setShow(false);
  };

  const toggleDialog = () => {
    setRegulationDocumentsModal(!regulationDocumentsModal);
  };

  return (
    <>
      <RegulationDocumentDialog
        isOpen={regulationDocumentsModal}
        toggleDialog={toggleDialog}
        regulationID={selectedRegulationID}
      />
      <Drawer
        open={show}
        anchor="right"
        // maxWidth={"sm"}
        // fullWidth={true}
        // BackdropProps={{ invisible: true }}
        //TransitionComponent={Transition}
        // disableEnforceFocus
        //keepMounted
        onClose={handleClose}
      >
        <Grid style={{ width: "600px", maxWidth: "100%" }}>
          {!addRegulationDialog ? (
            <>
              <Box className={classes.regulationACMHeader}>
                <Grid container spacing={2}>
                  <Grid xs={12} md={6}>
                    <CloseIcon onClick={handleClose} />
                  </Grid>
                  <Grid xs={12} md={6} style={{ textAlign: "right" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setAddRegulationDialog(true);
                        setSelectedRegulation(regulationInitialValues);
                      }}
                    >
                      {langConstant.ADD_NEW_REGULATION}
                    </Button>
                  </Grid>
                </Grid>
                <Typography style={{ marginTop: "30px", fontWeight: "500" }}>
                  {selectedRegulatorInfo.REGULATOR_NAME}
                </Typography>
              </Box>
              <TableContainer
                component={Paper}
                style={{ borderRadius: "0px", margin: 1 }}
              >
                <Table className="data-table">
                  <TableHead>
                    <TableRow>
                      {columns.map((item, index) => (
                        <TableCell
                          component="th"
                          scope="row"
                          key={"column_" + index + item.field}
                          className={classNames(
                            classes.stickyColl,
                            classes.regulationACMtablespaceHead
                          )}
                        >
                          {item.headerName}
                        </TableCell>
                      ))}
                      <TableCell
                        component="th"
                        scope="row"
                        className={classNames(
                          classes.stickyColl,
                          classes.regulationACMtablespaceHead
                        )}
                      >
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {regulationList.map((row, rowIndex) => (
                      <TableRow key={"row_" + rowIndex}>
                        {columns.map((column, columnIndex) => (
                          <TableCell
                            className={classes.regulationACMtablespace}
                            component="td"
                            scope="row"
                            key={
                              "column_" + columnIndex + row[columns[0].field]
                            }
                          >
                            {row[column.field]}
                          </TableCell>
                        ))}
                        <TableCell
                          className={classes.regulationACMtablespace}
                          component="td"
                          scope="row"
                        >
                          <IconButton
                            title="Regulation Details"
                            aria-label="Regulation Details"
                            onClick={() => {
                              setSelectedRegulation({
                                ...row,
                                HORIZON_SCAN:
                                  row.HORIZON_SCAN === 1 ? true : false,
                              });
                              setAddRegulationDialog(true);
                            }}
                          >
                            <FormatListBulletedIcon
                              color="info"
                            />
                          </IconButton>
                          <IconButton
                            title="Documents Required"
                            aria-label="Documents"
                            onClick={() => {
                              setSelectedRegulationID(row.REGULATION_ID)
                              setRegulationDocumentsModal(true)
                            }}
                          >
                            <ArticleIcon
                              color="success"
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {regulationList.length === 0 && (
                      <TableRow style={{ height: 53 }}>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={columns.length}
                        >
                          {langConstant.NO_RECORD_FOUND}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <RegulationForm
              setAddRegulationDialog={setAddRegulationDialog}
              selectedRegulation={selectedRegulation}
              setRegulationList={setRegulationList}
              regulationList={regulationList}
            />
          )}
        </Grid>
      </Drawer>
    </>
  );
};

RegulationListDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  regulatorId: PropTypes.number,
  selectedRegulatorInfo: PropTypes.object,
};

export default RegulationListDialog;
