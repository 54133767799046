import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";
import DocumentViewer from "_components/DocumentViewer";
import useStyles from "../styles";
import { useSelector } from "react-redux";
import langConstant from "_lang";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const DocumentDrawer = ({ show, setShow, documentDetails }) => {
  const classes = useStyles();

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [tabValue, setTabValue] = useState(0);

  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
  };

  const baseExtension = documentDetails.base.fileName.split(".").pop();
  const targetExtension = documentDetails.target.fileName.split(".").pop();

  return (
    <Drawer
      anchor={"right"}
      open={show}
      onClose={() => setShow(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "700px !important",
        },
      }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        TabIndicatorProps={{ style: { padding: 2 } }}
        orientation="horizontal"
        variant="scrollable"
      >
        <Tab aria-orientation="horizontal" label={langConstant.BASE_DOCUMENT} />
        <Tab
          aria-orientation="horizontal"
          label={langConstant.TARGET_DOCUMENT}
        />
      </Tabs>
      <Grid className={classes.docViewer} style={{ height: "100vh" }}>
        {tabValue === 0 && (
          <DocumentViewer
            documentClass={classes.documentClass}
            height="calc(100vh - 20px)"
            filePath={documentDetails.base.fileFolder}
            fileName={documentDetails.base.fileName}
            fileType={baseExtension}
          />
        )}
        {tabValue === 1 && (
          <DocumentViewer
            documentClass={classes.documentClass}
            height="calc(100vh - 20px)"
            filePath={documentDetails.target.fileFolder}
            fileName={documentDetails.target.fileName}
            fileType={targetExtension}
          />
        )}
      </Grid>
    </Drawer>
  );
};

DocumentDrawer.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  documentDetails: PropTypes.object,
};

export default DocumentDrawer;
