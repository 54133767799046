import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import React from "react";

const getTableauToken = (setTokenData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("user")
      .send(`/getTableauToken?userName=tableau-admin`)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response != -1) {
            setTokenData(response);
          } else {
            setTokenData(null);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

export default {
  getTableauToken,
};
