import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import langConstant  from "_lang"
import { useDispatch, useSelector } from "react-redux";
import AppPagination from "_components/AppPagination";
import DialogBox from "_components/DialogBox";
import moment from "moment";
import FeatureServices from "./services";
import { alertActions, loadingActions } from "_actions";
import { Checkbox } from "@mui/material";
import { globalConstants } from "_constants";


const FeatureDialog = ({show, setOpenFeatureDialog, userID, FeatureList, setFeatureCount}) => {
    
    const dispatch = useDispatch()

    const [page, setPage] = useState(0);
    const [rowData, setRowData] = useState(FeatureList)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [toggleView, setToggleView] = useState(false)

    const handleClose = () => setOpenFeatureDialog(false)

    const calculatePageCount = (rows) => {
        return rows.length > 0
            ? Math.round(Math.ceil(rows.length / rowsPerPage))
            : 0;
    };

    const [pageCount, setPageCount] = useState(calculatePageCount(FeatureList));

    const handleChangePage = (event) => {
        setPage(event);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const columns = [
        {
          field: "FEATURE_DESCRIPTION",
          headerName: langConstant.FEATURE_CHANGE,
          minWidth: 400,
          textAlign: "left",
          format: (value) => (value['FEATURE_DESCRIPTION'] ? window.atob(value['FEATURE_DESCRIPTION']) : "-"),
        },
        { 
          field: "FEATURE_RELEASE_DATE", 
          headerName: langConstant.RELEASE_DATE,
          format: (value) => (value['FEATURE_RELEASE_DATE'] ? moment(value['FEATURE_RELEASE_DATE']).format("DD-MMM-YY") : "-"),
        },
      ];

    const markAsView = async() => {
        const featureIdList = FeatureList.map(function (e) {
            return e.FEATURE_ID;
          }).join(',');
        dispatch(loadingActions.start())
        const response = await FeatureServices.putWhatsNewLogEntry({i_USER_ID: userID, i_FEATURE_ID: featureIdList})
        if(response.status === 200){
            setFeatureCount(0)
            dispatch({ type: globalConstants.RESET_FEATURE_LIST, data: []})
        } else {
            if(response.data && response.data.message)
            {
              dispatch(
                  alertActions.error(
                    response.data.message.map((item, index) => {
                          return <div key={index}>{item}</div>;
                      })
                  )
              );
            } else {
              dispatch(
                  alertActions.error(langConstant.SOMETHING_WENT_WRONG))
            }
        }
        dispatch(loadingActions.end())
    }

    useEffect(() => {
        (async () => {
            if(toggleView){
                dispatch(loadingActions.start())
                const response = await FeatureServices.getWhatsNewEntryforUser({i_USER_ID: userID})
                dispatch(loadingActions.end())
                if(response.status && response.status === 200){
                    setRowData(response['#result-set-1'])
                } else {
                    if(response.data && response.data.message)
                    {
                      dispatch(
                          alertActions.error(
                            response.data.message.map((item, index) => {
                                  return <div key={index}>{item}</div>;
                              })
                          )
                      );
                    } else {
                      dispatch(
                          alertActions.error(langConstant.SOMETHING_WENT_WRONG))
                    }
                }
            } else {
                setRowData(FeatureList)
            }
        })()
    },[toggleView])
    

    return (
        <DialogBox
            maxWidth={"md"}
            fullWidth={true}
            open={show}
            handleClose={handleClose}
            title={!toggleView ? langConstant.NEW_RELEASE : langConstant.HISTORY}
        >
            <Grid container style={{paddingBottom: "10px"}}>
                {!toggleView && rowData.length > 0 &&
                    <Grid item md={6} sm={6} container justify="flex-start" style={{alignItems: "center"}}>
                        <Checkbox
                            onClick={() => markAsView()}
                            sx={{
                                '&.Mui-checked': {
                                color: "#124571",
                                },
                                display: "inline"
                            }}
                            size="small"
                        />
                        <span style={{fontWeight: "bold", cursor: "pointer"}}>{langConstant.MARK_ALL_AS_READ}</span> 
                    </Grid>
                }
                <Grid  item md={toggleView || rowData.length === 0 ? 12 : 6} sm={toggleView || rowData.length === 0 ? 12 : 6} container justify="flex-end">
                    <Button variant="outlined" color="primary" size="small" onClick={() => setToggleView(!toggleView)}>
                        {!toggleView ? langConstant.HISTORY : langConstant.NEW_RELEASE }
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table className="data-table ">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        key={"column_" + index + column.field}
                                        style={{
                                            minWidth: column.minWidth,
                                            textAlign: column.textAlign,
                                            cursor: "pointer"
                                        }}
                                    > {column.headerName} </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? rowData.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                                )
                            : rowData
                            ).map((row, index) => (
                            <TableRow key={index + row[columns[0].field]}>
                                {columns.map((column, index) => {
                                    const value = row[column.field];
                                    return (
                                        <TableCell
                                            component="td"
                                            scope="row"
                                            key={"column_" + index + row[columns[0].field]}
                                            style={{
                                                wordBreak: "break-word",
                                                wordWrap: "break-word",
                                                cursor: "pointer"
                                            }}
                                        > {column.format ? column.format(row) : value} </TableCell>
                                    );
                                })}
                            </TableRow>
                            ))}
                            {rowData.length == 0 && (
                                <TableRow style={{ height: 53 }}>
                                    <TableCell
                                        style={{ textAlign: "center" }}
                                        colSpan={columns.length}
                                    > {langConstant.NO_RECORD_FOUND} </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            {pageCount > 1 && (
                <Box my={2}>
                    <Grid container justify="flex-end">
                        <AppPagination
                            colSpan={3}
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Box>
            )}
        </DialogBox>
    )
}

FeatureDialog.propTypes = {
    show: PropTypes.bool,
    setOpenFeatureDialog: PropTypes.func,
    userID: PropTypes.number,
    FeatureList: PropTypes.array, 
    setFeatureCount: PropTypes.func
}

export default FeatureDialog;

