import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Chip, Grid, Tooltip, Typography } from "@mui/material";
import { AccountCircle, People } from "@mui/icons-material";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import NoDataCard from "./Graphs/NoDataCard";
import { ApproverKpis } from "./CenterKpiView";

const approvalChipStyle = {
  P: { backgroundColor: "#fff6c8", color: "#c3a306" },
  R: { backgroundColor: "#ffdddd", color: "#ff3131" },
  A: { backgroundColor: "#dfffe1", color: "#4fcd00" },
};

const getApprovalStatus = (status) => {
  if (status) return "Approved";
  else if (status === false) return "Rejected";
  else return "Pending Approval";
};

const ApproverCard = ({ approver }) => (
  <Tooltip
    title={
      approver?.CREATE_DATE_TIME
        ? `Created At - ${new Date(approver?.CREATE_DATE_TIME).toLocaleString(
            [],
            {
              dateStyle: "medium",
              timeStyle: "short",
            }
          )}`
        : ""
    }
    placement="left"
  >
    <Box
      p=".5rem"
      display="flex"
      flex="auto"
      flexDirection="column"
      border="1px solid #0000001a"
      borderRadius="4px"
    >
      <Box display="flex" flex="auto" gap={1}>
        <AccountCircle style={{ alignSelf: "center" }} color="primary" />
        <Typography
          style={{
            alignSelf: "center",
            textTransform: "capitalize",
          }}
          flex={1}
          variant="body2"
          fontSize="14px"
        >
          <strong>{`${approver?.FIRST_NAME} ${approver?.LAST_NAME}`}</strong>
          {" - "}
          {approver?.ROLE_NAME}
        </Typography>
        <Chip
          label={getApprovalStatus(approver?.APPROVAL_STATUS)}
          size="small"
          style={{
            fontSize: 10,
            alignSelf: "center",
            padding: "0rem .25rem",
            ...approvalChipStyle[
              getApprovalStatus(
                approver?.APPROVAL_STATUS
              )?.[0]?.toLocaleUpperCase()
            ],
          }}
        />
      </Box>
    </Box>
  </Tooltip>
);

const ApproversList = ({ approvers, kpis }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const calculatePageCount = (rows) => {
    return rows?.length > 0
      ? Math.round(Math.ceil(rows?.length / rowsPerPage))
      : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(approvers));

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range?.value);
    const totalPageCount =
      approvers?.length > 0
        ? Math.round(Math.ceil(approvers?.length / range?.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
  };

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      borderRadius="4px"
      gap={1}
      bgcolor="white"
    >
      <Typography fontWeight="500" variant="subtitle1">
        Approvers Panel
      </Typography>
      <ApproverKpis {...kpis} />
      <Box flex="auto">
        <Box display="flex" gap={1} flexDirection="column">
          {approvers?.length ? (
            (rowsPerPage > 0
              ? approvers?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : approvers
            )?.map((approver) => (
              <ApproverCard key={approver?.APPROVAL_ID} approver={approver} />
            ))
          ) : (
            <NoDataCard message="No approvers available" Icon={People} />
          )}
        </Box>
      </Box>

      <Grid my={1} container display="flex" justifyContent="end">
        <SelectPageRange
          selectedRange={rowsPerPage}
          handleChange={handleSelectPageRange}
          disabled={!approvers?.length || approvers?.length <= 5}
        />

        <AppPagination
          count={pageCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          disabled={pageCount <= 1}
        />
      </Grid>
    </Box>
  );
};

ApproversList.propTypes = {
  approvers: PropTypes.array,
  kpis: PropTypes.shape({
    requested: PropTypes.number,
    recieved: PropTypes.number,
    approved: PropTypes.number,
  }),
};
ApproverCard.propTypes = {
  approver: PropTypes.object,
};

export default ApproversList;
