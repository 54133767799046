import { Box, Grid, Tab, Tabs, Tooltip } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import langConstant from "_lang";
import CompanyData from "./components/CompanyData";
import TechnologyData from "./components/TechnologyData";
import { makeStyles } from "@material-ui/core";
import DataAssetPopUp from "./components/DataAssetPopUp";

const ContentView = ({ rows, setRows, expandedPanelID, block }) => {
  const styles = makeStyles(() => ({
    summerayCompareTabsWrap: {
      minHeight: "28px !important",

      "& button": {
        minHeight: "28px",
        padding: "0px !important",
      },
    },
  }));

  const classes = styles();
  const [tabValue, setTabValue] = useState(0);
  const [showDataAsset, setShowDataAsset] = useState(false);
  const [selectedDataAsset, setSelectedDataAsset] = useState({
    UNID: null,
    DATA_ASSET_NAME: "",
    DATA_ASSET_ATTRIBUTE_NAME: "",
    SOURCE_DATA_POINT_VALUE: "",
  });

  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
  };

  const updateDataAsset = (dataAsset, action) => {
    if (action === "create") {
      setRows((prevState) => [...prevState, dataAsset]);
    } else {
      let tempRows = [...rows];
      const rowIndex = tempRows.findIndex((row) => row.UNID == dataAsset.UNID);
      tempRows[rowIndex]["DATA_ASSET_NAME"] = dataAsset["DATA_ASSET_NAME"];
      tempRows[rowIndex]["DATA_ASSET_ATTRIBUTE_NAME"] =
        dataAsset["DATA_ASSET_ATTRIBUTE_NAME"];
      tempRows[rowIndex]["SOURCE_DATA_POINT_VALUE"] =
        dataAsset["SOURCE_DATA_POINT_VALUE"];
      tempRows[rowIndex]["TAB_LABEL"] = dataAsset["TAB_LABEL"];
      setRows(tempRows);
    }
  };

  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        className={classes.summerayCompareTabsWrap}
        TabIndicatorProps={{
          style: { padding: "0px  !important" },
        }}
        orientation="horizontal"
        variant="scrollable"
      >
        {console.log(expandedPanelID, block.id, "-----------------------")}
        <Tab
          aria-orientation="horizontal"
          label={
            <Tooltip title={langConstant.COMPANY} placement="bottom">
              <span>{langConstant.COMPANY}</span>
            </Tooltip>
          }
        />
        <Tab
          aria-orientation="horizontal"
          label={
            <Tooltip
              title={langConstant.TECHNOLOGY_AND_INFRASTRUCTURE}
              placement="bottom"
            >
              <span>
                {expandedPanelID == block.id
                  ? langConstant.TECHNOLOGY_AND_INFRASTRUCTURE
                  : langConstant.TECHNOLOGY_AND_INFRASTRUCTURE.length > 8
                  ? langConstant.TECHNOLOGY_AND_INFRASTRUCTURE.slice(0, 8) +
                    "..."
                  : langConstant.TECHNOLOGY_AND_INFRASTRUCTURE}
              </span>
            </Tooltip>
          }
        />
      </Tabs>
      <Grid style={{ margin: "10px 0" }}>
        {tabValue == 0 && (
          <CompanyData
            data={rows.filter((row) => row.DATA_ASSET_NAME === "COMPANY_DATA")}
            setSelectedDataAsset={setSelectedDataAsset}
            setShowDataAsset={setShowDataAsset}
          />
        )}
        {tabValue == 1 && (
          <TechnologyData
            data={rows.filter(
              (row) => row.DATA_ASSET_NAME === "TECHNOLOGY_DATA"
            )}
            setSelectedDataAsset={setSelectedDataAsset}
            setShowDataAsset={setShowDataAsset}
          />
        )}
      </Grid>
      {showDataAsset && (
        <DataAssetPopUp
          title={
            selectedDataAsset.UNID
              ? langConstant.EDIT_DATA_ASSET
              : langConstant.ADD_NEW_DATA_ASSET
          }
          showDataAsset={showDataAsset}
          setShowDataAsset={setShowDataAsset}
          selectedDataAsset={selectedDataAsset}
          rows={rows}
          updateDataAsset={updateDataAsset}
        />
      )}
    </Box>
  );
};

ContentView.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  setRows: PropTypes.func,
  expandedPanelID: PropTypes.string,
  block: PropTypes.object,
};

export default ContentView;
