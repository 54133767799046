import React from "react";
import {
  Button,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import propTypes from "prop-types";
import { Save } from "@mui/icons-material";
import rootStyles from "rootStyles";
import { Formik, Form, Field } from "formik";
import { commonDialogStyles } from "./styles";
import AsyncSelect from "react-select/async";
import { Box, CircularProgress } from "@mui/material";
import {
  ACTION_STATUS_LIST,
  linkTypes,
  priorities,
} from "../../containers/cyber-assessment/AuditActions/constants";

const Label = ({ label, htmlFor, ...rest }) => (
  <FormLabel required htmlFor={htmlFor} {...rest}>
    {label}
  </FormLabel>
);

export const AddActionListForm = ({
  intialValues,
  validationSchema,
  onSubmit,
  onClose,
  setCurrentLink,
  currentLink,
  defaultLinkType,
  formattedOptions,
  isOptionLoading,
  paginatedOptions,
  loadMoreOptions,
}) => {
  const dialogStyles = commonDialogStyles();
  const rootClasses = rootStyles();

  const filterOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(() => {
          return formattedOptions?.filter((i) =>
            i?.label?.toLowerCase().includes(inputValue?.toLowerCase())
          );
        });
      }, 500);
    });

  return (
    <Formik
      initialValues={intialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form autoComplete="off" className={dialogStyles.form}>
          <Box className={dialogStyles.formElementWrapper}>
            <Label
              htmlFor="actionListName"
              label="Action List Name"
              error={touched?.actionListName && Boolean(errors?.actionListName)}
            />
            <TextField
              id="actionListName"
              name="actionListName"
              variant="outlined"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.actionListName}
              error={touched?.actionListName && Boolean(errors?.actionListName)}
            />
          </Box>
          {/* <Box className={dialogStyles.inputAndRadioWrapper}> */}
          <Box className={dialogStyles.formElementWrapper}>
            <Label
              error={touched?.priority && Boolean(errors?.priority)}
              htmlFor="priority"
              label="Select Priority"
            />
            <RadioGroup
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.priority}
              row
              name="priority"
            >
              {priorities.map((priority) => (
                <FormControlLabel
                  key={priority}
                  control={
                    <Radio
                      className={rootClasses.greenCheckbox}
                      color="default"
                      name="priority"
                      size="small"
                    />
                  }
                  value={priority}
                  label={priority}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box className={dialogStyles.formElementWrapper}>
            <Label
              htmlFor="status"
              label="Select Status"
              error={touched?.status && Boolean(errors?.status)}
            />
            <RadioGroup
              row
              name="status"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.status}
            >
              {ACTION_STATUS_LIST.map((item) => (
                <FormControlLabel
                  key={item.value}
                  control={
                    <Radio
                      className={rootClasses.greenCheckbox}
                      color="default"
                      name="status"
                      size="small"
                    />
                  }
                  value={item.value}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          {/* </Box> */}
          <Box className={dialogStyles.formElementWrapper}>
            <Label
              htmlFor="linkType"
              label="Select item to link actions list to"
              error={touched?.linkType && Boolean(errors?.linkType)}
            />
            <RadioGroup
              row
              name="linkType"
              onChange={(e, value) => {
                handleChange(e, value);
                setCurrentLink(value);
              }}
              onBlur={handleBlur}
              value={currentLink}
            >
              {linkTypes.map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Radio
                      className={rootClasses.greenCheckbox}
                      color="default"
                      size="small"
                      disabled={defaultLinkType && defaultLinkType !== type}
                    />
                  }
                  value={type}
                  label={type}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box className={dialogStyles.formElementWrapper}>
            <Label
              htmlFor="linkedItem"
              label={`Select ${values?.linkType} to link`}
              error={touched?.linkedItem && Boolean(errors?.linkedItem)}
            />
            <Field name="linkedItem">
              {() => (
                <AsyncSelect
                  cacheOptions
                  loadOptions={filterOptions}
                  defaultOptions={paginatedOptions}
                  onMenuScrollToBottom={loadMoreOptions}
                  isLoading={isOptionLoading}
                  options={paginatedOptions}
                  placeholder="Select an option"
                  value={values?.linkedItem}
                  onChange={(e) => setFieldValue("linkedItem", e)}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  closeMenuOnSelect
                  isSearchable
                  isClearable
                  isOptionDisabled={(option) => option?.isDisabled}
                  noOptionsMessage={() => `No ${values?.linkType} found`}
                  // components={{
                  //   MenuList,
                  // }}
                />
              )}
            </Field>
          </Box>
          <Box gap={2} display="flex" py={1} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="small"
              startIcon={
                isSubmitting ? <CircularProgress size={16} /> : <Save />
              }
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

AddActionListForm.propTypes = {
  //formik
  intialValues: propTypes.object,
  validationSchema: propTypes.object,
  onSubmit: propTypes.func,
  onClose: propTypes.func,
  setCurrentLink: propTypes.func,
  defaultLinkType: propTypes.string,
  currentLink: propTypes.string,
  // select
  loadMoreOptions: propTypes.func,
  formattedOptions: propTypes.array,
  isOptionLoading: propTypes.bool,
  paginatedOptions: propTypes.array,
};

Label.propTypes = {
  label: propTypes.string,
  htmlFor: propTypes.string,
};
