import React, { useEffect, useState } from "react";
import ProfileServices from "../services";
import sessionService from "_services/session.service";
import { Grid, Typography, Button } from "@material-ui/core";
import Select from "react-select";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import useStyles from "./styles";

const NotificationTab = ({
  notificationPreferences,
  setNotificationPreferences,
  user_ID,
}) => {
  const classes = useStyles();

  const [notificationPreferenceList, setNotificationPreferenceList] = useState(
    []
  );
  const [selectedPreferences, setSelectedPreferences] = useState([]);

  const dispatch = useDispatch();

  const sendData = async () => {
    dispatch(loadingActions.start());
    const selectedPreferenceIdList = [];
    selectedPreferences.forEach((selectedPreference) => {
      selectedPreferenceIdList.push(selectedPreference.value);
    });
    const selectedPreferenceStr = selectedPreferenceIdList.join(",");
    const res = await ProfileServices.putUserNotificationPreference(
      user_ID,
      selectedPreferenceStr,
      "Y"
    );
    if (res.status === 200 && res["#result-set-1"]) {
      setNotificationPreferences(res["#result-set-1"]);
      dispatch(
        alertActions.success("Notification Preferences Updated Successfully!")
      );
    } else {
      dispatch(alertActions.error("Something went wrong"));
    }
    dispatch(loadingActions.end());
  };

  useEffect(() => {
    (async () => {
      const response = await ProfileServices.getUserNotificationPreferences(
        user_ID
      );
      if (response.status === 200) {
        if (response["#result-set-1"].length > 0) {
          const tempPreferenceList = [];
          response["#result-set-1"].forEach((element) => {
            tempPreferenceList.push({
              label: element.NOTIFICATION_TYPE,
              value: element.NOTIFICATION_ID,
            });
          });
          setNotificationPreferenceList(tempPreferenceList);
          if (notificationPreferences && notificationPreferences.length > 0) {
            const alreadySelectedPreferences = tempPreferenceList.filter(
              (preferenceList) =>
                notificationPreferences.find(
                  (selectedPreferenceList) =>
                    selectedPreferenceList.NOTIFICATION_ID ===
                    preferenceList.value
                )
            );
            setSelectedPreferences(alreadySelectedPreferences);
          }
        }
      }
    })();
  }, [user_ID]);

  return (
    <Grid className={classes.notificationWrapper}>
      <Typography className={classes.inputLabel}>
        Notification Preferences
      </Typography>
      <Select
        options={notificationPreferenceList}
        placeholder="Notification Preferences"
        value={selectedPreferences}
        className={classes.inputProfileField}
        onChange={(pref) => setSelectedPreferences(pref)}
        isMulti
      />
      <Grid className={classes.profileButtonWrapper}>
        <Button color="primary" onClick={sendData} variant="contained">
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

NotificationTab.propTypes = {
  notificationPreferences: PropTypes.array,
  setNotificationPreferences: PropTypes.func,
  user_ID: PropTypes.number,
};

export default NotificationTab;
