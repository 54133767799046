export const InitiateMyAssessmentConstants = {
    SET_MY_ASSESSMENT_QUESTIONS: "SET_MY_ASSESSMENT_QUESTIONS",
    SET_MY_ASSESSMENT_OBSERVATIONS: "SET_MY_ASSESSMENT_OBSERVATIONS",
    MY_ASSESSMENT_CLEANUP: "MY_ASSESSMENT_CLEANUP",
    SET_MY_ASSESSMENT_TRUST_SCORE_ALL_QUESTIONS: "SET_MY_ASSESSMENT_TRUST_SCORE_ALL_QUESTIONS",
    MY_ASSESSMENT_RFI_ITEMS : "MY_ASSESSMENT_RFI_ITEMS",
    SET_RFI_RECIPIENTS : "SET_RFI_RECIPIENTS",
    SET_RESULT_SET_2 : "SET_RESULT_SET_2",
    SET_ACTION_LIST: "SET_ACTION_LIST",
    SET_ASSESMENT_KPI_DATA:"SET_ASSESMENT_KPI_DATA",
    SET_APPROVERS_LIST:"SET_APPROVERS_LIST",
    SET_REGULATORS_LIST:"SET_REGULATORS_LIST"
}