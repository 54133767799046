import React from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import { Grid } from "@material-ui/core";
import ProgressComponent from "./progressComponent";

const Stepper2ProgressDrawer = ({ show, setShow, progressLogsData }) => {
  return (
    <Drawer
      anchor={"right"}
      open={show}
      onClose={() => setShow(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "700px !important",
        },
      }}
    >
      <Grid style={{ height: "100vh", padding: "30px 10px 30px 20px" }}>
        <ProgressComponent
          progressLogsData={progressLogsData}
          currentStep={2}
        />
      </Grid>
    </Drawer>
  );
};

Stepper2ProgressDrawer.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  progressLogsData: PropTypes.array,
};

export default Stepper2ProgressDrawer;
