import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";
import propTypes from "prop-types";
import { QUESTION_STATUS_TYPES } from "_components/MasterAssessmentComp/utils/constants";
import { TopBarView } from "./components/TopBarView";
import { styles } from "./styles";
import AppDataTable from "./components/AppDataTable";

const PlanView = ({
  QuestionList,
  observationList,
  identifier,
  RfiitemsList,
  submissionId,
  assessmentDetails,
  TopTabsArray,
  setSelectedQuestionFromPlan,
  setTopSelectedTab,
  onTopTabChange,
  setPage,
  setPlanActionsDataLength,
}) => {
  const columns = [
    {
      field: "REGULATORS_CONTROL_GROUP",
      headerName: "REGULATORS CONTROL GROUP",
      minWidth: 20,
      maxWidth: 80,
    },
    {
      field: "QUESTION_TEXT",
      headerName: "QUESTION TEXT",
      minWidth: 20,
      maxWidth: 100,
    },
    {
      field: "REGULATION_REFERENCE",
      headerName: "REGULATION REFERENCE",
      minWidth: 20,
      maxWidth: 80,
    },

    {
      field: "ITEM_TYPE",
      headerName: "ITEM TYPE",
      minWidth: 10,
      maxWidth: 40,
    },
    {
      field: "ACTION_TYPE",
      headerName: "ACTION TYPE",
      minWidth: 10,
      maxWidth: 30,
    },
    {
      field: "ACTION_STATUS",
      headerName: "ACTION STATUS",
      minWidth: 10,
      maxWidth: 30,
    },
    {
      field: "action",
      headerName: "ACTIONS",
      minWidth: 10,
      maxWidth: 20,
      sticky: true,
    },
  ];

  const [kpiData, setKpiData] = useState({
    evidenceToCollect: 0,
    answersToPrepare: 0,
    questionsToComplete: 0,
  });
  const [rfiOpen, setRfiOpen] = useState(0);

  return (
    <React.Fragment>
      <Card
        id="elementToCapture"
        sx={{
          my: 1,
          backgroundColor: "#F0F4F8",
          border: "none",
          overflow: "auto",
          scrollbarWidth: "none",
          height: "100%",
        }}
        variant="outlined"
      >
        <CardContent
          sx={{
            p: 2,
            display: "flex",
            gap: 2,
            flex: "auto",
            flexDirection: "column",
          }}
        >
          <Box>
            <TopBarView
              assessmentDetails={assessmentDetails}
              kpiData={kpiData}
              rfiOpen={rfiOpen}
            />
            <AppDataTable
              observationList={observationList}
              identifier={identifier}
              RfiitemsList={RfiitemsList}
              submissionId={submissionId}
              columns={columns}
              className="data-table"
              QuestionList={QuestionList}
              TopTabsArray={TopTabsArray}
              setSelectedQuestionFromPlan={setSelectedQuestionFromPlan}
              setTopSelectedTab={setTopSelectedTab}
              onTopTabChange={onTopTabChange}
              setPage={setPage}
              setKpiData={setKpiData}
              setRfiOpen={setRfiOpen}
              setPlanActionsDataLength={setPlanActionsDataLength}
            />
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

PlanView.propTypes = {
  QuestionList: propTypes.array,
  observationList: propTypes.array,
  identifier: propTypes.string,
  RfiitemsList: propTypes.array,
  submissionId: propTypes.oneOfType([propTypes.number, propTypes.string]),
  assessmentDetails: propTypes.object,
  TopTabsArray: propTypes.array,
  setSelectedQuestionFromPlan: propTypes.func,
  DrawerItemClick: propTypes.func,
  setTopSelectedTab: propTypes.func,
  onTopTabChange: propTypes.func,
  setPage: propTypes.func,
  setPlanActionsDataLength: propTypes.func,
};

export default PlanView;
