import { Box, Button, TextField } from "@material-ui/core";
import DialogBox from "_components/DialogBox";
import React from "react";
import PropTypes from "prop-types";
import langConstant from "_lang";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import useStyles from "../styles";

const FileNamePopUp = ({ isOpen, setIsOpen, saveNewComparisonRecipie }) => {
  const classes = useStyles();

  const initialValues = { name: "" };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(100, "Maximum 100 Characters Allowed")
      .required("Name is Required"),
  });

  const handleClose = () => setIsOpen(false);

  return (
    <DialogBox
      maxWidth={"sm"}
      open={isOpen}
      title={langConstant.ENTER_NAME_FOR_COMPARISON}
      handleClose={handleClose}
    >
      <Box style={{ textAlign: "center" }}>
        <Box style={{ marginBottom: "20px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(value) => saveNewComparisonRecipie(value)}
          >
            {() => (
              <Form>
                <Field style={{ width: "100%" }} name="name">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <TextField
                      className={classes.inputProfileField}
                      value={value}
                      placeholder={langConstant.COMPARISON_NAME}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                      variant="outlined"
                    />
                  )}
                </Field>
                <ErrorMessage name="name">
                  {(msg) => (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        justifyContent: "flex-start",
                      }}
                    >
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
                <Box style={{ marginTop: "20px" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="small"
                    style={{ width: "100px", marginRight: "10px" }}
                  >
                    {langConstant.SAVE}
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    type="button"
                    size="small"
                    style={{
                      width: "100px",
                      border: "2px solid",
                      fontWeight: "bold",
                    }}
                    onClick={handleClose}
                  >
                    {langConstant.CANCEL}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </DialogBox>
  );
};

FileNamePopUp.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  saveNewComparisonRecipie: PropTypes.func,
};

export default FileNamePopUp;
