import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  Switch,
  TextField,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import CountryDropdown from "_components/CountryListDropDown";
import UserRoleDropdown from "_components/UserRoleDropDown";
import { Drawer, IconButton } from "@mui/material";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import langConstant from "_lang";
import EditIcon from "@mui/icons-material/Edit";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import HubIcon from "@mui/icons-material/Hub";
import CategoryIcon from "@mui/icons-material/Category";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import AddPlatformLogin from "./addPlatformLogin";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import SupplierServices from "../services";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import Select from "react-select";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  borderNone: {
    border: "none",
  },
  width100: {
    width: "100%",
  },
  inputProfileField: {
    padding: "5px",
    color: "#000000",
  },
  filedTitle: {
    color: "#a9a8a8",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  suppliersACMOdalOne: {
    right: "0px",
    width: "500px",
    bottom: "0px",
    position: "fixed",
    border: "1px solid rgb(230,230,230)",
    height: "calc(100% - 65px)",
    backgroundColor: "#fff",
    padding: "0px 30px 30px 30px",
    overflowY: "auto",
    "& .MuiDialogTitle-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
    },
    "& .MuiDialogContent-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "calc(100% - 60px)",
    },
  },
  profileMDCloseBtn: {
    top: "9px",
    right: "14px",
    width: "18px",
    height: "18px",
    position: "absolute",
  },
  profileMDCloseFirst: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  profileMDCloseSecond: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(-45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  suppliersTopHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "9px",
  },
  suppliersTopHeaderEdit: {
    "& > div": {
      width: "100%",
    },
    "& input": {
      height: "30px",
      padding: "5px 13px",
    },
  },
  regulatorstatusWrapper: {
    "& > .MuiSwitch-root": {
      position: "relative",
      left: "-10px",
    },
  },

  PersonTitle: {
    color: "#3c0292",
    marginBottom: "5px",
    fontWeight: "500",
  },
  KeyTitle: {
    color: "#a9a8a8",
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },
  filedTitleIcon: {
    display: "flex",
    fontWeight: "500",
    "& svg": {
      marginRight: "6px",
      position: "relative",
      top: "-1px",
    },
  },
  wordBreak: {
    wordBreak: "break-all",
  },
}));

const AddSupplierDialog = ({
  show,
  setShow,
  supplierId,
  rows,
  setSupplierList,
  data,
  keyPersonView,
  setKeyPersonView,
  setAnchorMenuEl,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customerId = useSelector(
    (state) => state?.authentication?.detailedInfo?.CUSTOMER_ID
  );
  const Supplier_Customer_ID = data ? data[0].Supplier_Customer_ID : null;

  const User_ID = data ? data[0].USER_ID : null;

  const userID = sessionService.getUserId();
  const username = useSelector(
    (state) => state.authentication.detailedInfo?.USER_NAME
  );
  const [showAddPlatformLogin, setShowAddPlatformLogin] = useState(false);

  const supplierInitialData = {
    SUPPLIER_NAME: "",
    SUPPLIER_ID: null,
    Supplier_Customer_ID: null,
    SUPPLIER_CATEGORY: "",
    ACTIVE: true,
    SUPPLIER_RISK_RATING: "",
    SUPPLIER_PRODUCT_SERVICE_PROVIDED: "",
    SUPPLIER_CONTRACT_DURATION: "",
    CONTRACT_STATUS: "",
    SUPPLIER_ADDRESS: "",
    COUNTRY_CODE: "",
    RESPONSIBLE_DEPARTMENT: "",
    START_DATE: moment().format("YYYY-MM-DD HH:mm:ss"),
    END_DATE: null,
  };

  const [personDataForPlatformLogin, setPersonDataForPlatformLogin] = useState({
    CONTACT_NAME: "",
    ROLE: null,
    ROLE_NAME: "",
    EMAIL_ADDRESS: "",
    DEPARTMENT: "",
    TEAM: "",
    PHONE_NUMBER: "",
    SUPPLIER_CONTACT_ID: null,
  });

  const [supplierData, setSupplierData] = useState(supplierInitialData);
  const [keyPersonData, setKeyPersonData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [lastLoggedin, setLastLoggedin] = useState(null);
  const [newSupplier, setNewSupplier] = useState(null);
  const [selectedSupplierSpoc, setSelectedSupplierSpoc] = useState(null);
  const [initialContactDetails, setInitialContactDetails] = useState({
    SUPPLIER_ID: data ? data[0].UNID?.toString().trim() : null,
    CONTACT_NAME: "",
    ROLE: null,
    ROLE_NAME: "",
    EMAIL_ADDRESS: "",
    DEPARTMENT: "",
    PHONE_NUMBER: "",
    USER_ACTIVE: null,
    SUPPLIER_CONTACT_ID: null,
    USER_ID: data && data[0].USER_ID ? data[0].USER_ID : null,
    START_DATE:
      data && data[0].START_DATE
        ? data[0].START_DATE?.toString().trim()
        : moment().format("YYYY-MM-DD HH:mm:ss"),
    END_DATE: data ? data[0].END_DATE?.toString().trim() : null,
  });

  useEffect(() => {
    if (supplierId && customerId) {
      const initialKeyPersonData = [];
      setSupplierData((prev) => ({
        ...prev,
        SUPPLIER_ID: data[0]["SUPPLIER_ID"],
        SUPPLIER_NAME: data[0]["SUPPLIER_NAME"],
        Supplier_Customer_ID: data[0]["Supplier_Customer_ID"],
        SUPPLIER_CATEGORY: data[0]["SUPPLIER_CATEGORY"],
        SUPPLIER_RISK_RATING: data[0]["SUPPLIER_RISK_RATING"],
        SUPPLIER_PRODUCT_SERVICE_PROVIDED:
          data[0]["SUPPLIER_PRODUCT_SERVICE_PROVIDED"],
        SUPPLIER_CONTRACT_DURATION: data[0]["SUPPLIER_CONTRACT_DURATION"],
        CONTRACT_STATUS: data[0]["CONTRACT_STATUS"],
        SUPPLIER_ADDRESS: data[0]["SUPPLIER_ADDRESS"],
        COUNTRY_CODE: data[0]["COUNTRY_CODE"],
        RESPONSIBLE_DEPARTMENT: data[0]["RESPONSIBLE_DEPARTMENT"],
        ACTIVE: data[0]["ACTIVE"] == "ACTIVE" ? true : false,
      }));

      data.forEach((item) => {
        const tempSpoc = {
          SUPPLIER_ID: item.SUPPLIER_ID?.toString().trim(),
          CONTACT_NAME: `${item.SPOC_FIRSTNAME?.toString().trim()}   ${item.SPOC_LASTNAME?.toString().trim()}`,
          ROLE: item.ROLE_ID ? item.ROLE_ID : null,
          ROLE_NAME: item.ROLE_NAME?.toString().trim(),
          EMAIL_ADDRESS: item.SPOC_EMAIL?.toString().trim(),
          DEPARTMENT: item.SPOC_DEPARTMENT?.toString().trim(),
          PHONE_NUMBER: item.PHONE_NUMBER?.toString().trim(),
          USER_ACTIVE: item.USER_ACTIVE?.toString().trim(),
          SUPPLIER_CONTACT_ID: item.SUPPLIER_CONTACT_ID
            ? item.SUPPLIER_CONTACT_ID
            : null,
          USER_ID: item.USER_ID ? item.USER_ID : null,
          START_DATE: item.START_DATE
            ? item.START_DATE.toString().trim()
            : moment().format("YYYY-MM-DD HH:mm:ss"),
          END_DATE: item.END_DATE?.toString().trim(),
        };

        initialKeyPersonData.push(tempSpoc);
      });

      setKeyPersonData(initialKeyPersonData);
      setIsEditMode(false);
      // setKeyPersonView(false);
      setSelectedSupplierSpoc(null);
    } else {
      setIsEditMode(true);
    }
  }, [rows]);

  const validationSchema = Yup.object().shape({
    SUPPLIER_NAME: Yup.string().required("Supplier Name is required"),
    SUPPLIER_ADDRESS: Yup.string().required("Supplier Address is required"),
    COUNTRY_CODE: Yup.string().required("Country is required"),
    SUPPLIER_RISK_RATING: Yup.string().required("Risk rating is required"),
    SUPPLIER_PRODUCT_SERVICE_PROVIDED: Yup.string().required(
      "Product service provided is required"
    ),
    SUPPLIER_CATEGORY: Yup.string().required("Category is required"),
    SUPPLIER_CONTRACT_DURATION: Yup.string().required(
      "Contract duration is required"
    ),
    CONTRACT_STATUS: Yup.string().required("Contract status is required"),
    RESPONSIBLE_DEPARTMENT: Yup.string().required(
      "Responsible department is required"
    ),
  });

  const contactValidationSchema = Yup.object().shape({
    CONTACT_NAME: Yup.string().required("Contact Name is Required"),
    ROLE: Yup.number().required("Role is Required"),
    EMAIL_ADDRESS: Yup.string()
      .email("Invalid Email Address")
      .required("Email Address is Required"),
    DEPARTMENT: Yup.string().required("Department is Required"),
    PHONE_NUMBER: Yup.number().required("Phone Number is Required"),
  });

  const addSupplier = (values) => {
    dispatch(
      SupplierServices.putNewSupplier(
        values,
        customerId,
        setInitialContactDetails,
        setNewSupplier,
        rows,
        setSupplierList,
        setAnchorMenuEl
      )
    );
    if (values.SUPPLIER_ID == null) {
      setKeyPersonView(true);
    } else {
      setIsEditMode(false);
    }
  };

  const saveContact = async (values) => {
    dispatch(
      SupplierServices.putNewSupplierSPOC(
        values,
        rows,
        newSupplier,
        setNewSupplier,
        setPersonDataForPlatformLogin,
        keyPersonData,
        setKeyPersonData,
        setSupplierList
      )
    );
    setKeyPersonView(false);
    setIsEditMode(false);
    setSelectedSupplierSpoc(null);
  };

  const handleClose = () => {
    setKeyPersonView(false);
    setIsEditMode(false);
    setSelectedSupplierSpoc(null);
    // setSupplierData(supplierInitialData);
    setShow(false);
  };

  return (
    <Drawer anchor={"right"} open={show} onClose={handleClose}>
      <div className={classes.suppliersACMOdalOne}>
        <CloseIcon onClick={handleClose} />

        <Box>
          {!keyPersonView ? (
            <>
              <Formik
                enableReinitialize={true}
                initialValues={supplierData}
                validationSchema={validationSchema}
                onSubmit={addSupplier}
              >
                {({ values, errors, setFieldValue, resetForm }) => (
                  <Form>
                    <Grid
                      container
                      spacing={2}
                      className={`${classes.suppliersTopHeader} ${
                        isEditMode ? classes.suppliersTopHeaderEdit : ""
                      }`}
                    >
                      <div className={classes.regulatorsTopHeaderTitle}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.SUPPLIER_NAME}
                        </Typography>

                        {isEditMode ? (
                          <Field name="SUPPLIER_NAME">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue("SUPPLIER_NAME", e.target.value)
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                  readOnly: false,
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["SUPPLIER_NAME"]}
                          </Typography>
                        )}
                        <ErrorMessage name="SUPPLIER_NAME">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </div>
                      {!isEditMode && (
                        <div className={classes.regulatorsTopHeaderTitle}>
                          <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => {
                              setIsEditMode(true);
                              (async () => {
                                const response =
                                  await SupplierServices.getSupplierSPOCUserDetails(
                                    User_ID
                                  );
                                if (
                                  response &&
                                  response["#result-set-1"] &&
                                  response["#result-set-1"].length > 0
                                ) {
                                  setLastLoggedin(
                                    response["#result-set-1"][0][
                                      "LOGIN_DATETIME"
                                    ]
                                  );
                                  setPersonDataForPlatformLogin(
                                    (prevState) => ({
                                      ...prevState,
                                      TEAM: response["#result-set-1"][0][
                                        "TEAM_ID"
                                      ],
                                    })
                                  );
                                }
                              })();
                            }}
                          >
                            {langConstant.EDIT}
                          </Button>
                        </div>
                      )}
                    </Grid>
                    {isEditMode && (
                      <Grid style={{ marginTop: "10px", textAlign: "right" }}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          size="small"
                          startIcon={<SaveIcon />}
                        >
                          {" "}
                          {langConstant.SAVE}{" "}
                        </Button>
                        {supplierId ? (
                          <Button
                            color="primary"
                            size="small"
                            variant="text"
                            onClick={() => {
                              setIsEditMode(false);
                              resetForm();
                            }}
                          >
                            {" "}
                            {langConstant.CANCEL}{" "}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            size="small"
                            variant="text"
                            onClick={() => {
                              resetForm();
                            }}
                          >
                            {" "}
                            {langConstant.RESET}{" "}
                          </Button>
                        )}
                      </Grid>
                    )}

                    <Grid
                      className={classNames(
                        classes.regulatorstatusWrapper,
                        classes.AddressGrid
                      )}
                      style={{ marginTop: "20px" }}
                    >
                      <Typography className={classes.filedTitle}>
                        {langConstant.STATUS}
                      </Typography>
                      <Switch
                        checked={values.ACTIVE}
                        onClick={() => setFieldValue("ACTIVE", !values.ACTIVE)}
                      />{" "}
                      <span>{values.ACTIVE ? "ACTIVE" : "INACTIVE"}</span>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <ElectricBoltIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.SUPPLIER_RISK_RATING}
                        </Typography>
                        {isEditMode ? (
                          <Field name="SUPPLIER_RISK_RATING">
                            {({ field: { value } }) => (
                              <TextField
                                value={values.SUPPLIER_RISK_RATING}
                                // disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue(
                                    "SUPPLIER_RISK_RATING",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["SUPPLIER_RISK_RATING"]}
                          </Typography>
                        )}
                        <ErrorMessage name="SUPPLIER_RISK_RATING">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <HubIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.PRODUCT_SERVICE_PROVIDED}
                        </Typography>
                        {isEditMode ? (
                          <Field name="SUPPLIER_PRODUCT_SERVICE_PROVIDED">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                // disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue(
                                    "SUPPLIER_PRODUCT_SERVICE_PROVIDED",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["SUPPLIER_PRODUCT_SERVICE_PROVIDED"]}
                          </Typography>
                        )}
                        <ErrorMessage name="SUPPLIER_PRODUCT_SERVICE_PROVIDED">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <CalendarMonthIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.CONTRACT_DURATION}
                        </Typography>
                        {isEditMode ? (
                          <Field name="SUPPLIER_CONTRACT_DURATION">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                // disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue(
                                    "SUPPLIER_CONTRACT_DURATION",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["SUPPLIER_CONTRACT_DURATION"]}
                          </Typography>
                        )}
                        <ErrorMessage name="SUPPLIER_CONTRACT_DURATION">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <AutoAwesomeMotionIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.CONTRACT_STATUS}
                        </Typography>
                        {isEditMode ? (
                          <Field name="CONTRACT_STATUS">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                // disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue(
                                    "CONTRACT_STATUS",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["CONTRACT_STATUS"]}
                          </Typography>
                        )}
                        <ErrorMessage name="CONTRACT_STATUS">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.SUPPLIER_ADDRESS}
                        </Typography>
                        {isEditMode ? (
                          <Field name="SUPPLIER_ADDRESS">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                //  disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue(
                                    "SUPPLIER_ADDRESS",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["SUPPLIER_ADDRESS"]}
                          </Typography>
                        )}
                        <ErrorMessage name="SUPPLIER_ADDRESS">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <FlagCircleIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.COUNTRY}
                        </Typography>
                        {isEditMode ? (
                          <Field name="COUNTRY_CODE">
                            {() => (
                              <CountryDropdown
                                value={values?.COUNTRY_CODE}
                                onChange={(e) =>
                                  setFieldValue("COUNTRY_CODE", e.value)
                                }
                                placeholder="Select Country"
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["COUNTRY_CODE"]}
                          </Typography>
                        )}
                        <ErrorMessage name="COUNTRY_CODE">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <CategoryIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.SUPPLIER_CATEGORY}
                        </Typography>
                        {isEditMode ? (
                          <Field name="SUPPLIER_CATEGORY">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                // disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue(
                                    "SUPPLIER_CATEGORY",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["SUPPLIER_CATEGORY"]}
                          </Typography>
                        )}
                        <ErrorMessage name="SUPPLIER_CATEGORY">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <AccountTreeOutlinedIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.RESPONSIBLE_DEPARTMENT}
                        </Typography>
                        {isEditMode ? (
                          <Field name="RESPONSIBLE_DEPARTMENT">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue(
                                    "RESPONSIBLE_DEPARTMENT",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["RESPONSIBLE_DEPARTMENT"]}
                          </Typography>
                        )}
                        <ErrorMessage name="RESPONSIBLE_DEPARTMENT">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              {supplierId && (
                <>
                  <Box
                    style={{
                      borderTop: "1px solid #dedede",
                      marginBottom: "10px",
                    }}
                  />

                  <Grid>
                    <Button
                      color="primary"
                      size="small"
                      variant="text"
                      style={{ fontWeight: "600", marginBottom: "10px" }}
                      onClick={() => {
                        setKeyPersonView(true);
                      }}
                      startIcon={<AddIcon />}
                    >
                      {langConstant.ADD_SUPPLIER_CONTACT}
                    </Button>
                  </Grid>
                  {keyPersonData.length > 0 &&
                    keyPersonData.map((item, index) => (
                      <Grid container key={index}>
                        <Grid item xs={11}>
                          <Typography
                            className={classes.fieldTitleIcon}
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              display: "inline",
                            }}
                          >
                            {item.CONTACT_NAME},{" "}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "steelblue",
                              display: "inline",
                            }}
                          >
                            {item.EMAIL_ADDRESS}
                          </Typography>

                          <Typography className={classes.fieldTitleIcon}>
                            {item.ROLE_NAME},{" "}
                            {item.USER_ACTIVE == 1 ? "ACTIVE." : "INACTIVE."}
                          </Typography>
                        </Grid>
                        {isEditMode && (
                          <Grid item xs={1}>
                            <IconButton
                              style={{ position: "absolute", right: 4 }}
                              aria-label="edit"
                              onClick={() => {
                                setSelectedSupplierSpoc(index);
                                setKeyPersonView(true);
                              }}
                            >
                              <EditIcon
                                sx={{
                                  color: "green",
                                }}
                                size="small"
                              />{" "}
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                </>
              )}
            </>
          ) : (
            <>
              <Grid container style={{ marginBottom: "20px" }}>
                <Grid item xs={6}>
                  <Typography className={classes.filedTitleIcon}>
                    <PersonIcon />
                    {langConstant.KEY_PERSON}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="small"
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      setKeyPersonView(false);
                      setIsEditMode(false);
                      setSelectedSupplierSpoc(null);
                    }}
                  >
                    {langConstant.GO_BACK}
                  </Button>
                </Grid>
              </Grid>
              <Formik
                enableReinitialize={true}
                initialValues={
                  selectedSupplierSpoc == null
                    ? initialContactDetails
                    : keyPersonData[selectedSupplierSpoc]
                }
                validationSchema={contactValidationSchema}
                onSubmit={(values) => saveContact(values)}
              >
                {({ values, errors, setFieldValue, resetForm }) => (
                  <Form>
                    <>
                      <Grid
                        container
                        style={{
                          marginTop: "10px",
                          marginBottom: "5px",
                          display: "flex",
                          justifyContent: "flex-end",
                          // textAlign: "right",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: "10px", textAlign: "right" }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            size="small"
                            startIcon={<SaveIcon />}
                          >
                            {" "}
                            {langConstant.SAVE}{" "}
                          </Button>
                        </Grid>

                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => {
                            setShowAddPlatformLogin(true);
                            setPersonDataForPlatformLogin((prevState) => ({
                              ...prevState,
                              CONTACT_NAME: values?.CONTACT_NAME,
                              EMAIL_ADDRESS: values?.EMAIL_ADDRESS,
                              ROLE: values?.ROLE,
                              ROLE_NAME: values?.ROLE_NAME,
                              PHONE_NUMBER: values?.PHONE_NUMBER,
                              DEPARTMENT: values?.DEPARTMENT,
                            }));
                            if (
                              personDataForPlatformLogin.SUPPLIER_CONTACT_ID ==
                                null ||
                              personDataForPlatformLogin.SUPPLIER_CONTACT_ID ==
                                ""
                            ) {
                              setPersonDataForPlatformLogin((prevState) => ({
                                ...prevState,
                                SUPPLIER_CONTACT_ID: values.SUPPLIER_CONTACT_ID,
                              }));
                            }
                          }}
                        >
                          {langConstant.CREATE_PLATFORM_LOGIN}
                        </Button>
                      </Grid>
                      <Grid container>
                        <Grid item xs={11}>
                          <Grid style={{ width: "100%" }}>
                            <>
                              <Grid container>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Grid item xs={12}>
                                    <Typography className={classes.filedTitle}>
                                      {langConstant.CONTACT_NAME}
                                    </Typography>
                                    <Field name={"CONTACT_NAME"}>
                                      {({ field: { value } }) => (
                                        <TextField
                                          value={value}
                                          className={classes.width100}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "CONTACT_NAME",
                                              e.target.value
                                            );
                                          }}
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputProfileField,
                                              notchedOutline: "",
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <ErrorMessage name={"CONTACT_NAME"}>
                                      {(msg) => (
                                        <Typography style={{ color: "red" }}>
                                          {msg}
                                        </Typography>
                                      )}
                                    </ErrorMessage>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Typography className={classes.filedTitle}>
                                    {langConstant.EMAIL_ADDRESS}
                                  </Typography>
                                  <Field name={"EMAIL_ADDRESS"}>
                                    {({ field: { value } }) => (
                                      <TextField
                                        value={value}
                                        className={classes.width100}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "EMAIL_ADDRESS",
                                            e.target.value
                                          );
                                        }}
                                        variant="outlined"
                                        InputProps={{
                                          classes: {
                                            input: classes.inputProfileField,
                                            notchedOutline: "",
                                          },
                                        }}
                                      />
                                    )}
                                  </Field>
                                  <ErrorMessage name={"EMAIL_ADDRESS"}>
                                    {(msg) => (
                                      <Typography style={{ color: "red" }}>
                                        {msg}
                                      </Typography>
                                    )}
                                  </ErrorMessage>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Typography className={classes.filedTitle}>
                                    {langConstant.ROLE}
                                  </Typography>

                                  <Field name="ROLE">
                                    {() => (
                                      <UserRoleDropdown
                                        value={values?.ROLE}
                                        onChange={(e) => {
                                          setFieldValue("ROLE", e.value);
                                          setFieldValue("ROLE_NAME", e.label);
                                        }}
                                        placeholder="Select Role"
                                        customerID={customerId}
                                      />
                                    )}
                                  </Field>

                                  <ErrorMessage name={"ROLE"}>
                                    {(msg) => (
                                      <Typography style={{ color: "red" }}>
                                        {msg}
                                      </Typography>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Typography className={classes.filedTitle}>
                                      {langConstant.PHONE_NUMBER}
                                    </Typography>
                                    <Field name={"PHONE_NUMBER"}>
                                      {({ field: { value } }) => (
                                        <TextField
                                          value={value}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "PHONE_NUMBER",
                                              e.target.value
                                            );
                                          }}
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputProfileField,
                                              notchedOutline: "",
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <ErrorMessage name={"PHONE_NUMBER"}>
                                      {(msg) => (
                                        <Typography style={{ color: "red" }}>
                                          {msg}
                                        </Typography>
                                      )}
                                    </ErrorMessage>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Typography className={classes.filedTitle}>
                                      {langConstant.DEPARTMENT}
                                    </Typography>
                                    <Field name={"DEPARTMENT"}>
                                      {({ field: { value } }) => (
                                        <TextField
                                          value={value}
                                          className={classes.width100}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "DEPARTMENT",
                                              e.target.value
                                            );
                                          }}
                                          variant="outlined"
                                          InputProps={{
                                            classes: {
                                              input: classes.inputProfileField,
                                              notchedOutline: "",
                                            },
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <ErrorMessage name={"DEPARTMENT"}>
                                      {(msg) => (
                                        <Typography style={{ color: "red" }}>
                                          {msg}
                                        </Typography>
                                      )}
                                    </ErrorMessage>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Box
                                style={{
                                  borderTop: "1px solid #dedede",
                                  marginBottom: "10px",
                                }}
                              />

                              {supplierId && selectedSupplierSpoc != null && (
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      color: "primary",
                                      fontWeight: "bold",
                                      marginTop: "20px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Typography
                                      color="primary"
                                      style={{
                                        color: "#3c0292",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Login Details
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Typography className={classes.filedTitle}>
                                      User ID:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Typography className={classes.wordBreak}>
                                      {User_ID}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Typography className={classes.filedTitle}>
                                      User Name:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Typography className={classes.wordBreak}>
                                      {username}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Typography className={classes.filedTitle}>
                                      Last Logged in:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Typography className={classes.wordBreak}>
                                      {lastLoggedin}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Box>
        {showAddPlatformLogin && (
          <AddPlatformLogin
            show={showAddPlatformLogin}
            setShow={setShowAddPlatformLogin}
            data={personDataForPlatformLogin}
          />
        )}
      </div>
    </Drawer>
  );
};

AddSupplierDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  supplierId: PropTypes.string,
  rows: PropTypes.array,
  setSupplierList: PropTypes.func,
  data: PropTypes.array,
  keyPersonView: PropTypes.bool,
  setKeyPersonView: PropTypes.func,
  setAnchorMenuEl: PropTypes.func,
};

export default AddSupplierDialog;
