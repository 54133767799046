import React from "react";
import useStyles from "../../styles";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import UsersTable from "./UsersTable";
import TeamsTable from "./TeamsTable";

const UsersTeams = ({ selectedCustomer }) => {
  const classes = useStyles();
  return (
    <Grid style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Grid className={classes.entitlementWrapper}>
        <UsersTable selectedCustomer={selectedCustomer} />
      </Grid>
      <Grid className={classes.entitlementWrapper}>
        <TeamsTable selectedCustomer={selectedCustomer} />
      </Grid>
    </Grid>
  );
};

UsersTeams.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default UsersTeams;
