import React from "react";
import Panel1 from "./Panels/Panel1";
import Panel2 from "./Panels/Panel2";
import Panel3 from "./Panels/Panel3";
import Panel4 from "./Panels/Panel4";
import Panel5 from "./Panels/Panel5";
import Panel6 from "./Panels/Panel6";
import Panel7 from "./Panels/Panel7";
import Panel8 from "./Panels/Panel8";
import Panel9 from "./Panels/Panel9";

const Components = {
  Panel1,
  Panel2,
  Panel3,
  Panel4,
  Panel5,
  Panel6,
  Panel7,
  Panel8,
  Panel9,
};

// eslint-disable-next-line react/display-name
export default (block, columnID, data) => {
  if (typeof Components[block.content] !== "undefined") {
    return React.createElement(Components[block.content], {
      key: block.id,
      block: block,
      columnID: columnID,
      panel: data,
    });
  }
  return React.createElement(
    () => <div>The component {block.content} has not been created yet.</div>,
    { key: block.id }
  );
};
