import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  customerWrapper: {
    display: "inline-flex",
    border: "2px solid grey",
    width: "100%",
    padding: "20px 10px",
    alignItems: "center",
    borderRadius: "5px",
  },
  sourceSystemWrapper: {
    display: "inline-flex",
    border: "2px solid grey",
    width: "100%",
    padding: "10px",
    alignItems: "center",
    borderRadius: "5px",
  },

  customerSelect: {
    width: "40%",
  },
  customerSelectForm: {
    width: "80%",
  },
  customerLabel: {
    fontSize: "18px",
    fontWeight: "bold",
    paddingRight: "15px",
  },
  tabsLabel: {
    border: "2px solid grey",
    padding: "5px 10px",
    textAlign: "center",
    fontWeight: "bold",
    borderRadius: "5px",
    cursor: "pointer",
  },
  customerFormWrapper: {
    border: "2px Solid grey",
    borderRadius: "5px",
  },
  inputField: {
    padding: "10px 5px !important",
    width: "90%",
    color: "#000",
  },
  customerFormLabel: {
    fontWeight: "bold",
    padding: "10px 0",
  },
  legalEntityLabel: {
    fontWeight: "bold",
    padding: "10px 0",
  },
  errorTxt: {
    color: "red",
    display: "block",
    width: "100%",
    fontSize: "14px",
    lineHeight: "18px",
    marginTop: "0px",
  },
  input: {
    "& input.MuiInputBase-input": {
      padding: "8px",
      width: "80%",
    },
    "& .Mui-disabled": {
      color: "gray",
    },
  },
  dateInput: {
    "& dateInput .MuiFormControl-root": {
      marginRight: "2em",
    },
  },
  entityPopupHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid",
    paddingBottom: "7px",
    marginBottom: "12px",
  },
  entitlementWrapper: {
    border: "2px solid grey",
    borderRadius: "5px",
    padding: "10px",
  },
  entitlementSelectForm: {
    width: "40%",
  },
  select100Width: {
    width: "100%",
  },
  dialogTitleWrapper: {
    textAlign: "center",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid",
    paddingBottom: "10px",
  },
  dialogTitleLabel: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  formLabel: {
    fontWeight: "bold",
    fontSize: "16px",
    margin: "7px 0",
  },
  btnWrapper: {
    margin: "15px 0 0",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default useStyles;
