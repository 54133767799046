import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import React from "react";
import FilterDropdown from "_components/FilterDropdown";
import PropTypes from "prop-types";
import langConstant from "_lang";
import CloseIcon from '@mui/icons-material/Close';

const FilterComponent = ({  show, setShow, filterData, onFilterChange, baseDocumentOptions, targetDocumentOptions, uploadedByOptions, resetFilter }) => {
    
    const handleClose = () => setShow(false)
    
    const archivedOption = [
        {value: 'all', name: "All"},
        {value: '1', name: "Archived"},
        {value: '0', name: "UnArchived"},
    ]

    const currentStatusOptions = [
        {value: 'all', name: 'All'},
        {value: 'DRAFT', name: "DRAFT"},
        {value: 'REVIEW', name: "REVIEW"},
        {value: 'COMPLETED', name: "COMPLETED"},
    ]

    const filtersList = [
        { options: archivedOption, fieldName: 'isArchived', lable: langConstant.ARCHIVED },
        { options: [{value: "all", name: "All"}, ...baseDocumentOptions], fieldName: 'baseDocument', lable: langConstant.BASE_DOCUMENT },
        { options: [{value: "all", name: "All"}, ...targetDocumentOptions], fieldName: 'targetDocument', lable: langConstant.TARGET_DOCUMENT },
        { options: currentStatusOptions, fieldName: 'currentStatus', lable: langConstant.CURRENT_STATUS },
        { options: [{value: "all", name: "All"}, ...uploadedByOptions], fieldName: 'uploadedBy', lable: langConstant.UPLOADED_BY },
    ]

    return (
        <Dialog
            open={show}
            maxWidth={"sm"}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogTitle>
                <Grid container>
                    <Grid item xs={10} md={10}>
                        <Typography style={{fontWeight: "bold"}}>{langConstant.SET_FILTERS_CAPS}</Typography>
                    </Grid>
                    <Grid item xs={2} md={2} style={{textAlign: "right"}}>
                        <CloseIcon sx={{fontWeight: "bold", cursor: "pointer" }} onClick={handleClose}/>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {filtersList.map((item, index) => {
                        return (
                            <Grid item xs={6} md={6} key={index}>
                                <Typography>{item.lable} : </Typography>
                                <FilterDropdown 
                                    options={item.options}
                                    fieldName={item.fieldName}
                                    value={filterData[item.fieldName]}
                                    onChange={onFilterChange}
                                    icon={true}
                                />
                            </Grid>
                        )
                    })}
                    <Grid item xs={6} md={6}>
                        <Typography style={{height: "100%", alignItems: "center",display:"flex"}}>
                            <span onClick={resetFilter} className="linkIcon">{langConstant.RESET_ALL}</span>
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

FilterComponent.propTypes = {
    show: PropTypes.bool, 
    setShow: PropTypes.func, 
    filterData: PropTypes.object, 
    onFilterChange: PropTypes.func, 
    baseDocumentOptions: PropTypes.array, 
    targetDocumentOptions: PropTypes.array, 
    uploadedByOptions: PropTypes.array,
    resetFilter: PropTypes.func
}


export default FilterComponent;