/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as _ from "lodash";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import * as am4charts from "@amcharts/amcharts4/charts";
import DialogBox from "_components/DialogBox";
import langConstant from "_lang";
import {
  defaultGaugeConfig,
  getMinMaxFromArray,
  getScoreColorClass,
  getTrustScoreColorClass,
  getTrustScoreLevel,
} from "_helpers/utils";
import CssGauge from "./CssGauge";
import CustomizedSlider from "_components/MasterAssessmentComp/component/SliderGraph";

const styles = makeStyles((theme) => ({
  td: {
    textAlign: "center",
  },
  highlightColumn: {
    fontSize: "1.250rem",
    fontWeight: 800,
  },
}));

const DataPointQuality = ({
  dqProfile = [],
  size = "large",
  uniqueId,
  chartType = "",
}) => {
  const classes = styles();
  const [openPopup, setOpenPopup] = useState(false);
  const [score, setScore] = useState({
    minScore: 0,
    maxScore: 100,
    averageScore: 0,
    level: "",
  });
  const handlePopupClose = () => {
    setOpenPopup(false);
  };
  const handlePopupOpen = () => {
    setOpenPopup(true);
  };

  const columns = [
    {
      field: "DATA_ASSET_ATTRIBUTE_NAME",
      headerName: "METADATA",
      minWidth: 135,
      textAlign: "left",
    },
    { field: "DATA_ACCURACY_SCORE", headerName: "ACCURACY" },
    { field: "DATA_COMPLETENESS_SCORE", headerName: "COMPLETENESS" },
    { field: "DATA_CONSISTENCY_SCORE", headerName: "CONSISTENCY" },
    {
      field: "TRUST_SCORE",
      headerName: "TRUST SCORE",
      className: classes.highlightColumn,
    },
  ];

  const chartBox = useRef(null);
  useEffect(() => {
    let average = 0;
    if (dqProfile.length > 0) {
      const maxValue = getMinMaxFromArray("TRUST_SCORE", dqProfile, "max");
      const minValue = getMinMaxFromArray("TRUST_SCORE", dqProfile, "min");
      average =
        (_.sumBy(dqProfile, "TRUST_SCORE") / dqProfile.length / 125) * 100;
      average = Math.round(average);

      setScore({
        minScore: minValue,
        maxScore: maxValue,
        averageScore: average,
        level: getTrustScoreLevel(average),
      });
    } else {
      setScore({
        minScore: 0,
        maxScore: 100,
        averageScore: 0,
        level: getTrustScoreLevel(0),
      });
    }
    if (chartType != "css") {
      let gaugeConfig = defaultGaugeConfig(size);
      let chart = am4core.createFromConfig(
        gaugeConfig,
        "chartdiv" + uniqueId,
        am4charts.GaugeChart
      );
      chart.hands.getIndex(0).showValue(average);

      chartBox.current = chart;
      return () => {
        chart.dispose();
      };
    }
  }, [dqProfile]);
  return (
    <>
      <Box style={{ position: "relative" }} pb={2}>
        <Box style={{ height: "85px", marginRight: "5px", }}>
          <Box style={{ height: "60px", display: "flex", alignItems: "flex-end", width: "100%", }}>
            <CustomizedSlider score={score.averageScore} />
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box >
              Data Trust Score
            </Box>
            {
              dqProfile.length > 0 && (
                <Box className="linkIcon" style={{ display: "flex", justifyContent: "end" }} onClick={handlePopupOpen}>
                  {langConstant.VIEW_DETAIL}
                </Box>
              )
            }

          </Box>

        </Box>
        {/* {dqProfile.length == 0 && (
          <Box
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              zIndex: 1,
              backgroundColor: "rgba(255,255,255,0.7)",
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                height: "100%",
              }}
            >
              <Grid>
                <Typography
                  align="center"
                  variant="body2"
                  gutterBottom={false}
                  component="div"
                >
                  <strong>{langConstant.NO_DATAPOINTS_AVAILABLE}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )} */}
        {/* {dqProfile.length > 0 && (
          <Box
            onClick={handlePopupOpen}
            style={{ zIndex: 1, position: "relative" }}
          >
            <Typography
              align="center"
              variant="body2"
              gutterBottom={false}
              component="div"
            >
              <Box className="linkIcon">
                {" "}
                <ErrorOutlineIcon />{" "}
                <Box ml={1}>{langConstant.VIEW_DETAIL}</Box>
              </Box>
            </Typography>
          </Box>
        )} */}
        {/* {chartType == "css" ? (
          <CssGauge value={score.averageScore} size={size} />
        ) : (
          <Box
            id={"chartdiv" + uniqueId}
            style={{
              width: "100%",
              height: size == "small" ? "150px" : "200px",
            }}
          ></Box>
        )} */}
        {/* <Box
          style={{
            width: chartType == "css" ? "auto" : "57%",
            margin: "0 auto",
            textTransform: "uppercase",
            position: "absolute",
            bottom: "4px",
            left: 0,
            right: 0,
            fontWeight: "bold",
          }}
        >
          <Grid
            container
            justify="space-between"
            alignItems="flex-end"
            spacing={0}
          >
            <Grid style={{ minWidth: "20%" }}>
              <Typography
                align="center"
                className={getTrustScoreColorClass(5)}
                style={{ borderRadius: 5 }}
              >
                <strong>{0}</strong>
              </Typography>
            </Grid>
            <Grid>
              <Box align="center">
                <Typography
                  align="center"
                  style={{
                    fontSize: size == "small" ? "1.5rem" : "1.87rem",
                  }}
                >
                  <strong>{score.averageScore}</strong>
                </Typography>{" "}
                <Box
                  style={{
                    fontSize: size == "small" ? "0.875rem" : "1rem",
                  }}
                >
                  {" "}
                  {score.level}
                </Box>
              </Box>
            </Grid>
            <Grid style={{ minWidth: "20%" }}>
              <Typography
                align="center"
                className={getTrustScoreColorClass(100)}
                style={{ borderRadius: 5 }}
              >
                <strong>{100}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Box> */}
      </Box>
      <DialogBox
        title={langConstant.TRUST_SCORE}
        open={openPopup}
        handleClose={handlePopupClose}
        maxWidth={"md"}
      >
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Box className={" colorTag highScoreBgColor"}></Box>{" "}
              {langConstant.HIGH}
            </Grid>
            <Grid item>
              <Box ml={2} className={"colorTag mediumScoreBgColor"}></Box>{" "}
              {langConstant.MEDIUM}
            </Grid>
            <Grid item>
              <Box ml={2} className={"colorTag lowScoreBgColor"}></Box>{" "}
              {langConstant.LOW}
            </Grid>
          </Grid>
        </Box>
        <TableContainer component={Paper}>
          <Table
            aria-label={langConstant.DATAPOINTS_LIST}
            className="data-table "
          >
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  return (
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.td}
                      key={"column_" + index + column.field}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.textAlign,
                      }}
                    >
                      {column.headerName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dqProfile.map((row, index) => (
                <TableRow key={index + row[columns[0].field]}>
                  {columns.map((column, index) => {
                    //const Comp = column.component && column.component
                    const value = row[column.field];
                    return (
                      <TableCell
                        component="td"
                        scope="row"
                        key={"column_" + index + row[columns[0].field]}
                        style={{
                          textAlign: column.textAlign,
                        }}
                        className={
                          classes.td +
                          " " +
                          column.className +
                          " " +
                          (column.field == "TRUST_SCORE"
                            ? getTrustScoreColorClass(value)
                            : null)
                        }
                      >
                        {[
                          "DATA_ACCURACY_SCORE",
                          "DATA_COMPLETENESS_SCORE",
                          "DATA_CONSISTENCY_SCORE",
                        ].indexOf(column.field) > -1 && (
                          <div
                            className={"colorTag " + getScoreColorClass(value)}
                          ></div>
                        )}
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogBox>
    </>
  );
};
DataPointQuality.propTypes = {
  dqProfile: PropTypes.array,
  size: PropTypes.string,
  uniqueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chartType: PropTypes.string,
};

export default DataPointQuality;
