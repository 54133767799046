import React, { useCallback, useEffect, useState } from "react";
import { Box, makeStyles, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, InputAdornment } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Checkbox from "@mui/material/Checkbox";
import AppPagination from '_components/AppPagination';
import PropTypes from "prop-types";
import CopyModulesDialogBox from "./CopyModulesDialogBox";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from "@mui/material/Tooltip";
import individualQuestionAction from "../actions";
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import CircularProgress from '@mui/material/CircularProgress';
import { Fragment } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import sessionService from "_services/session.service";
import FilterDropdown from "_components/FilterDropdown";
import langConstant from "_lang";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";


const useStyles = makeStyles(() => ({
  margintop: {
    marginTop: "8px"
  },
  maxHeight: {
    maxHeight: "450px"
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  copybtn: {
    height: "25px"
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#F6F6F6 !important",
      cursor: "pointer"
    }
  }
}));


const SimilarQuestions = ({ questionID, regulationID, fetchdata }) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles();

  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState({ TableRows: [] })
  const [filteredRows, setFilteredRows] = useState([])
  const [filterBy, setFilterBy] = useState({REGULATION_ID: ""})
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const calculatePageCount = (rows) => { return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0; };
  const [pageCount, setPageCount] = useState(calculatePageCount(filteredRows));
  const [order, setOrder] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [matchScoreFilter, setMatchScoreFilter] = useState([7,8,9,10]);
  const [selectedQuestionForCopy, setSelectedQuestionForCopy] = useState([]);
  const [SaveSelectionData, setSaveSelectionData] = useState([]);
  const [SearchText, setSearchText] = useState("");
  const [regulationOptions, setRegulationOptions] = useState([])
  const [sortedBy, setSortedBy] = useState({SIMILAR_QUESTION: "desc", SIMILARITY_SCORE: "desc"})

  const columns = [
    { field: "checkbox", headerName: "", minWidth: 40 },
    { field: "REGULATION_SHORT_NAME", headerName: "REGULATION", minWidth: 40 },
    { field: "SIMILAR_QUESTION", headerName: "QUESTION REF.", minWidth: 50 },
    { field: "QUESTION_TEXT", headerName: "QUESTION SUMMARY", minWidth: 50, maxWidth: 70 },
    { field: "SIMILARITY_SCORE", headerName: "MATCH SCORE(IN%)", minWidth: 20 },
    { field: "CISO_CONTROL", headerName: "CONTROLS", minWidth: 20 },
    { field: "DATA_ASSETS", headerName: "DATA POINTS", minWidth: 20 },
    { field: "RULE_COUNT", headerName: "RULES", minWidth: 20 },
    { field: "EVIDENCE", headerName: "EVIDENCE", minWidth: 20 },
    { field: "action", headerName: "ACTION", minWidth: 50, sticky: true },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (event) => {
    setPage(event);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const GetSimilarQuestions = async () => {
    if (questionID && regulationID) {
      const data = await individualQuestionAction.GetSimilarQuestionsByQuestionID(questionID, regulationID, customerID);

      if (data["#result-set-1"] && data["#result-set-1"]?.length > 0) {
        setFilteredRows(data["#result-set-1"]);
        setPageCount(calculatePageCount(data["#result-set-1"]));
        setAllData((prev) => ({ ...prev, TableRows: data["#result-set-1"] }));
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    GetSimilarQuestions();
  }, [questionID, regulationID])

  useEffect(() => {
    let updatedData = filterFunction([...allData["TableRows"]]);
    updatedData = applySorting(updatedData)
    setFilteredRows(updatedData)
    setPageCount(calculatePageCount(updatedData))
    setRegulationOptions([...new Map(allData["TableRows"].map(item => [item['REGULATION_ID'], {value: item['REGULATION_ID'], name: item['REGULATION_SHORT_NAME']}])).values()])
  }, [matchScoreFilter, allData["TableRows"], filterBy, sortedBy]);

  const applySorting = (data) => {
    let tempData = [...data]
    if(sortedBy['SIMILAR_QUESTION']){
      if(sortedBy['SIMILAR_QUESTION'] === "asc"){
        tempData = tempData.sort((a, b) => a.SIMILAR_QUESTION.localeCompare(b.SIMILAR_QUESTION))
      } else {
        tempData = tempData.sort((a, b) => b.SIMILAR_QUESTION.localeCompare(a.SIMILAR_QUESTION))
      }
    } 
    if(sortedBy['SIMILARITY_SCORE']){
      if(sortedBy['SIMILARITY_SCORE'] === "asc"){
        tempData = tempData.sort((a, b) => a.SIMILARITY_SCORE - b.SIMILARITY_SCORE)
      } else {
        tempData = tempData.sort((a, b) => b.SIMILARITY_SCORE - a.SIMILARITY_SCORE)
      }
    }
    return tempData
  }


  const pageSetter = (sortedData) => {
    const totalrows = calculatePageCount(sortedData)
    if (page + 1 > totalrows && totalrows > 0) {
      setPage(totalrows - 1)
    }
  }

  const filterFunction = (data = []) => {

    let sortedData = data;
    if(filterBy.REGULATION_ID !== ""){
      sortedData = sortedData.filter((ele) => ele.REGULATION_ID == filterBy.REGULATION_ID)
    }

    if (matchScoreFilter.length > 0) {
      let temp = [];
      matchScoreFilter.map((el) => {
        if(el !== 0){
          let tempdata = sortedData.filter((ele) => ele.SIMILARITY_SCORE >= (el === 1 ? 0 : ((el - 1) * 10) + 1) && ele.SIMILARITY_SCORE <= (el * 10))
          temp = [...temp, ...tempdata]
        }
      });
      sortedData = [...temp];
      
    }
    pageSetter(sortedData);
    return sortedData;
  }

  const MatchScoreFilterFunc = (element) => {
    if (matchScoreFilter.indexOf(element) > -1) {

      if (element === 0) {
        setMatchScoreFilter([]);
      } else {
        const data = [...matchScoreFilter].filter((el) => el !== element);
        setMatchScoreFilter(data);
      }
    } else {
      if (element === 0) {
        setMatchScoreFilter([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
      } else {
        setMatchScoreFilter((prev) => ([...prev, element]))
      }
    }

  }

  const MatchScoreSortingTooltip = () => {
    return (
      <List
        dense
        sx={{ width: "100%", color: "black" }}
      >
        {
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el, i) => (
            <Fragment key={`${el}_${i}`}>
              <ListItem
                onClick={(e) => { MatchScoreFilterFunc(el) }}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onClick={(e) => { e.preventDefault() }}
                    checked={matchScoreFilter.some((ele) => ele === el)}
                    disableRipple
                    size="small"
                  />
                }
                style={{ padding: "0px" }}
                dense
              >
                <ListItemButton dense disablePadding
                  style={{ height: "20px", fontSize: "12px", paddingTop: "0px", paddingBottom: "0px" }}
                >
                  <ListItemText
                    id={`checkbox-list-secondary-label-${el}`}
                    primary={el === 0 ? (`Select all`) : (`${el === 1 ? 0 : ((el - 1) * 10) + 1} - ${el * 10}%`)}
                  />
                </ListItemButton>
              </ListItem>
              <Divider variant="middle" />
            </Fragment>

          ))
        }
      </List>

    )
  }


  const TableLoopFilterFunc = () => {

    if (order === "") {
      return filteredRows.sort((a, b) => b.SIMILARITY_SCORE - a.SIMILARITY_SCORE);
    }

    if (order === "desc") {
      return filteredRows.sort((a, b) => b.SIMILARITY_SCORE - a.SIMILARITY_SCORE);
    }

    return filteredRows.sort((a, b) => a.SIMILARITY_SCORE - b.SIMILARITY_SCORE);

  }

  const TableRowClick = (row) => {
    const data = [...selectedQuestionForCopy];
    const filterddata = data.some((el) => `${el.SIMILAR_QUESTION}_${el.CISO_CONTROL}_${el.DATA_ASSETS}_${el.EVIDENCE}_${el.SIMILARITY_SCORE}` === `${row.SIMILAR_QUESTION}_${row.CISO_CONTROL}_${row.DATA_ASSETS}_${row.EVIDENCE}_${row.SIMILARITY_SCORE}`);
    if (filterddata) {
      const tempdata = data.filter((el) => `${el.SIMILAR_QUESTION}_${el.CISO_CONTROL}_${el.DATA_ASSETS}_${el.EVIDENCE}_${el.SIMILARITY_SCORE}` !== `${row.SIMILAR_QUESTION}_${row.CISO_CONTROL}_${row.DATA_ASSETS}_${row.EVIDENCE}_${row.SIMILARITY_SCORE}`);
      setSelectedQuestionForCopy(tempdata);

      if (SaveSelectionData.length > 0) {
        const myArr = [...SaveSelectionData].map((el) => ({ header: el.header, data: el.data.filter((ele) => ele.QUESTION_ID !== row.SIMILAR_QUESTION) }));
        setSaveSelectionData(myArr)
      }

    } else {
      setSelectedQuestionForCopy((prev) => ([...prev, row]));
    }
  }

  const CopySingleQuestionHandler = (row) => {
    const data = [...selectedQuestionForCopy];
    const filterddata = data.some((el) => `${el.SIMILAR_QUESTION}_${el.CISO_CONTROL}_${el.DATA_ASSETS}_${el.EVIDENCE}_${el.SIMILARITY_SCORE}` === `${row.SIMILAR_QUESTION}_${row.CISO_CONTROL}_${row.DATA_ASSETS}_${row.EVIDENCE}_${row.SIMILARITY_SCORE}`);
    if (!filterddata) {
      setSelectedQuestionForCopy((prev) => ([...prev, row]));
    }
    setIsDialogOpen(true);
  }

  const SearchDebouncedChangeHandler = useCallback(_.debounce((e) => {
    setSearchText(e.target.value);
  }, 200));

  const SearchQuestionsByRef = async () => {
    const data = await individualQuestionAction.GetQuestionRef(SearchText, userID);
    if (data["#result-set-1"] && data["#result-set-1"].length > 0) {
      const temp = []

      data["#result-set-1"].map((srchQues) => {
        const bool = allData["TableRows"].some((el) => `${el.SIMILAR_QUESTION}_${el.CISO_CONTROL}_${el.DATA_ASSETS}_${el.EVIDENCE}_${el.SIMILARITY_SCORE}` === `${srchQues.SIMILAR_QUESTION}_${srchQues.CISO_CONTROL}_${srchQues.DATA_ASSETS}_${srchQues.EVIDENCE}_${srchQues.SIMILARITY_SCORE}`);
        if (!bool) {
          temp.push(srchQues)
        }
      });

      if (temp.length > 0) {
        setAllData((prev) => ({ ...prev, TableRows: [...prev["TableRows"], ...temp] }))
      }
    }
  }

  const onFilterChange = (fieldName, e) => {
    setFilterBy((prev) => ({ ...prev, [fieldName]: e.target.value }))
  }


  return (
    <Box>
      {
        isLoading === true ? (
          <div style={{ display: 'flex', justifyContent: 'center', height: "200px", alignItems: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid container style={{ marginTop: "5px" }}>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  placeholder="Search"
                  onChange={(e) => { SearchDebouncedChangeHandler(e) }}
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1} md={1} style={{ marginLeft: "10px" }}>
                <Button color="primary" onClick={SearchQuestionsByRef} variant="contained" size="small" style={{ width: "100%" }} disabled={SearchText.trim().length === 0}>
                  Search
                </Button>
              </Grid>
              <Grid item xs={3} md={3} style={{ marginLeft: "10px" }}>
                <FilterDropdown
                  options={regulationOptions}
                  label={langConstant.SELECT_REGULATION}
                  fieldName={'REGULATION_ID'}
                  value={filterBy['REGULATION_ID']}
                  onChange={onFilterChange}
                  icon={true}
                />
              </Grid> 
            </Grid>
            <TableContainer component={Paper} className={`${classes.margintop} ${classes.maxHeight}`}>
              <Table stickyHeader className="data-table">
                <TableHead >
                  <TableRow component="tr" style={{ height: 40 }}>
                    {columns.map((item, index) => (
                      <TableCell
                        component="th"
                        scope="row"
                        key={"column_" + index + item.field}
                        className={classes.stickyColl}
                      >
                        {item.field !== "SIMILARITY_SCORE" && item.field !== "SIMILAR_QUESTION" &&(item.headerName)}
                        {item.field === "SIMILAR_QUESTION" && (
                          <TableSortLabel
                            active={sortedBy['SIMILAR_QUESTION'] !== "" ? true : false}
                            onClick={(e) => { e.stopPropagation(); setSortedBy((prevState) => ({...prevState, SIMILAR_QUESTION: sortedBy['SIMILAR_QUESTION'] === "" ? "asc" : (sortedBy['SIMILAR_QUESTION'] === "asc" ? ("desc") : ("asc"))})); }}
                            direction={sortedBy['SIMILAR_QUESTION'] ? sortedBy['SIMILAR_QUESTION'] : "desc"}
                          >
                            <span>{item.headerName}</span>
                          </TableSortLabel>
                        )}
                        {item.field === "SIMILARITY_SCORE" && (
                          <>
                            <TableSortLabel
                              active={sortedBy['SIMILARITY_SCORE'] !== "" ? true : false}
                              onClick={(e) => { e.stopPropagation(); setSortedBy((prevState) => ({...prevState, SIMILARITY_SCORE: sortedBy['SIMILARITY_SCORE'] === "" ? "asc" : (sortedBy['SIMILARITY_SCORE'] === "asc" ? ("desc") : ("asc"))})); }}
                              direction={sortedBy['SIMILARITY_SCORE'] ? sortedBy['SIMILARITY_SCORE'] : "desc"}
                            >
                              <span onMouseEnter={handleClick} key={"anchor-element"}>{item.headerName}</span>
                            </TableSortLabel>
                            <Menu
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                            >
                              {MatchScoreSortingTooltip()}
                            </Menu>

                          </>
                        )}

                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {(rowsPerPage > 0
                    ? filteredRows.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                        )
                    : filteredRows
                    ).map((row, rowIndex) => (
                    <TableRow key={"Row_" + rowIndex} component="tr" className={classes.tableRow}>
                      {
                        columns.map((col, index) => (
                          <TableCell component="td" key={"Row_" + index + row[col.field]} style={{ minWidth: col.minWidth, maxWidth: col.maxWidth }} onClick={() => { col.field !== "action" && TableRowClick(row) }}>
                            {
                              col.field === "action" ? (

                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Link
                                    to={{
                                      pathname: "/manage-questions/" + row.REGULATION_ID,
                                      state: {
                                        SIMILAR_QUESTION: row.SIMILAR_QUESTION,
                                        regulationID: row.REGULATION_ID
                                      }
                                    }}
                                    className="linkIcon"
                                  >
                                    <Tooltip title="View" placement="top">
                                      <Box
                                        className={`${classes.actionWrap}`}
                                        style={{
                                          backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                                          marginRight: "5px",
                                          cursor: "pointer"
                                        }}
                                      >
                                        <VisibilityIcon
                                          style={{
                                            color: "#fff",
                                            fontSize: "18px",
                                            margin: "0 auto",
                                          }}

                                        />
                                      </Box>
                                    </Tooltip>
                                  </Link>

                                  <Tooltip title="copy" placement="top">
                                    <Box
                                      className={classes.actionWrap}
                                      style={{
                                        backgroundColor: "#11954C",
                                        marginRight: "5px",
                                        marginLeft: "5px",
                                        cursor: "pointer"
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        CopySingleQuestionHandler(row);
                                      }}
                                    >
                                      <ContentCopyIcon
                                        style={{
                                          color: "#fff",
                                          fontSize: "18px",
                                          margin: "0 auto",
                                        }}
                                      />
                                    </Box>
                                  </Tooltip>

                                </Box>

                              ) : (col.field === "checkbox" ? (
                                <Checkbox
                                  edge="end"
                                  key={row["MATCH_SCORE"]}
                                  onClick={(e) => { e.preventDefault() }}
                                  checked={selectedQuestionForCopy.some((el) => `${el.SIMILAR_QUESTION}_${el.CISO_CONTROL}_${el.DATA_ASSETS}_${el.EVIDENCE}_${el.SIMILARITY_SCORE}` === `${row.SIMILAR_QUESTION}_${row.CISO_CONTROL}_${row.DATA_ASSETS}_${row.EVIDENCE}_${row.SIMILARITY_SCORE}`)}
                                  disableRipple
                                />

                              ) : (col.field === "QUESTION_TEXT" ? (
                                row[col.field].length > 100 ? (<span>{row[col.field].slice(0, 100)} ...</span>) : (row[col.field])
                              ) : (row[col.field])))
                            }
                          </TableCell>
                        ))
                      }

                    </TableRow>
                  ))}

                </TableBody>
              </Table>

            </TableContainer>
            <div style={{ marginTop: "10px" }}>
              <Button color="primary" className={classes.copybtn} variant="contained" onClick={() => { setIsDialogOpen(true) }} disabled={selectedQuestionForCopy.length === 0}>
                COPY SELECTED
              </Button>
            </div>
          </>
        )
      }

      {
        pageCount > 1 && (
          <Box my={2}>
            <Grid container justify="flex-end">
              <AppPagination
                colSpan={3}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Box>
        )
      }

      {
        isDialogOpen && <CopyModulesDialogBox SaveSelectionData={SaveSelectionData} setSaveSelectionData={setSaveSelectionData} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} questionID={questionID} regulationID={regulationID} selectedQuestionForCopy={selectedQuestionForCopy} fetchdata={fetchdata} />
      }


    </Box >
  )
}

SimilarQuestions.propTypes = {
  questionID: PropTypes.string,
  regulationID: PropTypes.string,
  fetchdata: PropTypes.func
};

export default SimilarQuestions