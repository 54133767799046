import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  inputField: {
    padding: "5px 5px !important",
    width: "100%",
    color: "#000",
  },
}));

export default useStyles;
