import { Grid, Typography, Tooltip } from "@material-ui/core";
import { Drawer, Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import langConstant from "_lang";
import Select from "react-select";
import styles from "../styles";
import { useDispatch } from "react-redux";
import NetworkActions from "../actions";
import { useParams } from "react-router";
import sessionService from "_services/session.service";
import { generateBrightRandomColor } from "_helpers/utils";
import OptionComponent from "./OptionComponent";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip placement="bottom" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const FilterDrawer = ({
  show,
  setShow,
  setNodes,
  setEdges,
  networkData,
  setNetworkData,
}) => {
  const [selectedRange, setSelectedRange] = useState({
    value: 60,
    label: "60 % - 50 %",
    startRange: 50,
    endRange: 60,
  });
  const [customRange, setCustomRange] = useState([50, 60]);

  const { id } = useParams();

  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();

  const getNetworkData = async () => {
    await dispatch(
      NetworkActions.getNetworkData(
        id,
        customerID,
        customRange[0],
        customRange[1],
        setNetworkData
      )
    );
  };

  useEffect(() => {
    (async () => {
      await getNetworkData();
    })();
  }, [selectedRange]);

  const rangeOptions = [
    { value: 70, label: "60 % +", startRange: 60, endRange: 100 },
    { value: 60, label: "60 % - 50 %", startRange: 50, endRange: 60 },
    { value: 50, label: "50 % - 40 %", startRange: 40, endRange: 50 },
    { value: 40, label: "40 % - 30 %", startRange: 30, endRange: 40 },
    { value: 30, label: "30 % - 20 %", startRange: 20, endRange: 30 },
    { value: 20, label: "20 % - 10 %", startRange: 10, endRange: 20 },
    { value: 10, label: "10 % - 0 %", startRange: -100, endRange: 10 },
  ];
  const [selectedSourceQuestion, setSelectedSourceQuestion] = useState({
    value: "all",
    label: "All",
  });
  const [selectedSubSourceQuestion, setSelectedSubSourceQuestion] = useState(
    []
  );
  const [selectedSimilarQuestion, setSelectedSimilarQuestion] = useState({
    value: "all",
    label: "All",
  });

  const [sourceQuestionOptions, setSourceQuestionOptions] = useState([
    { value: "all", label: "All" },
  ]);
  const [similarQuestionOptions, setSimilarQuestionOptions] = useState([
    { value: "all", label: "All" },
  ]);
  const [sourceSubQuestionOptions, setSourceSubQuestionOptions] = useState([]);

  const classes = styles();

  const updateNodeData = (
    sourceFilter,
    similarFilter,
    question = [],
    customRangeData = []
  ) => {
    let sourceFilterShape = "circle";
    let similarFilterShape = "circle";

    let tempNetworkData = [...networkData];

    if (customRangeData.length > 0) {
      tempNetworkData = tempNetworkData.filter(
        (item) =>
          item.SIMILARITY_SCORE * 100 >= customRangeData[0] &&
          item.SIMILARITY_SCORE * 100 <= customRangeData[1]
      );
    }

    if (sourceFilter === "all") {
      if (similarFilter !== "all") {
        tempNetworkData = tempNetworkData.filter(
          (item) => item.REGULATION_ID_sec == similarFilter
        );
        similarFilterShape = "square";
      }
    } else {
      tempNetworkData = tempNetworkData.filter(
        (item) => item.REGULATION_ID == sourceFilter
      );
      sourceFilterShape = "square";
      if (similarFilter !== "all") {
        tempNetworkData = tempNetworkData.filter(
          (item) => item.REGULATION_ID_sec == similarFilter
        );
        similarFilterShape = "square";
      }
    }

    if (question.length > 0) {
      tempNetworkData = tempNetworkData.filter((item) =>
        question.some((el) => el.value === item.SOURCE_QUESTION)
      );
    }

    let nodeData = [];
    let tempNodeData = [];
    let relationShips = [];

    tempNetworkData.forEach((item) => {
      if (!tempNodeData.includes(item.SOURCE_QUESTION)) {
        const colorCode = generateBrightRandomColor();
        tempNodeData.push(item.SOURCE_QUESTION);
        nodeData.push({
          id: item.SOURCE_QUESTION,
          title: item.QUESTION_TEXT,
          label: item.SOURCE_QUESTION,
          shape: sourceFilterShape,
          short_name: item.REGULATION_SHORT_NAME,
          color: colorCode,
        });
      }
      if (!tempNodeData.includes(item.SIMILAR_QUESTION)) {
        const colorCode = generateBrightRandomColor();
        tempNodeData.push(item.SIMILAR_QUESTION);
        nodeData.push({
          id: item.SIMILAR_QUESTION,
          title: item.QUESTION_TEXT_sec,
          label: item.SIMILAR_QUESTION,
          shape: similarFilterShape,
          short_name: item.REGULATION_SHORT_NAME_sec,
          color: colorCode,
        });
      }
      relationShips.push({
        from: item.SOURCE_QUESTION,
        to: item.SIMILAR_QUESTION,
        label:
          parseFloat(item.SIMILARITY_SCORE * 100)
            .toFixed(2)
            .toString() + " %",
      });
    });
    setNodes(nodeData);
    setEdges(relationShips);
  };

  const filterQuestionData = (type, question) => {
    if (type == "source") {
      updateNodeData(
        question.value,
        selectedSimilarQuestion.value,
        selectedSubSourceQuestion,
        customRange
      );
      setSelectedSourceQuestion(question);
      const tempNetworkData = networkData.filter(
        (item) => item.REGULATION_ID == question.value
      );
      let sourceSubQuestion = [
        ...new Map(
          tempNetworkData.map((item) => [
            item["SOURCE_QUESTION"],
            {
              value: item["SOURCE_QUESTION"],
              label: item["SOURCE_QUESTION"],
            },
          ])
        ).values(),
      ];
      setSourceSubQuestionOptions(sourceSubQuestion);
    }
    if (type == "similar") {
      updateNodeData(
        selectedSourceQuestion.value,
        question.value,
        selectedSubSourceQuestion,
        customRange
      );
      setSelectedSimilarQuestion(question);
    }
    if (type == "subSource") {
      updateNodeData(
        selectedSourceQuestion.value,
        selectedSimilarQuestion.value,
        question,
        customRange
      );
      setSelectedSubSourceQuestion(question);
    }
  };

  useEffect(() => {
    if (networkData && networkData.length > 0) {
      let tempNetworkData = [...networkData];

      if (customRange.length > 0) {
        tempNetworkData = tempNetworkData.filter(
          (item) =>
            item.SIMILARITY_SCORE * 100 >= customRange[0] &&
            item.SIMILARITY_SCORE * 100 <= customRange[1]
        );
      }
      let sourceQuestion = [
        ...new Map(
          tempNetworkData.map((item) => [
            item["REGULATION_ID"],
            {
              value: item["REGULATION_ID"],
              label: item["REGULATION_SHORT_NAME"],
            },
          ])
        ).values(),
      ];
      setSourceQuestionOptions([
        { value: "all", label: "All" },
        ...sourceQuestion,
      ]);
      let sourceSubQuestion = [
        ...new Map(
          tempNetworkData.map((item) => [
            item["SOURCE_QUESTION"],
            {
              value: item["SOURCE_QUESTION"],
              label: item["SOURCE_QUESTION"],
            },
          ])
        ).values(),
      ];
      setSourceSubQuestionOptions(sourceSubQuestion);
      let similarQuestion = [
        ...new Map(
          tempNetworkData.map((item) => [
            item["REGULATION_ID_sec"],
            {
              value: item["REGULATION_ID_sec"],
              label: item["REGULATION_SHORT_NAME_sec"],
            },
          ])
        ).values(),
      ];
      setSimilarQuestionOptions([
        { value: "all", label: "All" },
        ...similarQuestion,
      ]);
      let selectedSource = selectedSourceQuestion.value;
      let selectedSimilar = selectedSimilarQuestion.value;
      if (
        !sourceQuestion.find(
          (item) => item.value == selectedSourceQuestion.value
        )
      ) {
        selectedSource = "all";
        setSelectedSourceQuestion({ value: "all", label: "All" });
      }
      if (
        !similarQuestion.find(
          (item) => item.value == selectedSimilarQuestion.value
        )
      ) {
        selectedSimilar = "all";
        setSelectedSimilarQuestion({ value: "all", label: "All" });
      }
      updateNodeData(
        selectedSource,
        selectedSimilar,
        selectedSubSourceQuestion,
        customRange
      );
    }
  }, [networkData, customRange]);

  const handleCustomRange = (e) => {
    setCustomRange(e.target.value);
  };

  return (
    <Drawer anchor={"right"} open={show} onClose={() => setShow(false)}>
      <Grid style={{ width: "450px", padding: "30px 15px" }}>
        <Grid>
          <Typography className={classes.filterHeader}>
            {langConstant.NETWORK_SETTINGS}
          </Typography>
        </Grid>
        <Grid style={{ padding: "20px 15px" }}>
          <Grid>
            <Typography className={classes.filterSubHeader}>
              {langConstant.RELATIONSHIP_SETTINGS}
            </Typography>
            <Grid
              className={classes.networkFilterWrapper}
              style={{ paddingTop: "15px" }}
            >
              <Typography className={classes.filterLable}>
                {langConstant.RELATIONSHIP_STRENGTH}
              </Typography>
              <Select
                className={classes.filterSelect}
                options={rangeOptions}
                value={selectedRange}
                onChange={(range) => {
                  setSelectedRange(range);
                  setCustomRange([range.startRange, range.endRange]);
                }}
              />
            </Grid>
            <Grid>
              <Typography className={classes.filterLable}>
                {langConstant.SELECT_CUSTOM_RANGE}
              </Typography>
              <Slider
                value={customRange}
                min={selectedRange.startRange}
                max={selectedRange.endRange}
                onChange={(e) => handleCustomRange(e)}
                valueLabelDisplay="auto"
                step={0.5}
                className={classes.filterSlider}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography className={classes.filterSubHeader}>
              {langConstant.QUESTION_SETTINGS}
            </Typography>
            <Grid
              className={classes.networkFilterWrapper}
              style={{ paddingTop: "15px" }}
            >
              <Typography className={classes.filterLable}>
                {langConstant.SOURCE_QUESTIONS}
              </Typography>
              <Select
                className={classes.filterSelect}
                options={sourceQuestionOptions}
                value={selectedSourceQuestion}
                onChange={(question) => {
                  filterQuestionData("source", question);
                }}
              />
            </Grid>
            <Grid className={classes.networkFilterWrapper}>
              <Typography className={classes.filterLable}>
                {langConstant.SOURCE_SUB_QUESTIONS}
              </Typography>
              <Select
                className={classes.filterSelect}
                options={sourceSubQuestionOptions}
                value={selectedSubSourceQuestion}
                isMulti
                components={{ Option: OptionComponent }}
                closeMenuOnSelect={false}
                close
                hideSelectedOptions={false}
                allowSelectAll={true}
                onChange={(question) => {
                  filterQuestionData("subSource", question);
                }}
              />
            </Grid>
            <Grid className={classes.networkFilterWrapper}>
              <Typography className={classes.filterLable}>
                {langConstant.SIMILAR_QUESTIONS}
              </Typography>
              <Select
                className={classes.filterSelect}
                options={similarQuestionOptions}
                value={selectedSimilarQuestion}
                onChange={(question) => {
                  filterQuestionData("similar", question);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setNodes: PropTypes.func,
  setEdges: PropTypes.func,
  networkData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  setNetworkData: PropTypes.func,
};

export default FilterDrawer;
