import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  Collapse,
  Box,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
  Fade,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import langConstant from "_lang";
import {
  Button,
  TableHead,
  Paper,
  IconButton,
  TableCell,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import RegulationServices from "../../services";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import AppPagination from "_components/AppPagination";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FilterDropdown from "_components/FilterDropdown";
import SearchIcon from "@mui/icons-material/Search";
import { isEqual, uniqBy } from "lodash";
import { boldOnSearch } from "_helpers/utils";
import sessionService from "_services/session.service";
import Select from "react-select";

const summaryHeaders = [
  "ARTICLE",
  "CHAPTER",
  "DOCUMENT RECOMMENDATION",
  "SECTION",
  "ACTION",
];

const documentHeaders = [
  "ARTICLE",
  "CHAPTER",
  "DOCUMENT RECOMMENDATION",
  "EVIDENCE SHORTNAME",
  "SENTENCE DETAILS",
  "ACTIVE",
  "CREATE DATE",
  "ACTION",
];

const summaryInitialFilterParams = {
  article: "",
  chapter: "",
  section: "",
  docRecommendation: "",
  sentDetails: "",
};
const controlLevelInitialFilterParams = {
  article: "",
  chapter: "",
  eviShortName: "",
  docRecommendation: "",
  active: "",
  sentDetails: "",
};

const CollapsibleRow = ({
  item,
  setShowForm,
  setSelectedRow,
  isSelected,
  searchValue,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (searchValue?.trim()?.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [searchValue]);

  return (
    <>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell>{item?.ARTICLE}</TableCell>
        <TableCell>{item?.CHAPTER}</TableCell>
        <TableCell>{item?.DOCUMENT_RECOMMENDATION}</TableCell>
        <TableCell>{item?.SECTION}</TableCell>
        <TableCell>
          <EditIcon
            sx={{ color: "red", cursor: "pointer" }}
            onClick={() => {
              setShowForm(true);
              setSelectedRow(item);
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" mountOnEnter unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography fontWeight="bold" fontSize={16} px={2} pt={1}>
                Sentence Details
              </Typography>
              <Typography flex={1} px={2}>
                {boldOnSearch(item?.SENTENCE_DETAILS, searchValue)}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CollapsibleRow.propTypes = {
  item: PropTypes.object,
  setShowForm: PropTypes.func.isRequired,
  setSelectedRow: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  searchValue: PropTypes.string,
};

const getFilterOptions = (list = [], key = "") => {
  if (!list?.length || !key) return;
  list = list?.filter((i) => i?.[key]);
  return (
    uniqBy(list, key)?.map((item) => ({
      name: item?.[key],
      value: item?.[key]?.trim()?.toLowerCase(),
    })) ?? []
  );
};

const RegulationDocumentDialog = ({ regulationID, toggleDialog, isOpen }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [tabValue, setTabValue] = useState("1");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedControlRow, setSelectedControlRow] = useState();
  const [showForm, setShowForm] = useState(false);
  const [documentRows, setDocumentRows] = useState([]);
  const [filteredDocumentRows, setFilteredDocumentRows] = useState([]);
  const [summaryFilterParameter, setSummaryFilterParameter] = useState(
    summaryInitialFilterParams
  );
  const [controlFilterParameter, setControlFilterParameter] = useState(
    controlLevelInitialFilterParams
  );

  const EVIDENCE_SHORTNAME_OPTIONS =
    uniqBy(documentRows ?? [], "EVIDENCE_TYPE_ID") ?? [];

  const filterOptions = useMemo(() => {
    return {
      article: getFilterOptions(rows, "ARTICLE") ?? [],
      chapter: getFilterOptions(rows, "CHAPTER") ?? [],
      section: getFilterOptions(rows, "SECTION") ?? [],
      docRecommendation:
        getFilterOptions(rows, "DOCUMENT_RECOMMENDATION") ?? [],
      controlArticle: getFilterOptions(documentRows, "ARTICLE") ?? [],
      controlChapter: getFilterOptions(documentRows, "CHAPTER") ?? [],
      controlDocRecommendation:
        getFilterOptions(documentRows, "DOCUMENT_RECOMMENDATION") ?? [],
      controlActive: [
        { name: "ACTIVE", value: true },
        { name: "INACTIVE", value: false },
      ],
      eviShortName: getFilterOptions(documentRows, "EVIDENCE_SHORTNAME") ?? [],
    };
  }, [rows, documentRows]);

  const customerID = sessionService.getCustomerId();
  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };

  const [pageCount, setPageCount] = useState(calculatePageCount(rows));

  const handleTabValueChange = (event, value) => {
    setShowForm(false);
    setPage(0);
    setTabValue(value);
  };

  const validationSchema = Yup.object().shape({
    // ARTICLE: Yup.string().required("Article Name is Required"),
    // CHAPTER: Yup.string().required("Chapter Name is Required"),
    DOCUMENT_RECOMMENDATION: Yup.string().max(100).nullable(),
    // SECTION: Yup.string().required("Section Name is Required"),
    // SENTENCE_DETAILS: Yup.string().required(
    //   "Sentence Details Name is Required"
    // ),
  });

  const controlValidationSchema = Yup.object().shape({
    EVIDENCE_SHORTNAME: Yup.string()
      .max(100)
      .nullable()
      .matches(/^[\w\s]+$/, "No Special Characters"),
    DOCUMENT_RECOMMENDATION: Yup.string()
      .max(100)
      .nullable()
      .matches(/^[\w\s]+$/, "No Special Characters"),
  });

  const formFields = [
    // {
    //   fieldName: "ARTICLE",
    //   label: "Article",
    // },
    // {
    //   fieldName: "CHAPTER",
    //   label: "Chapter",
    // },
    {
      fieldName: "DOCUMENT_RECOMMENDATION",
      label: "Document Recommendation",
    },
    // {
    //   fieldName: "SECTION",
    //   label: "Section",
    // },
    // {
    //   fieldName: "SENTENCE_DETAILS",
    //   label: "Sentence Details",
    // },
  ];

  const controlFormFields = [
    {
      fieldName: "DOCUMENT_RECOMMENDATION",
      label: "Document Recommendation",
    },
    // {
    //   fieldName: "EVIDENCE_TYPE_ID",
    //   label: "Evidence ShortName",
    // },
  ];

  const fetchList = async () => {
    setIsLoading(true);
    if (tabValue === "1") {
      const responseSummaryDocuments =
        await RegulationServices.getSummaryDocumentsForRegulation(
          regulationID,
          customerID
        );

      if (responseSummaryDocuments.status === 200) {
        setRows(responseSummaryDocuments["#result-set-1"]);
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    } else if (tabValue === "2") {
      const responseDocuments =
        await RegulationServices.getDocumentsForRegulation(regulationID, customerID);
      if (responseDocuments.status === 200) {
        setDocumentRows(responseDocuments["#result-set-1"]);
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isOpen) return;
    // prevent call if already has data
    if (tabValue === "1" && rows?.length) return;
    if (tabValue === "2" && documentRows?.length) return;
    fetchList();
  }, [isOpen, tabValue]);

  useEffect(() => {
    let tempRows;
    if (tabValue === "1") {
      tempRows = [...rows];
      if (summaryFilterParameter["article"] !== "") {
        tempRows = tempRows.filter(
          (item) =>
            item["ARTICLE"].trim()?.toLowerCase() ==
            summaryFilterParameter["article"]
        );
      }
      if (summaryFilterParameter["chapter"] !== "") {
        tempRows = tempRows.filter(
          (item) =>
            item["CHAPTER"].trim()?.toLowerCase() ==
            summaryFilterParameter["chapter"]
        );
      }
      if (summaryFilterParameter["docRecommendation"] !== "") {
        tempRows = tempRows.filter(
          (item) =>
            item["DOCUMENT_RECOMMENDATION"].trim()?.toLowerCase() ==
            summaryFilterParameter["docRecommendation"]
        );
      }
      if (summaryFilterParameter["section"] !== "") {
        tempRows = tempRows.filter(
          (item) =>
            item["SECTION"].trim()?.toLowerCase() ==
            summaryFilterParameter["section"]
        );
      }
      if (summaryFilterParameter["sentDetails"] !== "") {
        tempRows = tempRows.filter((item) =>
          item["SENTENCE_DETAILS"]
            .toLowerCase()
            .includes(
              summaryFilterParameter?.["sentDetails"]?.toLowerCase()?.trim()
            )
        );
      }

      setFilteredRows(
        tempRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
      );
    } else if (tabValue === "2") {
      tempRows = [...documentRows];
      if (controlFilterParameter["article"] !== "") {
        tempRows = tempRows.filter(
          (item) =>
            item["ARTICLE"].trim()?.toLowerCase() ==
            controlFilterParameter["article"]
        );
      }
      if (controlFilterParameter["chapter"] !== "") {
        tempRows = tempRows.filter(
          (item) =>
            item["CHAPTER"].trim()?.toLowerCase() ==
            controlFilterParameter["chapter"]
        );
      }
      if (controlFilterParameter["docRecommendation"] !== "") {
        tempRows = tempRows.filter(
          (item) =>
            item["DOCUMENT_RECOMMENDATION"].trim()?.toLowerCase() ==
            controlFilterParameter["docRecommendation"]
        );
      }
      if (controlFilterParameter["active"] !== "") {
        tempRows = tempRows.filter(
          (item) => item["ACTIVE"] == controlFilterParameter["active"]
        );
      }
      if (controlFilterParameter["sentDetails"] !== "") {
        tempRows = tempRows.filter((item) =>
          item["SENTENCE_DETAILS"]
            .toLowerCase()
            .includes(
              controlFilterParameter?.["sentDetails"]?.toLowerCase()?.trim()
            )
        );
      }
      if (controlFilterParameter["eviShortName"] !== "") {
        tempRows = tempRows.filter(
          (item) =>
            item["EVIDENCE_SHORTNAME"].trim()?.toLowerCase() ==
            controlFilterParameter["eviShortName"]
        );
      }
      setFilteredDocumentRows(
        tempRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
      );
    }
    setPageCount(calculatePageCount(tempRows));
  }, [
    page,
    rows,
    documentRows,
    summaryFilterParameter,
    controlFilterParameter,
  ]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const onSummaryFilterChange = (fieldName, e) => {
    setSummaryFilterParameter((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value,
    }));
  };

  const onControlFilterChange = (fieldName, e) => {
    setControlFilterParameter((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value,
    }));
  };

  const updateRegDocForSummary = async (values) => {
    await RegulationServices.updateRegulationDocumentSummaryLevel(
      customerID,
      values.REGULATION_ID,
      selectedRow.DOCUMENT_RECOMMENDATION,
      values.DOCUMENT_RECOMMENDATION
    )
      .then((response) => {
        // console.log(response);
        // if (response?.status !== 200) throw new Error("Something went wrong");
        fetchList();
        dispatch(alertActions.success("Document updated sucessfully"));
      })
      .catch((err) => dispatch(alertActions.error(err?.toString())));
    setShowForm(false);
  };

  const updateRegDocForControl = async (values) => {
    await RegulationServices.updateRegulationDocumentControlLevel(
      customerID,
      values.REGULATION_ID,
      selectedControlRow.DOCUMENT_RECOMMENDATION,
      selectedControlRow.SENTENCE_DETAILS,
      values.DOCUMENT_RECOMMENDATION,
      selectedControlRow.EVIDENCE_TYPE_ID,
      values.EVIDENCE_TYPE_ID
    )
      .then((response) => {
        // console.log(response);
        // if (response?.status !== 200) throw new Error("Something went wrong");
        fetchList();
        dispatch(alertActions.success("Document updated sucessfully"));
      })
      .catch((err) => dispatch(alertActions.error(err?.toString())));

    setShowForm(false);
  };

  return (
    <Dialog open={isOpen} onClose={toggleDialog} maxWidth="xl" fullWidth>
      <DialogTitle id="dialog-title">
        <Tabs
          value={tabValue}
          onChange={handleTabValueChange}
          aria-label="Document Tabs"
        >
          <Tab label={langConstant.SUMMARY} value="1" />
          <Tab label={langConstant.CONTROL_LEVEL} value="2" />
        </Tabs>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={toggleDialog}
        style={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <CloseIcon />
      </IconButton>
      {tabValue === "1" && (
        <Button
          onClick={() => {
            setSelectedRow();
            setShowForm(true);
          }}
          title="Add"
          color="primary"
          variant="contained"
          style={{
            position: "absolute",
            right: 60,
            top: 22,
          }}
          disabled
        >
          Add Document
        </Button>
      )}
      <DialogContent sx={{ py: 2 }}>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {tabValue === "1" && (
          <>
            <Box mb={2} mt={3}>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={2}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="searchText"
                        label=""
                        name="searchText"
                        placeholder="Filter Table"
                        value={summaryFilterParameter["sentDetails"]}
                        onChange={(e) =>
                          setSummaryFilterParameter((prevState) => ({
                            ...prevState,
                            sentDetails: e.target.value,
                          }))
                        }
                        InputProps={{
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <FilterDropdown
                        options={filterOptions?.article}
                        label={"Article"}
                        fieldName={"article"}
                        value={summaryFilterParameter["article"]}
                        onChange={onSummaryFilterChange}
                        icon={true}
                      />
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <FilterDropdown
                        options={filterOptions?.chapter}
                        label={"Chapter"}
                        fieldName={"chapter"}
                        value={summaryFilterParameter["chapter"]}
                        onChange={onSummaryFilterChange}
                        icon={true}
                      />
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <FilterDropdown
                        options={filterOptions?.section}
                        label={"Section"}
                        fieldName={"section"}
                        value={summaryFilterParameter["section"]}
                        onChange={onSummaryFilterChange}
                        icon={true}
                      />
                    </Grid>
                    <Grid item xs={8} md={3}>
                      <FilterDropdown
                        options={filterOptions?.docRecommendation}
                        label={"Document Recommendation"}
                        fieldName={"docRecommendation"}
                        value={summaryFilterParameter["docRecommendation"]}
                        onChange={onSummaryFilterChange}
                        icon={true}
                      />
                    </Grid>
                    <Fade
                      in={
                        !isEqual(
                          summaryFilterParameter,
                          summaryInitialFilterParams
                        )
                      }
                    >
                      <Grid
                        item
                        xs={8}
                        md
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            color: "red",
                            fontSize: "12px",
                          }}
                          onClick={() =>
                            setSummaryFilterParameter(
                              summaryInitialFilterParams
                            )
                          }
                        >
                          Reset All
                        </Typography>
                      </Grid>
                    </Fade>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: "flex", gap: 5 }}>
              <TableContainer
                component={Paper}
                style={{
                  flex: showForm ? "60%" : "100%",
                  transition: "all ease-in-out .3s",
                  maxHeight: "50vh",
                }}
              >
                <Table stickyHeader className="data-table">
                  <TableHead>
                    <TableRow>
                      {summaryHeaders.map((item) => (
                        <>
                          <TableCell>{item}</TableCell>
                        </>
                      ))}
                      <TableCell></TableCell>
                      {/* empty cell to complete table header */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows?.map((item, index) => (
                      <CollapsibleRow
                        item={item}
                        key={index}
                        setShowForm={setShowForm}
                        setSelectedRow={setSelectedRow}
                        searchValue={summaryFilterParameter["sentDetails"]}
                        // isSelected={
                        //   showForm &&
                        //   index ===
                        //     filteredRows?.findIndex(
                        //       (i) =>
                        //         i?.SENTENCE_DETAILS === item?.SENTENCE_DETAILS
                        //     )
                        // }
                      />
                    ))}
                    {filteredRows?.length === 0 && (
                      <TableRow style={{ height: 53 }}>
                        <TableCell style={{ textAlign: "center" }}>
                          {langConstant.NO_RECORD_FOUND}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {showForm && (
                <Formik
                  enableReinitialize={true}
                  initialValues={selectedRow}
                  validationSchema={validationSchema}
                  onSubmit={updateRegDocForSummary}
                >
                  {({ setFieldValue }) => (
                    <Form style={{ flex: "auto" }}>
                      <Typography variant="h5" gutterBottom>
                        {selectedRow ? "Edit Document" : "Add New Document"}
                      </Typography>
                      {formFields.map((formField, index) => {
                        const handlechange = (data) =>
                          setFieldValue(formField.fieldName, data.value);
                        return (
                          <Grid container key={index}>
                            <Grid item xs={12} md={12}>
                              <Grid container>
                                <Grid item xs={11}>
                                  <Typography>{formField.label}</Typography>
                                  <Field name={formField.fieldName}>
                                    {({ field: { value } }) =>
                                      formField.format ? (
                                        formField.format(value, handlechange)
                                      ) : (
                                        <TextField
                                          value={value}
                                          fullWidth
                                          variant="outlined"
                                          onChange={(e) =>
                                            setFieldValue(
                                              formField.fieldName,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )
                                    }
                                  </Field>
                                  <ErrorMessage name={formField.fieldName}>
                                    {(msg) => (
                                      <Typography style={{ color: "red" }}>
                                        {msg}
                                      </Typography>
                                    )}
                                  </ErrorMessage>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          m: 2,
                          gap: 1,
                        }}
                      >
                        <Button
                          title="Cancel"
                          onClick={() => setShowForm(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          title="Save"
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
            {pageCount > 1 && (
              <Box my={2}>
                <Grid container sx={{ display: "flex", justifyContent: "end" }}>
                  <AppPagination
                    colSpan={3}
                    count={pageCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Grid>
              </Box>
            )}
          </>
        )}
        {tabValue === "2" && (
          <>
            <Box>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={8} md={4}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="searchText"
                        label=""
                        name="searchText"
                        placeholder="Filter Table"
                        value={controlFilterParameter["sentDetails"]}
                        onChange={(e) =>
                          setControlFilterParameter((prevState) => ({
                            ...prevState,
                            sentDetails: e.target.value,
                          }))
                        }
                        InputProps={{
                          autoComplete: "off",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <FilterDropdown
                        options={filterOptions?.controlArticle}
                        label={"Article"}
                        fieldName={"article"}
                        value={controlFilterParameter["article"]}
                        onChange={onControlFilterChange}
                        icon={true}
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <FilterDropdown
                        options={filterOptions?.controlChapter}
                        label={"Chapter"}
                        fieldName={"chapter"}
                        value={controlFilterParameter["chapter"]}
                        onChange={onControlFilterChange}
                        icon={true}
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <FilterDropdown
                        options={filterOptions?.controlDocRecommendation}
                        label={"Document Recommendation"}
                        fieldName={"docRecommendation"}
                        value={controlFilterParameter["docRecommendation"]}
                        onChange={onControlFilterChange}
                        icon={true}
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <FilterDropdown
                        options={filterOptions?.eviShortName}
                        label="Evidence Shortname"
                        fieldName={"eviShortName"}
                        value={controlFilterParameter["eviShortName"]}
                        onChange={onControlFilterChange}
                        icon={true}
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <FilterDropdown
                        options={filterOptions?.controlActive}
                        label={"Active"}
                        fieldName={"active"}
                        value={controlFilterParameter["active"]}
                        onChange={onControlFilterChange}
                        icon={true}
                      />
                    </Grid>
                    <Fade
                      in={
                        !isEqual(
                          controlFilterParameter,
                          controlLevelInitialFilterParams
                        )
                      }
                    >
                      <Grid
                        item
                        xs={8}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            color: "red",
                            fontSize: "12px",
                          }}
                          onClick={() =>
                            setControlFilterParameter(
                              controlLevelInitialFilterParams
                            )
                          }
                        >
                          Reset All
                        </Typography>
                      </Grid>
                    </Fade>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: "flex", gap: 5 }}>
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "50vh",
                  flex: showForm ? "60%" : "100%",
                  transition: "all ease-in-out .3s",
                }}
              >
                <Table stickyHeader className="data-table">
                  <TableHead>
                    <TableRow>
                      {documentHeaders.map((item) => (
                        <>
                          <TableCell>{item}</TableCell>
                        </>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDocumentRows?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item?.ARTICLE}</TableCell>
                        <TableCell>{item?.CHAPTER}</TableCell>
                        <TableCell>{item?.DOCUMENT_RECOMMENDATION}</TableCell>
                        <TableCell>{item?.EVIDENCE_SHORTNAME}</TableCell>
                        <TableCell>
                          {boldOnSearch(
                            item?.SENTENCE_DETAILS,
                            controlFilterParameter["sentDetails"]
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.ACTIVE && item.ACTIVE === 1 ? (
                            <Chip label="ACTIVE" color="success" />
                          ) : (
                            <Chip label="INACTIVE" />
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.CREATE_DATE?.split("T")[0]}
                        </TableCell>
                        <TableCell>
                          <EditIcon
                            sx={{ color: "red", cursor: "pointer" }}
                            onClick={() => {
                              setShowForm(true);
                              setSelectedControlRow(item);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {filteredDocumentRows?.length === 0 && (
                      <TableRow style={{ height: 53 }}>
                        <TableCell style={{ textAlign: "center" }}>
                          {langConstant.NO_RECORD_FOUND}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {showForm && (
                <Formik
                  enableReinitialize={true}
                  initialValues={selectedControlRow}
                  validationSchema={controlValidationSchema}
                  onSubmit={updateRegDocForControl}
                >
                  {({ setFieldValue, resetForm, values }) => (
                    <Form
                      style={{
                        gap: 2,
                        display: "flex",
                        flexDirection: "column",
                        flex: "auto",
                      }}
                    >
                      <Typography variant="h5" gutterBottom>
                        Edit Document
                      </Typography>
                      <Box
                        sx={{
                          gap: 2,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {controlFormFields.map((formField, index) => {
                          const handlechange = (data) =>
                            setFieldValue(formField.fieldName, data.value);
                          return (
                            <Box key={index}>
                              <Typography>{formField.label}</Typography>
                              <Field name={formField.fieldName}>
                                {({ field: { value } }) =>
                                  formField.format ? (
                                    formField.format(value, handlechange)
                                  ) : (
                                    <TextField
                                      value={value}
                                      fullWidth
                                      variant="outlined"
                                      onChange={(e) =>
                                        setFieldValue(
                                          formField.fieldName,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )
                                }
                              </Field>
                              <ErrorMessage name={formField.fieldName}>
                                {(msg) => (
                                  <Typography style={{ color: "red" }}>
                                    {msg}
                                  </Typography>
                                )}
                              </ErrorMessage>
                            </Box>
                          );
                        })}

                        <Box>
                          <Typography>Evidence Shortname</Typography>
                          <Select
                            options={EVIDENCE_SHORTNAME_OPTIONS}
                            getOptionLabel={(option) =>
                              option?.EVIDENCE_SHORTNAME
                            }
                            getOptionValue={(option) =>
                              option?.EVIDENCE_TYPE_ID
                            }
                            isClearable
                            isSearchable
                            placeholder="Evidence Shortname"
                            defaultValue={EVIDENCE_SHORTNAME_OPTIONS?.find(
                              (i) =>
                                i?.EVIDENCE_TYPE_ID ===
                                selectedControlRow?.EVIDENCE_TYPE_ID
                            )}
                            onChange={(e) => {
                              if (e && "EVIDENCE_TYPE_ID" in e) {
                                setFieldValue(
                                  "EVIDENCE_TYPE_ID",
                                  e.EVIDENCE_TYPE_ID
                                );
                              } else {
                                setFieldValue("EVIDENCE_TYPE_ID", e);
                              }
                            }}
                            menuPortalTarget={document.body}
                          />

                          <ErrorMessage name="EVIDENCE_TYPE_ID">
                            {(msg) => (
                              <Typography style={{ color: "red" }}>
                                {msg}
                              </Typography>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          m: 2,
                          gap: 1,
                        }}
                      >
                        <Button
                          title="Cancel"
                          onClick={() => setShowForm(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          title="Save"
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
            {pageCount > 1 && (
              <Box my={2}>
                <Grid container sx={{ display: "flex", justifyContent: "end" }}>
                  <AppPagination
                    colSpan={3}
                    count={pageCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Grid>
              </Box>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

RegulationDocumentDialog.propTypes = {
  isOpen: PropTypes.bool,
  toggleDialog: PropTypes.func,
  regulationID: PropTypes.number,
};

export default RegulationDocumentDialog;
