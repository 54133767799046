/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography, Tooltip, Divider } from "@material-ui/core";
import KeyboardOutlinedIcon from "@material-ui/icons/KeyboardOutlined";
import FlashOnOutlined from "@material-ui/icons/FlashOnOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import LineStyleIcon from "@material-ui/icons/LineStyle";
import langConstant from "_lang";
import RulesBuilder from "_components/RulesBuilder";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";

const ListItem = ({ item = {}, viewIconClassName = "", setCurrentIndex }) => {
  const themeDetails = useSelector(getThemeDetails);
  const { id } = useParams();
  const history = useHistory();
  const [showRuleBuilder, setShowRuleBuilder] = useState(false);
  const handleCloseRuleBuilder = () => {
    setShowRuleBuilder(false);
  };
  return (
    <>
      <Grid item md={2} style={{ display: "flex" }}>
        <Box pl={2} style={{ margin: "2px 15px 0 0" }}>
          {item.RULE_FLAG ? (
            <FlashOnOutlined style={{ color: "#009646" }} />
          ) : (
            <KeyboardOutlinedIcon style={{ color: "#FF7284" }} />
          )}
        </Box>
        <Grid
          item
          style={{
            minWidth: "120px",
            fontWeight: "bold",
            opacity: "0.6",

            margin: "4px 0 0 0 ",
          }}
        >
          {item.REGULATOR_UNIQUE_REFERENCE} &nbsp; &nbsp;
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item md={7}>
        <Grid item style={{ margin: "4px 0 0 0 " }}>
          {item.QUESTION_TEXT}
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item md={1}>
        <Grid item style={{ margin: "4px 0 0 0 " }}>
          {item?.EVIDENCE_COUNT ? item.EVIDENCE_COUNT : 0}
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item md={1}>
        <Grid item style={{ margin: "4px 0 0 0 " }}>
          {item?.POLICY_SENTENCE_COUNT ? item.POLICY_SENTENCE_COUNT : 0}
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item md>
        <Box align="center">
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title="View" placement="top">
                <Box
                  className={viewIconClassName}
                  style={{
                    backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                    cursor: "pointer",
                  }}
                >
                  <VisibilityIcon
                    onClick={() => {
                      // history.push(
                      //   `/manage-questions/${id}/${item.QUESTION_ID}`
                      // );
                      setCurrentIndex(item.QUESTION_ID);
                    }}
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      margin: "0 auto",
                    }}
                  />
                </Box>
              </Tooltip>
            </Grid>
            {/* <Grid item>
              <Tooltip
                title={langConstant.RULE_BUILDER_TOOLTIP}
                placement="top"
              >
                <Box
                  className={viewIconClassName}
                  style={{
                    backgroundColor: "#0e9439",
                    cursor: "pointer",
                  }}
                >
                  <LineStyleIcon
                    onClick={() => {
                      setShowRuleBuilder(true);
                    }}
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      margin: "0 auto",
                    }}
                  />
                </Box>
              </Tooltip>
              <RulesBuilder
                show={showRuleBuilder}
                handleCloseRuleBuilder={handleCloseRuleBuilder}
                questionID={item.QUESTION_ID}
              />
            </Grid> */}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};
ListItem.propTypes = {
  item: PropTypes.object,
  viewIconClassName: PropTypes.string,
  setCurrentIndex: PropTypes.func,
};
export default ListItem;
