import React from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import langConstant from "_lang";
import sessionService from "_services/session.service";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import Tooltip from '@mui/material/Tooltip';


const useStyles = makeStyles(() => ({
    contantContaxMenu:{
        padding: "5px 0px 0px 0px",
        '& .MuiListItemIcon-root':{
            minWidth:'unset !important',
        }
    }
}))

const ContextMenu = ({
    anchorEl, 
    open, 
    handleMenuClose, 
    updateEvidenceStatus,
}) => {

    const classes = useStyles();

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
            },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuList divider={true} style={{textAlign: "center", justifyContent: "space-between", display: "flex", padding: "0px 0px"}} className={classes.contantContaxMenu}>
                <Tooltip title={langConstant.ACCEPTED_SMALL}>
                    <MenuItem>
                        <ListItemIcon onClick={() => updateEvidenceStatus(1)}><CheckCircleIcon fontSize='small' color="success"/></ListItemIcon>
                    </MenuItem>
                </Tooltip>
                <Tooltip title={langConstant.REJECTED}>
                    <MenuItem>
                        <ListItemIcon onClick={() => updateEvidenceStatus(2)}><CancelIcon fontSize='small' style={{color: "#af3939"}}/></ListItemIcon>
                    </MenuItem>
                </Tooltip>
                <Tooltip title={langConstant.PENDING_CLARIFICATION}>
                    <MenuItem>
                        <ListItemIcon onClick={() => updateEvidenceStatus(3)}><InfoIcon fontSize='small' style={{color: "#b7913c"}}/></ListItemIcon>
                    </MenuItem>
                </Tooltip>
            </MenuList>
        </Menu>
    )
}

ContextMenu.propTypes = {
    anchorEl: PropTypes.any,
    open: PropTypes.bool,
    handleMenuClose: PropTypes.func,
    updateEvidenceStatus: PropTypes.func
}

export default ContextMenu;