import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Select from "react-select";
import UserServices from "./services";
import { alertActions } from "_actions";

const EntitlementDropdown = (props) => {
    
    const [entitlementOptions, setEntitlementOptions] = useState([])
    const dispatch = useDispatch();

    const getEntitlementList = async () => {
        const response = await UserServices.getEntitlementsDetails()
        const tempEntitlementList = []   
        if(response.isSuccess){
            response.data.forEach(element => {
                tempEntitlementList.push({value: element.ENTITLEMENT_ID, label: element.ENTITLEMENT_NAME})
            });
        } else {
            dispatch(alertActions.error(response.message))
        }
        setEntitlementOptions(tempEntitlementList)
    }

    useEffect(() => {
        (async () => {
            await getEntitlementList();
        })()
    }, []);
  
    return (
        <Select 
            value={entitlementOptions.find((entitlement) => entitlement.value == props.value)} 
            options={entitlementOptions} 
            onChange={props.onChange} placeholder={props.placeholder}
        />
    );
};

EntitlementDropdown.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.number,
};

export default EntitlementDropdown;