/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Avatar,
  Typography,
  TextField,
  MenuItem,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import _ from "lodash";
import FilterDropdown from "_components/FilterDropdown";
import AppPagination from "_components/AppPagination";
import langConstant from "_lang";
import AssessmentListItem from "./AssessmentListItem";
import PropTypes from "prop-types";
import rootStyles from "rootStyles";
import { sortByPriority } from "_helpers/utils";
const useStyles = makeStyles((theme) => ({
  teamMemberDropdown: { display: "flex", alignItems: "center" },
  menuItem: {
    paddingTop: "4px",
    paddingBottom: "4px",
    height: "32px",
    display: "flex",
    alignItems: "center",
  },
}));
const ItemTable = ({ data = [] }) => {
  const classes = useStyles();
  const rootClasses = rootStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredRows, setFilteredRows] = useState([]);
  const initialFilterObj = { TEAM_MEMBER: "", STATUS: "" };
  const [filterBy, setFilterBy] = useState(initialFilterObj);
  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(0);

  const handleChangePage = (event) => {
    setPage(event);
  };
  const onFilterChange = (fieldName, e) => {
    const filterValue = e.target.value;

    const filterObj = { ...filterBy, [fieldName]: filterValue };

    setFilterBy(filterObj);

    let filtered_array = sortByPriority(
      data.filter((item) => {
        if (item.REGULATION_ID) {
          if (filterObj["TEAM_MEMBER"] && filterObj["STATUS"]) {
            return (
              filterObj["TEAM_MEMBER"] == item["USER_ID"] &&
              filterObj["STATUS"] == item["STATUS"]
            );
          } else if (filterObj["TEAM_MEMBER"]) {
            return filterObj["TEAM_MEMBER"] == item["USER_ID"];
          } else if (filterObj["STATUS"]) {
            return filterObj["STATUS"] == item["STATUS"];
          } else {
            return true;
          }
        } else {
          return false;
        }
      }),
      "PRIORITY"
    );

    setFilteredRows(filtered_array);
    setPageCount(calculatePageCount(filtered_array));
    setPage(0);
  };
  const createFilterDropdown = (fieldName, label) => {
    let options = [];
    if (fieldName == "TEAM_MEMBER") {
      options = _.orderBy(_.uniqBy(data, "USER_ID"), "FIRST_NAME");
      if (options.length > 0 && options[0]) {
        options = options
          .filter((item) => {
            return item.USER_ID && item;
          })
          .map((item) => {
            return {
              icon: item.IMAGE_URL,
              value: item.USER_ID,
              name: item.FIRST_NAME + " " + item.LAST_NAME,
            };
          });
      }
    }
    if (fieldName == "STATUS") {
      options = _.orderBy(_.uniqBy(data, "STATUS"), "STATUS");
      if (options.length > 0 && options[0]) {
        options = options.map((item) => {
          return {
            value: item.STATUS,
            name: item.DISPLAY,
          };
        });
      }
    }

    if (options.length > 0) {
      if (fieldName == "TEAM_MEMBER") {
        return (
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            select
            id={"TEAM_MEMBER"}
            label=""
            name={"TEAM_MEMBER"}
            SelectProps={{
              displayEmpty: true,
              inputProps: { className: classes.menuItem },
            }}
            value={filterBy[fieldName]}
            onChange={(e) => {
              onFilterChange("TEAM_MEMBER", e);
            }}
          >
            <MenuItem
              key={"TEAM_MEMBER"}
              value={""}
              className={classes.menuItem}
            >
              <Typography variant="inherit">{label}</Typography>
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option.value + "TEAM_MEMBER"}
                value={option.value}
                className={classes.menuItem}
              >
                <ListItemIcon style={{ minWidth: "30px" }}>
                  <Avatar
                    src={option.icon}
                    alt={option.name}
                    className={rootClasses.smallAvatar}
                    style={{ width: "24px", height: "24px" }}
                  ></Avatar>
                </ListItemIcon>
                <Typography variant="inherit">{option.name}</Typography>
              </MenuItem>
            ))}
          </TextField>
        );
      } else {
        return (
          <>
            <FilterDropdown
              options={options}
              label={label}
              fieldName={fieldName}
              value={filterBy[fieldName]}
              onChange={onFilterChange}
              icon={false}
            />
          </>
        );
      }
    }
  };
  const resetFilter = () => {
    const dataFinal = sortByPriority(
      _.filter(data, (item) => {
        return item.REGULATION_ID;
      }),
      "PRIORITY"
    );
    setFilterBy(initialFilterObj);
    setFilteredRows(dataFinal);
    setPageCount(calculatePageCount(dataFinal));
    setPage(0);
  };
  useEffect(() => {
    const dataFinal = sortByPriority(
      _.filter(data, (item) => {
        return item.REGULATION_ID;
      }),
      "PRIORITY"
    );
    setFilteredRows(dataFinal);
    setPageCount(calculatePageCount(dataFinal));
    setPage(0);
  }, [data]);
  return (
    <Box>
      <Box mb={2} mt={4}>
        <Grid container spacing={1}>
          <Grid item md={3}>
            {createFilterDropdown(
              "TEAM_MEMBER",
              langConstant.SELECT_TEAM_MEMBER
            )}
          </Grid>

          <Grid item md={3}>
            {createFilterDropdown("STATUS", langConstant.SELECT_STATUS)}
          </Grid>
          <Grid item md={2}>
            <Box my={1}>
              {(filterBy["TEAM_MEMBER"] || filterBy["STATUS"]) && (
                <span onClick={resetFilter} className="linkIcon">
                  Reset All
                </span>
              )}
            </Box>
          </Grid>

          <Grid item md={4}>
            <Box textAlign="right">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                to={"/manage-assignments/assign-assignments"}
                startIcon={<AddIcon />}
                component={Link}
              >
                {langConstant.ASSIGN_ASSESSMENT}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={2}
        ml={2}
        mb={1}
        style={{
          marginRight: "60px",
          color: "rgba(142, 142, 142, 1)",
          fontWeight: 500,
        }}
      >
        <Grid container spacing={1} alignItems="stretch">
          {" "}
          <Grid item md={2}>
            <Box
              className={
                rootClasses.collapsibleHead +
                " " +
                rootClasses.collapsibleHeadFirst
              }
            >
              {langConstant.ASSESSMENT_NAME} - {langConstant.CONTROL_OWNER}
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className={rootClasses.collapsibleHead}>
              {langConstant.DATE_INITIATED_FROM} -{" "}
              {langConstant.SUBMISSION_DATE_TO}
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box className={rootClasses.collapsibleHead}>
              {langConstant.EFFORT_LOGGED_EXPECTED}
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box className={rootClasses.collapsibleHead}>
              {langConstant.SUBMISSION_DEADLINE_TO_REGULATOR}
            </Box>
          </Grid>
          <Grid
            item
            md={1}
            style={{ maxWidth: "10.333333%", flexBasis: "10.333333%" }}
          >
            <Box className={rootClasses.collapsibleHead}>
              {langConstant.DAYS_LEFT_FOR_SUBMISSION}
            </Box>
          </Grid>
          <Grid
            item
            md={2}
            style={{ maxWidth: "14.666667%", flexBasis: "14.666667%" }}
          >
            <Box
              className={
                rootClasses.collapsibleHead +
                " " +
                rootClasses.collapsibleHeadLast
              }
            >
              {langConstant.STATUS}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {(rowsPerPage > 0
        ? filteredRows.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : filteredRows
      ).map((item, index) => {
        return <AssessmentListItem key={index} data={item} />;
      })}
      {filteredRows.length == 0 && (
        <Box my={3} display="flex" justifyContent="center" alignItems="center">
          {langConstant.NO_RECORD_FOUND}
        </Box>
      )}
      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={() => {}}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

ItemTable.propTypes = {
  data: PropTypes.array,
};
export default ItemTable;
