import { Switch, Typography, Button } from "@material-ui/core";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import Select from "react-select";
import langConstant from "_lang";
import PropTypes from "prop-types";
import ProfileServices from "../services";
import { alertActions, loadingActions } from "_actions";
import { useDispatch } from "react-redux";
import PWDPopUp from "./PWDPopUp";
import sessionService from "_services/session.service";

const SecurityComponent = ({
  twoFADetail,
  setTwoFADetail,
  user_ID,
  userData,
  setUserData,
  isTeamView,
}) => {
  const classes = useStyles();

  const [enable2FA, setEnable2FA] = useState(false);
  const [showPwdPopUp, setShowPwdPopUp] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [authenticationType, setAuthenticationType] = useState("EMAIL OTP");
  const [enableQRService, setEnableQRService] = useState(false);
  const [QRCode, setQRCode] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isTeamView && userData.USER_QR_CODE) {
      dispatch(
        ProfileServices.generateQRFromKey(userData.USER_QR_CODE, setQRCode)
      );
    }
  }, []);

  const authenticationOptions = [
    { value: "EMAIL OTP", label: "2FA - OTP code via Email" },
    { value: "QR", label: "2FA - OTP code via QR code" },
    // {value: 2, label: "2FA - Microsoft Authenticator"},
    // {value: 3, label: "2FA - Authy"},
    // {value: 4, label: "2FA - Google Authenticator"},
  ];

  const sendData = async () => {
    if (authenticationType.length > 0) {
      dispatch(loadingActions.start());
      const res = await ProfileServices.update2FA(
        user_ID,
        enable2FA,
        authenticationType,
        ""
      );
      if (res.status === 200) {
        setTwoFADetail([
          {
            "2FA_ENABLED": enable2FA,
            "2FA_SERVICE_NAME": authenticationType,
            "2FA_SERVICE_URL": "",
          },
        ]);
        if (authenticationType == "QR" && enable2FA) {
          setEnableQRService(true);
        } else {
          setEnableQRService(false);
        }
        dispatch(alertActions.success("2FA Updated Successfully!"));
      } else {
        dispatch(alertActions.error("Something went wrong"));
      }
      dispatch(loadingActions.end());
    }
  };

  useEffect(() => {
    if (twoFADetail && twoFADetail.length > 0) {
      if (twoFADetail[0]["2FA_ENABLED"]) {
        setEnable2FA(true);
        setAuthenticationType(twoFADetail[0]["2FA_SERVICE_NAME"]);
        if (
          twoFADetail[0]["2FA_ENABLED"] &&
          twoFADetail[0]["2FA_SERVICE_NAME"] == "QR"
        ) {
          setEnableQRService(true);
        }
      } else {
        setEnable2FA(false);
      }
    } else {
      setEnable2FA(false);
    }
  }, [twoFADetail]);

  return (
    <Grid className={classes.notificationWrapper}>
      <Grid>
        <Grid className={classes.authWrapper}>
          <Typography className={classes.inputLabel}>
            {langConstant.ENABLE_2FA_AUTHENTICATION}
          </Typography>
          <Switch
            className={classes.profileMDTopHedaerToggleBtn}
            checked={enable2FA}
            onClick={(event) => setEnable2FA(event.target.checked)}
          />
        </Grid>
        <Grid className={classes.authWrapper}>
          <Typography className={classes.inputLabel}>
            {langConstant.AUTHENTICATION_TYPE}
          </Typography>
          <Select
            className={classes.profileMDBasicSelect}
            options={authenticationOptions}
            placeholder="Select Authentication Type"
            value={authenticationOptions.find(
              (auth) => auth.value === authenticationType
            )}
            onChange={(auth) => setAuthenticationType(auth.value)}
            isDisabled={!enable2FA}
          />
        </Grid>
        <Grid className={classes.profileButtonWrapper}>
          <Button color="primary" onClick={sendData} variant="contained">
            {langConstant.SAVE}
          </Button>
        </Grid>
      </Grid>
      {!isTeamView && enableQRService && (
        <Grid>
          <Grid>
            {QRCode ? (
              <Grid>
                <img src={QRCode} style={{ width: "150px", height: "150px" }} />
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "grey",
                    fontWeight: "bold",
                    marginTop: "5px",
                  }}
                >
                  {langConstant.SCAN_THIS_QR_FROM_AUTHENTICATOR_APP}
                </Typography>
              </Grid>
            ) : (
              <Grid>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  {langConstant.CLICK_BUTTON_TO_GENERATE_QR_CODE}
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setShowPwdPopUp(true)}
                >
                  {langConstant.GENERATE_QR_CODE}
                </Button>
              </Grid>
            )}
          </Grid>
          <PWDPopUp
            showPwdPopUp={showPwdPopUp}
            setShowPwdPopUp={setShowPwdPopUp}
            userPassword={userPassword}
            setUserPassword={setUserPassword}
            setQRCode={setQRCode}
          />
        </Grid>
      )}
    </Grid>
  );
};

SecurityComponent.propTypes = {
  twoFADetail: PropTypes.array,
  setTwoFADetail: PropTypes.func,
  user_ID: PropTypes.number,
  userData: PropTypes.object,
  setUserData: PropTypes.func,
  isTeamView: PropTypes.bool,
};

export default SecurityComponent;
