import React from "react";
import { Box, Typography } from "@material-ui/core";
import sessionService from "_services/session.service";

const Page404 = () => {
  const isLoggedIn = sessionService.getItem("jwtToken");
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: isLoggedIn ? "calc(100% - 135px)" : "100vh",
        width: "100%",
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">Page not found</Typography>
    </Box>
  );
};

export default Page404;
