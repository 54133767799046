import React from 'react'
import PropTypes from "prop-types";
import { Avatar, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import moment from 'moment'

const useStyles = makeStyles(() => ({
    remarkPBox: {
        display:"flex",
        flexWrap:"wrap",
        alignItems:"flex-start",
        marginBottom: "25px",
    },
    remarkPBoxEven:{
        flexDirection: "row-reverse",
    },
    remakPCN: {
        width:"calc(100% - 50px)",
        marginLeft:"13px",
        backgroundColor:"#f8fcfd",
        borderRadius:"7px",
        padding:"10px",
        boxShadow:"0px 2px 6px lightgrey",
        position:"relative",
    },
    remakPCNEven:{
        marginLeft:"0px",
        marginRight:"13px",
        backgroundColor:"#efefef",
    },
    remarkPImg:{
        width:"32px",
        height:"32px",
        borderRadius:"50%",
        overflow:"hidden",
    },
    remakPComment:{
        width:"calc(100% - 104px)",
        paddingRight:"10px",
    },
    remakPCommentEven:{
        marginLeft:"auto",
        paddingRight:"0px",
        paddingLeft:"10px",
        textAlign:"right",
    },
    remakPTime:{
        fontSize:"10px",
        position:"absolute",
        bottom:"5px",
        right:"13px",
        color:"grey",   
    },
    remakPTimeEven:{
        right:"unset",
        left:"13px",
    },
}));

const RemarkPill = ({ remarkData, userID }) => {
    const classes = useStyles();
    let remarkComment = remarkData.COMMENT.replace("EvidenceID:","")
    remarkComment = remarkComment.substring(remarkComment.indexOf(":") + 1);
    let remarkDate = moment(remarkData.CREATE_DATE,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD h:mm a")
    return (
        <Grid className={userID == remarkData.USER_ID ? classes.remarkPBoxEven+ " " + classes.remarkPBox : classes.remarkPBox}>
            <Grid className={userID == remarkData.USER_ID ? classes.remarkPImgWrapEven + " " + classes.remarkPImgWrap : classes.remarkPImgWrap}>
                {remarkData.AVATAR_IMAGE ? 
                    <Avatar className={classes.remarkPImg} alt={remarkData.NAME} src={"data:image/png;base64," + window.atob(remarkData.AVATAR_IMAGE)}/> :
                    <Avatar className={classes.remarkPImg} >{remarkData.NAME[0].toUpperCase()}</Avatar>
                }
            </Grid>
            <div className={userID == remarkData.USER_ID ? classes.remakPCNEven + " " + classes.remakPCN : classes.remakPCN}>
                <Grid className={userID == remarkData.USER_ID ? classes.remakPCommentEven + " " + classes.remakPComment : classes.remakPComment}>
                    <span>{remarkComment}</span>
                </Grid>
                <Grid className={userID == remarkData.USER_ID ? classes.remakPTimeEven + " " + classes.remakPTime : classes.remakPTime}>
                    <span>{remarkDate}</span>
                </Grid>
            </div>
        </Grid>
    )
}

RemarkPill.propTypes = {
    remarkData: PropTypes.object,
    userID: PropTypes.number,
};

export default RemarkPill