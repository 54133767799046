// OP#3745 - Manage Questions - New Tab for Policy Linking on Manage Questions

import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import langConstant from "_lang";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Button
} from "@material-ui/core";
import PolicyMappingActions from "../actions";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { groupingOnDocumentTypeOrOwner } from "../../../Policy/PolicyExplorer/component/PolicyDataTable/utils";
import PolicyServices from "../../../Policy/PolicyExplorer/services";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import styles from "../styles";
import classNames from "classnames";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FilePresentRoundedIcon from "@mui/icons-material/FilePresentRounded";

const PolicyMappingDialog = ({
  open,
  setOpen,
  customerID,
  userID,
  regulationID,
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const policies = useSelector((state) => state.policyReducer.policies);

  const [policyMappingData, setPolicyMappingData] = useState([]);
  const [groupingData, setGroupingData] = useState({});
  const [tableFilter, setTableFilter] = useState("document_type");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentOwners, setDocumentOwners] = useState([]);
  const [groupHeaders, setGroupHeaders] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [isMappingView, setIsMappingView] = useState(true);

  const handleClose = () => setOpen(false);

  const fetchPolicyMappingData = async () => {
    const payload = {
      i_REGULATION_ID: regulationID,
      i_USER_ID: userID,
      i_CUSTOMER_ID: customerID,
    };
    await dispatch(
      PolicyMappingActions.getRegulationPolicyMap(payload, setPolicyMappingData)
    );
  };

  const getDocumentControlData = async () => {
    await dispatch(
      PolicyServices.getDocumentControlInformation(
        userID,
        false,
        setDocumentOwners,
        setDocumentTypes
      )
    );
  };

  useEffect(() => {
    (async () => {
      isMappingView
        ? await fetchPolicyMappingData()
        : dispatch(PolicyServices.getAvailablePolicies(userID));
      if (documentTypes.length == 0 || documentOwners.length == 0) {
        await getDocumentControlData();
      }
    })();
  }, [isMappingView]);

  useEffect(() => {
    if (
      (isMappingView && policyMappingData.length > 0) ||
      (!isMappingView && policies.length > 0)
    ) {
      const documentGroupingData = groupingOnDocumentTypeOrOwner(
        isMappingView ? policyMappingData : policies,
        tableFilter,
        documentTypes
      );
      setGroupingData(documentGroupingData);
      let headersData = [];
      if (tableFilter == "document_type") {
        documentTypes.forEach((item) => {
          headersData.push({
            ...item,
            isOpen: false,
            rows: 10,
            hasMore:
              documentGroupingData[item.value] &&
              documentGroupingData[item.value].length > 10
                ? true
                : false,
          });
        });
      } else {
        documentOwners.forEach((item) => {
          headersData.push({
            ...item,
            isOpen: false,
            rows: 10,
            hasMore:
              documentGroupingData[item.value] &&
              documentGroupingData[item.value].length > 10
                ? true
                : false,
          });
        });
      }
      if (documentGroupingData[null].length > 0) {
        headersData.push({
          value: null,
          isOpen: false,
          name: "No Data",
          rows: 10,
          hasMore:
            groupingData[null] && groupingData[null].length > 10 ? true : false,
        });
      }
      headersData = headersData.sort((a, b) => b.value - a.value);
      setGroupHeaders(headersData);
    }
  }, [policyMappingData, documentTypes, documentOwners, tableFilter, policies]);

  const updateIsOpen = (header) => {
    const tempGroupHeaders = [...groupHeaders];
    const index = tempGroupHeaders.findIndex(
      (item) => item.value == header.value
    );
    tempGroupHeaders[index].isOpen = !tempGroupHeaders[index].isOpen;
    tempGroupHeaders[index].rows = 10;
    if (groupingData[header.value].length > 10) {
      tempGroupHeaders[index].hasMore = true;
    }
    setGroupHeaders(tempGroupHeaders);
  };

  const fetchMoreData = (headerValue) => {
    const tempGroupHeaders = [...groupHeaders];
    const index = tempGroupHeaders.findIndex(
      (item) => item.value == headerValue
    );
    tempGroupHeaders[index].rows = tempGroupHeaders[index].rows + 10;
    if (tempGroupHeaders[index].rows >= groupingData[headerValue].length) {
      tempGroupHeaders[index].hasMore = false;
    }
    setGroupHeaders(tempGroupHeaders);
  };

  const modifyDocumentOwner = (value) => {
    const documentOwner = value ? JSON.parse(value) : null;
    const ownerString = (
      <span>
        {documentOwner && documentOwner["label"]
          ? documentOwner["label"]
          : langConstant.NOT_AVAILABLE}
      </span>
    );
    return ownerString;
  };

  const modifyDocumentType = (value) => {
    const documentType = value ? JSON.parse(value) : null;
    let documentTypeString = "";
    if (documentType && documentType.length) {
      for (let i = 0; i < documentType.length; i++) {
        documentTypeString = documentTypeString + documentType[i].label + ", ";
      }
      documentTypeString = documentTypeString.trim();
      documentTypeString = documentTypeString.slice(0, -1);
    }
    const documentTypeData = (
      <span>
        {documentTypeString ? documentTypeString : langConstant.NOT_AVAILABLE}
      </span>
    );
    return documentTypeData;
  };

  const documentTypeColumns = [
    {
      field: "DOCUMENT_TYPE",
      headerName: langConstant.DOCUMENT_TYPE,
      format: (value) => modifyDocumentType(value),
    },
    { field: "POLICY_NAME", headerName: langConstant.POLICY_NAME },
    {
      field: "DOCUMENT_OWNER",
      headerName: langConstant.POLICY_OWNER,
      format: (value) => modifyDocumentOwner(value),
    },
  ];

  const documentOwnerColumns = [
    {
      field: "DOCUMENT_OWNER",
      headerName: langConstant.POLICY_OWNER,
      format: (value) => modifyDocumentOwner(value),
    },
    { field: "POLICY_NAME", headerName: langConstant.POLICY_NAME },
    {
      field: "DOCUMENT_TYPE",
      headerName: langConstant.DOCUMENT_TYPE,
      format: (value) => modifyDocumentType(value),
    },
  ];

  const updateSelectedPolicies = (policyID) => {
    if (selectedPolicies.includes(policyID)) {
      const tempPolicyList = selectedPolicies.filter(
        (policy) => policy != policyID
      );
      setSelectedPolicies(tempPolicyList);
    } else {
      setSelectedPolicies((prevState) => [...prevState, policyID]);
    }
  };

  const onConfirmClick = async () => {
    const payload = {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
      i_POLICY_ID: selectedPolicies.toString(),
      i_USER_ID: userID,
    };
    await dispatch(
      PolicyMappingActions.putRegulationPolicyMap(payload, setIsMappingView)
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className={classes.policyMapDialogHeader}>
            {langConstant.POLICY_MAPPING}
          </Typography>
          <span
            style={{
              textAlign: "right",
              alignItems: "right",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <CloseIcon onClick={handleClose} />
          </span>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={5} xs={12}>
            <Checkbox
              checked={tableFilter === "document_type"}
              onClick={() => setTableFilter("document_type")}
              sx={{ "&.Mui-checked": { color: "#124571" }, display: "inline" }}
              size="small"
            />
            <span className={classes.tableFilterText}>
              {langConstant.DOCUMENT_TYPE}
            </span>
          </Grid>
          <Grid item md={5} xs={10}>
            <Checkbox
              checked={tableFilter === "document_owner"}
              onClick={() => setTableFilter("document_owner")}
              sx={{ "&.Mui-checked": { color: "#124571" }, display: "inline" }}
              size="small"
            />
            <span className={classes.tableFilterText}>
              {langConstant.DOCUMENT_OWNER}
            </span>
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
            style={{ textAlign: "end", marginBottom: "5px" }}
          >
            <IconButton className={classes.cursorPointer} size="small">
              {isMappingView ? (
                <Tooltip title={langConstant.SELECT_POLICY_TO_MAP}>
                  <NoteAddIcon
                    onClick={() => setIsMappingView(false)}
                    style={{ color: "#124571" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={langConstant.POLICY_MAPPING_VIEW}>
                  <FilePresentRoundedIcon
                    onClick={() => setIsMappingView(true)}
                    style={{ color: "#124571" }}
                  />
                </Tooltip>
              )}
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table
            aria-label={langConstant.POLICY_MAPPING}
            className="data-table"
          >
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row"></TableCell>
                {(tableFilter === "document_type"
                  ? documentTypeColumns
                  : documentOwnerColumns
                ).map((column, index) => {
                  return (
                    <TableCell
                      component="th"
                      scope="row"
                      key={"column_" + index + column.field}
                    >
                      {column.headerName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {groupHeaders.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {groupingData[item["value"]] &&
                      groupingData[item["value"]].length > 0 && (
                        <TableRow>
                          <TableCell
                            component="td"
                            scope="row"
                            style={{ cursor: "pointer" }}
                          >
                            {item.isOpen ? (
                              <ArrowCircleDownIcon
                                style={{ color: "#124571" }}
                                onClick={() => updateIsOpen(item)}
                              />
                            ) : (
                              <ArrowCircleRightIcon
                                style={{ color: "#124571" }}
                                onClick={() => updateIsOpen(item)}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            colSpan={10}
                            onClick={() => updateIsOpen(item)}
                            style={{ fontWeight: "bold", cursor: "pointer" }}
                          >
                            {item["name"]}
                            <span className={classes.recordLength}>
                              {groupingData[item["value"]]
                                ? groupingData[item["value"]].length
                                : 0}
                            </span>
                          </TableCell>
                        </TableRow>
                      )}
                    {item.isOpen &&
                      groupingData[item["value"]] &&
                      groupingData[item["value"]]
                        .slice(0, item["rows"])
                        .map((policy, policyIndex) => {
                          let isDisabled = false;
                          if (!isMappingView) {
                            isDisabled = policyMappingData.some(
                              (item) => item.POLICY_ID == policy["POLICY_ID"]
                            );
                          }
                          return (
                            <TableRow
                              key={policyIndex}
                              style={{
                                backgroundColor: isDisabled ? "#a9ffa9" : "",
                              }}
                            >
                              <TableCell component="td" scope="row">
                                {!isMappingView && (
                                  <input
                                    type="checkbox"
                                    checked={
                                      isDisabled ||
                                      selectedPolicies.includes(
                                        policy["POLICY_ID"]
                                      )
                                    }
                                    disabled={isDisabled}
                                    onChange={() =>
                                      updateSelectedPolicies(
                                        policy["POLICY_ID"]
                                      )
                                    }
                                    style={{ accentColor: "#124571" }}
                                  />
                                )}
                              </TableCell>
                              {(tableFilter === "document_type"
                                ? documentTypeColumns
                                : documentOwnerColumns
                              ).map((column, index) => {
                                const value = policy[column.field];
                                return (
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    key={"column_" + index + policyIndex}
                                    className={
                                      index === 0
                                        ? classNames(
                                            classes.stickyColl,
                                            classes.cursorPointer
                                          )
                                        : classes.stickyColl
                                    }
                                    style={{ minWidth: item.minWidth }}
                                  >
                                    {column.format
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    {item.isOpen && item.hasMore && (
                      <TableRow>
                        <TableCell
                          colSpan={11}
                          component="td"
                          scope="row"
                          className={classes.loadMore}
                        >
                          <span onClick={() => fetchMoreData(item.value)}>
                            {langConstant.LOAD_MORE}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        {!isMappingView && (
          <Button
            onClick={onConfirmClick}
            variant="contained"
            color="primary"
          >
            {langConstant.CONFIRM}
          </Button>
        )}
        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
          style={{
            fontWeight: "bold",
          }}
        >
          {langConstant.CANCEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PolicyMappingDialog.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  customerID: PropTypes.string,
  userID: PropTypes.string,
  regulationID: PropTypes.string,
};

export default PolicyMappingDialog;
