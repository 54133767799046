import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, IconButton } from "@mui/material";
import { Grid, Typography } from "@material-ui/core";
import Select from "react-select";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import useStyles from "../styles";


const PolicyTextComponent = ({ policyType, selectedBaseText, policyText }) => {
    const classes = useStyles()

    const [currentPage, setCurrentPage] = useState(null)
    const [selectedComparison, setSelectedComparison] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(null)

    let dataByPage = {}
    let pageOptions = []
    policyText.forEach((element, index) => {
        if(Object.keys(dataByPage).includes(element['PAGE_NUMBER'].toString())){
            dataByPage[element['PAGE_NUMBER']] = [
                ...dataByPage[element['PAGE_NUMBER']],
                {...element, key: "key_"+element['PAGE_NUMBER']+"_"+index, SENTENCESDATA: element['SENTENCES'] ? JSON.parse(element['SENTENCES']) : []}]
        } else {
            dataByPage[element['PAGE_NUMBER']] = [{...element, key: "key_"+element['PAGE_NUMBER']+"_"+index, SENTENCESDATA: element['SENTENCES'] ? JSON.parse(element['SENTENCES']) : []}]
            pageOptions.push({value: element['PAGE_NUMBER'], label: element['PAGE_NUMBER']})
        }
    });

    useEffect(() => {
        if(policyType === "target"){
            onUpdateOfComparisonText()
        } else {
            setCurrentPage(selectedBaseText['PAGE_NUMBER'])
            setTimeout(() => {
                scrollToHeader(selectedBaseText['POLICY_EXTRACTION_DETAILS_ID'])
            }, 500)
        }
    },[selectedBaseText, selectedBaseText.selectedComparison])

    const onUpdateOfComparisonText = () => {
        const tempSelectedComparison = selectedBaseText.selectedComparison.map((obj) => { return {id: obj.value, page: obj.page}})
        setSelectedComparison(tempSelectedComparison)
        if(tempSelectedComparison.length > 0 && selectedIndex && selectedIndex > (tempSelectedComparison.length -1)){
            setSelectedIndex(tempSelectedComparison.length -1)
            setCurrentPage(tempSelectedComparison[tempSelectedComparison.length -1].page)
        } else {
            if(tempSelectedComparison.length > 0) {
                setSelectedIndex(0)
                setCurrentPage(tempSelectedComparison[0].page)
                setTimeout(() => {
                    scrollToHeader(tempSelectedComparison[0].id)
                }, 500)
            } else {
                setSelectedIndex(null)
                setCurrentPage(pageOptions[0]['value'])
            }
        }
    }

    const scrollToHeader = (selectedElement) => {
        const element = document.getElementById(selectedElement);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center'});
        }
      };

    const findComparison = (type) => {
        if(type === "prev"){
            setCurrentPage(selectedComparison[selectedIndex - 1].page)
            scrollToHeader(selectedComparison[selectedIndex - 1].id)
            setSelectedIndex(selectedIndex - 1)
        } else {
            setCurrentPage(selectedComparison[selectedIndex + 1].page)
            scrollToHeader(selectedComparison[selectedIndex + 1].id)
            setSelectedIndex(selectedIndex + 1)
        }
    }

    return (
        <Card>
            <CardContent className={classes.cardWrapper}>
                <Grid container justify="flex-end">
                    {policyType === "target" && 
                        <Grid>
                            <IconButton disabled={selectedIndex == null || selectedIndex == 0 ? true : false} color="error">
                                <NavigateBeforeIcon onClick={() => findComparison("prev")}/>
                            </IconButton>
                            <IconButton disabled={selectedIndex == null || selectedIndex == (selectedComparison.length -1) ? true : false } color="success">
                                <NavigateNextIcon onClick={() => findComparison("next")}/>
                            </IconButton>
                        </Grid>
                    }
                    <Select
                        options={pageOptions}
                        value={pageOptions.find(option => option.value === currentPage)}
                        onChange={(option) => setCurrentPage(option.value)}
                    />
                </Grid>
                <Grid className={classes.contentWrap} style={{minHeight: "400px", maxHeight: "400px", overflow: "auto"}}>
                    {currentPage != null && dataByPage && Object.keys(dataByPage).length > 0 && dataByPage[currentPage].map((item, index) =>  {
                        return (
                            <Grid 
                                key={index} 
                                id={item.POLICY_EXTRACTION_DETAILS_ID}
                                style={{
                                    textAlign: "left", 
                                    backgroundColor: policyType === "base" ? selectedBaseText.POLICY_EXTRACTION_DETAILS_ID === item.POLICY_EXTRACTION_DETAILS_ID ? "#ff2d2d33" : "" 
                                    : selectedComparison.length > 0 && selectedComparison[selectedIndex]?.id === item.POLICY_EXTRACTION_DETAILS_ID ? "#ff2d2d33" : "",
                                    margin: "5px 0px",
                                    padding: "10px 5px",
                                    borderRadius: "10px"
                                }}
                            >
                                <Typography className={classes.headerText}>{item.NAME}</Typography>
                                {item.ITEM_BODY_CONTENT && 
                                    <Grid style={{margin: "10px 0px 10px 40px"}}>
                                        <Typography className={classes.bodyText}>
                                            {item.ITEM_BODY_CONTENT}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        )
                    })}
                </Grid>
            </CardContent>
        </Card>
    )
}

PolicyTextComponent.propTypes = {
    policyType: PropTypes.string,
    selectedBaseText: PropTypes.object,
    policyText: PropTypes.array,
}

export default PolicyTextComponent;