import { PolicyConstants } from "./constants";

export const setPolicyData = (data) => ({
    type: PolicyConstants.SET_POLICIES,
    payload: data,
});

export const setSelectedPolicies = (data) => ({
    type: PolicyConstants.SET_SELECTED_POLICIES,
    payload: data,
});

export const clearSelectedPolicies = () => ({
    type: PolicyConstants.CLEAR_SELECTED_POLICIES
});

export const setErrorMessages = (data) => ({
    type: PolicyConstants.SET_ERROR_MESSAGES,
    payload: data,
});

export const addErrorMessages = (data) => ({
    type: PolicyConstants.ADD_ERROR_MESSAGE,
    payload: data,
})

export const updatePolicyRecord = (data) => ({
    type: PolicyConstants.UPDATE_POLICY_RECORD,
    payload: data,
})
