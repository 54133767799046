/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import { globalConstants } from "../_constants";
import apiService from "../_services/api.service";
import { alertActions } from "./alert.action";
import axios from "axios";

// const mockNotifications = [
//   {
//     NotificationHeading: "Sarah Jones",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Sept 01 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription: "Answered the question EU-UNISA-02",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Tony Ferguson",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 27 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription: "Answered the question EU-UNISA-02",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Tony Ferguson",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 26 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "RFI information received for the question EU-UNISA-01",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Sherlock Holmes",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 22 2021 17:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: false,
//     NotificationDescription:
//       "Answered the question EU-UNISA-02 in the Assessment",
//     NotificationChannel: "QUESTION",
//     NotificationBookmarked: true,
//   },
//   {
//     NotificationHeading: "HKMA Regulation",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Sept 05 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription: "status changed from Inprogress to Completed",
//     NotificationChannel: "ASSESSMENT",
//     NotificationBookmarked: true,
//   },
//   {
//     NotificationHeading: "FCA Regulation",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Sept 01 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription: "Reviewed FCA Regulation.",
//     NotificationChannel: "ASSESSMENT",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Tony Ferguson",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 21 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "commented on EU-USINA-01 'Please clarify the sou....'",
//     NotificationChannel: "ASSESSMENT",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Tony Ferguson",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 21 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "      RFI information received for the question EU-UNISA-01",
//     NotificationChannel: "ASSESSMENT",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Null",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Sept 06 2021 12:56:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "RFI information received for the question EU-UNISA-01",
//     NotificationChannel: "RFI",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "Null",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 21 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "RFI information received for the question EU-UNISA-01",
//     NotificationChannel: "RFI",
//     NotificationBookmarked: true,
//   },
//   {
//     NotificationHeading: "Null",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 21 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "RFI information received for the question EU-UNISA-01",
//     NotificationChannel: "RFI",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "USER LOCKED",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 21 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "Sherlock Holmes just locked himself out of his account",
//     NotificationChannel: "USER",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "USER LOCKED",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 21 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "Dustin Poirier just locked himself out of his account",
//     NotificationChannel: "USER",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "USER LOCKED",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 21 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "Jason Momoa just locked himself out of his account",
//     NotificationChannel: "USER",
//     NotificationBookmarked: false,
//   },
//   {
//     NotificationHeading: "USER LOCKED",
//     NotificationID: "12345",
//     Notification_DateTime: new Date(
//       "Wed Aug 21 2021 17:06:09 GMT+0530 (India Standard Time)"
//     ),
//     UserReadNotification: true,
//     NotificationDescription:
//       "RFI information received for the question EU-UNISA-01",
//     NotificationChannel: "USER",
//     NotificationBookmarked: true,
//   },
// ];

const getUserNotifications = (userID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetUserNotification", {
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            dispatch({
              type: globalConstants.GLOBAL_NOTIFICATIONS_LIST,
              data: response["#result-set-1"],
            });
          } else {
            dispatch(alertActions.error("User Notifications not found"));
          }
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
};

const updateNotification = (messageId, read, bookmarked, bulk, userId) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateNotifications", {
        i_MESSAGE_ID: messageId,
        i_READ_NOTIFICATION: read,
        i_BOOKMARK_NOTIFICATION: bookmarked,
        i_Bulk_Update_Flag: bulk,
        i_User_ID: userId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#result-set-1"][0]["#update-count-1"]) {
            if (bulk) {
              dispatch({
                type: globalConstants.GLOBAL_NOTIFICATIONS_READ,
                data: null,
              });
            } else {
              dispatch({
                type: globalConstants.GLOBAL_NOTIFICATIONS_UPDATE,
                data: { messageId, read, bookmarked },
              });
            }
          } else {
            dispatch(alertActions.error("Notification not updated"));
          }
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
};

export default {
  getUserNotifications,
  updateNotification,
};
