import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@material-ui/core";
import AppPagination from "_components/AppPagination";
import useStyles from "../styles";
import SelectPageRange from "_components/SelectPageRange";
import { scrollToTop } from "_helpers/utils";

const TableComponent = ({ columns, rows, expandedPanelID, block }) => {
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    expandedPanelID === block.id ? 10 : 5
  );

  const classes = useStyles();

  const calculatePageCount = (rows, rowsPerPage) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  useEffect(() => {
    setPageCount(calculatePageCount(rows, rowsPerPage));
  }, [rows]);

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      rows?.length > 0 ? Math.round(Math.ceil(rows.length / range.value)) : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  return (
    <Box flexGrow={1}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" className="data-table">
          <TableHead>
            <TableRow>
              {columns.map((column, columnIndex) => (
                <TableCell
                  className={
                    expandedPanelID === block.id
                      ? classes.tableRow
                      : classes.outerTableRow
                  }
                  key={columnIndex}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, columnIndex) => (
                  <TableCell
                    className={
                      expandedPanelID === block.id
                        ? classes.tableCell
                        : classes.outerTableCell
                    }
                    // style={{ maxWidth: "10px" }}
                    key={rowIndex.toString() + "_" + columnIndex.toString()}
                  >
                    {column.format ? column.format(row) : row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid container justify="flex-end">
          {expandedPanelID === block.id && (
            <SelectPageRange
              selectedRange={rowsPerPage}
              handleChange={handleSelectPageRange}
            />
          )}
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
};

TableComponent.propTypes = {
  columns: propTypes.arrayOf(propTypes.object),
  rows: propTypes.arrayOf(propTypes.object),
  expandedPanelID: propTypes.string,
  block: propTypes.object,
};

export default TableComponent;
