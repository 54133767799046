import DialogBox from "_components/DialogBox";
import React from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { panelSectorOptions } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedPanels } from "../actions";

const PanelFilterPopUp = ({ panelFilterPopUp, setPanelFilterPopUp }) => {
  const dispatch = useDispatch();
  const selectedPanelList = useSelector(
    (state) => state.complianceReducer.selectedPanels
  );

  const toggleDialog = () => {
    setPanelFilterPopUp(false);
  };

  return (
    <DialogBox
      maxWidth={"sm"}
      title={langConstant.DASHBOARD_PANELS}
      open={panelFilterPopUp}
      handleClose={toggleDialog}
      style={{ paddingTop: "0px" }}
    >
      <Box>
        {panelSectorOptions.map((panel, index) => {
          return (
            <Grid
              key={index}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {panel.label}
              </Typography>
              <Checkbox
                checked={selectedPanelList.includes(panel.id)}
                onClick={() => dispatch(updateSelectedPanels(panel.id))}
                sx={{
                  "&.Mui-checked": { color: "#124571" },
                  display: "inline",
                }}
                size="small"
              />
            </Grid>
          );
        })}
      </Box>
    </DialogBox>
  );
};

PanelFilterPopUp.propTypes = {
  panelFilterPopUp: PropTypes.bool,
  setPanelFilterPopUp: PropTypes.func,
};

export default PanelFilterPopUp;
