import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
} from "@material-ui/core";
import propTypes from "prop-types";

const modifiyData = (list) => {
  return list?.map((item) => {
    let responseJson = item?.RFI_RESPONSE_JSON
      ? JSON.parse(item?.RFI_RESPONSE_JSON)
      : {};
    return {
      ...item,
      ...responseJson,
    };
  });
};

export const NarrativeTable = ({ rfiNarrativeList }) => {
  const rows = rfiNarrativeList?.length ? modifiyData(rfiNarrativeList) : [],
    columns = [
      { field: "RFI_ID", headerName: "RFI ID" },
      { field: "RFI_RESPONSE_RECORD_TYPE", headerName: "RESPONSE RECORD TYPE" },
      {
        field: "NARRATIVE",
        headerName: "NARRATIVE",
        format: (value) => (value ? value : "-"),
      },
      {
        field: "DATA_ASSET_ATTRIBUTE_NAME",
        headerName: "DATA ASSET ATTRIBUTE NAME",
        format: (value) => (value ? value : "-"),
      },
      {
        field: "DATA_ASSET_ENTITY_NAME",
        headerName: "DATA ASSET ENTITY NAME",
        format: (value) => (value ? value : "-"),
      },
      {
        field: "SOURCE_DATA_POINT_VALUE",
        headerName: "SOURCE DATA POINT VALUE",
        format: (value) => (value ? value : "-"),
      },
    ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Action List">
        <TableHead>
          <TableRow>
            {columns?.map((item, index) => (
              <TableCell
                component="th"
                scope="row"
                key={"column_" + index + item.field}
                style={{ minWidth: item?.minWidth }}
              >
                {item?.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={index + (row?.[columns?.[0]?.field] || 0)}>
              {columns?.map((column, index) => {
                const value = row?.[column?.field];
                return (
                  <TableCell
                    component="td"
                    scope="row"
                    key={`column_${index + (row?.[columns?.[0]?.field] || 0)}`}
                  >
                    {column?.format ? column.format(value) : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}

          {rows?.length === 0 && (
            <TableRow style={{ height: 60 }}>
              <TableCell
                style={{ textAlign: "center" }}
                colSpan={columns?.length}
              >
                No record found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

NarrativeTable.propTypes = {
  rfiNarrativeList: propTypes.array,
};
