import React from "react";
import propTypes from "prop-types";

export const GradientSVG = ({ id }) => {
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={id} gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="orange" />
          <stop offset="100%" stopColor="green" />
        </linearGradient>
      </defs>
    </svg>
  );
};

GradientSVG.propTypes = {
  id: propTypes.string,
};
