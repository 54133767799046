import React, { useState } from "react";
import useStyles from "./styles";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Tooltip } from "@mui/material";
import ChatBox from "./ChatBox";
import langConstant from "_lang";
import { Box } from '@mui/material';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";


const ChatBot = ({entitlement}) => {
    const themeDetails = useSelector(getThemeDetails);
    const classes = useStyles({
      actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
    });

    const [isChatBoxAvailable, setIsChatBoxAvailable] = useState(false)

    const handleClose = () => {
        setIsChatBoxAvailable(false)
    }

    return (
        <Box className={classes.chatMain}>
            {!isChatBoxAvailable && 
                <Tooltip title={langConstant.HOW_CAN_I_HELP_YOU} placement="left">
                    <div className={classes.chatIcon} onClick={() => setIsChatBoxAvailable(true)}>
                        <QuestionAnswerIcon style={{ color: 'white' }} />
                    </div>
                </Tooltip>
            }
            <ChatBox handleClose={handleClose} isChatBoxAvailable={isChatBoxAvailable} entitlement={entitlement}/>
        </Box>
    )
}

ChatBot.propTypes = {
    entitlement: PropTypes.string
}

export default ChatBot;