import { Grid, IconButton, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ClassificationServices from "../services";
import { useState } from "react";
import ClassificationUtils from "../utils";
import TableComponent from "./TableComponent";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SanctionDialog from "./SanctionDialog";
import useStyles from "../styles";
import langConstant from "_lang";

const SanctionKeywords = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [sanctionKeywords, setSanctionKeywords] = useState(null);
  const [sanctionDialog, setSanctionDialog] = useState(false);
  const [selectedSanctionKeyword, setSelectedSanctionKeyword] = useState(null);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(
        ClassificationServices.getCustomerSanctionKeywords(
          selectedCustomer.value,
          setSanctionKeywords
        )
      );
    }
  }, [selectedCustomer]);

  return (
    <Grid className={classes.classificationFormWrapper}>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className={classes.sectionHeader}>
          {langConstant.SENCTION_KEYWORDS}
        </Typography>
        {selectedCustomer && selectedCustomer.value && (
          <IconButton
            type="button"
            color="primary"
            onClick={() => setSanctionDialog(true)}
          >
            <AddBoxIcon />
          </IconButton>
        )}
      </Grid>
      {selectedCustomer && selectedCustomer.value ? (
        <TableComponent
          columns={ClassificationUtils.SanctionKeywordsColumns}
          rows={sanctionKeywords}
          setSelectedRow={setSelectedSanctionKeyword}
          setDialog={setSanctionDialog}
        />
      ) : (
        <Typography className={classes.selectCustText}>
          {langConstant.PLEASE_SELECT_CUSTOMER_FIRST}
        </Typography>
      )}
      <SanctionDialog
        open={sanctionDialog}
        setOpen={setSanctionDialog}
        selectedSanctionKeyword={selectedSanctionKeyword}
        sanctionKeywords={sanctionKeywords}
        setSanctionKeywords={setSanctionKeywords}
        selectedCustomer={selectedCustomer}
        setSelectedSanctionKeyword={setSelectedSanctionKeyword}
      />
    </Grid>
  );
};

SanctionKeywords.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default SanctionKeywords;
