/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormHelperText,
  Button,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import CloseIcon from "@material-ui/icons/Close";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import PublishIcon from "@material-ui/icons/Publish";
import Loader from "_components/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import rootStyles from "rootStyles";
import styles from "./styles";
import sessionService from "_services/session.service";
import Divider from "@mui/material/Divider";
import uploadDataAction from "./actions";
import langConstant from "_lang/index";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { alertActions, loadingActions } from "_actions";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import VerifiedIcon from "@mui/icons-material/Verified";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import { getRouteBasedTitle, virusCheckerService } from "_helpers/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const tabsArray = [
  { label: "DATA FILE" },
  { label: "EXECUTED JOBS", successful: 0, failed: 0 },
];

const UploadDataForm = () => {
  const validationSchema = yup.object({
    dataType: yup
      .object()
      .required(langConstant.DATA_TYPE_REQUIRED)
      .test("test1", "required", (a, b) => {
        if (a === undefined || a === "") {
          return false;
        }

        if (Object.keys(a).length === 0) {
          return false;
        }

        return true;
      }),
    uploadedFile: yup.mixed().required(langConstant.FILE_IS_REQUIRED),
  });
  const classes = styles();
  const rootClasses = rootStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const alert = useSelector((state) => state.alert);
  const loading = useSelector((state) => state.uploadData.loading);
  const dataList = useSelector((state) => state.uploadData.dataTypes);
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );
  const isVirusCheckingAvailable = useSelector(
    (state) => state.authentication?.detailedInfo?.VIRUS_CHECKER
  );
  const dataUpload = useSelector(
    (state) => state?.authentication?.detailedInfo?.DATA_UPLOAD
  );
  const [count, setCount] = useState(0);
  const inputFileRef = useRef(null);
  const [value, setValue] = useState(0);
  const [TableDataObj, setTableDataObj] = useState({});
  const [TotalJobsTableData, setTotalJobsTableData] = useState([]);
  const [FilteredJobsTableData, setFilteredJobsTableData] = useState([]);
  const [TabPanelArray, setTabPanelArray] = useState(tabsArray);
  const [isVirusFile, setIsVirusFile] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(uploadDataAction.getDataTypes(userID));
  }, []);

  const ExecutedJobsApi = async (sourceSystemID) => {
    const data = await uploadDataAction.GetDataUploadLog(
      userID,
      sourceSystemID
    );

    if (data["#result-set-1"] === undefined) {
      dispatch(
        alertActions.error(
          data.data.message.map((item, index) => {
            return <div key={index}>{item}</div>;
          })
        )
      );
    } else {
      if (data["#result-set-1"].length > 0) {
        const data2 = [...data["#result-set-1"]].sort(
          (a, b) =>
            new Date(b.UPLOAD_EVENT_DATE) - new Date(a.UPLOAD_EVENT_DATE)
        );
        setFilteredJobsTableData(data2);
        setTotalJobsTableData(data2);

        let success = 0;
        let fail = 0;

        data2.forEach((el) => {
          if (
            el.STATUS_EVENT === "successful" ||
            el.STATUS_EVENT === "successful with errors"
          ) {
            success += 1;
          }

          if (el.STATUS_EVENT === "failed") {
            fail += 1;
          }
        });

        let tempArray = [
          { label: "DATA FILE" },
          { label: "EXECUTED JOBS", successful: success, failed: fail },
        ];
        setTabPanelArray(tempArray);
      }
    }
  };

  const KafkaTopicUpload = async (sourceID) => {
    let tempobj = {
      payload: {
        evidenceLogId: "string",
        messageDataTime: moment().format("YYYY-MM-DD hh:mm:ss"),
        messageSource: "string",
        questionId: "string",
        regulationId: "string",
        rfiId: "string",
        ruleId: "string",
        sourceDataId: "string",
        sourceSystemId: sourceID,
        submissionId: "string",
        uniqueMessageId: "string",
        userId: userID,
      },
      topicID: 0,
      topicName: window.REACT_APP_KAFKA_TOPIC,
    };

    const response = await uploadDataAction.kafkatopicUpload(tempobj);
  };

  //Trigger file Input
  const triggerFileUpload = () => {
    inputFileRef.current.click();
  };
  //Reset file Input
  const resetFileUpload = () => {
    formik.setFieldValue("uploadedFile", null);
    inputFileRef.current.value = "";
  };
  const fileUploadProgress = (event) => {
    setCount(Math.round((100 * event.loaded) / event.total));
  };
  const formik = useFormik({
    initialValues: {
      dataType: "",
      uploadedFile: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!isVirusFile) {
        let data = new FormData();
        data.append("file", values.uploadedFile);
        data.append(
          "dataSourceNameAndType",
          values.dataType.DATA_SOURCE_NAMES_AND_TYPES
        );
        data.append("userId", userID);
        data.append("sourceSystemId", values.dataType.SOURCE_SYSTEM_ID);
        data.append(
          "fileLocation",
          customerID.toString() + "/" + dataUpload + "/"
        );
        data.append("bucketName", window.BUCKET_NAME);
        data.append("storageType", docStorageType);
        data.append("preProcessingIP", docStorageIP);
        data.append("preProcessingURL", docStorageURL);
        const redirectTo = () => {
          KafkaTopicUpload(values.dataType.SOURCE_SYSTEM_ID);
          history.push("/upload-data");
          resetForm();
          setTableDataObj({});
          setTotalJobsTableData([]);
          setFilteredJobsTableData([]);
        };
        dispatch(
          uploadDataAction.uploadFile(
            data,
            { "Content-type": "multipart/form-data" },
            redirectTo,
            fileUploadProgress
          )
        );
      } else {
        dispatch(alertActions.error("FILE CONTAINS VIRUS"));
      }
    },
  });

  const handleDataTypeChange = (e) => {
    ExecutedJobsApi(e.target.value.SOURCE_SYSTEM_ID);
    formik.handleChange(e);
    setTableDataObj(e.target.value);
  };

  const columns = [
    { field: "SOURCE_SYSTEM_NAME", headerName: "Name of Source" },
    { field: "FILE_FORMAT", headerName: "Expected File Format" },
    { field: "FREQUENCY", headerName: "Frequency of File" },
    { field: "DELIVERY_METHOD", headerName: "Delivery Method" },
    { field: "DEDICATED_BUCKET_FOLDER_NAME", headerName: "Destination Folder" },
    { field: "FILE_NAME_SYNTAX", headerName: "File Syntax" },
    { field: "SCHEDULING_PERIOD", headerName: "Schedule" },
    { field: "SOURCE_SYSTEM_OWNER", headerName: "Data Owner" },
    {
      field: "SOURCE_SYSTEM_OWNER_EMAIL_ADDRESS",
      headerName: "Data Owner Email Address",
    },
  ];

  const ExpectedDataAttributesRender = () => {
    const datastr = TableDataObj?.DATA_ATTRIBUTES_EXPECTED_IN_SOURCE_FILE;

    if (
      (datastr === "" &&
        datastr?.split(";").length === 1 &&
        datastr?.split(";")[0] === "") ||
      datastr === null
    ) {
      return (
        <TableRow style={{ height: 40 }}>
          <TableCell style={{ textAlign: "center" }}>
            Data not Available
          </TableCell>
        </TableRow>
      );
    } else {
      return datastr?.split(";").map(
        (col, colIndex) =>
          col !== "" && (
            <TableRow
              key={"Col_" + colIndex}
              component="tr"
              className={classes.tableRow}
            >
              <TableCell component="td">
                <Typography className={classes.filedTitle2}>{col}</Typography>
              </TableCell>
            </TableRow>
          )
      );
    }
  };

  function s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const DownloadFile = () => {
    let data = new Blob([s2ab(window.atob(TableDataObj.TEMPLATE_FILE))], {
      type: "text/csv;charset=utf-8",
    });
    let csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "SampleFile.csv");
    tempLink.click();
  };

  const DataFileTab = () => {
    return (
      Object.keys(TableDataObj).length > 0 && (
        <Box className={classes.height100}>
          <Grid
            container={true}
            justify="space-between"
            className={classes.height100}
          >
            <Grid
              container={true}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              <Button
                color="primary"
                variant="text"
                disabled={
                  TableDataObj.TEMPLATE_FILE === null ||
                  TableDataObj.TEMPLATE_FILE === ""
                }
                startIcon={<FileDownloadIcon style={{ fontSize: "30px" }} />}
                onClick={() => {
                  DownloadFile();
                }}
              >
                Download sample file
              </Button>
            </Grid>
            <Grid item={true} xs={12} md={6} className={classes.height100}>
              <TableContainer
                component={Paper}
                className={`${classes.margintop} ${classes.maxHeight}`}
              >
                <Table stickyHeader className="data-table">
                  <TableHead>
                    <TableRow component="tr">
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.stickyColl}
                      >
                        Data File Details
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.stickyColl}
                      >
                        Data File Values
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {columns.map((col, colIndex) => (
                      <TableRow
                        key={"Col_" + colIndex}
                        component="tr"
                        className={classes.tableRow}
                      >
                        <TableCell component="td">
                          <Typography className={classes.filedTitle2}>
                            {col.headerName}
                          </Typography>
                        </TableCell>

                        <TableCell component="td">
                          <Typography className={classes.filedTitle3}>
                            {TableDataObj[col.field]}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Divider variant="middle" orientation="vertical" flexItem />

            <Grid item={true} xs={12} md={5} className={classes.height100}>
              <TableContainer component={Paper} style={{ maxHeight: "500px" }}>
                <Table stickyHeader className="data-table">
                  <TableHead>
                    <TableRow component="tr">
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.stickyColl}
                      >
                        Data Attributes Expected in Source File
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{ExpectedDataAttributesRender()}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      )
    );
  };

  const JobsTableFilter = (val) => {
    if (val === "Select All" || val === null) {
      setFilteredJobsTableData(TotalJobsTableData);
    } else if (val === "This Week") {
      const data = [...TotalJobsTableData].filter((el) =>
        moment(el.UPLOAD_EVENT_DATE).isSameOrAfter(
          moment().startOf("isoWeek"),
          "day"
        )
      );
      setFilteredJobsTableData(data);
    } else if (val === "Last Week") {
      const data2 = [...TotalJobsTableData].filter((el) =>
        moment(el.UPLOAD_EVENT_DATE).isSameOrAfter(
          moment().startOf("isoWeek").subtract(7, "days"),
          "day"
        )
      );
      setFilteredJobsTableData(data2);
    } else if (val === "Last Month") {
      const data3 = [...TotalJobsTableData].filter((el) =>
        moment(el.UPLOAD_EVENT_DATE).isSameOrAfter(
          moment().subtract(1, "months").startOf("month"),
          "day"
        )
      );
      setFilteredJobsTableData(data3);
    } else if (val === "Last 6 Months") {
      const data4 = [...TotalJobsTableData].filter((el) =>
        moment(el.UPLOAD_EVENT_DATE).isSameOrAfter(
          moment().subtract(6, "months").startOf("month"),
          "day"
        )
      );
      setFilteredJobsTableData(data4);
    }
  };

  const Jobscolumns = [
    { field: "USER NAME", headerName: "User Name" },
    { field: "UPLOAD_EVENT_TYPE", headerName: "Upload Event Type" },
    { field: "UPLOAD_EVENT_DATE", headerName: "Upload Event Date" },
    { field: "SOURCE_OF_DATA", headerName: "Data Source" },
    { field: "STATUS_EVENT", headerName: "Status" },
    { field: "RECORDS_UPLOADED", headerName: "Records Uploaded" },
    { field: "RECORDS_FAILED", headerName: "Records Failed" },
    { field: "SOURCE_SYSTEM_ID", headerName: "Source System ID" },
    { field: "SOURCE", headerName: "Source" },
    { field: "SOURCE_SYSTEM_NAME", headerName: "Source System Name" },
    {
      field: "DATA_SOURCE_NAMES_AND_TYPES",
      headerName: "Data Source Names and Types",
    },
  ];

  const ExecutedJobsTab = () => {
    return (
      <Box className={classes.height100}>
        <Grid
          container
          className={classes.GridContainer}
          style={{ marginTop: "30px" }}
        >
          <Grid item xs={3} md={3}>
            <Autocomplete
              size="small"
              autoHighlight
              openOnFocus
              options={[
                "Select All",
                "This Week",
                "Last Week",
                "Last Month",
                "Last 6 Months",
              ]}
              renderInput={(params) => (
                <TextField {...params} placeholder="Filter" />
              )}
              onChange={(e, val) => {
                JobsTableFilter(val);
              }}
            />
          </Grid>
        </Grid>

        <TableContainer component={Paper} style={{ maxHeight: "500px" }}>
          <Table stickyHeader className="data-table">
            <TableHead>
              <TableRow component="tr">
                {Jobscolumns.map((col, colIndex) => (
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.stickyColl}
                    key={"Header_" + colIndex}
                  >
                    {col.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {FilteredJobsTableData.length > 0 &&
                FilteredJobsTableData.map((row, rowIndex) => (
                  <TableRow
                    key={"Row_" + rowIndex}
                    component="tr"
                    style={{ height: 46 }}
                  >
                    {Jobscolumns.map((col, index) => (
                      <TableCell component="td" key={"column_" + index}>
                        {col.field === "UPLOAD_EVENT_DATE"
                          ? moment(row[col.field]).format("DD-MM-YYYY")
                          : row[col.field] === null || row[col.field] === ""
                          ? "-"
                          : row[col.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

              {FilteredJobsTableData.length === 0 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell
                    style={{ textAlign: "center" }}
                    colSpan={Jobscolumns.length}
                  >
                    No log entries found for this data file
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  //* Shows loader is still loading
  if (loading) {
    return <Loader count={count} />;
  }

  return (
    <Box style={{ height: "82vh" }}>
      <Grid
        container={true}
        justify="space-between"
        className={classes.height100}
      >
        <Grid item={true} xs={12} md={3} className={classes.height100}>
          <form
            autoComplete="off"
            className={classes.form}
            onSubmit={formik.handleSubmit}
          >
            <Grid container className={classes.GridContainer}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.filedTitle}>
                  {langConstant.DATA_TYPE} *
                </Typography>

                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  select
                  id="dataType"
                  label=""
                  name="dataType"
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  value={formik.values.dataType}
                  onChange={(e) => {
                    handleDataTypeChange(e);
                  }}
                  error={
                    formik.touched.dataType && Boolean(formik.errors.dataType)
                  }
                  helperText={formik.touched.dataType && formik.errors.dataType}
                >
                  <MenuItem key={1} value="">
                    {langConstant.SELECT}
                  </MenuItem>
                  {dataList.map((option) => (
                    <MenuItem
                      key={option.DATA_SOURCE_NAMES_AND_TYPES}
                      // value={option.DATA_SOURCE_NAMES_AND_TYPES}
                      value={option}
                    >
                      {option.DATA_SOURCE_NAMES_AND_TYPES &&
                        option.DATA_SOURCE_NAMES_AND_TYPES.replace(/_/g, " ")}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid
              container
              justify="space-between"
              className={classes.GridContainer}
            >
              <Grid item xs={12} md={6}>
                <Typography className={classes.filedTitle}>
                  {langConstant.UPLOAD_FILE} *
                </Typography>

                <Button
                  onClick={triggerFileUpload}
                  fullWidth
                  variant="contained"
                  color="default"
                  size="small"
                  startIcon={<FolderOpenIcon />}
                >
                  {langConstant.BROWSE_FILE}
                </Button>

                <input
                  ref={inputFileRef}
                  type="file"
                  name="uploadedFile"
                  className="hidden-input"
                  onChange={async (event) => {
                    if (event.target.files.length > 0) {
                      if (isVirusCheckingAvailable == 1) {
                        const fData = new FormData();
                        fData.append("file", event.target.files[0]);
                        dispatch(loadingActions.start());
                        dispatch(
                          loadingActions.setLoaderText(
                            "Checking Virus In Uploaded File"
                          )
                        );
                        const response = await virusCheckerService(fData);
                        dispatch(loadingActions.setLoaderText(null));
                        dispatch(loadingActions.end());
                        if (response.status == 200) {
                          if (response.data && response.data.hasVirus) {
                            setIsVirusFile(true);
                            dispatch(
                              alertActions.success(response.data.message)
                            );
                          } else {
                            setIsVirusFile(false);
                            dispatch(
                              alertActions.success(response.data.message)
                            );
                            formik.setFieldValue(
                              "uploadedFile",
                              event.target.files[0]
                            );
                          }
                        } else {
                          dispatch(
                            alertActions.error("Virus Checker Service Failed")
                          );
                        }
                      } else {
                        formik.setFieldValue(
                          "uploadedFile",
                          event.target.files[0]
                        );
                      }
                    } else {
                      formik.setFieldValue("uploadedFile", "");
                    }
                  }}
                />

                <Box style={{ minHeight: "25px" }}>
                  {formik.touched.uploadedFile &&
                    Boolean(formik.errors.uploadedFile) && (
                      <Typography
                        style={{ color: "red", fontSize: "12px" }}
                        className={classes.marginLeft14}
                      >
                        {formik.errors.uploadedFile}
                      </Typography>
                    )}
                </Box>
              </Grid>

              <Grid item xs={12} md={5} style={{ alignSelf: "center" }}>
                {formik.values.uploadedFile && (
                  <Box pt={1}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs zeroMinWidth>
                        <Typography variant="body2" noWrap>
                          {formik.values.uploadedFile.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          aria-label="Upload Data"
                          size="small"
                          onClick={resetFileUpload}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                      {isVirusCheckingAvailable == 1 && (
                        <Grid item>
                          {isVirusFile ? (
                            <Tooltip
                              title="Virus Found In File"
                              placement="right"
                            >
                              <CoronavirusIcon color="error" />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Virus Not Found In File"
                              placement="right"
                            >
                              <VerifiedIcon color="success" />
                            </Tooltip>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              className={classes.GridContainer}
              style={{ marginTop: "30px" }}
            >
              <Grid item xs={12} md={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="default"
                  size="small"
                  className={rootClasses.greenButton}
                  startIcon={<PublishIcon />}
                >
                  {langConstant.UPLOAD_FILE}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Divider variant="middle" orientation="vertical" flexItem />

        <Grid item={true} xs={12} md={8} className={classes.height100}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {TabPanelArray.map((tabs, i) => (
                <Tab
                  key={i}
                  iconPosition="end"
                  icon={
                    i !== 0 && (
                      <span
                        style={{
                          width: 45,
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "5px",
                        }}
                      >
                        {tabs.successful > 0 && (
                          <span
                            style={{
                              background: "#22B14C",
                              color: "white",
                              textAlign: "center",
                              borderRadius: "50px",
                              padding: "0.3em 0.58em",
                              fontSize: "11px",
                            }}
                          >
                            {tabs.successful}
                          </span>
                        )}
                        {tabs.failed > 0 && (
                          <span
                            style={{
                              background: "#e93434",
                              color: "white",
                              textAlign: "center",
                              borderRadius: "50px",
                              padding: "0.3em 0.58em",
                              fontSize: "11px",
                            }}
                          >
                            {tabs.failed}
                          </span>
                        )}
                      </span>
                    )
                  }
                  label={tabs.label}
                  {...a11yProps(i)}
                />
              ))}
            </Tabs>
          </Box>

          <TabPanel value={value} index={0} style={{ height: "93%" }}>
            {DataFileTab()}
          </TabPanel>

          <TabPanel value={value} index={1} style={{ height: "93%" }}>
            {ExecutedJobsTab()}
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
};
export default UploadDataForm;
