/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Grid,
  IconButton,
  CardContent,
  Divider,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Field, FieldArray } from "formik";
import langConstant from "_lang";
import ChildField from "./ChildField";
const ParentField = ({
  values = {},
  outerArrayHelpers = {},
  tabIndex = 0,
  index = 0,
  pLength = 0,
  initialValues = {},
  parentLogicalOperator = "",
  attributeDropdownOptions = [],
  dataTypeData = [],
}) => {
  return (
    <>
      <Box mt={2}>
        <Card key={index}>
          <Box p={2} style={{ background: "#efefef" }}>
            <Grid container spacing={2} align={"stretch"}>
              <Grid item>
                Match &nbsp; &nbsp;
                <label
                  htmlFor={`rules[${tabIndex}].parent[${index}].childLogicalOperator1`}
                >
                  <Field
                    id={`rules[${tabIndex}].parent[${index}].childLogicalOperator1`}
                    name={`rules[${tabIndex}].parent[${index}].childLogicalOperator`}
                    type="radio"
                    value="OR"
                  />
                  Any
                </label>
                <label
                  htmlFor={`rules[${tabIndex}].parent[${index}].childLogicalOperator2`}
                >
                  <Field
                    id={`rules[${tabIndex}].parent[${index}].childLogicalOperator2`}
                    name={`rules[${tabIndex}].parent[${index}].childLogicalOperator`}
                    type="radio"
                    value="AND"
                  />
                  All of the following
                </label>
              </Grid>
              <Grid item>
                {index == pLength - 1 && (
                  <Box style={{ marginTop: "-2px" }}>
                    <IconButton
                      color="primary"
                      aria-label="remove Rule"
                      component="span"
                      size="small"
                      onClick={() =>
                        outerArrayHelpers.push(initialValues.rules[0].parent[0])
                      }
                    >
                      <AddIcon />
                    </IconButton>
                    {index > 0 && (
                      <IconButton
                        color="primary"
                        aria-label="Add Rule"
                        component="span"
                        size="small"
                        onClick={() => outerArrayHelpers.remove(index)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          <CardContent>
            <FieldArray
              name={`rules[${tabIndex}].parent[${index}].child`}
              render={(innerArrayHelpers) => {
                return (
                  <>
                    {values.child.map((child, childIndex) => (
                      <>
                        <ChildField
                          key={"child" + index + childIndex}
                          innerArrayHelpers={innerArrayHelpers}
                          tabIndex={tabIndex}
                          childIndex={childIndex}
                          index={index}
                          initialValues={initialValues}
                          attributeDropdownOptions={attributeDropdownOptions}
                          dataTypeData={dataTypeData}
                        />
                        {childIndex != values.child.length - 1 && (
                          <Box my={2} component="div">
                            <Divider />
                          </Box>
                        )}
                      </>
                    ))}
                  </>
                );
              }}
            />
          </CardContent>
        </Card>
      </Box>
      {index < pLength - 1 && (
        <Box mt={2} align="center" style={{ fontSize: 18, fontWeight: "bold" }}>
          {parentLogicalOperator}
        </Box>
      )}
    </>
  );
};
ParentField.propTypes = {
  values: PropTypes.object,
  outerArrayHelpers: PropTypes.object,
  tabIndex: PropTypes.number,
  index: PropTypes.number,
  pLength: PropTypes.number,
  initialValues: PropTypes.object,
  parentLogicalOperator: PropTypes.string,
  attributeDropdownOptions: PropTypes.array,
  dataTypeData: PropTypes.array,
};
export default ParentField;
