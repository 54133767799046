import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import langConstant from "_lang";

const Graph = ({
  expandedPanelID,
  panelID,
  data,
  contentData,
  nonNullGroup,
  isGraphView,
}) => {
  const countPercentage = (partialValue, totalValue) => {
    return ((100 * partialValue) / totalValue).toFixed(2);
  };
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: isGraphView ? 6 : 1,
      }}
    >
      <ResponsiveContainer
        width={expandedPanelID === panelID ? (isGraphView ? 600 : 400) : "100%"}
        aspect={1}
      >
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={expandedPanelID === panelID ? 120 : 80}
            fill="#8884d8"
            dataKey="value"
            label={expandedPanelID === panelID ? true : false}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      {expandedPanelID === panelID && (
        <Paper
          sx={{
            p: 2,
            height: "auto",
            backgroundColor: "#00C49F",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: isGraphView ? "15%" : "auto",
            minHeight: isGraphView ? "150px" : "100px",
          }}
          elevation={2}
        >
          <div style={{ textAlign: "center" }}>
            <Typography
              variant="body1"
              sx={{ color: "white", fontWeight: "bold", fontSize: "24px" }}
            >
              {`${countPercentage(nonNullGroup?.length, contentData?.length)}%`}{" "}
            </Typography>
            <Typography variant="body1" sx={{ color: "white" }}>
              {langConstant.COMPLETED}
            </Typography>
          </div>
        </Paper>
      )}
    </Box>
  );
};

Graph.propTypes = {
  expandedPanelID: PropTypes.string,
  panelID: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  nonNullGroup: PropTypes.array,
  contentData: PropTypes.array,
  isGraphView: PropTypes.bool,
};

export default Graph;
