import { Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Typography } from "@material-ui/core";
import React from "react";
import FilterDropdown from "_components/FilterDropdown";
import PropTypes from "prop-types";
import Checkbox from '@mui/material/Checkbox';
import langConstant from "_lang";
import CloseIcon from '@mui/icons-material/Close';

const FilterComponent = ({  show, setShow, filterData, onFilterChange, documentLanguages, documentOwners, documentTypes, setFilterData, uploadedByOptions }) => {
    
    const handleClose = () => setShow(false)
    
    const archivedOption = [
        {value: 'all', name: "All"},
        {value: '1', name: "Archived"},
        {value: '0', name: "UnArchived"},
    ]

    const filtersList = [
        { options: archivedOption, fieldName: 'isArchived', lable: langConstant.ARCHIVED },
        { options: documentLanguages, fieldName: 'document_language', lable: langConstant.DOCUMENT_LANGUAGE },
        { options: documentOwners, fieldName: 'document_owner', lable: langConstant.DOCUMENT_OWNER },
        { options: documentTypes, fieldName: 'document_type', lable: langConstant.DOCUMENT_TYPES },
        { options: uploadedByOptions, fieldName: 'uploaded_by', lable: langConstant.UPLOADED_BY },
    ]

    return (
        <Dialog
            open={show}
            maxWidth={"sm"}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogTitle>
                <Grid container>
                    <Grid item xs={10} md={10}>
                        <Typography style={{fontWeight: "bold"}}>{langConstant.SET_FILTERS_CAPS}</Typography>
                    </Grid>
                    <Grid item xs={2} md={2} style={{textAlign: "right"}}>
                        <CloseIcon sx={{fontWeight: "bold", cursor: "pointer" }} onClick={handleClose}/>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {filtersList.map((item, index) => {
                        return (
                            <Grid item xs={6} md={6} key={index}>
                                <Typography>{item.lable} : </Typography>
                                <FilterDropdown 
                                    options={item.options}
                                    fieldName={item.fieldName}
                                    value={filterData[item.fieldName]}
                                    onChange={onFilterChange}
                                    icon={true}
                                />
                            </Grid>
                        )
                    })}
                    <Grid item xs={6} md={12}>
                        <FormControlLabel control={
                            <Checkbox 
                                onChange={(e) => { setFilterData({ ...filterData, isCheckBoxSelected: !filterData.isCheckBoxSelected }) }} 
                                sx={{'&.Mui-checked': { color: "#124571" }}}
                                checked={filterData.isCheckBoxSelected}
                            />
                            } 
                            label="Extracted Policy Document" 
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

FilterComponent.propTypes = {
    show: PropTypes.bool, 
    setShow: PropTypes.func, 
    filterData: PropTypes.object, 
    onFilterChange: PropTypes.func, 
    documentLanguages: PropTypes.array, 
    documentOwners: PropTypes.array, 
    documentTypes: PropTypes.array, 
    setFilterData: PropTypes.func,
    uploadedByOptions: PropTypes.array,
}


export default FilterComponent;