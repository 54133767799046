/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED.

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import constant from "./constants";
import apiService from "_services/api.service";
import { alertActions, loadingActions } from "_actions";
import langConstant from "_lang";

const getDraft = (id, goBackOnError) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetDraftData", { i_REGULATION_ID: id })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let payload = { draft: [], activeStep: 0 };

          const userID = response["#result-set-1"][0].USER_ID;
          const teamleaderID = response["#result-set-1"][0].TeamLeaderID;
          const managerID = response["#result-set-1"][0].ManagerID;

          const functionWithPromise = (item) => {
            return Promise.resolve(item);
          };
          const anAsyncFunction = async (item) => {
            return functionWithPromise(item);
          };

          try {
            const parseJson = JSON.parse(response["#result-set-1"][0].PAYLOAD);

            if (parseJson.draft) {
              payload = {
                draft: parseJson.draft,
                activeStep: parseJson.activeStep,
              };
            } else {
              payload = { draft: parseJson, activeStep: 0 };
            }
            const getData = async () => {
              return Promise.all(
                payload.draft.map((item, index) => {
                  const newItem = {
                    ...item,
                    id: item.id ? item.id : "Q" + (index + 1),
                    selected: item.selected ? item.selected : false,
                    selectedMatch: item.selectedMatch ? item.selectedMatch : "",
                    ref: item.ref ? item.ref : "Q" + (index + 1),
                    info: item.info ? item.info : "",
                    matches: item.matches ? item.matches : [],
                    mappedControl: item.mappedControl ? item.mappedControl : [],
                    narratives: item.narratives ? item.narratives : "",
                    selectedForReview: false,
                    status: item.status ? item.status : null,
                    category: item.mappedControl[0]
                      ? item.mappedControl[0].control
                      : null,
                    remark: item.remark ? item.remark : "",
                  };

                  return anAsyncFunction(newItem);
                })
              );
            };

            getData().then((data) => {
              dispatch({
                type: constant.approveRegulationConstant_GET,
                data: {
                  draft: data,
                  activeStep: payload.activeStep,
                  USER_ID: userID,
                  TL_ID: teamleaderID,
                  MANAGER_ID: managerID,
                },
              });
            });
          } catch (e) {
            dispatch({ type: constant.approveRegulationConstant_FAILURE });
            dispatch(alertActions.error(langConstant.ERROR_IN_QUESTIONS_LIST));
            goBackOnError();
            console.log(e, true);
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({ type: constant.approveRegulationConstant_FAILURE });
          goBackOnError();
        }
      );
  };
};

const updateSingleItemInDraft = (obj) => {
  return (dispatch) => {
    dispatch({
      type: constant.approveRegulationConstant_UPDATE_SINGLE_ITEM,
      data: obj,
    });
  };
};
const updateMultipleItemsInDraft = (
  draft,
  userID,
  teamleaderID,
  managerID,
  activeStep = 0
) => {
  return (dispatch) => {
    dispatch({
      type: constant.approveRegulationConstant_UPDATE,
      data: {
        draft: draft,
        activeStep: activeStep,
        USER_ID: userID,
        TL_ID: teamleaderID,
        MANAGER_ID: managerID,
      },
    });
  };
};

const updateDraft = (
  id,
  draft,
  userID,
  teamleaderID,
  managerID,
  activeStep = 0,
  alertMsg = null
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutDraftData", {
        i_REGULATION_ID: id,
        i_PAYLOAD: JSON.stringify({ draft: draft, activeStep: activeStep }),
        i_USER_ID: userID,
        i_TL_ID: teamleaderID,
        i_MANAGER_ID: managerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch({
            type: constant.approveRegulationConstant_UPDATE,
            data: {
              draft: draft,
              activeStep: activeStep,
              USER_ID: userID,
              TL_ID: teamleaderID,
              MANAGER_ID: managerID,
            },
          });
          if (alertMsg == null) {
            dispatch(
              alertActions.success(langConstant.DRAFT_UPDATED_SUCCESSFULLY)
            );
          } else {
            dispatch(alertActions.success(alertMsg));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
const processDraft = (
  id,
  draft,
  teamleaderID,
  managerID,
  userId,
  customerId,
  filePath
) => {
  return (dispatch) => {
    // Get regulation details

    var REGULATION_NAME = "";
    var REGULATOR_ID = "";
    var COUNTRY_CODE = "";

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRegulations", {
        i_USER_ID: userId,
        i_CUSTOMER_ID: customerId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#result-set-1"]) {
            const regulations = response["#result-set-1"];

            const regulation = regulations.filter((item) => {
              return item.REGULATION_ID == id;
            });

            REGULATION_NAME = regulation[0].REGULATION_NAME;
            REGULATOR_ID = regulation[0].REGULATOR_ID;
            COUNTRY_CODE = regulation[0].COUNTRY_CODE;

            const data = {
              regulationId: id,
              regulationName: REGULATION_NAME,
              regulatorId: REGULATOR_ID,
              countryCD: COUNTRY_CODE,
              managerId: managerID,
              teamLeaderId: teamleaderID,
              draftFile: { draft: draft },
              bucketName: window.BUCKET_NAME,
              fileLocation: filePath,
            };

            apiService
              .apiCall("pdfprocessor")
              .send(`/api/v1/process-draft`, data)
              .then(apiService.handleResponse)
              .then(
                (response) => {},
                (error) => {
                  dispatch(loadingActions.end());
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                  ) {
                    dispatch(
                      alertActions.error(
                        error.response.data.message.map((item, index) => {
                          return <div key={index}>{item}</div>;
                        })
                      )
                    );
                  } else {
                    dispatch(alertActions.error(error.toString()));
                  }
                }
              );

            // ---------------------------------------
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateRegulationStatus = (
  id,
  status,
  callback = null,
  regulationDetails = null
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutRegulationStatus", {
        i_REGULATION_ID: id,
        i_STATUS: status,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (status == "AVAILABLE") {
            callback();
            dispatch(alertActions.success(langConstant.REGULATION_APPROVED));
          } else if (status == "IN_PROGRESS") {
            if (callback != null) {
              callback();
              dispatch(
                alertActions.success(
                  langConstant.REGULATION_SENT_BACK_ALERT(
                    regulationDetails.name,
                    regulationDetails.initiator
                  )
                )
              );
            }
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export default {
  getDraft,
  updateSingleItemInDraft,
  updateMultipleItemsInDraft,
  updateDraft,
  processDraft,
  updateRegulationStatus,
};
