// OP#3052 - UJC5.1 - Assign Assessment - Extension Features
// Email-Autocomplete component for the emailDialog component of step2 screen

import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Typography , Tooltip} from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';


const AutocompleteEmail = ({ onChangeValue, placeholder = "Email", value = [] }) => {
  const [selected, setSelected] = useState(value);
  const [inputValue, setInputValue] = useState("");
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [error, setError] = useState(false);

  const onChange = (e, value) => {
    // error
    const errorEmail = value.find((email) => !regex.test(email));
    if (errorEmail) {
      // set value displayed in the textbox
      setInputValue(errorEmail);
      setError(true);
    } else {
      setError(false);
    }
    // Update state
    const selectedData = value.filter((email) => regex.test(email))
    setSelected(selectedData);
    onChangeValue(selectedData)
  }

  const onDelete = (value) => {
    setSelected(selected.filter((e) => e !== value));
  }

  const onInputChange = (e, newValue) => {
    setInputValue(newValue);
  }

  return (
    <>
      <Tooltip placement="left"
        title={
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div>
              <InfoIcon />
            </div>
            <div style={{ textAlign: "center", marginLeft: "5px", fontSize: "12px" }}> Type Email and Press Enter</div>
          </div>
        }
      >
        <Autocomplete
          size="small"
          limitTags={3}
          multiple
          freeSolo
          onChange={onChange}
          id="tags-filled"
          value={selected}
          inputValue={inputValue}
          onInputChange={onInputChange}
          options={[]}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                key={index}
                label={option}
                {...getTagProps({ index })}
                onDelete={() => onDelete(option)}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              // variant="filled"
              // label="Email Addresses"
              placeholder={placeholder}
              type="email"
              error={error}
            // helperText={error && "Please enter a valid email address"}
            />
          )}
        />
      </Tooltip>
      { error && <Typography style={{ color: "red", fontSize: "12px" }}>{"Please enter a valid email address"}</Typography>}
    </>
  )
}

AutocompleteEmail.propTypes = {
  onChangeValue: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.array,
};



export default AutocompleteEmail