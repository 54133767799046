import { Box, makeStyles } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import CompareTabPanel from "./CompareTabPanel";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import InsightGraph from "./InsightGraph";

const useStyles = makeStyles(() => ({
  TabTitle: {
    fontWeight: "700 !important",
    padding: "0px !important",
    height: "auto",
    minHeight: "unset !important",
    maxWidth: "unset !important",
    width: "auto",
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    position: "absolute !important",
    fontSize: "13px",
    "&:nth-child(1)": {
      top: "33px",
      right: "-32px",
    },
    "&:nth-child(2)": {
      top: "130px",
      right: "-32px",
    },
    "&:nth-child(3)": {
      top: "225px",
      right: "-32px",
    },
    "& span": {
      display: "none !important",
    },
  },
  summerayCompareTabsWrap: {
    display: "inline-flex !important",
    position: "absolute",
    top: "0",
    left: "-15px",
    "& + .MuiBox-root": {
      paddingLeft: "20px !important",
    },
    "& .MuiTabs-flexContainer": {
      height: "350px",
      position: "relative",
      width: "25px",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    "& .MuiTabs-indicator": {
      width: "2px",
      padding: "0px !important",
    },
  },
  drawerOpen: {
    "& > div": {
      summerayCompareTabsWrap: {
        left: "257px",
      },
    },
  },
  positionRelative: {
    position: "relative",
    height: "100vh",
  },
}));

const ComparisonDetail = () => {
  const classes = useStyles();
  const navigationDetails = useSelector(
    (state) => state.authentication.detailedInfo.NAVIGATION_DETAILS
  );

  const [isInsightAvailable, setIsInsightAvailable] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (navigationDetails && navigationDetails.length > 0) {
      setIsInsightAvailable(
        navigationDetails?.some(
          (el) => el.ENTITLEMENT_NAME === "POLICY_MANAGEMENT/INSIGHTS"
        )
      );
    }
  }, [navigationDetails]);

  const { id } = useParams();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box className={classes.positionRelative}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        TabIndicatorProps={{ style: { padding: 2 } }}
        orientation="vertical"
        variant="scrollable"
        className={classes.summerayCompareTabsWrap}
      >
        {/* <Tab aria-orientation="vertical" className={classes.TabTitle} label={langConstant.STATUS} /> */}
        <Tab
          aria-orientation="horizontal"
          className={classes.TabTitle}
          label={langConstant.DETAILS}
        />
        {isInsightAvailable && (
          <Tab
            aria-orientation="horizontal"
            className={classes.TabTitle}
            label={langConstant.INSIGHT}
          />
        )}
      </Tabs>
      {/* {tabValue === 0 && 
                <Box hidden={tabValue !== 0}>
                    <StatusGraph />
                </Box>
            } */}
      {tabValue === 0 && (
        <Box hidden={tabValue !== 0}>
          <CompareTabPanel />
        </Box>
      )}
      {tabValue === 1 && (
        <Box hidden={tabValue !== 1}>
          <InsightGraph />
        </Box>
      )}
    </Box>
  );
};

export default ComparisonDetail;
