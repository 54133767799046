import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import langConstant from "_lang";
import useStyles from "../../styles";
import CloseIcon from "@mui/icons-material/Close";
import PolicyMapTable from "./policyMapTable";
import SentenceView from "./sentenceView";

const SentenceMappingDialog = ({
  show,
  setShow,
  policyMappingData,
  regulationID,
  questionID,
  sentenceMappingData,
  sentenceView,
  setSentenceView,
  setSentenceMappingData,
}) => {
  const classes = useStyles();

  const handleClose = () => setShow(false);

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className={classes.policyMapDialogHeader}>
            {langConstant.SENTENCE_MAPPING}
          </Typography>
          <span
            style={{
              textAlign: "right",
              alignItems: "right",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <CloseIcon onClick={handleClose} />
          </span>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {sentenceView.isSentenceView ? (
          <SentenceView
            sentenceView={sentenceView}
            setSentenceView={setSentenceView}
            handleClose={handleClose}
            regulationID={regulationID}
            questionID={questionID}
            setSentenceMappingData={setSentenceMappingData}
            sentenceMappingData={sentenceMappingData}
          />
        ) : (
          <PolicyMapTable
            policyMappingData={policyMappingData}
            setSentenceView={setSentenceView}
            handleClose={handleClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

SentenceMappingDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  policyMappingData: PropTypes.array,
  regulationID: PropTypes.string,
  questionID: PropTypes.string,
  sentenceMappingData: PropTypes.array,
  sentenceView: PropTypes.object,
  setSentenceView: PropTypes.func,
  setSentenceMappingData: PropTypes.func,
};

export default SentenceMappingDialog;
