import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import sessionService from "_services/session.service";
import { GetAssessmentsForManageReview } from "./utils/services";
import ReviewAssessmentStep from "_components/MasterAssessmentComp/steps/ReviewAssessmentStep";
import { useParams } from "react-router";
import { ReviewManagerCleanUpFunc } from "./utils/actions";
import langConstant from "_lang";

const ReviewManagerAssessment = () => {
  const userID = sessionService.getUserId();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [IsDataLoaded, setIsDataLoaded] = useState(false);
  const [ReviewScreenStatus, setReviewScreenStatus] = useState(null);
  const questionList = useSelector(
    (state) => state.reviewManagerReducer.QuestionDetails
  );
  const observationList = useSelector(
    (state) => state.reviewManagerReducer.ObservationDetails
  );
  const trustScoreList = useSelector(
    (state) => state.reviewManagerReducer.TrustScoreForAllQuestion
  );

  // ON REJECT CALLBACK FUNC REMAINING

  const callBackFunc = (data) => {
    let tempStatus = { All: "All", "Sent Back": "SENT_BACK" };

    // if (true) {
    tempStatus["Reviewed"] = "COMPLETED_AND_LOCKED";
    tempStatus["Reject_status"] = "PENDING_FOR_REVIEW";
    tempStatus["Return_To_Name"] = data?.["#result-set-2"]?.[0]?.["TL_NAME"];
    tempStatus["Primary_btn_text"] = langConstant.SUBMIT_TO_NEXT_APPROVER;
    tempStatus["Final_assessment_status"] = "SUBMITTED_TO_REGULATOR";
    // }

    // if (true) {
    //   tempStatus["Reviewed"] = "FINAL";
    //   tempStatus["Reject_status"] = "RETURNED";
    //   tempStatus["Return_To_Name"] = data["#result-set-2"][0]["ANALYST_NAME"];
    //   tempStatus["Primary_btn_text"] = langConstant.SUBMIT_TO_MANAGER;
    //   tempStatus["Final_assessment_status"] = "FINAL_REVIEW";
    // }

    setReviewScreenStatus(tempStatus);
    setIsDataLoaded(true);
  };

  const DataSetter = async () => {
    return await new Promise((resolve) => {
      setTimeout(async () => {
        const temp = await dispatch(
          GetAssessmentsForManageReview(id, userID, callBackFunc)
        );
        resolve("resolved");
      }, 0);
    });
  };

  useEffect(() => {
    dispatch(ReviewManagerCleanUpFunc());
    (async () => {
      if (id && userID) {
        await DataSetter();
      }
    })();
  }, [id]);

  return (
    <Box>
      {questionList.length > 0 && IsDataLoaded === true && (
        <ReviewAssessmentStep
          QuestionList={questionList}
          ReviewScreenStatus={ReviewScreenStatus}
          observationList={observationList}
          trustScoreList={trustScoreList}
          identifier="reviewmanager"
        />
      )}
    </Box>
  );
};

export default ReviewManagerAssessment;
