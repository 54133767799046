/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import { makeStyles } from "@material-ui/core";
const inputStyles = makeStyles((theme) => ({
  inputWrap: {
    backgroundColor: "#f8fcfd",
    display: "flex",
    justifyContent: "stretch",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "10px",
    padding: "12px 25px",
  },
  inputField: {
    backgroundColor: "white",
    overflow: "hidden",
    flex: "1",
  },
  postButton: {
    marginLeft: "10px",
    backgroundColor: ({ actionButtonColor }) => actionButtonColor,
    color: "white",
    width: "110px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

//---------------------------------------------

const itemStyles = makeStyles((theme) => ({
  itemWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px 0px",
  },
  imgWrap: {
    height: "36px",
    width: "36px",
    backgroundColor: "#c1c1c1",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
  commentWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 10px",
    boxShadow: "0px 1px 4px 1.5px #ddd",
    flex: "1",
    borderRadius: "5px",
  },
  timestamp: {
    margin: "0 10px",
    opacity: "70%",
  },
}));

export { inputStyles, itemStyles };
