/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import { Paper, Tabs, Badge, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Tab } from "@mui/material";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";

const styles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
    borderBottom: "1px solid #F2F2F2",
  },
  badge: {
    background: "#e93434",
    color: "#fff",
  },
  rating: {
    background: "#f9a3ae",
    color: "black",
  },
  ratingHighest: {
    background: "#9cf1aa",
    color: "black",
  },
}));

const AssessmentTabs = ({
  selectedTab,
  onChange,
  tabs = [],
  bottomTabs = false,
  currentQuestion,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = styles();
  const [HighestScore, setHighestScore] = useState(8);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (bottomTabs) {
      let tempScore = 0;
      if (currentQuestion?.HIST_ANSWER_RESPONSE_01) {
        if (currentQuestion?.HIST_ANSWER_MATCH_SCORE_01 > tempScore) {
          tempScore = currentQuestion?.HIST_ANSWER_MATCH_SCORE_01;
        }
      }
      if (currentQuestion?.HIST_ANSWER_RESPONSE_02) {
        if (currentQuestion?.HIST_ANSWER_MATCH_SCORE_02 > tempScore) {
          tempScore = currentQuestion?.HIST_ANSWER_MATCH_SCORE_02;
        }
      }
      if (currentQuestion?.HIST_ANSWER_RESPONSE_03) {
        if (currentQuestion?.HIST_ANSWER_MATCH_SCORE_03 > tempScore) {
          tempScore = currentQuestion?.HIST_ANSWER_MATCH_SCORE_03;
        }
      }
      setHighestScore(tempScore);
    }
  }, [currentQuestion, tabs]);

  const getClassName = (score) => {
    return `rating${HighestScore == score ? "Highest" : ""}`;
  };

  return (
    <Paper square className={classes.paper}>
      <Tabs
        value={selectedTab.id ? selectedTab.id : 0}
        indicatorColor="primary"
        textColor="primary"
        onChange={onChange}
        aria-label="full width tabs example"
        style={{ height: "4rem" }}
        TabIndicatorProps={{
          style: {
            height: 4,
          },
        }}
      >
        {tabs.map((item, index) => {
          return (
            <Tab
              key={index}
              {...a11yProps(index)}
              disabled={
                !item?.count &&
                item?.id !== 0 &&
                item?.id !== 1 &&
                item?.id !== 2
              }
              wrapped
              // style={{minWidth:"140px"}}
              iconPosition="end"
              sx={{
                "&.Mui-selected": {
                  color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                },
              }}
              icon={
                <span hidden={!item?.count} style={{ marginLeft: "1.25rem" }}>
                  <Badge
                    badgeContent={item?.count}
                    // classes={{ badge: classes.badge }}
                    classes={{
                      badge: bottomTabs
                        ? item?.count
                          ? classes[getClassName(item.count)]
                          : classes.badge
                        : classes.badge,
                    }}
                    max={9999}
                  />
                </span>
              }
              label={item?.name}
            />
          );
        })}
      </Tabs>
    </Paper>
  );
};
AssessmentTabs.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.object,
  onChange: PropTypes.func,
  bottomTabs: PropTypes.bool,
  currentQuestion: PropTypes.object,
};
export default AssessmentTabs;
