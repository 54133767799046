/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import { alertActions, loadingActions } from "../../../../_actions";
import apiService from "../../../../_services/api.service";
import langConstant from "_lang";

// i_USER_ID INT,
// i_FILE_NAME VARCHAR(255),
// i_FILE_TYPE VARCHAR(5),
// i_FILE_LOCATION VARCHAR(255),
// i_FILE_CONTENT VARCHAR(MAX),
// i_COUNTRY_CODE VARCHAR(2),
// i_REGULATION_ID INT

const updateRawData = (
  userID,
  fileName,
  fileType,
  filePath,
  countryCode,
  regulationID
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutRawData", {
        i_USER_ID: userID,
        i_FILE_NAME: fileName,
        i_FILE_TYPE: fileType,
        i_FILE_LOCATION: filePath,
        i_FILE_CONTENT: "",
        i_COUNTRY_CODE: countryCode,
        i_REGULATION_ID: regulationID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            dispatch(alertActions.success("Raw data updated successfully."));
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateDraft = (id, draft) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutDraftData", {
        i_REGULATION_ID: id,
        i_PAYLOAD: draft,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            dispatch(alertActions.success("Draft data updated successfully."));
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const UploadFileOnS3Bucket = async (payload) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/upload/fileUploadOnS3", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error?.response) {
          return error?.response;
        }
      }
    );
};

const uploadFile = (params, headers, redirectTo, fileUploadProgress) => {
  return (dispatch) => {
    dispatch(loadingActions.startFileUpload());
    apiService
      .apiCall("pdfprocessor", headers)
      .send("/api/v1/saveRawContent", params, fileUploadProgress)
      .then(apiService.handleResponse)
      .then(
        () => {
          dispatch(loadingActions.endFileUpload());
          dispatch(
            alertActions.success(langConstant.FILE_UPLOADED_SUCCESSFULLY)
          );
          redirectTo();
        },
        (error) => {
          dispatch(loadingActions.endFileUpload());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export default {
  updateRawData,
  updateDraft,
  UploadFileOnS3Bucket,
  uploadFile,
};
