import React from "react";
import _ from "lodash";
import propTypes from "prop-types";

const Separator = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style} />
    </div>
  );
};

Separator.propTypes = {
  turns: propTypes.number,
  style: propTypes.object,
};

const RadialSeparators = (props) => {
  const turns = 1 / props.count;
  return _.range(props.count).map((index) => (
    <Separator key={index} turns={index * turns} style={props.style} />
  ));
};

export default RadialSeparators;
