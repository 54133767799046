import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  formFieldLabel: {
    fontWeight: "bold",
    fontSize: "16px",
    width: "35%",
  },
  classificationSelectForm: {
    width: "80%",
  },
  classificationFormWrapper: {
    border: "2px Solid grey",
    borderRadius: "5px",
    padding: "10px",
    minHeight: "300px",
    height: "100%",
  },
  sectionHeader: {
    fontWeight: "bold",
  },
  selectCustText: {
    fontWeight: "bold",
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "300px",
  },
}));

export default useStyles;
