import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Paper,
  IconButton,
  Button,
  TextField,
  makeStyles,
  Grid,
  Checkbox,
} from "@material-ui/core";
import { ErrorMessage, Field, Formik, Form, FieldArray } from "formik";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { alertActions, loadingActions } from "_actions";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { PutEvidenceType, getDomainList } from "../../services";
import langConstant from "_lang";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import sessionService from "_services/session.service";

const useStyles = makeStyles(() => ({
  title: { display: "flex", alignItems: "center", padding: ".75rem" },
  error: { color: "red", fontSize: "12px" },
  // textField: {
  //   "& .MuiOutlinedInput-root": {
  //     "& fieldset": {
  //       border: "none",
  //     },
  //   },
  // },
  wideColumn: {
    width: "30%",
  },
  normalColumn: {
    width: "10%",
  },
  regColumn: {
    width: "20%",
  },
}));

const validationSchema = Yup.object().shape({
  rows: Yup.array().of(
    Yup.object().shape({
      Evidence_Shortname: Yup.string().required(
        "Evidence_Shortname is required"
      ),
      Evidence_Description: Yup.string().required(
        "Evidence_Description is required"
      ),
      Regulatory_Domain: Yup.string().required("Regulatory_Domain is required"),
    })
  ),
});

const BulkEvidenceUpload = ({ BulkEvidenceModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authentication.detailedInfo);
  const options = BulkEvidenceModal?.data.fileContent;
  const fileContent = BulkEvidenceModal.data.fileContent;
  const fileName = BulkEvidenceModal.data.fileName;
  const userID = BulkEvidenceModal.data.userID;
  const customerID = sessionService.getCustomerId();

  const username = useSelector(
    (state) => state.authentication.detailedInfo?.USER_NAME
  );
  const [domainData, setDomainData] = useState([]);
  const [selectedEvidences, setSelectedEvidences] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const domainList = domainData.map((domain) => {
    return {
      value: domain.REGULATORY_DOMAIN_ID,
      label: domain.REGULATION_DOMAIN,
    };
  });

  const calculatePageCount = (rows) => {
    return rows?.length > 0
      ? Math.round(Math.ceil(rows?.length / rowsPerPage))
      : 0;
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      fileContent.length > 0
        ? Math.round(Math.ceil(fileContent.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSave = async (rowData) => {
    const currentDate = new Date();

    const pad = (number) => (number < 10 ? `0${number}` : number);

    const year = currentDate.getFullYear();
    const month = pad(currentDate.getMonth() + 1);
    const day = pad(currentDate.getDate());
    const hours = pad(currentDate.getHours());
    const minutes = pad(currentDate.getMinutes());
    const seconds = pad(currentDate.getSeconds());

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const DataObj = {
      i_EVIDENCE_TYPE_ID: null,
      i_USER_ID: userID,
      i_EVIDENCE_SHORTNAME: rowData.Evidence_Shortname.trim(),
      i_EVIDENCE_DESCRIPTION: rowData.Evidence_Description.trim(),
      i_EVIDENCE_KEYWORDS: rowData.Keywords ? rowData.Keywords.trim() : "",
      i_REGULATORY_DOMAIN_ID: parseInt(rowData.Regulatory_Domain, 10),
      i_OWNER: rowData.Evidence_Owner ? rowData.Evidence_Owner.trim() : "",
      i_EVIDENCE_SOURCE: rowData.Evidence_Source
        ? rowData.Evidence_Source.trim()
        : "",
      i_DESCRIPTION: "Internal",
      i_ACTIVE: 1,
      i_START_DATE: formattedDate,
      i_END_DATE: null,
      i_EVIDENCE_TEMPLATE: null,
      i_EVIDENCE_CONTACT: username,
      i_EVIDENCE_URL_TO_SOURCE: null,
      i_EXPIRY_PERIODICITY: "1M",
      i_CUSTOMER_ID: customerID,
    };

    try {
      const response = await PutEvidenceType(DataObj);

      if (response.status !== 200) {
        dispatch(alertActions.error("Evidence not saved error: ", response));
      } else {
        dispatch(alertActions.success("Evidence Saved."));
      }
    } catch (error) {
      dispatch(
        alertActions.error("An error occurred while saving evidence:  ", error)
      );
    }
  };

  const createValidationSchema = () => {
    return Yup.object().shape({
      rows: Yup.array().of(
        Yup.object().shape({
          Evidence_Shortname: Yup.string().when("EVIDENCE_SELECTED", {
            is: true,
            then: Yup.string().required("Evidence_Shortname is required"),
          }),
          Evidence_Description: Yup.string().when("EVIDENCE_SELECTED", {
            is: true,
            then: Yup.string().required("Evidence_Description is required"),
          }),
          Regulatory_Domain: Yup.string().when("EVIDENCE_SELECTED", {
            is: true,
            then: Yup.string().required("Regulatory_Domain is required"),
          }),
        })
      ),
    });
  };

  const onSubmit = (values) => {
    for (let row of values.rows) {
      if (row.EVIDENCE_SELECTED) handleSave(row);
    }
    //   BulkEvidenceModal?.closeModal();
  };

  useEffect(() => {
    const fetchDomainData = async () => {
      dispatch(loadingActions.start());
      const domainResponse = await getDomainList();
      dispatch(loadingActions.end());
      if (domainResponse && domainResponse["#result-set-1"]) {
        setDomainData(domainResponse["#result-set-1"]);
      } else {
        if (domainResponse.data && domainResponse.data.message) {
          dispatch(
            alertActions.error(
              domainResponse.data.message.map((item, index) => (
                <div key={index}>{item}</div>
              ))
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    };

    fetchDomainData();
  }, [dispatch]);

  useEffect(() => {
    calculatePageCount(BulkEvidenceModal.data.fileContent);
  }, [BulkEvidenceModal?.data]);

  return (
    <Dialog
      open={BulkEvidenceModal?.isOpen}
      maxWidth="xl"
      fullWidth
      keepMounted
      onClose={BulkEvidenceModal?.closeModal}
    >
      <DialogTitle className={classes.title}>
        <IconButton
          style={{ position: "absolute", right: 4 }}
          aria-label="close"
          onClick={BulkEvidenceModal?.closeModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ rows: fileContent }}
          validationSchema={createValidationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ values, errors, setFieldValue, handleChange }) => (
            <Form>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Typography style={{ flex: 1 }} variant="h6" align="center">
                  {fileName}
                </Typography>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const updatedSelectedEvidences = values.rows.map(
                      (_, index) => `${index}`
                    );
                    setSelectedEvidences(updatedSelectedEvidences);

                    setFieldValue(
                      "rows",
                      values.rows.map((row) => ({
                        ...row,
                        EVIDENCE_SELECTED: true,
                      }))
                    );
                  }}
                  style={{ marginRight: 10 }}
                >
                  Select All
                </Button>
                <Button
                  type="submit"
                  //  onClick={(values) => handleSaveSelectedEvidence(values)}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
              <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Evidence ShortName</TableCell>
                      <TableCell>Evidence Description</TableCell>
                      <TableCell>Evidence Owner</TableCell>
                      <TableCell>Evidence Source</TableCell>
                      <TableCell>Keywords</TableCell>
                      <TableCell>Regulatory Domain</TableCell>
                      <TableCell>Select</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="rows">
                      {({ insert, remove, push }) => (
                        <>
                          {values.rows.length > 0 &&
                            (rowsPerPage > 0
                              ? values.rows.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              : values.rows
                            ).map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  className={classes.wideColumn}
                                  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.Evidence_Shortname`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.Evidence_Shortname}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                    inputProps={{
                                      style: {
                                        whiteSpace: "normal",
                                        wordBreak: "break-all",
                                      },
                                    }}
                                    multiline
                                  />
                                  <ErrorMessage
                                    name={`rows.${index}.Evidence_Shortname`}
                                    component="div"
                                    className={classes.error}
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.wideColumn}
                                  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.Evidence_Description`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.Evidence_Description}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                    inputProps={{
                                      style: {
                                        whiteSpace: "normal",
                                        wordBreak: "break-all",
                                      },
                                    }}
                                    multiline
                                  />
                                  <ErrorMessage
                                    name={`rows.${index}.Evidence_Description`}
                                    component="div"
                                    className={classes.error}
                                  />
                                </TableCell>
                                <TableCell className={classes.normalColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.Evidence_Owner`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      row.Evidence_Owner
                                        ? row.Evidence_Owner
                                        : ""
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.normalColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.Evidence_Source`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      row.Evidence_Source
                                        ? row.Evidence_Source
                                        : ""
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.normalColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.Keywords`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.Keywords ? row.Keywords : ""}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.regColumn}>
                                  <Field
                                    style={{ width: "100%" }}
                                    name={`rows.${index}.Regulatory_Domain`}
                                  >
                                    {({
                                      field: { value },
                                      form: { setFieldValue },
                                    }) => (
                                      <Select
                                        options={domainList}
                                        placeholder="Select Domain"
                                        menuPortalTarget={document.body}
                                        value={domainList.find(
                                          (domain) =>
                                            domain.value ==
                                            row.Regulatory_Domain
                                        )}
                                        onChange={(domain) =>
                                          setFieldValue(
                                            `rows.${index}.Regulatory_Domain`,
                                            domain.value
                                          )
                                        }
                                      />
                                    )}
                                  </Field>

                                  <ErrorMessage
                                    name={`rows.${index}.Regulatory_Domain`}
                                    component="div"
                                    className={classes.error}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    name={`rows.${index}.EVIDENCE_SELECTED`}
                                    checked={selectedEvidences.includes(
                                      `${index}`
                                    )}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rows.${index}.EVIDENCE_SELECTED`,
                                        e.target.checked
                                      );

                                      if (e.target.checked) {
                                        setSelectedEvidences(
                                          (prevEvidences) => [
                                            ...prevEvidences,
                                            `${index}`,
                                          ]
                                        );
                                      } else {
                                        setSelectedEvidences((prevEvidences) =>
                                          prevEvidences.filter(
                                            (item) => item !== `${index}`
                                          )
                                        );
                                      }
                                    }}
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#124571",
                                      },
                                      display: "inline",
                                    }}
                                    size="small"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </>
                      )}
                    </FieldArray>
                  </TableBody>
                </Table>
              </TableContainer>
            </Form>
          )}
        </Formik>
        <Box my={2}>
          <Grid container style={{ justifyContent: "end" }} justify="flex-end">
            {fileContent?.length > 0 && (
              <SelectPageRange
                selectedRange={rowsPerPage}
                handleChange={handleSelectPageRange}
              />
            )}
            {pageCount > 0 && (
              <AppPagination
                colSpan={3}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

BulkEvidenceUpload.propTypes = {
  BulkEvidenceModal: PropTypes.object.isRequired,
};

export default BulkEvidenceUpload;
