/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import { Typography, Box, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      },
    },
  },
});

const QuestionItem = ({ question, setPageNumber, completed }) => {
  let statusColor;

  switch (question.QUESTION_STATUS) {
    case "DRAFT":
      statusColor = "rgba(255, 252, 210, 1)";
      break;
    case "PENDING_DATA":
      statusColor = "rgba(255, 248, 156, 1)";
      break;
    case "READY_FOR_REVIEW":
      statusColor = "rgba(175, 243, 187, 0.7)";
      break;
    case "FINAL":
      statusColor = "rgba(175, 243, 187, 1)";
      break;
    case "COMPLETED_AND_LOCKED":
      statusColor = "rgba(175, 243, 187, 1)";
      break;
    case "RFI_SENT":
      statusColor = "rgba(123, 192, 255, 1)";
      break;
    case "RFI_PENDING":
      statusColor = "rgba(255, 248, 156, 1)";
      break;
    case "RFI_OVERDUE":
      statusColor = "rgba(255, 188, 188, 1)";
      break;
    case "RFI_RESOLVED":
      statusColor = "rgba(175, 243, 187, 0.8)";
      break;
    case "SENT_BACK":
      statusColor = "rgba(255, 188, 188, 1)";
      break;

    default:
    // code block
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip title={question.QUESTION_TEXT} placement="top">
        <Button
          style={{
            width: "80px",
            backgroundColor: `${
              completed ? "rgba(175, 243, 187, 1)" : statusColor
            }`,
            margin: "10px 20px 10px 0",
            borderRadius: "5px",
            padding: "2px",
          }}
          onClick={() => setPageNumber(question.PAGE_NUMBER)}
        >
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              wordBreak: "break-word",
              wordWrap: "break-word",
            }}
            noWrap={false}
          >
            {question.REGULATORS_QUESTION_REF &&
              question.REGULATORS_QUESTION_REF.substring(0, 20)}
          </Typography>
        </Button>
      </Tooltip>
    </MuiThemeProvider>
  );
};

QuestionItem.propTypes = {
  question: PropTypes.object,
  setPageNumber: PropTypes.func,
  completed: PropTypes.bool,
};

export default QuestionItem;
