/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import { IconButton, Badge, Box, Button } from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { makeStyles } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import langConstant from "_lang";
import NotificationContent from "./component/NotificationContent/index.jsx";
import { useSelector, useDispatch } from "react-redux";
import notificationsActions from "_actions/notifications.action";
import sessionService from "_services/session.service";

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#F0142F",
  },
  notificationBell: {
    alignSelf: "center",
  },
  countWrapper: {
    background: "#c13636",
    color: "white",
    borderRadius: "5px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "max-content",
    padding: "2px 5px",
    fontSize: "12px",
    position: "absolute",
    top: "-1px",
    right: "0",
  },
  badgeWrap: (props) => ({
    position: "relative",
    color: props.fontColor,
  }),
  sectionDesktop: {
    alignItems: "center",
  },
}));

const NotificationsPopup = () => {
  const fontColor = window.THEME_FONT_COLOR;
  const classes = useStyles({ fontColor });
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();

  useEffect(() => {
    dispatch(notificationsActions.getUserNotifications(userID));
  }, []);

  const allNotifications = useSelector((state) => state.notificationsList);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState({ name: "QUESTION", id: 0 });

  const unreadNotificationsNumber = allNotifications.filter(
    (notificationItem) => notificationItem.READ_NOTIFICATION === "N"
  ).length;

  const openNotifications = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeNotifications = () => {
    setAnchorEl(null);
  };

  const onChange = (e, newValue) => {
    switch (newValue) {
      case 0:
        setSelectedTab({ name: "QUESTION", id: 0 });
        break;
      case 1:
        setSelectedTab({ name: "ASSESSMENT", id: 1 });
        break;
      case 2:
        setSelectedTab({ name: "RFI", id: 2 });
        break;
      case 3:
        setSelectedTab({ name: "USER", id: 3 });
        break;
      case 4:
        setSelectedTab({ name: "POLICY", id: 4 });
        break;
      case 5:
        setSelectedTab({ name: "REGULATION", id: 5 });
        break;
      case 6:
        setSelectedTab({ name: "SERVICE", id: 6 });
        break;
      case 7:
        setSelectedTab({ name: "SUPPORT", id: 7 });
        break;
      default:
        setSelectedTab({ name: "QUESTION", id: 0 });
    }
    // setPage(0);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <IconButton
        classes={{ root: classes.notificationBell }}
        aria-label="show 17 new notifications"
        onClick={openNotifications}
        className={classes.badgeWrap}
      >
        <NotificationsNoneOutlinedIcon />
        {unreadNotificationsNumber !== 0 && (
          <span className={classes.countWrapper}>
            {unreadNotificationsNumber}
          </span>
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeNotifications}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          // p={1}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
            pl={2}
            pr={2}
            pt={1}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "#333",
              }}
            >
              {langConstant.NOTIFICATIONS}
            </Typography>
            <Button
              color="primary"
              style={{ height: "30px" }}
              onClick={() => {
                dispatch(
                  notificationsActions.updateNotification(
                    null,
                    "Y",
                    null,
                    1,
                    userID
                  )
                );
              }}
            >
              {langConstant.MARK_ALL_AS_READ}
            </Button>
          </Box>
          <NotificationContent
            onChange={onChange}
            selectedTab={selectedTab}
            notifications={allNotifications}
          />
          <Button
            color="primary"
            size="small"
            style={{ height: "30px", margin: ".5rem" }}
            href="/notifications"
          >
            {langConstant.SEE_EARLIER_NOTIFICATIONS}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationsPopup;
