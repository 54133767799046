import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GraphView from "./GraphView";
import ContentView from "./ContentView";
import ActionsPanelServices from "./services";
import { useDispatch, useSelector } from "react-redux";
import sessionService from "_services/session.service";
import PanelHeader from "../components/PanelHeader";
import ProgressBar from "../components/ProgressBar";
import SelectRegulation from "../components/SelectRegulation";
import langConstant from "_lang";

const Panel4 = ({ block, columnID, panel }) => {
  const dispatch = useDispatch();

  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );

  const [isGraphView, setIsGraphView] = useState(false);
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);

  const regulationID = useSelector(
    (state) => state.complianceReducer.selectedRegulation
  );
  const userID = sessionService.getUserId();

  useEffect(() => {
    if (regulationID && regulationID.value) {
      dispatch(
        ActionsPanelServices.getActions(
          setActions,
          setLoading,
          regulationID?.REGULATION_ID,
          userID,
          regulationID?.SUBMISSION_ID
        )
      );
    }
  }, [regulationID]);

  const updateActionsData = () => {
    dispatch(
      ActionsPanelServices.getActions(
        setActions,
        setLoading,
        regulationID?.REGULATION_ID,
        userID,
        regulationID?.SUBMISSION_ID
      )
    );
  };

  return (
    <>
      {loading ? (
        <ProgressBar />
      ) : (
        <Box>
          <PanelHeader
            expandedPanelID={expandedPanelID}
            block={block}
            panel={panel}
            columnID={columnID}
            isGraphView={isGraphView}
            setIsGraphView={setIsGraphView}
            panelName={langConstant.COMPLIANCE_ACTIONS}
          />
          {regulationID && regulationID.value ? (
            <Grid>
              {isGraphView ? (
                <GraphView rows={actions} panelID={panel.id} />
              ) : (
                <ContentView
                  rows={actions}
                  updateActionsData={updateActionsData}
                  block={block}
                />
              )}
            </Grid>
          ) : (
            <SelectRegulation />
          )}
        </Box>
      )}
    </>
  );
};

Panel4.propTypes = {
  block: PropTypes.object,
  columnID: PropTypes.string,
  panel: PropTypes.object,
};

export default Panel4;
