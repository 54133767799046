/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import { constants } from "./constants";
const initialState = { evidence: [], loading: false };

const updateEvidenceTags = (state, data) => {
  let tempEvidence = [...state.evidence]
  const evidenceIndex = tempEvidence.findIndex((item)=> item.EVIDENCE_LOG_ID === data.evidenceLogId && item.QUESTION_ID === data.questionId)
  tempEvidence[evidenceIndex]['KEYWORDS'] = data.keyWords
  tempEvidence[evidenceIndex]['COMMENTS'] = data.comments
  return { ...state, evidence: [...tempEvidence]}
}

export default function evidenceLocker(state = initialState, action) {
  switch (action.type) {
    case constants.getEvidence_SUCCESS:
      return { ...state, evidence: action.data };
    case constants.getEvidence_FAILURE:
      return { evidence: [], loading: false };
    case constants.update_EvidenceTags_SUCCESS:
      return updateEvidenceTags(state, action.data)
    default:
      return state;
  }
}
