import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import NoDataCard from "_components/MasterAssessmentComp/component/DashboardPanel/components/Graphs/NoDataCard";
import _ from "lodash";
import Graph from "./components/Graph";

const GraphView = ({ regulationID, panelID, graphData, contentData }) => {
  const [nullGroup, nonNullGroup] = _.partition(contentData, (item) =>
    _.isNull(item["POLICY_AVAILABLE"])
  );
  const data = [
    { name: "Documents Required", value: nullGroup.length },
    { name: "Documents Provided", value: nonNullGroup.length },
  ];

  const regulationGraphLabels = [
    { status: "COMPLETE", color: "#00b050" },
    { status: "DOCS PROVIDED", color: "#ed7d31" },
    { status: "DOC REQUIRED", color: "#4472c4" },
  ];

  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );

  return (
    <Box>
      {regulationID && regulationID.REGULATION_ID ? (
        <Graph
          data={data}
          expandedPanelID={expandedPanelID}
          panelID={panelID}
          nonNullGroup={nonNullGroup}
          contentData={contentData}
          isGraphView={true}
        />
      ) : (
        <>
          {graphData && graphData.length > 0 ? (
            <ResponsiveContainer
              width="99%"
              height={expandedPanelID === panelID ? "85%" : "280px"}
              aspect={expandedPanelID == panelID ? 3 : 1}
            >
              <BarChart
                data={graphData}
                layout="vertical"
                margin={{
                  top: 10,
                  right: 20,
                  left: 20,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  type="number"
                  style={{
                    fontSize: expandedPanelID == panelID ? "10px" : "10px",
                    fontWeight: "bold",
                  }}
                />
                <YAxis
                  dataKey="REGULATION_SHORT_NAME"
                  type="category"
                  style={{
                    fontSize: expandedPanelID == panelID ? "12px" : "10px",
                    fontWeight: "bold",
                  }}
                />
                {expandedPanelID === panelID && <Tooltip />}
                <Legend
                  // layout={
                  //   expandedPanelID === panelID ? "horizontal" : "vertical"
                  // }
                  payload={regulationGraphLabels.map((item, index) => ({
                    id: index,
                    type: "square",
                    value: (
                      <span style={{ fontSize: "12px" }}>{item.status}</span>
                    ),
                    color: item.color,
                  }))}
                />
                {regulationGraphLabels.map((label, index) => (
                  <Bar
                    key={index}
                    barSize={20}
                    dataKey={label.status}
                    fill={label.color}
                    opacity={0.8}
                    label={{
                      position: "right",
                      fontSize: expandedPanelID === panelID ? "14px" : "10px",
                      fontWeight: "bold",
                    }}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <NoDataCard />
          )}
        </>
      )}
    </Box>
  );
};

GraphView.propTypes = {
  regulationID: PropTypes.object,
  panelID: PropTypes.string,
  graphData: PropTypes.arrayOf(PropTypes.object),
  contentData: PropTypes.arrayOf(PropTypes.object),
};

export default GraphView;
