import { Grid, Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import sessionService from "_services/session.service";
import useStyles from "_components/ChatBot/styles";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import Select from 'react-select';
import ProfileServices from "../services";
import langConstant from "_lang";
import UserServices from "../services";
import TeamServices from "containers/cyber-assessment/ManageTeam/services";
import teamActions from "containers/cyber-assessment/ManageTeam/actions";

const NotificationForm = ({ userData, setUserData, handleClose, setCurrentStep}) => {
    const userId = sessionService.getUserId()
    const classes = useStyles()
    const [notificationPreferenceList, setNotificationPreferenceList] = useState([])

    const dispatch = useDispatch();

    const getTeamMembers = async () => {
        dispatch(loadingActions.start());
        const response = await TeamServices.getTeamDetails(userId);
        dispatch(loadingActions.end());
        if(response) {
            if(response.status === 200)
            {
            if(response['#result-set-1'].length)
            {
                let uniqueTeamMembers = response['#result-set-1'].filter((item,index) => 
                response['#result-set-1'].findIndex(obj => obj.USER_ID === item.USER_ID) === index
                )
                dispatch(teamActions.setUserTeamMembers(uniqueTeamMembers))
            }
            } else {
                if(response.data && response.data.message)
                {
                dispatch(
                    alertActions.error(
                        response.data.message.map((item, index) => {
                            return <div key={index}>{item}</div>;
                        })
                    )
                );
                } else {
                dispatch(
                    alertActions.error(langConstant.SOMETHING_WENT_WRONG))
                }
            }
        }
    }

    const sendData = async () =>{
        dispatch(loadingActions.start());
        const putUserRes = await UserServices.putUser(userData.basic_information, userData.team)
        if(putUserRes.status == 200){
            const res = await ProfileServices.update2FA(putUserRes['#result-set-1'][0]['USERID'], userData.security.isEnabled, userData.security.authenticationType, "")
            if(res.status !== 200){
                if(res.data && res.data.message)
                {
                  dispatch(
                      alertActions.error(
                        res.data.message.map((item, index) => {
                              return <div key={index}>{item}</div>;
                          })
                      )
                  );
                } else {
                  dispatch(
                      alertActions.error(langConstant.SOMETHING_WENT_WRONG))
                }
            }
            const selectedPreferenceIdList = [];
            userData.notification_pref.forEach((selectedPreference)=>{
                selectedPreferenceIdList.push(selectedPreference.value);
            });
            const selectedPreferenceStr = selectedPreferenceIdList.join(",");
            const notificationPref = await ProfileServices.putUserNotificationPreference(putUserRes['#result-set-1'][0]['USERID'], selectedPreferenceStr, "Y");
            if(notificationPref.status === 200 && notificationPref['#result-set-1']){
                handleClose()
                await getTeamMembers()
            } else {
                if(notificationPref.data && notificationPref.data.message)
                {
                  dispatch(
                      alertActions.error(
                        notificationPref.data.message.map((item, index) => {
                              return <div key={index}>{item}</div>;
                          })
                      )
                  );
                } else {
                  dispatch(
                      alertActions.error(langConstant.SOMETHING_WENT_WRONG))
                }
            }
        } else {
            if(putUserRes.data && putUserRes.data.message)
            {
              dispatch(
                  alertActions.error(
                    putUserRes.data.message.map((item, index) => {
                          return <div key={index}>{item}</div>;
                      })
                  )
              );
            } else {
              dispatch(
                  alertActions.error(langConstant.SOMETHING_WENT_WRONG))
            }
        }
        dispatch(loadingActions.end());
    }

    useEffect(() => {
        (async() => {
            const response = await ProfileServices.getUserNotificationPreferences(userId)
            if(response.status === 200){
                if(response['#result-set-1'].length > 0){
                    const tempPreferenceList = [];
                    response['#result-set-1'].forEach(element => {
                        tempPreferenceList.push({label: element.NOTIFICATION_TYPE, value: element.NOTIFICATION_ID})
                    });
                    setNotificationPreferenceList(tempPreferenceList);
                }
            }
        })()
    },[]);
    
    const goToPrevMenu = () => {
        setCurrentStep(2)
    }

    return (
        <Grid className={classes.notificationWrapper}>
            <Typography style={{fontSize: "18px", fontWeight: "bold", marginBottom: "15px" }}>{langConstant.NOTIFICATION_PREFERENCES}</Typography>
            <Select
                options={notificationPreferenceList}
                placeholder="Notification Preferences"
                value={userData.notification_pref}
                className={classes.inputProfileField}
                onChange={(pref) => setUserData((prevState) => ({...prevState, notification_pref: pref}))}
                isMulti
            />
            <Grid className={classes.profileButtonWrapper} style={{marginTop: "15px", textAlign: "right"}}>
                <Button color="primary" onClick={goToPrevMenu} style={{ border: "2px solid #124571", marginRight: "10px"}} variant="outlined" >Back</Button>
                <Button color="primary" onClick={sendData} variant="contained">Save</Button>
            </Grid>
        </Grid>
    )
}

NotificationForm.propTypes = {
    userData: PropTypes.object,
    setUserData: PropTypes.func,
    handleClose: PropTypes.func,
    setCurrentStep: PropTypes.func,
};

export default NotificationForm;