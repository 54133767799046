/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Button, TextField } from "@material-ui/core";
import moment from "moment";

import AppDataTable from "./components/AppDataTable";
import vendorAssessmentAction from "./actions";
import sessionService from "../../../_services/session.service";
import langConstant from "_lang";

const VendorRiskManagement = () => {
  //REGULATION ID REGULATION NAME REGULATOR COUNTRY STATUS UPLOAD DATE
  const columns = [
    { field: "SUBMISSION_ID", headerName: "ASSESMENT ID", minWidth: 130 },
    { field: "SUPPLIER_NAME", headerName: "VENDOR NAME", minWidth: 150 },
    {
      field: "DATE_ASSIGNED",
      headerName: "DATE ASSIGNED",
      format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
      minWidth: 130,
    },
    { field: "user_name", headerName: "OWNER", minWidth: 120 },
    { field: "ASSIGNED_BY", headerName: "ASSIGNED BY", minWidth: 120 },
    { field: "Status_label", headerName: "VRM STATUS", minWidth: 200 },
    {
      field: "LAST_UPDATED_DATE",
      headerName: "LAST UPDATED DATE",
      minWidth: 130,
      format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
    },
    { field: "action", headerName: "ACTIONS", minWidth: 115, sticky: true },
  ];

  const dispatch = useDispatch();
  const rows = useSelector((state) => state.vendorRiskManagementList);

  const userID = sessionService.getUserId();

  useEffect(() => {
    dispatch(vendorAssessmentAction.getVendorRiskManagementList(userID));
  }, []);
  return (
    <Box>
      <AppDataTable rows={rows} columns={columns} className="data-table" />
    </Box>
  );
};

export default VendorRiskManagement;
