import { makeStyles } from "@material-ui/core";

const globalMuiStyles = makeStyles(() => ({
  buttonRoot: {},
  buttonOutlined: {},
  buttonContained: {
    "& .MuiButtonBase-root-MuiButton-root": {
      backgroundColor: ({ bgColor }) => bgColor,
    },
  },
}));

const convertColorToRGBA = (color, opacity = 1) => {
  let rgbaColor = "";

  // If the color is in hex format (#RRGGBB)
  if (color.startsWith("#")) {
    const hex = color.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  // If the color is in RGB format (rgb(r, g, b))
  else if (color.startsWith("rgb")) {
    const rgba = color.replace(/[^\d,]/g, "").split(",");
    rgbaColor = `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${opacity})`;
  }

  // If the color is a named color
  else {
    const tempElement = document.createElement("div");
    tempElement.style.color = color;
    document.body.appendChild(tempElement);
    const computedColor = window.getComputedStyle(tempElement).color;
    document.body.removeChild(tempElement);
    rgbaColor = computedColor
      .replace(")", `, ${opacity})`)
      .replace("rgb", "rgba");
  }

  return rgbaColor;
};

const getButtonStylesSx = (bgcolor, variant, fontColor = "#fff") => {
  switch (variant) {
    case "contained":
      return {
        bgcolor,
        "&:hover": {
          backgroundColor: bgcolor,
          color: fontColor,
        },
        "&:active": {
          backgroundColor: bgcolor,
        },
      };

    case "outlined":
      return {
        borderColor: bgcolor,
        color: bgcolor,
        "&:hover": {
          backgroundColor: bgcolor,
          color: fontColor,
          borderColor: bgcolor,
        },
        "&:active": {
          backgroundColor: bgcolor,
        },
      };

    default:
      return {
        color: bgcolor,
        "&:hover": {
          backgroundColor: convertColorToRGBA(bgcolor, 0.1),
          color: fontColor,
        },
        "&:active": {
          backgroundColor: bgcolor,
        },
      };
  }
};

export { globalMuiStyles, getButtonStylesSx, convertColorToRGBA };
