// OP#2994 - UJA10 - UI Development : Manage Evidence Types - Part 1 only
// Array for dynamic menu , icons and there respective components

import React from "react";
import PublicIcon from "@mui/icons-material/Public";
import MapIcon from "@mui/icons-material/Map";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import StarsIcon from "@mui/icons-material/Stars";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import SchoolIcon from "@mui/icons-material/School";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import GroupIcon from "@mui/icons-material/Group";
import EmailIcon from "@mui/icons-material/Email";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LayersIcon from "@mui/icons-material/Layers";
import { makeStyles } from "@material-ui/core";
import Tab from "@mui/material/Tab";

import EvidenceTypes from "../components/EvidenceTypes/EvidenceTypes";

const myList = [
  { label: "Countries", icon: <PublicIcon style={{ marginRight: "15px" }} /> },
  { label: "Region", icon: <MapIcon style={{ marginRight: "15px" }} /> },
  // {
  //   label: "ML Model Strategy",
  //   icon: <PermDataSettingIcon style={{ marginRight: "15px" }} />,
  // },
  // {
  //   label: "Control Classifications",
  //   icon: <StarsIcon style={{ marginRight: "15px" }} />,
  // },
  {
    label: "Information Classifications",
    icon: <ContentPasteIcon style={{ marginRight: "15px" }} />,
  },
  {
    label: "Entitlements",
    icon: <SchoolIcon style={{ marginRight: "15px" }} />,
  },
  {
    label: "Roles",
    icon: <AirlineSeatReclineNormalIcon style={{ marginRight: "15px" }} />,
  },
  {
    label: "Evidence Types",
    icon: <AttachmentIcon style={{ marginRight: "15px" }} />,
    comp: <EvidenceTypes />,
  },
  // { label: "Customer Legal Entity Management", icon: <GroupIcon style={{ marginRight: "15px" }} /> },
  // { label: "Customer Data Sources Management", icon: <DriveFolderUploadIcon style={{ marginRight: "15px" }} /> },
  // {
  //   label: "RFI Recipients",
  //   icon: <EmailIcon style={{ marginRight: "15px" }} />,
  // },
  // {
  //   label: "Assessment Statuses",
  //   icon: <TextSnippetIcon style={{ marginRight: "15px" }} />,
  // },
  // {
  //   label: "Regulation Statuses",
  //   icon: <AccountBalanceIcon style={{ marginRight: "15px" }} />,
  // },
  {
    label: "Rules Data Types",
    icon: <LayersIcon style={{ marginRight: "15px" }} />,
  },
];

export default myList;
