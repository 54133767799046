import React, { useState } from "react";
import propTypes from "prop-types";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
}));

export const ViewActionsTable = (props) => {
  const classes = useStyles();
  const rows = props.rows;
  const columns = props.columns;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(rows));
  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filteredRows, setFilteredRows] = useState(rows);
  const [searchText, setSearchText] = useState("");

  const onSearch = (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);
    const searchResult = rows?.filter((item) =>
      item?.ACTIONS_LIST_NAME?.toUpperCase()?.includes(
        searchValue?.toUpperCase()
      )
    );
    setFilteredRows(searchResult);
    setPageCount(calculatePageCount(searchResult));
    setPage(0);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredRows.length > 0
        ? Math.round(Math.ceil(filteredRows.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={props.className} aria-label="Action List">
          <TableHead>
            <TableRow>
              {columns?.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={item?.sticky && classes.stickyColl}
                  style={{ minWidth: item?.minWidth }}
                >
                  {item?.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, index) => (
              <TableRow key={index + row[columns[0].field]}>
                {columns?.map((column, index) => {
                  const value = row[column.field];
                  return (
                    <TableCell
                      component="td"
                      scope="row"
                      key={`column_${index + row?.[columns?.[0]?.field]}`}
                      className={column?.sticky && classes.stickyColl}
                    >
                      {column?.format ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {filteredRows?.length === 0 && (
              <TableRow style={{ height: 60 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns?.length}
                >
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid container justify="flex-end">
          {filteredRows?.length > 5 && (
            <SelectPageRange
              selectedRange={rowsPerPage}
              handleChange={handleSelectPageRange}
            />
          )}
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

ViewActionsTable.propTypes = {
  rows: propTypes.array.isRequired,
  columns: propTypes.array.isRequired,
  className: propTypes.string,
};
