/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

// OP#2830 - UJA8 - UI Development - Manage Teams Profile
// Team Member screen

import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import TeamMemberPill from "./TeamMemberPill";
import TeamServices from "./services";
import sessionService from "_services/session.service";
import { default as UserProfilePopup } from "_components/Layout/MyProfilePopup";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, loadingActions } from "../../../_actions";
import teamActions from "./actions";
import AddIcon from "@mui/icons-material/Add";
import CreateUser from "_components/Layout/MyProfilePopup/component/CreateUser";

const useStyles = makeStyles(() => ({
  subtitle: {
    color: "#707070",
    marginBottom: "20px",
    marginTop: "20px",
  },
  teamGridToggle: {
    paddingRight: "475px",
  },
}));

const ManageTeam = () => {
  const classes = useStyles();
  const user_ID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();

  // const [userTeam, setUserTeam] = useState([])
  const [show, setShow] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [twoFADetail, setTwoFADetail] = useState([]);
  const [notificationPreferences, setNotificationPreferences] = useState([]);

  const userTeam = useSelector((state) => state.manageTeamMember.teamMembers);

  useEffect(() => {
    (async () => {
      dispatch(loadingActions.start());
      const response = await TeamServices.getTeamDetails(user_ID);
      dispatch(loadingActions.end());
      if (response) {
        if (response.status === 200) {
          if (response["#result-set-1"].length) {
            let uniqueTeamMembers = response["#result-set-1"].filter(
              (item, index) =>
                response["#result-set-1"].findIndex(
                  (obj) => obj.USER_ID === item.USER_ID
                ) === index
            );
            dispatch(teamActions.setUserTeamMembers(uniqueTeamMembers));
          }
        } else {
          if (response.data && response.data.message) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        }
      }
    })();
  }, []);

  return (
    <Grid
      className={
        (show && selectedUserId) || addUserPopUp ? classes.teamGridToggle : ""
      }
    >
      <Grid container>
        <Grid item md={12} xs={4} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setAddUserPopUp(true);
              setShow(false);
            }}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
      <TeamMemberPill
        members={userTeam}
        setShow={setShow}
        setSelectedUserId={setSelectedUserId}
        setAddUserPopUp={setAddUserPopUp}
      />
      {show && selectedUserId && (
        <UserProfilePopup
          user_ID={selectedUserId}
          show={show}
          setShow={setShow}
          isTeamView={true}
          twoFADetail={twoFADetail}
          setTwoFADetail={setTwoFADetail}
          notificationPreferences={notificationPreferences}
          setNotificationPreferences={setNotificationPreferences}
        />
      )}
      {addUserPopUp && (
        <CreateUser
          setAddUserPopUp={setAddUserPopUp}
          addUserPopUp={addUserPopUp}
          selectedCustomer={{ value: customerID }}
        />
      )}
    </Grid>
  );
};

export default ManageTeam;
