import React from "react";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { alertActions } from "_actions";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import langConstant from "_lang";
import { Field, Form, Formik } from "formik";
import ConfigurationsUtils from "./utils";
import Select from "react-select";
import UploadPhoto from "_components/UploadPhoto";
import { useState } from "react";
import { useRef } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useDispatch } from "react-redux";
import ConfigurationServices from "./services";
import { useEffect } from "react";
import FormTextField from "_components/FormTextField";
import FormDateField from "_components/FormDateField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailIcon from "@mui/icons-material/Mail";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RequestLicenceKey from "./components/requestLicenceKey";
import NewLicenceKeyPopUp from "./components/newLicenceKeyPopUP";
import DisplayDetailsForLicenceKey from "./components/detailsForLicenceKey";

const CustomerConfiguration = ({
  customerData,
  selectedCustomer,
  setCustomerData,
}) => {
  const formRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authentication.userData);
  const [primaryPhotoView, setPrimaryPhotoView] = useState(false);
  const [photoView, setPhotoView] = useState(false);
  const [primaryLogoImage, setPrimaryLogoImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [newLicenceKey, setNewLicenceKey] = useState(null);
  const [openRequestLicenceKey, setOpenRequestLicenceKey] = useState(false);
  const [openDisplayDetailsLicenceKey, setOpenDisplayDetailsLicenceKey] =
    useState(false);
  const [openNewLicenceKeyPopup, setOpenNewLicenceKeyPopup] = useState(false);
  const [viewLicenceKeyInitialValues, setViewLicenceKeyInitialValues] =
    useState({
      CUSTOMER_ID: selectedCustomer?.CUSTOMER_ID,
      CUSTOMER_NAME: selectedCustomer?.CUSTOMER_NAME,
      LICENCE_EXPIRY_DATE: "",
      LICENCE_TYPE: "",
    });

  let userAdminLicenceRequestor = false;
  let adminConsoleLicenceManager = false;

  if (
    userData &&
    userData.allowedNavItems &&
    userData.allowedNavItems.length > 0 &&
    userData.allowedNavItems.indexOf("ADMIN_CONSOLE/LICENCE_MANAGER") > -1
  ) {
    adminConsoleLicenceManager = true;
  }

  if (
    userData &&
    userData.allowedNavItems &&
    userData.allowedNavItems.length > 0 &&
    userData.allowedNavItems.indexOf("USER_ADMINISTRATION/LICENCE_REQUESTER") >
      -1
  ) {
    userAdminLicenceRequestor = true;
  }

  useEffect(() => {
    formRef.current.setFieldValue("LICENCE_KEY", newLicenceKey);
  }, [newLicenceKey]);

  useEffect(() => {
    if (customerData && customerData.CUSTOMER_ID) {
      setPrimaryLogoImage(window.btoa(customerData.PRIMARY_LOGO));
      setLogoImage(window.btoa(customerData.LOGO));
    }
  }, [customerData, selectedCustomer]);

  const onSubmit = (values) => {
    dispatch(
      ConfigurationServices.updateCustomerConfiguration(values, setCustomerData)
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        // Parse the JSON content
        const parsedContent = JSON.parse(e.target.result);
        // Extract the value associated with the key "KeyValue"
        const keyValue = parsedContent ? parsedContent.KeyValue : "";
        setNewLicenceKey(keyValue);
      } catch (error) {
        dispatch(
          alertActions.error(
            "Error parsing JSON file of LicenceKey:",
            error.toString()
          )
        );
      }
    };

    reader.readAsText(file);
  };

  const handleButtonClick = () => {
    // Trigger click on file input element
    document.getElementById("fileInput").click();
  };

  const handleGenerateKey = (values) => {
    dispatch(
      ConfigurationServices.generateLicenceKey(
        values,
        selectedCustomer,
        setNewLicenceKey,
        setOpenNewLicenceKeyPopup
      )
    );

    formRef.current.setFieldValue("LICENCE_KEY", newLicenceKey);
  };

  const updateCustomerPrimaryLogo = (fieldValue) => {
    formRef.current.setFieldValue("PRIMARY_LOGO", fieldValue);
    setPrimaryLogoImage(window.btoa(fieldValue));
    setPrimaryPhotoView(false);
  };

  const updateCustomerLogo = (fieldValue) => {
    formRef.current.setFieldValue("LOGO", fieldValue);
    setLogoImage(window.btoa(fieldValue));
    setPhotoView(false);
  };

  return (
    <Grid className={classes.configurationWrapper}>
      <Grid className={classes.configurationLabelWrapper}>
        <Typography className={classes.configurationLabel}>
          {langConstant.CUSTOMER_CONFIGURATION}
        </Typography>
      </Grid>
      <Grid>
        <Formik
          initialValues={
            customerData
              ? customerData
              : ConfigurationsUtils.CustomerInitialData
          }
          validationSchema={ConfigurationsUtils.CustomerValidationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={false}
          innerRef={formRef}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <Grid container>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <Typography className={classes.formFieldLabel}>
                    {langConstant.PRIMARY_LOGO}
                  </Typography>
                  {primaryLogoImage && (
                    <img
                      src={
                        "data:image/png;base64," + window.atob(primaryLogoImage)
                      }
                    />
                  )}
                  <CameraAltIcon
                    onClick={() => setPrimaryPhotoView(true)}
                    style={{ cursor: "pointer", marginLeft: "15px" }}
                  />
                  <UploadPhoto
                    show={primaryPhotoView}
                    setShow={setPrimaryPhotoView}
                    updateImage={updateCustomerPrimaryLogo}
                  />
                  {errors && errors.PRIMARY_LOGO && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.PRIMARY_LOGO}
                    </Typography>
                  )}
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <Typography className={classes.formFieldLabel}>
                    {langConstant.LOGO}
                  </Typography>
                  {logoImage && (
                    <img
                      src={"data:image/png;base64," + window.atob(logoImage)}
                    />
                  )}
                  <CameraAltIcon
                    onClick={() => setPhotoView(true)}
                    style={{ cursor: "pointer", marginLeft: "15px" }}
                  />
                  <UploadPhoto
                    show={photoView}
                    setShow={setPhotoView}
                    updateImage={updateCustomerLogo}
                  />
                  {errors && errors.LOGO && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.LOGO}
                    </Typography>
                  )}
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.DEFAULT_LANDING_ZONE}
                    value={values.DEFAULT_LANDING_ZONE}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="DEFAULT_LANDING_ZONE"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.DEFAULT_DOCUMENT_URL}
                    value={values.DEFAULT_DOC_URL}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="DEFAULT_DOC_URL"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.REPORTING_URL}
                    value={values.REPORTING_URL}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="REPORTING_URL"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.DEFAULT_PM_URL}
                    value={values.DEFAULT_PM_URL}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="DEFAULT_PM_URL"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.PREPROCESSING_URL}
                    value={values.PRE_PROCESSING_URL}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="PRE_PROCESSING_URL"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.PREPROCESSING_IP}
                    value={values.PRE_PROCESSING_IP}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="PRE_PROCESSING_IP"
                    errors={errors}
                  />
                </Grid>

                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.LOGIN_TIMEOUT}
                    value={values.LOGIN_TIMEOUT}
                    type="number"
                    setFieldValue={setFieldValue}
                    fieldName="LOGIN_TIMEOUT"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.PASSWORD_RESET_EMAIL_ADDRESS}
                    value={values.PASSWORD_RESET_EMAIL_ADDRESS}
                    type="email"
                    setFieldValue={setFieldValue}
                    fieldName="PASSWORD_RESET_EMAIL_ADDRESS"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.POLLING_TIMER}
                    value={values.POLLING_TIMER}
                    type="number"
                    setFieldValue={setFieldValue}
                    fieldName="POLLING_TIMER"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={3} xs={6}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      width: "55%",
                    }}
                  >
                    {langConstant.MULTI_PROCESSING}
                  </Typography>
                  <Field style={{ width: "45%" }} name="MULTI_PROCESSING">
                    {() => (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={JSON.parse(values.MULTI_PROCESSING)}
                        onChange={(e) =>
                          setFieldValue("MULTI_PROCESSING", e.target.value)
                        }
                        style={{
                          display: "inline",
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "2px 0",
                    alignItems: "center",
                  }}
                  item
                  md={3}
                  xs={6}
                >
                  <Typography
                    style={{
                      fontWeight: "bold !important",
                      fontSize: "14px !important",
                      width: "40%",
                    }}
                  >
                    {langConstant.VIRUS_CHECKER}
                  </Typography>
                  <Field style={{ width: "100%" }} name="VIRUS_CHECKER">
                    {() => (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={parseInt(values["VIRUS_CHECKER"])}
                        onChange={(e) =>
                          setFieldValue("VIRUS_CHECKER", e.target.value)
                        }
                        style={{
                          display: "inline",
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.WORKFLOW_URL}
                    value={values.WORKFLOW_URL}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="WORKFLOW_URL"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.COMP_THRESHOLD_DEFAULT}
                    value={values.COMP_THRESHOLD_DEFAULT}
                    type="number"
                    setFieldValue={setFieldValue}
                    fieldName="COMP_THRESHOLD_DEFAULT"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.AWS_ACCESS_KEY}
                    value={values.REACT_APP_AWS_ACCESS_KEY_ID}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="REACT_APP_AWS_ACCESS_KEY_ID"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.AWS_SECRET_ACCESS_KEY}
                    value={values.REACT_APP_AWS_SECRET_ACCESS_KEY}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="REACT_APP_AWS_SECRET_ACCESS_KEY"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <Typography className={classes.formFieldLabel}>
                    {langConstant.AI_ASSISTANT_AUDITLOG_ACTIVE}
                  </Typography>
                  <Field
                    style={{ width: "100%" }}
                    name="AI_ASSISTANT_AUDITLOG_ACTIVE"
                  >
                    {() => (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={JSON.parse(values.AI_ASSISTANT_AUDITLOG_ACTIVE)}
                        onChange={(e) =>
                          setFieldValue(
                            "AI_ASSISTANT_AUDITLOG_ACTIVE",
                            e.target.value
                          )
                        }
                        style={{
                          display: "inline",
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <FormTextField
                    fieldLabel={langConstant.AUDITLOG_RETENSION_IN_DAYS}
                    value={values.AI_ASSISTANT_AUDITLOG_RETENSION}
                    type="number"
                    setFieldValue={setFieldValue}
                    fieldName="AI_ASSISTANT_AUDITLOG_RETENSION"
                    errors={errors}
                  />
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <Typography className={classes.formFieldLabel}>
                    {langConstant.TWOFA_ENABLED}
                  </Typography>
                  <Field style={{ width: "100%" }} name="2FA_ENABLED">
                    {() => (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={parseInt(values["2FA_ENABLED"])}
                        onChange={(e) =>
                          setFieldValue("2FA_ENABLED", e.target.value)
                        }
                        style={{
                          display: "inline",
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <Typography className={classes.formFieldLabel}>
                    {langConstant.TWOFA_SERVICE_NAME}
                  </Typography>
                  <Grid style={{ width: "100%" }}>
                    <Field
                      style={{ width: "100%", margin: "10px" }}
                      name="2FA_SERVICE_NAME"
                    >
                      {() => (
                        <Select
                          className={classes.customerSelectForm}
                          options={ConfigurationsUtils.authenticationOptions}
                          value={ConfigurationsUtils.authenticationOptions.find(
                            (type) => type.value == values["2FA_SERVICE_NAME"]
                          )}
                          placeholder={"2FA_SERVICE_NAME"}
                          onChange={(type) =>
                            setFieldValue("2FA_SERVICE_NAME", type.value)
                          }
                        />
                      )}
                    </Field>
                    {errors && errors["2FA_SERVICE_NAME"] && (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        {errors["2FA_SERVICE_NAME"]}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  //   className={classes.formFieldWrapper}
                  style={{
                    display: "flex",
                    fontWeight: "bold",
                    fontSize: "16px",
                    width: "100%",
                    alignItems: "center",
                    padding: "5px 0",
                    paddingRight: "0px",
                  }}
                  item
                  md={4}
                  xs={6}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      width: "32%",
                    }}
                  >
                    {langConstant.LICENCE_KEY}
                  </Typography>
                  <FormTextField
                    value={values.LICENCE_KEY}
                    type="text"
                    setFieldValue={setFieldValue}
                    fieldName="LICENCE_KEY"
                    errors={errors}
                    style={{ width: "68%" }}
                  />
                </Grid>

                <Grid
                  style={{
                    padding: "0px 0px",
                    alignItems: "center",
                    display: "flex",
                    justify: "flex-end",
                    width: "100%",
                  }}
                  item
                  md={2}
                  xs={6}
                >
                  <input
                    type="file"
                    accept=".lic"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  <Tooltip title="Request Licence Key">
                    <IconButton
                      color={
                        userAdminLicenceRequestor ? "primary" : "secondary"
                      }
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        width: "auto",
                        padding: "0px 0px",
                      }}
                      disabled={!userAdminLicenceRequestor}
                    >
                      <MailIcon
                        onClick={() => setOpenRequestLicenceKey(true)}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Upload Licence Key">
                    <IconButton
                      color={
                        userAdminLicenceRequestor ? "primary" : "secondary"
                      }
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        width: "auto",
                        padding: "0px 2px",
                      }}
                      disabled={!userAdminLicenceRequestor}
                    >
                      <FileUploadIcon onClick={handleButtonClick} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Generate Licence Key">
                    <IconButton
                      color={
                        adminConsoleLicenceManager ? "primary" : "secondary"
                      }
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        width: "auto",
                        padding: "0px 2px",
                      }}
                      disabled={!adminConsoleLicenceManager}
                    >
                      <ChangeCircleIcon
                        onClick={() => handleGenerateKey(values)}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip color="primary" title="View Licence Key">
                    <IconButton
                      color={
                        adminConsoleLicenceManager ? "primary" : "secondary"
                      }
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        width: "auto",
                        padding: "0px 2px",
                      }}
                      disabled={!adminConsoleLicenceManager}
                    >
                      <VisibilityIcon
                        onClick={() =>
                          dispatch(
                            ConfigurationServices.viewLicenceKey(
                              values?.LICENCE_KEY,
                              setViewLicenceKeyInitialValues,
                              setOpenDisplayDetailsLicenceKey
                            )
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid className={classes.formFieldWrapper} item md={6} xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      width: "26%",
                    }}
                  >
                    {langConstant.LICENCE_EXPIRY_DATE}
                  </Typography>
                  <FormDateField
                    value={values.LICENCE_EXPIRY_DATE}
                    setFieldValue={setFieldValue}
                    fieldName="LICENCE_EXPIRY_DATE"
                    errors={errors}
                  />
                </Grid>
                <Grid container justify="flex-end">
                  <Grid
                    className={classes.formFieldWrapper}
                    item
                    md={6}
                    xs={12}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        width: "26%",
                      }}
                    >
                      {langConstant.LICENCE_TYPE}
                    </Typography>

                    <Field style={{ width: "100%" }} name="LICENCE_TYPE">
                      {() => (
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={values.LICENCE_TYPE}
                          onChange={(e) =>
                            setFieldValue("LICENCE_TYPE", e.target.value)
                          }
                          style={{
                            display: "inline",
                          }}
                        >
                          <FormControlLabel
                            value={"SaaS"}
                            control={<Radio />}
                            label="SaaS"
                          />
                          <FormControlLabel
                            value={"Installed"}
                            control={<Radio />}
                            label="Installed"
                          />
                        </RadioGroup>
                      )}
                    </Field>
                  </Grid>
                  <RequestLicenceKey
                    open={openRequestLicenceKey}
                    setOpen={setOpenRequestLicenceKey}
                    values={values}
                    customerName={selectedCustomer?.CUSTOMER_NAME}
                  />
                  <DisplayDetailsForLicenceKey
                    open={openDisplayDetailsLicenceKey}
                    setOpen={setOpenDisplayDetailsLicenceKey}
                    values={viewLicenceKeyInitialValues}
                    customerName={selectedCustomer?.CUSTOMER_NAME}
                  />
                  <NewLicenceKeyPopUp
                    open={openNewLicenceKeyPopup}
                    setOpen={setOpenNewLicenceKeyPopup}
                    newLicenceKey={newLicenceKey}
                  />
                </Grid>
              </Grid>
              <Grid style={{ textAlign: "right" }}>
                <Button type="submit" variant="contained" color="primary">
                  {langConstant.UPDATE}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

CustomerConfiguration.propTypes = {
  customerData: PropTypes.oneOfType([() => null, PropTypes.object]),
  selectedCustomer: PropTypes.object,
  setCustomerData: PropTypes.func,
};

export default CustomerConfiguration;
