/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import uploadDataConstant from "./constants";
import langConstant from "_lang";
import axios from "axios";


const getDataTypes = (userID) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetDataSourceNames", { i_USER_ID: userID })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#result-set-1"]) {
            dispatch({
              type: uploadDataConstant.uploadDataConstant_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch({ type: uploadDataConstant.uploadDataConstant_FAILURE });
            dispatch(alertActions.error(langConstant.DATA_TYPE_NOT_FOUND));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const uploadFile = (params, headers, redirectTo, fileUploadProgress) => {
  return (dispatch) => {
    const fileName = params.get("file").name.split(".")[0];
    const fileType = params.get("file").type;
    const extension = params.get("file").name.split(".").pop();
    const dataType = params.get("dataSourceNameAndType");

    const namesMatch = fileName.toUpperCase() === dataType.toUpperCase();
    const extensionSupported =
      ["text/csv", "application/json"].includes(fileType) ||
      ["csv", "json"].includes(extension);

    if (!namesMatch) {
      dispatch(alertActions.error(langConstant.TYPE_FILE_DONT_MATCH));
    } else if (!extensionSupported) {
      dispatch(alertActions.error(langConstant.EXTENSION_NOT_SUPPORTED));
    } else {
      dispatch({
        type: uploadDataConstant.loading_START,
        data: null,
      });
      apiService
        .apiCall("", headers)
        .send(
          "/pdfprocessor/api/v1/commonFileUpload",
          params,
          fileUploadProgress
        )
        .then(apiService.handleResponse)
        .then(
          () => {
            dispatch({
              type: uploadDataConstant.loading_END,
              data: null,
            });
            dispatch(alertActions.success("File Uploaded Successfully"));
            redirectTo();
          },
          (error) => {
            dispatch({
              type: uploadDataConstant.loading_END,
              data: null,
            });
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              dispatch(
                alertActions.error(
                  error.response.data.message.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })
                )
              );
            } else {
              dispatch(alertActions.error(error.toString()));
            }
          }
        );
    }
  };
};

const GetDataUploadLog = (userID, sourceSystemID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetDataUploadLog", { i_USER_ID: userID, in_SOURCE_SYSTEM_ID: sourceSystemID })
    .then(apiService.handleResponse)
    .then((response) => {
      if (response) {
        return response
      }
    },
      (error) => {
        if (error.response) {
          return error.response
        }
      }
    );
}

const kafkatopicUpload = async (TopicObj) => {

  // return await axios.post(`${window.REACT_APP_KAFKA_ENDPOINT}/notification/api/v1/sendMessage`, TopicObj)

  return apiService
    .apiCall("notification")
    .send("api/v1/sendMessage", TopicObj)
    .then(apiService.handleResponse)
    .then((response) => {
      if (response) {
        return response
      }
    },
      (error) => {
        if (error.response) {
          return error.response
        }
      }
    );

}

export default {
  uploadFile,
  getDataTypes,
  GetDataUploadLog,
  kafkatopicUpload
};
