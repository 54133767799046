/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import alert from "./alert.reducer";
import loadingReducer from "./loading.reducer";
import countriesList from "./countriesList.reducer";
import controlGroupsList from "./controlGroupsList.reducer";
import confirmationDialog from "./confirmationDialog.reducer";
import userCalendarEntries from "./calendar.reducer"
import notificationsList from "./notificationsList.reducer";

const loading = loadingReducer.loading;
const loadingFile = loadingReducer.loadingFile;
const loaderTextReducer = loadingReducer.loaderTextReducer;

export {
  alert,
  loading,
  loadingFile,
  countriesList,
  controlGroupsList,
  confirmationDialog,
  userCalendarEntries,
  notificationsList,
  loaderTextReducer,
};
