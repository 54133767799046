import apiService from "_services/api.service";

const getApproversForAssessment = (submission_ID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetApproversForAssessment", {
      i_SUBMISSION_ID: submission_ID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateApproverForAssessment = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateApproverForAssessment", {
      i_SUBMISSION_ID: data.submissionID,
      i_TO_USER_ID: data.toUserId,
      i_CURRENT_USER_ID: data.userId,
      i_STATUS: "EXTRA_APPROVAL",
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getRfiNarrative = (payload) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRFINarrative", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getQuestionNarrativeTemplate = ({
  i_CUSTOMER_ID = null,
  i_REGULATION_ID = null,
  i_QUESTION_ID = null,
}) => {
  return apiService
    .apiCall("dbapi")
    .send(
      "/v1/api/proc/DRegulativDB/GetQuestionNarrativeTemplateForRegulation",
      {
        i_CUSTOMER_ID,
        i_REGULATION_ID,
        i_QUESTION_ID,
      }
    )
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  getApproversForAssessment,
  updateApproverForAssessment,
  getRfiNarrative,
  getQuestionNarrativeTemplate,
};
