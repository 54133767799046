import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import * as Icons from "@mui/icons-material";
import langConstant from "_lang";
import PropTypes from "prop-types";
import DialogBox from "_components/DialogBox";
import { useState } from "react";
import { useEffect } from "react";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 30,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    cursor: "pointer",
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    fontSize: 20,
    marginBottom: theme.spacing(1),
  },
}));

const OutlinedIconsPreview = ({ show, setShow, handleChange }) => {
  const classes = useStyles();
  const handleClose = () => setShow(false);
  const [outlinedIcons, setOutlinedIcons] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(48);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let tempOutlinedIcons = [];
    Object.entries(Icons).forEach(([name, Icon]) => {
      if (name.endsWith("Outlined")) {
        tempOutlinedIcons.push({ name, Icon });
      }
    });
    setPageCount(calculatePageCount(tempOutlinedIcons));
    setOutlinedIcons(tempOutlinedIcons);
  }, []);

  const handleChangePage = (event) => {
    setPage(event);
  };

  // Separate icons into two groups: "outlined" and others

  const onIconChange = (name) => {
    handleChange("ENTITLEMENT_ICON_PREVIEW.inactive", name);
    handleClose();
  };

  return (
    <DialogBox
      maxWidth={"lg"}
      title={langConstant.SELECT_INACTIVE_ICON}
      open={show}
      handleClose={handleClose}
      style={{ paddingTop: "0px" }}
    >
      <div className={classes.section}>
        <Grid container spacing={2}>
          {(rowsPerPage > 0
            ? outlinedIcons.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : outlinedIcons
          ).map(({ name, Icon }) => (
            <Grid
              item
              md={2}
              xs={3}
              key={name}
              container
              direction="column"
              alignItems="center"
              onClick={() => onIconChange(name)}
            >
              <Typography variant="caption" style={{ cursor: "pointer" }}>
                {name}
              </Typography>
              <Icon className={classes.icon} />
            </Grid>
          ))}
        </Grid>
      </div>
      <Box my={2}>
        <Grid container justify="flex-end">
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
            />
          )}
        </Grid>
      </Box>
    </DialogBox>
  );
};

OutlinedIconsPreview.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  handleChange: PropTypes.func,
};

export default OutlinedIconsPreview;
