import React from "react";
import apiService from "_services/api.service";
import axios from "axios";
import sessionService from "_services/session.service";

const getPolicyChunking = (policyId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyChunking", {
      i_POLICY_ID: policyId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getSplitterDetails = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetSplitterDetails")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getVectorDatabasedetails = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetVectorDatabasedetails")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getEmbaddingDetails = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetEmbaddingDetails")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const performSplits = async (data) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/splitFile", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
  // return await axios.post("https://ml-api.internal.regulativ.ai/splitFile", data)
};

const getPolicyChunkingAvailable = (policyID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyChunkingAvailable", {
      i_POLICY_ID: policyID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

// const putDocDetails = (data) => {
//     return apiService
//       .apiCall("dbapi")
//       .send("/v1/api/proc/DRegulativDB/PutDocDetails", {
//         i_POLICY_ID: data.policyID,
//         i_DOC_NAME: data.docName,
//         i_SPLITTER_ID: data.splitterID,
//         i_EMBEDDING_ID: data.embeddingID,
//         i_DB_ID: data.dbID,
//         i_IS_SPLITTED: data.isSplitted
//       })
//       .then(apiService.handleResponse)
//       .then((response) => {
//         if(response)
//         {
//           response['status'] = 200
//           return response
//         }
//       },
//       (error) => {
//         if(error.response) {
//           return error.response
//         }
//       }
//     );
// }

export default {
  getPolicyChunking,
  getSplitterDetails,
  getVectorDatabasedetails,
  getEmbaddingDetails,
  performSplits,
  getPolicyChunkingAvailable,
  // putDocDetails,
};
