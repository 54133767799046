import React from "react";
import { alertActions } from "_actions";
import apiService from "_services/api.service";

const getSupplierThirdPartySecurityRisk = (
  setSuppliers,
  setTPSRData,
  setLoading,
  i_CUSTOMER_ID
) => {
  return (dispatch) => {
    setLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetSupplierThirdPartySecurityRisk", {
        i_CUSTOMER_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setSuppliers(response["#result-set-1"]);
          setTPSRData(response["#result-set-2"]);
        },
        (error) => {
          setSuppliers([]);
          setTPSRData([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
};

export default { getSupplierThirdPartySecurityRisk };
