import React from 'react'
import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    inputProfileField: {
        padding: "13px 14px",
    },
    insBoxLRField: {
        width: "80%",
        padding: "10px 0"
    }
}))

const CustomTextField = (props) => {
    const classes = useStyles()
    return <TextField 
                variant="outlined" 
                className={classes.insBoxLRField}
                InputProps={{
                    classes: { input: classes.inputProfileField },
                }} 
                size="small"
                {...props} 
            />;
};

export default CustomTextField;