import { PolicyConstants } from "./constants";

export const setStep1Values = (data) => ({
    type: PolicyConstants.SET_STEP1_VALUES,
    payload: data,
});

export const setStep2Values = (data) => ({
    type: PolicyConstants.SET_STEP2_VALUES,
    payload: data,
});

export const setStep3Values = (data) => ({
    type: PolicyConstants.SET_STEP3_VALUES,
    payload: data,
});

export const setOriginalPredictedtextValues = (data)  => ({
    type: PolicyConstants.SET_ORIGINAL_PREDICTEDTEXT_VALUES,
    payload: data,
});

export const setProcessedPredictedtextValues = (data)  => ({
    type: PolicyConstants.SET_PROCESSED_PREDICTEDTEXT_VALUES,
    payload: data,
});

export const setOriginalDocumentInfo = (data) => ({
    type: PolicyConstants.SET_ORIGINAL_DOCUMENT_INFO,
    payload: data
});

export const setProcessedDocumentInfo = (data) => ({
    type: PolicyConstants.SET_PROCESSED_DOCUMENT_INFO,
    payload: data
})

export const updatePolicyId = (data) => ({
    type: PolicyConstants.UPDATE_POLICY_ID,
    payload: data
})

export const updateBackView = (data) => ({
    type: PolicyConstants.UPDATE_BACK_VIEW,
    payload: data
}) 

export const clearData = () => ({
    type: PolicyConstants.CLEAR_DATA
})

export const updateProcessedView = (data) => ({
    type: PolicyConstants.UPDATE_PROCESSED_VIEW,
    payload: data
})

export const updateReRunMode = (data) => ({
    type: PolicyConstants.UPDATE_RERUN_POLICY,
    payload: data
})

export const updateParentPolicyID = (data) => ({
    type: PolicyConstants.UPDATE_PARENT_POLICYID,
    payload: data
})

