import React from "react";
import langConstant from "_lang";
import DialogBox from "_components/DialogBox";
import PropTypes from "prop-types";
import { TextField, Button, Typography, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import UserServices from "../services";
import useStyles from "./styles";

const PWDPopUp = ({
  showPwdPopUp,
  setShowPwdPopUp,
  userPassword,
  setUserPassword,
  setQRCode,
}) => {
  const handleClose = () => setShowPwdPopUp(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const userName = useSelector(
    (state) => state.authentication.detailedInfo.USER_NAME
  );

  const generateQRCode = () => {
    dispatch(
      UserServices.loginWithMFA(userName, userPassword, setQRCode, handleClose)
    );
  };

  return (
    <DialogBox
      maxWidth={"sm"}
      title={langConstant.ENTER_PASSWORD}
      open={showPwdPopUp}
      handleClose={handleClose}
    >
      <Grid style={{ textAlign: "center" }}>
        <TextField
          type="password"
          variant="outlined"
          className={classes.inputField}
          InputProps={{
            classes: {
              input: classes.inputPasswordField,
            },
          }}
          onChange={(e) => setUserPassword(e.target.value)}
        />
        <Typography
          style={{
            fontSize: "12px",
            color: "grey",
            fontWeight: "bold",
            marginBottom: "15px",
          }}
        >
          {langConstant.ENTER_PASSWORD_TO_GENERATE_QR}
        </Typography>
      </Grid>
      <Grid style={{ textAlign: "right" }}>
        <Button color="primary" onClick={generateQRCode} variant="contained">
          {langConstant.GENERATE_QR}
        </Button>
      </Grid>
    </DialogBox>
  );
};

PWDPopUp.propTypes = {
  showPwdPopUp: PropTypes.bool,
  setShowPwdPopUp: PropTypes.func,
  userPassword: PropTypes.string,
  setUserPassword: PropTypes.func,
  setQRCode: PropTypes.func,
};

export default PWDPopUp;
