import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { UseModalPropType } from "_hooks";
import { alertActions } from "_actions";
import { Typography, Button, Box, makeStyles } from "@material-ui/core";
import sessionService from "_services/session.service";
import langConstant from "_lang";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import TextEditor from "_components/TextEditor/TextEditor";
import moment from "moment";
import {
  GetCustomerServiceNotifications,
  sendEmail,
  GetCustomerServiceType,
  GetUserNotificationPreferences,
  PutServiceRequestIntoLog,
  PutUserNotificationEvent,
} from "../services";

const useStyles = makeStyles(() => ({
  width100: {
    width: "100%",
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "15px",
  },
}));

const ServiceRequestDialog = ({ modal }) => {
  const dispatch = useDispatch();
  const customerID = parseInt(sessionService.getCustomerId(), 10);
  const userID = parseInt(sessionService.getUserId(), 10);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [preferenceID, setPreferenceID] = useState(null);
  const [notificationTemplate, setNotificationTemplate] = useState(null);

  const classes = useStyles();

  const requestType = modal?.data.requestType ? "SERVICE" : "SUPPORT";

  const initialValues = {
    NAME: modal.data ? modal.data.userName : "",
    CUSTOMER: modal.data ? modal.data.customerName : "",
    EMAIL: modal.data ? modal.data.userEmail : "",
    TYPE: "",
    DESCRIPTION: "",
    SEND_COPY_TO_ME: true,
  };

  const validationSchema = yup.object({
    NAME: yup.string().required("Name is required "),
    CUSTOMER: yup.string().required("Customer name is required"),
    //EMAIL: yup.string().required("Email is required"),
    TYPE: yup.string().trim().required("Service Type is required"),
    DESCRIPTION: yup.string().trim().required("Description is required"),
  });

  const removeHtmlTags = (str) => {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = str;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const onSubmit = async (values) => {
    //   1 Call PutServiceRequestIntoLog (CustomerID, USER_ID, ServiceRequest_Payload)

    const dateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    let tempNotificationTemplate = notificationTemplate;
    let notificationMessage = notificationTemplate["Notificaton Message"];

    // notificationMessage = notificationMessage.toString();
    notificationMessage = notificationMessage.replace(
      /@messageDatetime/g,
      dateTime
    );
    notificationMessage = notificationMessage.replace(
      /@userName/g,
      values.NAME
    );
    notificationMessage = notificationMessage.replace(
      /@bodyMessage/g,
      removeHtmlTags(values.DESCRIPTION)
    );

    tempNotificationTemplate["Notificaton Message"] = notificationMessage;

    const res = await PutServiceRequestIntoLog(
      customerID,
      userID,
      JSON.stringify(tempNotificationTemplate)
    );
    if (res.status !== 200) {
      dispatch(alertActions.error("Something went wrong"));
    }

    //2 Call PutUserNotificationEvent (
    const res1 = await PutUserNotificationEvent(
      userID,
      preferenceID,
      JSON.stringify(tempNotificationTemplate),
      dateTime
    );
    if (res1.status !== 200) {
      dispatch(alertActions.error("Something went wrong"));
    }

    //4 Call JavaService for Email

    const emailTo =
      requestType == "SERVICE"
        ? langConstant.SERVICE_REQUEST_EMAIL
        : langConstant.SUPPORT_REQUEST_EMAIL;

    const mesg = `${requestType} request from: ${values.CUSTOMER}`;
    const cc = values.SEND_COPY_TO_ME ? `${values.EMAIL}` : "";
    const desc = `${values.DESCRIPTION}`;

    const resSendEmail = await sendEmail("", cc, desc, mesg, emailTo);
    if (resSendEmail.status === 200) {
      dispatch(
        alertActions.success(
          modal?.data.requestType
            ? langConstant.SERVICE_REQUEST_RAISED_MESSAGE
            : langConstant.SUPPORT_REQUEST_RAISED_MESSAGE
        )
      );
    } else {
      dispatch(alertActions.error("Something went wrong"));
    }
    modal?.closeModal();
  };

  useEffect(() => {
    //To Populate Type dropdown
    (async () => {
      const serviceTypes = await GetCustomerServiceType(
        customerID,
        requestType
      );
      const serviceTypeList = [];
      if (serviceTypes.status === 200) {
        serviceTypes["#result-set-1"].forEach((item) => {
          serviceTypeList.push({
            value: item.UNID,
            label: item.SERVICE_NAME,
          });
        });

        setServiceTypeOptions(serviceTypeList);
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    })();

    //To get preference Id
    (async () => {
      const preferences = await GetUserNotificationPreferences(userID);
      preferences["#result-set-1"].forEach((item) => {
        const type = item.NOTIFICATION_GROUP.toString().toUpperCase().trim();
        if (type == requestType)
          setPreferenceID(item.NOTIFICATION_PREFERENCE_ID);
      });
    })();

    //Calling GetCustomerServiceNotifications
    (async () => {
      const responseServiceNotifications =
        await GetCustomerServiceNotifications(customerID);

      responseServiceNotifications["#result-set-1"].forEach((item) => {
        const type = item.NOTIFICATION_GROUP.toString().toUpperCase().trim();
        if (type == requestType) {
          const parsedtemp = JSON.parse(item.NOTIFICATION_TEMPLATE);
          setNotificationTemplate(parsedtemp);
        }
      });
    })();
  }, []);

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={modal?.closeModal}
        open={modal?.isOpen}
        disableEscapeKeyDown
        disableEnforceFocus
        style={{
          backdropFilter: "blur(2px) brightness(0.8)",
        }}
      >
        {/* <Grid p={1.5} display="inline-flex" flex="auto"> */}
        <Grid p={1.5}>
          <DialogTitle
            sx={{
              m: 0,
              p: 0,
              flex: 1,
              color: "#9d9d9d",
              textAlign: "center",
            }}
          >
            {modal.data.requestType == "SERVICE"
              ? langConstant.SERVICE_REQUEST_FORM
              : langConstant.SUPPORT_REQUEST_FORM}
          </DialogTitle>
        </Grid>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <DialogContent>
                <Grid>
                  <Box>
                    <Grid
                      container
                      className={classes.AddressGrid}
                      style={{ alignItems: "center" }}
                    >
                      <Grid item md={2}>
                        <Typography>Name </Typography>
                      </Grid>
                      <Grid md={10} item>
                        <Field name="NAME">
                          {({ field: { value } }) => (
                            <TextField
                              fullWidth
                              value={value}
                              className={classes.width100}
                              onChange={(e) =>
                                setFieldValue("NAME", e.target.value)
                              }
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="NAME">
                          {(msg) => (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className={classes.AddressGrid}
                      style={{ alignItems: "center" }}
                    >
                      <Grid item md={2}>
                        <Typography>Customer </Typography>
                      </Grid>
                      <Grid md={10} item>
                        <Field name="CUSTOMER">
                          {({ field: { value } }) => (
                            <TextField
                              fullWidth
                              value={value}
                              className={classes.width100}
                              onChange={(e) =>
                                setFieldValue("CUSTOMER", e.target.value)
                              }
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="CUSTOMER">
                          {(msg) => (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className={classes.AddressGrid}
                      style={{ alignItems: "center" }}
                    >
                      <Grid item md={2}>
                        <Typography>Email </Typography>
                      </Grid>
                      <Grid md={10} item>
                        <Field name="EMAIL">
                          {({ field: { value } }) => (
                            <TextField
                              fullWidth
                              value={value}
                              className={classes.width100}
                              onChange={(e) =>
                                setFieldValue("EMAIL", e.target.value)
                              }
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="EMAIL">
                          {(msg) => (
                            <Typography
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={classes.AddressGrid}
                      style={{ alignItems: "center" }}
                    >
                      <Grid item md={2}>
                        <Typography>
                          {modal.data.requestType
                            ? langConstant.SERVICE_TYPE
                            : langConstant.SUPPORT_TYPE}
                        </Typography>
                      </Grid>
                      <Grid md={10} item>
                        <Field name="TYPE">
                          {({ field: { value } }) => (
                            <Select
                              value={serviceTypeOptions.find(
                                (type) => type.value === value
                              )}
                              menuPortalTarget={document.body}
                              onChange={(type) =>
                                setFieldValue("TYPE", type.value)
                              }
                              options={serviceTypeOptions}
                              fullWidth
                            />
                          )}
                        </Field>
                        <ErrorMessage name="TYPE">
                          {(msg) => (
                            <Typography
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className={classes.AddressGrid}
                      style={{ alignItems: "center" }}
                    >
                      <Grid item md={12}>
                        <Typography>Description of Request</Typography>
                      </Grid>
                      <Field name="DESCRIPTION">
                        {({ field: { value } }) => (
                          <TextEditor
                            onChangeHandler={(e) => {
                              setFieldValue("DESCRIPTION", e);
                            }}
                            data={value}
                            onCancelClick={() => {
                              setFieldValue("DESCRIPTION", values.DESCRIPTION);
                            }}
                            onSaveClick={() => {
                              setFieldValue("DESCRIPTION", values.DESCRIPTION);
                            }}
                            identifier={1}
                          />
                        )}
                      </Field>

                      {errors && errors["DESCRIPTION"] && (
                        <Typography
                          style={{
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors["DESCRIPTION"]}
                        </Typography>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <DialogActions sx={{ p: 1.5, justifyContent: "space-between" }}>
                  <Grid
                    item
                    md={6}
                    sm={6}
                    justify="flex-start"
                    style={{
                      justifyContent: "flex-start",
                    }}
                  >
                    <Checkbox
                      name="SEND_COPY_TO_ME"
                      checked={values.SEND_COPY_TO_ME}
                      onChange={(e) =>
                        setFieldValue("SEND_COPY_TO_ME", e.target.checked)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: "#124571",
                        },
                        display: "inline",
                      }}
                      size="small"
                    />
                    <span style={{ fontWeight: "bold", cursor: "pointer" }}>
                      {langConstant.SEND_COPY_TO_ME}
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    sm={6}
                    display="flex"
                    justify="flex-end"
                    style={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <Grid
                      item
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      <Button
                        onClick={modal?.closeModal}
                        variant="contained"
                        color="primary"
                        disabled
                      >
                        Call Me
                      </Button>
                    </Grid>
                    <Grid
                      item
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      <Button
                        onClick={modal?.closeModal}
                        variant="contained"
                        color="primary"
                        disabled
                      >
                        Chat
                      </Button>
                    </Grid>
                    <Grid
                      item
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      <Button type="submit" variant="contained" color="primary">
                        Submit
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={modal?.closeModal}
                        variant="contained"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
};

ServiceRequestDialog.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default ServiceRequestDialog;
