import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "_components/DialogBox";
import { Box, Button, makeStyles, IconButton, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TabsContent from "_components/MasterAssessmentComp/component/TabsContent";
import AssessmentTabs from "./AssessmentTabs";
import { UseModalPropType } from "_hooks";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { EditAnswer } from "./EditAnswer";
import ObservationDialogBox from "_components/MasterAssessmentComp/component/ObservationDialogBox";
import planActionsServices from "../../services";

import _ from "lodash";
import {
  getEvidenceForMasterAssessment,
  getDataAssestsForMasterAssessment,
  saveSingleQuestion,
} from "_components/MasterAssessmentComp/utils/actions";
import sessionService from "_services/session.service";

import storeProcedure from "_components/MasterAssessmentComp/steps/services";
import { alertActions } from "_actions";
import { useModal } from "_hooks";

import { useParams } from "react-router-dom";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles((theme) => ({
  height100: {
    height: "100%",
  },
  width100: {
    width: "100%",
  },
  marginTop: {
    marginTop: "10px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  statusicon: {
    fontSize: 20,
    marginRight: "5px",

    "&.COMPLETED": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.RFI_SENT": {
      color: "#105BA1",
    },

    "&.RFI_RESOLVED": {
      color: "#1a751a",
    },

    "&.RFI_PENDING": {
      color: "#895514",
    },

    RFI_OVERDUE: {
      color: "#d62000",
    },

    "&.SENT_BACK": {
      color: "rgba(240, 20, 47, 1)",
    },

    "&.FINAL": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.PENDING_DATA": {
      color: "rgba(187, 174, 0, 1)",
    },

    "&.READY_FOR_REVIEW": {
      color: "rgba(1, 148, 25, 1)",
    },

    "&.COMPLETED_AND_LOCKED": {
      color: "rgba(0, 159, 26, 1)",
    },
  },
}));

const BottomTabsArray = [
  { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },
  { name: "DATA ASSETS", count: 0, isCount: false, id: 1 },
  { name: "EVIDENCES", count: 0, isCount: false, id: 2 },
  { name: "Responses and narratives", count: 0, isCount: true, id: 3 },
];

const PrepareSummaryAnswer = ({ modal }) => {
  const QuestionList = modal ? modal.data.filteredRows : [];
  const selRow = modal?.data.selRow;
  const IsAudit = false;
  const observationList = [];
  const identifier = "";
  const RfiitemsList = [];
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState({
    name: "",
    id: 0,
  });

  // const [TopSelectedTab, setTopSelectedTab] = useState(TopTabsArray[0]);
  const [BottomTabs, setBottomTabs] = useState(BottomTabsArray);
  const [BottomSelectedTab, setBottomSelectedTab] = useState(
    BottomTabsArray[0]
  );
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(QuestionList?.length);
  const [filteredQuestionList, setfilteredQuestionList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [score, setScore] = useState(0);
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [ObservationDialog, setObservationDialog] = useState({
    visible: false,
    mode: "",
    ObservationList: {},
  });
  const [rfiNarrative, setRfiNarrative] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState({});

  const [value, setValue] = React.useState(0);

  const EvidenceList = useSelector(
    (state) => state.MasterAssessment.EvidencesList
  );
  const DataAssetsList = useSelector(
    (state) => state.MasterAssessment.DataAssetsList
  );

  const themeDetails = useSelector(getThemeDetails);
  const ArrayOfQuestionIDOfObservations = observationList?.map((el) =>
    el.QUESTION_ID.trim()
  );
  const currentRfi = RfiitemsList?.find(
    (rfi) =>
      rfi.REGULATORS_QUESTION_REF === selectedQuestion?.REGULATORS_QUESTION_REF
  );
  const addActionModal = useModal();
  const { sid: SUBMISSION_ID, id: ASSESSMENT_ID } = useParams();

  const actions = useSelector((state) => state?.MasterAssessment?.ActionsList),
    assessmentDetails = useSelector(
      (state) => state?.MyAssessmnetReducer?.resultSet2
    )?.[0],
    assessmentKpiData = useSelector(
      (state) => state?.MyAssessmnetReducer?.AssessmentKpiData
    )?.[0],
    approversList = useSelector(
      (state) => state?.MyAssessmnetReducer?.ApproversList
    ),
    regulatorsList = useSelector(
      (state) => state?.MyAssessmnetReducer?.RegulatorsList
    );
  const saveAnswerFunction = (data) => {
    SaveAnswerFromRichTextEditor(data);
  };
  const getRfiNarrativeByApi = async () => {
    const toNumber = (num) => {
      if (!num) return null;
      return Number(num);
    };
    const payload = {
      i_RFI_ID: toNumber(currentRfi?.RFI_ID),
      i_QUESTION_ID: toNumber(selectedQuestion?.QUESTION_ID),
      i_SUBMISSION_ID: toNumber(selectedQuestion?.SUBMISSION_ID),
    };

    await storeProcedure
      .getRfiNarrative(payload)
      .then((response) => {
        if (response?.status !== 200) return showAlert(response);
        setRfiNarrative(response?.["#result-set-1"]);
      })
      .catch((error) => {
        showAlert(error);
        console.log("error", error);
      });
  };
  const onBottomTabChange = (e, value) => {
    setBottomSelectedTab(BottomTabsArray[value]);
  };

  const CardBox = (identifier, currentQuestion) => {
    return (
      <Card variant="outlined" style={{ height: "100%" }}>
        <CardContent style={{ padding: "5px", height: "100%" }}>
          <PerfectScrollbar>
            {identifier === 1 && (
              <div style={{ display: "flex", fontSize: "15px" }}>
                <div>
                  {" "}
                  <span>
                    {" "}
                    <strong>
                      {currentQuestion?.REGULATORS_QUESTION_REF}
                    </strong>{" "}
                    -
                  </span>
                  &nbsp;{currentQuestion?.QUESTION_TEXT}
                </div>
              </div>
            )}

            {identifier === 3 && (
              <EditAnswer
                currentQuestion={currentQuestion}
                currentRfi={currentRfi}
                onSaveAnswer={saveAnswerFunction}
                showAlert={showAlert}
                onClose={modal?.closeModal}
              />
            )}
          </PerfectScrollbar>
        </CardContent>
      </Card>
    );
  };

  const UpdateMyAssessmentQuestionArray = (data) => {
    dispatch({ type: "SET_MY_ASSESSMENT_QUESTIONS", payload: data });
  };

  const showAlert = (error, type = "error") => {
    dispatch(
      alertActions?.[type](
        error?.data?.message?.map((item, index) => {
          return <span key={index}>{item}</span>;
        }) ?? error?.toString()
      )
    );
  };

  const SaveAnswerFromRichTextEditor = (answer) => {
    let currentQuestion = {
      ...selectedQuestion,
      SUBMISSION_ID: selRow.SUBMISSION_ID,
    };
    currentQuestion.CURRENT_ANSWER_SELECTED_RESPONSE = answer;

    const RichTextCallback = () => {
      const data = [...QuestionList].map((que) => {
        if (
          `${que.REGULATION_REFERENCE}` === `${selectedQuestion.QUESTION_ID}`
        ) {
          return { ...currentQuestion };
        }

        return que;
      });

      UpdateMyAssessmentQuestionArray(data);
    };

    dispatch(saveSingleQuestion(currentQuestion, RichTextCallback));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await planActionsServices.GetSubmissionQuestionDetails(
          selRow.REGULATION_REFERENCE,
          selRow.SUBMISSION_ID
        );
        if (response?.status !== 200) throw new Error("Unable to fetch data");
        else if (response && response?.["#result-set-1"][0]) {
          const tempSelectedQuestion = response?.["#result-set-1"][0];
          setSelectedQuestion(tempSelectedQuestion);
        }
      } catch (error) {
        console.error("error", error);
      }
    })();

    const index = _.findIndex(filteredQuestionList, (e) => {
      return e.REGULATION_REFERENCE == selRow.QUESTION_ID;
    });

    if (filteredQuestionList.length > 0) {
      setSelectedQuestion(filteredQuestionList[index > -1 ? index : page]);

      dispatch(
        getEvidenceForMasterAssessment(
          selRow.REGULATION_REFERENCE,
          selRow.SUBMISSION_ID
        )
      );
      dispatch(getDataAssestsForMasterAssessment(selRow.REGULATION_REFERENCE));
      selRow.REGULATION_REFERENCE &&
        currentRfi?.RFI_ID &&
        getRfiNarrativeByApi();
    }
  }, [page, filteredQuestionList, currentRfi]);

  // useEffect(() => {
  //   setfilteredQuestionList(QuestionList);
  // }, [QuestionList]);

  useEffect(() => {
    let tempArr = [
      { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },

      {
        name: "DATA ASSETS",
        count: DataAssetsList.length,
        isCount: false,
        id: 1,
      },
      {
        name: "EVIDENCES",
        count: EvidenceList.length,
        isCount: false,
        id: 2,
      },
      {
        name: "Responses and narratives",
        count: rfiNarrative?.length,
        isCount: false,
        id: 3,
      },
    ];
    setBottomTabs(tempArr);
  }, [EvidenceList, DataAssetsList, rfiNarrative]);

  return (
    <DialogBox
      fullWidth
      maxWidth="sm"
      title={selectedQuestion?.QUESTION_ID}
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableEnforceFocus
      disableAutoFocus
      PaperProps={{
        style: {
          height: "80vh",
        },
      }}
    >
      <Grid
        item
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <IconButton aria-label="close" onClick={modal?.closeModal}>
          <CloseIcon />
        </IconButton>
      </Grid>

      <Box
        style={{
          overflowY: "auto",
          maxHeight: "80vh",
          // padding: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "160px",
          }}
        >
          <Box style={{ width: "99%" }}>{CardBox(1, selectedQuestion)}</Box>
        </Box>

        <AssessmentTabs
          selectedTab={BottomSelectedTab}
          onChange={onBottomTabChange}
          tabs={BottomTabs}
          bottomTabs
          currentQuestion={selectedQuestion}
        />

        <Box style={{ height: "calc(100vh - 611px)", overflow: "auto" }}>
          <Box style={{ width: "100%", height: "100%" }}>
            {CardBox(3, selectedQuestion)}
          </Box>
        </Box>

        {ObservationDialog.visible &&
          (identifier === "audit" || identifier === "myassessment") && (
            <ObservationDialogBox
              Controls={selectedQuestion?.CISO_CONTROL_NAME}
              submissionID={selectedQuestion?.SUBMISSION_ID}
              questionID={selectedQuestion?.QUESTION_ID}
              questionText={selectedQuestion?.QUESTION_TEXT}
              miscData={ObservationDialog}
              setObservationDialog={setObservationDialog}
              TotalObservationList={observationList}
            />
          )}
      </Box>
    </DialogBox>
  );
};

PrepareSummaryAnswer.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default PrepareSummaryAnswer;
