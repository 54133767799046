/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Box,
  Grid,
  Divider,
  Typography,
  Button,
  Checkbox,
  Chip,
} from "@material-ui/core";

import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import langConstant from "_lang";

import rootStyles from "rootStyles";

import { useParams } from "react-router";
const SingleQuestion = ({
  item,
  assessmentObj,
  roleBasedConstant,
  selectQuestion,
}) => {
  const rootClasses = rootStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs zeroMinWidth>
        {/* Status tags */}
        <Box pl={6}>
          {item.status == "SENT_BACK" ? (
            <Chip
              icon={<ReplyAllIcon />}
              color="default"
              size="small"
              label={langConstant.SENT_BACK}
              className={rootClasses.squareChip}
            />
          ) : (
            <></>
          )}
          {item.status == roleBasedConstant.questionReviewedStatus ? (
            <Chip
              icon={<DoneAllIcon />}
              color="default"
              size="small"
              label={langConstant.REVIEWED}
              className={rootClasses.squareChip}
            />
          ) : (
            <></>
          )}
        </Box>
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}>
            <Checkbox
              className={
                rootClasses.greenCheckbox + " " + rootClasses.mtMinus10
              }
              color="default"
              id={"checkId_" + item.id}
              checked={item.selectedForReview}
              onChange={(e) => {
                selectQuestion(e, item);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Grid>

          <Grid item style={{ minWidth: "130px" }}>
            <Typography color="textSecondary" variant="body1" gutterBottom>
              <strong>{item.ref} -</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" gutterBottom>
              <strong>{item.sentence}</strong>
            </Typography>
          </Grid>
        </Grid>
        {/* Metadata Grid */}
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}></Grid>

          <Grid item style={{ minWidth: "130px" }}>
            <Typography variant="body1" gutterBottom>
              <strong> {langConstant.METADATA}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}></Grid>

          <Grid item>
            <Typography variant="body2" gutterBottom>
              {item.mappedControl[0]
                ? item.mappedControl.map((item, index) => {
                    return (
                      <Box mt={1} key={index}>
                        {item.control} &nbsp;&nbsp;.{item.dataPoints}
                      </Box>
                    );
                  })
                : langConstant.NO_METADATA_AVAILABLE}
            </Typography>
          </Grid>
        </Grid>
        {/* Narrative Grid */}
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}></Grid>
          <Grid item style={{ minWidth: "130px" }}>
            <Typography variant="body1" gutterBottom>
              <strong> {langConstant.NARRATIVES}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}></Grid>
          <Grid item>
            <Typography variant="body2" gutterBottom>
              {item.narratives
                ? langConstant.NARRATIVES_WITHIN_SCOPE(item.narratives)
                : langConstant.NO_NARRATIVES_AVAILABLE}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ padding: 0 }}>
        <Box mb={2}>
          <Divider />
        </Box>
      </Grid>
    </Grid>
  );
};
SingleQuestion.propTypes = {
  item: PropTypes.object,
  assessmentObj: PropTypes.object,
  roleBasedConstant: PropTypes.object,
  selectQuestion: PropTypes.func,
};
export default React.memo(SingleQuestion);
