import React from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import langConstant from "_lang";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  borderNone: {
    border: "none",
  },
  width100: {
    width: "100%",
  },
  profileMDBasicInfoIcon: {
    width: "18px !important",
    height: "18px !important",
    fill: "#000 !important",
  },
  inputProfileField: {
    padding: "5px",
    color: "#000000",
  },
  filedTitle: {
    color: "#a9a8a8",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  suppliersACMOdalOne: {
    right: "0px",
    width: "500px",
    bottom: "0px",
    position: "fixed",
    border: "1px solid rgb(230,230,230)",
    height: "calc(100% - 65px)",
    backgroundColor: "#fff",
    padding: "0px 30px 30px 30px",
    overflowY: "auto",
    "& .MuiDialogTitle-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
    },
    "& .MuiDialogContent-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "calc(100% - 60px)",
    },
  },
  profileMDCloseBtn: {
    top: "9px",
    right: "14px",
    width: "18px",
    height: "18px",
    position: "absolute",
  },
  profileMDCloseFirst: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  profileMDCloseSecond: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(-45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  suppliersTopHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "9px",
  },
  suppliersTopHeaderEdit: {
    "& > div": {
      width: "100%",
    },
    "& input": {
      height: "30px",
      padding: "5px 13px",
    },
  },
  supplierstatusWrapper: {
    "& > .MuiSwitch-root": {
      position: "relative",
      left: "-10px",
    },
  },
  PersonTitle: {
    color: "#000000",
    marginBottom: "5px",
    fontWeight: "500",
  },
  KeyTitle: {
    color: "#a9a8a8",
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },
  filedTitleIcon: {
    display: "flex",
    fontWeight: "500",
    "& svg": {
      marginRight: "6px",
      position: "relative",
      top: "-1px",
    },
  },
  wordBreak: {
    wordBreak: "break-all",
  },
}));

const Confirmation = ({ modal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customerId = useSelector(
    (state) => state?.authentication?.detailedInfo?.CUSTOMER_ID
  );

  const message = modal.data.message ? modal.data.message : "";

  const initialAttributeData = {
    NAME: "",
  };

  const validationSchema = Yup.object().shape({
    NAME: Yup.string()
      .required("Attribute name is required")
      .test("is-unique", "Attribute is already there", function (value) {
        return !modal?.data.includes(value?.trim());
      }),
  });

  const saveAttribute = (values) => {
    const tempAttributes = modal ? [...modal.data] : [];

    if (!tempAttributes.includes(values.NAME.trim())) {
      tempAttributes.push(values.NAME.trim());
      modal?.closeModal();
    }
  };

  const handleClose = () => {
    modal?.closeModal();
  };

  return (
    <Dialog
      open={modal?.isOpen}
      maxWidth="sm"
      fullWidth
      keepMounted
      onClose={modal?.closeModal}
    >
      <div className={classes.padding}>
        <DialogContent>
          <Grid container style={{ marginBottom: "10px", marginTop: "20px" }}>
            <Grid item xs={12}>
              <Typography>{message}</Typography>
            </Grid>
          </Grid>
          <Box mt="2%" display="flex" gap={2} justifyContent="right">
            <Button
              type="button"
              variant="contained"
              color="primary"
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
              onClick={() => {
                modal?.closeModal();
              }}
            >
              {langConstant.OK}
            </Button>
          </Box>
        </DialogContent>
      </div>
    </Dialog>
  );
};

Confirmation.propTypes = {
  modal: PropTypes.object,
};

export default Confirmation;
