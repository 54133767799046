import React from "react";

import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import propTypes from "prop-types";
import { Assignment, AssignmentReturn, Info } from "@mui/icons-material";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import { styles } from "../styles";
import { TileCard } from "./TileCard";
import "react-circular-progressbar/dist/styles.css";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { useSelector } from "react-redux";
import sessionService from "_services/session.service";
import ArticleIcon from "@mui/icons-material/Article";

const InfoTooltip = ({ message }) => (
  <Tooltip placement="left" sx={{ zIndex: 4 }} title={message}>
    <IconButton
      size="small"
      sx={{ top: -2, right: -2, position: "absolute", zIndex: 4 }}
    >
      <Info fontSize="small" />
    </IconButton>
  </Tooltip>
);

export const TopBarView = ({ assessmentDetails, kpiData, rfiOpen }) => {
  const themeDetails = useSelector(getThemeDetails);
  const userId = sessionService.getUserId();
  return (
    <Grid container spacing={2}>
      <Grid md={4} item display="flex" gap={2}>
        <Box display="flex" sx={styles.whiteWrapper} alignItems="center">
          <Avatar
            alt="assessment-priority"
            sx={{
              ...styles.avatarColor[assessmentDetails?.PRIORITY],
              fontWeight: "bold",
              width: 56,

              fontSize: 30,
            }}
            variant="rounded"
          >
            {assessmentDetails?.PRIORITY}
          </Avatar>
        </Box>
        <Box
          sx={styles.whiteWrapper}
          display="flex"
          flex={1}
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex" flex={1} flexDirection="column" gap={2}>
            <Typography variant="body2" fontSize="1rem">
              Assesment Name -
              <strong>{assessmentDetails?.REGULATION_SHORT_NAME}</strong>
            </Typography>
            <Typography variant="body2" fontSize="1rem">
              Legel Entity -{" "}
              <strong>{assessmentDetails?.LEGAL_ENTITY_NAME}</strong>
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box display="flex" flex={1} flexDirection="column" gap={2}>
            <Typography variant="body2" fontSize="1rem">
              Assigned To - <strong>{assessmentDetails?.USER_NAME}</strong>
            </Typography>
            <Typography variant="body2" fontSize="1rem">
              Assigned By -{" "}
              <strong>{assessmentDetails?.ASSIGNRD_BY_USER_NAME}</strong>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid md={8} item display="flex" gap={2}>
        <TileCard
          label="Evidences To Collect"
          count={Number(kpiData?.evidenceToCollect)}
          icon={<Assignment sx={{ fontSize: 44 }} />}
          color="blue"
        />
        <TileCard
          label="Answers To Prepare"
          count={Number(kpiData?.answersToPrepare)}
          icon={<ArticleIcon sx={{ fontSize: 44 }} />}
          color="yellowgreen"
        />
        <TileCard
          label="RFI Open"
          count={Number(rfiOpen)}
          icon={<AssignmentReturn sx={{ fontSize: 44 }} />}
          color="orange"
        />
        <TileCard
          label="Questions To Complete"
          count={Number(kpiData?.questionsToComplete)}
          icon={<FormatLineSpacingIcon sx={{ fontSize: 44 }} />}
          color="cadetblue"
        />
      </Grid>
    </Grid>
  );
};

InfoTooltip.propTypes = {
  message: propTypes.string.isRequired,
};

TopBarView.propTypes = {
  kpiData: propTypes.object,
  rfiOpen: propTypes.any,
  assessmentDetails: propTypes.object,
};
