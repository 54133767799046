import { Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import { Field } from "formik";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  formLabel: {
    fontWeight: "bold",
    fontSize: "16px",
    width: "35%",
    marginBottom: "5px",
  },
  inputField: {
    paddingRight: "10px",
    paddingLeft: "5px",
  },
}));

const FormTextField = ({
  fieldLabel,
  value,
  type,
  setFieldValue,
  fieldName,
  errors,
  labelClass = "",
  display = "flex",
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={labelClass ? labelClass : classes.formLabel}>
        {fieldLabel}
      </Typography>
      <Grid style={{ width: "100%" }}>
        <Field name={fieldName}>
          {() => (
            <TextField
              size="small"
              type={type}
              variant="outlined"
              className={display === "flex" ? classes.inputField : ""}
              fullWidth
              value={value}
              onChange={(e) => setFieldValue(fieldName, e.target.value)}
            />
          )}
        </Field>
        {errors && errors[fieldName] && (
          <Typography style={{ color: "red", fontSize: "12px" }}>
            {errors[fieldName]}
          </Typography>
        )}
      </Grid>
    </>
  );
};

FormTextField.propTypes = {
  fieldLabel: PropTypes.string,
  value: PropTypes.oneOfType([() => null, PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  setFieldValue: PropTypes.func,
  fieldName: PropTypes.string,
  errors: PropTypes.object,
  display: PropTypes.string,
  labelClass: PropTypes.oneOfType([
    () => null,
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default FormTextField;
