/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import sessionService from "./../_services/session.service";
const NoAuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          sessionService.getItem("authData") &&
          sessionService.getItem("jwtToken") &&
          !sessionService.getTempLogin()
        ) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              exact
              to={{ pathname: "/", state: { from: props.location } }}
            />
          );
        }

        // logged in so return component
        return <Component {...props} />;
      }}
    />
  );
};
NoAuthRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
};
export default NoAuthRoute;
