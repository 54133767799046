import { useDispatch, useSelector } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { Box, IconButton, Typography, Divider } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { areEqual } from "react-window";
import { forwardRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import sessionService from "_services/session.service";
import uploadDocumentService from "./services";
import { alertActions } from "_actions";

function getStyle({ provided, style, isDragging }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const marginBottom = 8;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    marginBottom,
  };
  return withSpacing;
}

export function Item({
  provided,
  item,
  style,
  isDragging,
  setFilteredFiles,
  items,
  file,
}) {
  const customerID = sessionService.getCustomerId();
  const userID = sessionService.getUserId();
  const regulationDetails = useSelector(
    (state) => state.manageQuestions.regulationDetail
  );
  const [isEdit, setIsEdit] = React.useState(false);

  const dispatch = useDispatch();
  const themeDetails = useSelector(getThemeDetails);

  const linkHandler = async () => {
    let response;
    if (isEdit) {
      response = await uploadDocumentService.updateCustomerRegulationPolicyMap(
        customerID,
        regulationDetails?.REGULATION_ID,
        file.POLICY_ID,
        item.REGULATIONDOCREQ_UNID,
        userID,
        item.ACTIVE
      );
    } else {
      response = await uploadDocumentService.putCustomerRegulationPolicyUpload(
        customerID,
        regulationDetails?.REGULATION_ID,
        file.POLICY_ID,
        userID
      );
    }
    if (response.status === 200) {
      setIsEdit(!isEdit);
    } else {
      dispatch(alertActions.error("Something went wrong"));
    }
  };

  return (
    <Box
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging })}
      sx={{
        border: isDragging
          ? `2px solid ${themeDetails?.ACTIONBUTTON_BGROUND_COLOUR}`
          : "1px solid #0000001f",
        borderRadius: 1,
        display: "inline-flex",
        gap: 2,
        alignItems: "center",
        p: 0.5,
        px: 1,
        // my: isDragging ? 2 : 0,
        boxShadow:
          isDragging &&
          `0px 0px 5px 0px ${themeDetails?.ACTIONBUTTON_BGROUND_COLOUR}`,
        transition: "all ease-in-out .3s",
        fontSize: 12,
      }}
    >
      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
        {item?.no}. &nbsp;
      </Typography>

      <Typography fontSize={12} flex={1}>
        {item?.DOCUMENT_RECOMMENDATION} ({item?.EVIDENCE_SHORTNAME})
      </Typography>

      {true && <StarIcon style={{ color: "gold" }} />}

      {item?.location === "right" && (
        <Box>
          <IconButton
            style={{ cursor: "pointer" }}
            onClick={() => {
              item.location = "left";
              setFilteredFiles((prev) => [item, ...prev]);
              const tempIndex = items.findIndex((i) => i.uuid === item.uuid);
              items.splice(tempIndex, 1);
            }}
            size="small"
          >
            <DeleteIcon color="error" />
          </IconButton>
          <IconButton
            title="Save Mapping"
            style={{ cursor: "pointer" }}
            onClick={linkHandler}
            size="small"
          >
            {isEdit ? <FmdGoodIcon /> : <AddLocationAltIcon />}
            {/* <Check color="success" fontSize="small" /> */}
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

// Recommended react-window performance optimisation: memoize the row render function
// Things are still pretty fast without this
export const Row = React.memo(function Row(props) {
  const rowRef = React.useRef();
  const { data, index, style } = props;
  const { items, setFilteredFiles, setSize, windowWidth, file } = data;
  const item = items[index];

  // React.useEffect(() => {
  //   setSize(index, rowRef?.current?.getBoundingClientRect?.()?.height);
  // }, [setSize, index, windowWidth]);

  return (
    <Draggable draggableId={item?.uuid} index={index} key={item?.uuid}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          items={items}
          style={style}
          index={index}
          setFilteredFiles={setFilteredFiles}
          file={file}
        />
      )}
    </Draggable>
  );
}, areEqual);

// eslint-disable-next-line react/display-name, react/prop-types
export const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
    }}
    {...rest}
    className="innerClass"
  />
));

Row.propTypes = {
  data: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object,
  file: PropTypes.any,
};
Item.propTypes = {
  provided: PropTypes.any,
  item: PropTypes.any,
  style: PropTypes.any,
  isDragging: PropTypes.bool,
  setFilteredFiles: PropTypes.func,
  items: PropTypes.array,
  file: PropTypes.any,
};
