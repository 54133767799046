/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import axios from "axios";
import { authConstants } from "containers/LoginPage/constants";
import sessionService from "./session.service";
import { store } from "../store";
import { loadingActions } from "_actions";

let isRefreshing = false;
let refreshSubscribers = [];
let isBlocked = false;

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (newToken) => {
  refreshSubscribers.forEach((cb) => cb(newToken));
  refreshSubscribers = [];
};

const refreshToken = async () => {
  try {
    const refreshToken = sessionService.getItem("refreshToken");
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }
    const response = await axios.post(
      window["REACT_APP_API_URL"] + `/user/auth/mfa/refreshToken`,
      { refreshToken },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    const newAccessToken = response.data.token;
    sessionService.setItem("jwtToken", newAccessToken);
    onRefreshed(newAccessToken);
    return newAccessToken;
  } catch (error) {
    console.error("Failed to refresh token", error);
    // Handle token refresh failure
    // Possibly log out the user
    sessionService.clear(); // Assumes sessionService has a method to clear all session items
    store.dispatch(loadingActions.end());
    store.dispatch({ type: authConstants.LOGOUT });
  } finally {
    isRefreshing = false;
    isBlocked = false;
  }
};

const apiCall = (service, customHeaders = {}) => {
  const headers = {
    "Content-type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    ...customHeaders,
  };

  const http = axios.create({
    baseURL: window["REACT_APP_API_URL"] + "/" + service,
    headers: headers,
  });

  http.interceptors.request.use(
    (config) => {
      if (isBlocked) {
        return new Promise((resolve, reject) => {
          subscribeTokenRefresh((newToken) => {
            config.headers.Authorization = `Bearer ${newToken}`;
            resolve(config);
          });
        });
      }
      const accessToken = sessionService.getItem("jwtToken"); // get stored access token
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`; // set in header
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { config, response } = error;
      const originalRequest = config;

      if (response && response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve) => {
            subscribeTokenRefresh((newToken) => {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              resolve(axios(originalRequest));
            });
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        isBlocked = true;

        try {
          const newToken = await refreshToken();
          if (newToken) {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return axios(originalRequest);
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }

      return Promise.reject(error);
    }
  );

  const get = (url) => {
    return http.get(`${url}`);
  };

  const send = (url, params, fileUploadProgress = () => {}) => {
    return http.post(url, params, { onUploadProgress: fileUploadProgress });
  };

  const update = (url, id, params) => {
    return http.put(`${url}/${id}`, params);
  };

  const remove = (url, id) => {
    return http.delete(`${url}/${id}`);
  };

  return {
    get,
    send,
    update,
    remove,
  };
};

const handleResponse = (response) => {
  if (response.status != 200) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      sessionService.clear();
      store.dispatch(loadingActions.end());
      store.dispatch({ type: authConstants.LOGOUT });
      //location.reload(true);
    }
    const error =
      (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  }
  return response.data;
};

export default {
  apiCall,
  handleResponse,
};
