import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { TileCard } from "./TileCard";
import { AccountCircle, Email, PendingActions } from "@mui/icons-material";

const iconColors = {
    evidence: "blue",
    rfi: "red",
    approvers: "green",
  },
  customStyles = {
    border: "1px solid #F0F4F8",
    borderRadius: 1,
    flexDirection: "row",
    alignItems: "stretch",
  };

const EvidenceKpis = ({ requested = 0, recieved = 0, approved = 0 }) => (
  <Box display="flex" gap={1}>
    <TileCard
      label="Requested"
      count={requested}
      icon={<PendingActions sx={{ fontSize: 44 }} />}
      color={iconColors.evidence}
      customStyles={customStyles}
    />
    <TileCard
      label="Recieved"
      count={recieved}
      icon={<PendingActions sx={{ fontSize: 44 }} />}
      color={iconColors.evidence}
      customStyles={customStyles}
    />
    <TileCard
      label="Approved"
      count={approved}
      icon={<PendingActions sx={{ fontSize: 44 }} />}
      color={iconColors.evidence}
      customStyles={customStyles}
    />
  </Box>
);

const RfiKpis = ({ sent = 0, overdue = 0, resolved = 0, pending }) => (
  <Box display="flex" flex={1} gap={1}>
    <TileCard
      label="Sent"
      count={sent}
      icon={<Email sx={{ fontSize: 44 }} />}
      color={iconColors.rfi}
      customStyles={customStyles}
    />
    <TileCard
      label="Overdue"
      count={overdue}
      icon={<Email sx={{ fontSize: 44 }} />}
      color={iconColors.rfi}
      customStyles={customStyles}
    />
    <TileCard
      label="Resolved"
      count={resolved}
      icon={<Email sx={{ fontSize: 44 }} />}
      color={iconColors.rfi}
      customStyles={customStyles}
    />
    <TileCard
      label="Pending"
      count={pending}
      icon={<Email sx={{ fontSize: 44 }} />}
      color={iconColors.rfi}
      customStyles={customStyles}
    />
  </Box>
);

const ApproverKpis = ({ required = 0, approved = 0, rejected = 0 }) => (
  <Box display="flex" gap={1}>
    <TileCard
      label="Required"
      count={required}
      icon={<AccountCircle sx={{ fontSize: 44 }} />}
      color={iconColors.approvers}
      customStyles={customStyles}
    />
    <TileCard
      label="Approved"
      count={approved}
      icon={<AccountCircle sx={{ fontSize: 44 }} />}
      color={iconColors.approvers}
      customStyles={customStyles}
    />
    <TileCard
      label="Rejected"
      count={rejected}
      icon={<AccountCircle sx={{ fontSize: 44 }} />}
      color={iconColors.approvers}
      customStyles={customStyles}
    />
  </Box>
);

EvidenceKpis.propTypes = {
  requested: PropTypes.number,
  recieved: PropTypes.number,
  approved: PropTypes.number,
};

RfiKpis.propTypes = {
  sent: PropTypes.number,
  overdue: PropTypes.number,
  resolved: PropTypes.number,
  pending: PropTypes.number,
};

ApproverKpis.propTypes = {
  required: PropTypes.number,
  approved: PropTypes.number,
  rejected: PropTypes.number,
};

export { EvidenceKpis, RfiKpis, ApproverKpis };
