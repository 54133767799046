import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import CustomTextField from "containers/manage-regulation/Policy/PolicyExplorer/component/PolicyUploadPopup/component/formField";
import Select from 'react-select';
import RuleBookService from "./services";
import sessionService from "_services/session.service";


const useStyles = makeStyles(() => ({
    inputProfileField: {
        padding: "7px 0px !important",
        width: "50%",
        color: "#000",
    },
    header: {
        fontWeight: "bold",
        paddingBottom: "15px",
        textAlign: "center"
    }
}))

const RuleBookDialogBox = ({open , setOpen, data }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const handleClose = () => setOpen(false)
    const [regulationOptions, setRegulationOptions] = useState([])

    const customerID = sessionService.getCustomerId()

    let countryOptions = [...new Map(data.map(item => [item['COUNTRY_CODE'], {value: item['COUNTRY_CODE'], label: item['COUNTRY_NAME']}])).values()]
    let regulatorOptions = [...new Map(data.map(item => [item['REGULATOR_ID'], {value: item['REGULATOR_ID'], label: item['REGULATOR_NAME']}])).values()]

    const initialValues = {
        COUNTRY : "",
        REGULATOR_ID: "",
        REGULATION_ID: "",
        NO_OF_QUESTIONS: "",
    }

    const findRegulations = (regulatorID) => {
        let tempData = [...data].filter((item) => item.REGULATOR_ID == regulatorID)
        setRegulationOptions([...new Map(tempData.map(item => [item['REGULATION_ID'], {value: item['REGULATION_ID'], label: item['REGULATION_NAME']}])).values()])
    }

    const validationSchema = yup.object({
        COUNTRY: yup.string().required("Country Is Required"),
        REGULATOR_ID: yup.string().required("Regulator Is Required"),
        REGULATION_ID: yup.string().required("Regulation Is Required"),
        NO_OF_QUESTIONS: yup.number().min(1, "Number Range from 1 to 1000").max(1000, "Number Range from 1 to 1000").required("Number Of Question Is Required")
    })

    const handleSubmit = (values) => {
        const payload={
            i_NO_OF_QUESTIONS: values.NO_OF_QUESTIONS,
            i_REGULATION_ID: values.REGULATION_ID,
            i_REGULATOR_ID: values.REGULATOR_ID,
            i_COUNTRY_CODE: values.COUNTRY,
            i_CUSTOMER_ID: customerID
        }
        dispatch(RuleBookService.putNewTemplateQuestions(payload))
        handleClose()
    }

    const customStyles = {
        control: base => ({
          ...base,
          paddingTop: 4,
          paddingBottom: 4,
          width: "80%"
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        container: provided => ({
            ...provided,
            padding: "10px 0"
          }),
      };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <Grid container>
                    <Grid item md={11} xs={9}>
                        <Typography className={classes.header}>{langConstant.ADD_NEW_RULE_BOOK}</Typography>
                    </Grid>
                    <Grid container item md={1} xs={3} justify="flex-end">
                        <CloseIcon style={{cursor: "pointer", fontWeight: "bold"}} onClick={handleClose} />
                    </Grid>
                </Grid>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                >
                    {({values, errors, setFieldValue}) => (
                        <Form>
                            <Grid container>
                                <Grid item md={6} xs={12} style={{paddingBottom: "10px"}}>
                                    <Select
                                        options={countryOptions}
                                        placeholder={langConstant.COUNTRY}
                                        value={countryOptions.find((item) => item.value == values['COUNTRY'])}
                                        onChange={(types) => {
                                            setFieldValue("COUNTRY", types['value'])
                                        }}
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                    />
                                    <ErrorMessage name="COUNTRY">
                                        {(msg) => <Typography style={{ color: "red" }}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item md={6} xs={12} style={{paddingBottom: "10px"}}>
                                    <Select
                                        options={regulatorOptions}
                                        placeholder={langConstant.SELECT_REGULATOR}
                                        value={regulatorOptions.find((item) => item.value == values['REGULATOR_ID'])}
                                        onChange={(types) => {
                                            setFieldValue("REGULATOR_ID", types['value'])
                                            findRegulations(types['value'])
                                            setFieldValue("REGULATION_ID", "")
                                        }}
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                    />
                                    <ErrorMessage name="REGULATOR_ID">
                                        {(msg) => <Typography style={{ color: "red" }}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item md={6} xs={12} style={{paddingBottom: "10px"}}>
                                    <Select
                                        options={regulationOptions}
                                        placeholder={langConstant.SELECT_REGULATION}
                                        value={values['REGULATION_ID'] ? regulationOptions.find((item) => item.value == values['REGULATION_ID']) : ""}
                                        onChange={(types) => {
                                            setFieldValue("REGULATION_ID", types['value'])
                                        }}
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                    />
                                    <ErrorMessage name="REGULATION_ID">
                                        {(msg) => <Typography style={{ color: "red" }}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item md={6} xs={12} style={{paddingBottom: "10px"}}>
                                    <Field
                                        name="NO_OF_QUESTIONS"
                                        type="number"
                                        component={CustomTextField}
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setFieldValue("NO_OF_QUESTIONS", e.target.value)
                                            }
                                        }}
                                        placeholder={langConstant.NO_OF_QUESTIONS}
                                        value={values.NO_OF_QUESTIONS}
                                    />
                                    <ErrorMessage name="NO_OF_QUESTIONS">
                                        {(msg) => <Typography style={{ color: "red" }}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained" 
                                    style={{ marginRight: "10px" }}
                                >{langConstant.SAVE}
                                </Button>
                                <Button color="primary" type="button" variant="outlined" onClick={handleClose} >
                                    {langConstant.CANCEL}
                                </Button>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

RuleBookDialogBox.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.array
};

export default RuleBookDialogBox;