/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Box, Grid, Divider, Typography } from "@material-ui/core";

import langConstant from "_lang";
// import DataPointQuality from "_components/DataPointQuality";
import rootStyles from "rootStyles";

import { useParams } from "react-router";
const ApproveQuestion = ({ item, assessmentObj, roleBasedConstant }) => {
  const rootClasses = rootStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs zeroMinWidth>
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}></Grid>

          <Grid item style={{ minWidth: "130px" }}>
            <Typography color="textSecondary" variant="body1" gutterBottom>
              <strong>{item.ref} -</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" gutterBottom>
              <strong>{item.sentence}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}></Grid>

          <Grid item style={{ minWidth: "130px" }}>
            <Typography variant="body1" gutterBottom>
              <strong> {langConstant.METADATA}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}></Grid>

          <Grid item>
            <Typography variant="body2" gutterBottom>
              {item.mappedControl[0]
                ? item.mappedControl.map((item, index) => {
                    return (
                      <Box mt={1} key={index}>
                        {item.control} &nbsp;&nbsp;.{item.dataPoints}
                      </Box>
                    );
                  })
                : langConstant.NO_METADATA_AVAILABLE}
            </Typography>
          </Grid>
        </Grid>
        {/* Narrative Grid */}
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}></Grid>
          <Grid item style={{ minWidth: "130px" }}>
            <Typography variant="body1" gutterBottom>
              <strong> {langConstant.NARRATIVES}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={rootClasses.flexNoWrap}>
          <Grid item style={{ minWidth: "50px" }}></Grid>
          <Grid item>
            <Typography variant="body2" gutterBottom>
              {item.narratives
                ? langConstant.NARRATIVES_WITHIN_SCOPE(item.narratives)
                : langConstant.NO_NARRATIVES_AVAILABLE}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ padding: 0 }}>
        <Box mb={2}>
          <Divider />
        </Box>
      </Grid>
    </Grid>
  );
};
ApproveQuestion.propTypes = {
  item: PropTypes.object,
  assessmentObj: PropTypes.object,
  roleBasedConstant: PropTypes.object,
};
export default React.memo(ApproveQuestion);
