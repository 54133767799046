import { Dialog, DialogContent, DialogTitle, Grid, Typography, makeStyles, Button, IconButton } from "@material-ui/core";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import langConstant from "_lang";

const useStyles = makeStyles(() => ({
    entiBtn: {
        // backgroundColor: "#124571 !important",
        marginRight: "10px !important"
    },
    noBtn: {
        color: "#124571 !important",
    },
    dialogText: {
        fontWeight: "bold"
    },
    dialogTitle: {
        padding: "10px 24px 0",
        textAlign: "right"
    },
    dialogContent: {
        paddingBottom: "15px",
        "& p": {
            padding: "0 0 15px"
        }
    }
}))


const ConfirmDialogBox = ({ isDialogOpen, setIsDialogOpen, changeAssessmentFlag }) => {

    const classes = useStyles()
    const HandleDiaglogClose = () => { setIsDialogOpen(false) }

    return (
        <Dialog
            open={isDialogOpen}
            maxWidth={"sm"}
            keepMounted
            onClose={HandleDiaglogClose}
            disableEnforceFocus
        >
            <DialogTitle className={classes.dialogTitle}>
                <IconButton aria-label="close" onClick={HandleDiaglogClose} ><CloseIcon /></IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid>
                    <Typography className={classes.dialogText}>
                        {langConstant.QUESTION_IS_ALREADY_IN_LIVE_ASSESSMENT}
                    </Typography>
                </Grid>
                <Grid>
                    <Button color="primary" className={classes.entiBtn} variant="contained" size="small" onClick={() => changeAssessmentFlag("Y")}>{langConstant.YES}</Button>
                    <Button color="primary" size="small" onClick={() => changeAssessmentFlag("N")}>{langConstant.NO}</Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}


ConfirmDialogBox.propTypes = {
    isDialogOpen: PropTypes.bool,
    setIsDialogOpen: PropTypes.func,
    changeAssessmentFlag: PropTypes.func
}

export default ConfirmDialogBox;