/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Box, Grid, TextField, Divider } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import rootStyles from "../../../../../rootStyles";
import Truncate from "../../../../../_components/Truncate";
import questionMappingAction from "../actions";

const ConfirmationStep = (props) => {
  const rootClasses = rootStyles();
  const [list, setList] = React.useState(props.questionDraft);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setList(props.questionDraft);
  }, []);

  if (list.length == 0) {
    return true;
  }

  return (
    <Box mt={2}>
      {list.map((item, index) => {
        if (item.selected) {
          return (
            <Grid container spacing={1} key={index}>
              <Grid item>
                <p>{item.ref} -</p>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs>
                  <p>{item.sentence}</p>
                  {item.info ? (
                    <Box className="info-box">
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                          <InfoIcon color="inherit" />
                        </Grid>
                        <Grid item>
                          <Truncate content={item.info} wordCount={50} />
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                  <Box mt={2} mb={1}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      id="searchText"
                      label=""
                      name="searchText"
                      multiline
                      placeholder=""
                      value={item.narratives}
                      rows={4}
                      rowsMax={Infinity}
                      InputProps={{
                        readOnly: true,
                        style: { fontSize: 14 },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              {list.length - 1 > index && (
                <Grid item xs={12} style={{ padding: 0 }}>
                  <Divider />
                </Grid>
              )}
            </Grid>
          );
        }
      })}
    </Box>
  );
};
ConfirmationStep.propTypes = {
  questionDraft: PropTypes.array,
};
export default ConfirmationStep;
