import { Box, Typography } from "@mui/material";
import React from "react";
import langConstant from "_lang";

const SelectRegulation = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography
        style={{ fontSize: "14px", color: "grey", fontWeight: "bold" }}
      >
        {langConstant.PLEASE_SELECT_REGULATION_FIRST}
      </Typography>
    </Box>
  );
};

export default SelectRegulation;
