import {
  Visibility,
  Person,
  Whatshot,
  NotificationsActive,
  NoteAdd,
  DateRange,
  AttachFile,
} from "@mui/icons-material";

export const AuditActionConstants = {
  SET_AUDIT_ACTION_LIST: "SET_AUDIT_ACTION_LIST",
  SET_ACTION_LIST_DETAILS: "SET_ACTION_LIST_DETAILS",
  AUDIT_ACTIONS_CLEANUP: "AUDIT_ACTIONS_CLEANUP",
  SET_USERS_LIST: "SET_USERS_LIST",
};

export const ACTION_STATUS_LIST = [
  { value: "TO_DO", label: "To Do" },
  { value: "IN_PROGRESS", label: "In Progress" },
  { value: "DONE", label: "Done" },
];

export const priorities = ["High", "Medium", "Low"];
export const linkTypes = ["Assesment", "Policy", "Comparision", "Regulation"];

export const listOfActions = [
  { label: "View Action", icon: Visibility },
  { label: "Assigned To", icon: Person },
  { label: "Set Status", icon: Whatshot },
  { label: "Set Priority", icon: NotificationsActive },
  // { label: "Add / View Comment", icon: Sms },
  { label: "Add Note", icon: NoteAdd },
  { label: "Closure Date", icon: DateRange },
  { label: "Add Attachment", icon: AttachFile, isDisabled: true },
];
