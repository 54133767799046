import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import langConstant from "_lang";
import DialogBox from "_components/DialogBox";
import PropTypes from "prop-types";
import TopicUtils from "../utils";
import useStyles from "../styles";
import HelpServices from "../services";
import sessionService from "_services/session.service";
import FormTextField from "_components/FormTextField";
import CustomRichTextEditor from "./CustomEditor";
import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import Select from "react-select";
import { Form, Formik, Field } from "formik";
import { virusCheckerService } from "_helpers/utils";
import VerifiedIcon from "@mui/icons-material/Verified";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import { alertActions, loadingActions } from "_actions";
// import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const styles = {
  wrapperStyle: {
    border: "1px solid #969696",
  },
  editorStyle: {
    padding: "0.5rem",
  },
};

const AddTopic = ({
  open,
  setOpen,
  selectedTopic,
  type,
  content,
  setContent,
  setFilteredContent,
  searchInput,
  searchData,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [sanctionKeywords, setSanctionKeywords] = useState("");
  const [errorTopicGroup, setErrorTopicGroup] = useState(false);
  const [errorTopicName, setErrorTopicName] = useState(false);
  const [errorTopicContent, setErrorTopicContent] = useState(false);

  const onlineHelp = useSelector(
    (state) => state.authentication.detailedInfo.ONLINE_HELP
  );

  const classes = useStyles();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();
  const isVirusCheckingAvailable = useSelector(
    (state) => state.authentication?.detailedInfo?.VIRUS_CHECKER
  );
  const [isVirusFile, setIsVirusFile] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const showAlert = (message, type = "error") => {
    if (!message) return;
    dispatch(alertActions?.[type]?.(message));
  };

  const handleEditorCancel = (setFieldValue, data) => {
    setFieldValue("TOPIC_CONTENT", data);
  };

  const validateSanctionKeywords = (values) => {
    let field = values["TOPIC_GROUP"].toLowerCase();
    let matchCount = 0;
    for (let keyword of sanctionKeywords) {
      if (field.includes(keyword.toLowerCase())) {
        setErrorTopicGroup(true);
        matchCount++;
      }
    }

    field = values["TOPIC_NAME"].toLowerCase();
    for (let keyword of sanctionKeywords) {
      if (field.includes(keyword.toLowerCase())) {
        setErrorTopicName(true);
        matchCount++;
      }
    }

    field = values["TOPIC_CONTENT"].toLowerCase();

    for (let keyword of sanctionKeywords) {
      if (field.includes(keyword.toLowerCase())) {
        setErrorTopicContent(true);
        matchCount++;
      }
    }
    return matchCount;
  };

  const onSubmit = async (values) => {
    setErrorTopicGroup(false);
    setErrorTopicName(false);
    setErrorTopicContent(false);

    if (validateSanctionKeywords(values) != 0) {
      return;
    }

    if (
      !selectedTopic?.TOPIC_URL &&
      values?.TYPE.toString().toLowerCase().trim() ==
        ("text + document" || "text + video")
    ) {
      await handleUpload(values?.document);
    }

    dispatch(
      HelpServices.UpdateOnlineHelpItem(
        customerID,
        values,
        selectedTopic,
        1,
        handleClose,
        content,
        setContent,
        setFilteredContent,
        searchInput,
        searchData
      )
    );
    handleClose();
  };

  const handleUpload = async (file) => {
    // const BUCKET = `regulativ-${window.REACT_APP_ENV?.toLowerCase()}`,
    //   REGION = "us-east-1",
    //   CLOUDFRONT_URL = defaultDocUrl,
    //   ONLINE_HELP_FOLDER = onlineHelp,
    //   REACT_APP_AWS_ACCESS_KEY_ID = userInfo?.REACT_APP_AWS_ACCESS_KEY_ID
    //     ? window.atob(userInfo?.REACT_APP_AWS_ACCESS_KEY_ID)
    //     : "",
    //   REACT_APP_AWS_SECRET_ACCESS_KEY =
    //     userInfo?.REACT_APP_AWS_SECRET_ACCESS_KEY
    //       ? window.atob(userInfo?.REACT_APP_AWS_SECRET_ACCESS_KEY)
    //       : "";
    const ONLINE_HELP_FOLDER = onlineHelp || "Online_Help";

    // const S3_CLIENT = new S3Client({
    //   region: REGION,
    //   credentials: {
    //     accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
    //     secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
    //   },
    //   // endpoint: CLOUDFRONT_URL, //cloudfront
    // });

    try {
      setIsUploading(true);
      let filePath = `${customerID}/${ONLINE_HELP_FOLDER}/`,
        fileName = file?.name?.replaceAll(" ", "_");

      // const params = {
      //   Bucket: BUCKET,
      //   Key: filePath + fileName,
      //   Body: file /** object body */,
      // };
      // const command = new PutObjectCommand(params);
      // const data = await S3_CLIENT.send(command);
      // if (data?.$metadata?.httpStatusCode === 200) {
      const formData = new FormData();
      formData.append("document_obj", file);
      formData.append("fileLocation", filePath + fileName);

      const res = await HelpServices.UploadFileOnS3Bucket(formData);
      if (res?.status === 200) {
        setIsUploading(false);
      }
    } catch (error) {
      setIsUploading(false);
      showAlert(error?.toString() || "Unable to upload file");
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    setErrorTopicGroup(false);
    setErrorTopicName(false);
    setErrorTopicContent(false);
    dispatch(
      HelpServices.GetCustomerSanctionKeywords(customerID, setSanctionKeywords)
    );
  }, []);

  return (
    <DialogBox
      maxWidth={"md"}
      title={
        selectedTopic
          ? langConstant.EDIT_HELP_TOPIC
          : langConstant.ADD_HELP_TOPIC
      }
      open={open}
      handleClose={handleClose}
    >
      <Formik
        initialValues={
          selectedTopic ? selectedTopic : TopicUtils.topicInitialValues
        }
        validationSchema={TopicUtils.topicValidationSchema}
        onSubmit={onSubmit}
        onChange={() => {
          setErrorTopicGroup(false);
          setErrorTopicName(false);
          setErrorTopicContent(false);
        }}
        enableReinitialize
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Grid container>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.TOPIC_GROUP}
                  value={values.TOPIC_GROUP}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="TOPIC_GROUP"
                  errors={errors}
                />
                {errorTopicGroup && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {langConstant.VALIDATE_SANCTION_KEYWORDS_MESSAGE}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.TOPIC_NAME}
                  value={values.TOPIC_NAME}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="TOPIC_NAME"
                  errors={errors}
                />

                {errorTopicName && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {langConstant.VALIDATE_SANCTION_KEYWORDS_MESSAGE}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.TOPIC_CONTENT}
                </Typography>

                <Field name="TOPIC_CONTENT">
                  {({ field: { value } }) => (
                    <CustomRichTextEditor
                      onChangeHandler={(e) => setFieldValue("TOPIC_CONTENT", e)}
                      data={value}
                      onCancelClick={() => {
                        handleEditorCancel(setFieldValue, values.TOPIC_CONTENT);
                      }}
                    />
                  )}
                </Field>

                {errors && errors["TOPIC_CONTENT"] && (
                  <Typography
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors["TOPIC_CONTENT"]}
                  </Typography>
                )}

                {errorTopicContent && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {langConstant.VALIDATE_SANCTION_KEYWORDS_MESSAGE}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                style={{ marginBottom: "10px", marginTop: "40px" }}
              >
                <Typography className={classes.formFieldLabel}>
                  {langConstant.TOPIC_URL}
                </Typography>

                {selectedTopic?.TOPIC_URL && (
                  <Typography>{selectedTopic?.TOPIC_URL}</Typography>
                )}

                {!selectedTopic?.TOPIC_URL && (
                  <Field name="document">
                    {() => (
                      <input
                        type="file"
                        accept=".doc,.docx,.pdf,.png,.jpg,.jpeg,.html,.xlsx,.txt,.mp4,.avi,.mov,.img,.bmp,.img"
                        onChange={async (e) => {
                          if (e.target.files.length > 0) {
                            if (isVirusCheckingAvailable == 1) {
                              const fData = new FormData();
                              fData.append("file", e.target.files[0]);
                              dispatch(loadingActions.start());
                              dispatch(
                                loadingActions.setLoaderText(
                                  "Checking Virus In Uploaded File"
                                )
                              );
                              const response = await virusCheckerService(fData);
                              dispatch(loadingActions.setLoaderText(null));
                              dispatch(loadingActions.end());
                              if (response.status == 200) {
                                if (response.data && response.data.hasVirus) {
                                  setIsVirusFile(true);
                                  dispatch(
                                    alertActions.success(response.data.message)
                                  );
                                } else {
                                  setIsVirusFile(false);
                                  dispatch(
                                    alertActions.success(response.data.message)
                                  );
                                  setFieldValue("document", e.target.files[0]);
                                  const fileData =
                                    e.target.files[0].name.split(".");
                                  setFieldValue(
                                    "document_extension",
                                    fileData.length >= 2
                                      ? fileData[fileData.length - 1]
                                      : ""
                                  );
                                  if (
                                    e.target.files[0].name.split(".").pop() ==
                                      "docx" ||
                                    e.target.files[0].name.split(".").pop() ==
                                      "txt" ||
                                    e.target.files[0].name.split(".").pop() ==
                                      "doc"
                                  ) {
                                    setFieldValue("is_pre_proccesing", true);
                                  }
                                }
                              } else {
                                dispatch(
                                  alertActions.error(
                                    "Virus Checker Service Failed"
                                  )
                                );
                              }
                            } else {
                              setFieldValue("document", e.target.files[0]);
                              const fileData =
                                e.target.files[0].name.split(".");
                              setFieldValue(
                                "document_extension",
                                fileData.length >= 2
                                  ? fileData[fileData.length - 1]
                                  : ""
                              );
                              if (
                                e.target.files[0].name.split(".").pop() ==
                                  "docx" ||
                                e.target.files[0].name.split(".").pop() == "doc"
                              ) {
                                setFieldValue("is_pre_proccesing", true);
                              }
                            }
                          } else {
                            setFieldValue("document", "");
                            setFieldValue("document_extension", "");
                            setFieldValue("is_pre_proccesing", false);
                          }
                        }}
                      />
                    )}
                  </Field>
                )}

                {isVirusCheckingAvailable == 1 && (
                  <span style={{ paddingLeft: "10px" }}>
                    {values.document &&
                      (isVirusFile ? (
                        <Tooltip title="Virus Found In File" placement="right">
                          <CoronavirusIcon color="error" />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Virus Not Found In File"
                          placement="right"
                        >
                          <VerifiedIcon color="success" />
                        </Tooltip>
                      ))}
                  </span>
                )}
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
                style={{ marginBottom: "10px", fontWeight: "bold" }}
              >
                <Typography className={classes.formFieldLabel}>
                  {langConstant.TOPIC_TYPE}
                </Typography>
                <Field style={{ width: "100%" }} name="TYPE">
                  {({ field }) => (
                    <Select
                      {...field}
                      options={type}
                      id="TYPE"
                      menuPortalTarget={document.body}
                      errors={errors}
                      value={type.find((t) => values.TYPE == t.value)}
                      onChange={(option) => {
                        setFieldValue("TYPE", option.value);
                      }}
                    />
                  )}
                </Field>

                {errors && errors["TYPE"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["TYPE"]}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid style={{ textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                {selectedTopic ? langConstant.SAVE : langConstant.ADD}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogBox>
  );
};

AddTopic.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  selectedTopic: PropTypes.object,
  type: PropTypes.array,
  content: PropTypes.array,
  setContent: PropTypes.func,
  setFilteredContent: PropTypes.func,
  searchInput: PropTypes.string,
  searchData: PropTypes.func,
};

export default AddTopic;
