/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import { Box, Button } from "@material-ui/core";

import QuestionsPanel from "./components/QuestionsPanel";

const AssessmentNavigator = ({
  selectedTab,
  selectedRfisList,
  setPageNumber,
  onClose,
}) => {
  return (
    <Box style={{ overflow: "hidden" }}>
      <Button
        onClick={() => {
          onClose();
        }}
        style={{
          position: "absolute",
          left: "-50px",
          top: "50vh",
          backgroundColor: "rgba(240, 20, 47, 1)",
          color: "white",
          borderRadius: "6px 0px 0px 6px",
          zIndex: "100",
          minWidth: "50px ",
        }}
      >
        <ExploreOutlinedIcon />
      </Button>

      <Box
        style={{
          display: "flex",
          height: "100%",
          padding: "65px 0px 80px 0px",
        }}
      >
        <QuestionsPanel
          selectedRfisList={selectedRfisList}
          setPageNumber={setPageNumber}
          selectedTab={selectedTab}
        />
      </Box>
    </Box>
  );
};

AssessmentNavigator.propTypes = {
  onClose: PropTypes.func,
  setPageNumber: PropTypes.func,
  selectedRfisList: PropTypes.array,
  selectedTab: PropTypes.string,
};
export default AssessmentNavigator;
