import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CreateIcon from "@mui/icons-material/Create";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";

const CompanyData = ({ data, setSelectedDataAsset, setShowDataAsset }) => {
  return (
    <Box>
      <Grid style={{ textAlign: "right", marginBottom: "10px" }}>
        <IconButton color="primary">
          <AddBoxRoundedIcon
            onClick={() => {
              setSelectedDataAsset({
                UNID: null,
                DATA_ASSET_NAME: "COMPANY_DATA",
                DATA_ASSET_ATTRIBUTE_NAME: "",
                SOURCE_DATA_POINT_VALUE: "",
              });
              setShowDataAsset(true);
            }}
          />
        </IconButton>
      </Grid>
      {data.map((cData, index) => {
        const attributeName = cData.DATA_ASSET_ATTRIBUTE_NAME.replaceAll(
          "_",
          " "
        );
        return (
          <React.Fragment key={index}>
            <Grid
              style={{
                alignItems: "center",
                padding: "5px 0",
              }}
              container
            >
              <Grid item md={10} xs={8}>
                <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {attributeName}
                </Typography>
              </Grid>
              <Grid item md={2} xs={4}>
                {cData.SOURCE_DATA_POINT_VALUE ? (
                  <CheckCircleOutlineOutlinedIcon
                    color="primary"
                    fontSize="small"
                  />
                ) : (
                  <CancelOutlinedIcon color="error" fontSize="small" />
                )}
                <CreateIcon
                  fontSize="small"
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                  onClick={() => {
                    setSelectedDataAsset(cData);
                    setShowDataAsset(true);
                  }}
                  color="success"
                />
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

CompanyData.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  setSelectedDataAsset: PropTypes.func,
  setShowDataAsset: PropTypes.func,
};

export default CompanyData;
