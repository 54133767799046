import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Box,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import RichTextEditor from "_components/RichTextEditor/RichTextEditor";
import langConstant from "_lang";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardContent } from "@mui/material";


const ShowTabContent = ({ selectedTab, saveAnswer }) => {
  const [editAnswer, setEditAnswer] = useState(false);

  const saveAnswerFunction = (data) => {
    saveAnswer(data)
    setEditAnswer(false)
  }

  return (
    // <div style={{ fontSize: '1rem', padding: '1rem' }}>
      <Card variant="outlined" style={{ height: "100%" }}>
        <CardContent style={{ padding: "5px", height: "100%" }}>
          <PerfectScrollbar >
            {selectedTab.id === 4 || selectedTab.id === 5 ?
              (<TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow >
                      {selectedTab?.data.headers?.length && selectedTab?.data.headers.map((header) => (

                        <TableCell key={header} style={{ textTransform: 'uppercase', padding: '5px' }}>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedTab?.data.rowData?.length && selectedTab?.data.rowData.map((el,i) => {

                      switch (selectedTab.id) {
                        
                        case 4: return (
                          <TableRow
                            key={`${el.SOURCE_DATA_ID}${i}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                              {el.DATA_ASSET_ATTRIBUTE_NAME}
                            </TableCell>
                            <TableCell style={{ padding: '5px' }}>{el.SOURCE_DATA_POINT_VALUE}</TableCell>
                          </TableRow>

                        )

                        case 5: return (
                          <TableRow
                            key={`${el.EVIDENCE_LOG_ID}${i}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" style={{ padding: '5px' }}>
                              {el.EVIDENCE_SHORTNAME}
                            </TableCell>
                            <TableCell style={{ padding: '5px' }}>{el.accuracy}</TableCell>
                            <TableCell style={{ padding: '5px' }}>{el.suitability}</TableCell>
                            <TableCell style={{ padding: '5px' }}>{el?.DESCRIPTION}</TableCell>

                          </TableRow>
                        );

                        default:
                          break;
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>) : selectedTab.id === 0 ? (
                <Box>
                  {!editAnswer && <Box style={{ display: 'flex' }}>
                    <Box style={{ width: '100%' }}>
                      <div dangerouslySetInnerHTML={{ __html: selectedTab?.data }}></div>
                    </Box>
                    <Box>
                      <Button variant="outlined" size="small" color='primary'
                        onClick={() => setEditAnswer(true)}
                        startIcon={<EditIcon />}>
                        {langConstant.EDIT}
                      </Button>
                    </Box>
                  </Box>}
                  {editAnswer && <Box>
                    <RichTextEditor data={selectedTab?.data ? selectedTab.data : ''} onCancelClick={setEditAnswer} onSaveClick={saveAnswerFunction} />
                  </Box>
                  }
                </Box>
              ) : (
                <Box>
                  {selectedTab?.data}
                </Box>
              )
            }
          </PerfectScrollbar>
        </CardContent>
      </Card>
    // </div>
  )
}
ShowTabContent.propTypes = {
  selectedTab: PropTypes.any,
  saveAnswer: PropTypes.func
};
export default ShowTabContent;