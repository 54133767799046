import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import langConstant from "_lang";
import React from "react";
import PropTypes from "prop-types";
import { normalViewColumns as columns } from "./utils";

const useStyles = makeStyles(() => ({
    stickyColl: {
        position: "sticky",
        right: 0,
        paddingTop:"10px",
        paddingBottom:"10px",
      }
  }));

const DataTable = ({filteredRows, page, rowsPerPage}) => {
    const classes = useStyles()

    return (
        <TableContainer component={Paper}>
            <Table className="data-table">
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => (
                            <TableCell
                                component="th"
                                scope="row"
                                key={"column_" + index + item.field}
                                className={classes.stickyColl}
                                style={{ minWidth: item.minWidth, textAlign: "center" }}
                            >
                                {item.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                    ? filteredRows.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                        )
                    : filteredRows
                    ).map((row, rowIndex)=>(
                        <TableRow key={"row_" + rowIndex}>
                            {columns.map((column, index) => {
                                const value = row[column.field];
                                return (
                                    <TableCell
                                        component="td"
                                        scope="row"
                                        key={"column_" + index + row[columns[0].field]}
                                        style={{textAlign: "center"}}
                                    >
                                        {column.format ? column.format(value) : value}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                    {filteredRows.length === 0 && 
                        <TableRow style={{ height: 53}}>
                            <TableCell
                                style={{ textAlign: "center" }}
                                colSpan={columns.length}
                            >
                                {langConstant.NO_RECORD_FOUND}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

DataTable.propTypes = {
    filteredRows: PropTypes.array,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
}

export default DataTable;