import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import langConstant from "_lang";
import { NarrativeTable } from "./NarrativeTable";
import { EditAnswer } from "./EditAnswer";

const TabsContent = ({
  selectedTab,
  SaveAnswerFromRichTextEditor,
  bottomTabs = false,
  EvidenceList,
  DataAssetsList,
  currentQuestion,
  rfiNarrative,
  isQuestionEditable,
  currentRfi,
  showAlert,
}) => {
  const [editAnswer, setEditAnswer] = useState(false);
  const EvidenceTableHeaders = [
    "EVIDENCE TYPE",
    "Accuracy",
    "Suitability",
    "Classification",
    "Evidence File Name",
    "Evidence Uploaded By",
  ];
  const DataAssetsTableHeaders = ["Data Assets Name", "Data Assets Value"];

  const saveAnswerFunction = (data) => {
    SaveAnswerFromRichTextEditor(data);
    setEditAnswer(false);
  };

  const TableRenderer = (Headers, Rowdata, id) => {
    const metaDataRender = (MetaData) => {
      let accuracy;
      let suitability;
      let value = MetaData;

      if (MetaData === null) {
        accuracy = "No Data";
        suitability = "No Data";
      } else {
        if (value && value !== "MQ==") {
          let tempData = window.atob(value);
          tempData = tempData.slice(1, -1);
          tempData = tempData.replaceAll("], ", "]&");
          const convertedData = new URLSearchParams(tempData);
          value = JSON.parse(convertedData.get("score"));
        } else if (value === "MQ==") {
          value = [];
        }

        accuracy = value?.length ? value[0] : "No Data";
        suitability = value?.length ? value[1] : "No Data";
      }

      return (
        <>
          <TableCell style={{ padding: "5px" }}>{accuracy}</TableCell>
          <TableCell style={{ padding: "5px" }}>{suitability}</TableCell>
        </>
      );
    };

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {Headers.map((header) => (
                <TableCell
                  key={header}
                  style={{ textTransform: "uppercase", padding: "5px" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Rowdata.length > 0 &&
              Rowdata.map((el, i) => {
                switch (id) {
                  case 4:
                    return (
                      <TableRow
                        key={`${el.SOURCE_DATA_ID}${i}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {el.DATA_ASSET_ATTRIBUTE_NAME}
                        </TableCell>
                        <TableCell style={{ padding: "5px" }}>
                          {el.SOURCE_DATA_POINT_VALUE}
                        </TableCell>
                      </TableRow>
                    );

                  case 5:
                    return (
                      <TableRow
                        key={`${el.EVIDENCE_LOG_ID}${i}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {el.EVIDENCE_SHORTNAME}
                        </TableCell>
                        {metaDataRender(el.METADATA)}
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {el?.DESCRIPTION}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {`${el?.FILE_NAME} ${el?.FILE_FORMAT}`}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {`${el.FIRST_NAME} ${el.LAST_NAME}`}
                        </TableCell>
                      </TableRow>
                    );

                  default:
                    break;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (bottomTabs) {
    return (
      <div style={{ fontSize: "1rem", padding: "1rem" }}>
        {selectedTab.id === 4 &&
          TableRenderer(DataAssetsTableHeaders, DataAssetsList, selectedTab.id)}
        {selectedTab.id === 5 &&
          TableRenderer(EvidenceTableHeaders, EvidenceList, selectedTab.id)}
        {selectedTab?.id === 6 && (
          <NarrativeTable rfiNarrativeList={rfiNarrative} />
        )}
        {selectedTab.id === 0 && (
          <Box>
            {!editAnswer && (
              <Box style={{ display: "flex" }}>
                <Box style={{ width: "100%" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion?.CURRENT_ANSWER_SELECTED_RESPONSE
                        ? currentQuestion?.CURRENT_ANSWER_SELECTED_RESPONSE
                        : "",
                    }}
                  ></div>
                </Box>
                <Box>
                  {isQuestionEditable && (
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => setEditAnswer(true)}
                      startIcon={<EditIcon />}
                    >
                      {langConstant.EDIT}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            {editAnswer && isQuestionEditable && (
              <EditAnswer
                currentQuestion={currentQuestion}
                currentRfi={currentRfi}
                onSaveAnswer={saveAnswerFunction}
                setEditAnswer={setEditAnswer}
                showAlert={showAlert}
              />
            )}
          </Box>
        )}
        {selectedTab.id === 1 &&
          (currentQuestion?.HIST_ANSWER_RESPONSE_01
            ? currentQuestion?.HIST_ANSWER_RESPONSE_01
            : "")}
        {selectedTab.id === 2 &&
          (currentQuestion?.HIST_ANSWER_RESPONSE_02
            ? currentQuestion?.HIST_ANSWER_RESPONSE_02
            : "")}
        {selectedTab.id === 3 &&
          (currentQuestion?.HIST_ANSWER_RESPONSE_03
            ? currentQuestion?.HIST_ANSWER_RESPONSE_03
            : "")}
      </div>
    );
  }
};
TabsContent.propTypes = {
  selectedTab: PropTypes.any,
  SaveAnswerFromRichTextEditor: PropTypes.func,
  bottomTabs: PropTypes.bool,
  EvidenceList: PropTypes.array,
  DataAssetsList: PropTypes.array,
  rfiNarrative: PropTypes.array,
  currentQuestion: PropTypes.object,
  isQuestionEditable: PropTypes.bool,
  currentRfi: PropTypes.object,
  showAlert: PropTypes.func,
};
export default TabsContent;
