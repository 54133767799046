import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, makeStyles } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CustomRichTextEditor from "./CustomRichTextEditor";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import individualQuestionAction from "../actions";
import QuestionServices from "../services";
import { alertActions } from "_actions";
import langConstant from "_lang";
import Select from "react-select";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import sessionService from "_services/session.service";
import uploadDataAction from "containers/cyber-assessment/UploadDataForm/actions";
import PolicyMappingTable from "./PolicyMappingTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  width100: {
    width: "100%",
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  bottomGridContainer: {
    marginTop: "20px",
    height: "calc(100vh - 230px)",
    maxHeight: "calc(100vh - 230px)",
  },
  btnWidth: {
    width: "170px",
  },
}));

const ViewQuestionTab = ({
  questionTextHtml,
  questionGuidanceHtml,
  questionObjectiveHtml,
  questionArchitectureHtml,
  guidanceTypes,
  objectiveType,
  guidanceType,
  architectureType,
  questionreference,
  questionID,
  setQuestionGuidance,
  setObjectiveType,
  setGuidanceType,
  setArchitectureType,
  setQuestionText,
  regulationID,
}) => {
  const questionList = useSelector(
    (state) => state.manageQuestions.questionList
  );

  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const classes = useStyles();
  const dispatch = useDispatch();

  const InitialData1 = {
    QUESTION_REFERENCE_NO: "",
    QUESTION_NAME: "",
    DUMMY_QUESTION_NAME: "",
  };

  const InitialData2 = {
    OBJECTIVE_TYPE: "",
    ARCHITECTURE_TYPE: "",
    GUIDANCE_TYPE: "",
    QUESTION_OBJECTIVES: "",
    DUMMY_QUESTION_OBJECTIVES: "",
    QUESTION_GUIDANCE: "",
    DUMMY_QUESTION_GUIDANCE: "",
    QUESTION_ARCHITECTURE: "",
    DUMMY_QUESTION_ARCHITECTURE: "",
  };

  const [formInitialData1, setFormInitialData1] = useState(InitialData1);
  const [formInitialData2, setFormInitialData2] = useState(InitialData2);
  const [isQuestionNameEdit, setIsQuestionNameEdit] = useState(false);
  const [isQuestionObjectivesEdit, setIsQuestionObjectivesEdit] =
    useState(false);
  const [isQuestionGuidanceEdit, setIsQuestionGuidanceEdit] = useState(false);
  const [isQuestionArchitectureEdit, setIsQuestionArchitectureEdit] =
    useState(false);

  const [regulatorControlGroup, setRegulatorControlGroup] = useState("");
  const [questionClassification, setQuestionClassification] = useState({
    value: "",
    label: "",
  });
  const [selectedGuidancePanel, setSelectedGuidancePanel] = useState(
    objectiveType || guidanceType || architectureType
  );

  const [selectedObjectiveType, setSelectedObjectiveType] = useState({
    value: "",
    label: objectiveType ? objectiveType : "",
  });
  const [selectedGuidanceType, setSelectedGuidanceType] = useState({
    value: "",
    label: guidanceType ? guidanceType : "",
  });
  const [selectedArchitectureType, setSelectedArchitectureType] = useState({
    value: "",
    label: architectureType ? architectureType : "",
  });

  const [questionClassificationOptions, setQuestionClassificationOptions] =
    useState([]);
  const [guidanceTypeOptions, setGuidanceTypeOptions] = useState([]);
  const [isGroupAndQuestionEdit, setIsGroupAndQuestionEdit] = useState({
    questionClassification: true,
    controlGroup: true,
  });
  const [value, setValue] = React.useState(
    objectiveType ? 0 : guidanceType ? 1 : architectureType ? 2 : 0
  );

  const handleChange = (event, newValue) => {
    if (newValue == 2) {
      setArchitectureType(langConstant.ARCHITECTURE);
      setSelectedGuidancePanel(langConstant.ARCHITECTURE);
    } else if (newValue == 1) {
      setGuidanceType(langConstant.GUIDANCE);
      setSelectedGuidancePanel(langConstant.GUIDANCE);
    } else {
      setObjectiveType(langConstant.OBJECTIVES);
      setSelectedGuidancePanel(langConstant.OBJECTIVES);
    }
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const response = await QuestionServices.getQuestionClassification(
        customerID
      );
      if (response.status == 200) {
        const options = [];
        response["#result-set-1"].forEach((element) => {
          options.push({
            value: element.QUESTION_CLASSIFICATION_ID,
            label: element.QUESTION_CLASSIFICATION_NAME,
          });
        });
        setQuestionClassificationOptions(options);
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    })();
    const filteredQuestionList = questionList.filter(
      (item) =>
        item.REGULATION_ID == regulationID && item.QUESTION_ID == questionID
    );
    if (filteredQuestionList.length > 0) {
      if (filteredQuestionList[0].REGULATOR_CONTROL_GROUP)
        setRegulatorControlGroup(
          filteredQuestionList[0].REGULATOR_CONTROL_GROUP
        );
      if (
        filteredQuestionList[0].QUESTION_CLASSIFICATION_ID &&
        filteredQuestionList[0].QUESTION_CLASSIFICATION_NAME
      ) {
        setQuestionClassification({
          value: filteredQuestionList[0].QUESTION_CLASSIFICATION_ID,
          label: filteredQuestionList[0].QUESTION_CLASSIFICATION_NAME,
        });
      }
    }
  }, [questionList]);

  useEffect(() => {
    setFormInitialData1({
      QUESTION_REFERENCE_NO: questionreference,
      QUESTION_NAME: questionTextHtml,
      DUMMY_QUESTION_NAME: questionTextHtml,
    });
  }, [questionTextHtml, questionreference]);

  useEffect(() => {
    //to select tab at starting

    setValue(objectiveType ? 0 : guidanceType ? 1 : architectureType ? 2 : 0);

    //to populate dropdown
    const options = [];
    guidanceTypes?.forEach((element) => {
      options.push({
        value: element.ID,
        label: element.GUIDANCE_DESCRIPTION,
      });
    });
    setGuidanceTypeOptions(options);

    setFormInitialData2({
      QUESTION_GUIDANCE: questionGuidanceHtml,
      DUMMY_QUESTION_GUIDANCE: questionGuidanceHtml,
      GUIDANCE_TYPE: guidanceType,
      OBJECTIVE_TYPE: objectiveType,
      ARCHITECTURE_TYPE: architectureType,
      QUESTION_ARCHITECTURE: questionArchitectureHtml,
      DUMMY_QUESTION_ARCHITECTURE: questionArchitectureHtml,
      QUESTION_OBJECTIVES: questionObjectiveHtml,
      DUMMY_QUESTION_OBJECTIVES: questionObjectiveHtml,
    });
  }, [
    questionObjectiveHtml,
    questionGuidanceHtml,
    questionArchitectureHtml,
    guidanceTypes,
  ]);

  const handleQuestionNameCancel = (setFieldValue, data) => {
    setIsQuestionNameEdit(false);
    setFieldValue("DUMMY_QUESTION_NAME", data);
  };

  const handleQuestionObjectivesCancel = (setFieldValue, data) => {
    setIsQuestionObjectivesEdit(false);
    setFieldValue("DUMMY_QUESTION_OBJECTIVES", data);
  };
  const handleQuestionGuidanceCancel = (setFieldValue, data) => {
    setIsQuestionGuidanceEdit(false);
    setFieldValue("DUMMY_QUESTION_GUIDANCE", data);
  };
  const handleQuestionArchitectureCancel = (setFieldValue, data) => {
    setIsQuestionArchitectureEdit(false);
    setFieldValue("DUMMY_QUESTION_ARCHITECTURE", data);
  };

  const SubmitQuestionNameData = async (
    values,
    { resetForm, setFieldValue }
  ) => {
    setFieldValue("QUESTION_NAME", values.DUMMY_QUESTION_NAME);
    setQuestionText(values.DUMMY_QUESTION_NAME);

    let htmlToString = values.DUMMY_QUESTION_NAME;

    htmlToString = htmlToString.replace(/<style([\s\S]*?)<\/style>/gi, "");
    htmlToString = htmlToString.replace(/<script([\s\S]*?)<\/script>/gi, "");
    htmlToString = htmlToString.replace(/<\/div>/gi, "");
    htmlToString = htmlToString.replace(/<\/li>/gi, "");
    htmlToString = htmlToString.replace(/<li>/gi, "• ");
    htmlToString = htmlToString.replace(/\n/gi, " ");
    htmlToString = htmlToString.replace(/\t/gi, "");
    htmlToString = htmlToString.replace(/<\/ul>/gi, "");
    htmlToString = htmlToString.replace(/<\/p>/gi, "");
    htmlToString = htmlToString.replace(/<br\s*[/]?>/gi, "");
    htmlToString = htmlToString.replace(/<[^>]+>/gi, "");

    dispatch(
      individualQuestionAction.updateQuestionText(
        questionID,
        htmlToString,
        values.DUMMY_QUESTION_NAME,
        customerID
      )
    );
    await KafkaTopicUpload();
    setIsQuestionNameEdit(false);
  };

  const KafkaTopicUpload = async () => {
    let tempobj = {
      payload: {
        evidenceLogId: "",
        messageDataTime: moment().format("YYYY-MM-DD hh:mm:ss"),
        messageSource: "",
        questionId: questionID,
        regulationId: regulationID,
        rfiId: "",
        ruleId: "",
        sourceDataId: "",
        sourceSystemId: "",
        submissionId: "",
        uniqueMessageId: "",
        userId: userID,
      },
      topicID: 0,
      topicName: window.REACT_QUESTION_UPDATED_KAFKA_TOPIC,
    };
    await uploadDataAction.kafkatopicUpload(tempobj);
  };

  const SubmitQuestionGuidanceData = (values, { resetForm, setFieldValue }) => {
    setFieldValue("QUESTION_GUIDANCE", values.DUMMY_QUESTION_GUIDANCE);
    setFieldValue("QUESTION_OBJECTIVES", values.DUMMY_QUESTION_OBJECTIVES);
    setFieldValue("QUESTION_ARCHITECTURE", values.DUMMY_QUESTION_ARCHITECTURE);
    setFieldValue("OBJECTIVE_TYPE", values.OBJECTIVE_TYPE);
    setFieldValue("GUIDANCE_TYPE", values.GUIDANCE_TYPE);
    setFieldValue("ARCHITECTURE_TYPE", values.ARCHITECTURE_TYPE);

    let guidance = "";
    let htmlToString = "";
    let guidanceTypeFromPicklist = "";

    if (selectedGuidancePanel == langConstant.ARCHITECTURE) {
      guidance = values.DUMMY_QUESTION_ARCHITECTURE;
      htmlToString = values.DUMMY_QUESTION_ARCHITECTURE;
      guidanceTypeFromPicklist = values.ARCHITECTURE_TYPE;
    } else if (selectedGuidancePanel == langConstant.GUIDANCE) {
      guidance = values.DUMMY_QUESTION_GUIDANCE;
      htmlToString = values.DUMMY_QUESTION_GUIDANCE;
      guidanceTypeFromPicklist = values.GUIDANCE_TYPE;
    } else {
      guidance = values.DUMMY_QUESTION_OBJECTIVES;
      htmlToString = values.DUMMY_QUESTION_OBJECTIVES;
      guidanceTypeFromPicklist = values.OBJECTIVE_TYPE;
    }

    htmlToString = htmlToString.replace(/<style([\s\S]*?)<\/style>/gi, "");
    htmlToString = htmlToString.replace(/<script([\s\S]*?)<\/script>/gi, "");
    htmlToString = htmlToString.replace(/<\/div>/gi, "");
    htmlToString = htmlToString.replace(/<\/li>/gi, "");
    htmlToString = htmlToString.replace(/<li>/gi, "• ");
    htmlToString = htmlToString.replace(/\n/gi, " ");
    htmlToString = htmlToString.replace(/\t/gi, "");
    htmlToString = htmlToString.replace(/<\/ul>/gi, "");
    htmlToString = htmlToString.replace(/<\/p>/gi, "");
    htmlToString = htmlToString.replace(/<br\s*[/]?>/gi, "");
    htmlToString = htmlToString.replace(/<[^>]+>/gi, "");

    dispatch(
      individualQuestionAction.updateQuestionGuidance(
        questionID,
        htmlToString.trim(),
        guidance.trim(),
        guidanceTypeFromPicklist,
        customerID
      )
    );
    setFormInitialData2({
      QUESTION_GUIDANCE: values.DUMMY_QUESTION_GUIDANCE,
      DUMMY_QUESTION_GUIDANCE: values.DUMMY_QUESTION_GUIDANCE,
      GUIDANCE_TYPE: values.GUIDANCE_TYPE,
      OBJECTIVE_TYPE: values.OBJECTIVE_TYPE,
      ARCHITECTURE_TYPE: values.ARCHITECTURE_TYPE,
      QUESTION_ARCHITECTURE: values.DUMMY_QUESTION_ARCHITECTURE,
      DUMMY_QUESTION_ARCHITECTURE: values.DUMMY_QUESTION_ARCHITECTURE,
      QUESTION_OBJECTIVES: values.DUMMY_QUESTION_OBJECTIVES,
      DUMMY_QUESTION_OBJECTIVES: values.DUMMY_QUESTION_OBJECTIVES,
    });

    setIsQuestionGuidanceEdit(false);
    setIsQuestionObjectivesEdit(false);
    setIsQuestionArchitectureEdit(false);
  };

  const resetClassificationAndGroup = (type) => {
    const filteredQuestionList = questionList.filter(
      (item) =>
        item.REGULATION_ID == regulationID && item.QUESTION_ID == questionID
    );
    if (filteredQuestionList.length > 0) {
      if (type === "control_group") {
        setRegulatorControlGroup(
          filteredQuestionList[0].REGULATOR_CONTROL_GROUP
        );
        setIsGroupAndQuestionEdit((prevState) => ({
          ...prevState,
          controlGroup: true,
        }));
      } else {
        if (
          filteredQuestionList[0].QUESTION_CLASSIFICATION_ID &&
          filteredQuestionList[0].QUESTION_CLASSIFICATION_NAME
        ) {
          setQuestionClassification({
            value: filteredQuestionList[0].QUESTION_CLASSIFICATION_ID,
            label: filteredQuestionList[0].QUESTION_CLASSIFICATION_NAME,
          });
        }
        setIsGroupAndQuestionEdit((prevState) => ({
          ...prevState,
          questionClassification: true,
        }));
      }
    }
  };

  const updateControlGroup = () => {
    const payload = {
      regulationId: regulationID,
      questionId: questionID,
      control_group: regulatorControlGroup,
      customerID: customerID,
    };
    dispatch(QuestionServices.updateRegulatorControlGroup(payload));
    setIsGroupAndQuestionEdit((prevState) => ({
      ...prevState,
      controlGroup: true,
    }));
  };

  const updateQuestionClassificationData = () => {
    const payload = {
      regulationId: regulationID,
      questionId: questionID,
      classificationId: questionClassification.value,
      classificationText: questionClassification.label,
      customerID: customerID,
    };
    dispatch(QuestionServices.updateQuestionClassification(payload));
    setIsGroupAndQuestionEdit((prevState) => ({
      ...prevState,
      questionClassification: true,
    }));
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box style={{ padding: "20px 10px 10px 10px", height: "75vh" }}>
      <Grid container={true}>
        <Grid item={true} xs={3} md={12}>
          <Grid
            container={true}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item={true} xs={3} md={2}>
              <Grid style={{ padding: "10px 0px" }}>
                <Typography className={classes.filedTitle}>
                  Add Question Reference No.
                </Typography>
                <Formik
                  initialValues={formInitialData1}
                  enableReinitialize={true}
                  // onSubmit={SubmitQuestionNameData}
                >
                  {({ values, setFieldValue, resetForm, touched, errors }) => (
                    <Form>
                      <Field name="QUESTION_REFERENCE_NO">
                        {({ field: { value } }) => (
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={value}
                            InputProps={{ readOnly: true }}
                          />
                        )}
                      </Field>
                    </Form>
                  )}
                </Formik>
              </Grid>
              <Grid style={{ padding: "10px 0px" }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={classes.filedTitle}>
                    {langConstant.REGULATOR_CONTROL_GROUP}
                  </Typography>
                  {isGroupAndQuestionEdit.controlGroup ? (
                    <EditIcon
                      color="success"
                      sx={{ fontSize: "26px", cursor: "pointer" }}
                      onClick={() =>
                        setIsGroupAndQuestionEdit((prevState) => ({
                          ...prevState,
                          controlGroup: false,
                        }))
                      }
                    />
                  ) : (
                    <>
                      <CheckIcon
                        color="success"
                        sx={{ fontSize: "26px", cursor: "pointer" }}
                        onClick={updateControlGroup}
                      />
                      <CloseIcon
                        color="error"
                        sx={{ fontSize: "26px", cursor: "pointer" }}
                        onClick={() =>
                          resetClassificationAndGroup("control_group")
                        }
                      />
                    </>
                  )}
                </Box>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={regulatorControlGroup}
                  onChange={(e) => setRegulatorControlGroup(e.target.value)}
                  disabled={isGroupAndQuestionEdit.controlGroup}
                />
              </Grid>
              <Grid style={{ padding: "10px 0px" }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={classes.filedTitle}>
                    {langConstant.QUESTION_CLASSIFICATION}
                  </Typography>
                  {isGroupAndQuestionEdit.questionClassification ? (
                    <EditIcon
                      color="success"
                      sx={{ fontSize: "26px", cursor: "pointer" }}
                      onClick={() =>
                        setIsGroupAndQuestionEdit((prevState) => ({
                          ...prevState,
                          questionClassification: false,
                        }))
                      }
                    />
                  ) : (
                    <>
                      <CheckIcon
                        color="success"
                        sx={{ fontSize: "26px", cursor: "pointer" }}
                        onClick={updateQuestionClassificationData}
                      />
                      <CloseIcon
                        color="error"
                        sx={{ fontSize: "26px", cursor: "pointer" }}
                        onClick={() =>
                          resetClassificationAndGroup("question_classification")
                        }
                      />
                    </>
                  )}
                </Box>
                <Select
                  options={questionClassificationOptions}
                  placeholder={langConstant.SELECT_QUESTION_CLASSIFICATION}
                  value={questionClassification}
                  onChange={(role) => setQuestionClassification(role)}
                  isDisabled={isGroupAndQuestionEdit.questionClassification}
                />
              </Grid>
            </Grid>
            <Grid item={true} xs={12} md={9}>
              <Formik
                initialValues={formInitialData2}
                enableReinitialize={true}
                onSubmit={SubmitQuestionGuidanceData}
              >
                {({ values, setFieldValue, resetForm, touched, errors }) => (
                  <Form>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs value={value} onChange={handleChange}>
                        <Tab
                          label={langConstant.OBJECTIVES}
                          {...a11yProps(0)}
                        />
                        <Tab label={langConstant.GUIDANCE} {...a11yProps(1)} />
                        <Tab
                          label={langConstant.ARCHITECTURE}
                          {...a11yProps(2)}
                        />
                      </Tabs>

                      {/* Tab Panels */}
                      <TabPanel value={value} index={0}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            md={4}
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {isQuestionObjectivesEdit ? (
                              <Select
                                options={guidanceTypeOptions}
                                label={langConstant.SELECT_QUESTION_TYPE}
                                fieldName={"OBJECTIVE_TYPE"}
                                id="OBJECTIVE_TYPE"
                                menuPortalTarget={document.body}
                                value={guidanceTypeOptions.find(
                                  (type) => type.label == values.OBJECTIVE_TYPE
                                )}
                                onChange={(type) => {
                                  setSelectedObjectiveType(type);
                                  setFieldValue("OBJECTIVE_TYPE", type.label);
                                  setFormInitialData2((prevData) => ({
                                    ...prevData,
                                    OBJECTIVE_TYPE: type.label,
                                  }));
                                }}
                              />
                            ) : (
                              <Field name="OBJECTIVE_TYPE">
                                {({ field: { value } }) => (
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={value}
                                    InputProps={{ readOnly: true }}
                                  />
                                )}
                              </Field>
                            )}
                          </Grid>

                          {!isQuestionObjectivesEdit && (
                            <EditIcon
                              color="success"
                              sx={{ fontSize: "26px", cursor: "pointer" }}
                              onClick={(e) => {
                                setIsQuestionObjectivesEdit(true);
                              }}
                            />
                          )}
                        </Box>
                        <Field name="QUESTION_OBJECTIVES">
                          {({ field: { value } }) =>
                            isQuestionObjectivesEdit ? (
                              <Box
                                style={{
                                  height: "15vh",
                                  maxHeight: "15vh",
                                }}
                              >
                                <CustomRichTextEditor
                                  onChangeHandler={(e) => {
                                    setFieldValue(
                                      "DUMMY_QUESTION_OBJECTIVES",
                                      e
                                    );
                                  }}
                                  data={value}
                                  onCancelClick={() => {
                                    handleQuestionObjectivesCancel(
                                      setFieldValue,
                                      values.QUESTION_OBJECTIVES
                                    );
                                  }}
                                  identifier={3}
                                />
                              </Box>
                            ) : (
                              <Card variant="outlined">
                                <CardContent
                                  style={{
                                    padding: "0px",
                                    height: "45%",
                                    minHeight: "15vh",
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: values.DUMMY_QUESTION_OBJECTIVES,
                                      //  __html: value,
                                    }}
                                  />
                                </CardContent>
                              </Card>
                            )
                          }
                        </Field>
                      </TabPanel>

                      <TabPanel value={value} index={1}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            md={4}
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {isQuestionGuidanceEdit ? (
                              <Select
                                options={guidanceTypeOptions}
                                label={langConstant.SELECT_QUESTION_TYPE}
                                fieldName={"GUIDANCE_TYPE"}
                                id="GUIDANCE_TYPE"
                                menuPortalTarget={document.body}
                                value={guidanceTypeOptions.find(
                                  (type) => type.label == values.GUIDANCE_TYPE
                                )}
                                onChange={(type) => {
                                  setSelectedGuidanceType(type);
                                  setFieldValue("GUIDANCE_TYPE", type.label);
                                  setFormInitialData2((prevData) => ({
                                    ...prevData,
                                    GUIDANCE_TYPE: type.label,
                                  }));
                                }}
                              />
                            ) : (
                              <Field name="GUIDANCE_TYPE">
                                {({ field: { value } }) => (
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={value}
                                    InputProps={{ readOnly: true }}
                                  />
                                )}
                              </Field>
                            )}
                          </Grid>

                          {!isQuestionGuidanceEdit && (
                            <EditIcon
                              color="success"
                              sx={{ fontSize: "26px", cursor: "pointer" }}
                              onClick={(e) => {
                                setIsQuestionGuidanceEdit(true);
                              }}
                            />
                          )}
                        </Box>
                        <Field name="QUESTION_GUIDANCE">
                          {({ field: { value } }) =>
                            isQuestionGuidanceEdit ? (
                              <Box
                                style={{
                                  height: "30vh",
                                  maxHeight: "30vh",
                                }}
                              >
                                <CustomRichTextEditor
                                  onChangeHandler={(e) =>
                                    setFieldValue("DUMMY_QUESTION_GUIDANCE", e)
                                  }
                                  data={value}
                                  onCancelClick={() => {
                                    handleQuestionGuidanceCancel(
                                      setFieldValue,
                                      values.QUESTION_GUIDANCE
                                    );
                                  }}
                                  identifier={2}
                                />
                              </Box>
                            ) : (
                              <Card variant="outlined">
                                <CardContent
                                  style={{
                                    padding: "0px",
                                    height: "45%",
                                    minHeight: "15vh",
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: values.DUMMY_QUESTION_GUIDANCE,
                                      //  __html: value,
                                    }}
                                  />
                                </CardContent>
                              </Card>
                            )
                          }
                        </Field>
                      </TabPanel>

                      <TabPanel value={value} index={2}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            md={4}
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {isQuestionArchitectureEdit ? (
                              <Select
                                options={guidanceTypeOptions}
                                label={langConstant.SELECT_QUESTION_TYPE}
                                fieldName={"ARCHITECTURE_TYPE"}
                                id="ARCHITECTURE_TYPE"
                                menuPortalTarget={document.body}
                                value={guidanceTypeOptions.find(
                                  (type) =>
                                    type.label == values.ARCHITECTURE_TYPE
                                )}
                                onChange={(type) => {
                                  setSelectedArchitectureType(type);
                                  setFieldValue(
                                    "ARCHITECTURE_TYPE",
                                    type.label
                                  );
                                  setFormInitialData2((prevData) => ({
                                    ...prevData,
                                    ARCHITECTURE_TYPE: type.label,
                                  }));
                                }}
                              />
                            ) : (
                              <Field name="ARCHITECTURE_TYPE">
                                {({ field: { value } }) => (
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={value}
                                    InputProps={{ readOnly: true }}
                                  />
                                )}
                              </Field>
                            )}
                          </Grid>

                          {!isQuestionArchitectureEdit && (
                            <EditIcon
                              color="success"
                              sx={{ fontSize: "26px", cursor: "pointer" }}
                              onClick={(e) => {
                                setIsQuestionArchitectureEdit(true);
                              }}
                            />
                          )}
                        </Box>
                        <Field name="QUESTION_ARCHITECTURE">
                          {({ field: { value } }) =>
                            isQuestionArchitectureEdit ? (
                              <Box
                                style={{
                                  height: "30vh",
                                  maxHeight: "30vh",
                                }}
                              >
                                <CustomRichTextEditor
                                  onChangeHandler={(e) =>
                                    setFieldValue(
                                      "DUMMY_QUESTION_ARCHITECTURE",
                                      e
                                    )
                                  }
                                  data={value}
                                  onCancelClick={() => {
                                    handleQuestionArchitectureCancel(
                                      setFieldValue,
                                      values.QUESTION_ARCHITECTURE
                                    );
                                  }}
                                  identifier={4}
                                />
                              </Box>
                            ) : (
                              <Card variant="outlined">
                                <CardContent
                                  style={{
                                    padding: "0px",
                                    height: "45%",
                                    minHeight: "15vh",
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        values.DUMMY_QUESTION_ARCHITECTURE,
                                      //  __html: value,
                                    }}
                                  />
                                </CardContent>
                              </Card>
                            )
                          }
                        </Field>
                      </TabPanel>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>

            <Grid
              container={true}
              style={{ paddingTop: "20px", marginBottom: "20px" }}
            >
              <Grid item={true} xs={12} md={3} style={{ paddingRight: "30px" }}>
                <PolicyMappingTable
                  regulationID={regulationID}
                  userID={userID}
                  customerID={customerID}
                  questionID={questionID}
                />
              </Grid>
              <Grid item={true} xs={3} md={9}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={classes.filedTitle}>
                    Question Name
                  </Typography>

                  {!isQuestionNameEdit && (
                    <EditIcon
                      color="success"
                      sx={{ fontSize: "26px", cursor: "pointer" }}
                      onClick={(e) => {
                        setIsQuestionNameEdit(true);
                      }}
                    />
                  )}
                </Box>
                <Formik
                  initialValues={formInitialData1}
                  enableReinitialize={true}
                  onSubmit={SubmitQuestionNameData}
                >
                  {({ values, setFieldValue, resetForm, touched, errors }) => (
                    <Form>
                      <Field name="QUESTION_NAME">
                        {({ field: { value } }) =>
                          isQuestionNameEdit ? (
                            <Box
                              style={{
                                height: "30vh",
                                maxHeight: "30vh",
                              }}
                            >
                              <CustomRichTextEditor
                                onChangeHandler={(e) =>
                                  setFieldValue("DUMMY_QUESTION_NAME", e)
                                }
                                data={value}
                                onCancelClick={() => {
                                  handleQuestionNameCancel(
                                    setFieldValue,
                                    values.QUESTION_NAME
                                  );
                                }}
                                identifier={1}
                              />
                            </Box>
                          ) : (
                            <Card variant="outlined">
                              <CardContent
                                style={{
                                  padding: "0px",
                                  height: "90%",
                                  minHeight: "30vh",
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: value,
                                  }}
                                />
                              </CardContent>
                            </Card>
                          )
                        }
                      </Field>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

ViewQuestionTab.propTypes = {
  questionTextHtml: PropTypes.string,
  questionGuidanceHtml: PropTypes.string,
  questionObjectiveHtml: PropTypes.string,
  questionArchitectureHtml: PropTypes.string,
  guidanceTypes: PropTypes.object,
  objectiveType: PropTypes.string,
  guidanceType: PropTypes.string,
  architectureType: PropTypes.string,
  questionreference: PropTypes.string,
  questionID: PropTypes.string,
  setQuestionGuidance: PropTypes.func,
  setObjectiveType: PropTypes.func,
  setGuidanceType: PropTypes.func,
  setArchitectureType: PropTypes.func,
  setQuestionText: PropTypes.func,
  regulationID: PropTypes.string,
};

export default ViewQuestionTab;
