/* eslint-disable react/prop-types */
import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Text,
  LabelList,
} from "recharts";
import propTypes from "prop-types";
import { getShortName } from "../../utils";

const CustomYAxisLabel = ({ x, y, value }) => (
  <MuiTooltip title={value}>
    <Text x={x} y={y} fontSize={12} textAnchor="end">
      {getShortName(value)}
    </Text>
  </MuiTooltip>
);

const EvidenceCount = ({ data }) => {
  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        layout="vertical"
        margin={{
          top: 10,
          right: 20,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />

        <YAxis
          dataKey="EVIDENCE_SHORTNAME"
          type="category"
          // tick={<CustomYAxisLabel />}
          tickFormatter={(value) => getShortName(value)}
        />

        <Tooltip />
        <Legend />

        <Bar barSize={10} dataKey="UPLOADED" stackId="a" fill="#046DB8" />
        <Bar dataKey="APPROVED" stackId="a" fill="#59ad59" />
        <Bar dataKey="EXPIRED" stackId="a" fill="#ff7777" />
      </BarChart>
    </ResponsiveContainer>
  );
};

EvidenceCount.propTypes = {
  data: propTypes.array,
};

export default EvidenceCount;
