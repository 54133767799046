/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import { ComplianceConstants } from "./constants";
import { COMPONENT, ROW, COLUMN } from "./components/constants";
import { generateRandomId } from "./components/helpers";

const INITIAL_STATE = {
  selectedRegulation: null,
  selectedPanels: [
    "Panel1",
    "Panel2",
    "Panel3",
    "Panel4",
    "Panel5",
    "Panel6",
    "Panel7",
    "Panel8",
    "Panel9",
  ],
  layoutData: [
    {
      type: ROW,
      id: "row0",
      children: [
        {
          type: COLUMN,
          id: "column0",
          children: [
            {
              type: COMPONENT,
              id: "Panel1",
              isPinned: false,
            },
          ],
        },
        {
          type: COLUMN,
          id: "column1",
          children: [
            {
              type: COMPONENT,
              id: "Panel2",
              isPinned: false,
            },
            {
              type: COMPONENT,
              id: "Panel3",
              isPinned: false,
            },
          ],
        },
        {
          type: COLUMN,
          id: "column2",
          children: [
            {
              type: COMPONENT,
              id: "Panel4",
              isPinned: false,
            },
            {
              type: COMPONENT,
              id: "Panel5",
              isPinned: false,
            },
          ],
        },
        {
          type: COLUMN,
          id: "column3",
          children: [
            {
              type: COMPONENT,
              id: "Panel6",
              isPinned: false,
            },
            {
              type: COMPONENT,
              id: "Panel7",
              isPinned: false,
            },
          ],
        },
        {
          type: COLUMN,
          id: "column4",
          children: [
            {
              type: COMPONENT,
              id: "Panel8",
              isPinned: false,
            },
            {
              type: COMPONENT,
              id: "Panel9",
              isPinned: false,
            },
          ],
        },
      ],
    },
  ],
  components: {
    Panel1: { id: "Panel1", type: "input", content: "Panel1" },
    Panel2: { id: "Panel2", type: "image", content: "Panel2" },
    Panel3: { id: "Panel3", type: "email", content: "Panel3" },
    Panel4: { id: "Panel4", type: "name", content: "Panel4" },
    Panel5: { id: "Panel5", type: "phone", content: "Panel5" },
    Panel6: { id: "Panel6", type: "image", content: "Panel6" },
    Panel7: { id: "Panel7", type: "email", content: "Panel7" },
    Panel8: { id: "Panel8", type: "name", content: "Panel8" },
    Panel9: { id: "Panel9", type: "phone", content: "Panel9" },
  },
  expandedPanelID: null,
};

const updatePinData = (state, data) => {
  let tempLayoutData = { ...state.layoutData[0] };
  let columnIndex = tempLayoutData.children.findIndex(
    (item) => item.id == data.columnID
  );
  let panelIndex = tempLayoutData.children[columnIndex].children.findIndex(
    (item) => item.id == data.panelID
  );
  tempLayoutData.children[columnIndex].children[panelIndex].isPinned =
    !tempLayoutData.children[columnIndex].children[panelIndex].isPinned;
  return {
    ...state,
    layoutData: [tempLayoutData],
  };
};

const removePanel = (state, data) => {
  let tempLayoutData = { ...state.layoutData[0] };
  let columnIndex = tempLayoutData.children.findIndex(
    (item) => item.id == data.columnID
  );
  tempLayoutData.children[columnIndex].children = tempLayoutData.children[
    columnIndex
  ].children.filter((item) => item.id != data.panelID);

  if (tempLayoutData.children[columnIndex].children.length === 0) {
    tempLayoutData.children = tempLayoutData.children.filter(
      (item) => item.id != data.columnID
    );
    return {
      ...state,
      layoutData: [tempLayoutData],
      expandedPanelID: null,
    };
  } else {
    return {
      ...state,
      layoutData: [tempLayoutData],
      expandedPanelID: null,
    };
  }
};

const updateSelectedPanels = (state, data) => {
  let tempSelectedPanels = [...state.selectedPanels];
  let tempLayoutData = { ...state.layoutData[0] };

  if (tempSelectedPanels.includes(data)) {
    tempSelectedPanels = tempSelectedPanels.filter((panel) => panel !== data);
    let isElementFound = false;
    tempLayoutData.children.forEach((childLayout, childIndex) => {
      if (
        childLayout.children.some(
          (childChildren) => childChildren.id == data
        ) &&
        !isElementFound
      ) {
        tempLayoutData.children[childIndex].children = tempLayoutData.children[
          childIndex
        ].children.filter((tChildren) => tChildren.id !== data);
        if (tempLayoutData.children[childIndex].children.length == 0) {
          tempLayoutData.children = tempLayoutData.children.filter(
            (cData) => cData.id !== tempLayoutData.children[childIndex].id
          );
        }
        isElementFound = true;
      }
    });
  } else {
    tempSelectedPanels.push(data);
    if (tempLayoutData.children.length < 5) {
      tempLayoutData.children[tempLayoutData.children.length] = {
        type: COLUMN,
        id: generateRandomId(),
        children: [
          {
            type: COMPONENT,
            id: data,
            isPinned: false,
          },
        ],
      };
    } else {
      let isInserted = false;
      tempLayoutData.children.forEach((childLayout, childIndex) => {
        if (childLayout.children.length < 2 && !isInserted) {
          tempLayoutData.children[childIndex].children.push({
            type: COMPONENT,
            id: data,
            isPinned: false,
          });
          isInserted = true;
        }
      });
    }
  }

  return {
    ...state,
    selectedPanels: [...tempSelectedPanels],
    layoutData: [tempLayoutData],
    expandedPanelID: null,
  };
};

export default function complianceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ComplianceConstants.SET_REGULATION:
      return {
        ...state,
        selectedRegulation: action.payload,
      };
    case ComplianceConstants.UPDATE_COMPONENTS:
      return {
        ...state,
        components: action.payload,
      };
    case ComplianceConstants.UPDATE_LAYOUT:
      return {
        ...state,
        layoutData: action.payload,
      };
    case ComplianceConstants.SET_PIN:
      return updatePinData(state, action.payload);
    case ComplianceConstants.REMOVE_PANEL:
      return removePanel(state, action.payload);
    case ComplianceConstants.SET_EXPANDED_PANELID:
      return {
        ...state,
        expandedPanelID: action.payload,
      };
    case ComplianceConstants.UPDATE_SELECTED_PANELS:
      return updateSelectedPanels(state, action.payload);
    default:
      return state;
  }
}
