import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { headerType, reHeaderType, stepper3Data } from "../utils";
import ExtractionServices from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import AppPagination from "_components/AppPagination";
import { Grid, IconButton, Tooltip, Button } from "@material-ui/core";
import FindAndReplace from "./findAndReplace";
import DocumentDrawer from "./documentDrawer";
import useStyles from "../../styles";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import langConstant from "_lang";
import PropTypes from "prop-types";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import HelperTextComponent from "_components/HelpText";
import { thirdStepHelperText } from "../utils";
import { getThemeDetails } from "containers/LoginPage/reducers";
import sessionService from "_services/session.service";

const ExtractionEditor = ({ stepperClose }) => {
  const dispatch = useDispatch();
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [initialExtractionData, setInitialExtractionData] = useState({});
  const [pageWiseExtractionData, setPageWiseExtractionData] = useState({});
  const [page, setPage] = useState(0);

  const [documentView, setDocumentView] = useState(false);
  const [findAndReplacePopUp, setFindAndReplacePopUp] = useState(false);

  const [contentState, setContentState] = useState({
    blocks: [],
    entityMap: {},
  });
  const [finalContentState, setFinalContentState] = useState({
    blocks: [],
    entityMap: {},
  });

  const documentInfo = useSelector(
    (state) => state.uploadPolicyReducer.documentInfo
  );
  const documentName = useSelector(
    (state) => state.uploadPolicyReducer.step1.document?.name
  );
  const startPage = parseInt(
    useSelector(
      (state) => state.uploadPolicyReducer.step1.pages_extraction_start
    )
  );
  const endPage = parseInt(
    useSelector((state) => state.uploadPolicyReducer.step1.pages_extraction_end)
  );
  const pageCount = endPage - startPage + 1;

  const handleClose = () => setFindAndReplacePopUp(false);

  const setContentStateData = (data, currentPage) => {
    const pageData = [];
    if (Object.keys(data).includes(currentPage.toString())) {
      data[currentPage].forEach((item, index) => {
        pageData.push({
          data: {},
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
          key: "header_" + index,
          text: item.NAME,
          type: headerType(item.LEVEL),
          ROW_ITEM_NO: item.ROW_ITEM_NO,
        });
        const itemSentences = item.SENTENCESDATA;
        if (itemSentences.length > 0) {
          itemSentences.forEach((sentence, sentenceIndex) => {
            pageData.push({
              data: {},
              depth: 0,
              entityRanges: [],
              inlineStyleRanges: [],
              key: "header_" + index + "_sub_" + sentenceIndex,
              text: sentence,
              type: "unstyled",
              ROW_ITEM_NO: item.ROW_ITEM_NO,
            });
          });
        }
      });
    }
    return pageData;
  };

  useEffect(() => {
    if (Object.keys(pageWiseExtractionData).length == 0) {
      (async () => {
        const response =
          await ExtractionServices.getPolicyExtractionDetailsByPage({
            policyId: documentInfo.policyId,
            pageNo: null,
          });
        if (response.status == 200) {
          if (response["#result-set-1"].length > 0) {
            const pageWiseData = {};
            response["#result-set-1"].forEach((element, index) => {
              if (
                Object.keys(pageWiseData).includes(
                  element["PAGE_NUMBER"].toString()
                )
              ) {
                pageWiseData[element["PAGE_NUMBER"]] = [
                  ...pageWiseData[element["PAGE_NUMBER"]],
                  {
                    ...element,
                    key: "key_" + element["PAGE_NUMBER"] + "_" + index,
                    SENTENCESDATA: element["SENTENCES"]
                      ? JSON.parse(element["SENTENCES"])
                      : [],
                  },
                ];
              } else {
                pageWiseData[element["PAGE_NUMBER"]] = [
                  {
                    ...element,
                    key: "key_" + element["PAGE_NUMBER"] + "_" + index,
                    SENTENCESDATA: element["SENTENCES"]
                      ? JSON.parse(element["SENTENCES"])
                      : [],
                  },
                ];
              }
            });
            const data = setContentStateData(pageWiseData, startPage);
            if (data.length > 0) {
              setContentState({ blocks: [...data], entityMap: {} });
              setFinalContentState({ blocks: [...data], entityMap: {} });
            } else {
              setContentState({
                blocks: [
                  {
                    data: {},
                    depth: 0,
                    entityRanges: [],
                    inlineStyleRanges: [],
                    key: "0_" + startPage,
                    text: "",
                    type: "unstyled",
                    ROW_ITEM_NO: "",
                  },
                ],
                entityMap: {},
              });
              setFinalContentState({
                blocks: [
                  {
                    data: {},
                    depth: 0,
                    entityRanges: [],
                    inlineStyleRanges: [],
                    key: "0_" + startPage,
                    text: "",
                    type: "unstyled",
                    ROW_ITEM_NO: "",
                  },
                ],
                entityMap: {},
              });
            }
            setPageWiseExtractionData(pageWiseData);
            setInitialExtractionData(pageWiseData);
          }
        } else {
          dispatch(alertActions.error("Policy Extraction Details SP Failed."));
        }
      })();
    } else {
      const currentPage = page + startPage;
      const data = setContentStateData(pageWiseExtractionData, currentPage);
      if (data.length > 0) {
        setContentState({ blocks: [...data], entityMap: {} });
        setFinalContentState({ blocks: [...data], entityMap: {} });
      } else {
        setContentState({
          blocks: [
            {
              data: {},
              depth: 0,
              entityRanges: [],
              inlineStyleRanges: [],
              key: "f04a" + currentPage,
              text: "",
              type: "unstyled",
              ROW_ITEM_NO: "",
            },
          ],
          entityMap: {},
        });
        setFinalContentState({
          blocks: [
            {
              data: {},
              depth: 0,
              entityRanges: [],
              inlineStyleRanges: [],
              key: "0_" + startPage,
              text: "",
              type: "unstyled",
              ROW_ITEM_NO: "",
            },
          ],
          entityMap: {},
        });
      }
    }
  }, [page]);

  const onContentStateChange = (contentState) => {
    setFinalContentState(contentState);
  };

  const updateHtmlEditorData = () => {
    const clonedPageWiseExtractionData = JSON.parse(
      JSON.stringify(pageWiseExtractionData)
    );
    const tempClonedPageWiseExtractionData = [];
    finalContentState.blocks.forEach((block) => {
      const blockType = reHeaderType(block.type);
      if (tempClonedPageWiseExtractionData.length > 0) {
        if (blockType) {
          const tempBlock = {};
          tempBlock["PAGE_NUMBER"] = page + startPage;
          tempBlock["LEVEL"] = blockType;
          tempBlock["NAME"] = block.text;
          tempBlock["ITEM_BODY_CONTENT"] = "";
          tempBlock["ROW_ITEM_NO"] = block?.ROW_ITEM_NO
            ? block.ROW_ITEM_NO
            : "";
          tempBlock["SENTENCESDATA"] = [];
          tempClonedPageWiseExtractionData.push(tempBlock);
        } else {
          const updatedExtraction = tempClonedPageWiseExtractionData.pop();
          updatedExtraction["ITEM_BODY_CONTENT"] =
            updatedExtraction["ITEM_BODY_CONTENT"] + " " + block.text;
          updatedExtraction["SENTENCESDATA"] = [
            ...updatedExtraction["SENTENCESDATA"],
            block.text,
          ];
          tempClonedPageWiseExtractionData.push(updatedExtraction);
        }
      } else {
        const tempBlock = {};
        tempBlock["LEVEL"] = blockType ? blockType : "H";
        tempBlock["NAME"] = blockType ? block.text : "No Heading";
        tempBlock["ITEM_BODY_CONTENT"] = blockType ? "" : block.text;
        tempBlock["ROW_ITEM_NO"] = block?.ROW_ITEM_NO ? block.ROW_ITEM_NO : "";
        tempBlock["PAGE_NUMBER"] = page + startPage;
        tempBlock["SENTENCESDATA"] = blockType ? [] : [block.text];
        tempClonedPageWiseExtractionData.push(tempBlock);
      }
    });
    clonedPageWiseExtractionData[page + startPage] =
      tempClonedPageWiseExtractionData;
    setPageWiseExtractionData(clonedPageWiseExtractionData);
    return clonedPageWiseExtractionData;
  };

  const handleChangePage = (event) => {
    updateHtmlEditorData();
    setPage(event);
  };

  const submitEditorData = async () => {
    const updatedHtmlEditorData = updateHtmlEditorData();
    const payload = {
      policyId: documentInfo.policyId,
      data: updatedHtmlEditorData,
      policyName: documentName,
      userID: userID,
      customerID: customerID,
      storageType: docStorageType.toLowerCase(),
      storePreProcessingIP: docStorageIP,
      storePreProcessingURL: docStorageURL,
    };
    dispatch(loadingActions.start());
    const saveExtraction = await ExtractionServices.saveExtraction(payload);
    dispatch(loadingActions.end());
    if (saveExtraction.status) {
      if (saveExtraction.status == 200) {
        stepperClose();
        dispatch(alertActions.success("extraction done"));
      } else {
        if (saveExtraction.message) {
          dispatch(alertActions.error(saveExtraction.message));
        }
      }
    } else {
      if (saveExtraction.data && saveExtraction.data.message) {
        dispatch(
          alertActions.error(
            saveExtraction.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  const resetData = () => {
    setPageWiseExtractionData(initialExtractionData);
    const data = setContentStateData(initialExtractionData, page + startPage);
    if (data.length > 0) {
      setContentState({ blocks: [...data], entityMap: {} });
      setFinalContentState({ blocks: [...data], entityMap: {} });
    } else {
      setContentState({
        blocks: [
          {
            data: {},
            depth: 0,
            entityRanges: [],
            inlineStyleRanges: [],
            key: "0_" + startPage,
            text: "",
            type: "unstyled",
            ROW_ITEM_NO: "",
          },
        ],
        entityMap: {},
      });
      setFinalContentState({
        blocks: [
          {
            data: {},
            depth: 0,
            entityRanges: [],
            inlineStyleRanges: [],
            key: "0_" + startPage,
            text: "",
            type: "unstyled",
            ROW_ITEM_NO: "",
          },
        ],
        entityMap: {},
      });
    }
  };

  return (
    <Grid className={classes.headContent}>
      <Button
        className={classes.docScannerBtn}
        onClick={() => setDocumentView(!documentView)}
      >
        <DocumentScannerIcon />
      </Button>
      <Grid container>
        <Grid
          item
          md={6}
          xs={6}
          container
          justify="flex-start"
          alignItems="center"
        >
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => submitEditorData()}
          >
            {langConstant.SAVE}
          </Button>
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={stepperClose}
          >
            {langConstant.CANCEL}
          </Button>
          <HelperTextComponent
            id={thirdStepHelperText["save_cancel"]["id"]}
            textData={thirdStepHelperText["save_cancel"]["text"]}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          container
          justify="flex-end"
          className={classes.paginationWrapper}
        >
          <IconButton className={classes.rotateBTN} onClick={() => resetData()}>
            <Tooltip title={langConstant.RESET}>
              <RotateLeftIcon
                style={{
                  backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                  color: "white",
                }}
              />
            </Tooltip>
          </IconButton>
          <IconButton
            className={classes.findReplaceBTN}
            onClick={() => setFindAndReplacePopUp(true)}
          >
            <Tooltip title={langConstant.FIND_AND_REPLACE}>
              <FindReplaceIcon
                style={{
                  backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                  color: "white",
                }}
              />
            </Tooltip>
          </IconButton>
          <HelperTextComponent
            id={thirdStepHelperText["find_reset"]["id"]}
            textData={thirdStepHelperText["find_reset"]["text"]}
          />
          <AppPagination
            colSpan={3}
            count={pageCount}
            page={page}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {findAndReplacePopUp && (
        <FindAndReplace
          show={findAndReplacePopUp}
          finalContentState={finalContentState}
          setContentState={setContentState}
          pageWiseExtractionData={pageWiseExtractionData}
          setPageWiseExtractionData={setPageWiseExtractionData}
          setFinalContentState={setFinalContentState}
          handleClose={handleClose}
        />
      )}
      <DocumentDrawer
        show={documentView}
        setShow={setDocumentView}
        documentInfo={documentInfo}
      />
      <Grid>
        <Editor
          editorClassName={classes.contentEditor}
          toolbarClassName={classes.contentToolBar}
          contentState={contentState}
          toolbar={{
            options: ["blockType"],
            blockType: { options: ["Normal", "H1", "H2", "H3", "H4", "H5"] },
          }}
          onContentStateChange={onContentStateChange}
        />
      </Grid>
    </Grid>
  );
};

ExtractionEditor.propTypes = {
  stepperClose: PropTypes.func,
};

export default ExtractionEditor;
