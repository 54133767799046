import React from "react";
import propTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import langConstant from "_lang";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import useStyles from "../styles";

const InformationStatusGraph = ({ block, rfiStatus }) => {
  const classes = useStyles();
  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );

  const colors = ["#f00", "#00a83e", "#ffc000", "#3365be"];

  return (
    <Grid
      style={{
        backgroundColor: "#fff",
        borderRadius: "4px",
        height: "100%",
      }}
    >
      <Typography className={classes.chartLabel}>
        {langConstant.REQUEST_FOR_INFORMATION_STATUS}
      </Typography>
      <ResponsiveContainer width="99%" height="99%" aspect={2}>
        <BarChart
          data={rfiStatus}
          layout="vertical"
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            style={{
              fontSize: expandedPanelID == block.id ? "12px" : "10px",
              fontWeight: "bold",
            }}
          />
          <YAxis
            dataKey="RFI_LABEL"
            type="category"
            style={{
              fontSize: expandedPanelID == block.id ? "12px" : "10px",
              fontWeight: "bold",
            }}
          />
          <Tooltip />
          <Legend
            payload={rfiStatus.map((item, index) => ({
              id: item.RFI_STATUS,
              type: "square",
              value: `${item.RFI_LABEL}`,
              color: colors[index],
            }))}
          />
          <Bar
            barSize={20}
            dataKey={"RFI_COUNT"}
            opacity={0.8}
            label={{ position: "right" }}
          >
            {rfiStatus.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
};

InformationStatusGraph.propTypes = {
  block: propTypes.object,
  rfiStatus: propTypes.object,
};

export default InformationStatusGraph;
