import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import propTypes from "prop-types";
import { getRandomHexColor } from "../../utils";

const RfiGraph = ({ data }) => {
  const groupedData = data?.reduce((acc, item) => {
    const existingGroup = acc?.find(
      (group) => group?.status === item?.RFI_STATUS
    );

    if (existingGroup) {
      existingGroup[item?.RECIPIENT_NAME] =
        (existingGroup[item?.RECIPIENT_NAME] || 0) + item?.RFI_COUNT;
    } else {
      const newGroup = {
        status: item?.RFI_STATUS,
        [item?.RECIPIENT_NAME]: item?.RFI_COUNT,
      };
      acc.push(newGroup);
    }

    return acc;
  }, []);

  const stackKeys = Array.from(
    new Set(data?.map((item) => item?.RECIPIENT_NAME))
  );

  // console.log({ stackKeys, groupedData, rawData: data });

  return (
    <ResponsiveContainer>
      <BarChart
        data={groupedData}
        layout="vertical"
        margin={{
          top: 10,
          right: 20,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis
          tickFormatter={(value) => value?.replaceAll("_", " ")}
          dataKey="status"
          type="category"
        />
        <Tooltip />
        <Legend />
        {stackKeys?.map((key, index) => (
          <Bar
            barSize={20}
            key={index}
            dataKey={key}
            stackId="a"
            fill={getRandomHexColor()}
            opacity={0.8}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

RfiGraph.propTypes = {
  data: propTypes.array,
};

export default RfiGraph;
