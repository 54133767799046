import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";
import { Close, Save } from "@mui/icons-material";
import { commonDialogStyles } from "_components/ActionList/styles";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import sessionService from "_services/session.service";
import { GetTeamDetails, UpdateAction } from "../../services";
import { OptionLabelTemplate } from "_components/ActionList/OptionLabelTemplate";

export const AssignListDialog = ({ modal, action, submitCallback }) => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state?.auditActions?.userList);
  const userID = sessionService.getUserId();
  const dialogStyles = commonDialogStyles();
  const assignedTo = userList?.find(
    (user) => user?.USER_ID == action?.ASSIGNED_TO
  );
  const defaultUser = assignedTo
    ? {
        value: assignedTo?.USER_ID,
        label: (
          <OptionLabelTemplate
            user={assignedTo}
            isCurrentUser={assignedTo?.USER_ID == userID}
          />
        ),
        payload: assignedTo,
      }
    : null;
  const [selectedUser, setSelectedUser] = useState(defaultUser);

  const options = userList?.map((user) => {
    return {
      value: user?.USER_ID,
      label: (
        <OptionLabelTemplate
          user={user}
          isCurrentUser={user?.USER_ID == userID}
        />
      ),
      payload: user,
    };
  });

  const filterUsers = (option, searchText) => {
    let username = `${option?.data?.payload?.FIRST_NAME} ${option?.data?.payload?.LAST_NAME}`;
    if (username?.toLowerCase().includes(searchText?.toLowerCase())) {
      return true;
    }
    return false;
  };

  const onSubmit = () => {
    if (!selectedUser || !action?.ACTIONS_ID) return;
    const update = { i_ASSIGNED_TO: selectedUser?.value };
    dispatch(
      UpdateAction(
        update,
        action?.ACTIONS_ID,
        action?.ACTIONS_LIST_ID,
        submitCallback
      )
    );
    setTimeout(() => {
      modal?.closeModal();
    }, 500);
  };

  useEffect(() => {
    if (!userList?.length) {
      dispatch(GetTeamDetails(userID));
    }
    setSelectedUser(defaultUser);
  }, [userList]);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography className={dialogStyles.title}>
        <Typography style={{ flex: 1 }} variant="h6" align="center">
          ASSIGN ACTION LIST
        </Typography>
        <IconButton
          style={{ position: "absolute", right: 4 }}
          aria-label="close"
          onClick={modal?.closeModal}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ flex: 1 }} variant="body1" align="center">
          Assign the Action Item to
        </Typography>
        <Box marginY="1rem">
          <Select
            placeholder="Select"
            options={options}
            filterOption={filterUsers}
            isLoading={!options}
            value={selectedUser}
            onChange={(e) => {
              setSelectedUser(e);
              console.log(e);
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            isDisabled={Boolean(!userList?.length)}
            isSearchable
            isClearable
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ alignSelf: "center", padding: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Save />}
          disabled={!selectedUser || selectedUser?.value == assignedTo?.USER_ID}
          onClick={onSubmit}
        >
          Save
        </Button>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={modal?.closeModal}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssignListDialog.propTypes = {
  modal: propTypes.object,
  action: propTypes.object,
  submitCallback: propTypes.func,
};
