import {
  Dialog,
  Typography,
  Box,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  FormLabel,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import propTypes from "prop-types";
import { commonDialogStyles } from "_components/ActionList/styles";
import { Close, Save } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { UpdateAction } from "../../services";

export const AddClosureDateDialog = ({ modal, action, submitCallback }) => {
  const dispatch = useDispatch();
  const dialogStyles = commonDialogStyles();
  const [date, setDate] = useState("");

  const onSubmit = () => {
    if (!date || !action?.ACTIONS_ID) return;
    const update = { i_CLOSURE_DATE: date };
    dispatch(
      UpdateAction(
        update,
        action?.ACTIONS_ID,
        action?.ACTIONS_LIST_ID,
        submitCallback
      )
    );
    setTimeout(() => {
      modal?.closeModal();
    }, 500);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <DialogTitle disableTypography className={dialogStyles.title}>
        <Typography style={{ flex: 1 }} variant="h6" align="center">
          ASSIGN CLOSURE DATE
        </Typography>
        <IconButton
          style={{ position: "absolute", right: 4 }}
          aria-label="close"
          onClick={modal?.closeModal}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          marginY="1rem"
          flexDirection="column"
          display="flex"
          style={{ gap: 6 }}
        >
          <FormLabel htmlFor="closureDate" variant="body1">
            Assign closure date to the action item
          </FormLabel>
          <TextField
            size="small"
            value={date}
            id="closureDate"
            variant="outlined"
            type="date"
            inputProps={{
              min: new Date().toISOString().split("T")[0],
            }}
            onChange={(e) => setDate(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ alignSelf: "center", padding: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Save />}
          disabled={!date}
          onClick={onSubmit}
        >
          Save
        </Button>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={modal?.closeModal}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddClosureDateDialog.propTypes = {
  modal: propTypes.object,
  action: propTypes.object,
  submitCallback: propTypes.func,
};
