import React from "react";
import * as Yup from "yup";

const CustomerInitialData = {
  CUSTOMER_ID: "",
  // CUSTOMER_TYPE: "E",
  DEFAULT_LANDING_ZONE: "",
  DEFAULT_DOC_URL: "",
  REPORTING_URL: "",
  DEFAULT_PM_URL: "",
  PRE_PROCESSING_URL: "",
  PRE_PROCESSING_IP: "",
  LICENCE_KEY: "",
  LICENCE_EXPIRY_DATE: "",
  LICENCE_TYPE: "",
  LOGIN_TIMEOUT: 0,
  PASSWORD_RESET_EMAIL_ADDRESS: "",
  PRIMARY_LOGO: "",
  POLLING_TIMER: 0,
  WORKFLOW_URL: "",
  COMP_THRESHOLD_DEFAULT: 0,
  REACT_APP_AWS_ACCESS_KEY_ID: "",
  REACT_APP_AWS_SECRET_ACCESS_KEY: "",
  MULTI_PROCESSING: false,
  VIRUS_CHECKER: 0,
  AI_ASSISTANT_AUDITLOG_RETENSION: 0,
  AI_ASSISTANT_AUDITLOG_ACTIVE: false,
  LOGO: "",
  "2FA_ENABLED": 0,
  "2FA_SERVICE_NAME": "",
};

const CustomerValidationSchema = Yup.object().shape({
  // CUSTOMER_TYPE: "E",
  DEFAULT_LANDING_ZONE: Yup.string()
    .trim()
    .required("Default Landing Zone is Required"),
  DEFAULT_DOC_URL: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Doc URL is Required"),
  REPORTING_URL: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Reporting URL is Required"),
  DEFAULT_PM_URL: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Default PM URL is Required"),
  PRE_PROCESSING_URL: Yup.string()
    .trim()
    .required("Pre Processing URL is Required"),
  PRE_PROCESSING_IP: Yup.string()
    .trim()
    .required("Pre Processing IP is Required"),
  LICENCE_KEY: Yup.string().trim().required("Licence Key is Required"),
  LICENCE_EXPIRY_DATE: Yup.string()
    .trim()
    .required("Licence Expiry Date is Required"),
  LOGIN_TIMEOUT: Yup.number()
    .min(0, "Must be more than 0")
    .required("Login TimeOut is Required"),
  PASSWORD_RESET_EMAIL_ADDRESS: Yup.string()
    .email("Invalid Email Address")
    .required("Email Address is Required"),
  PRIMARY_LOGO: Yup.string()
    .trim()
    .nullable()
    .required("Primary Logo is Required"),
  LOGO: Yup.string().trim().nullable().required("Logo is Required"),
  POLLING_TIMER: Yup.number()
    .min(0, "Must be more than 0")
    .required("Polling Timer is Required"),
  WORKFLOW_URL: Yup.string()
    .nullable()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("WorkFlow URL is Required"),
  COMP_THRESHOLD_DEFAULT: Yup.number()
    .min(0, "Must be more than 0")
    .required("Threshold is Required"),
  REACT_APP_AWS_ACCESS_KEY_ID: Yup.string()
    .trim()
    .required("AWS Access Key is Required"),
  REACT_APP_AWS_SECRET_ACCESS_KEY: Yup.string()
    .trim()
    .required("AWS Secret Key is Required"),
  AI_ASSISTANT_AUDITLOG_RETENSION: Yup.number().when(
    "AI_ASSISTANT_AUDITLOG_ACTIVE",
    {
      is: (AI_ASSISTANT_AUDITLOG_ACTIVE) => AI_ASSISTANT_AUDITLOG_ACTIVE,
      then: Yup.number()
        .min(0, "Must be more than 0")
        .required("Audit Log Retension is Required"),
      otherwise: Yup.number(),
    }
  ),
  "2FA_SERVICE_NAME": Yup.string().when("2FA_ENABLED", {
    is: "1",
    then: Yup.string()
      .trim()
      .required("TWO Factor Authentication Type is Required"),
    otherwise: Yup.string().nullable(),
  }),
});

const ThemeInitialData = {
  TOPBAR_BGROUND_COLOUR: "#3c0292",
  MENUBAR_BGROUND_COLOUR: null,
  ACTIONBUTTON_BGROUND_COLOUR: "#3c0292",
  TABLE_HEADER_COLOUR: "#3c0292",
  FONT_TYPEFACE_BOLD: "#3c0292",
  FONT_TYPEFACE_LIGHT: null,
  FONT_COLOUR_LIGHT: null,
  FONT_COLOUR_DARK: "#3c0292",
};

const ThemeValidationSchema = Yup.object().shape({
  TOPBAR_BGROUND_COLOUR: Yup.string()
    .matches(/^#[0-9A-F]{6}$/i)
    .required("TopBar Color Code Is Required"),
  MENUBAR_BGROUND_COLOUR: Yup.string()
    .matches(/^#[0-9A-F]{6}$/i)
    .nullable()
    .required("TopBar Color Code Is Required"),
  ACTIONBUTTON_BGROUND_COLOUR: Yup.string()
    .matches(/^#[0-9A-F]{6}$/i)
    .required("TopBar Color Code Is Required"),
  TABLE_HEADER_COLOUR: Yup.string()
    .matches(/^#[0-9A-F]{6}$/i)
    .required("TopBar Color Code Is Required"),
  FONT_COLOUR_LIGHT: Yup.string()
    .nullable()
    .matches(/^#[0-9A-F]{6}$/i)
    .required("TopBar Color Code Is Required"),
  FONT_COLOUR_DARK: Yup.string()
    .matches(/^#[0-9A-F]{6}$/i)
    .required("TopBar Color Code Is Required"),
});

const authenticationOptions = [
  { value: "EMAIL OTP", label: "2FA - OTP code via Email" },
  { value: "QR", label: "2FA - OTP code via QR code" },
  // {value: 1, label: "2FA - OTP code via Email and Mobile"},
  // {value: 2, label: "2FA - Microsoft Authenticator"},
  // {value: 3, label: "2FA - Authy"},
  // {value: 4, label: "2FA - Google Authenticator"},
];

export default {
  CustomerInitialData,
  CustomerValidationSchema,
  ThemeInitialData,
  authenticationOptions,
  ThemeValidationSchema,
};
