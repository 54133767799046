import React, { useEffect} from "react"
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import sessionService from "_services/session.service";
import userActions from "../containers/LoginPage/actions"
import PropTypes from "prop-types";


const TimeOut = ({Timeout , setIsIdle}) => {
  const FinalTimeOut = Timeout * 60000;
  const dispatch = useDispatch();

  const handleOnIdle = () => {
    let id = sessionService.getUserId();
    if (id && Timeout !== undefined) {
      setIsIdle(true);
      dispatch(userActions.logout(id));
    }
  }

  // const handleOnAction = () => {
  //   let id = sessionService.getUserId();
  //   if (id) {
  //     localStorage.setItem("TIMEOUT_TIME", new Date);
  //   }
  // }

  // useEffect(() => {
  //   setInterval(() => {
  //     let id = sessionService.getUserId()
  //     if (id) {
  //       let OldDate = new Date(localStorage.getItem("TIMEOUT_TIME"));
  //       let newDate = new Date;
  //       let difference = newDate.getTime() - OldDate.getTime();

  //       if (difference > FinalTimeOut) {
  //         handleOnIdle()
  //       }
  //     }
  //   }, 4*60*1000);
  // }, []);


  const { getRemainingTime } = useIdleTimer({
    timeout: FinalTimeOut,
    onIdle: handleOnIdle,
    startOnMount: true,
    // onAction: handleOnAction,
  });

  // useEffect(() => {
  //   setInterval(() => {
  //     console.log(getRemainingTime(), Timeout);
  //   }, 1000)
  // }, [])

  return (
    <></>
  )

}


TimeOut.propTypes = {
  Timeout : PropTypes.number,
  setIsIdle: PropTypes.func
}


export default TimeOut