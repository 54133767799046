import {
  Box,
  Grid,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import useStyles from "./styles";
import { getThemeDetails } from "containers/LoginPage/reducers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import HelpServices from "./services";
import langConstant from "_lang";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddBoxIcon from "@mui/icons-material/AddBox";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import SearchIcon from "@mui/icons-material/Search";
import sessionService from "_services/session.service";
import { confirmationDialogActions } from "../../_actions/confirmationDialog.action";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import DisplayPopup from "./components/display-file";
import AddTopic from "./components/add-topic";

const HelpPortal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeDetails = useSelector(getThemeDetails);
  const customerID = sessionService.getCustomerId();

  const defaultDocUrl = useSelector(
    (state) => state.authentication?.detailedInfo?.DEFAULT_DOC_URL
  );

  const onlineHelp = useSelector(
    (state) => state.authentication?.detailedInfo?.ONLINE_HELP
  );

  const userData = useSelector((state) => state.authentication.userData);

  const [filteredContent, setFilteredContent] = useState([]);
  const [content, setContent] = useState("");
  const [type, setType] = useState([]);
  const [activeGroup, setActiveGroup] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [expanded, setExpanded] = useState(null);
  const [currentUrl, setCurrentUrl] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddTopic, setOpenAddTopic] = useState(false);
  const [contentType, setContentType] = useState("");
  const [topic, setTopic] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  let onlineHelpEditor = "N";
  if (
    userData &&
    userData.allowedNavItems &&
    userData.allowedNavItems.length > 0 &&
    userData.allowedNavItems.indexOf("ONLINE_HELP_EDITOR") > -1
  ) {
    onlineHelpEditor = "Y";
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEdit = (item) => {
    setTopic({
      ID: item["ID"],
      TOPIC_GROUP: item["HELP_GROUPING"],
      TOPIC_NAME: item["TOPIC"],
      TOPIC_CONTENT: item["TOPIC_DETAILS"],
      TOPIC_URL: item["TOPIC_DETAILS_URL"],
      TYPE: item["ONLINE_HELP_TYPE"],
    });
    setOpenAddTopic(true);
  };

  const handleDelete = (item) => {
    dispatch(
      confirmationDialogActions.open({
        message: langConstant.CONFIRMATION_TO_DELETE,

        onConfirm: () => {
          setTopic({
            ID: item["ID"],
            TOPIC_GROUP: item["HELP_GROUPING"],
            TOPIC_NAME: item["TOPIC"],
            TOPIC_CONTENT: item["TOPIC_DETAILS"],
            TOPIC_URL: item["TOPIC_DETAILS_URL"],
            TYPE: item["ONLINE_HELP_TYPE"],
          });
          filteredContent[activeGroup].splice(
            filteredContent[activeGroup].indexOf(item),
            1
          );
          dispatch(
            HelpServices.DeleteOnlineHelpItem(
              customerID,
              {
                ID: item["ID"],
                TOPIC_GROUP: item["HELP_GROUPING"],
                TOPIC_NAME: item["TOPIC"],
                TOPIC_CONTENT: item["TOPIC_DETAILS"],
                TOPIC_URL: item["TOPIC_DETAILS_URL"],
                TYPE: item["ONLINE_HELP_TYPE"],
              },
              0
            )
          );
        },
        title: "",
        option1: langConstant.YES,
        option2: langConstant.NO,
      })
    );
  };

  const searchData = debounce((userInput) => {
    const output = Object.fromEntries(
      Object.entries(content).map(([key, value]) => [
        key,
        value.filter(
          (entry) =>
            entry.TOPIC.toString()
              .toUpperCase()
              .includes(userInput.toString().toUpperCase()) ||
            entry.TOPIC_DETAILS?.toString()
              .toUpperCase()
              .includes(userInput.toString().toUpperCase())
        ),
      ])
    );
    setPageCount(calculatePageCount(output[activeGroup]));
    setFilteredContent(output);
  }, 1000);

  const handleCloseAddTopic = () => {
    setOpenAddTopic(false);
  };

  const calculatePageCount = (rows) => {
    return rows?.length > 0
      ? Math.round(Math.ceil(rows?.length / rowsPerPage))
      : 0;
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const renderContent = (url) => {
    const contentType = getContentType(url);
    const baseurl = `${defaultDocUrl}${onlineHelp}/`;
    let currentUrl = `${baseurl}${url}`;

    setOpenDialog(true);
    setContentType(contentType);
    setCurrentUrl([{ uri: currentUrl, filetype: contentType }]);
  };

  const getContentType = (url) => {
    if (url.match(/\.(pdf|txt|docx|xls|xlsx|xml|doc|csv|rtf)$/)) {
      return "document";
    } else if (url.match(/\.(mp4|mov|avi|mkv)$/)) {
      return "video";
    } else if (url.match(/\.(jpeg|jpg|gif|png)$/)) {
      return "image";
    } else {
      return "unknown";
    }
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredContent[activeGroup]?.length > 0
        ? Math.round(
            Math.ceil(filteredContent[activeGroup].length / range.value)
          )
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const capitalizeFirstLetter = (groupName) => {
    return groupName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getFileType = (url) => {
    if (url.endsWith(".pdf")) {
      return "pdf";
    } else if (url.endsWith(".txt")) {
      return "txt";
    } else if (url.endsWith(".doc")) {
      return "doc";
    } else if (url.endsWith(".rtf")) {
      return "rtf";
    } else if (url.endsWith(".xml")) {
      return "xml";
    } else if (url.endsWith(".xlsx")) {
      return "xlsx";
    } else if (url.endsWith(".xls")) {
      return "xls";
    } else if (url.endsWith(".docx")) {
      return "docx";
    } else if (url.match(/\.(mp4|mov|avi|mkv)$/)) {
      return "video";
    } else if (url.match(/\.(jpeg|jpg|gif|png)$/)) {
      return "image";
    } else {
      return "unknown";
    }
  };

  useEffect(() => {
    dispatch(
      HelpServices.GetOnLineHelpTopic(
        customerID,
        setContent,
        setType,
        setFilteredContent,
        setActiveGroup,
        calculatePageCount,
        setPageCount
      )
    );
  }, []);

  return (
    <Grid>
      <Grid>
        <Grid>
          <TextField
            className={classes.textField}
            placeholder="Search..."
            onChange={(event) => {
              setSearchInput(event.target.value);
              searchData(event.target.value);
            }}
            InputProps={{
              classes: {
                input: classes.inputField,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "25px", paddingTop: "5px" }}>
        <Grid item md={4} xs={6}>
          <Paper style={{ backgroundColor: "whitesmoke" }}>
            <Grid container justifyItems="flex-end">
              <Grid
                item
                md={9}
                xs={7}
                style={{
                  padding: "1px 35px",
                  margin: "10px 0px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <Typography variant="h6">{langConstant.CONTENT}</Typography>
              </Grid>
              {onlineHelpEditor == "Y" && (
                <Grid
                  item
                  md={3}
                  xs={5}
                  textAlign={"end"}
                  style={{
                    padding: "1px 20px",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setTopic(null);
                      setOpenAddTopic(true);
                    }}
                  >
                    <AddBoxIcon
                      sx={{
                        color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                        fontSize: "32px",
                      }}
                    />
                  </IconButton>
                </Grid>
              )}

              <AddTopic
                open={openAddTopic}
                setOpen={setOpenAddTopic}
                selectedTopic={topic}
                type={type}
                content={filteredContent}
                setContent={setContent}
                setFilteredContent={setFilteredContent}
                searchInput={searchInput}
                searchData={searchData}
              />
            </Grid>

            <Grid>
              {Object.keys(filteredContent).map((group, index) => (
                <Grid
                  key={index}
                  container
                  style={{ padding: "10px 35px" }}
                  onClick={() => {
                    setActiveGroup(group);
                    setPage(0);
                    setPageCount(calculatePageCount(filteredContent[group]));
                  }}
                  sx={{
                    "&:hover": {
                      color: themeDetails?.FONT_TYPEFACE_BOLD,
                      cursor: "pointer",
                    },
                    color:
                      activeGroup == group
                        ? themeDetails?.FONT_TYPEFACE_BOLD
                        : "inherit",
                  }}
                >
                  <Grid item md={9} xs={7}>
                    <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
                      {capitalizeFirstLetter(group.toString())}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={5} textAlign={"end"}>
                    <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
                      {String(filteredContent[group].length).padStart(2, "0")}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={8} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            <Grid
              item
              xs={8}
              style={{
                paddingTop: "5px",
                paddingLeft: "20px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <Typography variant="h6">{langConstant.FAQ}</Typography>
            </Grid>
            {filteredContent[activeGroup] &&
              filteredContent[activeGroup]?.length == 0 && (
                <Typography
                  style={{
                    marginTop: "20px",
                    paddingLeft: "60px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {langConstant.NO_CONTENT_FIND_IN}{" "}
                  {capitalizeFirstLetter(activeGroup)}
                </Typography>
              )}

            {activeGroup &&
              filteredContent[activeGroup] &&
              (rowsPerPage > 0
                ? filteredContent[activeGroup].slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredContent[activeGroup]
              ).map((item, index) => (
                <Grid
                  key={index}
                  container
                  spacing={2}
                  justifyItems="center"
                  alignItems="center"
                >
                  <Grid item xs={1}>
                    <Typography
                      style={{
                        paddingLeft: "10x",
                        marginLeft: "20px",
                      }}
                    >
                      {String(page * rowsPerPage + index + 1).padStart(2, "0")}
                    </Typography>
                  </Grid>
                  <Grid item xs={11} justifyItems="flex-start">
                    <Accordion
                      expanded={expanded === `activeGroup${index}`}
                      onChange={handleAccordionChange(`activeGroup${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container style={{ alignItems: "center" }}>
                          <Grid item md={9} xs={7} textAlign={"start"}>
                            <Typography
                              style={{
                                fontWeight: "normal",
                                fontSize: "16px",
                              }}
                            >
                              {item.TOPIC}
                            </Typography>
                          </Grid>
                          <Grid item md={3} xs={5} textAlign={"end"}>
                            {item.TOPIC_DETAILS_URL &&
                              item.TOPIC_DETAILS_URL != "" &&
                              ((item.TOPIC_DETAILS_URL.endsWith(".pdf") && (
                                <IconButton
                                  onClick={() =>
                                    renderContent(item.TOPIC_DETAILS_URL)
                                  }
                                >
                                  <PictureAsPdfIcon
                                    sx={{
                                      color:
                                        themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                                    }}
                                    size="small"
                                  />
                                </IconButton>
                              )) ||
                                (item.TOPIC_DETAILS_URL.endsWith(
                                  ".txt" ||
                                    ".xls" ||
                                    ".xlsx" ||
                                    ".doc" ||
                                    ".docx" ||
                                    ".csv" ||
                                    ".xml" ||
                                    ".rtf"
                                ) && (
                                  <IconButton
                                    onClick={() =>
                                      renderContent(item.TOPIC_DETAILS_URL)
                                    }
                                  >
                                    <DescriptionIcon
                                      sx={{
                                        color:
                                          themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                                      }}
                                      size="small"
                                    />
                                  </IconButton>
                                )) ||
                                (item.TOPIC_DETAILS_URL.endsWith(
                                  ".mp4" || ".avi" || ".mkv" || ".mov"
                                ) && (
                                  <IconButton
                                    onClick={() =>
                                      renderContent(item.TOPIC_DETAILS_URL)
                                    }
                                  >
                                    <VideoFileIcon
                                      sx={{
                                        color:
                                          themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                                      }}
                                      size="small"
                                    />
                                  </IconButton>
                                )) ||
                                (item.TOPIC_DETAILS_URL.endsWith(
                                  ".png" || ".jpg" || ".jpeg" || ".gif"
                                ) && (
                                  <IconButton
                                    onClick={() =>
                                      renderContent(item.TOPIC_DETAILS_URL)
                                    }
                                  >
                                    <ImageIcon
                                      sx={{
                                        color:
                                          themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                                      }}
                                      size="small"
                                    />
                                  </IconButton>
                                )))}

                            <DisplayPopup
                              open={openDialog}
                              onClose={handleCloseDialog}
                              url={currentUrl}
                              contentType={contentType}
                            />
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container style={{ alignItems: "center" }}>
                          <Grid item md={9} xs={7} textAlign={"start"}>
                            <Typography
                              style={{ fontSize: "14px" }}
                              dangerouslySetInnerHTML={{
                                __html: item.TOPIC_DETAILS,
                              }}
                            />
                          </Grid>
                          <Grid item md={3} xs={5} textAlign={"end"}>
                            <IconButton onClick={() => handleEdit(item)}>
                              <EditIcon
                                sx={{
                                  color: "green",
                                }}
                                size="small"
                              />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(item)}>
                              <DeleteIcon
                                sx={{
                                  color: "red",
                                }}
                                size="small"
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              ))}

            <Box my={2}>
              <Grid
                container
                style={{ justifyContent: "end" }}
                justify="flex-end"
              >
                {filteredContent[activeGroup]?.length > 0 && (
                  <SelectPageRange
                    selectedRange={rowsPerPage}
                    handleChange={handleSelectPageRange}
                  />
                )}
                {pageCount > 0 && (
                  <AppPagination
                    colSpan={3}
                    count={pageCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HelpPortal;
