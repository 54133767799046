import { InitiateMyAssessmentConstants } from "./constant"

export const setMyAssessmentQuestions = (data) => ({
    type: InitiateMyAssessmentConstants.SET_MY_ASSESSMENT_QUESTIONS,
    payload: data,
});

export const setMyAssessmentObservations = (data) => ({
    type: InitiateMyAssessmentConstants.SET_MY_ASSESSMENT_OBSERVATIONS,
    payload: data,
});

export const setMyAssessmentTrustScoreForAllQuestions = (data) => ({
    type: InitiateMyAssessmentConstants.SET_MY_ASSESSMENT_TRUST_SCORE_ALL_QUESTIONS,
    payload: data,
});

export const MyAssessmentCleanUpFunc = () => ({ type: InitiateMyAssessmentConstants.MY_ASSESSMENT_CLEANUP });

export const setMyAssessmentRfiItems = (data) => ({
    type: InitiateMyAssessmentConstants.MY_ASSESSMENT_RFI_ITEMS,
    payload: data
});

export const setRfirecipients = (data) => ({
    type: InitiateMyAssessmentConstants.SET_RFI_RECIPIENTS,
    payload: data,
});

export const setMyassessmentResultSet2 = (data) => ({
    type: InitiateMyAssessmentConstants.SET_RESULT_SET_2,
    payload: data,
});