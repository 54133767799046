import React from 'react';
import useStyles from './styles';
import { Grid } from '@mui/material';
import classNames from 'classnames';


const BouncingLoader = () => {
    const classes = useStyles()
    return (
        <Grid className={classes.bouncingLoader}>
            <Grid className={classNames(classes.loaderDiv,classes.loadone)}>   </Grid>
            <Grid className={classNames(classes.loaderDiv,classes.loadtwo)}></Grid>
            <Grid className={classNames(classes.loaderDiv,classes.loadthree)}></Grid>
        </Grid>
    )
}

export default BouncingLoader;