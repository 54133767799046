import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "../styles";
import { convertColorToRGBA } from "globalMuiStyles";
import propTypes from "prop-types";

export const TileCard = ({
  label,
  count = 0,
  icon,
  color,
  customStyles = {},
}) => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    gap={1}
    sx={{ ...styles.whiteWrapper, ...customStyles }}
    flex="1"
  >
    <Avatar
      variant="rounded"
      sx={{
        color,
        backgroundColor: convertColorToRGBA(color, 0.075),
        width: "100%",
        flex: 1,
        height: "inherit",
      }}
    >
      {icon}
    </Avatar>
    <Box
      sx={{ display: "flex", placeContent: "center", flexDirection: "column" }}
      flex="1"
      textAlign="center"
    >
      <Typography style={{ fontSize: "1rem" }}>{label}</Typography>
      <Typography variant="h5" component="h5">
        <strong>{count}</strong>
      </Typography>
    </Box>
  </Box>
);

TileCard.propTypes = {
  label: propTypes.string,
  count: propTypes.number,
  icon: propTypes.element,
  color: propTypes.string,
  customStyles: propTypes.object,
};
