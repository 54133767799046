import React from "react";

import { alertActions, loadingActions } from "../_actions";
import apiService from "../_services/api.service";
import { constants } from "../_constants/calendar.constants";
import langConstant from "../_lang";

const getUserCalendarEntries = (data) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetUserCalendarEntries", {
        v_entry_type: data.entryType,
        v_user_id: data.userID,
        v_submission_id: data.submissionId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#result-set-1"]) {
            dispatch({
              type: constants.CALENDAR_ENTRIES_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch({ type: constants.CALENDAR_ENTRIES_FAILURE });
            dispatch(
              alertActions.error(langConstant.GET_CALENDAR_ENTRIES_FAILURE)
            );
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateCalendar = (entryData, data) => {
  return (dispatch) => {
    dispatch(updateUserCalendarEntries(entryData)).then((res) => {
      dispatch(getUserCalendarEntries(data));
    });
  };
};

const updateUserCalendarEntries = (entryData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCalendarEntry", entryData)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#update-count-1"]) {
            dispatch(
              alertActions.success(langConstant.UPDATE_CALENDAR_ENTRIES_SUCCESS)
            );
            dispatch({ type: constants.CALENDAR_ENTRIES_INITIATING });
          } else {
            dispatch(
              alertActions.error(langConstant.UPDATE_CALENDAR_ENTRIES_FAILURE)
            );
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
const meetingOperation = (meetingData, data) => {
  return (dispatch) => {
    if (meetingData.operation === "create") {
      dispatch(putCalenderEntryMeeting(meetingData)).then((res) => {
        dispatch(getUserCalendarEntries(data));
      });
    } else {
      dispatch(updateCalendarEntryMeeting(meetingData)).then((res) => {
        dispatch(getUserCalendarEntries(data));
      });
    }
  };
};

const putCalenderEntryMeeting = (meetingData) => {
  return (dispatch) => {
    delete meetingData.operation;
    dispatch(loadingActions.start());
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutCalenderEntryMeeting", meetingData)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#result-set-1"][0]["#update-count-1"]) {
            dispatch(alertActions.success(langConstant.CREATE_MEETING_SUCCESS));
            dispatch({ type: constants.CALENDAR_ENTRIES_INITIATING });
          } else {
            dispatch(alertActions.error(langConstant.CREATE_MEETING_FAILURE));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
const updateCalendarEntryMeeting = (meetingData) => {
  return (dispatch) => {
    delete meetingData.operation;
    dispatch(loadingActions.start());
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCalendarEntryMeeting", meetingData)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#update-count-1"]) {
            dispatch(alertActions.success(langConstant.UPDATE_MEETING_SUCCESS));
            dispatch({ type: constants.CALENDAR_ENTRIES_INITIATING });
          } else {
            dispatch(alertActions.error(langConstant.UPDATE_MEETING_FAILURE));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
const setSelectedEntry = (selectedEntry) => {
  return {
    type: constants.SET_SELECTED_EVENT,
    selectedEntry: selectedEntry,
  };
};

export default {
  updateCalendar,
  meetingOperation,
  getUserCalendarEntries,
  updateUserCalendarEntries,
  setSelectedEntry,
};
