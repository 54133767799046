import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import langConstant from "_lang";
import DialogBox from "_components/DialogBox";
import PropTypes from "prop-types";
import useStyles from "../styles";
import sessionService from "_services/session.service";
import FormTextField from "_components/FormTextField";
import { Button, Grid, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import { alertActions } from "_actions";
import * as Yup from "yup";
import EmailDialog from "./emailPopUP";

const styles = {
  wrapperStyle: {
    border: "1px solid #969696",
  },
  editorStyle: {
    padding: "0.5rem",
  },
};

const RequestLicenceKey = ({ open, setOpen, values, customerName }) => {
  //   const [errorCustomerID, setErrorCustomerID] = useState(false);
  //   const [errorCustomerName, setErrorCustomerName] = useState(false);
  //   const [errorCurrentLicenceExpiryDate, setErrorCurrentLicenceExpiryDate] =
  //     useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);

  const classes = useStyles();
  const customerID = sessionService.getCustomerId();

  const RequestLicenceKeyInitialValues = {
    CUSTOMER_ID: values ? values.CUSTOMER_ID : "",
    CUSTOMER_NAME: customerName ? customerName : "",
    LICENCE_EXPIRY_DATE: values ? values.LICENCE_EXPIRY_DATE : "",
    LICENCE_TYPE: values ? values.LICENCE_TYPE : "",
  };

  const RequestLicenceKeyValidationSchema = Yup.object().shape({
    CUSTOMER_ID: Yup.string().trim().required("Customer ID Is Required"),
    CUSTOMER_NAME: Yup.string().trim().required("Customer Name Is Required"),
    LICENCE_EXPIRY_DATE: Yup.string()
      .trim()
      .required("Current Licence Expiry Date Is Required"),
    LICENCE_TYPE: Yup.string().trim().required("Licence Type Is Required"),
  });

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const showAlert = (message, type = "error") => {
    if (!message) return;
    dispatch(alertActions?.[type]?.(message));
  };

  const onSubmit = async (values) => {};

  return (
    <DialogBox
      maxWidth={"sm"}
      title={langConstant.REQUEST_LICENCE_KEY}
      open={open}
      handleClose={handleClose}
    >
      <Formik
        initialValues={RequestLicenceKeyInitialValues}
        validationSchema={RequestLicenceKeyValidationSchema}
        onSubmit={onSubmit}
        //onChange={() => {
        //   setErrorCustomerID(false);
        //   setErrorCustomerName(false);
        //   setErrorCurrentLicenceExpiryDate(false);
        //   setErrorLicenceType(false);
        // }}
        enableReinitialize
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Grid container>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.CUSTOMER_ID}
                  value={values.CUSTOMER_ID}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="CUSTOMER_ID"
                  errors={errors}
                  disabled={true}
                />
                {errors && errors["CUSTOMER_ID"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["CUSTOMER_ID"]}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.CUSTOMER_NAME}
                  value={values.CUSTOMER_NAME}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="CUSTOMER_NAME"
                  errors={errors}
                  disabled={true}
                />

                {errors && errors["CUSTOMER_NAME"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["CUSTOMER_NAME"]}
                  </Typography>
                )}
              </Grid>

              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.LICENCE_EXPIRY_DATE}
                  value={
                    values
                      ? values.LICENCE_EXPIRY_DATE.split("T")[0] +
                        " " +
                        values.LICENCE_EXPIRY_DATE.split("T")[1].split(".")[0]
                      : ""
                  }
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="LICENCE_EXPIRY_DATE"
                  errors={errors}
                  disabled={true}
                />

                {errors && errors["LICENCE_EXPIRY_DATE"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["LICENCE_EXPIRY_DATE"]}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.LICENCE_TYPE}
                  value={values.LICENCE_TYPE}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="LICENCE_TYPE"
                  errors={errors}
                  disabled={true}
                />

                {errors && errors["LICENCE_TYPE"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["LICENCE_TYPE"]}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid style={{ textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => setOpenEmailPopup(true)}
              >
                {langConstant.SEND_REQUEST}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
      <EmailDialog
        open={openEmailPopup}
        setOpen={setOpenEmailPopup}
        values={values}
        customerName={customerName}
      />
    </DialogBox>
  );
};

RequestLicenceKey.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  values: PropTypes.object,
  customerName: PropTypes.string,
};

export default RequestLicenceKey;
