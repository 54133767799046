import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Button
} from "@material-ui/core";
import PropTypes from "prop-types";
import ExtractionService from "containers/manage-regulation/Policy/PolicyExplorer/component/NewPolicyUpload/services";
import { alertActions } from "_actions";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, IconButton, Typography } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import QuestionMappingService from "../../services";
import sessionService from "_services/session.service";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import useStyles from "../../styles";
import { getThemeDetails } from "containers/LoginPage/reducers";

const SentenceView = ({
  sentenceView,
  setSentenceView,
  handleClose,
  regulationID,
  questionID,
  sentenceMappingData,
  setSentenceMappingData,
}) => {
  const [policyText, setPolicyText] = useState([]);
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });

  const [sentenceData, setSentenceData] = useState(sentenceMappingData);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deatiledView, setDetailedView] = useState({
    policyExtractionID: null,
    sentences: [],
  });
  const [filters, setFilters] = useState({ text: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [pageCount, setPageCount] = useState(1);

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredData.length > 0
        ? Math.round(Math.ceil(filteredData.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
  };

  const handleSelectedData = (extractionID, isChecked) => {
    if (!isChecked) {
      let tempSelectedHeaders = [...selectedHeaders];
      const headersData = tempSelectedHeaders.filter(
        (header) => header != extractionID
      );
      setSelectedHeaders(headersData);
    } else {
      setSelectedHeaders((prevState) => [...prevState, extractionID]);
    }
  };

  useEffect(() => {
    (async () => {
      const policyResponse =
        await ExtractionService.getPolicyExtractionDetailsByPage({
          policyId: sentenceView["selectedPolicyId"],
          pageNo: null,
        });
      if (policyResponse.status == 200) {
        setPolicyText(policyResponse["#result-set-1"]);
        setFilteredData(policyResponse["#result-set-1"]);
        calculatePageCount(policyResponse["#result-set-1"]);
        setSentenceData(
          sentenceMappingData.filter(
            (sentence) => sentence.POLICY_ID == sentenceView.selectedPolicyId
          )
        );
      } else {
        dispatch(alertActions.error("Policy Extraction Details SP Failed."));
      }
    })();
  }, [sentenceView.selectedPolicyId]);

  useEffect(() => {
    const tempData = filterData();
    setFilteredData(tempData);
    setPageCount(calculatePageCount(tempData));
    setPage(0);
  }, [filters, policyText]);

  const filterData = () => {
    let data = [...policyText];
    if (filters.text) {
      data = data.filter(
        (sentence) =>
          sentence.NAME.includes(filters.text) ||
          sentence.SENTENCES.includes(filters.text)
      );
    }
    return data;
  };

  const updateQuestionPolicyMapping = () => {
    if (selectedHeaders.length) {
      const payload = {
        i_CUSTOMER_ID: customerID,
        i_REGULATION_ID: regulationID,
        i_QUESTION_ID: questionID,
        i_POLICY_EXTRACTION_DETAILS_ID: selectedHeaders.toString(),
        i_USER_ID: userID,
      };
      dispatch(
        QuestionMappingService.putQuestionPolicyMap(payload, setSentenceView)
      );
    } else {
      dispatch(alertActions.error(langConstant.PLEASE_SELECT_HEADERS));
    }
  };

  const removeSentenceLink = (extractionID) => {
    const payload = {
      i_REGULATION_ID: regulationID,
      i_QUESTION_ID: questionID,
      i_POLICY_EXTRACTION_DETAILS_ID: extractionID,
      i_USER_ID: userID,
      i_ACTIVE: false,
    };
    dispatch(
      QuestionMappingService.updateRegulationPolicyMap(
        payload,
        setSentenceMappingData,
        sentenceMappingData,
        setSentenceData,
        sentenceData
      )
    );
  };

  return (
    <React.Fragment>
      <Grid style={{ display: "flex", marginBottom: "15px" }}>
        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
          Search By Sentence
        </Typography>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          id="searchText"
          label=""
          name="searchText"
          placeholder={langConstant.FILTER_TABLE}
          value={filters.text}
          onChange={(e) =>
            setFilters((prevState) => ({
              ...prevState,
              text: e.target.value,
            }))
          }
          InputProps={{
            autoComplete: "off",
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <IconButton
          className={classes.iconButton}
          style={{ marginLeft: "15px" }}
        >
          <Tooltip title={langConstant.POLICY_MAPPING_VIEW}>
            <NoteAddIcon
              onClick={() =>
                setSentenceView({ selectedPolicyId: "", isSentenceView: false })
              }
              style={{ color: "white" }}
            />
          </Tooltip>
        </IconButton>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          aria-label={langConstant.SENTENCE_MAPPING}
          className="data-table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{ width: "80px" }}
              ></TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ width: "80px" }}
              ></TableCell>
              <TableCell component="th" scope="row" colSpan={2}>
                {langConstant.HEADERS}
              </TableCell>
              <TableCell component="th" scope="row" colSpan={4}>
                {langConstant.SENTENCE}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredData
            ).map((headerData, index) => {
              const isDisabled = sentenceData.some(
                (item) =>
                  item.POLICY_EXTRACTION_DETAILS_ID ==
                  headerData.POLICY_EXTRACTION_DETAILS_ID
              );
              return (
                <React.Fragment key={index}>
                  <TableRow
                    style={{
                      backgroundColor: isDisabled ? "#a9ffa9" : "",
                    }}
                  >
                    <TableCell
                      component="td"
                      scope="row"
                      style={{ width: "80px" }}
                    >
                      <Checkbox
                        checked={
                          isDisabled ||
                          selectedHeaders.includes(
                            headerData.POLICY_EXTRACTION_DETAILS_ID
                          )
                        }
                        onClick={(e) =>
                          handleSelectedData(
                            headerData.POLICY_EXTRACTION_DETAILS_ID,
                            e.target.checked
                          )
                        }
                        disabled={isDisabled}
                        sx={{
                          "&.Mui-checked": {
                            color: "#124571",
                          },
                          display: "inline",
                        }}
                        size="small"
                      />
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      style={{ width: "80px" }}
                    >
                      {deatiledView.policyExtractionID ==
                      headerData.POLICY_EXTRACTION_DETAILS_ID ? (
                        <ArrowCircleDownIcon
                          style={{ color: "#124571", cursor: "pointer" }}
                          onClick={() =>
                            setDetailedView({
                              policyExtractionID: null,
                              sentences: [],
                            })
                          }
                        />
                      ) : (
                        <ArrowCircleRightIcon
                          style={{ color: "#124571", cursor: "pointer" }}
                          onClick={() =>
                            setDetailedView({
                              policyExtractionID:
                                headerData.POLICY_EXTRACTION_DETAILS_ID,
                              sentences: JSON.parse(headerData.SENTENCES),
                            })
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      style={{ cursor: "pointer", fontWeight: "bold" }}
                      colSpan={2}
                    >
                      {headerData.NAME}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      colSpan={4}
                      style={{ textAlign: "right", color: "#af2222" }}
                    >
                      {isDisabled && (
                        <DeleteIcon
                          onClick={() =>
                            removeSentenceLink(
                              headerData.POLICY_EXTRACTION_DETAILS_ID
                            )
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  {deatiledView.policyExtractionID ==
                    headerData.POLICY_EXTRACTION_DETAILS_ID && (
                    <>
                      {deatiledView.sentences.map((sentence, sentenceIndex) => {
                        return (
                          <TableRow key={sentenceIndex}>
                            <TableCell
                              component="td"
                              scope="row"
                              style={{ width: "80px" }}
                            ></TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              style={{ width: "80px" }}
                            ></TableCell>
                            <TableCell colSpan={2}></TableCell>
                            <TableCell colSpan={4}>{sentence}</TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                </React.Fragment>
              );
            })}
            {filteredData.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell style={{ textAlign: "center" }} colSpan={8}>
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid container justify="flex-end">
          <Grid style={{ paddingBottom: "5px" }}>
            <SelectPageRange
              selectedRange={rowsPerPage}
              handleChange={handleSelectPageRange}
            />
          </Grid>
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Box>
      <Box my={2}>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={updateQuestionPolicyMapping}
            size="small"
          >
            {langConstant.CONFIRM}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClose}
            size="small"
          >
            {langConstant.CLOSE}
          </Button>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

SentenceView.propTypes = {
  sentenceView: PropTypes.object,
  handleClose: PropTypes.func,
  setSentenceView: PropTypes.func,
  regulationID: PropTypes.string,
  questionID: PropTypes.string,
  sentenceMappingData: PropTypes.array,
  setSentenceMappingData: PropTypes.func,
};

export default SentenceView;
