import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import LocationCityIcon from "@mui/icons-material/LocationCity";
import BalanceIcon from "@mui/icons-material/Balance";
import UserServices from "_components/Layout/MyProfilePopup/services";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import langConstant from "_lang";
import SaveIcon from "@mui/icons-material/Save";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SupplierServices from "../services";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  borderNone: {
    border: "none",
  },
  width100: {
    width: "100%",
  },
  profileMDBasicInfoIcon: {
    width: "18px !important",
    height: "18px !important",
    fill: "#000 !important",
  },
  inputProfileField: {
    padding: "5px",
    color: "#000000",
  },
  filedTitle: {
    color: "#a9a8a8",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  suppliersACMOdalOne: {
    right: "0px",
    width: "500px",
    bottom: "0px",
    position: "fixed",
    border: "1px solid rgb(230,230,230)",
    height: "calc(100% - 65px)",
    backgroundColor: "#fff",
    padding: "0px 30px 30px 30px",
    overflowY: "auto",
    "& .MuiDialogTitle-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
    },
    "& .MuiDialogContent-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "calc(100% - 60px)",
    },
  },
  profileMDCloseBtn: {
    top: "9px",
    right: "14px",
    width: "18px",
    height: "18px",
    position: "absolute",
  },
  profileMDCloseFirst: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  profileMDCloseSecond: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(-45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  suppliersTopHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "9px",
  },
  suppliersTopHeaderEdit: {
    "& > div": {
      width: "100%",
    },
    "& input": {
      height: "30px",
      padding: "5px 13px",
    },
  },
  supplierstatusWrapper: {
    "& > .MuiSwitch-root": {
      position: "relative",
      left: "-10px",
    },
  },
  PersonTitle: {
    color: "#000000",
    marginBottom: "5px",
    fontWeight: "500",
  },
  KeyTitle: {
    color: "#a9a8a8",
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },
  filedTitleIcon: {
    display: "flex",
    fontWeight: "500",
    "& svg": {
      marginRight: "6px",
      position: "relative",
      top: "-1px",
    },
  },
  wordBreak: {
    wordBreak: "break-all",
  },
}));

const AddTeam = ({ addTeamModal, teamOptions, setTeamOptions }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customerId = useSelector(
    (state) => state?.authentication?.detailedInfo?.CUSTOMER_ID
  );
  const userID = sessionService.getUserId();
  // const username = useSelector(
  //   (state) => state.authentication.detailedInfo?.USER_NAME
  // );
  const [legalEntityData, setLegalEntityData] = useState([]);
  const initialTeamData = {
    TEAM_NAME: "",
    LEGAL_ENTITY_ID: "",
    CITY: "",
    COUNTRY_CODE: addTeamModal ? addTeamModal.data : "",
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [infoClassification, setInfoClassification] = useState([]);

  useEffect(() => {
    dispatch(
      UserServices.getCustomerInfoClassification(userID, setInfoClassification)
    );
    dispatch(
      SupplierServices.getCustomerLegalEntities(
        customerId,
        null,
        setLegalEntityData
      )
    );
  }, []);

  const validationSchema = Yup.object().shape({
    TEAM_NAME: Yup.string().required("User Name is required"),
    LEGAL_ENTITY_ID: Yup.string().required("Legal Entity is required"),
    CITY: Yup.string().required("City is Required"),
    COUNTRY_CODE: Yup.string().required(
      "Select Country Code on previous screen"
    ),
  });

  const saveSupplierTeam = (values) => {
    dispatch(
      SupplierServices.putSupplierTeam(values, teamOptions, setTeamOptions)
    );
    setIsEditMode(false);
    addTeamModal?.closeModal();
  };

  const handleClose = () => {
    setIsEditMode(false);
    addTeamModal?.closeModal();
  };

  return (
    <Dialog
      open={addTeamModal?.isOpen}
      maxWidth="sm"
      fullWidth
      keepMounted
      onClose={addTeamModal?.closeModal}
    >
      <div className={classes.padding}>
        <DialogTitle disableTypography className={classes.title}>
          <Typography style={{ flex: 1 }} variant="h6" align="center">
            {langConstant.ADD_NEW_TEAM}
          </Typography>
          <IconButton
            style={{ position: "absolute", right: 4 }}
            aria-label="close"
            onClick={addTeamModal?.closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={initialTeamData}
            validationSchema={validationSchema}
            onSubmit={saveSupplierTeam}
          >
            {({ values, errors, setFieldValue, resetForm }) => (
              <Form>
                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={1}>
                    <GroupWorkIcon />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.TEAM_NAME}
                    </Typography>
                    <Field name="TEAM_NAME">
                      {({ field: { value } }) => (
                        <TextField
                          value={value}
                          // disabled={!isEditMode}
                          className={classes.width100}
                          onChange={(e) =>
                            setFieldValue("TEAM_NAME", e.target.value)
                          }
                          variant="outlined"
                          InputProps={{
                            classes: {
                              input: classes.inputProfileField,
                              notchedOutline: "",
                            },
                          }}
                        />
                      )}
                    </Field>

                    <ErrorMessage name="TEAM_NAME">
                      {(msg) => (
                        <Typography style={{ color: "red" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={1}>
                    <BalanceIcon />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.LEGAL_ENTITY}
                    </Typography>
                    <Field name="LEGAL_ENTITY_ID">
                      {({ field: { value } }) => (
                        <Select
                          className={classes.select100Width}
                          value={legalEntityData.find(
                            (entity) => entity.value == value
                          )}
                          options={legalEntityData}
                          onChange={(country) =>
                            setFieldValue("LEGAL_ENTITY_ID", country.value)
                          }
                          placeholder={langConstant.SELECT_LEGAL_ENTITY}
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Field>

                    <ErrorMessage name="LEGAL_ENTITY_ID">
                      {(msg) => (
                        <Typography style={{ color: "red" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={1}>
                    <LocationCityIcon />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.CITY}
                    </Typography>
                    <Field name="CITY">
                      {({ field: { value } }) => (
                        <TextField
                          value={value}
                          // disabled={!isEditMode}
                          className={classes.width100}
                          onChange={(e) =>
                            setFieldValue("CITY", e.target.value)
                          }
                          variant="outlined"
                          InputProps={{
                            classes: {
                              input: classes.inputProfileField,
                              notchedOutline: "",
                            },
                          }}
                        />
                      )}
                    </Field>

                    <ErrorMessage name="CITY">
                      {(msg) => (
                        <Typography style={{ color: "red" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="COUNTRY_CODE">
                      {(msg) => (
                        <Typography style={{ color: "red", marginTop: "10px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "10px", textAlign: "right" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      size="small"
                      startIcon={<SaveIcon />}
                    >
                      {" "}
                      {langConstant.SAVE}{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  );
};

AddTeam.propTypes = {
  addTeamModal: PropTypes.object,
  teamOptions: PropTypes.array,
  setTeamOptions: PropTypes.func,
};

export default AddTeam;
