import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tab,
  Typography,
} from "@material-ui/core";
import { Tabs } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ACTION_STATUS_LIST } from "../../constants";
import { TabContext, TabPanel } from "@material-ui/lab";
import { ActionCard } from "./ActionCard";

export const ListCard = ({ data }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const onTabChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Grid container style={styles.containerCard} spacing={2}>
      <Grid
        item
        md={1}
        style={{ borderRight: "1px solid #E5EAF2", alignItems: "center" }}
      >
        <Box style={{ margin: "1rem" }} justifyContent="center" display="flex">
          <Avatar
            style={{
              ...styles.avatarColor[data?.ACTIONS_PRIORITY],
              fontWeight: "bold",
            }}
          >
            {data?.ACTIONS_PRIORITY}
          </Avatar>
        </Box>
      </Grid>
      <Grid
        onClick={() => setIsOpen(!isOpen)}
        item
        md={9}
        style={{ alignItems: "center" }}
      >
        <Box display="flex" flexDirection="column" margin={2}>
          <Typography
            style={{ fontSize: "12px" }}
            variant="body1"
            color="textSecondary"
          >
            Action List
          </Typography>
          <Typography variant="subtitle2">{data?.ACTIONS_LIST_NAME}</Typography>
        </Box>
      </Grid>
      <Grid md={2} style={{ alignItems: "center" }} item>
        <Box
          style={{ margin: "1rem", gap: 12 }}
          justifyContent="center"
          display="flex"
        >
          <Typography
            style={{ fontSize: "12px", display: "flex", alignItems: "center" }}
            variant="body1"
            color="textSecondary"
          >
            {data?.ACTION_COUNT} Action Items
          </Typography>
          <IconButton onClick={() => setIsOpen(!isOpen)}>
            <ArrowForwardIosIcon
              style={{
                transition: "all ease-in-out 0.3s",
                transform: `rotate(${isOpen ? "90" : "0"}deg)`,
              }}
            />
          </IconButton>
        </Box>
      </Grid>
      {isOpen && (
        <Box
          style={{
            borderTop: "1px solid #E5EAF2",
            display: "flex",
            flexDirection: "column",
            flex: "auto",
          }}
        >
          <TabContext value={currentTab?.toString()}>
            <Tabs
              value={currentTab}
              onChange={onTabChange}
              aria-label="basic tabs example"
            >
              {ACTION_STATUS_LIST.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  icon={
                    <span style={styles.tabIcon}>
                      {data?.[tab?.value]?.length ?? 0}
                    </span>
                  }
                />
              ))}
            </Tabs>
            <TabPanel value={currentTab?.toString()}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "1rem 0rem",
                }}
              >
                {data?.[ACTION_STATUS_LIST[currentTab].value]?.length ? (
                  data?.[ACTION_STATUS_LIST[currentTab].value]?.map(
                    (item, index) => (
                      <ActionCard
                        key={item?.ACTIONS_ID}
                        action={item}
                        isLastChild={
                          index ===
                          data?.[ACTION_STATUS_LIST[currentTab].value]?.length -
                            1
                        }
                      />
                    )
                  )
                ) : (
                  <span
                    style={{
                      alignSelf: "center",
                      margin: "auto",
                      minHeight: "3rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    No records found
                  </span>
                )}
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </Grid>
  );
};

ListCard.propTypes = {
  data: PropTypes.object,
};

const styles = {
  avatarColor: {
    M: { backgroundColor: "#fff6c8", color: "#f5cc00" },
    H: { backgroundColor: "#ffdddd", color: "#ff3131" },
    L: { backgroundColor: "#dfffe1", color: "#4fcd00" },
  },
  tabIcon: {
    width: "1.75rem",
    height: "1.75rem",
    borderRadius: "50%",
    backgroundColor: "red",
    color: "#fff",
    fontSize: "10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontWeight: "bold",
  },
  containerCard: {
    display: "flex",
    gap: "2",
    alignItems: "center",
    minHeight: "3rem",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px 4px #E5EAF2",
  },
};
