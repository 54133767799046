import DialogBox from '_components/DialogBox';
import React, { useEffect, useState } from 'react';
import langConstant from "_lang";
import assessmentServices from "../services" ;
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { alertActions } from '_actions';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core';
import AppPagination from '_components/AppPagination';
import { Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SelectPageRange from '_components/SelectPageRange';


const useStyles = makeStyles(() => ({
    stickyColl: {
      position: "sticky",
      right: 0,
    },
    actionWrap: {
      borderRadius: "50%",
      padding: "4px",
      height: "28px",
      width: "28px",
      display: "flex",
      alignItems: "center",
    },
  }));

const AuditDialog = ({show, setShow, submissionId}) => {
    
    const dispatch = useDispatch()
    const classes = useStyles()
    const toggleDialog = () => setShow(false)

    const [auditInformation, setAuditInformation ] = useState([])

    const calculatePageCount = (rows) => {
        return rows.length > 0
            ? Math.round(Math.ceil(rows.length / rowsPerPage))
            : 0;
    };

    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event) => {
        setPage(event);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const returnClassName = (status) => {
        if(status === "H"){
            return {text: "High", class: "error"}
        } else if(status === "L"){
            return {text: "Low", class: "success"}
        } else if(status === "M"){
            return {text: "Medium", class: "warning"}
        } else{
            return {text: status, class: "info"}
        }
    }

    const columns = [
        { field: "QUESTION_ID", headerName: "Question Id", minWidth: 100 },
        {
            field: "CONTROL_RISK_STATUS",
            headerName: "Control Risk Status",
            format: function formatData(value) {
                const statusInfo = returnClassName(value['CONTROL_RISK_STATUS'])
                return <Alert icon={false} variant="filled" severity={statusInfo.class}>{statusInfo.text}</Alert>
            },
            minWidth: 100,
        },
        {
            field: "RISK_ACCEPT",
            headerName: "Risk Accept",
            format: function formatData(value) {
                return value['RISK_ACCEPT'] == "1" ? <CheckCircleIcon color='success'/> : <CancelIcon color='error'/>
            },
            minWidth: 100,
        },
        { field: "EXPECTATIONS", headerName: "Expectations", minWidth: 100 },
        { field: "FINDINGS", headerName: "Findings", minWidth: 100 },
        { field: "RECOMMENDATIONS", headerName: "Recommendations", minWidth: 100 },
        {
            field: "CREATE_DATE",
            headerName: "Date",
            format: (value) => (value['CREATE_DATE'] ? moment(value['CREATE_DATE']).format("DD-MMM-YY") : ""),
            minWidth: 120,
          },
        { field: "ENTERED BY", headerName: "Entered By", minWidth: 135 },
    ];


    useEffect(() => {
        if(submissionId){
            (async() => {
                const response = await assessmentServices.getAssessmentAuditInformation(submissionId)
                if(response.status == 200){
                    setAuditInformation([...response['#result-set-1']])
                    setPageCount(calculatePageCount(response['#result-set-1']))
                  } else {
                    if(response.data && response.data.message)
                    {
                      dispatch(
                          alertActions.error(
                            response.data.message.map((item, index) => {
                                  return <div key={index}>{item}</div>;
                              })
                          )
                      );
                    } else {
                      dispatch(
                          alertActions.error(langConstant.SOMETHING_WENT_WRONG))
                    }
                  }
            })()
        }
    },[submissionId])

    const handleSelectPageRange = (range) => {
        setRowsPerPage(range.value)
        const totalPageCount = auditInformation.length > 0 ? Math.round(Math.ceil(auditInformation.length / range.value)): 0
        if(page >= totalPageCount){
          setPage(totalPageCount - 1)
        }
        setPageCount(totalPageCount);
      }

    return (
        <DialogBox
            maxWidth={"lg"}
            title={langConstant.AUDIT_INFORMATION}
            open={show}
            handleClose={toggleDialog}
            style={{paddingTop:"0px",}}
        >
            <TableContainer
                component={Paper}
                style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderBottomStyle: "none",
                    borderTopStyle: "solid",
                    borderRightStyle: "solid",
                    borderLeftStyle: "solid",
                }}
            >
                <Table className="data-table" aria-label="Regulation List">
                    <TableHead>
                        <TableRow>
                        {columns.map((item, index) => (
                            <TableCell
                                component="th"
                                scope="row"
                                key={"column_" + index + item.field}
                                className={
                                    (item.sticky && classes.stickyColl) + " " + classes.heading
                                }
                                style={{ minWidth: item.minWidth }}
                            >
                                {item.headerName}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                        ? auditInformation.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                            )
                        : auditInformation
                        ).map((row, index) => (
                        <TableRow key={index + row[columns[0].field]}>
                            {columns.map((column, index) => {
                            const value = row[column.field];

                            return (
                                <TableCell
                                    component="td"
                                    scope="row"
                                    key={"column_" + index + row[columns[0].field]}
                                    className={column.sticky && classes.stickyColl}
                                    style={{
                                        wordBreak: "break-word",
                                        wordWrap: "break-word",
                                    }}
                                >
                                    {column.format ? column.format(row) : value}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                        ))}

                        {auditInformation.length == 0 && (
                            <TableRow style={{ height: 53 }}>
                                <TableCell
                                    style={{ textAlign: "center" }}
                                    colSpan={columns.length}
                                    >
                                    {langConstant.NO_RECORD_FOUND}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box my={2}>
                <Grid container justify="flex-end">
                    {auditInformation.length > 5 &&
                        <SelectPageRange
                            selectedRange={rowsPerPage}
                            handleChange={handleSelectPageRange}
                        />
                    }
                    {pageCount > 1 && (
                        <AppPagination
                            colSpan={3}
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            </Box>
        </DialogBox>
    )
}

AuditDialog.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    submissionId: PropTypes.number,
};
  

export default AuditDialog;