import DialogBox from "_components/DialogBox";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field, Form, Formik } from "formik";
import UserServices from "../services";
import * as Yup from "yup";
import sessionService from "_services/session.service";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import langConstant from "_lang";
import Select from "react-select";
import useStyles from "./styles";

const ServiceConnectionPopUp = ({
  connectionDialog,
  serviceConnections,
  setServiceConnections,
  selectedConnection,
  handleClose,
}) => {
  const classes = useStyles();
  const [allService, setAllService] = useState([]);
  const dispatch = useDispatch();
  const customerID = sessionService.getCustomerId();

  useEffect(() => {
    dispatch(UserServices.getServiceConnectionName(customerID, setAllService));
  }, []);

  const validationSchema = Yup.object().shape({
    SERVICE_CONNECTION_TYPE_ID: Yup.string().required(
      "Connection Name is Required"
    ),
    SERVICE_URL: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    USER_CREDENTIALS: Yup.string().required("User Credentials is Required"),
    ACTIVE: Yup.string().required("Department is Required"),
  });

  const initialValues = selectedConnection
    ? selectedConnection
    : {
        SERVICE_CONNECTION_TYPE_ID: "",
        CONNECTION_NAME: "",
        SERVICE_URL: "",
        USER_CREDENTIALS: "",
        ACTIVE: true,
      };

  const submitFormData = (values) => {
    values["USER_ID"] = sessionService.getUserId();
    dispatch(
      UserServices.updateUserServiceConnection(
        values,
        serviceConnections,
        setServiceConnections,
        handleClose
      )
    );
  };

  return (
    <DialogBox
      maxWidth={"sm"}
      title={langConstant.SERVICE_CONNECTION}
      open={connectionDialog}
      handleClose={handleClose}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={submitFormData}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formLabel}>
                  {langConstant.SERVICE_CONNECTION}
                </Typography>
                <Field style={{ width: "100%", margin: "10px" }} name="">
                  {() => (
                    <Select
                      className={classes.customerSelectForm}
                      options={allService}
                      value={allService.find(
                        (connection) =>
                          connection.value === values.SERVICE_CONNECTION_TYPE_ID
                      )}
                      placeholder={"select "}
                      onChange={(connection) => {
                        setFieldValue(
                          "SERVICE_CONNECTION_TYPE_ID",
                          connection.value
                        );
                        setFieldValue("CONNECTION_NAME", connection.label);
                      }}
                      isDisabled={selectedConnection ? true : false}
                    />
                  )}
                </Field>
                <ErrorMessage name="SERVICE_CONNECTION_TYPE_ID">
                  {(msg) => <span>{msg}</span>}
                </ErrorMessage>
              </Grid>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formLabel}>
                  {langConstant.SERVICE_URL}
                </Typography>
                <Field style={{ width: "100%" }} name="SERVICE_URL">
                  {() => (
                    <TextField
                      style={{ width: "80%", paddingBottom: "10px" }}
                      value={values.SERVICE_URL}
                      onChange={(e) =>
                        setFieldValue("SERVICE_URL", e.target.value)
                      }
                      placeholder={langConstant.SERVICE_URL}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.inputPasswordField,
                        },
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="SERVICE_URL">
                  {(msg) => <span>{msg}</span>}
                </ErrorMessage>
              </Grid>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formLabel}>
                  {langConstant.USER_CREDENTIALS}
                </Typography>
                <Field style={{ width: "100%" }} name="USER_CREDENTIALS">
                  {() => (
                    <TextField
                      style={{ width: "80%", paddingBottom: "10px" }}
                      value={values.USER_CREDENTIALS}
                      onChange={(e) =>
                        setFieldValue("USER_CREDENTIALS", e.target.value)
                      }
                      placeholder={langConstant.USER_CREDENTIALS}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.inputPasswordField,
                        },
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="USER_CREDENTIALS">
                  {(msg) => <span>{msg}</span>}
                </ErrorMessage>
              </Grid>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formLabel}>
                  {langConstant.ACTIVE}
                </Typography>
                <Field style={{ width: "100%" }} name="ACTIVE">
                  {() => (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={JSON.parse(values.ACTIVE)}
                      onChange={(e) => setFieldValue("ACTIVE", e.target.value)}
                      style={{
                        display: "inline",
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid style={{ textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                {selectedConnection ? langConstant.UPDATE : langConstant.SAVE}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogBox>
  );
};

ServiceConnectionPopUp.propTypes = {
  connectionDialog: PropTypes.bool,
  serviceConnections: PropTypes.array,
  setServiceConnections: PropTypes.func,
  selectedConnection: PropTypes.object,
  handleClose: PropTypes.func,
};

export default ServiceConnectionPopUp;
