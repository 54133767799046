import { ComplianceConstants } from "./constants";

export const setRegulationData = (data) => ({
  type: ComplianceConstants.SET_REGULATION,
  payload: data,
});

export const updateComponents = (data) => ({
  type: ComplianceConstants.UPDATE_COMPONENTS,
  payload: data,
});

export const updateLayout = (data) => ({
  type: ComplianceConstants.UPDATE_LAYOUT,
  payload: data,
});

export const setPin = (data) => ({
  type: ComplianceConstants.SET_PIN,
  payload: data,
});

export const removePanel = (data) => ({
  type: ComplianceConstants.REMOVE_PANEL,
  payload: data,
});

export const setExpandedPanelID = (data) => ({
  type: ComplianceConstants.SET_EXPANDED_PANELID,
  payload: data,
});

export const updateSelectedPanels = (data) => ({
  type: ComplianceConstants.UPDATE_SELECTED_PANELS,
  payload: data,
});
