import React, { useCallback, useEffect, useState } from "react"
import {
  Typography,
  Box,
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  Tooltip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { Formik, Field, ErrorMessage, Form } from "formik";
import TextField from "@mui/material/TextField";
import SearchIcon from "@material-ui/icons/Search";
import FilterDropdown from "_components/FilterDropdown";
import _ from "lodash";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import AppPagination from '_components/AppPagination';
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import langConstant from "_lang";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import EnhancedEncryptionOutlinedIcon from "@mui/icons-material/EnhancedEncryptionOutlined";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { GetAssessmentsForAuditors } from "./services"
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import initiateAssessmentAction from "../MyAssessments/InitiateAssessment/actions";
import DialogBox from "_components/DialogBox";
import RfiFilterTable from "_components/RfiFilterTable";
import CircularProgress from '@mui/material/CircularProgress';
import { auditCleanUpFunc } from "./action"
import { resetStepper } from "_components/Stepper/action"
import AuditDialog from "../MyAssessments/components/AuditDialog";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { ActionMenu } from "_components/ActionMenu";
import { ActionIcon } from "_components/ActionIcon";






const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  bottomGridContainer: {
    marginTop: "20px",
    height: "calc(100vh - 230px)",
    maxHeight: "calc(100vh - 230px)",
  },
  btnWidth: {
    width: "170px"
  },



  marginTopTable: {
    marginTop: "15px",
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px"
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  linkActive: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
  },

}));

const columns = [
  { field: "SUBMISSION_ID", headerName: "SUBMISSION ID", minWidth: 130 },
  { field: "AUDIT_NAME", headerName: "Audit NAME", minWidth: 150 },
  {
    field: "SUBMISSION_DATE",
    headerName: "ASSIGNED DATE",
    format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
    minWidth: 130,
  },
  { field: "ASSIGNED BY", headerName: "ASSIGNED BY", minWidth: 120 },
  { field: "Status_label", headerName: "STATUS", minWidth: 200 },
  { field: "REVIEWER", headerName: "Auditor", minWidth: 120 },
  { field: "action", headerName: "ACTIONS", minWidth: 115, sticky: true },
];

const rfiColumns = [
  {
    field: "REGULATORS_QUESTION_REF",
    headerName: "QUESTION REF.",
    minWidth: 135,
  },
  { field: "Status_label", headerName: "STATUS" },
  {
    field: "RFI_DESCRIPTION",
    headerName: "COVER NOTE",
    minWidth: 350,
  },
  {
    field: "RFI_DUE_DATE",
    headerName: "DUE DATE",
    format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
    // format: (value) => (value ? value.substring(0, 10) : ""),
    minWidth: 120,
  },
];



const Audtis = () => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const dispatch = useDispatch();

  const [auditDialog, setAuditDialog] = useState(false);
  const [submissionId, setSubmissionId] = useState(null)
  const [AllTableData, setAllTableData] = useState([]);
  const [FilteredTableData, setFilteredTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const initialFilterObj = { ASSIGNED_BY: "", STATUS: "", searchText: "" };
  const [filterBy, setFilterBy] = useState(initialFilterObj);
  const [RfiDialogOpen, setRfiDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(FilteredTableData));
  const rfiRows = useSelector((state) => state.initiateRfiDetails.rfiItems);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);

  const handleChangePage = (event) => {
    setPage(event);
  };

  const fetchData = async () => {
    const data = await GetAssessmentsForAuditors(userID);
    if (!data["#result-set-1"] && data["data"]) {
      dispatch(
        alertActions.error(
          data.data.message.map((item, index) => {
            return <div key={index}>{item}</div>;
          })
        )
      );

    } else {
      setAllTableData(data["#result-set-1"])

    }
    setIsLoading(false);

  }

  useEffect(() => {
    dispatch(auditCleanUpFunc());
    dispatch(resetStepper())
    if (userID) {
      setIsLoading(true);
      fetchData();
    }
  }, [userID])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const updatedData = filterFunction();
    setFilteredTableData(updatedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
    setPageCount(calculatePageCount(updatedData))
  }, [page, filterBy, AllTableData]);


  const pageSetter = (sortedData) => {
    const totalrows = calculatePageCount(sortedData)
    if (page + 1 > totalrows) {
      setPage(0)
    }
  }

  const filterFunction = () => {

    let data = [...AllTableData]

    if (filterBy.ASSIGNED_BY !== "") {
      data = data.filter((el) => el["ASSIGNED BY"] === filterBy.ASSIGNED_BY);
      pageSetter(data)
    }

    if (filterBy.STATUS !== "") {
      data = data.filter((el) => el.STATUS === filterBy.STATUS);
      pageSetter(data)
    }

    if (filterBy.searchText !== "") {
      data = data.filter((el) => el.AUDIT_NAME.trim().toLowerCase().includes(filterBy.searchText.trim().toLowerCase()))
    }

    return data
  }

  const onFilterChange = (fieldName, e) => {
    setFilterBy((prev) => ({ ...prev, [fieldName]: e.target.value }))
  }

  const createFilterDropdown = (fieldName, label) => {
    let options = _.reject(_.uniq(_.map(AllTableData, fieldName)).sort(), _.isNull);

    if (options.length > 0 && options[0]) {
      options = options.map((item) => {
        return { value: item, name: item };
      });
    }
    if (options.length > 0) {
      return (
        <FilterDropdown
          options={options}
          label={label}
          fieldName={fieldName === "ASSIGNED BY" ? "ASSIGNED_BY" : fieldName}
          value={fieldName === "ASSIGNED BY" ? filterBy["ASSIGNED_BY"] : filterBy[fieldName]}
          onChange={onFilterChange}
          icon={true}
        />
      );
    }
  };


  const toggleRfiDialogTrue = (id, SUBMISSION_ID) => {
    const goBackOnError = () => { };
    dispatch(initiateAssessmentAction.getAssessmentById(id, null, goBackOnError, SUBMISSION_ID, userID));
    setRfiDialogOpen(true);
  };

  const resetFilter = () => {
    setFilterBy(initialFilterObj);
    // setFilteredRows(rows);
    // setPageCount(calculatePageCount(rows));
    // setPage(0);
  };

  const onActionClick = (event, data) => {
    event?.preventDefault();
    setSelectedRow(data);
    setAnchorMenuEl(openMenu ? null : event.currentTarget);
  };

  return (
    <Box>
      {openMenu && (
        <ActionMenu
          anchorMenuEl={anchorMenuEl}
          onClose={onActionClick}
          isOpen={openMenu}
          menuItems={[
            {
              tooltipLabel: langConstant.VIEW,
              icon: VisibilityIcon,
              isDisabled: selectedRow?.disableLink,
              linkTo: {
                pathname: "/my-audits/" + selectedRow?.REGULATION_ID + "/" + selectedRow?.SUBMISSION_ID,
                state: { AssessmentName: selectedRow?.AUDIT_NAME + " - " + selectedRow?.LEGAL_ENTITY_NAME, AssessmentStatus: selectedRow?.STANDARD_STATUS }
              },
            },
            {
              tooltipLabel: "Check RFI",
              icon: DraftsOutlinedIcon,
              onClick: () => toggleRfiDialogTrue(selectedRow?.REGULATION_ID, selectedRow?.SUBMISSION_ID),
              color: "#0e9439",
              // isDisabled: selectedRow?.data?.RFI != "TRUE",
            },
            {
              tooltipLabel: langConstant.VIEW_EVIDENCE_LOCKER,
              icon: EnhancedEncryptionOutlinedIcon,
              linkTo: "/my-audits/view-all-evidence/" + selectedRow?.REGULATION_ID + "/" + selectedRow?.SUBMISSION_ID,
              color: "#C13636",
            },
            {
              tooltipLabel: "View Checklist",
              icon: ContentPasteIcon,
              color: "#012457",
              onClick: () => {
                setSubmissionId(selectedRow?.['SUBMISSION_ID']);
                setAuditDialog(true)
              }
            }
          ]}
        />
      )}
      <Grid container={true} spacing={2}>
        <Grid item xs={4} md={4}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            id="searchText"
            label=""
            name="searchText"
            placeholder="Filter Table"
            value={filterBy["searchText"]}
            onChange={(e) => { onFilterChange("searchText", e); }}
            InputProps={{
              autoComplete: "off",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

        </Grid>

        <Grid item xs={3} md={3}>
          {createFilterDropdown("ASSIGNED BY", "Assigned By")}
        </Grid>

        <Grid item xs={3} md={3}>
          {createFilterDropdown("STATUS", "Status")}
        </Grid>

        <Grid item xs={3} md={1} style={{ display: "flex" }}>
          {(
            filterBy["searchText"] ||
            filterBy["ASSIGNED_BY"] ||
            filterBy["STATUS"]) && (
              <span onClick={resetFilter} className="linkIcon">
                Reset All
              </span>
            )}

        </Grid>





      </Grid>

      {
        isLoading === true ? (
          <div style={{ display: 'flex', justifyContent: 'center', height: "200px", alignItems: "center" }}>
            <CircularProgress />
          </div>
        ) : (

          <Box className={classes.marginTopTable}>
            <TableContainer component={Paper} style={{ maxHeight: "400px" }}>
              <Table stickyHeader className="data-table" >
                <TableHead>
                  <TableRow>
                    {columns.map((item, index) => (
                      <TableCell
                        component="th"
                        scope="row"
                        key={"column_" + index + item.field}
                        className={item.sticky && classes.stickyColl}
                        style={{ minWidth: item.minWidth }}
                      >
                        {item.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FilteredTableData && FilteredTableData.map((row, rowIndex) => (
                    <TableRow key={"Row_" + rowIndex} component="tr" className={classes.tableRow}>
                      {
                        columns.map((col, index) => (
                          <TableCell component="td" key={"column_" + index} style={{ minWidth: col.minWidth, maxWidth: col.maxWidth }}>
                            {
                              col.field === "SUBMISSION_DATE" ? col.format(row[col.field])
                                : col.field === "Status_label" ? (row.STATUS ? (
                                  <span className={(row.STANDARD_STATUS).toUpperCase() + " status-badge"}>
                                    {row.STATUS}
                                  </span>
                                ) : (
                                  row.STATUS
                                )) : col.field === "action" ? (
                                    <ActionIcon onClick={(e) => onActionClick(e, row)} />
                                ) : row[col.field]
                            }


                          </TableCell>
                        ))}

                    </TableRow>

                  ))}

                  {FilteredTableData.length == 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={columns.length}
                      >
                        No record found
                </TableCell>
                    </TableRow>
                  )}

                </TableBody>

              </Table>

            </TableContainer>

          </Box>

        )}

      {auditDialog && submissionId &&
        <AuditDialog 
          show={auditDialog}
          setShow={setAuditDialog}
          submissionId={submissionId}
        />
      }

      {
        pageCount > 1 && (
          <Box my={2}>
            <Grid container justify="flex-end">
              <AppPagination
                colSpan={3}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Box>
        )
      }

      <DialogBox
        maxWidth={"lg"}
        title={"RFI Details"}
        open={RfiDialogOpen}
        handleClose={() => { setRfiDialogOpen(false); }}
      >
        {rfiRows && (
          <RfiFilterTable
            rows={rfiRows}
            columns={rfiColumns}
            className="data-table"
          />
        )}
      </DialogBox>




    </Box>
  )
}

export default Audtis