import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import classNames from "classnames";
import FileComparisonComponent from "./component/fileComparisonComponent";
import { IconButton, Checkbox, Menu, MenuItem } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterDrawer from "./component/fileComparisonComponent/filterDrawer";
import PolicyServices from "../../../PolicyExplorer/services";
import sessionService from "_services/session.service";
import { useParams } from "react-router-dom";
import { alertActions, loadingActions } from "_actions";
import { useDispatch, useSelector } from "react-redux";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import useStyles from "./styles";
import TuneIcon from "@mui/icons-material/Tune";
import WidgetsIcon from "@mui/icons-material/Widgets";
import CompareDetailMenu from "./compareDetailMenu";
import { checkChunkingAvailability } from "_components/ChatBot/utils";
import ExtractionService from "../../../PolicyExplorer/component/NewPolicyUpload/services";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { ViewNotesDialog } from "./component/ViewNotesDialog";
import { ViewActionsDialog } from "./component/ViewActionsDialog";

const CompareTabPanel = () => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });

  const { id } = useParams();
  const userID = sessionService.getUserId();
  const dispatch = useDispatch();

  const [basePolicyText, setBasePolicyText] = useState([]);
  const [targetPolicyText, setTargetPolicyText] = useState([]);
  const [basePolicyInfo, setBasePolicyInfo] = useState({});
  const [targetPolicyInfo, setTargetPolicyInfo] = useState({});
  const [decisionChangesFlag, setDecisionChangesFlag] = useState(true);
  const [selectedBaseRows, setSelectedBaseRows] = useState([]);

  const [decisionScore, setDecisionScore] = useState({
    accepted: 0,
    rejected: 0,
    pending: 0,
    discard: 0,
    not_processed: 0,
  });
  const [autoDecisionData, setAutoDecisionData] = useState([]);
  const [comparisonStatus, setComparisonStatus] = useState({});
  const [comparisonDetail, setComparisonDetail] = useState({});
  const [chatBotAvailibility, setChatBotAvailibility] = useState({
    base: { isChatBoxAvailable: false, documentSplitterPopUp: false },
    target: { isChatBoxAvailable: false, documentSplitterPopUp: false },
  });
  const [actionList, setActionList] = useState(null);
  const [currentOpenDialog, setCurrentOpenDialog] = useState(null);
  const modalOptions = {
    isOpen: Boolean(currentOpenDialog),
    closeModal: () => setCurrentOpenDialog(null),
  };

  useEffect(() => {
    (async () => {
      dispatch(loadingActions.start());
      const response = await PolicyServices.getPolicyComparisonDetails(id);
      const tempDecisionScore = {
        accepted: 0,
        rejected: 0,
        pending: 0,
        discard: 0,
        not_processed: 0,
      };
      if (response.status === 200) {
        setBasePolicyInfo(response["#result-set-2"][0]);
        setTargetPolicyInfo(response["#result-set-3"][0]);
        setAutoDecisionData(response["#result-set-5"]);
        setComparisonDetail(response["#result-set-7"][0]);
        setComparisonStatus(response["#result-set-6"][0]);
        setActionList(response?.["#result-set-8"]?.[0]);
        const baseResponse = await checkChunkingAvailability(
          response["#result-set-2"][0]["v_base_policy_id"]
        );
        if (baseResponse["isChunking"]) {
          if (baseResponse["isSplitted"]) {
            setChatBotAvailibility((prevState) => ({
              ...prevState,
              base: { ...prevState.base, isChatBoxAvailable: true },
            }));
          } else {
            setChatBotAvailibility((prevState) => ({
              ...prevState,
              base: { ...prevState.base, documentSplitterPopUp: true },
            }));
          }
        } else {
          dispatch(alertActions.error("Policy Chunking Service Failed"));
        }
        const targetResponse = await checkChunkingAvailability(
          response["#result-set-3"][0]["v_target_policy_id"]
        );
        if (targetResponse["isChunking"]) {
          if (targetResponse["isSplitted"]) {
            setChatBotAvailibility((prevState) => ({
              ...prevState,
              target: { ...prevState.target, isChatBoxAvailable: true },
            }));
          } else {
            setChatBotAvailibility((prevState) => ({
              ...prevState,
              target: { ...prevState.target, documentSplitterPopUp: true },
            }));
          }
        } else {
          dispatch(alertActions.error("Policy Chunking Service Failed"));
        }
        response["#result-set-1"].map((item) => {
          if (item["DECISION_DESCRIPTION"] == "Accepted") {
            tempDecisionScore["accepted"] = item["COUNT"];
          } else if (item["DECISION_DESCRIPTION"] == "Rejected") {
            tempDecisionScore["rejected"] = item["COUNT"];
          } else if (item["DECISION_DESCRIPTION"] == "Pending Clarification") {
            tempDecisionScore["pending"] = item["COUNT"];
          } else if (item["DECISION_DESCRIPTION"] == "Discarded") {
            tempDecisionScore["discard"] = item["COUNT"];
          } else {
            tempDecisionScore["not_processed"] = item["COUNT"];
          }
        });
        setDecisionScore(tempDecisionScore);
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
      dispatch(loadingActions.end());
    })();
  }, [decisionChangesFlag]);

  useEffect(() => {
    (async () => {
      if (basePolicyInfo["v_base_policy_id"]) {
        const basePolicyResponse =
          await ExtractionService.getPolicyExtractionDetailsByPage({
            policyId: basePolicyInfo["v_base_policy_id"],
            pageNo: null,
          });
        if (basePolicyResponse.status == 200) {
          setBasePolicyText(basePolicyResponse["#result-set-1"]);
          setSelectedBaseRows(
            basePolicyResponse["#result-set-1"].length
              ? [basePolicyResponse["#result-set-1"][0]]
              : []
          );
        } else {
          dispatch(alertActions.error("Policy Extraction Details SP Failed."));
        }
      }
      if (targetPolicyInfo["v_target_policy_id"]) {
        const targetPolicyResponse =
          await ExtractionService.getPolicyExtractionDetailsByPage({
            policyId: targetPolicyInfo["v_target_policy_id"],
            pageNo: null,
          });
        if (targetPolicyResponse.status == 200) {
          setTargetPolicyText(targetPolicyResponse["#result-set-1"]);
        } else {
          dispatch(alertActions.error("Policy Extraction Details SP Failed."));
        }
      }
    })();
  }, [basePolicyInfo, targetPolicyInfo]);

  const [vectorsFilter, setVectorsFilter] = useState("KEYWORDS_VECTORIZED");
  const [sentenceFilter, setSentenceFilter] = useState("all");
  const [autoDecisionScore, setAutoDecisionScore] = useState({
    acceptScore: null,
    rejectScore: null,
    discardScore: null,
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [decisionFilter, setDecisionFilter] = useState([]);

  // let metaData = comparisonResponse['#result-set-4'][0]['COMPARISON_META_DATA']
  // metaData=JSON.parse(metaData)
  const thresholdvalue = 0;

  const statusOptions = ["DRAFT", "REVIEW", "COMPLETED"];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
  const openMenu = Boolean(anchorMenuEl);
  const handleMenuClick = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const openFilter = Boolean(filterAnchorEl);
  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleVectorsChange = (e) => {
    setVectorsFilter(e.target.value);
  };

  const handleSentenceChange = (e) => {
    setSentenceFilter(e.target.value);
  };

  const decisionDataList = [
    {
      label: langConstant.ACCEPTED_SMALL,
      value: 1,
      count: decisionScore["accepted"],
      color: "#1bff0014",
    },
    {
      label: langConstant.REJECTED,
      value: 2,
      count: decisionScore["rejected"],
      color: "#ff000047",
    },
    {
      label: langConstant.PENDING_CLARIFICATION,
      value: 3,
      count: decisionScore["pending"],
      color: "#fbff0014",
    },
    {
      label: langConstant.DISCARD,
      value: 4,
      count: decisionScore["discard"],
      color: "#00000045",
    },
    {
      label: langConstant.NOT_PROCESSED,
      value: null,
      count: decisionScore["not_processed"],
      color: "#ff000047",
    },
  ];

  const applyDecisionFilter = (decisionValue) => {
    if (decisionFilter.includes(decisionValue)) {
      let tempDecisionFilter = [...decisionFilter].filter(
        (item) => item !== decisionValue
      );
      setDecisionFilter(tempDecisionFilter);
    } else {
      setDecisionFilter((prevState) => [...prevState, decisionValue]);
    }
  };

  const onStatusChange = async (status) => {
    const data = {
      id,
      userID,
      status: status,
    };
    dispatch(loadingActions.start());
    const response = await PolicyServices.updateComparisonWorkflowStatus(data);
    dispatch(loadingActions.end());
    if (response["status"] === 200) {
      setComparisonStatus((prevState) => ({ ...prevState, v_STATUS: status }));
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  const _renderDialogs = () => {
    if (!currentOpenDialog) return <></>;
    const payload = {
      i_POLICYCOMPARISON_ID: id ? Number(id) : null,
      i_USER_ID: userID ? Number(userID) : null,
      i_POLICY_ID: null,
      i_COMPARISON_ID: id ? Number(id) : null,
    };
    switch (currentOpenDialog) {
      case "viewNotes":
        return <ViewNotesDialog modal={modalOptions} data={payload} />;
      case "viewActions":
        return <ViewActionsDialog modal={modalOptions} data={payload} />;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {_renderDialogs()}
      <Box>
        <TableContainer
          component={Paper}
          className={classNames(classes.TableBorder)}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ padding: "10px", verticalAlign: "middle" }}>
                  <Grid
                    container
                    style={{
                      fontWeight: "500",
                      cursor: "pointer",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                      container
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "20px",
                      }}
                    >
                      <Grid
                        item
                        md={5}
                        xs={9}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Box
                          className={classes.actionWrap}
                          style={{
                            // backgroundColor: "#124571",
                            margin: "0 15px",
                            cursor: "pointer",
                          }}
                          onClick={handleMenuClick}
                        >
                          <WidgetsIcon
                            style={{
                              color: "#fff",
                              fontSize: "18px",
                              margin: "0 auto",
                            }}
                          />
                        </Box>
                        <CompareDetailMenu
                          anchorEl={anchorMenuEl}
                          open={openMenu}
                          handleClose={handleMenuClose}
                          comparisonId={id}
                          setCurrentOpenDialog={setCurrentOpenDialog}
                          currentOpenDialog={currentOpenDialog}
                        />
                        <Typography className={classes.labelText}>
                          {langConstant.COMPARISON_NAME} :{" "}
                          {comparisonDetail["COMPARISON_NAME"]}
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={6}>
                        <Typography className={classes.labelText}>
                          {langConstant.BASE_POLICY} :{" "}
                          {basePolicyInfo["v_base_aliasname"]}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={6}>
                        <Typography className={classes.labelText}>
                          {langConstant.TARGET_POLICY} :{" "}
                          {targetPolicyInfo["v_target_aliasname"]}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      container
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid
                        item
                        md={7}
                        xs={6}
                        container
                        className={classes.flex123}
                      >
                        <Grid item md={1} xs={1}>
                          <Tooltip title={langConstant.CHANGE_STATUS}>
                            <Box
                              className={classes.actionWrap}
                              style={{
                                // backgroundColor: "#124571",
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                              onClick={handleFilterClick}
                            >
                              <TuneIcon className={classes.actionButton} />
                            </Box>
                          </Tooltip>
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={filterAnchorEl}
                            open={openFilter}
                            onClose={handleFilterClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            {decisionDataList.map((item, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  onClick={() =>
                                    applyDecisionFilter(item["value"])
                                  }
                                >
                                  <Grid>
                                    <Typography
                                      style={{
                                        display: "inline",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item["label"]}
                                    </Typography>
                                    <Checkbox
                                      value={item["value"]}
                                      checked={decisionFilter.includes(
                                        item["value"]
                                      )}
                                      sx={{
                                        "&.Mui-checked": {
                                          color: "#124571",
                                        },
                                        "& .MuiSvgIcon-root": { fontSize: 20 },
                                      }}
                                    />
                                  </Grid>
                                </MenuItem>
                              );
                            })}
                          </Menu>
                        </Grid>
                        {decisionDataList.map((item, index) => {
                          return (
                            <Grid
                              item
                              key={index}
                              md={2}
                              xs={2}
                              style={{ textAlign: "left" }}
                            >
                              <Tooltip title={item.label}>
                                <span
                                  style={{
                                    backgroundColor: item["color"],
                                    padding: "3px",
                                    borderRadius: "4px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {item["count"]}
                                </span>
                              </Tooltip>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item md={5} xs={6} container>
                        <Grid item md={6} xs={6} container>
                          <Grid item md={7} xs={6} className={classes.flex123}>
                            <Tooltip title={langConstant.CHANGE_STATUS}>
                              <Box
                                className={classes.actionWrap}
                                style={{
                                  // backgroundColor: "#124571",
                                  marginRight: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={handleClick}
                              >
                                <AutorenewIcon
                                  className={classes.actionButton}
                                />
                              </Box>
                            </Tooltip>
                            <Typography
                              style={{ display: "inline", fontWeight: "500" }}
                            >
                              {langConstant.STATUS}:{" "}
                            </Typography>
                            <Typography
                              style={{
                                display: "inline",
                                fontWeight: "500",
                                color: "#124571",
                                padding: "0px 8px",
                              }}
                            >
                              {comparisonStatus["v_STATUS"]}
                            </Typography>
                            <Menu
                              id="demo-positioned-menu"
                              aria-labelledby="demo-positioned-button"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              {statusOptions.map((item, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    style={{ fontWeight: "bold", fontSize: 13 }}
                                    onClick={() => {
                                      handleClose();
                                      onStatusChange(item);
                                    }}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                          </Grid>
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Tooltip title={langConstant.PROGRESS}>
                            <Typography
                              style={{
                                fontWeight: "500",
                                color: "#0b7604",
                                paddingLeft: "5px",
                              }}
                            >
                              {" "}
                              {Math.round(
                                parseFloat(comparisonStatus["PROGRESS"])
                              )}
                              % {langConstant.COMPLETED_SM}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <IconButton
          className={classes.filterButton}
          onClick={() => setOpenDrawer(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <FilterDrawer
          setShow={setOpenDrawer}
          show={openDrawer}
          autoDecisionScore={autoDecisionScore}
          setAutoDecisionScore={setAutoDecisionScore}
          handleVectorsChange={handleVectorsChange}
          handleSentenceChange={handleSentenceChange}
          vectorsFilter={vectorsFilter}
          sentenceFilter={sentenceFilter}
          comparisonDetail={comparisonDetail}
          autoDecisionData={autoDecisionData}
          thresholdvalue={thresholdvalue}
          setDecisionChangesFlag={setDecisionChangesFlag}
          decisionChangesFlag={decisionChangesFlag}
        />
        <FileComparisonComponent
          basePolicyText={basePolicyText}
          targetPolicyText={targetPolicyText}
          basePolicyInfo={basePolicyInfo}
          targetPolicyInfo={targetPolicyInfo}
          vectorsFilter={vectorsFilter}
          sentenceFilter={sentenceFilter}
          decisionScore={decisionScore}
          setDecisionScore={setDecisionScore}
          decisionFilter={decisionFilter}
          setComparisonStatus={setComparisonStatus}
          comparisonId={id}
          chatBotAvailibility={chatBotAvailibility}
          setChatBotAvailibility={setChatBotAvailibility}
          setSelectedBaseRows={setSelectedBaseRows}
          selectedBaseRows={selectedBaseRows}
          actionList={actionList}
          setActionList={setActionList}
        />
      </Box>
    </React.Fragment>
  );
};

export default CompareTabPanel;
