import React from "react";
import { components } from "react-select";
import PropTypes from "prop-types";
import useStyles from "../styles";

const OptionComponent = (props) => {
  const classes = useStyles();

  return (
    <div>
      <components.Option {...props} className={classes.optionsStyle}>
        <label className={classes.labelStyle}>{props.label}</label>
        <input
          className={classes.checkboxStyle}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ accentColor: "#124571" }}
        />
      </components.Option>
    </div>
  );
};

OptionComponent.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  data: PropTypes.object,
  recordCount: PropTypes.number,
};

export default OptionComponent;
