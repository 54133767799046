import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "./constants";
import DropZone from "./dropzone";
import Component from "./component";
import PropTypes from "prop-types";
import useStyles from "../styles";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

const style = {};
const Column = ({ data, components, handleDrop, path }) => {
  const ref = useRef(null);
  const classes = useStyles();
  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderComponent = (component, currentPath, columnID) => {
    return (
      <Component
        key={component.id}
        data={component}
        components={components}
        path={currentPath}
        columnID={columnID}
      />
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
      }}
    >
      {data.children.map((component, index) => {
        const currentPath = `${path}-${index}`;

        return (
          <Box key={component.id} sx={{ height: "100%" }}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
              }}
              onDrop={handleDrop}
              className={classes.verticalDrag}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#ffffff",
                height: "calc(100% - 20px)",
              }}
              className={
                expandedPanelID == component.id ? classes.expandView : ""
              }
            >
              {renderComponent(component, currentPath, data.id)}
            </Box>
          </Box>
        );
      })}
      <DropZone
        data={{
          path: `${path}-${data.children.length}`,
          childrenCount: data.children.length,
        }}
        onDrop={handleDrop}
        isLast
        className={classes.verticalDrag}
      />
    </Box>
  );
};

Column.propTypes = {
  data: PropTypes.any,
  components: PropTypes.any,
  handleDrop: PropTypes.any,
  path: PropTypes.any,
};

export default Column;
