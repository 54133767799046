import { AuditActionConstants } from "./constants";

const setAuditActionList = (payload) => ({
  type: AuditActionConstants.SET_AUDIT_ACTION_LIST,
  payload,
});

const setActionListDetails = (payload) => ({
  type: AuditActionConstants.SET_ACTION_LIST_DETAILS,
  payload,
});

const setUsersList = (payload) => ({
  type: AuditActionConstants.SET_USERS_LIST,
  payload,
});

export { setAuditActionList, setActionListDetails, setUsersList };
