// OP#2994 - UJA10 - UI Development : Manage Evidence Types - Part 1 only
// Main index file for Configuration-Management list

import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";
import TabPanel from "./MenuItems/components/TabPanel";
import MenuItems from "./MenuItems/MenuItems";

const ConfigurationManagement = () => {
  const [value, setValue] = useState(5);

  const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isTabDisabled = (label) => {
    return label !== "Evidence Types";
  };
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: "divider",
            // overflowY: "scroll",
            // height: "100vh"
          }}
        >
          {MenuItems.map((el, i) => (
            <Tab
              label={el.label}
              {...a11yProps(i)}
              iconPosition="start"
              icon={el.icon}
              key={`${el.label}${i}`}
              style={{
                minHeight: "52px",
                paddingLeft: "0px",
                width: "100%",
                justifyContent: "flex-start",
              }}
              disabled={isTabDisabled(el.label)}
            />
          ))}
        </Tabs>

        {MenuItems.map((el, i) => (
          <TabPanel value={value} index={i} key={`${i}${el.label}`}>
            {el.comp}
          </TabPanel>
        ))}
      </Box>
    </>
  );
};

export default ConfigurationManagement;
