import React from "react";
import apiService from "_services/api.service";
import { alertActions, loadingActions } from "_actions";
import { setAuditQuestions, setAuditObservations, setAuditTrustScoreForAllQuestions } from "./action"


export const GetAssessmentsForAuditors = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetAssessmentsForAuditors", { i_USER_ID: userID })
    .then(apiService.handleResponse)
    .then((response) => {
      if (response) {
        return response
      }
    },
      (error) => {
        if (error.response) {
          return error.response
        }
      }
    );

}


export const GetAssessmentsForAuditorDetails = (submissionID, userID) => {

  return (dispatch) => {
    dispatch(loadingActions.start());

    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetAssessmentsForAuditorDetails", { i_SUBMISSION_ID: submissionID, i_USER_ID: userID })
      .then(apiService.handleResponse)
      .then((response) => {
        if (response) {
          const cisoControlArray = [...response["#result-set-8"]];
          const questionData = [...response["#result-set-1"]].map((el) => {
            let ciscoControl = cisoControlArray?.find((control) => `${el?.QUESTION_ID?.trim()}_${el?.REGULATORS_QUESTION_REF?.trim()}` === `${control?.QUESTION_ID?.trim()}_${control?.REGULATORS_QUESTION_REF?.trim()}`);
            if (ciscoControl !== undefined || ciscoControl !== null) {
              return { ...el, CISO_CONTROL_NAME: ciscoControl.CISO_CONTROL_NAME }
            } else {
              return { ...el, CISO_CONTROL_NAME: null }
            }
          });

          dispatch(setAuditQuestions(questionData));
          dispatch({ type: "GET_RFI_FILTER", payload: response["#result-set-4"] });
          dispatch(setAuditObservations(response["#result-set-6"]));
          dispatch(setAuditTrustScoreForAllQuestions(response["#result-set-7"]));
          dispatch(loadingActions.end());
          return response
        }
      },
        (error) => {
          if (error.response) {
            dispatch(loadingActions.end());
            return error.response
          }
        }
      );
  }
}