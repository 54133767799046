/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  Fab
} from "@material-ui/core";

import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import SaveIcon from "@material-ui/icons/Save";
import langConstant from "_lang";
import rootStyles from "rootStyles";
import CompeteSqm from "./component/steps/CompleteSqm";
import ProgressBarLarge from "_components/ProgressBarLarge";
import ConfirmationStep from "./component/steps/ConfirmationStep";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styles from "./styles";
import initiateAssessmentAction from "./actions";
import constants from "./constants";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: theme.shadows[1],
  },
}));
const InitiateSupplierAssessment = () => {
  const classes = styles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isOneOfChecked, setIsOneOfChecked] = React.useState(false);
  const [list, setList] = React.useState([]);
  const sqmAssesmentData = useSelector((state) => state.supplierQuestionnaireAssessmentDetails);
  const narrative = sqmAssesmentData.narrative;
  const dataAssetsList = sqmAssesmentData.dataAssetsList;
  const supplierQuestionAssetList = sqmAssesmentData.supplierQuestionList;
  const questionList = sqmAssesmentData.questionList;
  const [resolvedPercentage, setresolvedPercentage] = useState(0);
  const history = useHistory();
  const { id, rid } = useParams();
  const dispatch = useDispatch();
  const steps = [
    langConstant.COMPLETE_SQM,
    langConstant.CONFIRMATION,
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    saveToDraft(false)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const goToSQMAssessment = () => {
    history.push("/supplier-questionnaire");
  };
  const updateAssignedStatus = () => {
    const assignedByUserID = questionList[0].assessmentObj[0].USER_ID;
    dispatch(
      initiateAssessmentAction.updateAssignedStatus(
        id,
        rid,
        assignedByUserID,
        "PENDING_FOR_REVIEW",
        goToSQMAssessment
      )
    );
  };
 

  const getPercentage = (data) => {
    const total = data.length;
    const resolved = _.filter(data, { status: 'RESOLVED' });
    if (resolved.length > 0) {
      let count = (resolved.length * 100) / total;
      count =
        count === 100 || count === 0 ? count : parseFloat(count.toFixed(2));
      return count;
    } else {
      return 0;
    }
  };
  const saveToDraft = (draftButton) => {
    if(dataAssetsList?.length > 0){
    dispatch(initiateAssessmentAction.putSupplierQuestionResponse(draftButton, dataAssetsList, narrative));
    } else{
    dispatch(initiateAssessmentAction.updateSupplierQuestionResponse(draftButton, supplierQuestionAssetList, narrative));
    }
  };
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <CompeteSqm questionList={questionList} onPageChange={saveToDraft} />
        );
      default:
        return <ConfirmationStep questionList={questionList} />;
    }
  };
  const goBackOnError = () => {
    history.goBack();
  };
  useEffect(() => {
    if (history.location.state) {
      const status = history.location.state;
      dispatch(
        initiateAssessmentAction.checkAssignmentStatus(
          id,
          status,
          goBackOnError
        )
      );
    } else {
      goBackOnError();
    }
    return dispatch({
      type: constants.initiateAssessmentConstant_EMPTY,
    });
  }, []);
  useEffect(() => {
    if (questionList && questionList.length > 0) {
      setList(questionList);
      setresolvedPercentage(getPercentage(questionList));
    }
  }, [questionList]);
  if (list.length == 0) {
    return <></>;
  }
  return (
    <Box>
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className="custom-stepper"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep == 0 && <ProgressBarLarge percentage={resolvedPercentage} />}
          <Box mb={8} mt={1}>{getStepContent(activeStep)}</Box>
          <Box className="fixed-action-bar" p={2} >
            <Grid container alignItems="center" spacing={2}>
              <Grid item style={{ width: "80px" }}></Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={resolvedPercentage != '100'}
                  startIcon={<SubdirectoryArrowRightIcon />}
                  onClick={activeStep == 1 ? updateAssignedStatus : handleNext}
                >
                  {activeStep == 1
                    ? langConstant.SEND_FOR_REVIEW
                    : langConstant.CONTINUE}
                </Button>
              </Grid>
              <Grid item>
                {activeStep < 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={() => saveToDraft(true)}
                  >
                    Save As Draft
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="small"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  {langConstant.GO_BACK}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default InitiateSupplierAssessment;
