import React from "react";
import propTypes from "prop-types";
import { AddEditActionTabMenu } from "_components/ActionList/AddEditActionTabMenu";
import { linkTypes } from "containers/cyber-assessment/AuditActions/constants";
import { useDispatch, useSelector } from "react-redux";

export const AddActionDialog = ({ addActionModal }) => {
  const dispatch = useDispatch();
  const actionList = useSelector(
    (state) => state.MyAssessmnetReducer.ActionList
  );
  const linkedId = addActionModal?.data?.SUBMISSION_ID; // using SUBMISSION_ID for assesments

  const setActionList = (data) => {
    dispatch({ type: "SET_ACTION_LIST", payload: { ...actionList, ...data } });
  };

  return (
    <React.Fragment>
      {addActionModal?.isOpen && (
        <AddEditActionTabMenu
          modal={addActionModal}
          linkedId={linkedId?.toString()}
          actionListType={linkTypes[0]}
          actionList={actionList}
          setActionList={setActionList}
        />
      )}
    </React.Fragment>
  );
};

AddActionDialog.propTypes = {
  addActionModal: propTypes.object,
};
