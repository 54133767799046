/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Box,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import langConstant from "_lang";
import AppPagination from "_components/AppPagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import MailIcon from "@material-ui/icons/Mail";
import { makeStyles } from "@material-ui/core";
import FilterDropdown from "_components/FilterDropdown";
import _ from "lodash";
const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },

  statusIcon: {
    fontSize: 20,
    margin: "0px 5px 0px 0px",
  },
  heading: {
    textTransform: "uppercase",
  },
}));

const RfiFilterTable = (props) => {
  const classes = useStyles();
  const rows = props.rows;

  const columns = props.columns;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(calculatePageCount(rows));
  const emptyRows =
    rowsPerPage -
    Math.round(Math.ceil(rowsPerPage, rows.length - page * rowsPerPage));

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filteredRows, setFilteredRows] = useState([]);
  const initialFilterObj = { STATUS: "" };
  const [filterBy, setFilterBy] = useState(initialFilterObj);
  const [searchText, setSearchText] = useState("");

  const onFilterChange = (fieldName, e) => {
    const filterValue = e.target.value;

    const filterObj = { ...filterBy, [fieldName]: filterValue };
    setSearchText("");
    setFilterBy(filterObj);

    let filtered_array = rows.filter((item) => {
      if (filterObj["STATUS"]) {
        return filterObj["STATUS"] == item["STATUS"];
      } else {
        return true;
      }
    });

    setFilteredRows(filtered_array);
    setPageCount(calculatePageCount(filtered_array));
    setPage(0);
  };
  const resetFilter = () => {
    setFilterBy(initialFilterObj);
    setSearchText("");
    setFilteredRows(rows);
    setPageCount(calculatePageCount(rows));
    setPage(0);
  };

  useEffect(() => {
    const updateRows =
      rows.length > 0
        ? rows.map((item) => {
            let data = item;
            data["Status_label"] = item.STATUS ? (
              <span className={item.RFI_STATUS + " rfi-status"}>
                {item.RFI_STATUS == "RFI_SENT" ? (
                  <MailIcon
                    className={classes.statusIcon}
                    style={{
                      color: "#105BA1",
                    }}
                  />
                ) : (
                  ""
                )}

                {item.RFI_STATUS == "RFI_PENDING" ? (
                  <HelpOutlineIcon
                    className={classes.statusIcon}
                    style={{
                      color: "#895514",
                    }}
                  />
                ) : (
                  ""
                )}
                {item.RFI_STATUS == "RFI_OVERDUE" ? (
                  <ReportProblemIcon
                    className={classes.statusIcon}
                    style={{
                      color: "#d62000",
                    }}
                  />
                ) : (
                  ""
                )}
                {item.RFI_STATUS == "RFI_RESOLVED" ? (
                  <CheckOutlinedIcon
                    className={classes.statusIcon}
                    style={{
                      color: "#1a751a",
                    }}
                  />
                ) : (
                  ""
                )}
                {item.STATUS}
              </span>
            ) : (
              item.STATUS
            );

            return data;
          })
        : [];

    setFilteredRows(updateRows);
    setPageCount(calculatePageCount(updateRows));
  }, [rows]);

  const createFilterDropdown = (fieldName, label) => {
    let options = _.reject(_.uniq(_.map(rows, fieldName)).sort(), _.isNull);
    // let options0 = _.reject(_.uniq(_.map(rows, fieldName)).sort(), _.isNull);
    // let options1 = _.uniq(_.map(rows, fieldName)).sort();
    // let options2 = _.uniq(_.map(rows, fieldName));
    // let options3 = _.map(rows, fieldName);

    if (options.length > 0 && options[0]) {
      options = options.map((item) => {
        return { value: item, name: item };
      });
    }
    if (options.length > 0) {
      return (
        <FilterDropdown
          options={options}
          label={label}
          fieldName={fieldName}
          value={filterBy[fieldName]}
          onChange={onFilterChange}
          icon={true}
        />
      );
    }
  };

  return (
    <div>
      <Box mb={2} mt={3}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={10}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                {createFilterDropdown("STATUS", "Filter by Status")}
              </Grid>
              <Grid item xs={12} md={2}>
                {" "}
                <Box mx={1} style={{ marginTop: "10px" }}>
                  {filterBy["STATUS"] && (
                    <span onClick={resetFilter} className="linkIcon">
                      Reset All
                    </span>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Box>
      <TableContainer
        component={Paper}
        style={{
          border: "1px solid rgba(0,0,0,0.1)",
          borderBottomStyle: "none",
          borderTopStyle: "solid",
          borderRightStyle: "solid",
          borderLeftStyle: "solid",
        }}
      >
        <Table className={props.className} aria-label="Regulation List">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={
                    (item.sticky && classes.stickyColl) + " " + classes.heading
                  }
                  style={{ minWidth: item.minWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, index) => (
              <TableRow key={index + row[columns[0].field]}>
                {columns.map((column, index) => {
                  //const Comp = column.component && column.component
                  const value = row[column.field];

                  return (
                    <TableCell
                      component="td"
                      scope="row"
                      key={"column_" + index + row[columns[0].field]}
                      className={column.sticky && classes.stickyColl}
                      style={{
                        wordBreak: "break-word",
                        wordWrap: "break-word",
                      }}
                    >
                      {column.format ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {filteredRows.length == 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      )}
    </div>
  );
};

RfiFilterTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default RfiFilterTable;
