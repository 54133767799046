import React from "react";
import PropTypes from "prop-types";
import { ListItemIcon, Menu, MenuItem, MenuList, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BarChartIcon from "@mui/icons-material/BarChart";
import InsightsIcon from "@mui/icons-material/Insights";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import langConstant from "_lang";
import { Box, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { downloadFile } from "_helpers/utils";

const useStyles = makeStyles(() => ({
  contantContaxMenu: {
    padding: "5px 0px 0px 0px",
    "& .MuiListItemIcon-root": {
      minWidth: "unset !important",
    },
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
}));

const ActionMenu = ({
  anchorMenuEl,
  handleMenuClose,
  openMenu,
  selectedComparison,
  getComparisonMetaDataInfo,
}) => {
  const classes = useStyles();
  const navigationDetails = useSelector(
    (state) => state.authentication.detailedInfo.NAVIGATION_DETAILS
  );
  const isComparisonGraphViewAvail = navigationDetails?.some(
    (el) => el.ENTITLEMENT_NAME === "POLICY_MANAGEMENT/COMPARISON_GRAPH"
  );

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  return (
    <Menu
      anchorEl={anchorMenuEl}
      id="account-menu"
      open={openMenu}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuList
        style={{
          textAlign: "center",
          justifyContent: "space-between",
          display: "flex",
          padding: "0px 0px",
          outline: "0",
        }}
        className={classes.contantContaxMenu}
      >
        <Tooltip title={langConstant.COMPARISON_RECIPE}>
          <MenuItem>
            <ListItemIcon>
              <Link
                to={`comparison-insights/${selectedComparison.POLICYCOMPARISONID}`}
                className="linkIcon"
              >
                <Box
                  className={classes.actionWrap}
                  style={{ backgroundColor: "#eaa327", marginRight: "5px" }}
                >
                  <InsightsIcon
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      margin: "0 auto",
                    }}
                  />
                </Box>
              </Link>
            </ListItemIcon>
          </MenuItem>
        </Tooltip>
        {isComparisonGraphViewAvail && (
          <Tooltip title={langConstant.VIEW}>
            <MenuItem>
              <ListItemIcon>
                <Link
                  to={`comparison-chart/${selectedComparison.POLICYCOMPARISONID}`}
                  className="linkIcon"
                >
                  <Box
                    className={classes.actionWrap}
                    style={{ backgroundColor: "#007bff", marginRight: "5px" }}
                  >
                    <BarChartIcon
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        margin: "0 auto",
                      }}
                    />
                  </Box>
                </Link>
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        )}
        <Tooltip title={langConstant.COMPARISON_ANALYSIS}>
          <MenuItem>
            <ListItemIcon>
              <Link
                to={`policy-comparison/${selectedComparison.POLICYCOMPARISONID}`}
                className="linkIcon"
              >
                <Box
                  className={classes.actionWrap}
                  style={{ backgroundColor: "#0e9339", marginRight: "5px" }}
                >
                  <VisibilityIcon
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      margin: "0 auto",
                    }}
                  />
                </Box>
              </Link>
            </ListItemIcon>
          </MenuItem>
        </Tooltip>
        <Tooltip title={langConstant.COMPARISON_META_DATA}>
          <MenuItem>
            <ListItemIcon
              onClick={() => getComparisonMetaDataInfo(selectedComparison)}
            >
              <Box
                className={classes.actionWrap}
                style={{ backgroundColor: "#c13636", marginRight: "5px" }}
              >
                <InfoOutlinedIcon
                  style={{ color: "#fff", fontSize: "18px", margin: "0 auto" }}
                />
              </Box>
            </ListItemIcon>
          </MenuItem>
        </Tooltip>
        <Tooltip title={langConstant.COMPARISON_FILE_NAME}>
          <MenuItem>
            <ListItemIcon>
              <Box
                className={classes.actionWrap}
                style={{ backgroundColor: "#007bff", marginRight: "5px" }}
              >
                <DescriptionIcon
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    margin: "0 auto",
                  }}
                  onClick={() =>
                    downloadFile(
                      selectedComparison.STORAGE_FOLDER_URL,
                      selectedComparison.COMPARISON_FILE_NAME,
                      docStorageType,
                      docStorageIP,
                      docStorageURL
                    )
                  }
                />
              </Box>
            </ListItemIcon>
          </MenuItem>
        </Tooltip>
      </MenuList>
    </Menu>
  );
};

ActionMenu.propTypes = {
  anchorMenuEl: PropTypes.any,
  handleMenuClose: PropTypes.func,
  openMenu: PropTypes.bool,
  selectedComparison: PropTypes.object,
  getComparisonMetaDataInfo: PropTypes.func,
};

export default ActionMenu;
