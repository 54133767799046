import { Grid, IconButton, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ClassificationServices from "../services";
import { useState } from "react";
import ClassificationUtils from "../utils";
import TableComponent from "./TableComponent";
import AddBoxIcon from "@mui/icons-material/AddBox";
import QuestionDialog from "./QuestionDialog";
import useStyles from "../styles";
import langConstant from "_lang";

const QuestionClassification = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [questionClassification, setQuestionClassification] = useState(null);
  const [questionDialog, setQuestionDialog] = useState(false);
  const [selectedQueClassification, setSelectedQueClassification] =
    useState(null);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(
        ClassificationServices.getCustomerQuestionClassifications(
          selectedCustomer.value,
          setQuestionClassification
        )
      );
    }
  }, [selectedCustomer]);

  return (
    <Grid className={classes.classificationFormWrapper}>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className={classes.sectionHeader}>
          {langConstant.QUESTION_CLASSIFICATION}
        </Typography>
        {selectedCustomer && selectedCustomer.value && (
          <IconButton
            type="button"
            color="primary"
            onClick={() => setQuestionDialog(true)}
          >
            <AddBoxIcon />
          </IconButton>
        )}
      </Grid>
      {selectedCustomer && selectedCustomer.value ? (
        <TableComponent
          columns={ClassificationUtils.QuestionClassificationColumns}
          rows={questionClassification}
          setSelectedRow={setSelectedQueClassification}
          setDialog={setQuestionDialog}
        />
      ) : (
        <Typography className={classes.selectCustText}>
          {langConstant.PLEASE_SELECT_CUSTOMER_FIRST}
        </Typography>
      )}
      <QuestionDialog
        open={questionDialog}
        setOpen={setQuestionDialog}
        selectedQueClassification={selectedQueClassification}
        questionClassification={questionClassification}
        setQuestionClassification={setQuestionClassification}
        selectedCustomer={selectedCustomer}
        setSelectedQueClassification={setSelectedQueClassification}
      />
    </Grid>
  );
};

QuestionClassification.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default QuestionClassification;
