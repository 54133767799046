// OP#2883 - UJA11 & UJA12 - UI Development : Manage Regulators & Regulations List
// actions for Regulators

import constant from "./constants";

const getRegulators = (data) => ({
    type: constant.GET_AVAILABLE_REGULATORS,
    payload: data
})

const updateRegulatorData = (data) => ({
    type: constant.UPDATE_REGULATOR,
    payload: data
})

const addRegulatorData = (data) => ({
    type: constant.ADD_REGULATOR,
    payload: data
})

export default {
    getRegulators,
    updateRegulatorData,
    addRegulatorData,
}