/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState } from "react";
import LoupeIcon from "@material-ui/icons/Loupe";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import rootStyles from "rootStyles";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import individualQuestionAction from "../actions";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  MenuItem,
  Switch,
  IconButton,
} from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import langConstant from "_lang";
import { alertActions } from "_actions";
import { Formik, Field, Form, FieldArray } from "formik";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const EvidenceListTab = ({
  qid,
  getEvidenceTypes,
  getEvidenceList,
  evidenceList,
  evidenceTypesState,
  callUpdateEvidenceMapping,
  callPutEvidenceMapping,
  callAlertAction,
  fetchdata
}) => {
  const rootClasses = rootStyles();

  useEffect(() => {
    getEvidenceTypes();
    getEvidenceList();
  }, []);

  var initialValues = {
    evidenceTypes: evidenceTypesState,
  };

  return (
    <Box>
      <Typography
        style={{
          margin: "20px 0",
          fontWeight: "bold",
        }}
      >
        {langConstant.ADD_EVIDENCE_LIST_THAT_NEEDS_TO_BE_UPLOADED}
      </Typography>

      <Formik
        enableReinitialize
        // validationSchema={ControlSchema}
        initialValues={initialValues}
        // validate={() => ({ foo: true })}
        onSubmit={(values) => {
          let evidenceTypesAdded = [];
          let evidenceTypesRemoved = [];
          let evidenceTypesRemaining = [];
          let evidenceTypesRemainingChanged = [];
          let evidenceTypesSelected = true;
          let evidenceTypesWeightsAdded = true;
          let evidenceWeightsAddTo100 = true;

          // Selecting only unique evidence Types
          const uniqueEvidenceTypes = _.uniqBy(
            values.evidenceTypes,
            "EVIDENCE_TYPE_ID"
          );

          // Throw error if duplicates found
          if (uniqueEvidenceTypes.length != values.evidenceTypes.length) {
            callAlertAction(
              "error",
              langConstant.DUPLICATE_EVIDENCE_TYPES_FOUND
            );
            return;
          }

          // Check if all evidence rows selected
          uniqueEvidenceTypes.forEach((evidenceType) => {
            if (evidenceType.EVIDENCE_TYPE_ID == "select") {
              evidenceTypesSelected = false;
            }
          });

          // Throw error if evidence types not selected
          if (!evidenceTypesSelected) {
            callAlertAction("error", langConstant.PLEASE_SELECT_EVIDENCE_TYPES);
            return;
          }

          // Check if weights added to all evidences
          values.evidenceTypes.forEach((evidence) => {
            if (
              evidence.EVIDENCE_WEIGHTAGE <= 0 ||
              evidence.EVIDENCE_WEIGHTAGE <= null
            ) {
              evidenceTypesWeightsAdded = false;
            }
          });

          // Throw error if evidences don't have weights
          if (!evidenceTypesWeightsAdded) {
            callAlertAction(
              "error",
              langConstant.PLEASE_SELECT_EVIDENCE_WEIGHTS
            );
            return;
          }

          // Check if all weights add up to 100
          let total = 0;
          values.evidenceTypes.forEach((evidence) => {
            total = total + evidence.EVIDENCE_WEIGHTAGE;
          });

          // Throw error if weights dont add to 100
          if (total > 100) {
            callAlertAction(
              "error",
              langConstant.EVIDENCE_WEIGHTS_MORE_THAN_100
            );

            return;
          } else if (total < 100) {
            callAlertAction(
              "error",
              langConstant.EVIDENCE_WEIGHTS_LESS_THAN_100
            );

            return;
          }

          // Finding the evidence types added
          evidenceTypesAdded = uniqueEvidenceTypes.filter((evidenceType) => {
            let added = true;
            evidenceTypesState.forEach((evidenceTypeState) => {
              if (
                evidenceTypeState.EVIDENCE_TYPE_ID ==
                evidenceType.EVIDENCE_TYPE_ID
              ) {
                added = false;
              }
            });
            return added;
          });

          // Finding the evidence types Removed
          evidenceTypesRemoved = evidenceTypesState.filter(
            (evidenceTypeState) => {
              let removed = true;
              uniqueEvidenceTypes.forEach((evidenceType) => {
                if (
                  evidenceTypeState.EVIDENCE_TYPE_ID ==
                  evidenceType.EVIDENCE_TYPE_ID
                ) {
                  removed = false;
                }
              });
              return removed;
            }
          );

          // Finding Evidence Types remaining
          evidenceTypesRemainingChanged = uniqueEvidenceTypes.filter(
            (evidenceType) => {
              let changed = false;
              evidenceTypesState.forEach((evidenceTypeState) => {
                if (
                  evidenceTypeState.EVIDENCE_TYPE_ID ==
                  evidenceType.EVIDENCE_TYPE_ID
                ) {
                  if (
                    evidenceTypeState.EVIDENCE_WEIGHTAGE !=
                    evidenceType.EVIDENCE_WEIGHTAGE ||
                    evidenceTypeState.MANDATORY != evidenceType.MANDATORY
                  ) {
                    changed = true;
                  }
                }
              });
              return changed;
            }
          );

          // Making API calls to Add Evidence Types
          evidenceTypesAdded.forEach((evidence) => {
            callPutEvidenceMapping(evidence);
          });

          // Making API calls to Remove Evidence Types
          evidenceTypesRemoved.forEach((evidence) => {
            callUpdateEvidenceMapping(evidence, 0);
          });

          // Making API calls to Update Remaining Evidence Types
          evidenceTypesRemainingChanged.forEach((evidence) => {
            callUpdateEvidenceMapping(evidence, 1);
          });

          // Call Evidence Types API
          setTimeout(function () {
            fetchdata()
            getEvidenceTypes();
          }, 2000);
        }}
        // validator={() => ({})}
        render={({
          values,
          errors,
          touched,
          handleReset,
          handleChange,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Box style={{ display: "flex" }}>
                {/* Evidence Types Input starts here------------------ */}
                <Box style={{ marginRight: "50px" }}>
                  <FieldArray
                    name="evidenceTypes"
                    render={({ insert, remove, push }) => (
                      <Box>
                        {values.evidenceTypes.length > 0
                          ? values.evidenceTypes.map((item, index) => (
                            <Box
                              key={index}
                              display="flex"
                              alignItems="center"
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* Evidence Type Dropdown */}
                                <Box
                                  style={{
                                    position: "relative",
                                    width: "300px",
                                  }}
                                >
                                  <TextField
                                    name={`evidenceTypes.${index}.EVIDENCE_TYPE_ID`}
                                    placeholder="Select"
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    value={
                                      values.evidenceTypes[index]
                                        .EVIDENCE_TYPE_ID
                                    }
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                  >
                                    <MenuItem key={1} value="select">
                                      {langConstant.SELECT_TYPE}
                                    </MenuItem>

                                    {evidenceList.length > 0 && evidenceList.map((evidenceItem) => (
                                      <MenuItem
                                        key={
                                          evidenceItem.EVIDENCE_TYPE_ID
                                        }
                                        value={
                                          evidenceItem.EVIDENCE_TYPE_ID
                                        }
                                      >
                                        {evidenceItem.EVIDENCE_SHORTNAME}
                                      </MenuItem>
                                    ))
                                    }
                                  </TextField>

                                  {errors.evidenceTypes &&
                                    errors.evidenceTypes[index] &&
                                    errors.evidenceTypes[index]
                                      .EVIDENCE_TYPE_ID &&
                                    touched.evidenceTypes &&
                                    touched.evidenceTypes[index]
                                      .EVIDENCE_TYPE_ID && (
                                      <div className="field-error">
                                        {
                                          errors.evidenceTypes[index]
                                            .EVIDENCE_TYPE_ID
                                        }
                                      </div>
                                    )}
                                </Box>

                                {/* Switch */}
                                <Box
                                  style={{
                                    marginLeft: "50px",
                                    width: "230px",
                                    display: "flex",
                                  }}
                                >
                                  <IOSSwitch
                                    name={`evidenceTypes.${index}.MANDATORY`}
                                    value={
                                      values.evidenceTypes[index].MANDATORY
                                    }
                                    checked={
                                      values.evidenceTypes[index].MANDATORY ==
                                      true
                                    }
                                    onChange={(event, checked) => {
                                      setFieldValue(
                                        `evidenceTypes.${index}.MANDATORY`,
                                        checked ? true : false
                                      );
                                    }}
                                  />
                                  <Box style={{ marginLeft: "20px" }}>
                                    {values.evidenceTypes[index].MANDATORY
                                      ? "MANDATORY"
                                      : "NOT MANDATORY"}
                                  </Box>
                                </Box>

                                {/* Weightage */}
                                <Box style={{ width: "100px" }}>
                                  <TextField
                                    variant="outlined"
                                    // fullWidth
                                    id="email"
                                    name={`evidenceTypes.${index}.EVIDENCE_WEIGHTAGE`}
                                    // label="Weightage"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          %
                                        </InputAdornment>
                                      ),
                                    }}
                                    value={
                                      values.evidenceTypes[index]
                                        .EVIDENCE_WEIGHTAGE
                                        ? values.evidenceTypes[index]
                                          .EVIDENCE_WEIGHTAGE
                                        : ""
                                    }
                                    type="number"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                  // error={touched.evidenceTypes.${index}.MANDATORY && Boolean(formik.errors.evidenceTypes.${index}.MANDATORY)}
                                  // helperText={formik.touched.evidenceTypes.${index}.MANDATORY && formik.errors.evidenceTypes.${index}.MANDATORY}
                                  />
                                </Box>
                              </Box>
                              {/* Delete Button */}
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "10px",
                                }}
                              >
                                <Button
                                  color="primary"
                                  type="button"
                                  className="secondary"
                                  style={{
                                    minWidth: "30px",
                                    lineHeight: "18px",
                                  }}
                                  onClick={() => remove(index)}
                                >
                                  <DeleteOutlineIcon
                                    style={{
                                      color: "#FF7070",
                                      fontSize: "28px",
                                    }}
                                  />
                                </Button>
                              </Box>
                            </Box>
                          ))
                          : langConstant.NO_EVIDENCE_MAPPED_FOR_QUESTION}
                        <Button
                          type="button"
                          color="primary"
                          // className="secondary"
                          onClick={() =>
                            push({
                              EVIDENCE_TYPE_ID: "select",
                              MANDATORY: false,
                              EVIDENCE_WEIGHTAGE: 0,
                            })
                          }
                          style={{
                            // color: "#007BFF",
                            fontWeight: "400",
                            fontSize: "14px",
                            margin: "20px 0 0 0px",
                            display: "flex",
                          }}
                        >
                          <LoupeIcon style={{ marginRight: "10px" }} />
                          {langConstant.ADD_EVIDENCE_TYPE}
                        </Button>
                      </Box>
                    )}
                  />
                </Box>
              </Box>

              <Box mt={3} display="flex" style={{}}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  // className={rootClasses.blueButton}
                  startIcon={<SaveOutlinedIcon />}
                >
                  {langConstant.SAVE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={(event) => {
                    event.preventDefault();
                    handleReset();
                  }}
                  style={{ color: "black", marginLeft: "30px" }}
                >
                  {langConstant.CANCEL}
                </Button>
              </Box>
            </Form>
          );
        }}
      />
    </Box>
  );
};

EvidenceListTab.propTypes = {
  questionResponses: PropTypes.array,
  qid: PropTypes.string,
  evidenceList: PropTypes.array,
  evidenceTypesState: PropTypes.array,
  getEvidenceTypes: PropTypes.func,
  getEvidenceList: PropTypes.func,
  callUpdateEvidenceMapping: PropTypes.func,
  callPutEvidenceMapping: PropTypes.func,
  callAlertAction: PropTypes.func,
  fetchdata:PropTypes.func
};

export default EvidenceListTab;
