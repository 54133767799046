import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CompleteAssessmentStep from "./steps/CompleteAssessments";
import ReviewAssessmentStep from "./steps/ReviewAssessmentStep";

const MasterAssessment = ({
  TopTabsArray = [],
  QuestionList,
  IsAudit = false,
  ReviewScreenStatus,
  observationList,
  trustScoreList,
  identifier,
  RfiitemsList = [],
  setPlanActionsDataLength,
}) => {
  const steps = useSelector((state) => state.stepper.steps);
  const activeStep = useSelector((state) => state.stepper.activestep);
  const getStepComponent = (stepid) => {
    switch (stepid.compID) {
      case 1:
        return (
          <CompleteAssessmentStep
            TopTabsArray={TopTabsArray}
            QuestionList={QuestionList}
            IsAudit={IsAudit}
            observationList={observationList}
            identifier={identifier}
            RfiitemsList={RfiitemsList}
            setPlanActionsDataLength={setPlanActionsDataLength}
          />
        );
      case 2:
        return (
          <ReviewAssessmentStep
            QuestionList={QuestionList}
            ReviewScreenStatus={ReviewScreenStatus}
            observationList={observationList}
            trustScoreList={trustScoreList}
            identifier={identifier}
            RfiitemsList={RfiitemsList}
          />
        );
      default:
        break;
    }
  };

  return getStepComponent(steps[activeStep]);
};

MasterAssessment.propTypes = {
  TopTabsArray: PropTypes.array,
  QuestionList: PropTypes.array,
  IsAudit: PropTypes.bool,
  ReviewScreenStatus: PropTypes.object,
  observationList: PropTypes.array,
  trustScoreList: PropTypes.array,
  RfiitemsList: PropTypes.array,
  setPlanActionsDataLength: PropTypes.func,
};

export default MasterAssessment;
