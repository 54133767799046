import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import NetworkGraph from "_components/NetworkGraph";
import langConstant from "_lang";
import styles from "../styles";
import { IconButton } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterDrawer from "./FilterDrawer";

const NetworkGraphData = () => {
  const classes = styles();
  const [networkData, setNetworkData] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Grid>
      <IconButton
        className={classes.filterButton}
        onClick={() => setOpenDrawer(true)}
      >
        <FilterAltIcon />
      </IconButton>
      {networkData && (
        <>
          {networkData.length > 0 ? (
            <NetworkGraph edges={edges} nodes={nodes} />
          ) : (
            <Grid>
              <Typography className={classes.noData}>
                {langConstant.NO_DATA_FOUND}
              </Typography>
            </Grid>
          )}
        </>
      )}
      <FilterDrawer
        show={openDrawer}
        setShow={setOpenDrawer}
        setNodes={setNodes}
        setEdges={setEdges}
        networkData={networkData}
        setNetworkData={setNetworkData}
      />
    </Grid>
  );
};

export default NetworkGraphData;
