import React from "react";
import langConstant from "_lang";

const suppliersColumns = [
  { field: "SUPPLIER_NAME", headerName: langConstant.SUPPLIERS },
  { field: "SUPPLIER_CATEGORY", headerName: langConstant.CATEGORY },
  { field: "CONTRACT_STATUS", headerName: langConstant.CONTRACT_STATUS },
];

const TPSRColumns = [
  { field: "SUPPLIER_NAME", headerName: langConstant.TPSR },
  { field: "REGULATION_SHORT_NAME", headerName: langConstant.ASSESSMENT },
  {
    field: "STATUS",
    headerName: langConstant.STATUS,
    format: function formatStatus(value) {
      return statusTitles[value["STATUS"]];
    },
  },
];

const expandedTPSRColumns = [
  ...TPSRColumns,
  {
    field: "ASSIGNED_TO",
    headerName: langConstant.ASSIGNED_TO,
    format: function formatAssignedTo(value) {
      return `${value["TEAM_NAME"]} - ${value["ASSIGNED_TO"]}`;
    },
  },
];

const statusTitles = {
  PENDING_FOR_REVIEW: langConstant.PENDING_FOR_REVIEW,
  ASSIGNED: langConstant.ASSIGNED,
  IN_PROGRESS: langConstant.IN_PROGRESS,
};

export default {
  suppliersColumns,
  TPSRColumns,
  expandedTPSRColumns,
  statusTitles,
};
