import { Box, Avatar, Chip } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import propTypes from "prop-types";
import React from "react";

export const OptionLabelTemplate = ({ user, isCurrentUser }) => {
  if (!user) return <></>;
  return (
    <Box display="flex" alignItems="center" style={{ gap: 8 }}>
      <Avatar
        style={{ width: 24, height: 24 }}
        alt="user_avatar"
        src={
          user?.AVATAR_IMAGE &&
          "data:image/png;base64," + window.atob(user?.AVATAR_IMAGE)
        }
      >
        <Person />
      </Avatar>

      {`${user?.FIRST_NAME} ${user?.LAST_NAME}`}

      {isCurrentUser && <Chip label="You" color="primary" size="small" />}
    </Box>
  );
};

OptionLabelTemplate.propTypes = {
  user: propTypes.object,
  isCurrentUser: propTypes.bool,
};
