/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Slide,
  Grid,
  Box,
  Container,
  Tabs,
  Tab,
  Paper,
  DialogTitle,
  DialogActions,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { decodeB64ToJsonObject, replaceAll } from "_helpers/utils";
import SubjectIcon from "@material-ui/icons/Subject";
import { Formik, Form, Field, FieldArray } from "formik";

import langConstant from "_lang";
import rootStyles from "rootStyles";
import ParentField from "./component/ParentField";
import CustomButton from "_components/Layout/CustomButton";
import dropdownOptions from "./constants";
import { getOptionKeyValue } from "_helpers/utils";
import apiService from "_services/api.service";
import { alertActions } from "_actions";
import actions from "./action";
import { useDispatch } from "react-redux";
import _ from "lodash";
import sessionService from "_services/session.service";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RulesBuilder = ({
  handleCloseRuleBuilder = () => {},
  show = false,
  questionID = "",
}) => {
  const rootClasses = rootStyles();
  const dispatch = useDispatch();

  const userID = sessionService.getUserId()
  const customerID = sessionService.getCustomerId()

  const formRef = useRef();
  const formTypeRef = useRef("SAVE");
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState("");
  const [value, setValue] = useState(0);
  const [velocity, setVelocity] = useState("");
  const [callType, setCallType] = useState("TEST");
  const [attributeDropdownOptions, setAttributeDropdownOptions] = useState([]);
  const [dataTypeDropdownOptions, setDataTypeDropdownOptions] = useState([]);
  const [ruleData, setRuleData] = useState({});
  const [answersOption, setAnswersOption] = useState([]);
  const [dialogOpacity, setDialogOpacity] = useState(100);
  const handleResultClose = () => {
    setDialogOpacity(0);
    setVelocity("");
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    handleCloseRuleBuilder();
    setOpen(false);
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`option-${index}`}
        aria-labelledby={`option-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const rules = [
    {
      option: "",
      parentLogicalOperator: "OR",
      parent: [
        {
          childLogicalOperator: "OR",
          child: [
            {
              attribute: "",
              comparisonOperator: "",
              dataType: "",
              compareValue: "",
            },
          ],
        },
      ],
    },
  ];

  const initialValues = { rules };

  const generateReadableCondition = (data) => {
    return data.parent.map((item, index) => {
      return (
        <>
          <span>
            {item.child.map((childItem, childIndex) => {
              return (
                <span key={"_" + index + childIndex}>
                  {childItem.attribute
                    ? getOptionKeyValue(
                        attributeDropdownOptions,
                        childItem.attribute,
                        "name"
                      )
                    : null}{" "}
                  {/*childItem.dataType*/}{" "}
                  {childItem.comparisonOperator
                    ? getOptionKeyValue(
                        dropdownOptions.comparisonOperator,
                        childItem.comparisonOperator,
                        "name"
                      )
                    : null}{" "}
                  {childItem.compareValue}{" "}
                  {childIndex != item.child.length - 1 &&
                    item.childLogicalOperator}{" "}
                </span>
              );
            })}
          </span>{" "}
          {index != data.parent.length - 1 && data.parentLogicalOperator}{" "}
        </>
      );
    });
  };

  const getQuestionDetails = () => {
    actions.getAllDetails(questionID, dispatch, customerID, userID).then((res) => {
      let dataTypeData = res.datatypeData["#result-set-1"];

      setDataTypeDropdownOptions(dataTypeData);
      let data = {
        ...res.rulesData["#result-set-1"][0],
        ...res.questionData["#result-set-1"][0],
      };

      if (data.DISPLAY_RULE_FORMAT) {
        data.rules = {
          rules: decodeB64ToJsonObject(
            res.rulesData["#result-set-1"][0].DISPLAY_RULE_FORMAT
          ),
        };
      } else {
        let ruleByResponse = [...rules];

        if (res.responseData["#result-set-1"].length > 0) {
          ruleByResponse = res.responseData["#result-set-1"].map(
            (item, index) => {
              return {
                option: item.RESPONSE_OPTION,
                parentLogicalOperator: "OR",
                parent:
                  index == res.responseData["#result-set-1"].length - 1
                    ? []
                    : [...rules[0].parent],
              };
            }
          );
        }

        data.rules = { rules: ruleByResponse };
      }
      setAnswersOption(
        res.responseData["#result-set-1"].length > 0
          ? res.responseData["#result-set-1"]
          : []
      );
      setRuleData(data);

      let attributes = res.attributeData["#result-set-2"].map((item) => {
        return {
          name: item.DATA_ASSET_ATTRIBUTE_NAME,
          value: item.DATA_ASSET_ATTRIBUTE_NAME,
          entity: item.DATA_ASSET_ENTITY_NAME,
          dataType: item.DATA_TYPE,
          attributeValue: item.SOURCE_DATA_POINT_VALUE,
        };
      });

      setAttributeDropdownOptions(attributes);
    });
  };

  const saveRules = (data, type) => {
    let attributeNull = false;
    let operatorNull = false;
    let typeNull = false;
    let valueNull = false;
    const attributeList = [];

    data.rules.filter((option) => {
      option.parent.filter((parent) => {
        parent.child.filter((child) => {
          if (child.attribute == "") {
            attributeNull = true;
          } else if (child.comparisonOperator == "") {
            operatorNull = true;
          } else if (child.dataType == null || child.dataType == "") {
            if (
              child.comparisonOperator != "IE" &&
              child.comparisonOperator != "INE"
            ) {
              typeNull = true;
            }
          } else if (child.compareValue == "") {
            if (
              child.comparisonOperator != "IE" &&
              child.comparisonOperator != "INE"
            ) {
              valueNull = true;
            }
          }

          const value = attributeDropdownOptions.filter(
            (item) => item.value == child.attribute
          );

          if (value.length > 0) {
            attributeList.push(value[0]);
          }
        });
      });
    });

    const body = {
      active: true,
      requestType: type,
      ruleDescription: ruleData.RULE_DESCRIPTION
        ? ruleData.RULE_DESCRIPTION
        : "Rule " + ruleData.QUESTION_ID,
      ruleDimension: ruleData.RULE_DIMENSION,
      ruleId: ruleData.RULE_ID,
      rules: data.rules,
      endDate: ruleData.END_DATE,
      startDate: ruleData.START_DATE,
      questionId: ruleData.QUESTION_ID,
      inFlight: false,
      attributeList: attributeList,
    };

    if (attributeNull) {
      dispatch(alertActions.error(langConstant.PLEASE_SELECT_ATTRIBUTE));
      return;
    } else if (operatorNull) {
      dispatch(alertActions.error(langConstant.PLEASE_SELECT_CONDITION));
      return;
    } else if (typeNull) {
      dispatch(alertActions.error(langConstant.PLEASE_SELECT_TYPE));
      return;
    } else if (valueNull) {
      dispatch(alertActions.error(langConstant.PLEASE_ADD_VALUE));
      return;
    }

    apiService
      .apiCall("rules")
      .send("/v1/api/testSaveRule", body)
      .then(
        (response) => {
          setDialogOpacity(100);

          if (response.data.status != "Fallback method executed") {
            const newRule = replaceAll(
              response.data ? response.data.data.rule : "",
              "#else",
              "<br> #else <br>"
            );
            const finalRule = replaceAll(newRule, "#end", "<br> #end");
            setVelocity({
              rule: finalRule,
              result: response.data.data.result,
              message: response.data.data.message,
            });
          }
        },
        (error) => {
          dispatch(alertActions.error("This" + error.toString()));
        }
      )
      .then((response) => {
        // Reloads if new rule is being generated for the first time
        if (response) {
          if (type == "SAVE" && ruleData.RULE_ID == null) {
            window.location.reload();
          }
        }
      });
  };

  const testRules = (data) => {
    saveRules(data, "TEST");
  };

  const handleSubmit = () => {
    formTypeRef.current = "TEST";
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  useEffect(() => {
    if (show) {
      getQuestionDetails();
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  return (
    <Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          {Object.keys(ruleData).length > 0 &&
          attributeDropdownOptions.length > 0 &&
          answersOption.length > 0 ? (
            <Container maxWidth="lg">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h4" align="center">
                {langConstant.RULES_BUILDER}
              </Typography>
              <Box>
                <Typography variant="h6">
                  {ruleData.REGULATOR_UNIQUE_REFERENCE} -
                  {ruleData.QUESTION_TEXT}
                </Typography>
              </Box>

              <Formik
                innerRef={formRef}
                initialValues={ruleData.rules}
                onSubmit={(values) => {
                  saveRules(values, callType);
                }}
                render={({ values }) => (
                  <Form>
                    <Box>
                      <Paper square>
                        <Paper square>
                          <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}
                            aria-label="Option Tab"
                          >
                            {answersOption.map((item, index) => {
                              return (
                                <Tab
                                  key={index}
                                  label={`option ${index + 1}`}
                                />
                              );
                            })}
                          </Tabs>
                        </Paper>
                        {answersOption.map((item, tabIndex) => {
                          return (
                            <TabPanel
                              key={tabIndex}
                              value={value}
                              index={tabIndex}
                            >
                              {tabIndex == answersOption.length - 1 ? (
                                <div>
                                  {`Set`} <b>{item.RESPONSE_OPTION}</b>{" "}
                                  {`if none of the previous condition are met.`}
                                </div>
                              ) : (
                                <>
                                  <Box
                                    p={2}
                                    style={{
                                      background: "#124571",
                                      color: "#ffffff",
                                    }}
                                  >
                                    <Grid
                                      container
                                      spacing={2}
                                      align={"stretch"}
                                    >
                                      <Grid item>
                                        Match &nbsp; &nbsp;
                                        <label
                                          htmlFor={`rules[${tabIndex}].parentLogicalOperator`}
                                        >
                                          <Field
                                            id={`rules[${tabIndex}].parentLogicalOperator1`}
                                            name={`rules[${tabIndex}].parentLogicalOperator`}
                                            type="radio"
                                            value="OR"
                                          />
                                          Any
                                        </label>
                                        <label
                                          htmlFor={`rules[${tabIndex}].parentLogicalOperator`}
                                        >
                                          <Field
                                            id={`rules[${tabIndex}].parentLogicalOperator2`}
                                            name={`rules[${tabIndex}].parentLogicalOperator`}
                                            type="radio"
                                            value="AND"
                                          />
                                          All of the following
                                        </label>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <FieldArray
                                    name={`rules[${tabIndex}].parent`}
                                    render={(outerArrayHelpers) => {
                                      return (
                                        <div>
                                          {values.rules[tabIndex].parent.map(
                                            (item, index) => (
                                              <ParentField
                                                attributeDropdownOptions={
                                                  attributeDropdownOptions
                                                }
                                                dataTypeData={
                                                  dataTypeDropdownOptions
                                                }
                                                key={
                                                  `rules[${tabIndex}].parent` +
                                                  index
                                                }
                                                values={
                                                  values.rules[tabIndex].parent[
                                                    index
                                                  ]
                                                }
                                                outerArrayHelpers={
                                                  outerArrayHelpers
                                                }
                                                tabIndex={tabIndex}
                                                index={index}
                                                pLength={
                                                  values.rules[tabIndex].parent
                                                    .length
                                                }
                                                initialValues={initialValues}
                                                parentLogicalOperator={
                                                  values.rules[tabIndex]
                                                    .parentLogicalOperator
                                                }
                                              />
                                            )
                                          )}
                                        </div>
                                      );
                                    }}
                                  />
                                  <Box mt={2}>
                                    {`Set`} <b>{item.RESPONSE_OPTION}</b>{" "}
                                    {`If the following condition are met`}
                                  </Box>
                                  <Box className="helpText" component="div">
                                    {" "}
                                    {generateReadableCondition(
                                      values.rules[tabIndex]
                                    )}
                                  </Box>
                                </>
                              )}
                            </TabPanel>
                          );
                        })}
                      </Paper>
                      <Grid container>
                        <Grid md={6}>
                          <CustomButton
                            style={{ marginTop: 20 }}
                            // type="submit"
                            variant="contained"
                            color="primary"
                            size="medium"
                            startIcon={<SaveIcon />}
                            onClick={() => {
                              setCallType("SAVE");
                              handleSubmit();
                            }}
                          >
                            {langConstant.SAVE_RULES}
                          </CustomButton>
                          &nbsp;&nbsp;
                          <CustomButton
                            style={{ marginTop: 20 }}
                            type="reset"
                            variant="contained"
                            color="default"
                            size="medium"
                          >
                            {langConstant.RESET}
                          </CustomButton>
                        </Grid>
                        <Grid md={6}>
                          <Box align="right">
                            <CustomButton
                              style={{ marginTop: 20 }}
                              className={rootClasses.redButton}
                              type="button"
                              variant="contained"
                              size="medium"
                              startIcon={<SubjectIcon />}
                              onClick={() => {
                                setCallType("TEST");
                                handleSubmit();
                              }}
                            >
                              {langConstant.TEST_RULES}
                            </CustomButton>
                            <input
                              type="hidden"
                              name="formType"
                              ref={formTypeRef}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                )}
              />

              <Dialog
                onClose={handleResultClose}
                aria-labelledby="Result"
                open={velocity}
                style={{ opacity: dialogOpacity }}
              >
                <DialogTitle id="Result" style={{ textAlign: "center" }}>
                  Result
                </DialogTitle>
                <DialogContent style={{ width: "100%" }}>
                  <p
                    style={{ textAlign: "center", textTransform: "uppercase" }}
                  >
                    {velocity.result}
                  </p>
                  <Divider />
                  <p style={{ textAlign: "center" }}>
                    <b>Velocity Template</b>
                  </p>

                  <Box
                    dangerouslySetInnerHTML={{ __html: velocity.rule }}
                  ></Box>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleResultClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Container>
          ) : null}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
RulesBuilder.propTypes = {
  handleCloseRuleBuilder: PropTypes.func,
  show: PropTypes.bool,
  questionID: PropTypes.string,
};

export default RulesBuilder;
