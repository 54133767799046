import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Box, TextField, makeStyles, Tooltip, IconButton, Typography } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import StorageIcon from "@material-ui/icons/Storage";
import moment, { localeData } from "moment";

import langConstant from "_lang";
import SelectAnswer from "../SelectAnswer";
import RfiFilterTable from "_components/RfiFilterTable";
import FilterByStatus from "_components/FilterByStatusTab";
import CommentsComponent from "_components/CommentsComponent";
import AssessmentNavigator from "../AssessmentNavigator";
import constants from "../../constants";
import DialogBox from "_components/DialogBox";
import AttachEvidence from "_components/AttachEvidence/index";
import EvidenceLocker from "_components/EvidenceLocker/index";
import OpenCalendarPopup from "_components/OpenCalendarPopup/OpenCalendarPopup";

const useStyles = makeStyles(() => ({
  drawerPaper: {
    overflow: "visible",
  },
}));

const CompeteSqm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {questionList, onPageChange} = props
  const rfiRows = useSelector((state) => state.supplierQuestionnaireRfiDetails.rfiItems);

  questionList?.length &&
    questionList.forEach((question, index) => {
      return (question.PAGE_NUMBER = index);
    });

  const [page, setPage] = useState(0);

  // const [dialogOpen, setDialogOpen] = useState(false);

  let rfiDisabled = true;
  if (rfiRows && rfiRows.length != 0) {
    rfiDisabled = false;
  } else {
    rfiDisabled = true;
  }

  const initialTabs = [
    { name: langConstant.ALL, count: 0 },
    { name: langConstant.SENT_BACK, count: 0 },
    { name: langConstant.SUBMITTED, count: 0 },
    { name: langConstant.ACCEPTED, count: 0 },
  ];

  const [drawerState, setDrawerState] = useState(false);
  const [tabs, setTabs] = useState(initialTabs);
  // const [selectedTab, setSelectedTab] = useState({ name: "ALL", id: 0 });
  const [selectedList, setSelectedList] = useState(questionList || []);
  const [searchList, setSearchList] = useState([]);
  const [pageCount, setPageCount] = useState(0);


  const onChangePage = (value) => {
    setPage(value);
  };

  useEffect(() => {
    let tempList = [];
   if( selectedList) 
   { selectedList.forEach((question) => {
      let newQuestion = Object.assign({}, question);
      newQuestion.search = question.question;
      tempList.push(newQuestion);
    });

    selectedList.forEach((question) => {
      let idQuestion = Object.assign({}, question);
      idQuestion.search = question.refId;
      tempList.push(idQuestion);
    });}

    setSearchList(tempList);
  }, [selectedList]);

  //* This changes count of count of tabs
  useEffect(() => {
    setSelectedList(questionList)
    setPageCount(questionList?.length)
  }, [questionList]);


  const updateAssesmentRemarksCount = (QUESTION_ID) => {
    dispatch({
      type: constants.UPDATE_REMARK,
      data: QUESTION_ID,
    });
  };


  // * Toggles Assessment Navigator on the right
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(open);
  };

  // * Navigates to page and closes assessment navigator
  const setPageNumber = (pageNumber) => {
    setDrawerState(false);
    setPage(pageNumber);
  };

  return (
    <>
      <Box>
        <Button
          onClick={toggleDrawer(true)}
          style={{
            position: "fixed",
            right: "0",
            top: "50vh",
            backgroundColor: "rgba(240, 20, 47, 1)",
            color: "white",
            borderRadius: "6px 0px 0px 6px",
            zIndex: "100",
            minWidth: "50px ",
          }}
        >
          <ExploreOutlinedIcon />
        </Button>
        <Drawer
          anchor={"right"}
          open={drawerState}
          onClose={toggleDrawer(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AssessmentNavigator
            questionList={questionList}
            onClose={() => setDrawerState(false)}
            setPageNumber={(pageNumber) => {
              setPageNumber(pageNumber);
            }}
            style={{ position: "relative", overflow: "visible" }}
          />
        </Drawer>
      </Box>

      {/* Icons and pagination  */}
      {selectedList?.length > 0 && selectedList[page] != null ? (
        <Box>
      <Box mt={1}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <AttachEvidence
              questionId={
                selectedList?.length && selectedList[page] ? selectedList[page].QUESTION_ID : ""
              }
              submissionId={
                selectedList?.length && selectedList[page] ? selectedList[page].SUBMISSION_ID : ""
              }
            />
            <EvidenceLocker
              questionId={
                selectedList?.length && selectedList[page] ? selectedList[page].QUESTION_ID : ""
              }
              submissionId={
                selectedList?.length && selectedList[page] ? selectedList[page].SUBMISSION_ID : ""
               }
            />
            <Typography style={{marginLeft: '5px'}}><span style={{color: 'red', marginRight: '3px'}}>*</span>{langConstant.EVIDENCE_ATTACHMENT_MANDETORY}</Typography>
          </Box>
          <Pagination
            shape="rounded"
            siblingCount={1}
            className="custom-pagination"
            color="primary"
            count={pageCount}
            page={page + 1}
            showFirstButton={false}
            showLastButton={false}
            onChange={(event, value) => {
              onChangePage(value - 1);
              onPageChange(false)
            }}
          />
        </Box>

        {/* search question input field  */}
        {selectedList?.length && selectedList[page] != null ? (
          <SelectAnswer
            questionData={selectedList[page]}
          />
         ) : (
          ""
        )}
       </Box>
 
        <CommentsComponent
          direction={"column"}
          question_ID={selectedList[page].QUESTION_ID}
          submission_ID={selectedList[page].SUBMISSION_ID}
          remarksCount={() =>
            updateAssesmentRemarksCount(selectedList[page].QUESTION_ID)
          }
        />
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

CompeteSqm.propTypes = {
  questionList: PropTypes.array,
  userId: PropTypes.number,
  onPageChange: PropTypes.func
};
export default CompeteSqm;
