/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useRef, useState } from "react";
import NotificationByStatus from "../NotificationByStatus";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import QuestionNotification from "./component/QuestionNotification";
import AssessmentNotification from "./component/AssessmentNotification";
import RfiNotification from "./component/RfiNotification";
import UserNotification from "./component/UserNotification";
import PolicyNotification from "./component/PolicyNotification";
import RegulationNotification from "./component/RegulationNotification";
import SupportNotification from "./component/SupportNotification";
import ServiceNotification from "./component/ServiceNotification";
import AppPagination from "_components/AppPagination";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import notificationsActions from "_actions/notifications.action";
import moment from "moment";
import { debounce, set } from "lodash";
import { DateRangePicker } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Menu from "@mui/material/Menu";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import langConstant from "_lang";
import sessionService from "_services/session.service";

const useStyles = makeStyles(() => ({
  textField: {
    width: "500px",
    minWidth: "100%",
    borderRadius: "10px",
    height: "20px",
  },
  inputField: {
    padding: "10px 5px !important",
    width: "100%",
    color: "#000",
  },
}));

const NotificationContent = ({
  onChange,
  selectedTab,
  notifications,
  allNotifications,
}) => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();

  const tabs = [
    { name: "QUESTION", count: 0 },
    { name: "ASSESSMENT", count: 0 },
    { name: "RFI", count: 0 },
    { name: "USER", count: 0 },
    { name: "POLICY", count: 0 },
    { name: "REGULATION", count: 0 },
    { name: "SERVICE", count: 0 },
    { name: "SUPPORT", count: 0 },
    { name: "BOOKMARKED ALERTS", count: 0 },
  ];

  const [page, setPage] = useState(0);
  let rows = 0;

  const [notificationByTag, setNotificationByTag] = useState([]);
  const [pageWiseNotification, setPageWiseNotificationByTag] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const hasMounted = useRef(false);

  let currentNotifications = notifications.slice(page * 10, page * 10 + 10);

  const [searchInput, setSearchInput] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const datePickerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const datePickerClose = () => {
    setAnchorEl(null);
  };

  const dateRangeSelector = (ranges) => {
    const newStartDate = moment(ranges.selection.startDate)
      .startOf("day")
      .toDate();
    const newEndDate = moment(ranges.selection.endDate).endOf("day").toDate();

    if (moment(ranges.selection.endDate).isAfter(moment())) {
      setDateRange((prevState) => ({
        ...prevState,
        startDate: newStartDate,
        endDate: moment().endOf("day").toDate(),
      }));
    } else {
      setDateRange((prevState) => ({
        ...prevState,
        startDate: newStartDate,
        endDate: newEndDate,
      }));
    }

    setDateRange((prevState) => {
      const effectiveEndDate = moment(ranges.selection.endDate).isAfter(
        moment()
      )
        ? moment().endOf("day").toDate()
        : newEndDate;

      // Filter notifications by date range
      const filteredNotifications = notifications.filter((notification) => {
        const notificationDate = moment(notification.CREATE_DATE);
        return notificationDate.isBetween(
          newStartDate,
          effectiveEndDate,
          null,
          "[]"
        );
      });

      setPageWiseNotificationByTag(filteredNotifications);

      return {
        ...prevState,
        startDate: newStartDate,
        endDate: effectiveEndDate,
      };
    });
  };

  const datePickerOpen = Boolean(anchorEl);

  const onSelectAll = () => {
    setSelectedNotifications(notifications);
  };

  const onMarkSelectedAsRead = () => {
    const selectedNotificationMessageIDList = [];
    const tempSelectedNotifications = selectedNotifications;
    selectedNotifications.forEach((notification) => {
      selectedNotificationMessageIDList.push(notification.MESSAGE_ID);
      tempSelectedNotifications.pop(notification.MESSAGE_ID);
    });
    const selectedNotificationStr = selectedNotificationMessageIDList.join(",");

    dispatch(
      notificationsActions.updateNotification(
        selectedNotificationStr,
        "Y",
        null,
        0,
        userID
      )
    );
    setPageWiseNotificationByTag(pageWiseNotification);
    setSelectedNotifications([]);
  };

  const onShowAll = () => {
    setSearchInput("");
    setDateRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
    setPageWiseNotificationByTag(notifications);
  };

  const onClearFilters = () => {
    setSearchInput("");
    setDateRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
    setPageWiseNotificationByTag(notifications);
    setSelectedNotifications([]);
  };

  const searchData = debounce((userInput) => {
    const filteredOutput = notifications.filter((notification) => {
      try {
        let messageObj = JSON.parse(notification.NOTIFICATION_MESSAGE);
        messageObj = messageObj["Notificaton Message"].toString().toUpperCase();

        return messageObj.includes(userInput.toString().toUpperCase());
      } catch (error) {
        console.log("Invalid JSON format : ", error);
        try {
          let messageObj = notification.NOTIFICATION_MESSAGE;
          messageObj = messageObj.toString().toUpperCase();

          return messageObj.includes(userInput.toString().toUpperCase());
        } catch (error) {
          return false;
        }
      }
    });
    setPageWiseNotificationByTag(filteredOutput);
  }, 1000);

  const handleChangePage = (event) => {
    setPage(event);
  };

  const calculatePageCount = (notifications) => {
    return notifications ? Math.round(Math.ceil(notifications.length / 10)) : 0;
  };

  const [pageCount, setPageCount] = useState(calculatePageCount(notifications));
  // const [pageCount, setPageCount] = useState(2);

  useEffect(() => {
    let filteredNotification = [];

    if (selectedTab.name === "BOOKMARKED ALERTS") {
      filteredNotification = notifications.filter((item) => {
        return item.BOOKMARK_NOTIFICATION === "Y";
      });
    } else if (!hasMounted.current) {
      hasMounted.current = true;
      filteredNotification = notifications.filter((item) => {
        return (
          item.NOTIFICATION_GROUP === selectedTab.name &&
          item.READ_NOTIFICATION === "N"
        );
      });
    } else {
      filteredNotification = notifications.filter((item) => {
        return item.NOTIFICATION_GROUP === selectedTab.name;
      });
    }
    setNotificationByTag(filteredNotification);
    setPageCount(calculatePageCount(filteredNotification));
    setPage(0);
  }, [selectedTab, notifications]);

  useEffect(() => {
    setPageWiseNotificationByTag(
      //  notifications.slice(page * 10, page * 10 + 10)
      notificationByTag.slice(page * 10, page * 10 + 10)
    );
    currentNotifications = notifications.slice(page * 10, page * 10 + 10);
  }, [page, notificationByTag]);

  if (allNotifications) {
    //* Calculate number of notifications in each tab
    tabs.forEach((tab) => {
      allNotifications.forEach((notification) => {
        if (tab.name == notification.NOTIFICATION_GROUP) {
          tab.count++;
        }
      });
    });

    //* Calculate number of bookmarked notifications
    allNotifications.forEach((notification) => {
      if (notification.BOOKMARK_NOTIFICATION == "Y") {
        tabs[8].count++;
      }
    });
  }

  return (
    <Box style={{ width: "100%", marginTop: "20px" }}>
      <NotificationByStatus
        selectedTab={selectedTab}
        onChange={onChange}
        tabs={tabs}
      />

      <Grid container display="flex" style={{ marginTop: "5px" }}>
        <Grid item xs={4} md={4} style={{ textAlign: "center" }}>
          <TextField
            className={useStyles.textField}
            placeholder="Filter Table"
            style={{ width: "100%" }}
            value={searchInput}
            onChange={(event) => {
              setSearchInput(event.target.value);
              searchData(event.target.value);
            }}
            InputProps={{
              // classes: {
              //   input: useStyles.inputField,
              // },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          md={3}
          style={{ textAlign: "center", marginLeft: "10px" }}
        >
          <TextField
            variant="outlined"
            onClick={datePickerClick}
            placeholder={langConstant.SELECT_DATE_RANGE}
            // disabled={true}
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthIcon />
                </InputAdornment>
              ),
              // classes: { input: classes.inputProfileField },
            }}
            aria-controls={datePickerOpen ? "long-menu" : undefined}
            aria-expanded={datePickerOpen ? "true" : undefined}
            aria-haspopup="true"
            value={
              dateRange.startDate
                ? moment(dateRange.startDate).format("ll") +
                  " - " +
                  moment(dateRange.endDate).format("ll")
                : ""
            }
          />
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={datePickerOpen}
            onClose={datePickerClose}
          >
            <DateRangePicker
              ranges={[dateRange]}
              onChange={dateRangeSelector}
              maxDate={moment()._d}
              showPreview={true}
            />
          </Menu>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            marginTop: "10px",
            //     justifyItems: "flex-start",
            //     justifyContent: "flex-start",
          }}
        >
          <Button
            color="primary"
            onClick={onSelectAll}
            style={{ height: "30px" }}
          >
            {langConstant.SELECT_ALL}
          </Button>

          <Button
            color="primary"
            onClick={onMarkSelectedAsRead}
            style={{ height: "30px", marginLeft: "10px" }}
          >
            {langConstant.MARK_SELECTED_AS_READ}
          </Button>

          <Button
            color="primary"
            onClick={onShowAll}
            style={{ height: "30px", marginLeft: "10px" }}
          >
            {langConstant.SHOW_ALL}
          </Button>
          <Button
            color="primary"
            onClick={onClearFilters}
            style={{ height: "30px", marginLeft: "10px" }}
          >
            {langConstant.CLEAR_FILTERS}
          </Button>
        </Grid>
      </Grid>

      {pageWiseNotification.length == 0 && (
        <Typography
          style={{
            marginTop: "100px",
            paddingLeft: "60px",
            fontSize: "18px",
            //  fontWeight: "bold",
          }}
        >
          {"No unread notifications available at this time. "}
        </Typography>
      )}

      {pageWiseNotification.map((item, index) => {
        const isSelected = selectedNotifications.some(
          (n) => n.MESSAGE_ID === item.MESSAGE_ID
        );
        if (selectedTab.name == "QUESTION") {
          return (
            <QuestionNotification
              key={index}
              notification={item}
              isSelected={isSelected}
              setSelectedNotifications={setSelectedNotifications}
            />
          );
        } else if (selectedTab.name == "ASSESSMENT") {
          return (
            <AssessmentNotification
              key={index}
              notification={item}
              isSelected={isSelected}
              setSelectedNotifications={setSelectedNotifications}
            />
          );
        } else if (selectedTab.name == "USER") {
          return (
            <UserNotification
              key={index}
              notification={item}
              isSelected={isSelected}
              setSelectedNotifications={setSelectedNotifications}
            />
          );
        } else if (selectedTab.name == "RFI") {
          return (
            <RfiNotification
              key={index}
              notification={item}
              isSelected={isSelected}
              setSelectedNotifications={setSelectedNotifications}
            />
          );
        } else if (selectedTab.name == "POLICY") {
          return (
            <PolicyNotification
              key={index}
              notification={item}
              isSelected={isSelected}
              setSelectedNotifications={setSelectedNotifications}
            />
          );
        } else if (selectedTab.name == "REGULATION") {
          return (
            <RegulationNotification
              key={index}
              notification={item}
              isSelected={isSelected}
              setSelectedNotifications={setSelectedNotifications}
            />
          );
        } else if (selectedTab.name == "SERVICE") {
          return (
            <ServiceNotification
              key={index}
              notification={item}
              isSelected={isSelected}
              setSelectedNotifications={setSelectedNotifications}
            />
          );
        } else if (selectedTab.name == "SUPPORT") {
          return (
            <SupportNotification
              key={index}
              notification={item}
              isSelected={isSelected}
              setSelectedNotifications={setSelectedNotifications}
            />
          );
        } else if (selectedTab.name == "BOOKMARKED ALERTS") {
          if (item.NOTIFICATION_GROUP == "QUESTION") {
            return (
              <QuestionNotification
                key={index}
                notification={item}
                isSelected={isSelected}
                setSelectedNotifications={setSelectedNotifications}
              />
            );
          } else if (item.NOTIFICATION_GROUP == "ASSESSMENT") {
            return (
              <AssessmentNotification
                key={index}
                notification={item}
                isSelected={isSelected}
                setSelectedNotifications={setSelectedNotifications}
              />
            );
          } else if (item.NOTIFICATION_GROUP == "USER") {
            return (
              <UserNotification
                key={index}
                notification={item}
                isSelected={isSelected}
                setSelectedNotifications={setSelectedNotifications}
              />
            );
          } else if (item.NOTIFICATION_GROUP == "RFI") {
            return (
              <RfiNotification
                key={index}
                notification={item}
                isSelected={isSelected}
                setSelectedNotifications={setSelectedNotifications}
              />
            );
          } else if (item.NOTIFICATION_GROUP == "POLICY") {
            return (
              <PolicyNotification
                key={index}
                notification={item}
                isSelected={isSelected}
                setSelectedNotifications={setSelectedNotifications}
              />
            );
          } else if (item.NOTIFICATION_GROUP == "REGULATION") {
            return (
              <RegulationNotification
                key={index}
                notification={item}
                isSelected={isSelected}
                setSelectedNotifications={setSelectedNotifications}
              />
            );
          } else if (item.NOTIFICATION_GROUP == "SERVICE") {
            return (
              <ServiceNotification
                key={index}
                notification={item}
                isSelected={isSelected}
                setSelectedNotifications={setSelectedNotifications}
              />
            );
          } else if (item.NOTIFICATION_GROUP == "SUPPORT") {
            return (
              <SupportNotification
                key={index}
                notification={item}
                isSelected={isSelected}
                setSelectedNotifications={setSelectedNotifications}
              />
            );
          }
        }
      })}
      {/* {currentNotifications &&
        currentNotifications.map((notification, index) => {
          let key = notification.MESSAGE_ID + index;
          if (notification.NOTIFICATION_GROUP == selectedTab.name) {
            if (selectedTab.name == "QUESTION") {
              return (
                <QuestionNotification key={key} notification={notification} />
              );
            } else if (selectedTab.name == "ASSESSMENT") {
              return (
                <AssessmentNotification key={key} notification={notification} />
              );
            } else if (selectedTab.name == "USER") {
              return <UserNotification key={key} notification={notification} />;
            } else if (selectedTab.name == "RFI") {
              return <RfiNotification key={key} notification={notification} />;
            }
          } else if (
            selectedTab.name == "BOOKMARKED ALERTS" &&
            notification.BOOKMARK_NOTIFICATION == "Y"
          ) {
            if (notification.NOTIFICATION_GROUP == "QUESTION") {
              return (
                <QuestionNotification key={key} notification={notification} />
              );
            } else if (notification.NOTIFICATION_GROUP == "ASSESSMENT") {
              return (
                <AssessmentNotification key={key} notification={notification} />
              );
            } else if (notification.NOTIFICATION_GROUP == "USER") {
              return <UserNotification key={key} notification={notification} />;
            } else if (notification.NOTIFICATION_GROUP == "RFI") {
              return <RfiNotification key={key} notification={notification} />;
            }
          }
        })} */}

      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
              // onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

NotificationContent.propTypes = {
  onChange: PropTypes.func,
  selectedTab: PropTypes.object,
  notifications: PropTypes.array,
  allNotifications: PropTypes.array,
};

export default NotificationContent;
