import React from "react";
import apiService from "_services/api.service";
import { alertActions, loadingActions } from "_actions";
import {
  setMyAssessmentQuestions,
  setMyAssessmentObservations,
  setMyAssessmentTrustScoreForAllQuestions,
  setMyAssessmentRfiItems,
  setRfirecipients,
  setMyassessmentResultSet2,
} from "./actions";

export const GetAssessmentsForMyAssessments = (submissionID, userID) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetAssessments", {
        i_SUBMISSION_ID: submissionID,
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            const cisoControlArray = [...response["#result-set-7"]];
            const questionData = [...response["#result-set-1"]].map((el) => {
              let ciscoControl = cisoControlArray.find(
                (control) =>
                  `${el.QUESTION_ID.trim()}_${el.REGULATORS_QUESTION_REF.trim()}` ===
                  `${control.QUESTION_ID.trim()}_${control.REGULATORS_QUESTION_REF.trim()}`
              );

              if (ciscoControl !== undefined && ciscoControl !== null) {
                return {
                  ...el,
                  CISO_CONTROL_NAME: ciscoControl.CISO_CONTROL_NAME,
                };
              } else {
                return { ...el, CISO_CONTROL_NAME: null };
              }
            });

            dispatch(setMyAssessmentQuestions(questionData));
            dispatch(setMyAssessmentRfiItems(response["#result-set-4"]));
            dispatch({
              type: "GET_RFI_FILTER",
              payload: response["#result-set-4"],
            });
            dispatch({
              type: "SET_ASSESMENT_KPI_DATA",
              payload: response["#result-set-5"],
            });
            dispatch(
              setMyAssessmentTrustScoreForAllQuestions(
                response["#result-set-6"]
              )
            );
            dispatch(setMyAssessmentObservations(response["#result-set-8"]));
            dispatch(setMyassessmentResultSet2(response["#result-set-2"]));
            dispatch({
              type: "SET_ACTION_LIST",
              payload: response?.["#result-set-9"]?.[0],
            });
            dispatch({
              type: "SET_APPROVERS_LIST",
              payload: response?.["#result-set-10"],
            });
            dispatch({
              type: "SET_REGULATORS_LIST",
              payload: response?.["#result-set-11"],
            });
            dispatch(loadingActions.end());
            return response;
          }
        },
        (error) => {
          if (error.response) {
            dispatch(loadingActions.end());
            return error.response;
          }
        }
      );
  };
};

export const GetRFIRecipients = (userid) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRFIRecipients", { i_USER_ID: userid })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#result-set-1"]) {
            dispatch(setRfirecipients(response["#result-set-1"]));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
