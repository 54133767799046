import { makeStyles } from "@material-ui/core";
import { width } from "@material-ui/system";

const useStyles = makeStyles(() => ({
    pageHeader: {
        fontSize: "25px",
        fontWeight: "bold"
    },
    stickyColl: {
        position: "sticky",
        right: 0,
        paddingTop:"10px",
        paddingBottom:"10px",
    },
    headerText: {
        margin: "0 0 0 10px",
        fontSize: "18px",
        fontWeight: "bold"
    },
    bodyText: {
        fontSize: "14px"
    },
    cardWrapper: {
        background: "rgba(0 0 0 / 0.1)"
    },
    contentWrap: {
        background: "rgb(255 255 255)",
    padding: "10px",
    marginTop: "10px",
    borderRadius: "10px",
    },
    selctPadding: {
        paddingBottom: "10px"
    },
    eyeIcon: {
        verticalAlign: "text-top",
        textAlign: "center",
        marginTop: "10px",
        width:"56px"
    },
    optionsStyle: {
        display: "flex !important",
        justifyContent: "space-between"
    },
    labelStyle: {
        paddingRight: "10px",
        paddingLeft: "10px",
        borderRight: "2px solid rgba(0 0 0 / 0.3)",
        width: "70px",
        textAlign:'center',
        fontWeight: "bold"
    },
    labelStyle2: {
        paddingRight: "10px",
        borderRight: "2px solid rgba(0 0 0 / 0.3)",
        width: "90%",
    },
    labelScoreStyle: {
        borderRight: "2px solid rgba(0 0 0 / 0.3)",
        paddingRight: "15px",
        paddingLeft: "15px",
        "&>span": {
            width: "50px",
            display: "inline-block",
            paddingLeft: "10px",
            paddingRight: "10px",
            color: "#FFFFFF"
        }
    },
    checkboxStyle:{
        width: "50px"
    },
    inputProfileField: {
        padding: "7px 0px !important",
        width: "100%",
        color: "#000",
    },
    docViewer: {
        padding: "10px",
        background: "#80808052",
        height: "600px",
    },
    documentClass: {
        "& #header-bar": {
            display: "none !important"
        },
        "& #pdf-download": {
            display: "none !important"
        },
        "& #proxy-renderer": {
            overflow: "hidden",
        }
    },
    docScannerBtn: {
        right: 0,
        position: "absolute !important",
        top: "50%",
        transform: "translateY(-50%)",
        background: "#000 !important",
        borderTopRightRadius: "0 !important",
        borderBottomRightRadius: "0 !important",
        padding: "5px !important",
        minWidth: "30px !important",  
    },
}));

export default useStyles;