// OP#3118 - UJL4 - Password Reset
// Password SuccessFully Updated Screen

import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import styles from "./styles";
import { logoData } from "_assets/logoFile";

const PasswordSuccess = () => {
    const classes = styles();
    
    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.wrapper}
            style={{background: logoData[window.REACT_APP_LOGIN_ENV]['bgColor']}}
        >
            <Grid item>
            <Card className={classes.card}>
                <CardContent>
                <Box align="center" mb={3}>
                    <img src={logoData[window.REACT_APP_LOGIN_ENV]['logo']} style={{ width: 280 }} />
                </Box>
                <Box align="center">
                    <Typography align="left" className={classes.successLabel}>
                        Your Password has been reset successfully.
                    </Typography>
                    <Typography align="left" className={classes.loginLabel}>
                        Please login <Link to="/login" className={classes.link}>here</Link>.
                    </Typography>
                </Box>
                </CardContent>
            </Card>
            </Grid>
        </Grid>
    );
}

export default PasswordSuccess;