import React from "react";
import { BarChart } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import propTypes from "prop-types";

const NoDataCard = ({
  message = "No data available yet..!",
  Icon = BarChart,
  textColor = "red",
  iconProps = {},
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        placeContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Icon {...iconProps} sx={{ fontSize: 124, color: "#F0F4F8" }} />
      <Typography color={textColor} variant="caption">
        {message}
      </Typography>
    </Box>
  );
};

NoDataCard.propTypes = {
  message: propTypes.string,
  textColor: propTypes.string,
  Icon: propTypes.any,
  iconProps: propTypes.object,
};

export default NoDataCard;
