import {
  Container,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import PolicyServices from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { addErrorMessages, clearSelectedPolicies } from "../../actions";
import useStyles from "./styles";
import { putPolicyComparison } from "./services";
import moment from "moment";
import CompareProgress from "./CompareProgress";
import CircularProgress from "@mui/material/CircularProgress";
import sessionService from "_services/session.service";

const ExecuteComparisonPopUp = ({
  show,
  setShow,
  reRunMode = false,
  metaData = {},
  userID,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const customerID = sessionService.getCustomerId();
  const isMultiProcessing = useSelector(
    (state) => state.authentication.detailedInfo.MULTI_PROCESSING
  );

  const marks = [
    { value: 0, label: "0" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 75, label: "75" },
    { value: 100, label: "100" },
  ];

  const [comparisonValue, setComparisonValue] = useState({
    name: "",
    threshold: 0,
  });
  const [comparisonError, setComparisonError] = useState({
    name: "",
    threshold: "",
  });

  const [editMode, setEditMode] = useState(false);

  const [selectedBasePolicy, setSelectedBasePolicy] = useState(null);
  const [selectedTargetPolicy, setSelectedTargetPolicy] = useState(null);
  const [progressLogsData, setProgressLogsData] = useState([]);
  const [progressDialog, setProgressDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const policies = useSelector((state) => state.policyReducer.policies);
  const selectedPolicies = useSelector(
    (state) => state.policyReducer.selectedPolicies
  );
  const errorMessages = useSelector(
    (state) => state.policyReducer.errorMessages
  );
  const defaultThreshold = useSelector(
    (state) => state.authentication?.detailedInfo?.COMP_THRESHOLD_DEFAULT
  );

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  useEffect(() => {
    // setComparisonValue({name: '', threshold: defaultThreshold ? parseInt(defaultThreshold) : 40})
    if (reRunMode) {
      const comparisonMetaData = JSON.parse(
        window.atob(metaData.COMPARISON_META_DATA)
      );

      const basePolicy = {
        POLICY_ID: metaData.BASE_POLICY_ID,
        POLICY_NAME: metaData.BASE_ALIAS_NAME,
        POLICY_FILE_NAME: metaData.BASE_POLICY_FILE_NAME,
        EXTRACTION_FILE_NAME: metaData.BASE_EXTRACTION_FILE_NAME,
        LANGUAGE_CODE: comparisonMetaData.language.value,
        NATIONAL_LANGUAGE: comparisonMetaData.language.label,
      };
      const targetPolicy = {
        POLICY_ID: metaData.TARGET_POLICY_ID,
        POLICY_NAME: metaData.TARGET_ALIAS_NAME,
        POLICY_FILE_NAME: metaData.TARGET_POLICY_FILE_NAME,
        EXTRACTION_FILE_NAME: metaData.TARGET_EXTRACTION_FILE_NAME,
        LANGUAGE_CODE: comparisonMetaData.language.value,
        NATIONAL_LANGUAGE: comparisonMetaData.language.label,
      };
      setSelectedBasePolicy(basePolicy);
      setSelectedTargetPolicy(targetPolicy);
      const comparisonThreshold = JSON.parse(
        window.atob(metaData.COMPARISON_META_DATA)
      );
      setComparisonValue({
        name: metaData.COMPARISON_NAME,
        threshold: comparisonThreshold.threshold[0],
      });
    } else {
      setEditMode(true);
      if (selectedPolicies.length === 2) {
        const basePolicy = policies.find(
          (item) => item.POLICY_ID === selectedPolicies[0]
        );
        setSelectedBasePolicy(basePolicy);
        const targetPolicy = policies.find(
          (item) => item.POLICY_ID === selectedPolicies[1]
        );
        setSelectedTargetPolicy(targetPolicy);
      }
    }
  }, []);

  const validationCheck = () => {
    let validationFlag = 1;
    if (comparisonValue["name"]) {
      setComparisonError((prevState) => ({ ...prevState, ["name"]: "" }));
    } else {
      validationFlag = 0;
      setComparisonError((prevState) => ({
        ...prevState,
        ["name"]: "Name Is Required.",
      }));
    }
    return validationFlag;
  };

  const putUserErrorLogs = async (errorData) => {
    await PolicyServices.putUserErrorMessage(errorData);
  };

  const putErrorLogs = async (errorData) => {
    await PolicyServices.putErrorLog({
      TECHNICAL_ERROR_MESSAGE: errorData["TECHNICAL_ERROR_MESSAGE"],
      URL: errorData["URL"],
    });
  };

  let userProgressTimer;

  const updateUserProgress = async (policyId) => {
    const userProgressResponse = await PolicyServices.getUserProgress(
      policyId + "_comparison"
    );
    setProgressLogsData(userProgressResponse["#result-set-1"]);
    if (userProgressResponse["#result-set-1"].length > 0) {
      const progressLen = userProgressResponse["#result-set-1"].length;
      let progressString = userProgressResponse["#result-set-1"][
        progressLen - 1
      ]["LOG_VALUE"]
        .split("_")
        .pop();
      if (
        progressString &&
        (progressString == "comparison success" ||
          progressString == "comparison failed")
      ) {
        dispatch(clearSelectedPolicies());
        setShow(false);
        setIsLoading(false);
        setProgressDialog(false);
        if (progressString == "comparison success") {
          dispatch(alertActions.success(progressString));
        } else {
          dispatch(alertActions.error(progressString));
        }
        clearInterval(userProgressTimer);
        history.push("/policy-comparison-analysis");
      }
    }
  };

  const compareSectionToSection = async () => {
    if (validationCheck()) {
      const requestData = {
        basePolicyId: selectedBasePolicy.POLICY_ID,
        targetPolicyId: selectedTargetPolicy.POLICY_ID,
        comparisonName: comparisonValue.name,
        threshold: comparisonValue.threshold,
        basePolicyName: selectedBasePolicy.EXTRACTION_FILE_NAME,
        targetPolicyName: selectedTargetPolicy.EXTRACTION_FILE_NAME,
        comparisonType: "sentencetosentence",
        language: JSON.stringify({
          value: selectedBasePolicy.LANGUAGE_CODE,
          label: selectedBasePolicy.NATIONAL_LANGUAGE,
        }),
        currentDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        comparisonMetaData: {
          actionType: "sentencetosentence",
          threshold: comparisonValue.threshold,
          language: {
            value: selectedBasePolicy.LANGUAGE_CODE,
            label: selectedBasePolicy.NATIONAL_LANGUAGE,
          },
        },
        storageType: docStorageType.toLowerCase(),
        storePreProcessingIP: docStorageIP,
        storePreProcessingURL: docStorageURL,
        userID,
        customerID,
      };
      const putComp = await putComparisonData(requestData);
      if (putComp) {
        requestData["comparisonId"] = putComp;
        requestData["isMultiProcessing"] = isMultiProcessing;
        setIsLoading(true);
        setProgressDialog(true);
        userProgressTimer = setInterval(async function () {
          await updateUserProgress(putComp);
        }, 5000);
        PolicyServices.executePolicyComparison(requestData);

        // if (response.status) {
        //   clearInterval(userProgressTimer);
        //   updateUserProgress(putComp);
        //   if (response.status == 200) {
        //     dispatch(clearSelectedPolicies());
        //     setShow(false);
        //     dispatch(alertActions.success(response.message));
        //     history.push("/policy-comparison-analysis");
        //   } else {
        //     if (response.message) {
        //       const userError = errorMessages.find(
        //         (item) => item.TECHNICAL_ERROR_MESSAGE == response.message
        //       );
        //       if (userError && userError["TECHNICAL_ERROR_MESSAGE"]) {
        //         const errorText = userError["USER_ERROR_MESSAGE"]
        //           ? userError["USER_ERROR_MESSAGE"]
        //           : userError["TECHNICAL_ERROR_MESSAGE"];
        //         dispatch(alertActions.error(errorText));
        //         const errorData = {
        //           CUSTOMER_ID: userID,
        //           URL: "POLICY_COMPARISON",
        //           TECHNICAL_ERROR_MESSAGE: response.message,
        //         };
        //         putErrorLogs(errorData);
        //       } else {
        //         dispatch(alertActions.error(response.message));
        //         const errorTextData = {
        //           ACTIVE: true,
        //           CUSTOMER_ID: customerID,
        //           TECHNICAL_ERROR_MESSAGE: response.message,
        //           URL: "POLICY_COMPARISON",
        //           USER_ERROR_MESSAGE: "",
        //         };
        //         dispatch(addErrorMessages(errorTextData));
        //         putUserErrorLogs(errorTextData);
        //         putErrorLogs(errorTextData);
        //       }
        //     }
        //   }
        // } else {
        //   if (response.data && response.data.message) {
        //     dispatch(
        //       alertActions.error(
        //         response.data.message.map((item, index) => {
        //           return <div key={index}>{item}</div>;
        //         })
        //       )
        //     );
        //   } else {
        //     dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        //   }
        // }
      }
    }
  };

  // const compareWholeDocument = async() => {
  //     if(validationCheck()){
  //         const requestData = {
  //             basePolicyId: selectedBasePolicy.POLICY_ID,
  //             targetPolicyId: selectedTargetPolicy.POLICY_ID,
  //             comparisonName: comparisonValue.name,
  //             threshold: comparisonValue.threshold,
  //             basePolicyName: selectedBasePolicy.EXTRACTION_FILE_NAME,
  //             targetPolicyName: selectedTargetPolicy.EXTRACTION_FILE_NAME,
  //             comparisonType: "wholedocument",
  //             language: JSON.stringify({value: selectedBasePolicy.LANGUAGE_CODE, label: selectedBasePolicy.NATIONAL_LANGUAGE}),
  //             userID,
  //         }
  //         dispatch(loadingActions.setLoaderText(langConstant.COMPARISON_IN_PROGRESS))
  //         dispatch(loadingActions.start())
  //         const response = await PolicyServices.executePolicyComparison(requestData)
  //         dispatch(loadingActions.end())
  //         dispatch(loadingActions.setLoaderText(null))
  //         if(response.status === 200){
  //             if(response.data.status === 200)
  //             {
  //                 setShow(false)
  //                 dispatch(alertActions.success(response.data.message))
  //                 history.push("/policy-comparison-analysis")
  //             } else {
  //                 const userError = errorMessages.find((item) => item.TECHNICAL_ERROR_MESSAGE == response.data.message)
  //                 if(userError && userError['TECHNICAL_ERROR_MESSAGE']){
  //                     const errorText = userError['USER_ERROR_MESSAGE'] ? userError['USER_ERROR_MESSAGE'] : userError['TECHNICAL_ERROR_MESSAGE']
  //                     dispatch(alertActions.error(errorText))
  //                     const errorData = {
  //                         CUSTOMER_ID: userID,
  //                         URL: 'POLICY_COMPARISON',
  //                         TECHNICAL_ERROR_MESSAGE: response.data.message,
  //                     }
  //                     putErrorLogs(errorData)
  //                 } else {
  //                     dispatch(alertActions.error(response.data.message))
  //                     const errorTextData = {
  //                         ACTIVE: true,
  //                         CUSTOMER_ID: customerID,
  //                         TECHNICAL_ERROR_MESSAGE: response.data.message,
  //                         URL: "POLICY_COMPARISON",
  //                         USER_ERROR_MESSAGE: ""
  //                     }
  //                     dispatch(addErrorMessages(errorTextData))
  //                     putUserErrorLogs(errorTextData)
  //                     putErrorLogs(errorTextData)
  //                 }
  //             }
  //         }
  //     }
  // }

  const handleClose = () => {
    setShow(false);
  };

  const putComparisonData = async (data) => {
    const response = await putPolicyComparison(data);
    if (response && response.status == 200) {
      return response["#result-set-1"][0]["new_id"];
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
      return null;
    }
  };

  return (
    <div>
      <Dialog
        open={show}
        maxWidth={progressDialog ? "lg" : "md"}
        fullWidth={true}
        onClose={handleClose}
        className={classes.policyModal}
      >
        <Container className={classes.policyUploadmodalWrapper}>
          <DialogContent>
            <Grid container>
              <Grid
                item
                md={progressDialog ? 9 : 12}
                style={{ paddingRight: "10px" }}
              >
                <Grid
                  className={classNames(
                    classes.modalTitleWrapper,
                    classes.flex
                  )}
                >
                  <Typography className={classes.displayContent}>
                    {langConstant.POLICY_EXECUTE_COMPARISON}
                  </Typography>
                  <Grid>
                    <Typography className={classes.displayLanguage}>
                      {langConstant.LANGUAGE} :{" "}
                      {selectedBasePolicy?.NATIONAL_LANGUAGE}
                    </Typography>
                    {!editMode && (
                      <Button
                        className={classes.marginLeft}
                        color="primary"
                        variant="contained"
                        onClick={() => setEditMode(true)}
                      >
                        {langConstant.EDIT_COMPARISON}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <div className={classes.insBoxLR}>
                      <Typography className={classes.insBoxLRLabel}>
                        {langConstant.BASE_DOCUMENT}
                      </Typography>
                      <div className={classes.insBoxLRField50}>
                        {selectedBasePolicy?.POLICY_NAME}
                      </div>
                    </div>
                    <div className={classes.insBoxLR}>
                      <Typography className={classes.insBoxLRLabel}>
                        {langConstant.BASE_DOCUMENT_NAME}
                      </Typography>
                      <div className={classes.insBoxLRField50}>
                        {selectedBasePolicy?.POLICY_FILE_NAME}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.insBoxLR}>
                      <Typography className={classes.insBoxLRLabel}>
                        {langConstant.TARGET_DOCUMENT}
                      </Typography>
                      <div className={classes.insBoxLRField50}>
                        {selectedTargetPolicy?.POLICY_NAME}
                      </div>
                    </div>
                    <div className={classes.insBoxLR}>
                      <Typography className={classes.insBoxLRLabel}>
                        {langConstant.TARGET_DOCUMENT_NAME}
                      </Typography>
                      <div className={classes.insBoxLRField50}>
                        {selectedTargetPolicy?.POLICY_FILE_NAME}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className={classes.compareBtn}>
                  <Grid item xs={6}>
                    <Typography className={classes.insBoxLRLabel}>
                      {langConstant.COMPARISON_NAME}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.insBoxLRField}
                      inputProps={{ style: { padding: 8 } }}
                      variant="outlined"
                      name="comparison_name"
                      onChange={(e) => {
                        setComparisonValue((prevState) => ({
                          ...prevState,
                          name: e.target.value,
                        }));
                      }}
                      disabled={!editMode}
                      value={comparisonValue.name}
                      placeholder="Comparison Name"
                    />
                    {comparisonError.name && (
                      <Typography style={{ color: "red" }}>
                        {comparisonError.name}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {/* <Grid>
                                    <Typography className={classes.insBoxLRLabel}>{langConstant.THRESHOLD_FOR_COMPARING_HEADING}</Typography>
                                    <Grid container>
                                        <Grid item xs={10} md={6}>
                                            <Slider
                                                track={false}
                                                value={comparisonValue.threshold}
                                                step={5}
                                                marks={marks}
                                                onChange={(e) => {
                                                    setComparisonValue((prevState) => ({...prevState, threshold: e.target.value}))
                                                }}
                                                valueLabelDisplay="auto"
                                                disabled={!editMode}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                <Grid container justify="flex-end">
                  <Button
                    color="primary"
                    style={{ margin: "15px 15px 15px" }}
                    variant="contained"
                    onClick={compareSectionToSection}
                    disabled={!editMode}
                  >
                    {langConstant.GENERATE_COMAPRISON}
                  </Button>
                  <Button
                    color="primary"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                    variant="outlined"
                    onClick={handleClose}
                  >
                    {langConstant.CANCEL}
                  </Button>
                  {/* <Grid item xs={12} md={4}>
                                        <Button 
                                            style={{backgroundColor: "#124571", marginTop:"15px",marginBottom:"15px", color: "white"}}
                                            variant="contained"
                                            onClick={compareWholeDocument}
                                            disabled={!editMode}
                                        >
                                            {langConstant.COMPARE_WHOLE_DOCUMENT}
                                        </Button>
                                    </Grid> */}
                </Grid>
                {isLoading && (
                  <Grid
                    className={classNames(
                      classes.loaderWrap,
                      !progressDialog ? classes.showProgress : ""
                    )}
                  >
                    <Grid className={classes.loaderInnerWrap}>
                      <CircularProgress />
                      <Typography style={{ fontWeight: "bold" }}>
                        {langConstant.COMPARISON_IN_PROGRESS}
                      </Typography>
                      {!progressDialog && (
                        <Typography
                          onClick={() => setProgressDialog(true)}
                          style={{
                            fontWeight: "bold",
                            color: "#001eff",
                            borderBottom: "1px solid #001eff",
                            cursor: "pointer",
                            display: "inline-block",
                          }}
                        >
                          {langConstant.SHOW_PROGRESS}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {progressDialog && (
                <Grid item md={3}>
                  <CompareProgress
                    setShow={setProgressDialog}
                    progressLogsData={progressLogsData}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  );
};

ExecuteComparisonPopUp.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  reRunMode: PropTypes.bool,
  metaData: PropTypes.object,
  userID: PropTypes.string,
};

export default ExecuteComparisonPopUp;
