import { Grid } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import ThemeCustomisation from "./ThemeCustomisation";
import CustomerConfiguration from "./CustomerConfiguration";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfigurationServices from "./services";
import { useState } from "react";

const Configurations = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const [customerData, setCustomerData] = useState(null);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(
        ConfigurationServices.getCustomerConfigurationByCustomerID(
          selectedCustomer.value,
          setCustomerData
        )
      );
    }
  }, [selectedCustomer]);

  return (
    <Grid spacing={2} container>
      <Grid item md={9} xs={12}>
        <CustomerConfiguration
          customerData={customerData}
          selectedCustomer={selectedCustomer}
          setCustomerData={setCustomerData}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <ThemeCustomisation
          customerData={customerData}
          setCustomerData={setCustomerData}
        />
      </Grid>
    </Grid>
  );
};

Configurations.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default Configurations;
