import React, { useEffect, useState } from "react";
import Graph from "react-graph-vis";
import "vis-network/styles/vis-network.css";
import PropTypes from "prop-types";

const NetworkGraph = ({ nodes, edges }) => {
  const [graphData, setGraphData] = useState({
    nodes: [...nodes],
    edges: [...edges],
  });

  useEffect(() => {
    setGraphData({
      nodes: [...nodes],
      edges: [...edges],
    });
  }, [nodes, edges]);

  const options = {
    nodes: {
      shape: "dot",
      scaling: {
        min: 10,
        max: 30,
      },
      font: {
        size: 9,
        face: "Tahoma",
        bold: {
          mod: "bold",
        },
      },
    },
    edges: {
      width: 0.15,
      color: { inherit: "from" },
      smooth: {
        type: "continuous",
      },
    },
    physics: {
      stabilization: true,
      barnesHut: {
        gravitationalConstant: -2000,
        springConstant: 0.001,
        springLength: 200,
      },
    },
    interaction: {
      tooltipDelay: 200,
      hideEdgesOnDrag: true,
    },
  };
  return (
    <Graph graph={graphData} options={options} style={{ height: "100vh" }} />
  );
};

NetworkGraph.propTypes = {
  nodes: PropTypes.array,
  edges: PropTypes.array,
};

export default NetworkGraph;
