// OP#2883 - UJA11 & UJA12 - UI Development : Manage Regulators & Regulations List
// index file for regulators

import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import RegulatorsListTable from "./components/RegulatorsList";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import langConstant from "_lang";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RegulatorServices from './services';
import sessionService from '_services/session.service';
import { useSelector } from 'react-redux';
import { loadingActions } from "_actions";

const Regulators = () => {
    const dispatch = useDispatch();
    const userID = sessionService.getUserId();

    const [regulatorInfoDialog, setRegulatorInfoDialog] = useState(false);
    const [regulatorId, setRegulatorId] = useState(null)

    const columns = [
        {field: "REGULATOR_ID", headerName: "REGULATOR ID", minWidth: 50},
        {field: "REGULATOR_NAME", headerName: "REGULATOR NAME", minWidth: 150},
        {field: "COUNTRY_SHORT_NAME", headerName: "REGULATOR COUNTRY", minWidth: 100},
        {field: "STATUS", headerName: "REGULATOR STATUS", minWidth: 100},
        {field: "ACTIONS", headerName: "ACTIONS", minWidth: 100},
    ]

    const regulatorsList = useSelector((state) => state.manageRegulators.regulators);
    
    useEffect(()=>{
        dispatch(loadingActions.start())
        dispatch(RegulatorServices.getAvailableRegulators(userID))
        dispatch(loadingActions.end())
    },[])

    return (
        <Box>
            <Box textAlign="right">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={()=> { 
                    setRegulatorInfoDialog(true)
                    setRegulatorId(null)
                }}
                startIcon={<AccountBalanceIcon />}
              >
                {langConstant.ADD_NEW_REGULATOR}
              </Button>
            </Box>
            <RegulatorsListTable 
                rows={regulatorsList} 
                columns={columns}
                regulatorInfoDialog={regulatorInfoDialog}
                setRegulatorInfoDialog={setRegulatorInfoDialog}
                regulatorId={regulatorId}
                setRegulatorId={setRegulatorId}
            />
        </Box>
    )
}



export default Regulators;