import apiService from "_services/api.service";
import React from "react";

const getEvidenceApprovals = (data) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetEvidenceApprovals",data)
      .then(apiService.handleResponse)
      .then((response) => {
        if(response)
        {
          response['status'] = 200
          return response
        }
      },
      (error) => {
        if(error.response) {
          return error.response
        }
      }
    );
};

const getEvidenceComments = (data) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetEvidenceComments",data)
      .then(apiService.handleResponse)
      .then((response) => {
        if(response)
        {
          response['status'] = 200
          return response
        }
      },
      (error) => {
        if(error.response) {
          return error.response
        }
      }
    );
};

export default {
    getEvidenceApprovals,
    getEvidenceComments,
}