import { Dialog, DialogContent, DialogTitle, Tab } from "@material-ui/core";
import React, { useState } from "react";
import propTypes from "prop-types";
import { TabContext, TabPanel, TabList } from "@material-ui/lab";
import { AddEditActionList } from "./AddEditActionList";
import { AddAction } from "./AddAction";
import { linkTypes } from "containers/cyber-assessment/AuditActions/constants";

export const AddEditActionTabMenu = ({
  modal,
  actionList,
  setActionList,
  linkedId,
  actionListType,
}) => {
  const isActionListEdit = Boolean(actionList?.ACTIONS_LIST_ID);
  const tabs = [
    {
      value: "addEditActionList",
      label: `${isActionListEdit ? "Update" : "Add"} Action List`,
    },
    {
      value: "addAction",
      label: "Add Action",
      disabled: !isActionListEdit,
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const actionListSuccessCallback = (apiResponse, values) => {
    if (apiResponse?.id || values?.ACTIONS_LIST_ID) {
      setActionList?.({
        ...values,
        ACTIONS_LIST_ID: apiResponse?.id || values?.ACTIONS_LIST_ID,
      });
    }

    setCurrentTab(tabs[1].value);
  };

  const onTabChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
    >
      <TabContext value={currentTab}>
        <DialogTitle disableTypography>
          <TabList variant="fullWidth" onChange={onTabChange}>
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                icon={tab?.icon}
                disabled={tab?.disabled}
              />
            ))}
          </TabList>
        </DialogTitle>
        <DialogContent>
          <TabPanel value={tabs[0].value}>
            <AddEditActionList
              successCallback={actionListSuccessCallback}
              isEditMode={isActionListEdit}
              onClose={modal?.closeModal}
              actionList={actionList}
              linkedId={linkedId}
              actionListType={actionListType}
            />
          </TabPanel>
          <TabPanel value={tabs[1].value}>
            <AddAction addActionModal={modal} actionList={actionList} />
          </TabPanel>
        </DialogContent>
      </TabContext>
    </Dialog>
  );
};

AddEditActionTabMenu.propTypes = {
  modal: propTypes.object,
  actionList: propTypes.object,
  setActionList: propTypes.func,
  linkedId: propTypes.string,
  actionListType: propTypes.oneOf(linkTypes),
};
