/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import logoFilled from "_assets/regulativai-filled.png";
import logoOpaque from "_assets/regulativai-opaque.png";
import rootStyles from "rootStyles";
import PropTypes from "prop-types";
import langConstant from "_lang";
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  "@keyframes flicker": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.2,
    },
  },
  flicker: {
    animationName: "$flicker",
    animationDuration: "400ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out",
  },
});
const Loader = ({ count, isProgressiveLogo }) => {
  const rootClasses = rootStyles();
  const { flicker } = useStyles();
  const primaryLogo = useSelector(
    (state) => state?.authentication?.detailedInfo?.PRIMARY_LOGO
  );

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: "70vh" }}
    >
      {isProgressiveLogo ? (
        <div className={rootClasses.progressLogoWrapper}>
          <div
            className={rootClasses.progressLogoBack}
            style={{ backgroundImage: `url(${logoOpaque})` }}
          />
          <div
            className={
              rootClasses.progressLogoBack + " " + rootClasses.progressLogoFront
            }
            style={{
              width: count + "%",
              backgroundImage: `url(${logoFilled})`,
            }}
          />
        </div>
      ) : (
        <div style={{ borderRadius: "8px", overflow: "hidden" }}>
          <img src={primaryLogo} alt="primaryLogo" />
        </div>
      )}

      <div className={rootClasses.progressbar}>
        <div
          className={rootClasses.progressbarInner}
          style={{ width: count + "%" }}
        ></div>
      </div>

      <div
        style={{
          fontSize: "26px",
          fontWeight: "bold",
          color: "#124571",
          marginBottom: "0px",
        }}
      >
        {count}%
      </div>
      <div style={{ fontSize: "16px", textTransform: "uppercase" }}>
        {count == 100 ? (
          <div className={`${flicker}`}>{langConstant.EXTRACTING}</div>
        ) : (
          langConstant.UPLOADING
        )}
      </div>
    </Grid>
  );
};

Loader.propTypes = {
  count: PropTypes.number.isRequired,
  isProgressiveLogo: PropTypes.bool,
};

export default Loader;
