import React, { useEffect, useState, useMemo } from "react";
import { Typography, Box, Button, Grid, makeStyles, Dialog, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';
import { Formik, Field, ErrorMessage, Form } from "formik";
import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from "prop-types";
import moment from "moment";
import individualQuestionAction from "../actions";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from '@mui/icons-material/Save';
import * as yup from "yup";
import IconButton from '@mui/material/IconButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CheckIcon from '@mui/icons-material/Check';
import { Fragment } from "react";
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch } from "react-redux";
import _ from "lodash";
import sessionService from "_services/session.service";


const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  height100: {
    height: "100%"
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px"
  },
  bottomGridContainer: {
    marginTop: "20px",
    height: "calc(100vh - 230px)",
    maxHeight: "calc(100vh - 230px)",
  },
  btnWidth: {
    width: "170px"
  },
  justifybetween: {
    justifyContent: "space-between",
    display: "flex"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  cursorpointer: {
    cursor: "pointer"
  },
  marginTop: {
    marginTop: "10px"
  }

}));


const NarrativeTab = ({ questionTextHtml, QuestionRef, questionID }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const customerID = sessionService.getCustomerId();

  const temparry = ["Question Text", "Audit Info", "Rule Decision Prefix Text", "Rule Decision", "Data Assets", "Combined Evidence Score"];
  const InitialData = { RULES_PREFIX: "Based on the data points and evidences available and considered for the assessment, the organization's response against this item question requirement is @RuleDecision" }
  const [formInitialData, setFormInitialData] = useState(InitialData);
  const [selectedModules, setSelectedModules] = useState([]);
  const [ApiDataObj, setApiDataObj] = useState({});
  const [RulesPrefixDialog, setRulesPrefixDialog] = useState(false);
  const [RulesPrefixText, setRulesPrefixText] = useState(InitialData["RULES_PREFIX"]);
  const [isRulesDialogEdit, setIsRulesDialogEdit] = useState(false);
  const [isTemplateFunctionEdit, setIsTemplateFunctionEdit] = useState(false);
  const [EditedText, setEditedText] = useState("");
  const [DummyEditedText, setDummyEditedText] = useState("");
  const [ModulesArray, setModulesArray] = useState([]);
  const [ErrorArr, setErrorArr] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [DummyTemplateString, setDummyTemplateString] = useState("");


  // old working matcher
  // const matchchecker = (word) => word.replace(/[&/\\#, +()$~%.'":*?<>{}]/g, "").replace(/\n/g, " ");


  const matchchecker = (word) => word.replace(/[&/\\#, +()$~%.'":*?<>{}]/g, "").replace(/\n/g, " ").replace(/^[0-9]+/g, "").replace(/[0-9]$/g, "");

  const isWordExist = (string, matchWord, identifier) => {

    const str1 = string?.split(";");
    const str2 = str1?.join(";")
    const splitArray = str2?.split(";");
    const matchedWordArr = splitArray?.filter(
      (word) => matchchecker(word)?.trim() === matchWord
    );

    if (matchedWordArr?.length > 0) {

      if (identifier) {
        if (matchedWordArr?.length > 1) {
          return { bool: false, index: null, required: false, multiple: true };
        }
      }


      let index;
      splitArray?.map((el, i) => {
        if (matchchecker(el)?.trim() === matchWord) {
          return (index = i);
        }
      });

      return { bool: true, index: index };
    } else {
      return { bool: false, index: null, required: true, multiple: false };
    }
  };

  const getNarrativeTemplate = async () => {
    const res = await individualQuestionAction.GetNarrativeTemplateForQuestion(questionID, customerID);
    if (res["#result-set-1"] && res["#result-set-1"].length > 0) {
      setEditedText(res["#result-set-1"][0].NARRATIVE_TEMPLATE);
      setDummyTemplateString(res["#result-set-1"][0].NARRATIVE_TEMPLATE);
    }

  }


  const Apicaller = async () => {
    setIsLoading(true);

    if (ApiDataObj["Data Assets"] === undefined) {
      const data = await individualQuestionAction.GetDataAssetsforQuestion(questionID, customerID);
      if (data["#result-set-1"]) {
        setApiDataObj((prev) => ({ ...prev, "Data Assets": data["#result-set-1"] }));
      }
    }

    if (ApiDataObj["Combined Evidence Score"] === undefined) {
      const data2 = await individualQuestionAction.GetEvidenceForQuestion(questionID, customerID);
      if (data2["#result-set-1"]) {
        setApiDataObj((prev) => ({ ...prev, "Combined Evidence Score": data2["#result-set-1"] }));
      }
    }


    if (ApiDataObj["Rule Decision Prefix Text"] === undefined && ApiDataObj["Rule Decision"] === undefined) {
      const data3 = await individualQuestionAction.GetSampleRuleDecisionForQuestion(questionID, customerID);
      if (data3["#result-set-1"]) {
        setApiDataObj((prev) => ({ ...prev, "Rule Decision Prefix Text": data3["#result-set-1"], "Rule Decision": data3["#result-set-1"] }));
      }
    }

    getNarrativeTemplate();

    setIsLoading(false);

  }

  useEffect(() => {
    if (questionID) {
      Apicaller();
    }
  }, [questionID])

  const ErrorCheckerFunc = () => {
    const tempText = EditedText;
    const ModulesArr = [...selectedModules].map((element) => {
      let tempele = element.split(" ").join("");
      return "@" + tempele
    });
    const errorArr = [];

    ModulesArr.forEach((ele) => {
      const { bool, index, required, multiple } = isWordExist(tempText, ele, true);
      if (!bool) {

        let ErrMessage;

        if (required) {
          ErrMessage = " is required"
        }

        if (multiple) {
          ErrMessage = " is repeated"
        }

        errorArr.push({ element: ele, message: ErrMessage });
      }
    })

    setErrorArr(errorArr);
  }

  const TemplateListClick = (element) => {

    if (selectedModules.indexOf(element) > -1) {
      const data = [...selectedModules].filter((ele) => ele !== element);
      setSelectedModules(data);
      const tempstr = EditedText;
      const tempele = element.split(" ").join("");
      const str1 = tempstr.split(";");

      const finalstrarr = str1.filter((word) => matchchecker(word).trim() !== "@" + tempele);
      setEditedText(finalstrarr.join(";"));

      if (isTemplateFunctionEdit) {
        setDummyEditedText(finalstrarr.join(";"));
      }

    } else {

      if (element === "Rule Decision Prefix Text") {
        setRulesPrefixDialog(true);
        setFormInitialData(InitialData);
      } else {
        setSelectedModules((prev) => ([...prev, element]));
        let str = element.split(" ").join("");
        let finalstr = "@" + str + ";";
        setEditedText((prev) => {
          if(prev && prev.length > 1){
            return (prev + finalstr)
          }
          return finalstr
        })
      }
    }
  }

  const ModuleSetterFunc = () => {

    if (ErrorArr.length === 0) {
      const tempText = EditedText
      const arr = ["@QuestionText", "@AuditInfo", "@RuleDecisionPrefixText", "@RuleDecision", "@DataAssets", "@CombinedEvidenceScore"];
      const tempobj = {};

      arr.forEach((el) => {
        const { bool, index } = isWordExist(tempText, el, false);
        if (bool) {
          tempobj[el] = index;
        }
      });

      const finalArr = Object.fromEntries(Object.entries(tempobj).sort(([, a], [, b]) => a - b));
      setModulesArray(Object.keys(finalArr));

      Object.keys(finalArr).length > 0 && Object.keys(finalArr).forEach((el) => {
        let index = arr.indexOf(el);
        if (index > -1) {

          if (selectedModules.indexOf(temparry[index]) === -1) {
            if (temparry[index] === "Rule Decision Prefix Text") {
              if (isTemplateFunctionEdit) {
                setRulesPrefixDialog(true);
                setFormInitialData(InitialData);
              }

              if (!isEdit) {
                setSelectedModules((prev) => ([...prev, temparry[index]]));
              }

            } else {
              setSelectedModules((prev) => ([...prev, temparry[index]]));
            }

          }
        }
      })
    }
  }

  useMemo(() => ModuleSetterFunc(), [EditedText, ErrorArr]);
  useMemo(() => ErrorCheckerFunc(), [EditedText, selectedModules]);

  const validationSchema = yup.object({
    RULES_PREFIX: yup.string().required("required").matches(/@RuleDecision/, 'Prefix must contain @RuleDecision').test("test1", "@RuleDecision appeared multiple times",
      (item, context) => {
        const { multiple } = isWordExist(item, "@RuleDecision", true);

        if (multiple) {
          return false;
        }

        return true;
      }),
  });

  const HandleDiaglogEdit = () => {
    setIsRulesDialogEdit(true);
    setFormInitialData({ RULES_PREFIX: RulesPrefixText })
    setRulesPrefixDialog(true);
  }

  const TemplateContentList = () => {
    return (
      <List dense className={classes.width100}>
        {temparry.map((el) => (
          <ListItem
            key={el}
            onClick={(e) => { TemplateListClick(el); }}
            disablePadding
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <ListItemButton>
              <Checkbox
                edge="end"
                onClick={(e) => { e.preventDefault() }}
                checked={selectedModules.indexOf(el) !== -1}
                inputProps={{ 'aria-labelledby': `checkbox-list-label-${el}` }}
                disableRipple
              />
              <ListItemText style={{ marginLeft: "10px" }} id={`checkbox-list-label-${el}`} primary={<span style={{ fontWeight: "500", }}>{el}</span>} />
              {
                el === "Rule Decision Prefix Text" && (selectedModules.indexOf(el) === -1 ? (
                  <Tooltip placement="bottom" title="Entered text will be appended to the rule decision">
                    <IconButton onClick={(e) => { e.stopPropagation(); }}>
                      <InfoOutlinedIcon color="success" />
                    </IconButton>
                  </Tooltip>)
                  : <IconButton onClick={(e) => { e.stopPropagation(); HandleDiaglogEdit(); }}> <EditIcon color="success" /> </IconButton>)
              }
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    )
  }

  const ModuleRenderer = () => {

    const ModuleArr = [...ModulesArray];

    const renderRulesHtmlText = () => {
      let responsedata = ApiDataObj['Rule Decision Prefix Text'] ? ApiDataObj['Rule Decision Prefix Text'][0]?.RESPONSE_VALUE : "";
      let data = RulesPrefixText?.replace("@RuleDecision", responsedata);
      data = data.replace(responsedata, "<strong>" + responsedata + "</strong>")
      return ("<p>" + data + "</p>")
    }

    const tempobj = {
      "@QuestionText": <div style={{ display: "flex", fontWeight: "bold" }} className={classes.marginTop}>
        <div>
          <p>{QuestionRef + " - "} </p>
        </div>
        <div dangerouslySetInnerHTML={{ __html: questionTextHtml }} />
      </div>,
      "@AuditInfo": <div className={classes.marginTop}>
        <p>This answer was generated on {moment().utc().format("MMMM DD, YYYY")} at {moment().utc().format("h.mm A")}</p>
      </div>,
      "@RuleDecisionPrefixText": <div className={classes.marginTop} dangerouslySetInnerHTML={{ __html: renderRulesHtmlText() }}></div>,
      "@RuleDecision": <div className={classes.marginTop}>
        <p><strong>{ApiDataObj['Rule Decision'] ? ApiDataObj["Rule Decision"][0]?.RESPONSE_VALUE : ""}</strong></p>
      </div>,
      "@DataAssets": <div className={classes.marginTop}>
        The data attributes available and their values are :
        {
          ApiDataObj["Data Assets"]?.map((el, i) => <div key={`${el.DATA_ASSET_ATTRIBUTE_NAME}_${el.SOURCE_DATA_POINT_VALUE}_${i}`} >{el.DATA_ASSET_ATTRIBUTE_NAME} = {el.SOURCE_DATA_POINT_VALUE}</div>)
        }
      </div>,
      "@CombinedEvidenceScore": <div className={classes.marginTop}>
        The evidences provided are :
        {
          ApiDataObj["Combined Evidence Score"]?.map((el, i) => <div key={`${el.EVIDENCE_SHORTNAME}}_${i}`} >{el.EVIDENCE_SHORTNAME}</div>)
        }
      </div>
    }

    return (
      <div style={{ fontSize: "14px", padding: "0px 10px" }} className={`${classes.height100}`}>

        {
          ModuleArr.map((module, i) => {

            if (module === "@RuleDecision" && ModuleArr.includes("@RuleDecisionPrefixText")) {
              return
            }

            return <Fragment key={"modules" + i}> {tempobj[module]}</Fragment>
          })
        }

      </div>
    )

  }

  const HandleDiaglogClose = () => {
    setRulesPrefixDialog(false);
  }

  const SubmitDialogData = (values, { resetForm }) => {
    setRulesPrefixDialog(false);
    setRulesPrefixText(values.RULES_PREFIX);

    if (!isRulesDialogEdit) {
      let element = "Rule Decision Prefix Text"
      setSelectedModules((prev) => ([...prev, element]));
      let str = element.split(" ").join("");
      let finalstr = "@" + str + ";";
      setEditedText((prev) => (prev + finalstr));
    }

    if (isRulesDialogEdit) {
      setIsRulesDialogEdit(false);
    }

  }

  const HandleFunctionCancel = () => {
    setIsTemplateFunctionEdit(false);
    setEditedText(DummyEditedText);
  }

  const HandleFunctionConfirm = () => {
    setIsTemplateFunctionEdit(false);
    setDummyEditedText("");
  }

  const ErrorMessageRenderer = () => {
    return (
      <>
        {
          ErrorArr.length > 0 && (
            <Typography style={{ color: "red", fontSize: "12px" }}>
              {ErrorArr[0].element}, {ErrorArr[0].message} {ErrorArr.length - 1 === 0 ? "" : `(+${ErrorArr.length - 1} errors)`}
            </Typography>
          )

        }
      </>
    )

  }

  const SubmitNarrative = () => {
    if (ErrorArr.length === 0) {
      dispatch(individualQuestionAction.UpdateQuestionNarrative(questionID, EditedText, customerID));
      setIsEdit(false);
      setRulesPrefixText(InitialData["RULES_PREFIX"]);
      setTimeout(() => getNarrativeTemplate(), 200);
      setIsTemplateFunctionEdit(false)
    }
  }

  return (
    <Box style={{ padding: "10px", height: "52vh" }}>
      <Grid container={true} spacing={4} style={{ height: "90%" }}>

        {
          isEdit && (
            <>

              <Grid item={true} xs={3} md={3} className={`${classes.height100}`}>
                <Card variant="outlined" className={`${classes.height100}`}>
                  <Grid item={true} xs={3} md={12} style={{ padding: "5px 5px 0px 5px" }}>
                    <Typography className={classes.filedTitle} style={{ marginLeft: "5px" }}>Template Content</Typography>
                  </Grid>
                  <Divider variant="fullWidth" />
                  <CardContent style={{ padding: "0px", height: "91%" }}>
                    <PerfectScrollbar >
                      {TemplateContentList()}
                    </PerfectScrollbar >
                  </CardContent>
                </Card>
              </Grid>

              <Grid item={true} xs={3} md={3} className={`${classes.height100}`} >
                <Card variant="outlined" className={`${classes.height100}`}>
                  <Grid item={true} xs={3} md={12} style={{ padding: "5px 5px 0px 5px", display: "flex", justifyContent: "space-between" }}>
                    <div style={{display:"flex"}}>
                      <Typography className={classes.filedTitle} style={{ marginLeft: "5px" }}>Template Functions</Typography>

                      <Tooltip placement="top" title={<span style={{fontSize:"12px"}}>The order of the functions and content of the template can be edited here.</span>}>
                        <InfoOutlinedIcon color="success" />
                      </Tooltip>
                    </div>



                    <div style={{ padding: "0px" }}>
                      {
                        isTemplateFunctionEdit && (
                          <>
                            {
                              ErrorArr.length === 0 && <CheckIcon color="success" sx={{ fontSize: "24px", marginRight: "10px", cursor: "pointer" }} onClick={HandleFunctionConfirm} />
                            }
                            <CloseIcon color="error" sx={{ fontSize: "24px", cursor: "pointer" }} onClick={HandleFunctionCancel} />
                          </>
                        )
                      }
                      {
                        !isTemplateFunctionEdit && (<EditIcon color="success" sx={{ fontSize: "24px", cursor: "pointer" }} onClick={(e) => { setIsTemplateFunctionEdit(true); setDummyEditedText(EditedText) }} />)
                      }
                    </div>
                  </Grid>
                  <Divider variant="fullWidth" />
                  <CardContent style={{ padding: "0px", height: "91%" }}>
                    <TextField
                      multiline
                      rows={15}
                      fullWidth
                      value={EditedText}
                      onChange={(e) => { setEditedText(e.target.value); }}
                      error={ErrorArr.length > 0}
                      disabled={!isTemplateFunctionEdit}
                    />
                  </CardContent>
                </Card>
                {
                  ErrorMessageRenderer()
                }
              </Grid>

              <Grid item={true} xs={3} md={6} className={`${classes.height100}`}>
                <Card variant="outlined" className={`${classes.height100}`}>
                  <Grid item={true} xs={3} md={12} style={{ padding: "5px 5px 0px 5px" }}>
                    <Typography className={classes.filedTitle} style={{ marginLeft: "5px" }}>Template Preview</Typography>
                  </Grid>
                  <Divider variant="fullWidth" />
                  <CardContent style={{ padding: "0px", height: "91%" }}>
                    <PerfectScrollbar >
                      {ModuleRenderer()}
                    </PerfectScrollbar >
                  </CardContent>
                </Card>
              </Grid>

            </>
          )
        }

        {
          !isEdit && (
            <Grid item={true} xs={12} md={12} className={`${classes.height100}`}>
              <Card variant="outlined" className={`${classes.height100}`}>
                <Grid item={true} xs={3} md={12} style={{ padding: "5px 5px 0px 5px", display: "flex" }}>
                  <Typography className={classes.filedTitle} style={{ marginLeft: "5px" }}>Template Preview</Typography>
                  {
                    !isLoading && <EditIcon color="success" sx={{ fontSize: "24px", cursor: "pointer", marginLeft: "10px" }} onClick={() => { setIsEdit(true); }} />
                  }
                </Grid>
                <Divider variant="fullWidth" />

                {
                  isLoading === true ? (
                    <div style={{ display: 'flex', justifyContent: 'center', height: "inherit", alignItems: "center" }}>
                      <CircularProgress />
                    </div>

                  ) : (
                    <CardContent style={{ padding: "0px", height: "91%" }}>
                      <PerfectScrollbar >
                        {ModuleRenderer()}
                      </PerfectScrollbar >
                    </CardContent>

                  )
                }

              </Card>
            </Grid>
          )
        }

        {
          isEdit && (
            <Grid container>
              <Grid container justify="space-between" style={{ width: "25%", marginLeft: "20px" }}>
                <Button color="primary" className={classes.btnWidth} variant="contained" disabled={ErrorArr.length > 0} onClick={SubmitNarrative} startIcon={<SaveIcon style={{ fontSize: "30px" }} />}>
                  SAVE
                </Button>
                <Button color="primary" className={classes.btnWidth} variant="outlined" onClick={() => { setIsEdit(false); setEditedText(DummyTemplateString); setIsTemplateFunctionEdit(false) }}>
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          )
        }

      </Grid>



      <Dialog
        open={RulesPrefixDialog}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={HandleDiaglogClose}
      >
        <DialogTitle>
          <div className={`${classes.justifybetween} ${classes.alignItemsCenter}`} >

            <span> RULES DECISION PREFIX TEXT </span>
            <CloseIcon className={classes.cursorpointer} onClick={HandleDiaglogClose} />
          </div>
        </DialogTitle>
        <DialogContent>

          <Formik
            validateOnChange={true}
            initialValues={formInitialData}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={SubmitDialogData}
          >
            {({ values, setFieldValue, resetForm, touched, errors }) => (
              <Form>
                <Box>

                  <Grid>
                    <Field name="RULES_PREFIX" >
                      {({ field: { value } }) => (

                        <TextField
                          multiline
                          rows={8}
                          fullWidth
                          value={value}
                          onChange={(e) => { setFieldValue("RULES_PREFIX", e.target.value) }}
                          error={touched.RULES_PREFIX && Boolean(errors.RULES_PREFIX)}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="RULES_PREFIX">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid style={{ marginTop: "15px", display: "flex", justifyContent: "center" }}>
                    <Button color="primary" type="submit" variant="contained" startIcon={<SaveIcon style={{ fontSize: "30px" }} />}>
                      SAVE
                    </Button>
                  </Grid>

                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>

      </Dialog>

    </Box>
  )
}

NarrativeTab.propTypes = {
  questionTextHtml: PropTypes.string,
  QuestionRef: PropTypes.string,
  questionID: PropTypes.string
};

export default NarrativeTab