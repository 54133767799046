import { makeStyles } from "@material-ui/core";

export const commonDialogStyles = makeStyles(() => ({
  form: {
    display: "flex",
    flexDirection: "column",
    m: "auto",
    gap: 24,
  },
  formElementWrapper: {
    gap: 4,
    display: "flex",
    flexDirection: "column",
    flex: "auto",
  },
  inputAndRadioWrapper: {
    gap: 16,
    display: "flex",
    flex: "auto",
  },
  title: { display: "flex", alignItems: "center", padding: ".75rem" },
}));
