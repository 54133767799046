/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

// OP#3052 - UJC5.1 - Assign Assessment - Extension Features
// Team-members table list component for the step2 screen

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { getLevelColor } from "_helpers/utils";
import _ from "lodash";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 5,
  },
  row: {
    padding: "5px 0",
  },
  smallFont: {
    fontSize: 12,
  },
  priority: {
    width: "7%",
  },
  name: {
    width: "45%",
  },
  status: {
    width: "25%",
  },
  date: {
    width: "23%",
  },
  targetDateLabel: {
    color: "#707070",
    fontSize: 11,
  },
  dateFont: {
    fontSize: 12,
  },
  cardclass: {
    width: '100%',
  },
  width100: {
    width: '100%',
  },
  acinStatus: {
    color: '#000000',
    border: '1px solid',
    borderRadius: '5px',
    padding: '3px 5px 1px 5px',
    textAlign: 'center',
    display: 'inline-block',
    textTransform: 'uppercase',
    fontSize: '13px',
    width: "90%",
  }

}));

const AssessmentTableList = ({ selectedTeamMember = {}, list = [] }) => {
  const classes = useStyles();
  const [listdata, setListData] = useState([]);
  const imgstr = "data:image/png;base64," + window.atob(selectedTeamMember.AVATAR_IMAGE ? selectedTeamMember.AVATAR_IMAGE : "");

  useEffect(() => {
    const data = list.filter((el) => (el.FIRST_NAME + el.LAST_NAME === selectedTeamMember.FIRST_NAME + selectedTeamMember.LAST_NAME) && (el.USER_ID === selectedTeamMember.USER_ID));
    setListData(data)

  }, [selectedTeamMember]);

  return (
    <>

      {/* <ListItem
        style={{ padding: "0px", border: "1px solid #e0e0e0", borderRadius: "4px", height: "50px" }}
      >
        <ListItemButton
          sx={{ "&:hover": { backgroundColor: "transparent" } }}
          disableRipple
          disableTouchRipple
        >
          <ListItemAvatar>
            <Avatar alt={selectedTeamMember.FIRST_NAME} src={imgstr} />
          </ListItemAvatar>
          <span><strong>{selectedTeamMember.FIRST_NAME} {selectedTeamMember.LAST_NAME}</strong> is currently working on</span>
        </ListItemButton>
      </ListItem> */}


      <Card className={classes.width100} variant="outlined">
        <PerfectScrollbar >
          <CardContent style={{ padding: "0px", maxHeight: '33vh' }}>
            <List dense className={classes.width100}>
              {
                listdata.map((el ,i) => el.REGULATION_ID ? (
                  <ListItem
                    style={{ padding: "0px" }}
                    key={`${el.USER_ID}${el.REGULATION_ID}${el.SUBMISSION_ID}${i}`}
                  >
                    <ListItemButton
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                      style={{ display: "flex", justifyContent: "space-between" }}
                    // disableRipple
                    // disableTouchRipple
                    >
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      // style={{wid}}
                      >

                        <Grid item className={classes.priority} >
                          <Avatar style={{
                            color: `rgb(${getLevelColor(el.PRIORITY)})`,
                            backgroundColor: `rgba(${getLevelColor(
                              el.PRIORITY
                            )},0.17)`
                          }}>
                            {el.PRIORITY}
                          </Avatar>
                        </Grid>

                        <Grid item className={classes.name} >
                          <ListItemText primary={el.LEGAL_ENTITY_NAME} secondary={el.REGULATION_NAME} secondaryTypographyProps={{style:{fontSize:"13px"}}} />
                          {/* {data.LEGAL_ENTITY_NAME} - <br />
                          {data.REGULATION_NAME} */}
                        </Grid>

                        <Grid item className={classes.status} style={{ display: "flex", justifyContent: "center" }}>
                          <span className={`${classes.acinStatus} ${el.STATUS}`} style={{ border: "none" }}>
                            {el.DISPLAY}
                          </span>
                        </Grid>

                        <Grid item className={classes.date} style={{ display: "flex", justifyContent: "flex-end" }}>
                          <ListItemText primaryTypographyProps={{style:{fontSize:"12px" , fontWeight : "500" , color : "#707070"}}} primary={"Target Completion Date"} secondary={el.TARGET_COMPLETION_DATE
                              ? moment(el.TARGET_COMPLETION_DATE).format(
                                "DD MMM YYYY"
                              )
                              : "NA"} />
                        </Grid>

                      </Grid>

                    </ListItemButton>
                  </ListItem>

                ) : (
                  <ListItem key={`${i}`}>
                    <ListItemButton
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    >
                      <ListItemText primary={"No assignment to display"} />
                    </ListItemButton>
                  </ListItem>

                ))
              }

            </List>
          </CardContent>
        </PerfectScrollbar >

      </Card>
    </>
  )
};

AssessmentTableList.propTypes = {
  list: PropTypes.array,
  selectedTeamMember: PropTypes.object,
};
export default AssessmentTableList;
