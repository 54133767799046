export const textCategories = [
    // { "value": "Caption", "name": "Caption" },
    // { "value": "Footnote", "name": "Footnote" },
    // { "value": "Formula", "name": "Formula" },
    // { "value": "List-item", "name": "List-item" },
    { "value": "Page-footer", "name": "Page-footer" },
    { "value": "Page-header", "name": "Page-header" },
    // { "value": "Picture", "name": "Picture" },
    { "value": "Section-header", "name": "Section-header" },
    // { "value": "Table", "name": "Table" },
    { "value": "Text", "name": "Text" },
    // { "value": "Title", "name": "Title" }
]

export const categoryWiseData = {
    "Page-footer": [],
    "Page-header": [],
    "Section-header": [],
    "Text": [],
}

export const stepper3Data = [
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22a", text: "Hello", type: "header-one"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22b", text: "This is paragraph", type: "unstyled"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22c", text: "This is paragraph", type: "unstyled"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22d", text: "Hello 1", type: "header-one"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22e", text: "Hello sub 1", type: "header-two"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22f", text: "This is paragraph", type: "unstyled"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22g", text: "This is paragraph", type: "unstyled"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22h", text: "This is paragraph", type: "unstyled"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22i", text: "Hello Sub 2", type: "header-two"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22j", text: "This is paragraph", type: "unstyled"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22k", text: "Hello sub 3", type: "header-two"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22l", text: "Hello sub sub 1", type: "header-three"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22m", text: "This is paragraph", type: "unstyled"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc22n", text: "This is paragraph", type: "unstyled"},
    {data: {},depth: 0, entityRanges: [], inlineStyleRanges: [], key: "fc220", text: "This is paragraph 123", type: "unstyled"},

]

export const headerType = (type) => {
    switch(type) {
        case "H": return "header-one";
        case "HH": return "header-two";
        case "HHH": return "header-three";
        case "HHHH": return "header-four";
        case "HHHHH": return "header-five";
        case "HHHHHH": return "header-six";
        default: return "unstyled"
    }
}

export const reHeaderType = (type) => {
    switch(type) {
        case "header-one": return "H";
        case "header-two": return "HH";
        case "header-three": return "HHH";
        case "header-four": return "HHHH";
        case "header-five": return "HHHHH";
        case "header-six": return "HHHHHH";
        default: return ""
    }
}

export const firstStepHelperText = {
    'document': {id: "document", text: ['DOCX, PDF, IMG, XCL, TXT, CSV - Document Types allowed to upload']},
    'document_name': {id: "document_name", text: ['No Special characters allowed']},
    'document_language': {id: "document_language", text: ['Please choose from the available picklist']},
    'document_owner': {id: "document_owner", text: ['Please choose from the available picklist']},
}

export const secondStepHelperText = {
    'Page-header': {id: "Page-header", text: ['- Sample header text is pre selected.','- To make any changes, use Edit button and Select one of the options from the drop down list.','- Sample header text can be chosen from the uploaded document as well, if list options are not viable.','- When selecting the text, choose the Lower Most Text Line in the header section if the header text consists more than one line of text.']},
    'Page-footer': {id: "Page-footer", text: ['- Sample footer text is pre selected.','- To make any changes, use Edit button and Select one of the options from the drop down list.','- Sample footer text can be chosen from the uploaded document as well , if list options are not viable.','When selecting the text few things to remember','* Choose the Upper Most Text Line in the header section if the header text consists more than one line of text.','* Page numbers should NOT BE selected as part of the footer text.']},
    'Text': {id: "Text", text: ['When selecting the main text for the extraction, please consider points mentioned below:','- Any line of the plain text can be selected','- No Bullet Points','- It should be plain text with no bold or underlined text to be selected','- No integers to be included in the text']},
    'Section-header': {id: "Section-header", text: ['- Multiple level of headings and sub headings can be selected from the document.','- There is an option of selecting the headings from the uploaded documents.']},
}

export const thirdStepHelperText = {
    'save_cancel': {id: "save_cancel", text: ['- Save button is to save the copy of extracted document which can be downloaded on the machine from the main page','- Cancel button is used to exit the present screen without saving the extraction']},
    'find_reset': {id: "find_reset", text: ['- Use the search box to find and replace text or phrases from the extracted text before saving the extracted document copy','- To reset all the changes made before saving']},
}

export const contentUpdatedFlag = (oldData, newData) => {
    if(oldData['pages_extraction_start'] !== newData['pages_extraction_start'] ||
        oldData['pages_extraction_end'] !== newData['pages_extraction_end'] ||
        oldData['document_language']['value'] !== newData['document_language']['value']
    ){
        return true
    } else {
        return false
    }
}

export const baseTextObj = { text: "", pageNo: "", isEdit: false, selectedText: "" }