import { ReviewManagerAssessmentConstants } from "./constants"

export const setReviewManagerQuestions = (data) => ({
    type: ReviewManagerAssessmentConstants.SET_REVIEW_MANAGER_QUESTIONS,
    payload: data,
});

export const setReviewManagerObservations = (data) => ({
    type: ReviewManagerAssessmentConstants.SET_REVIEW_MANAGER_OBSERVATIONS,
    payload: data,
});

export const setReviewManagerTrustScoreForAllQuestions = (data) => ({
    type: ReviewManagerAssessmentConstants.SET_REVIEW_MANAGER_TRUST_SCORE_ALL_QUESTIONS,
    payload: data,
});

export const ReviewManagerCleanUpFunc = () => ({ type: ReviewManagerAssessmentConstants.REVIEW_MANAGER_CLEANUP })