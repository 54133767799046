import apiService from "_services/api.service";

export const putPolicyComparison = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutPolicyComparison", {
      i_BASE_POLICY_ID: data.basePolicyId,
      i_TARGET_POLICY_ID: data.targetPolicyId,
      i_COMPARISON_NAME: data.comparisonName,
      i_COMPARISON_META_DATA: JSON.stringify(data.comparisonMetaData),
      i_USER_ID: data.userID,
      i_COMPARISON_FILE_NAME: "",
      i_STORAGE_FOLDER_URL:
        data.customerID.toString() + "/Policy_Management/comparison/",
      i_START_DATE: data.currentDateTime,
      i_END_DATE: "",
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};
