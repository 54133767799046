import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import langConstant from "_lang";
import NotificationContent from "./component/NotificationContent/index.jsx";
import { useSelector, useDispatch } from "react-redux";
import notificationsActions from "_actions/notifications.action";
import sessionService from "_services/session.service";

const Notifications = () => {
  useEffect(() => {
    dispatch(notificationsActions.getUserNotifications(userID));
  }, []);

  const dispatch = useDispatch();
  const userID = sessionService.getUserId();

  const allNotifications = useSelector((state) => state.notificationsList);

  const [selectedTab, setSelectedTab] = useState({ name: "QUESTION", id: 0 });
  const onChange = (e, newValue) => {
    switch (newValue) {
      case 0:
        setSelectedTab({ name: "QUESTION", id: 0 });
        break;
      case 1:
        setSelectedTab({ name: "ASSESSMENT", id: 1 });
        break;
      case 2:
        setSelectedTab({ name: "RFI", id: 2 });
        break;
      case 3:
        setSelectedTab({ name: "USER", id: 3 });
        break;
      case 4:
        setSelectedTab({ name: "POLICY", id: 4 });
        break;
      case 5:
        setSelectedTab({ name: "REGULATION", id: 5 });
        break;
      case 6:
        setSelectedTab({ name: "SERVICE", id: 6 });
        break;
      case 7:
        setSelectedTab({ name: "SUPPORT", id: 7 });
        break;
      case 8:
        setSelectedTab({ name: "BOOKMARKED ALERTS", id: 8 });
        break;
      default:
        setSelectedTab({ name: "QUESTION", id: 0 });
    }
    // setPage(0);
  };

  // const onMarkAllRead = () => {
  //   dispatch(
  //     notificationsActions.updateNotification(null, "Y", null, 1, userID)
  //   );
  // };

  const [filteredNotifications, setFilteredNotifications] = useState([]);

  //*   Filter notifications everytime selected tab changes or notifications change
  useEffect(() => {
    let tempNotifications = [];

    if (selectedTab.name == "BOOKMARKED ALERTS") {
      allNotifications.forEach((notification) => {
        if (notification.BOOKMARK_NOTIFICATION == "Y") {
          tempNotifications.push(notification);
        }
      });
      setFilteredNotifications(tempNotifications);
    } else {
      allNotifications.forEach((notification) => {
        if (notification.NOTIFICATION_GROUP == selectedTab.name) {
          tempNotifications.push(notification);
        }
      });
      setFilteredNotifications(
        tempNotifications.sort((item1, item2) => {
          return new Date(item2.CREATE_DATE) - new Date(item1.CREATE_DATE);
        })
      );
    }
  }, [selectedTab, allNotifications]);

  return (
    <>
      <Box
        // p={1}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          pl={2}
          pr={2}
          pt={1}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: "#333",
            }}
          >
            {langConstant.NOTIFICATIONS}
          </Typography>
          {/* <Button
            color="primary"
            onClick={onMarkAllRead}
            style={{ height: "30px" }}
          >
            {langConstant.MARK_ALL_AS_READ}
          </Button> */}
        </Box>
        <NotificationContent
          onChange={onChange}
          selectedTab={selectedTab}
          notifications={filteredNotifications}
          allNotifications={allNotifications}
        />
      </Box>
    </>
  );
};

export default Notifications;
