import { StepperConstants } from "./constants";

const INITIAL_STATE = {
  steps: [],
  activestep: 0,
}

export default function stepperReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case StepperConstants.SET_STEPS:
      return {
        ...state,
        steps: action.payload
      };
    case StepperConstants.SET_ACTIVE_STEP:
      return {
        ...state,
        activestep: action.payload
      };
    case StepperConstants.INCREASE_STEPPER_STEP:
      return {
        ...state,
        activestep: state.activestep + 1
      };
    case StepperConstants.DECREASE_STEPPER_STEP:
      return {
        ...state,
        activestep: state.activestep - 1
      };
    case StepperConstants.RESET_STEPPER:
      return {
        ...state,
        activestep: 0,
        steps: []
      };
    default:
      return state;
  }
}

