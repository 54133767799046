import React from "react";
import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";

const getPolicyComparisonNetworkData = (
  comparison_ID,
  baseHeaders,
  targetHeaders,
  startRange,
  endRange,
  setComparisonText
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetPolicyComparisonNetworkData", {
        i_POLICYCOMPARISONID: comparison_ID,
        i_BASE_HEADER: baseHeaders,
        i_TARGET_HEADER: targetHeaders,
        i_START_RANGE: startRange,
        i_END_RANGE: endRange,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setComparisonText(response["#result-set-1"]);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

export default {
  getPolicyComparisonNetworkData,
};
