import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import React from "react";
import moment from "moment";

const calculateSubscriptionCostWithCouponForAdmin = (
  couponCode,
  customerID,
  planID,
  billingFrequency
) => {
  return apiService
    .apiCall("dbapi")
    .send(
      "/v1/api/proc/DRegulativDB/CalculateSubscriptionCostWithCouponForAdmin",
      {
        i_COUPON_CODE: couponCode,
        i_CUSTOMER_ID: parseInt(customerID),
        i_CUSTOMER_ID2: parseInt(customerID),
        i_PLAN_ID: parseInt(planID),
        i_SUBSCRIPTION_RENEWAL_FREQ: parseInt(billingFrequency),
      }
    )
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPlanDetails = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetPlanDetails")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getCustomerSubscriptionsForAdmin = (customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCustomerSubscriptionsForAdmin", {
      i_CUSTOMER_ID: parseInt(customerID),
      i_CUSTOMER_ID2: parseInt(customerID),
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getSubscriptionPrices = (planID, user, billingFrequency) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetSubscriptionPrices", {
      i_PLAN_ID: planID,
      i_MEMBER: user,
      i_SUBSCRIPTION_RENEWAL_FREQ: billingFrequency,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putNotificationMessage = async (userID, preferenceID, mesg) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutNotificationMessage", {
      i_USER_ID: userID,
      i_NOTIFICATION_PREFERENCE_ID: preferenceID,
      i_NOTIFICATION_MESSAGE: mesg,
      i_START_DATE: null,
      i_END_DATE: null,
      i_ACTIVE: 1,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetCustomerListForAdmin = (
  setCustomerListForAdmin,
  setUniqueCustomerList,
  setUniqueCustomerUserList
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .get("/v1/api/proc/DRegulativDB/GetCustomerListForAdmin")
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let uniqueList = [];

          if (response && response["#result-set-1"]) {
            setCustomerListForAdmin(response["#result-set-1"]);
            response["#result-set-1"].forEach((item) => {
              const customer = item.CUSTOMER_NAME.toString().trim();
              if (item.ACTIVE == 1) {
                if (!uniqueList[customer]) {
                  uniqueList[customer] = [];
                }
                uniqueList[customer].push(item);
              }
            });

            const uniqueCustomers = Object.keys(uniqueList).sort();

            uniqueCustomers.forEach((key) => {
              uniqueCustomers[key] = [];
              uniqueCustomers[key].push(...uniqueList[key]);
            });
            uniqueList = uniqueCustomers;

            const uniqueParsedCustomers = [];
            uniqueCustomers.forEach((item) => {
              const tempData = {
                CUSTOMER_ID: uniqueList[item][0].CUSTOMER_ID,
                CUSTOMER_NAME: item,
                COUNT: uniqueList[item].length,
              };
              uniqueParsedCustomers.push(tempData);
            });

            setUniqueCustomerList(uniqueParsedCustomers);
            setUniqueCustomerUserList(uniqueList);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};
const getNotificationType = (userID, customerID, notificationType) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetNotificationType", {
      i_USER_ID: userID,
      i_CUSTOMER_ID: customerID,
      i_NOTIFICATION_TYPE: notificationType,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getUserNotificationPreferencesAdmin = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetUserNotificationPreferencesAdmin", {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetCustomerList = (setCustomerList) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .get("/v1/api/proc/DRegulativDB/GetCustomerList")
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setCustomerList(
            response["#result-set-1"].map((item) => {
              return {
                value: item.CUSTOMER_ID,
                label: item.CUSTOMER_NAME,
                ...item,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const sendEmail = (bccEmailId, ccEmailId, htmlBody, subject, toEmailId) => {
  return apiService
    .apiCall("notification")
    .send("/api/v1/sendMail", {
      bccEmailId: bccEmailId,
      ccEmailId: ccEmailId,
      htmlBody: htmlBody,
      subject: subject,
      toEmailId: toEmailId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response.statusCode == 200) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};
const updateCustomerInformation = (
  customerData,
  customerList,
  setCustomerList,
  setSelectedCustomer
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCustomerInformation", {
        i_CUSTOMER_ID: customerData.CUSTOMER_ID,
        i_CUSTOMER_NAME: customerData.CUSTOMER_NAME,
        i_ACTIVE: customerData.ACTIVE,
        i_CUSTOMER_ID2: customerData.CUSTOMER_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const tempCustomers = [...customerList];
          const index = tempCustomers.findIndex(
            (customer) => customer.CUSTOMER_ID === customerData.CUSTOMER_ID
          );
          if (index !== -1) {
            tempCustomers[index]["CUSTOMER_NAME"] = customerData.CUSTOMER_NAME;
            tempCustomers[index]["ACTIVE"] = customerData.ACTIVE;
            tempCustomers[index]["label"] = customerData.CUSTOMER_NAME;
            setCustomerList(tempCustomers);
            setSelectedCustomer({
              value: customerData.CUSTOMER_ID,
              label: customerData.CUSTOMER_NAME,
              ...tempCustomers[index],
            });
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putNewCustomerRecord = (
  customerData,
  setCustomerList,
  setSelectedCustomer,
  setInitialCustomerValues
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutNewCustomerRecord", {
        i_CUSTOMER_NAME: customerData.CUSTOMER_NAME,
        i_ACTIVE: customerData.ACTIVE,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const newCustomer = {
            value: response["#result-set-1"][0]["NEW_CUSTOMER"],
            label: customerData.CUSTOMER_NAME,
            ACTIVE: customerData.ACTIVE,
            CUSTOMER_NAME: customerData.CUSTOMER_NAME,
            CUSTOMER_ID: response["#result-set-1"][0]["NEW_CUSTOMER"],
          };
          setCustomerList((prevState) => [...prevState, newCustomer]);
          setSelectedCustomer(newCustomer);
          setInitialCustomerValues((prevState) => ({
            CUSTOMER_NAME: customerData.CUSTOMER_NAME,
            ACTIVE: customerData.ACTIVE,
            CUSTOMER_ID: response["#result-set-1"][0]["NEW_CUSTOMER"],
          }));
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerLegalEntitiesForAdmin = (
  customerID,
  userID = null,
  setLegalEntityData
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerLegalEntitiesForAdmin", {
        i_CUSTOMER_ID: customerID,
        i_USER_ID: userID,
        i_CUSTOMER_ID2: customerID,
        i_USER_ID2: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setLegalEntityData(
            response["#result-set-1"].map((item) => {
              return {
                value: item.LEGAL_ENTITY_ID,
                label: item.LEGAL_ENTITY_NAME,
                ...item,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getLegalEntityType = (setLegalEntityTypes) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .get("/v1/api/proc/DRegulativDB/GetLegalEntityType")
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setLegalEntityTypes(
            response["#result-set-1"].map((item) => {
              return {
                value: item.LE_TYPE_ID,
                label: item.TYPE_NAME,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putNewCustomerLE = (data, setLegalEntityData, handleClose) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/putNewCustomerLE", {
        i_LEGAL_ENTITY_NAME: data.LEGAL_ENTITY_NAME,
        i_LE_TYPE_ID: parseInt(data.LE_TYPE_ID),
        i_LEGAL_ENTITY_ADDRESS: data.LEGAL_ENTITY_ADDRESS,
        i_CONTRACT_DURATION: data.CONTRACT_DURATION,
        i_PRODUCT_SERVICE_PROVIDED: data.PRODUCT_SERVICE_PROVIDED,
        i_RESPONSIBLE_DEPARTMENT: data.RESPONSIBLE_DEPARTMENT,
        i_COUNTRY_CODE: data.COUNTRY_CODE,
        i_ACTIVE: data.ACTIVE,
        i_START_DATE: moment(data.START_DATE).format("YYYY-MM-DD"),
        i_END_DATE: moment(data.END_DATE).format("YYYY-MM-DD"),
        i_CUSTOMER_ID: data.CUSTOMER_ID,
        i_CUSTOMER_ID2: data.CUSTOMER_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setLegalEntityData((prevState) => [
            ...prevState,
            {
              LEGAL_ENTITY_ID:
                response["#result-set-1"][0]["NEW_LEGAL_ENTITY_ID"],
              ...data,
            },
          ]);
          handleClose();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const updateCustomerLE = (
  data,
  setLegalEntityData,
  legalEntityData,
  handleClose
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCustomerLE", {
        i_LEGAL_ENTITY_ID: data.LEGAL_ENTITY_ID,
        i_LEGAL_ENTITY_NAME: data.LEGAL_ENTITY_NAME,
        i_LE_TYPE_ID: parseInt(data.LE_TYPE_ID),
        i_LEGAL_ENTITY_ADDRESS: data.LEGAL_ENTITY_ADDRESS,
        i_CONTRACT_DURATION: data.CONTRACT_DURATION,
        i_PRODUCT_SERVICE_PROVIDED: data.PRODUCT_SERVICE_PROVIDED,
        i_RESPONSIBLE_DEPARTMENT: data.RESPONSIBLE_DEPARTMENT,
        i_COUNTRY_CODE: data.COUNTRY_CODE,
        i_ACTIVE: data.ACTIVE,
        i_START_DATE: moment(data.START_DATE).format("YYYY-MM-DD"),
        i_END_DATE: moment(data.END_DATE).format("YYYY-MM-DD"),
        i_CUSTOMER_ID: data.CUSTOMER_ID,
        i_CUSTOMER_ID2: data.CUSTOMER_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const entityIndex = legalEntityData.findIndex(
            (entity) => entity.LEGAL_ENTITY_ID == data.LEGAL_ENTITY_ID
          );
          if (entityIndex != -1) {
            legalEntityData[entityIndex] = data;
          } else {
            setLegalEntityData((prevState) => [
              ...prevState,
              {
                ...data,
              },
            ]);
          }
          handleClose();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putCustomerSourceSystem = (
  data,
  systemSourcesData,
  setSystemSourcesData,
  setSelectedSystemSource
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutCustomerSourceSystem", {
        i_SOURCE: data.SOURCE ? data.SOURCE : null,
        i_SOURCE_SYSTEM_NAME: data.SOURCE_SYSTEM_NAME,
        i_DATA_SOURCE_NAMES_AND_TYPES: data.DATA_SOURCE_NAMES_AND_TYPES,
        i_FILE_FORMAT: data.FILE_FORMAT,
        i_FREQUENCY: data.FREQUENCY,
        i_DELIVERY_METHOD: data.DELIVERY_METHOD,
        i_DEDICATED_BUCKET_FOLDER_NAME: data.DEDICATED_BUCKET_FOLDER_NAME,
        i_FILE_NAME_SYNTAX: data.FILE_NAME_SYNTAX,
        i_SCHEDULING_PERIOD: data.SCHEDULING_PERIOD,
        i_TARGET_DATA_STRUCTURE: data.TARGET_DATA_STRUCTURE,
        i_DATA_ATTRIBUTES_EXPECTED_IN_SOURCE_FILE:
          data.DATA_ATTRIBUTES_EXPECTED_IN_SOURCE_FILE,
        i_SOURCE_SYSTEM_OWNER: data.SOURCE_SYSTEM_OWNER,
        i_SOURCE_SYSTEM_OWNER_EMAIL_ADDRESS:
          data.SOURCE_SYSTEM_OWNER_EMAIL_ADDRESS,
        i_TEMPLATE_FILE: data.TEMPLATE_FILE,
        i_CUSTOMER_ID: data.CUSTOMER_ID,
        i_CUSTOMER_ID2: data.CUSTOMER_ID,
        i_SOURCE_SYSTEM_ID: data.SOURCE_SYSTEM_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          data["ENTITLEMENT_ICON"] = JSON.stringify(
            data.ENTITLEMENT_ICON_PREVIEW
          );
          data["label"] = data.ENTITLEMENT_NAME;
          if (data.SOURCE_SYSTEM_ID) {
            data["value"] = data.SOURCE_SYSTEM_ID;
            const tempsourceSystems = [...systemSourcesData];
            const index = tempsourceSystems.findIndex(
              (source) => source.SOURCE_SYSTEM_ID == data.SOURCE_SYSTEM_ID
            );
            if (index != -1) {
              tempsourceSystems[index] = data;
              setSystemSourcesData(tempsourceSystems);
            } else {
              setSystemSourcesData((prevState) => [...prevState, data]);
            }
            setSelectedSystemSource(data);
          } else {
            data["value"] = response["id"];
            data["SOURCE_SYSTEM_ID"] = response["id"];
            setSystemSourcesData((prevState) => [...prevState, data]);
            setSelectedSystemSource(data);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putOrUpdateEntitlementLabel = (
  data,
  entitlementLables,
  setEntitlementLabels,
  setSelectedEntitlement,
  setIsEditView
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutOrUpdateEntitlementLabel", {
        i_ENTITLEMENT_LABEL_ID: data.ENTITLEMENT_LABEL_ID
          ? data.ENTITLEMENT_LABEL_ID
          : null,
        i_CUSTOMER_ID: data.CUSTOMER_ID,
        i_CUSTOMER_ID2: data.CUSTOMER_ID,
        i_ENTITLEMENT_NAME: data.ENTITLEMENT_NAME,
        i_ENTITLEMENT_LABEL: data.ENTITLEMENT_LABEL,
        i_ENTITLEMENT_TOOLTIP: data.ENTITLEMENT_TOOLTIP,
        i_ENTITLEMENT_PARENT: data.ENTITLEMENT_PARENT,
        i_ENTITLEMENT_LABEL_LEVEL: data.ENTITLEMENT_LABEL_LEVEL,
        i_ENTITLEMENT_TITLE: null,
        i_ENTITLEMENT_SLUG: null,
        i_ENTITLEMENT_PATH: data.ENTITLEMENT_PATH,
        i_ENTITLEMENT_ICON: JSON.stringify(data.ENTITLEMENT_ICON_PREVIEW),
        i_CUSTOMER_DEFAULT: data.CUSTOMER_DEFAULT,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          data["ENTITLEMENT_ICON"] = JSON.stringify(
            data.ENTITLEMENT_ICON_PREVIEW
          );
          data["label"] = data.ENTITLEMENT_NAME;
          if (data.ENTITLEMENT_LABEL_ID) {
            data["value"] = data.ENTITLEMENT_LABEL_ID;
            const tempEntitlementLabels = [...entitlementLables];
            const index = tempEntitlementLabels.findIndex(
              (entitlement) =>
                entitlement.ENTITLEMENT_LABEL_ID == data.ENTITLEMENT_LABEL_ID
            );
            if (index != -1) {
              tempEntitlementLabels[index] = data;
              setEntitlementLabels(tempEntitlementLabels);
            } else {
              setEntitlementLabels((prevState) => [...prevState, data]);
            }
            setSelectedEntitlement(data);
          } else {
            data["value"] = response["id"];
            data["ENTITLEMENT_LABEL_ID"] = response["id"];
            setEntitlementLabels((prevState) => [...prevState, data]);
            setSelectedEntitlement(data);
          }
          setIsEditView({ mode: "", view: false });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const removeEntitlementLabel = (
  entitlementLableID,
  entitlementLables,
  setEntitlementLabels,
  setSelectedEntitlement,
  setIsEditView
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/RemoveEntitlementLabel", {
        i_ENTITLEMENT_LABEL_ID: entitlementLableID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setIsEditView({ mode: "", view: false });
          setSelectedEntitlement(null);
          setEntitlementLabels(
            entitlementLables.filter(
              (entitlementLable) =>
                entitlementLable.ENTITLEMENT_LABEL_ID != entitlementLableID
            )
          );
          dispatch(
            alertActions.success("Entitlement Label is removed successfully.")
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerDataUploadLog = (
  customerID,
  sourceSystemID,
  setEntitlementLabels
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerDataUploadLog", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
        i_SOURCE_SYSTEM_ID: sourceSystemID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setEntitlementLabels(
            response["#result-set-1"].map((item) => {
              return {
                value: item.ENTITLEMENT_LABEL_ID,
                label: item.ENTITLEMENT_NAME,
                ...item,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerDataSources = (customerID, setSystemSourcesData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerDataSources", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setSystemSourcesData(response["#result-set-1"]);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getSystemMessage = (customerID, setSystemMessages, setIsLoading) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetSystemMessage", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && Array.isArray(response["#result-set-1"])) {
            setSystemMessages((prevMessages) => [
              ...(prevMessages || []),
              ...response["#result-set-1"],
            ]);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            // Pass the message data and let the component handle display logic
            dispatch(alertActions.error(error.response.data.message));
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        // Stop loading in both success and error cases
        setIsLoading(false);
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerEntitlementLabels = (customerID, setEntitlementLabels) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerEntitlementLabels", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setEntitlementLabels(
            response["#result-set-1"].map((item) => {
              return {
                value: item.ENTITLEMENT_LABEL_ID,
                label: item.ENTITLEMENT_NAME,
                ...item,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getEntitlementsList = (setEntitlementList) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .get("/v1/api/proc/DRegulativDB/GetEntitlementsListV1")
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setEntitlementList(
            response["#result-set-1"].map((item) => {
              return {
                value: item.FEATURE_NAME,
                label: item.FEATURE_NAME,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerRegulatorRegulations = (
  customerID,
  setRegulatorsList,
  setRegulationsList
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerRegulatorRegulations", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setRegulatorsList(
            response["#result-set-2"].map((item) => {
              return {
                label: item.REGULATOR_NAME,
                value: item.REGULATOR_ID,
                ...item,
              };
            })
          );
          setRegulationsList(
            response["#result-set-1"].map((item) => {
              return {
                label: item.REGULATION_SHORT_NAME,
                value: item.REGULATION_ID,
                ...item,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getRegulatorRegulations = (
  setAllRegulationsList,
  setAllRegulatorsList
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .get("/v1/api/proc/DRegulativDB/GetRegulatorRegulations")
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setAllRegulatorsList(
            response["#result-set-2"].map((item) => {
              return {
                label: item.REGULATOR_NAME,
                value: item.REGULATOR_ID,
                ...item,
              };
            })
          );
          setAllRegulationsList(
            response["#result-set-1"].map((item) => {
              return {
                label: item.REGULATION_SHORT_NAME,
                value: item.REGULATION_ID,
                ...item,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const updateCustomerRegulatorMapping = (
  data,
  customerID,
  regulatorsList,
  setRegulatorsList,
  mode
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCustomerRegulatorMapping", {
        i_CUSTOMER_ID: customerID,
        i_REGULATOR_ID: data.REGULATOR_ID,
        i_APPLICABLE_FLAG: data.APPLICABLE_FLAG
          ? data.APPLICABLE_FLAG
          : "FALSE",
        i_ACTIVE: data.ACTIVE,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (mode == "create") {
            setRegulatorsList((prevState) => [
              ...prevState,
              { ...data, APPLICABLE_FLAG: "TRUE" },
            ]);
            dispatch(alertActions.success("Regulator Assigned Successfully"));
          } else {
            const tempRegulatorsList = [...regulatorsList];
            const index = tempRegulatorsList.findIndex(
              (regulator) => regulator.REGULATOR_ID == data.REGULATOR_ID
            );
            if (index != -1) {
              tempRegulatorsList[index] = data;
              setRegulatorsList(tempRegulatorsList);
            } else {
              setRegulatorsList((prevState) => [...prevState, data]);
            }
            dispatch(alertActions.success("Regulator Updated Successfully"));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const updateCustomerRegulationMapping = (
  data,
  customerID,
  regulationsList,
  setRegulationsList
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCustomerRegulationMapping", {
        i_CUSTOMER_ID: customerID,
        i_REGULATION_ID: data.REGULATION_ID,
        i_APPLICABLE_FLAG: data.APPLICABLE_FLAG
          ? data.APPLICABLE_FLAG
          : "FALSE",
        i_ACTIVE: data.ACTIVE,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const tempRegulationsList = [...regulationsList];
          const index = tempRegulationsList.findIndex(
            (regulator) => regulator.REGULATOR_ID == data.REGULATOR_ID
          );
          if (index != -1) {
            tempRegulationsList[index] = data;
            setRegulationsList(tempRegulationsList);
          } else {
            setRegulationsList((prevState) => [...prevState, data]);
          }
          dispatch(alertActions.success("Regulation Updated Successfully"));
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getRegulationsFromRegulator = (
  regulatorID,
  setRegulationsList,
  calculatePageCount
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRegulationsFromRegulator", {
        i_REGULATOR_ID: regulatorID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setRegulationsList(response["#result-set-1"]);
          calculatePageCount(response["#result-set-1"]);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putCustomerRegulationRegulators = (
  selectedRegulator,
  customerID,
  regulationIDs,
  regulationsList,
  setCustomerRegulationsList
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutCustomerRegulationRegulators", {
        i_REGULATOR_ID: selectedRegulator.REGULATOR_ID,
        i_CUSTOMER_ID: customerID,
        i_REGULATION_ID: regulationIDs.toString(),
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let tempRegulations = [];
          regulationIDs.forEach((regulation) => {
            const filteredRegulation = regulationsList.find(
              (regulationData) => regulationData.REGULATION_ID == regulation
            );
            if (filteredRegulation) {
              filteredRegulation["APPLICABLE_FLAG"] = "TRUE";
              filteredRegulation["ACTIVE"] = 1;
              filteredRegulation["REGULATOR_NAME"] =
                selectedRegulator.REGULATOR_NAME;
              tempRegulations.push(filteredRegulation);
            }
          });
          setCustomerRegulationsList((prevState) => [
            ...prevState,
            ...tempRegulations,
          ]);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const updateSystemMessage = (
  message,
  type,
  active,
  customerID,
  messageID,
  setSystemMessages,
  setIsLoading
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateSystemMessage", {
        i_MESSAGE_DESCRIPTION: message,
        i_MESSAGE_TYPE: type,
        i_ACTIVE: active,
        i_CUSTOMER_ID: customerID,
        i_SYSTEM_MESSAGE_ID: messageID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const tempMessage = {
            MESSAGE_DESCRIPTION: message,
            ACTIVE: active,
            MESSAGE_TYPE: type,
            SYSTEM_MESSAGE_ID: messageID,
            CUSTOMER_ID: customerID,
          };

          setSystemMessages((prev) => {
            return prev.map((msg) =>
              msg.SYSTEM_MESSAGE_ID === messageID ? tempMessage : msg
            );
          });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
        dispatch(loadingActions.end());
      });
  };
};

const putSystemMessage = (
  message,
  CustomerID,
  setSystemMessages,
  setIsLoading
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutSystemMessage", {
        i_MESSAGE_DESCRIPTION: message,
        i_MESSAGE_TYPE: "NOTIFY",
        i_ACTIVE: 0,
        i_CUSTOMER_ID: CustomerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const tempMessage = {
            MESSAGE_DESCRIPTION: message,
            ACTIVE: 1,
            MESSAGE_TYPE: "NOTIFY",
            CUSTOMER_ID: CustomerID,
          };
          setSystemMessages((prev) => [...prev, tempMessage]);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
        dispatch(loadingActions.end());
      });
  };
};

export default {
  GetCustomerList,
  updateCustomerInformation,
  putNewCustomerRecord,
  getCustomerLegalEntitiesForAdmin,
  getLegalEntityType,
  putNewCustomerLE,
  updateCustomerLE,
  getCustomerEntitlementLabels,
  getSystemMessage,
  putSystemMessage,
  updateSystemMessage,
  getCustomerDataSources,
  getCustomerDataUploadLog,
  putOrUpdateEntitlementLabel,
  putCustomerSourceSystem,
  getEntitlementsList,
  getCustomerRegulatorRegulations,
  getRegulatorRegulations,
  updateCustomerRegulatorMapping,
  updateCustomerRegulationMapping,
  getRegulationsFromRegulator,
  putCustomerRegulationRegulators,
  removeEntitlementLabel,
  GetCustomerListForAdmin,
  getUserNotificationPreferencesAdmin,
  putNotificationMessage,
  sendEmail,
  getNotificationType,
  getCustomerSubscriptionsForAdmin,
  getSubscriptionPrices,
  getPlanDetails,
  calculateSubscriptionCostWithCouponForAdmin,
};
