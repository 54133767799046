import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import TableauReport from "tableau-react";
import TableauServices from "_services/tableau.service";
import { useDispatch, useSelector } from "react-redux";
import sessionService from "_services/session.service";
import { alertActions } from "_actions";

const PolicyComparisonOverview = () => {
  const dispatch = useDispatch();
  const [tableauToken, setTableauToken] = useState(null);
  const [DefaultUrl, setDefaultUrl] = useState(null);
  const [tableauParameters, setTableauParameters] = useState({});

  const username = useSelector(
    (state) => state.authentication.detailedInfo?.USER_NAME
  );
  const reportingUrl = useSelector(
    (state) => state?.authentication?.detailedInfo?.REPORTING_URL
  );
  const TableauDefaultObjArray = useSelector(
    (state) => state?.authentication?.detailedInfo?.TABLEAU_DEFAULT_DASHBOARDS
  );

  const userId = sessionService.getUserId();
  const customerId = sessionService.getCustomerId();

  const options = {
    height: 800,
    width: "100%",
  };

  useEffect(() => {
    if (username) {
      dispatch(TableauServices.getTableauToken(setTableauToken));
    }
  }, [username]);

  useEffect(() => {
    if (TableauDefaultObjArray?.length > 0) {
      let tempTableauParameters = {};
      const Urlobj = TableauDefaultObjArray.find(
        (el) => el.ENTITLEMENT_NAME.trim() === "POLICY_COMPARISON_OVERVIEW"
      );
      if (Urlobj !== undefined && Urlobj.DASHBOARD_URL) {
        setDefaultUrl(Urlobj["DASHBOARD_URL"].trim());
        if (Urlobj.Parameter) {
          if (Urlobj.Parameter.includes("@USER_ID")) {
            tempTableauParameters["UserId"] = userId;
          }
          if (Urlobj.Parameter.includes("@CUSTOMER_ID")) {
            tempTableauParameters["CustomerId"] = customerId;
          }
          setTableauParameters(tempTableauParameters);
        }
      } else {
        dispatch(
          alertActions.error(
            "Tableau Default Url Error, URL = " + Urlobj?.DASHBOARD_URL
          )
        );
      }
    }
  }, [TableauDefaultObjArray]);

  return (
    <Box>
      <h1>{langConstant.COMPARISON_OVERVIEW}</h1>
      {tableauToken && DefaultUrl && (
        <TableauReport
          url={reportingUrl + DefaultUrl}
          token={tableauToken}
          parameters={tableauParameters}
          options={options}
          query="?:embed=yes&:comments=no&:toolbar=top&:refresh=yes"
        />
      )}
    </Box>
  );
};

export default PolicyComparisonOverview;
