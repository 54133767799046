import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import React from "react";
import moment from "moment";

const getUsersByCustomerForAdmin = (
  customerID,
  setUsersData,
  setFilteredUsersData
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetUsersByCustomerForAdmin", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setUsersData(response["#result-set-1"]);
          setFilteredUsersData(response["#result-set-1"]);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerTeamListForAdmin = (
  customerID,
  setTeamsData,
  setFilteredTeamsData
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerTeamListForAdmin", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setTeamsData(
            response["#result-set-1"].map((item) => {
              return {
                value: item.TEAM_ID,
                label: item.TEAM_NAME,
                ...item,
              };
            })
          );
          setFilteredTeamsData(
            response["#result-set-1"].map((item) => {
              return {
                value: item.TEAM_ID,
                label: item.TEAM_NAME,
                ...item,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putTeamByAdmin = (data, teamsData, setTeamsData, handleClose) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutTeamByAdmin", {
        i_TEAM_ID: data.TEAM_ID ? data.TEAM_ID : null,
        i_LEGAL_ENTITY_ID: data.LEGAL_ENTITY_ID,
        i_TEAM_NAME: data.TEAM_NAME,
        i_CITY: data.CITY,
        i_COUNTRY_CODE: data.COUNTRY_CODE,
        i_DEPARTMENT_NAME: data.DEPARTMENT_NAME,
        i_EMAIL: data.EMAIL,
        i_ANNUAL_BUDGET: data.ANNUAL_BUDGET,
        i_TEAM_PARENT_ID: data.TEAM_PARENT_ID,
        i_START_DATE: moment(data.START_DATE).format("YYYY-MM-DD"),
        i_END_DATE: moment(data.END_DATE).format("YYYY-MM-DD"),
        i_ACTIVE: data.ACTIVE,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (data.TEAM_ID) {
            const tempTeamsData = [...teamsData];
            const index = tempTeamsData.findIndex(
              (team) => team.TEAM_ID === data.TEAM_ID
            );
            if (index !== -1) {
              tempTeamsData[index] = {
                ...tempTeamsData[index],
                ...data,
                label: data.TEAM_NAME,
              };
              setTeamsData(tempTeamsData);
            }
          } else {
            setTeamsData((prevState) => [
              ...prevState,
              {
                value: response["#result-set-1"][0]["TEAM_ID"],
                label: data.TEAM_NAME,
                TEAM_ID: response["#result-set-1"][0]["TEAM_ID"],
                ...data,
              },
            ]);
          }
          handleClose();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

export default {
  getUsersByCustomerForAdmin,
  getCustomerTeamListForAdmin,
  putTeamByAdmin,
};
