import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import propTypes from "prop-types";
import { useDispatch } from "react-redux";
import sessionService from "_services/session.service";
import {
  ACTION_STATUS_LIST,
  linkTypes,
  priorities,
} from "containers/cyber-assessment/AuditActions/constants";
import {
  PutActionsList,
  getDropdownOptions,
} from "containers/cyber-assessment/AuditActions/services";
import { alertActions } from "_actions";
import { AddActionListForm } from "./AddActionListForm";
import { actionValidation } from "./contants";
import moment from "moment";

const validationSchema = actionValidation.addActionList;

function removePrefixFromKeys(originalObject, prefix) {
  const updatedObject = {};

  for (const key in originalObject) {
    // eslint-disable-next-line no-prototype-builtins
    if (originalObject?.hasOwnProperty(key)) {
      const newKey = key.startsWith(prefix) ? key.slice(prefix.length) : key;
      updatedObject[newKey] = originalObject[key];
    }
  }

  return updatedObject;
}

const API_LIST = {
  [linkTypes[0]]: "GetAssignedRegulations",
  [linkTypes[1]]: "GetAvailablePolicies",
  [linkTypes[2]]: "GetAvailablePolicyComparisons",
  [linkTypes[3]]: "GetAvailableRegulations",
};

const optionsMapping = {
  [linkTypes[0]]: {
    value: "SUBMISSION_ID",
    label: "REGULATION_SHORT_NAME",
  },
  [linkTypes[1]]: {
    value: "POLICY_ID",
    label: "POLICY_NAME",
  },
  [linkTypes[2]]: {
    value: "POLICYCOMPARISONID",
    label: "COMPARISON_NAME",
  },
  [linkTypes[3]]: {
    value: "REGULATION_ID",
    label: "REGULATION_SHORT_NAME",
  },
};

export const AddEditActionList = ({
  isEditMode,
  actionList,
  successCallback,
  onClose,
  linkedId,
  actionListType,
}) => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId(),
    customerID = sessionService.getCustomerId();
  const [isOptionLoading, setIsOptionLoading] = useState(true);
  const [formattedOptions, setFormatedOptions] = useState();
  const [paginatedOptions, setPaginatedOptions] = useState();
  const pageLimit = 20;
  const defaulSelectLink = linkTypes?.find(
    (type) => type == actionList?.ACTIONS_LIST_TYPE || type == actionListType
  );
  const [currentLink, setCurrentLink] = useState(defaulSelectLink);
  const intialValues = {
    actionListName: actionList?.ACTIONS_LIST_NAME || "",
    priority:
      (isEditMode &&
        priorities.find(
          (p) => p[0] == actionList?.ACTIONS_LIST_PRIORITY?.[0]
        )) ||
      priorities[0],
    linkType: defaulSelectLink,
    status:
      (isEditMode && actionList?.ACTIONS_LIST_STATUS) ||
      ACTION_STATUS_LIST[0].value,
    linkedItem:
      formattedOptions?.find(
        (o) =>
          o?.payload?.[optionsMapping?.[currentLink]?.value] ==
          (actionList?.ACTIONS_LIST_LINK_ID || linkedId)
      ) || "",
  };

  const onSubmit = async (values, actions) => {
    actions?.setSubmitting(true);
    const theDate = moment(new Date()).format("YYYY-MM-DD");
    const newDate = theDate.toString();
    const payload = {
      i_ACTIONS_LIST_NAME: values?.actionListName,
      i_ACTIONS_LIST_DESCRIPTION: values?.actionListName,
      i_ACTIONS_LIST_PRIORITY: values?.priority,
      i_ACTIONS_LIST_OWNER: userID,
      i_ACTIONS_LIST_TYPE: currentLink,
      i_ACTIONS_LIST_LINK_ID: values?.linkedItem?.value,
      // i_CUSTOMER_ID: customerID ? Number(customerID) : null,
      i_ACTIONS_LIST_ID: (isEditMode && actionList?.ACTIONS_LIST_ID) || null,
      i_LIST_STATUS: values?.status || ACTION_STATUS_LIST[1].value,
      i_ACTIONS_CATEGORY: null,
      i_ACTIVE: true,
      i_START_DATE: newDate,
      i_END_DATE: newDate,
    };
    await PutActionsList(payload)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            alertActions.success(
              `Action List successfully ${isEditMode ? "updated" : "added"}`
            )
          );
          successCallback?.(response, removePrefixFromKeys(payload, "i_"));
        } else {
          dispatch(
            alertActions.error(
              response?.data?.message?.map((item, index) => {
                return <span key={index}>{item}</span>;
              }) || "Something went wrong"
            )
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(alertActions.error(err?.toString() || "Something went wrong"));
      })
      .finally(() => actions?.setSubmitting(false));
  };

  const loadMoreOptions = () => {
    if (paginatedOptions?.length >= formattedOptions?.length) return;
    setIsOptionLoading(true);
    let length = paginatedOptions?.length || 0;
    const data = [...formattedOptions].slice(length, length + pageLimit);
    setTimeout(() => {
      setPaginatedOptions((prev) => [...prev, ...data]);
      setIsOptionLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (!currentLink) return;
    setIsOptionLoading(true);
    getDropdownOptions(userID, API_LIST[currentLink])
      .then((res) => {
        if (res?.status !== 200) {
          dispatch(
            alertActions.error(
              res?.data?.message?.map((item, index) => {
                return <span key={index}>{item}</span>;
              }) ?? res?.toString()
            )
          );
        }
        let list = res?.["#result-set-1"];
        setIsOptionLoading(false);
        const options = list?.map((item) => {
          return {
            value: item?.[optionsMapping[currentLink].value],
            label: item?.[optionsMapping[currentLink].label],
            isDisabled: !item?.[optionsMapping[currentLink].value]
              ? true
              : false,
            payload: item,
          };
        });
        setFormatedOptions(options);
        setPaginatedOptions(options?.slice(0, pageLimit));
      })
      .catch((error) => {
        setIsOptionLoading(false);
        dispatch(
          alertActions.error(
            error?.data?.message?.map((item, index) => {
              return <span key={index}>{item}</span>;
            }) ?? error?.toString()
          )
        );
      });
  }, [currentLink]);

  return (
    <React.Fragment>
      <AddActionListForm
        currentLink={currentLink}
        setCurrentLink={setCurrentLink}
        intialValues={intialValues}
        defaultLinkType={defaulSelectLink}
        formattedOptions={formattedOptions}
        isOptionLoading={isOptionLoading}
        loadMoreOptions={loadMoreOptions}
        onSubmit={onSubmit}
        paginatedOptions={paginatedOptions}
        validationSchema={validationSchema}
        onClose={onClose}
      />
    </React.Fragment>
  );
};

AddEditActionList.propTypes = {
  isEditMode: propTypes.bool,
  actionList: propTypes.object,
  successCallback: propTypes.func,
  onClose: propTypes.func,
  linkedId: propTypes.string,
  actionListType: propTypes.oneOf(linkTypes),
};
