/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Box, TextField } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SelectedQuestionForMapping from "./SelectedQuestionForMapping";
import ProgressBarLarge from "_components/ProgressBarLarge";

const AssignNarrativesStep = (props) => {
  const [list, setList] = React.useState(props.questionDraft);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [searchList, setSearchList] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const onChangePage = (value) => {
    setPage(value);
    setSelectedQuestion(value);
  };
  useEffect(() => {
    const newList = _.filter(props.questionDraft, { selected: true });
    if (newList.length == 0) {
      props.handleBack();
    }
    setPageCount(newList.length);
    setList(newList);
    setSelectedQuestion(0);
  }, []);

  useEffect(() => {
    let tempList = [];

    list.forEach((question) => {
      let newQuestion = Object.assign({}, question);
      newQuestion.search = question.sentence;
      tempList.push(newQuestion);
    });

    list.forEach((question) => {
      let idQuestion = Object.assign({}, question);
      idQuestion.search = question.ref;
      tempList.push(idQuestion);
    });

    setSearchList(tempList);
  }, [list]);

  const onSearch = (e, obj) => {
    if (obj && obj.id) {
      const index = _.findIndex(list, (e) => {
        return e.id == obj.id;
      });
      setPage(index);
      setSelectedQuestion(index);
    }
  };

  return (
    <>
      <ProgressBarLarge percentage={props.percentage} />
      <Box mt={2}>
        <Box display="flex" justifyContent="flex-end">
          <Pagination
            shape="rounded"
            siblingCount={1}
            className="custom-pagination"
            color="primary"
            count={pageCount}
            page={page + 1}
            showFirstButton={false}
            showLastButton={false}
            onChange={(event, value) => {
              onChangePage(value - 1);
            }}
          />
        </Box>
        {/*<TextField
        size="small"
        variant="outlined"
        fullWidth
        id="searchText"
        label=""
        name="searchText"            
        placeholder="Filter Table"        
        value={searchText}
        onChange={e=> onSearch(e)}   
        
        InputProps={{        
          startAdornment: <InputAdornment position="start"> <SearchIcon /></InputAdornment>,
        }}  
    />*/}
        <Box my={2}>
          <Autocomplete
            id="combo-box-demo"
            options={searchList}
            getOptionLabel={(option) => option.search}
            onChange={(e, newValue) => {
              onSearch(e, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Questions"
                label=""
                variant="outlined"
              />
            )}
          />
        </Box>
        {selectedQuestion != null && (
          <SelectedQuestionForMapping questionData={list[selectedQuestion]} />
        )}
        <Box display="flex" justifyContent="flex-end" mt={2} pb={3}>
          <Pagination
            shape="rounded"
            siblingCount={1}
            className="custom-pagination"
            color="primary"
            count={pageCount}
            page={page + 1}
            showFirstButton={false}
            showLastButton={false}
            onChange={(event, value) => {
              onChangePage(value - 1);
            }}
          />
        </Box>
      </Box>
    </>
  );
};
AssignNarrativesStep.propTypes = {
  questionDraft: PropTypes.array,
  percentage: PropTypes.number,
  handleBack: PropTypes.func,
};
export default AssignNarrativesStep;
