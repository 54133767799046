import {
  Box,
  Grid,
  makeStyles,
  Tooltip,
  InputAdornment,
  TextField,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AppPagination from "_components/AppPagination";
import PropTypes from "prop-types";
import langConstant from "_lang";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PolicyServices from "../../../PolicyExplorer/services";
import ExecuteComparisonPopUp from "containers/manage-regulation/Policy/PolicyExplorer/component/ExecuteComparisonPopUp";
import { alertActions, loadingActions } from "_actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import TuneIcon from "@material-ui/icons/Tune";
import { Menu, MenuItem } from "@mui/material";
import FilterComponent from "./filterComponent";
import DataTable from "./dataTable";
import {
  baseDocumentViewColumns,
  currentStatusViewColumns,
  targetDocumentViewColumns,
  uploadedByViewColumns,
} from "./utils";
import GroupingData from "./groupingDataTable";
import MenuIcon from "@mui/icons-material/Menu";
import ActionMenu from "./actionMenu";
import SelectPageRange from "_components/SelectPageRange";
import TableChartIcon from "@mui/icons-material/TableChart";
import { useHistory } from "react-router-dom";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
    display: "inline-flex !important",
    borderRadius: "50% !important",
    padding: "10px !important",
    cursor: "pointer !important",
  },
}));

const ComparisonPolicyDataTable = ({ rows, setRows, userID }) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const allowedNavItems = useSelector(
    (state) => state.authentication?.userData?.allowedNavItems
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modifiedRows, setModifiedRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [groupHeaders, setGroupHeaders] = useState([]);
  const [comparisonPopUp, setComparisonPopUp] = useState(false);
  const [selectedComparisonMetaData, setSelectedComparisonMetaData] = useState(
    {}
  );
  const [filterPopUp, setFilterPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedComparison, setSelectedComparison] = useState({});

  const [baseDocumentOptions, setBaseDocumentOptions] = useState([]);

  const initialFilters = {
    text: "",
    isArchived: "0",
    baseDocument: "all",
    targetDocument: "all",
    uploadedBy: "all",
    currentStatus: "all",
    currentView: {
      value: "base_document_view",
      name: "Base Document View",
      label: langConstant.BASE_DOCUMENT,
      columnList: baseDocumentViewColumns,
      options: baseDocumentOptions,
      groupBy: "BASE_POLICY_ID",
      filterColumn: "baseDocument",
    },
  };
  const [filters, setFilters] = useState(initialFilters);
  const [targetDocumentOptions, setTargetDocumentOptions] = useState([]);
  const [uploadedByOptions, setUploadedByOptions] = useState([]);

  const open = Boolean(anchorEl);
  const viewHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const viewHandleClose = () => {
    setAnchorEl(null);
  };

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);
  const handleMenuClick = (event) => {
    event.preventDefault();
    setAnchorMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const currentStatusOptions = [
    { value: "DRAFT", name: "DRAFT" },
    { value: "REVIEW", name: "REVIEW" },
    { value: "COMPLETED", name: "COMPLETED" },
  ];

  const comparisonViewList = [
    {
      value: "normal_view",
      name: "Normal View",
      label: "",
      columnList: [],
      options: [],
      groupBy: "",
      filterColumn: "",
    },
    {
      value: "base_document_view",
      name: "Base Document View",
      label: langConstant.BASE_DOCUMENT,
      columnList: baseDocumentViewColumns,
      options: baseDocumentOptions,
      groupBy: "BASE_POLICY_ID",
      filterColumn: "baseDocument",
    },
    {
      value: "target_document_view",
      name: "Target Document View",
      label: langConstant.TARGET_DOCUMENT,
      columnList: targetDocumentViewColumns,
      options: targetDocumentOptions,
      groupBy: "TARGET_POLICY_ID",
      filterColumn: "targetDocument",
    },
    {
      value: "current_status_view",
      name: "Status View",
      label: langConstant.CURRENT_STATUS,
      columnList: currentStatusViewColumns,
      options: currentStatusOptions,
      groupBy: "CURRENT_STATUS",
      filterColumn: "currentStatus",
    },
    {
      value: "uploaded_by",
      name: "Uploaded By View",
      label: langConstant.UPLOADED_BY,
      columnList: uploadedByViewColumns,
      options: uploadedByOptions,
      groupBy: "UPLOADED_BY",
      filterColumn: "uploadedBy",
    },
  ];

  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(rows));

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [groupPage, setGroupPage] = useState(0);
  const [groupRowsPerPage, setGroupRowsPerPage] = useState(10);
  const calculateGroupPageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [groupPageCount, setGroupPageCount] = useState(0);

  const handleGroupChangePage = (event) => {
    setGroupPage(event);
  };

  const handleGroupChangeRowsPerPage = (event) => {
    setGroupRowsPerPage(parseInt(event.target.value, 10));
    setGroupPage(0);
  };

  const getComparisonMetaDataInfo = async (comparison) => {
    if (comparison["COMPARISON_META_DATA"]) {
      const response = await PolicyServices.getPolicyComparisonByComparisonId(
        comparison["POLICYCOMPARISONID"]
      );
      if (response.status === 200) {
        setSelectedComparisonMetaData(response["#result-set-1"][0]);
        setComparisonPopUp(true);
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    } else {
      dispatch(
        alertActions.error(langConstant.COMPARISON_META_DATA_NOT_AVAILABLE)
      );
    }
  };

  const handleArchiveChange = async (isChecked, comparisonId) => {
    dispatch(loadingActions.start());
    const response = await PolicyServices.updatePolicyComparisonArchiveStatus({
      userID: userID,
      policyComparisonID: comparisonId,
      status: isChecked ? 1 : 0,
    });
    dispatch(loadingActions.end());
    if (response.status === 200) {
      if (isChecked) {
        dispatch(
          alertActions.success(langConstant.ADDED_SUCCESSFULLY_TO_ARCHIVED)
        );
      } else {
        dispatch(
          alertActions.success(langConstant.REMOVED_SUCCESSFULLY_FROM_ARCHIVED)
        );
      }
      let tempAvailablePolicyComparison = [...rows];
      const comparisonIndex = tempAvailablePolicyComparison.findIndex(
        (item) => item.POLICYCOMPARISONID === comparisonId
      );
      tempAvailablePolicyComparison[comparisonIndex]["ARCHIVED"] = isChecked
        ? 1
        : 0;
      setRows(tempAvailablePolicyComparison);
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  useEffect(() => {
    const updatedRows =
      rows.length > 0
        ? rows.map((item) => {
            const data = item;
            let comparisonMetaData = data["COMPARISON_META_DATA"];
            let thresHold = null;
            if (comparisonMetaData) {
              comparisonMetaData = window.atob(comparisonMetaData);
              comparisonMetaData = JSON.parse(comparisonMetaData.toString());
              thresHold =
                comparisonMetaData["threshold"] &&
                comparisonMetaData["threshold"].length
                  ? comparisonMetaData["threshold"][0]
                  : null;
            }
            data["Comparison_status_Icon"] = (
              <Box>
                {item.Comparison_status === "Successful" ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon sx={{ color: "#c13636" }} />
                )}
              </Box>
            );
            data["ARCHIVED_STATUS"] = (
              <Switch
                checked={item["ARCHIVED"] == "1" ? true : false}
                onChange={(e) =>
                  handleArchiveChange(
                    e.target.checked,
                    data["POLICYCOMPARISONID"]
                  )
                }
              />
            );
            data["THRESHOLD"] =
              thresHold !== null ? (
                <span>{thresHold}</span>
              ) : (
                <span style={{ color: "red" }}>
                  {langConstant.NOT_AVAILABLE}
                </span>
              );

            data["ACTION"] = (
              <Box style={{ textAlign: "center" }}>
                <Tooltip title={langConstant.ACTIONS} placement="top">
                  <Box
                    className={classes.actionWrap}
                    style={{
                      backgroundColor:
                        themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                      margin: "0 auto",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      handleMenuClick(e);
                      setSelectedComparison(item);
                    }}
                  >
                    <MenuIcon
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        margin: "0 auto",
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            );
            return data;
          })
        : [];
    let uniqueBaseDocuments = [
      ...new Map(
        rows.map((item) => [
          item["BASE_POLICY_ID"],
          { value: item["BASE_POLICY_ID"], name: item["BASE_POLICY_NAME"] },
        ])
      ).values(),
    ];
    let uniqueTargetDocuments = [
      ...new Map(
        rows.map((item) => [
          item["TARGET_POLICY_ID"],
          { value: item["TARGET_POLICY_ID"], name: item["TARGET_POLICY_NAME"] },
        ])
      ).values(),
    ];
    let uniqueUploadedBy = [
      ...new Map(
        rows.map((item) => [
          item["UPLOADED_BY"],
          { value: item["UPLOADED_BY"], name: item["NAME"] },
        ])
      ).values(),
    ];
    setBaseDocumentOptions(uniqueBaseDocuments);
    setFilters((prevState) => ({
      ...prevState,
      currentView: { ...prevState.currentView, options: uniqueBaseDocuments },
    }));
    setTargetDocumentOptions(uniqueTargetDocuments);
    setUploadedByOptions(uniqueUploadedBy);
    setModifiedRows(updatedRows);
  }, [rows]);

  useEffect(() => {
    if (modifiedRows.length) {
      let tempModifiedRows = [...modifiedRows];
      if (filters.text) {
        tempModifiedRows = tempModifiedRows.filter((policy) =>
          policy.COMPARISON_NAME.toLowerCase().includes(
            filters.text.toLowerCase()
          )
        );
      }
      if (filters.isArchived !== "all") {
        if (filters.isArchived == "1") {
          tempModifiedRows = tempModifiedRows.filter(
            (policy) => policy["ARCHIVED"] == "1"
          );
        } else {
          tempModifiedRows = tempModifiedRows.filter(
            (policy) => policy["ARCHIVED"] != "1"
          );
        }
      }
      if (filters.baseDocument !== "all") {
        tempModifiedRows = tempModifiedRows.filter(
          (policy) => policy.BASE_POLICY_ID === filters.baseDocument
        );
      }
      if (filters.targetDocument !== "all") {
        tempModifiedRows = tempModifiedRows.filter(
          (policy) => policy.TARGET_POLICY_ID === filters.targetDocument
        );
      }
      if (filters.currentStatus !== "all") {
        tempModifiedRows = tempModifiedRows.filter(
          (policy) => policy.CURRENT_STATUS === filters.currentStatus
        );
      }
      if (filters.uploadedBy !== "all") {
        tempModifiedRows = tempModifiedRows.filter(
          (policy) => policy.UPLOADED_BY === filters.uploadedBy
        );
      }
      const totalPages =
        tempModifiedRows.length > 0
          ? Math.ceil(Math.min(tempModifiedRows.length / rowsPerPage))
          : 0;
      if (page + 1 > totalPages) {
        setPage(0);
        setFilteredRows(tempModifiedRows);
        setPageCount(calculatePageCount(tempModifiedRows));
      } else {
        setFilteredRows(tempModifiedRows);
        setPageCount(calculatePageCount(tempModifiedRows));
      }
    }
  }, [page, modifiedRows, filters]);

  const onFilterChange = (fieldName, e) => {
    setFilters((prevState) => ({ ...prevState, [fieldName]: e.target.value }));
  };

  const resetFilter = () => {
    setFilters(initialFilters);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredRows.length > 0
        ? Math.round(Math.ceil(filteredRows.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
  };

  const handleSelectGroupPageRange = (range) => {
    setGroupRowsPerPage(range.value);
    const totalPageCount =
      groupHeaders.length > 0
        ? Math.round(Math.ceil(groupHeaders.length / range.value))
        : 0;
    if (groupPage >= totalPageCount) {
      setGroupPage(totalPageCount - 1);
    }
    setGroupPageCount(totalPageCount);
  };

  return (
    <Box>
      <Grid container spacing={3} alignItems="center">
        {/* <Grid item xs={12} md={2}>
                    <h1>{langConstant.POLICY_COMPARISON}</h1>
                </Grid> */}
        <Grid item xs={12} md={4}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            id="searchText"
            name="searchText"
            placeholder="Filter Table"
            value={filters.text}
            onChange={(e) =>
              setFilters((prevState) => ({
                ...prevState,
                text: e.target.value,
              }))
            }
            InputProps={{
              autoComplete: "off",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={2} container>
          <Grid item md={3} xs={4}>
            <IconButton
              className={classes.iconButton}
              onClick={() => setFilterPopUp(true)}
            >
              <Tooltip title={langConstant.SET_FILTERS}>
                <TuneIcon
                  style={{
                    backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                    color: "white",
                  }}
                />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item md={3} xs={4}>
            <IconButton className={classes.iconButton}>
              <Tooltip title={langConstant.CHANGE_VIEW}>
                <ChangeCircleIcon
                  style={{
                    backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                    color: "white",
                  }}
                  onClick={viewHandleClick}
                />
              </Tooltip>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={viewHandleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {comparisonViewList.map((option, optionIndex) => {
                  return (
                    <MenuItem
                      style={{ fontWeight: "500" }}
                      key={optionIndex}
                      onClick={() => {
                        viewHandleClose();
                        setFilters({ ...filters, currentView: option });
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </IconButton>
          </Grid>
          {allowedNavItems &&
            allowedNavItems.includes("POLICY_COMPARISON_OVERVIEW") && (
              <Grid item md={3} xs={4}>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => history.push("/policy-comparison-overview")}
                >
                  <Tooltip title={langConstant.COMPARISON_OVERVIEW}>
                    <TableChartIcon
                      style={{
                        backgroundColor:
                          themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                        color: "white",
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </Grid>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {filters.currentView.value === "normal_view" ? (
            <>
              {pageCount > 1 && (
                <Box my={2}>
                  <Grid container justify="flex-end">
                    <Grid style={{ paddingBottom: "5px" }}>
                      <SelectPageRange
                        selectedRange={rowsPerPage}
                        handleChange={handleSelectPageRange}
                      />
                    </Grid>
                    <AppPagination
                      colSpan={3}
                      count={pageCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Grid>
                </Box>
              )}
            </>
          ) : (
            <>
              {groupPageCount > 1 && (
                <Box my={2}>
                  <Grid container justify="flex-end">
                    <Grid style={{ paddingBottom: "5px" }}>
                      <SelectPageRange
                        selectedRange={groupRowsPerPage}
                        handleChange={handleSelectGroupPageRange}
                      />
                    </Grid>
                    <AppPagination
                      colSpan={3}
                      count={groupPageCount}
                      rowsPerPage={groupRowsPerPage}
                      page={groupPage}
                      onChangePage={handleGroupChangePage}
                      onChangeRowsPerPage={handleGroupChangeRowsPerPage}
                    />
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Box>
        {filters.currentView.value === "normal_view" ? (
          <DataTable
            filteredRows={filteredRows}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        ) : (
          <GroupingData
            filteredRows={filteredRows}
            filters={filters}
            setGroupPageCount={setGroupPageCount}
            setGroupPage={setGroupPage}
            groupPage={groupPage}
            groupRowsPerPage={groupRowsPerPage}
            calculateGroupPageCount={calculateGroupPageCount}
            groupHeaders={groupHeaders}
            setGroupHeaders={setGroupHeaders}
          />
        )}
        {comparisonPopUp && (
          <ExecuteComparisonPopUp
            show={comparisonPopUp}
            setShow={setComparisonPopUp}
            reRunMode={true}
            metaData={selectedComparisonMetaData}
          />
        )}
      </Box>
      {filterPopUp && (
        <FilterComponent
          show={filterPopUp}
          setShow={setFilterPopUp}
          filterData={filters}
          onFilterChange={onFilterChange}
          baseDocumentOptions={baseDocumentOptions}
          targetDocumentOptions={targetDocumentOptions}
          uploadedByOptions={uploadedByOptions}
          resetFilter={resetFilter}
        />
      )}
      {openMenu && (
        <ActionMenu
          anchorMenuEl={anchorMenuEl}
          handleMenuClose={handleMenuClose}
          openMenu={openMenu}
          selectedComparison={selectedComparison}
          getComparisonMetaDataInfo={getComparisonMetaDataInfo}
        />
      )}
    </Box>
  );
};

ComparisonPolicyDataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  setRows: PropTypes.func,
  userID: PropTypes.string,
};

export default ComparisonPolicyDataTable;
