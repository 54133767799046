import DialogBox from "_components/DialogBox";
import { UseModalPropType } from "_hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Box,
  Button,
  makeStyles,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@mui/material/TextField";
import FilterByStatus from "_components/MasterAssessmentComp/steps/components/TabFilter";
import TabPanel from "_components/MasterAssessmentComp/steps/components/TabPanel";
import AssessmentTabs from "_components/MasterAssessmentComp/component/AssessmentTabs";
import PropTypes from "prop-types";
import langConstant from "_lang";
import ProgressBarLarge from "_components/ProgressBarLarge";
import CustomizedSlider from "_components/MasterAssessmentComp/component/SliderGraph";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import planActionsServices from "../../services";
import ToolBar from "_components/MasterAssessmentComp/component/ToolBar";
import ObservationDialogBox from "_components/MasterAssessmentComp/component/ObservationDialogBox";
import TrustScoreDialogBox from "_components/MasterAssessmentComp/component/TrustScoreDialogBox";
import TabsContent from "_components/MasterAssessmentComp/component/TabsContent";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Divider from "@mui/material/Divider";
import _ from "lodash";
import {
  getCommentsList,
  getQuestionDataQualityPoints,
  getEvidenceForMasterAssessment,
  getDataAssestsForMasterAssessment,
  UpdateAuditorQuestionStatus,
  updateRFI,
  updateQuestionStatusForRfiDialog,
  GetAuditObservationforQuestion,
  GetQuestionResponsesForAssessments,
  saveSingleQuestion,
  updateComplianceDecision,
} from "_components/MasterAssessmentComp/utils/actions";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { increaseActiveStep } from "_components/Stepper/action";
import sessionService from "_services/session.service";
import moment from "moment";
import MailIcon from "@material-ui/icons/Mail";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import LockIcon from "@material-ui/icons/Lock";
import RestorePageIcon from "@material-ui/icons/RestorePage";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CheckIcon from "@material-ui/icons/Check";
import RfiDialogBox from "_components/MasterAssessmentComp/component/RfiDialogBox";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import Autocomplete from "@mui/material/Autocomplete";
import storeProcedure from "_components/MasterAssessmentComp/steps/services";
import { alertActions } from "_actions";
import { RightPanelTabs } from "_components/MasterAssessmentComp/component/RightPanelTabs";
import { useModal } from "_hooks";
import { AddActionDialog } from "_components/MasterAssessmentComp/component/AddActionDialog";
import {
  MasterAssessmentConstants,
  QUESTION_STATUS_TYPES,
} from "_components/MasterAssessmentComp/utils/constants";
import getActionService from "containers/manage-regulation/Policy/PolicyExplorer/services";
import { useParams } from "react-router-dom";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles((theme) => ({
  height100: {
    height: "100%",
  },
  width100: {
    width: "100%",
  },
  marginTop: {
    marginTop: "10px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  statusicon: {
    fontSize: 20,
    marginRight: "5px",

    "&.COMPLETED": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.RFI_SENT": {
      color: "#105BA1",
    },

    "&.RFI_RESOLVED": {
      color: "#1a751a",
    },

    "&.RFI_PENDING": {
      color: "#895514",
    },

    RFI_OVERDUE: {
      color: "#d62000",
    },

    "&.SENT_BACK": {
      color: "rgba(240, 20, 47, 1)",
    },

    "&.FINAL": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.PENDING_DATA": {
      color: "rgba(187, 174, 0, 1)",
    },

    "&.READY_FOR_REVIEW": {
      color: "rgba(1, 148, 25, 1)",
    },

    "&.COMPLETED_AND_LOCKED": {
      color: "rgba(0, 159, 26, 1)",
    },
  },
}));

const initialTabs = [
  { name: langConstant.OBJECTIVES, count: 0 },
  { name: langConstant.GUIDANCE, count: 0 },
  { name: langConstant.ARCHITECTURE, count: 0 },
];

const BottomTabsArray = [
  { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },
  { name: "PAST YEAR + 1", count: 0, isCount: true, id: 1 },
  { name: "PAST YEAR + 2", count: 0, isCount: true, id: 2 },
  { name: "PAST YEAR + 3", count: 0, isCount: true, id: 3 },
  { name: "DATA ASSETS", count: 0, isCount: false, id: 4 },
  { name: "EVIDENCES", count: 0, isCount: false, id: 5 },
  { name: "Responses and narratives", count: 0, isCount: true, id: 6 },
];

const View = ({ modal }) => {
  const TopTabsArray = modal ? modal.data.TopTabsArray : [];
  const QuestionList = modal?.data.filteredRows;
  const selRow = modal?.data.selRow;

  const questionData = {
    QUESTION_STATUS: selRow.QUESTION_STATUS,
    QUESTION_ID: selRow.REGULATION_REFERENCE,
    SUBMISSION_ID: selRow.SUBMISSION_ID,
  };
  const observationList = modal?.data.observationList;
  const identifier = modal?.data.identifier;
  const RfiitemsList = modal?.data.RfiitemsList;
  const setPlanActionsDataLength = modal?.data.setPlanActionsDataLength;
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState({
    name: "",
    id: 0,
  });

  const [TopSelectedTab, setTopSelectedTab] = useState(TopTabsArray[0]);
  const [BottomTabs, setBottomTabs] = useState(BottomTabsArray);
  const [BottomSelectedTab, setBottomSelectedTab] = useState(
    BottomTabsArray[0]
  );
  const [page, setPage] = useState(0);
  const [filteredQuestionList, setfilteredQuestionList] =
    useState(QuestionList);
  const [selectedQuestion, setSelectedQuestion] = useState({
    ...selRow,
    QUESTION_ID: selRow.REGULATION_REFERENCE,
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [score, setScore] = useState(0);
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [ObservationDialog, setObservationDialog] = useState({
    visible: false,
    mode: "",
    ObservationList: {},
  });
  const [QuestionResponses, setQuestionResponses] = useState([]);
  const [DecisionSelectedOption, setDecisionSelectedOption] = useState(null);
  const [rfiNarrative, setRfiNarrative] = useState(null);
  const [value, setValue] = React.useState(0);
  const commentsList = useSelector(
    (state) => state.MasterAssessment.AssessmentComments
  );
  const DataPointsList = useSelector(
    (state) => state.MasterAssessment.QuestionDataPoints
  );
  const EvidenceList = useSelector(
    (state) => state.MasterAssessment.EvidencesList
  );
  const DataAssetsList = useSelector(
    (state) => state.MasterAssessment.DataAssetsList
  );
  const themeDetails = useSelector(getThemeDetails);
  const ArrayOfQuestionIDOfObservations = observationList?.map((el) =>
    el.QUESTION_ID.trim()
  );
  const currentRfi = RfiitemsList?.find(
    (rfi) =>
      rfi.REGULATORS_QUESTION_REF === selectedQuestion?.REGULATORS_QUESTION_REF
  );
  const addActionModal = useModal();
  const { sid: SUBMISSION_ID, id: ASSESSMENT_ID } = useParams();

  const actions = useSelector((state) => state?.MasterAssessment?.ActionsList),
    assessmentDetails = useSelector(
      (state) => state?.MyAssessmnetReducer?.resultSet2
    )?.[0],
    assessmentKpiData = useSelector(
      (state) => state?.MyAssessmnetReducer?.AssessmentKpiData
    )?.[0],
    approversList = useSelector(
      (state) => state?.MyAssessmnetReducer?.ApproversList
    ),
    regulatorsList = useSelector(
      (state) => state?.MyAssessmnetReducer?.RegulatorsList
    );

  const onChange = (e, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        setSelectedTab({ name: langConstant.OBJECTIVES, id: 0 });
        break;
      case 1:
        setSelectedTab({ name: langConstant.GUIDANCE, id: 1 });
        break;
      case 2:
        setSelectedTab({ name: langConstant.ARCHITECTURE, id: 2 });
        break;
    }
  };
  const StatusTagObject = {
    RFI_SENT: {
      statusBackground: "rgba(123, 192, 255, 1)",
      statusTag: langConstant.RFI_SENT,
      icon: <MailIcon className={`${classes.statusicon} RFI_SENT`} />,
    },
    RFI_PENDING: {
      statusBackground: "rgba(255, 248, 156, 1)",
      statusTag: langConstant.RFI_PENDING,
      icon: <HelpOutlineIcon className={`${classes.statusicon} RFI_PENDING`} />,
    },
    RFI_OVERDUE: {
      statusBackground: "rgba(255, 188, 188, 1)",
      statusTag: langConstant.RFI_OVERDUE,
      icon: (
        <ReportProblemIcon className={`${classes.statusicon} RFI_OVERDUE`} />
      ),
    },
    RFI_RESOLVED: {
      statusBackground: "rgba(175, 243, 187, 0.8)",
      statusTag: langConstant.RFI_RESOLVED,
      icon: (
        <CheckOutlinedIcon className={`${classes.statusicon} RFI_RESOLVED`} />
      ),
    },
    SENT_BACK: {
      statusBackground: "rgba(255, 188, 188, 1)",
      statusTag: langConstant.SENT_BACK,
      icon: <ReplyAllIcon className={`${classes.statusicon} SENT_BACK`} />,
    },
    FINAL: {
      statusBackground: "rgba(175, 243, 187, 1)",
      statusTag: langConstant.FINAL,
      icon: <ThumbUpAltIcon className={`${classes.statusicon} FINAL`} />,
    },
    PENDING_DATA: {
      statusBackground: "rgba(255, 248, 156, 1)",
      statusTag: langConstant.PENDING_DATA,
      icon: (
        <RestorePageIcon className={`${classes.statusicon} PENDING_DATA`} />
      ),
    },
    READY_FOR_REVIEW: {
      statusBackground: "rgba(175, 243, 187, 0.7)",
      statusTag: langConstant.READY_FOR_REVIEW,
      icon: (
        <FlashOnIcon className={`${classes.statusicon} READY_FOR_REVIEW`} />
      ),
    },
    COMPLETED_AND_LOCKED: {
      statusBackground: "rgba(175, 243, 187, 1)",
      statusTag: langConstant.COMPLETED_AND_LOCKED,
      icon: (
        <LockIcon className={`${classes.statusicon} COMPLETED_AND_LOCKED`} />
      ),
    },
    COMPLETED: {
      statusBackground: "rgba(175, 243, 187, 1)",
      statusTag: langConstant.COMPLETED,
      icon: <LockIcon className={`${classes.statusicon} COMPLETED`} />,
    },
  };

  const CardBox = (identifier, currentQuestion) => {
    return (
      <Card variant="outlined" style={{ height: "100%" }}>
        <CardContent style={{ padding: "5px", height: "100%" }}>
          <PerfectScrollbar>
            {identifier === 1 && (
              <div style={{ display: "flex", fontSize: "15px" }}>
                <div>
                  {" "}
                  <span>
                    {" "}
                    <strong>
                      {currentQuestion?.REGULATORS_QUESTION_REF}
                    </strong>{" "}
                    -
                  </span>
                  &nbsp;{currentQuestion?.QUESTION_TEXT}
                </div>
              </div>
            )}

            {identifier === 2 && (
              <div style={{ fontSize: "15px" }}>
                <Box pb={2}>
                  <FilterByStatus
                    selectedTab={selectedTab}
                    onChange={onChange}
                    tabs={tabs}
                  />
                </Box>{" "}
                <TabPanel value={value} index={0}>
                  {currentQuestion?.OBJECTIVE_TEXT}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {currentQuestion?.GUIDANCE_TEXT}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {currentQuestion?.ARCHITECTURE_TEXT}
                </TabPanel>
              </div>
            )}

            {identifier === 3 && (
              <TabsContent
                bottomTabs={true}
                selectedTab={BottomSelectedTab}
                EvidenceList={EvidenceList}
                DataAssetsList={DataAssetsList}
                currentQuestion={selectedQuestion}
                rfiNarrative={rfiNarrative}
                isQuestionEditable={true}
                SaveAnswerFromRichTextEditor={SaveAnswerFromRichTextEditor}
                currentRfi={currentRfi}
                showAlert={showAlert}
              />
            )}
          </PerfectScrollbar>
        </CardContent>
      </Card>
    );
  };

  const saveComplianceDecision = () => {
    const complainceCallback = () => {
      const data = [...QuestionList].map((que) => {
        if (
          `${que.REGULATION_REFERENCE}` === `${selectedQuestion.QUESTION_ID}`
        ) {
          return {
            ...que,
            QUESTION_COMPLIANCE_DECISION:
              DecisionSelectedOption?.RESPONSE_OPTION,
          };
        }
        return que;
      });

      UpdateMyAssessmentQuestionArray(data);
    };

    dispatch(
      updateComplianceDecision(
        selectedQuestion,
        DecisionSelectedOption?.RESPONSE_OPTION,
        complainceCallback
      )
    );
  };

  const getQuestionResponse = async () => {
    const data = await dispatch(
      GetQuestionResponsesForAssessments(
        selectedQuestion?.QUESTION_ID,
        customerID
      )
    );
    if (data?.length > 0) {
      setQuestionResponses(data);
      const tempdata = data.find(
        (el) =>
          el.RESPONSE_OPTION?.trim() ===
          selectedQuestion?.QUESTION_COMPLIANCE_DECISION?.trim()
      );
      setDecisionSelectedOption(tempdata ? tempdata : null);
    } else {
      setQuestionResponses([]);
    }
  };

  const showAlert = (error, type = "error") => {
    dispatch(
      alertActions?.[type](
        error?.data?.message?.map((item, index) => {
          return <span key={index}>{item}</span>;
        }) ?? error?.toString()
      )
    );
  };

  const getRfiNarrativeByApi = async () => {
    const toNumber = (num) => {
      if (!num) return null;
      return Number(num);
    };
    const payload = {
      i_RFI_ID: toNumber(currentRfi?.RFI_ID),
      i_QUESTION_ID: toNumber(selectedQuestion?.QUESTION_ID),
      i_SUBMISSION_ID: toNumber(selectedQuestion?.SUBMISSION_ID),
    };
    await storeProcedure
      .getRfiNarrative(payload)
      .then((response) => {
        if (response?.status !== 200) return showAlert(response);
        setRfiNarrative(response?.["#result-set-1"]);
      })
      .catch((error) => {
        showAlert(error);
        console.log("error", error);
      });
  };

  const onBottomTabChange = (e, value) => {
    setBottomSelectedTab(BottomTabsArray[value]);
  };

  const SaveAnswerFromRichTextEditor = (answer) => {
    let currentQuestion = { ...selectedQuestion };
    currentQuestion.CURRENT_ANSWER_SELECTED_RESPONSE = answer;
    setSelectedQuestion({
      ...selectedQuestion,
      CURRENT_ANSWER_SELECTED_RESPONSE: answer,
    });
    const RichTextCallback = () => {
      const data = [...QuestionList].map((que) => {
        if (
          `${que.REGULATION_REFERENCE}` === `${selectedQuestion.QUESTION_ID}`
        ) {
          return { ...currentQuestion };
        }
        return que;
      });

      UpdateMyAssessmentQuestionArray(data);
    };

    dispatch(saveSingleQuestion(currentQuestion, RichTextCallback));
  };

  const UpdateMyAssessmentQuestionArray = (data) => {
    setfilteredQuestionList(data);
    dispatch({ type: "SET_MY_ASSESSMENT_QUESTIONS", payload: data });
  };
  const onAddAction = () => {
    addActionModal.setData(selectedQuestion);
    addActionModal.openModal();
  };
  const getPercentage = (data) => {
    const total = data?.length || 0;
    // if (IsAudit) {
    //   const completed = data.filter(
    //     (que) =>
    //       que.QUESTION_STATUS === "AUDIT_REVIEWED" ||
    //       que.QUESTION_STATUS === "FINAL"
    //   );
    //   if (completed.length > 0) {
    //     let count = (completed.length * 100) / total;
    //     count =
    //       count === 100 || count === 0 ? count : parseFloat(count.toFixed(2));
    //     return count;
    //   } else {
    //     return 0;
    //   }
    // }

    if (identifier === "myassessment") {
      const completedQuestions = data?.filter(
          (q) =>
            q?.QUESTION_STATUS?.trim() === "COMPLETED" ||
            q?.QUESTION_STATUS?.trim() === "COMPLETED_AND_LOCKED"
        ),
        completionPercentage =
          (Number(completedQuestions?.length || 0) / total) * 100;
      return completionPercentage?.toFixed(2) || 0;
    }
  };

  const getActions = async () => {
    if (!SUBMISSION_ID || !userID) return;
    await getActionService
      ?.getActions({
        i_SUBMISSION_ID: SUBMISSION_ID,
        i_USER_ID: userID,
      })
      .then((response) => {
        if (response?.status !== 200)
          return showAlert(response || "Something went wrong");
        dispatch({
          type: MasterAssessmentConstants.SET_ACTIONS_LIST,
          payload: response?.["#result-set-1"],
        });
      })
      .catch((error) => {
        console.error("error", error);
        showAlert(error);
      });
  };

  const MyAssessmentToolbar = () => {
    return (
      <>
        {selectedQuestion.QUESTION_STATUS && (
          <Box
            wrap="nowrap"
            spacing={1}
            display={"flex"}
            justifyContent={"space-between"}
            className={`${classes.width100} ${classes.height100}`}
          >
            <Box display={"flex"}>
              {selectedQuestion.QUESTION_STATUS !== "DRAFT" && (
                <Box
                  style={{
                    backgroundColor:
                      StatusTagObject[selectedQuestion.QUESTION_STATUS]
                        ?.statusBackground,
                    padding: "5px 10px",
                    borderRadius: "4px",
                    marginRight: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {StatusTagObject[selectedQuestion.QUESTION_STATUS]?.icon}
                  <Typography variant="body2" style={{ color: "black" }}>
                    {StatusTagObject[
                      selectedQuestion.QUESTION_STATUS
                    ]?.statusTag.toUpperCase()}
                  </Typography>
                </Box>
              )}

              {(selectedQuestion.QUESTION_STATUS === "DRAFT" ||
                selectedQuestion.QUESTION_STATUS === "RFI_RESOLVED" ||
                selectedQuestion.QUESTION_STATUS === "SENT_BACK" ||
                selectedQuestion.QUESTION_STATUS ===
                  "COMPLETED_AND_LOCKED") && (
                <Box
                  style={{
                    width: "190px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    color="success"
                    inputProps={{ "aria-label": "Mark as Completed" }}
                    checked={
                      selectedQuestion.QUESTION_STATE === "1" ? true : false
                    }
                    onClick={(e) => {
                      setPlanActionsDataLength((length) => length - 1);
                      MyAssessmentCheckboxClick(e.target.checked);
                    }}
                  />
                  <Typography>{langConstant.MARK_AS_COMPLETED}</Typography>
                </Box>
              )}

              {selectedQuestion.QUESTION_STATUS != "DRAFT" &&
                selectedQuestion.QUESTION_STATUS != "RFI_RESOLVED" &&
                selectedQuestion.QUESTION_STATUS != "SENT_BACK" &&
                selectedQuestion.QUESTION_STATUS != "FINAL" &&
                selectedQuestion.QUESTION_STATUS != "READY_FOR_REVIEW" &&
                selectedQuestion.QUESTION_STATUS != "PENDING_DATA" &&
                selectedQuestion.QUESTION_STATUS != "COMPLETED_AND_LOCKED" &&
                selectedQuestion.QUESTION_STATUS != "COMPLETED" && (
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ color: "#777", marginRight: "10px" }}>
                      {langConstant.DUE_DATE}
                    </Typography>
                    <Typography style={{ color: "#000", marginRight: "20px" }}>
                      {GetRfiDueDate()}
                    </Typography>
                  </Box>
                )}

              {selectedQuestion.QUESTION_STATUS == "RFI_OVERDUE" &&
                GetRfiPastDueDate()}
            </Box>

            <Box
              wrap="nowrap"
              style={{ gap: 8 }}
              spacing={1}
              display={"flex"}
              alignItems="center"
            >
              {selectedQuestion?.OBS_COUNT !== null &&
                selectedQuestion?.OBS_COUNT !== 0 &&
                selectedQuestion?.OBS_COUNT > 0 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      OpenObservationDialogInViewMode();
                    }}
                    style={{
                      borderRadius: "50px",
                      height: "35px",
                    }}
                    startIcon={<EditIcon style={{ fontSize: "20px" }} />}
                  >
                    VIEW OBSERVATION
                  </Button>
                )}

              {selectedQuestion.QUESTION_STATUS !== "DRAFT" &&
                selectedQuestion.QUESTION_STATUS !== "RFI_RESOLVED" &&
                selectedQuestion.QUESTION_STATUS !== "SENT_BACK" &&
                selectedQuestion.QUESTION_STATUS !== "FINAL" &&
                selectedQuestion.QUESTION_STATUS !== "READY_FOR_REVIEW" &&
                selectedQuestion.QUESTION_STATUS !== "PENDING_DATA" &&
                selectedQuestion.QUESTION_STATUS !== "COMPLETED" &&
                selectedQuestion.QUESTION_STATUS !== "COMPLETED_AND_LOCKED" && (
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    style={{
                      borderRadius: "50px",
                      height: "35px",
                    }}
                    onClick={() => {
                      dispatch(
                        confirmationDialogActions.open({
                          message: langConstant.RESOLVE_RFI_PROMPT_FN(
                            selectedQuestion.REGULATORS_QUESTION_REF
                          ),
                          onConfirm: () => {
                            dispatch(
                              updateRFI(
                                selectedQuestion,
                                "RFI_RESOLVED",
                                moment(new Date()).format(
                                  "yyyy-MM-DD hh:mm:ss"
                                ),
                                null,
                                null,
                                RfiitemsList,
                                currentRfi?.RFI_ID
                              )
                            );
                            dispatch(
                              updateQuestionStatusForRfiDialog(
                                selectedQuestion.QUESTION_ID,
                                selectedQuestion.SUBMISSION_ID,
                                "RFI_RESOLVED",
                                userID
                              )
                            );
                            const data = [...QuestionList].map((el) => {
                              if (
                                `${el.REGULATORS_QUESTION_REF}_${el.QUESTION_ID}` ===
                                `${selectedQuestion.REGULATORS_QUESTION_REF}_${selectedQuestion.QUESTION_ID}`
                              ) {
                                return {
                                  ...el,
                                  QUESTION_STATUS: "RFI_RESOLVED",
                                };
                              }

                              return el;
                            });

                            UpdateMyAssessmentQuestionArray(data);
                          },
                          title: langConstant.RESOLVE_RFI_CONFIRMATION,
                          option1: langConstant.YES_PLEASE,
                          option2: langConstant.NO_THANKS,
                        })
                      );
                    }}
                  >
                    <CheckIcon
                      style={{ fontSize: "20px", marginRight: "10px" }}
                    />
                    {langConstant.RESOLVE_RFI}
                  </Button>
                )}
              <Button
                onClick={onAddAction}
                style={{ borderRadius: "50px", height: "35px" }}
                variant="contained"
                color="primary"
                title="Add Action"
              >
                Add Action
              </Button>
              <RfiDialogBox questionData={questionData} />
            </Box>
          </Box>
        )}
      </>
    );
  };

  const AuditorToolbar = () => {
    return (
      <>
        <Box style={{ width: "190px", display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={
              selectedQuestion.QUESTION_STATUS === "AUDIT_REVIEWED" ||
              selectedQuestion.QUESTION_STATUS === "FINAL"
            }
            color="success"
            inputProps={{ "aria-label": "Mark as Reviewed" }}
            onClick={() => {
              QuestionStatusUpdater("AUDIT_REVIEWED");
            }}
            disabled={
              selectedQuestion.QUESTION_STATUS === "AUDIT_REVIEWED" ||
              selectedQuestion.QUESTION_STATUS === "FINAL"
            }
          />
          <Typography>{langConstant.MARK_AS_REVIEWED}</Typography>
        </Box>

        <Box>
          {!ArrayOfQuestionIDOfObservations?.includes(
            selectedQuestion?.QUESTION_ID?.trim()
          ) && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setObservationDialog({
                  visible: true,
                  mode: "add",
                  ObservationList: {},
                });
              }}
              style={{
                borderRadius: "50px",
                height: "35px",
              }}
              startIcon={<EditIcon style={{ fontSize: "20px" }} />}
            >
              ADD OBSERVATION
            </Button>
          )}

          {ArrayOfQuestionIDOfObservations?.includes(
            selectedQuestion?.QUESTION_ID?.trim()
          ) && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setObservationDialog({
                  visible: true,
                  mode: "edit",
                  ObservationList: observationList.find(
                    (el) => el.QUESTION_ID === selectedQuestion.QUESTION_ID
                  ),
                });
              }}
              style={{
                borderRadius: "50px",
                height: "35px",
              }}
              startIcon={<EditIcon style={{ fontSize: "20px" }} />}
            >
              Edit OBSERVATION
            </Button>
          )}
        </Box>
      </>
    );
  };

  const GetRfiDueDate = () => {
    const currentRfi = [...RfiitemsList].find(
      (rfi) =>
        rfi.REGULATORS_QUESTION_REF === selectedQuestion.REGULATORS_QUESTION_REF
    );

    if (currentRfi !== undefined) {
      return moment(new Date(currentRfi.RFI_DUE_DATE.slice(0, 10))).format(
        "DD-MMM-YY"
      );
    }
  };

  const GetRfiPastDueDate = () => {
    const currentRfi = [...RfiitemsList].find(
      (rfi) =>
        rfi.REGULATORS_QUESTION_REF === selectedQuestion.REGULATORS_QUESTION_REF
    );

    if (currentRfi !== undefined) {
      let now = moment(new Date()).format("DD-MMM-YY h:mm:ss a");
      let duePlusOne = new Date(currentRfi.RFI_DUE_DATE);
      duePlusOne.setDate(duePlusOne.getDate() + 1);
      let dateToCheck = moment(duePlusOne).format("DD-MMM-YY h:mm:ss a");

      if (moment(dateToCheck).isBefore(now)) {
        let pastDate = moment(dateToCheck, "DD-MMM-YY")
          .fromNow()
          .split(" ")
          .slice(0, 2)
          .join(" ");

        return (
          <Typography
            variant="caption"
            style={{
              color: "#ff1616",
              marginTop: "8px",
              marginRight: "20px",
            }}
          >
            {pastDate}&nbsp;{langConstant.PAST_DUE_DATE}
          </Typography>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  const QuestionStatusUpdater = (status) => {
    let dataobj = {
      i_QUESTION_ID: selectedQuestion?.QUESTION_ID,
      i_SUBMISSION_ID: selectedQuestion?.SUBMISSION_ID,
      i_QUESTION_STATUS: status,
      i_USER_ID: userID,
    };
    dispatch(
      UpdateAuditorQuestionStatus(
        dataobj,
        QuestionList,
        selectedQuestion,
        () => {},
        false,
        identifier
      )
    );
  };

  const ScoreSetterFunc = () => {
    if (DataPointsList.length === 0) {
      return setScore(0);
    }
    950;
    let average = 0;
    average =
      (_.sumBy(DataPointsList, "TRUST_SCORE") / DataPointsList.length / 125) *
      100;
    average = Math.round(average);
    setScore(average);
  };

  const OpenObservationDialogInViewMode = async () => {
    const obsData = await dispatch(
      GetAuditObservationforQuestion(
        selectedQuestion?.SUBMISSION_ID,
        selectedQuestion?.QUESTION_ID
      )
    );
    setObservationDialog({
      visible: true,
      mode: "view",
      ObservationList: obsData[0],
    });
  };

  const MyAssessmentCheckboxClick = (checked) => {
    let currentQuestion = { ...selectedQuestion };

    if (checked) {
      currentQuestion.QUESTION_STATE = "1";
      currentQuestion.QUESTION_STATUS = "COMPLETED";
      setSelectedQuestion({
        ...selectedQuestion,
        QUESTION_STATE: "1",
        QUESTION_STATUS: "COMPLETED",
      });
    } else {
      currentQuestion.QUESTION_STATE = "0";
      setSelectedQuestion({
        ...selectedQuestion,
        QUESTION_STATE: "0",
      });
    }

    const MyAssessmentCallback = () => {
      const data = [...QuestionList].map((que) => {
        if (
          `${que.REGULATION_REFERENCE}` === `${selectedQuestion.QUESTION_ID}`
        ) {
          return { ...currentQuestion };
        }
        return que;
      });

      UpdateMyAssessmentQuestionArray(data);
    };

    dispatch(saveSingleQuestion(currentQuestion, MyAssessmentCallback));
  };

  const getSelectedQuestionDetails = async () => {
    try {
      const response = await planActionsServices.GetSubmissionQuestionDetails(
        selectedQuestion.QUESTION_ID,
        selectedQuestion.SUBMISSION_ID
      );
      if (response?.status !== 200) throw new Error("Unable to fetch data");
      else if (response && response?.["#result-set-1"][0]) {
        const tempSelectedQuestion = response?.["#result-set-1"][0];

        setSelectedQuestion({
          ...tempSelectedQuestion,
          SUBMISSION_ID: selectedQuestion.SUBMISSION_ID,
        });

        let currQue = { ...tempSelectedQuestion };

        let tempArr = [
          { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },
          {
            name: "PAST YEAR + 1",
            count: currQue?.HIST_ANSWER_RESPONSE_01
              ? currQue?.HIST_ANSWER_MATCH_SCORE_01
              : 0,
            isCount: true,
            id: 1,
          },
          {
            name: "PAST YEAR + 2",
            count: currQue?.HIST_ANSWER_RESPONSE_02
              ? currQue?.HIST_ANSWER_MATCH_SCORE_02
              : 0,
            isCount: true,
            id: 2,
          },
          {
            name: "PAST YEAR + 3",
            count: currQue?.HIST_ANSWER_RESPONSE_03
              ? currQue?.HIST_ANSWER_MATCH_SCORE_03
              : 0,
            isCount: true,
            id: 3,
          },
          {
            name: "DATA ASSETS",
            count: DataAssetsList.length,
            isCount: false,
            id: 4,
          },
          {
            name: "EVIDENCES",
            count: EvidenceList.length,
            isCount: false,
            id: 5,
          },
          { ...BottomTabsArray?.[6], count: rfiNarrative?.length },
        ];

        setBottomTabs(tempArr);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    getSelectedQuestionDetails();

    const index = _.findIndex(filteredQuestionList, (e) => {
      return e.REGULATION_REFERENCE == selectedQuestion.QUESTION_ID;
    });
    dispatch(
      getCommentsList(
        selectedQuestion.QUESTION_ID,
        selectedQuestion.SUBMISSION_ID,
        true
      )
    );

    dispatch(
      getEvidenceForMasterAssessment(
        selectedQuestion.QUESTION_ID,
        selectedQuestion.SUBMISSION_ID
      )
    );
    dispatch(getDataAssestsForMasterAssessment(selectedQuestion.QUESTION_ID));
    selectedQuestion.QUESTION_ID &&
      currentRfi?.RFI_ID &&
      getRfiNarrativeByApi();
  }, [page, filteredQuestionList, currentRfi]);

  useEffect(() => {
    setQuestionResponses([]);
    setDecisionSelectedOption(null);
    if (filteredQuestionList[page]?.REGULATION_REFERENCE) {
      getQuestionResponse();
    }
  }, [page, filteredQuestionList]);

  useEffect(() => {
    getActions();
  }, []);

  useEffect(() => {
    if (selectedQuestion) {
      let questionText = selectedQuestion?.ARCHITECTURE_TEXT;
      const filterTabs = _.cloneDeep(initialTabs);

      //setting the values for Guidance Text, Objectives Text and Architecture Text
      if (questionText != null) {
        filterTabs[2].count = 1;
        setSelectedTab({ name: langConstant.ARCHITECTURE, id: 2 });
        setValue(2);
      }
      questionText = selectedQuestion?.GUIDANCE_TEXT;
      if (questionText != null) {
        filterTabs[1].count = 1;
        setSelectedTab({ name: langConstant.GUIDANCE, id: 1 });
        setValue(1);
      }
      questionText = selectedQuestion?.OBJECTIVE_TEXT;
      if (questionText != null) {
        filterTabs[0].count = 1;
        setSelectedTab({ name: langConstant.OBJECTIVES, id: 0 });
        setValue(0);
      }

      setTabs(filterTabs);
      setCompletedPercentage(getPercentage(QuestionList));
    }
  }, []);

  useEffect(() => {
    ScoreSetterFunc();
  }, [DataPointsList]);

  // useEffect(() => {
  //   if (selectedQuestion) {
  //     let currQue = selectedQuestion;

  //     let tempArr = [
  //       { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },
  //       {
  //         name: "PAST YEAR + 1",
  //         count: currQue?.HIST_ANSWER_RESPONSE_01
  //           ? currQue?.HIST_ANSWER_MATCH_SCORE_01
  //           : 0,
  //         isCount: true,
  //         id: 1,
  //       },
  //       {
  //         name: "PAST YEAR + 2",
  //         count: currQue?.HIST_ANSWER_RESPONSE_02
  //           ? currQue?.HIST_ANSWER_MATCH_SCORE_02
  //           : 0,
  //         isCount: true,
  //         id: 2,
  //       },
  //       {
  //         name: "PAST YEAR + 3",
  //         count: currQue?.HIST_ANSWER_RESPONSE_03
  //           ? currQue?.HIST_ANSWER_MATCH_SCORE_03
  //           : 0,
  //         isCount: true,
  //         id: 3,
  //       },
  //       {
  //         name: "DATA ASSETS",
  //         count: DataAssetsList.length,
  //         isCount: false,
  //         id: 4,
  //       },
  //       {
  //         name: "EVIDENCES",
  //         count: EvidenceList.length,
  //         isCount: false,
  //         id: 5,
  //       },
  //       { ...BottomTabsArray?.[6], count: rfiNarrative?.length },
  //     ];

  //     setBottomTabs(tempArr);
  //   }
  // }, [EvidenceList, DataAssetsList, rfiNarrative]);

  return (
    <DialogBox
      fullWidth
      maxWidth="lg"
      title={
        selRow?.REGULATION_REFERENCE
          ? selRow.REGULATION_REFERENCE
          : selectedQuestion?.QUESTION_ID
      }
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableEnforceFocus
      disableAutoFocus
      PaperProps={{
        style: {
          height: "80vh",
        },
      }}
    >
      <>
        <AddActionDialog addActionModal={addActionModal} />
        <Grid
          item
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
          }}
        >
          <IconButton aria-label="close" onClick={modal?.closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Box
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ width: "72%" }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "160px",
              }}
              className={classes.marginTop}
            >
              <Box style={{ width: "55%" }}>{CardBox(1, selectedQuestion)}</Box>
              <Box style={{ width: "44%" }}>{CardBox(2, selectedQuestion)}</Box>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 42,
              }}
              className={classes.marginTop}
            >
              {identifier === "audit" && AuditorToolbar()}
              {identifier === "myassessment" && MyAssessmentToolbar()}
            </Box>

            <>
              {" "}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  alignItems: "center",
                }}
                className={classes.marginTop}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "40%",
                  }}
                >
                  <Box>
                    <Typography>Decision : </Typography>
                  </Box>
                  <Box style={{ width: "60%" }}>
                    {/* <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={
                        selectedQuestion.QUESTION_COMPLIANCE_DECISION
                          ? selectedQuestion.QUESTION_COMPLIANCE_DECISION
                          : "No Decision Selected"
                      }
                      className={classes.width100}
                      inputProps={{ readOnly: true }}
                    /> */}

                    <Autocomplete
                      size="small"
                      className={`${classes.width100}`}
                      autoHighlight
                      openOnFocus
                      id="checkboxes-tags-demo"
                      options={QuestionResponses}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Search" />
                      )}
                      getOptionLabel={(option) =>
                        option.RESPONSE_OPTION ? option.RESPONSE_OPTION : ""
                      }
                      onChange={(e, val) => {
                        setDecisionSelectedOption(val);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.RESPONSE_OPTION?.trim() ===
                        value?.RESPONSE_OPTION?.trim()
                      }
                      value={DecisionSelectedOption}
                      disabled={
                        selectedQuestion.QUESTION_STATUS ===
                        "COMPLETED_AND_LOCKED"
                      }
                    />
                  </Box>
                  <Box style={{ width: "17%" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<SaveIcon />}
                      onClick={saveComplianceDecision}
                      disabled={
                        DecisionSelectedOption?.RESPONSE_OPTION ===
                          selectedQuestion?.QUESTION_COMPLIANCE_DECISION ||
                        DecisionSelectedOption === null
                      }
                    >
                      {langConstant.SAVE}
                    </Button>
                  </Box>
                </Box>

                <Box
                  style={{ height: "85px", width: "35%", marginRight: "5px" }}
                >
                  <Box
                    style={{
                      height: "60px",
                      display: "flex",
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    <CustomizedSlider score={score} />
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>Data Trust Score</Box>

                    {DataPointsList.length > 0 && (
                      <Box
                        className="linkIcon"
                        style={{ display: "flex", justifyContent: "end" }}
                        onClick={() => {
                          setOpenPopup(true);
                        }}
                      >
                        {langConstant.VIEW_DETAIL}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box>
                <AssessmentTabs
                  selectedTab={BottomSelectedTab}
                  onChange={onBottomTabChange}
                  tabs={BottomTabs}
                  bottomTabs={true}
                  currentQuestion={selectedQuestion}
                />
              </Box>
              <Box style={{ height: "calc(100vh - 611px)" }}>
                <Box style={{ width: "100%", height: "100%" }}>
                  {CardBox(3)}
                </Box>
              </Box>
            </>
          </Box>

          <>
            <Divider
              style={{ height: "calc(100vh - 197px)" }}
              orientation="vertical"
              flexItem
            />
            <Box style={{ width: "25%" }}>
              <Box className={classes.marginTop}>
                <ProgressBarLarge
                  percentage={parseInt(completedPercentage)}
                  size="small"
                />
              </Box>

              <Box className={classes.marginTop}>
                <ToolBar selectedQuestion={selectedQuestion} />
              </Box>

              <RightPanelTabs
                commentsList={commentsList}
                selectedQuestion={selectedQuestion}
                getActions={getActions}
              />
            </Box>
          </>
        </Box>
        <TrustScoreDialogBox
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          DataQualityProfile={DataPointsList}
        />

        {ObservationDialog.visible &&
          (identifier === "audit" || identifier === "myassessment") && (
            <ObservationDialogBox
              Controls={selectedQuestion?.CISO_CONTROL_NAME}
              submissionID={selectedQuestion?.SUBMISSION_ID}
              questionID={selectedQuestion?.QUESTION_ID}
              questionText={selectedQuestion?.QUESTION_TEXT}
              miscData={ObservationDialog}
              setObservationDialog={setObservationDialog}
              TotalObservationList={observationList}
            />
          )}
      </>
    </DialogBox>
  );
};

View.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default View;
