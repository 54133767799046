import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Slide,
} from "@mui/material";
import PropTypes from "prop-types";
import ChatServices from "./services";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import Select from "react-select";
import SettingsIcon from "@mui/icons-material/Settings";
import SplitterSummaryForm from "./splitterSummaryForm";
import SplittedDataList from "./splittedDataList";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@material-ui/core";
import langConstant from "_lang";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentSplitter = ({
  open,
  setOpen,
  selectedPolicy,
  updateChatBotSplittingStatus,
  setIsChatBoxAvailable,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [splitterData, setSplitterData] = useState([]);
  const [dbNames, setDbNames] = useState([]);
  const [embeddingData, setEmbeddingData] = useState([]);
  const [selectedSplitter, setSelectedSplitter] = useState({});
  const [splittedDataList, setSplittedDataList] = useState([]);
  const [isOpenSetting, setIsOpenSetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [initialValues, setInitialValues] = useState({
    CHUNK_SIZE: "600",
    CHUNK_OVERLAP: "100",
    DATABASE: "2",
    DATABASE_COLLECTION: "my_information3",
    EMBEDDING: "1",
  });

  const dispatch = useDispatch();
  const classes = useStyles();
  const formRef = useRef();

  useEffect(() => {
    (async () => {
      let tempSplitterData = [];
      let tempDbData = [];
      let tempEmbedding = [];
      const splitterResponse = await ChatServices.getSplitterDetails();
      if (splitterResponse.status === 200) {
        splitterResponse["#result-set-1"].forEach((element) =>
          tempSplitterData.push({
            value: element.SPLITTER_ID,
            label: element.SPLITTER_NAME,
          })
        );
        setSelectedSplitter(tempSplitterData[0]);
        setSplitterData(tempSplitterData);
      } else {
        dispatch(alertActions.error("Splitter Service Failed"));
      }
      const dbResponse = await ChatServices.getVectorDatabasedetails();
      if (dbResponse.status === 200) {
        dbResponse["#result-set-1"].forEach((element) =>
          tempDbData.push({
            value: element.VECTOR_DATABASE_ID,
            label: element.VECTOR_DATABASE_NAME,
          })
        );
        setDbNames(tempDbData);
      } else {
        dispatch(alertActions.error("Vector Database Service Failed"));
      }
      const embeddingResponse = await ChatServices.getEmbaddingDetails();
      if (embeddingResponse.status === 200) {
        embeddingResponse["#result-set-1"].forEach((element) =>
          tempEmbedding.push({
            value: element.EMBADDING_ID,
            label: element.EMBADDING_NAME,
          })
        );
        setEmbeddingData(tempEmbedding);
      } else {
        dispatch(alertActions.error("Embedding Service Failed"));
      }
    })();
  }, []);

  const handleClose = () => {
    if (currentStep == 2) {
      setIsChatBoxAvailable(true);
    }
    setOpen(false);
  };

  const onNextClick = async () => {
    if (currentStep === 1) {
      if (splittedDataList.length > 0) {
        setCurrentStep(2);
      } else {
        if (isOpenSetting) {
          if (formRef.current.isValid) {
            const splitResponse = await performSplitting();
            splitResponse ? setCurrentStep(2) : setCurrentStep(1);
            if (splitResponse && updateChatBotSplittingStatus) {
              updateChatBotSplittingStatus();
            }
          }
        } else {
          const splitResponse = await performSplitting();
          splitResponse ? setCurrentStep(2) : setCurrentStep(1);
          if (splitResponse && updateChatBotSplittingStatus) {
            updateChatBotSplittingStatus();
          }
        }
      }
    } else if (currentStep === 2) {
      handleClose();
    }
  };

  const onPrevClick = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    }
  };

  const performSplitting = async () => {
    const payload = {
      FolderPath: selectedPolicy.STORAGE_FOLDER_URL,
      FileName: selectedPolicy.POLICY_FILE_NAME,
      ChunkSize: initialValues.CHUNK_SIZE,
      ChunkOverlap: initialValues.CHUNK_OVERLAP,
      PolicyID: selectedPolicy.POLICY_ID,
      SplitterID: parseInt(selectedSplitter.value),
      EmbeddingID: parseInt(initialValues.EMBEDDING),
      DbID: parseInt(initialValues.DATABASE),
      docStorageType: docStorageType.toLowerCase(),
      docStorageIP: docStorageIP,
      docStorageURL: docStorageURL,
    };
    setIsLoading(true);
    const response = await ChatServices.performSplits(payload);
    setIsLoading(false);
    if (response.status) {
      if (response.status == 200) {
        if (
          response.data &&
          response.data.Splits &&
          response.data.Splits.length
        ) {
          setSplittedDataList(response.data.Splits);
          return true;
        } else {
          dispatch(alertActions.error("Splitting Data Not Available"));
          return false;
        }
      }
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
      return false;
    }
  };

  const openSettingMenu = () => {
    if (isOpenSetting) {
      if (formRef && formRef.current.isValid) {
        setIsOpenSetting(false);
      } else {
        dispatch(alertActions.error("Please Fill Correct Details"));
      }
    } else {
      setIsOpenSetting(true);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={currentStep === 2 || isOpenSetting ? "md" : "sm"}
      fullWidth={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {isLoading && (
        <Grid className={classes.progressWraper}>
          {" "}
          <CircularProgress className={classes.progressBar} />{" "}
        </Grid>
      )}
      <DialogTitle sx={{ position: "relative" }}>
        <Grid container>
          <Grid item md={9} xs={8}>
            Regulativ Policy Splitter
          </Grid>
          {currentStep === 1 && (
            <Grid item md={2} xs={3} className={classes.contentRight}>
              <SettingsIcon
                onClick={() => openSettingMenu()}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          )}
          <Grid
            item
            md={currentStep !== 1 ? 3 : 1}
            xs={currentStep !== 1 ? 4 : 1}
            className={classes.contentRight}
          >
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container>
            <Grid item md={isOpenSetting ? 4 : 9}>
              {currentStep === 1 && (
                <Select
                  options={splitterData}
                  value={selectedSplitter}
                  onChange={(splitter) => setSelectedSplitter(splitter)}
                  menuPortalTarget={document.body}
                  placeholder="Select Splitter"
                />
              )}
            </Grid>
            {currentStep === 1 && isOpenSetting && (
              <Grid
                item
                md={7}
                style={{ paddingLeft: "10px", display: "flex" }}
              >
                <Divider orientation="vertical" />
                <SplitterSummaryForm
                  dbNames={dbNames}
                  embeddingData={embeddingData}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  formRef={formRef}
                />
              </Grid>
            )}
          </Grid>
          {currentStep === 2 && (
            <Grid>
              <SplittedDataList splittedDataList={splittedDataList} />
            </Grid>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={currentStep === 1 ? onNextClick : onPrevClick}
        >
          {currentStep === 1 ? "Next" : "Previous"}
        </Button>
        {currentStep === 2 && (
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

DocumentSplitter.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  selectedPolicy: PropTypes.object,
  updateChatBotSplittingStatus: PropTypes.func,
  setIsChatBoxAvailable: PropTypes.func,
};

export default DocumentSplitter;
