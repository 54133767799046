import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import PropTypes from "prop-types";
import Regulators from "./Regulators";
import Regulations from "./Regulations";
import { useDispatch } from "react-redux";
import AdminServices from "../../services";

const RegulatorRegulations = ({ selectedCustomer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [regulatorsList, setRegulatorsList] = useState(null);
  const [regulationsList, setRegulationsList] = useState(null);
  const [allRegulatorsList, setAllRegulatorsList] = useState(null);
  const [allRegulationsList, setAllRegulationsList] = useState(null);
  const [selectedRegulator, setSelectedRegulator] = useState(null);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(
        AdminServices.getCustomerRegulatorRegulations(
          selectedCustomer.value,
          setRegulatorsList,
          setRegulationsList
        )
      );
      dispatch(
        AdminServices.getRegulatorRegulations(
          setAllRegulationsList,
          setAllRegulatorsList
        )
      );
    }
  }, [selectedCustomer]);

  return (
    <Grid container spacing={2} style={{ height: "calc( 100vh - 300px )" }}>
      <Grid item md={5} xs={12}>
        <Regulators
          regulatorsList={regulatorsList}
          setRegulatorsList={setRegulatorsList}
          allRegulatorsList={allRegulatorsList}
          selectedCustomer={selectedCustomer}
          regulationsList={regulationsList}
          setRegulationsList={setRegulationsList}
          selectedRegulator={selectedRegulator}
          setSelectedRegulator={setSelectedRegulator}
        />
      </Grid>
      <Grid item md={7} xs={12}>
        <Regulations
          regulationsList={regulationsList}
          setRegulationsList={setRegulationsList}
          selectedCustomer={selectedCustomer}
          selectedRegulator={selectedRegulator}
        />
      </Grid>
    </Grid>
  );
};

RegulatorRegulations.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default RegulatorRegulations;
