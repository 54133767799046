/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React from "react";
import constant from "./constants";
import apiService from "_services/api.service";
import { alertActions, loadingActions } from "_actions";

const getMQBAssessments = (id, customerID) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetMQBAssessments", {
        i_REGULATION_ID: id,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          const detail =
            response && response["#result-set-1"]
              ? response["#result-set-1"][0]
              : {};
          const list =
            response && response["#result-set-2"]
              ? response["#result-set-2"]
              : [];

          dispatch({
            type: constant.manageQuestionsConstant_GET,
            data: { questionList: list, regulationDetail: detail },
          });
          dispatch(loadingActions.end());
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
    //dispatch({ type: constant.manageQuestionsConstant_GET, data: draft })
  };
};

const putNewQuestion = (data) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutNewQuestions", data)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          dispatch(getMQBAssessments(data.i_REGULATION_ID, data.i_CUSTOMER_ID));
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getRegulationPolicyMap = (data, setPolicyMappingData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRegulationPolicyMap", data)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          setPolicyMappingData(response["#result-set-1"]);
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const putRegulationPolicyMap = (data, setIsMappingView) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutRegulationPolicyMap", data)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          setIsMappingView(true);
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getNetworkData = (
  networkID,
  customerID,
  startRange,
  endRange,
  setNetworkData
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetNetworkData", {
        i_CUSTOMER_ID: customerID,
        i_REGULATION_ID: networkID,
        i_START_RANGE: startRange / 100,
        i_END_RANGE: endRange / 100,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setNetworkData(response["#result-set-1"]);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

export default {
  getMQBAssessments,
  putNewQuestion,
  getRegulationPolicyMap,
  putRegulationPolicyMap,
  getNetworkData,
};
