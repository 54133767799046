/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import QuestionItem from "./QuestionItem";

const QuestionsPanel = ({ questionsList, setPageNumber }) => {
  const statusList = [
    "COMPLETED",
    "DRAFT",
    "PENDING_DATA",
    "READY_FOR_REVIEW",
    "FINAL",
    "COMPLETED_AND_LOCKED",
    "RFI_SENT",
    "RFI_PENDING",
    "RFI_OVERDUE",
    "RFI_RESOLVED",
    "SENT_BACK",
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "10px 30px 50px 30px",
        // margin: "70px 0px 150px 0px",
        boxShadow: "2px 2px 2px rgba(0,0,0,0.2)",
        overflowY: "auto",
      }}
    >
      {/* //* Map through statuses */}
      {statusList.map((status, index) => {
        let matches = 0;
        return (
          <Box key={status}>
            <Typography style={{ padding: "30px 0 0px 0" }} key={index}>
              {status && status.replace(/_/g, " ").replace(/&/g, "AND")}
            </Typography>
            <Box
              style={{
                display: "flex",
                width: "400px",
                flexWrap: "wrap",
              }}
            >
              {status == "COMPLETED"
                ? questionsList &&
                  questionsList.map((question, index) => {
                    if (question.QUESTION_STATE == 1) {
                      matches++;
                      return (
                        <QuestionItem
                          key={question + index}
                          question={question}
                          setPageNumber={setPageNumber}
                          questionIndex={index}
                          completed
                        />
                      );
                    }
                  })
                : questionsList &&
                  questionsList.map((question, index) => {
                    if (question.QUESTION_STATUS == status) {
                      matches++;
                      return (
                        <QuestionItem
                          key={question + index}
                          question={question}
                          setPageNumber={setPageNumber}
                          questionIndex={index}
                        />
                      );
                    }
                  })}
              {/*//* For completed STATE  */}

              {/* //* Map through questions */}
            </Box>
            <Typography>
              {/* //*Print if no questions match for status */}
              {!matches ? "No questions match this status" : ""}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

QuestionsPanel.propTypes = {
  questionsList: PropTypes.array,
  setPageNumber: PropTypes.func,
};

export default QuestionsPanel;
