/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  Fab,
} from "@material-ui/core";

import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import SaveIcon from "@material-ui/icons/Save";
import langConstant from "_lang";
import rootStyles from "rootStyles";
import ReviewAssessmet from "./component/steps/ReviewAssessment";
import ProgressBarLarge from "_components/ProgressBarLarge";
import ApproveAssessment from "./component/steps/ApproveAssessment";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styles from "./styles";
import initiateAssessmentAction from "./actions";
import constants from "./constants";
import {
  decreaseActiveStep,
  increaseActiveStep,
  setSteps,
} from "_components/Stepper/action";
import Pagination from "@material-ui/lab/Pagination";
import ActionBar from "_components/MasterAssessmentComp/component/ActionBar";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: theme.shadows[1],
  },
}));
const InitiateSupplierAssessment = () => {
  const classes = styles();

  const activeStep = useSelector((state) => state.stepper.activestep);
  const vrmAssesmentData = useSelector(
    (state) => state.vendorRiskManagementAssesmentDetails
  );

  // const [activeStep, setActiveStep] = React.useState(0);
  const [isOneOfChecked, setIsOneOfChecked] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const questionList = vrmAssesmentData.questionList;
  const supplierData = vrmAssesmentData.supplierData;
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const history = useHistory();
  const { id, rid } = useParams();
  const dispatch = useDispatch();
  // const steps = [
  //   langConstant.REVIEW_VRM_ASSESSMENT,
  //   langConstant.APPROVE_ASSESSMENT,
  // ];

  const steps = [
    { stepname: langConstant.REVIEW_VRM_ASSESSMENT, compID: 1 },
    { stepname: langConstant.APPROVE_ASSESSMENT, compID: 2 },
  ];

  const handleNext = () => {
    dispatch(increaseActiveStep());
    //saveToDraft()
  };

  const handleBack = () => {
    dispatch(decreaseActiveStep());
  };

  const goToVRMAssessment = () => {
    history.push("/vendor-assessment");
  };
  const updateAssignedStatus = () => {
    const assignedByUserID = questionList[0].assessmentObj[0].USER_ID;
    dispatch(
      initiateAssessmentAction.updateAssignedStatus(
        id,
        rid,
        assignedByUserID,
        "PENDING_FOR_REVIEW",
        goToVRMAssessment
      )
    );
  };

  const getPercentage = (data) => {
    const total = data.length;
    const completed = _.filter(data, { status: "ACCEPTED" });
    if (completed.length > 0) {
      let count = (completed.length * 100) / total;
      count =
        count === 100 || count === 0 ? count : parseFloat(count.toFixed(2));
      return count;
    } else {
      return 0;
    }
  };

  const onChangePage = (value) => {
    setPage(value);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <ReviewAssessmet
            questionList={questionList}
            completedPercentage={completedPercentage}
            page={page}
            setPage={setPage}
            setPageCount={setPageCount}
          />
        );
      default:
        return <ApproveAssessment questionList={questionList} />;
    }
  };
  const goBackOnError = () => {
    history.goBack();
  };
  useEffect(() => {
    dispatch(setSteps(steps));
    if (history.location.state) {
      const status = history.location.state;
      dispatch(
        initiateAssessmentAction.checkAssignmentStatus(
          id,
          status,
          goBackOnError
        )
      );
    } else {
      goBackOnError();
    }
    return dispatch({
      type: constants.initiateAssessmentConstant_EMPTY,
    });
  }, []);
  useEffect(() => {
    if (questionList && questionList.length > 0) {
      setPageCount(questionList.length);
      setList(questionList);
      setCompletedPercentage(getPercentage(questionList));
    }
  }, [questionList]);
  if (list.length == 0) {
    return <></>;
  }
  return (
    <Box>
      {supplierData && (
        <Box style={{ display: "flex", fontSize: "1.2rem" }}>
          <Box style={{ display: "flex", width: "50%" }}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography style={{ display: "flex", width: "100%" }}>
                    <span>{langConstant.CATEGORY}:</span>
                    <span style={{ marginLeft: "0.5rem" }}>
                      {supplierData.SUPPLIER_CATEGORY}
                    </span>
                  </Typography>
                  <Typography style={{ display: "flex", width: "100%" }}>
                    <span>{langConstant.DURATION}</span>
                    <span style={{ marginLeft: "0.5rem" }}>
                      {supplierData.SUPPLIER_CONTRACT_DURATION}
                    </span>
                  </Typography>
                  <Typography style={{ display: "flex", width: "100%" }}>
                    <span>{langConstant.SERVICE}</span>
                    <span style={{ marginLeft: "0.5rem" }}>
                      {supplierData.SUPPLIER_PRODUCT_SERVICE_PROVIDED}
                    </span>
                  </Typography>
                  <Typography style={{ display: "flex", width: "100%" }}>
                    <span>{langConstant.ADDRESS}</span>
                    <span style={{ marginLeft: "0.5rem" }}>
                      {supplierData.SUPPLIER_ADDRESS}
                    </span>
                  </Typography>
                </React.Fragment>
              }
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {" "}
                <strong>{langConstant.SUPPLIER} </strong>
                <strong style={{ marginLeft: "0.5rem" }}>
                  {supplierData.SUPPLIER_NAME}
                </strong>
                <span style={{ marginLeft: "0.5rem" }}>
                  {supplierData.SUPPLIER_RISK_RATING === "M" ? (
                    <Box>
                      <Fab
                        size="small"
                        style={{
                          boxShadow: "none",
                          color: "white",
                          backgroundColor: "orange",
                          borderColor: "orange",
                        }}
                      >
                        {supplierData.SUPPLIER_RISK_RATING}
                      </Fab>
                    </Box>
                  ) : supplierData.SUPPLIER_RISK_RATING === "L" ? (
                    <Box>
                      <Fab
                        size="small"
                        style={{
                          boxShadow: "none",
                          color: "green",
                          backgroundColor: "#aff3bb",
                          borderColor: "#aff3bb",
                        }}
                      >
                        {supplierData.SUPPLIER_RISK_RATING}
                      </Fab>{" "}
                    </Box>
                  ) : supplierData.SUPPLIER_RISK_RATING === "H" ? (
                    <Box>
                      <Fab
                        size="small"
                        style={{
                          color: "red",
                          backgroundColor: "#ffbcbc",
                          borderColor: "#ffbcbc",
                          boxShadow: "none",
                        }}
                      >
                        {supplierData.SUPPLIER_RISK_RATING}
                      </Fab>
                    </Box>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </HtmlTooltip>
          </Box>
        </Box>
      )}

      <div className={classes.root}>
        {/* <Stepper
          activeStep={activeStep}
          alternativeLabel
          className="custom-stepper"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper> */}
        <div>
          {/* {activeStep == 0 && <ProgressBarLarge percentage={completedPercentage} />} */}
          <Box mb={8} mt={1}>
            {getStepContent(activeStep)}
          </Box>
          <ActionBar
            onChangePage={onChangePage}
            page={page}
            pageCount={pageCount}
            isPagination={activeStep === 0 ? true : false}
            PrimaryBtn={{
              btnicon: <SubdirectoryArrowRightIcon />,
              btntext:
                activeStep == 1
                  ? langConstant.SEND_FOR_REVIEW
                  : langConstant.CONTINUE,
              onbtnclick: activeStep == 1 ? updateAssignedStatus : handleNext,
              btndisabled: completedPercentage !== 100,
            }}
          />
          {/* <Box className="fixed-action-bar" p={2} style={{ display: "flex" }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item style={{ width: "80px" }}></Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={completedPercentage != '100'}
                  startIcon={<SubdirectoryArrowRightIcon />}
                  onClick={activeStep == 1 ? updateAssignedStatus : handleNext}
                >
                  {activeStep == 1
                    ? langConstant.SEND_FOR_REVIEW
                    : langConstant.CONTINUE}
                </Button>
              </Grid>
              <Grid item>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  {langConstant.GO_BACK}
                </Button>
              </Grid>
            </Grid>
            <Grid container style={{ justifyContent: "flex-end", marginRight: "80px" }}>
              <Box display="flex" justifyContent="space-between">
                <Pagination
                  shape="rounded"
                  siblingCount={1}
                  className="custom-pagination"
                  color="primary"
                  count={pageCount}
                  page={page + 1}
                  showFirstButton={false}
                  showLastButton={false}
                  onChange={(event, value) => {
                    onChangePage(value - 1);
                  }}
                />
              </Box>
            </Grid>
          </Box> */}
        </div>
      </div>
    </Box>
  );
};

export default InitiateSupplierAssessment;
