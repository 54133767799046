// OP#3052 - UJC5.1 - Assign Assessment - Extension Features
// EmailDialog component for the step2 screen


import React, { useEffect, useState } from "react";
import { Typography, Button, Grid, Box, makeStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Formik, Field, ErrorMessage, Form, } from "formik";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import AutocompleteEmail from "./AutocompleteForEmail"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import * as yup from "yup";
// import RichTextEditor from "_components/RichTextEditor/RichTextEditor";
import { GetCustomerInfoClassfication } from "../../../../../../user-administration/ConfigurationManagement/components/EvidenceTypes/services"

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px"
  },
  AddressGrid: {
    marginBottom: "20px",
  },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EmailDialog = ({ EditEmailBox, setEditEmailBox, userID, FinalSourceArray }) => {

  const classes = useStyles();
  const [initialFormData, setInitialFormData] = useState();
  const [classificationOptions, setclassificationOptions] = useState([]);

  const getClassification = async () => {
    const data = await GetCustomerInfoClassfication(userID);

    if (data["#result-set-1"] && data.status === 200) {
      let options = []
      data["#result-set-1"].map((item) => {
        options.push({ value: item.INFO_CLASSIFICATION_ID, label: item.DESCRIPTION })
      });
      setclassificationOptions(options)

    }
  }

  useEffect(() => {
    setInitialFormData(EditEmailBox.data)
  }, [EditEmailBox.data])

  useEffect(() => {
    getClassification();
  }, []);

  const validationSchema = yup.object({
    EMAIL_SUBJECT: yup.string().required("required"),
    EMAIL_BODY: yup.string().required("required"),
    EMAIL_CLASSIFICATION: yup.object().test("EMAIL_CLASSIFICATION", "required", (item, Context) => {
      if (Object.keys(item).length === 0) {
        return false;
      }

      return true;
    }).required("required"),
  });

  const SubmitEmailData = (values, { resetForm }) => {
    setEditEmailBox({ visible: false, data: values })
  }

  // const saveAnswerFunction = (data) => {
  //   console.log(data);
  // }

  return (
    <Dialog fullWidth maxWidth="md" open={EditEmailBox.visible} onClose={() => { setEditEmailBox((prev) => ({ ...prev, visible: false })) }}>
      <Formik
        validateOnChange={true}
        initialValues={initialFormData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={SubmitEmailData}
      >
        {({ values, setFieldValue, resetForm, touched, errors }) => (
          <Form>

            <DialogContent>
              <Box>
                <Grid container className={classes.AddressGrid} style={{ alignItems: "center" }}>
                  <Grid item md={2} >
                    <Typography>
                      To :
                  </Typography>
                  </Grid>

                  <Grid item md={10} >

                    <Field name="EMAIL_TO">
                      {({ field: { value } }) => (
                        <span style={{ fontSize: "14px", fontWeight: "400" }}>{value}</span>
                      )}
                    </Field>
                    <ErrorMessage name="EMAIL_TO">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid} style={{ alignItems: "center" }}>
                  <Grid item md={2} >
                    <Typography>
                      Cc :
                  </Typography>
                  </Grid>

                  <Grid item md={10} >

                    <Field name="EMAIL_CC">
                      {({ field: { value } }) => (
                        <AutocompleteEmail value={value} onChangeValue={(val) => { setFieldValue("EMAIL_CC", val) }} placeholder="Cc" />
                      )}
                    </Field>

                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid} style={{ alignItems: "center" }}>
                  <Grid item md={2} >
                    <Typography>
                      Bcc :
                  </Typography>
                  </Grid>

                  <Grid item md={10} >

                    <Field name="EMAIL_BCC">
                      {({ field: { value } }) => (
                        <AutocompleteEmail value={value} onChangeValue={(val) => { setFieldValue("EMAIL_BCC", val) }} placeholder="Bcc" />
                      )}
                    </Field>

                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid} style={{ alignItems: "center" }}>
                  <Grid item md={2}>
                    <Typography >
                      Subject :
                  </Typography>
                  </Grid>
                  <Grid md={10} item >

                    <Field name="EMAIL_SUBJECT">
                      {({ field: { value } }) => (
                        <TextField
                          fullWidth
                          value={value}
                          className={classes.width100}
                          onChange={(e) => setFieldValue("EMAIL_SUBJECT", e.target.value)}
                          variant="outlined"
                          size="small"
                          placeholder="Subject"
                          error={touched.EMAIL_SUBJECT && Boolean(errors.EMAIL_SUBJECT)}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="EMAIL_SUBJECT">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid} style={{ alignItems: "center" }}>
                  <Grid item md={2}>
                    <Typography >
                      Classification :
                  </Typography>
                  </Grid>
                  <Grid md={10} item >

                    <Field name="EMAIL_CLASSIFICATION">
                      {({ field: { value } }) => (
                        <Select
                          value={value}
                          fullWidth
                          onChange={(e, val) => setFieldValue("EMAIL_CLASSIFICATION", e.target.value)}
                          style={{ height: 44 }}
                          displayEmpty
                          renderValue={(selected) => {
                            if (Object.keys(selected).length === 0 || selected === undefined) {
                              return <span style={{ color: "#BBB6C4" }}>Select</span>;
                            }
                            return selected.label;
                          }}
                          MenuProps={MenuProps}
                          error={touched.EMAIL_CLASSIFICATION && Boolean(errors.EMAIL_CLASSIFICATION)}
                        >
                          {classificationOptions.map((el) => (
                            <MenuItem key={el.value} value={el}>
                              <ListItemText primary={el.label} />
                              <Checkbox checked={value?.value === el.value} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                    <ErrorMessage name="EMAIL_CLASSIFICATION">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid} style={{ alignItems: "center" }}>

                  <Field name="EMAIL_BODY" >
                    {({ field: { value }, form: { errors } }) => (
                      <TextField
                        size="small"
                        multiline
                        fullWidth
                        variant="outlined"
                        rows={7}
                        style={{ marginTop: "10px" }}
                        onChange={(e) => setFieldValue("EMAIL_BODY", e.target.value)}
                        value={value}
                        placeholder="Email Body"
                        error={touched.EMAIL_BODY && Boolean(errors.EMAIL_BODY)}

                      />
                      // <RichTextEditor data={"hello"} onSaveClick={saveAnswerFunction} />
                    )}
                  </Field>
                  <ErrorMessage name="EMAIL_BODY">
                    {(msg) => (
                      <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => {
                setEditEmailBox((prev) => ({
                  visible: false,
                  data: {
                    EMAIL_TO: prev.data.EMAIL_TO,
                    EMAIL_CC: [],
                    EMAIL_BCC: [],
                    EMAIL_SUBJECT: "",
                    EMAIL_CLASSIFICATION: {},
                    EMAIL_BODY: "",
                  }
                }))
              }}>Cancel</Button>
              <Button color="primary" type="submit" >Save</Button>
            </DialogActions>


          </Form>
        )}
      </Formik>
    </Dialog >

  )
}

EmailDialog.propTypes = {
  setEditEmailBox: PropTypes.func,
  EditEmailBox: PropTypes.object,
  userID: PropTypes.any,
  FinalSourceArray: PropTypes.array
};

export default EmailDialog