import apiService from "_services/api.service";
import React from "react";

const getAssessmentAuditInformation = ( submissionId ) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetAssessmentAuditInformation",{i_SUBMISSION_ID: submissionId})
      .then(apiService.handleResponse)
      .then((response) => {
        if(response)
        {
          response['status'] = 200
          return response
        }
      },
      (error) => {
        if(error.response) {
          return error.response
        }
      }
    );
};

export default {
    getAssessmentAuditInformation
}