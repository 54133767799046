/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import { globalConstants } from "../_constants";

const loaderText = {
  text: null
}

const loading = (state = false, action) => {
  switch (action.type) {
    case globalConstants.GLOBAL_REQUEST_START:
      return true;
    case globalConstants.GLOBAL_REQUEST_END:
      return false;
    default:
      return state;
  }
};

const loaderTextReducer = (state = loaderText, action) => {
  switch (action.type) {
    case globalConstants.GLOBAL_LOADER_TEXT:
      return {
        ...state,
        text: action.data
      }
    default:
      return state;
  }
}

const loadingFile = (state = false, action) => {
  switch (action.type) {
    case globalConstants.GLOBAL_REQUEST_FILE_UPLOAD_START:
      return true;
    case globalConstants.GLOBAL_REQUEST_FILE_UPLOAD_END:
      return false;
    default:
      return state;
  }
};

export default {
  loading,
  loadingFile,
  loaderTextReducer,
};
