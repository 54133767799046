import React, { useEffect, useState } from "react";
import { InsertLink } from "@mui/icons-material";
import { Box, Button } from "@material-ui/core";
import RichTextEditor from "_components/RichTextEditor/RichTextEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import langConstant from "_lang";
import propTypes from "prop-types";
import questionApiService from "../../../../steps/services";
import individualQuestionAction from "containers/manage-regulation/NewRegulation/IndividualQuestion/actions";
import ReactDomServer from "react-dom/server";
import moment from "moment";
import sessionService from "_services/session.service";
import { useParams } from "react-router-dom";

export const EditAnswer = ({
  currentQuestion,
  onSaveAnswer,
  currentRfi,
  showAlert,
  onClose,
}) => {
  const [ApiDataObj, setApiDataObj] = useState({});
  const [narrativeTemplate, setNarrativeTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState();
  const questionID = currentQuestion?.QUESTION_ID,
    customerID = sessionService.getCustomerId();
  const { id } = useParams();

  const renderRulesHtmlText = () => {
    let responsedata = ApiDataObj["Rule Decision Prefix Text"]
      ? ApiDataObj["Rule Decision Prefix Text"][0]?.RESPONSE_VALUE
      : "";
    let data = responsedata?.replace("@RuleDecision", responsedata);
    return data;
  };

  const createNarativeTemplate = (stringArr) => {
    let template = [];
    stringArr?.forEach?.((i) => {
      switch (i?.toLowerCase()) {
        case "@questiontext":
          template.push(
            ReactDomServer.renderToString(
              <p>
                {currentQuestion?.QUESTION_TEXT}{" "}
                {currentQuestion?.REGULATORS_QUESTION_REF} -{" "}
                <strong>{currentQuestion?.QUESTION_TEXT_HTML}</strong>
              </p>
            )
          );
          break;
        case "@auditinfo":
          template.push(
            ReactDomServer.renderToString(
              <p>
                This answer was generated on{" "}
                {moment().utc().format("MMMM DD, YYYY")} at{" "}
                {moment().utc().format("h.mm A")}
              </p>
            )
          );
          break;
        case "@ruledecisionprefixtext":
          template.push(
            ReactDomServer.renderToString(<p>{renderRulesHtmlText()}</p>)
          );
          break;
        case "@ruledecision":
          template.push(
            ReactDomServer.renderToString(
              <p>
                <strong>
                  {ApiDataObj["Rule Decision"]
                    ? ApiDataObj["Rule Decision"][0]?.RESPONSE_VALUE
                    : ""}
                </strong>
              </p>
            )
          );
          break;
        case "@dataassets":
          template.push(
            ReactDomServer.renderToString(
              <p>
                The data attributes available and their values are :
                {ApiDataObj["Data Assets"]?.map((el, i) => (
                  <div
                    key={`${el.DATA_ASSET_ATTRIBUTE_NAME}_${el.SOURCE_DATA_POINT_VALUE}_${i}`}
                  >
                    {el.DATA_ASSET_ATTRIBUTE_NAME} ={" "}
                    {el.SOURCE_DATA_POINT_VALUE}
                  </div>
                ))}
              </p>
            )
          );
          break;
        case "@combinedevidencescore":
          template.push(
            ReactDomServer.renderToString(
              <p>
                The evidences provided are :
                {ApiDataObj["Combined Evidence Score"]?.map((el, i) => (
                  <div key={`${el.EVIDENCE_SHORTNAME}}_${i}`}>
                    {el.EVIDENCE_SHORTNAME}
                  </div>
                ))}
              </p>
            )
          );
      }
    });
    return template?.join("\n");
  };

  const getNarrativeTemplateByApi = async () => {
    if (!currentQuestion?.QUESTION_ID) return;
    await Apicaller();
    await questionApiService
      .getQuestionNarrativeTemplate({
        i_QUESTION_ID: currentQuestion?.QUESTION_ID,
        i_CUSTOMER_ID: customerID,
        i_REGULATION_ID: id,
      })
      .then((response) => {
        if (response?.status !== 200) return showAlert?.(response);
        const template = response?.["#result-set-1"]?.[0];
        setNarrativeTemplate(template);
        if (!template?.NARRATIVE_TEMPLATE)
          showAlert?.("No Templates available for insertion");
      })
      .catch((error) => {
        showAlert?.(error);
        console.error("error", error);
      });
  };

  const Apicaller = async () => {
    setIsLoading(true);

    if (!ApiDataObj?.["Data Assets"]?.length) {
      const data = await individualQuestionAction.GetDataAssetsforQuestion(
        questionID,
        customerID
      );
      if (data["#result-set-1"]) {
        setApiDataObj((prev) => ({
          ...prev,
          "Data Assets": data["#result-set-1"],
        }));
      }
    }

    if (!ApiDataObj?.["Combined Evidence Score"]?.length) {
      const data2 = await individualQuestionAction.GetEvidenceForQuestion(
        questionID,
        customerID
      );
      if (data2["#result-set-1"]) {
        setApiDataObj((prev) => ({
          ...prev,
          "Combined Evidence Score": data2["#result-set-1"],
        }));
      }
    }

    if (
      !ApiDataObj?.["Rule Decision Prefix Text"]?.length &&
      !ApiDataObj?.["Rule Decision"]?.length
    ) {
      const data3 =
        await individualQuestionAction.GetSampleRuleDecisionForQuestion(
          questionID,
          customerID
        );
      if (data3["#result-set-1"]) {
        setApiDataObj((prev) => ({
          ...prev,
          "Rule Decision Prefix Text": data3["#result-set-1"],
          "Rule Decision": data3["#result-set-1"],
        }));
      }
    }

    // getNarrativeTemplate();

    setIsLoading(false);
  };

  return (
    <Box>
      <RichTextEditor
        data={
          narrativeTemplate?.NARRATIVE_TEMPLATE
            ? createNarativeTemplate(
                narrativeTemplate?.NARRATIVE_TEMPLATE?.split(";")
              )
            : currentQuestion?.CURRENT_ANSWER_SELECTED_RESPONSE
            ? currentQuestion?.CURRENT_ANSWER_SELECTED_RESPONSE
            : ""
        }
        // data="@auditinfo\n@ruleDecision\n"
        onCancelClick={onClose}
        onSaveClick={onSaveAnswer}
        customButtons={
          <Button
            variant="contained"
            size="small"
            color="primary"
            startIcon={<InsertLink />}
            onClick={getNarrativeTemplateByApi}
          >
            {langConstant.INSERT_NARRATIVE_TEMPLATE}
          </Button>
        }
      />
    </Box>
  );
};

EditAnswer.propTypes = {
  currentQuestion: propTypes.object,
  currentRfi: propTypes.object,
  onSaveAnswer: propTypes.func,
  showAlert: propTypes.func,
  onClose: propTypes.func,
};
