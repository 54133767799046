/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import { Grid, Box, Typography, makeStyles, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import individualQuestionAction from "./actions";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/Info";
import langConstant from "_lang";
import ViewQuestionTab from "./components/ViewQuestionTab";
import SimilarQuestions from "./components/SimilarQuestionsTab";
import ResponseTab from "./components/ResponseTab";
import DataAndAutomationTab from "./components/DataAndAutomationTab";
import EvidenceListTab from "./components/EvidenceListTab";
import ControlsTab from "./components/ControlsTab";
import NarrativeTab from "./components/NarrativeTab";
import { alertActions } from "_actions";
import sessionService from "_services/session.service";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { IconButton, Switch } from "@mui/material";
import questionServices from "./services";
import { async } from "regenerator-runtime";
import ConfirmDialogBox from "./components/ConfirmDialogBox";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { green } from "@mui/material/colors";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  truncateCss: {
    lineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    wordWrap: "break-word",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
  iconButton: {
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
    display: "inline-flex !important",
    borderRadius: "50% !important",
    padding: "10px !important",
    cursor: "pointer !important",
  },
  refNo: {
    fontWeight: "bold",
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
  },
  buttonWrap: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "70px",
  },
}));

const tabsArray = [
  { label: langConstant.VIEW_QUESTIONS, count: 0 },
  { label: langConstant.SIMILAR_QUESTIONS, count: 0 },
  { label: langConstant.RESPONSE, count: 0 },
  { label: langConstant.DATA_AND_AUTOMATION_RULES, count: 0 },
  { label: langConstant.EVIDENCE_LIST, count: 0 },
  { label: langConstant.CONTROLS, count: 0 },
  { label: langConstant.NARATTIVE, count: 0 },
];

const IndividualQuestion = ({
  id,
  qid,
  setQuestionDetailView,
  onQuestionRefChange,
  currentQuestionRefrence,
  recordLength,
  value,
  setValue,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const dispatch = useDispatch();

  const customerID = sessionService.getCustomerId();

  const guidanceTypes = useSelector(
    (state) => state.manageIndividualQuestion.guidanceTypes
  );

  const questionDetails = useSelector(
    (state) => state.manageIndividualQuestion.questionDetails
  );

  const [objectiveType, setObjectiveType] = useState("");
  const [guidanceType, setGuidanceType] = useState("");

  const [architectureType, setArchitectureType] = useState("");

  const [questionGuidance, setQuestionGuidance] = useState("");

  const [questionObjective, setQuestionObjective] = useState("");

  const [questionArchitecture, setQuestionArchitecture] = useState("");

  const [questionText, setQuestionText] = useState("");
  const [questionReference, setQuestionReference] = useState("");

  const [TabPanelArray, setTabPanelArray] = useState(tabsArray);

  const questionResponses = useSelector(
    (state) => state.manageIndividualQuestion.questionResponses
  );

  const [questionReadMore, setQuestionReadMore] = useState(false);
  const [guidanceReadMore, setguidanceReadMore] = useState(false);
  const [isConfirmBox, setIsConfirmBox] = useState(false);
  const [questionStatus, setQuestionStatus] = useState(false);

  const getEvidenceTypes = () => {
    dispatch(
      individualQuestionAction.getIndividualQuestionEvidenceTypes(
        qid,
        customerID
      )
    );
  };
  const getEvidenceList = () => {
    dispatch(
      individualQuestionAction.getIndividualQuestionEvidenceList(
        qid,
        customerID
      )
    );
  };

  const callUpdateEvidenceMapping = (evidence, active) => {
    dispatch(
      individualQuestionAction.UpdateEvidenceMapping(
        evidence.EVIDENCE_MAPPING_ID,
        evidence.MANDATORY,
        evidence.EVIDENCE_WEIGHTAGE,
        active
      )
    );
  };
  const callPutEvidenceMapping = (evidence) => {
    dispatch(
      individualQuestionAction.PutEvidenceMapping(
        qid,
        evidence.EVIDENCE_TYPE_ID,
        evidence.MANDATORY,
        evidence.EVIDENCE_WEIGHTAGE,
        customerID
      )
    );
  };

  const callAlertAction = (type, message) => {
    if (type == "error") {
      dispatch(alertActions.error(message));
    } else if (type == "success") {
      dispatch(alertActions.success(message));
    }
  };

  const evidenceList = useSelector(
    (state) => state.manageIndividualQuestion.questionEvidenceList
  );
  const evidenceTypes = useSelector(
    (state) => state.manageIndividualQuestion.questionEvidenceTypes
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {

    if (questionDetails && Object.keys(questionDetails).length > 0) {
      questionDetails.forEach((item) => {
        if (item.GUIDANCE_TEXT && item.GUIDANCE_TYPE) {
          switch (item.GUIDANCE_TYPE) {
            case langConstant.ARCHITECTURE:
              setQuestionArchitecture(item.GUIDANCE_TEXT);
              setArchitectureType(item.GUIDANCE_TYPE);

              break;
            case langConstant.GUIDANCE:
              setQuestionGuidance(item.GUIDANCE_TEXT);
              setGuidanceType(item.GUIDANCE_TYPE);

              break;
            case langConstant.OBJECTIVES:
              setQuestionObjective(item.GUIDANCE_TEXT);
              setObjectiveType(item.GUIDANCE_TYPE);
              break;
          }
        }
      });
      //   setQuestionGuidance(questionDetails.GUIDANCE_TEXT_HTML);
      setQuestionText(questionDetails[0].QUESTION_TEXT_HTML);
      setQuestionReference(questionDetails[0].REGULATOR_UNIQUE_REFERENCE);
      setQuestionStatus(questionDetails[0].ACTIVE);
    }
  }, [questionDetails, id, qid]);

  const fetchdata = async () => {
    const data = await individualQuestionAction.GetQuestionMappingCount(
      qid,
      id,
      customerID
    );
    const tempObj = [
      { label: langConstant.VIEW_QUESTIONS, count: 0 },
      {
        label: langConstant.SIMILAR_QUESTIONS,
        count: data?.SIMILAR_QUESTION_COUNT ? data.SIMILAR_QUESTION_COUNT : 0,
      },
      {
        label: langConstant.RESPONSE,
        count: data?.RESPONSE_OPTIONS_COUNT ? data.RESPONSE_OPTIONS_COUNT : 0,
      },
      {
        label: langConstant.DATA_AND_AUTOMATION_RULES,
        count: data?.DATA_POINT_COUNT ? data.DATA_POINT_COUNT : 0,
      },
      {
        label: langConstant.EVIDENCE_LIST,
        count: data?.EVIDENCE_COUNT ? data.EVIDENCE_COUNT : 0,
      },
      {
        label: langConstant.CONTROLS,
        count: data?.CISO_CONTROL_QUESTION_COUNT
          ? data.CISO_CONTROL_QUESTION_COUNT
          : 0,
      },
      { label: langConstant.NARATTIVE, count: 0 },
    ];
    setTabPanelArray(tempObj);
  };

  // Calling all APIs
  useEffect(() => {
    dispatch(
      individualQuestionAction.getIndividualQuestionDetails(qid, customerID)
    );
    dispatch(
      individualQuestionAction.getIndividualQuestionResponses(qid, customerID)
    );
    fetchdata();
  }, [id, qid]);

  const toggleQuestionReadMore = () => {
    setQuestionReadMore(!questionReadMore);
  };

  const toggleGuidanceReadMore = () => {
    setguidanceReadMore(!guidanceReadMore);
  };

  const changeAssessmentFlag = (assessmentFlag) => {
    dispatch(
      questionServices.updateQuestionActiveStatus(
        qid,
        customerID,
        !questionStatus,
        assessmentFlag
      )
    );
    setIsConfirmBox(false);
  };

  const changeQuestionStatus = async () => {
    const response = await questionServices.getQuestionInUse(qid, customerID);
    if (response && response.status === 200) {
      if (
        response["#result-set-1"] &&
        response["#result-set-1"][0]["Q_count"] > 0
      ) {
        setIsConfirmBox(true);
      } else {
        dispatch(
          questionServices.updateQuestionActiveStatus(
            qid,
            customerID,
            !questionStatus,
            "N"
          )
        );
      }
    } else {
      if (response && response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        overflow: "visible",
        marginTop: "10px",
        position: "relative",
      }}
    >
      <Box className={classes.buttonWrap}>
        <IconButton
          disabled={currentQuestionRefrence.index === 0 ? true : false}
          onClick={() => onQuestionRefChange("prev")}
          className={classes.iconButton}
        >
          <NavigateBeforeIcon style={{ color: "#fff" }} />
        </IconButton>
        <span className={classes.refNo}>{questionReference}</span>
        <IconButton
          disabled={
            recordLength - 1 > currentQuestionRefrence.index ? false : true
          }
          onClick={() => onQuestionRefChange("next")}
          className={classes.iconButton}
        >
          <NavigateNextIcon style={{ color: "#fff" }} />
        </IconButton>
        <Switch
          checked={questionStatus}
          onChange={changeQuestionStatus}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: green[600],
              "&:hover": {
                backgroundColor: green[20],
              },
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: green[600],
            },
          }}
        />
        <Button
          onClick={() => setQuestionDetailView(false)}
          style={{
            display: "block",
            color: "black",
            borderRadius: "50%",
            zIndex: "100",
            minWidth: "50px",
            position: "absolute",
            right: "10px",
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value !== 0 && (
          <>
            <Box style={{ display: "flex", paddingRight: "100px" }}>
              <Box
                style={{ width: "10%", alignItems: "center", display: "flex" }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {questionReference} -
                </Typography>
              </Box>
              <Box style={{ width: "100%" }}>
                <Box style={{ padding: "10px" }}>
                  <Grid
                    container
                    wrap="nowrap"
                    spacing={0}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item>
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        {questionText != null ? (
                          questionReadMore ? (
                            <>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: questionText,
                                }}
                              ></span>{" "}
                              ...
                              <span
                                style={{
                                  color: "#3EA0FB",
                                  fontWeight: "normal",
                                  cursor: "pointer",
                                }}
                                onClick={toggleQuestionReadMore}
                              >
                                Less
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: questionText,
                                }}
                                className={classes.truncateCss}
                              ></span>
                              <span
                                style={{
                                  color: "#3EA0FB",
                                  fontWeight: "normal",
                                  cursor: "pointer",
                                }}
                                onClick={toggleQuestionReadMore}
                              >
                                More
                              </span>{" "}
                            </>
                          )
                        ) : (
                          langConstant.NO_QUESTION_TEXT_AVAILABLE
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                marginTop: "10px",
                paddingRight: "100px",
                display: "flex",
              }}
            >
              <Box
                style={{
                  width: "100%",
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {langConstant.GUIDANCE_INFORMATION}
                </Typography>
                <Box className="info-box">
                  <Grid
                    container
                    wrap="nowrap"
                    spacing={0}
                    style={{ display: "flex" }}
                  >
                    <Grid item style={{ marginRight: "5px" }}>
                      <InfoIcon color="inherit" />
                    </Grid>
                    <Grid item>
                      {questionGuidance != null ? (
                        guidanceReadMore ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: questionGuidance,
                              }}
                            ></span>{" "}
                            ...
                            <span
                              style={{
                                color: "#3EA0FB",
                                fontWeight: "normal",
                                cursor: "pointer",
                              }}
                              onClick={toggleGuidanceReadMore}
                            >
                              Less
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: questionGuidance,
                              }}
                              className={classes.truncateCss}
                            ></span>
                            <span
                              style={{
                                color: "#3EA0FB",
                                fontWeight: "normal",
                                cursor: "pointer",
                              }}
                              onClick={toggleGuidanceReadMore}
                            >
                              More
                            </span>{" "}
                          </>
                        )
                      ) : (
                        langConstant.NO_QUESTION_TEXT_AVAILABLE
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </>
        )}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {/* Tab Headings */}
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {TabPanelArray.map((tabs, i) => (
              <Tab
                key={i}
                iconPosition="end"
                icon={
                  tabs.count === 0 ? (
                    ""
                  ) : (
                    <span
                      style={{
                        background: "#e93434",
                        color: "white",
                        textAlign: "center",
                        borderRadius: "50px",
                        padding: "0.3em 0.58em",
                        fontSize: "11px",
                      }}
                    >
                      {tabs.count}
                    </span>
                  )
                }
                label={tabs.label}
                {...a11yProps(i)}
              />
            ))}
          </Tabs>
        </Box>
        {/* Tab Panels */}
        <TabPanel value={value} index={0} style={{ paddingLeft: "10px" }}>
          <ViewQuestionTab
            setQuestionGuidance={setQuestionGuidance}
            setObjectiveType={setObjectiveType}
            setGuidanceType={setGuidanceType}
            setArchitectureType={setArchitectureType}
            setQuestionText={setQuestionText}
            questionID={qid}
            questionGuidanceHtml={questionGuidance}
            questionObjectiveHtml={questionObjective}
            questionArchitectureHtml={questionArchitecture}
            objectiveType={objectiveType}
            guidanceType={guidanceType}
            architectureType={architectureType}
            guidanceTypes={guidanceTypes}
            questionTextHtml={questionText}
            questionreference={questionReference}
            regulationID={id}
          />
        </TabPanel>

        <TabPanel value={value} index={1} style={{ paddingLeft: "10px" }}>
          <SimilarQuestions
            questionID={qid}
            regulationID={id}
            fetchdata={fetchdata}
          />
        </TabPanel>

        <TabPanel value={value} index={2} style={{ paddingLeft: "10px" }}>
          <ResponseTab fetchdata={fetchdata} qid={qid} />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <DataAndAutomationTab
            qid={qid}
            // questionResponses={questionResponses}
            callAlertAction={callAlertAction}
            fetchdata={fetchdata}
          />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <EvidenceListTab
            qid={qid}
            getEvidenceTypes={getEvidenceTypes}
            getEvidenceList={getEvidenceList}
            evidenceList={evidenceList}
            evidenceTypesState={evidenceTypes}
            callUpdateEvidenceMapping={callUpdateEvidenceMapping}
            callPutEvidenceMapping={callPutEvidenceMapping}
            callAlertAction={callAlertAction}
            fetchdata={fetchdata}
          />
        </TabPanel>

        <TabPanel value={value} index={5} style={{ paddingLeft: "10px" }}>
          <ControlsTab
            questionID={qid}
            regulationID={id}
            fetchdata={fetchdata}
          />
        </TabPanel>

        <TabPanel value={value} index={6} style={{ paddingLeft: "10px" }}>
          <NarrativeTab
            questionID={qid}
            questionTextHtml={questionText}
            QuestionRef={questionDetails.REGULATOR_UNIQUE_REFERENCE}
          />
        </TabPanel>
      </Box>
      {isConfirmBox && (
        <ConfirmDialogBox
          isDialogOpen={isConfirmBox}
          setIsDialogOpen={setIsConfirmBox}
          changeAssessmentFlag={changeAssessmentFlag}
        />
      )}
    </Box>
  );
};

IndividualQuestion.propTypes = {
  id: PropTypes.string,
  qid: PropTypes.string,
  setQuestionDetailView: PropTypes.func,
  onQuestionRefChange: PropTypes.func,
  currentQuestionRefrence: PropTypes.object,
  recordLength: PropTypes.number,
  value: PropTypes.number,
  setValue: PropTypes.func,
};

export default IndividualQuestion;
