import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from '@mui/material';
import PropTypes from "prop-types";
import useStyles from "./styles";
import { useSelector } from "react-redux";


const PrivacySettingPrompt = ({ handleClose, anchorEl, setChatMetaData, chatMetaData }) => {

    const classes = useStyles();
    const open = Boolean(anchorEl);

    const privacyData = useSelector((state) => state?.authentication?.detailedInfo?.PRIVACY_DATALIST);

    const [privacyDataList, setPrivacyDataList] = useState([])
    
    useEffect(() => {
        const tempList = []
        privacyData.forEach(element => {
            tempList.push({value: element.PRIVACY_ID, label: element.PRIVACY_DESCRIPTION})
        });
        setPrivacyDataList(tempList)
    },[privacyData])


    return (
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            classes={{root: classes.rootWrapper}}
        >
            {privacyDataList.map((menu, index) => { return (
                <MenuItem key={index} onClick={() => {
                    setChatMetaData((prevState) => ({...prevState, privacyId: menu.value}))
                    handleClose()
                }}
                >{menu.label}</MenuItem>
            )})}
        </Menu>
    )
}

PrivacySettingPrompt.propTypes = {
    handleClose: PropTypes.func,
    anchorEl: PropTypes.any,
    setChatMetaData: PropTypes.func,
    chatMetaData: PropTypes.object
}

export default PrivacySettingPrompt;