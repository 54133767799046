/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import { PolicyConstants } from "./constants";

const INITIAL_STATE = {
    policies: [],
    selectedPolicies: [],
    errorMessages: [],
}

const updateErrorMessage = (state, data) => {
  const tempErrorMessages = [ ...state.errorMessages, data ]
  return {
    ...state,
    errorMessages: tempErrorMessages
  }
}

const updatePolicyRecord = (state, data) => {
  const tempPolicies = [...state.policies]
  const policyIndex = tempPolicies.findIndex(item => item.POLICY_ID === data.policy_id)
  tempPolicies[policyIndex]['POLICY_NAME'] = data.document_name
  tempPolicies[policyIndex]['DOCUMENT_OWNER'] = data.document_owner ? JSON.stringify(data.document_owner) : ""
  tempPolicies[policyIndex]['DOCUMENT_TYPE'] = data.document_type.length ? JSON.stringify(data.document_type) : ""
  tempPolicies[policyIndex]['DOCUMENT_TAGS'] = data.document_tags
  return {
    ...state,
    policies: tempPolicies
  }
}

export default function policyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PolicyConstants.SET_POLICIES:
      return {
          ...state,
          policies: action.payload
      };
    case PolicyConstants.SET_SELECTED_POLICIES:
      return {
        ...state,
        selectedPolicies: action.payload
      }
    case PolicyConstants.SET_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: action.payload
      }
    case PolicyConstants.ADD_ERROR_MESSAGE:
      return updateErrorMessage(state, action.payload);
    case PolicyConstants.CLEAR_SELECTED_POLICIES:
      return {
        ...state,
        selectedPolicies: []
      }
    case PolicyConstants.UPDATE_POLICY_RECORD: 
      return updatePolicyRecord(state, action.payload)
    default:
      return state;
  }
}
