// OP#2994 - UJA10 - UI Development : Manage Evidence Types - Part 1 only
// pop-up component for Evidence-types

import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  makeStyles,
  Button,
  FormLabel,
  IconButton,
} from "@material-ui/core";
import { ErrorMessage, Field, Formik, Form } from "formik";
import AsyncSelect from "react-select/async";
import CloseIcon from "@mui/icons-material/Close";
import langConstant from "_lang";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import rootStyles from "rootStyles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PropTypes from "prop-types";
import * as Yup from "yup";
// import {
//   S3Client,
//   PutObjectCommand,
//   ListObjectsV2Command,
// } from "@aws-sdk/client-s3";
import { useDispatch, useSelector } from "react-redux";
import sessionService from "_services/session.service";
import { alertActions } from "_actions";
import { CircularProgress, Slide } from "@mui/material";
import { GetExtractedKeywords, UploadFileOnS3Bucket } from "../../services";

const validation = Yup.object({
  file: Yup.mixed().required("File is required"),
  evidenceType: Yup.mixed().required("Evidence type is required"),
});

const useStyles = makeStyles(() => ({
  justifybetween: {
    justifyContent: "space-between",
    display: "flex",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  padding: {
    padding: "10px 30px",
  },
  cursorpointer: {
    cursor: "pointer",
  },
  error: { color: "red", fontSize: "12px" },
  title: { display: "flex", alignItems: "center", padding: ".75rem" },
}));

const UploadFile = ({ uploadFileModal, extractedKeywordModal }) => {
  const dispatch = useDispatch();
  const classes = useStyles(),
    rootClasses = rootStyles();
  const customerID = sessionService.getCustomerId();
  const userInfo = useSelector((state) => state.authentication.detailedInfo);
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );
  const options = uploadFileModal?.data;
  const fileRef = useRef(null);
  const [paginatedOptions, setPaginatedOptions] = useState(),
    [isOptionLoading, setIsOptionLoading] = useState(false),
    [isUploading, setIsUploading] = useState(false),
    [progressMessage, setProgressMessage] = useState("");

  const EVIDENCE_TRAINING_FOLDER =
    userInfo?.EVIDENCE_TRAINING || "Evidence_Training";
  // BUCKET = window?.BUCKET_NAME,
  // REGION = "us-east-1";

  // const S3_CLIENT = new S3Client({
  //   region: REGION,
  //   credentials: {
  //     accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
  //     secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
  //   },
  // });

  const initialValues = {
    file: "",
    evidenceType: "",
  };

  const loadMoreOptions = () => {
    if (paginatedOptions?.length >= options?.length) return;
    setIsOptionLoading(true);
    let length = paginatedOptions?.length || 0;
    const data = options?.slice(length, length + 20);
    setTimeout(() => {
      setPaginatedOptions((prev) => [...prev, ...data]);
      setIsOptionLoading(false);
    }, 500);
  };

  const filterOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(() => {
          return options?.filter((i) =>
            i?.EVIDENCE_SHORTNAME?.toLowerCase().includes(
              inputValue?.toLowerCase()
            )
          );
        });
      }, 100);
    });

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    await handleUpload(values);
    actions.setSubmitting(false);
  };

  const showAlert = (message, type = "error") => {
    if (!message) return;
    dispatch(alertActions?.[type]?.(message));
  };

  // not is use

  // const getListS3OfFiles = async () => {
  //   const listObjectsParams = {
  //     Bucket: BUCKET,
  //     Prefix: `${customerID}/${EVIDENCE_TRAINING_FOLDER}/`,
  //   };

  //   const listObjectsCommand = new ListObjectsV2Command(listObjectsParams);

  //   try {
  //     const data = await S3_CLIENT.send(listObjectsCommand);
  //     console.log(data);
  //   } catch (error) {
  //     console.error("Error listing objects:", error);
  //     throw error;
  //   }
  // };

  const resetUploadStates = () => {
    setIsUploading(false);
    setProgressMessage("");
  };

  const handleUpload = async (values) => {
    try {
      setIsUploading(true);
      setProgressMessage("Please wait... Uploading a file");
      let filePath = `${customerID}/${EVIDENCE_TRAINING_FOLDER}/`,
        fileName = values?.file?.name?.replaceAll(" ", "_");
      const formData = new FormData();
      formData.append("document_obj", values?.file);
      formData.append("fileLocation", filePath + fileName);
      const res = await UploadFileOnS3Bucket(formData);
      if (res?.status === 200) {
        setProgressMessage("Please wait... File upload succeed");
        await extractKeywordsService(filePath, fileName, values);
      }
    } catch (error) {
      console.error(error);
      resetUploadStates();
      showAlert(error?.toString() || "Unable to upload file");
    } finally {
      resetUploadStates();
    }
  };

  const extractKeywordsService = async (
    file_directory = "",
    filename = "",
    values
  ) => {
    setProgressMessage("Please wait... Extracting keywords from evidence file");
    await GetExtractedKeywords({
      file_directory: file_directory,
      filename: filename,
      alias: "dummy",
      storageType: docStorageType,
      preProcessingIP: docStorageIP,
      preProcessingURL: docStorageURL,
    })
      .then((response) => {
        if (response?.status !== 200)
          throw new Error("Unable to extract keywords");
        extractedKeywordModal?.setData({
          extractedKeywords: response?.data,
          values,
        });
        extractedKeywordModal?.openModal();
        uploadFileModal?.closeModal();
        resetUploadStates();
      })
      .catch((err) => {
        console.log("error at extractKeywordsService", err);
        showAlert(err?.toString() || "Unable to extract keywords");
      });
  };

  useEffect(() => {
    setPaginatedOptions(options?.slice(0, 20));
  }, [uploadFileModal?.data]);

  return (
    <Dialog
      open={uploadFileModal?.isOpen}
      maxWidth="sm"
      fullWidth
      keepMounted
      onClose={uploadFileModal?.closeModal}
      disableEscapeKeyDown={isUploading}
      disableBackdropClick={isUploading}
    >
      <div className={classes.padding}>
        <DialogTitle disableTypography className={classes.title}>
          <Typography style={{ flex: 1 }} variant="h6" align="center">
            UPLOAD FILE
          </Typography>
          <IconButton
            style={{ position: "absolute", right: 4 }}
            aria-label="close"
            onClick={uploadFileModal?.closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
          >
            {({
              isSubmitting,
              values,
              touched,
              errors,
              handleBlur,
              setFieldValue,
            }) => (
              <Form autoComplete="off">
                <Box>
                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={8}>
                      <FormLabel
                        error={
                          touched?.evidenceType && Boolean(errors?.evidenceType)
                        }
                        htmlFor="evidenceType"
                      >
                        {langConstant.EVIDENCE_TYPE}
                      </FormLabel>
                      <Field name="evidenceType">
                        {({ field: { value } }) => (
                          <AsyncSelect
                            cacheOptions
                            loadOptions={filterOptions}
                            defaultOptions={paginatedOptions}
                            getOptionLabel={(option) =>
                              option?.EVIDENCE_SHORTNAME
                            }
                            getOptionValue={(option) =>
                              option?.EVIDENCE_TYPE_ID
                            }
                            onMenuScrollToBottom={loadMoreOptions}
                            isLoading={!options || isOptionLoading}
                            isDisabled={!options?.length}
                            options={paginatedOptions}
                            placeholder="Select an option"
                            value={value}
                            // onChange={(e) => setFieldValue("linkedItem", e)}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (base, state) => ({
                                ...base,
                                borderColor:
                                  !state?.isFocused &&
                                  touched?.evidenceType &&
                                  Boolean(errors?.evidenceType)
                                    ? "red"
                                    : base?.borderColor,
                              }),
                            }}
                            closeMenuOnSelect
                            isSearchable
                            isClearable
                            isOptionDisabled={(option) => option?.isDisabled}
                            onBlur={handleBlur}
                            onChange={(e) => setFieldValue("evidenceType", e)}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="evidenceType">
                        {(msg) => (
                          <Typography className={classes.error}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={5}>
                      <FormLabel
                        error={touched?.file && Boolean(errors?.file)}
                        htmlFor="file"
                      >
                        Select a file
                      </FormLabel>
                      <input
                        ref={fileRef}
                        accept="application/pdf"
                        id="raised-button-file"
                        hidden
                        name="file"
                        type="file"
                        onBlur={handleBlur}
                        // value={values?.file?.fileName || ""}
                        onChange={(e) =>
                          setFieldValue("file", e?.target?.files?.[0] || "")
                        }
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          component="span"
                          startIcon={<FolderOpenIcon />}
                        >
                          Browse File
                        </Button>
                      </label>
                      <ErrorMessage name="file">
                        {(msg) => (
                          <Typography className={classes.error}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                    {values?.file && (
                      <Typography
                        style={{
                          alignSelf: "self-end",
                          fontSize: "12px",
                          padding: "0 .5rem",
                        }}
                        color="primary"
                      >
                        {values?.file?.name}
                      </Typography>
                    )}
                  </Grid>

                  <Grid container className={classes.AddressGrid}>
                    <Typography style={{ fontSize: "14px" }}>
                      <strong>NOTE :</strong> {langConstant.UPLOAD_FILE_NOTE}
                    </Typography>
                  </Grid>

                  <Grid container spacing={2} justify="space-around">
                    <Grid item xs={5}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={
                          isUploading ? (
                            <CircularProgress size={25} />
                          ) : (
                            <FileUploadIcon />
                          )
                        }
                        className={rootClasses.greenButton}
                        disabled={isSubmitting}
                      >
                        UPLOAD File
                      </Button>
                    </Grid>
                    <Grid style={{ textAlign: "center", p: 1 }} item md={12}>
                      <Slide
                        direction="up"
                        in={Boolean(progressMessage)}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Typography
                          style={{ fontSize: 12 }}
                          variant="body1"
                          color="primary"
                        >
                          {progressMessage}
                        </Typography>
                      </Slide>
                    </Grid>

                    {/* <Grid item xs={5}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ width: "100%" }}
                        onClick={uploadFileModal?.closeModal}
                      >
                        CANCEL
                      </Button>
                      <button type="button" onClick={getListS3OfFiles}>
                        Get File List
                      </button>
                      <button
                        type="button"
                        onClick={extractedKeywordModal?.openModal}
                      >
                        Extracted Keyword Modal
                      </button>
                    </Grid> */}
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  );
};

UploadFile.propTypes = {
  uploadFileModal: PropTypes.object,
  extractedKeywordModal: PropTypes.object,
};

export default UploadFile;
