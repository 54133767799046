/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Button, TextField, Input, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CommentInput from "./component/CommentInput";
import CommentItem from "./component/CommentItem";
import commentsAction from "./actions";

const CommentsComponent = (props) => {
  const { direction, question_ID, submission_ID, remarksCount, button } = props;
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.commentsList);

  useEffect(() => {
    dispatch(commentsAction.getCommentsList(question_ID, submission_ID));
  }, [question_ID]);

  let oppositeDirection = "column";

  if (direction == "column") {
    oppositeDirection = "column-reverse";
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: `${props.direction}`,
      }}
    >
     {!button && <CommentInput
        question_ID={question_ID}
        submission_ID={submission_ID}
        remarksCount={() => remarksCount()}
      />}
{!comments?.length && <Typography style={{textAlign: 'center', marginTop: '1rem'}}>Comments not found</Typography> }
      <div
        style={{
          display: "flex",
          flexDirection: `${oppositeDirection}`,
          height: !button && '25vh',
          overflowY: 'auto'
        }}
      >
        {comments.map((comment, index) => {
          return <CommentItem key={index} comment={comment} />;
        })}
      </div>
    </div>
  );
};

CommentsComponent.propTypes = {
  direction: PropTypes.string,
  question_ID: PropTypes.string,
  submission_ID: PropTypes.number,
  remarksCount: PropTypes.func,
  button: PropTypes.bool,
};

export default CommentsComponent;
