import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  removePanel,
  setExpandedPanelID,
  setPin,
  updateSelectedPanels,
} from "containers/ComplianceCenter/actions";
import langConstant from "_lang";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StyledMenu from "_components/StyledMenu";
import CloseIcon from "@mui/icons-material/Close";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useDrag } from "react-dnd";
import { COMPONENT } from "../../constants";
import { Tooltip } from "@material-ui/core";

const PanelHeader = ({
  expandedPanelID,
  block,
  panel,
  columnID,
  isGraphView,
  setIsGraphView,
  isSwitchAvailable = true,
  panelName = "",
}) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const viewHandleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const viewHandleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        {!panel.isPinned ? (
          <IconButton
            sx={{ cursor: "move" }}
            onDoubleClick={() =>
              dispatch(setExpandedPanelID(expandedPanelID ? null : block.id))
            }
          >
            <OpenWithIcon fontSize="small" />
          </IconButton>
        ) : (
          <IconButton
            sx={{ cursor: "move" }}
            onDoubleClick={() =>
              dispatch(setExpandedPanelID(expandedPanelID ? null : block.id))
            }
          >
            <PushPinIcon fontSize="small" />
          </IconButton>
        )}
        {panelName && panelName.length ? (
          <Tooltip title={panelName}>
            <Typography
              sx={{
                textAlign: "left",
                fontWeight: "bold",
                width: expandedPanelID === block.id ? "auto" : "70px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {panelName}
            </Typography>
          </Tooltip>
        ) : (
          ""
        )}
      </Grid>
      <Grid
        sx={{
          display: "inline-flex",
          alignItems: "center",
          textAlign: "right",
        }}
      >
        {isSwitchAvailable && (
          <>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "bold", color: "grey" }}
            >
              {langConstant.CONTENT_CAPS}
            </Typography>
            <Switch
              // className={classes.profileMDTopHedaerToggleBtn}
              checked={isGraphView}
              onClick={(event) => setIsGraphView(event.target.checked)}
            />
            <Typography
              sx={{ fontSize: "12px", fontWeight: "bold", color: "grey" }}
            >
              {langConstant.GRAPH}
            </Typography>
          </>
        )}
        <IconButton onClick={viewHandleClick}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={viewHandleClose}
        >
          <MenuList>
            <MenuItem
              onClick={() => {
                viewHandleClose();
                dispatch(removePanel({ columnID, panelID: block.id }));
                dispatch(updateSelectedPanels(panel.id));
              }}
            >
              <ListItemIcon>
                <CloseIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography style={{ fontSize: "14px", fontWeight: "500" }}>
                  {langConstant.REMOVE}
                </Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                viewHandleClose();
                dispatch(setPin({ columnID, panelID: block.id }));
              }}
            >
              <ListItemIcon>
                <PushPinIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography style={{ fontSize: "14px", fontWeight: "500" }}>
                  {panel.isPinned ? langConstant.UNPIN : langConstant.PIN}
                </Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                viewHandleClose();
                dispatch(setExpandedPanelID(expandedPanelID ? null : block.id));
              }}
            >
              <ListItemIcon>
                {expandedPanelID ? (
                  <CloseFullscreenIcon fontSize="small" />
                ) : (
                  <AspectRatioIcon fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>
                <Typography style={{ fontSize: "14px", fontWeight: "500" }}>
                  {expandedPanelID
                    ? langConstant.COLLAPSE
                    : langConstant.EXPAND}
                </Typography>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </StyledMenu>
      </Grid>
    </Grid>
  );
};

PanelHeader.propTypes = {
  expandedPanelID: PropTypes.string,
  block: PropTypes.object,
  panel: PropTypes.object,
  columnID: PropTypes.string,
  isGraphView: PropTypes.bool,
  setIsGraphView: PropTypes.func,
  isSwitchAvailable: PropTypes.bool,
  panelName: PropTypes.string,
};

export default PanelHeader;
