import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import langConstant from "_lang";
import { useDispatch, useSelector } from "react-redux";
import AdminServices from "../services";
import sessionService from "_services/session.service";
import AdminUtils from "../utils";
import PropTypes from "prop-types";
import AppPagination from "_components/AppPagination";
import SelectPageRange from "_components/SelectPageRange";
import { scrollToTop } from "_helpers/utils";
import { countriesListActions } from "_actions";
import AddBoxIcon from "@mui/icons-material/AddBox";
import LegalEntityForm from "./LegalEntityForm";

const LegalEntityConfiguration = ({ themeDetails, selectedCustomer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const countries = useSelector((state) => state.countriesList);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [legalEntityData, setLegalEntityData] = useState(null);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState(null);
  const [showLegalEntityForm, setShowLegalEntityForm] = useState(false);
  const [legalEntityTypes, setLegalEntityTypes] = useState(null);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(1);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.CUSTOMER_ID) {
      dispatch(
        AdminServices.getCustomerLegalEntitiesForAdmin(
          selectedCustomer.CUSTOMER_ID,
          userID,
          setLegalEntityData
        )
      );
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (legalEntityTypes == null) {
      dispatch(countriesListActions.getCountriesList());
      dispatch(AdminServices.getLegalEntityType(setLegalEntityTypes));
    }
    if (legalEntityData) {
      setPageCount(calculatePageCount(legalEntityData));
    }
  }, [legalEntityData]);

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      legalEntityData?.length > 0
        ? Math.round(Math.ceil(legalEntityData.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  return (
    <Grid
      className={classes.customerFormWrapper}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "10px",
      }}
    >
      <Grid style={{ display: "inline-flex", alignItems: "center" }}>
        <Typography className={classes.legalEntityLabel}>
          {langConstant.LEGAL_ENTITY}
        </Typography>
        {selectedCustomer && selectedCustomer.CUSTOMER_ID && (
          <Grid>
            <IconButton
              type="button"
              color="primary"
              onClick={() => {
                setSelectedLegalEntity(null);
                setShowLegalEntityForm(true);
              }}
            >
              <AddBoxIcon fontSize="large" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {legalEntityData && (
        <>
          <TableContainer component={Paper}>
            <Table className="data-table" aria-label="Legal Entity List">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {AdminUtils.columns.map((item, index) => (
                    <TableCell
                      component="th"
                      scope="row"
                      key={"column_" + index + item.field}
                    >
                      {item.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? legalEntityData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : legalEntityData
                ).map((row, index) => {
                  const country = countries.find(
                    (country) => country.COUNTRY_CODE == row["COUNTRY_CODE"]
                  );
                  const entityType =
                    legalEntityTypes &&
                    legalEntityTypes.find(
                      (type) => type.value == row["LE_TYPE_ID"]
                    );
                  return (
                    <TableRow key={index + row[AdminUtils.columns[0].field]}>
                      <TableCell>
                        <Checkbox
                          checked={row.LEGAL_ENTITY_ID == selectedLegalEntity}
                          onClick={() => {
                            setSelectedLegalEntity(row.LEGAL_ENTITY_ID);
                            setShowLegalEntityForm(true);
                          }}
                        />
                      </TableCell>
                      {AdminUtils.columns.map((column, index) => {
                        const value = row[column.field];
                        return (
                          <TableCell
                            component="td"
                            scope="row"
                            key={
                              "column_" +
                              index +
                              row[AdminUtils.columns[0].field]
                            }
                            style={{
                              wordBreak: "break-word",
                              wordWrap: "break-word",
                            }}
                          >
                            {column.format
                              ? column.format(row, country, entityType)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}

                {legalEntityData.length == 0 && (
                  <TableRow style={{ height: 53 }}>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={AdminUtils.columns.length}
                    >
                      {langConstant.NO_RECORD_FOUND}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box my={2}>
            <Grid container justify="flex-end">
              <SelectPageRange
                selectedRange={rowsPerPage}
                handleChange={handleSelectPageRange}
              />
              {pageCount > 1 && (
                <AppPagination
                  colSpan={3}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </Box>
        </>
      )}
      {showLegalEntityForm && (
        <LegalEntityForm
          show={showLegalEntityForm}
          setShow={setShowLegalEntityForm}
          legalEntityData={legalEntityData}
          setLegalEntityData={setLegalEntityData}
          legalEntityTypes={legalEntityTypes}
          selectedLegalEntity={selectedLegalEntity}
          selectedCustomer={selectedCustomer}
        />
      )}
    </Grid>
  );
};

LegalEntityConfiguration.propTypes = {
  themeDetails: PropTypes.object,
  selectedCustomer: PropTypes.object,
};

export default LegalEntityConfiguration;
