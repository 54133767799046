import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  FormLabel,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { Close } from "@mui/icons-material";
import { commonDialogStyles } from "_components/ActionList/styles";
import {
  GetActionListForAudit,
  getDropdownOptions,
  PutActionsList,
} from "../services";
import sessionService from "_services/session.service";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { ACTION_STATUS_LIST, priorities, linkTypes } from "../constants";
import { AddActionListForm } from "_components/ActionList/AddActionListForm";
import { actionValidation } from "_components/ActionList/contants";
import moment from "moment";

const validationSchema = actionValidation.addActionList;

const Label = ({ label, htmlFor, ...rest }) => (
  <FormLabel required htmlFor={htmlFor} {...rest}>
    {label}
  </FormLabel>
);

const API_LIST = {
  [linkTypes[0]]: "GetAssignedRegulations",
  [linkTypes[1]]: "GetAvailablePolicies",
  [linkTypes[2]]: "GetAvailablePolicyComparisons",
  [linkTypes[3]]: "GetAvailableRegulations",
};

const optionsMapping = {
  [linkTypes[0]]: {
    value: "SUBMISSION_ID",
    label: "REGULATION_SHORT_NAME",
  },
  [linkTypes[1]]: {
    value: "POLICY_ID",
    label: "POLICY_NAME",
  },
  [linkTypes[2]]: {
    value: "POLICYCOMPARISONID",
    label: "COMPARISON_NAME",
  },
  [linkTypes[3]]: {
    value: "REGULATION_ID",
    label: "REGULATION_SHORT_NAME",
  },
};

export const AddActionListDialog = ({
  addActionListModal,
  mode = "add",
  actionList,
}) => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId(),
    customerID = sessionService.getCustomerId();
  const dialogStyles = commonDialogStyles();
  const [isOptionLoading, setIsOptionLoading] = useState(true);
  const [formattedOptions, setFormatedOptions] = useState();
  const [paginatedOptions, setPaginatedOptions] = useState();
  const pageLimit = 20;
  const isEditMode = mode === "edit";
  const defaulSelectLink =
    linkTypes?.find((type) => type == actionList?.ACTIONS_LIST_TYPE) ||
    linkTypes[0];
  const [currentLink, setCurrentLink] = useState(defaulSelectLink);
  const intialValues = {
    actionListName: actionList?.ACTIONS_LIST_NAME || "",
    priority:
      (isEditMode &&
        priorities.find(
          (p) => p[0] == actionList?.ACTIONS_LIST_PRIORITY?.[0]
        )) ||
      priorities[0],
    linkType: defaulSelectLink,
    status:
      (isEditMode && actionList?.ACTIONS_LIST_STATUS) ||
      ACTION_STATUS_LIST[0].value,
    linkedItem:
      (actionList?.ACTIONS_LIST_TYPE &&
        actionList?.ACTIONS_LIST_LINK_ID &&
        formattedOptions?.find(
          (o) =>
            o?.payload?.[optionsMapping?.[currentLink]?.value] ==
            actionList?.ACTIONS_LIST_LINK_ID
        )) ||
      "",
  };

  const onSubmit = async (values, actions) => {
    actions?.setSubmitting(true);

    const theDate = moment(new Date()).format("YYYY-MM-DD");
    const newDate = theDate.toString();
    const payload = {
      i_ACTIONS_LIST_NAME: values?.actionListName,
      i_ACTIONS_LIST_DESCRIPTION: values?.actionListName,
      i_ACTIONS_LIST_PRIORITY: values?.priority,
      i_ACTIONS_LIST_OWNER: userID,
      i_ACTIONS_LIST_TYPE: currentLink,
      i_ACTIONS_LIST_LINK_ID: values?.linkedItem?.value,
      // i_CUSTOMER_ID: customerID ? Number(customerID) : null,
      i_ACTIONS_LIST_ID: (isEditMode && actionList?.ACTIONS_LIST_ID) || null,
      i_LIST_STATUS: values?.status || ACTION_STATUS_LIST[1].value,
      i_ACTIONS_CATEGORY: null,
      i_ACTIVE: true,
      i_START_DATE: newDate,
      i_END_DATE: newDate,
    };
    await PutActionsList(payload)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            alertActions.success(
              `Action List successfully ${isEditMode ? "updated" : "added"}`
            )
          );
          dispatch(GetActionListForAudit(userID));
          setTimeout(() => {
            addActionListModal?.closeModal();
          }, 300);
        } else {
          dispatch(
            alertActions.error(
              response?.data?.message?.map((item, index) => {
                return <span key={index}>{item}</span>;
              }) || "Something went wrong"
            )
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(alertActions.error(err?.toString() || "Something went wrong"));
      })
      .finally(() => actions?.setSubmitting(false));
  };

  const loadMoreOptions = () => {
    if (paginatedOptions?.length >= formattedOptions?.length) return;
    setIsOptionLoading(true);
    let length = paginatedOptions?.length || 0;
    const data = [...formattedOptions].slice(length, length + pageLimit);
    setTimeout(() => {
      setPaginatedOptions((prev) => [...prev, ...data]);
      setIsOptionLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (!currentLink) return;
    setIsOptionLoading(true);
    getDropdownOptions(userID, API_LIST[currentLink])
      .then((res) => {
        if (res?.status !== 200) {
          dispatch(
            alertActions.error(
              res?.data?.message?.map((item, index) => {
                return <span key={index}>{item}</span>;
              }) ?? res?.toString()
            )
          );
        }
        let list = res?.["#result-set-1"];
        setIsOptionLoading(false);
        const options = list?.map((item) => {
          return {
            value: item?.[optionsMapping[currentLink].value],
            label: item?.[optionsMapping[currentLink].label],
            isDisabled: !item?.[optionsMapping[currentLink].value]
              ? true
              : false,
            payload: item,
          };
        });
        setFormatedOptions(options);
        setPaginatedOptions(options?.slice(0, pageLimit));
      })
      .catch((error) => {
        setIsOptionLoading(false);
        dispatch(
          alertActions.error(
            error?.data?.message?.map((item, index) => {
              return <span key={index}>{item}</span>;
            }) ?? error?.toString()
          )
        );
      });
  }, [currentLink]);

  return (
    <Dialog
      maxWidth="md"
      onClose={addActionListModal?.closeModal}
      open={addActionListModal?.isOpen}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography className={dialogStyles.title}>
        <Typography style={{ flex: 1 }} variant="h6" align="center">
          {`${isEditMode ? "EDIT" : "ADD NEW"} ACTION LIST`}
        </Typography>
        <IconButton
          style={{ position: "absolute", right: 4 }}
          aria-label="close"
          onClick={addActionListModal?.closeModal}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <AddActionListForm
          currentLink={currentLink}
          setCurrentLink={setCurrentLink}
          intialValues={intialValues}
          onClose={addActionListModal?.closeModal}
          onSubmit={onSubmit}
          isOptionLoading={isOptionLoading}
          loadMoreOptions={loadMoreOptions}
          formattedOptions={formattedOptions}
          paginatedOptions={paginatedOptions}
          validationSchema={validationSchema}
        />
      </DialogContent>
    </Dialog>
  );
};

AddActionListDialog.propTypes = {
  addActionListModal: propTypes.object,
  mode: propTypes.string,
  actionList: propTypes.object,
};
Label.propTypes = {
  label: propTypes.string,
  htmlFor: propTypes.string,
};
