import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import langConstant from "_lang";
import useStyles from "../../styles";
import RegulationForRegulator from "./RegulationForRegulator";
import CloseIcon from "@mui/icons-material/Close";

const RegulatorEditPopUp = ({
  show,
  setShow,
  selectedRegulator,
  setSelectedRegulator,
  mode,
  setMode,
  updateRegulatorsDetails,
  regulationsList,
  setRegulationsList,
  selectedCustomer,
}) => {
  const handleClose = () => {
    setShow(false);
    setSelectedRegulator(null);
    setMode("create");
  };
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = async (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Dialog open={show} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
      <Grid style={{ padding: "20px 30px" }}>
        <Grid className={classes.dialogTitleWrapper}>
          <Typography className={classes.dialogTitleLabel}>
            {langConstant.UPDATE_REGULATOR_DETAILS}
          </Typography>
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </Grid>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          TabIndicatorProps={{ style: { padding: 2 } }}
          orientation="horizontal"
          variant="scrollable"
        >
          <Tab aria-orientation="horizontal" label={langConstant.DETAILS} />
          <Tab
            aria-orientation="horizontal"
            label={langConstant.REGULATION}
            disabled={mode === "create" ? true : false}
          />
        </Tabs>
        {tabValue === 0 && (
          <Grid style={{ marginTop: "10px" }}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <Typography className={classes.formLabel}>
                  {langConstant.REGULATOR_NAME}
                </Typography>
                <Typography>{selectedRegulator.REGULATOR_NAME}</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.formLabel}>
                  {langConstant.DOMAIN}
                </Typography>
                <Typography>{selectedRegulator.DOMAIN_NAME}</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.formLabel}>
                  {langConstant.COUNTRY}
                </Typography>
                <Typography>{selectedRegulator.COUNTRY_SHORT_NAME}</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.formLabel}>
                  {langConstant.APPLICABLE_FLAG}
                </Typography>
                <Typography>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectedRegulator.APPLICABLE_FLAG}
                    onChange={(e) =>
                      setSelectedRegulator((prevState) => ({
                        ...prevState,
                        APPLICABLE_FLAG: e.target.value,
                      }))
                    }
                    style={{
                      display: "inline",
                    }}
                  >
                    <FormControlLabel
                      value={"TRUE"}
                      control={<Radio />}
                      label="TRUE"
                    />
                    <FormControlLabel
                      value={"FALSE"}
                      control={<Radio />}
                      label="FALSE"
                    />
                  </RadioGroup>
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.formLabel}>
                  {langConstant.ACTIVE}
                </Typography>
                <Typography>
                  <Switch
                    checked={selectedRegulator.ACTIVE == "1" ? true : false}
                    onChange={(e) =>
                      setSelectedRegulator((prevState) => ({
                        ...prevState,
                        ACTIVE: e.target.checked ? 1 : 0,
                      }))
                    }
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.btnWrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={updateRegulatorsDetails}
              >
                {mode === "create"
                  ? langConstant.CREATE_BUTTON
                  : langConstant.UPDATE}
              </Button>
            </Grid>
          </Grid>
        )}
        {tabValue === 1 && (
          <RegulationForRegulator
            selectedRegulator={selectedRegulator}
            customerID={selectedCustomer.value}
            customerRegulationsList={regulationsList}
            setCustomerRegulationsList={setRegulationsList}
          />
        )}
      </Grid>
    </Dialog>
  );
};

RegulatorEditPopUp.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  selectedRegulator: PropTypes.object,
  setSelectedRegulator: PropTypes.func,
  mode: PropTypes.string,
  setMode: PropTypes.func,
  updateRegulatorsDetails: PropTypes.func,
  regulationsList: PropTypes.oneOfType([() => null, PropTypes.array]),
  setRegulationsList: PropTypes.func,
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default RegulatorEditPopUp;
