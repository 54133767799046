// OP#2830 - UJA8 - UI Development - Manage Teams Profile
// Team Member Data table

import { Avatar, Divider, Grid, List, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from "prop-types";
import { ListItemButton } from '@mui/material';
import langConstant from "_lang";

const useStyles = makeStyles(() => ({
    activetip : {
        fontSize:"14px",
        padding: "4px 30px",
        backgroundColor:"#9ef0ae",
        textAlign:"center",
        borderRadius:"4px",
        color:"#064f0d",
        fontWeight:"bold",

    },

    inactivetip : {
        fontSize:"14px",
        padding: "4px 23px",
        backgroundColor:"#e56d6d",
        textAlign:"center",
        borderRadius:"4px",
        color:"#7e2121",
        fontWeight:"bold",
    },
    authorrole :{
        fontSize:"12px",
        color:"#999999",
    },
    authorname :{
        color:"#444444",
    },
    avatarstyle :{
        width:"56px",
        height:"56px",
        border:"1px solid #8d8888",
        marginRight:"26px",
    }

}))

const TeamMemberPill = ({members, setShow, setSelectedUserId, setAddUserPopUp}) => {
    const classes = useStyles();

    return (
        <List sx={{width:"100%", bgcolor:'background.paper'}}>
            {members && members.map((item, index) => {
                return (
                    <Grid key={index}>
                        <ListItemButton
                            onClick={() => {
                                setAddUserPopUp(false)
                                setShow(true)
                                setSelectedUserId(item.USER_ID)
                            }}
                        >
                            <ListItemAvatar>
                                {item.AVATAR_IMAGE ? 
                                    <Avatar  className={classes.avatarstyle} src={"data:image/png;base64,"+window.atob(item.AVATAR_IMAGE)} /> :
                                    <Avatar className={classes.avatarstyle} >{item.FIRST_NAME[0]}</Avatar>
                                }
                            </ListItemAvatar>
                            <ListItemText 
                                primary={
                                    <Typography className={classes.authorname}>{item.FIRST_NAME} {item.LAST_NAME}</Typography>
                                }
                                secondary={        
                                    <Typography className={classes.authorrole}>{item.ROLE_NAME}</Typography>
                                }
                            />
                            {item.ACTIVE ? 
                                <Typography className={classes.activetip}>{langConstant.ACTIVE_CAPS}</Typography> : 
                                <Typography className={classes.inactivetip}>{langConstant.INACTIVE_CAPS}</Typography>}
                        </ListItemButton>
                        <Divider varient="inset" component="li" />
                    </Grid>
                    )
                })}
        </List>
    )
}

TeamMemberPill.propTypes = {
    members: PropTypes.arrayOf(PropTypes.object),
    setShow: PropTypes.func,
    setSelectedUserId: PropTypes.func,
    setAddUserPopUp: PropTypes.func
};
export default TeamMemberPill;