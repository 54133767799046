import React, { useState } from "react";
import PropTypes from "prop-types";
import DialogBox from "_components/DialogBox";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import langConstant from "_lang";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import DataAssetServices from "../services";
import sessionService from "_services/session.service";

const DataAssetPopUp = ({
  title,
  showDataAsset,
  setShowDataAsset,
  selectedDataAsset,
  updateDataAsset,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const dispatch = useDispatch();
  const toggleDialog = () => setShowDataAsset(false);

  const dataAssetValidationSchema = Yup.object().shape({
    DATA_ASSET_NAME: Yup.string().required("Entity Name Is Required"),
    DATA_ASSET_ATTRIBUTE_NAME: Yup.string()
      .trim()
      .required("Asset Attribute Name Is Required"),
    SOURCE_DATA_POINT_VALUE: Yup.string()
      .trim()
      .required("Asset Value Is Required"),
    TAB_LABEL: Yup.string().trim().required("Tab Label Is Required"),
  });

  const onSubmit = (values) => {
    console.log(values);
    values["CUSTOMER_ID"] = customerID;
    values["USER_ID"] = userID;
    if (values.UNID) {
      dispatch(
        DataAssetServices.updateCustomerDataAssetList(
          setLoading,
          values,
          updateDataAsset,
          toggleDialog
        )
      );
    } else {
      dispatch(
        DataAssetServices.putCustomerDataAssetList(
          setLoading,
          values,
          updateDataAsset,
          toggleDialog
        )
      );
    }
  };

  return (
    <DialogBox
      maxWidth={"sm"}
      title={title}
      open={showDataAsset}
      handleClose={toggleDialog}
      style={{ paddingTop: "0px" }}
    >
      <Formik
        initialValues={selectedDataAsset}
        validationSchema={dataAssetValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12} style={{ fontWeight: "bold" }}>
                {langConstant.DATA_ENTITY_NAME}
              </Grid>
              <Grid item md={8} xs={12}>
                <Field name="DATA_ASSET_NAME">
                  {() => (
                    <TextField
                      size="small"
                      type="string"
                      variant="outlined"
                      fullWidth
                      value={values.DATA_ASSET_NAME}
                      onChange={(e) =>
                        setFieldValue("DATA_ASSET_NAME", e.target.value)
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputField,
                        },
                      }}
                    />
                  )}
                </Field>
                {errors && errors["DATA_ASSET_NAME"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["DATA_ASSET_NAME"]}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} xs={12} style={{ fontWeight: "bold" }}>
                {langConstant.DATA_ASSET_ATTRIBUTE_NAME}
              </Grid>
              <Grid item md={8} xs={12}>
                <Field name="DATA_ASSET_ATTRIBUTE_NAME">
                  {() => (
                    <TextField
                      size="small"
                      type="string"
                      variant="outlined"
                      fullWidth
                      error={errors && errors["DATA_ASSET_ATTRIBUTE_NAME"]}
                      value={values.DATA_ASSET_ATTRIBUTE_NAME}
                      onChange={(e) =>
                        setFieldValue(
                          "DATA_ASSET_ATTRIBUTE_NAME",
                          e.target.value
                        )
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputField,
                        },
                      }}
                    />
                  )}
                </Field>
                {errors && errors["DATA_ASSET_ATTRIBUTE_NAME"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["DATA_ASSET_ATTRIBUTE_NAME"]}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} xs={12} style={{ fontWeight: "bold" }}>
                {langConstant.DATA_ASSET_VALUE}
              </Grid>
              <Grid item md={8} xs={12}>
                <Field name="SOURCE_DATA_POINT_VALUE">
                  {() => (
                    <TextField
                      size="small"
                      type="string"
                      variant="outlined"
                      fullWidth
                      error={errors && errors["SOURCE_DATA_POINT_VALUE"]}
                      value={values.SOURCE_DATA_POINT_VALUE}
                      onChange={(e) =>
                        setFieldValue("SOURCE_DATA_POINT_VALUE", e.target.value)
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputField,
                        },
                        style: {
                          borderColor:
                            errors && errors["SOURCE_DATA_POINT_VALUE"]
                              ? "red"
                              : undefined,
                        },
                      }}
                    />
                  )}
                </Field>
                {errors && errors["SOURCE_DATA_POINT_VALUE"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["SOURCE_DATA_POINT_VALUE"]}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} xs={12} style={{ fontWeight: "bold" }}>
                {langConstant.TAB_LABEL}
              </Grid>
              <Grid item md={8} xs={12}>
                <Field name="TAB_LABEL">
                  {() => (
                    <TextField
                      size="small"
                      type="string"
                      variant="outlined"
                      fullWidth
                      error={errors && errors["TAB_LABEL"]}
                      value={values.TAB_LABEL}
                      onChange={(e) =>
                        setFieldValue("TAB_LABEL", e.target.value)
                      }
                      InputProps={{
                        classes: {
                          input: classes.inputField,
                        },
                        style: {
                          borderColor:
                            errors && errors["TAB_LABEL"] ? "red" : undefined,
                        },
                      }}
                    />
                  )}
                </Field>
                {errors && errors["TAB_LABEL"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["TAB_LABEL"]}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12} style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={
                    loading ? (
                      <CircularProgress
                        style={{
                          width: "25px",
                          height: "25px",
                          color: "white",
                        }}
                      />
                    ) : (
                      ""
                    )
                  }
                  disabled={loading}
                >
                  {selectedDataAsset.UNID
                    ? langConstant.UPDATE_BUTTON
                    : langConstant.CREATE_BUTTON}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogBox>
  );
};

DataAssetPopUp.propTypes = {
  title: PropTypes.string,
  showDataAsset: PropTypes.bool,
  setShowDataAsset: PropTypes.func,
  selectedDataAsset: PropTypes.object,
  updateDataAsset: PropTypes.func,
};

export default DataAssetPopUp;
