import moment from "moment";
import langConstant from "_lang";

export const groupingOnDocumentTypeOrOwner = (
  data,
  document_option,
  documentTypes
) => {
  if (document_option === "document_owner") {
    const ownerData = data.reduce((r, a) => {
      let owner = a["DOCUMENT_OWNER"];
      if (owner && JSON.parse(owner)["value"]) {
        owner = JSON.parse(owner);
        owner = owner["value"];
      } else {
        owner = null;
      }
      r[owner] = r[owner] || [];
      r[owner]?.push(a);
      return r;
    }, Object.create(null));
    return ownerData;
  } else if (document_option === "document_type") {
    const typeData = { null: [] };
    documentTypes.map((item) => {
      item.value === "all"
        ? (typeData[null] = [])
        : (typeData[item.value] = []);
    });
    data.map((item) => {
      let policyDocumentType = item["DOCUMENT_TYPE"];
      if (policyDocumentType && JSON.parse(policyDocumentType).length) {
        policyDocumentType = JSON.parse(policyDocumentType);
        policyDocumentType.map((type) => {
          if (type.value) {
            typeData[type.value]?.push(item);
          } else {
            typeData[type]?.push(item);
          }
        });
      } else {
        typeData[null].push(item);
      }
    });
    return typeData;
  } else if (document_option === "uploaded_by") {
    const ownerData = data.reduce((r, a) => {
      let owner = a["UPLOADED_BY"];
      r[owner] = r[owner] || [];
      r[owner].push(a);
      return r;
    }, Object.create(null));
    return ownerData;
  }
};

export const ownerColumnList = [
  { field: "POLICY_ID", headerName: "ID", minWidth: 50 },
  {
    field: "POLICY_NAME_WITH_INFO",
    headerName: langConstant.POLICY_NAME,
    minWidth: 50,
  },
  // {field: "POLICY_FILE_NAME_LINK", headerName: langConstant.POLICY_FILE_NAME, minWidth: 50},
  // {field: "NATIONAL_LANGUAGE", headerName: langConstant.LANGUAGE , minWidth: 50},
  // {field: "Extraction_Status_Icon", headerName:langConstant.EXTRACTION_STATUS, minWidth: 50},
  // {field: "DOCUMENT_TYPE_DATA", headerName: langConstant.DOCUMENT_TYPES, minWidth: 50},
  // {field: "ARCHIVED_STATUS", headerName: langConstant.ARCHIVED, minWidth: 50 },
  { field: "UPLOADED_BY", headerName: langConstant.UPLOADED_BY, minWidth: 50 },
  {
    field: "CREATE_DATETIME",
    headerName: langConstant.UPLOADED_DATE_TIME,
    minWidth: 50,
    format: (value) => (value ? moment(value).format("DD-MMM-YY h:mm A") : ""),
  },
  {
    field: "ACTION",
    headerName: langConstant.ACTIONS,
    minWidth: 50,
    sticky: true,
  },
];

export const typeColumnList = [
  { field: "POLICY_ID", headerName: "ID", minWidth: 50 },
  {
    field: "POLICY_NAME_WITH_INFO",
    headerName: langConstant.POLICY_NAME,
    minWidth: 50,
  },
  // {field: "POLICY_FILE_NAME_LINK", headerName: langConstant.POLICY_FILE_NAME, minWidth: 50},
  // {field: "NATIONAL_LANGUAGE", headerName: langConstant.LANGUAGE , minWidth: 50},
  // {field: "Extraction_Status_Icon", headerName:langConstant.EXTRACTION_STATUS, minWidth: 50},
  // {field: "DOCUMENT_OWNER_DATA", headerName: langConstant.DOCUMENT_OWNER, minWidth: 50},
  // {field: "ARCHIVED_STATUS", headerName: langConstant.ARCHIVED, minWidth: 50 },
  { field: "UPLOADED_BY", headerName: langConstant.UPLOADED_BY, minWidth: 50 },
  {
    field: "CREATE_DATETIME",
    headerName: langConstant.UPLOADED_DATE_TIME,
    minWidth: 50,
    format: (value) => (value ? moment(value).format("DD-MMM-YY h:mm A") : ""),
  },
  {
    field: "ACTION",
    headerName: langConstant.ACTIONS,
    minWidth: 50,
    sticky: true,
  },
];

export const uploadedByColumnList = [
  { field: "POLICY_ID", headerName: "ID", minWidth: 50 },
  {
    field: "POLICY_NAME_WITH_INFO",
    headerName: langConstant.POLICY_NAME,
    minWidth: 50,
  },
  // {field: "POLICY_FILE_NAME_LINK", headerName: langConstant.POLICY_FILE_NAME, minWidth: 50},
  // {field: "NATIONAL_LANGUAGE", headerName: langConstant.LANGUAGE , minWidth: 50},
  // {field: "Extraction_Status_Icon", headerName:langConstant.EXTRACTION_STATUS, minWidth: 50},
  // {field: "DOCUMENT_TYPE_DATA", headerName: langConstant.DOCUMENT_TYPES, minWidth: 50},
  // {field: "DOCUMENT_OWNER_DATA", headerName: langConstant.DOCUMENT_OWNER, minWidth: 50},
  // {field: "ARCHIVED_STATUS", headerName: langConstant.ARCHIVED, minWidth: 50 },
  {
    field: "CREATE_DATETIME",
    headerName: langConstant.UPLOADED_DATE_TIME,
    minWidth: 50,
    format: (value) => (value ? moment(value).format("DD-MMM-YY h:mm A") : ""),
  },
  {
    field: "ACTION",
    headerName: langConstant.ACTIONS,
    minWidth: 50,
    sticky: true,
  },
];
