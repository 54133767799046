import React, { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { COMPONENT } from "./constants";
import PropTypes from "prop-types";
import Components from "./components.js";
import { IconButton } from "@mui/material";
import OpenWithIcon from "@mui/icons-material/OpenWith";

const style = {
  borderRadius: "10px",
  padding: "0.5rem 0.75rem",
  backgroundColor: "white",
  height: "100%",
  overflow: "auto",
};

const Component = ({ data, components, path, columnID }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: COMPONENT, id: data.id, path },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  const component = components[data.id];

  return (
    <div ref={data.isPinned ? null : drag} style={{ ...style, opacity }}>
      {Components(component, columnID, data)}
    </div>
  );
};

Component.propTypes = {
  data: PropTypes.any,
  components: PropTypes.any,
  path: PropTypes.any,
  columnID: PropTypes.string,
};

export default Component;
