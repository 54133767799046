import { createMuiTheme } from "@material-ui/core";
import { convertColorToRGBA } from "./globalMuiStyles";

export const getMuiTheme = ({
  primaryColor = "#046DB8",
  primaryFontColor = "#505050",
  secondaryColor = "#124571",
  secondaryFontColor = "",
  fontFamily = ["Roboto", "Helvetica", "Arial", "Nunito", "sans-serif"],
  actionButttonColor = "",
  tableHeaderColor = "#eeeeee",
}) => {
  return createMuiTheme({
    palette: {
      primaryColor: actionButttonColor,
      text: {
        primary: primaryFontColor,
      },
      primary: {
        //light: '#',
        main: primaryColor,
        // dark: palette.primary[700],
        //contrastText: getContrastText(palette.primary[500]),
      },
      secondary: {
        //light: palette.secondary.A200,
        main: secondaryColor,
        //dark: palette.secondary.A700,
        //contrastText: getContrastText(palette.secondary.A400),
      },
      // action: {
      //   disabledBackground: convertColorToRGBA(actionButttonColor, 0.1),
      //   disabled:actionButttonColor,
      // }
    },
    typography: {
      // fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontFamily: fontFamily?.join(","),
    },
    shape: {
      borderRadius: 5,
    },
    overrides: {
      // Name of the component
      MuiButton: {
        // The default props to change
        containedPrimary: {
          backgroundColor: convertColorToRGBA(actionButttonColor, 0.9),
          "&:hover": {
            backgroundColor: actionButttonColor,
          },
        },
        outlinedPrimary: {
          borderColor: actionButttonColor,
          color: actionButttonColor,
          "&:hover": {
            backgroundColor: convertColorToRGBA(actionButttonColor, 0.075),
            borderColor: actionButttonColor,
            // backgroundColor: actionButttonColor,
            // color: "#fff",
          },
        },
        textPrimary: {
          color: actionButttonColor,
          "&:hover": {
            backgroundColor: convertColorToRGBA(actionButttonColor, 0.075),
          },
          "&:active": {
            backgroundColor: actionButttonColor,
          },
        },
        // sizeLarge: {
        //   fontSize: "1.500rem",
        //   fontWeight: 700,
        //   padding: "2rem",
        // },
        sizeSmall: {
          height: 40,
          padding: "0.875rem",
        },
      },
      MuiTableCell: {
        head: {
          backgroundColor: tableHeaderColor,
          color: "#fff",
          fontWeight: 500,
          lineHeight: "1.5rem",
          padding: "6px 10px 2px",
        },
        stickyHeader: {
          backgroundColor: tableHeaderColor,
        },
      },
      MuiIconButton: {
        // root: {
        //   backgroundColor: `${actionButttonColor} !important`,
        //   color: "#fff",
        // },
        // colorInherit: {
        //   backgroundColor: `${actionButttonColor} !important`,
        //   color: "#fff",
        // },
        colorPrimary: {
          color: `${actionButttonColor} !important`,
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: `${actionButttonColor} !important`,
        },
        root: {
          color: `${actionButttonColor} !important`,
        },
      },
      // MuiTableHead: {
      //   root: {
      //     backgroundColor: "red",
      //   },
      // },
      // MuiTableRow: {
      //   root: {
      //     backgroundColor: "transparent",
      //   },
      // },
    },
  });
};
