import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import moment from "moment/moment";
import React from "react";

const getCustomerOrgUnits = (customerID, setOrgManagementData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerOrgUnits", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setOrgManagementData(response["#result-set-1"]);
        },
        (error) => {
          setOrgManagementData([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerSanctionKeywords = (customerID, setSanctionKeywords) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerSanctionKeywords", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setSanctionKeywords(response["#result-set-1"]);
        },
        (error) => {
          setSanctionKeywords([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerQuestionClassifications = (
  customerID,
  setQuestionClassification
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerQuestionClassifications", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setQuestionClassification(response["#result-set-1"]);
        },
        (error) => {
          setQuestionClassification([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerDocumentClassifications = (
  customerID,
  setDocClassificationData
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerDocumentClassifications", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setDocClassificationData(response["#result-set-1"]);
        },
        (error) => {
          setDocClassificationData([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerCISOControls = (customerID, setCISOControls) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerCISOControls", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setCISOControls(
            response["#result-set-1"].map((item) => {
              return {
                value: item.CISO_CONTROL_ID,
                label: item.CISO_CONTROL_NAME,
                ...item,
              };
            })
          );
        },
        (error) => {
          setCISOControls([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putSanctionKeywords = (
  customerID,
  data,
  sanctionKeywords,
  setSanctionKeywords,
  handleClose
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutSanctionKeywords", {
        i_SANCTION_KEYWORDS_ID: data.SANCTION_KEYWORDS_ID,
        i_KEYWORD: data.KEYWORD,
        i_ACTIVE: data.ACTIVE,
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (data.SANCTION_KEYWORDS_ID) {
            const tempSanctionKeywords = [...sanctionKeywords];
            const index = tempSanctionKeywords.findIndex(
              (keyword) =>
                keyword.SANCTION_KEYWORDS_ID === data.SANCTION_KEYWORDS_ID
            );
            if (index !== -1) {
              tempSanctionKeywords[index]["KEYWORD"] = data.KEYWORD;
              tempSanctionKeywords[index]["ACTIVE"] = data.ACTIVE;
              setSanctionKeywords(tempSanctionKeywords);
            }
          } else {
            setSanctionKeywords((prevState) => [
              ...prevState,
              {
                ...data,
                SANCTION_KEYWORDS_ID:
                  response["#result-set-1"][0]["SANCTION_KEYWORDS_ID"],
              },
            ]);
          }
          handleClose();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putDocumentclassification = (
  customerID,
  data,
  docClassificationData,
  setDocClassificationData,
  handleClose
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutDocumentclassification", {
        i_ID: data.ID,
        i_DOCUMENT_CLASSIFICATION_NAME: data.DOCUMENT_CLASSIFICATION_NAME,
        i_ACTIVE: data.ACTIVE,
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (data.ID) {
            const tempDocClassificationData = [...docClassificationData];
            const index = tempDocClassificationData.findIndex(
              (keyword) => keyword.ID === data.ID
            );
            if (index !== -1) {
              tempDocClassificationData[index]["DOCUMENT_CLASSIFICATION_NAME"] =
                data.DOCUMENT_CLASSIFICATION_NAME;
              tempDocClassificationData[index]["ACTIVE"] = data.ACTIVE;
              setDocClassificationData(tempDocClassificationData);
            }
          } else {
            setDocClassificationData((prevState) => [
              ...prevState,
              {
                ...data,
                ID: response["#result-set-1"][0]["ID"],
              },
            ]);
          }
          handleClose();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putQuestionClassification = (
  customerID,
  data,
  questionClassification,
  setQuestionClassification,
  handleClose
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutQuestionClassification", {
        i_QUESTION_CLASSIFICATION_ID: data.QUESTION_CLASSIFICATION_ID,
        i_QUESTION_CLASSIFICATION_NAME: data.QUESTION_CLASSIFICATION_NAME,
        i_ACTIVE: data.ACTIVE,
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
        i_START_DATE: moment(data.START_DATE).format("YYYY-MM-DD HH:mm:ss"),
        i_END_DATE: moment(data.END_DATE).format("YYYY-MM-DD HH:mm:ss"),
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (data.QUESTION_CLASSIFICATION_ID) {
            const tempQueClassificationData = [...questionClassification];
            const index = tempQueClassificationData.findIndex(
              (keyword) =>
                keyword.QUESTION_CLASSIFICATION_ID ===
                data.QUESTION_CLASSIFICATION_ID
            );
            if (index !== -1) {
              tempQueClassificationData[index]["QUESTION_CLASSIFICATION_NAME"] =
                data.QUESTION_CLASSIFICATION_NAME;
              tempQueClassificationData[index]["ACTIVE"] = data.ACTIVE;
              tempQueClassificationData[index]["START_DATE"] = data.START_DATE;
              tempQueClassificationData[index]["END_DATE"] = data.END_DATE;
              setQuestionClassification(tempQueClassificationData);
            }
          } else {
            setQuestionClassification((prevState) => [
              ...prevState,
              {
                ...data,
                QUESTION_CLASSIFICATION_ID:
                  response["#result-set-1"][0]["QUESTION_CLASSIFICATION_ID"],
              },
            ]);
          }
          handleClose();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putORGUnit = (
  customerID,
  data,
  orgManagementData,
  setOrgManagementData,
  handleClose
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutORGUnit", {
        i_ORG_UNIT_ID: data.ORG_UNIT_ID,
        i_CISO_CONTROL_ID: data.CISO_CONTROL_ID,
        i_ORG_UNIT_NAME: data.ORG_UNIT_NAME,
        i_ORG_UNIT_PURPOSE: data.ORG_UNIT_PURPOSE,
        i_ORG_UNIT_LEVEL: data.ORG_UNIT_LEVEL,
        i_ORG_UNIT_OWNER: data.ORG_UNIT_OWNER,
        i_PARENT_ORG_UNIT_ID: data.PARENT_ORG_UNIT_ID,
        i_ORG_UNIT_REFERENCE: data.ORG_UNIT_REFERENCE,
        i_HOME_COUNTRY_ISO_CODE: data.HOME_COUNTRY_ISO_CODE,
        i_ORG_UNIT_COST_CENTER: data.ORG_UNIT_COST_CENTER,
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
        i_ACTIVE: data.ACTIVE,
        i_UPDATE_DATE: moment(data.UPDATE_DATE).format("YYYY-MM-DD HH:mm:ss"),
        i_START_DATE: moment(data.START_DATE).format("YYYY-MM-DD HH:mm:ss"),
        i_END_DATE: moment(data.END_DATE).format("YYYY-MM-DD HH:mm:ss"),
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (data.ORG_UNIT_ID) {
            const tempOrgManagementData = [...orgManagementData];
            const index = tempOrgManagementData.findIndex(
              (keyword) => keyword.ORG_UNIT_ID === data.ORG_UNIT_ID
            );
            if (index !== -1) {
              tempOrgManagementData[index] = { ...data };
              setOrgManagementData(tempOrgManagementData);
            }
          } else {
            setOrgManagementData((prevState) => [
              ...prevState,
              {
                ...data,
                ORG_UNIT_ID: response["#result-set-1"][0]["ORG_UNIT_ID"],
              },
            ]);
          }
          handleClose();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

export default {
  getCustomerOrgUnits,
  getCustomerSanctionKeywords,
  getCustomerQuestionClassifications,
  getCustomerDocumentClassifications,
  getCustomerCISOControls,
  putSanctionKeywords,
  putDocumentclassification,
  putQuestionClassification,
  putORGUnit,
};
