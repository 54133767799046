import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import {
  getShortName,
  getRandomHexColor,
  getRandomNum,
} from "../../utils";
import propTypes from "prop-types";

const EvidenceCollected = ({ data }) => {
  const groupedData = data?.reduce((acc, item) => {
    const existingGroup = acc?.find(
      (group) => group?.name === item?.EVIDENCE_SHORTNAME
    );

    if (existingGroup) {
      existingGroup[item?.USER_ID] =
        (existingGroup[item?.USER_ID] || 0) + item?.UPLOADED_BY_COUNT;
    } else {
      const newGroup = {
        name: item?.EVIDENCE_SHORTNAME,
        [item?.USER_ID]: item?.UPLOADED_BY_COUNT,
      };
      acc?.push(newGroup);
    }

    return acc;
  }, []);

  const userList = Array.from(new Set(data?.map((item) => item?.USER_ID)));

  return (
    <ResponsiveContainer>
      <BarChart
        data={groupedData}
        layout="vertical"
        margin={{
          top: 10,
          right: 20,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis
          tickFormatter={(value) => getShortName(value)}
          dataKey="name"
          type="category"
        />
        <Tooltip />
        <Legend
          formatter={(value) =>
            data?.find((i) => i?.USER_ID === value)?.USER_NAME
          }
        />
        {userList?.map((user) => (
          <Bar
            key={user}
            barSize={10}
            dataKey={user}
            stackId="a"
            fill={getRandomHexColor()}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

EvidenceCollected.propTypes = {
  data: propTypes.array,
};

export default EvidenceCollected;
