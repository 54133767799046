import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from "prop-types";
import langConstant from "_lang";
import classNames from 'classnames';
import useStyles from '../styles';
import Step1Form from './components/step1Form';
import ProgressComponent from './components/progressComponent';


const Step1 = ({ handleClose, uploadOnly, setUploadOnly, setCurrentStep }) => {

    const [progressLogsData, setProgressLogsData] = useState([])
    const [progressView, setProgressView] = useState(false)

    const classes = useStyles()

    return (
        <Grid container>
            <Grid container className={classNames(classes.modalTitleWrapper,classes.flex)} item md={12} xs={12}>
                <Typography className={classes.modalTitle}>{langConstant.DOCUMENT_DETAILS}</Typography>
            </Grid>
            <Grid item md={8} style={{height: "calc(100vh - 16vh)"}}>
                <Step1Form 
                    uploadOnly={uploadOnly}
                    setUploadOnly={setUploadOnly}
                    handleClose={handleClose}
                    setProgressLogsData={setProgressLogsData}
                    setProgressView={setProgressView}
                    progressView={progressView}
                    setCurrentStep={setCurrentStep}
                />
            </Grid>
            <Grid item md={4}>
                {progressView &&
                    <ProgressComponent 
                        progressLogsData={progressLogsData}
                        currentStep={1}
                    />
                }
            </Grid>
        </Grid>
    )
}

Step1.propTypes = {
    handleClose: PropTypes.func,
    uploadOnly: PropTypes.bool,
    setUploadOnly: PropTypes.func,
    setCurrentStep: PropTypes.func,
};

export default Step1;