import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Typography, Box, Grid, TextField, makeStyles, Button, MenuItem } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Select from '@mui/material/Select';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CircleIcon from "@mui/icons-material/Circle";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Checkbox from "@mui/material/Checkbox";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import _ from "lodash"
import { alertActions } from "_actions";
import { useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import individualQuestionAction from "../actions";
import sessionService from "_services/session.service";



const useStyles = makeStyles(() => ({

  justifybetween: {
    justifyContent: "space-between",
    display: "flex"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  cursorpointer: {
    cursor: "pointer"
  },
  height100: {
    height: "100%"
  },
  width100: {
    width: '100%',
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px"
  },
  btnWidth: {
    width: "170px"
  },
  warningBox: {
    border: "1px solid #FFEB9B",
    borderRadius: "10px",
    width: "90%",
    background: "#FFFDF3",
    display: "flex"
  },
  errorBox: {
    borderRadius: "10px",
    width: "90%",
    background: "#F8A4AE",
    display: "flex",
    color: "black",
    alignItems: "center",
    justifyContent: "center"
  },
  noteBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: "#5CB683"
  }
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const optionArr = ["RESPONSE OPTIONS", "CONTROLS", "EVIDENCES", "DATA ASSETS", "RULES"];

const CopyModulesDialogBox = ({ isDialogOpen, selectedQuestionForCopy, setIsDialogOpen, questionID, regulationID, SaveSelectionData, setSaveSelectionData, fetchdata }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const customerID = sessionService.getCustomerId();

  const [openArr, setOpenArr] = useState(optionArr);
  const [openArr2, setOpenArr2] = useState(optionArr);
  const [confirmpageOpenArr, setConfirmpageOpenArr] = useState(optionArr)
  const [page, setPage] = useState(0);
  const [gridOneListData, setGridOneListData] = useState([]);
  const [gridThirdListData, setGridThirdListData] = useState([]);
  const [selectedQuestionID, setSelectedQuestionID] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [selectedModules, setSelectedModules] = useState([{ header: "RESPONSE OPTIONS", data: [] }, { header: "CONTROLS", data: [] }, { header: "EVIDENCES", data: [] }, { header: "DATA ASSETS", data: [] }, { header: "RULES", data: [] }]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModuleRepeated, setIsModuleRepeated] = useState(false);

  useEffect(() => {
    const flattenArr = [...SaveSelectionData].map((el) => el.data).flat();
    if (flattenArr.length > 0) {
      setSelectedModules(SaveSelectionData)
    }
  }, [SaveSelectionData])

  const HandleDiaglogClose = () => {
    setSaveSelectionData([]);
    setIsDialogOpen(false);
    setSelectedModules([{ header: "RESPONSE OPTIONS", data: [] }, { header: "CONTROLS", data: [] }, { header: "EVIDENCES", data: [] }, { header: "DATA ASSETS", data: [] }, { header: "RULES", data: [] }]);
    setSelectedQuestion({});
    setSelectedQuestionID("")
  }


  const SubmitCopiedAssets = async () => {
    const data = [...selectedModules]?.map((el) => (el.data.map((element) => ({ ...element, ControlAttributeType: el.header })))).flat();
    const objectWithGroupBy = _.groupBy([...data], "QUESTION_ID");

    await Promise.all(
      Object.keys(objectWithGroupBy).map(async (Qid) => {

        let dataObj = _.groupBy([...objectWithGroupBy[Qid]], "ControlAttributeType");

        const dataObjj = {
          i_SOURCEQUESTION_ID: Qid,
          i_TARGETQUESTION_ID: questionID,
          i_REGULATION_ID: regulationID,
          i_RESPONSE_OPTION_IDS: dataObj["RESPONSE OPTIONS"] ? dataObj["RESPONSE OPTIONS"].map((el) => el.RESPONSE_OPTION_ID).join(",") : "",
          i_CISO_CONTROL_IDS: dataObj["CONTROLS"] ? dataObj["CONTROLS"].map((el) => el.CISO_CONTROL_ID).join(",") : "",
          i_EVIDENCE_TYPE_ID: dataObj["EVIDENCES"] ? dataObj["EVIDENCES"].map((el) => el.EVIDENCE_TYPE_ID).join(",") : "",
          i_SOURCE_DATA_IDS: dataObj["DATA ASSETS"] ? dataObj["DATA ASSETS"].map((el) => el.SOURCE_DATA_ID).join(",") : "",
          i_CUSTOMER_ID: customerID
        }

        const data = await individualQuestionAction.PutCopiedMappings(dataObjj);

        if (data.data && data.status) {
          dispatch(
            alertActions.error(
              data.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );

        } else {
          dispatch(alertActions.success("All assets have been successfully copied"));
          HandleDiaglogClose();
        }
      })
    );

    setTimeout(function () {
      fetchdata();
    }, 200);


  }

  const handleListCollapse = (header, identifier, flag) => {

    if (flag) {

      const data = [...confirmpageOpenArr];

      if (data.indexOf(header) > -1) {
        const finalarr = data.filter((el) => el !== header);
        setConfirmpageOpenArr(finalarr);
      } else {
        setConfirmpageOpenArr([...data, header]);
      }

    } else {
      if (identifier) {
        const data = [...openArr];

        if (data.indexOf(header) > -1) {
          const finalarr = data.filter((el) => el !== header);
          setOpenArr(finalarr);
        } else {
          setOpenArr([...data, header]);
        }

      } else {
        const data = [...openArr2];

        if (data.indexOf(header) > -1) {
          const finalarr = data.filter((el) => el !== header);
          setOpenArr2(finalarr);
        } else {
          setOpenArr2([...data, header]);
        }

      }

    }

  };

  const HandleOnQuestionchange = async (element) => {
    setIsLoading(true);
    const response = await individualQuestionAction.GetQuestionMappingData(element.SIMILAR_QUESTION);
    const temp = [...openArr].map((el, i) => ({ header: el, data: response["#result-set-" + (i + 1)] }));
    setGridOneListData(temp)
    setSelectedQuestion(element);
    setIsLoading(false);

  }

  const FirstListClickHandler = (header, element) => {
    const tempModules = [...selectedModules]

    if (tempModules.some((el) => el.header === header)) {

      let tempobj = tempModules.find((el) => el.header === header);
      const data2 = [...tempModules].filter((el) => el.header !== header);

      if (tempobj["data"]?.some((elee) => JSON.stringify(elee) === JSON.stringify(element))) {
        tempobj["data"] = tempobj["data"].filter((el) => JSON.stringify(el) !== JSON.stringify(element));
        let tempdata = [...data2, tempobj];
        setSelectedModules(tempdata);
        SecondListClickHandler(element, false, tempdata);

      } else {
        let tempdataAarr = [...tempobj["data"]];
        tempdataAarr.push(element);
        tempobj["data"] = tempdataAarr;
        setSelectedModules([...data2, tempobj]);
        let tempdata2 = [...data2, tempobj];
        SecondListClickHandler(element, false, tempdata2);
      }
    } else {
      setSelectedModules((prev) => ([...prev, { header, data: [element] }]));
      let tempdata3 = [...selectedModules, { header, data: [element] }];
      SecondListClickHandler(element, false, tempdata3);
    }
  }

  const gridTwoListData = () => {
    const flattenArr = [...selectedModules].map((el) => el.data).flat();
    const objectWithGroupBy = _.countBy(flattenArr, "QUESTION_ID");
    const result = Object.keys(objectWithGroupBy).map((key) => ({ questionID: key, count: objectWithGroupBy[key] }));
    return result;

  }

  const SecondListClickHandler = (element, bool, arr) => {
    setSelectedQuestionID(element.questionID);
    if (bool) {
      const tempaArr = [...selectedModules].map((el) => {
        let tempdata = el["data"].filter((data) => data.QUESTION_ID === element.questionID);
        return ({ header: el.header, data: tempdata })
      })
      setGridThirdListData(tempaArr);
    } else {
      const tempaArr2 = [...arr].map((el) => {
        let tempdata = el["data"].filter((data) => data.QUESTION_ID === element.QUESTION_ID);
        return ({ header: el.header, data: tempdata })
      })
      setGridThirdListData(tempaArr2);

    }

  }

  const RemoveQuestions = (questionID) => {
    const data = [...selectedModules].map((el) => ({ header: el.header, data: el.data.filter((ele) => ele.QUESTION_ID !== questionID) }));
    setSelectedModules(data);
    setSelectedQuestionID("");
    setGridThirdListData([]);
  }

  const DeleteAssetsFromConfirmationPage = (header, element, index) => {
    const data = [...selectedModules].map((el) => {
      if (el.header === header) {
        return { header: el.header, data: el.data.filter((ele, i) => (i !== index) && !(_.isEqual(ele, element))) }
      }
      return el
    });
    setSelectedModules(data);
  }

  const ListItem = (Listdata, checkArr, flag, onListClick, collapseIdentifier) => {

    const ListTextHandler = (element, header, identifier) => {

      let text;
      let bool = false;

      switch (header) {
        case "RESPONSE OPTIONS": text = element.RESPONSE_VALUE
          break;

        case "CONTROLS": text = element.CISO_CONTROL_NAME
          break;

        case "EVIDENCES": text = element.EVIDENCE_SHORTNAME
          break;

        case "DATA ASSETS": text = element.DATA_ASSET_ATTRIBUTE_NAME
          break;

        case "RULES": text = element.RULE_DESCRIPTION
          break;

        default:
          break;
      }

      if (identifier) {

        const data1 = [...Listdata].find((el) => el.header === header)["data"];

        switch (header) {
          case "RESPONSE OPTIONS": {
            let arr1 = data1.filter((ele) => `${ele.RESPONSE_OPTION}_${ele.RESPONSE_VALUE}` === `${element.RESPONSE_OPTION}_${element.RESPONSE_VALUE}`);
            if (arr1.length > 1) {
              bool = true;
            }

            break;
          }

          case "CONTROLS": {
            let arr2 = data1.filter((ele) => `${ele.CISO_CONTROL_NAME}_${ele.CISO_CONTROL_ID}` === `${element.CISO_CONTROL_NAME}_${element.CISO_CONTROL_ID}`);
            if (arr2.length > 1) {
              bool = true;
            }

            break;
          }

          case "EVIDENCES": {
            let arr3 = data1.filter((ele) => `${ele.EVIDENCE_SHORTNAME}_${ele.EVIDENCE_TYPE_ID}` === `${element.EVIDENCE_SHORTNAME}_${element.EVIDENCE_TYPE_ID}`);
            if (arr3.length > 1) {
              bool = true;
            }

            break;
          }

          case "DATA ASSETS": {
            let arr4 = data1.filter((ele) => `${ele.DATA_ASSET_ATTRIBUTE_NAME}_${ele.SOURCE_SYSTEM_NAME}` === `${element.DATA_ASSET_ATTRIBUTE_NAME}_${element.SOURCE_SYSTEM_NAME}`);
            if (arr4.length > 1) {
              bool = true;
            }

            break;
          }

          case "RULES": {
            let arr5 = data1.filter((ele) => `${ele.RULE_DESCRIPTION}_${ele.ATTRIBUTE_NAME}` === `${element.RULE_DESCRIPTION}_${element.ATTRIBUTE_NAME}`);
            if (arr5.length > 1) {
              bool = true;
            }

            break;
          }

          default:
            break;
        }


      }



      return (
        <Typography style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontSize: "14px", color: bool ? "red" : "" }}>
          {text}
        </Typography>)

    }

    return (
      <List
        dense
        sx={{ width: "100%", bgcolor: "background.paper", padding: "0px", height: "100%" }}
      >
        {Listdata.sort((a, b) => a.header.localeCompare(b.header)).map((el, i) => (
          <>
            <ListItemButton
              onClick={() => {
                handleListCollapse(el.header, collapseIdentifier, flag);
              }}
              sx={{ background: "#F6F6F6" }}
            >
              <ListItemText primary={el.header} />
              {
                flag && (
                  <ListItemAvatar>
                    <Avatar sx={{ width: 30, height: 30, bgcolor: "#CEF8D6", color: "black", fontSize: "14px" }}>{el?.data.length}</Avatar>
                  </ListItemAvatar>
                )
              }

              {checkArr.indexOf(el.header) > -1 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={checkArr.indexOf(el.header) > -1}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding dense>
                {el?.data?.map((ele, index) => (
                  <>
                    <ListItemButton sx={{ padding: "0px 15px", "&:hover": { backgroundColor: "transparent" } }} key={`${ele.text}-${i}`} onClick={(e) => { flag ? e.preventDefault() : onListClick(el.header, ele) }}>


                      {
                        !flag && (
                          <>
                            <CircleIcon sx={{ fontSize: 10, color: "#757575" }} />
                            <ListItemText primary={ListTextHandler(ele, el.header, false)} style={{ marginLeft: "10px" }} />
                            <Checkbox
                              edge="end"
                              checked={!collapseIdentifier ? true : [...selectedModules].map((el) => el.data).flat().some((elee) => JSON.stringify(elee) === JSON.stringify(ele))}
                              disableRipple disabled={!collapseIdentifier}
                              onClick={(e) => { e.preventDefault() }}
                            />
                          </>
                        )
                      }

                      {
                        flag && (
                          <>
                            <ListItemText primary={ele.QUESTION_ID} style={{ maxWidth: "25%", minWidth: "25%", marginLeft: "10px" }} />
                            <Divider orientation="vertical" flexItem />
                            <ListItemText primary={ListTextHandler(ele, el.header, true)} style={{ marginLeft: "10px" }} />
                            <DeleteOutlineRoundedIcon
                              sx={{ fontSize: 30, cursor: "pointer" }}
                              color="error"
                              onClick={() => { DeleteAssetsFromConfirmationPage(el.header, ele, index) }}
                            />
                          </>
                        )
                      }

                    </ListItemButton>
                    {
                      flag && <Divider />
                    }

                  </>
                ))}
              </List>
            </Collapse>
          </>
        ))}
      </List>
    )
  }

  const FirstGrid = () => {

    return (
      <>
        <Grid container={true} style={{ height: "13%" }}>
          <Grid item={true} xs={12} md={5}>
            <Typography className={classes.filedTitle}>
              Copying asset from
            </Typography>
            <Select
              value={selectedQuestion}
              onChange={(e) => { HandleOnQuestionchange(e.target.value) }}
              renderValue={(selected) => {
                if (selected === undefined || selected === null || selected === "") {
                  return <span style={{ color: "#BBB6C4" }}>Select</span>;
                }
                return selected.SIMILAR_QUESTION;
              }}
              displayEmpty
              MenuProps={MenuProps}
              className={`${classes.width100}`}
              sx={{ height: 40 }}
            >
              {selectedQuestionForCopy.map((el) => (
                <MenuItem key={`${el.SIMILAR_QUESTION}_${el.CISO_CONTROL}_${el.DATA_ASSETS}_${el.EVIDENCE}_${el.SIMILARITY_SCORE}`} value={el}>
                  <ListItemText primary={el.SIMILAR_QUESTION} />
                </MenuItem>
              ))}
            </Select>

          </Grid>
          <Grid item={true} xs={12} md={2} style={{ alignSelf: "center", display: "flex", justifyContent: "center" }}>
            <ArrowForwardIcon style={{ fontSize: "36px" }} />
          </Grid>
          <Grid item={true} xs={12} md={5} >

            <Typography className={classes.filedTitle}>
              Copying asset to
            </Typography>

            <TextField
              size="small"
              variant="outlined"
              fullWidth
              value={questionID ? questionID : ""}
              className={classes.width100}
              inputProps={
                { readOnly: true, }
              }
            />
          </Grid>
        </Grid>

        <Grid item={true} style={{ height: "86%" }}>
          <Typography className={classes.filedTitle}>
            Module
          </Typography>
          <Card variant="outlined" style={{ width: "100%", height: "95%" }}>
            {
              isLoading === true ? (
                <div style={{ display: 'flex', justifyContent: 'center', height: "inherit", alignItems: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <CardContent style={{ padding: "0px", height: "100%", minHeight: "100%" }}>
                  <PerfectScrollbar >
                    {ListItem(gridOneListData, openArr, false, FirstListClickHandler, true)}
                  </PerfectScrollbar >
                </CardContent>
              )
            }
          </Card>
        </Grid>
      </>
    )

  }

  const SecondGrid = () => {

    return (
      <Grid item={true} xs={12} md={12} className={classes.height100}>
        <Typography className={classes.filedTitle}>
          Copied Questions
        </Typography>

        <Card variant="outlined" style={{ width: "100%", height: "95%" }}>
          <CardContent style={{ padding: "0px", height: "100%", minHeight: "100%" }}>
            <PerfectScrollbar >
              <List sx={{ width: "100%", bgcolor: "background.paper", padding: "0px", height: "100%" }}>
                {gridTwoListData().map((el) => (
                  <>
                    <ListItemButton
                      selected={el.questionID === selectedQuestionID}
                      onClick={() => { SecondListClickHandler(el, true) }}
                    >
                      <IconButton style={{ marginRight: "10px" }} onClick={(e) => { e.stopPropagation(); RemoveQuestions(el.questionID); }} sx={{ "&:hover": { backgroundColor: "#f7cfcf" } }}>
                        <CloseIcon className={classes.cursorpointer} style={{ fontSize: "20px", stroke: "black", strokeWidth: 1 }} />
                      </IconButton>

                      <ListItemAvatar>
                        <Avatar sx={{ width: 34, height: 34, bgcolor: "#CEF8D6", color: "black", fontSize: "16px" }}>{el?.count}</Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={el?.questionID} />
                      {el.questionID === selectedQuestionID ? <KeyboardArrowRightIcon /> : <ExpandMore />}
                    </ListItemButton>
                    <Divider variant="middle" />
                  </>
                ))}
              </List>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </Grid>
    )

  }


  const ThirdGrid = () => {

    return (
      <Grid item={true} style={{ height: "100%" }}>
        <Typography className={classes.filedTitle}>
          Copied Module
        </Typography>
        <Card variant="outlined" style={{ width: "100%", height: "95%" }}>
          <CardContent style={{ padding: "0px", height: "100%", minHeight: "100%" }}>
            <PerfectScrollbar >
              {ListItem(gridThirdListData, openArr2, false, () => { }, false)}
            </PerfectScrollbar >
          </CardContent>
        </Card>
      </Grid>
    )

  }

  const SecondPageList = () => {

    return (
      <Grid item={true} style={{ height: "100%" }}>
        <Typography className={classes.filedTitle}>
          Assets copied to questionid
        </Typography>
        <Card variant="outlined" style={{ width: "100%", height: "95%" }}>
          <CardContent style={{ padding: "0px", height: "100%", minHeight: "100%" }}>
            <PerfectScrollbar >
              {ListItem(selectedModules, confirmpageOpenArr, true, () => { }, false)}
            </PerfectScrollbar >
          </CardContent>
        </Card>
      </Grid>
    )

  }


  const ComfimCopy = () => {
    setPage(1);

    selectedModules.map((element) => {

      if (element.header === "CONTROLS") {
        let arr1 = element.data.map((ele) => `${ele.CISO_CONTROL_NAME}_${ele.CISO_CONTROL_ID}`)
        let isdupli = arr1.some((ele, i) => arr1.indexOf(ele) !== i);
        if (isdupli) {
          setIsModuleRepeated(true)
        }
      }

      if (element.header === "RESPONSE OPTIONS") {
        let arr1 = element.data.map((ele) => `${ele.RESPONSE_OPTION}_${ele.RESPONSE_VALUE}`)
        let isdupli = arr1.some((ele, i) => arr1.indexOf(ele) !== i);
        if (isdupli) {
          setIsModuleRepeated(true)
        }
      }

      if (element.header === "EVIDENCES") {
        let arr1 = element.data.map((ele) => `${ele.EVIDENCE_SHORTNAME}_${ele.EVIDENCE_TYPE_ID}`)
        let isdupli = arr1.some((ele, i) => arr1.indexOf(ele) !== i);
        if (isdupli) {
          setIsModuleRepeated(true)
        }
      }

      if (element.header === "DATA ASSETS") {
        let arr1 = element.data.map((ele) => `${ele.DATA_ASSET_ATTRIBUTE_NAME}_${ele.SOURCE_SYSTEM_NAME}`)
        let isdupli = arr1.some((ele, i) => arr1.indexOf(ele) !== i);
        if (isdupli) {
          setIsModuleRepeated(true)
        }
      }

      if (element.header === "RULES") {
        let arr1 = element.data.map((ele) => `${ele.RULE_DESCRIPTION}_${ele.ATTRIBUTE_NAME}`)
        let isdupli = arr1.some((ele, i) => arr1.indexOf(ele) !== i);
        if (isdupli) {
          setIsModuleRepeated(true)
        }
      }

    })

  }

  const renderSecondPageWarning = () => {
    if (isModuleRepeated) {
      return (
        <div className={classes.errorBox}>
          <div style={{ margin: "0px 5px" }}>
            <ReportProblemOutlinedIcon />
          </div>
          <div>
            Highlighted asset has been repeated
          </div>
        </div>

      )
    } else {
      return (<></>)
    }

  }

  return (
    <Dialog
      open={isDialogOpen}
      maxWidth={false}
      fullWidth={true}
      keepMounted
      onClose={HandleDiaglogClose}
      disableEnforceFocus
    >
      <DialogTitle>
        <div className={`${classes.justifybetween} ${classes.alignItemsCenter}`} >

          <span> {page === 0 ? "COPY MODULES" : "COPY CONFIRMATION"} </span>
          <CloseIcon className={classes.cursorpointer} onClick={HandleDiaglogClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Box style={{ height: "80vh" }}>
          <Grid container={true} justify="space-between" style={{ height: "90%" }}>

            {
              page === 0 && (
                <>
                  <Grid item={true} xs={12} md={4} className={classes.height100} >
                    {FirstGrid()}
                  </Grid>

                  <Divider variant="middle" orientation="vertical" flexItem />

                  <Grid item={true} xs={12} md={3} className={classes.height100} >
                    {SecondGrid()}
                  </Grid>

                  <Divider variant="middle" orientation="vertical" flexItem />

                  <Grid item={true} xs={12} md={4} className={classes.height100} >
                    {ThirdGrid()}
                  </Grid>

                </>
              )
            }

            {
              page === 1 && (
                <Grid item={true} xs={12} md={12} className={classes.height100} >
                  {SecondPageList()}
                </Grid>
              )
            }



          </Grid>

          <Grid container={true} style={{ marginTop: "10px" }}>



            <Grid item={true} xs={12} md={4} style={{ display: "flex", justifyContent: "center" }}>
              {
                page === 0 && (
                  <div className={classes.warningBox}>
                    <div style={{ margin: "0px 5px" }}>
                      <InfoOutlinedIcon />
                    </div>
                    <div>
                      Only one rule can be copied per question. if you try to select multiple rules then the previously selected rule will be deselected
                    </div>
                  </div>
                )
              }


              {
                (page === 1 && isModuleRepeated === true) && renderSecondPageWarning()
              }
            </Grid>

            <Grid item={true} xs={12} md={4} style={{ justifyContent: "space-evenly", display: "flex" }}>

              {
                page === 0 && (
                  <>
                    <Button color="primary" className={classes.btnWidth} variant="outlined"
                      onClick={() => {
                        setSaveSelectionData(selectedModules);
                        setIsDialogOpen(false);
                        setSelectedQuestion({});
                        setSelectedQuestionID("");
                      }}
                      disabled={selectedModules.map((el) => el.data).flat().length === 0}
                    >
                      SAVE SELECTION
                    </Button>
                    <Button color="primary" className={classes.btnWidth} variant="contained" type="submit" startIcon={<ContentCopyIcon />} onClick={ComfimCopy} disabled={selectedModules.map((el) => el.data).flat().length === 0}>
                      CONFIRM COPY
                    </Button>
                  </>
                )
              }

              {
                page === 1 && (
                  <>
                    <Button color="primary" className={classes.btnWidth} variant="outlined" onClick={() => { setPage(0) }}>
                      GO BACK
                    </Button>
                    <Button color="primary" className={classes.btnWidth} variant="contained" startIcon={<ContentCopyIcon />} disabled={selectedModules.map((el) => el.data).flat().length === 0} onClick={SubmitCopiedAssets}>
                      COPY ASSETS
                    </Button>
                  </>
                )
              }
            </Grid>

            {
              page === 1 && (
                <Grid item={true} xs={12} md={4} className={classes.noteBox}>
                  <div>
                    <strong>NOTE :</strong> <span style={{ fontWeight: 500 }}>Saving would append the assets with existing assets</span>
                  </div>
                </Grid>
              )
            }

          </Grid>

        </Box>
      </DialogContent>

    </Dialog>
  )
}

CopyModulesDialogBox.propTypes = {
  isDialogOpen: PropTypes.bool,
  selectedQuestionForCopy: PropTypes.array,
  setIsDialogOpen: PropTypes.func,
  questionID: PropTypes.string,
  regulationID: PropTypes.string,
  setSaveSelectionData: PropTypes.func,
  SaveSelectionData: PropTypes.array,
  fetchdata: PropTypes.func
}

export default CopyModulesDialogBox