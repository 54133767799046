import React from "react";
import moment from "moment";
import langConstant from "_lang";
import { Chip } from "@mui/material";

const columns = [
  { field: "ACTIONS_SHORT_DESCRIPTION", headerName: langConstant.DESCRIPTION },
  {
    field: "ASSIGN_BY",
    headerName: langConstant.ASSIGN_BY,
    format: function formatData(value) {
      return value["ASSIGN_BY_FIRST_NAME"] + " " + value["ASSIGN_BY_LAST_NAME"];
    },
  },
  {
    field: "ASSIGN_TO",
    headerName: langConstant.ASSIGN_TO,
    format: function formatData(value) {
      return value["ASSIGN_TO_FIRST_NAME"] + " " + value["ASSIGN_TO_LAST_NAME"];
    },
  },
  {
    field: "ACTIONS_PRIORITY",
    headerName: langConstant.PRIORITY,
    format: function formatData(value) {
      const priorityLabel = { H: "HIGH", M: "MEDIUM", L: "LOW" };
      const textBgColor = { H: "red", M: "green", L: "yellow" };
      return (
        <Chip
          label={priorityLabel[value["ACTIONS_PRIORITY"]]}
          sx={{
            backgroundColor: textBgColor[value["ACTIONS_PRIORITY"]],
            color: "white",
            fontWeight: "bold",
          }}
        />
      );
    },
  },
  {
    field: "DUE_DATE",
    headerName: langConstant.DUE_DATE,
    format: function formatData(value) {
      return moment(value["DUE_DATE"]).format("YYYY-MM-DD HH:mm:ss");
    },
  },
];

const expandedColumns = [
  ...columns,
  {
    field: "CLOSURE_DATE",
    headerName: langConstant.CLOSURE_DATE,
    format: function formatData(value) {
      return moment(value["CLOSURE_DATE"]).format("YYYY-MM-DD HH:mm:ss");
    },
  },
];

export default { columns, expandedColumns };
