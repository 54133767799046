import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import langConstant from "_lang";

const useStyles = makeStyles(() => ({
  selectWrap: {
    width: "76px",
    margin: "0 10px",
  },
}));

const SelectPageRange = ({
  selectedRange,
  handleChange,
  styles = { height: 35, minHeight: 35 },
  disabled,
}) => {
  const classes = useStyles();
  const pageRanges = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 25, label: 25 },
  ];

  const customStyles = {
    control: (base) => ({
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      ...base,
      ...styles,
    }),
  };

  return (
    <Grid style={{ display: "inline-flex", alignItems: "center" }}>
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: "bold",
          opacity: disabled ? 0.6 : 1,
        }}
      >
        {langConstant.SELECT_NO_OF_RECORDS}
      </Typography>
      <Select
        options={pageRanges}
        value={pageRanges.find((item) => item.value == selectedRange)}
        onChange={handleChange}
        isSearchable={false}
        className={classes.selectWrap}
        styles={customStyles}
        menuPortalTarget={document.body}
        isDisabled={disabled}
      />
    </Grid>
  );
};

SelectPageRange.propTypes = {
  selectedRange: PropTypes.number,
  handleChange: PropTypes.func,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
};

export default SelectPageRange;
