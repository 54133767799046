// OP#2994 - UJA10 - UI Development : Manage Evidence Types - Part 1 only
// Main index file for Evidence-types tab

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import classNames from "classnames";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  GetEvidenceTypeList,
  GetRegulatoryDomainsForUser,
  GetCustomerInfoClassfication,
} from "./services";
import AppPagination from "_components/AppPagination";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@mui/icons-material/Add";
import OutboxIcon from "@mui/icons-material/Outbox";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import rootStyles from "rootStyles";
import FilterDropdown from "_components/FilterDropdown";
import AddEditForm from "./components/EvidenceAddEditForm/AddEditForm";
import UploadFile from "./components/UploadFilePopUp/UploadFile";
import BulkEvidenceUpload from "./components/BulkEvidenceUpload/BulkEvidenceUpload";
import ExtractedKeywords from "./components/ExtractedkeywordsPopUp/ExtractedKeywords";
import sessionService from "_services/session.service";
import langConstant from "_lang";
import { PutEvidenceType } from "./services";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { useModal } from "_hooks";
import { boldOnSearch } from "_helpers/utils";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  marginTop: {
    marginTop: "15px",
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  acinStatus: {
    color: "#000000",
    border: "1px solid",
    borderRadius: "5px",
    padding: "3px 5px 1px",
    minWidth: "140px",
    textAlign: "center",
    display: "inline-block",
    textTransform: "uppercase",
    fontSize: "13px",
  },
  activeStatus: {
    borderColor: "#aff3bb",
    backgroundColor: "#aff3bb",
    textAlign: "center",
  },
  inactiveStatus: {
    borderColor: "#dfdfdf",
    backgroundColor: "#dfdfdf",
    textAlign: "center",
  },
}));

const EvidenceTypes = () => {
  const themeDetails = useSelector(getThemeDetails);
  const rootClasses = rootStyles();
  const classes = useStyles();
  const [evidenceList, setevidenceList] = useState([]);
  const [filteredEvidenceList, setFilteredEvidenceList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const [domainOptions, setDomainOptions] = useState([]);
  const [classificationOptions, setclassificationOptions] = useState([]);
  const [fileContent, setFileContent] = useState(null);
  const activeOptions = [
    { value: 1, name: "ACTIVE" },
    { value: 0, name: "INACTIVE" },
  ];
  const userData = useSelector((state) => state.authentication.userData);

  const fileRef = useRef(null);
  let manageRegulation_RegulationAdmin = false;

  if (
    userData &&
    userData.allowedNavItems &&
    userData.allowedNavItems.length > 0 &&
    userData.allowedNavItems.indexOf("MANAGE_REGULATION/REGULATION_ADMIN") > -1
  ) {
    manageRegulation_RegulationAdmin = true;
  }

  const calculatePageCount = (evidenceList) => {
    return evidenceList.length > 0
      ? Math.ceil(Math.min(evidenceList.length / rowsPerPage))
      : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(evidenceList));
  const [filterData, setFilterData] = useState({
    searchText: "",
    DomainName: "",
    orderByName: "",
    ACTIVE: "",
  });
  const [AddEditModal, setAddEditModal] = useState({
    visible: false,
    mode: "add",
    data: {},
  });
  const uploadFileModal = useModal(),
    extractedKeywordModal = useModal(),
    BulkEvidenceModal = useModal();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");

  const columns = [
    { field: "EVIDENCE_TYPE_ID", headerName: "ID", minWidth: 40 },
    {
      field: "EVIDENCE_SHORTNAME",
      headerName: "NAME",
      format: (value) => boldOnSearch(value, filterData?.searchText),
      minWidth: 100,
    },
    { field: "OWNER", headerName: "OWNER", minWidth: 100 },
    { field: "EVIDENCE_SOURCE", headerName: "SOURCE", minWidth: 100 },
    { field: "ACTIVE", headerName: "STATUS", minWidth: 100 },
    { field: "action", headerName: "ACTIONS", minWidth: 100, sticky: true },
  ];

  const updaterFunction = (data) => {
    const updatedRows = JSON.parse(JSON.stringify(data));
    const updatedData = filterFunction(updatedRows);
    setFilteredEvidenceList(
      updatedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    );
    setPageCount(calculatePageCount(updatedData));
  };

  const getEvidenceTypeListByAPI = async () => {
    await GetEvidenceTypeList({
      i_USER_ID: parseInt(userID),
      i_CUSTOMER_ID: parseInt(customerID),
    })
      .then((response) => {
        if (response?.status !== 200) throw new Error("Something went wrong");
        setevidenceList(response?.["#result-set-1"]);
        response?.["#result-set-1"]?.length &&
          updaterFunction(response?.["#result-set-1"]);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(getEvidenceTypeListByAPI, []);

  useEffect(() => {
    updaterFunction(evidenceList);
  }, [page, filterData, evidenceList]);

  useEffect(async () => {
    const data = await GetRegulatoryDomainsForUser(userID);
    const data2 = await GetCustomerInfoClassfication(userID);
    setDomainOptions(data["#result-set-1"]);
    setclassificationOptions(data2["#result-set-1"]);
  }, [userID]);

  const pageSetter = (sortedData) => {
    const totalrows = calculatePageCount(sortedData);
    if (page + 1 > totalrows) {
      setPage(0);
    }
  };

  const filterFunction = (data) => {
    let sortedData = data;

    if (filterData.orderByName !== "") {
      sortedData = sortedData.sort((a, b) =>
        filterData.orderByName === "ASC"
          ? a.EVIDENCE_SHORTNAME.localeCompare(b.EVIDENCE_SHORTNAME)
          : b.EVIDENCE_SHORTNAME.localeCompare(a.EVIDENCE_SHORTNAME)
      );
      pageSetter(sortedData);
    }

    if (filterData.searchText !== "") {
      sortedData = sortedData.filter((evidence) =>
        evidence.EVIDENCE_SHORTNAME.toLowerCase().includes(
          filterData.searchText.toLowerCase()
        )
      );
      pageSetter(sortedData);
    }

    if (filterData.DomainName !== "") {
      sortedData = sortedData.filter(
        (evidence) => evidence.REGULATORY_DOMAIN_ID === filterData.DomainName
      );
      pageSetter(sortedData);
    }

    if (filterData.ACTIVE !== "") {
      sortedData = sortedData.filter(
        (evidence) => evidence.ACTIVE == filterData.ACTIVE
      );
      pageSetter(sortedData);
    }

    return sortedData;
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onFilterChange = (fieldName, e) => {
    const filterValue = e.target.value;
    const filterObj = { ...filterData, [fieldName]: filterValue };
    setFilterData(filterObj);
  };

  const createFilterDropdown = (fieldName, label) => {
    let options = [];

    if (domainOptions.length > 0) {
      domainOptions.map((item) => {
        options.push({
          value: item.REGULATORY_DOMAIN_ID,
          name: item.REGULATION_DOMAIN,
        });
      });
    }
    if (options.length > 0) {
      return (
        <FilterDropdown
          options={options}
          label={label}
          fieldName={fieldName}
          value={filterData[fieldName]}
          onChange={onFilterChange}
          icon={true}
        />
      );
    }
  };

  const viewDataToggle = (rowData) => {
    setAddEditModal({
      ...AddEditModal,
      visible: true,
      mode: "view",
      data: rowData,
    });
  };

  const sortByName = () => {
    if (filterData.orderByName === "") {
      setFilterData({ ...filterData, orderByName: "ASC" });
    } else {
      if (filterData.orderByName === "ASC") {
        setFilterData({ ...filterData, orderByName: "DESC" });
      } else {
        setFilterData({ ...filterData, orderByName: "ASC" });
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    let hasRequiredHeaders = true;
    const required_headers = [
      "Evidence_Shortname",
      "Evidence_Description",
      "Regulatory_Domain",
    ];

    let parsedContent = [];
    let hasInvalidStructure = false;

    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const lines = text.split("\n");
        lines.forEach((line, index) => {
          if (hasInvalidStructure) return;

          if (index == 0) {
            //validate file structure
            const headers = line.trim().toLowerCase();
            hasRequiredHeaders = required_headers.every((header) =>
              headers.includes(header.trim().toLowerCase())
            );

            if (!hasRequiredHeaders) {
              BulkEvidenceModal.closeModal();
              dispatch(
                alertActions.error(langConstant.ERROR_IN_FILE_STRUCTURE)
              );
              hasInvalidStructure = true;
              return;
            }
          } else {
            const [
              Evidence_Shortname,
              Evidence_Description,
              Regulatory_Domain,
            ] = line.split(",");

            parsedContent.push({
              Evidence_Shortname: Evidence_Shortname?.trim(),
              Evidence_Description: Evidence_Description?.trim(),
              Regulatory_Domain: Regulatory_Domain?.trim(),
            });
          }
        });
        setFileContent(parsedContent);
      };
      reader.readAsText(file);
    }
    const lastDotIndex = file.name.lastIndexOf(".");

    const bulkEvidenceData = {
      userID: userID,
      fileName:
        lastDotIndex === -1 ? file.name : file.name.substring(0, lastDotIndex),
      fileContent: parsedContent,
    };
    BulkEvidenceModal.setData(bulkEvidenceData);

    BulkEvidenceModal.openModal();
  };

  const openFileExplorer = () => {
    document.getElementById("fileInput").click();
  };

  const deleteEvidence = (rowData) => {
    if (rowData.ACTIVE === false || rowData.ACTIVE === 0) {
      return;
    }

    const DataObj = {
      i_EVIDENCE_TYPE_ID: rowData.EVIDENCE_TYPE_ID,
      i_USER_ID: rowData.USER_ID,
      i_EVIDENCE_SHORTNAME: rowData.EVIDENCE_SHORTNAME,
      i_EVIDENCE_DESCRIPTION: rowData.EVIDENCE_DESCRIPTION,
      i_EVIDENCE_KEYWORDS: rowData.EVIDENCE_KEYWORDS,
      i_REGULATORY_DOMAIN_ID: rowData.REGULATORY_DOMAIN_ID,
      i_OWNER: rowData.OWNER,
      i_EVIDENCE_SOURCE: rowData.EVIDENCE_SOURCE,
      i_DESCRIPTION: rowData.DESCRIPTION,
      i_ACTIVE: 0,
      i_START_DATE: rowData.START_DATE,
      i_END_DATE: rowData.END_DATE,
      i_EVIDENCE_TEMPLATE: rowData.EVIDENCE_TEMPLATE,
      i_EVIDENCE_CONTACT: rowData.EVIDENCE_CONTACT,
      i_EVIDENCE_URL_TO_SOURCE: rowData.EVIDENCE_URL_TO_SOURCE,
      i_CREATE_DATE: rowData.CREATE_DATE,
      i_DISPLAY: rowData.DISPLAY,
      i_CUSTOMER_ID: customerID,
    };

    PutEvidenceType(DataObj).then((data) => {
      if (data.error) {
        dispatch(alertActions.error(data.error));
      } else {
        dispatch(alertActions.success("Evidence Deleted"));
        const updatedEvidenceList = [...evidenceList];
        const index = updatedEvidenceList.findIndex(
          (x) => x.EVIDENCE_TYPE_ID === rowData.EVIDENCE_TYPE_ID
        );
        updatedEvidenceList[index].ACTIVE = false;
        setevidenceList(updatedEvidenceList);
      }
    });
  };

  return (
    <Box>
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={5}>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                id="searchText"
                name="searchText"
                placeholder="Filter List"
                onChange={(e) => {
                  onFilterChange("searchText", e);
                }}
                InputProps={{
                  autoComplete: "off",
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>{createFilterDropdown("DomainName", "Domain")}</Grid>
            <Grid item>
              <FilterDropdown
                options={activeOptions}
                label={langConstant.STATUS}
                fieldName={"ACTIVE"}
                value={filterData["ACTIVE"]}
                onChange={onFilterChange}
                icon={true}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <input
                type="file"
                accept=".csv"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {manageRegulation_RegulationAdmin && (
                <Button
                  variant="contained"
                  color="default"
                  size="medium"
                  startIcon={<OutboxIcon />}
                  className={rootClasses.greenButton}
                  onClick={openFileExplorer}
                >
                  Bulk Upload Evidence List
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="default"
                size="medium"
                startIcon={<FileUploadIcon />}
                className={rootClasses.greenButton}
                onClick={() => {
                  uploadFileModal.openModal();
                  uploadFileModal.setData(evidenceList);
                }}
                disabled={!evidenceList?.length}
              >
                Upload Sample File
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => {
                  setAddEditModal({
                    ...AddEditModal,
                    visible: true,
                    mode: "add",
                  });
                }}
                startIcon={<AddIcon />}
              >
                Add Evidence
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TableContainer component={Paper} className={classes.marginTop}>
        <Table className="data-table">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={
                    index === 1
                      ? classNames(classes.cursorPointer)
                      : classes.stickyColl
                  }
                  style={{
                    minWidth: item.minWidth,
                    textAlign: index === 4 ? "center" : "",
                  }}
                  onClick={() => {
                    if (index === 1) {
                      setFilterData({ ...filterData, orderByName: "" });
                    }
                  }}
                >
                  {item.headerName}

                  {index === 1 && (
                    <TableSortLabel
                      active={filterData.orderByName !== "" ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        sortByName();
                      }}
                      direction={
                        filterData.orderByName
                          ? filterData.orderByName === "ASC"
                            ? "desc"
                            : "asc"
                          : "asc"
                      }
                    ></TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEvidenceList &&
              filteredEvidenceList.map((row, rowIndex) => (
                <TableRow key={"Row_" + rowIndex} component="tr">
                  {columns.map((col, index) => (
                    <TableCell
                      component="td"
                      key={"column_" + index + row[col.field]}
                      style={{ textAlign: index === 4 ? "center" : "" }}
                    >
                      {index === 4 ? (
                        <span
                          className={classNames(
                            classes.acinStatus,
                            row[col.field] == 1
                              ? classes.activeStatus
                              : classes.inactiveStatus
                          )}
                        >
                          {row[col.field] == 1 ? "ACTIVE" : "INACTIVE"}
                        </span>
                      ) : // <span style={{ color: row[col.field] === true ? "green" : "red" }}> {row[col.field] === true ? "ACTIVE" : "INACTIVE"} </span>
                      index === 5 ? (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="View" placement="top">
                            <Box
                              className={classes.actionWrap}
                              style={{
                                backgroundColor:
                                  themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                viewDataToggle(row);
                              }}
                            >
                              <VisibilityIcon
                                style={{
                                  color: "#fff",
                                  fontSize: "18px",
                                  margin: "0 auto",
                                }}
                              />
                            </Box>
                          </Tooltip>
                          {/* <Tooltip title="Delete" placement="top">
                            <Box
                              className={classes.actionWrap}
                              style={{
                                backgroundColor: "#F0142F",
                                marginRight: "5px",
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                deleteEvidence(row);
                              }}
                            >
                              <DeleteOutlineIcon
                                style={{
                                  color: "#fff",
                                  fontSize: "18px",
                                  margin: "0 auto",
                                }}
                              />
                            </Box>
                          </Tooltip> */}
                        </Box>
                      ) : col?.format ? (
                        col?.format(row?.[col?.field])
                      ) : (
                        row[col.field]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

            {filteredEvidenceList.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      )}

      <AddEditForm
        evidenceList={evidenceList}
        setevidenceList={setevidenceList}
        AddEditModal={AddEditModal}
        setAddEditModal={setAddEditModal}
        domainOptions={domainOptions}
        classificationOptions={classificationOptions}
        userID={userID}
      />
      {fileContent && BulkEvidenceModal?.isOpen && (
        <BulkEvidenceUpload BulkEvidenceModal={BulkEvidenceModal} />
      )}
      {uploadFileModal?.isOpen && (
        <UploadFile
          uploadFileModal={uploadFileModal}
          extractedKeywordModal={extractedKeywordModal}
        />
      )}
      {extractedKeywordModal?.isOpen && (
        <ExtractedKeywords
          getEvidenceTypeListByAPI={getEvidenceTypeListByAPI}
          extractedKeywordModal={extractedKeywordModal}
        />
      )}
    </Box>
  );
};

export default EvidenceTypes;
