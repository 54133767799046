import { Box, Dialog, DialogContent, Grid, Tab } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import SearchByTextDataTable from "./searchByTextDataTable";
import langConstant from "_lang";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";


const PolicySearchComponent = ({show, setShow, advanceSearchResult, clearFilterSearchText}) => {

    const [currentTab, setCurrentTab] = useState('tag')

    const classes = useStyles();

    const handleClose = () => {
        setShow(false)
        clearFilterSearchText()
    }

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const tagColumns = [
        {field: "POLICY_ID", headerName: "ID", minWidth: 50},
        {field: "POLICY_NAME", headerName: langConstant.POLICY_NAME, minWidth: 50},
        {field: "DOCUMENT_TAGS", headerName: langConstant.DOCUMENT_TAGS, minWidth: 50},
    ]

    const textColumns = [
        {field: "POLICY_ID", headerName: "ID", minWidth: 50},
        {field: "POLICY_NAME", headerName: langConstant.POLICY_NAME, minWidth: 50},
        {field: "PAGE_NUMBER", headerName: langConstant.PAGE_NUMBER, minWidth: 50},
        {
            field: "score", 
            headerName: langConstant.SCORE, 
            minWidth: 50,
            format: (value) => parseInt(value)
        },
        {field: "ITEM_BODY_CONTENT", headerName: langConstant.TEXT, minWidth: 50},
    ]

    return (
        <Dialog
            open={show}
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogContent>
                <TabContext value={currentTab}>
                    <Grid container>
                        <Grid item md={10} xs={10}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab 
                                        label={<span>{langConstant.BY_TAG} <span className={classes.countWrapper}>{advanceSearchResult['documentTag'] && advanceSearchResult['documentTag'].length}</span></span>} 
                                        value="tag" style={{fontSize: "16px", fontWeight: "bold"}} 
                                    />
                                    <Tab 
                                        label={<span>{langConstant.BY_TEXT} <span className={classes.countWrapper}>{advanceSearchResult['documentTag'] && advanceSearchResult['documentText'].length}</span></span>} 
                                        value="text" style={{fontSize: "16px", fontWeight: "bold"}} 
                                    />
                                </TabList>
                            </Box>
                        </Grid>
                        <Grid item md={2} xs={2} style={{textAlign: "right", alignItems: "center"}}>
                            <CloseIcon onClick={handleClose} style={{cursor: "pointer"}}/>
                        </Grid>
                    </Grid>
                    <TabPanel value="tag" style={{padding: "0"}}>
                        {advanceSearchResult['documentTag'] && 
                            <SearchByTextDataTable data={advanceSearchResult['documentTag']} columns={tagColumns} type="tag"/>
                        }
                    </TabPanel>
                    <TabPanel value="text" style={{padding: "0"}}>
                        {advanceSearchResult['documentText'] && 
                            <SearchByTextDataTable data={advanceSearchResult['documentText']} columns={textColumns} type="text"/>
                        }
                    </TabPanel>
                </TabContext>
            </DialogContent>
        </Dialog>
    )
}

PolicySearchComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    advanceSearchResult: PropTypes.object,
    clearFilterSearchText: PropTypes.func,
};

export default PolicySearchComponent;