/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Grid, Box, Typography } from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import langConstant from "_lang";
import { alertActions, downloadedFileActions } from "_actions";
import { useDispatch, useSelector } from "react-redux";
import sessionService from "_services/session.service";

const RegulationDetail = ({ data = {} }) => {
  const dispatch = useDispatch();

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const handleLinkClick = async () => {
    const authToken = sessionService.getItem("jwtToken");

    try {
      const response = await fetch(
        `${window["REACT_APP_API_URL"]}/pdfprocessor/api/v1/downloadFile?fileLocation=${data.FILE_LOCATION}&fileName=${data.FILE_NAME}&bucketName=${window.BUCKET_NAME}&storageType=${docStorageType}&preProcessingIP=${docStorageIP}&preProcessingURL=${docStorageURL}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        const blob = await response.blob();

        // Create a temporary URL to the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and simulate a click to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = data.FILE_NAME; // Set the desired filename
        document.body.appendChild(link);
        link.click();

        // Cleanup: remove the link and revoke the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Box mt={2}>
      <Grid spacing={2} container>
        <Grid item md={4}>
          <Typography variant="body2" className={"lightHeading"}>
            {langConstant.REGULATION_NAME}
          </Typography>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {data.REGULATION_NAME}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" className={"lightHeading"}>
            {langConstant.REGULATOR}
          </Typography>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {" "}
            {data.REGULATOR_NAME}
          </Typography>
        </Grid>
        {data.FILE_LOCATION && data.FILE_NAME && (
          <Grid item md={4}>
            <Link
              className="linkIcon"
              mt={2}
              // to={{ pathname: downloadPDF() }}
              // target="_blank"
              onClick={handleLinkClick}
            >
              <FolderOpenIcon style={{ marginRight: "5px" }} />{" "}
              {langConstant.VIEW_ORIGINAL_DOC}
            </Link>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
RegulationDetail.propTypes = {
  data: PropTypes.object,
};
export default RegulationDetail;
