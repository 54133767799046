import {
  Box,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TileCard } from "_components/MasterAssessmentComp/component/DashboardPanel/components/TileCard";
import {
  Assignment,
  AssignmentReturn,
  FactCheck,
  Info,
  PendingActions,
} from "@mui/icons-material";
import SegmentIcon from "@mui/icons-material/Segment";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import sessionService from "_services/session.service";
import { GradientSVG } from "_components/MasterAssessmentComp/component/DashboardPanel/CirculerSpeedDialProgress/GradientSVG";
import RadialSeparators from "_components/MasterAssessmentComp/component/DashboardPanel/CirculerSpeedDialProgress/RadialSeprator";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
  Legend,
  Label,
} from "recharts";
import useStyles from "./styles";
import { QUESTION_STATUS_TYPES } from "_components/MasterAssessmentComp/utils/constants";
import langConstant from "_lang";
import AssessmentApproverGraph from "./components/AssessmentApproverGraph";
import LabelIcon from "_components/LabelIcon";
import InformationStatusGraph from "./components/InformationStatusGraph";

const InfoTooltip = ({ message }) => (
  <Tooltip placement="left" sx={{ zIndex: 4 }} title={message}>
    <IconButton
      size="small"
      sx={{ top: -2, right: -2, position: "absolute", zIndex: 4 }}
    >
      <Info fontSize="small" />
    </IconButton>
  </Tooltip>
);

const GraphView = ({
  evidenceStatus,
  assessments,
  rfiStatus,
  expandedPanelID,
  block,
}) => {
  const userID = sessionService.getUserId();
  const classes = useStyles();
  const [assessmentKpiData, setAssessmentKpiData] = useState({});
  const [kpiData, setKpiData] = useState({});

  const getPercentage = (data) => {
    if (!data?.length) return 0;
    const total = data?.length || 0;
    const completedQuestions = data?.filter(
        (q) =>
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.COMPLETED ||
          q?.QUESTION_STATUS?.trim() ===
            QUESTION_STATUS_TYPES.COMPLETED_AND_LOCKED
      ),
      completionPercentage =
        (Number(completedQuestions?.length || 0) / total) * 100;
    return completionPercentage?.toFixed(2) || 0;
  };

  const getProgressPercentage = (data) => {
    if (!data?.length) return 0;
    const total = data?.length || 0;
    const completedQuestions = data?.filter(
        (q) =>
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.PENDING_DATA ||
          q?.QUESTION_STATUS?.trim() ===
            QUESTION_STATUS_TYPES.READY_FOR_REVIEW ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_SENT ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_PENDING ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_OVERDUE ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_RESOLVED ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.SENT_BACK
      ),
      progressPercentage =
        (Number(completedQuestions?.length || 0) / total) * 100;
    // return progressPercentage?.toFixed(2) || 0;
    return completedQuestions?.length || 0;
  };

  useEffect(() => {
    if (assessments && Object.keys(assessments).length > 0) {
      const cisoControlArray = [...assessments["#result-set-7"]];
      const questionData = [...assessments["#result-set-1"]].map((el) => {
        let ciscoControl = cisoControlArray.find(
          (control) =>
            `${el.QUESTION_ID?.trim()}_${el.REGULATORS_QUESTION_REF?.trim()}` ===
            `${control.QUESTION_ID?.trim()}_${control.REGULATORS_QUESTION_REF?.trim()}`
        );

        if (ciscoControl !== undefined && ciscoControl !== null) {
          return {
            ...el,
            CISO_CONTROL_NAME: ciscoControl.CISO_CONTROL_NAME,
          };
        } else {
          return { ...el, CISO_CONTROL_NAME: null };
        }
      });
      setKpiData({
        completedPercentage: getPercentage(questionData),
        progress: getProgressPercentage(questionData),
      });
      setAssessmentKpiData({
        ...assessments["#result-set-5"],
        SENT_BACK:
          questionData?.filter(
            (i) => i?.QUESTION_STATUS === QUESTION_STATUS_TYPES.SENT_BACK
          )?.length || 0,
      });
    }
  }, [assessments]);

  const data = [
    { name: "Requested", value: 15 },
    { name: "Received", value: 20 },
    { name: "Approved", value: 45 },
  ];

  const colors = ["#ffc000", "#ed7d31", "#00b050"];

  return (
    <Card
      id="elementToCapture"
      sx={{
        my: 1,
        backgroundColor: expandedPanelID == block.id ? "#F0F4F8" : "",
        border: "none",
        overflow: "auto",
        scrollbarWidth: "none",
        height: "100%",
      }}
      variant="outlined"
    >
      {expandedPanelID == block.id ? (
        <Grid container>
          <Grid item md={9} xs={7} container spacing={2} p={1}>
            <Grid item md={2} xs={6}>
              <TileCard
                label="Total Controls"
                count={assessmentKpiData?.ALL_QUESTION}
                icon={<Assignment sx={{ fontSize: 44 }} />}
                color="blue"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TileCard
                label="Controls In Progress"
                count={kpiData?.progress}
                icon={<SegmentIcon sx={{ fontSize: 44 }} />}
                color="yellowgreen"
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <TileCard
                label="Sent Back"
                count={assessmentKpiData?.SENT_BACK}
                icon={<AssignmentReturn sx={{ fontSize: 44 }} />}
                color="orange"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TileCard
                label="Audit Observations"
                count={assessmentKpiData?.AUDIT_OBSERVATIONS}
                icon={<PendingActions sx={{ fontSize: 44 }} />}
                color="cadetblue"
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <TileCard
                label="Completed"
                count={assessmentKpiData?.COMPLETED}
                icon={<FactCheck sx={{ fontSize: 44 }} />}
                color="green"
              />
            </Grid>
          </Grid>
          <Grid item md={3} xs={5} container spacing={2} p={1}>
            <Grid md xs item display="flex" p={0} pr="0px !important">
              <Box
                display="flex"
                flex={1}
                gap={1}
                p={1}
                alignItems="center"
                justifyContent="center"
                overflow="hidden"
                bgcolor="#fff"
                position="relative"
              >
                <InfoTooltip message="Shows count of all controls which are Completed, Completed and Locked, Final" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                    flex: 1,
                    position: "relative",
                    // mt:4,
                    height: "6rem",
                  }}
                >
                  <CircularProgressbarWithChildren
                    circleRatio={0.6}
                    value={kpiData?.completedPercentage}
                    text={`${kpiData?.completedPercentage}%`}
                    strokeWidth={10}
                    styles={buildStyles({
                      rotation: 0.7,
                      strokeLinecap: "butt",
                      pathColor: `url(#id-${userID})`,
                      textColor: "#000",
                      textSize: "1rem",
                    })}
                  >
                    <GradientSVG id={`id-${userID}`} />
                    <RadialSeparators
                      count={13}
                      style={{
                        background: "#fff",
                        width: "2px",
                        // This needs to be equal to props.strokeWidth
                        height: `${10}%`,
                      }}
                    />
                  </CircularProgressbarWithChildren>
                </Box>
                <Typography
                  sx={{
                    zIndex: 2,
                    fontSize: "1rem",
                    textAlign: "center",
                    flex: 1,
                  }}
                >
                  Completed Controls
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} container>
            <Grid item md={3} xs={12} p={1}>
              <Grid
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                }}
              >
                <Typography className={classes.chartLabel}>
                  {langConstant.ASSESSMENT_EVIDENCE_STATUS}
                </Typography>
                <ResponsiveContainer width="99%" height="99%" aspect={1}>
                  <PieChart width={400} height={400}>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                      ))}
                    </Pie>
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
            <Grid item md={5} xs={12} p={1}>
              <InformationStatusGraph block={block} rfiStatus={rfiStatus} />
            </Grid>
            <Grid item md={4} xs={12} p={1}>
              <AssessmentApproverGraph block={block} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          <Grid container style={{ minWidth: 320 }}>
            <Grid item md={2}>
              <LabelIcon
                label="Total Controls"
                count={assessmentKpiData?.ALL_QUESTION}
                icon={<Assignment sx={{ fontSize: 24 }} />}
                color="blue"
                labelClass={classes.labelClass}
                countlabelClass={classes.countClass}
              />
            </Grid>
            <Grid item md={3}>
              <LabelIcon
                label="Controls In Progress"
                count={kpiData?.progress}
                icon={<SegmentIcon sx={{ fontSize: 24 }} />}
                color="yellowgreen"
                labelClass={classes.labelClass}
                countlabelClass={classes.countClass}
              />
            </Grid>
            <Grid item md={2}>
              <LabelIcon
                label="Sent Back"
                count={assessmentKpiData?.SENT_BACK}
                icon={<AssignmentReturn sx={{ fontSize: 24 }} />}
                color="orange"
                labelClass={classes.labelClass}
                countlabelClass={classes.countClass}
              />
            </Grid>
            <Grid item md={3}>
              <LabelIcon
                label="Audit Observations"
                count={assessmentKpiData?.AUDIT_OBSERVATIONS}
                icon={<PendingActions sx={{ fontSize: 24 }} />}
                color="cadetblue"
                labelClass={classes.labelClass}
                countlabelClass={classes.countClass}
              />
            </Grid>
            <Grid item md={2}>
              <LabelIcon
                label="Completed"
                count={assessmentKpiData?.COMPLETED}
                icon={<FactCheck sx={{ fontSize: 24 }} />}
                color="green"
                labelClass={classes.labelClass}
                countlabelClass={classes.countClass}
              />
            </Grid>
          </Grid>
          <AssessmentApproverGraph block={block} />
        </Grid>
      )}
    </Card>
  );
};

GraphView.propTypes = {
  evidenceStatus: PropTypes.object,
  assessments: PropTypes.object,
  expandedPanelID: PropTypes.string,
  block: PropTypes.object,
  rfiStatus: PropTypes.object,
};

InfoTooltip.propTypes = {
  message: PropTypes.string.isRequired,
};

export default GraphView;
