import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Paper,
  IconButton,
  Button,
  TextField,
  makeStyles,
  Grid,
  Checkbox,
} from "@material-ui/core";
import { ErrorMessage, Formik, Form, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import RuleBookService from "../components/services";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import _ from "lodash";
import sessionService from "_services/session.service";
import ProgressBarLarge from "_components/ProgressBarLarge";
import langConstant from "_lang";
import { alertActions } from "_actions";

const useStyles = makeStyles(() => ({
  title: { display: "flex", alignItems: "center", padding: ".75rem" },
  error: { color: "red", fontSize: "12px" },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
  },
  wideColumn: {
    width: "30%",
  },
  regColumn: {
    width: "14%",
  },
  normalColumn: {
    width: "9%",
  },
  smallColumn: {
    width: "2%",
  },
  stickyColl: {
    position: "sticky",
    right: 0,
  },
}));

const BulkQuestionUpload = ({ modal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileContent = modal?.data.fileContent;
  const fileName = modal.data.fileName;
  const customerID = modal.data.customerID;
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [percentage, setPercentage] = useState(0);
  const [progressView, setProgressView] = useState(false);
  const columns = [
    { field: "Select", headerName: "Select", minWidth: 2 },

    { field: "RegulationID", headerName: "Regulation ID", minWidth: 30 },
    { field: "RegulatorID", headerName: "Regulator ID", minWidth: 30 },
    {
      field: "RegulatoryDomainID",
      headerName: "Regulatory DomainID",
      minWidth: 30,
    },
    { field: "RegulationName", headerName: "RegulationName", minWidth: 180 },
    { field: "SeqenceID", headerName: "Seqence ID", minWidth: 30 },
    {
      field: "RegulatorUniqueRef",
      headerName: "Regulator UniqueRef",
      minWidth: 80,
    },
    {
      field: "Regulator ControlGroup",
      headerName: "Regulator ControlGroup",
      minWidth: 150,
    },
    { field: "QuestionTEXT", headerName: "Question TEXT", minWidth: 500 },
    { field: "QuestionID", headerName: "Question ID", minWidth: 250 },
    { field: "QuestionType", headerName: "Question Type", minWidth: 150 },
    { field: "QuestionVersion", headerName: "Question Version", minWidth: 150 },
    {
      field: "NarrativeTemplate",
      headerName: "Narrative Template",
      minWidth: 150,
    },
    {
      field: "ModelStrategyID",
      headerName: "Model StrategyID",
      minWidth: 50,
    },
    { field: "Active", headerName: "Active", minWidth: 50 },
    { field: "IngestFlag", headerName: "IngestFlag", minWidth: 50 },
    {
      field: "QuesClassification",
      headerName: "Question Classification",
      minWidth: 100,
    },
    { field: "CiscoControlID", headerName: "Cisco ControlID", minWidth: 50 },
    {
      field: "QuestionTextHTML",
      headerName: "Question Text HTML",
      minWidth: 200,
    },
    { field: "CreateDate", headerName: "Create Date", minWidth: 150 },
    { field: "StartDate", headerName: "Start Date", minWidth: 150 },
    { field: "EndDate", headerName: "End Date", minWidth: 150 },
  ];

  const calculatePageCount = (rows) => {
    return rows?.length > 0
      ? Math.round(Math.ceil(rows?.length / rowsPerPage))
      : 0;
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      fileContent.length > 0
        ? Math.round(Math.ceil(fileContent.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createValidationSchema = () => {
    return Yup.object().shape({
      rows: Yup.array().of(
        Yup.object().shape({
          RegulationID: Yup.string().when("QUESTION_SELECTED", {
            is: true,
            then: Yup.string().required("RegulationID is required"),
          }),
          RegulatorID: Yup.string().when("QUESTION_SELECTED", {
            is: true,
            then: Yup.string().required("RegulatorID is required"),
          }),
          RegulatoryDomainID: Yup.string().when("QUESTION_SELECTED", {
            is: true,
            then: Yup.string().required("Regulatory DomainID is required"),
          }),
          Regulation: Yup.string().when("QUESTION_SELECTED", {
            is: true,
            then: Yup.string().required("Regulation Name is required"),
          }),
          Question_Version: Yup.string().when("QUESTION_SELECTED", {
            is: true,
            then: Yup.string().required("Question Version is required"),
          }),
          Regulator_Control_Group: Yup.string().when("QUESTION_SELECTED", {
            is: true,
            then: Yup.string().required("Regulator Control Group is required"),
          }),
          QuestionTEXT: Yup.string().when("QUESTION_SELECTED", {
            is: true,
            then: Yup.string().required("Question TEXT is required"),
          }),
          QuestionID: Yup.string().when("QUESTION_SELECTED", {
            is: true,
            then: Yup.string().required("Question ID is required"),
          }),
        })
      ),
    });
  };

  const onSubmit = async (values) => {
    console.log("Check values===================", values);
    let count = 0;

    for (let rowData of values.rows) {
      if (rowData.QUESTION_SELECTED) {
        const DataObj = {
          i_REGULATION_ID: rowData.RegulationID,
          i_REGULATOR_ID: parseInt(rowData.RegulatorID),
          i_QUESTION_ID: rowData.QuestionID,
          i_CUSTOMER_ID: customerID,
          i_REGULATORY_DOMAIN_ID: rowData.RegulatoryDomainID,
          i_QUESTION_VERSION: rowData.Question_Version,
          i_REGULATOR_CONTROL_GROUP: rowData.Regulator_Control_Group,
          i_QUESTION_TEXT: rowData.QuestionTEXT,
          i_QUESTION_CLASSIFICATION_ID: rowData.QuestionClassification,
          i_MODEL_STRATEGY_ID: rowData.ModelStrategyID,
          i_CISO_CONTROL_ID: rowData.CiscoControlID,
          i_QUESTION_TEXT_HTML: rowData.QuestionTEXT,
          i_REGULATOR_UNIQUE_REFERENCE: rowData.RegulatorUniqueRef,
        };
        console.log("Check DataObj===================", DataObj);

        const response = await RuleBookService.putQuestionInRuleBook(DataObj);
        console.log("Check response===================", response);

        if (response && response["status"] == 200) {
          count = count + 1;
          const tempPercentage = (count / selectedQuestions.length) * 100;
          setPercentage((prev) => tempPercentage.toFixed(2));
          setProgressView(true);
        } else {
          if (response.data && response.data.message) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        }
      }
    }
    dispatch(alertActions.success(`${count} Records Updated`));
    modal?.closeModal();
  };

  useEffect(() => {
    setPageCount(calculatePageCount(modal.data.fileContent));
  }, [modal?.data]);

  return (
    <Dialog
      open={modal?.isOpen}
      maxWidth="xl"
      fullWidth
      keepMounted
      onClose={modal?.closeModal}
    >
      <DialogTitle className={classes.title}>
        <IconButton
          style={{ position: "absolute", right: 4 }}
          aria-label="close"
          onClick={() => {
            modal?.closeModal();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ rows: fileContent }}
          validationSchema={createValidationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ values, errors, setFieldValue, handleChange }) => (
            <Form>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" mt={2}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      const updatedSelectedEvidences = values.rows.map(
                        (_, index) => `${index}`
                      );
                      setSelectedQuestions(updatedSelectedEvidences);

                      setFieldValue(
                        "rows",
                        values.rows.map((row) => ({
                          ...row,
                          QUESTION_SELECTED: true,
                        }))
                      );
                    }}
                    style={{ marginRight: 10 }}
                  >
                    Select All
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Upload Selected Records
                  </Button>
                </Box>

                <Typography
                  variant="h6"
                  style={{ textAlign: "center", flexGrow: 1 }}
                >
                  {fileName}
                </Typography>

                <Box my={2} display="flex" justifyContent="flex-end">
                  <Grid container justify="flex-end">
                    {fileContent?.length > 0 && (
                      <SelectPageRange
                        selectedRange={rowsPerPage}
                        handleChange={handleSelectPageRange}
                      />
                    )}
                    {pageCount > 0 && (
                      <AppPagination
                        colSpan={3}
                        count={pageCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    )}
                  </Grid>
                </Box>
              </Box>

              {progressView && (
                <ProgressBarLarge percentage={parseInt(percentage)} />
              )}

              <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table
                  sx={{ minWidth: 800, width: "100%", borderSpacing: "0px" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((item, index) => (
                        <TableCell
                          component="th"
                          scope="row"
                          key={"column_" + index + item.field}
                          className={item.sticky && classes.stickyColl}
                          style={{ minWidth: item.minWidth }}
                        >
                          {item.headerName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="rows">
                      {({ insert, remove, push }) => (
                        <>
                          {values.rows.length > 0 &&
                            (rowsPerPage > 0
                              ? values.rows.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              : values.rows
                            ).map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "1px",
                                    marginLeft: "1px",
                                    marginRight: "1px",
                                  }}
                                >
                                  <Checkbox
                                    name={`rows.${index}.QUESTION_SELECTED`}
                                    checked={selectedQuestions.includes(
                                      `${index}`
                                    )}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `rows.${index}.QUESTION_SELECTED`,
                                        e.target.checked
                                      );

                                      if (e.target.checked) {
                                        setSelectedQuestions(
                                          (prevQuestions) => [
                                            ...prevQuestions,
                                            `${index}`,
                                          ]
                                        );
                                      } else {
                                        setSelectedQuestions((prevQuestions) =>
                                          prevQuestions.filter(
                                            (item) => item !== `${index}`
                                          )
                                        );
                                      }
                                    }}
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#124571",
                                      },
                                      display: "inline",
                                    }}
                                    size="small"
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.regColumn}
                                  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.RegulationID`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.RegulationID}
                                    onChange={(e) => {
                                      if (e.target.value.match(/^\d*$/)) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                    inputProps={{
                                      style: {
                                        whiteSpace: "normal",
                                        wordBreak: "break-all",
                                      },
                                    }}
                                    multiline
                                  />
                                  <ErrorMessage
                                    name={`rows.${index}.RegulationID`}
                                    component="div"
                                    className={classes.error}
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.regColumn}
                                  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.RegulatorID`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.RegulatorID}
                                    onChange={(e) => {
                                      if (e.target.value.match(/^\d*$/)) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                    inputProps={{
                                      style: {
                                        whiteSpace: "normal",
                                        wordBreak: "break-all",
                                      },
                                    }}
                                    multiline
                                  />
                                  <ErrorMessage
                                    name={`rows.${index}.RegulatorID`}
                                    component="div"
                                    className={classes.error}
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.regColumn}
                                  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.RegulatoryDomainID`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.RegulatoryDomainID}
                                    onChange={(e) => {
                                      if (e.target.value.match(/^\d*$/)) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                    inputProps={{
                                      style: {
                                        whiteSpace: "normal",
                                        wordBreak: "break-all",
                                      },
                                    }}
                                    multiline
                                  />
                                  <ErrorMessage
                                    name={`rows.${index}.RegulatoryDomainID`}
                                    component="div"
                                    className={classes.error}
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.textField}
                                  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.Regulation`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.Regulation}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                    inputProps={{
                                      style: {
                                        whiteSpace: "normal",
                                        wordBreak: "break-all",
                                      },
                                    }}
                                    multiline
                                  />
                                  <ErrorMessage
                                    name={`rows.${index}.Regulation`}
                                    component="div"
                                    className={classes.error}
                                  />
                                </TableCell>

                                <TableCell className={classes.smallColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.SequenceID`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.SequenceID}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.smallColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.RegulatorUniqueRef`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      row.RegulatorUniqueRef
                                        ? row.RegulatorUniqueRef
                                        : ""
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.regColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.Regulator_Control_Group`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      row.Regulator_Control_Group
                                        ? row.Regulator_Control_Group
                                        : ""
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                  <ErrorMessage
                                    name={`rows.${index}.Regulator_Control_Group`}
                                    component="div"
                                    className={classes.error}
                                  />
                                </TableCell>
                                <TableCell className={classes.wideColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.QuestionTEXT`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      row.QuestionTEXT ? row.QuestionTEXT : ""
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.regColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.QuestionID`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.QuestionID ? row.QuestionID : ""}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                  <ErrorMessage
                                    name={`rows.${index}.QuestionID`}
                                    component="div"
                                    className={classes.error}
                                  />

                                </TableCell>
                                <TableCell className={classes.regColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.QuestionType`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      row.QuestionType ? row.QuestionType : ""
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.smallColumn}
                                  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.Question_Version`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.Question_Version}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                    inputProps={{
                                      style: {
                                        whiteSpace: "normal",
                                        wordBreak: "break-all",
                                      },
                                    }}
                                    multiline
                                  />
                                  <ErrorMessage
                                    name={`rows.${index}.Question_Version`}
                                    component="div"
                                    className={classes.error}
                                  />
                                </TableCell>
                                <TableCell className={classes.smallColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.NarrativeTemplate`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.NarrativeTemplate}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.smallColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.ModelStrategyID`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.ModelStrategyID}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.smallColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.Active`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.Active}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.smallColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.IngestFlag`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.IngestFlag}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.smallColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.QuestionClassification`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.QuestionClassification}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.smallColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.CiscoControlID`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.CiscoControlID}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.wideColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.QuestionTextHTML`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      row.QuestionTextHTML
                                        ? row.QuestionTextHTML
                                        : ""
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>

                                <TableCell className={classes.regColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.CreateDate`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.CreateDate}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                  {/* <FormDateField
                                    value={row.CreateDate}
                                    setFieldValue={setFieldValue}
                                    fieldName={`rows.${index}.CreateDate`}
                                    errors={errors}
                                  /> */}
                                </TableCell>
                                <TableCell className={classes.regColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.StartDate`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.StartDate}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                                <TableCell className={classes.regColumn}>
                                  <TextField
                                    className={classes.textField}
                                    name={`rows.${index}.EndDate`}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={row.EndDate}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.match(
                                          /^[a-zA-Z0-9\s.'"]*$/
                                        )
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    onPaste={(e) => e.preventDefault()}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </>
                      )}
                    </FieldArray>
                  </TableBody>
                </Table>
              </TableContainer>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

BulkQuestionUpload.propTypes = {
  modal: PropTypes.object.isRequired,
};

export default BulkQuestionUpload;
