import React from "react";
import MasterTableauReport from "_components/MasterTableauReport"
import sessionService from "_services/session.service";

const BreachReporting = () => {

  const customerID = sessionService.getCustomerId()
  const userID = sessionService.getUserId()

  const parameters = {
    CustomerId: customerID,
    UserId: userID,
  }

  return <MasterTableauReport slug="INSIGHTS/BREACH_REPORTING" parameters={parameters}/>
};

export default BreachReporting;