/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  TextField,
  Box,
  InputAdornment,
  Button,
  Tooltip,
  IconButton,
  Paper,
} from "@material-ui/core";
import AppPagination from "../../../../_components/AppPagination";
import { Add, Search, Visibility } from "@mui/icons-material";
import FilterDropdown from "_components/FilterDropdown";
import _ from "lodash";
import SelectPageRange from "_components/SelectPageRange";
import { scrollToTop } from "_helpers/utils";
import { useHistory } from "react-router-dom";
import { useModal } from "_hooks";
import { AddActionListDialog } from "./AddActionListDialog";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { useSelector } from "react-redux";
import { ACTION_STATUS_LIST } from "../constants";
import langConstant from "_lang";
import { ActionIcon } from "_components/ActionIcon";
import { ActionMenu } from "_components/ActionMenu";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
}));

const badgeStyle = {
  [ACTION_STATUS_LIST[0].value]: {
    backgroundColor: "#7bc0ff",
    border: "#7bc0ff",
  },
  [ACTION_STATUS_LIST[1].value]: {
    backgroundColor: "#ffae6b",
    border: "#ffae6b",
  },
  [ACTION_STATUS_LIST[2].value]: {
    backgroundColor: "#aff3bb",
    border: "#aff3bb",
  },
};

const DataTable = (props) => {
  const classes = useStyles();
  const rows = props.rows;
  const columns = props.columns;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(rows));
  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filteredRows, setFilteredRows] = useState(rows);
  const initialFilterObj = { LIST_STATUS: "" };
  const [filterBy, setFilterBy] = useState(initialFilterObj);
  const [searchText, setSearchText] = useState("");
  const addActionListModal = useModal();

  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);

  const onActionClick = (event, data) => {
    event?.preventDefault();
    setSelectedRow(data);
    setAnchorMenuEl(openMenu ? null : event.currentTarget);
  };

  const onFilterChange = (fieldName, e) => {
    setSearchText("");
    let filterObj = { ...filterBy, [fieldName]: e.target.value };
    let filteredList = rows?.filter((item) => {
      return filterObj?.[fieldName] === item?.[fieldName];
    });
    // if (filterObj?.LIST_STATUS) {
    //   filteredList = rows?.filter((item) => {
    //     return filterObj?.[fieldName] === item?.[fieldName];
    //   });
    // }
    setFilterBy(filterObj);
    setFilteredRows(getUpdatedRows(filteredList));
    setPageCount(calculatePageCount(filteredList));
    setPage(0);
  };

  const resetFilter = () => {
    setFilterBy(initialFilterObj);
    setSearchText("");
    setFilteredRows(getUpdatedRows(rows));
    setPageCount(calculatePageCount(rows));
    setPage(0);
  };

  const onSearch = (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);
    const searchResult = rows?.filter((item) =>
      item?.ACTIONS_LIST_NAME?.toUpperCase()?.includes(
        searchValue?.toUpperCase()
      )
    );
    setFilteredRows(getUpdatedRows(searchResult));
    setPageCount(calculatePageCount(searchResult));
    setPage(0);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredRows.length > 0
        ? Math.round(Math.ceil(filteredRows.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const getPriorityFullLabel = (priority) => {
    switch (priority) {
      case "H":
        return "High";
      case "M":
        return "Medium";
      case "L":
        return "Low";
      default:
        return priority ?? "-";
    }
  };

  const getStatusBadge = (status) => {
    let listStatus = ACTION_STATUS_LIST.find((i) => i?.value == status);
    if (!listStatus) return "";
    return (
      <span
        className="status-badge"
        style={{ color: "#fff", ...badgeStyle[listStatus.value] }}
      >
        {listStatus?.label}
      </span>
    );
  };

  const createFilterDropdown = (fieldName, label) => {
    let options =
      _.reject(_.uniq(_.map(rows, fieldName)).sort(), _.isNull)?.map((item) => {
        return {
          value: item,
          name:
            ACTION_STATUS_LIST?.find((i) => i?.value == item)?.label || item,
        };
      }) ?? [];

    if (options?.length > 0 && options?.[0]) {
      return (
        <FilterDropdown
          options={options}
          label={label}
          fieldName={fieldName}
          value={filterBy[fieldName]}
          onChange={onFilterChange}
          icon={true}
        />
      );
    }
  };

  const getUpdatedRows = (list) => {
    return list?.map((item) => {
      let updated = { ...item };
      let actionIcon = <ActionIcon onClick={(e) => onActionClick(e, item)} />,
        actionPriority = getPriorityFullLabel(
          updated?.["ACTIONS_LIST_PRIORITY"]
        ),
        actionStatus = getStatusBadge(updated?.["LIST_STATUS"]);
      updated["action"] = actionIcon;
      updated["ACTIONS_LIST_PRIORITY"] = actionPriority;
      updated["LIST_STATUS"] = actionStatus;
      return updated;
    });
  };

  useEffect(() => {
    setFilteredRows(getUpdatedRows(rows));
    setPageCount(calculatePageCount(rows));
  }, [rows]);

  return (
    <>
      {openMenu && (
        <ActionMenu
          anchorMenuEl={anchorMenuEl}
          onClose={onActionClick}
          isOpen={openMenu}
          menuItems={[
            {
              linkTo: `/audit-actions/${selectedRow?.ACTIONS_LIST_ID}`,
              icon: Visibility,
              tooltipLabel: langConstant.VIEW,
            },
          ]}
        />
      )}
      {addActionListModal?.isOpen && (
        <AddActionListDialog addActionListModal={addActionListModal} />
      )}
      <Box mb={2} mt={3}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="searchText"
                  label=""
                  name="searchText"
                  placeholder="Filter Table"
                  value={searchText}
                  onChange={onSearch}
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                {createFilterDropdown("LIST_STATUS", "Status")}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mx={1}>
              {(searchText || filterBy["LIST_STATUS"]) && (
                <span onClick={resetFilter} className="linkIcon">
                  Reset All
                </span>
              )}
            </Box>
          </Grid>
          <Grid item style={{ textAlign: "end" }} xs={12} md={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<Add />}
              onClick={addActionListModal?.openModal}
              // component={Link}
            >
              Add New Action List
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table className={props.className} aria-label="Action List">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={item.sticky && classes.stickyColl}
                  style={{ minWidth: item.minWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, index) => (
              <TableRow key={index + row[columns[0].field]}>
                {columns.map((column, index) => {
                  //const Comp = column.component && column.component
                  const value = row[column.field];
                  return (
                    <TableCell
                      component="td"
                      scope="row"
                      key={`column_${index + row?.[columns?.[0]?.field]}`}
                      className={column?.sticky && classes.stickyColl}
                    >
                      {column?.format ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {filteredRows?.length === 0 && (
              <TableRow style={{ height: 60 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns?.length}
                >
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid container justify="flex-end">
          {filteredRows?.length > 5 && (
            <SelectPageRange
              selectedRange={rowsPerPage}
              handleChange={handleSelectPageRange}
            />
          )}
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

DataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default DataTable;
