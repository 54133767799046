/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

// OP#3118 - UJL4 - Password Reset
// Reset Password Screen

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import VpnKey from "@material-ui/icons/VpnKey";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "./styles";
import PasswordServices from "./services";
import sessionService from "_services/session.service";
import { alertActions } from "_actions";
import PasswordSuccess from "./PasswordSuccess";
import langConstant from "_lang";
import UserActions from "../LoginPage/actions";
import { logoData } from "_assets/logoFile";

const ResetPassword = () => {
  const validationSchema = yup.object({
    password: yup.string().trim().required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contains 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirm_password: yup.string().trim().required("Confirm Password is required").oneOf([yup.ref('password'), null], 'Passwords must match')
  });
  const classes = styles();

  const dispatch = useDispatch();

  const [successView, setSuccessView] = useState(false)

  const userId = sessionService.getUserId()

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        userId,
        password: values.password
      }
      const response = await PasswordServices.updateUserPassword(data)
      if(response && response.status && response.status === 200) {
        setSuccessView(true)
        dispatch(UserActions.logout());
      } else {
        if (response.data && response.data.message) {
            dispatch(
                alertActions.error(
                    response.data.message.map((item, index) => {
                        return <div key={index}>{item}</div>;
                    })
                )
            );
        } else {
            dispatch(
                alertActions.error(langConstant.SOMETHING_WENT_WRONG))
        }
    }
    },
  });

  return (
    <>
      {!successView ? 
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.wrapper}
          style={{background: logoData[window.REACT_APP_LOGIN_ENV]['bgColor']}}
        >
          <Grid item>
            <Card className={classes.card}>
              <CardContent>
                <Box align="center" mb={3}>
                  <img src={logoData[window.REACT_APP_LOGIN_ENV]['logo']} style={{ width: 280 }} />
                </Box>

                <form
                  autoComplete="off"
                  className={classes.form}
                  onSubmit={formik.handleSubmit}
                >
                  <Typography align="center" className={classes.passwordLabel}>
                    Please enter your new password & confirm password
                  </Typography>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      autoComplete: "off",
                      className: classes.input,
                      startAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          <VpnKey />
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{
                      className: classes.errorHelper,
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="password"
                    type="password"
                    label=""
                    name="password"
                    placeholder="New Password"
                    autoFocus
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={formik.touched.password && formik.errors.password}
                  />
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      autoComplete: "off",
                      className: classes.input,

                      startAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          <VpnKey />
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{
                      className: classes.errorHelper,
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="confirm_password"
                    label=""
                    type="password"
                    id="confirm_password"
                    placeholder="Confirm New Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirm_password && Boolean(formik.errors.confirm_password)
                    }
                    helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.submit}
                  >
                    Confirm
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid> : <PasswordSuccess />
      }
    </>
  );
};

export default ResetPassword;
