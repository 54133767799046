import { AuditConstants } from "./constants"

export const setAuditQuestions = (data) => ({
    type: AuditConstants.SET_AUDIT_QUESTIONS,
    payload: data,
});

export const setAuditObservations = (data) => ({
    type: AuditConstants.SET_AUDIT_OBSERVATIONS,
    payload: data,
});

export const setAuditTrustScoreForAllQuestions = (data) => ({
    type: AuditConstants.SET_AUDTI_TRUST_SCORE_ALL_QUESTIONS,
    payload: data,
});

export const auditCleanUpFunc = () => ({ type: AuditConstants.AUDIT_CLEANUP })