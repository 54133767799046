import React from "react";
import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import langConstant from "_lang";
import {
  setReviewManagerQuestions,
  setReviewManagerObservations,
  setReviewManagerTrustScoreForAllQuestions,
} from "./actions";

export const GetAssessmentsForManageReview = (
  submissionID,
  userID,
  callback
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetAssessmentsForManageReview", {
        i_SUBMISSION_ID: submissionID,
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        async (response) => {
          const cisoControlArray = [...response["#result-set-8"]];
          const questionData = await [...response["#result-set-1"]].map(
            (el) => {
              let ciscoControl = cisoControlArray.find(
                (control) =>
                  `${el.QUESTION_ID.trim()}_${el.REGULATORS_QUESTION_REF.trim()}` ===
                  `${control.QUESTION_ID.trim()}_${control.REGULATORS_QUESTION_REF.trim()}`
              );
              if (ciscoControl !== undefined || ciscoControl !== null) {
                return {
                  ...el,
                  CISO_CONTROL_NAME: ciscoControl.CISO_CONTROL_NAME,
                };
              } else {
                return { ...el, CISO_CONTROL_NAME: null };
              }
            }
          );
          await dispatch(setReviewManagerQuestions(questionData));
          await dispatch(
            setReviewManagerObservations(response["#result-set-6"])
          );
          await dispatch(
            setReviewManagerTrustScoreForAllQuestions(response["#result-set-7"])
          );
          await dispatch({
            type: "GET_RFI_FILTER",
            payload: response["#result-set-4"],
          });
          await dispatch(loadingActions.end());
          callback(response);
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
