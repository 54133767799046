/* eslint-disable react/react-in-jsx-scope */
import apiService from "_services/api.service";
import { alertActions } from "_actions";
import { v4 as uuidv4 } from "uuid";

export const GetDocumentsForRegulation = (
  i_REGULATION_ID,
  i_CUSTOMER_ID,
  setRequiredFiles,
  setIsLoading
) => {
  return (dispatch) => {
    setIsLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetDocumentsForRegulation", {
        i_REGULATION_ID: i_REGULATION_ID,
        i_CUSTOMER_ID: i_CUSTOMER_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response?.["#result-set-1"]) {
            setRequiredFiles(
              response["#result-set-1"]?.map((item, index) => ({
                ...item,
                uuid: uuidv4(),
                no: index + 1,
              }))
            );
          }
        },
        (error) => {
          if (error?.response?.data?.message) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
};

const getCustomerRegulationPolicyUploaded = (customerID, regulationID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCustomerRegulationPolicyUploaded", {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateCustomerRegulationPolicyMap = (
  customerID,
  regulationID,
  policyID,
  documentRequiredID,
  userID,
  active
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateCustomerRegulationPolicyMap", {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
      i_POLICY_ID: policyID,
      i_DOCUMENT_REQUIRED_ID: documentRequiredID,
      i_USER_ID: userID,
      i_ACTIVE: active,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putCustomerRegulationPolicyUpload = (
  customerID,
  regulationID,
  policyID,
  userID
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutCustomerRegulationPolicyUpload", {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
      i_POLICY_ID: policyID,
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const fileUpload = (data) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/upload/fileUpload", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updatePolicyExtractionFile = (
  policyID,
  filename,
  filepath,
  customerID,
  regulationID,
  userID
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutCustomerRegulationPolicyUpload", {
      i_POLICY_ID: policyID,
      i_EXTRACTION_FILE_NAME: filename,
      i_STORAGE_FOLDER_URL: filepath,
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  getCustomerRegulationPolicyUploaded,
  updateCustomerRegulationPolicyMap,
  putCustomerRegulationPolicyUpload,
  fileUpload,
  updatePolicyExtractionFile,
};
