import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Grid, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import langConstant from "_lang";
import PropTypes from "prop-types";
import CloseIcon from '@mui/icons-material/Close';
import useStyles from '../../styles';
import Alert from '@mui/material/Alert';

const FindAndReplace = ({ show, handleClose, finalContentState, setContentState, setFinalContentState, pageWiseExtractionData, setPageWiseExtractionData}) => {

    const classes = useStyles()

    const [findWord, setFindWord] = useState("")
    const [replaceWord, setReplaceWord] = useState("")
    const [wordCount, setWordCount] = useState("")

    const findAndReplace = () => {
        if(findWord){
            const tempContentState = []
            //eslint-disable-next-line
            const specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            let regEx;
            if(specialChar.test(findWord)){
                regEx = findWord
            } else {
                regEx = new RegExp(findWord,'ig')
            }
            finalContentState?.blocks.forEach(content => {
                const tempContent = {...content}
                tempContent['text'] = tempContent['text'].replaceAll(regEx, replaceWord)
                tempContentState.push(tempContent)
            });
            setContentState({blocks: [...tempContentState], entityMap: {}})
            setFinalContentState({blocks: [...tempContentState], entityMap: {}})
            const tempPageWiseExtractionData = {}
            const clonedPageWiseExtractionData = JSON.parse(JSON.stringify(pageWiseExtractionData))
            Object.keys(clonedPageWiseExtractionData).forEach((item) => {
                tempPageWiseExtractionData[item] = []
                clonedPageWiseExtractionData[item].forEach((subItem) => {
                    const tempSentencesData = []
                    subItem['NAME'] = subItem['NAME'].replaceAll(regEx, replaceWord)
                    subItem.SENTENCESDATA.forEach((sent) => {
                        tempSentencesData.push(sent.replaceAll(regEx, replaceWord))        
                    })
                    subItem['SENTENCESDATA'] = tempSentencesData
                    tempPageWiseExtractionData[item].push(subItem)
                })
            })
            setPageWiseExtractionData(tempPageWiseExtractionData)
            handleClose()
        }
    }

    const findCount = () => {
        if(findWord){
            //eslint-disable-next-line
            const specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            let regEx;
            if(specialChar.test(findWord)){
                regEx = findWord
            } else {
                regEx = new RegExp(findWord,'ig')
            }
            let count = 0
            const clonedPageWiseExtractionData = JSON.parse(JSON.stringify(pageWiseExtractionData))
            
            Object.keys(clonedPageWiseExtractionData).forEach((item) => {
                clonedPageWiseExtractionData[item].forEach((subItem) => {
                    const headingCount = (subItem["NAME"].match(regEx) || []).length
                    count = headingCount + count
                    subItem.SENTENCESDATA.forEach((sent) => {
                        const tempCount = (sent.match(regEx) || []).length
                        count = tempCount + count
                    })
                })
            })
            setWordCount(count)
        }
    }

    return (
        <Dialog
            open={show}
            maxWidth={"sm"}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogTitle className={classes.headWrap}>
                <Typography variant='h6' style={{alignItems: "center"}}>{langConstant.FIND_AND_REPLACE}</Typography>
                <IconButton onClick={handleClose}><CloseIcon /></IconButton>
            </DialogTitle>
            <DialogContent>
                {wordCount !== "" &&
                    <Grid style={{paddingBottom: "10px"}}>
                        <Alert severity="info"><span style={{fontWeight:"bold"}}>{wordCount}</span> {langConstant.INSTANCES_FOUND}</Alert>
                    </Grid>
                }
                <Grid>
                    <Typography>{langConstant.FIND}</Typography>
                    <TextField
                        className={classes.inputWrap}
                        onChange={(e) => setFindWord(e.target.value)}
                        variant="outlined"
                    />
                </Grid>
                <Grid>
                    <Typography className={classes.replaceWrap}>{langConstant.REPLACE}</Typography>
                    <TextField
                        className={classes.inputWrap}
                        onChange={(e) => setReplaceWord(e.target.value)}
                        variant="outlined"
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => findCount()} variant='contained' color="primary">{langConstant.FIND}</Button>
                <Button onClick={() => findAndReplace()} color="primary" variant='contained'>{langConstant.REPLACE}</Button>
                <Button variant="outlined" color="primary" onClick={handleClose}>
                    {langConstant.CANCEL}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

FindAndReplace.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    finalContentState: PropTypes.object,
    setContentState: PropTypes.func,
    setFinalContentState: PropTypes.func,
    pageWiseExtractionData: PropTypes.object,
    setPageWiseExtractionData: PropTypes.func,
}

export default FindAndReplace;