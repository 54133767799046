// OP#3652 New Policy Upload recipie - Implement Help Tips

import React, { useState } from "react"
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from "prop-types";


const HelperTextComponent = ({id, textData}) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClick} style={{marginLeft: "5px"}}>
                <HelpIcon style={{fontSize: "18px"}}/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                {textData.map((text, index) => {
                    return (
                        <Typography sx={{ p: 2, fontSize: "12px", padding: "2px 10px !important" }} key={index}>{text}</Typography>
                    )
                })}
            </Popover>
        </>
    )
}

HelperTextComponent.propTypes = {
    id: PropTypes.string,
    textData: PropTypes.array
};

export default HelperTextComponent;