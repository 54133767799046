/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React from "react";
import constant from "./constants";
import apiService from "../../../../_services/api.service";
import { alertActions, loadingActions } from "../../../../_actions";
import mockData from "./../../../../_helpers/mockData";
import langConstant from "_lang";
import sessionService from "_services/session.service";

const getDraft = (id, goBackOnError) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetDraftData", { i_REGULATION_ID: id })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let payload = { draft: [], activeStep: 0 };
          const functionWithPromise = (item) => {
            return Promise.resolve(item);
          };
          const anAsyncFunction = async (item) => {
            return functionWithPromise(item);
          };

          try {
            const parseJson = JSON.parse(response["#result-set-1"][0].PAYLOAD);
            if (parseJson.draft) {
              payload = {
                draft: parseJson.draft,
                activeStep: parseJson.activeStep,
              };
            } else {
              payload = { draft: parseJson, activeStep: 0 };
            }
            const getData = async () => {
              return Promise.all(
                payload.draft.map((item, index) => {
                  const newItem = {
                    ...item,
                    id: item.id ? item.id : "Q" + (index + 1),
                    selected: item.selected ? item.selected : false,
                    selectedMatch: item.selectedMatch ? item.selectedMatch : "",
                    ref: item.ref ? item.ref : "Q" + (index + 1),
                    info: item.info ? item.info : "",
                    matches: item.matches ? item.matches : [],
                    mappedControl: item.mappedControl ? item.mappedControl : [],
                    narratives: item.narratives ? item.narratives : "",
                  };
                  return anAsyncFunction(newItem);
                })
              );
            };

            getData().then((data) => {
              dispatch({
                type: constant.questionDraftConstant_GET,
                data: { draft: data, activeStep: payload.activeStep },
              });
            });
          } catch (e) {
            dispatch({ type: constant.questionDraftConstant_FAILURE });
            dispatch(alertActions.error("Error in extracted Questions list"));
            goBackOnError();
            console.log(e, true);
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({ type: constant.questionDraftConstant_FAILURE });
          goBackOnError();
        }
      );
    //dispatch({ type: constant.questionDraftConstant_GET, data: draft })
  };
};
const updateDraft = (id, draft, activeStep = 0) => {
  //let data = new Blob(JSON.stringify(draft) );

  return (dispatch) => {
    const userID = sessionService.getUserId();
    const userRole = sessionService.getUserRole();
    var teamLeaderID = null;
    var managerID = null;

    // ------------------------------

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetTLMDetails", {
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (userRole == "CISO Analyst") {
            teamLeaderID = response["#result-set-1"][1].USER_ID;
            managerID = response["#result-set-1"][2].USER_ID;
          } else if (userRole == "CISO Team Leader") {
            managerID = response["#result-set-1"][1].USER_ID;
          }

          apiService
            .apiCall("dbapi")
            .send("/v1/api/proc/DRegulativDB/PutDraftData", {
              i_REGULATION_ID: id,
              i_PAYLOAD: JSON.stringify({
                draft: draft,
                activeStep: activeStep,
              }),
              i_USER_ID: userID,
              i_TL_ID: teamLeaderID,
              i_MANAGER_ID: managerID,
            })
            .then(apiService.handleResponse)
            .then(
              (response) => {
                dispatch({
                  type: constant.questionDraftConstant_UPDATE,
                  data: { draft: draft, activeStep: activeStep },
                });
                dispatch(
                  alertActions.success(langConstant.DRAFT_UPDATED_SUCCESSFULLY)
                );
              },
              (error) => {
                dispatch(loadingActions.end());
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  dispatch(
                    alertActions.error(
                      error.response.data.message.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })
                    )
                  );
                } else {
                  dispatch(alertActions.error(error.toString()));
                }
              }
            );

          // ---------------------------
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );

    // ----------------------------

    //dispatch(loadingActions.start());
  };
};

const updateSingleItemInDraft = (obj) => {
  return (dispatch) => {
    //dispatch(loadingActions.start());
    dispatch({
      type: constant.questionDraftConstant_UPDATE_SINGLE_ITEM,
      data: obj,
    });
    //dispatch(alertActions.success('Draft updated successfully!'));
  };
};
const updateRegulationStatus = (id, status, callback) => {
  //let data = new Blob(JSON.stringify(draft) );

  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutRegulationStatus", {
        i_REGULATION_ID: id,
        i_STATUS: status,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (status == "PENDING_FOR_REVIEW") {
            dispatch(
              alertActions.success(langConstant.REGULATION_SENT_SUCCESSFULLY)
            );
            callback();
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
    //dispatch(loadingActions.start());
  };
};

export default {
  getDraft,
  updateDraft,
  updateSingleItemInDraft,
  updateRegulationStatus,
};
