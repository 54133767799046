/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import ScreenLockLandscapeIcon from "@material-ui/icons/ScreenLockLandscape";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DialogBox from "_components/DialogBox";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import EvidenceTable from "./component/EvidenceTable";
import evidenceAction from "./actions";
import PropTypes from "prop-types";
import langConstant from "_lang";

const EvidenceLocker = ({
  questionId,
  renderForRfi = false,
  evidenceCount,
  button, 
  attachmentsCount,
  submissionId
}) => {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const evidenceRows = useSelector((state) => state.evidenceLocker.evidence);

  const evidenceColumns = {
    'view_evidence': [
      {
        field: "Version_Label",
        headerName: langConstant.VERSION,
        maxWidth:50,
      },
      { field: "EVIDENCE_SHORTNAME", headerName: "EVIDENCE TYPE", minWidth: 140 },
      { field: "EVIDENCE_STATUS", headerName: "Evidence Status", maxWidth: 70},
     
      {
        field: "EVIDENCE_WEIGHTAGE",
        headerName: "Weightage (In %)",
        maxWidth: 70,
        format: function formatData(value) {
          if(value) {
            return value
          } else{ 
            return <Grid style={{color: "red"}}>No Data</Grid>
          }
        }
      },
      {
        field: "Attachment_Label",
        headerName: langConstant.ATTACHMENTS,
        minWidth: 170,
      },
      {
        field: "Evidence_WordCloud",
        headerName: "Wordcloud",
        minWidth: 170,
        format: function formatData(value) {
          if(value) {
            return value
          } else{ 
            return <Grid style={{color: "red"}}>No Data</Grid>
          }
        }
      },
      {
        field: "UPLOAD_DATE",
        headerName: langConstant.UPLOAD_DATE_AND_TIME,
        format: (value) =>
          value ? moment(value).format("DD-MMM-YY h:mm A") : "",
        minWidth: 170,
      },
      {
        field: "EXPIRY_DATE",
        headerName: langConstant.EXPIRY_DATE,
        format: (value) =>
          value ? moment(value).format("DD-MMM-YY h:mm A") : "-",
        minWidth: 150,
      },
      {
        field: "Uploaded_By",
        headerName: langConstant.UPLOADED_BY,
        minWidth: 140,
      },
      {
        field: "METADATA",
        headerName: "Accuracy Score",
        format: function formatData(value) {
          if(value && value.length >= 1)
          {
            return value[0]
          } else{ 
            return <Grid style={{color: "red"}}>No Data</Grid>
          }
        }
      },
      {
        field: "METADATA",
        headerName: "Suitability Score",
        format: function formatData(value) {
          if(value && value.length >= 2)
          {
            return value[1]
          } else{ 
            return <Grid style={{color: "red"}}>No Data</Grid>
          }
        }
      },
    ],
    'tags' : [
      {
        field: "Version_Label",
        headerName: "Version",
      },  
      { field: "EVIDENCE_SHORTNAME", headerName: "Evidence Type", minWidth: 140 },
      {
        field: "COMMENTS_INPUT",
        headerName: "Comments",
        minWidth: 100,
      },
      {
        field: "Tags_Label",
        headerName: "TAGS",
        minWidth: 100,
      },
    ]
  }

  // * Toggles the RFI Filter Dialog box
  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };


  const openDialogAndRefreshData = () => {
    if(submissionId){
      dispatch(evidenceAction.getEvidenceBySubmissionId(questionId, submissionId));
    }else{
      dispatch(evidenceAction.getEvidence(questionId, submissionId));
    }
    toggleDialog();
  };

  const renderedButton = renderForRfi ? (
    <Button
      color="primary"
      size="small"
      startIcon={<AttachmentIcon />}
      onClick={openDialogAndRefreshData}
    >
      {`${evidenceCount}${" "}${langConstant.ATTACHMENTS}`}
    </Button>
  ) : (
    <IconButton
      style={{
        backgroundColor: "#007BFF",
        borderRadius: "50%",
        width: "25px",
        height: "25px",
        color: "white",
        fontSize: "18px",
      }}
      onClick={openDialogAndRefreshData}
    >
      <ScreenLockLandscapeIcon color="inherit" fontSize="inherit" />
    </IconButton>
  );

  return (
    <Box ml={1}>
      {button ? (<Button
        variant="outlined"
        color="primary"
        onClick={openDialogAndRefreshData}
        startIcon={<AttachmentIcon />}
      >
        {attachmentsCount+" "+langConstant.ATTACHMENTS}
      </Button>) : (<Tooltip title={langConstant.VIEW_ATTACHED_EVIDENCE} placement="top">
        {renderedButton}
      </Tooltip>)
      }
      <DialogBox
        maxWidth={"lg"}
        title={langConstant.EVIDENCE_LOCKER_TITLE}
        open={dialogOpen}
        handleClose={toggleDialog}
        style={{paddingTop:"0px",}}
      >
        <EvidenceTable
          rows={evidenceRows}
          columns={evidenceColumns}
          isAccordion={false}
          className="data-table"
          type="evidencePopup"
          submissionId={submissionId}
        />
      </DialogBox>
    </Box>
  );
};

EvidenceLocker.propTypes = {
  questionId: PropTypes.string,
  button: PropTypes.bool,
  attachmentsCount: PropTypes.number,
  renderForRfi: PropTypes.bool,
  evidenceCount: PropTypes.number,
  submissionId: PropTypes.number
};

export default EvidenceLocker;
