/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import _ from "lodash";
import { Box, Grid, Checkbox, Divider } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import rootStyles from "../../../../../rootStyles";
import Truncate from "../../../../../_components/Truncate";
import questionMappingAction from "../actions";
import QuestionRow from "./QuestionRow";
import QuestionsCountInfoBar from "./QuestionsCountInfoBar";

const EditQuestionsStep = (props) => {
  const { id } = useParams();
  const [list, setList] = React.useState(props.questionDraft);
  const dispatch = useDispatch();
  useEffect(() => {
    setList(props.questionDraft);

    const selectedList = _.filter(props.questionDraft, { selected: true });
    if (selectedList.length == 0) {
      props.handleBack();
    }
  }, []);

  const deselectQuestion = (id, value) => {
    const newList = list.map((item) => {
      if (id == item.id) {
        return { ...item, selected: value };
      }
      return { ...item };
    });

    const selectedList = _.filter(newList, { selected: true });
    if (selectedList.length == 0) {
      props.handleBack();
    }

    setList(newList);
    updateQuestionDraft(newList);
  };

  const updateQuestion = (id, value) => {
    if (value) {
      const val = value;
      const newList = list.map((item) => {
        if (id == item.id) {
          return { ...item, sentence: val };
        }
        return { ...item };
      });

      setList(newList);
      updateQuestionDraft(newList);
    }
  };

  const updateQuestionDraft = (list) => {
    props.editDeleteQuestions(list);
  };

  if (list.length == 0) {
    return true;
  }

  return (
    <>
      <QuestionsCountInfoBar questionDraft={props.questionDraft} />
      <Box mt={2}>
        <Grid container spacing={0} justify="space-between">
          <Grid>
            <p>
              <b>SELECTED QUESTIONS</b>
            </p>
          </Grid>
          <Grid style={{ minWidth: 115 }}>
            <p>
              <b>ACTIONS</b>
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        {list.map((item, index) => {
          if (item.selected) {
            return (
              <Box key={index}>
                <QuestionRow
                  data={item}
                  updateQuestion={updateQuestion}
                  deselectQuestion={deselectQuestion}
                />
                {list.length - 1 > index && (
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                )}
              </Box>
            );
          }
        })}
      </Box>
    </>
  );
};
EditQuestionsStep.propTypes = {
  questionDraft: PropTypes.array,
  editDeleteQuestions: PropTypes.func,
  handleBack: PropTypes.func,
};
export default EditQuestionsStep;
