import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  profileMDTopHedaerMain: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  profileMDTopHedaer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "calc(100% - 130px)",
    paddingRight: "15px",
  },
  profileMDTopHedaerImg: {
    width: "100px",
    height: "100px",
    position: "relative",
    fontSize: "50px !important",
  },
  profileUploadinlineBlock: {
    position: "relative",
    display: "inline-block",
    "&:hover": {
      "& $profileHoverChnage": {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  profileHoverChnage: {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    borderRadius: "50%",
    display: "none",
  },
  profileMDTopHedaerCN: {
    width: "calc(100% - 100px)",
    paddingLeft: "15px",
  },
  profileMDTopHedaerToggleWrapper: {
    display: "inline-flex",
    alignItems: "center",
  },
  profileMDTopHedaerToggleBtn: {},
  profileMDTopHedaerToggleLabel: {
    color: "grey",
  },
  profileMDTopHedaerName: {
    color: "#000",
    fontSize: "16px",
  },
  profileMDTopHedaerRole: {
    color: "grey",
    fontSize: "14px",
  },
  profileMDBasicInfoHeading: {
    color: "#000",
    fontSize: "15px",
    textTransform: "uppercase",
    marginBottom: "4px",
    marginTop: "15px",
    fontWeight: "600",
  },
  profileMDBasicInfoItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    // paddingBottom: "9px",
    marginBottom: "4px",
  },
  profileMDBasicInfoIcon: {
    width: "18px !important",
    height: "18px !important",
    fill: "#000 !important",
  },
  profileMDBasicInfoLabel: {
    color: "#000",
    fontSize: "14px",
    width: "calc(100% - 18px)",
    paddingLeft: "12px",
  },
  profileMDialogBox: {
    position: "static !important",
    width: "0px !important",
    height: "0px !important",
  },
  profileMDTopHedaerCardFX: {
    width: "500px",
    backgroundColor: "#fff",
    padding: "30px",
  },
  profileMDCloseBtn: {
    top: "9px",
    right: "14px",
    width: "18px",
    height: "18px",
    position: "absolute",
    cursor: "pointer",
    color: "#000000",
  },
  profileMDCloseFirst: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  profileMDCloseSecond: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(-45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  inputProfileField: {
    padding: "7px 0px !important",
    width: "100%",
    color: "#000",
  },
  inputPasswordField: {
    padding: "10px 10px !important",
  },
  editBTNProfile: {
    fontSize: "14px",
    border: "1px solid #0080ff",
    borderRadius: "5px",
    padding: "4px 10px 2px 10px !important",
  },
  editBTNProfileIcon: {
    width: "16px !important",
    marginRight: "4px",
    position: "relative",
    top: "-1px",
  },
  profileButtonWrapper: {
    marginTop: "14px",
    textAlign: "right",
  },
  inputProfilefieldset: {
    borderColor: "transparent",
  },
  borderNone: {
    border: "none",
  },
  profileMDBasicSelect: {
    width: "100%",
    color: "#000000",
  },
  sendTempPass: {
    border: "1px solid #0280ff !important",
    padding: "5px 10px 4px 10px !important",
  },
  sendTempPassIcon: {
    marginRight: "4px",
    width: "19px !important",
    height: "10px",
  },
  unlockBtn: {
    border: "1px solid #0280ff !important",
    padding: "5px 10px 4px 10px !important",
  },
  unlockBtnIcon: {
    marginRight: "4px",
    width: "19px !important",
    height: "10px",
  },
  profiletemPassBTNwrapper: {
    flexWrap: "wrap",
    justifyContent: "center !important",
  },
  profileMDBasicInfoItemHalf: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  authWrapper: {
    paddingBottom: "10px",
  },
  inputLabel: {
    color: "#000",
    fontSize: "15px",
    width: "calc(100% - 18px)",
    fontWeight: "bold",
  },
  notificationWrapper: {
    padding: "15px",
  },
  formFieldWrapper: {
    margin: "15px 0",
    alignItems: "center",
  },
  userFormLabel: {
    fontWeight: "bold",
    fontSize: "14px",
  },
  formHeader: {
    fontWeight: "bold",
    fontSize: "24px",
  },
  inputField: {
    width: "70%",
  },
  customerSelectForm: {
    width: "80%",
  },
  formLabel: {
    fontWeight: "bold",
  },
}));

export default useStyles;
