import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  configurationLabelWrapper: {
    borderBottom: "2px solid grey",
    marginBottom: "15px",
    padding: "7px 0px",
  },
  configurationWrapper: {
    border: "2px solid grey",
    borderRadius: "5px",
    padding: "10px",
    height: "100%",
  },
  configurationLabel: {
    fontWeight: "bold",
  },
  formFieldWrapper: {
    display: "flex",
    padding: "5px 0",
    alignItems: "center",
  },
  formFieldLabel: {
    fontWeight: "bold",
    fontSize: "14px",
    width: "35%",
  },
  formThemeFieldLabel: {
    fontWeight: "bold",
    fontSize: "14px",
    width: "60%",
  },
  input: {
    "& input.MuiInputBase-input": {
      padding: "10px",
      //   width: "80%",
    },
    "& .Mui-disabled": {
      color: "gray",
    },
  },
  dateInput: {
    "& dateInput .MuiFormControl-root": {
      marginRight: "2em",
    },
  },
  customerSelectForm: {
    width: "80%",
  },
}));

export default useStyles;
