import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import langConstant from "_lang";
import DialogBox from "_components/DialogBox";
import PropTypes from "prop-types";
import useStyles from "../styles";
import { Button, Typography } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const styles = {
  wrapperStyle: {
    border: "1px solid #969696",
  },
  editorStyle: {
    padding: "0.5rem",
  },
};

const NewLicenceKeyPopUp = ({ open, setOpen, newLicenceKey }) => {

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveKey = async () => {
    // Create a Blob containing the newLicenceKey value
    const jsonString = JSON.stringify({ KeyValue: newLicenceKey });
    const blob = new Blob([jsonString], { type: "application/json" });

    const fileHandle = await window.showSaveFilePicker();

    // Create a WritableStream to write the Blob to the selected file
    const writable = await fileHandle.createWritable();
    await writable.write(blob);
    await writable.close();

    setOpen(false); 
  };

  return (
    <DialogBox
      maxWidth={"sm"}
      title={langConstant.NEW_LICENCE_KEY}
      open={open}
      handleClose={handleClose}
    >
      <DialogContent>
        <Typography
          style={{
            minWidth: "400px",
            maxWidth: "400px",
            minHeight: "60px",
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 0 30px",
            overflowWrap: "break-word",
          }}
          align="center"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: newLicenceKey
              ? newLicenceKey.substring(0, 30) + "***********"
              : "",
          }}
        ></Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSaveKey} color="primary" size="medium" autoFocus>
          {"Save Key"}
        </Button>
        <Button onClick={handleClose} color="primary" size="medium">
          {"OK"}
        </Button>
      </DialogActions>
    </DialogBox>
  );
};

NewLicenceKeyPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  newLicenceKey: PropTypes.string,
};

export default NewLicenceKeyPopUp;
