import React, { useState } from "react";
import { Box, makeStyles } from '@material-ui/core';
import langConstant from "_lang";
import PropTypes from "prop-types";
import FilterListIcon from "@material-ui/icons/FilterList";
import DialogBox from "_components/DialogBox";
import RfiFilterTable from "_components/RfiFilterTable";
import AttachEvidence from "_components/AttachEvidence/index";
import EvidenceLocker from "_components/EvidenceLocker/index";
import OpenCalendarPopup from "_components/OpenCalendarPopup/OpenCalendarPopup";
import { useSelector } from "react-redux";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";


const useStyles = makeStyles((theme) => ({
    backButton: {
        marginRight: theme.spacing(1),
    },
}));


const ToolBar = ({ selectedQuestion }) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const rfiRows = useSelector((state) => state.MasterAssessment.RfiFilterDetails);

    const columns = [
        {
            field: "REGULATORS_QUESTION_REF",
            headerName: "QUESTION REF.",
            minWidth: 135,
        },
        { field: "Status_label", headerName: "STATUS" },
        {
            field: "RFI_DESCRIPTION",
            headerName: "COVER NOTE",
            minWidth: 350,
        },
        {
            field: "RFI_DUE_DATE",
            headerName: "DUE DATE",
            format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
            // format: (value) => (value ? value.substring(0, 10) : ""),
            minWidth: 120,
        },
    ];



    const toggleDialog = () => {
        setDialogOpen(!dialogOpen);
    };

    return (
        <Box display="flex" alignItems="center" style={{height:"42px"}}>

            <Tooltip
                title={
                    rfiRows.length == 0
                        ? "Rfi Filter Disabled"
                        : langConstant.RFI_FILTER
                }
                placement="top"
            >
                <span>
                    <IconButton
                        style={{
                            borderRadius: "50%",
                            width: "25px",
                            height: "25px",
                            fontSize: "22px",
                            color: "white",
                            backgroundColor: rfiRows.length == 0 ? "#B1B1B1" : "#007BFF",
                        }}
                        disabled={rfiRows.length == 0}
                        onClick={toggleDialog}
                    >
                        <FilterListIcon color="inherit" fontSize="inherit" />
                    </IconButton>
                </span>
            </Tooltip>



            {/* <Button
                color="primary"
                onClick={toggleDialog}
                disabled={rfiRows.length == 0 ? true : false}
                startIcon={<FilterListIcon />}
            >
                {langConstant.RFI_FILTER}
            </Button> */}




            <DialogBox
                maxWidth={"lg"}
                title={langConstant.RFI_DETAILS}
                open={dialogOpen}
                handleClose={toggleDialog}
            >
                <RfiFilterTable
                    rows={rfiRows}
                    columns={columns}
                    className="data-table"
                />
            </DialogBox>
            <AttachEvidence
                questionId={
                    selectedQuestion ? selectedQuestion.QUESTION_ID : null
                }
                submissionId={
                    selectedQuestion ? selectedQuestion.SUBMISSION_ID : null
                }
            />
            <EvidenceLocker
                questionId={
                    selectedQuestion ? selectedQuestion.QUESTION_ID : null
                }
                submissionId={
                    selectedQuestion ? selectedQuestion.SUBMISSION_ID : null
                }
            />
            <OpenCalendarPopup submissionId={selectedQuestion ? selectedQuestion.SUBMISSION_ID : ""} width='25px' height='25px' />
        </Box>

    )
}

ToolBar.propTypes = {
    selectedQuestion: PropTypes.object,
};


export default ToolBar