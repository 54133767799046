import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Select from "react-select";
import UserServices from "./services";
import sessionService from "_services/session.service";
import { alertActions } from "_actions";

const UserRoleDropdown = (props) => {
  const [roleOptions, setRoleOptions] = useState([]);
  const dispatch = useDispatch();

  const getUserRoleList = async () => {
    const response = await UserServices.getRoleDetails(props.customerID);
    const tempUserRoleList = [];
    if (response.isSuccess) {
      response.data.forEach((element) => {
        tempUserRoleList.push({
          value: element.ROLE_ID,
          label: element.ROLE_NAME,
        });
      });
    } else {
      dispatch(alertActions.error(response.message));
    }
    setRoleOptions(tempUserRoleList);
  };

  useEffect(() => {
    (async () => {
      await getUserRoleList();
    })();
  }, []);

  return (
    <Select
      value={roleOptions.find((role) => role.value == props.value)}
      options={roleOptions}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  );
};

UserRoleDropdown.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  customerID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default UserRoleDropdown;
