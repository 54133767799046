import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";
import DocumentViewer from "_components/DocumentViewer";
import useStyles from "../../styles";
import { useSelector } from "react-redux";
import sessionService from "_services/session.service";

const DocumentDrawer = ({ show, setShow, documentInfo }) => {
  const classes = useStyles();

  const [currentUrl, setCurrentUrl] = useState({
    filePath: "",
    fileName: "",
    fileType: "",
  });
  const customerID = sessionService.getCustomerId();

  const isProcessed = useSelector(
    (state) => state.uploadPolicyReducer.processedView
  );

  useEffect(() => {
    if (isProcessed) {
      setCurrentUrl({
        filePath:
          customerID.toString() +
          "/Policy_Management/Extraction/" +
          documentInfo.policyId +
          "/",
        fileName: documentInfo.processed.name,
        fileType: documentInfo.processed.extension,
      });
    } else {
      setCurrentUrl({
        filePath:
          customerID.toString() +
          "/Policy_Management/Extraction/" +
          documentInfo.policyId +
          "/",
        fileName: documentInfo.original.name,
        fileType: documentInfo.original.extension,
      });
    }
  }, [documentInfo]);

  return (
    <Drawer
      anchor={"right"}
      open={show}
      onClose={() => setShow(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "700px !important",
        },
      }}
    >
      <Grid className={classes.docViewer} style={{ height: "100vh" }}>
        {currentUrl.fileName && (
          <DocumentViewer
            documentClass={classes.documentClass}
            height="calc(100vh - 20px)"
            fileName={currentUrl.fileName}
            filePath={currentUrl.filePath}
            fileType={currentUrl.fileType}
          />
        )}
      </Grid>
    </Drawer>
  );
};

DocumentDrawer.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  documentInfo: PropTypes.object,
};

export default DocumentDrawer;
