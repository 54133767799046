import DialogBox from "_components/DialogBox";
import React from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { Form, Formik, Field } from "formik";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import ClassificationUtils from "../utils";
import ClassificationServices from "../services";
import FormTextField from "_components/FormTextField";
import useStyles from "../styles";
import { useDispatch } from "react-redux";

const SanctionDialog = ({
  open,
  setOpen,
  selectedSanctionKeyword,
  sanctionKeywords,
  setSanctionKeywords,
  selectedCustomer,
  setSelectedSanctionKeyword,
}) => {
  const handleClose = () => {
    setOpen(false);
    setSelectedSanctionKeyword(null);
  };
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(
      ClassificationServices.putSanctionKeywords(
        selectedCustomer.value,
        values,
        sanctionKeywords,
        setSanctionKeywords,
        handleClose
      )
    );
  };

  return (
    <DialogBox
      maxWidth={"sm"}
      title={langConstant.SANCTION_KEYWORD}
      open={open}
      handleClose={handleClose}
    >
      <Formik
        initialValues={
          selectedSanctionKeyword
            ? selectedSanctionKeyword
            : ClassificationUtils.sanctionKeywordInitialValues
        }
        validationSchema={ClassificationUtils.sanctionKeywordValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Grid container>
              <Grid item md={12} xs={12} style={{ margin: "10px 0" }}>
                <FormTextField
                  fieldLabel={langConstant.SANCTION_KEYWORD}
                  value={values.KEYWORD}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="KEYWORD"
                  errors={errors}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.ACTIVE}
                </Typography>
                <Field style={{ width: "100%" }} name="ACTIVE">
                  {() => (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={parseInt(values["ACTIVE"])}
                      onChange={(e) => setFieldValue("ACTIVE", e.target.value)}
                      style={{
                        display: "inline",
                      }}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid style={{ textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                {selectedSanctionKeyword
                  ? langConstant.UPDATE
                  : langConstant.CREATE_BUTTON}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogBox>
  );
};

SanctionDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  selectedSanctionKeyword: PropTypes.oneOfType([() => null, PropTypes.object]),
  sanctionKeywords: PropTypes.array,
  setSanctionKeywords: PropTypes.func,
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
  setSelectedSanctionKeyword: PropTypes.func,
};

export default SanctionDialog;
