import React from 'react'

import PropTypes from "prop-types";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
import { Box, Fab, Grid, Tooltip, Button, TextField, MenuItem, IconButton, Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import MailIcon from "@material-ui/icons/Mail";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { connect } from "react-redux";
import compose from 'recompose/compose';
import CloseIcon from "@mui/icons-material/Close"
import GroupIcon from "@mui/icons-material/Group"
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save"
import Popover from "@material-ui/core/Popover";
import { useFormik, Formik, useFiel, Field } from "formik";
import * as yup from "yup";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MuiDialogActions from "@material-ui/core/DialogActions";
import classNames from 'classnames';

import calendarAction from "../../_actions/calendar.action";
import PopupOnCalendar from '_components/PopupOnCalendar/PopupOnCalendar';
import sessionService from "../../_services/session.service";
import { constants } from "../../_constants/calendar.constants";
import langConstant from "_lang";



const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    calendar: {
        '& calendar.fc.fc-col-header-cell-cushion ': {
            display: 'inlineBlock',
            padding: '2px 4px',
            color: 'red'
        }
    },
    input: {
        "& input.MuiInputBase-input": {
            padding: '8px'
        },
        "& .Mui-disabled": {
            color: "gray"
        }
    },
    label: {
        "& .MuiFormControlLabel-label.Mui-disabled": {
            color: "gray"
        }
    },
    dateInput: {
        "& dateInput .MuiFormControl-root": {
            marginRight: '2em'
        }
    },
    select: {
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            padding: '8px'
        }
    }
});
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
function renderEventContent(eventInfo) {
    var statusBackground;
    if (eventInfo.event.extendedProps.ENTRY_TYPE === constants.RFI) {
        if (eventInfo.event.extendedProps.STATUS == constants.RFI_SENT) {
            statusBackground = "#7BC0FF";
        } else if (eventInfo.event.extendedProps.STATUS == constants.RFI_PENDING) {
            statusBackground = "#FFF89C";
        } else if (eventInfo.event.extendedProps.STATUS == constants.RFI_OVERDUE) {
            statusBackground = "#FFBCBC";
        } else if (eventInfo.event.extendedProps.STATUS == constants.RFI_RESOLVED) {
            statusBackground = "#AFF3BB";
        }
    }

    return (
        <>
            {eventInfo.event.extendedProps.ENTRY_TYPE === constants.RFI ?
                (<Box
                    style={{
                        backgroundColor: `${statusBackground}`,
                        padding: "10px",
                        borderRadius: "4px",
                        marginRight: "20px",
                        alignItems: 'center',
                        width:'100%',
                        display: 'flex'
                    }}>
                    {eventInfo.event.extendedProps.STATUS == constants.RFI_SENT ?
                        (<MailIcon
                            style={{
                                fontSize: 20,
                                color: "#105BA1",
                                marginRight: "5px",
                            }}
                        />) : eventInfo.event.extendedProps.STATUS == constants.RFI_RESOLVED ?
                            (<CheckOutlinedIcon
                                style={{
                                    fontSize: 20,
                                    color: "#1a751a",
                                    marginRight: "5px",
                                }}
                            />) :
                            eventInfo.event.extendedProps.STATUS == constants.RFI_PENDING ? (
                                <HelpOutlineIcon
                                    style={{
                                        fontSize: 20,
                                        color: "#895514",
                                        marginRight: "5px",
                                    }}
                                />) : eventInfo.event.extendedProps.STATUS == constants.RFI_OVERDUE ? (
                                    <ReportProblemIcon
                                        style={{
                                            fontSize: 20,
                                            color: "#d62000",
                                            marginRight: "5px",
                                        }}
                                    />) :
                                ('')

                    }
                    <Tooltip title={eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE} placement="top">

                        <Typography variant="body2" style={{
                            color: "black", whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE}
                        </Typography>
                    </Tooltip>
                </Box>) : eventInfo.event.extendedProps.ENTRY_TYPE === constants.MEETING ?
                    (<Box
                        style={{
                            backgroundColor: `lightskyblue`,
                            padding: "6px",
                            borderRadius: "4px",
                            marginRight: "20px",
                            alignItems: 'center',
                            width:'100%',
                            display: 'flex'
                        }}>
                        <GroupIcon
                            style={{
                                fontSize: 26,
                                color: "#0963eb",
                                marginRight: "5px",
                            }}
                        />
                        <Tooltip title={eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE} placement="top">

                            <Typography variant="body2" style={{
                                color: "black", whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                {eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE}
                            </Typography>
                        </Tooltip>
                    </Box>) : (eventInfo.event.extendedProps.PRIORITY === 'H' ? (
                        <Box style={{width: '100%'}}>
                            <Grid container>
                                <Grid item xs={3} style={{ textAlign: 'center' }}>
                                    <Fab size="small" style={{ color: 'red', backgroundColor: '#ffbcbc', borderColor: '#ffbcbc', boxShadow: 'none' }}>
                                        {eventInfo.event.extendedProps.PRIORITY}
                                    </Fab>
                                </Grid>
                                <Grid item xs={9} style={{
                                    justifyContent: 'left', alignItems: 'center',
                                    display: 'flex'
                                }}>
                                    <Tooltip title={eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE} placement="top">
                                        <Typography variant="body2" style={{
                                            color: "black", whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>) : eventInfo.event.extendedProps.PRIORITY === 'M' ? (<Box style={{width: '100%'}}>
                            <Grid container>
                                <Grid item xs={3} style={{ textAlign: 'center' }}>
                                    <Fab size="small" style={{ boxShadow: 'none', color: 'white', backgroundColor: 'orange', borderColor: 'orange' }}>
                                        {eventInfo.event.extendedProps.PRIORITY}
                                    </Fab>
                                </Grid>
                                <Grid item xs={9} style={{
                                    justifyContent: 'left', alignItems: 'center',
                                    display: 'flex'
                                }}>
                                    <Tooltip title={eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE} placement="top">

                                        <Typography variant="body2" style={{
                                            color: "black", whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>) : eventInfo.event.extendedProps.PRIORITY === 'L' ? (<Box style={{width: '100%'}}>
                            <Grid container>
                                <Grid item xs={3} style={{ textAlign: 'center' }}>
                                    <Fab size="small" style={{ boxShadow: 'none', color: 'green', backgroundColor: '#aff3bb', borderColor: '#aff3bb' }} >
                                        {eventInfo.event.extendedProps.PRIORITY}
                                    </Fab>
                                </Grid>
                                <Grid item xs={9} style={{
                                    justifyContent: 'left', alignItems: 'center',
                                    display: 'flex'
                                }}>
                                    <Tooltip title={eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE} placement="top">

                                        <Typography variant="body2" style={{
                                            color: "black", whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {eventInfo.view.type === 'listMonth' ? eventInfo.event.extendedProps.ENTRY_DESCRIPTION : eventInfo.event.extendedProps.ENTRY_TITLE}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>) : ('')
                    )}
        </>
    )
}

class CalendarView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            weekendsVisible: false,
            open: false,
            anchorEl: null,
            isMeetingEdit: false,
            canEdit: false
        }
    }

    componentDidMount = () => {
        this.props.getCalendarEntries(this.getData())
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.entryType !== this.props.entryType) {
            this.props.getCalendarEntries(this.getData())
        }
    }

    handleClickOpen = (e) => {
        this.props.setSelectedEntry(e.event.extendedProps)
        if (e.event.extendedProps.ENTRY_TYPE === constants.MEETING) {
            this.openNewMeetingPopover(e.jsEvent, 'update');
        } else {
            this.setState({ ...this.state, open: true });
        }

    };

    getData = () => {
        if (this.props.entryType) {
            return {
                entryType: !this.props.onPopup ? ((this.props.entryType.filter(e => e.value)).map(e => e.id)).toString() : this.props.entryType,
                userID: sessionService.getUserId(),
                submissionId: this.props.submissionId ? this.props.submissionId : 0
            }
        }
    }
    updateCalendarEntry = (entryData = null) => {
        this.setState({ ...this.state, open: false });
        if (entryData && entryData.v_duedate) {
            delete entryData.canEdit;
            entryData.v_duedate = moment(entryData.v_duedate).format('YYYY-MM-DD')
            entryData.v_user_id = sessionService.getUserId();
            this.props.updateEntry(entryData, this.getData());

        }
    }

    handleClose = () => {
        this.setState({ ...this.state, open: false });
    };

    openNewMeetingPopover = (event, operation) => {
        if (operation === 'create') {
            this.setState({ ...this.state, isMeetingEdit: false, canEdit: false, anchorEl: event.currentTarget });
            this.props.removeEntry();
        }
        else {
            this.setState({ ...this.state, isMeetingEdit: true, canEdit: true, anchorEl: event.currentTarget });
        }
    };

    closeNewMeetingPopover = () => {
        this.setState({ ...this.state, anchorEl: null });
    };
    validationSchema = yup.object({
        v_title: yup.string().required("Title is required"),
        v_description: yup.string().required("Discription is required"),
        v_start_time: yup.string().required("Start date is required"),
        v_start_date: yup.string().required("Start time is required"),
        v_end_time: yup.string().required("End date is required"),
        v_end_date: yup.string().required("End time is required")
    });
    render() {
        const { currentEntry, calendarEntries, isFetched, onPopup, classes, meetingOperations } = this.props;
        const open = Boolean(this.state.anchorEl);
        const id = open ? "simple-popover" : undefined;
        const userName = sessionService.getUserName();
        return (
            <div className='demo-app'>
                <div className='demo-app-main'>
                    {isFetched && <Box mb={2} mt={3}>
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth newMeetingButton'
                            }}
                            initialView={onPopup ? 'listMonth' : 'dayGridMonth'}
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={this.state.weekendsVisible}
                            customButtons={{
                                newMeetingButton: {
                                    text: 'New Meeting',
                                    click: (event) => this.openNewMeetingPopover(event, 'create')
                                },
                            }}
                            initialEvents={calendarEntries && calendarEntries.map(event => {
                                return {
                                    ...event,
                                    id: event.ENTRY_ID,
                                    allDay: event.ENTRY_TYPE === constants.MEETING ? false : true,
                                    start: moment(new Date(event.ENTRY_START_DATETIME)).format('YYYY-MM-DDTHH:mm:ss'),
                                    end: moment(new Date(event.ENTRY_END_DATETIME)).format('YYYY-MM-DDTHH:mm:ss'),
                                    backgroundColor: event.ENTRY_TYPE=== constants.MEETING ? 'lightskyblue':'white',
                                    borderColor: 'white'
                                }
                            })}
                            eventContent={renderEventContent}
                            eventClick={this.handleClickOpen}
                            className={classes.calendar}
                        />
                    </Box>}
                </div>
                <PopupOnCalendar open={this.state.open} updateCalendarEntry={this.updateCalendarEntry} currentEntry={currentEntry}></PopupOnCalendar>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.closeNewMeetingPopover}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}

                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            v_title: currentEntry.ENTRY_TITLE ? currentEntry.ENTRY_TITLE : "",
                            v_description: currentEntry.ENTRY_DESCRIPTION ? currentEntry.ENTRY_DESCRIPTION : "",
                            v_start_date: currentEntry.ENTRY_START_DATETIME ? currentEntry.ENTRY_START_DATETIME : "",
                            v_start_time: currentEntry.ENTRY_START_DATETIME ? currentEntry.ENTRY_START_DATETIME : "",
                            v_end_date: currentEntry.ENTRY_END_DATETIME ? currentEntry.ENTRY_END_DATETIME : "",
                            v_end_time: currentEntry.ENTRY_END_DATETIME ? currentEntry.ENTRY_END_DATETIME : "",
                            v_attendees: currentEntry.ENTRY_ATTENDEES ? currentEntry.ENTRY_ATTENDEES : "",
                            v_priority: currentEntry.PRIORITY ? currentEntry.PRIORITY : "",
                            IS_fullday: currentEntry.IS_fullday === 1 ? true : false,
                        }}
                        validationSchema={this.validationSchema}
                        onSubmit={(values) => {
                            values.v_start_time = new Date(values.v_start_time).toTimeString()
                            values.v_end_time = new Date(values.v_end_time).toTimeString()
                            values.v_start_date = moment(values.v_start_date).format('YYYY-MM-DD');
                            values.v_end_date = moment(values.v_end_date).format('YYYY-MM-DD');
                            values.v_user_id = sessionService.getUserId();
                            values.v_entry_type = '4';
                            if (this.state.isMeetingEdit) {
                                values.v_entry_id = currentEntry.ENTRY_ID;
                                values.operation = 'update';
                                meetingOperations(values, this.getData());

                            } else {
                                values.v_submission = onPopup ? this.props.submissionId : null;
                                values.operation = 'create';
                                meetingOperations(values, this.getData());
                            }

                            this.closeNewMeetingPopover();
                        }}
                    >
                        {({
                            values,
                            handleChange,
                            setFieldValue,
                            handleSubmit,
                            touched,
                            errors,
                        }) => (
                            <form autoComplete="off" onSubmit={handleSubmit} style={{ margin: '10px 20px' }}>
                                <Box>
                                    <Grid container style={{
                                        alignItems: "center",
                                    }}>
                                        <Grid item xs={2}  > <IconButton onClick={this.closeNewMeetingPopover} style={{ paddingLeft: '0px' }}> <CloseIcon /></IconButton></Grid>
                                        <Grid item xs={7}  >{this.state.isMeetingEdit ? langConstant.UPDATE_MEETING : langConstant.CREATE_NEW_MEETING}</Grid>
                                        <Grid item xs={3} style={{ textAlign: 'end' }}>{this.state.isMeetingEdit && <Button size='small' variant='outlined'
                                            color='primary' onClick={() => {
                                                this.setState({ ...this.state, canEdit: false }, () => {
                                                })
                                            }} startIcon={<EditIcon />} style={{ height: '20px' }}>
                                            Edit</Button>}</Grid>
                                    </Grid>
                                    <Typography style={{ marginTop: '15px' }}>
                                        {langConstant.TITLE}
                                    </Typography>

                                    <TextField
                                        name="v_title"
                                        variant="outlined"
                                        value={values.v_title}
                                        label=""
                                        inputProps={{ maxLength: 1000 }}
                                        onChange={handleChange}
                                        error={touched.v_title && Boolean(errors.v_title)}
                                        helperText={touched.v_title && errors.v_title}
                                        style={{ width: "400px" }}
                                        className={classes.input}
                                        disabled={this.state.canEdit}
                                    />
                                    <Typography style={{ marginTop: '15px' }}>
                                        {langConstant.DESCRIPTION}

                                    </Typography>
                                    <TextField
                                        multiline
                                        rows={2}
                                        name="v_description"
                                        variant="outlined"
                                        value={values.v_description}
                                        label=""
                                        inputProps={{ maxLength: 1000 }}
                                        onChange={handleChange}
                                        error={touched.v_description && Boolean(errors.v_description)}
                                        helperText={touched.v_description && errors.v_description}
                                        style={{ width: "400px" }}
                                        className={classes.input}
                                        disabled={this.state.canEdit}
                                    />
                                    <Grid container style={{ width: '400px' }}>
                                        <Grid item xs={6}  >
                                            <Typography style={{ marginTop: '15px' }}>
                                                {langConstant.START_DATE}
                                            </Typography>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    style={{ marginRight: '1em' }}
                                                    inputVariant="outlined"
                                                    variant="inline"
                                                    format="dd-MMM-yy"
                                                    autoOk
                                                    minDate={new Date()}
                                                    disablePast
                                                    value={values.v_start_date}
                                                    onChange={(val) => {
                                                        setFieldValue("v_start_date", val);
                                                    }}
                                                    InputProps={{ readOnly: true }}
                                                    className={classNames(classes.input, classes.dateInput)}
                                                    error={touched.v_start_date && Boolean(errors.v_start_date)}
                                                    helperText={touched.v_start_date && errors.v_start_date}
                                                    disabled={this.state.canEdit}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={6}  >
                                            <Typography style={{ margin: "15px  0px  0px  0px", marginLeft: '1em' }}>
                                                {langConstant.START_TIME}
                                            </Typography>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    style={{ marginLeft: '1em' }}
                                                    inputVariant="outlined"
                                                    variant="inline"
                                                    autoOk
                                                    disablePast
                                                    ampm={false}
                                                    id="time-picker"
                                                    value={values.v_start_time}
                                                    onChange={(val) => {
                                                        setFieldValue("v_start_time", val);
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                    className={classNames(classes.input, classes.dateInput)}
                                                    error={touched.v_start_time && Boolean(errors.v_start_time)}
                                                    helperText={touched.v_start_time && errors.v_start_time}
                                                    disabled={this.state.canEdit}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ width: '400px' }}>
                                        <Grid item xs={6}  >
                                            <Typography style={{ marginTop: '15px' }}>
                                                {langConstant.END_DATE}
                                            </Typography>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    style={{ marginRight: '1em' }}
                                                    inputVariant="outlined"
                                                    variant="inline"
                                                    format="dd-MMM-yy"
                                                    autoOk
                                                    minDate={values.v_start_date}
                                                    disablePast
                                                    value={values.v_end_date}
                                                    onChange={(val) => {
                                                        setFieldValue("v_end_date", val);
                                                    }}
                                                    InputProps={{ readOnly: true }}
                                                    className={classNames(classes.input, classes.dateInput)}
                                                    error={touched.v_end_date && Boolean(errors.v_end_date)}
                                                    helperText={touched.v_end_date && errors.v_end_date}
                                                    disabled={this.state.canEdit}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={6}  >
                                            <Typography style={{ margin: "15px  0px  0px  0px", marginLeft: '1em' }}>
                                                {langConstant.END_TIME}
                                            </Typography>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    style={{ marginLeft: '1em' }}
                                                    inputVariant="outlined"
                                                    variant="inline"
                                                    autoOk
                                                    disablePast
                                                    ampm={false}
                                                    id="time-picker"
                                                    value={values.v_end_time}
                                                    onChange={(val) => {
                                                        setFieldValue("v_end_time", val);
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                    className={classNames(classes.input, classes.dateInput)}
                                                    error={touched.v_end_time && Boolean(errors.v_end_time)}
                                                    helperText={touched.v_end_time && errors.v_end_time}
                                                    disabled={this.state.canEdit}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                    <FormControlLabel style={{ marginTop: '15px' }}
                                        control={<Checkbox name="IS_fullday" size='medium'
                                        checked={values.IS_fullday} disabled />} label={langConstant.ALL_DAY_EVENT} className={classes.label} />
                                    <Typography style={{ marginTop: '15px' }}>
                                        {langConstant.OWNER}
                                    </Typography>

                                    <TextField
                                        name="v_owner"
                                        variant="outlined"
                                        value={userName}
                                        label=""
                                        inputProps={{ maxLength: 1000 }}
                                        onChange={handleChange}
                                        error={touched.v_owner && Boolean(errors.v_owner)}
                                        helperText={touched.v_owner && errors.v_owner}
                                        style={{ width: "400px" }}
                                        className={classes.input}
                                        disabled
                                    />
                                    <Typography style={{ marginTop: '15px' }}>
                                        {langConstant.ATTENDEES}
                                    </Typography>
                                    <TextField
                                        multiline
                                        rows={2}
                                        name="v_attendees"
                                        variant="outlined"
                                        value={values.v_attendees}
                                        label=""
                                        inputProps={{ maxLength: 1000 }}
                                        onChange={handleChange}
                                        error={touched.v_attendees && Boolean(errors.v_attendees)}
                                        helperText={touched.v_attendees && errors.v_attendees}
                                        style={{ width: "400px" }}
                                        className={classes.input}
                                        disabled={this.state.canEdit}
                                    />
                                    <Typography style={{ marginTop: '15px' }}>
                                        {langConstant.SELECT_PRIORITY}

                                    </Typography>
                                    <TextField
                                        name="v_priority"
                                        value={values.v_priority}
                                        select
                                        label=""
                                        onChange={handleChange}
                                        variant="outlined"
                                        error={touched.v_priority && Boolean(errors.v_priority)}
                                        helpertext={touched.v_priority && errors.v_priority}
                                        style={{ width: "400px" }}
                                        className={classes.select}
                                        disabled={this.state.canEdit}
                                    >
                                        <MenuItem value={constants.HIGH}>High</MenuItem>
                                        <MenuItem value={constants.MEDIUM}>Medium</MenuItem>
                                        <MenuItem value={constants.LOW}>Low</MenuItem>
                                    </TextField>
                                </Box>

                                <Box>
                                    <DialogActions
                                        style={{
                                            display: "flex",
                                            justifyContent: "start",
                                            paddingLeft: '0',
                                            marginTop: "5px",
                                        }}
                                    >
                                        {this.state.isMeetingEdit ? (<Button size='small' type="submit" variant='contained' color='primary' startIcon={<SaveIcon />} >
                                            {langConstant.SAVE_CHANGES_BUTTON}
                                        </Button>) :
                                            (<Button size='small' type="submit" variant='contained' color='primary' startIcon={<GroupIcon />} >
                                                {langConstant.CREATE_BUTTON}
                                            </Button>)}
                                    </DialogActions>
                                </Box>
                            </form>
                        )}
                    </Formik>

                </Popover>
            </div>
        )

    }

}
CalendarView.propTypes = {
    calendarEntries: PropTypes.array,
    onPopup: PropTypes.bool,
    setSelectedEntry: PropTypes.any,
    updateEntry: PropTypes.any,
    meetingOperations: PropTypes.any,
    removeEntry: PropTypes.any,
    currentEntry: PropTypes.object,
    getCalendarEntries: PropTypes.any,
    entryType: PropTypes.any,
    classes: PropTypes.any,
    submissionId: PropTypes.any,
    isFetched: PropTypes.bool
};

const mapStateToProps = (state) => {
    const currentEntry = state.userCalendarEntries.selectedEntry;
    const calendarEntries = state.userCalendarEntries.calendarEntries;
    const isFetched = state.userCalendarEntries.isFetched
    return {
        currentEntry,
        calendarEntries,
        isFetched
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedEntry: (selectedEntry) => dispatch(calendarAction.setSelectedEntry(selectedEntry)),
        updateEntry: (entryData, data) => {
            dispatch(calendarAction.updateCalendar(entryData, data))
        },
        meetingOperations: (newMeetingData, data) => {
            dispatch(calendarAction.meetingOperation(newMeetingData, data));
        },
        getCalendarEntries: (data) => {
            dispatch({ type: constants.CALENDAR_ENTRIES_INITIATING })
            dispatch(calendarAction.getUserCalendarEntries(data)
            )
        },
        removeEntry: () => {
            dispatch({ type: constants.REMOVE_SELECTED_EVENT });
        }
    }
}
export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(CalendarView);



