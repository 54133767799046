import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import PropTypes from "prop-types";
import AdminUtils from "../../utils";
import { scrollToTop } from "_helpers/utils";
import langConstant from "_lang";
import Select from "react-select";
import OptionComponent from "containers/manage-regulation/NewRegulation/ManageQuestions/component/OptionComponent";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { useDispatch } from "react-redux";
import AdminServices from "../../services";
import RegulationEditPopUp from "./RegulationEditPopUp";

const Regulations = ({
  regulationsList,
  setRegulationsList,
  selectedCustomer,
  selectedRegulator,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedRegulation, setSelectedRegulation] = useState(null);
  const [filteredRegulations, setFilteredRegulations] =
    useState(regulationsList);
  const [regulationPopUp, setRegulationPopUp] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (regulationsList && regulationsList.length > 0) {
      let tempData = [...regulationsList];
      if (selectedRegulator && selectedRegulator.REGULATOR_ID) {
        tempData = tempData.filter(
          (regulation) =>
            regulation.REGULATOR_ID === selectedRegulator.REGULATOR_ID
        );
      }
      setPageCount(calculatePageCount(tempData));
      setFilteredRegulations(tempData);
    }
  }, [regulationsList, selectedRegulator]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredRegulations?.length > 0
        ? Math.round(Math.ceil(filteredRegulations.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const updateRegulationMapping = () => {
    dispatch(
      AdminServices.updateCustomerRegulationMapping(
        selectedRegulation,
        selectedCustomer.value,
        regulationsList,
        setRegulationsList
      )
    );
  };

  return (
    <Grid
      className={classes.customerFormWrapper}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: " 10px",
      }}
    >
      <Grid
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <Typography style={{ marginRight: "10px", fontWeight: "bold" }}>
          {langConstant.REGULATION}
        </Typography>
      </Grid>
      <TableContainer component={Paper}>
        <Table className="data-table" aria-label="Regulator List">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {AdminUtils.regulationsColumns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRegulations &&
              (rowsPerPage > 0
                ? filteredRegulations.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRegulations
              ).map((row, index) => {
                return (
                  <TableRow
                    key={index + row[AdminUtils.regulationsColumns[0].field]}
                  >
                    <TableCell>
                      <Checkbox
                        checked={
                          row.REGULATION_ID == selectedRegulation?.REGULATION_ID
                        }
                        onClick={() => {
                          setSelectedRegulation(row);
                          setRegulationPopUp(true);
                        }}
                      />
                    </TableCell>
                    {AdminUtils.regulationsColumns.map((column, index) => {
                      const value = row[column.field];
                      return (
                        <TableCell
                          component="td"
                          scope="row"
                          key={
                            "column_" +
                            index +
                            row[AdminUtils.regulationsColumns[0].field]
                          }
                          style={{
                            wordBreak: "break-word",
                            wordWrap: "break-word",
                          }}
                        >
                          {column.format ? column.format(row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {filteredRegulations?.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={AdminUtils.regulationsColumns.length + 1}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid container justify="flex-end">
          <SelectPageRange
            selectedRange={rowsPerPage}
            handleChange={handleSelectPageRange}
          />
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Box>
      {regulationPopUp && selectedRegulation && (
        <RegulationEditPopUp
          show={regulationPopUp}
          setShow={setRegulationPopUp}
          selectedRegulation={selectedRegulation}
          setSelectedRegulation={setSelectedRegulation}
          updateRegulationMapping={updateRegulationMapping}
        />
      )}
    </Grid>
  );
};

Regulations.propTypes = {
  regulationsList: PropTypes.oneOfType([() => null, PropTypes.array]),
  setRegulationsList: PropTypes.func,
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
  selectedRegulator: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default Regulations;
