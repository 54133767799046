import React from 'react';
import { components } from "react-select";
import PropTypes from "prop-types";
import useStyles from '../styles';


const OptionComponent = (props) => {

    const classes = useStyles()

    const giveColorByScore = (score) => {
        let scoreData = parseFloat(score)
        if(scoreData >= 0.6){
            return {backgroundColor: "green", fontWeight: "bold"}
        } else if (scoreData < 0.6 && scoreData >= 0.4){
            return {backgroundColor: "#ffff0078", color: "black", fontWeight: "bold"}
        } else {
            return {backgroundColor: "red", fontWeight: "bold"}
        }
    }

    return (
        <div>
            <components.Option {...props} className={classes.optionsStyle}>
                <label className={classes.labelStyle2}>{props.label}</label>
                <label className={classes.labelStyle}>{props.data.recordCount}</label>
                <label className={classes.labelScoreStyle}><span style={giveColorByScore(props.data.score)}>{props.data.score}</span></label>
                <input
                    className={classes.checkboxStyle}
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    style={{accentColor: "#124571"}}
                />
            </components.Option>
        </div>
    );
};

OptionComponent.propTypes = {
    label: PropTypes.string,
    isSelected: PropTypes.bool,
    data: PropTypes.object,
    recordCount: PropTypes.number
}

export default OptionComponent;