/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import constant from "./constants";
const initialState = {
  questionDetails: {},
  guidanceTypes: [],
  questionResponses: [],
  regulationDetail: {},
  questionControls: {},
  questionDatapoints: {},
  questionEvidenceList: [],
  questionEvidenceTypes: [],
  questionRuleLogic: "",
  narrative: "",
  responseTypes: [],
};
export default function manageIndividualQuestion(state = initialState, action) {
  switch (action.type) {
    case constant.GET_RESPONSE_TYPE_SUCCESS:
      return {
        ...state,
        responseTypes: action.data,
      };
    case constant.GET_RESPONSE_TYPE_FAILURE:
      return {
        ...state,
        responseTypes: [],
      };
    case constant.individualQuestionDetails_GET_SUCCESS:
      return {
        ...state,
        questionDetails: action.data,
      };
    case constant.individualQuestionDetails_GET_FAILURE:
      return {
        ...state,
        questionDetails: {},
      };
    case constant.guidanceTypes_GET_SUCCESS:
      return {
        ...state,
        guidanceTypes: action.data,
      };
    case constant.guidanceTypes_GET_FAILURE:
      return {
        ...state,
        guidanceTypes: [],
      };
    case constant.individualQuestionResponses_GET_SUCCESS:
      return {
        ...state,
        questionResponses: action.data,
      };
    case constant.individualQuestionResponses_GET_FAILURE:
      return {
        ...state,
        questionResponses: [],
      };
    case constant.manageQuestionsConstant_UPDATE:
      return action.data;
    case constant.manageQuestionsConstant_FAILURE:
      return state;
    case constant.individualQuestionMapping_GET_SUCCESS:
      return {
        ...state,
        questionControls: action.data["#result-set-1"],
        questionDatapoints: action.data["#result-set-2"],
        narrative: action.data["#result-set-3"][0].NARRATIVE_TEMPLATE,
      };
    case constant.individualQuestionEvidenceList_GET_SUCCESS:
      return {
        ...state,
        questionEvidenceList: action.data,
      };
    case constant.individualQuestionEvidenceTypes_GET_SUCCESS:
      return {
        ...state,
        questionEvidenceTypes: action.data,
      };
    case constant.individualQuestionRules_GET_SUCCESS:
      return {
        ...state,
        questionRuleLogic: action.data,
      };
    case constant.individualQuestionRules_GET_FAILURE:
      return {
        ...state,
        questionRuleLogic: "",
      };
    case constant.individualQuestionDetails_ACTIVE_STATUS:
      return {
        ...state,
        questionDetails: {
          ...state.questionDetails,
          ACTIVE: action.data,
        },
      };
    default:
      return state;
  }
}
