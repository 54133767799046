/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import AppDataTable from "./components/AppDataTable";
import SupplierServices from "./services";
import sessionService from "_services/session.service";

const ManageSuppliers = () => {
  const columns = [
    { field: "SUPPLIER_ID", headerName: "SUPPLIER ID", minWidth: 130 },
    { field: "SUPPLIER_NAME", headerName: "SUPPLIER NAME", minWidth: 150 },

    {
      field: "SUPPLIER_RISK_RATING",
      headerName: "SUPPLIER RISK RATING",
      minWidth: 120,
    },
    {
      field: "SUPPLIER_PRODUCT_SERVICE_PROVIDED",
      headerName: "PRODUCT SERVICE PROVIDED",
      minWidth: 120,
    },

    {
      field: "SUPPLIER_CATEGORY",
      headerName: "SUPPLIER CATEGORY",
      minWidth: 200,
    },

    {
      field: "SUPPLIER_CONTRACT_DURATION",
      headerName: "CONTRACT DURATION",
      minWidth: 115,
      sticky: true,
    },
    {
      field: "ACTIVE",
      headerName: "STATUS",
      minWidth: 100,
      sticky: true,
    },
    {
      field: "ACTIONS",
      headerName: "ACTIONS",
      minWidth: 100,
      sticky: true,
    },
  ];
  const dispatch = useDispatch();
  const [supplierList, setSupplierList] = useState([]);

  const userID = sessionService.getUserId();
  useEffect(() => {
    dispatch(
      SupplierServices.getSuppliersAndSpocsList(userID, setSupplierList)
    );
  }, []);
  return (
    <Box>
      {supplierList && (
        <AppDataTable
          rows={supplierList}
          setSupplierList={setSupplierList}
          columns={columns}
          className="data-table"
        />
      )}
    </Box>
  );
};

export default ManageSuppliers;
