import {
  Button,
  Box,
  Grid,
  Paper,
  Table,
  Dialog,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Form, Formik, FieldArray } from "formik";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useModal } from "_hooks";
import langConstant from "_lang";
import { useDispatch } from "react-redux";
import { UseModalPropType } from "_hooks";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import CreateMessage from "./CreateMessageDialog";
import Confirmation from "./ConfirmationDialog";

const useStyles = makeStyles(() => ({
  title: { display: "flex", alignItems: "center", padding: ".75rem" },
  error: { color: "red", fontSize: "12px" },

  wideColumn: {
    width: "50%",
  },
  normalColumn: {
    width: "40%",
  },
  regColumn: {
    width: "20%",
  },
}));
const UserListDialog = ({ modal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const parsedCustomerList = modal.data.users ? modal.data.users : [];

  const createMessageModal = useModal();
  const confirmationModal = useModal();

  const calculatePageCount = (rows) => {
    return rows?.length > 0
      ? Math.round(Math.ceil(rows?.length / rowsPerPage))
      : 0;
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      parsedCustomerList.length > 0
        ? Math.round(Math.ceil(parsedCustomerList.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = (values) => {};

  useEffect(() => {
    setPageCount(calculatePageCount(parsedCustomerList));
  }, [parsedCustomerList]);

  return (
    <React.Fragment>
      <Dialog
        open={modal?.isOpen}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
        disableEnforceFocus
        keepMounted
        onClose={modal?.closeModal}
      >
        <DialogTitle className={classes.title}>
          <IconButton
            style={{ position: "absolute", right: 4 }}
            aria-label="close"
            onClick={modal?.closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ rows: parsedCustomerList }}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({ values, errors, setFieldValue, handleChange }) => (
              <Form>
                <Grid>
                  <Typography style={{ flex: 1 }} variant="h6" align="center">
                    {langConstant.USERLIST}
                  </Typography>
                </Grid>

                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>{langConstant.NAME}</TableCell>
                        <TableCell>{langConstant.EMAIL}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <FieldArray name="rows">
                        {({ insert, remove, push }) => (
                          <>
                            {values.rows.length > 0 &&
                              (rowsPerPage > 0
                                ? values.rows.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : values.rows
                              ).map((row, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    className={classes.wideColumn}
                                    style={{
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <TextField
                                      className={classes.textField}
                                      name={`rows.${index}.FIRST_NAME`}
                                      size="small"
                                      variant="outlined"
                                      disabled
                                      fullWidth
                                      value={`${row.FIRST_NAME} ${row.LAST_NAME}`}
                                      onChange={(e) => {
                                        if (
                                          e.target.value.match(
                                            /^[a-zA-Z0-9\s.'"]*$/
                                          )
                                        ) {
                                          handleChange(e);
                                        }
                                      }}
                                      onPaste={(e) => e.preventDefault()}
                                      inputProps={{
                                        style: {
                                          whiteSpace: "normal",
                                          wordBreak: "break-all",
                                        },
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <TextField
                                      className={classes.textField}
                                      name={`rows.${index}.EMAIL_ADDRESS`}
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled
                                      value={row.EMAIL_ADDRESS}
                                      onChange={(e) => {
                                        if (
                                          e.target.value.match(
                                            /^[a-zA-Z0-9\s.'"]*$/
                                          )
                                        ) {
                                          handleChange(e);
                                        }
                                      }}
                                      onPaste={(e) => e.preventDefault()}
                                      inputProps={{
                                        style: {
                                          whiteSpace: "normal",
                                          wordBreak: "break-all",
                                        },
                                      }}
                                      multiline
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                          </>
                        )}
                      </FieldArray>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Form>
            )}
          </Formik>
          <Box my={2}>
            <Grid
              container
              style={{ justifyContent: "end" }}
              justify="flex-end"
            >
              {parsedCustomerList?.length > 0 && (
                <SelectPageRange
                  selectedRange={rowsPerPage}
                  handleChange={handleSelectPageRange}
                />
              )}
              {pageCount > 0 && (
                <AppPagination
                  colSpan={3}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      {createMessageModal?.isOpen && (
        <CreateMessage modal={createMessageModal} />
      )}
      {confirmationModal?.isOpen && <Confirmation modal={confirmationModal} />}
    </React.Fragment>
  );
};

UserListDialog.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default UserListDialog;
