import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import React from "react";
import searchData from "./index.jsx";

const GetOnLineHelpTopic = (
  customerID,
  setContent,
  setType,
  setFilteredContent,
  setActiveGroup,
  calculatePageCount,
  setPageCount
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetOnLineHelpTopic", {
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let uniqueHelpGroup = {};
          const sortedItems = [];
          let firstGroup = "";
          let uniqueType = [];

          if (response && response["#result-set-2"]) {
            response["#result-set-2"].forEach((item) => {
              const type = item.ONLINE_HELP_TYPE.toString()
                .toLowerCase()
                .trim();

              if (!uniqueType.includes(type)) {
                uniqueType.push(type);
              }
            });
            setType(
              uniqueType.map((option) => ({
                value: option,
                label: option,
              }))
            );
          }

          if (response && response["#result-set-1"]) {
            response["#result-set-1"].forEach((item) => {
              const helpGrouping = item.HELP_GROUPING.toString()
                .toLowerCase()
                .trim();
              if (item.ACTIVE == 1) {
                if (!uniqueHelpGroup[helpGrouping]) {
                  uniqueHelpGroup[helpGrouping] = [];
                }
                uniqueHelpGroup[helpGrouping].push(item);
              }
            });
            const sortedKeys = Object.keys(uniqueHelpGroup).sort();

            sortedKeys.forEach((key) => {
              sortedItems[key] = [];
              sortedItems[key].push(...uniqueHelpGroup[key]);
              if (firstGroup.length == 0) firstGroup = key;
            });
            uniqueHelpGroup = sortedItems;
            setPageCount(calculatePageCount(uniqueHelpGroup[firstGroup]));
            setContent(uniqueHelpGroup);
            setFilteredContent(uniqueHelpGroup);
            setActiveGroup(firstGroup);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const DeleteOnlineHelpItem = (customerID, data, Action_Type) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateOnlineHelpItem", {
        i_ID: data.ID,
        i_CUSTOMER_ID: customerID,
        i_HELP_GROUPING: data.TOPIC_GROUP.toString(),
        i_TOPIC: data.TOPIC_NAME.toString(),
        i_TOPIC_DETAILS: data.TOPIC_CONTENT.toString(),
        i_TOPIC_DETAILS_URL: data.TOPIC_URL ? data.TOPIC_URL : "",
        i_ONLINE_HELP_TYPE: data.TYPE.toString(),
        i_ACTIVE: Action_Type,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {},
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const UpdateOnlineHelpItem = (
  customerID,
  values,
  selectedTopic,
  Action_Type,
  handleClose,
  content,
  setContent,
  setFilteredContent,
  searchInput,
  searchData
) => {
  return (dispatch) => {
    const activeGroup = values.TOPIC_GROUP.toString().toLowerCase().trim();
    let tempContent = { ...content };

    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateOnlineHelpItem", {
        i_ID: values.ID,
        i_CUSTOMER_ID: customerID,
        i_HELP_GROUPING: values.TOPIC_GROUP.toString(),
        i_TOPIC: values.TOPIC_NAME.toString(),
        i_TOPIC_DETAILS: values.TOPIC_CONTENT.toString(),
        i_TOPIC_DETAILS_URL: selectedTopic?.TOPIC_URL
          ? selectedTopic?.TOPIC_URL
          : values.TYPE.toString().toLowerCase().trim() == "text"
          ? ""
          : values?.document?.name,
        i_ONLINE_HELP_TYPE: values.TYPE.toString(),
        i_ACTIVE: Action_Type,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (!tempContent[activeGroup]) {
            tempContent[activeGroup] = [];
          }

          if (response && response["#result-set-1"]) {
            const updatedTopic = {
              ACTIVE: 1,
              HELP_GROUPING: values.TOPIC_GROUP,
              ID: response["#result-set-1"][0]["ID"],
              ONLINE_HELP_TYPE: values.TYPE,
              TOPIC: values.TOPIC_NAME,
              TOPIC_DETAILS: values.TOPIC_CONTENT,
              TOPIC_DETAILS_URL:
                values.TYPE.toString().toLowerCase().trim() == "text"
                  ? ""
                  : values?.document?.name,
            };

            tempContent[activeGroup].push(updatedTopic);
            setContent(tempContent);
            setFilteredContent(tempContent);
          } else {
            if (
              selectedTopic &&
              selectedTopic.TOPIC_GROUP &&
              activeGroup != selectedTopic.TOPIC_GROUP
            ) {
              const index = tempContent[selectedTopic.TOPIC_GROUP].findIndex(
                (topic) => topic.ID == selectedTopic.ID
              );

              if (index > -1) {
                tempContent[selectedTopic.TOPIC_GROUP].splice(index, 1);
              }
            }

            const updatedTopic = {
              ACTIVE: 1,
              HELP_GROUPING: values.TOPIC_GROUP,
              ID: values.ID,
              ONLINE_HELP_TYPE: values.TYPE,
              TOPIC: values.TOPIC_NAME,
              TOPIC_DETAILS: values.TOPIC_CONTENT,
              TOPIC_DETAILS_URL: selectedTopic?.TOPIC_URL
                ? selectedTopic?.TOPIC_URL
                : values.TYPE.toString().toLowerCase().trim() == "text"
                ? ""
                : values?.document?.name,
            };

            const index = tempContent[activeGroup].findIndex(
              (topic) => topic.ID == selectedTopic.ID
            );
            if (index > -1) {
              tempContent[activeGroup][index] = updatedTopic;
            } else {
              tempContent[activeGroup].push(updatedTopic);
            }

            // setContent(tempContent);
            // setFilteredContent(tempContent);
          }
          setContent(tempContent);
          setFilteredContent(tempContent);
          searchData(searchInput);
          handleClose();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const GetCustomerSanctionKeywords = (customerID, setSanctionKeywords) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerSanctionKeywords", {
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let uniqueKeyWords = [];
          response["#result-set-1"].forEach((item) => {
            const keyword = item.KEYWORD.toString().toLowerCase().trim();

            if (item.ACTIVE == 1) {
              if (!uniqueKeyWords.includes(keyword)) {
                uniqueKeyWords.push(keyword);
              }
            }
          });
          setSanctionKeywords(uniqueKeyWords);
        },
        (error) => {
          setSanctionKeywords([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const UploadFileOnS3Bucket = async (payload) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/upload/fileUploadOnS3", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error?.response) {
          return error?.response;
        }
      }
    );
};

export default {
  GetOnLineHelpTopic,
  DeleteOnlineHelpItem,
  UpdateOnlineHelpItem,
  GetCustomerSanctionKeywords,
  UploadFileOnS3Bucket,
};
