import { constants } from "../_constants/calendar.constants";
const initSate = { calendarEntries: [], selectedEntry: {}, isFetched: false }
export default function userCalendarEntries(state = initSate, action) {
  switch (action.type) {
    case constants.CALENDAR_ENTRIES_INITIATING: 
    return { ...state, calendarEntries: [], isFetched: false}
    case constants.SET_SELECTED_EVENT:
      return { ...state, selectedEntry: action.selectedEntry };
      case constants.REMOVE_SELECTED_EVENT:
        return { ...state, selectedEntry: {} };
    case constants.CALENDAR_ENTRIES_SUCCESS:
      return { ...state, calendarEntries: action.data, isFetched: true};
    case constants.CALENDAR_ENTRIES_FAILURE:
      return  { ...state, isFetched: false};
    default:
      return state;
  }
}