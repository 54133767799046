import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Chip, Divider, Tooltip } from "@mui/material";
import { getFileExtension } from "./EditableFilenameRow";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import { Item, Row, innerElementType } from "./DraggableRow";
import { useWindowResize } from "./userWindowResize";
import { Droppable } from "react-beautiful-dnd";

function FileTableRow(props) {
  const { row, setFilteredFiles, provided, snapshot } = props;
  const listRef = React.useRef();
  const sizeMap = React.useRef({});
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = { ...sizeMap?.current, [index]: size };
    listRef.current?.resetAfterIndex?.(index);
  }, []);
  const getSize = (index) => sizeMap?.current?.[index] || 50;
  const [windowWidth] = useWindowResize();

  const [open, setOpen] = React.useState(false);

  const ExtensionBadge = getFileExtension(row?.name) && (
    <Chip
      sx={{
        textTransform: "uppercase",
        color: "#d32f2f",
        fontWeight: "600",
        px: 1,
      }}
      label={getFileExtension(row?.name)}
      size="small"
      component="span"
    />
  );

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        ref={provided.innerRef}
        {...provided.droppableProps}
        style={{ background: snapshot.isDraggingOver ? "lightblue" : "white" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.id}
        </TableCell>
        <TableCell>
          {row?.document_name ? row?.document_name : row?.POLICY_NAME}{" "}
          {ExtensionBadge}
        </TableCell>
        <TableCell>0</TableCell>
        <TableCell>0</TableCell>
        <TableCell>0</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Box mt={1} py={1} display="flex" gap={1}>
                <Typography
                  flex="1"
                  variant="caption"
                  color="GrayText"
                  fontSize=".75rem"
                >
                  Original Filename -{" "}
                  <strong>{row?.name ? row?.name : row?.POLICY_NAME}</strong>
                </Typography>
                <Divider flexItem orientation="vertical" />
                <Typography
                  flex="1"
                  variant="caption"
                  color="GrayText"
                  fontSize=".75rem"
                >
                  User - <strong>user@admin</strong>
                </Typography>
              </Box>
              {row?.children?.length > 0 && (
                <Box sx={{ width: "100%", height: "100px" }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <VariableSizeList
                        ref={listRef}
                        height={height}
                        itemCount={row?.children?.length}
                        itemSize={getSize}
                        width={width}
                        innerElementType={innerElementType}
                        itemData={{
                          items: row?.children,
                          setFilteredFiles: setFilteredFiles,
                          setSize: setSize,
                          windowWidth: windowWidth,
                          file: row,
                        }}
                      >
                        {Row}
                      </VariableSizeList>
                    )}
                  </AutoSizer>
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

FileTableRow.propTypes = {
  row: PropTypes.object,
  setFilteredFiles: PropTypes.func,
  provided: PropTypes.any,
  snapshot: PropTypes.any,
};

const FileTable = ({ files, setFilteredFiles, requiredFiles }) => {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ fontWeight: "bold" }}>No.</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Document Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Size</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Docs Mapped</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Recommendations</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((row, index) => (
            <Droppable
              key={row?.uuid}
              droppableId={row.uuid}
              renderClone={(provided, snapshot, rubric) => {
                return (
                  <Item
                    provided={provided}
                    isDragging={snapshot.isDragging}
                    item={requiredFiles?.find(
                      (item) => item?.uuid === rubric.draggableId
                    )}
                  />
                );
              }}
            >
              {(provided, snapshot) => (
                <FileTableRow
                  key={row.uuid}
                  row={Object.assign(row, { id: `${index + 1}.` })}
                  setFilteredFiles={setFilteredFiles}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </Droppable>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

FileTable.propTypes = {
  files: PropTypes.array,
  setFilteredFiles: PropTypes.any,
  requiredFiles: PropTypes.array,
};

export default FileTable;
