/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";

const NotificationElement = ({ notification }) => {
  let notificationTime = moment(notification.CREATE_DATE).format(
    "DD-MMM-YY h:mm:ss a"
  );

  let notificationData;

  try {
    notificationData = JSON.parse(notification.NOTIFICATION_MESSAGE);
  } catch (error) {
    notificationData = notification.NOTIFICATION_MESSAGE;
  }

  let pastTime = moment(notificationTime, "DD-MMM-YY h:mm:ss a").fromNow();

  function displayAvatar(avatarName) {
    switch (avatarName) {
      case "QUESTION": {
        return (
          <Avatar
            alt={notification.NOTIFICATION_ICON}
            src={"data:image/png;base64," + notification.NOTIFICATION_ICON}
            style={{ marginRight: "5px" }}
          ></Avatar>
        );
      }
      case "ASSESSMENT": {
        return (
          <Avatar
            style={{
              marginRight: "5px",
              color: "#03B220",
              backgroundColor: "#9CF1AA",
            }}
          >
            <AssignmentIcon />
          </Avatar>
        );
      }
      case "RFI": {
        return (
          <Avatar
            style={{
              marginRight: "5px",
              color: "#005E7A",
              backgroundColor: "rgba(18, 186, 233, 0.47)",
            }}
          >
            <MailOutlineIcon />
          </Avatar>
        );
      }
      case "USER": {
        return (
          <Avatar
            style={{
              marginRight: "5px",
              color: "#BC0017",
              backgroundColor: "rgba(240, 20, 47, 0.32)",
            }}
          >
            <PersonOutlineOutlinedIcon />
          </Avatar>
        );
      }
      case "POLICY": {
        return (
          <Avatar
            style={{
              marginRight: "5px",
              color: "#BC0017",
              backgroundColor: "rgba(240, 20, 47, 0.32)",
            }}
          >
            <PolicyOutlinedIcon />
          </Avatar>
        );
      }
      case "REGULATION": {
        return (
          <Avatar
            style={{
              marginRight: "5px",
              color: "#BC0017",
              backgroundColor: "rgba(240, 20, 47, 0.32)",
            }}
          >
            <AutoStoriesOutlinedIcon />
          </Avatar>
        );
      }
      case "SERVICE": {
        return (
          <Avatar
            style={{
              marginRight: "5px",
              color: "#6783c7",
              backgroundColor: "#b4c4ed",
            }}
          >
            <BuildCircleOutlinedIcon />
          </Avatar>
        );
      }
      case "SUPPORT": {
        return (
          <Avatar
            style={{
              marginRight: "5px",
              color: "#92d19b",
              backgroundColor: "#c3e8c8",
            }}
          >
            <SupportOutlinedIcon />
          </Avatar>
        );
      }
    }
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "15px 10px 15px 10px",
        overflow: "hidden",
        borderBottom: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {displayAvatar(notification.NOTIFICATION_GROUP)}
        <Box>
          <Typography variant="body1" style={{ color: "#444" }}>
            {/* {notification.NotificationHeading} */}
            {notificationData["title"]}
          </Typography>

          <Typography
            variant="body2"
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "12px",
              color: notification.READ_NOTIFICATION == "N" ? "#444" : "#888",
              fontWeight:
                notification.READ_NOTIFICATION == "N" ? "bold" : "normal",
              whiteSpace: "nowrap",
              width: "250px",
              overflow: "hidden",
            }}
          >
            {/* {notification.NOTIFICATION_MESSAGE} */}
            {notificationData["Notificaton Message"]}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="body1"
          style={{
            color: "#888",
            fontSize: "10px",
            width: "65px",
            textAlign: "right",
          }}
        >
          {pastTime && pastTime.replace("minutes", "mins")}
        </Typography>
      </Box>
    </Box>
  );
};

NotificationElement.propTypes = {
  notification: PropTypes.object,
};

export default NotificationElement;
