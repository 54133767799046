import React from "react";
import propTypes from "prop-types";
import { Popover, Typography, Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TabMenu } from "./TabMenu";

export const Popup = ({ isOpen, onClose, anchorEl, taskList }) => {
  const history = useHistory();

  const onViewTask = () => {
    history.push("/audit-actions");
    onClose?.();
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box p={2}>
          <Typography
            variant="subtitle2"
            style={{
              color: "#333",
            }}
          >
            MY TASK LIST
          </Typography>
        </Box>
        <Box p={0} m={0} width="26rem">
          <TabMenu taskList={taskList} />
        </Box>
        <Button
          style={{ height: "30px", margin: ".5rem" }}
          color="primary"
          onClick={onViewTask}
        >
          See all tasks
        </Button>
      </Box>
    </Popover>
  );
};

Popup.propTypes = {
  isOpen: propTypes.bool,
  onClose: propTypes.func,
  anchorEl: propTypes.object,
  taskList: propTypes.array,
};
