import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core'
import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import sessionService from '_services/session.service';
import evidenceAction from "../actions"
import RemarkPill from './RemarkPill';
import langConstant from "_lang";
import { alertActions, loadingActions } from "../../../_actions";
import CloseIcon from "@material-ui/icons/Close";
import moment from 'moment'
import { useSelector } from "react-redux";

const RemarkPopUp = ({show, setShow, selectedEvidence}) => {
    const dispatch = useDispatch();
    const [remarks, setRemarks] = useState([])
    const [remarkText, setRemarkText] = useState('')
    const [remarkRequired, setRemarkRequired] = useState(null)

    const user_ID = sessionService.getUserId();
    const user_Name = sessionService.getUserName();
    let userAvatar = useSelector((state) => state.authentication.detailedInfo?.AVATAR_IMAGE);
    userAvatar = userAvatar.replace("data:image/png;base64,","")

    useEffect(()=> {
        (async() => {
            const remarksData = await evidenceAction.getEvidenceComments(selectedEvidence['SUBMISSION_ID'], selectedEvidence['QUESTION_ID'], selectedEvidence['EVIDENCE_LOG_ID'])
            setRemarks([...remarksData])
            let dialogContent = document.getElementById("remarkContent");
            dialogContent.scrollTop = dialogContent.scrollHeight
        })();
    },[selectedEvidence])

    const handleClose = () => setShow(false)

    const sendRemark = async () => {
        if(remarkText)
        {
            dispatch(loadingActions.start());
            const updateComment = await evidenceAction.updateEvidenceComment(selectedEvidence['SUBMISSION_ID'], selectedEvidence['QUESTION_ID'], selectedEvidence['EVIDENCE_LOG_ID'], user_ID, remarkText)
            dispatch(loadingActions.end());
            if(updateComment['#update-count-1']){
                const currentDate = moment().format("YYYY-MM-DD HH:mm:ss")
                const remark = {
                    SUBMISSION_ID: selectedEvidence['SUBMISSION_ID'], 
                    QUESTION_ID: selectedEvidence['QUESTION_ID'],
                    COMMENT: remarkText, 
                    NAME: user_Name, 
                    USER_ID: parseInt(user_ID), 
                    AVATAR_IMAGE: window.btoa(userAvatar), 
                    CREATE_DATE: currentDate,
                }
                setRemarks((prevState)=>[...prevState, remark])
                setRemarkText('')
                dispatch(
                    alertActions.success(
                        langConstant.REMARK_ADDED
                    )
                );
                let dialogContent = document.getElementById("remarkContent");
                dialogContent.scrollTop = dialogContent.scrollHeight
            }
        }
    }

    const updateRemarkText = (value) => {
        setRemarkText(value)
        if(value){
            setRemarkRequired(null)
        } else {
            setRemarkRequired(langConstant.INPUT_REQUIRED)
        }
    }

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth={true}
            open={show}
            onClose={handleClose}
        >
            <DialogTitle style={{textAlign:"center",fontWeight:"400 !important",}} onClose={handleClose}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid alignItems="center" style={{width:"calc(100% - 48px)",textAlign:"center",paddingRight:"10px",paddingLeft:"48px",}}>
                        {langConstant.REMARKS_HISTORY}
                    </Grid>
                    <Grid alignItems="center">
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent
                style={{maxHeight:"400px"}}
                id="remarkContent"
            >
                <Grid>
                    {remarks.map((item, index) => {
                        return <RemarkPill key={index} remarkData={item} userID={user_ID}/>
                    })}
                </Grid>
            </DialogContent>
            <DialogActions style={{padding:"12px",width:"100%",}}>
                <div style={{padding:"8px 15px",backgroundColor:"#f8fcfd",border:"1px solid #dddddd",borderRadius:"7px",width:"100%",}}>
                <input type='text' placeholder="Enter Your Remark" value={remarkText} onChange={(e)=>updateRemarkText(e.target.value)} style={{padding:"7px 10px",height:"32px",borderRadius:"5px",border:"1px solid #bfbfbf",width:"calc(100% - 85px)",}} />
                <Button variant="contained" color="primary" onClick={() => sendRemark()} style={{textTransform:"capitalize",fontWeight:"normal",marginLeft:"12px",}}>
                    Post It
                </Button>
                {remarkRequired && 
                    <Grid>
                        <span>{remarkRequired}</span>
                    </Grid>
                }
                </div>
            </DialogActions>
        </Dialog>
    )
}

RemarkPopUp.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    selectedEvidence: PropTypes.object,
};

export default RemarkPopUp;