import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  DialogActions,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState } from "react";
import propTypes from "prop-types";
import { Close, Save } from "@material-ui/icons";
import { commonDialogStyles } from "_components/ActionList/styles";
import rootStyles from "rootStyles";
import { useDispatch } from "react-redux";
import { UpdateAction } from "../../services";
import { ACTION_STATUS_LIST } from "../../constants";

export const SetStatusDialog = ({ modal, action, submitCallback }) => {
  const dispatch = useDispatch();
  const dialogStyles = commonDialogStyles();
  const rootClasses = rootStyles();
  const [status, setStatus] = useState(
    ACTION_STATUS_LIST?.find((i) => i?.value == action?.ACTIONS_STATUS)
      ?.value || ACTION_STATUS_LIST[0].value
  );

  const onSubmit = () => {
    if (!status || !action?.ACTIONS_ID) return;
    const update = { i_ACTIONS_STATUS: status };
    dispatch(
      UpdateAction(
        update,
        action?.ACTIONS_ID,
        action?.ACTIONS_LIST_ID,
        submitCallback
      )
    );
    setTimeout(() => {
      modal?.closeModal();
    }, 500);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <DialogTitle disableTypography className={dialogStyles.title}>
        <Typography style={{ flex: 1 }} variant="h6" align="center">
          SET STATUS
        </Typography>
        <IconButton
          style={{ position: "absolute", right: 4 }}
          aria-label="close"
          onClick={modal?.closeModal}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ flex: 1 }} variant="body1" align="center">
          Select status to be assigned
        </Typography>
        <Box marginY="1rem">
          <RadioGroup
            style={{ display: "flex", justifyContent: "center" }}
            onChange={(e) => setStatus(e.target.value)}
            // onBlur={handleBlur}
            value={status}
            row
            name="priority"
          >
            {ACTION_STATUS_LIST.map((item) => (
              <FormControlLabel
                key={item.value}
                control={
                  <Radio
                    className={rootClasses.greenCheckbox}
                    color="default"
                    size="small"
                  />
                }
                value={item.value}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </Box>
      </DialogContent>
      <DialogActions style={{ alignSelf: "center", padding: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Save />}
          onClick={onSubmit}
          disabled={action?.ACTIONS_STATUS === status}
        >
          Save
        </Button>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={modal?.closeModal}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SetStatusDialog.propTypes = {
  modal: propTypes.object,
  action: propTypes.object,
  submitCallback: propTypes.func,
};
