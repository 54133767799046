import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import useStyles from "./styles";
import classNames from "classnames";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import langConstant from "_lang";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";

const GroupingData = ({
  filteredRows,
  filters,
  setGroupPageCount,
  setGroupPage,
  groupPage,
  groupRowsPerPage,
  calculateGroupPageCount,
  groupHeaders,
  setGroupHeaders,
}) => {
  // const classes = useStyles();
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const { currentView } = filters;
  const [groupingData, setGroupingData] = useState({});

  useEffect(() => {
    const groupArrayObject = filteredRows.reduce((group, arr) => {
      const groupElement = arr[currentView.groupBy];
      group[groupElement] = group[groupElement] ?? [];
      group[groupElement].push(arr);
      return group;
    }, {});
    setGroupingData(groupArrayObject);
    let headersData = [];
    let tempHeaderOptions = [...currentView.options];
    if (filters[currentView.filterColumn] !== "all") {
      tempHeaderOptions = currentView.options.filter(
        (item) => item.value == filters[currentView.filterColumn]
      );
    }
    tempHeaderOptions.forEach((item) => {
      if (item.value) {
        headersData.push({
          ...item,
          isOpen: false,
          rows: 10,
          hasMore:
            groupArrayObject[item.value] &&
            groupArrayObject[item.value].length > 10
              ? true
              : false,
        });
      } else {
        headersData.push({
          ...item,
          value: null,
          isOpen: false,
          name: "No Data",
          rows: 10,
          hasMore:
            groupArrayObject[null] && groupArrayObject[null].length > 10
              ? true
              : false,
        });
      }
    });
    headersData = headersData.sort((a, b) => b.value - a.value);
    setGroupHeaders(headersData);
    setGroupPage(0);
    setGroupPageCount(calculateGroupPageCount(headersData));
  }, [currentView, filteredRows]);

  const updateIsOpen = (header) => {
    const tempGroupHeaders = [...groupHeaders];
    const index = tempGroupHeaders.findIndex(
      (item) => item.value == header.value
    );
    tempGroupHeaders[index].isOpen = !tempGroupHeaders[index].isOpen;
    tempGroupHeaders[index].rows = 10;
    if (groupingData[header.value].length > 10) {
      tempGroupHeaders[index].hasMore = true;
    }

    setGroupHeaders(tempGroupHeaders);
  };

  const fetchMoreData = (headerValue) => {
    const tempGroupHeaders = [...groupHeaders];
    const index = tempGroupHeaders.findIndex(
      (item) => item.value == headerValue
    );
    tempGroupHeaders[index].rows = tempGroupHeaders[index].rows + 10;
    if (tempGroupHeaders[index].rows >= groupingData[headerValue].length) {
      tempGroupHeaders[index].hasMore = false;
    }
    setGroupHeaders(tempGroupHeaders);
  };

  return (
    <TableContainer component={Paper} className={classes.marginTop}>
      <Table className="data-table ">
        <TableHead>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              className={classes.stickyColl}
            />
            <TableCell component="th" scope="row">
              {currentView.label}
            </TableCell>
            {currentView.columnList.map((item, index) => (
              <TableCell
                component="th"
                scope="row"
                key={"column_" + index + item.field}
                className={
                  index === 0
                    ? classNames(classes.stickyColl, classes.cursorPointer)
                    : classes.stickyColl
                }
                style={{ minWidth: item.minWidth }}
              >
                {item.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {(groupRowsPerPage > 0
            ? groupHeaders.slice(
                groupPage * groupRowsPerPage,
                groupPage * groupRowsPerPage + groupRowsPerPage
              )
            : groupHeaders
          ).map((item, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ cursor: "pointer" }}
                  >
                    {item.isOpen ? (
                      <ArrowCircleDownIcon
                        style={{ color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR }}
                        onClick={() => updateIsOpen(item)}
                      />
                    ) : (
                      <ArrowCircleRightIcon
                        style={{ color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR }}
                        onClick={() => updateIsOpen(item)}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    colSpan={12}
                    onClick={() => updateIsOpen(item)}
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                  >
                    {item["name"]}
                    <span className={classes.recordLength}>
                      {groupingData[item["value"]]?.length}
                    </span>
                  </TableCell>
                </TableRow>
                {item.isOpen &&
                  groupingData[item["value"]] &&
                  groupingData[item["value"]]
                    .slice(0, item["rows"])
                    .map((policy, policyIndex) => (
                      <TableRow key={policyIndex}>
                        <TableCell component="td" scope="row"></TableCell>
                        <TableCell component="td" scope="row" />
                        {currentView.columnList.map((column, index) => {
                          const value = policy[column.field];
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              key={"column_" + index + policyIndex}
                              className={
                                index === 0
                                  ? classNames(
                                      classes.stickyColl,
                                      classes.cursorPointer
                                    )
                                  : classes.stickyColl
                              }
                              style={{ minWidth: item.minWidth }}
                            >
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                {item.isOpen && item.hasMore && (
                  <TableRow>
                    <TableCell
                      colSpan={11}
                      component="td"
                      scope="row"
                      className={classes.loadMore}
                    >
                      <span onClick={() => fetchMoreData(item.value)}>
                        {langConstant.LOAD_MORE}
                      </span>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

GroupingData.propTypes = {
  filteredRows: PropTypes.array,
  filters: PropTypes.object,
  setGroupPage: PropTypes.func,
  setGroupPageCount: PropTypes.func,
  groupPage: PropTypes.number,
  groupRowsPerPage: PropTypes.number,
  calculateGroupPageCount: PropTypes.func,
  groupHeaders: PropTypes.array,
  setGroupHeaders: PropTypes.func,
};

export default GroupingData;
