import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    inputField: {
        padding: "7px 8px !important",
    },
    errorMessage: {
        color: "red !important", 
        fontSize: "12px !important"
    },
    iconButton: {
        backgroundColor: "#124571 !important",
        display: "inline-flex !important",
        borderRadius: "50% !important",
        padding: "10px !important",
        cursor: "pointer !important"
    }, 
    progressWraper: {
        position:'absolute', 
        width:'100%', 
        height:'100%',  
        background:'#00000057', 
        zIndex: '99999999999999'
    },
    contentRight: {
        display: 'flex', 
        justifyContent: 'flex-end'
    },
    progressBar: {
        position:'absolute', 
        top:'0', 
        right: '0', 
        left: '0', 
        bottom: '0', 
        margin:'auto', 
        zIndex:'9'
    },
    accordionWrapper: {
        maxHeight: '350px', 
        minHeight: '350px' , 
        overflowY :'auto'
    }, 
    accordionText: {
        maxHeight: '150px', 
        minHeight: '150px' , 
        overflowY :'auto'
    }
}));

export default useStyles;