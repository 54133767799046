import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import ProgressBar from "../components/ProgressBar";
import PanelHeader from "../components/PanelHeader";
import { useDispatch, useSelector } from "react-redux";
import GraphView from "./GraphView";
import ContentView from "./ContentView";
import SuppliersServices from "./services";
import sessionService from "_services/session.service";
import langConstant from "_lang";

const Panel7 = ({ refObj, block, columnID, panel }) => {
  const [isGraphView, setIsGraphView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [TPSRData, setTPSRData] = useState([]);

  const dispatch = useDispatch();

  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );
  const customerID = sessionService.getCustomerId();

  useEffect(() => {
    dispatch(
      SuppliersServices.getSupplierThirdPartySecurityRisk(
        setSuppliers,
        setTPSRData,
        setLoading,
        customerID
      )
    );
  }, []);

  return (
    <>
      {loading ? (
        <ProgressBar />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <PanelHeader
            refObj={refObj}
            expandedPanelID={expandedPanelID}
            block={block}
            panel={panel}
            columnID={columnID}
            isGraphView={isGraphView}
            setIsGraphView={setIsGraphView}
            panelName={langConstant.THIRD_PARTY_RISK}
          />
          <Grid sx={{ flexGrow: 1 }}>
            {isGraphView ? (
              <GraphView
                block={block}
                expandedPanelID={expandedPanelID}
                row1={suppliers}
                row2={TPSRData}
              />
            ) : (
              <ContentView
                block={block}
                expandedPanelID={expandedPanelID}
                row1={suppliers}
                row2={TPSRData}
              />
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

Panel7.propTypes = {
  refObj: PropTypes.any,
  block: PropTypes.object,
  columnID: PropTypes.string,
  panel: PropTypes.object,
};

export default Panel7;
