import { Grid, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import AdminServices from "./services";
import langConstant from "_lang";
import CustomerEntities from "./components/CustomerEntities";
import OptionComponent from "containers/manage-regulation/NewRegulation/ManageQuestions/component/OptionComponent";
import { getThemeDetails } from "containers/LoginPage/reducers";
import AdminUtils from "./utils";
import EntitlementLabels from "./components/EntitlementLabels";
import SourceSystems from "./components/SourceSystem/SourceSystems";
import Configurations from "./components/Configurations";
import RegulatorRegulations from "./components/RegulatorsRegulations";
import Classifications from "./components/Classifications";
import UsersTeams from "./components/UsersTeams";
import { useModal } from "_hooks";
import CustomerListForAdmin from "./components/SendNotification/CustomerListForAdminDialog";
import SystemAlertMessage from "./components/SystemAlertMessage/SystemAlertMessage";
import Subscriptions from "./components/subscriptions";

const AdminConsole = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeDetails = useSelector(getThemeDetails);
  const customerListForAdminModal = useModal();
  const systemAlertMessageModal = useModal();

  const [customerList, setCustomerList] = useState([]);
  const [customerListForAdmin, setCustomerListForAdmin] = useState([]);
  const [uniqueCustomerList, setUniqueCustomerList] = useState([]);
  const [uniqueCustomerUserList, setUniqueCustomerUserList] = useState([]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  const onOpenCustomerListForAdminDialog = () => {
    customerListForAdminModal?.setData({
      customerListForAdmin,
      uniqueCustomerList,
      uniqueCustomerUserList,
    });
    customerListForAdminModal?.openModal();
  };

  const onOpenSystemAlertMessageDialog = () => {
    systemAlertMessageModal?.setData({
      customerListForAdmin,
      uniqueCustomerList,
      uniqueCustomerUserList,
    });
    systemAlertMessageModal?.openModal();
  };

  useEffect(() => {
    dispatch(AdminServices.GetCustomerList(setCustomerList));
    dispatch(
      AdminServices.GetCustomerListForAdmin(
        setCustomerListForAdmin,
        setUniqueCustomerList,
        setUniqueCustomerUserList
      )
    );
  }, []);

  return (
    <Grid>
      <Grid container className={classes.customerWrapper}>
        <Grid item md={6} style={{ display: "flex" }}>
          <Typography className={classes.customerLabel}>
            {langConstant.SELECT_CUSTOMER}
          </Typography>
          <Select
            className={classes.customerSelect}
            options={customerList}
            components={{ Option: OptionComponent }}
            closeMenuOnSelect={true}
            close
            hideSelectedOptions={false}
            value={selectedCustomer}
            placeholder={langConstant.SELECT_CUSTOMER}
            onChange={(customer) => setSelectedCustomer(customer)}
          />
        </Grid>
        <Grid
          item
          md={6}
          style={{
            textAlign: "right",
          }}
        >
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={onOpenSystemAlertMessageDialog}
            style={{ height: "50px", marginRight: "5px" }}
          >
            {langConstant.SYSTEM_ALERT_MESSAGES}
          </Button>

          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={onOpenCustomerListForAdminDialog}
            style={{ height: "50px" }}
          >
            {langConstant.SEND_NOTIFICATION}
          </Button>
        </Grid>
      </Grid>

      <Grid container style={{ margin: "20px 0px" }}>
        <Grid item md={7} xs={12} container>
          {AdminUtils.tabList.first.map((tab) => {
            return (
              <Grid
                item
                md={tab.labelLength}
                style={{
                  padding: "0px 10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                key={tab.value}
              >
                <Typography
                  className={classes.tabsLabel}
                  style={{
                    backgroundColor:
                      tab.value == activeTab
                        ? themeDetails?.ACTIONBUTTON_BGROUND_COLOUR
                        : "",
                    color: tab.value == activeTab ? "#ffffff" : "#000000",
                    cursor: "pointer",
                  }}
                  onClick={() => setActiveTab(tab.value)}
                >
                  {tab.label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Grid item md={5} xs={12} container>
          {AdminUtils.tabList.second.map((tab) => {
            return (
              <Grid
                item
                md={tab.labelLength}
                style={{
                  padding: "0px 10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                key={tab.value}
              >
                <Typography
                  className={classes.tabsLabel}
                  style={{
                    backgroundColor:
                      tab.value == activeTab
                        ? themeDetails?.ACTIONBUTTON_BGROUND_COLOUR
                        : "",
                    color: tab.value == activeTab ? "#ffffff" : "#000000",
                    cursor: "pointer",
                  }}
                  onClick={() => setActiveTab(tab.value)}
                >
                  {tab.label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid>
        {activeTab == 1 && (
          <CustomerEntities
            selectedCustomer={
              selectedCustomer
                ? customerList.find(
                    (customer) => customer.CUSTOMER_ID == selectedCustomer.value
                  )
                : null
            }
            customerList={customerList}
            setCustomerList={setCustomerList}
            setSelectedCustomer={setSelectedCustomer}
            themeDetails={themeDetails}
          />
        )}
        {activeTab == 2 && (
          <Configurations selectedCustomer={selectedCustomer} />
        )}
        {activeTab == 3 && (
          <RegulatorRegulations selectedCustomer={selectedCustomer} />
        )}
        {activeTab == 4 && (
          <EntitlementLabels selectedCustomer={selectedCustomer} />
        )}
        {activeTab == 5 && (
          <SourceSystems selectedCustomer={selectedCustomer} />
        )}
        {activeTab == 6 && (
          <Classifications selectedCustomer={selectedCustomer} />
        )}
        {activeTab == 7 && (
          <Subscriptions selectedCustomer={selectedCustomer} />
        )}

        {activeTab == 8 && <UsersTeams selectedCustomer={selectedCustomer} />}
      </Grid>
      {customerListForAdminModal?.isOpen && (
        <CustomerListForAdmin modal={customerListForAdminModal} />
      )}
      {systemAlertMessageModal?.isOpen && (
        <SystemAlertMessage modal={systemAlertMessageModal} />
      )}
    </Grid>
  );
};

export default AdminConsole;
