/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED.

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import ClearAllOutlinedIcon from "@material-ui/icons/ClearAllOutlined";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import EventIcon from "@material-ui/icons/Event";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import Tiles from "_components/Tiles";
import langConstant from "_lang";
import _ from "lodash";
import moment from "moment";
import RfiAssessments from "./RfiAssessments/index";
import assessmentAction from "./actions";
import { useDispatch, useSelector } from "react-redux";
import sessionService from "_services/session.service";
import manageRfiProcess from "./ManageRfiProcess/actions";

const ManageRfi = ({ data = [] }) => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  let TOTAL_SUBMITTED = 0;
  let TOTAL_EFFORT_LOGGED = 0;
  let PASSED_DUE_DATE = 0;
  let userIdArray = [];
  let NEARING_DUE_DATE = 0;
  let TOTAL_ASSESSMENT = 0;

  const MY_TEAM = userIdArray.length;
  useEffect(() => {
    dispatch(assessmentAction.GetRFIRequestSummaryForUser(userID));
    dispatch(manageRfiProcess.clearStateRfiData());
  }, []);

  const count = useSelector((state) => state.rfiAssessmentList.rfiSummary);

  // SUBMISSION_DATE  2021-04-13 11:23:31

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        style={{ marginTop: "20px" }}
      >
        <Grid item md={2}>
          <Tiles
            label={langConstant.TOTAL_RFI_RECEIVED}
            count={count.received}
            icon={<AssignmentOutlinedIcon />}
            color="71, 145, 255"
          />
        </Grid>
        <Grid item md={2}>
          <Tiles
            label={langConstant.TOTAL_RFI_RESOLVED}
            count={count.resolved}
            icon={<AssignmentOutlinedIcon />}
            color="213, 173, 29"
          />
        </Grid>
        <Grid item md={2}>
          <Tiles
            label={langConstant.RFI_PENDING_TEXT}
            count={count.pending}
            icon={<AssignmentOutlinedIcon />}
            color="2, 188, 119"
          />
        </Grid>
        <Grid item md={2}>
          <Tiles
            label={langConstant.RFI_NEARING_DUE_DATE}
            count={count.nearingDueDate}
            icon={<AssignmentOutlinedIcon />}
            color="255, 35, 102"
          />
        </Grid>
        <Grid item md={2}>
          <Tiles
            label={langConstant.RFI_PASSED_DUE_DATE}
            count={count.pastDueDate}
            icon={<AssignmentOutlinedIcon />}
            color="239, 52, 208"
          />
        </Grid>
      </Grid>

      <RfiAssessments />
    </>
  );
};
ManageRfi.propTypes = {
  data: PropTypes.array,
};
export default ManageRfi;
