import { Grid, Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import langConstant from "_lang";
import PropTypes from "prop-types";
import userActions from "./actions";
import { useDispatch } from "react-redux";
import styles from "./styles";
import authService from "_services/auth.service";

const TwoFAPage = ({
  setIsLoginView,
  redirectTo,
  userID,
  username,
  password,
}) => {
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(30);
  const dispatch = useDispatch();
  const classes = styles();

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const confirmOTP = () => {
    dispatch(userActions.loginByOTP(userID, redirectTo, otp, "EMAIL OTP"));
  };

  const resendCode = () => {
    setCounter(30);
    authService.loginWithMFA(username, password);
  };

  return (
    <Grid>
      <Grid>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={{
            border: "1px solid transparent",
            borderRadius: "8px",
            width: "50px",
            height: "50px",
            fontSize: "20px",
            color: "#000",
            fontWeight: "bold",
            caretColor: "blue",
            backgroundColor: "#f3f3f3",
          }}
          renderSeparator={<span style={{ width: "15px" }}></span>}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus={true}
        />
      </Grid>
      <Grid className={classes.resendOTPDiv}>
        <Button
          color="primary"
          type="button"
          variant="text"
          disabled={counter ? true : false}
          onClick={resendCode}
        >
          <Typography
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              color: counter ? "grey" : "#124571",
            }}
          >
            Resend OTP{counter > 0 && <span> in {counter}</span>}
          </Typography>
        </Button>
      </Grid>
      <Grid style={{ textAlign: "right" }} className={classes.twoFAButtonsDiv}>
        <Button
          color="primary"
          type="button"
          variant="outlined"
          onClick={() => setIsLoginView(true)}
          size="medium"
          className={classes.backToLoginBtn}
        >
          {langConstant.BACK_TO_LOGIN}
        </Button>
        <Button
          color="primary"
          type="button"
          variant="contained"
          onClick={confirmOTP}
          size="medium"
          className={classes.confirmBtn}
        >
          {langConstant.CONFIRM}
        </Button>
      </Grid>
    </Grid>
  );
};

TwoFAPage.propTypes = {
  setIsLoginView: PropTypes.func,
  redirectTo: PropTypes.func,
  userID: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
};

export default TwoFAPage;
