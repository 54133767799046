/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import _ from "lodash";
import { Box, Grid, Checkbox, Divider } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import rootStyles from "../../../../../rootStyles";
import Truncate from "../../../../../_components/Truncate";
import questionMappingAction from "../actions";
import QuestionsCountInfoBar from "./QuestionsCountInfoBar";

const SelectQuestionsStep = (props) => {
  const { id } = useParams();
  const rootClasses = rootStyles();
  const [list, setList] = React.useState(props.questionDraft);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const isAllChecked = props.questionDraft && props.questionDraft.length > 0 ? _.every(props.questionDraft, { selected: true }) : false;
    
    setCheckedAll(isAllChecked)
    setList(props.questionDraft);
  }, [props.questionDraft]);

  useEffect(() => {
    const isExist = _.find(list, { selected: true });
    if (isExist) {
      props.updateIsOneOfChecked(true);
    } else {
      props.updateIsOneOfChecked(false);
    }
  }, [list]);

  const selectQuestion = (e, index) => {
    /*let newList = [...list];
        newList[index].selected = e.target.checked;
        setList(newList);
        
        !e.target.checked ? setCheckedAll(false) : null
        dispatch(questionMappingAction.updateDraft(id, newList))*/

    let item = list[index];
    item.selected = e.target.checked;
    dispatch(questionMappingAction.updateSingleItemInDraft(item));
  };
  const selectAllQuestion = (e) => {
    const val = e.target.checked;
    const newList = list.map((item) => {
      return { ...item, selected: val };
    });
    setList(newList);
    setCheckedAll(e.target.checked);
    dispatch(questionMappingAction.updateDraft(id, newList));
  };

  if (list.length == 0) {
    return true;
  }

  return (
    <>
      <QuestionsCountInfoBar questionDraft={props.questionDraft} />
      <Box mt={2}>
        <Checkbox
          className={rootClasses.greenCheckbox}
          color="default"
          id={"checkId_all"}
          checked={checkedAll}
          onChange={selectAllQuestion}
          inputProps={{ "aria-label": "primary checkbox" }}
        />{" "}
        Select All
        <Divider />
        {list.map((item, index) => {
          return (
            <Grid container spacing={1} key={index}>
              <Grid item>
                <Checkbox
                  className={rootClasses.greenCheckbox}
                  color="default"
                  id={"checkId_" + index}
                  checked={item.selected}
                  onChange={(e) => {
                    selectQuestion(e, index);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                {item.ref} -
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs>
                  <p className="list-question-margin">{item.sentence}</p>
                  {item.info ? (
                    <Box className="info-box">
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                          <InfoIcon color="inherit" />
                        </Grid>
                        <Grid item>
                          <Truncate content={item.info} wordCount={50} />
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
              {list.length - 1 > index && (
                <Grid item xs={12} style={{ padding: 0 }}>
                  <Divider />
                </Grid>
              )}
            </Grid>
          );
        })}
      </Box>
    </>
  );
};
SelectQuestionsStep.propTypes = {
  questionDraft: PropTypes.array,
  updateIsOneOfChecked: PropTypes.func,
};
export default SelectQuestionsStep;
