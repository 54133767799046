export const rfiConstants = {
  rfiStatus: {

    RFI_RESOLVED: "RFI_RESOLVED",
    RFI_ASSIGNED: "RFI_ASSIGNED"
  },
  rfiDueStatus: {
    DUE_IN_WEEK: "RFI DUE WITHIN A WEEK",
    DUE_IN_TWO_WEEKS: "RFI DUE WITHIN TWO WEEKS",
    DUE_IN_MORE_THAN_TWO_WEEKS: "RFI DUE WITHIN MORE THAN TWO WEEKS",
    RFI_OVERDUE: "RFI OVERDUE",
    RFI_RESOLVED: "RFI RESOLVED",
  },
};
