import apiService from "_services/api.service";

const GetUsersTaskList = (userId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetMyActionsList", {
      i_USER_ID: userId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const GetActionsByListId = (actionListId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetActionListDetails", {
      i_ACTIONS_LIST_ID: actionListId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const sendEmail = (bccEmailId, ccEmailId, htmlBody, subject, toEmailId) => {
  return apiService
    .apiCall("notification")
    .send("/api/v1/sendMail", {
      bccEmailId: bccEmailId,
      ccEmailId: ccEmailId,
      htmlBody: htmlBody,
      subject: subject,
      toEmailId: toEmailId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response.statusCode == 200) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const GetCustomerServiceType = (customerId, serviceCategory) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCustomerServiceType", {
      i_CUSTOMER_ID: customerId,
      i_SERVICE_CATEGORY: serviceCategory,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const GetUserNotificationPreferences = (userId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetUserNotificationPreferences", {
      i_USER_ID: userId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetCustomerServiceNotifications = (customerId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCustomerServiceNotifications", {
      i_CUSTOMER_ID: customerId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response && response["#result-set-1"]) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};
const PutServiceRequestIntoLog = (customerId, userId, payload) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutServiceRequestIntoLog", {
      i_CUSTOMER_ID: customerId,
      i_USER_ID: userId,
      i_SERVICE_REQUEST_PAYLOAD: payload,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const PutUserNotificationEvent = (
  userId,
  preferenceID,
  notificationMessage,
  messageDatetime
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutUserNotificationEvent", {
      i_USER_ID: userId,
      i_Notification_Preference_ID: preferenceID,
      i_Notification_Message: notificationMessage,
      i_Notification_MessageDateTime: messageDatetime,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export {
  GetUsersTaskList,
  GetActionsByListId,
  sendEmail,
  GetCustomerServiceType,
  GetCustomerServiceNotifications,
  GetUserNotificationPreferences,
  PutServiceRequestIntoLog,
  PutUserNotificationEvent,
};
