// OP#2830 - UJA8 - UI Development - Manage Teams Profile
// services for Team Member

import apiService from "../../_services/api.service";

const getUserEmailAddress = (userName) => {
    return apiService
        .apiCall("dbapi")
        .send(`/v1/api/proc/DRegulativDB/GetUserEmailAddress`,{
            i_USER_NAME: userName
        })
        .then(apiService.handleResponse)
        .then((response) => {
            if(response)
            {
              response['status'] = 200
              return response
            }
          },
          (error) => {
            if(error.response) {
              return error.response
            }
        }
    );
};

const generateTemporaryPassword = (userName) => {
    return apiService
        .apiCall("dbapi")
        .send(`/v1/api/proc/DRegulativDB/GenerateTemporaryPassword`,{
            i_USER_NAME: userName
        })
        .then(apiService.handleResponse)
        .then((response) => {
            if(response)
            {
              response['status'] = 200
              return response
            }
          },
          (error) => {
            if(error.response) {
              return error.response
            }
        }
    );
};

const updateUserPassword = (userData) => {
    return apiService
        .apiCall("dbapi")
        .send(`/v1/api/proc/DRegulativDB/UpdateUserPassword`,{
            i_USER_ID: userData.userId,
            i_NEW_PASSWORD: userData.password
        })
        .then(apiService.handleResponse)
        .then((response) => {
            if(response)
            {
              response['status'] = 200
              return response
            }
          },
          (error) => {
            if(error.response) {
              return error.response
            }
        }
    );
};



export default {
    getUserEmailAddress,
    generateTemporaryPassword,
    updateUserPassword,
};