import { globalConstants } from "./../_constants/";
import authService from "./../_services/auth.service";
import { alertActions } from "./alert.action";

const getUserDetails = (params) => {
  return (dispatch) => {
    authService.getUserDetails(params).then(
      (data) => {
        if (data) {
          dispatch({ type: globalConstants.USER_DATA_ADD, data });
        } else {
          dispatch(alertActions.error("User detail not found"));
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
const getUserImageDetails = (params) => {
  return (dispatch) => {
    authService.getUserImageDetails(params).then(
      (userData) => {
        dispatch({
          type: globalConstants.ADD_USER_DETAILED_INFO,
          data: userData,
        });
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
export const userDataActions = {
  getUserDetails,
  getUserImageDetails,
};
