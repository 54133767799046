import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import AdminServices from "../services";
import LegalEntityConfiguration from "./LegalEntityConfiguration";

const CustomerEntities = ({
  selectedCustomer,
  customerList,
  setCustomerList,
  setSelectedCustomer,
  themeDetails,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [initialCustomerValues, setInitialCustomerValues] = useState({
    CUSTOMER_ID: null,
    CUSTOMER_NAME: "",
    ACTIVE: 1,
    ACTION: "CREATE",
  });

  useEffect(() => {
    selectedCustomer && setInitialCustomerValues(selectedCustomer);
  }, [selectedCustomer]);

  const validationSchema = Yup.object().shape({
    CUSTOMER_NAME: Yup.string()
      .trim()
      .max(100, "Maximum 100 Characters Allowed")
      .required("Name is Required"),
  });

  const submitFormData = (values) => {
    if (values.ACTION == "CREATE") {
      dispatch(
        AdminServices.putNewCustomerRecord(
          values,
          setCustomerList,
          setSelectedCustomer,
          setInitialCustomerValues
        )
      );
    } else {
      dispatch(
        AdminServices.updateCustomerInformation(
          values,
          customerList,
          setCustomerList,
          setSelectedCustomer
        )
      );
    }
  };

  return (
    <Grid container spacing={2} style={{ height: "calc( 100vh - 300px )" }}>
      <Grid item md={4} xs={12}>
        <Grid
          className={classes.customerFormWrapper}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: " 10px",
          }}
        >
          <Formik
            enableReinitialize={true}
            initialValues={initialCustomerValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            onSubmit={submitFormData}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {values.CUSTOMER_ID && (
                  <Grid>
                    <Typography className={classes.customerFormLabel}>
                      {langConstant.CUSTOMER_ID} :{values.CUSTOMER_ID}
                    </Typography>
                  </Grid>
                )}
                <Grid>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.CUSTOMER_NAME}
                  </Typography>
                  <Field
                    style={{ width: "100%", margin: "10px" }}
                    name="CUSTOMER_NAME"
                  >
                    {() => (
                      <TextField
                        style={{ width: "80%", paddingBottom: "10px" }}
                        value={values.CUSTOMER_NAME}
                        onChange={(e) =>
                          setFieldValue("CUSTOMER_NAME", e.target.value)
                        }
                        placeholder={langConstant.CUSTOMER_NAME}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            input: classes.inputField,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="CUSTOMER_NAME">
                    {(msg) => (
                      <span
                        style={{
                          color: "red",
                          display: "block",
                          width: "100%",
                          fontSize: "14px",
                          lineHeight: "18px",
                          marginTop: "0px",
                        }}
                      >
                        {msg}
                      </span>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid>
                  <Typography className={classes.customerFormLabel}>
                    {langConstant.ACTIVE}
                  </Typography>
                  <Field style={{ width: "100%" }} name="ACTIVE">
                    {() => (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={parseInt(values.ACTIVE)}
                        onChange={(e) =>
                          setFieldValue("ACTIVE", e.target.value)
                        }
                        style={{ paddingBottom: "15px" }}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setFieldValue("ACTION", "CREATE")}
                  style={{ marginRight: "15px" }}
                >
                  {langConstant.CREATE_CUSTOMER}
                </Button>
                {values.CUSTOMER_ID && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => setFieldValue("ACTION", "UPDATE")}
                  >
                    {langConstant.UPDATE}
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Grid item md={8} xs={12}>
        <LegalEntityConfiguration
          themeDetails={themeDetails}
          selectedCustomer={selectedCustomer}
        />
      </Grid>
    </Grid>
  );
};

CustomerEntities.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
  customerList: PropTypes.array,
  setCustomerList: PropTypes.func,
  setSelectedCustomer: PropTypes.func,
  themeDetails: PropTypes.object,
};

export default CustomerEntities;
