import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import {
  Button,
  CircularProgress,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Chip,
  Tooltip,
} from "@material-ui/core";
import { Close, Save } from "@material-ui/icons";
import PolicyServices from "../../../../PolicyExplorer/services";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { ViewActionsTable } from "./ViewActionsTable";
import { ACTION_STATUS_LIST } from "containers/cyber-assessment/AuditActions/constants";

const styles = makeStyles(() => ({
  actionsWrapper: { display: "flex", gap: 8, flexDirection: "column" },
  actionsCard: {
    border: "1px solid #d5d5d5",
    borderRadius: "4px",
    padding: ".75rem",
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  flexOne: {
    flex: 1,
  },
  blankDataWrapper: { margin: "auto", padding: "1rem" },
  loading: { margin: "auto" },
  dialogTitle: { display: "flex", alignItems: "center", padding: ".75rem" },
  closeButton: { position: "absolute", right: 4 },
  [ACTION_STATUS_LIST[0].value]: {
    backgroundColor: "#7bc0ff",
    border: "#7bc0ff",
  },
  [ACTION_STATUS_LIST[1].value]: {
    backgroundColor: "#ffae6b",
    border: "#ffae6b",
  },
  [ACTION_STATUS_LIST[2].value]: {
    backgroundColor: "#4fcd00",
    border: "#4fcd00",
  },
}));

const priorityChipStyles = {
  M: { backgroundColor: "#fff6c8", color: "#c3a306" },
  H: { backgroundColor: "#ffdddd", color: "#ff3131" },
  L: { backgroundColor: "#dfffe1", color: "#4fcd00" },
};

const getPriorityChipLabel = (priority) => {
  switch (priority) {
    case "H":
      return "High";
    case "M":
      return "Medium";
    case "L":
      return "Low";
    default:
      return "-";
  }
};

const formatDate = (value) =>
  value ? new Date(value).toLocaleDateString() : "-";

export const ViewActionsDialog = ({ modal, data }) => {
  const dispatch = useDispatch();
  const classes = styles();
  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([]);

  const truncate = (string, limit = 100) => {
    return string?.length > limit ? string?.slice(0, limit) + "..." : string;
  };

  const columns = [
    { field: "ACTIONS_ID", headerName: "ACTION ID", minWidth: 80 },
    { field: "ASSIGN_TO_FIRST_NAME", headerName: "ASSIGNED TO" },
    {
      field: "ACTIONS_SHORT_DESCRIPTION",
      headerName: "SHORT NAME",
      // eslint-disable-next-line react/display-name
      format: (value) => {
        return (
          <Tooltip title={value}>
            <span>{truncate(value, 15)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "ACTIONS_FULL_DESCRIPTION",
      headerName: "DESCRIPTION",
      // eslint-disable-next-line react/display-name
      format: (value) => {
        return (
          <Tooltip title={value}>
            <span>{truncate(value, 15)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "ACTIONS_STATUS",
      headerName: "STATUS",
      format: (value) => getStatusBadge(value),
    },
    {
      field: "ACTIONS_PRIORITY",
      headerName: "PRIORITY",
      format: (value) => getPriorityBadge(value),
    },
    {
      field: "DUE_DATE",
      headerName: "DUE DATE",
      format: formatDate,
    },
    {
      field: "CLOSURE_DATE",
      headerName: "CLOSURE DATE",
      format: formatDate,
    },
    { field: "POLICY_ID", headerName: "POLICY ID" },
    // { field: "action", headerName: "ACTIONS", minWidth: 115, sticky: true },
  ];

  const getActions = async () => {
    await PolicyServices.getActions(data)
      .then((response) => {
        if (response?.status === 200) {
          setActions(response?.["#result-set-1"]);
        } else {
          dispatch(
            alertActions.error(
              response?.data?.message?.map((item, index) => {
                return <span key={index}>{item}</span>;
              }) || "Something went wrong"
            )
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(alertActions.error(err?.toString() || "Something went wrong"));
      })
      .finally(() => setIsLoading(false));
  };

  const getStatusBadge = (status) => {
    let listStatus = ACTION_STATUS_LIST.find((i) => i?.value == status);
    if (!listStatus) return "";
    return (
      <Chip
        size="small"
        className={classes?.[listStatus.value]}
        style={{ color: "#fff" }}
        label={listStatus?.label}
      />
    );
  };

  const getPriorityBadge = (value) => {
    if (!value) return "";
    return (
      <Chip
        label={getPriorityChipLabel(value)}
        size="small"
        style={{
          fontSize: 10,
          alignSelf: "center",
          padding: "0rem .25rem",
          ...priorityChipStyles[value],
        }}
      />
    );
  };

  useEffect(() => {
    getActions();
  }, []);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      //   disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.flexOne} variant="h6" align="center">
          VIEW ACTIONS
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={modal?.closeModal}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.actionsWrapper}>
          {isLoading ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <ViewActionsTable
              columns={columns}
              rows={actions}
              className="data-table"
            />
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={modal?.closeModal}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewActionsDialog.propTypes = {
  modal: propTypes.object,
  data: propTypes.object,
};
