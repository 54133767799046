import { Grid, IconButton, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ClassificationServices from "../services";
import { useState } from "react";
import ClassificationUtils from "../utils";
import TableComponent from "./TableComponent";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DocumentDialog from "./DocumentDialog";
import langConstant from "_lang";
import useStyles from "../styles";

const DocumentClassification = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [docClassificationData, setDocClassificationData] = useState(null);
  const [documentDialog, setDocumentDialog] = useState(false);
  const [selectedDocClassification, setSelectedDocClassification] =
    useState(null);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(
        ClassificationServices.getCustomerDocumentClassifications(
          selectedCustomer.value,
          setDocClassificationData
        )
      );
    }
  }, [selectedCustomer]);

  return (
    <Grid className={classes.classificationFormWrapper}>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className={classes.sectionHeader}>
          {langConstant.DOCUMENT_CLASSIFICATION}
        </Typography>
        {selectedCustomer && selectedCustomer.value && (
          <IconButton
            type="button"
            color="primary"
            onClick={() => setDocumentDialog(true)}
          >
            <AddBoxIcon />
          </IconButton>
        )}
      </Grid>
      {selectedCustomer && selectedCustomer.value ? (
        <TableComponent
          columns={ClassificationUtils.DocumentClassificationColumns}
          rows={docClassificationData}
          setSelectedRow={setSelectedDocClassification}
          setDialog={setDocumentDialog}
        />
      ) : (
        <Typography className={classes.selectCustText}>
          {langConstant.PLEASE_SELECT_CUSTOMER_FIRST}
        </Typography>
      )}
      <DocumentDialog
        open={documentDialog}
        setOpen={setDocumentDialog}
        selectedDocClassification={selectedDocClassification}
        docClassificationData={docClassificationData}
        setDocClassificationData={setDocClassificationData}
        selectedCustomer={selectedCustomer}
        setSelectedDocClassification={setSelectedDocClassification}
      />
    </Grid>
  );
};

DocumentClassification.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default DocumentClassification;
