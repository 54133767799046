/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Box,
  Button,
  Paper,
} from "@material-ui/core";
import langConstant from "_lang";
import SelectPageRange from "_components/SelectPageRange";
import { scrollToTop } from "_helpers/utils";
import AppPagination from "../../../../_components/AppPagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PeopleIcon from "@mui/icons-material/People";
import { useSelector } from "react-redux";
import AddSupplierDialog from "./addSupplierDilaog";
import moment from "moment";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import FilterDropdown from "../../../../_components/FilterDropdown";
import _ from "lodash";
import sessionService from "_services/session.service";
import AddIcon from "@mui/icons-material/Add";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { ActionIcon } from "_components/ActionIcon";
import { ActionMenu } from "_components/ActionMenu";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
  activeStatus: {
    borderColor: "#aff3bb",
    backgroundColor: "#aff3bb",
  },
  inactiveStatus: {
    borderColor: "#dfdfdf",
    backgroundColor: "#dfdfdf",
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
}));

const AppDataTable = (props) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles();
  const columns = props.columns;
  const [page, setPage] = useState(0);
  const [uniqueSupplierList, setUniqueSupplierList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };
  const [supplierId, setSupplierId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [keyPersonView, setKeyPersonView] = useState(false);

  const openMenu = Boolean(anchorMenuEl);

  const onActionClick = (event, data) => {
    event?.preventDefault();
    data && setSelectedRow(data);
    setAnchorMenuEl(openMenu ? null : event.currentTarget);
  };

  const [pageCount, setPageCount] = useState(
    calculatePageCount(uniqueSupplierList)
  );

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      uniqueSupplierList.length > 0
        ? Math.round(Math.ceil(uniqueSupplierList.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filteredRows, setFilteredRows] = useState([]);
  const initialFilterObj = {
    SUPPLIER_NAME: "",
    SUPPLIER_CATEGORY: "",
    SUPPLIER_PRODUCT_SERVICE_PROVIDED: "",
    ACTIVE: "",
  };
  const [filterBy, setFilterBy] = useState(initialFilterObj);
  const [showAddSupplierDialog, setShowAddSupplierDialog] = useState(false);
  const initialFilterParameter = {
    name: "",
    category: "",
    service: "",
    status: "",
  };
  const [filterParameter, setFilterParameter] = useState(
    initialFilterParameter
  );

  const userRole = sessionService.getUserRole();
  const userRoleId = sessionService.getUserRoleId();
  const onFilterChange = (fieldName, e) => {
    const filterValue = e.target.value;

    const filterObj = { ...filterBy, [fieldName]: filterValue };
    setFilterBy(filterObj);

    let filtered_array = uniqueSupplierList.filter((item) => {
      if (filterObj["SUPPLIER_NAME"]) {
        return filterObj["SUPPLIER_NAME"] == item["SUPPLIER_NAME"];
      } else if (filterObj["SUPPLIER_PRODUCT_SERVICE_PROVIDED"]) {
        return (
          filterObj["SUPPLIER_PRODUCT_SERVICE_PROVIDED"] ==
          item["SUPPLIER_PRODUCT_SERVICE_PROVIDED"]
        );
      } else if (filterObj["SUPPLIER_CATEGORY"]) {
        return filterObj["SUPPLIER_CATEGORY"] == item["SUPPLIER_CATEGORY"];
      } else if (filterObj["ACTIVE"]) {
        return filterObj["ACTIVE"] == item["ACTIVE"];
      } else {
        return true;
      }
    });

    setFilteredRows(filtered_array);
    setPageCount(calculatePageCount(filtered_array));
    setPage(0);
  };
  const resetFilter = () => {
    setFilterBy(initialFilterObj);
    setFilteredRows(uniqueSupplierList);
    setPageCount(calculatePageCount(uniqueSupplierList));
    setPage(0);
  };

  useEffect(() => {
    let orderedSupplierList = {};

    props.rows.forEach((item) => {
      item.ACTIVE =
        item.ACTIVE == 1 || item.ACTIVE == true || item.ACTIVE == "ACTIVE"
          ? 1
          : 0;
      const supplier = item.SUPPLIER_ID ? item.SUPPLIER_ID : null;
      if (!orderedSupplierList[supplier]) {
        orderedSupplierList[supplier] = [];
      }
      orderedSupplierList[supplier].push(item);
    });

    const supplierMap = new Map();

    props.rows.forEach((supplier) => {
      supplier.ACTIVE =
        supplier.ACTIVE == 1 ||
        supplier.ACTIVE == true ||
        supplier.ACTIVE == "ACTIVE"
          ? "ACTIVE"
          : "INACTIVE";

      if (!supplierMap.has(supplier.SUPPLIER_ID)) {
        supplierMap.set(supplier.SUPPLIER_ID, supplier);
      }
    });

    const supplierList = Array.from(supplierMap.values());

    setUniqueSupplierList(supplierList);

    let tempRows = [...supplierList];

    if (filterParameter["name"] !== "") {
      tempRows = tempRows.filter((tempRow) =>
        tempRow.SUPPLIER_NAME.toLowerCase().includes(
          filterParameter["name"].toLowerCase()
        )
      );
    }

    if (filterParameter["category"] !== "") {
      tempRows = tempRows.filter(
        (tempRow) => tempRow.SUPPLIER_CATEGORY == filterParameter["category"]
      );
    }

    if (filterParameter["service"] !== "") {
      tempRows = tempRows.filter(
        (tempRow) =>
          tempRow.SUPPLIER_PRODUCT_SERVICE_PROVIDED ==
          filterParameter["service"]
      );
    }

    if (filterParameter["status"] !== "") {
      tempRows = tempRows.filter(
        (tempRow) => tempRow.ACTIVE == filterParameter["status"]
      );
    }

    tempRows =
      tempRows.length > 0
        ? tempRows.map((item) => {
            let data = item;
            const StatusLable = (
              <span
                className={classNames(
                  classes.acinStatus,
                  item.ACTIVE == "ACTIVE"
                    ? classes.activeStatus
                    : classes.inactiveStatus
                )}
              >
                {item.ACTIVE == "ACTIVE" ? "ACTIVE" : "INACTIVE"}
              </span>
            );
            data["STATUS"] = StatusLable;
            data["ACTIONS"] = (
              <ActionIcon
                onClick={(e) =>
                  onActionClick(e, orderedSupplierList[item.SUPPLIER_ID])
                }
              />
            );

            return data;
          })
        : [];

    setFilteredRows(
      tempRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    );
    // setFilteredRows(tempRows);
    setPageCount(calculatePageCount(tempRows));
  }, [page, props.rows, filterParameter]);

  const createFilterDropdown = (fieldName, label) => {
    let options = _.reject(
      _.uniq(_.map(uniqueSupplierList, fieldName)).sort(),
      _.isNull
    );

    if (options.length > 0 && options[0]) {
      options = options.map((item) => {
        return { value: item, name: item };
      });
    }

    if (options.length > 0) {
      return (
        <FilterDropdown
          label={label}
          options={options}
          fieldName={fieldName}
          value={filterBy[fieldName]}
          onChange={onFilterChange}
          icon={true}
        />
      );
    }
  };

  return (
    <div>
      {openMenu && (
        <ActionMenu
          anchorMenuEl={anchorMenuEl}
          isOpen={openMenu}
          onClose={onActionClick}
          menuItems={[
            {
              icon: RemoveRedEyeIcon,
              tooltipLabel: langConstant.VIEW_SUPPLIER_RECORD,
              color: "rgb(0, 123, 255)",
              onClick: () => {
                setSupplierId(selectedRow[0]["SUPPLIER_ID"]);
                setShowAddSupplierDialog(true);
              },
            },
            {
              tooltipLabel: langConstant.ADD_SUPPLIER_CONTACT,
              color: "rgb(14, 148, 57)",
              onClick: () => {
                setSupplierId(selectedRow[0]["SUPPLIER_ID"]);
                setKeyPersonView(true);
                setShowAddSupplierDialog(true);
              },
              icon: PeopleIcon,
            },
          ]}
        />
      )}

      <Box mb={2} mt={3}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                {uniqueSupplierList.length > 0 &&
                  createFilterDropdown("SUPPLIER_NAME", "Supplier Name")}
              </Grid>

              <Grid item xs={12} md={3}>
                {uniqueSupplierList.length > 0 &&
                  createFilterDropdown(
                    "SUPPLIER_PRODUCT_SERVICE_PROVIDED",
                    "Product Service Provided"
                  )}
              </Grid>

              <Grid item xs={12} md={3}>
                {uniqueSupplierList.length > 0 &&
                  createFilterDropdown("SUPPLIER_CATEGORY", "Category")}
              </Grid>
              <Grid item xs={12} md={3}>
                {uniqueSupplierList.length > 0 &&
                  createFilterDropdown("ACTIVE", "Status")}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={1}>
            <Box mx={1}>
              {(filterBy["SUPPLIER_NAME"] ||
                filterBy["SUPPLIER_PRODUCT_SERVICE_PROVIDED"] ||
                filterBy["SUPPLIER_CATEGORY"] ||
                filterBy["ACTIVE"]) && (
                <Button onClick={resetFilter} variant="text" color="primary">
                  Reset All
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              color="primary"
              onClick={() => {
                setSupplierId(null);
                setShowAddSupplierDialog(true);
              }}
            >
              {langConstant.ADD_NEW_SUPPLIER}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table className={props.className} aria-label="Suplliers List">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={item.sticky && classes.stickyColl}
                  style={{ minWidth: item.minWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? uniqueSupplierList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : uniqueSupplierList
            ).map((row, index) => (
              <TableRow key={index + row[columns[0].field]}>
                {columns.map((column, index) => {
                  //const Comp = column.component && column.component
                  const value = row[column.field];
                  return (
                    <TableCell
                      component="td"
                      scope="row"
                      key={"column_" + index + row[columns[0].field]}
                      className={column.sticky && classes.stickyColl}
                    >
                      {column.format ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {filteredRows.length == 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            {uniqueSupplierList && uniqueSupplierList.length > 0 && (
              <SelectPageRange
                selectedRange={rowsPerPage}
                handleChange={handleSelectPageRange}
              />
            )}
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      )}
      {showAddSupplierDialog && (
        <AddSupplierDialog
          show={showAddSupplierDialog}
          setShow={setShowAddSupplierDialog}
          supplierId={selectedRow ? selectedRow[0]["SUPPLIER_ID"] : supplierId}
          rows={props.rows}
          setSupplierList={props.setSupplierList}
          data={selectedRow}
          keyPersonView={keyPersonView}
          setKeyPersonView={setKeyPersonView}
          setAnchorMenuEl={setAnchorMenuEl}
        />
      )}
    </div>
  );
};

AppDataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  setSupplierList: PropTypes.func,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default AppDataTable;
