/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import sessionService from "./../_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import { authConstants } from "../containers/LoginPage/constants";
import Page404 from "./Layout/Page404";

const PrivateRoute = ({ component: Component, entitlement, ...rest }) => {
  const [isAllowed, setIsAllowed] = useState(null);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.authentication.userData);

  useEffect(() => {
    if (userData) {
      let allowed;
      if (!entitlement?.length) {
        allowed = true;
      } else {
        allowed = userData?.allowedNavItems?.indexOf(entitlement) > -1;
      }
      setIsAllowed(allowed);
    }
  }, [userData, entitlement]);

  useEffect(() => {
    if (
      !sessionService.getItem("authData") &&
      !sessionService.getItem("jwtToken")
    ) {
      dispatch({ type: authConstants.LOGOUT });
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          !sessionService.getItem("authData") &&
          !sessionService.getItem("jwtToken")
        ) {
          // not logged in so redirect to login page with the return url
          return <Redirect exact to={"/login"} />;
        }
        if (
          sessionService.getItem("authData") &&
          sessionService.getItem("jwtToken") &&
          sessionService.getTempLogin()
        ) {
          return <Redirect exact to={"/reset-password"} />;
        }
        // logged in so return component
        if (isAllowed === null) return <></>;
        if (!isAllowed) return <Page404 />;

        // Pass dashboard_url as a prop
        return <Component {...props} />;
      }}
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
  entitlement: PropTypes.string,
};
export default PrivateRoute;
