import React, { useEffect, useState } from "react";
import PolicyServices from "../../PolicyExplorer/services";
import { alertActions, loadingActions } from "_actions";
import langConstant from "_lang";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InsightTable from "./component/InsightTable";

const ComparisonInsights = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [basePolicyText, setBasePolicyText] = useState([]);
  const [targetPolicyText, setTargetPolicyText] = useState([]);
  const [comparisonInfo, setComparisonInfo] = useState({});
  const [documentDetails, setDocumentDetails] = useState({
    base: { fileName: "", id: "" },
    target: { fileName: "", id: "" },
  });

  useEffect(() => {
    (async () => {
      dispatch(loadingActions.start());
      const response = await PolicyServices.getPolicyComparisonRecipeDetails(
        id
      );
      if (response.status === 200) {
        setComparisonInfo(
          response["#result-set-6"] && response["#result-set-6"].length > 0
            ? response["#result-set-6"][0]
            : {}
        );
        const basePolicy = {
          fileName: response["#result-set-2"][0]["v_base_policyname"],
          fileFolder: response["#result-set-2"][0]["v_base_folder"],
          id: response["#result-set-6"][0]["BASE_POLICY_ID"],
        };
        const targetPolicy = {
          fileName: response["#result-set-3"][0]["v_target_policyname"],
          fileFolder: response["#result-set-3"][0]["v_target_folder"],
          id: response["#result-set-6"][0]["TARGET_POLICY_ID"],
        };
        setDocumentDetails({ base: basePolicy, target: targetPolicy });
        if (response["#result-set-4"].length) {
          const tempBasePolicyText = response["#result-set-4"].map((text) => {
            let filteredComparisonData = response["#result-set-1"].filter(
              (item) => text.NAME === item.BASE_DOCUMENT
            );
            let filteredSentenceCount = response["#result-set-7"].filter(
              (item) => item.BASE_DOCUMENT === text.NAME
            );
            const targetPolicyOptions = response["#result-set-5"].map(
              (element) => {
                const tempFilteredComparisonData =
                  filteredComparisonData.filter(
                    (item) => element.NAME === item.TARGET_DOCUMENT
                  );
                const tempCountData = filteredSentenceCount.filter(
                  (item) => element.NAME === item.TARGET_DOCUMENT
                );
                return {
                  value: element.POLICY_EXTRACTION_DETAILS_ID,
                  label: element.NAME,
                  page: element.PAGE_NUMBER,
                  score:
                    tempFilteredComparisonData.length > 0
                      ? tempFilteredComparisonData[0].SEMANTIC_SCORE
                      : 0,
                  selectedIDs: tempFilteredComparisonData.map(
                    (comparison) => comparison.POLICY_COMPARISON_DETAILS_ID
                  ),
                  recordCount:
                    tempCountData && tempCountData.length > 0
                      ? tempCountData[0].total_sentences
                      : 0,
                };
              }
            );
            const selectedComparison = targetPolicyOptions.filter(
              (option) => option.score >= 0.6
            );
            return {
              ...text,
              selectedComparison: [...selectedComparison],
              options: targetPolicyOptions,
              isSelectedUpdated: false,
            };
          });
          setBasePolicyText(tempBasePolicyText);
        }
        if (response["#result-set-5"].length) {
          setTargetPolicyText(response["#result-set-5"]);
        }
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
      dispatch(loadingActions.end());
    })();
  }, []);

  return (
    <InsightTable
      basePolicyText={basePolicyText}
      targetPolicyText={targetPolicyText}
      comparisonInfo={comparisonInfo}
      setBasePolicyText={setBasePolicyText}
      documentDetails={documentDetails}
    />
  );
};

export default ComparisonInsights;
