import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import ProgressBar from "../components/ProgressBar";
import PanelHeader from "../components/PanelHeader";
import { useDispatch, useSelector } from "react-redux";
import GraphView from "./GraphView";
import ContentView from "./ContentView";
import sessionService from "_services/session.service";
import DocsRequiredService from "./services";
import langConstant from "_lang";

const Panel6 = ({ refObj, block, columnID, panel }) => {
  const [isGraphView, setIsGraphView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contentData, setContentData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );
  const regulationID = useSelector(
    (state) => state.complianceReducer.selectedRegulation
  );

  const dispatch = useDispatch();
  const customerID = sessionService.getCustomerId();

  useEffect(() => {
    if (regulationID && regulationID.REGULATION_ID) {
      dispatch(
        DocsRequiredService.getRegulationDocsRequired(
          setContentData,
          setLoading,
          customerID,
          regulationID.REGULATION_ID
        )
      );
    } else {
      dispatch(
        DocsRequiredService.getRegulationDocsRequiredStatus(
          setGraphData,
          setLoading,
          customerID
        )
      );
    }
  }, [regulationID]);

  return (
    <>
      {loading ? (
        <ProgressBar />
      ) : (
        <Box>
          <PanelHeader
            refObj={refObj}
            expandedPanelID={expandedPanelID}
            block={block}
            panel={panel}
            columnID={columnID}
            isGraphView={isGraphView}
            setIsGraphView={setIsGraphView}
            panelName={langConstant.POLICY_AND_DOCUMENTATION}
          />
          {isGraphView ? (
            <GraphView
              regulationID={regulationID}
              panelID={panel.id}
              graphData={graphData}
              contentData={contentData}
            />
          ) : (
            <ContentView
              regulationID={regulationID}
              setLoading={setLoading}
              contentData={contentData}
              panelID={panel.id}
              isGraphView={isGraphView}
            />
          )}
          {/* {regulationID && regulationID.value ? (
            <Grid></Grid>
          ) : (
            <SelectRegulation />
          )} */}
        </Box>
      )}
    </>
  );
};

Panel6.propTypes = {
  refObj: PropTypes.any,
  block: PropTypes.object,
  columnID: PropTypes.string,
  panel: PropTypes.object,
};

export default Panel6;
