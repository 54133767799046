import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import PolicyServices from "../PolicyExplorer/services";
import moment from "moment";
import ComparisonPolicyDataTable from "./component/ComparisonPolicyDataTable";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";


const PolicyComparison = () => {
    const [availablePolicyComparison, setAvailablePolicyComparison] = useState([]);
    const userID = sessionService.getUserId();
    const customerID = useSelector((state) => state.authentication.detailedInfo.CUSTOMER_ID)

    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            const response = await PolicyServices.getAvailablePolicyComparisons(userID);
            if (response && response.status === 200) {
                setAvailablePolicyComparison(response["#result-set-1"])
            }
        })();
    }, [])

    useEffect(() => {
        if(customerID){
            dispatch(PolicyServices.getAllUserErrorMessages(customerID))
        }
    },[customerID])

    return (
        <Box>
            <ComparisonPolicyDataTable 
                userID={userID}
                rows={availablePolicyComparison} 
                setRows={setAvailablePolicyComparison}
            />
        </Box>
    );
};

export default PolicyComparison;