import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Paper,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { IconButton, Tooltip } from "@mui/material";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { Formik, Form } from "formik";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddNewSubscriptionPopup from "./components/AddNewSubscriptionPopup";
import { alertActions, loadingActions } from "_actions";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useModal } from "_hooks";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ActionIcon } from "_components/ActionIcon";
import { ActionMenu } from "_components/ActionMenu";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionServices from "../../services";
import langConstant from "_lang";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import PropTypes from "prop-types";
import sessionService from "_services/session.service";

const useStyles = makeStyles(() => ({
  title: { display: "flex", alignItems: "center", padding: ".75rem" },
  error: { color: "red", fontSize: "12px" },
  //   textField: {
  //     "& .MuiOutlinedInput-root": {
  //       "& fieldset": {
  //         border: "none",
  //       },
  //     },
  //   },
  subscriptionWrapper: {
    // display: "inline-flex",
    border: "2px solid grey",
    width: "100%",
    padding: "10px",
    // alignItems: "center",
    borderRadius: "5px",
  },

  wideColumn: {
    width: "30%",
  },
  normalColumn: {
    width: "10%",
    textAlign: "center",
  },
  regColumn: {
    width: "20%",
  },
}));

const validationSchema = Yup.object().shape({
  //   rows: Yup.array().of(
  //     Yup.object().shape({
  //       Evidence_Shortname: Yup.string().required(
  //         "Evidence_Shortname is required"
  //       ),
  //       Evidence_Description: Yup.string().required(
  //         "Evidence_Description is required"
  //       ),
  //       Regulatory_Domain: Yup.string().required("Regulatory_Domain is required"),
  //     })
  //   ),
});

const Subscriptions = ({ selectedCustomer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const username = useSelector(
    (state) => state.authentication.detailedInfo?.USER_NAME
  );

  const [subscriptionData, setSubscriptionData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const themeDetails = useSelector(getThemeDetails);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);
  const addNewSubscriptionModal = useModal();

  const onOpenAddNewSubscriptionDialog = () => {
    addNewSubscriptionModal?.setData({
      userID,
      customerID,
      subscriptionData,
      setSubscriptionData,
    });
    addNewSubscriptionModal?.openModal();
  };
  const onActionClick = (event, data) => {
    event?.preventDefault();
    data && setSelectedRow(data);
    setAnchorMenuEl(openMenu ? null : event.currentTarget);
  };

  const calculatePageCount = (rows) => {
    return rows?.length > 0
      ? Math.round(Math.ceil(rows?.length / rowsPerPage))
      : 0;
  };

  const handleInvoice = (row) => {
    //Logic for Invoice
  };
  const handleRenew = (row) => {
    //Logic for Renew
  };
  const handleChangePage = (event) => {
    setPage(event);
  };

  const convertDateFormat = (dateString) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getUTCFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      subscriptionData.length > 0
        ? Math.round(Math.ceil(subscriptionData.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchSubscriptionData = async () => {
    dispatch(loadingActions.start());
    const subscriptionResponse =
      await SubscriptionServices.getCustomerSubscriptionsForAdmin(
        selectedCustomer.value
      );
    dispatch(loadingActions.end());

    if (subscriptionResponse && subscriptionResponse["#result-set-1"]) {
      setSubscriptionData(subscriptionResponse["#result-set-1"]);
    } else {
      if (subscriptionResponse.data && subscriptionResponse.data.message) {
        dispatch(
          alertActions.error(
            subscriptionResponse.data.message.map((item, index) => (
              <div key={index}>{item}</div>
            ))
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  const onSubmit = (values) => {
    for (let row of values.rows) {
      //logic for onSubmit
    }
    //   modal?.closeModal();
  };

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      fetchSubscriptionData();
    }
  }, []);

  useEffect(() => {
    calculatePageCount(subscriptionData);
  }, [subscriptionData]);

  return (
    <Grid
      className={classes.sourceSystemWrapper}
      style={{ display: "flex", flexDirection: "column", gap: "10px" }}
    >
      <Grid
        className={classes.subscriptionWrapper}
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        {selectedCustomer && selectedCustomer.value ? (
          <Grid>
            <Grid
              // style={{
              //   display: "flex",
              //   marginBottom: "5px",
              //   alignItems: "center",
              // }}
              container
            >
              <Grid container style={{ display: "flex" }}>
                <Grid
                  item
                  xs={7}
                  style={{
                    justifyItems: "center",
                    alignItems: "right",
                    justifyContent: "right",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  >
                    {langConstant.MY_SUBSCRIPTIONS}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    justifyItems: "right",
                    alignItems: "right",
                    justifyContent: "right",
                  }}
                >
                  <Box display="flex" justifyContent="right">
                    <Tooltip title="Add a new subscription">
                      <IconButton
                        onClick={() => {
                          onOpenAddNewSubscriptionDialog();
                        }}
                      >
                        <AddBoxIcon
                          sx={{
                            color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                            fontSize: "35px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Formik
                initialValues={{ rows: subscriptionData }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={onSubmit}
              >
                {({ values, errors, setFieldValue, handleChange }) => (
                  <Form>
                    <TableContainer
                      component={Paper}
                      style={{ marginleft: 10, marginRight: 10 }}
                    >
                      <Table
                        sx={{ minWidth: 650 }}
                        //  size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Subscription</TableCell>
                            <TableCell>Status</TableCell>

                            <TableCell>Renewal Date</TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {subscriptionData.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell
                                className={classes.wideColumn}
                                style={{
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row.PLAN_NAME}
                              </TableCell>
                              <TableCell
                                className={classes.regColumn}
                                style={{
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row.DESCRIPTION}
                              </TableCell>
                              <TableCell className={classes.regColumn}>
                                {row.SUBSCRIPTION_STATUS}
                              </TableCell>
                              <TableCell className={classes.regColumn}>
                                {(row.RENEW != null &&
                                  row.RENEW != "" &&
                                  convertDateFormat(row.RENEW)) ||
                                  "--"}
                              </TableCell>
                              <TableCell className={classes.normalColumn}>
                                {/* <div
                           style={{
                             display: "flex",
                             justifyContent: "space-between",
                           }}
                         >
                           <button onClick={() => handleInvoice(row)}>
                             Invoice
                           </button>
                           <button onClick={() => handleRenew(row)}>
                             Renew
                           </button>
                         </div>{" "} */}
                                <ActionIcon
                                  onClick={(e) => onActionClick(e, row)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Box my={1}>
              <Grid
                container
                style={{ justifyContent: "end" }}
                justify="flex-end"
              >
                {subscriptionData?.length > 0 && (
                  <SelectPageRange
                    selectedRange={rowsPerPage}
                    handleChange={handleSelectPageRange}
                  />
                )}
                {pageCount > 0 && (
                  <AppPagination
                    colSpan={3}
                    count={pageCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </Grid>
            </Box>
            {openMenu && (
              <ActionMenu
                anchorMenuEl={anchorMenuEl}
                isOpen={openMenu}
                onClose={onActionClick}
                menuItems={[
                  {
                    icon: ReceiptIcon,
                    tooltipLabel: langConstant.INVOICE,
                    color: "rgb(0, 123, 255)",
                    onClick: () => {
                      handleInvoice();
                    },
                  },
                  {
                    tooltipLabel: langConstant.RENEW,
                    color: "rgb(14, 148, 57)",
                    onClick: () => {
                      handleRenew();
                    },
                    icon: AutorenewIcon,
                  },
                ]}
              />
            )}
            {addNewSubscriptionModal?.isOpen && (
              <AddNewSubscriptionPopup modal={addNewSubscriptionModal} />
            )}
          </Grid>
        ) : (
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bold",
              minHeight: "100px",
              display: " flex",
              alignItems: " center",
              justifyContent: "center",
            }}
          >
            {langConstant.PLEASE_SELECT_CUSTOMER_FIRST}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

Subscriptions.propTypes = {
  selectedCustomer: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};

export default Subscriptions;
