import { Box, Button, InputAdornment } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import SupplierUtils from "./utils";
import TableComponent from "./components/TableComponent";
import { TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

const ContentView = ({ expandedPanelID, block, row1, row2 }) => {
  const [search, setSearch] = useState("");
  const [filteredRow1, setFilteredRow1] = useState(row1);
  const [filteredRow2, setFilteredRow2] = useState(row2);

  const onSearch = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    const tempFilteredRow1 = row1?.filter((item) =>
      item?.SUPPLIER_NAME?.toUpperCase()?.includes(searchValue?.toUpperCase())
    );
    const tempFilteredRow2 = row2?.filter((item) =>
      item?.SUPPLIER_NAME?.toUpperCase()?.includes(searchValue?.toUpperCase())
    );

    setFilteredRow2(tempFilteredRow2);
    setFilteredRow1(tempFilteredRow1);
  };

  const resetFilters = () => {
    setFilteredRow1(row1);
    setFilteredRow2(row2);
    setSearch("");
  };

  return (
    <Box
      sx={{
        height: "90%",
        width: "100%",
        gap: 5,
        display: "flex",
        flexDirection: "column",
        mt: 2,
      }}
    >
      {expandedPanelID === block.id && (
        <Box sx={{ display: "flex", gap: "inherit" }}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            id="searchText"
            label="Search Suppliers"
            name="searchText"
            placeholder="Filter Table"
            value={search}
            onChange={onSearch}
            InputProps={{
              autoComplete: "off",
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={resetFilters}>
            Clear
          </Button>
        </Box>
      )}
      <Box sx={{ display: "flex", gap: 5 }}>
        {expandedPanelID === block.id && (
          <TableComponent
            columns={SupplierUtils.suppliersColumns}
            rows={filteredRow1}
            expandedPanelID={expandedPanelID}
            block={block}
          />
        )}
        <TableComponent
          columns={
            expandedPanelID === block.id
              ? SupplierUtils.expandedTPSRColumns
              : SupplierUtils.TPSRColumns
          }
          rows={filteredRow2}
          expandedPanelID={expandedPanelID}
          block={block}
        />
      </Box>
    </Box>
  );
};

ContentView.propTypes = {
  expandedPanelID: PropTypes.string,
  block: PropTypes.object,
  row1: PropTypes.array,
  row2: PropTypes.array,
};
export default ContentView;
