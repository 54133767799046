import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import styles from "./styles";
import langConstant from "_lang";
import { useDispatch } from "react-redux";
import userActions from "./actions";
import { useState } from "react";
import OtpInput from "react-otp-input";
import PropTypes from "prop-types";
import sessionService from "_services/session.service";

const QRCodePage = ({ userID, redirectTo, setIsLoginView }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const qrString = sessionService.getItem("QRCode");
  const [otp, setOtp] = useState("");
  const [isQRVisible, setIsQRVisible] = useState(false);

  const confirmOTP = () => {
    dispatch(userActions.loginByOTP(userID, redirectTo, otp, "QR"));
  };
  return (
    <Grid>
      <Grid>
        <Grid style={{ textAlign: "center", marginBottom: "20px" }}>
          {isQRVisible && qrString && (
            <>
              <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>
                {langConstant.SCAN_QR_CODE_TO_GET_OTP}
              </Typography>
              <img src={qrString} style={{ width: "150px", height: "150px" }} />
            </>
          )}
        </Grid>
        {!isQRVisible && (
          <Typography
            onClick={() => setIsQRVisible(true)}
            className={classes.showQRText}
          >
            {langConstant.VIEW_QR_CODE}
          </Typography>
        )}
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={{
            border: "1px solid transparent",
            borderRadius: "8px",
            width: "50px",
            height: "50px",
            fontSize: "20px",
            color: "#000",
            fontWeight: "bold",
            caretColor: "blue",
            backgroundColor: "#f3f3f3",
          }}
          renderSeparator={<span style={{ width: "15px" }}></span>}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus={true}
        />
        <Typography style={{ fontWeight: "bold", fontSize: "12px" }}>
          {langConstant.PLEASE_CHECK_AUTHENTICATOR_APP_FOR_OTP}
        </Typography>
      </Grid>
      <Grid className={classes.resendOTPDiv}></Grid>
      <Grid style={{ textAlign: "right" }} className={classes.twoFAButtonsDiv}>
        <Button
          color="primary"
          type="button"
          variant="outlined"
          onClick={() => setIsLoginView(true)}
          size="medium"
          className={classes.backToLoginBtn}
        >
          {langConstant.BACK_TO_LOGIN}
        </Button>
        <Button
          color="primary"
          type="button"
          variant="contained"
          onClick={confirmOTP}
          size="medium"
          className={classes.confirmBtn}
        >
          {langConstant.CONFIRM}
        </Button>
      </Grid>
    </Grid>
  );
};

QRCodePage.propTypes = {
  redirectTo: PropTypes.func,
  userID: PropTypes.string,
  setIsLoginView: PropTypes.func,
};

export default QRCodePage;
