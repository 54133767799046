/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
} from "@material-ui/core";
import EventIcon from "@mui/icons-material/Event";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import rootStyles from "rootStyles";
import { rfiConstants } from "_constants";
import langConstant from "_lang";
import AttachEvidence from "_components/AttachEvidence/index";
import EvidenceLocker from "_components/EvidenceLocker/index";
import CommentSection from "_components/CommentSection/index";

import actions from "../../../../actions";
import uploadDataAction from "../../../../../../UploadDataForm/actions";


const styles = {
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  coverNoteContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  coverNoteIcon: {
    color: "#007BFF",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  coverNoteText: {
    color: "#959595",
  },
  markCompletedCheckbox: {
    marginRight: "50px",
  },
  dueDateContainer: {
    marginRight: "50px",
    display: "flex",
    alignItems: "center",
  },
  lightBlueIcon: {
    color: "#12BAE9",
    marginRight: "10px",
  },
  infoTextLabel: {
    marginRight: "10px",
    color: "#959595",
  },
  attachEvidenceContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  evidenceLocker: {
    marginLeft: "10px",
    color: "#FF1030",
  },
  narrativeContainer: {
    margin: "15px 0px",
    display: "flex",
    flexDirection: "column",
  },
};

const RfiDetails = ({ isFormDisabled, setIsAttributeChanged, userID }) => {
  const dispatch = useDispatch();
  const rootClasses = rootStyles();
  const { assessmentId } = useParams();
  const [resolveChecked, setResolvedChecked] = useState(false);

  const rfiDetails = useSelector(
    (state) => state.rfiListForAssessment.rfiDetails
  );
  const rfiDataAttributes = useSelector(
    (state) => state.rfiListForAssessment.rfiDataAttributes
  );
  const rfiNarratives = useSelector(
    (state) => state.rfiListForAssessment.rfiNarratives
  );

  const { rfiStatus } = rfiConstants;
  const formatToDate = (date) => moment(date).format("DD-MM-yyyy");

  const KafkaTopicUpload = async () => {

    let tempobj = {
      payload: {
        evidenceLogId: "",
        messageDataTime: moment().format("YYYY-MM-DD hh:mm:ss"),
        messageSource: "",
        questionId: rfiDetails.QUESTION_ID,
        regulationId: "",
        rfiId: rfiDetails.RFI_ID,
        ruleId: "",
        sourceDataId: "",
        sourceSystemId: "",
        submissionId: assessmentId,
        uniqueMessageId: "",
        userId: userID
      },
      topicID: 0,
      topicName: window.REACT_APP_KAFKA_RFICOMPLETE_TOPIC

    }

    const response = await uploadDataAction.kafkatopicUpload(tempobj);

  }

  const handleResolveCheckbox = (e) => {
    setResolvedChecked(e.target.checked);
    if (e.target.checked === true) {
      KafkaTopicUpload();
    }
    dispatch(
      actions.updateRfiStatus(
        assessmentId,
        rfiDetails.QUESTION_ID,
        e.target.checked ? rfiStatus.RFI_RESOLVED : rfiStatus.RFI_ASSIGNED,
        rfiDetails.RFI_ID
      )
    );
  };

  const handleFormInputChange = (attributeType, itemKey, value) => {
    dispatch(actions.handleDataEntryChange(attributeType, itemKey, value));
    if (attributeType === "ATTRIBUTE") {
      setIsAttributeChanged((prev) => ({ bool: true, id: [...prev["id"], itemKey] }));
    }
  };

  useEffect(() => {
    setResolvedChecked(rfiDetails?.RFI_STATUS === rfiStatus.RFI_RESOLVED);
  }, [rfiDetails]);

  if (rfiDetails?.length !== 0) {
    return (
      <>
        {/* cover note */}
        <Box style={styles.flexCenter}>
          <InfoOutlinedIcon style={styles.coverNoteIcon} />
          <Typography style={styles.coverNoteText}>
            {langConstant.COVER_NOTE}&nbsp;&#x3a;&nbsp;
          </Typography>
          <Typography>{rfiDetails["Cover Note"]}</Typography>
        </Box>

        {/* mark completed part  */}
        <Box style={styles.flexCenter}>
          {
            <FormControlLabel
              style={styles.markCompletedCheckbox}
              control={
                <Checkbox
                  checked={resolveChecked}
                  color="default"
                  inputProps={{ "aria-label": "Mark as Completed" }}
                  onChange={handleResolveCheckbox}
                  className={rootClasses.greenCheckbox}
                  name="questionState"
                />
              }
              label={langConstant.MARK_AS_COMPLETED}
              labelPlacement="end"
            />
          }

          <Box style={styles.dueDateContainer}>
            <EventIcon style={styles.lightBlueIcon} />
            <span
              style={styles.infoTextLabel}
            >{`${langConstant.DUE_DATE}:`}</span>
            <span>{formatToDate(rfiDetails.RFI_DUE_DATE)}</span>
          </Box>
          <Box style={styles.flexCenter}>
            <PersonOutlineIcon style={styles.lightBlueIcon} />
            <span
              style={styles.infoTextLabel}
            >{`${langConstant.ASSIGNED_BY}:`}</span>
            <span>{rfiDetails["Assigned By"]}</span>
          </Box>
        </Box>

        {/* attachments part */}
        <Box style={styles.attachEvidenceContainer}>
          <AttachEvidence
            style={{
              marginRight: "5px",
            }}
            questionId={rfiDetails.QUESTION_ID}
            submissionId={assessmentId}
            isDisabled={isFormDisabled}
            rfiID={rfiDetails.RFI_ID}
          />
          <EvidenceLocker questionId={rfiDetails.QUESTION_ID} submissionId={assessmentId} />
          <span style={styles.evidenceLocker}>&#42;</span>
          <span style={styles.infoTextLabel}>
            {langConstant.EVIDENCE_ATTACHMENT_MANDATORY}
          </span>
        </Box>

        {/* question details  */}
        <Box style={{ marginTop: "15px" }}>
          <Box
            style={{
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Typography
              variant="h6"
              style={{
                color: "#8E8E8E",
              }}
            >
              {rfiDetails.REGULATORS_QUESTION_REF}&nbsp;&mdash;&nbsp;
            </Typography>
            <Typography
              style={{
                color: "#444",
                fontSize: "18px",
              }}
            >
              {rfiDetails.QUESTION_TEXT}
            </Typography>
          </Box>

          {rfiDataAttributes?.map((dataEntry, index) => {
            return (
              <Box
                key={index}
                style={{
                  margin: "15px 0px",
                }}
              >
                <Typography>{`${dataEntry.DATA_ASSET_ENTITY_NAME}.${dataEntry.DATA_ASSET_ATTRIBUTE_NAME}`}</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder={langConstant.PROVIDE_ANSWER}
                  value={dataEntry.SOURCE_DATA_POINT_VALUE}
                  disabled={isFormDisabled}
                  size="small"
                  onChange={(e) =>
                    handleFormInputChange(
                      "ATTRIBUTE",
                      dataEntry.RFI_ITEM_KEY,
                      e.target.value
                    )
                  }
                />
              </Box>
            );
          })}

          <Box style={styles.narrativeContainer}>
            <Typography>{langConstant.ADD_NARRATIVES}</Typography>
            <Box
              style={{
                display: "flex",
              }}
            >
              <TextField
                style={{
                  flex: 1,
                }}
                id="outlined-basic"
                variant="outlined"
                value={rfiNarratives.NARRATIVE}
                placeholder={langConstant.PROVIDE_NARRATIVE}
                disabled={isFormDisabled}
                onChange={(e) => {
                  handleFormInputChange(
                    "NARRATIVE",
                    rfiNarratives.RFI_ITEM_KEY,
                    e.target.value
                  );
                }}
              />
              <Box
                style={{
                  width: "145px",
                }}
              ></Box>
            </Box>
          </Box>
        </Box>

        <Box
          style={{
            marginTop: "50px",
          }}
        >
          <CommentSection
            direction={"column"}
            question_ID={rfiDetails.QUESTION_ID}
            submission_ID={+assessmentId}
          />
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

RfiDetails.propTypes = {
  isFormDisabled: PropTypes.bool,
  setIsAttributeChanged: PropTypes.func,
  userID:PropTypes.any
};

export default RfiDetails;
