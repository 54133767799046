import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  filterWrapper: {
    display: "inline-flex",
    alignItems: "center",
  },
  filterButton: {
    position: "fixed !important",
    right: "0px",
    top: "50vh",
    backgroundColor: "rgb(240, 20, 47) !important",
    color: "white !important",
    borderRadius: "6px 0px 0px 6px !important",
    zIndex: "100",
    minWidth: "46px",
  },
  filterHeader: {
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: "2px solid black",
  },
  filterSubHeader: {
    fontSize: "15px",
    fontWeight: "bold",
    borderBottom: "1px solid black",
  },
  filterLable: {
    fontWeight: "bold",
    fontSize: "14px",
    paddingBottom: "5px",
  },
  filterSelect: {
    width: "80%",
    marginLeft: "10px",
  },
  btnWrapper: {
    padding: "20px 0",
    textAlign: "right",
  },
  networkFilterWrapper: {
    paddingBottom: "10px",
  },
  btnGraph: {
    backgroundColor: "#124571",
    color: "#ffffff",
    "&:hover": {
      opacity: "0.7",
      backgroundColor: "#124571",
    },
  },
}));
export default useStyles;
