import React, { useEffect, useState } from "react";
import DialogBox from "_components/DialogBox";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import FilterDropdown from "_components/FilterDropdown";
import { useDispatch } from "react-redux";
import AppPagination from "_components/AppPagination";
import moment from "moment";
import EvidenceServices from "../services";
import { alertActions } from "_actions";

const useStyles = makeStyles(() => ({
    stickyColl: {
      position: "sticky",
      right: 0,
    },
    actionWrap: {
      borderRadius: "50%",
      padding: "4px",
      height: "28px",
      width: "28px",
      display: "flex",
      alignItems: "center",
    },
  }));

const RemarksList = ({show, setShow, submissionId, evidenceLogId, questionId}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toggleDialog = () => setShow(false)

    const calculatePageCount = (rows) => {
        return rows.length > 0
            ? Math.round(Math.ceil(rows.length / rowsPerPage))
            : 0;
    };

    const [approversList, setApproversList] = useState([])
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    
    // let remarkDate = moment(remarkData.CREATE_DATE,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD h:mm a")

    const columns = [
        {
          field: "NAME",
          headerName: "Comment By",
          minWidth: 100,
        },
        {
          field: "COMMENT",
          headerName: "Comment",
          format: function formatData(value) {
            let remarkComment = value['COMMENT'].replace("EvidenceID:","")
            remarkComment = remarkComment.substring(remarkComment.indexOf(":") + 1);
            return remarkComment
          },
          minWidth: 135,
        },
        {
          field: "CREATE_DATE",
          headerName: "Comment Date",
          format: (value) => (value['CREATE_DATE'] ? moment(value['CREATE_DATE']).format("DD-MMM-YY") : ""),
          minWidth: 120,
        },
    ];

    const handleChangePage = (event) => {
        setPage(event);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        (async() => {
            const payload = {
                i_SUBMISSION_ID: submissionId,
                i_QUESTION_ID: questionId,
                i_EVIDENCE_LOG_ID: evidenceLogId
            }
            const response = await EvidenceServices.getEvidenceComments(payload)
            if(response.status == 200){
                setApproversList([...response['#result-set-1']])
                setPageCount(calculatePageCount(response['#result-set-1']))
              } else {
                if(response.data && response.data.message)
                {
                  dispatch(
                      alertActions.error(
                        response.data.message.map((item, index) => {
                              return <div key={index}>{item}</div>;
                          })
                      )
                  );
                } else {
                  dispatch(
                      alertActions.error(langConstant.SOMETHING_WENT_WRONG))
                }
              }
        })()
    },[])

    return (
        <DialogBox
            maxWidth={"lg"}
            title={langConstant.REMARKS}
            open={show}
            handleClose={toggleDialog}
            style={{paddingTop:"0px",}}
        >
            <TableContainer
                component={Paper}
                style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderBottomStyle: "none",
                    borderTopStyle: "solid",
                    borderRightStyle: "solid",
                    borderLeftStyle: "solid",
                }}
            >
                <Table className="data-table" aria-label="Regulation List">
                    <TableHead>
                        <TableRow>
                        {columns.map((item, index) => (
                            <TableCell
                                component="th"
                                scope="row"
                                key={"column_" + index + item.field}
                                className={
                                    (item.sticky && classes.stickyColl) + " " + classes.heading
                                }
                                style={{ minWidth: item.minWidth }}
                            >
                                {item.headerName}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                        ? approversList.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                            )
                        : approversList
                        ).map((row, index) => (
                        <TableRow key={index + row[columns[0].field]}>
                            {columns.map((column, index) => {
                            const value = row[column.field];

                            return (
                                <TableCell
                                component="td"
                                scope="row"
                                key={"column_" + index + row[columns[0].field]}
                                className={column.sticky && classes.stickyColl}
                                style={{
                                    wordBreak: "break-word",
                                    wordWrap: "break-word",
                                }}
                                >
                                {column.format ? column.format(row) : value}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                        ))}

                        {approversList.length == 0 && (
                        <TableRow style={{ height: 53 }}>
                            <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={columns.length}
                            >
                            {langConstant.NO_RECORD_FOUND}
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {pageCount > 1 && (
                <Box my={2}>
                <Grid container justify="flex-end">
                    <AppPagination
                        colSpan={3}
                        count={pageCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
                </Box>
            )}
        </DialogBox>
    )
}

RemarksList.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    submissionId: PropTypes.number,
    evidenceLogId: PropTypes.number,
    questionId: PropTypes.string,
};

export default RemarksList;