import React from "react";
import { Divider, Menu, MenuItem, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import BarChartIcon from "@mui/icons-material/BarChart";
import useStyles from "./styles";
import langConstant from "_lang";
import DoneIcon from "@mui/icons-material/Done";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";

const CompareDetailMenu = ({
  anchorEl,
  open,
  handleClose,
  comparisonId,
  setCurrentOpenDialog,
  currentOpenDialog,
}) => {
  const classes = useStyles();

  const openDialog = (name) => {
    if (name === currentOpenDialog) return;
    setCurrentOpenDialog(name);
  };

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuItem
        className={classes.menuLabel}
        onClick={() =>
          window.open(`/comparison-chart/${comparisonId}`, "_blank")
        }
      >
        <span style={{ marginRight: "5px", color: "black" }}>
          {langConstant.COMPARISON_CHART}
        </span>
        <Tooltip title={langConstant.COMPARISON_CHART} placement="top">
          <BarChartIcon
            style={{ color: "#000000", fontSize: "16px", margin: "0 auto" }}
          />
        </Tooltip>
      </MenuItem>
      <MenuItem
        className={classes.menuLabel}
        onClick={() =>
          window.open(`/comparison-graph/${comparisonId}`, "_blank")
        }
      >
        <span style={{ marginRight: "5px", color: "black" }}>
          {langConstant.COMPARISON_GRAPH}
        </span>
        <Tooltip title={langConstant.COMPARISON_GRAPH} placement="top">
          <AutoGraphIcon
            style={{ color: "#000000", fontSize: "16px", margin: "0 auto" }}
          />
        </Tooltip>
      </MenuItem>
      <MenuItem
        className={classes.menuLabel}
        onClick={() =>
          window.open(`/comparison-status/${comparisonId}`, "_blank")
        }
      >
        Comparison Status
      </MenuItem>
      <MenuItem
        className={classes.menuLabel}
        onClick={() =>
          window.open(`/comparison-statistics/${comparisonId}`, "_blank")
        }
      >
        Comparison Statistics
      </MenuItem>
      <MenuItem disabled={true} className={classes.menuLabel}>
        Comparison Assistant
      </MenuItem>
      <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
      <MenuItem
        onClick={() => openDialog("viewNotes")}
        className={classes.menuLabel}
      >
        View Notes
      </MenuItem>
      <MenuItem
        onClick={() => openDialog("viewActions")}
        className={classes.menuLabel}
      >
        View Actions
      </MenuItem>
      <MenuItem className={classes.menuLabel}>
        Sentence View{" "}
        <span style={{ marginLeft: "20px" }}>
          <DoneIcon />
        </span>
      </MenuItem>
      <MenuItem disabled={true} className={classes.menuLabel}>
        Sectionwise View
      </MenuItem>
      <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
      <MenuItem disabled={true} className={classes.menuLabel}>
        Show Gaps
      </MenuItem>
      <MenuItem disabled={true} className={classes.menuLabel}>
        Show Contradictions
      </MenuItem>
      <MenuItem disabled={true} className={classes.menuLabel}>
        Show Actions
      </MenuItem>
      <MenuItem disabled={true} className={classes.menuLabel}>
        Show Audit Trail
      </MenuItem>
      <MenuItem disabled={true} className={classes.menuLabel}>
        Show Recommendations
      </MenuItem>
      <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
      <MenuItem disabled={true} className={classes.menuLabel}>
        HeatMap
      </MenuItem>
      <MenuItem disabled={true} className={classes.menuLabel}>
        Semantic Scores
      </MenuItem>
      <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
      <MenuItem disabled={true} className={classes.menuLabel}>
        Generate Recommendation
      </MenuItem>
    </Menu>
  );
};

CompareDetailMenu.propTypes = {
  anchorEl: PropTypes.array,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  comparisonId: PropTypes.string,
  currentOpenDialog: PropTypes.any,
  setCurrentOpenDialog: PropTypes.func,
};

export default CompareDetailMenu;
