import React from "react";
import apiService from "_services/api.service";


export const GetEvidenceForUser = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetEvidenceForUser", { i_USER_ID: userID })
    .then(apiService.handleResponse)
    .then((response) => {
      if (response) {
        return response
      }
    },
      (error) => {
        if (error.response) {
          return error.response
        }
      }
    );

}


export const GetControlsMappedToEvidence = (userID, evidenceTypeID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetControlsMappedToEvidence", { i_USER_ID: userID, i_EVIDENCE_TYPE_ID: evidenceTypeID })
    .then(apiService.handleResponse)
    .then((response) => {
      if (response) {
        return response
      }
    },
      (error) => {
        if (error.response) {
          return error.response
        }
      }
    );

}