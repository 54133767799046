import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import AdminServices from "../../services";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import useStyles from "../../styles";
import langConstant from "_lang";
import SourceSystemsTable from "./SourceSystemsTable";

const SourceSystems = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [systemSourcesData, setSystemSourcesData] = useState([]);
  const [selectedSystemSource, setSelectedSystemSource] = useState(null);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(
        AdminServices.getCustomerDataSources(
          selectedCustomer.value,
          setSystemSourcesData
        )
      );
    }
    setSelectedSystemSource(null);
  }, [selectedCustomer]);

  return (
    <Grid
      className={classes.sourceSystemWrapper}
      style={{ display: "flex", flexDirection: "column", gap: "10px" }}
    >
      {selectedCustomer && selectedCustomer.value ? (
        <SourceSystemsTable
          systemSourcesData={systemSourcesData}
          setSelectedSystemSource={setSelectedSystemSource}
          selectedSystemSource={selectedSystemSource}
          setSystemSourcesData={setSystemSourcesData}
          selectedCustomer={selectedCustomer}
        />
      ) : (
        <Typography
          style={{
            textAlign: "center",
            fontWeight: "bold",
            minHeight: "100px",
            display: " flex",
            alignItems: " center",
            justifyContent: "center",
          }}
        >
          {langConstant.PLEASE_SELECT_CUSTOMER_FIRST}
        </Typography>
      )}
    </Grid>
  );
};

SourceSystems.propTypes = {
  selectedCustomer: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};

export default SourceSystems;
