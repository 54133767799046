import apiService from "_services/api.service";

const putPolicyComparisionReceipes = (data) => {
    return apiService 
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutPolicyComparisonRecipes",{
        i_POLICY_COMPARISON_DETAIL_ID: data.selectedComparisonId,
        i_OLD_POLICYCOMPARISION_ID: data.oldComparisonId,
        i_NEW_POLICYCOMPARISION_ID: data.newComparisonId,
      })
      .then(apiService.handleResponse)
      .then((response) => {
        if(response){
          response['status'] = 200
          return response
        }
      },
      (error) => {
        if(error.response){
          return error.response
        }
      })
}

export default {
    putPolicyComparisionReceipes
}