/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import { alertActions, loadingActions } from "../../../_actions";
import apiService from "../../../_services/api.service";
import { regulationListConstant } from "./constants";

const getRegulationList = (userId, customerId) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRegulations",{
        i_USER_ID: userId,
        i_CUSTOMER_ID: customerId
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#result-set-1"]) {
            dispatch({
              type: regulationListConstant.SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch({ type: regulationListConstant.FAILURE });
            dispatch(alertActions.error("Regulation list not found"));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export default {
  getRegulationList,
};
