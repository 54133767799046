import React from "react";
import { ListItemIcon, Menu, MenuItem, MenuList, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BarChartIcon from "@mui/icons-material/BarChart";
import InsightsIcon from "@mui/icons-material/Insights";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import langConstant from "_lang";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles(() => ({
  contantContaxMenu: {
    padding: "5px 0px 0px 0px",
    "& .MuiListItemIcon-root": {
      minWidth: "unset !important",
    },
    textAlign: "center",
    justifyContent: "space-between",
    display: "flex",
    // padding: "0px 0px",
    outline: "0",
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
}));

export const ActionMenu = ({ anchorMenuEl, onClose, isOpen, menuItems }) => {
  const classes = useStyles();
  const themeDetails = useSelector(getThemeDetails);

  const WrapperButton = ({ item }) => {
    const Icon = item?.icon;
    return (
      <IconButton
        className={classes.actionWrap}
        style={{
          backgroundColor:
            item?.color || themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
        }}
        disabled={item?.isDisabled}
      >
        <Icon
          {...item?.iconProps}
          style={{
            ...item?.iconStyles,
            color: "#fff",
            fontSize: "18px",
            margin: "0 auto",
          }}
        />
      </IconButton>
    );
  };

  WrapperButton.propTypes = {
    item: propTypes.object,
  };

  return (
    <Menu
      anchorEl={anchorMenuEl}
      id="account-menu"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuList className={classes.contantContaxMenu}>
        {menuItems?.map((item, index) => {
          if (item?.component)
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: ".5rem",
                  marginRight: ".75rem",
                }}
                key={index}
              >
                {item?.component}
              </div>
            );
          return (
            <Tooltip key={index} title={item?.tooltipLabel}>
              <MenuItem disabled={item?.isDisabled}>
                {item?.linkTo ? (
                  <ListItemIcon>
                    <Link to={item?.linkTo} className="linkIcon">
                      <WrapperButton item={item} />
                    </Link>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon onClick={item?.onClick}>
                    <WrapperButton item={item} />
                  </ListItemIcon>
                )}
              </MenuItem>
            </Tooltip>
          );
        })}
      </MenuList>
    </Menu>
  );
};

ActionMenu.propTypes = {
  anchorMenuEl: propTypes.any,
  onClose: propTypes.func,
  isOpen: propTypes.bool,
  menuItems: propTypes.arrayOf(
    propTypes.shape({
      onClick: propTypes.func,
      icon: propTypes.any,
      color: propTypes.string,
      iconStyles: propTypes.object,
      iconProps: propTypes.object,
      tooltipLabel: propTypes.string,
      linkTo: propTypes.oneOfType([propTypes.string, propTypes.object]),
      isDisabled: propTypes.bool,
      component: propTypes.any,
    })
  ),
};
