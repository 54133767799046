import DialogBox from "_components/DialogBox";
import React from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { Form, Formik, Field } from "formik";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import ClassificationUtils from "../utils";
import FormTextField from "_components/FormTextField";
import useStyles from "../styles";
import { useDispatch } from "react-redux";
import ClassificationServices from "../services";

const DocumentDialog = ({
  open,
  setOpen,
  selectedDocClassification,
  docClassificationData,
  setDocClassificationData,
  selectedCustomer,
  setSelectedDocClassification,
}) => {
  const handleClose = () => {
    setOpen(false);
    setSelectedDocClassification(null);
  };
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(
      ClassificationServices.putDocumentclassification(
        selectedCustomer.CUSTOMER_ID,
        values,
        docClassificationData,
        setDocClassificationData,
        handleClose
      )
    );
  };

  return (
    <DialogBox
      maxWidth={"sm"}
      title={langConstant.DOCUMENT_CLASSIFICATION}
      open={open}
      handleClose={handleClose}
    >
      <Formik
        initialValues={
          selectedDocClassification
            ? selectedDocClassification
            : ClassificationUtils.docClassificationInitialValues
        }
        validationSchema={ClassificationUtils.docClassificationValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Grid container>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.CLASSIFICATION_NAME}
                  value={values.DOCUMENT_CLASSIFICATION_NAME}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="DOCUMENT_CLASSIFICATION_NAME"
                  errors={errors}
                />
              </Grid>
              <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.ACTIVE}
                </Typography>
                <Field style={{ width: "100%" }} name="ACTIVE">
                  {() => (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={parseInt(values["ACTIVE"])}
                      onChange={(e) => setFieldValue("ACTIVE", e.target.value)}
                      style={{
                        display: "inline",
                      }}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid style={{ textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                {langConstant.UPDATE}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogBox>
  );
};

DocumentDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  selectedDocClassification: PropTypes.object,
  docClassificationData: PropTypes.oneOfType([() => null, PropTypes.array]),
  setDocClassificationData: PropTypes.func,
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
  setSelectedDocClassification: PropTypes.func,
};

export default DocumentDialog;
