import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  formFieldWrapper: {
    padding: "5px 0",
    alignItems: "center",
  },
  formLabel: {
    fontWeight: "bold",
    fontSize: "14px",
    width: "100%",
    marginBottom: "3px",
  },
  select100Width: {
    width: "100%",
  },
  userTableWrapper: {
    marginBottom: "10px",
  },
  iconButton: {
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
    display: "inline-flex !important",
    borderRadius: "50% !important",
    padding: "10px !important",
    cursor: "pointer !important",
  },
}));

export default useStyles;
