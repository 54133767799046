import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  Box,
  Chip,
  Divider,
  Fade,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowForwardIos,
  Check,
  Delete,
  FilePresent,
  Refresh,
} from "@mui/icons-material";
import { readableFileSize } from "_helpers/utils";
import { VariableSizeList } from "react-window";
import { Row, innerElementType } from "./DraggableRow";
import { useWindowResize } from "./userWindowResize";
import CircularProgress from "@mui/material/CircularProgress";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import CheckIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

function getFileExtension(filename) {
  return filename?.slice(((filename?.lastIndexOf(".") - 1) >>> 0) + 2);
}

function getFileNameWithoutExtension(filename) {
  return filename?.slice(0, filename?.lastIndexOf("."));
}

const EditableFilenameRow = ({
  file,
  onFileNameUpdate,
  onDeleteFile,
  readOnly = true,
  setFilteredFiles,
  fileStatus = [],
}) => {
  const [documentName, setDocumentName] = React.useState(
    file?.document_name ? file?.document_name : file?.POLICY_NAME || ""
  );
  const [isOpen, setIsOpen] = React.useState(
    file?.children?.length ? true : false
  );
  const isUpdated = Boolean(documentName !== file?.document_name);
  const listRef = React.useRef();
  const sizeMap = React.useRef({});
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = { ...sizeMap?.current, [index]: size };
    listRef.current?.resetAfterIndex?.(index);
  }, []);
  const getSize = (index) => sizeMap?.current?.[index] || 50;
  const [windowWidth] = useWindowResize();

  const currentStatus = fileStatus?.find(
    (item) => item?.id === file?.uuid
  )?.status;

  const resetFileName = () => {
    setDocumentName(getFileNameWithoutExtension(file?.name));
  };

  const handleFileNameUpdate = () => {
    // const updatedFileName = `${documentName}.${fileExtension}`;
    onFileNameUpdate(file, documentName);
  };

  const updateOnKeyDown = (e) => {
    if (e.key === "Enter" && documentName?.length && isUpdated)
      handleFileNameUpdate();
  };

  const ExtensionBadge = getFileExtension(file?.name) && (
    <Chip
      sx={{
        textTransform: "uppercase",
        color: "#d32f2f",
        fontWeight: "600",
        px: 1,
      }}
      label={getFileExtension(file?.name)}
      size="small"
      component="span"
    />
  );

  const RenderUploadStatus = () => {
    return (
      <>
        <Box sx={{ width: "20px" }}>
          {currentStatus === "running" ? (
            <CircularProgress color="primary" size="small" />
          ) : currentStatus === "init" ? (
            <HourglassEmptyIcon size="small" />
          ) : currentStatus === "failed" ? (
            <SmsFailedIcon color="error" size="small" />
          ) : (
            <CheckIcon color="success" size="small" />
          )}
        </Box>
        <Divider sx={{ my: 0.5 }} flexItem orientation="vertical" />
      </>
    );
  };

  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 1,
        border: "1px solid #0000001f",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          gap: 2,
          alignItems: "center",
        }}
        // onClick={() => setIsOpen(!isOpen)}
      >
        {!readOnly && (
          <>
            <RenderUploadStatus />
            <IconButton
              title="Delete"
              onClick={() => onDeleteFile(file)}
              size="small"
            >
              <Delete color="error" fontSize="small" />
            </IconButton>
            <Divider sx={{ my: 0.5 }} flexItem orientation="vertical" />
          </>
        )}
        <Typography color="gray">{file?.id}</Typography>
        <FilePresent color="info" fontSize="medium" />
        {readOnly ? (
          <Tooltip
            title={
              file?.document_name ? file?.document_name : file?.POLICY_NAME
            }
          >
            <Typography
              sx={{
                display: "inline-flex",
                overflow: "hidden",
                gap: 1,
                flex: 1,
              }}
            >
              <span
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {file?.document_name ? file?.document_name : file?.POLICY_NAME}
              </span>{" "}
              {ExtensionBadge}
            </Typography>
          </Tooltip>
        ) : (
          <>
            <TextField
              size="small"
              variant="outlined"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              // onClick={(e) => e.stopPropagation()}
              sx={{ flex: 1, border: "none" }}
              onKeyDown={updateOnKeyDown}
            />
            {ExtensionBadge}
          </>
        )}
        {!readOnly && (
          <Box display="flex" gap={0.5}>
            <Fade in={isUpdated} mountOnEnter unmountOnExit>
              <IconButton
                title="Save changes"
                disabled={!documentName}
                opacity={documentName ? 1 : 0.5}
                size="small"
                onClick={handleFileNameUpdate}
              >
                <Check color="success" fontSize="small" />
              </IconButton>
            </Fade>
            <Fade in={isUpdated} mountOnEnter unmountOnExit>
              <IconButton
                title="Reset"
                disabled={!isUpdated}
                opacity={isUpdated ? 1 : 0.5}
                onClick={resetFileName}
                size="small"
              >
                <Refresh color="error" fontSize="small" />
              </IconButton>
            </Fade>
          </Box>
        )}
        <Typography variant="caption" fontSize=".75rem">
          {isNaN(file?.size) ? file?.size : readableFileSize?.(file?.size)}
        </Typography>
        {readOnly && (
          <>
            <Typography variant="caption" fontSize=".75rem">
              0
            </Typography>
            <Typography variant="caption" fontSize=".75rem">
              0
            </Typography>
          </>
        )}

        <IconButton onClick={() => setIsOpen(!isOpen)} size="small">
          <ArrowForwardIos
            fontSize="small"
            style={{
              transition: "all ease-in-out 0.2s",
              transform: `rotate(${isOpen ? "90" : "0"}deg)`,
            }}
          />
        </IconButton>
      </Box>
      <Fade in={isOpen} unmountOnExit mountOnEnter>
        <Box>
          <Box
            mt={1}
            py={1}
            borderTop="1px solid #0000001a"
            display="flex"
            gap={1}
          >
            <Typography
              flex="1"
              variant="caption"
              color="GrayText"
              fontSize=".75rem"
            >
              Original Filename -{" "}
              <strong>{file?.name ? file?.name : file?.POLICY_NAME}</strong>
            </Typography>
            <Divider flexItem orientation="vertical" />
            <Typography
              flex="1"
              variant="caption"
              color="GrayText"
              fontSize=".75rem"
            >
              User - <strong>user@admin</strong>
            </Typography>
          </Box>
          {readOnly && file?.children?.length > 0 && (
            <Box sx={{ width: "100%", height: "100px" }}>
              <AutoSizer>
                {({ height, width }) => (
                  <VariableSizeList
                    ref={listRef}
                    height={height}
                    itemCount={file?.children?.length}
                    itemSize={getSize}
                    width={width}
                    innerElementType={innerElementType}
                    itemData={{
                      items: file?.children,
                      setFilteredFiles: setFilteredFiles,
                      setSize: setSize,
                      windowWidth: windowWidth,
                      file: file,
                    }}
                  >
                    {Row}
                  </VariableSizeList>
                )}
              </AutoSizer>
            </Box>
          )}
        </Box>
      </Fade>
    </Box>
  );
};

EditableFilenameRow.propTypes = {
  file: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  onFileNameUpdate: PropTypes.func,
  onDeleteFile: PropTypes.func,
  setFilteredFiles: PropTypes.func,
  fileStatus: PropTypes.array,
};

export { EditableFilenameRow, getFileExtension, getFileNameWithoutExtension };
