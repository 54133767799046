import { Grid, Typography, IconButton, Button } from "@material-ui/core";
import { ErrorMessage, Field, FieldArray, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { alertActions } from "_actions";
import FilterDropdown from "_components/FilterDropdown";
import { GetUsersByCustomer } from "../../../services"
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import langConstant from "_lang";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from "prop-types";
import { ReactSortable } from "react-sortablejs";
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import CancelIcon from '@mui/icons-material/Cancel';


const ApproversList = ({ approversDataList, setApproversDataList, approversForm }) => {

    const customerID = useSelector((state) => state.authentication.detailedInfo.CUSTOMER_ID)
    const dispatch = useDispatch()
    const approverInitial = { id: 0, role: '', person: '', delegateTo: '', approveStatus: false }
    const [roles, setRoles] = useState([])
    const [approversByRole, setApproversByRole] = useState({})

    useEffect(() => {
        (async() => {
            const response = await GetUsersByCustomer(customerID)
            if(response.status === 200) {
                const approversData = response['#result-set-1'].reduce((r,a) => {
                    r[a['ROLE_ID']] = r[a['ROLE_ID']] || [];
                    r[a['ROLE_ID']].push({value: a['USER_ID'], name: a['FIRST_NAME'] + " " + a['LAST_NAME']});
                    return r;
                }, Object.create(null));
                setApproversByRole(approversData)
                const result = Object.values(
                    response['#result-set-1'].reduce((acc, obj) => ({ ...acc, [obj['ROLE_ID']]: obj }), {})
                );
                setRoles(result.map((item) => {return { value: item['ROLE_ID'], name: item['ROLE_NAME'] }}))
            } else {
                dispatch(
                    alertActions.error(
                        response.data.message.map((item, index) => {
                        return <div key={index}>{item}</div>;
                        })
                    )
                );
            }
        })()
    },[])

    return (
        <Grid style={{padding: '10px 10px', border: '1px solid #bcbcbc', borderRadius: "4px", minHeight: "200px", maxHeight: "300px", overflow: "auto"}}>
            <Grid style={{padding: "10px 0px"}}>
                <ReactSortable
                    list={approversForm.values.approvers}
                    setList={(newState) => {
                        approversForm.setFieldValue("approvers", newState)
                    }}
                >
                    {approversForm.values.approvers.map((item, index) => (
                        <Grid container key={item.id} spacing={2}>
                            <Grid item md={3} xs={6}>
                                <Field name={`${index}.role`}>
                                    {() => (
                                        <FilterDropdown
                                            options={roles}
                                            fieldName={`role_${item.id}`}
                                            value={item.role}
                                            onChange={(field, e) => {
                                                approversForm.setFieldValue(`approvers.${index}.role`, e.target.value)
                                                approversForm.setFieldValue(`approvers.${index}.person`, "")
                                                approversForm.setFieldValue(`approvers.${index}.delegateTo`, "")
                                            }}
                                            icon={false}
                                            label={langConstant.SELECT_ROLE}
                                        />
                                    )}
                                </Field>
                                {approversForm.errors.approvers && approversForm.errors.approvers[index] && approversForm.errors.approvers[index].role && 
                                    <Typography style={{ color: "red" }}>{approversForm.errors.approvers[index].role}</Typography>
                                }
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Field name={`approvers.${index}.person`}>
                                    {() => (
                                        <FilterDropdown
                                            options={approversByRole[approversForm.values.approvers[index]['role']] ? approversByRole[approversForm.values.approvers[index]['role']] : []}
                                            fieldName={`person_${item.id}`}
                                            value={item.person}
                                            onChange={(field, e) => 
                                                approversForm.setFieldValue(`approvers.${index}.person`, e.target.value)
                                            }
                                            icon={false}
                                            label={langConstant.SELECT_PERSON}
                                        />
                                    )}
                                </Field>
                                {approversForm.errors.approvers && approversForm.errors.approvers[index] && approversForm.errors.approvers[index].person && 
                                    <Typography style={{ color: "red" }}>{approversForm.errors.approvers[index].person}</Typography>
                                }
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Field name={`approvers.${index}.delegateTo`}>
                                    {() => (
                                        <FilterDropdown
                                            options={
                                                approversForm.values.approvers[index]['person'] && approversByRole[approversForm.values.approvers[index]['role']] ? 
                                                approversByRole[approversForm.values.approvers[index]['role']].filter((item) => item.value != approversForm.values.approvers[index]['person']) : []
                                            }
                                            fieldName={`delegateTo_${item.id}`}
                                            value={item.delegateTo}
                                            onChange={(field, e) => 
                                                approversForm.setFieldValue(`approvers.${index}.delegateTo`, e.target.value)
                                            }
                                            icon={false}
                                            label={langConstant.SELECT_DELEGATE}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item md={3} xs={6} style={{textAlign: "center"}}>
                                <IconButton
                                    aria-label="Approver Status"
                                    sx={{ fill: "#4c4c4c" }}
                                    size="small"
                                >
                                    <SwapVerticalCircleIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="Approver Status"
                                    color={approversForm.values.approvers[index]['approveStatus'] ? "success": "error"}
                                    size="small"
                                >
                                    {approversForm.values.approvers[index]['approveStatus'] ? <CheckCircleIcon /> : <CancelIcon />}
                                </IconButton>
                                <IconButton
                                    aria-label="Add Approver"
                                    color="success"
                                    onClick={() => {
                                        approversForm.setFieldValue("approvers", [...approversForm.values.approvers, { ...approverInitial, id: approversForm.values.approvers[approversForm.values.approvers.length - 1]['id'] + 1 }])
                                    }}
                                    size="small"
                                >
                                    <ControlPointIcon />
                                </IconButton>
                                {approversForm.values.approvers.length > 1 &&
                                    <IconButton
                                        aria-label="Remove Approver"
                                        color="error"
                                        onClick={() => {
                                            let tempApprovers = [...approversForm.values.approvers]
                                            tempApprovers.splice(index,1)
                                            approversForm.setFieldValue("approvers", [...tempApprovers])
                                        }}
                                        size="small"
                                    >
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>
                                }
                            </Grid>
                        </Grid>      
                    ))}
                </ReactSortable>
            </Grid>
        </Grid>
    )
}

ApproversList.propTypes = {
    approversDataList: PropTypes.array,
    setApproversDataList: PropTypes.func,
    approversForm: PropTypes.any,
};

export default ApproversList;