import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import langConstant from "_lang";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";

const ProgressBar = () => {
  const themeDetails = useSelector(getThemeDetails);
  return (
    <Box
      sx={{
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "100%",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <CircularProgress sx={{ color: themeDetails?.TABLE_HEADER_COLOUR }} />
      <Typography
        style={{ fontSize: "12px", fontWeight: "bold", color: "grey" }}
      >
        {langConstant.PLEASE_WAIT_FETCHING_DATA}
      </Typography>
    </Box>
  );
};

export default ProgressBar;
