import React from "react";
import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import langConstant from "_lang";
import constants from "../ManageQuestions/constants";
import IndividualQuestionConstants from "./constants";

const getQuestionClassification = (customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetQuestionClassification", {
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateRegulatorControlGroup = (data) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send(`/v1/api/proc/DRegulativDB/UpdateRegulatorControlGroup`, {
        i_REGULATION_ID: data.regulationId,
        i_QUESTION_ID: data.questionId,
        i_REGULATOR_CONTROL_GROUP_TEXT: data.control_group,
        i_CUSTOMER_ID: data.customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          dispatch(
            alertActions.success(langConstant.REGULATOR_CONTROL_GROUP_UPDATED)
          );
          dispatch({
            type: constants.UPDATE_REGULATION_CONTROL_GROUP,
            data: data,
          });
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateQuestionClassification = (data) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send(`/v1/api/proc/DRegulativDB/UpdateQuestionClassification`, {
        i_REGULATION_ID: data.regulationId,
        i_QUESTION_ID: data.questionId,
        i_QUESTION_CLASSIFICATION_ID: data.classificationId,
        i_QUESTION_CLASSIFICATION_TEXT: data.classificationText,
        i_CUSTOMER_ID: data.customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          dispatch(
            alertActions.success(langConstant.QUESTION_CLASSIFICATION_UPDATED)
          );
          dispatch({
            type: constants.UPDATE_QUESTION_CLASSIFICATIONS,
            data: data,
          });
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getQuestionInUse = (qID, customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetQuestionInUse", {
      i_CUSTOMER_ID: customerID,
      i_QUESTION_ID: qID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateQuestionActiveStatus = (
  qID,
  customerID,
  decision,
  assessmentFlag
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send(`/v1/api/proc/DRegulativDB/UpdateQuestionActiveStatus`, {
        i_CUSTOMER_ID: customerID,
        i_QUESTION_ID: qID,
        i_ACTIVE_DECISION: decision,
        i_UPDATE_ASSESSMENT_FLAG: assessmentFlag,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          dispatch(alertActions.success(langConstant.QUESTION_STATUS_UPDATED));
          dispatch({
            type: IndividualQuestionConstants.individualQuestionDetails_ACTIVE_STATUS,
            data: decision,
          });
          dispatch({
            type: constants.UPDATE_QUESTION_STATUS,
            data: { QUESTION_ID: qID, STATUS: decision },
          });
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getQuestionPolicyMap = (data, setSentenceMappingData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetQuestionPolicyMap", data)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          setSentenceMappingData(response["#result-set-1"]);
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const putDefaultQuestionResponses = (data) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutDefaultQuestionResponses", data)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          dispatch(
            alertActions.success(
              `${response["#result-set-1"][0]["Row Update Count"]}  Questions Updated with the Response Options.`
            )
          );
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const putQuestionPolicyMap = (data, setSentenceView) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutQuestionPolicyMap", data)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          setSentenceView({ selectedPolicyId: "", isSentenceView: false });
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateRegulationPolicyMap = (
  data,
  setSentenceMappingData,
  sentenceMappingData,
  setSentenceData,
  sentenceData
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateRegulationPolicyMap", data)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          const tempSentenceMappingData = sentenceMappingData.filter(
            (item) =>
              item.POLICY_EXTRACTION_DETAILS_ID !=
              data.i_POLICY_EXTRACTION_DETAILS_ID
          );
          setSentenceMappingData(tempSentenceMappingData);
          const tempSentence = sentenceData.filter(
            (item) =>
              item.POLICY_EXTRACTION_DETAILS_ID !=
              data.i_POLICY_EXTRACTION_DETAILS_ID
          );
          setSentenceData(tempSentence);
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export default {
  getQuestionClassification,
  updateRegulatorControlGroup,
  updateQuestionClassification,
  getQuestionInUse,
  updateQuestionActiveStatus,
  getQuestionPolicyMap,
  putQuestionPolicyMap,
  updateRegulationPolicyMap,
  putDefaultQuestionResponses,
};
