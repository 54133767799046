// OP#2883 - UJA11 & UJA12 - UI Development : Manage Regulators & Regulations List
// reducers for Regulators

import constant from "./constants";

const initialState = {
    regulators: []
};

const updateRegulator = (state, data) => {
    let tempRegulators = [...state.regulators]
    const regulatorIndex = tempRegulators.findIndex((item)=>item.REGULATOR_ID===data.REGULATOR_ID)
    if(regulatorIndex != -1)
    {
        tempRegulators[regulatorIndex] = data
        return {
            ...state,
            regulators: tempRegulators
        }
    } else {
        return state
    }
}

const addRegulator = (state, data) => {
    let tempRegulators = [...state.regulators, data]
    return {
        ...state,
        regulators: tempRegulators
    }
}  

export default function manageRegulators(state = initialState, action) {
    switch (action.type) {
        case constant.GET_AVAILABLE_REGULATORS:
            return {
                ...state,
                regulators: action.payload
            }
        case constant.UPDATE_REGULATOR:
            return updateRegulator(state, action.payload)
        case constant.ADD_REGULATOR:
            return addRegulator(state, action.payload)
        default:
            return state;
    }
}
