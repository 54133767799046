import React, { useEffect, useState } from "react";
import DialogBox from "_components/DialogBox";
import {
  Typography,
  Box,
  Button,
  Switch,
  Grid,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import SaveIcon from "@mui/icons-material/Save";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { PutAuditObservation, UpdateAuditObservation } from "../utils/actions";
import { useDispatch } from "react-redux";
import sessionService from "_services/session.service";
import { setAuditObservations } from "containers/cyber-assessment/Audits/action";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles(() => ({
  width100: {
    width: "100%",
  },
  filedTitle: {
    padding: "0 5px",
  },
  GridContainer: {
    marginBottom: "20px",
  },
  margintop: {
    marginTop: "8px",
  },

  customcard: {
    border: "2px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    borderWidth: "1px",
    padding: "5px",
  },

  PriorityBtnContainer: {
    cursor: "pointer",
    marginRight: "15px",
    "& .Active": {
      display: "flex",
      width: "150px",
      alignItems: "center",
      padding: "10px",
      borderRadius: "15px",
      border: "1px solid #AAAAAA",

      "& .avatar": {
        fontWeight: "600",
        color: "#4B4B4B",
        backgroundColor: "#E2E2E2",
      },

      "&.High": {
        border: "1px solid rgb(255, 35, 102)",
        color: "rgb(255, 35, 102)",

        "& .avatar": {
          backgroundColor: "rgba(255, 35, 102,0.17)",
          color: "rgb(255, 35, 102)",
        },
      },

      "&.Medium": {
        border: "1px solid rgb(255, 200, 0)",
        color: "rgb(255, 200, 0)",

        "& .avatar": {
          backgroundColor: "rgba(255, 200, 0,0.17)",
          color: "rgb(255, 200, 0)",
        },
      },

      "&.Low": {
        border: "1px solid rgb(2, 188, 119)",
        color: "rgb(2, 188, 119)",

        "& .avatar": {
          backgroundColor: "rgba(2, 188, 119,0.17)",
          color: "rgb(2, 188, 119)",
        },
      },
    },
  },
}));

const InitialData = {
  PRIORITY: null,
  RISK: true,
  EXPECTATIONS: "",
  FINDINGS: "",
  RECOMMENDATIONS: "",
};

const ObservationDialogBox = ({
  Controls,
  submissionID,
  questionID,
  questionText,
  miscData,
  setObservationDialog,
  TotalObservationList,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { visible, mode, ObservationList } = miscData;
  const userID = sessionService.getUserId();
  const [formInitialData, setFormInitialData] = useState(InitialData);
  const [controlsArr, setControlsArr] = useState([]);

  const validationSchema = Yup.object().shape({
    PRIORITY: Yup.string().nullable().required("Required"),
    RISK: Yup.bool().required("Required"),
    EXPECTATIONS: Yup.string().trim().nullable().required("Required"),
    FINDINGS: Yup.string().trim().nullable().required("Required"),
    RECOMMENDATIONS: Yup.string().trim().nullable().required("Required"),
  });

  useEffect(() => {
    if (Object.keys(ObservationList).length > 0) {
      if (mode === "edit" || mode === "view") {
        let priority;
        let risk = ObservationList.RISK_ACCEPT === 1 ? true : false;
        let expectations = ObservationList.EXPECTATIONS
          ? ObservationList.EXPECTATIONS
          : "";
        let findings = ObservationList.FINDINGS ? ObservationList.FINDINGS : "";
        let recommendations = ObservationList.RECOMMENDATIONS
          ? ObservationList.RECOMMENDATIONS
          : "";

        switch (ObservationList.CONTROL_RISK_STATUS) {
          case "H":
            priority = "High";
            break;

          case "M":
            priority = "Medium";
            break;

          case "L":
            priority = "Low";
            break;
          default:
            break;
        }

        let tempInitialData = {
          PRIORITY: priority,
          RISK: risk,
          EXPECTATIONS: expectations,
          FINDINGS: findings,
          RECOMMENDATIONS: recommendations,
        };

        setFormInitialData(tempInitialData);
      }
    }
  }, [ObservationList]);

  useEffect(() => {
    if (Controls) {
      let isArray = Array.isArray(Controls);
      if (isArray) {
        setControlsArr(Controls);
      } else {
        let myArray = Controls.split(";");
        setControlsArr(myArray);
      }
    }
  }, [Controls]);

  const handleClose = () => {
    setObservationDialog({ visible: false, mode: "", ObservationList: [] });
  };

  const RenderPriorityBtns = (priority, SelectedPriority, setFieldValue) => {
    const OnPriorityBtnClick = () => {
      if (SelectedPriority === null || SelectedPriority !== priority) {
        setFieldValue("PRIORITY", priority);
      } else {
        setFieldValue("PRIORITY", null);
      }
    };

    return (
      <Box
        className={`${classes.PriorityBtnContainer}`}
        onClick={() => {
          mode !== "view" && OnPriorityBtnClick();
        }}
      >
        <Box
          className={
            "Active " + (SelectedPriority === priority ? SelectedPriority : "")
          }
        >
          <Box>
            <Avatar className={"avatar"}>{priority[0]}</Avatar>
          </Box>
          <Box style={{ marginLeft: "10px" }}>
            <Typography style={{ fontSize: "14px" }}>
              {priority.toUpperCase()}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const ObservationUpdater = (values, mode, updatedObsID) => {
    const data = [...TotalObservationList];

    let dataobj = {
      QUESTION_ID: questionID,
      OBSERVATION_ID:
        mode === "edit" ? ObservationList["OBSERVATION_ID"] : updatedObsID,
      SUBMISSION_ID: submissionID,
      CONTROL_RISK_STATUS: values.PRIORITY[0],
      RISK_ACCEPT: values.RISK ? 1 : 0,
      EXPECTATIONS: values.EXPECTATIONS,
      FINDINGS: values.FINDINGS,
      RECOMMENDATIONS: values.RECOMMENDATIONS,
      USER_ID: userID,
    };

    if (mode === "add") {
      data.push(dataobj);
      return dispatch(setAuditObservations(data));
    }

    if (mode === "edit") {
      let tempdata = data.map((el) => {
        if (el.OBSERVATION_ID === ObservationList["OBSERVATION_ID"]) {
          return dataobj;
        }
        return el;
      });
      return dispatch(setAuditObservations(tempdata));
    }
  };

  const SubmitData = async (values, { resetForm }) => {
    if (mode === "edit") {
      let ObservationObj = {
        i_OBSERVATION_ID: ObservationList["OBSERVATION_ID"],
        i_CONTROL_RISK_STATUS: values.PRIORITY[0],
        i_RISK_ACCEPT: values.RISK,
        i_EXPECTATIONS: values.EXPECTATIONS,
        i_FINDINGS: values.FINDINGS,
        i_RECOMMENDATIONS: values.RECOMMENDATIONS,
      };
      dispatch(UpdateAuditObservation(ObservationObj));
      ObservationUpdater(values, mode);
      handleClose();
    }

    if (mode === "add") {
      let ObservationObj = {
        i_SUBMISSION_ID: submissionID,
        i_QUESTION_ID: questionID.trim(),
        i_CONTROL_RISK_STATUS: values.PRIORITY[0],
        i_RISK_ACCEPT: values.RISK,
        i_EXPECTATIONS: values.EXPECTATIONS,
        i_FINDINGS: values.FINDINGS,
        i_RECOMMENDATIONS: values.RECOMMENDATIONS,
        i_USER_ID: userID,
      };
      const data = await dispatch(PutAuditObservation(ObservationObj));
      if (data["rect"]) {
        ObservationUpdater(values, mode, data["rect"]);
      }
      handleClose();
    }
  };

  return (
    <DialogBox
      title={"Audit Observations"}
      open={visible}
      handleClose={handleClose}
      maxWidth={"md"}
    >
      <Formik
        enableReinitialize={true}
        initialValues={formInitialData}
        validationSchema={validationSchema}
        onSubmit={SubmitData}
      >
        {({ values, setFieldValue, resetForm, errors, touched }) => (
          <Form>
            <Box>
              <Grid container className={classes.GridContainer}>
                <Grid
                  item
                  xs={12}
                  md={7}
                  sm={12}
                  className={classes.GridContainer}
                >
                  <Typography className={classes.filedTitle}>
                    Control Risk Status
                  </Typography>

                  <Field name="PRIORITY">
                    {({ field: { value } }) => (
                      <Box style={{ display: "flex" }}>
                        {RenderPriorityBtns("High", value, setFieldValue)}
                        {RenderPriorityBtns("Medium", value, setFieldValue)}
                        {RenderPriorityBtns("Low", value, setFieldValue)}
                      </Box>
                    )}
                  </Field>

                  <ErrorMessage name="PRIORITY">
                    {(msg) => (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  sm={12}
                  className={classes.GridContainer}
                >
                  <Typography className={classes.filedTitle}>
                    Accept Risk ?
                  </Typography>

                  <Box className={classes.margintop}>
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={values.RISK}
                      onClick={() => setFieldValue("RISK", !values.RISK)}
                      disabled={mode === "view"}
                    />
                    <span style={{ color: values.RISK ? "#33cf4d" : "" }}>
                      {values.RISK ? "YES" : "NO"}
                    </span>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={classes.GridContainer}
                >
                  <Typography className={classes.filedTitle}>
                    Control Name
                  </Typography>

                  <Box
                    className={`${classes.customcard}`}
                    style={{ height: "110px" }}
                  >
                    <PerfectScrollbar>
                      <div>
                        {controlsArr?.map((el, i) => (
                          <div key={`${i}__${el}`}> {el}</div>
                        ))}
                      </div>
                      <div>{questionText}</div>
                    </PerfectScrollbar>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={classes.GridContainer}
                >
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography className={classes.filedTitle}>
                      Expectations
                    </Typography>
                    <ErrorMessage name="EXPECTATIONS">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Box>

                  <Field name="EXPECTATIONS">
                    {({ field: { value } }) => (
                      <TextField
                        variant="outlined"
                        size="small"
                        value={value}
                        onChange={(e) =>
                          setFieldValue("EXPECTATIONS", e.target.value)
                        }
                        error={
                          touched.EXPECTATIONS && Boolean(errors.EXPECTATIONS)
                        }
                        className={`${classes.width100} ${classes.margintop}`}
                        multiline
                        rows={2}
                        disabled={mode === "view"}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={classes.GridContainer}
                >
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography className={classes.filedTitle}>
                      Finding based on evidences provided
                    </Typography>
                    <ErrorMessage name="FINDINGS">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Box>

                  <Field name="FINDINGS">
                    {({ field: { value } }) => (
                      <TextField
                        variant="outlined"
                        size="small"
                        value={value}
                        onChange={(e) =>
                          setFieldValue("FINDINGS", e.target.value)
                        }
                        error={touched.FINDINGS && Boolean(errors.FINDINGS)}
                        className={`${classes.width100} ${classes.margintop}`}
                        multiline
                        rows={2}
                        disabled={mode === "view"}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={classes.GridContainer}
                >
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography className={classes.filedTitle}>
                      Recommendations & Observations
                    </Typography>
                    <ErrorMessage name="RECOMMENDATIONS">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Box>

                  <Field name="RECOMMENDATIONS">
                    {({ field: { value } }) => (
                      <TextField
                        variant="outlined"
                        size="small"
                        value={value}
                        onChange={(e) =>
                          setFieldValue("RECOMMENDATIONS", e.target.value)
                        }
                        error={
                          touched.RECOMMENDATIONS &&
                          Boolean(errors.RECOMMENDATIONS)
                        }
                        className={`${classes.width100} ${classes.margintop}`}
                        multiline
                        rows={2}
                        disabled={mode === "view"}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={classes.GridContainer}
                >
                  {mode !== "view" && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      type="submit"
                      startIcon={<SaveIcon />}
                    >
                      {mode === "edit" ? "UPDATE" : "SAVE"}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </DialogBox>
  );
};

ObservationDialogBox.propTypes = {
  Controls: PropTypes.any,
  submissionID: PropTypes.any,
  questionID: PropTypes.any,
  questionText: PropTypes.any,
  miscData: PropTypes.object,
  setObservationDialog: PropTypes.func,
  TotalObservationList: PropTypes.array,
};

export default ObservationDialogBox;
