import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Button
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PropTypes from "prop-types";
import useStyles from "../../styles";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import SearchIcon from "@mui/icons-material/Search";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { useSelector } from "react-redux";

const PolicyMapTable = ({
  policyMappingData,
  setSentenceView,
  handleClose,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const [filters, setFilters] = useState({ text: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredPolicy, setFilteredPolicy] = useState(policyMappingData);

  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [pageCount, setPageCount] = useState(
    calculatePageCount(policyMappingData)
  );

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredPolicy.length > 0
        ? Math.round(Math.ceil(filteredPolicy.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
  };

  useEffect(() => {
    const tempData = filterData();
    setFilteredPolicy(tempData);
    setPageCount(calculatePageCount(tempData));
    setPage(0);
  }, [filters]);

  const filterData = () => {
    let data = [...policyMappingData];
    if (filters.text) {
      data = data.filter((policy) => policy.POLICY_NAME.includes(filters.text));
    }
    return data;
  };

  return (
    <React.Fragment>
      <Grid style={{ display: "flex", marginBottom: "15px" }}>
        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
          Search By Policy Name
        </Typography>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          id="searchText"
          label=""
          name="searchText"
          placeholder={langConstant.FILTER_TABLE}
          value={filters.text}
          onChange={(e) =>
            setFilters((prevState) => ({
              ...prevState,
              text: e.target.value.trim(),
            }))
          }
          InputProps={{
            autoComplete: "off",
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <TableContainer component={Paper}>
        <Table
          aria-label={langConstant.SENTENCE_MAPPING}
          className="data-table"
        >
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">
                {langConstant.POLICY_NAME}
              </TableCell>
              <TableCell component="th" scope="row" style={{ width: "150px" }}>
                {langConstant.ACTIONS}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredPolicy.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredPolicy
            ).map((policy, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ cursor: "pointer" }}
                  >
                    {policy.POLICY_NAME}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{
                      cursor: "pointer",
                      width: "150px",
                    }}
                  >
                    <IconButton
                      className={classes.iconButton}
                      style={{ marginRight: "10px" }}
                    >
                      <Tooltip title={langConstant.POLICY_MAPPING}>
                        <RemoveRedEyeIcon
                          fontSize="small"
                          onClick={() =>
                            setSentenceView({
                              isSentenceView: true,
                              selectedPolicyId: policy.POLICY_ID,
                            })
                          }
                          style={{ color: "white" }}
                        />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            {filteredPolicy.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell style={{ textAlign: "center" }} colSpan={2}>
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box my={2}>
        <Grid container justify="flex-end">
          <Grid style={{ paddingBottom: "5px" }}>
            <SelectPageRange
              selectedRange={rowsPerPage}
              handleChange={handleSelectPageRange}
            />
          </Grid>
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Box>
      <Box my={2}>
        <Grid container justify="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            size="small"
          >
            {langConstant.CLOSE}
          </Button>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

PolicyMapTable.propTypes = {
  policyMappingData: PropTypes.array,
  setSentenceView: PropTypes.func,
  handleClose: PropTypes.func,
};

export default PolicyMapTable;
