// OP#3052 - UJC5.1 - Assign Assessment - Extension Features
// main index file

import React, { useEffect, useState } from "react";
import Step1 from "./components/Step1/Step1"
import Step2 from "./components/Step2/Step2"
import { useParams , useHistory } from "react-router-dom";
import { GetAssignedAssessments } from "./services"
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";

const AssignAssessment = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(Object.keys(params).length > 0 ? 1 : 0);
  const isEdit = Object.keys(params).length > 0 ? true : false
  const [mode, setMode] = useState("");
  const [finalQuestionsArray, setFinalQuestionsArray] = useState([]);
  const [EntityAndRegulationData, setEntityAndRegulationData] = useState({ entity: [], regulation: {} });
  const [FinalDomainAndQuestionList, setFinalDomainAndQuestionList] = useState({});
  const [SelectedDomainKeywords , setSelectedDomainKeywords] = useState([]);
  const [approversDataList, setApproversDataList] = useState([]);

  const getDataForAssignedAssessments = async () => {
    const data = await GetAssignedAssessments(params.id);
 
    if (data["#result-set-1"].length === 0 && data["#result-set-2"].length === 0 && data["#result-set-3"].length === 0) {

      dispatch(alertActions.error("Invalid assessment"));
      history.push("/manage-assignments");

    }

    if (data["#result-set-1"] !== undefined) {
      setFinalQuestionsArray(data["#result-set-1"]);
    }

    if (data["#result-set-2"] !== undefined && data["#result-set-3"] !== undefined) {
      setEntityAndRegulationData({ entity: data["#result-set-2"], regulation: data["#result-set-3"][0] });
    }

    if(data['#result-set-4'] && data['#result-set-4'] !== undefined) {
      let sortedData = data['#result-set-4'].sort((a,b) => a.SEQUENCE_ID - b.SEQUENCE_ID)
      sortedData = sortedData.map((item, index) => {
        return { 
          id: index, 
          role: item['ROLE_ID'], 
          person: item['USER_ID'], 
          delegateTo: item['DELEGATE_USER_ID'] == 0 ? '' : item['DELEGATE_USER_ID'], 
          approveStatus: item['APPROVAL_STATUS'] ? item['APPROVAL_STATUS'] : false
        }
      })
      setApproversDataList(sortedData)
    }

  }

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      getDataForAssignedAssessments();
    }
  }, [params]);


  return (
    <>
      {
        activePage === 0
          ? (<Step1
            setFinalDomainAndQuestionList={setFinalDomainAndQuestionList}
            FinalDomainAndQuestionList={FinalDomainAndQuestionList}
            mode={mode}
            setMode={setMode}
            setActivePage={setActivePage}
            finalQuestionsArray={finalQuestionsArray}
            setFinalQuestionsArray={setFinalQuestionsArray}
            EntityAndRegulationData={EntityAndRegulationData}
            setEntityAndRegulationData={setEntityAndRegulationData}
            setSelectedDomainKeywords={setSelectedDomainKeywords}
            SelectedDomainKeywords={SelectedDomainKeywords}
          />)
          : (<Step2
            FinalDomainAndQuestionList={FinalDomainAndQuestionList}
            mode={mode}
            setMode={setMode}
            setActivePage={setActivePage}
            finalQuestionsArray={finalQuestionsArray}
            EntityAndRegulationData={EntityAndRegulationData}
            isEdit={isEdit}
            approversDataList={approversDataList}
            setApproversDataList={setApproversDataList}
          />)
      }
    </>
  )
}

export default AssignAssessment