import React from "react";
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";
import { alertActions } from "_actions";
import { getEmailHtmlTemplate } from "_helpers/utils";

const AwsEmailService = async (
  AWSSecretKey,
  AWSAccessKey,
  EmailDataObj,
  UserDataObj,
  templateData,
  templateName,
  dispatch,
  subject = ""
) => {
  const sesClient = new SESClient({
    region: "us-east-1",
    credentials: { accessKeyId: AWSAccessKey, secretAccessKey: AWSSecretKey },
  });

  const HtmlTemplate = getEmailHtmlTemplate(
    templateName,
    UserDataObj,
    EmailDataObj,
    templateData
  );

  if (templateName === "Assessment_Assigned") {
    subject = EmailDataObj.EmailTitle;
  }

  if (templateName === "RFI_Email") {
    subject = templateData.title + UserDataObj.QuestionID;
  }

  if (templateName === "RFI_Update_Email") {
    subject = templateData.title + UserDataObj.QuestionID;
  }

  const EmailObj = {
    Destination: {
      ToAddresses: EmailDataObj.EmailTo,
      CcAddresses: EmailDataObj.EmailCC,
      BccAddresses: EmailDataObj.EmailBCC,
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data: HtmlTemplate,
        },
      },
      Subject: {
        Charset: "UTF-8",
        Data: subject,
      },
    },
    Source: EmailDataObj.EmailSourceAddress,
  };

  const sendEmailCommand = new SendEmailCommand(EmailObj);
  try {
    return await sesClient.send(sendEmailCommand);
  } catch (e) {
    dispatch(alertActions.error("Failed to send email. " + e));
    console.log("Failed to send email. ", e);
    return e;
  }
};

export default AwsEmailService;
