import React, { useCallback } from "react";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import "./styles.css";
import Row from "./components/row";
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
} from "./components/helpers";
import "./styles.css";
import { updateComponents, updateLayout } from "./actions";

import { SIDEBAR_ITEM, COMPONENT, COLUMN } from "./components/constants";
import { generateRandomId } from "./components/helpers";
import { useDispatch, useSelector } from "react-redux";

const ComplianceCenter = () => {
  const layout = useSelector((state) => state.complianceReducer.layoutData);
  const components = useSelector((state) => state.complianceReducer.components);

  const dispatch = useDispatch();

  const handleDrop = useCallback(
    (dropZone, item) => {
      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

      const newItem = { id: item.id, type: item.type };
      if (item.type === COLUMN) {
        newItem.children = item.children;
      }

      // sidebar into
      if (item.type === SIDEBAR_ITEM) {
        // 1. Move sidebar item into page
        const newComponent = {
          id: generateRandomId(),
          ...item.component,
        };
        const newItem = {
          id: newComponent.id,
          type: COMPONENT,
        };
        dispatch(
          updateComponents({
            ...components,
            [newComponent.id]: newComponent,
          })
        );
        dispatch(
          updateLayout(
            handleMoveSidebarComponentIntoParent(
              layout,
              splitDropZonePath,
              newItem
            )
          )
        );
        return;
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          dispatch(
            updateLayout(
              handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)
            )
          );
          return;
        }

        // 2.b. OR move different parent
        // TODO FIX columns. item includes children
        dispatch(
          updateLayout(
            handleMoveToDifferentParent(
              layout,
              splitDropZonePath,
              splitItemPath,
              newItem
            )
          )
        );
        return;
      }

      // 3. Move + Create
      dispatch(
        updateLayout(
          handleMoveToDifferentParent(
            layout,
            splitDropZonePath,
            splitItemPath,
            newItem
          )
        )
      );
    },
    [layout, components]
  );

  const renderRow = (row, currentPath) => {
    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
      />
    );
  };

  return (
    <DndProvider backend={Backend}>
      {layout.map((row, index) => {
        const currentPath = `${index}`;
        return (
          <React.Fragment key={row.id}>
            {renderRow(row, currentPath)}
          </React.Fragment>
        );
      })}
    </DndProvider>
  );
};

export default ComplianceCenter;
