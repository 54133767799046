/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  recordLength: {
    marginLeft: "20px",
    padding: "2px 5px",
    backgroundColor: ({ actionButtonColor }) => `${actionButtonColor} !important`,
    color: "white",
    borderRadius: "5px",
  },
  stickyColl: {
    position: "sticky",
    right: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  tableFilterText: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  policyMapDialogHeader: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  loadMore: {
    textAlign: "center",
    padding: "20px 0 !important",
    borderTop: "0 !important",
    "& span": {
      backgroundColor: "rgb(18, 69, 113)",
      color: "#fff",
      padding: "7px 15px",
      borderRadius: "5px",
      display: "inline-block",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#02335d",
      },
    },
  },
  networkFilterWrapper: {
    paddingBottom: "10px",
  },
  filterHeader: {
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: "2px solid black",
  },
  filterSubHeader: {
    fontSize: "15px",
    fontWeight: "bold",
    borderBottom: "1px solid black",
  },
  checkboxStyle: {
    width: "50px",
  },
  optionsStyle: {
    display: "flex !important",
    justifyContent: "space-between",
  },
  labelStyle: {
    paddingRight: "10px",
    borderRight: "2px solid rgba(0 0 0 / 0.3)",
    width: "90%",
  },
  filterSelect: {
    width: "80%",
    marginLeft: "10px",
  },
  filterSlider: {
    width: "70% !important",
    marginLeft: "25px",
  },
  filterLable: {
    fontWeight: "bold",
    fontSize: "14px",
    paddingBottom: "5px",
  },
  noData: {
    fontSize: "18px",
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    marginTop: "50px",
  },
  filterButton: {
    position: "fixed !important",
    right: "0px",
    top: "50vh",
    backgroundColor: "rgb(240, 20, 47) !important",
    color: "white !important",
    borderRadius: "6px 0px 0px 6px !important",
    zIndex: "100",
    minWidth: "46px",
  },
}));
export default styles;
