import {
  Drawer,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import langConstant from "_lang";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import sessionService from "_services/session.service";
import PolicyServices from "../../../../../PolicyExplorer/services";
import { alertActions, loadingActions } from "_actions";
import { useDispatch, useSelector } from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";
import { downloadFile } from "_helpers/utils";

const useStyles = makeStyles(() => ({
  inputProfileField: {
    padding: "5px 7px !important",
    width: "100%",
    color: "#000",
  },
  applyBtn: {
    fontSize: "10px !important",
    margin: "0px 10px !important",
    padding: "0px 0px !important",
  },
  vectorClass: {
    padding: "20px 10px",
    width: "100%",
  },
  decisionClass: {
    padding: "20px 10px",
    width: "100%",
  },
  labelClass: {
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
  },
}));

const FilterDrawer = ({
  show,
  setShow,
  autoDecisionScore,
  setAutoDecisionScore,
  handleVectorsChange,
  sentenceFilter,
  handleSentenceChange,
  vectorsFilter,
  autoDecisionData,
  comparisonDetail,
  thresholdvalue,
  setDecisionChangesFlag,
  decisionChangesFlag,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const userID = sessionService.getUserId();

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const dispatch = useDispatch();

  const [decisionData, setDecisionData] = useState({
    1: null,
    2: null,
    3: null,
    4: null,
  });

  useEffect(() => {
    autoDecisionData.map((item) => {
      setDecisionData((prevState) => ({
        ...prevState,
        [item["DECISION_TYPE_ID"]]: item["MAX_AUTOSCORE"],
      }));
    });
  }, [autoDecisionData]);

  const autoDecisionField = [
    { name: "acceptScore", value: 1, label: langConstant.AUTO_ACCEPT_SCORE },
    { name: "rejectScore", value: 2, label: langConstant.AUTO_REJECT_SCORE },
    { name: "discardScore", value: 4, label: langConstant.AUTO_DISCARD_SCORE },
  ];

  const vectorsFilterList = [
    { name: "KEYWORDS_VECTORIZED", label: langConstant.SPEED_READING },
    { name: "ENTITIES", label: langConstant.IMPORTANT_ENTITIES },
    { name: "KEYWORDS_NGRAM", label: langConstant.KEYWORDS_PHRASES },
    { name: "NONE", label: langConstant.NONE },
  ];

  const sentenceFilterList = [
    { name: "top_2", label: langConstant.TOP_2_SENTENCES },
    { name: "top_5", label: langConstant.TOP_5_SENTENCES },
    { name: "all", label: langConstant.ALL_SENTENCES },
  ];

  const detailList = [
    { label: langConstant.NAME, value: comparisonDetail["COMPARISON_NAME"] },
    {
      label: langConstant.FILE_NAME,
      value: comparisonDetail["COMPARISON_FILE_NAME"],
      format: function formatData(name) {
        return (
          <Tooltip title={name}>
            <span
              onClick={() => {
                downloadFile(
                  comparisonDetail["STORAGE_FOLDER_URL"],
                  name,
                  docStorageType,
                  docStorageIP,
                  docStorageURL
                );
              }}
            >
              <DescriptionIcon />
            </span>
          </Tooltip>
        );
      },
    },
    {
      label: langConstant.ACTIVE,
      value: comparisonDetail["ACTIVE"] == true ? "YES" : "NO",
    },
    { label: langConstant.THRESHOLD, value: thresholdvalue },
    {
      label: langConstant.ARCHIVED,
      value: comparisonDetail["ARCHIVED"] == "1" ? "YES" : "NO",
    },
  ];

  const applyAutoDecision = async (decisionType) => {
    const data = {
      i_POLICYCOMPARISONID: id,
      i_Decision_Type: decisionType,
      i_USER_ID: userID,
    };
    if (decisionType == "1") {
      data["i_Score_Value"] = autoDecisionScore.acceptScore;
    } else if (decisionType == "2") {
      data["i_Score_Value"] = autoDecisionScore.rejectScore;
    } else if (decisionType == "4") {
      data["i_Score_Value"] = autoDecisionScore.discardScore;
    }
    if (data["i_Score_Value"]) {
      dispatch(loadingActions.start());
      const response = await PolicyServices.putAutoPolicyComparisonDecision(
        data
      );
      dispatch(loadingActions.end());
      if (response.status === 200) {
        setDecisionChangesFlag(!decisionChangesFlag);
        dispatch(alertActions.success("Auto Decision Successfully Applied"));
        setDecisionData((prevState) => ({
          ...prevState,
          [decisionType]: data["i_Score_Value"],
        }));
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    } else {
      dispatch(
        alertActions.error(langConstant.PLEASE_ADD_VALUE_FOR_AUTO_DECISION)
      );
    }
  };

  return (
    <Drawer anchor={"right"} open={show} onClose={() => setShow(false)}>
      <Grid container style={{ width: "350px", padding: "30px 10px" }}>
        <Typography
          className={classes.labelClass}
          style={{ fontSize: "20px", borderBottom: "1px solid black" }}
        >
          {langConstant.COMPARISON_ACTIONS}
        </Typography>
        <Grid className={classes.decisionClass}>
          {autoDecisionField.map((field, index) => {
            return (
              <Grid key={index}>
                <Typography style={{ fontWeight: "500", padding: "12px 0px" }}>
                  {field["label"]}{" "}
                  {field["value"] == 1 ? <>&gt;=</> : <>&lt;=</>}{" "}
                </Typography>
                <Grid container style={{ textAlign: "right" }}>
                  <Grid item md={3} xs={6}>
                    <TextField
                      variant="outlined"
                      type="number"
                      size="small"
                      style={{ width: "100%" }}
                      placeholder={
                        decisionData[field["value"]]
                          ? decisionData[field["value"]].toString()
                          : ""
                      }
                      InputProps={{
                        classes: { input: classes.inputProfileField },
                      }}
                      onChange={(e) =>
                        setAutoDecisionScore((prevState) => ({
                          ...prevState,
                          [field["name"]]: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Button
                      className={classes.applyBtn}
                      variant="contained"
                      color="primary"
                      style={{ width: "100%", height: "30px" }}
                      onClick={() => applyAutoDecision(field["value"])}
                    >
                      {langConstant.APPLY}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Typography
          className={classes.labelClass}
          style={{ fontSize: "20px", borderBottom: "1px solid black" }}
        >
          {langConstant.KEYWORDS_FILTER}
        </Typography>
        <Grid className={classes.vectorClass}>
          {vectorsFilterList.map((item, index) => {
            return (
              <Grid key={index}>
                <Radio
                  checked={vectorsFilter === item["name"]}
                  onChange={handleVectorsChange}
                  value={item["name"]}
                  name="radio-buttons"
                  inputProps={{ "aria-label": item["label"] }}
                  sx={{
                    "&.Mui-checked": {
                      color: "#124571",
                    },
                  }}
                />
                <Typography
                  style={{
                    display: "inline",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  {item["label"]}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Typography
          className={classes.labelClass}
          style={{ fontSize: "20px", borderBottom: "1px solid black" }}
        >
          {langConstant.SENTENCE_FILTER}
        </Typography>
        <Grid className={classes.vectorClass}>
          {sentenceFilterList.map((item, index) => {
            return (
              <Grid key={index}>
                <Radio
                  checked={sentenceFilter === item["name"]}
                  onChange={handleSentenceChange}
                  value={item["name"]}
                  name="radio-buttons"
                  inputProps={{ "aria-label": item["label"] }}
                  sx={{
                    "&.Mui-checked": {
                      color: "#124571",
                    },
                  }}
                />
                <Typography
                  style={{
                    display: "inline",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  {item["label"]}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Typography
          className={classes.labelClass}
          style={{ fontSize: "20px", borderBottom: "1px solid black" }}
        >
          {langConstant.COMPARISON_DETAIL}
        </Typography>
        <Grid style={{ textAlign: "center" }}>
          <TableContainer>
            <Table style={{ width: "325px" }}>
              <TableBody>
                {detailList.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ fontWeight: "bold" }}>
                        {item["label"]}
                      </TableCell>
                      <TableCell style={{ wordBreak: "break-all" }}>
                        {item.format
                          ? item.format(item["value"])
                          : item["value"]}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  autoDecisionScore: PropTypes.object,
  setAutoDecisionScore: PropTypes.func,
  handleVectorsChange: PropTypes.func,
  handleSentenceChange: PropTypes.func,
  vectorsFilter: PropTypes.string,
  sentenceFilter: PropTypes.string,
  autoDecisionData: PropTypes.array,
  comparisonDetail: PropTypes.object,
  thresholdvalue: PropTypes.number,
  setDecisionChangesFlag: PropTypes.func,
  decisionChangesFlag: PropTypes.bool,
};

export default FilterDrawer;
