import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { Button } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const AlertDialog = ({
  open,
  handleClose,
  buttonText,
  onButtonClick,
  dialogText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
          {dialogText}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onButtonClick}>
          {buttonText}
        </Button>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  handleClose: PropTypes.func,
  buttonText: PropTypes.string,
  open: PropTypes.bool,
  onButtonClick: PropTypes.func,
  dialogText: PropTypes.string,
};

export default AlertDialog;
