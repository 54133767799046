/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import constant from "./constants";
const initialState = [];
const initiateAssessmentDetails = (state = initialState, action) => {
  switch (action.type) {
    case constant.initiateAssessmentConstant_GET:
      return action.data;
    case constant.initiateAssessmentConstant_UPDATE:
      return action.data;
    case constant.initiateAssessmentConstant_FAILURE:
      return [];
    case constant.initiateAssessmentConstant_UPDATE_SINGLE_ITEM:
      return state.map((item) => {
        return item.id == action.data.id ? action.data : item;
      });

    case constant.initiateAssessmentConstant_Add_DQ_PROFILE:
      return state.map((item) => {
        return item.id == action.data.id
          ? { ...item, dqProfile: action.data.profile }
          : item;
      });
    case constant.initiateAssessmentConstant_UPDATE_REMARKS:
      return state.map((item) => {
        if (item.id == action.data) {
          let data = item;
          data.NUM_OF_COMMENTS = data.NUM_OF_COMMENTS + 1;
          return data;
        } else {
          return item;
        }
      });
    case constant.changeQuestionStatus:
      return state.map((item) => {
        if (
          item.REGULATORS_QUESTION_REF == action.data.REGULATORS_QUESTION_REF
        ) {
          let newQuestion = item;
          newQuestion.QUESTION_STATUS = action.data.QUESTION_STATUS;
          //*  Changed question state locally
          return newQuestion;
        } else {
          //* Didn't change question state locally
          return item;
        }
      });
    case constant.initiateAssessmentConstant_EMPTY:
      return [];
    default:
      return state;
  }
};

const initialStateRFI = [];
const initiateRfiDetails = (state = initialStateRFI, action) => {
  switch (action.type) {
    case constant.initiateRfiConstant_GET:
      // return action.data;
      return { ...state, ...action.data }
    case constant.createRfi:
      //* adding RFI to state
      return {
        rfiItems: [...state.rfiItems, action.data],
        rfiGroups: state.rfiGroups,
      };
    case constant.deleteRfi:
      return {
        rfiItems: state.rfiItems.filter(
          (item) => item.REGULATORS_QUESTION_REF !== action.data
        ),
        rfiGroups: state.rfiGroups,
      };
    case constant.changeRfiStatus:
      return {
        rfiItems: state.rfiItems.map((item) => {
          if (
            item.REGULATORS_QUESTION_REF == action.data.REGULATORS_QUESTION_REF
          ) {
            let newRfi = item;
            newRfi.QUESTION_STATUS = action.data.QUESTION_STATUS;
            newRfi.STATUS = action.data.STATUS;
            return newRfi;
          } else {
            return item;
          }
        }),
        rfiGroups: state.rfiGroups,
      };
    case constant.changeRfiDueDate:
      return {
        rfiItems: state.rfiItems.map((item) => {
          if (
            item.REGULATORS_QUESTION_REF == action.data.REGULATORS_QUESTION_REF
          ) {
            let newRfi = item;
            newRfi.RFI_DUE_DATE = action.data.RFI_DUE_DATE;
            return newRfi;
          } else {
            return item;
          }
        }),
        rfiGroups: state.rfiGroups,
      };
    case constant.GetRFIRecipients:
      return { ...state, RFIRecipients: action.data };
    case constant.GetDataAsset:
      return { ...state, DataAsset: action.data };
    case constant.GetEvidences:
      return { ...state, Evidences: action.data };


    default:
      return state;
  }
};

const initialEachQuestionData = []
const EachQuestionData = (state = initialEachQuestionData, action) => {
  switch (action.type) {
    case constant.GET_DATA_ASSET_LIST_SUCCESSC:
      return { ...state, dataAssetsList: action.data };
    default:
      return state;
  }

}

export default { initiateAssessmentDetails, initiateRfiDetails, EachQuestionData };
