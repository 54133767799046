import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, makeStyles, Grid, Button } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Formik, Field, Form } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import PropTypes from "prop-types";
import _ from "lodash"
import SaveIcon from '@mui/icons-material/Save';
import individualQuestionAction from "../actions";
import sessionService from "_services/session.service";


const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "700",
    padding: "0 5px"
  },
  bottomGridContainer: {
    marginTop: "20px",
    height: "calc(100vh - 230px)",
    maxHeight: "calc(100vh - 230px)",
  },
  btnWidth: {
    width: "150px"
  }

}));


const ControlsTab = ({ questionID, fetchdata }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userID = sessionService.getUserId()

  const [formInitialData, setFormInitialData] = useState({ AddControls: [] });
  const [isEditControl, setIsEditControl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alloptions, setalloptions] = useState({ ControlDropDownOptions: [], FilteredSelectedControls: [] });
  const [ControlDropDownOptions, setControlDropDownOptions] = useState([]);
  const [FilteredSelectedControls, setFilteredSelectedControls] = useState([]);
  const [ControlsToBeDeleted, setControlsToBeDeleted] = useState([]);

  const GetControls = async () => {

    const data = await individualQuestionAction.GetQuestionControlsMapping(questionID, userID);

    if (data["#result-set-1"].length > 0) {
      const temp = [...data["#result-set-1"]].map((ele) => ({ ...ele, identifier: "options" }));
      setControlDropDownOptions(temp);
    }

    if (data["#result-set-2"].length > 0) {
      setFilteredSelectedControls(data["#result-set-2"])
    }

    if (data["#result-set-1"].length > 0 && data["#result-set-2"].length > 0) {
      setalloptions({ ControlDropDownOptions: data["#result-set-1"], FilteredSelectedControls: data["#result-set-2"] })
    }

    setIsLoading(false);

  }


  useEffect(() => {
    setIsLoading(true);
    GetControls();
  }, [])


  const DeleteControls = (element, index) => {
    if (element.identifier === undefined) {
      setControlsToBeDeleted((prev) => ([...prev, element]));
    }
    const data = [...FilteredSelectedControls].filter((el) => el.CISO_CONTROL_ID !== element.CISO_CONTROL_ID);
    setFilteredSelectedControls(data);
  }



  const ControlsList = () => {
    return (
      <List dense className={classes.width100}>
        {FilteredSelectedControls.map((el, i) => {
          const labelId = `list-secondary-label-${el?.CISO_CONTROL_ID}-${i}`
          return (
            <Fragment key={`${el?.CISO_CONTROL_ID}-${el?.CISO_CONTROL_NAME}-${i}-data-control`}>
              <ListItem disablePadding style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: "0px 20px 0px 20px" }}>
                  <ListItemText id={labelId} primary={<span style={{ fontWeight: "500" }}>{el?.CISO_CONTROL_NAME}</span>} />
                  <DeleteOutlineRoundedIcon sx={{ fontSize: 30, cursor: "pointer" }} color="error" onClick={() => { DeleteControls(el, i) }} />
                </div>
              </ListItem>
              <Divider variant="middle" />
            </Fragment>
          );
        })}

        {
          FilteredSelectedControls.length === 0 && (
            <ListItem disablePadding style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <ListItemButton style={{ textAlign: "center" }}>
                <ListItemText id={"nodata"} primary={"No record found"} />
              </ListItemButton>
            </ListItem>
          )
        }
      </List>
    )
  }

  const renderOption = (props, option, selected) => (
    <li
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
      key={`${option.CISO_CONTROL_ID}-${option.CISO_CONTROL_NAME}`}
    >
      {
        option.CISO_CONTROL_NAME
      }
    </li>

  );

  const ResetAll = () => {
    setFilteredSelectedControls(alloptions.SelectedDataPoints);
    setControlsToBeDeleted([]);
    setIsEditControl(false);
  }

  const updateQuestionToControlMapping = (
    controlId,
    dataAssetAttributeName,
    dataAssetEntityName,
    sourceSystemId,
    flag
  ) => {
    dispatch(
      individualQuestionAction.UpdateQuestionToControlMapping(
        questionID,
        controlId,
        dataAssetAttributeName,
        dataAssetEntityName,
        sourceSystemId,
        flag
      )
    );
  };

  const SubmitData = async (values, { resetForm }) => {

    if (ControlsToBeDeleted.length > 0) {
      ControlsToBeDeleted.map((el) => {
        updateQuestionToControlMapping(el.CISO_CONTROL_ID, null, null, null, 0)
      });

      setControlsToBeDeleted([]);
    }

    const data = FilteredSelectedControls.filter((el) => el.identifier !== undefined);

    if (data.length > 0) {
      data.map((ele) => {
        updateQuestionToControlMapping(ele.CISO_CONTROL_ID, null, null, null, 1)
      })
    }

    setIsEditControl(false);

    setTimeout(function () {
      GetControls();
      fetchdata();
    }, 200);

  }



  return (
    <Box>
      <Grid container={true} >
        <Grid item={true} md={6}>
          <Formik
            initialValues={formInitialData}
            enableReinitialize={true}
            onSubmit={SubmitData}
          >
            {({ values, setFieldValue, resetForm, touched, errors }) => (
              <Form>
                <Typography className={classes.filedTitle}>CONTROLS</Typography>

                <Card variant="outlined" style={{ height: "55vh", width: "100%" }}>

                  <Grid container={true} style={{ padding: "10px" }} justify="space-between">

                    <Grid item={true} xs={5} md={12} >
                      <Field name="AddControls" >
                        {({ field: { value } }) => (
                          <>
                            {
                              isEditControl ? (

                                <Autocomplete
                                  size="small"
                                  autoHighlight
                                  openOnFocus
                                  options={ControlDropDownOptions}
                                  getOptionLabel={(option) => option.CISO_CONTROL_NAME ? option.CISO_CONTROL_NAME : ""}
                                  renderOption={(props, option, { selected }) => { return renderOption(props, option, selected) }}
                                  renderInput={(params) => <TextField {...params} placeholder="Search" />}
                                  getOptionDisabled={(option) => FilteredSelectedControls?.some((el) => `${el?.CISO_CONTROL_NAME}_${el?.CISO_CONTROL_ID}` === `${option?.CISO_CONTROL_NAME}_${option?.CISO_CONTROL_ID}`)}
                                  onChange={(e, val) => {
                                    if (val !== null) {
                                      setFilteredSelectedControls((prev) => ([...prev, val]));
                                      setFieldValue("AddControls", {});
                                    }
                                  }}
                                  isOptionEqualToValue={(option, value) => option.CISO_CONTROL_ID === value.CISO_CONTROL_ID}
                                  value={value}
                                />

                              ) : (
                                <Button color="primary" style={{ height: "40px" }} onClick={() => { setIsEditControl(true) }}>
                                  <AddCircleIcon
                                    style={{ fontSize: "25px", marginRight: "10px" }}
                                  />
                                  ADD/REMOVE CONTROLS
                                </Button>
                              )
                            }


                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  <Divider variant="middle" />

                  {
                    isLoading === true ? (
                      <div style={{ display: 'flex', justifyContent: 'center', height: "inherit", alignItems: "center" }}>
                        <CircularProgress />
                      </div>
                    ) : (
                      <CardContent style={{ padding: "0px", height: "87%" }}>
                        <PerfectScrollbar >
                          {ControlsList()}
                        </PerfectScrollbar>
                      </CardContent>
                    )
                  }

                </Card>

                <Grid container={true} justify="space-between" style={{ width: "45%", marginTop: "15px" }}>

                  {
                    !(_.isEqual(alloptions.FilteredSelectedControls, FilteredSelectedControls)) && (
                      <>
                        <Button color="primary" className={classes.btnWidth} variant="contained" type="submit" startIcon={<SaveIcon style={{ fontSize: "30px" }} />}>
                          SAVE
                        </Button>
                        <Button color="primary" className={classes.btnWidth} variant="outlined" onClick={ResetAll}>
                          CANCEL
                        </Button>
                      </>
                    )
                  }

                </Grid>

              </Form>
            )}
          </Formik>


        </Grid>
      </Grid>

    </Box>
  )

}


ControlsTab.propTypes = {
  questionID: PropTypes.string,
  fetchdata: PropTypes.func,
};

export default ControlsTab