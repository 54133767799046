import ISGLogo from "./policy_align_logo.png";
// import LiveLogo from "./regulativ_live.png";
import SattrixLogo from "./sattrix_logo.png";
// import RegulativLogo from "./regulativ_office_header_logo.png";
// import RegulativFilledLogo from "./regulativai-filled.png";
import NewRegulativLogo from "./new_regulativ.png";

export const logoData = {
  dev2: {
    logo: NewRegulativLogo,
    bgColor: "#ffffff",
  },
  dev: {
    logo: NewRegulativLogo,
    bgColor: "#ffffff",
  },
  test: {
    logo: NewRegulativLogo,
    bgColor: "#ffffff",
  },
  prod: {
    logo: NewRegulativLogo,
    bgColor: "#ffffff",
  },
  live: {
    logo: NewRegulativLogo,
    bgColor: "#ffffff",
  },
  sattrix: {
    logo: SattrixLogo,
    bgColor: "#ffffff",
  },
  isg: {
    logo: ISGLogo,
    bgColor: "#001638",
  },
  doralive: {
    logo: NewRegulativLogo,
    bgColor: "#ffffff",
  },
};
