import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  paper: {
    padding: "2px 2px",
    textAlign: "left",
    color: "red",
    minHeight: "400px",
    width: "100%",
    border: "0px",
  },

  searchbar: {
    textAlign: "left",
    color: "gray",
    height: "40px",
    width: "80%",
  },

  textField: {
    width: "400px",
    borderRadius: "10px",
    height: "20px",
  },
  inputField: {
    padding: "10px 5px !important",
    width: "80%",
    color: "#000",
  },
  formFieldLabel: {
    fontWeight: "bold",
    fontSize: "16px",
    width: "35%",
    paddingBottom: "7px",
  },
}));

export default useStyles;
