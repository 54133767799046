/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Button,
  TextField,
  Input,
  Typography,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import sessionService from "../../../_services/session.service";
import { itemStyles } from "../styles";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { getNameInitial } from "_helpers/utils";

const CommentSection = ({ comment }) => {
  const user_ID = sessionService.getUserId();

  const classes = itemStyles();

  let flexDirection, margin, backgroundColor;
  if (comment.USER_ID == user_ID) {
    flexDirection = "row";
    margin = "0px 0px 0px 10px";
    backgroundColor = "#f8fcfd";
  } else {
    flexDirection = "row-reverse";
    margin = "0px 10px 0px 0px";
    backgroundColor = "#efefef";
  }

  return (
    <>
      <Box
        className={classes.itemWrap}
        style={{
          flexDirection: `${flexDirection}`,
        }}
      >
        {/* <Box className={classes.imgWrap}> */}
        <Avatar alt={comment.NAME} src={comment.NAME}></Avatar>
        {/* </Box> */}

        <Box
          className={classes.commentWrap}
          style={{
            backgroundColor: `${backgroundColor}`,
            margin: `${margin}`,
            flexDirection: `${flexDirection}`,
          }}
        >
          <Typography
            variant="body1"
            style={{
              // Breaks comments for both chrome and IE
              wordBreak: "break-word",
              wordWrap: "break-word",
              width: "100%",
            }}
          >
            {comment.COMMENT}
          </Typography>

          <Typography
            className={classes.timestamp}
            variant="caption"
            style={{
              minWidth: "115px",
            }}
          >
            {moment(comment.CREATE_DATE).format("DD-MMM-YY h:mm a")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

CommentSection.propTypes = {
  comment: PropTypes.object,
};

export default CommentSection;
