import React from "react";
import PropTypes from "prop-types";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Divider } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { styles } from "../../styles";
import { getShortName } from "../../utils";
import NoDataCard from "./NoDataCard";

const TableView = ({ columns, rows, className = "data-table" }) => {
  return (
    <TableContainer style={{ maxHeight: "30vh" }}>
      <Table stickyHeader className={className}>
        <TableHead>
          <TableRow>
            {columns?.map((item, index) => (
              <TableCell
                component="th"
                scope="row"
                key={"column_" + index + item?.field}
                style={{ minWidth: item?.minWidth, fontSize: 12 }}
              >
                {item?.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row, rowIndex) => (
            <TableRow key={rowIndex + row?.[columns?.[0].field]}>
              {columns?.map((column, index) => {
                const value = row?.[column?.field];
                return (
                  <TableCell
                    component="td"
                    scope="row"
                    key={`column_${index + row?.[columns?.[0]?.field]}`}
                    style={{ minWidth: column?.minWidth, fontSize: 12 }}
                  >
                    {column?.format ? column.format(value) : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}

          {rows?.length === 0 && (
            <TableRow style={{ height: 60 }}>
              <TableCell
                style={{ textAlign: "center" }}
                colSpan={columns?.length}
              >
                No record found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const QuestionSummaryGraphView = ({ regulatorsList }) => {
  const columns = [
    { field: "REGULATORS_CONTROL_GROUP", headerName: "Group" },
    {
      field: "COMPLETED",
      headerName: "Completed",
    },
    {
      field: "DRAFT",
      headerName: "Draft",
    },
    {
      field: "SENT_BACK",
      headerName: "Sent Back",
      minWidth: 80,
    },
  ];

  const groupedData = regulatorsList?.reduce((acc, item) => {
    const key = item?.REGULATORS_CONTROL_GROUP;
    acc[key] = acc[key] || {
      REGULATORS_CONTROL_GROUP: key,
      COMPLETED: 0,
      DRAFT: 0,
      SENT_BACK: 0,
    };

    if (
      item?.QUESTION_STATUS === "COMPLETED" ||
      item?.QUESTION_STATUS === "COMPLETED_AND_LOCKED" ||
      item?.QUESTION_STATUS === "FINAL"
    ) {
      acc[key].COMPLETED += item?.QUESTION_COUNT;
    } else if (item?.QUESTION_STATUS === "DRAFT") {
      acc[key].DRAFT += item?.QUESTION_COUNT;
    } else {
      acc[key].SENTBACK += item?.QUESTION_COUNT;
    }

    return acc;
  }, {});

  return (
    <Box
      sx={{
        ...styles.whiteWrapper,
        flex: "auto",
        display: "flex",
        gap: 2,
      }}
    >
      <Box flex={1}>
        {regulatorsList?.length ? (
          <ResponsiveContainer>
            <BarChart data={Object.values(groupedData)}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                tickFormatter={(value) => getShortName(value)}
                dataKey="REGULATORS_CONTROL_GROUP"
              />

              <YAxis />

              <Tooltip />
              <Brush
                tickFormatter={(value) => getShortName(value)}
                dataKey="REGULATORS_CONTROL_GROUP"
                stroke="red"
                height={20}
              />
              <Legend
                wrapperStyle={{ paddingTop: "1rem" }}
                formatter={(value) => value?.replaceAll("_", " ")}
              />

              <Bar barSize={12} dataKey="COMPLETED" stackId="a" fill="green" />
              <Bar dataKey="DRAFT" stackId="a" fill="orange" />
              <Bar dataKey="SENT_BACK" stackId="a" fill="gray" />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <NoDataCard />
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={1}>
        <TableView
          columns={columns}
          rows={regulatorsList?.length ? Object.values(groupedData) : []}
        />
      </Box>
    </Box>
  );
};

QuestionSummaryGraphView.propTypes = {
  regulatorsList: PropTypes.array,
};

TableView.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  className: PropTypes.string,
};

export default QuestionSummaryGraphView;
