/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";

import langConstant from "_lang";

const QuestionsCountInfoBar = (props) => {
  const allQuestion = props.questionDraft;
  const selectedQuestions = _.filter(props.questionDraft, { selected: true });

  return (
    <Box className="stats-bar" p={1}>
      <Grid container alignItems="center" justify="space-between">
        <Grid>
          <Grid container alignItems="center">
            <Grid>
              <AssignmentOutlinedIcon color="primary" />
            </Grid>
            <Grid>
              <span className="count">
                {allQuestion.length}/{allQuestion.length}
              </span>
            </Grid>
            <Grid>{langConstant.QUESTIONS_EXTRACTED}</Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container alignItems="center">
            <Grid>
              <PostAddOutlinedIcon color="primary" />
            </Grid>
            <Grid>
              <span className="count">
                {selectedQuestions.length}/{allQuestion.length}
              </span>
            </Grid>
            <Grid>{langConstant.QUESTIONS_ADDED_TO_QUESTION_BANK}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
QuestionsCountInfoBar.propTypes = {
  questionDraft: PropTypes.array,
};
export default QuestionsCountInfoBar;
