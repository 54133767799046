import { useEffect } from "react";
import sessionService from "./../_services/session.service";

const TawkToScript = () => {
  useEffect(() => {
    let script;
    const appendScript = () => {
      script = document.createElement("script");
      script.src = "https://embed.tawk.to/664de12c981b6c56477330e9/1i93nb4ni";
      script.async = true;
      script.setAttribute("crossorigin", "*");

      document.body.appendChild(script);
    };

    // Add script if user is logged in
    if (sessionService.getItem("jwtToken")) {
      appendScript();
      if (window.Tawk_API) {
        // Show the widget when needed
        window.Tawk_API.showWidget();
      }
    }

    // Clean up script on unmount or logout
    const removeScript = () => {
      if (script) {
        document.body.removeChild(script);
      }
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget(); // Hide the widget when the user logs out
      }
    };

    return () => {
      removeScript();
    };
  }, []);

  return null;
};

export default TawkToScript;
