import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import {
  setAuditActionList,
  setActionListDetails,
  setUsersList,
} from "./actions";
import _ from "lodash";

const GetActionListForAudit = (userID) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetActionsList", { i_USER_ID: userID })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const list = _.uniqBy(response?.["#result-set-1"], "ACTIONS_LIST_ID");
          if (response?.["#result-set-1"]) {
            dispatch(setAuditActionList(list));
            dispatch(loadingActions.end());
          }
          return response;
        },
        (error) => {
          dispatch(loadingActions.end());
          dispatch(
            alertActions.error(
              error?.response?.data?.message?.map((item, index) => {
                // eslint-disable-next-line react/react-in-jsx-scope
                return <div key={index}>{item}</div>;
              }) ?? error.toString()
            )
          );
        }
      );
  };
};

const GetActionListDetails = (listId) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetActionListDetails", {
        i_ACTIONS_LIST_ID: listId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response?.["#result-set-1"]) {
            dispatch(setActionListDetails(response?.["#result-set-1"]));
            dispatch(loadingActions.end());
          }
          return response;
        },
        (error) => {
          dispatch(loadingActions.end());
          dispatch(
            alertActions.error(
              error?.response?.data?.message?.map((item, index) => {
                // eslint-disable-next-line react/react-in-jsx-scope
                return <div key={index}>{item}</div>;
              }) ?? error?.toString()
            )
          );
        }
      );
  };
};

const GetTeamDetails = (userID) => {
  return (dispatch) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetMyTeamDetails", {
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response?.["#result-set-1"]) {
            dispatch(setUsersList(response?.["#result-set-1"]));
          }
          return response;
        },
        (error) => {
          dispatch(
            alertActions.error(
              error?.response?.data?.message?.map((item, index) => {
                // eslint-disable-next-line react/react-in-jsx-scope
                return <div key={index}>{item}</div>;
              }) ?? error?.toString()
            )
          );
        }
      );
  };
};

// sp used to fetch list of one of this assesment / policy / comparision / regulation
const getDropdownOptions = (userID, spEndPoint) => {
  return apiService
    .apiCall("dbapi")
    .send(`/v1/api/proc/DRegulativDB/${spEndPoint}`, {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const PutActionsList = (payload) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutActionsList", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const UpdateAction = (updatedFiled, actionId, actionsListId, callBack) => {
  const payload = {
    i_ACTIONS_ID: actionId,
    i_ASSIGNED_TO: null,
    i_ACTIONS_STATUS: null,
    i_ACTIONS_PRIORITY: null,
    i_CLOSURE_DATE: null,
    ...updatedFiled,
  };
  return (dispatch) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateAction", payload)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#result-set-1"][0]["#update-count-1"]) {
            response["status"] = 200;
            dispatch(alertActions.success("Action successfully updated."));
            callBack?.();
          } else {
            dispatch(alertActions.error("Unable to update actions"));
          }
          return response;
        },
        (error) => {
          dispatch(
            alertActions.error(
              error?.response?.data?.message?.map((item, index) => {
                // eslint-disable-next-line react/react-in-jsx-scope
                return <div key={index}>{item}</div>;
              }) ?? error?.toString()
            )
          );
        }
      );
  };
};

const PutNotes = (payload) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutNotes", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const GetNotesAttachments = (i_NOTES_ID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetNotesAttachments", { i_NOTES_ID })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const PutActions = (payload) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutActions", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

export {
  GetActionListForAudit,
  GetActionListDetails,
  GetTeamDetails,
  getDropdownOptions,
  PutActionsList,
  UpdateAction,
  PutNotes,
  PutActions,
};
