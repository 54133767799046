import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    stickyColl: {
        position: "sticky",
        right: 0,
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    marginTop: {
        marginTop: "15px"
    },
    cursorPointer: {
        cursor: "pointer"
    },
    loadMore: {
        textAlign: 'center',
        padding: '20px 0 !important',
        borderTop:'0 !important',
        '& span': {
            backgroundColor: 'rgb(18, 69, 113)',
            color: '#fff',
            padding: '7px 15px',
            borderRadius: '5px',
            display: 'inline-block',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#02335d'
            }
        }
    },
    tableBody: {
        '& tr': {
            '& td': {
                borderTop: '1px solid rgba(224,224,224,1)',
                borderBottom: '0'
            }
        }
    },
    recordLength: {
        marginLeft: "20px",
        padding: "2px 5px",
        backgroundColor: ({ actionButtonColor }) => actionButtonColor,
        color: "white",
        borderRadius: "5px"
    }

}));

export default useStyles;