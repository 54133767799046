import { useState } from "react";
import PropTypes from "prop-types";

const useModal = () => {
  const [state, setState] = useState({
    isOpen: false,
    data: null,
  });
  const toggleModal = (isOpen) => setState((pre) => ({ ...pre, isOpen }));
  const setData = (data) => setState((pre) => ({ ...pre, data }));

  return {
    isOpen: state?.isOpen,
    data: state?.data,
    openModal: () => toggleModal(true),
    closeModal: () => toggleModal(false),
    setData,
  };
};

const UseModalPropType = PropTypes.shape({
  isOpen: PropTypes.bool,
  data: PropTypes.any,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setData: PropTypes.func,
});

export { useModal, UseModalPropType };
