import React from "react";
import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";

const getCustomerDataAssetList = (
  setLoading,
  customerID,
  userID,
  setDataAssest
) => {
  return (dispatch) => {
    setLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerDataAssetList", {
        i_CUSTOMER_ID: customerID,
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setDataAssest(response["#result-set-1"]);
        },
        (error) => {
          setDataAssest([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
};

const updateCustomerDataAssetList = (
  setLoading,
  data,
  updateDataAsset,
  toggleDialog
) => {
  return (dispatch) => {
    setLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCustomerDataAssetList", {
        i_CUSTOMER_ID: data.CUSTOMER_ID,
        i_DATA_ASSET_NAME: data.DATA_ASSET_NAME,
        i_DATA_ASSET_ATTRIBUTE_NAME: data.DATA_ASSET_ATTRIBUTE_NAME,
        i_DATA_TYPE: "string",
        i_REQUIRED_FLAG: data.REQUIRED_FLAG,
        i_UNID: data.UNID,
        i_SOURCE_DATA_ID: data.SOURCE_DATA_ID,
        i_SOURCE_SYSTEM_ID: data.SOURCE_SYSTEM_ID,
        i_DATA_UPLOAD_ID: data.DATA_UPLOAD_ID,
        i_DATA_ASSET_ENTITY_NAME: data.DATA_ASSET_ENTITY_NAME,
        i_SOURCE_DATA_POINT_VALUE: data.SOURCE_DATA_POINT_VALUE,
        i_RECORD_KEY: data.RECORD_KEY,
        i_START_DATE: data.START_DATE,
        i_END_DATE: data.END_DATE,
        i_USER_ID: data.USER_ID,
        i_TAB_LABEL: data.TAB_LABEL,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          updateDataAsset(data, "update");
          toggleDialog();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
};

const putCustomerDataAssetList = (
  setLoading,
  data,
  updateDataAsset,
  toggleDialog
) => {
  return (dispatch) => {
    setLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutCustomerDataAssetList", {
        i_CUSTOMER_ID: data.CUSTOMER_ID,
        i_DATA_ASSET_NAME: data.DATA_ASSET_NAME,
        i_DATA_ASSET_ATTRIBUTE_NAME: data.DATA_ASSET_ATTRIBUTE_NAME,
        i_DATA_TYPE: "string",
        i_REQUIRED_FLAG: null,
        i_SOURCE_DATA_ID: null,
        i_DATA_ASSET_ATTRIBUTE_VALUE: data.SOURCE_DATA_POINT_VALUE,
        i_START_DATE: null,
        i_END_DATE: null,
        i_USER_ID: data.USER_ID,
        i_TAB_LABEL: data.TAB_LABEL,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          data["SOURCE_DATA_ID"] =
            response["#result-set-3"][0]["SOURCE_DATA_ID"];
          data["UNID"] = response["#result-set-1"][0]["UNID"];
          updateDataAsset(data, "create");
          toggleDialog();
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
};

export default {
  getCustomerDataAssetList,
  updateCustomerDataAssetList,
  putCustomerDataAssetList,
};
