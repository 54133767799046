import constant from "./constants";

const setUserTeamMembers = (data) => ({
    type: constant.GET_TEAM_MEMBERS,
    payload: data
})

const updateTeamMember = (data) => ({
    type: constant.UPDATE_TEAM_MEMBERS,
    payload: data
})

export default {
    setUserTeamMembers,
    updateTeamMember,
}