/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, IconButton, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Field } from "formik";
import CustomDropdown from "./CustomDropdown";
import dropdownOptions from "../constants";

const ChildField = ({
  innerArrayHelpers = {},
  tabIndex = 0,
  childIndex = 0,
  index = 0,
  initialValues = {},
  attributeDropdownOptions = [],
  dataTypeData = [],
}) => {
  const [conditionDropdown, seConditionDropdown] = useState([]);
  const setDataType = (e) => {
    if (e.target.value) {
      const val = attributeDropdownOptions.filter((item) => {
        return item.value == e.target.value;
      });
      return val.length > 0 ? val[0].dataType : null;
    } else {
      return null;
    }
  };
  const getConditionDropdown = (e) => {
    let options = [];
    if (e) {
      const dataType = setDataType(e);
      options = dataTypeData
        .filter((item) => {
          return item.DATA_TYPE_VALUE == dataType;
        })
        .map((item) => {
          return {
            value: item.OPERATOR_VALUE,
            name: item.OPERATOR_NAME,
          };
        });
    }

    seConditionDropdown(options);
  };
  useEffect(() => {
    getConditionDropdown();
  }, []);
  return (
    <>
      <Grid container spacing={3} key={childIndex}>
        <Grid item md={5}>
          <Field
            name={`rules[${tabIndex}].parent[${index}].child[${childIndex}].attribute`}
          >
            {({ field, form }) => (
              <CustomDropdown
                label={"Select Attribute"}
                fieldName={field.name}
                value={
                  form.values.rules[tabIndex].parent[index].child[childIndex]
                    .attribute
                }
                options={attributeDropdownOptions}
                onChange={(e) => {
                  form.handleChange(e);
                  form.setFieldValue(
                    `rules[${tabIndex}].parent[${index}].child[${childIndex}].dataType`,
                    setDataType(e)
                  );
                  getConditionDropdown(e);
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item md={2}>
          <Field
            name={`rules[${tabIndex}].parent[${index}].child[${childIndex}].comparisonOperator`}
          >
            {({ field, form }) => (
              <CustomDropdown
                label={"Select Condition"}
                fieldName={field.name}
                value={
                  form.values.rules[tabIndex].parent[index].child[childIndex]
                    .comparisonOperator
                }
                options={dropdownOptions.comparisonOperator}
                // options={conditionDropdown}
                onChange={form.handleChange}
              />
            )}
          </Field>
        </Grid>
        <Grid item md={5}>
          <Grid container spacing={2} key={childIndex}>
            <Grid item>
              <Field
                name={`rules[${tabIndex}].parent[${index}].child[${childIndex}].dataType`}
                type="hidden"
              >
                {({ field, form }) => (
                  <CustomDropdown
                    label={"Select Type"}
                    fieldName={field.name}
                    value={
                      form.values.rules[tabIndex].parent[index].child[
                        childIndex
                      ].dataType
                    }
                    error={Boolean(
                      form.errors.attribute && form.touched.attribute
                    )}
                    helperText={
                      form.errors.attribute &&
                      form.touched.attribute &&
                      String(form.errors.attribute)
                    }
                    options={dropdownOptions.dataType}
                    onChange={(e) => {
                      form.handleChange(e);
                      form.setFieldValue(
                        `rules[${tabIndex}].parent[${index}].child[${childIndex}].compareValue`,
                        ""
                      );
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item>
              <Field
                name={`rules[${tabIndex}].parent[${index}].child[${childIndex}].compareValue`}
              >
                {({ field, form }) => (
                  <>
                    <div>&nbsp;</div>
                    {/*For Integer*/}
                    {(form.values.rules[tabIndex].parent[index].child[
                      childIndex
                    ].dataType == "INTEGER" ||
                      form.values.rules[tabIndex].parent[index].child[
                        childIndex
                      ].dataType == "") && (
                      <TextField
                        {...field}
                        size="small"
                        type="number"
                        variant="outlined"
                        fullWidth
                        label=""
                        onChange={(e) => form.handleChange(e)}
                        InputProps={{
                          autoComplete: "off",
                          pattern: "[0-9]+",
                        }}
                      />
                    )}
                    {/*For Text*/}
                    {form.values.rules[tabIndex].parent[index].child[childIndex]
                      .dataType == "TEXT" && (
                      <TextField
                        {...field}
                        size="small"
                        variant="outlined"
                        fullWidth
                        label=""
                        onChange={(e) => form.handleChange(e)}
                        InputProps={{
                          autoComplete: "off",
                          pattern: "[0-9]+",
                        }}
                      />
                    )}

                    {form.values.rules[tabIndex].parent[index].child[childIndex]
                      .dataType == "DATE" && (
                      <TextField
                        {...field}
                        size="small"
                        variant="outlined"
                        fullWidth
                        label=""
                        type="date"
                        defaultValue="2017-05-24"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    {/*For Boolean*/}
                    {form.values.rules[tabIndex].parent[index].child[childIndex]
                      .dataType == "BOOLEAN" && (
                      <Box style={{ margin: "8px 0 8px 0" }}>
                        <label>
                          <Field {...field} type="radio" value="true" />
                          true
                        </label>
                        <label>
                          <Field {...field} type="radio" value="false" />
                          false
                        </label>
                      </Box>
                    )}

                    {form.values.rules[tabIndex].parent[index].child[childIndex]
                      .attribute &&
                      form.values.rules[tabIndex].parent[index].child[
                        childIndex
                      ].dataType &&
                      form.values.rules[tabIndex].parent[index].child[
                        childIndex
                      ].dataType != "STRING" && (
                        <Box className="helpText"> {field.value}</Box>
                      )}
                  </>
                )}
              </Field>
            </Grid>
            <Grid item style={{ marginTop: "5px" }}>
              <div>&nbsp;</div>
              <IconButton
                color="primary"
                aria-label="remove Rule"
                component="span"
                size="small"
                onClick={() =>
                  innerArrayHelpers.push(
                    initialValues.rules[0].parent[0].child[0]
                  )
                }
              >
                <AddIcon />
              </IconButton>
              {childIndex > 0 && (
                <IconButton
                  color="primary"
                  aria-label="Add Rule"
                  component="span"
                  size="small"
                  onClick={() => innerArrayHelpers.remove(childIndex)}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
ChildField.propTypes = {
  innerArrayHelpers: PropTypes.object,
  tabIndex: PropTypes.number,
  childIndex: PropTypes.number,
  index: PropTypes.number,
  initialValues: PropTypes.object,
  attributeDropdownOptions: PropTypes.array,
  dataTypeData: PropTypes.array,
};
export default React.memo(ChildField);
