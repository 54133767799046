export const styles = {
  whiteWrapper: {
    borderRadius: 1,
    backgroundColor: "#fff",
    p: 1,
  },
  avatarColor: {
    M: { backgroundColor: "#fff6c8", color: "#f5cc00" },
    H: { backgroundColor: "#ffdddd", color: "#ff3131" },
    L: { backgroundColor: "#dfffe1", color: "#4fcd00" },
  },
};
