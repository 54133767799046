/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Divider,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import Truncate from "../../../../../_components/Truncate";
import { confirmationDialogActions } from "../../../../../_actions/confirmationDialog.action";
import rootStyles from "../../../../../rootStyles";

const QuestionRow = (props) => {
  const rootClasses = rootStyles();
  const item = props.data;
  const [question, setQuestion] = useState(props.data.sentence);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setQuestion(e.target.value);
  };

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item style={{ minWidth: 70 }}>
          <Box p={1} pt={2}>
            {item.ref} -
          </Box>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs>
            {edit ? (
              <Box style={{ padding: 4 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  label=""
                  multiline
                  placeholder=""
                  rows={1}
                  rowsMax={Infinity}
                  autoFocus
                  id="question"
                  name="question"
                  value={question}
                  onChange={handleChange}
                />
              </Box>
            ) : (
              <Box p={1} pt={2}>
                <Typography variant="body2" gutterTop>
                  {item.sentence}
                </Typography>
              </Box>
            )}

            {item.info ? (
              <Box className="info-box">
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <InfoIcon color="inherit" />
                  </Grid>
                  <Grid item>
                    <Truncate content={item.info} wordCount={50} />
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              {!edit ? (
                <IconButton
                  className={rootClasses.greenText}
                  aria-label="edit"
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  <CreateIcon fontSize="inherit" color="inherit" />
                </IconButton>
              ) : (
                <IconButton
                  className={rootClasses.greenText}
                  aria-label="edit"
                  onClick={() => {
                    props.updateQuestion(item.id, question);
                    setEdit(false);
                  }}
                >
                  <CheckIcon fontSize="inherit" color="inherit" />
                </IconButton>
              )}
            </Grid>

            <Grid item>
              <IconButton
                className={rootClasses.redText}
                color="secondary"
                aria-label="delete"
                onClick={() => {
                  dispatch(
                    confirmationDialogActions.open({
                      message: "Are you sure you want to remove the question?",
                      onConfirm: () => {
                        props.deselectQuestion(item.id, false);
                      },
                    })
                  );
                }}
              >
                <DeleteIcon fontSize="inherit" color="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
QuestionRow.propTypes = {
  data: PropTypes.object,
  updateQuestion: PropTypes.func,
  deselectQuestion: PropTypes.func,
};
export default QuestionRow;
