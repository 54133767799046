import { Box, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import langConstant from "_lang";
import AppPagination from "_components/AppPagination";

const useStyles = makeStyles(() => ({
    stickyColl: {
        position: "sticky",
        right: 0,
        paddingTop:"10px",
        paddingBottom:"10px",
    },
    spllierSPOCTable: {
        marginTop: '50px',
        padding: '0px 50px'
    }
}))

const AssignAssessmentDataTable = ({rows, columns}) => {
    const classes = useStyles()

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filteredRows, setFilteredRows] = useState(rows)

    const calculatePageCount = (rows) => {
        return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
    }

    const [pageCount, setPageCount] = useState(calculatePageCount(rows));

    const handleChangePage = (event) => {
        setPage(event);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    useEffect(() => {
        setFilteredRows(rows.slice(page*rowsPerPage, (page+1)*rowsPerPage))
        setPageCount(calculatePageCount(rows))
    },[rows, page])

    return (
        <Box className={classes.spllierSPOCTable}>
            <TableContainer component={Paper}>
                <Table className="data-table">
                    <TableHead>
                        <TableRow>
                            {columns.map((item, index) => (
                                <TableCell
                                    component="th"
                                    scope="row"
                                    key={"column_" + index + item.field}
                                    className={classes.stickyColl}
                                >
                                    {item.headerName}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.map((row, rowIndex) => (
                            <TableRow key={'row_'+rowIndex}>
                                {columns.map((column, columnIndex) => {
                                    const value = row[column.field]
                                    return (
                                        <TableCell
                                            component="td"
                                            scope="row"
                                            key={"column_" + columnIndex + row[columns[0].field]}
                                        >
                                            {column.format ? column.format(row) : value}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                        {filteredRows.length === 0 && 
                            <TableRow style={{height: 53}}>
                                <TableCell
                                    style={{textAlign: "center"}}
                                    colSpan={columns.length}
                                >
                                    {langConstant.NO_RECORD_FOUND}
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {pageCount > 1 && (
                <Box my={2}>
                    <Grid container justify='flex-end'>
                        <AppPagination
                            colSpan={3}
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

AssignAssessmentDataTable.propTypes = {
    rows: PropTypes.array,
    columns: PropTypes.array
}

export default AssignAssessmentDataTable;