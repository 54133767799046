import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Select from "react-select";
import UserServices from "./services";
import { alertActions } from "_actions";

const ManageTeamDropdown = (props) => {
  const [teamOptions, setTeamOptions] = useState([]);
  const dispatch = useDispatch();

  const getTeamDetailsList = async () => {
    const response = await UserServices.getTeamList(props.customerID);
    const tempTeamList = [];
    if (response.isSuccess) {
      response.data.forEach((element) => {
        tempTeamList.push({ value: element.TEAM_ID, label: element.TEAM_NAME });
      });
    } else {
      dispatch(alertActions.error(response.message));
    }
    setTeamOptions(tempTeamList);
  };

  useEffect(() => {
    (async () => {
      await getTeamDetailsList();
    })();
  }, []);

  return (
    <Select
      value={teamOptions.find((role) => role.value == props.value)}
      options={teamOptions}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  );
};

ManageTeamDropdown.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  customerID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ManageTeamDropdown;
