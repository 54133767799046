import {
  Button,
  Box,
  Grid,
  Paper,
  Table,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  TableRow,
  TableContainer,
  TextField,
  TableHead,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import * as Yup from "yup";
import { alertActions } from "_actions";
import { Form, Formik, FieldArray, ErrorMessage, Field } from "formik";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useModal } from "_hooks";
import langConstant from "_lang";
import { useDispatch } from "react-redux";
import { UseModalPropType } from "_hooks";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import CircularProgress from "@mui/material/CircularProgress";
import AdminServices from "../../services";

const useStyles = makeStyles(() => ({
  title: { display: "flex", alignItems: "center", padding: ".75rem" },
  error: { color: "red", fontSize: "12px" },
  customerFormWrapper: {
    border: "2px Solid grey",
    borderRadius: "5px",
  },
  wideColumn: {
    width: "80%",
  },
  normalColumn: {
    width: "25%",
  },
  regColumn: {
    width: "20%",
  },
}));
const SystemAlertMessage = ({ modal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [progressView, setProgressView] = useState(false);
  const [disablePage, setDisablePage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const initialValues = {
    DESCRIPTION: "",
  };

  const handleEditorCancel = (setFieldValue, data) => {
    setFieldValue("DESCRIPTION", data);
  };
  const parsedCustomerList = modal.data.uniqueCustomerList
    ? modal.data.uniqueCustomerList
    : [];

  const customerList = modal.data.customerListForAdmin
    ? modal.data.customerListForAdmin
    : [];
  const userLists = modal.data.uniqueCustomerUserList
    ? modal.data.uniqueCustomerUserList
    : [];

  const confirmationModal = useModal();
  const [customerID, setcustomerID] = useState(null);
  const [systemMessages, setSystemMessages] = useState(null);
  const validationSchema = Yup.object().shape({
    DESCRIPTION: Yup.string()
      .trim()
      .max(100, "Maximum 100 Characters Allowed")
      .required("Message is Required"),
  });

  const [initialCustomerValues, setInitialCustomerValues] = useState({
    CUSTOMER_ID: null,
    CUSTOMER_NAME: "",
    ACTIVE: 1,
    ACTION: "CREATE",
  });

  const calculatePageCount = (rows) => {
    return rows?.length > 0
      ? Math.round(Math.ceil(rows?.length / rowsPerPage))
      : 0;
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      parsedCustomerList.length > 0
        ? Math.round(Math.ceil(parsedCustomerList.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = (values) => {
    setIsLoading(true);
    if (selectedCustomers.length > 0) {
      selectedCustomers.forEach((item) => {
        dispatch(
          AdminServices.putSystemMessage(
            values.DESCRIPTION,
            item.customerID,
            setSystemMessages,
            setIsLoading
          )
        );
      });
    } else {
      setIsLoading(false);
      dispatch(alertActions.error("Select atleast one customer"));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      AdminServices.getSystemMessage(
        customerID,
        setSystemMessages,
        setIsLoading
      )
    );
  }, [customerID]);

  useEffect(() => {
    setPageCount(calculatePageCount(parsedCustomerList));
    setProgressView(false);
    setDisablePage(false);
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={modal?.isOpen}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
        disableEnforceFocus
        keepMounted
        onClose={modal?.closeModal}
      >
        <DialogTitle className={classes.title}>
          <IconButton
            style={{ position: "absolute", right: 1 }}
            aria-label="close"
            onClick={modal?.closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} className={classes.customerFormWrapper}>
            <Grid item md={6} xs={12} className={classes.customerFormWrapper}>
              <Grid
                className={classes.customerFormWrapper}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  padding: " 10px",
                }}
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={{ rows: parsedCustomerList }}
                  validationSchema={validationSchema}
                  validateOnChange={true}
                  // onSubmit={onSubmit}
                >
                  {({ values, setFieldValue, handleChange, handleBlur }) => (
                    <Form>
                      <Grid>
                        <Typography
                          style={{ flex: 1 }}
                          variant="h6"
                          align="center"
                        >
                          {langConstant.LIST_OF_CUSTOMERS}
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Box
                          my={2}
                          style={{
                            marginRight: progressView ? 20 : "",
                            paddingRight: progressView ? 20 : "",
                            borderRight: progressView
                              ? "5px solid #dedede"
                              : "",
                          }}
                        >
                          <Grid>
                            <TableContainer
                              component={Paper}
                              style={{ marginTop: 5 }}
                            >
                              <Table
                                sx={{ minWidth: 650 }}
                                size="small"
                                aria-label="a dense table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      {langConstant.CUSTOMER_ID}
                                    </TableCell>
                                    <TableCell>
                                      {langConstant.CUSTOMER_NAME}
                                    </TableCell>

                                    <TableCell>Select</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <FieldArray name="rows">
                                    {({ insert, remove, push }) => (
                                      <>
                                        {values.rows.length > 0 &&
                                          (rowsPerPage > 0
                                            ? values.rows.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                              )
                                            : values.rows
                                          ).map((row, index) => (
                                            <TableRow
                                              key={index}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                className={classes.normalColumn}
                                                style={{
                                                  whiteSpace: "normal",
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                <TextField
                                                  className={classes.textField}
                                                  name={`rows.${index}.CUSTOMER_ID`}
                                                  size="small"
                                                  variant="outlined"
                                                  disabled
                                                  fullWidth
                                                  value={row.CUSTOMER_ID}
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value.match(
                                                        /^[a-zA-Z0-9\s.'"]*$/
                                                      )
                                                    ) {
                                                      handleChange(e);
                                                    }
                                                  }}
                                                  onPaste={(e) =>
                                                    e.preventDefault()
                                                  }
                                                  inputProps={{
                                                    style: {
                                                      whiteSpace: "normal",
                                                      wordBreak: "break-all",
                                                    },
                                                  }}
                                                />
                                              </TableCell>
                                              <TableCell
                                                className={classes.wideColumn}
                                                style={{
                                                  whiteSpace: "normal",
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                <TextField
                                                  className={classes.textField}
                                                  name={`rows.${index}.CUSTOMER_NAME`}
                                                  size="small"
                                                  variant="outlined"
                                                  disabled
                                                  fullWidth
                                                  value={row.CUSTOMER_NAME}
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value.match(
                                                        /^[a-zA-Z0-9\s.'"]*$/
                                                      )
                                                    ) {
                                                      handleChange(e);
                                                    }
                                                  }}
                                                  onPaste={(e) =>
                                                    e.preventDefault()
                                                  }
                                                  inputProps={{
                                                    style: {
                                                      whiteSpace: "normal",
                                                      wordBreak: "break-all",
                                                    },
                                                  }}
                                                />
                                              </TableCell>

                                              <TableCell>
                                                <Checkbox
                                                  name={`rows.${index}.CUSTOMER_SELECTED`}
                                                  disabled={disablePage}
                                                  checked={
                                                    selectedCustomers.findIndex(
                                                      (item) =>
                                                        item.index ===
                                                        `${index}`
                                                    ) > -1
                                                  }
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `rows.${index}.CUSTOMER_SELECTED`,
                                                      e.target.checked
                                                    );

                                                    if (e.target.checked) {
                                                      setcustomerID(
                                                        row.CUSTOMER_ID
                                                      );
                                                      const tempData = {
                                                        name: row.CUSTOMER_NAME,
                                                        index: `${index}`,
                                                        customerID:
                                                          row.CUSTOMER_ID,
                                                      };
                                                      setSelectedCustomers(
                                                        (prevUsers) => [
                                                          ...prevUsers,
                                                          tempData,
                                                        ]
                                                      );
                                                    } else {
                                                      setSelectedCustomers(
                                                        (prevUsers) =>
                                                          prevUsers.filter(
                                                            (item) =>
                                                              item.index !==
                                                              `${index}`
                                                          )
                                                      );
                                                      setSystemMessages(
                                                        (prevMsg) =>
                                                          prevMsg.filter(
                                                            (item) =>
                                                              item.CUSTOMER_ID !==
                                                              row.CUSTOMER_ID
                                                          )
                                                      );
                                                    }
                                                  }}
                                                  sx={{
                                                    "&.Mui-checked": {
                                                      color: "#124571",
                                                    },
                                                    display: "inline",
                                                  }}
                                                  size="small"
                                                />
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                      </>
                                    )}
                                  </FieldArray>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                          <Box my={2}>
                            <Grid container style={{ justifyContent: "right" }}>
                              {parsedCustomerList?.length > 0 &&
                                !disablePage && (
                                  <SelectPageRange
                                    selectedRange={rowsPerPage}
                                    handleChange={handleSelectPageRange}
                                  />
                                )}
                              {pageCount > 0 && !disablePage && (
                                <AppPagination
                                  colSpan={3}
                                  count={pageCount}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              )}
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
            {isLoading === true && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "200px",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            <Grid
              item
              md={6}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: "10px",
              }}
            >
              <Grid
                className={classes.customerFormWrapper}
                style={{
                  height: "50vh",
                  padding: "10px",
                  overflowY: "auto",
                }}
              >
                <Grid>
                  <Typography
                    style={{ flex: 1, marginBottom: "10px" }}
                    variant="h6"
                    align="center"
                  >
                    {langConstant.CURRENT_SYSTEM_MESSAGE}
                  </Typography>
                </Grid>
                {systemMessages &&
                  systemMessages.map((item, index) => (
                    <Grid
                      container
                      key={index}
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        textAlign: "left",
                      }}
                    >
                      <Grid item md={2} xs={3}>
                        {!item.CUSTOMER_ID && (
                          <Typography
                            style={{
                              flex: 1,
                              marginRight: "2px",
                              fontSize: "20px",
                            }}
                          >
                            [ * ]
                          </Typography>
                        )}
                        {item.CUSTOMER_ID && (
                          <Typography style={{ flex: 1, marginRight: "2px" }}>
                            [{item.CUSTOMER_ID}]
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        md={7}
                        xs={5}
                        style={{ justifyContent: "left" }}
                      >
                        <Typography>{item.MESSAGE_DESCRIPTION}</Typography>
                      </Grid>
                      <Grid item md={3} xs={4}>
                        <Button
                          type="button"
                          onClick={() => {
                            dispatch(
                              AdminServices.updateSystemMessage(
                                item.MESSAGE_DESCRIPTION,
                                item.MESSAGE_TYPE,
                                item.ACTIVE == 0 ? 1 : 0,
                                item.CUSTOMER_ID,
                                item.SYSTEM_MESSAGE_ID,
                                setSystemMessages,
                                setIsLoading
                              )
                            );
                          }}
                          variant={item.ACTIVE == 0 ? "outlined" : "contained"}
                          color="primary"
                        >
                          {item.ACTIVE == 0
                            ? langConstant.INACTIVE
                            : langConstant.ACTIVE}
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>

              <Grid
                className={classes.customerFormWrapper}
                style={{
                  display: "flex",
                  height: "40vh",
                  padding: "10px",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                  }) => (
                    <Form>
                      <Grid container p={1.5}>
                        <Grid item md={12} sm={8}>
                          <Grid>
                            <Typography
                              style={{ flex: 1 }}
                              variant="h6"
                              align="center"
                            >
                              {langConstant.ADD_NEW_SYSTEM_MESSAGE}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <DialogContent>
                        <Grid>
                          <Box>
                            <Grid
                              container
                              className={classes.AddressGrid}
                              style={{ alignItems: "center" }}
                            >
                              <TextField
                                id="DESCRIPTION"
                                name="DESCRIPTION"
                                variant="outlined"
                                size="small"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.DESCRIPTION}
                                style={{ width: "400px", height: "100px" }}
                                placeholder="Max chars(100)"
                                inputProps={{ maxLength: 100 }}
                                multiline
                                rows={4}
                              />

                              {errors?.DESCRIPTION && (
                                <Typography
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {errors.DESCRIPTION}
                                </Typography>
                              )}
                            </Grid>
                          </Box>
                        </Grid>
                        <DialogActions
                          sx={{ p: 1.5, justifyContent: "flex-end" }}
                        >
                          <Button
                            type="button"
                            onClick={() => {
                              setFieldValue("DESCRIPTION", "");
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            {langConstant.CANCEL}
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            {langConstant.SEND_MESSAGE}
                          </Button>
                        </DialogActions>
                      </DialogContent>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

SystemAlertMessage.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default SystemAlertMessage;
