import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { Formik, Field, ErrorMessage, Form } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { GetControlsMappedToEvidence, GetEvidenceForUser } from "./services";
import sessionService from "_services/session.service";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { GetCustomerInfoClassfication } from "../../user-administration/ConfigurationManagement/components/EvidenceTypes/services";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChipInput from "material-ui-chip-input";
import CircularProgress from "@mui/material/CircularProgress";
import AppPagination from "_components/AppPagination";
import AddIcon from "@mui/icons-material/Add";
import classNames from "classnames";
import * as yup from "yup";
import evidenceAction from "_components/AttachEvidence/actions";
import EnhancedEncryptionOutlinedIcon from "@mui/icons-material/EnhancedEncryptionOutlined";
import EvidenceLockerDialogBox from "./components/EvidenceLockerDialog";
import Loader from "_components/Loader";
import { alertActions, loadingActions } from "_actions";
import _ from "lodash";
import VerifiedIcon from "@mui/icons-material/Verified";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import { virusCheckerService } from "_helpers/utils";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles(() => ({
  height100: {
    height: "100%",
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  GridContainer: {
    marginBottom: "20px",
  },
  margintop: {
    marginTop: "8px",
  },
  width100: {
    width: "100%",
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#F6F6F6 !important",
      cursor: "pointer",
    },
  },
  chipInput: {
    borderRadius: "5px",
    border: "1px solid #cbcbcb",
    padding: "5px 7px 0px 7px",
    width: "100%",
    maxHeight: "10vh",
    overflowY: "auto",
    scrollBehavior: "smooth",
  },
  btnWidth: {
    width: "200px",
  },
  flex: {
    display: "flex",
  },
  flexCol: {
    flexDirection: "column",
  },
  marginTopAuto: {
    marginTop: "auto",
  },
  marginRight15: {
    marginRight: "15px",
  },
}));

const UploadEvidences = () => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const userID = sessionService.getUserId();
  const [DropDownOptions, setDropDownOptions] = useState({
    classification: [],
    evidenceType: [],
    regulation: [],
  });
  const customerID = sessionService.getCustomerId();
  const evidenceLocker = useSelector(
    (state) => state.authentication?.detailedInfo?.EVIDENCE_LOCKER
  );
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );
  const [TotalQuestionsData, setTotalQuestionsData] = useState([]);
  const [FilteredTableData, setFilteredTableData] = useState([]);
  const [SelectedQuestionList, setSelectedQuestionList] = useState([]);
  const [FilterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [KeywordsArr, setKeywordsArr] = useState([]);
  const [isVirusFile, setIsVirusFile] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [EvidenceDialogData, setEvidenceDialogData] = useState({ open: false });
  const [count, setCount] = useState(0);

  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [pageCount, setPageCount] = useState(
    calculatePageCount(FilteredTableData)
  );

  const loadingFile = useSelector((state) => state.loadingFile);
  const isVirusCheckingAvailable = useSelector(
    (state) => state.authentication?.detailedInfo?.VIRUS_CHECKER
  );

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fileUploadProgress = (event) => {
    setCount(Math.round((100 * event.loaded) / event.total));
  };

  useEffect(() => {
    const updatedData = filterFunction([...TotalQuestionsData]);
    setFilteredTableData(
      updatedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    );
    setPageCount(calculatePageCount(updatedData));
  }, [page, FilterData, TotalQuestionsData]);

  const pageSetter = (sortedData) => {
    const totalrows = calculatePageCount(sortedData);
    if (page + 1 > totalrows) {
      setPage(0);
    }
  };

  const filterFunction = (data = []) => {
    let sortedData = data;

    if (FilterData.length > 0) {
      sortedData = [...sortedData].filter((el) =>
        FilterData.includes(el.REGULATION_ID)
      );
      pageSetter(sortedData);
    }

    return sortedData;
  };

  const SUPPORTED_FORMATS = [
    "pdf",
    "jpeg",
    "jpg",
    "xls",
    "xlsx",
    "doc",
    "docx",
    "csv",
    "bmp",
    "png",
  ];

  const validationSchema = yup.object({
    EVIDENCE_CLASSIFICATION: yup
      .object()
      .required("required")
      .test("test2", "required", (value) => {
        if (Object.keys(value).length === 0) {
          return false;
        }
        return true;
      }),
    EVIDENCE_TYPE: yup.object().nullable().required("required"),
    REGULATION: yup.array().nullable(),
    UPLOAD_EVIDENCE: yup
      .mixed()
      .nullable()
      .required("required")
      .test(
        "size",
        "The file size should be less than 20MB",
        (value) => !value || (value && value.size <= 20400000)
      )
      .test(
        "format",
        "Invalid file format",
        (value) =>
          !value ||
          (value &&
            SUPPORTED_FORMATS.includes(
              value?.type.split("/")[value?.type.split("/").length - 1]
            ))
      ),
  });

  const formInitialData = {
    UPLOAD_EVIDENCE: null,
    EVIDENCE_CLASSIFICATION: {},
    EVIDENCE_TYPE: {},
    REGULATION: [],
  };

  const fetchData = async () => {
    const data = await GetCustomerInfoClassfication(userID);
    if (data["#result-set-1"] && data["#result-set-1"].length > 0) {
      let options = [];
      data["#result-set-1"].map((item) => {
        options.push({
          value: item.INFO_CLASSIFICATION_ID,
          label: item.DESCRIPTION,
          payload: item,
        });
      });
      setDropDownOptions((prev) => ({ ...prev, classification: options }));
    }

    const data2 = await GetEvidenceForUser(userID);
    if (data2["#result-set-1"] && data2["#result-set-1"].length > 0) {
      setDropDownOptions((prev) => ({
        ...prev,
        evidenceType: data2["#result-set-1"],
      }));
    }
  };

  useEffect(() => {
    if (userID) {
      fetchData();
    }
  }, [userID]);

  const columns = [
    { field: "checkbox", headerName: "", minWidth: 40, maxWidth: 40 },
    {
      field: "QUESTION_ID",
      headerName: "Ref No.",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "QUESTION_TEXT",
      headerName: "Questions",
      minWidth: 250,
      maxWidth: 250,
    },
    { field: "Tags", headerName: "Tags", minWidth: 170, maxWidth: 170 },
    {
      field: "action",
      headerName: "ACTIONS",
      minWidth: 50,
      maxWidth: 50,
      sticky: true,
    },
  ];

  const GetDataOnEvidenceChange = async (element) => {
    if (element !== null) {
      setIsLoading(true);
      setSelectedQuestionList([]);
      setFilterData([]);
      const data = await GetControlsMappedToEvidence(
        userID,
        element.EVIDENCE_TYPE_ID
      );
      if (data["#result-set-1"] && data["#result-set-1"].length > 0) {
        setPageCount(calculatePageCount(data["#result-set-1"]));
        setFilteredTableData(data["#result-set-1"]);
        setTotalQuestionsData(data["#result-set-1"]);

        let temp = [];
        let temp2 = [];
        data["#result-set-1"].map((el) => {
          if (
            !temp.some(
              (ele) =>
                ele.toString() === el.REGULATION_ID + "_" + el.REGULATION_NAME
            )
          ) {
            temp.push(el.REGULATION_ID + "_" + el.REGULATION_NAME);
            temp2.push({
              REGULATION_ID: el.REGULATION_ID,
              REGULATION_NAME: el.REGULATION_NAME,
            });
          }
        });

        setDropDownOptions((prev) => ({ ...prev, regulation: temp2 }));
      }
      setIsLoading(false);
    }
  };

  const TableRowClick = (row) => {
    const bool = SelectedQuestionList.some(
      (el) =>
        `${el.SUBMISSION_ID}_${el.LEGAL_ENTITY_NAME}` ===
        `${row.SUBMISSION_ID}_${row.LEGAL_ENTITY_NAME}`
    );
    if (bool) {
      const data = SelectedQuestionList.filter(
        (el) =>
          `${el.SUBMISSION_ID}_${el.LEGAL_ENTITY_NAME}` !==
          `${row.SUBMISSION_ID}_${row.LEGAL_ENTITY_NAME}`
      );
      setSelectedQuestionList(data);
    } else {
      setSelectedQuestionList((prev) => [...prev, row]);
    }
  };

  const HandleChipKeywords = (keywords, rowElement) => {
    const index = [...KeywordsArr].findIndex(
      (ele) => ele.SUBMISSION_ID === rowElement.SUBMISSION_ID
    );

    if (index > -1) {
      const data = [...KeywordsArr];
      data[index].keywords = keywords;
      setKeywordsArr(data);
    } else {
      let obj = { keywords: keywords, SUBMISSION_ID: rowElement.SUBMISSION_ID };
      setKeywordsArr((prev) => [...prev, obj]);
    }
  };

  const EvidenceLockerClickHandler = (row) => {
    setEvidenceDialogData({
      open: true,
      submissionId: row.SUBMISSION_ID,
      questionId: row.QUESTION_ID,
    });
  };

  const handleDialogClose = () => {
    setEvidenceDialogData({ open: false });
  };

  const SelectAll = (e) => {
    if (e.target.checked) {
      const data = [...FilteredTableData].filter(
        (que) =>
          ![...SelectedQuestionList].some(
            (el) =>
              `${el.SUBMISSION_ID}_${el.LEGAL_ENTITY_NAME}` ===
              `${que.SUBMISSION_ID}_${que.LEGAL_ENTITY_NAME}`
          )
      );
      setSelectedQuestionList((prev) => [...prev, ...data]);
    } else {
      const data1 = [...SelectedQuestionList].filter(
        (que) =>
          ![...FilteredTableData].some(
            (el) =>
              `${el.SUBMISSION_ID}_${el.LEGAL_ENTITY_NAME}` ===
              `${que.SUBMISSION_ID}_${que.LEGAL_ENTITY_NAME}`
          )
      );
      setSelectedQuestionList(data1);
    }
  };

  const SelectAllCheckerFunc = () => {
    const subSelectedID = SelectedQuestionList.map((el) => el.SUBMISSION_ID);
    const checkselectedalldata = FilteredTableData.filter(
      (ques) => !subSelectedID.includes(ques.SUBMISSION_ID)
    );

    if (checkselectedalldata.length === 0 && SelectedQuestionList.length > 0) {
      return true;
    }

    return false;
  };

  const TableGrid = () => {
    return (
      <TableContainer component={Paper} className={`${classes.height100}`}>
        <Table stickyHeader className="data-table">
          <TableHead>
            <TableRow component="tr" style={{ height: 40 }}>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={classes.stickyColl}
                  style={{ minWidth: item.minWidth }}
                >
                  {item.field === "checkbox" ? (
                    <Checkbox
                      edge="end"
                      onChange={(e) => {
                        SelectAll(e);
                      }}
                      disableRipple
                      checked={SelectAllCheckerFunc()}
                    />
                  ) : (
                    item.headerName
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {FilteredTableData &&
              FilteredTableData.map((row, rowIndex) => (
                <TableRow
                  key={"Row_" + rowIndex}
                  component="tr"
                  className={classes.tableRow}
                >
                  {columns.map((col, index) => (
                    <TableCell
                      component="td"
                      key={"column_" + index}
                      style={{ minWidth: col.minWidth, maxWidth: col.maxWidth }}
                      onClick={() => {
                        if (col.field !== "action" && col.field !== "Tags") {
                          TableRowClick(row);
                        }
                      }}
                    >
                      {col.field === "action" && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="View" placement="top">
                            <Box
                              className={`${classes.actionWrap}`}
                              style={{
                                backgroundColor:
                                  themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                            >
                              <VisibilityIcon
                                style={{
                                  color: "#fff",
                                  fontSize: "18px",
                                  margin: "0 auto",
                                }}
                              />
                            </Box>
                          </Tooltip>

                          <Tooltip title="Evidence Locker" placement="top">
                            <Box
                              className={classes.actionWrap}
                              style={{
                                backgroundColor: "#C13636",
                                marginRight: "5px",
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                EvidenceLockerClickHandler(row);
                              }}
                            >
                              <EnhancedEncryptionOutlinedIcon
                                style={{
                                  color: "#fff",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  margin: "3px 0 0 1px",
                                }}
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                      )}

                      {col.field === "checkbox" && (
                        <Checkbox
                          edge="end"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          checked={SelectedQuestionList.some(
                            (el) =>
                              `${el.SUBMISSION_ID}_${el.LEGAL_ENTITY_NAME}` ===
                              `${row.SUBMISSION_ID}_${row.LEGAL_ENTITY_NAME}`
                          )}
                          disableRipple
                        />
                      )}

                      {col.field === "Tags" && (
                        <ChipInput
                          className={classes.chipInput}
                          disableUnderline={true}
                          disabled={
                            !SelectedQuestionList.some(
                              (el) =>
                                `${el.SUBMISSION_ID}_${el.LEGAL_ENTITY_NAME}` ===
                                `${row.SUBMISSION_ID}_${row.LEGAL_ENTITY_NAME}`
                            )
                          }
                          onChange={(e) => {
                            HandleChipKeywords(e, row);
                          }}
                          defaultValue={
                            KeywordsArr.find(
                              (ele) => ele.SUBMISSION_ID === row.SUBMISSION_ID
                            )?.keywords
                          }
                        />
                      )}

                      {col.field === "QUESTION_TEXT" && row[col.field]}

                      {col.field === "QUESTION_ID" &&
                        row.LEGAL_ENTITY_NAME +
                          " - " +
                          row.QUESTION_ID +
                          " - " +
                          row.SUBMISSION_ID}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

            {FilteredTableData.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTags = (value) => {
    const numTags = value.length;

    return (
      <div style={{ display: "flex", width: "80%" }}>
        <div
          style={{
            width: "90%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {value
            .slice(0, 2)
            .map((option, _) => option.REGULATION_NAME)
            .join(", ")}
        </div>
        <div>{numTags > 2 && ` +${numTags - 2}`}</div>
      </div>
    );
  };

  const renderOption = (props, option, selected, identifier) => (
    <li
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
      key={
        identifier === "classification"
          ? `${option.value}_${option.label}`
          : identifier === "type"
          ? `${option.EVIDENCE_SHORTNAME}_${option.EVIDENCE_TYPE_ID}`
          : identifier === "regulation"
          ? `${option.REGULATION_ID}_${option.REGULATION_NAME}`
          : ""
      }
    >
      {identifier === "classification"
        ? option.label
        : identifier === "type"
        ? option.EVIDENCE_SHORTNAME
        : identifier === "regulation"
        ? option.REGULATION_NAME
        : ""}
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        checked={selected}
      />
    </li>
  );

  const SubmitData = (values, { resetForm }) => {
    if (!isVirusFile) {
      let tagError = false;

      SelectedQuestionList.map((el) => {
        const data = KeywordsArr.find(
          (ele) => ele.SUBMISSION_ID === el.SUBMISSION_ID
        );

        if (data === undefined || data["keywords"].length === 0) {
          tagError = true;
          dispatch(
            alertActions.error(
              "Tags are required for Ref No. " +
                el.LEGAL_ENTITY_NAME +
                "-" +
                el.QUESTION_ID +
                "-" +
                el.SUBMISSION_ID
            )
          );
        }
      });

      if (!tagError) {
        const redirectTo = () => {
          history.push("/manage-rfis");
        };

        [...SelectedQuestionList].forEach((question) => {
          let tags = [...KeywordsArr].find(
            (el) => el.SUBMISSION_ID === question.SUBMISSION_ID
          );

          let formData = new FormData();
          formData.append("submissionId", question.SUBMISSION_ID);
          formData.append("questionId", question.QUESTION_ID);
          formData.append("evidenceBy", userID);
          formData.append(
            "evidenceTypeId",
            values.EVIDENCE_TYPE.EVIDENCE_TYPE_ID
          );
          formData.append(
            "tags",
            tags === undefined ? "" : tags["keywords"].join(";")
          );
          formData.append(
            "classifications",
            values.EVIDENCE_CLASSIFICATION.value
          );
          formData.append("files", values.UPLOAD_EVIDENCE);
          const filePath = customerID.toString() + "/" + evidenceLocker + "/";
          formData.append("fileLocation", filePath);
          formData.append("bucketName", window.BUCKET_NAME);
          formData.append("storageType", docStorageType);
          formData.append("preProcessingIP", docStorageIP);
          formData.append("preProcessingURL", docStorageURL);
          dispatch(
            evidenceAction.uploadEvidence(
              formData,
              { "Content-type": "multipart/form-data" },
              redirectTo,
              fileUploadProgress
            )
          );
        });
      }
    } else {
      dispatch(alertActions.error("FILE CONTAINS VIRUS"));
    }
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={formInitialData}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={SubmitData}
    >
      {({ values, setFieldValue, resetForm, touched, errors }) => (
        <Form>
          {loadingFile ? (
            <Loader count={count} />
          ) : (
            <Box style={{ height: "82vh", marginTop: "20px" }}>
              <Grid
                container={true}
                justify="space-between"
                className={classes.height100}
              >
                <Grid item={true} xs={12} md={3} className={classes.height100}>
                  <Typography className={classes.filedTitle}>
                    UPLOAD EVIDENCES
                  </Typography>

                  <Grid container className={classes.GridContainer}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.filedTitle}>
                        Select Evidence Type
                      </Typography>
                      <Field name="EVIDENCE_TYPE">
                        {({ field: { value } }) => (
                          <Autocomplete
                            size="small"
                            className={`${classes.margintop}`}
                            autoHighlight
                            openOnFocus
                            options={DropDownOptions.evidenceType}
                            getOptionLabel={(option) =>
                              option.EVIDENCE_SHORTNAME
                            }
                            renderOption={(props, option, { selected }) => {
                              return renderOption(
                                props,
                                option,
                                selected,
                                "type"
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Search" />
                            )}
                            onChange={(e, val) => {
                              setFieldValue("EVIDENCE_TYPE", val);
                              GetDataOnEvidenceChange(val);
                            }}
                            value={
                              value === null
                                ? null
                                : Object.keys(value).length > 0
                                ? value
                                : null
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.EVIDENCE_TYPE_ID === value.EVIDENCE_TYPE_ID
                            }
                            error={
                              touched.EVIDENCE_TYPE &&
                              Boolean(errors.EVIDENCE_TYPE)
                            }
                          />
                        )}
                      </Field>
                      <ErrorMessage name="EVIDENCE_TYPE">
                        {(msg) => (
                          <Typography
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.GridContainer}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.filedTitle}>
                        Regulation *
                      </Typography>
                      <Field name="REGULATION">
                        {({ field: { value } }) => (
                          <Autocomplete
                            size="small"
                            className={`${classes.margintop}`}
                            autoHighlight
                            openOnFocus
                            multiple
                            id="checkboxes-tags-demo"
                            options={DropDownOptions.regulation}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.REGULATION_NAME}
                            renderOption={(props, option, { selected }) => {
                              return renderOption(
                                props,
                                option,
                                selected,
                                "regulation"
                              );
                            }}
                            renderTags={(value) => {
                              return renderTags(value);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Search" />
                            )}
                            onChange={(e, val) => {
                              setFieldValue("REGULATION", val);
                              setFilterData(val.map((el) => el.REGULATION_ID));
                            }}
                            value={value}
                            isOptionEqualToValue={(option, value) =>
                              option.REGULATION_ID === value.REGULATION_ID
                            }
                          />
                        )}
                      </Field>
                      <ErrorMessage name="REGULATION">
                        {(msg) => (
                          <Typography
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.GridContainer}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.filedTitle}>
                        Select Evidence Classification *
                      </Typography>
                      <Field name="EVIDENCE_CLASSIFICATION">
                        {({ field: { value } }) => (
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option.label === value.label
                            }
                            size="small"
                            className={`${classes.margintop}`}
                            autoHighlight
                            openOnFocus
                            options={DropDownOptions.classification}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option, { selected }) => {
                              return renderOption(
                                props,
                                option,
                                selected,
                                "classification"
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Search" />
                            )}
                            onChange={(e, val) => {
                              if (val === null) {
                                setFieldValue("EVIDENCE_CLASSIFICATION", {});
                              } else {
                                setFieldValue("EVIDENCE_CLASSIFICATION", val);
                              }
                            }}
                            // value={value === null ? null : Object.keys(value).length > 0 ? value : null}
                            // value={value}
                            error={
                              touched.EVIDENCE_CLASSIFICATION &&
                              Boolean(errors.EVIDENCE_CLASSIFICATION)
                            }
                          />
                        )}
                      </Field>
                      <ErrorMessage name="EVIDENCE_CLASSIFICATION">
                        {(msg) => (
                          <Typography
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.GridContainer}>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.filedTitle}>
                        Select Evidence *{" "}
                        {isVirusCheckingAvailable == 1 && (
                          <span style={{ paddingLeft: "10px" }}>
                            {values.UPLOAD_EVIDENCE &&
                              (isVirusFile ? (
                                <Tooltip
                                  title="Virus Found In File"
                                  placement="right"
                                >
                                  <CoronavirusIcon
                                    fontSize="small"
                                    color="error"
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Virus Not Found In File"
                                  placement="right"
                                >
                                  <VerifiedIcon
                                    fontSize="small"
                                    color="success"
                                  />
                                </Tooltip>
                              ))}
                          </span>
                        )}
                      </Typography>
                      <Field name="UPLOAD_EVIDENCE">
                        {({ field: { value } }) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <input
                              accept="image/png, image/jpeg, image/jpg ,.doc,.docx,.pdf,.xls,.xlsx,.csv,.bmp"
                              style={{ display: "none" }}
                              id="raised-button-file"
                              multiple
                              type="file"
                              onChange={async (e) => {
                                if (e.target.files.length > 0) {
                                  if (isVirusCheckingAvailable == 1) {
                                    const fData = new FormData();
                                    fData.append("file", e.target.files[0]);
                                    dispatch(loadingActions.start());
                                    dispatch(
                                      loadingActions.setLoaderText(
                                        "Checking Virus In Uploaded File"
                                      )
                                    );
                                    const response = await virusCheckerService(
                                      fData
                                    );
                                    dispatch(
                                      loadingActions.setLoaderText(null)
                                    );
                                    dispatch(loadingActions.end());
                                    if (response.status == 200) {
                                      if (
                                        response.data &&
                                        response.data.hasVirus
                                      ) {
                                        setIsVirusFile(true);
                                        dispatch(
                                          alertActions.success(
                                            response.data.message
                                          )
                                        );
                                      } else {
                                        setIsVirusFile(false);
                                        dispatch(
                                          alertActions.success(
                                            response.data.message
                                          )
                                        );
                                        setFieldValue(
                                          "UPLOAD_EVIDENCE",
                                          e.target.files[0]
                                        );
                                      }
                                    } else {
                                      dispatch(
                                        alertActions.error(
                                          "Virus Checker Service Failed"
                                        )
                                      );
                                    }
                                  } else {
                                    setFieldValue(
                                      "UPLOAD_EVIDENCE",
                                      e.target.files[0]
                                    );
                                  }
                                } else {
                                  setFieldValue("UPLOAD_EVIDENCE", "");
                                }
                              }}
                            />
                            <TextField
                              id="standard-name"
                              placeholder="Nothing selected"
                              value={
                                value === null || value === undefined
                                  ? ""
                                  : value.name
                              }
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <Divider
                                      variant="middle"
                                      orientation="vertical"
                                      flexItem
                                    />
                                    <label htmlFor="raised-button-file">
                                      <Button
                                        variant="text"
                                        fullWidth
                                        size="small"
                                        component="span"
                                        color="primary"
                                      >
                                        Browse
                                      </Button>
                                    </label>
                                  </>
                                ),
                                startAdornment: value?.name ? (
                                  <CloseIcon
                                    fontSize="inherit"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setFieldValue("UPLOAD_EVIDENCE", null);
                                    }}
                                  />
                                ) : null,
                                readOnly: true,
                                style: { paddingRight: "0px" },
                              }}
                              className={classes.width100}
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage name="UPLOAD_EVIDENCE">
                        {(msg) => (
                          <Typography
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider variant="middle" orientation="vertical" flexItem />

                <Grid
                  item={true}
                  xs={12}
                  md={8}
                  className={classNames(
                    classes.height100,
                    classes.flex,
                    classes.flexCol
                  )}
                >
                  <Typography className={classes.filedTitle}>
                    ATTACH EVIDENCES
                  </Typography>

                  {isLoading === true ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "200px",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <Box style={{ maxHeight: "60%" }}>{TableGrid()}</Box>
                  )}

                  {pageCount > 1 && (
                    <Box my={2}>
                      <Grid container justify="flex-end">
                        <AppPagination
                          colSpan={3}
                          count={pageCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </Grid>
                    </Box>
                  )}

                  <Grid
                    container
                    justify="flex-end"
                    style={{ alignSelf: "flex-end" }}
                    className={classes.marginTopAuto}
                  >
                    <Grid container justify="flex-end">
                      <Button
                        color="primary"
                        className={classNames(
                          classes.btnWidth,
                          classes.marginRight15
                        )}
                        disabled={SelectedQuestionList.length === 0}
                        variant="contained"
                        type="submit"
                        startIcon={<AddIcon style={{ fontSize: "30px" }} />}
                      >
                        ASSIGN EVIDENCE
                      </Button>
                      <Button
                        color="primary"
                        className={classes.btnWidth}
                        variant="outlined"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        CANCEL
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {EvidenceDialogData.open && (
                <EvidenceLockerDialogBox
                  identifier={true}
                  handleDialogClose={handleDialogClose}
                  dialogOpen={EvidenceDialogData.open}
                  submissionId={EvidenceDialogData?.submissionId}
                  questionId={EvidenceDialogData?.questionId}
                />
              )}
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UploadEvidences;
