import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "_actions";

const CustomRichTextEditor = ({
  onChangeHandler,
  data,
  onCancelClick,
  identifier,
}) => {
  const dispatch = useDispatch();

  const wrapperStyle = {
    border: "1px solid #969696",
    height: "100%",
    whiteSpace: "normal",
    wordBreak: "break-word",
  };
  const editorStyle = {
    height: identifier === 2 ? "calc(100% - 50px)" : "calc(100% - 100px)",
    // lineHeight: "25%"
  };
  const contentBlock = htmlToDraft(data === null ? "" : data);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  const [charCount, setCharCount] = useState(0);

  const onEditorStateChange = (editorState) => {
    const content = editorState.getCurrentContent();
    const rawContent = convertToRaw(content);
    const html = draftToHtml(rawContent);
    const text = html.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, "");

    if (text.length <= 200) {
      setEditorState(editorState);
      onChangeHandler(html);
      setCharCount(text.length);
    } else {
      dispatch(loadingActions.start());

      dispatch(alertActions.error("Message cannot exceed 200 characters."));
      dispatch(loadingActions.end());
    }
  };

  const CustomButton = ({ editorState, onChange }) => {
    if (identifier === 1) {
      let htmlToString = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      htmlToString = htmlToString.replace(/<style([\s\S]*?)<\/style>/gi, "");
      htmlToString = htmlToString.replace(/<script([\s\S]*?)<\/script>/gi, "");
      htmlToString = htmlToString.replace(/<\/div>/gi, "");
      htmlToString = htmlToString.replace(/<\/li>/gi, "");
      htmlToString = htmlToString.replace(/<li>/gi, "• ");
      htmlToString = htmlToString.replace(/\n/gi, " ");
      htmlToString = htmlToString.replace(/\t/gi, "");
      htmlToString = htmlToString.replace(/<\/ul>/gi, "");
      htmlToString = htmlToString.replace(/<\/p>/gi, "");
      htmlToString = htmlToString.replace(/<br\s*[/]?>/gi, "");
      htmlToString = htmlToString.replace(/<[^>]+>/gi, "");
      htmlToString = htmlToString.replace(/&nbsp;/g, "");

      return (
        <div>
          <IconButton onClick={onCancelClick}>
            <CloseIcon color="error" sx={{ fontSize: "26px" }} />
          </IconButton>
        </div>
      );
    }

    return (
      <div>
        <IconButton onClick={onCancelClick}>
          <CloseIcon color="error" sx={{ fontSize: "26px" }} />
        </IconButton>
      </div>
    );
  };

  CustomButton.propTypes = {
    editorState: PropTypes.object,
    onChange: PropTypes.func,
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        wrapperStyle={wrapperStyle}
        editorStyle={editorStyle}
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "history",
          ],
        }}
        toolbarCustomButtons={[<CustomButton key={1} />]}
      />
      <div style={{ marginTop: "10px", textAlign: "right" }}>
        {charCount}/200
      </div>
    </div>
  );
};

CustomRichTextEditor.propTypes = {
  onChangeHandler: PropTypes.func,
  onCancelClick: PropTypes.func,
  data: PropTypes.string,
  identifier: PropTypes.number,
};

export default CustomRichTextEditor;
