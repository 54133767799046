/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import constant from "./constants";

const initialState = { questionList: [], regulationDetail: {} };

const updateQuestionClassifications = (state, data) => {
  const tempQuestionList = [...state.questionList]
  const filteredQuestionList = tempQuestionList.map((item) => {
    if(item.REGULATION_ID == data.regulationId && item.QUESTION_ID == data.questionId){
      item['QUESTION_CLASSIFICATION_ID']= data.classificationId
      item['QUESTION_CLASSIFICATION_NAME']= data.classificationText
      return item
    } else {
      return item
    }
  })
  return {
    ...state,
    questionList: filteredQuestionList
  }
}

const updateRegulationControlGroup = (state, data) => {
  const tempQuestionList = [...state.questionList]
  const filteredQuestionList = tempQuestionList.map((item) => {
    if(item.REGULATION_ID == data.regulationId && item.QUESTION_ID == data.questionId){
      item['REGULATOR_CONTROL_GROUP']= data.control_group
      return item
    } else {
      return item
    }
  })
  return {
    ...state,
    questionList: filteredQuestionList
  }
}

const updateQuestionStatus = (state, data) => {
  const tempQuestionList = [...state.questionList]
  const index = tempQuestionList.findIndex((item) => item.QUESTION_ID === data.QUESTION_ID)
  if(index != -1){
    tempQuestionList[index]['ACTIVE'] = data.STATUS
    return {
      ...state,
      questionList: tempQuestionList
    }
  } else {
    return {
      ...state
    }
  }
}

export default function manageQuestions(state = initialState, action) {
  switch (action.type) {
    case constant.manageQuestionsConstant_GET:
      return action.data;
    case constant.manageQuestionsConstant_UPDATE:
      return action.data;
    case constant.manageQuestionsConstant_FAILURE:
      return state;
    case constant.UPDATE_QUESTION_CLASSIFICATIONS:
      return updateQuestionClassifications(state, action.data)
    case constant.UPDATE_REGULATION_CONTROL_GROUP:
      return updateRegulationControlGroup(state, action.data)
    case constant.UPDATE_QUESTION_STATUS:
      return updateQuestionStatus(state, action.data)
    default:
      return state;
  }
}
