import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  makeStyles,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Drawer, IconButton } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CountryDropdown from "_components/CountryListDropDown";
import EntitlementDropdown from "_components/EntitlementDropDown";
import UserRoleDropdown from "_components/UserRoleDropDown";
import ManageTeamDropdown from "_components/ManageTeamDropDown";
import UserServices from "_components/Layout/MyProfilePopup/services";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import langConstant from "_lang";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PasswordIcon from "@mui/icons-material/Password";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SupplierServices from "../services";
import Service from "_components/services";
import sessionService from "_services/session.service";
import AddTeam from "./addTeamDialog";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "_hooks";
import { alertActions } from "_actions";
import Select from "react-select";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  borderNone: {
    border: "none",
  },
  width100: {
    width: "100%",
  },
  profileMDBasicInfoIcon: {
    width: "18px !important",
    height: "18px !important",
    fill: "#000 !important",
  },
  inputProfileField: {
    padding: "5px",
    color: "#000000",
  },
  filedTitle: {
    color: "#a9a8a8",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  suppliersACMOdalOne: {
    right: "0px",
    width: "500px",
    bottom: "0px",
    position: "fixed",
    border: "1px solid rgb(230,230,230)",
    height: "calc(100% - 65px)",
    backgroundColor: "#fff",
    padding: "0px 30px 30px 30px",
    overflowY: "auto",
    "& .MuiDialogTitle-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
    },
    "& .MuiDialogContent-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "calc(100% - 60px)",
    },
  },
  profileMDCloseBtn: {
    top: "9px",
    right: "14px",
    width: "18px",
    height: "18px",
    position: "absolute",
  },
  profileMDCloseFirst: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  profileMDCloseSecond: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(-45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  suppliersTopHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "9px",
  },
  suppliersTopHeaderEdit: {
    "& > div": {
      width: "100%",
    },
    "& input": {
      height: "30px",
      padding: "5px 13px",
    },
  },
  supplierstatusWrapper: {
    "& > .MuiSwitch-root": {
      position: "relative",
      left: "-10px",
    },
  },
  PersonTitle: {
    color: "#000000",
    marginBottom: "5px",
    fontWeight: "500",
  },
  KeyTitle: {
    color: "#a9a8a8",
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },
  filedTitleIcon: {
    display: "flex",
    fontWeight: "500",
    "& svg": {
      marginRight: "6px",
      position: "relative",
      top: "-1px",
    },
  },
  wordBreak: {
    wordBreak: "break-all",
  },
}));

const AddPlatformLogin = ({ show, setShow, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customerId = useSelector(
    (state) => state?.authentication?.detailedInfo?.CUSTOMER_ID
  );
  const userID = sessionService.getUserId();
  // const username = useSelector(
  //   (state) => state.authentication.detailedInfo?.USER_NAME
  // );

  const addTeamModal = useModal();
  const [showPassword, setShowPassword] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);

  const getTeamDetailsList = async () => {
    const customerID = sessionService.getCustomerId();
    const response = await Service.getTeamList(customerID);
    const tempTeamList = [];
    if (response.isSuccess) {
      response.data.forEach((element) => {
        tempTeamList.push({ value: element.TEAM_ID, label: element.TEAM_NAME });
      });
    } else {
      dispatch(alertActions.error(response.message));
    }
    setTeamOptions(tempTeamList);
  };

  useEffect(() => {
    (async () => {
      await getTeamDetailsList();
    })();
  }, []);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const checkUserNameExists = async (customerID, userName) => {
    const response = await SupplierServices.getCheckUsername(
      customerID,
      userName
    );
    if (response.status === 200) {
      if (response.userAllowed) {
        return {
          responseStatus: 200,
          userAllowed: true,
        };
      } else {
        return {
          responseStatus: 200,
          userAllowed: false,
        };
      }
    } else {
      return {
        responseStatus: 500,
      };
    }
  };

  const createUsername = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    const lastInitial = lastName.charAt(0).toUpperCase();
    const username = firstName + lastInitial;

    return username;
  };

  const initialPlatformLoginData = {
    CONTACT_NAME: data ? data.CONTACT_NAME : "",
    USERNAME: data ? createUsername(data.CONTACT_NAME) : "",
    PASSWORD: "",
    PHONE_NUMBER: data ? data.PHONE_NUMBER : "",
    COUNTRY_CODE: "",
    EMAIL_ADDRESS: data ? data.EMAIL_ADDRESS : "",
    ENTITLEMENT: "",
    TEAM: data ? data.TEAM : "",
    ROLE: data ? data.ROLE : "",
    DATA_CLASSIFICATION: "",
    CUSTOMER_ID: customerId,
    SUPPLIER_CONTACT_ID: data ? data.SUPPLIER_CONTACT_ID : "",
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [infoClassification, setInfoClassification] = useState([]);

  useEffect(() => {
    dispatch(
      UserServices.getCustomerInfoClassification(userID, setInfoClassification)
    );
  }, []);

  const validationSchema = Yup.object().shape({
    USERNAME: Yup.string().required("User Name is required"),
    PASSWORD: Yup.string().trim().required("Password is required"),

    PHONE_NUMBER: Yup.number().required("Phone Number is Required"),
    COUNTRY_CODE: Yup.string().required("Country is Required"),
    EMAIL_ADDRESS: Yup.string()
      .trim()
      .email("Invalid Email Address")
      .required("Email is Required"),
    ENTITLEMENT: Yup.string().required("Entitlement is required"),
    TEAM: Yup.string().required("Please assign team to user"),
    ROLE: Yup.string().required("Role is required"),
    DATA_CLASSIFICATION: Yup.string().required(
      "Data Classification is required"
    ),
  });

  const putNewSupplierUser = async (values) => {
    const userNameRes = await checkUserNameExists(customerId, values.USERNAME);
    if (userNameRes.responseStatus === 200) {
      if (userNameRes.userAllowed) {
        dispatch(SupplierServices.putNewSupplierUser(values, customerId));
        setIsEditMode(false);
        setShow(false);
        handleClose();
      } else {
        dispatch(alertActions.error(langConstant.USERNAME_ALREADY_EXISTS));
      }
    }
  };

  const handleClose = () => {
    setIsEditMode(false);
    setShow(false);
  };

  return (
    <Drawer anchor={"right"} open={show} onClose={handleClose}>
      <div className={classes.suppliersACMOdalOne}>
        {" "}
        <CloseIcon onClick={handleClose} />
        <Box>
          <>
            <Formik
              enableReinitialize={true}
              initialValues={initialPlatformLoginData}
              validationSchema={validationSchema}
              onSubmit={putNewSupplierUser}
            >
              {({ values, errors, setFieldValue, resetForm }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={8}>
                      <div className={classes.suppliersTopHeaderTitle}>
                        <Box
                          display="flex"
                          alignItems="center"
                          alignSelf="center"
                        >
                          <Typography className={classes.filedTitle}>
                            {langConstant.CONTACT_NAME}:
                          </Typography>
                          <Typography className={classes.wordBreak}>
                            {data["CONTACT_NAME"]}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          alignContent="center"
                        >
                          <Typography className={classes.filedTitle}>
                            {langConstant.EMAIL}:{"  "}
                          </Typography>

                          <Typography className={classes.wordBreak}>
                            {data["EMAIL_ADDRESS"]}
                          </Typography>
                        </Box>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      style={{ marginTop: "10px", textAlign: "right" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        size="small"
                        startIcon={<SaveIcon />}
                      >
                        {" "}
                        {langConstant.SAVE}{" "}
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        variant="text"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                          setIsEditMode(false);
                          setShow(false);
                        }}
                      >
                        {langConstant.GO_BACK}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={1}>
                      <PersonIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.filedTitle}>
                        {langConstant.USERNAME}
                      </Typography>
                      <Field name="USERNAME">
                        {({ field: { value } }) => (
                          <TextField
                            value={value}
                            // disabled={!isEditMode}
                            className={classes.width100}
                            onChange={(e) =>
                              setFieldValue("USERNAME", e.target.value)
                            }
                            variant="outlined"
                            InputProps={{
                              classes: {
                                input: classes.inputProfileField,
                                notchedOutline: "",
                              },
                            }}
                          />
                        )}
                      </Field>

                      <ErrorMessage name="USERNAME">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={1}>
                      <PasswordIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.filedTitle}>
                        {langConstant.PASSWORD}
                      </Typography>
                      <Field name="PASSWORD">
                        {({ field: { value } }) => (
                          <TextField
                            value={values.PASSWORD}
                            // disabled={!isEditMode}
                            size="small"
                            fullWidth
                            className={classes.width100}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) =>
                              setFieldValue("PASSWORD", e.target.value)
                            }
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="PASSWORD">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={1}>
                      <LocalPhoneIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.filedTitle}>
                        {langConstant.PHONE_NUMBER}
                      </Typography>
                      <Field name="PHONE_NUMBER">
                        {({ field: { value } }) => (
                          <TextField
                            value={value}
                            // disabled={!isEditMode}
                            className={classes.width100}
                            onChange={(e) =>
                              setFieldValue("PHONE_NUMBER", e.target.value)
                            }
                            variant="outlined"
                            InputProps={{
                              classes: {
                                input: classes.inputProfileField,
                                notchedOutline: "",
                              },
                            }}
                          />
                        )}
                      </Field>

                      <ErrorMessage name="PHONE_NUMBER">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={1}>
                      <FlagCircleIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.filedTitle}>
                        {langConstant.COUNTRY_CODE}
                      </Typography>
                      <Field name="COUNTRY_CODE">
                        {() => (
                          <CountryDropdown
                            value={values.COUNTRY_CODE}
                            onChange={(e) =>
                              setFieldValue("COUNTRY_CODE", e.value)
                            }
                            placeholder="Select Country"
                          />
                        )}
                      </Field>

                      <ErrorMessage name="COUNTRY_CODE">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={1}>
                      <MilitaryTechIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.filedTitle}>
                        {langConstant.ENTITLEMENT}
                      </Typography>
                      <Field name="ENTITLEMENT">
                        {() => (
                          <EntitlementDropdown
                            value={values.ENTITLEMENT}
                            onChange={(e) =>
                              setFieldValue("ENTITLEMENT", e.value)
                            }
                            placeholder={langConstant.SELECT_ENTITLEMENT}
                          />
                        )}
                      </Field>

                      <ErrorMessage name="ENTITLEMENT">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={1}>
                      <GroupWorkIcon />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={classes.filedTitle}>
                        {langConstant.TEAM}
                      </Typography>
                      <Select
                        value={teamOptions.find(
                          (team) => team.value == values.TEAM
                        )}
                        options={teamOptions}
                        onChange={(e) => setFieldValue("TEAM", e.value)}
                        placeholder="Select Team"
                      />

                      {/* <ManageTeamDropdown
                        value={values.TEAM}
                        placeholder="Select Team"
                        onChange={(e) => setFieldValue("TEAM", e.value)}
                      /> */}

                      <ErrorMessage name="TEAM">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{ textAlign: "right", marginTop: "5px" }}
                    >
                      <Tooltip
                        title={langConstant.ADD_NEW_TEAM}
                        placement="top"
                      >
                        <IconButton
                          onClick={() => {
                            addTeamModal.openModal();
                            addTeamModal.setData(values.COUNTRY_CODE);
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: "red",
                              fontSize: "32px",
                              marginTop: "5px",
                              marginBottom: "1px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={1}>
                      <AdminPanelSettingsIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.filedTitle}>
                        {langConstant.ROLE}
                      </Typography>
                      <Field name="ROLE">
                        {() => (
                          <UserRoleDropdown
                            value={values.ROLE}
                            onChange={(e) => setFieldValue("ROLE", e.value)}
                            placeholder="Select Role"
                            customerID={customerId}
                          />
                        )}
                      </Field>

                      <ErrorMessage name="ROLE">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.AddressGrid}>
                    <Grid item xs={1}>
                      <DonutSmallIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.filedTitle}>
                        {langConstant.DATA_CLASSIFICATION}
                      </Typography>
                      <Field
                        style={{ width: "100%" }}
                        name="DATA_CLASSIFICATION"
                      >
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <Select
                            className={classes.profileMDBasicSelect}
                            options={infoClassification}
                            placeholder="Select Classification"
                            value={infoClassification.find(
                              (classification) => classification.value == value
                            )}
                            onChange={(classification) =>
                              setFieldValue(
                                "DATA_CLASSIFICATION",
                                classification.value
                              )
                            }
                          />
                        )}
                      </Field>
                      <ErrorMessage name="DATA_CLASSIFICATION">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>

                  {addTeamModal?.isOpen && (
                    <AddTeam
                      addTeamModal={addTeamModal}
                      teamOptions={teamOptions}
                      setTeamOptions={setTeamOptions}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </>
        </Box>
      </div>
    </Drawer>
  );
};

AddPlatformLogin.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  data: PropTypes.object,
};

export default AddPlatformLogin;
