// OP#2830 - UJA8 - UI Development - Manage Teams Profile
// entitlement Pop-up

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import userServices from "../services";
import { alertActions, loadingActions } from "../../../../_actions";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableBody,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import teamActions from "containers/cyber-assessment/ManageTeam/actions";
import { globalConstants } from "_constants";
import sessionService from "_services/session.service";

const useStyles = makeStyles(() => ({
  oddEvenCheckbox: {
    "&:nth-child(odd)": {
      backgroundColor: "#f6f6f6",
    },
  },
  aUSBTNWraperr: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "25px",
    paddingBottom: "30px",
  },
  aUSText: {
    marginBottom: "10px",
    marginTop: "20px",
  },
  entiBtn: {
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
  },
}));

const ChangeEntitlement = ({
  show,
  setShow,
  user_role,
  user_entitlement,
  user_ID,
  setUserData,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loginUserID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const userTeam = useSelector((state) => state.manageTeamMember.teamMembers);

  const [roleOptions, setRoleOptions] = useState([]);
  const [userRole, setUserRole] = useState({ value: "", label: "" });
  const [entitlementOptions, setEntitlementOptions] = useState([]);
  const [selectedEntitlementID, setSelectedEntitlementID] = useState();
  const [userEntitlement, setUserEntitlement] = useState([]);
  const [allEntitlements, setAllEntitlements] = useState([]);

  useEffect(() => {
    (async () => {
      dispatch(loadingActions.start());
      const response = await userServices.getAvailableRoles();
      dispatch(loadingActions.end());
      if (response) {
        if (response.status === 200) {
          if (response["#result-set-1"].length) {
            setRoleOptions(
              response["#result-set-1"].map((role) => {
                return { value: role.ROLE_ID, label: role.ROLE_NAME };
              })
            );
            const userRoleData = response["#result-set-1"].find(
              (role) => role.ROLE_ID === user_role
            );
            setUserRole({
              value: userRoleData.ROLE_ID,
              label: userRoleData.ROLE_NAME,
            });
          }
          dispatch(loadingActions.start());
          const responseEntitlement =
            await userServices.getAvailableEntitlements();
          dispatch(loadingActions.end());
          if (responseEntitlement.status === 200) {
            if (responseEntitlement["#result-set-1"].length) {
              setEntitlementOptions(
                responseEntitlement["#result-set-1"].map((entitlement) => {
                  return {
                    value: entitlement.ENTITLEMENT_MASTER_ID,
                    label: entitlement.ENTITLEMENT_NAME,
                  };
                })
              );
              const responseAllEntitlements = await userServices.getFeatures();
              if (responseAllEntitlements.status === 200) {
                setAllEntitlements(responseAllEntitlements["#result-set-1"]);
              } else {
                dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
              }

              const responseEntitlementMatching =
                await userServices.getCustomerEntitlementFeatureMapping(
                  customerID,
                  user_entitlement
                );

              if (responseEntitlementMatching.status === 200) {
                setUserEntitlement(
                  responseEntitlementMatching["#result-set-1"]
                );
              } else {
                dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
              }
              setSelectedEntitlementID(user_entitlement);
            }
          } else {
            if (responseEntitlement.data && responseEntitlement.data.message) {
              dispatch(
                alertActions.error(
                  responseEntitlement.data.message.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })
                )
              );
            } else {
              dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
            }
          }
        } else {
          if (response.data && response.data.message) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        }
      }
    })();
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const updateUserEntitlement = async () => {
    const payload = {
      i_UserID: user_ID,
      i_RoleID: userRole.value,
      i_ENTITLEMENT_ID: selectedEntitlementID,
    };
    const response = await userServices.updateUserRoleEntitlement(payload);
    if (response.status === 200) {
      if (
        response["#result-set-1"] &&
        response["#result-set-1"][0]["#update-count-1"] === 1
      ) {
        if (userTeam && userTeam.length) {
          let tempUserTeam = [...userTeam];
          const index = userTeam.findIndex((team) => team.USER_ID === user_ID);
          tempUserTeam[index].ROLE_NAME = userRole.label;
          dispatch(teamActions.setUserTeamMembers(tempUserTeam));
          if (user_ID === parseInt(loginUserID)) {
            dispatch({
              type: globalConstants.UPDATE_USER_ROLE,
              data: { id: userRole.value, name: userRole.label },
            });
          }
          setUserData((prevState) => ({
            ...prevState,
            ROLE_ID: userRole.value,
            ROLE_NAME: userRole.label,
            ENTITLEMENT_ID: selectedEntitlementID,
            ENTITLEMENT_NAME: entitlementOptions.find(
              (item) => item.value === selectedEntitlementID
            ).label,
          }));
          handleClose();
        }
        dispatch(alertActions.success(langConstant.USER_ENTITLEMENT_CHANGED));
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  const handleEntitlementChange = async (entitlement) => {
    setSelectedEntitlementID(entitlement.value);
    const response = await userServices.getCustomerEntitlementFeatureMapping(
      customerID,
      entitlement.value
    );
    if (response.status === 200) {
      setUserEntitlement(response["#result-set-1"]);
    } else {
      dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
    }
  };

  return (
    <Dialog open={show} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
      <DialogTitle>{langConstant.CHANGE_ENTITLEMENT}</DialogTitle>
      <DialogContent>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography>{langConstant.SELECT_ROLE}</Typography>
            <Select
              options={roleOptions}
              placeholder={langConstant.SELECT_ROLE}
              value={roleOptions.find((role) => role.value === userRole.value)}
              onChange={(role) => setUserRole(role)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>{langConstant.SELECT_ENTITLEMENT}</Typography>
            <Select
              options={entitlementOptions}
              placeholder={langConstant.SELECT_ENTITLEMENT}
              value={entitlementOptions.find(
                (entitlement) => entitlement.value === selectedEntitlementID
              )}
              onChange={handleEntitlementChange}
            />
          </Grid>
        </Grid>
        <Typography className={classes.aUSText}>
          {userRole && userRole.label} ENTITLEMENT WOULD HAVE ACCESS TO THE
          FOLLOWING SCREENS
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: 440 }}
          className={classes.aUSTableWrapper}
        >
          <Table>
            <TableBody>
              {allEntitlements?.map((item) => {
                return (
                  <>
                    <Grid className={classes.oddEvenCheckbox}>
                      <Checkbox
                        checked={userEntitlement.some(
                          (entitlement) =>
                            entitlement.FEATURE_NAME === item.FEATURE_NAME
                        )}
                        color="success"
                      />
                      <span>{item["FEATURE_NAME"].replaceAll("_", " ")}</span>
                    </Grid>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className={classes.aUSBTNWraperr}>
        <Button
          color="primary"
          onClick={updateUserEntitlement}
          variant="contained"
        >
          <PersonIcon />
          {langConstant.CHANGE_ENTITLEMENT}
        </Button>
        <Button color="primary" onClick={handleClose}>
          {langConstant.CANCEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeEntitlement.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  user_role: PropTypes.number,
  user_entitlement: PropTypes.number,
  user_ID: PropTypes.number,
  setUserData: PropTypes.func,
};

export default ChangeEntitlement;
