/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState, Fragment } from "react";
import { Box, Typography, makeStyles, Grid, Button } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import CreateRule from "./CreateRule";
import individualQuestionAction from "../actions";
import { useDispatch, useSelector } from "react-redux";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash"
import sessionService from "_services/session.service";

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "700",
    padding: "0 5px"
  },
  bottomGridContainer: {
    marginTop: "20px",
    height: "calc(100vh - 230px)",
    maxHeight: "calc(100vh - 230px)",
  },
  btnWidth: {
    width: "150px"
  }

}));

const DataAndAutomationTab = ({ qid, callAlertAction, fetchdata }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const customerID = sessionService.getCustomerId();

  const questionRuleLogic = useSelector(
    (state) => state.manageIndividualQuestion.questionRuleLogic
  );

  const questionResponses = useSelector(
    (state) => state.manageIndividualQuestion.questionResponses
  );

  const updateQuestionToControlMapping = (
    controlId,
    dataAssetAttributeName,
    dataAssetEntityName,
    sourceSystemId,
    flag
  ) => {
    dispatch(
      individualQuestionAction.UpdateQuestionToControlMapping(
        qid,
        controlId,
        dataAssetAttributeName,
        dataAssetEntityName,
        sourceSystemId,
        flag
      )
    );
  };

  const [alloptions, setalloptions] = useState({ DataPointsDropDownOptions: [], SelectedDataPoints: [] });
  const [DataPointsDropDownOptions, setDataPointsDropDownOptions] = useState([]);
  const [FilteredSelectedDataPoints, setFilteredSelectedDataPoints] = useState([]);
  const [isEditDataPoints, setIsEditDataPoints] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formInitialData, setFormInitialData] = useState({ AddDataPoint: [] });
  const [DatapointsToBeDeleted, setDatapointsToBeDeleted] = useState([]);

  const GetQuestionDataPoints = async () => {
    const data = await individualQuestionAction.GetQuestionDataPointMapping(qid, customerID);

    if (data["#result-set-1"].length > 0) {
      setDataPointsDropDownOptions(data["#result-set-1"]);
    }

    if (data["#result-set-2"].length > 0) {
      setFilteredSelectedDataPoints(data["#result-set-2"])
    }

    if (data["#result-set-1"] && data["#result-set-2"]) {
      setalloptions({ DataPointsDropDownOptions: data["#result-set-1"], SelectedDataPoints: data["#result-set-2"] })
    }

    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(individualQuestionAction.getIndividualQuestionRules(qid, customerID));
    GetQuestionDataPoints();
  }, []);

  const ResetAll = () => {
    setFilteredSelectedDataPoints(alloptions.SelectedDataPoints);
    setDatapointsToBeDeleted([]);
    setIsEditDataPoints(false);
  }

  const DeleteDataPoints = (element, index) => {
    if (element.SOURCE_SYSTEM_ID === undefined) {
      setDatapointsToBeDeleted((prev) => ([...prev, element]));
    }
    const data = [...FilteredSelectedDataPoints].filter((el) => `${el.DATA_ASSET_ENTITY_NAME}_${el.DATA_ASSET_ATTRIBUTE_NAME}` !== `${element.DATA_ASSET_ENTITY_NAME}_${element.DATA_ASSET_ATTRIBUTE_NAME}`);
    setFilteredSelectedDataPoints(data);
  }

  const DataPointsList = () => {
    return (
      <List dense className={classes.width100}>
        {FilteredSelectedDataPoints.map((el, i) => {
          const labelId = `list-secondary-label-${el?.DATA_ASSET_ATTRIBUTE_NAME}-${i}`
          return (
            <Fragment key={`${el?.DATA_ASSET_ATTRIBUTE_NAME}--${i}--data`}>
              <ListItem disablePadding style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: "0px 20px 0px 20px" }}>
                  <ListItemText id={labelId} primary={<span style={{ fontWeight: "500" }}>{el?.DATA_ASSET_ATTRIBUTE_NAME}</span>} />
                  <DeleteOutlineRoundedIcon sx={{ fontSize: 30, cursor: "pointer" }} color="error" onClick={() => { DeleteDataPoints(el, i) }} />
                </div>
              </ListItem>
              <Divider variant="middle" />
            </Fragment>
          );
        })}

        {
          FilteredSelectedDataPoints.length === 0 && (
            <ListItem disablePadding style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <ListItemButton style={{ textAlign: "center" }}>
                <ListItemText id={"nodata"} primary={"No record found"} />
              </ListItemButton>
            </ListItem>
          )
        }
      </List>
    )
  }

  const renderOption = (props, option, selected) => (
    <li
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
      key={`${option.DATA_ASSET_ATTRIBUTE_NAME}-${option.DATA_ASSET_ENTITY_NAME}-${option.SOURCE_SYSTEM_ID}-${option.SOURCE_DATA_ID}`}
    >
      {
        option.DATA_ASSET_ATTRIBUTE_NAME
      }
    </li>

  );

  const SubmitData = async (values, { resetForm }) => {

    if (DatapointsToBeDeleted.length > 0) {
      DatapointsToBeDeleted.map((el) => {
        updateQuestionToControlMapping(null, el.DATA_ASSET_ATTRIBUTE_NAME, null, null, 0)
      });

      setDatapointsToBeDeleted([]);
    }

    const data = FilteredSelectedDataPoints.filter((el) => el.SOURCE_SYSTEM_ID !== undefined);

    if (data.length > 0) {
      data.map((ele) => {
        updateQuestionToControlMapping(null, ele.DATA_ASSET_ATTRIBUTE_NAME, ele.DATA_ASSET_ENTITY_NAME, ele.SOURCE_SYSTEM_ID, 1)
      })
    }

    setIsEditDataPoints(false);

    setTimeout(function () {
      fetchdata();
      GetQuestionDataPoints();
    }, 200);

  }

  return (
    <Box style={{ display: "flex", width: "100%" }}>

      <Grid container={true} >
        <Grid item={true} xs={3} md={11}>

          <Formik
            initialValues={formInitialData}
            enableReinitialize={true}
            onSubmit={SubmitData}
          >
            {({ values, setFieldValue, resetForm, touched, errors }) => (
              <Form>
                <Typography className={classes.filedTitle}>QUESTION DATAPOINTS</Typography>
                <Card variant="outlined" style={{ height: "55vh", width: "100%" }}>

                  <Grid container={true} style={{ padding: "10px" }} justify="space-between">

                    <Grid item={true} xs={5} md={12} >
                      <Field name="AddDataPoint" >
                        {({ field: { value } }) => (
                          <>
                            {
                              isEditDataPoints ? (

                                <Autocomplete
                                  size="small"
                                  autoHighlight
                                  openOnFocus
                                  options={DataPointsDropDownOptions}
                                  getOptionLabel={(option) => option.DATA_ASSET_ATTRIBUTE_NAME ? option.DATA_ASSET_ATTRIBUTE_NAME : ""}
                                  renderOption={(props, option, { selected }) => { return renderOption(props, option, selected) }}
                                  renderInput={(params) => <TextField {...params} placeholder="Search" />}
                                  getOptionDisabled={(option) => FilteredSelectedDataPoints?.some((el) => `${el?.DATA_ASSET_ENTITY_NAME}_${el?.DATA_ASSET_ATTRIBUTE_NAME}_${el?.SOURCE_DATA_ID}` === `${option?.DATA_ASSET_ENTITY_NAME}_${option?.DATA_ASSET_ATTRIBUTE_NAME}_${option?.SOURCE_DATA_ID}`)}
                                  onChange={(e, val) => {
                                    if (val !== null) {
                                      setFilteredSelectedDataPoints((prev) => ([...prev, val]));
                                      setFieldValue("AddDataPoint", {});
                                    }
                                  }}
                                // isOptionEqualToValue={(option, value) => option.DATA_ASSET_ATTRIBUTE_NAME === value.DATA_ASSET_ATTRIBUTE_NAME}
                                // value={value}
                                />

                              ) : (
                                <Button color="primary" style={{ height: "40px" }} onClick={() => { setIsEditDataPoints(true) }}>
                                  <AddCircleIcon
                                    style={{ fontSize: "25px", marginRight: "10px" }}
                                  />
                                  ADD/REMOVE DATAPOINTS
                                </Button>
                              )
                            }
                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  <Divider variant="middle" />

                  {
                    isLoading === true ? (
                      <div style={{ display: 'flex', justifyContent: 'center', height: "inherit", alignItems: "center" }}>
                        <CircularProgress />
                      </div>
                    ) : (
                      <CardContent style={{ padding: "0px", height: "87%" }}>
                        <PerfectScrollbar >
                          {DataPointsList()}
                        </PerfectScrollbar>
                      </CardContent>
                    )
                  }

                </Card>


                <Grid container={true} justify="space-between" style={{ width: "45%", marginTop: "15px" }}>

                  {
                    !(_.isEqual(alloptions.SelectedDataPoints, FilteredSelectedDataPoints)) && !(alloptions.SelectedDataPoints.length === 0 && FilteredSelectedDataPoints.lengt === 0) && (
                      <>
                        <Button color="primary" className={classes.btnWidth} variant="contained" type="submit" startIcon={<SaveIcon style={{ fontSize: "30px" }} />}>
                          SAVE
                        </Button>
                        <Button color="primary" className={classes.btnWidth} variant="outlined" onClick={ResetAll}>
                          CANCEL
                        </Button>
                      </>
                    )
                  }

                </Grid>

              </Form>
            )}
          </Formik>

        </Grid>

      </Grid>

      <CreateRule
        qid={qid}
        questionResponses={questionResponses}
        questionRuleLogic={questionRuleLogic}
        dataPoints={alloptions.SelectedDataPoints}
      />
    </Box>
  );
};

DataAndAutomationTab.propTypes = {
  // questionResponses: PropTypes.array,
  qid: PropTypes.string,
  callAlertAction: PropTypes.func,
  fetchdata: PropTypes.func
};

export default DataAndAutomationTab;
