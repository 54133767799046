/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import CommentSection from "_components/CommentSection";
import PropTypes from "prop-types";
import manageRfiActions from "../../../actions";
import langConstant from "_lang";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CommentsDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const updateRfiRemarksCount = (rfiId) => {
    dispatch(manageRfiActions.updateRfiRemarksCount(rfiId));
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={handleClickOpen}
        startIcon={<QuestionAnswerIcon />}
      >
        {props.remarks == 0
          ? langConstant.ADD_REMARKS
          : props.remarks +
            " " +
            (props.remarks > 1 ? langConstant.REMARKS : langConstant.REMARK)}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography align="center" variant="h6">
            {langConstant.REMARKS_HISTORY.toUpperCase()}
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            width: "700px",
          }}
        >
          <CommentSection
            direction={"column-reverse"}
            question_ID={props.questionId}
            submission_ID={props.submissionId}
            remarksCount={() => updateRfiRemarksCount(props.questionId)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

CommentsDialog.propTypes = {
  questionId: PropTypes.string,
  submissionId: PropTypes.number,
  remarks: PropTypes.number,
};
