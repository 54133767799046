import React, { useState } from "react";
import { Box, Grid, Typography, Button, makeStyles } from "@material-ui/core";
import langConstant from "_lang";
import CompareFileModal from "./component/CompareFileModal";
import PolicyServices from "../../Policy/PolicyExplorer/services";
import sessionService from "_services/session.service";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { alertActions, loadingActions } from "_actions";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "_assets/regulativ_logo.png";


const useStyles = makeStyles(() => ({
    loaderWrapper: {
        background: "rgb(255 255 255 / 70%)",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "absolute",
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        zIndex: '10',
        width: '100%',
        height: '100%',
    },
    loaderText: {
        margin: "15px 0",
        fontWeight: "500",
    },
    comparisionWrapper: {
        position:"relative"
    }
}))

const ExecuteComparison = () => {
    
    let documentInitialValues = {
        base_document: '',
        base_document_name: '',
        base_document_filename: '',
        target_document: '',
        target_document_name: '',
        target_document_filename: '',
    }

    let documentComparisonInitialValues = {
        ...documentInitialValues,
        comparison_name: '',
        comparison_type: '',
        threshold_settings: [25,50,75],
        distance_calculator: '',
    }

    let comparisonInitialValues = {
        comparison_name: '',
        comparison_type: '',
        threshold_settings: [25,50,75],
        distance_calculator: '',
    }

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const userID = sessionService.getUserId();
    const [buttonClick, setButtonClick] = useState(null);
    const [selectedBasePolicy, setSelectedBasePolicy] = useState({})
    const [selectedTargetPolicy, setSelectedTargetPolicy] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState("")

    const selectedPolicies = useSelector((state)=>state.policyReducer.selectedPolicies)
    const primaryLogo = useSelector((state)=>state.authentication.detailedInfo?.PRIMARY_LOGO)

    const documentFormik = useFormik({
        initialValues: documentInitialValues,
        validationSchema: yup.object().shape({
            base_document: yup.mixed().when('target_document', {
                is: (target_document) => !target_document,
                then: yup.mixed().required(langConstant.BASE_DOCUMENT_REQUIRED),
                otherwise: yup.mixed(),
            }),
            base_document_name: yup.string().when('base_document', {
                is: (base_document) => base_document,
                then: yup.string().required(langConstant.BASE_DOCUMENT_NAME_REQUIRED),
                otherwise: yup.string(),
            }),
            base_document_filename: yup.string().when('base_document', {
                is: (base_document) => base_document,
                then: yup.string().required(langConstant.BASE_DOCUMENT_FILENAME_REQUIRED),
                otherwise: yup.string(),
            }),
            target_document: yup.mixed().when('base_document', {
                is: (base_document) => !base_document,
                then: yup.mixed().required(langConstant.TARGET_DOCUMENT_REQUIRED),
                otherwise: yup.mixed(),
            }),
            target_document_name: yup.string().when('target_document', {
                is: (target_document) => target_document,
                then: yup.string().required(langConstant.TARGET_DOCUMENT_NAME_REQUIRED),
            }),
            target_document_filename: yup.string().when('target_document', {
                is: (target_document) => target_document,
                then: yup.string().required(langConstant.TARGET_DOCUMENT_FILENAME_REQUIRED),
            }),
        },[['base_document', 'target_document']]),
        onSubmit: async(values) => {
            let formData = new FormData();
            if(values.base_document && values.target_document) {
                formData.append("baseFile", values.base_document);
                formData.append("basePolicyName", values.base_document_name);
                formData.append("targetFile", values.target_document);
                formData.append("targetPolicyName", values.target_document_name);
            } else {
                if(values.base_document){
                    formData.append("baseFile", values.base_document);
                    formData.append("basePolicyName", values.base_document_name);
                }
                if(values.target_document){
                    formData.append("baseFile", values.target_document);
                    formData.append("basePolicyName", values.target_document_name);
                }
            }
            formData.append("userId", userID);
            setIsLoading(true);
            setLoadingMessage(langConstant.FILE_UPLOADING);
            const response = await PolicyServices.uploadFile(formData)
            setLoadingMessage("");
            setIsLoading(false);
            if(response.status === 200) {
                dispatch(alertActions.success(langConstant.FILE_UPLOADED_SUCCESSFULLY))
                if(buttonClick === 'UploadFileOnly') {
                    history.push('/policy-explorer')
                }
            } else {
                dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG))
            }
            
        },
        validateOnBlur: false,
        validateOnChange: false 
    })

    const documentCompareFormik = useFormik({
        initialValues: documentComparisonInitialValues,
        validationSchema: yup.object().shape({
            base_document: yup.mixed().required(langConstant.BASE_DOCUMENT_REQUIRED),
            base_document_name: yup.string().required(langConstant.BASE_DOCUMENT_NAME_REQUIRED),
            base_document_filename: yup.string().required(langConstant.BASE_DOCUMENT_FILENAME_REQUIRED),
            target_document: yup.mixed().required(langConstant.TARGET_DOCUMENT_REQUIRED),
            target_document_name: yup.string().required(langConstant.TARGET_DOCUMENT_NAME_REQUIRED),
            target_document_filename: yup.string().required(langConstant.TARGET_DOCUMENT_FILENAME_REQUIRED),
            comparison_name: yup.string().required(langConstant.COMPARISON_NAME_REQUIRED),
            comparison_type: yup.string().required(langConstant.COMPARISON_TYPE_REQUIRED),
            distance_calculator: yup.string().required(langConstant.DISTANCE_CALCULATOR_REQUIRED),
        }),
        onSubmit: async(values) => {
            let formData = new FormData();
            formData.append("baseFile", values.base_document);
            formData.append("basePolicyName", values.base_document_name);
            formData.append("targetFile", values.target_document);
            formData.append("targetPolicyName", values.target_document_name);
            
            formData.append("userId", userID);
            setIsLoading(true)
            setLoadingMessage(langConstant.FILE_UPLOADING);
            const response = await PolicyServices.uploadFile(formData) 
            setIsLoading(false)
            setLoadingMessage("");
            if(response.status === 200){
                if(response.baseId){
                    values['basePolicyDetail'] = {id: parseInt(response.baseId), name: response.baseFileName}
                }
                if(response.targetId){
                    values['targetPolicyDetail'] = {id: parseInt(response.targetId), name: response.targetFileName}
                }
                setIsLoading(true)
                setLoadingMessage(langConstant.COMPARISON_UNDER_PROCESS);
                const comparisionResponse = await PolicyServices.executeComparison(values, userID)
                setIsLoading(false)
                setLoadingMessage("");
                if(comparisionResponse.status === 200){
                    history.push("/policy-comparison-analysis")
                } else {
                    dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG))
                }
            } else {
                dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG))
            }
        },
        validateOnBlur: false,
        validateOnChange: false
    })

    const comparisonFormik = useFormik({
        initialValues: comparisonInitialValues,
        validationSchema: yup.object().shape({
            comparison_name: yup.string().required(langConstant.COMPARISON_NAME_REQUIRED),
            comparison_type: yup.string().required(langConstant.COMPARISON_TYPE_REQUIRED),
            distance_calculator: yup.string().required(langConstant.DISTANCE_CALCULATOR_REQUIRED),
        }),
        onSubmit: async(values) => {
            values['basePolicyDetail'] = selectedBasePolicy
            values['targetPolicyDetail'] = selectedTargetPolicy
            setIsLoading(true)
            setLoadingMessage(langConstant.COMPARISON_UNDER_PROCESS);
            const response = await PolicyServices.executeComparison(values, userID)
            setIsLoading(false)
            setLoadingMessage("");
            dispatch(loadingActions.end())
            if(response.status === 200){
                history.push("/policy-comparison-analysis")
            } else {
                dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG))
            }
        },
        
    })

    const uploadAndExecuteComparison = () => {
        documentCompareFormik.handleSubmit();
    }

    const uploadFilesOnly = async() => {
        documentFormik.handleSubmit();
    }

    const executeComparisonOnly = () => {
        comparisonFormik.handleSubmit();
    }

    return (
        <Box className={classes.comparisionWrapper}>
            {isLoading && 
                <Box className={classes.loaderWrapper}>
                    <Box 
                        component="img"
                        src={primaryLogo ? primaryLogo : logo}
                    />
                    <Typography className={classes.loaderText}>
                        {loadingMessage}
                    </Typography>
                    <CircularProgress />
                </Box>
            }
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <h1>{langConstant.POLICY_EXECUTE_COMPARISON}</h1>
                    </Grid>
                    {selectedPolicies.length !== 2 && 
                        <Grid item xs={3} container direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <Button 
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        setButtonClick('UploadFileOnly')
                                        uploadFilesOnly()
                                    }}
                                >
                                    {langConstant.UPLOAD_FILES_ONLY}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <CompareFileModal
                    documentFormik={documentFormik}
                    comparisonFormik={comparisonFormik}
                    documentCompareFormik={documentCompareFormik}
                    setSelectedBasePolicy={setSelectedBasePolicy}
                    setSelectedTargetPolicy={setSelectedTargetPolicy}
                />
                {selectedPolicies.length === 2 &&
                    <Grid>
                        <Button 
                            color="primary"
                            variant="contained"
                            onClick={executeComparisonOnly}
                        >
                            {langConstant.EXECUTE_COMPARISON_ONLY}
                        </Button>
                    </Grid>
                }
                {selectedPolicies.length !== 2 &&
                    <Grid>
                        <Button color="primary" variant="contained" onClick={uploadAndExecuteComparison}>
                            {langConstant.UPLOAD_AND_EXECUTE_COMPARISON}
                        </Button>
                    </Grid>}
            </Box>
        </Box>
    );
  };
  
export default ExecuteComparison;