import * as React from "react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const PrettoSlider = styled(Slider)({
    color: "transparent",
    height: 10,
    "& .MuiSlider-track": {
        border: "none"
    },
    "& .MuiSlider-rail": {
        background:
            " linear-gradient(to right, red 40%, rgba(0, 0, 0, 0) 40%), linear-gradient(to right, #FCC034 68%, #6BC351 68%);",
        opacity: 1
    },
    "& .MuiSlider-thumb": {
        // height: 24,
        // width: 24,
        height: 0,
        width: 0,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit"
        },
        "&:before": {
            display: "none"
        }
    },
    "& .MuiSlider-valueLabel": {
        lineHeight: 1.2,
        fontSize: 12,
        background: "unset",
        padding: 0,
        width: 24,
        height: 24,
        borderRadius: "50% 50% 50% 0",
        backgroundColor: "#52af77",
        transformOrigin: "bottom left",
        transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
        "&:before": { display: "none" },
        "&.MuiSlider-valueLabelOpen": {
            transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
        },
        "& > *": {
            transform: "rotate(45deg)"
        }
    },

    "& .MuiSlider-mark": {
        backgroundColor: "#fff",
        height: 8,
        width: 3
    }
});

function CustomizedSlider({ score = 0 }) {
    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "self-end" }}>
            <PrettoSlider
                valueLabelDisplay="on"
                value={score}
                max={125}
                step={5}
                marks
            />
        </Box>
    );
}


CustomizedSlider.propTypes = {
    score: PropTypes.number,
};

export default CustomizedSlider
