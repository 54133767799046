import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import useStyles from '../../styles';
import langConstant from "_lang";
import { useSelector } from 'react-redux';
import ProgressBarLarge from '_components/ProgressBarLarge';


const ProgressComponent = ({progressLogsData, currentStep}) => {

    const classes = useStyles()

    const [progressData, setProgressData] = useState([])
    const [loaderPercentage, setLoaderPercentage] = useState(0)

    const startPage = useSelector((state) => state.uploadPolicyReducer.step1.pages_extraction_start)
    const endPage = useSelector((state) => state.uploadPolicyReducer.step1.pages_extraction_end)

    const countPercentageProgress = (startPage, endPage, currentPage) => {
        const totalPages = (parseInt(endPage) - parseInt(startPage)) + 1
        const totalExtractedPages = (parseInt(currentPage) - parseInt(startPage)) + 1
        let progressPercentage = (100*parseInt(totalExtractedPages))/parseInt(totalPages)
        progressPercentage = Math.round(progressPercentage)
        progressPercentage = (70 * progressPercentage)/100
        return Math.round(progressPercentage)
    }

    useEffect(() => {
        let tempProgressData = []
        let tempProgressLogsData = [...progressLogsData]
        if(currentStep === 1){
            tempProgressLogsData = tempProgressLogsData.filter((item) => item.LOG_VALUE.includes("_stepper1_"))
        } else if(currentStep === 2){
            tempProgressLogsData = tempProgressLogsData.filter((item) => item.LOG_VALUE.includes("_stepper2_"))
            const logIndex = tempProgressLogsData.findLastIndex((item) => {
                const logArray = item.LOG_VALUE.split("_")
                if(logArray.includes('progress started')){
                    return true
                }
            })
            tempProgressLogsData = tempProgressLogsData.slice(logIndex, tempProgressLogsData.length)
        }
        tempProgressLogsData.forEach((item) => { 
            const logString = item.LOG_VALUE.split("_")
            if(logString.length > 2){
                if(logString[1] === "extraction"){
                    if(logString[2] === "stepper1" && currentStep === 1){
                        if(logString.length > 4){
                            tempProgressData.unshift({log: logString[4], time: moment(item.CREATE_DATE,"YYYY-MM-DD HH:mm:ss").format("h:mm a")})
                        }
                    }
                    else if(logString[2] === "stepper2" && currentStep === 2){
                        if(logString.length > 4){
                            if(logString[3] === "progressStatus" && logString[4]){
                                const loaderInfo = logString[4].split(" ")
                                const loaderInfoLen = loaderInfo.length
                                if(loaderInfo[loaderInfoLen - 1].trim() === "started") {
                                    tempProgressData.unshift({log: langConstant.EXTRACTION_STARTED, time: moment(item.CREATE_DATE,"YYYY-MM-DD HH:mm:ss").format("h:mm a")})
                                    setLoaderPercentage(0)
                                } else if(loaderInfo[loaderInfoLen - 1].trim() === "completed") {
                                    tempProgressData.unshift({log: langConstant.EXTRACTION_COMPLETED, time: moment(item.CREATE_DATE,"YYYY-MM-DD HH:mm:ss").format("h:mm a")})
                                    setLoaderPercentage(70)
                                } else if(loaderInfo[loaderInfoLen - 1].trim() === "failed") {
                                    tempProgressData.unshift({log: langConstant.EXTRACTION_FAILED, time: moment(item.CREATE_DATE,"YYYY-MM-DD HH:mm:ss").format("h:mm a")})
                                } else {
                                    const percentageData = countPercentageProgress(startPage, endPage, parseInt(loaderInfo[loaderInfoLen - 1].trim()))
                                    setLoaderPercentage(percentageData)
                                }
                            } else if(logString[3] === "keywordVectorizer"){
                                const loaderInfo = logString[4].split(" ")
                                const loaderInfoLen = loaderInfo.length
                                if(loaderInfo[loaderInfoLen - 1].trim() === "ended") {
                                    setLoaderPercentage(80)
                                }
                                tempProgressData.unshift({log: logString[4], time: moment(item.CREATE_DATE,"YYYY-MM-DD HH:mm:ss").format("h:mm a")})
                            } else if(logString[3] === "Tokenization"){
                                tempProgressData.unshift({log: logString[4], time: moment(item.CREATE_DATE,"YYYY-MM-DD HH:mm:ss").format("h:mm a")})
                                const loaderInfo = logString[4].split(" ")
                                const loaderInfoLen = loaderInfo.length
                                if(loaderInfo[loaderInfoLen - 1].trim() === "completed") {
                                    setLoaderPercentage(90)
                                }
                            } else if(logString[3] === "extractiondb"){
                                tempProgressData.unshift({log: logString[4], time: moment(item.CREATE_DATE,"YYYY-MM-DD HH:mm:ss").format("h:mm a")})
                                const loaderInfo = logString[4].split(" ")
                                const loaderInfoLen = loaderInfo.length
                                if(loaderInfo[loaderInfoLen - 1].trim() === "completed") {
                                    setLoaderPercentage(100)
                                }
                            }
                        }
                    }
                }
            }
            return;
        })
        setProgressData([...tempProgressData])
    },[progressLogsData])
    
    return (
        <Grid>
            <Grid style={{width: "100%", borderBottom: "2px solid black", marginBottom: "10px"}}>
                <Typography style={{fontSize: "16px", fontWeight: "bold"}}>
                    {langConstant.PROGRESS_STATUS}
                </Typography>
            </Grid>
            {currentStep === 2 &&
                <Grid style={{padding: "20px 0px"}}>
                    <ProgressBarLarge percentage={loaderPercentage} size="small" />
                </Grid>
            }
            <Grid style={{width: "100%", textAlign: "center", padding: "10px 0px"}}>
                <CircularProgress style={{width: "25px", height: "25px", color: "#124571"}} />
            </Grid>
            {progressData.map((item, index) => {
                return (
                    <Grid key={index}>
                        <Grid style={{display: "flex"}} container>
                            <Grid item md={9}>
                                <Typography key={index} className={classes.progressText}>{item.log}</Typography>
                            </Grid>
                            <Grid item md={3} style={{textAlign: "right"}}><span className={classes.progressText}>{item.time}</span></Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

ProgressComponent.propTypes = {
    progressLogsData: PropTypes.array,
    currentStep: PropTypes.number,
};

export default ProgressComponent;