import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import useStyles from "../../../styles";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { MoreVert as ActionIcon } from "@mui/icons-material";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { useSelector } from "react-redux";
import { applyGroupByColumn } from "containers/ComplianceCenter/utils";
import { IconButton, Typography, Box } from "@mui/material";
import { listOfActions } from "containers/cyber-assessment/AuditActions/constants";
import { AddActionDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/AddActionDialog";
import { AssignListDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/AssignListDialog";
import { SetStatusDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/SetStatusDialog";
import { SetPriorityDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/SetPriorityDialog";
import { AddNoteDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/AddNoteDialog";
import { AddClosureDateDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/AddClosureDateDialog";
import ComplianceUtils from "./utils";

const ContentView = ({ columns, rows, updateActionsData, block }) => {
  const classes = useStyles();
  const themeDetails = useSelector(getThemeDetails);

  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );

  const [groupingData, setGroupingData] = useState({ group1: [], group2: [] });
  const [groupHeaders, setGroupHeaders] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const groupByColumnValue = "ACTIONS_LIST_ID";
  const groupByColumnLabel = "ACTIONS_LIST_NAME";

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDialog, setCurrentDialog] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, actions) => {
    setAnchorEl(event.currentTarget);
    setSelectedAction(actions);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onMenuItemClick = (type) => {
    handleClose();
    setCurrentDialog(type);
  };

  const modal = {
    closeModal: () => setCurrentDialog(null),
    isOpen: currentDialog !== null,
  };

  useEffect(() => {
    if (rows && rows.length > 0) {
      const responseData = applyGroupByColumn(
        rows,
        groupByColumnLabel,
        groupByColumnValue
      );
      let tempHeadersData = [];
      responseData["headers"].forEach((item) => {
        tempHeadersData.push({
          ...item,
          isOpen: false,
          rows: 5,
          hasMore:
            responseData["values"][item.value] &&
            responseData["values"][item.value].length > 5
              ? true
              : false,
        });
      });
      setGroupHeaders(tempHeadersData);
      setGroupingData(responseData["values"]);
    }
  }, [rows, groupByColumnValue]);

  const updateIsOpen = (header) => {
    const tempGroupHeaders = [...groupHeaders];
    const index = tempGroupHeaders.findIndex(
      (item) => item.value == header.value
    );
    tempGroupHeaders[index].isOpen = !tempGroupHeaders[index].isOpen;
    tempGroupHeaders[index].rows = 5;
    if (groupingData[header.value].length > 5) {
      tempGroupHeaders[index].hasMore = true;
    }
    setGroupHeaders(tempGroupHeaders);
  };

  const fetchMoreData = (headerValue) => {
    const tempGroupHeaders = [...groupHeaders];
    const index = tempGroupHeaders.findIndex(
      (item) => item.value == headerValue
    );
    tempGroupHeaders[index].rows = tempGroupHeaders[index].rows + 5;
    if (tempGroupHeaders[index].rows >= groupingData[headerValue].length) {
      tempGroupHeaders[index].hasMore = false;
    }
    setGroupHeaders(tempGroupHeaders);
  };

  const _renderDialog = () => {
    const commonProps = {
      modal,
      action: selectedAction,
      submitCallback: () => updateActionsData(),
    };
    switch (currentDialog) {
      case listOfActions[0].label:
        return (
          <AddActionDialog
            addActionModal={modal}
            actionList={selectedAction}
            isViewMode
          />
        );
      case listOfActions[1].label:
        return <AssignListDialog {...commonProps} />;
      case listOfActions[2].label:
        return <SetStatusDialog {...commonProps} />;
      case listOfActions[3].label:
        return <SetPriorityDialog {...commonProps} />;
      // case listOfActions[3].label:
      //   return <CommentsDialog modal={modal} action={action} />;
      case listOfActions[4].label:
        return <AddNoteDialog {...commonProps} />;
      case listOfActions[5].label:
        return <AddClosureDateDialog {...commonProps} />;
      default:
        return <></>;
    }
  };

  return (
    <React.Fragment>
      {_renderDialog()}
      <TableContainer component={Paper}>
        <Table className="data-table ">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row" />
              <TableCell component="th" scope="row">
                {langConstant.ACTION_LIST}
              </TableCell>
              {(expandedPanelID == block.id
                ? ComplianceUtils.expandedColumns
                : ComplianceUtils.columns
              ).map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  style={{ minWidth: item.minWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
              {expandedPanelID == block.id && (
                <TableCell component="th" scope="row">
                  {langConstant.ACTIONS}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {groupHeaders && groupHeaders.length > 0 ? (
              groupHeaders.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell
                        component="td"
                        scope="row"
                        style={{ cursor: "pointer" }}
                      >
                        {item.isOpen ? (
                          <ArrowCircleDownIcon
                            style={{
                              color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                            }}
                            onClick={() => updateIsOpen(item)}
                          />
                        ) : (
                          <ArrowCircleRightIcon
                            style={{
                              color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                            }}
                            onClick={() => updateIsOpen(item)}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        colSpan={9}
                        onClick={() => updateIsOpen(item)}
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                      >
                        {item["label"]}
                        <span className={classes.recordLength}>
                          {groupingData[item["value"]]
                            ? groupingData[item["value"]].length
                            : 0}
                        </span>
                      </TableCell>
                    </TableRow>
                    {item.isOpen &&
                      groupingData[item["value"]] &&
                      groupingData[item["value"]]
                        .slice(0, item["rows"])
                        .map((actions, actionsIndex) => (
                          <TableRow key={actionsIndex}>
                            <TableCell component="td" scope="row"></TableCell>
                            <TableCell component="td" scope="row" />
                            {(expandedPanelID == block.id
                              ? ComplianceUtils.expandedColumns
                              : ComplianceUtils.columns
                            ).map((column, index) => {
                              const value = actions[column.field];
                              return (
                                <TableCell
                                  component="td"
                                  scope="row"
                                  key={"column_" + index + actionsIndex}
                                  style={{ minWidth: item.minWidth }}
                                >
                                  {column.format
                                    ? column.format(actions)
                                    : value}
                                </TableCell>
                              );
                            })}
                            {expandedPanelID == block.id && (
                              <TableCell component="td" scope="row">
                                <IconButton
                                  aria-label="action"
                                  onClick={(e) => handleClick(e, actions)}
                                >
                                  <ActionIcon />
                                </IconButton>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  {listOfActions.map((actionItem) => {
                                    const Icon = actionItem?.icon;
                                    return (
                                      <MenuItem
                                        key={actionItem.label}
                                        onClick={() =>
                                          onMenuItemClick(actionItem.label)
                                        }
                                        disabled={actionItem?.isDisabled}
                                      >
                                        <Box
                                          display="flex"
                                          justifyItems="center"
                                          style={{ gap: 16 }}
                                        >
                                          <Icon />
                                          <Typography
                                            style={{ alignSelf: "center" }}
                                            variant="body2"
                                          >
                                            {actionItem.label}
                                          </Typography>
                                        </Box>
                                      </MenuItem>
                                    );
                                  })}
                                </Menu>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                    {item.isOpen && item.hasMore && (
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          component="td"
                          scope="row"
                          className={classes.loadMore}
                        >
                          <span onClick={() => fetchMoreData(item.value)}>
                            {langConstant.LOAD_MORE}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} component="td" scope="row">
                  <Typography className={classes.noData}>
                    {langConstant.NO_DATA_FOUND}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

ContentView.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  updateActionsData: PropTypes.func,
  block: PropTypes.object,
};

export default ContentView;
