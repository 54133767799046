// OP#2883 - UJA11 & UJA12 - UI Development : Manage Regulators & Regulations List
// regulator info Drawer box component

import React, { useEffect, useState } from "react";
import {
  Button,
  DialogTitle,
  Typography,
  Box,
  Grid,
  Switch,
  TextField,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import langConstant from "_lang";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LinkIcon from "@mui/icons-material/Link";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import RegulatorServices from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import Select from "react-select";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AttractionsIcon from "@mui/icons-material/Attractions";
import Checkbox from "@mui/material/Checkbox";
import { Drawer } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  borderNone: {
    border: "none",
  },
  width100: {
    width: "100%",
  },
  inputProfileField: {
    padding: "5px",
    color: "#000000",
  },
  filedTitle: {
    color: "#a9a8a8",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  regulatorsACMOdalOne: {
    right: "0px",
    width: "500px",
    bottom: "0px",
    position: "fixed",
    border: "1px solid rgb(230,230,230)",
    height: "calc(100% - 65px)",
    backgroundColor: "#fff",
    padding: "0px 30px 30px 30px",
    overflowY: "auto",
    "& .MuiDialogTitle-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
    },
    "& .MuiDialogContent-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "calc(100% - 60px)",
    },
  },
  profileMDCloseBtn: {
    top: "9px",
    right: "14px",
    width: "18px",
    height: "18px",
    position: "absolute",
  },
  profileMDCloseFirst: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  profileMDCloseSecond: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(-45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  regulatorsTopHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "9px",
  },
  regulatorsTopHeaderEdit: {
    "& > div": {
      width: "100%",
    },
    "& input": {
      height: "30px",
      padding: "5px 13px",
    },
  },
  regulatorstatusWrapper: {
    "& > .MuiSwitch-root": {
      position: "relative",
      left: "-10px",
    },
  },
  PersonTitle: {
    color: "#000000",
    marginBottom: "5px",
    fontWeight: "500",
  },
  KeyTitle: {
    color: "#a9a8a8",
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },
  filedTitleIcon: {
    display: "flex",
    fontWeight: "500",
    "& svg": {
      marginRight: "6px",
      position: "relative",
      top: "-1px",
    },
  },
  wordBreak: {
    wordBreak: "break-all",
  },
}));

const RegulatorInfoDialog = ({ show, setShow, regulatorId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Entitlements = useSelector(
    (state) => state?.authentication?.userData?.allowedNavItems
  );
  const customerId = useSelector(
    (state) => state?.authentication?.detailedInfo?.CUSTOMER_ID
  );

  const regulatorInitialData = {
    REGULATOR_NAME: "",
    REGULATOR_RESPONSE_TYPE_ID: "",
    ACTIVE: false,
    REGULATOR_WEB_URL: "",
    COUNTRY_CODE: "",
    ADDRESS: "",
    REGULATORY_DOMAIN_ID: "",
    HORIZON_SCAN: false,
  };

  const personData = {
    KEY_PERSON_NAME: "",
    KEY_PERSON_ROLE: "",
    KEY_PERSON_EMAIL_ADDRESS: "",
    KEY_PERSON_PHONE_NUMBER: "",
    ACTIVE: true,
    START_DATE: moment().format("YYYY-MM-DD HH:mm:ss"),
    END_DATE: "",
  };

  const initialKeyPersonData = {
    keyPersons: [],
  };

  const [regulatorData, setRegulatorData] = useState(regulatorInitialData);
  const [keyPersonData, setKeyPersonData] = useState(initialKeyPersonData);
  const [regulatorDomains, setRegulatorDomains] = useState([]);
  const [responseTypes, setResponseTypes] = useState([]);
  const [countryDetails, setCountryDetails] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isKeyPersonEditMode, setIsKeyPersonEditMode] = useState(false);
  const [keyPersonView, setKeyPersonView] = useState(false);

  useEffect(() => {
    (async () => {
      const responseDomains = await RegulatorServices.getRegulatoryDomains();
      const responseTypes = await RegulatorServices.getResponseType();
      const responseCountry = await RegulatorServices.getCountryDetails();
      if (
        responseDomains.status === 200 &&
        responseTypes.status === 200 &&
        responseCountry.status === 200
      ) {
        setRegulatorDomains(
          responseDomains["#result-set-1"].map((item) => {
            return {
              value: item.REGULATORY_DOMAIN_ID,
              label: item.REGULATION_DOMAIN,
            };
          })
        );
        setResponseTypes(
          responseTypes["#result-set-1"].map((item) => {
            return {
              value: item.RESPONSE_TYPE_ID,
              label: item.RESPONSE_TYPE_VALUE,
            };
          })
        );
        setCountryDetails(
          responseCountry["#result-set-1"].map((item) => {
            return { value: item.COUNTRY_CODE, label: item.COUNTRY_SHORT_NAME };
          })
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
      if (regulatorId && customerId) {
        const response = await RegulatorServices.getRegulatorDetails(
          regulatorId,
          customerId
        );
        const keyPersonResponse =
          await RegulatorServices.getRegulatorKeyPersons(regulatorId);
        if (response.status === 200 && keyPersonResponse.status === 200) {
          setRegulatorData({
            ...response["#result-set-1"][0],
            HORIZON_SCAN:
              response["#result-set-1"][0].HORIZON_SCAN === 1 ? true : false,
          });
          setKeyPersonData({
            keyPersons: keyPersonResponse["#result-set-1"].filter(
              (item) => item.ACTIVE === true
            ),
          });
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      } else {
        setIsEditMode(true);
      }
    })();
  }, []);

  const validationSchema = Yup.object().shape({
    REGULATOR_NAME: Yup.string().required("Regulator Name is Required"),
    ADDRESS: Yup.string().required("Address is Required"),
    REGULATOR_WEB_URL: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Regulator Url is Required"),
    COUNTRY_CODE: Yup.string().required("Juridiction is Required"),
    REGULATOR_RESPONSE_TYPE_ID: Yup.string().required(
      "Regulator Response Type is Required"
    ),
    REGULATORY_DOMAIN_ID: Yup.string().required(
      "Regulator Response Type is Required"
    ),
  });

  const keyPersonValidationSchema = Yup.object().shape({
    keyPersons: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          KEY_PERSON_NAME: Yup.string().required("Person is Required"),
          KEY_PERSON_ROLE: Yup.string().required("Person Role is Required"),
          KEY_PERSON_EMAIL_ADDRESS: Yup.string()
            .email("Invalid Email Address")
            .required("Person Email Address is Required"),
          KEY_PERSON_PHONE_NUMBER: Yup.string().required(
            "Person Phone Number is Required"
          ),
        })
      ),
  });

  const editRegulator = (value) => {
    let country = countryDetails.find(
      (item) => item.value === value.COUNTRY_CODE
    );
    value["COUNTRY_SHORT_NAME"] = country.label;
    dispatch(
      RegulatorServices.updateRegulator(value, setRegulatorData, customerId)
    );
    setIsEditMode(false);
  };

  const addRegulator = (value) => {
    let country = countryDetails.find(
      (item) => item.value === value.COUNTRY_CODE
    );
    value["COUNTRY_SHORT_NAME"] = country.label;
    dispatch(RegulatorServices.putRegulator(value, customerId));
    setIsEditMode(false);
    setShow(false);
  };

  const editKeyPersons = async (value) => {
    const tempKeyPersonData = await Promise.all(
      value.keyPersons.map(async (item) => {
        if (item.ID) {
          const response = await RegulatorServices.updateRegulatorKeyPerson(
            item
          );
          if (
            response &&
            response.status === 200 &&
            response["#result-set-1"][0]["pass"] === "pass"
          ) {
            return item;
          }
        } else {
          const response = await RegulatorServices.addRegulatorKeyPerson(item);
          if (
            response &&
            response.status === 200 &&
            response["#result-set-1"][0]["pass"] === "pass"
          ) {
            item["ID"] = response["#result-set-1"][0]["new_id"];
            return item;
          }
        }
      })
    );
    setKeyPersonData({
      keyPersons: tempKeyPersonData.filter((item) => item.ACTIVE === true),
    });
    setIsKeyPersonEditMode(false);
  };

  const handleClose = () => {
    setIsEditMode(false);
    setIsKeyPersonEditMode(false);
    setRegulatorData(regulatorInitialData);
    setKeyPersonData(initialKeyPersonData);
    setShow(false);
  };

  return (
    <Drawer open={show} onClose={handleClose}>
      <div className={classes.regulatorsACMOdalOne}>
        <DialogTitle>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>
        <Box>
          {!keyPersonView ? (
            <>
              <Formik
                enableReinitialize={true}
                initialValues={regulatorData}
                validationSchema={validationSchema}
                onSubmit={(value) =>
                  regulatorId ? editRegulator(value) : addRegulator(value)
                }
              >
                {({ values, setFieldValue, resetForm }) => (
                  <Form>
                    <Grid
                      container
                      spacing={2}
                      className={`${classes.regulatorsTopHeader} ${
                        isEditMode ? classes.regulatorsTopHeaderEdit : ""
                      }`}
                    >
                      <div className={classes.regulatorsTopHeaderTitle}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.REGULATOR_NAME}
                        </Typography>
                        {isEditMode ? (
                          <Field name="REGULATOR_NAME">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue(
                                    "REGULATOR_NAME",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["REGULATOR_NAME"]}
                          </Typography>
                        )}
                        <ErrorMessage name="REGULATOR_NAME">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </div>
                      {!isEditMode && (
                        <div className={classes.regulatorsTopHeaderTitle}>
                          <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => setIsEditMode(true)}
                          >
                            {langConstant.EDIT}
                          </Button>
                        </div>
                      )}
                    </Grid>
                    {isEditMode && (
                      <Grid style={{ marginTop: "20px", textAlign: "right" }}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          size="small"
                          startIcon={<SaveIcon />}
                        >
                          {" "}
                          {langConstant.SAVE}{" "}
                        </Button>
                        {regulatorId ? (
                          <Button
                            color="primary"
                            size="small"
                            variant="text"
                            onClick={() => {
                              setIsEditMode(false);
                              resetForm();
                            }}
                          >
                            {" "}
                            {langConstant.CANCEL}{" "}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            size="small"
                            variant="text"
                            onClick={() => {
                              resetForm();
                            }}
                          >
                            {" "}
                            {langConstant.RESET}{" "}
                          </Button>
                        )}
                      </Grid>
                    )}
                    <Grid
                      className={classNames(
                        classes.regulatorstatusWrapper,
                        classes.AddressGrid
                      )}
                      style={{ marginTop: "20px" }}
                    >
                      <Typography className={classes.filedTitle}>
                        {langConstant.STATUS}
                      </Typography>
                     
                      <Switch
                        checked={values.ACTIVE}
                        onClick={() => setFieldValue("ACTIVE", !values.ACTIVE)}
                      />{" "}
                      <span>{values.ACTIVE ? "ACTIVE" : "INACTIVE"}</span>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.ADDRESS_OF_THE_PRIMARY_OFFICE}
                        </Typography>
                        {isEditMode ? (
                          <Field name="ADDRESS">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue("ADDRESS", e.target.value)
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["ADDRESS"]}
                          </Typography>
                        )}
                        <ErrorMessage name="ADDRESS">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <LinkIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.REGULATOR_URL}
                        </Typography>
                        {isEditMode ? (
                          <Field name="REGULATOR_WEB_URL">
                            {({ field: { value } }) => (
                              <TextField
                                value={value}
                                disabled={!isEditMode}
                                className={classes.width100}
                                onChange={(e) =>
                                  setFieldValue(
                                    "REGULATOR_WEB_URL",
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.inputProfileField,
                                    notchedOutline: isEditMode
                                      ? ""
                                      : classes.borderNone,
                                  },
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          <Typography className={classes.wordBreak}>
                            {values["REGULATOR_WEB_URL"]}
                          </Typography>
                        )}
                        <ErrorMessage name="REGULATOR_WEB_URL">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <AccountTreeOutlinedIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.JURISDICTION}
                        </Typography>
                        <Field name="COUNTRY_CODE">
                          {({ field: { value } }) => (
                            <Select
                              options={countryDetails}
                              placeholder="Select Country"
                              value={countryDetails.find(
                                (country) => country.value === value
                              )}
                              onChange={(country) =>
                                setFieldValue("COUNTRY_CODE", country.value)
                              }
                              isDisabled={!isEditMode}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="COUNTRY_CODE">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <FilterNoneIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.REGULATOR_RESPONSE_TYPE}
                        </Typography>
                        <Field name="REGULATOR_RESPONSE_TYPE_ID">
                          {({ field: { value } }) => (
                            <Select
                              options={responseTypes}
                              placeholder="Select Regulator Response Type"
                              value={responseTypes.find(
                                (type) => type.value === value
                              )}
                              onChange={(type) =>
                                setFieldValue(
                                  "REGULATOR_RESPONSE_TYPE_ID",
                                  type.value
                                )
                              }
                              isDisabled={!isEditMode}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="REGULATOR_RESPONSE_TYPE_ID">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <FolderOpenOutlinedIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className={classes.filedTitle}>
                          {langConstant.REGULATORY_DOMAIN}
                        </Typography>
                        <Field name="REGULATORY_DOMAIN_ID">
                          {({ field: { value } }) => (
                            <Select
                              options={regulatorDomains}
                              placeholder="Select Regulator Response Type"
                              value={regulatorDomains.find(
                                (domain) => domain.value === value
                              )}
                              onChange={(domain) =>
                                setFieldValue(
                                  "REGULATORY_DOMAIN_ID",
                                  domain.value
                                )
                              }
                              isDisabled={!isEditMode}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="REGULATORY_DOMAIN_ID">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.AddressGrid}>
                      <Grid item xs={1}>
                        <AttractionsIcon />
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <Typography className={classes.filedTitle}>
                          Horizon Scan
                        </Typography>
                        <Field name="HORIZON_SCAN">
                          {({ field: { value } }) => (
                            <Checkbox
                              style={{ padding: 0, marginLeft: 6 }}
                              checked={value}
                              color="success"
                              inputProps={{
                                "aria-label": "Mark as Reviewed",
                              }}
                              onChange={(e) => {
                                setFieldValue("HORIZON_SCAN", e.target.checked);
                              }}
                              disabled={
                                !Entitlements.includes(
                                  "MANAGE_REGULATIONS/HORIZON_SCANNING"
                                ) || !isEditMode
                              }
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              {regulatorId && (
                <>
                  <Box
                    style={{
                      borderTop: "1px solid #dedede",
                      marginBottom: "10px",
                    }}
                  />
                  <>
                    {keyPersonData.keyPersons.length > 0 ? (
                      <Grid>
                        <Grid container>
                          <Grid item xs={1}>
                            <PersonIcon />
                          </Grid>
                          <Grid item xs={11}>
                            <Typography className={classes.PersonTitle}>
                              {langConstant.KEY_PERSON}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.KeyTitle}>
                              {" "}
                              Name
                            </Typography>
                            <Typography>
                              {keyPersonData.keyPersons[0].KEY_PERSON_NAME}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.KeyTitle}>
                              {" "}
                              Role
                            </Typography>

                            <Typography>
                              {keyPersonData.keyPersons[0].KEY_PERSON_ROLE}
                            </Typography>
                          </Grid>
                        </Grid>
                        {keyPersonData.keyPersons.length > 0 && (
                          <Typography style={{ textAlign: "right" }}>
                            <Button
                              size="small"
                              style={{ fontWeight: "600" }}
                              variant="text"
                              onClick={() => setKeyPersonView(true)}
                            >
                              {keyPersonData.keyPersons.length === 1
                                ? langConstant.SHOW_MORE_DETAIL
                                : langConstant.SHOW_MORE}
                            </Button>
                          </Typography>
                        )}
                      </Grid>
                    ) : (
                      <Grid>
                        <Button
                          color="primary"
                          size="small"
                          variant="text"
                          style={{ fontWeight: "600" }}
                          onClick={() => setKeyPersonView(true)}
                          startIcon={<AddIcon />}
                        >
                          {langConstant.ADD_KEY_PERSON}
                        </Button>
                      </Grid>
                    )}
                  </>
                </>
              )}
            </>
          ) : (
            <>
              <Grid container style={{ marginBottom: "20px" }}>
                <Grid item xs={6}>
                  <Typography className={classes.filedTitleIcon}>
                    <PersonIcon />
                    {langConstant.KEY_PERSON}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="small"
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      setKeyPersonView(false);
                      setIsEditMode(false);
                      setIsKeyPersonEditMode(false);
                    }}
                  >
                    {langConstant.GO_BACK}
                  </Button>
                </Grid>
              </Grid>

              <Formik
                enableReinitialize={true}
                initialValues={keyPersonData}
                validationSchema={keyPersonValidationSchema}
                onSubmit={(value) => editKeyPersons(value)}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <Form>
                    {!isKeyPersonEditMode && values.keyPersons.length >= 1 && (
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "10px" }}
                      >
                        <Button
                          color="primary"
                          size="small"
                          variant="outlined"
                          startIcon={<EditOutlinedIcon />}
                          onClick={() => setIsKeyPersonEditMode(true)}
                        >
                          {langConstant.EDIT}
                        </Button>
                      </Grid>
                    )}
                    {isKeyPersonEditMode && (
                      <Grid style={{ marginTop: "20px", textAlign: "right" }}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          size="small"
                          startIcon={<SaveIcon />}
                        >
                          {" "}
                          {langConstant.SAVE}{" "}
                        </Button>
                        <Button
                          color="primary"
                          size="small"
                          variant="text"
                          onClick={() => {
                            setIsKeyPersonEditMode(false);
                            resetForm();
                          }}
                        >
                          {" "}
                          {langConstant.CANCEL}{" "}
                        </Button>
                      </Grid>
                    )}
                    <FieldArray
                      name="keyPersons"
                      render={({ remove, push }) => (
                        <>
                          <Grid
                            container
                            style={{ marginTop: "20px", marginBottom: "5px" }}
                          >
                            {values.keyPersons.length > 0 ? (
                              <>
                                {isKeyPersonEditMode && (
                                  <Button
                                    color="primary"
                                    variant="text"
                                    startIcon={
                                      <AddCircleIcon
                                        style={{ fontSize: "30px" }}
                                      />
                                    }
                                    onClick={() =>
                                      push({
                                        ...personData,
                                        REGULATOR_ID:
                                          regulatorData.REGULATOR_ID,
                                      })
                                    }
                                  >
                                    {langConstant.ADD_MORE_KEY_PERSON}
                                  </Button>
                                )}
                              </>
                            ) : (
                              <Button
                                color="primary"
                                variant="text"
                                startIcon={
                                  <AddCircleIcon style={{ fontSize: "30px" }} />
                                }
                                onClick={() => {
                                  setIsKeyPersonEditMode(true);
                                  push({
                                    ...personData,
                                    REGULATOR_ID: regulatorData.REGULATOR_ID,
                                  });
                                }}
                              >
                                {langConstant.ADD_MORE_KEY_PERSON}
                              </Button>
                            )}
                          </Grid>
                          <Grid container>
                            <Grid item xs={11}>
                              {values.keyPersons.length > 0 &&
                                values.keyPersons.map((item, index) => (
                                  <Grid style={{ width: "100%" }} key={index}>
                                    {values.keyPersons[index].ACTIVE && (
                                      <>
                                        <Grid container>
                                          <Grid
                                            container
                                            item
                                            xs={12}
                                            style={{ marginBottom: "10px" }}
                                          >
                                            <Grid item xs={11}>
                                              <Typography
                                                className={classes.filedTitle}
                                              >
                                                {langConstant.KEY_PERSON}
                                              </Typography>
                                              <Field
                                                name={`keyPersons.${index}.KEY_PERSON_NAME`}
                                              >
                                                {({ field: { value } }) => (
                                                  <TextField
                                                    value={value}
                                                    disabled={
                                                      !isKeyPersonEditMode
                                                    }
                                                    className={classes.width100}
                                                    onChange={(e) =>
                                                      setFieldValue(
                                                        `keyPersons.${index}.KEY_PERSON_NAME`,
                                                        e.target.value
                                                      )
                                                    }
                                                    variant="outlined"
                                                    InputProps={{
                                                      classes: {
                                                        input:
                                                          classes.inputProfileField,
                                                        notchedOutline:
                                                          isKeyPersonEditMode
                                                            ? ""
                                                            : classes.borderNone,
                                                      },
                                                    }}
                                                  />
                                                )}
                                              </Field>
                                              <ErrorMessage
                                                name={`keyPersons.${index}.KEY_PERSON_NAME`}
                                              >
                                                {(msg) => (
                                                  <Typography
                                                    style={{ color: "red" }}
                                                  >
                                                    {msg}
                                                  </Typography>
                                                )}
                                              </ErrorMessage>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={1}
                                              style={{ textAlign: "right" }}
                                            >
                                              {isKeyPersonEditMode && (
                                                <DeleteIcon
                                                  style={{ color: "#bb4444" }}
                                                  onClick={() =>
                                                    values.keyPersons[index].ID
                                                      ? setFieldValue(
                                                          `keyPersons.${index}.ACTIVE`,
                                                          false
                                                        )
                                                      : remove(index)
                                                  }
                                                />
                                              )}
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ marginBottom: "10px" }}
                                          >
                                            <Typography
                                              className={classes.filedTitle}
                                            >
                                              {
                                                langConstant.KEY_PERSON_EMAIL_ADDRESS
                                              }
                                            </Typography>
                                            <Field
                                              name={`keyPersons.${index}.KEY_PERSON_EMAIL_ADDRESS`}
                                            >
                                              {({ field: { value } }) => (
                                                <TextField
                                                  value={value}
                                                  className={classes.width100}
                                                  disabled={
                                                    !isKeyPersonEditMode
                                                  }
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `keyPersons.${index}.KEY_PERSON_EMAIL_ADDRESS`,
                                                      e.target.value
                                                    )
                                                  }
                                                  variant="outlined"
                                                  InputProps={{
                                                    classes: {
                                                      input:
                                                        classes.inputProfileField,
                                                      notchedOutline:
                                                        isKeyPersonEditMode
                                                          ? ""
                                                          : classes.borderNone,
                                                    },
                                                  }}
                                                />
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`keyPersons.${index}.KEY_PERSON_EMAIL_ADDRESS`}
                                            >
                                              {(msg) => (
                                                <Typography
                                                  style={{ color: "red" }}
                                                >
                                                  {msg}
                                                </Typography>
                                              )}
                                            </ErrorMessage>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            style={{ marginBottom: "10px" }}
                                          >
                                            <Typography
                                              className={classes.filedTitle}
                                            >
                                              {langConstant.DESIGNATION}
                                            </Typography>
                                            <Field
                                              name={`keyPersons.${index}.KEY_PERSON_ROLE`}
                                            >
                                              {({ field: { value } }) => (
                                                <TextField
                                                  value={value}
                                                  disabled={
                                                    !isKeyPersonEditMode
                                                  }
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `keyPersons.${index}.KEY_PERSON_ROLE`,
                                                      e.target.value
                                                    )
                                                  }
                                                  variant="outlined"
                                                  InputProps={{
                                                    classes: {
                                                      input:
                                                        classes.inputProfileField,
                                                      notchedOutline:
                                                        isKeyPersonEditMode
                                                          ? ""
                                                          : classes.borderNone,
                                                    },
                                                  }}
                                                />
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`keyPersons.${index}.KEY_PERSON_ROLE`}
                                            >
                                              {(msg) => (
                                                <Typography
                                                  style={{ color: "red" }}
                                                >
                                                  {msg}
                                                </Typography>
                                              )}
                                            </ErrorMessage>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            style={{ marginBottom: "10px" }}
                                          >
                                            <Typography
                                              className={classes.filedTitle}
                                            >
                                              {
                                                langConstant.KEY_PERSON_PHONE_NUMBER
                                              }
                                            </Typography>
                                            <Field
                                              name={`keyPersons.${index}.KEY_PERSON_PHONE_NUMBER`}
                                            >
                                              {({ field: { value } }) => (
                                                <TextField
                                                  value={value}
                                                  disabled={
                                                    !isKeyPersonEditMode
                                                  }
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `keyPersons.${index}.KEY_PERSON_PHONE_NUMBER`,
                                                      e.target.value
                                                    )
                                                  }
                                                  variant="outlined"
                                                  InputProps={{
                                                    classes: {
                                                      input:
                                                        classes.inputProfileField,
                                                      notchedOutline:
                                                        isKeyPersonEditMode
                                                          ? ""
                                                          : classes.borderNone,
                                                    },
                                                  }}
                                                />
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`keyPersons.${index}.KEY_PERSON_PHONE_NUMBER`}
                                            >
                                              {(msg) => (
                                                <Typography
                                                  style={{ color: "red" }}
                                                >
                                                  {msg}
                                                </Typography>
                                              )}
                                            </ErrorMessage>
                                          </Grid>
                                        </Grid>
                                        <Box
                                          style={{
                                            borderTop: "1px solid #dedede",
                                            marginBottom: "10px",
                                          }}
                                        />
                                      </>
                                    )}
                                  </Grid>
                                ))}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Box>
      </div>
    </Drawer>
  );
};

RegulatorInfoDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  regulatorId: PropTypes.number,
};

export default RegulatorInfoDialog;
