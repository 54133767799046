/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import langConstant from "_lang";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Typography, Box, Button, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CachedSharpIcon from "@material-ui/icons/CachedSharp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Formik, Field, ErrorMessage, Form, } from "formik";
import * as yup from "yup";
import moment from "moment";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import classNames from 'classnames';
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditIcon from '@mui/icons-material/Edit';
import sessionService from "_services/session.service";
import { alertActions } from "_actions";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { GetEmailNotificationTemplateForUseCase } from "containers/cyber-assessment/ManageAssessments/NewAssignAssessment/services"
import AwsEmailService from "_services/AwsEmailService";
import {
  GetRFIList,
  GetRFIEvidenceAndDataAssetsForQuestion,
  UpdateMultiRFI,
  updateQuestionStatusForRfiDialog,
  PutMultiRFI
} from "../utils/actions"
import { getThemeDetails } from "containers/LoginPage/reducers";



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  filedTitle: {
    padding: "0 5px"
  },
  GridContainer: {
    marginBottom: "20px",
  },
  margintop: {
    marginTop: "8px"
  },
  ActionBtnContainer: {
    maxWidth: "91.666667%",
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  maxHeight: {
    maxHeight: "400px"
  },
  acinStatus: {
    color: '#000000',
    border: '1px solid',
    borderRadius: '5px',
    padding: '3px 5px 1px 5px',
    textAlign: 'center',
    display: 'inline-block',
    textTransform: 'uppercase',
    fontSize: '13px',
    width: "100%"
  },
  activeStatus: {
    borderColor: '#aff3bb',
    backgroundColor: '#aff3bb',
    textAlign: "center"
  },
  blinkingIcon: {
    animation: "$blink 1000ms steps(5, start) infinite"
  },
  "@keyframes blink": {
    to: {
      visibility: "hidden",
    }
  }
}));



export default function RfiDialogBox(props) {
  const {
    QUESTION_STATUS,
    QUESTION_ID,
    SUBMISSION_ID,
    REGULATORS_QUESTION_REF,
  } = props.questionData;
  const themeDetails = useSelector(getThemeDetails);
  const userID = sessionService.getUserId();
  const [open, setOpen] = React.useState({ visible: false });
  const [TableLoopData, setTableLoopData] = useState([]);
  const [viewAssignedRFI, setViewAssignedRFI] = useState({ open: false })
  const [DropDownOptions, setDropDownOptions] = useState({ RfiTeam: [] });
  const [ViewUseEffectHelper, setViewUseEffectHelper] = useState(false);
  const dispatch = useDispatch();
  const RfiRecepients = useSelector((state) => state.MyAssessmnetReducer.RfiRecipeints);
  const RfiEvidence = useSelector((state) => state.MasterAssessment.RfiDialogEvidences);
  const DataAssets = useSelector((state) => state.MasterAssessment.RfiDialogDataAssets);
  const rfiDialogRef = useRef([]);
  const classes = useStyles();
  const [EditRfiData, setEditRfiData] = useState({ AssignedRfis: [], Evidences: [], DataAssets: [] });
  const [tableEditHelperData, setTableEditHelperData] = useState({ isDataUpdated: false, isNewDataAddedInEdit: false })
  const [isBlinking, setIsBlinking] = useState(true);
  const [showAddEditBtnsFlag, setShowAddEditBtnsFlag] = useState(false);
  const [EditedRfiArray, setEditedRfiArray] = useState([]);

  let AWSAccessKey = useSelector((state) => state.authentication.detailedInfo?.REACT_APP_AWS_ACCESS_KEY_ID);
  let AWSSecretKey = useSelector((state) => state.authentication.detailedInfo?.REACT_APP_AWS_SECRET_ACCESS_KEY);

  AWSAccessKey = window.atob(AWSAccessKey ? AWSAccessKey : "")
  AWSSecretKey = window.atob(AWSSecretKey ? AWSSecretKey : "")

  const Tempcolumns = [
    { field: "ASSIGNED_TO", headerName: "ASSIGNED TO", minWidth: 140 },
    { field: "DUE_DATE", headerName: "DUE DATE", minWidth: 50 },
    { field: "action", headerName: "ACTIONS", minWidth: 50, sticky: true },
  ];

  const [columns, setcolumns] = useState(Tempcolumns);

  useEffect(() => {
    if (open.mode === "edit" || open.mode === "view") {
      setcolumns(
        [
          { field: "ASSIGNED_TO", headerName: "ASSIGNED TO", minWidth: 140 },
          { field: "START_DATE", headerName: "SENT DATE", minWidth: 50 },
          { field: "DUE_DATE", headerName: "DUE DATE", minWidth: 50 },
          { field: "RFI_STATUS", headerName: "RFI STATUS", minWidth: 50 },
          { field: "action", headerName: "ACTIONS", minWidth: 50, sticky: true },
        ]
      )

    }

  }, [ViewUseEffectHelper])

  const InitialData = {
    ASSIGN_TEAM: [],
    ASSIGN_INDIVIDUAL: [],
    ASSIGN_EVIDENCES: [],
    DUE_DATE: moment().format("DD MMM yyyy"),
    COVER_NOTE: '',
  }

  const [formInitialData, setFormInitialData] = useState(InitialData);

  const validationSchema = yup.object({
    ASSIGN_TEAM: yup.array().test('required',
      'Team is already assigned',
      (item, Context) => {
        if (Context.parent.ASSIGN_INDIVIDUAL.length === 0) {
          let test = []

          item.map((option) => {
            let boolvalues = TableLoopData.some((el) => el.ASSIGNED_TO === option)
            if (boolvalues === true) {
              test.push(option)
            }
          })

          if (test.length > 0) {
            return false
          }

          if (test.length === 0) {
            return true
          }

        }

        return true

      }).test("required", "required", (item, Context) => {
        if (item.length === 0 && Context.parent.ASSIGN_INDIVIDUAL.length === 0) {
          return false
        }

        return true
      }),
    ASSIGN_INDIVIDUAL: yup.array(),
    ASSIGN_EVIDENCES: yup.array(),
    COVER_NOTE: yup.string().nullable(),
    DUE_DATE: yup.string().nullable().required("required"),
  });

  const fetchdata = async () => {
    const data = await GetRFIList(QUESTION_ID, SUBMISSION_ID);

    if (!data["#result-set-1"] && data.data.message) {
      dispatch(
        alertActions.error(
          data.data.message.map((item, index) => {
            return <div key={index}>{item}</div>;
          })
        )
      );
      return
    }

    let Obj = {}
    if (data["#result-set-1"]) {
      Obj.AssignedRfis = data["#result-set-1"]
    }

    if (data["#result-set-2"]) {
      Obj.Evidences = data["#result-set-2"]
    }

    if (data["#result-set-3"]) {
      Obj.DataAssets = data["#result-set-3"]
    }

    setEditRfiData((prev) => ({ ...prev, ...Obj }));
    setShowAddEditBtnsFlag(true)
  }

  useEffect(() => {
    if (open.mode === "edit" || open.mode === "view") {
      const temp = []

      EditRfiData.AssignedRfis.map((el) => {

        const myArr = []
        let evidences = EditRfiData.Evidences.filter((evidence) => el.RFI_ID == evidence.RFI_REQUEST_ID);
        let dataAssets = EditRfiData.DataAssets.filter((assets) => el.RFI_ID == assets.RFI_ID);

        if (evidences.length > 0) {
          evidences.map((evi) => {
            myArr.push({ group: "EVIDENCES", data: evi, name: evi.EVIDENCE_SHORTNAME })
          })
        }

        if (dataAssets.length > 0) {
          dataAssets.map((data) => {
            myArr.push({ group: "DATA ASSETS", data: data, name: data.DATA_ASSET_ATTRIBUTE_NAME })
          })

        }

        if (el.RECIPIENT_TYPE === "Team") {

          let assignedto = { label: el.RECIPIENT_TYPE + " - " + el.DEPARTMENT, value: el }

          temp.push({ COVER_NOTE: el.RFI_DESCRIPTION, DUE_DATE: moment(el.RFI_DUE_DATE).format("DD MMM yyyy"), ASSIGNED_TO: assignedto, ASSIGN_EVIDENCES: myArr, HELPERGROUP: "team", START_DATE: moment(el.START_DATE).format("DD MMM yyyy"), RFI_STATUS: el.RFI_STATUS, RFI_ID: el.RFI_ID })
        }

        if (el.RECIPIENT_TYPE === "Individual") {
          let assignedto = el.RECIPIENT_TYPE + " - " + el.RECIPIENT_NAME
          temp.push({ COVER_NOTE: el.RFI_DESCRIPTION, DUE_DATE: moment(el.RFI_DUE_DATE).format("DD MMM yyyy"), ASSIGNED_TO: assignedto, ASSIGN_EVIDENCES: myArr, HELPERGROUP: "indi", START_DATE: moment(el.START_DATE).format("DD MMM yyyy"), RFI_STATUS: el.RFI_STATUS, RFI_ID: el.RFI_ID })
        }


      })

      setTableLoopData(temp)

    }
  }, [EditRfiData, ViewUseEffectHelper]);

  useEffect(() => {
    setShowAddEditBtnsFlag(false)
    dispatch(GetRFIEvidenceAndDataAssetsForQuestion(userID, SUBMISSION_ID, QUESTION_ID));

  }, [QUESTION_ID]);


  useEffect(() => {
    fetchdata()
  }, [QUESTION_ID, ViewUseEffectHelper])

  useEffect(() => {

    let temp = []

    if (RfiEvidence?.length > 0) {
      RfiEvidence.map((el) => {
        temp.push({ group: "EVIDENCES", data: el, name: el.EVIDENCE_SHORTNAME })
      })

    }

    if (DataAssets?.length > 0) {
      const data = DataAssets.map((el) => ({ group: "DATA ASSETS", data: el, name: el.DATA_ASSET_ATTRIBUTE_NAME }));
      temp = temp.concat(data)

    }
    setDropDownOptions((prev) => ({ ...prev, DataAssetsAndEvidences: temp }))

  }, [RfiEvidence, DataAssets]);

  useEffect(() => {
    const team = []
    const individual = []
    RfiRecepients?.map((el) => {

      if (el.RECIPIENT_TYPE === "Team") {
        if (!team.includes(el.RECIPIENT_TYPE + " - " + el.DEPARTMENT)) {
          team.push({ label: el.RECIPIENT_TYPE + " - " + el.DEPARTMENT, value: el });
        }
      }

      if (el.RECIPIENT_TYPE === "Individual") {

        if (!individual.includes(el.RECIPIENT_TYPE + " - " + el.RECIPIENT_NAME)) {

          individual.push(el.RECIPIENT_TYPE + " - " + el.RECIPIENT_NAME)
        }
      }


    })
    setDropDownOptions((prev) => ({ ...prev, RfiTeam: team, RfiIndividual: individual, originalRfiIndividual: individual }))
  }, [RfiRecepients])

  const FilterFunction = (e, val, setFieldValue) => {
    if (val.length === 0) {

      const individual = []
      RfiRecepients.map((el) => {
        if (!individual.includes(el.RECIPIENT_TYPE + " - " + el.RECIPIENT_NAME)) {

          individual.push(el.RECIPIENT_TYPE + " - " + el.RECIPIENT_NAME)
        }

      })

      return setDropDownOptions((prev) => ({ ...prev, RfiIndividual: individual }))
    } else {
      setFieldValue("ASSIGN_INDIVIDUAL", [])
    }

    const newdata = val.map((el) => el.label)
    const individuals = []
    const Myset = new Set(newdata);
    RfiRecepients.filter((o) => Myset.has("Team - " + o.DEPARTMENT)).map((el) => {
      if (el.RECIPIENT_TYPE === "Individual") {
        if (!individuals.includes(el.RECIPIENT_TYPE + " - " + el.RECIPIENT_NAME)) {
          individuals.push(el.RECIPIENT_TYPE + " - " + el.RECIPIENT_NAME)
        }
      }
    });

    setDropDownOptions((prev) => ({ ...prev, RfiIndividual: individuals }));

  }

  const handleClickOpen = () => {
    setOpen({ visible: true });
  };

  const handleClose = () => {

    if (TableLoopData.length === 0) {

      if (open.mode === "edit" || open.mode === "view") {
        fetchdata()
      }

    }

    setFormInitialData(InitialData);
    setEditedRfiArray([]);
    setTableEditHelperData({ isDataUpdated: false, isNewDataAddedInEdit: false })
    setOpen({ visible: false });
    setTableLoopData([]);
  };

  const renderTags = (value, identifier, helper) => {
    const numTags = value.length;

    return (
      <div style={{ display: "flex", width: "80%" }}>
        <div
          style={{
            width: "90%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
        >
          {value
            .slice(0, 2)
            .map((option, _) => identifier === false ? (helper === "1stdropdown" ? option.label : option) : option.name)
            .join(", ")}
        </div>
        <div>{numTags > 2 && ` +${numTags - 2}`}</div>
      </div>
    );
  }

  const renderOption = (props, option, selected, identifier, helper) => (
    <li
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
      key={identifier === true ? `${option.name}${option.data.SOURCE_DATA_ID}` : (helper === "1stdropdown" ? option.label : option)}
    >
      {
        identifier === false ? (helper === "1stdropdown" ? option.label : option) : option.name
      }
      <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} checked={selected} />
    </li>

  )

  const deleteRfiFromTable = (rowIndex, rowData) => {

    const deletedArr = JSON.parse(JSON.stringify(TableLoopData));
    if (rowIndex > -1) {
      deletedArr.splice(rowIndex, 1);
    }
    setTableLoopData([...deletedArr])

    if (rowData.RFI_ID !== undefined && rowData.RFI_ID) {

      let evidencesID = rowData.ASSIGN_EVIDENCES.length > 0 ? rowData.ASSIGN_EVIDENCES.filter((elements) => elements.group === "EVIDENCES").map((eles) => eles.data.EVIDENCE_TYPE_ID).join(",") : null
      let dataAssetsID = rowData.ASSIGN_EVIDENCES.length > 0 ? rowData.ASSIGN_EVIDENCES.filter((elements) => elements.group === "DATA ASSETS").map((element) => element.data.SOURCE_DATA_ID).join(",") : null

      let dataobj = {
        i_RFI_ID: rowData.RFI_ID,
        i_EVIDENCE_TYPE_ID: evidencesID,
        i_DATA_ASSETS_ID: dataAssetsID,
        i_RFI_DESCRIPTION: rowData.COVER_NOTE ? rowData.COVER_NOTE : null,
        i_RFI_DUE_DATE: moment(rowData.DUE_DATE).format("YYYY-MM-DD HH:mm:ss"),
        i_UPDATE_TYPE: 1,
      }

      dispatch(UpdateMultiRFI(dataobj, "delete"))

    }

    if (TableLoopData.length === 1) {
      dispatch(updateQuestionStatusForRfiDialog(QUESTION_ID, SUBMISSION_ID, "DRAFT", userID));

    }

  }

  const SendEmail = async (response, element, identifier) => {
    let templateData = JSON.parse(response["#result-set-1"][0].Notification_Template);
    let templateName = response["#result-set-1"][0].Notification_type;
    let recepientname;
    let recepientemail;

    if (element.HELPERGROUP === "team") {
      recepientname = element.ASSIGNED_TO.value.RECIPIENT_NAME;
      recepientemail = element.ASSIGNED_TO.value.EMAIL_ADDRESS;

    } else {
      let tempname = element.ASSIGNED_TO.replace("Individual - ", "");

      if (identifier === true) {
        let dataobj = RfiRecepients.filter((ele) => ele.RECIPIENT_NAME === tempname && ele.RECIPIENT_TYPE === "Individual");
        recepientname = dataobj[0].RECIPIENT_NAME;
        recepientemail = dataobj[0].EMAIL_ADDRESS;

      } else {
        let dataobj = EditRfiData.AssignedRfis.filter((ele) => ele.RECIPIENT_NAME === tempname && ele.RECIPIENT_TYPE === "Individual");
        recepientname = dataobj[0].RECIPIENT_NAME;
        recepientemail = dataobj[0].EMAIL;
      }

    }

    let sourceEmailAddress = response["#result-set-1"][0].OUTBOUND_EMAIL;

    let userObj = {
      QuestionID: QUESTION_ID,
      UserName: recepientname,
    }

    let EmailObj = {
      EmailTo: [recepientemail],
      EmailCC: [],
      EmailBCC: [],
      EmailSourceAddress: sourceEmailAddress,
    }

    await AwsEmailService(AWSSecretKey, AWSAccessKey, EmailObj, userObj, templateData, templateName, dispatch);
  }

  const FinalSubmitData = async () => {
    const data = [...TableLoopData]
    const response = await GetEmailNotificationTemplateForUseCase(2, "RFI_Email");
    await Promise.all(

      data.forEach((el) => {

        let evidencesID = el.ASSIGN_EVIDENCES.length > 0 ? el.ASSIGN_EVIDENCES.filter((elements) => elements.group === "EVIDENCES").map((eles) => eles.data.EVIDENCE_TYPE_ID).join(",") : null
        let dataAssetsID = el.ASSIGN_EVIDENCES.length > 0 ? el.ASSIGN_EVIDENCES.filter((elements) => elements.group === "DATA ASSETS").map((element) => element.data.SOURCE_DATA_ID).join(",") : null
        let recepientname = el.HELPERGROUP === "team" ? (el.ASSIGNED_TO.value.RECIPIENT_TYPE + " - " + el.ASSIGNED_TO.value.RECIPIENT_NAME) : el.ASSIGNED_TO

        let obj = {
          i_RECEPIENT_NAME: recepientname,
          i_SUBMISSION_ID: SUBMISSION_ID,
          i_QUESTION_ID: QUESTION_ID,
          i_EVIDENCE_TYPE_ID: evidencesID,
          i_DATA_ASSETS_ID: dataAssetsID,
          i_RFI_DESCRIPTION: el.COVER_NOTE ? el.COVER_NOTE : null,
          i_RFI_DUE_DATE: moment(el.DUE_DATE).format("YYYY-MM-DD HH:mm:ss")
        }

        dispatch(PutMultiRFI(obj));

        if (response["#result-set-1"] || response["#result-set-1"].length !== 0) {
          SendEmail(response, el, true);
        } else {
          dispatch(alertActions.error("Email Template Error"));
        }

      }))

    handleClose();
    setTableLoopData([]);
    await fetchdata();

    dispatch(updateQuestionStatusForRfiDialog(QUESTION_ID, SUBMISSION_ID, "RFI_SENT", userID));

  }

  const FinalSubmitEditData = async () => {

    const data = [...TableLoopData];
    let response;
    let response2;

    if (TableLoopData.some((el) => el.RFI_ID === undefined)) {
      response = await GetEmailNotificationTemplateForUseCase(2, "RFI_Email");
    }

    if (EditedRfiArray.length > 0) {
      response2 = await GetEmailNotificationTemplateForUseCase(2, "RFI_Update_Email");
    }

    data.forEach((el) => {

      let evidencesID = el.ASSIGN_EVIDENCES.length > 0 ? el.ASSIGN_EVIDENCES.filter((elements) => elements.group === "EVIDENCES").map((eles) => eles.data.EVIDENCE_TYPE_ID).join(",") : null
      let dataAssetsID = el.ASSIGN_EVIDENCES.length > 0 ? el.ASSIGN_EVIDENCES.filter((elements) => elements.group === "DATA ASSETS").map((element) => element.data.SOURCE_DATA_ID).join(",") : null
      let recepientname = el.HELPERGROUP === "team" ? (el.ASSIGNED_TO.value.RECIPIENT_TYPE + " - " + el.ASSIGNED_TO.value.RECIPIENT_NAME) : el.ASSIGNED_TO


      if (el.RFI_ID === undefined || el.RFI_ID === null || el.RFI_ID === "") {

        if (tableEditHelperData.isNewDataAddedInEdit === true) {
          let myobj = {
            i_RECEPIENT_NAME: recepientname,
            i_SUBMISSION_ID: SUBMISSION_ID,
            i_QUESTION_ID: QUESTION_ID,
            i_EVIDENCE_TYPE_ID: evidencesID,
            i_DATA_ASSETS_ID: dataAssetsID,
            i_RFI_DESCRIPTION: el.COVER_NOTE ? el.COVER_NOTE : null,
            i_RFI_DUE_DATE: moment(el.DUE_DATE).format("YYYY-MM-DD HH:mm:ss")
          }

          dispatch(PutMultiRFI(myobj));

          if (response["#result-set-1"] || response["#result-set-1"].length !== 0) {
            SendEmail(response, el, true);
          } else {
            dispatch(alertActions.error("Email Template Error"));
          }
        }

      }

      if (el.RFI_ID !== undefined) {

        let obj = {
          i_RFI_ID: el.RFI_ID,
          i_EVIDENCE_TYPE_ID: evidencesID,
          i_DATA_ASSETS_ID: dataAssetsID,
          i_RFI_DESCRIPTION: el.COVER_NOTE ? el.COVER_NOTE : null,
          i_RFI_DUE_DATE: moment(el.DUE_DATE).format("YYYY-MM-DD HH:mm:ss"),
          i_UPDATE_TYPE: 0,

        }
        dispatch(UpdateMultiRFI(obj));

        if (EditedRfiArray.includes(el.RFI_ID)) {
          if (response2["#result-set-1"] || response2["#result-set-1"].length !== 0) {
            SendEmail(response2, el, false);
          } else {
            dispatch(alertActions.error("Email Template Error"));
          }
        }
      }

    });

    handleClose();
    setTableLoopData([]);
    setFormInitialData(InitialData);
    setTableEditHelperData({ isDataUpdated: false, isNewDataAddedInEdit: false });
    setEditedRfiArray([]);

  }

  const EditRfiFromTable = (rowdata, rowIndex) => {
    setFormInitialData(InitialData)
    setIsBlinking(true)
    setTableEditHelperData((prev) => ({ ...prev, tableIndex: rowIndex, changed: false, changedindex: null, EditRfiId: rowdata.RFI_ID }))

    let assignteam = [];
    let assignindividual = [];

    if (rowdata.HELPERGROUP === "team") {
      assignteam.push(rowdata.ASSIGNED_TO)
    }

    if (rowdata.HELPERGROUP === "indi") {
      assignindividual.push(rowdata.ASSIGNED_TO);
    }

    const FormData = {
      ASSIGN_TEAM: assignteam,
      ASSIGN_INDIVIDUAL: assignindividual,
      ASSIGN_EVIDENCES: rowdata.ASSIGN_EVIDENCES,
      DUE_DATE: moment(rowdata.DUE_DATE).format("DD MMM yyyy"),
      COVER_NOTE: rowdata.COVER_NOTE,
    }

    setFormInitialData({ ...FormData });
  }


  const statusColorFilter = (data) => {

    if (data === undefined || data === null || data === "") {
      return
    }

    let color;
    let word;

    if (data === "RFI_SENT") {
      color = "#86C1FD"
      word = "RFI SENT"
    }

    if (data === "RFI_PENDING") {
      color = "#FDF897"
      word = "RFI PENDING"
    }

    if (data === "RFI_OVERDUE") {
      color = "#FABCBB"
      word = "RFI OVERDUE"
    }

    if (data === "RFI_RESOLVED") {
      color = "#C4F5C8"
      word = "RFI RESOLVED"
    }

    return <span style={{ backgroundColor: color, borderColor: color, textAlign: "center" }} className={classNames(classes.acinStatus)}>
      {word}
    </span>

  }

  const AddRfisInEdit = () => {
    setTableEditHelperData((prev) => ({ ...prev, tableIndex: null }));
    setFormInitialData(InitialData)
    setOpen((prev) => ({ ...prev, mode: "addedit" }))

  }

  return (
    <div>

      {showAddEditBtnsFlag && EditRfiData.AssignedRfis && (QUESTION_STATUS == "DRAFT" || QUESTION_STATUS == "SENT_BACK" || QUESTION_STATUS == "RFI_SENT") && (
        <>

          {

            EditRfiData?.AssignedRfis?.length > 0 && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => { setOpen({ visible: true, mode: "view" }); setViewUseEffectHelper(!ViewUseEffectHelper) }}
                style={{ borderRadius: "50px", height: "35px" }}
              >
                <CachedSharpIcon style={{ fontSize: "20px", marginRight: "10px" }} />{" "}
                Edit RFI
              </Button>
            )}


          {
            EditRfiData?.AssignedRfis?.length === 0 && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                style={{ borderRadius: "50px", height: "35px" }}
              >
                <CachedSharpIcon style={{ fontSize: "20px", marginRight: "10px" }} />{" "}
                {langConstant.REQUEST_RFI}
              </Button>

            )}


        </>

      )}

      <Dialog
        onClose={() => { handleClose() }}
        aria-labelledby="customized-dialog-title"
        open={open.visible}
        scroll={"paper"}
        maxWidth={"lg"}
        fullWidth={true}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          {
            open.mode === "edit" || open.mode === "view" ? "EDIT RFI ASSIGNMENTS" : "RFI ASSIGNMENTS"
          }

        </DialogTitle>
        <DialogContent
          ref={rfiDialogRef}
          dividers
          style={{ padding: "30px 40px 30px 40px" }}
        >
          <Box style={{ minHeight: "70vh" }} display="flex">
            <Grid container style={{ width: "40%" }}>

              <Formik
                validateOnChange={true}
                initialValues={formInitialData}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {

                  if (open.mode === "edit") {
                    const index = tableEditHelperData.tableIndex
                    setTableEditHelperData((prev) => ({ ...prev, changed: true, changedindex: prev.tableIndex, isDataUpdated: true }));

                    setTimeout(() => {
                      setIsBlinking(false);
                    }, 5000);

                    const tabledata = [...TableLoopData]

                    if (tabledata[index].HELPERGROUP === "team") {

                      let dataobj = {
                        COVER_NOTE: values.COVER_NOTE,
                        DUE_DATE: moment(values.DUE_DATE).format("DD MMM yyyy"),
                        ASSIGNED_TO: values.ASSIGN_TEAM[0],
                        ASSIGN_EVIDENCES: values.ASSIGN_EVIDENCES,
                        HELPERGROUP: "team",
                        RFI_STATUS: tabledata[index].RFI_STATUS,
                        START_DATE: tabledata[index].START_DATE,
                        RFI_ID: tabledata[index].RFI_ID

                      }

                      tabledata[index] = dataobj
                      setTableLoopData(tabledata)

                    }

                    if (tabledata[index].HELPERGROUP === "indi") {


                      let dataobj = {
                        COVER_NOTE: values.COVER_NOTE,
                        DUE_DATE: moment(values.DUE_DATE).format("DD MMM yyyy"),
                        ASSIGNED_TO: values.ASSIGN_INDIVIDUAL[0],
                        ASSIGN_EVIDENCES: values.ASSIGN_EVIDENCES,
                        HELPERGROUP: "indi",
                        RFI_STATUS: tabledata[index].RFI_STATUS,
                        START_DATE: tabledata[index].START_DATE,
                        RFI_ID: tabledata[index].RFI_ID
                      }

                      tabledata[index] = dataobj
                      setTableLoopData(tabledata)

                    }

                    setOpen((prev) => ({ ...prev, mode: "view" }));
                    setFormInitialData(InitialData);
                    setEditedRfiArray((prev) => ([...prev, tableEditHelperData.EditRfiId]))

                  } else {

                    if (open.mode === "addedit") {
                      setOpen((prev) => ({ ...prev, mode: "view" }));
                      setTableEditHelperData((prev) => ({ changed: true, changedindex: prev.tableIndex, isDataUpdated: true, isNewDataAddedInEdit: true }));
                    }

                    if (values.ASSIGN_INDIVIDUAL.length === 0 && values.ASSIGN_TEAM.length > 0) {
                      const temp = []
                      values.ASSIGN_TEAM.map((el) => {
                        temp.push({ COVER_NOTE: values.COVER_NOTE, DUE_DATE: moment(values.DUE_DATE).format("DD MMM yyyy"), ASSIGNED_TO: el, ASSIGN_EVIDENCES: values.ASSIGN_EVIDENCES, HELPERGROUP: "team" })
                      });

                      setTableLoopData((prev) => ([...prev, ...temp]))
                    }

                    if (values.ASSIGN_INDIVIDUAL.length > 0 && values.ASSIGN_TEAM.length >= 0) {

                      if (values.ASSIGN_TEAM.length === 0) {
                        // do something
                      }
                      const temp2 = []

                      values.ASSIGN_INDIVIDUAL.map((el) => {
                        temp2.push({ COVER_NOTE: values.COVER_NOTE, DUE_DATE: moment(values.DUE_DATE).format("DD MMM yyyy"), ASSIGNED_TO: el, ASSIGN_EVIDENCES: values.ASSIGN_EVIDENCES, HELPERGROUP: "indi" })
                      });

                      setTableLoopData((prev) => ([...prev, ...temp2]))
                    }

                    resetForm();
                  }

                  setDropDownOptions((prev) => ({ ...prev, RfiIndividual: prev.originalRfiIndividual }))

                }}
              >
                {({
                  values,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                  touched,
                  errors,
                }) => (
                  <Form style={{ width: "100%" }}>
                    <Box>

                      <Grid container className={classes.GridContainer}>
                        <Grid item xs={11}>
                          <Typography className={classes.filedTitle}>
                            Assign RFI to team
                          </Typography>
                          <Field name="ASSIGN_TEAM" >
                            {({ field: { value } }) => (

                              <Autocomplete
                                size="small"
                                className={`${classes.margintop}`}
                                autoHighlight
                                openOnFocus
                                multiple
                                id="checkboxes-tags-demo"
                                options={DropDownOptions.RfiTeam}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.label}
                                renderTags={(value) => { return renderTags(value, false, "1stdropdown") }}
                                renderOption={(props, option, { selected }) => { return renderOption(props, option, selected, false, "1stdropdown") }}
                                renderInput={(params) => <TextField {...params} placeholder="Search" />}
                                error={touched.ASSIGN_TEAM && Boolean(errors.ASSIGN_TEAM)}
                                onChange={(e, val) => { setFieldValue("ASSIGN_TEAM", val); FilterFunction(e, val, setFieldValue) }}
                                value={value}
                                disabled={open.mode === "view" || open.mode === "edit"}
                                isOptionEqualToValue={(option, value) => option.label === value.label}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="ASSIGN_TEAM">
                            {(msg) => (
                              <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>

                      <Grid container className={classes.GridContainer}>
                        <Grid item xs={11}>
                          <Typography className={classes.filedTitle}>
                            Assign RFI to an individual (Optional)
                          </Typography>
                          <Field name="ASSIGN_INDIVIDUAL">
                            {({ field: { value } }) => (

                              <Autocomplete
                                isOptionEqualToValue={(option, value) => option === value}
                                size="small"
                                className={`${classes.margintop}`}
                                autoHighlight
                                openOnFocus
                                multiple
                                id="checkboxes-tags-demo"
                                options={DropDownOptions.RfiIndividual}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option}
                                renderTags={(value) => { return renderTags(value, false) }}
                                renderOption={(props, option, { selected }) => { return renderOption(props, option, selected, false) }}
                                renderInput={(params) => <TextField {...params} placeholder="Search" />}
                                error={touched.ASSIGN_INDIVIDUAL && Boolean(errors.ASSIGN_INDIVIDUAL)}
                                onChange={(e, val) => setFieldValue("ASSIGN_INDIVIDUAL", val)}
                                value={value}
                                getOptionDisabled={(option) => TableLoopData.some((el) => el.ASSIGNED_TO === option)}
                                disabled={open.mode === "view" || open.mode === "edit"}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="ASSIGN_INDIVIDUAL">
                            {(msg) => (
                              <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>

                      <Grid container className={classes.GridContainer}>
                        <Grid item xs={11}>
                          <Typography className={classes.filedTitle}>
                            Assign evidences and data assets
                          </Typography>
                          <Field name="ASSIGN_EVIDENCES">
                            {({ field: { value } }) => (

                              <Autocomplete
                                size="small"
                                className={`${classes.margintop}`}
                                autoHighlight
                                openOnFocus
                                multiple
                                id="checkboxes-tags-demo"
                                options={DropDownOptions.DataAssetsAndEvidences}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                groupBy={(option) => option.group}
                                renderOption={(props, option, { selected }) => { return renderOption(props, option, selected, true) }}
                                renderTags={(value) => { return renderTags(value, true) }}
                                renderInput={(params) => <TextField {...params} placeholder="Search" />}
                                error={touched.ASSIGN_EVIDENCES && Boolean(errors.ASSIGN_EVIDENCES)}
                                onChange={(e, val) => setFieldValue("ASSIGN_EVIDENCES", val)}
                                value={value}
                                disabled={open.mode === "view"}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                              />

                            )}
                          </Field>
                          <ErrorMessage name="ASSIGN_EVIDENCES">
                            {(msg) => (
                              <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>

                      <Grid container className={classes.GridContainer}>
                        <Grid item xs={11}>
                          <Typography className={classes.filedTitle}>
                            Due Date
                          </Typography>
                          <Field name="DUE_DATE">
                            {({ field: { value } }) => (
                              <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.margintop}>
                                <KeyboardDatePicker
                                  size="small"
                                  disableToolbar
                                  inputVariant="outlined"
                                  variant="inline"
                                  format="dd MMM yyyy"
                                  margin="normal"
                                  autoOk
                                  disablePast
                                  value={value}
                                  onChange={(val) => {
                                    setFieldValue("DUE_DATE", val);
                                  }}
                                  InputProps={{ readOnly: true }}
                                  className={`dueDate_error ${classes.width100} ${classes.margintop}`}
                                  error={touched.DUE_DATE && Boolean(errors.DUE_DATE)}
                                  helperText={null}
                                  disabled={open.mode === "view"}
                                />
                              </MuiPickersUtilsProvider>

                            )}
                          </Field>
                          <ErrorMessage name="DUE_DATE">
                            {(msg) => (
                              <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>

                      <Grid container className={classes.GridContainer}>
                        <Grid item xs={11}>
                          <Typography className={classes.filedTitle}>
                            Cover Note
                          </Typography>
                          <Field name="COVER_NOTE">
                            {({ field: { value } }) => (
                              <TextField
                                multiline
                                rows={4}
                                variant="outlined"
                                value={value === null ? "" : value}
                                inputProps={{ maxLength: 1000 }}
                                onChange={(e) => setFieldValue("COVER_NOTE", e.target.value)}
                                error={touched.COVER_NOTE && Boolean(errors.COVER_NOTE)}
                                className={`covernote_error ${classes.width100} ${classes.margintop}`}
                                disabled={open.mode === "view"}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="COVER_NOTE">
                            {(msg) => (
                              <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>

                      {
                        open.mode === "view" ? (
                          <Grid container className={classes.ActionBtnContainer} justify="space-evenly" style={{ visibility: "hidden" }}>

                            <Button color="primary">
                              ASSIGN
                            </Button>
                            <Button color="primary">
                              {langConstant.NO_THANKS}
                            </Button>
                          </Grid>
                        ) : (
                          <Grid container className={classes.ActionBtnContainer} justify="space-evenly">

                            <Button color="primary" type="submit">
                              ASSIGN
                            </Button>
                            <Button onClick={handleClose} color="primary">
                              {langConstant.NO_THANKS}
                            </Button>
                          </Grid>

                        )
                      }
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid style={{ width: "60%" }}>

              <Box style={{ width: "100%", padding: "0 10px" }}>

                <Typography className={classes.filedTitle}>
                  Assigned RFI
                </Typography>

                <TableContainer component={Paper} className={`${classes.margintop} ${classes.maxHeight}`}>
                  <Table stickyHeader className="data-table">
                    <TableHead >
                      <TableRow component="tr" style={{ height: 40 }}>
                        {columns.map((item, index) => (
                          <TableCell
                            component="th"
                            scope="row"
                            key={"column_" + index + item.field}
                            className={classes.stickyColl}
                            style={{ minWidth: item.minWidth }}
                          >
                            {item.headerName}

                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {TableLoopData && TableLoopData.map((row, rowIndex) => (

                        <TableRow key={"Row_" + rowIndex} component="tr" style={{ height: 46, background: tableEditHelperData?.tableIndex === rowIndex ? "#eeeeee" : "" }}>
                          {
                            columns.map((col, index) => (
                              <TableCell component="td" key={"column_" + index + row[col.field]} >

                                {
                                  col.field === "action" ? (

                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >

                                      <Tooltip title="View" placement="top">
                                        <Box
                                          className={`${classes.actionWrap} ${tableEditHelperData.changed === true ? (rowIndex === tableEditHelperData?.changedindex ? (isBlinking === true ? classes.blinkingIcon : "") : "") : ""}`}
                                          style={{
                                            backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                                            marginRight: "5px",
                                            cursor: "pointer"
                                          }}
                                          onClick={() => {
                                            setViewAssignedRFI({ open: true, data: row.ASSIGN_EVIDENCES })
                                          }}
                                        >
                                          <VisibilityIcon
                                            style={{
                                              color: "#fff",
                                              fontSize: "18px",
                                              margin: "0 auto",
                                            }}

                                          />
                                        </Box>
                                      </Tooltip>

                                      {
                                        (open.mode === "view" || open.mode === "edit" || open.mode === "addedit") && (

                                          <Tooltip title="Edit" placement="top">
                                            <Box
                                              className={classes.actionWrap}
                                              style={{
                                                backgroundColor: "#11954C",
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                cursor: "pointer"
                                              }}
                                              onClick={() => { EditRfiFromTable(row, rowIndex); setOpen((prev) => ({ ...prev, mode: "edit" })) }}
                                            >
                                              <EditIcon
                                                style={{
                                                  color: "#fff",
                                                  fontSize: "18px",
                                                  margin: "0 auto",
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>

                                        )
                                      }


                                      <Tooltip title="Delete" placement="top">
                                        <Box
                                          className={classes.actionWrap}
                                          style={{
                                            backgroundColor: "#F0142F",
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            cursor: "pointer"
                                          }}
                                          onClick={() => { deleteRfiFromTable(rowIndex, row) }}
                                        >
                                          <DeleteOutlineIcon
                                            style={{
                                              color: "#fff",
                                              fontSize: "18px",
                                              margin: "0 auto",
                                            }}
                                          />
                                        </Box>
                                      </Tooltip>

                                    </Box>



                                  ) : (col.field === "ASSIGNED_TO" ? ("TO : " + (row.HELPERGROUP === "team" ? row[col.field].label : row[col.field]))
                                    : (col.field === "RFI_STATUS" ? (statusColorFilter(row[col.field])) : row[col.field])

                                  )
                                }

                              </TableCell>
                            ))
                          }

                        </TableRow>

                      ))}

                      {TableLoopData.length === 0 &&
                        <TableRow style={{ height: 53 }}>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={columns.length}
                          >
                            {langConstant.NO_RECORD_FOUND}
                          </TableCell>
                        </TableRow>
                      }

                      {
                        (open.mode === "view" || open.mode === "edit" || open.mode === "addedit") && (
                          <TableRow style={{ height: 46 }}>
                            <TableCell
                              colSpan={columns.length}
                            >
                              <Button color="primary" onClick={AddRfisInEdit}>
                                <AddCircleIcon
                                  style={{ fontSize: "25px", marginRight: "10px" }}
                                />
                                ADD ASSIGNMENTS
                              </Button>
                            </TableCell>
                          </TableRow>

                        )
                      }

                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "30px",
                  }}
                >
                  {
                    (open.mode !== "view" && open.mode !== "edit" && open.mode !== "addedit") && (

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={FinalSubmitData}
                        disabled={TableLoopData.length === 0}
                      >
                        <MailOutlineIcon
                          style={{ fontSize: "25px", marginRight: "10px" }}
                        />
                        SEND RFI
                      </Button>

                    )
                  }

                  {
                    (open.mode === "edit" || open.mode === "view" || open.mode === "addedit") && (

                      tableEditHelperData.isDataUpdated === true && (

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={FinalSubmitEditData}
                          disabled={TableLoopData.length === 0}
                        >
                          <MailOutlineIcon
                            style={{ fontSize: "25px", marginRight: "10px" }}
                          />
                            UPDATE RFI
                        </Button>

                      )

                    )
                  }

                </DialogActions>
              </Box>

            </Grid>
          </Box>

          <Dialog
            onClose={() => { setViewAssignedRFI({ open: false }) }}
            open={viewAssignedRFI.open}
          >
            <DialogContent>
              <Box style={{ padding: "10px" }}>
                <Typography className={classes.filedTitle}>
                  Assigned evidences and data assets
                </Typography>
                <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                  <Table className="data-table">
                    <TableHead >
                      <TableRow component="tr" style={{ height: 40 }}>

                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.stickyColl}
                        >
                          EVIDENCES
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        viewAssignedRFI?.data?.map((el, i) => {

                          if (el.group === "EVIDENCES") {

                            return <TableRow component="tr" style={{ height: 40 }} key={el.group + i}>
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.stickyColl}
                              >
                                {el.data.EVIDENCE_SHORTNAME}
                              </TableCell>
                            </TableRow>
                          }
                        })
                      }
                      {
                        !viewAssignedRFI?.data?.some((el) => el.group === "EVIDENCES") && (
                          <TableRow style={{ height: 40 }}>
                            <TableCell
                              style={{ textAlign: "center" }}
                            >
                              No Evidences Assigned
                            </TableCell>
                          </TableRow>

                        )
                      }
                    </TableBody>

                    <TableHead >
                      <TableRow component="tr" style={{ height: 40 }}>

                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.stickyColl}
                        >
                          DATA ASSETS
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        viewAssignedRFI?.data?.map((el, i) => {

                          if (el.group === "DATA ASSETS") {

                            return <TableRow component="tr" style={{ height: 40 }} key={el.group + i}>
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.stickyColl}
                              >
                                {el.data.DATA_ASSET_ATTRIBUTE_NAME}
                              </TableCell>
                            </TableRow>
                          }

                        })
                      }
                      {
                        !viewAssignedRFI?.data?.some((el) => el.group === "DATA ASSETS") && (
                          <TableRow style={{ height: 40 }}>
                            <TableCell
                              style={{ textAlign: "center" }}
                            >
                              No Data Assets Assigned
                            </TableCell>
                          </TableRow>

                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container className={classes.ActionBtnContainer} justify="center" style={{ marginTop: "10px" }}>
                  <Button
                    onClick={() => { setViewAssignedRFI({ open: false }) }}
                    color="primary">
                    Close
                  </Button>
                </Grid>
              </Box>
            </DialogContent>
          </Dialog>
        </DialogContent>
      </Dialog>
    </div>
  );
}

RfiDialogBox.propTypes = {
  questionData: PropTypes.object,
};
