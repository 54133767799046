import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import ProgressBar from "../components/ProgressBar";
import PanelHeader from "../components/PanelHeader";
import SelectRegulation from "../components/SelectRegulation";
import { useDispatch, useSelector } from "react-redux";
import GraphView from "./GraphView";
import EvidenceServices from "./services";
import sessionService from "_services/session.service";
import langConstant from "_lang";

const Panel9 = ({ refObj, block, columnID, panel }) => {
  const [isGraphView, setIsGraphView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [evidenceStatus, setEvidenceStatus] = useState({});

  const [assessments, setAssessments] = useState({});
  const [rfiStatus, setRfiStatus] = useState({});

  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );
  const regulationID = useSelector(
    (state) => state.complianceReducer.selectedRegulation
  );

  useEffect(() => {
    if (regulationID && regulationID?.SUBMISSION_ID) {
      dispatch(
        EvidenceServices.getEvidenceStatusForSubmission(
          setEvidenceStatus,
          setLoading,
          regulationID.SUBMISSION_ID
        )
      );
      dispatch(
        EvidenceServices.getAssessments(
          setAssessments,
          setLoading,
          regulationID.SUBMISSION_ID,
          userID
        )
      );
      dispatch(
        EvidenceServices.getRFIStatusForSubmission(
          setRfiStatus,
          setLoading,
          regulationID.SUBMISSION_ID
        )
      );
    } else {
      setEvidenceStatus({});
    }
  }, [regulationID]);

  return (
    <>
      {loading ? (
        <ProgressBar />
      ) : (
        <Box>
          <PanelHeader
            refObj={refObj}
            expandedPanelID={expandedPanelID}
            block={block}
            panel={panel}
            columnID={columnID}
            isGraphView={isGraphView}
            setIsGraphView={setIsGraphView}
            isSwitchAvailable={false}
            panelName={langConstant.ASSESSMENT_SUMMARY_STATUS}
          />
          {regulationID && regulationID.value ? (
            <Grid>
              <GraphView
                evidenceStatus={evidenceStatus}
                assessments={assessments}
                rfiStatus={rfiStatus}
                expandedPanelID={expandedPanelID}
                block={block}
              />
            </Grid>
          ) : (
            <SelectRegulation />
          )}
        </Box>
      )}
    </>
  );
};

Panel9.propTypes = {
  refObj: PropTypes.any,
  block: PropTypes.object,
  columnID: PropTypes.string,
  panel: PropTypes.object,
};

export default Panel9;
