/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import { fade, makeStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(9),
    height: "100vh",
  },
  grow2: {
    flexGrow: 3,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: (props) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: props.themeColor,
    color: props.fontColor,
    padding: "0 !important",
  }),
  menuButton: (props) => ({
    marginRight: theme.spacing(2),
    color: props.fontColor,
  }),
  iconBtn: (props) => ({
    color: props.fontColor,
  }),
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",

    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "25%",
    },
    color: "#7E84A3",
  },
  searchIcon: (props) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99",
    color: props.fontColor,
  }),
  inputRoot: (props) => ({
    backgroundColor: props.themeColor,
    width: "100%",
  }),
  inputInput: (props) => ({
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    color: props.fontColor,
  }),
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    alignItems: "center",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  profileDropDownList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  primaryText: {
    fontSize: "0.875rem",
    lineHeight: 1.2,
  },
  secondaryText: (props) => ({
    fontSize: "0.875rem",
    color: props.fontColor,
  }),
  profileAvatar: {
    width: "36px",
    height: "36px",
    marginLeft: "10px",
  },
  borderBottom: {
    borderBottom: "1px solid #DBDBDB",
  },
  profileMenuItem: {
    color: "#8E8E8E",
    fontSize: "0.875rem",
  },
  profileMenuIcon: {
    marginRight: "10px",
  },
  customBadge: {
    backgroundColor: "#F0142F",
    color: "#ffffff",
  },
  redBadge: {
    backgroundColor: "#c13636",
    padding: "2px 5px",
    color: "#fff",
    borderRadius: "5px",
    width: "max-content",
    position: "absolute",
    top: "0",
    right: "0",
    fontSize: "12px",
  },
  listItem: {
    fontSize: "0.875rem",
    transition: "0.3s",
    "&:hover": {
      paddingLeft: "28px",
    },
  },
  activeColor: (props) => ({
    color: props.themeColor,
  }),
  listItemText: {
    fontSize: "0.875rem",
  },
  listItemBold: {
    "& span": {
      fontWeight: "500",
    },
  },
  listItemIcon: {
    minWidth: "55px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    transition: "0.3s",
    "&:hover": {
      paddingLeft: "42px",
    },
  },
  listIcon: {
    width: "24px",
    marginLeft: "8px",
  },
  noWrap: {
    fontSize: "0.875rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));
export default styles;
