import { makeStyles } from "@material-ui/core";
import { bgcolor } from "@material-ui/system";

const useStyles = makeStyles(() => ({
    inputWrapper: {
        padding: "9px 14px !important"
    },
    textFieldWid: {
        width: "100% !important"
    },
    side_offset: {
        padding: " 10px 20px 20px 20px",
        background: "#eee",
        position: "absolute",
        top: "70px",
        right: "0",
        // height: "100%",
         width: "90%",
         maxHeight:"calc(100vh - 115px)", 
         overflowY: "auto"
        
    }
}));

export default useStyles;