import React from "react";
import langConstant from "_lang";
import * as Yup from "yup";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";

const tabList = {
  first: [
    {
      value: 1,
      label: langConstant.CUSTOMER_LEGAL_ENTITIES,
      labelLength: 3,
    },
    {
      value: 2,
      label: langConstant.CONFIGURATIONS,
      labelLength: 3,
    },
    {
      value: 8,
      label: langConstant.USERS_AND_TEAMS,
      labelLength: 3,
    },
    {
      value: 3,
      label: langConstant.REGULATORS_AND_REGULATIONS,
      labelLength: 3,
    },
  ],
  second: [
    {
      value: 4,
      label: langConstant.ENTITLEMENT_LABELS,
      labelLength: 3,
    },
    {
      value: 5,
      label: langConstant.SOURCE_SYSTEMS,
      labelLength: 3,
    },
    {
      value: 6,
      label: langConstant.CLASSIFICATION,
      labelLength: 3,
    },
    {
      value: 7,
      label: langConstant.SUBSCRIPTIONS,
      labelLength: 3,
    },
  ],
};

const columns = [
  { field: "LEGAL_ENTITY_NAME", headerName: langConstant.LEGAL_ENTITY_NAME },
  {
    field: "LEGAL_ENTITY_TYPE",
    headerName: langConstant.TYPE,
    format: function formatData(value, country, entityType) {
      if (value["LE_TYPE_ID"] && entityType && entityType.value) {
        return entityType.label;
      } else {
        return "No Data";
      }
    },
  },
  { field: "LEGAL_ENTITY_ADDRESS", headerName: langConstant.ADDRESS_TEXT },
  {
    field: "RESPONSIBLE_DEPARTMENT",
    headerName: langConstant.RESPONSIBLE_DEPARTMENT,
  },
  { field: "CONTRACT_DURATION", headerName: langConstant.CONTRACT_DURATION },
  {
    field: "PRODUCT_SERVICE_PROVIDED",
    headerName: langConstant.PRODUCT_SERVICE_PROVIDED,
  },
  {
    field: "COUNTRY_CODE",
    headerName: "Country Code",
    format: function formatData(value, country, entityType) {
      if (value["COUNTRY_CODE"] && country && country.COUNTRY_NAME) {
        return country.COUNTRY_NAME;
      } else {
        return "No Data";
      }
    },
  },
  {
    field: "ACTIVE",
    headerName: "Active",
    format: function formatData(value) {
      if (value["ACTIVE"] && value["ACTIVE"] == 1) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
];

const initialLegalEntityValues = {
  LEGAL_ENTITY_NAME: "",
  LE_TYPE_ID: "",
  LEGAL_ENTITY_ADDRESS: "",
  RESPONSIBLE_DEPARTMENT: "",
  CONTRACT_DURATION: "",
  PRODUCT_SERVICE_PROVIDED: "",
  COUNTRY_CODE: "",
  START_DATE: moment(),
  END_DATE: moment(),
  ACTIVE: true,
};

const legalEntityValidationSchema = Yup.object().shape({
  LEGAL_ENTITY_NAME: Yup.string().trim().required("Entity Name is Required"),
  LE_TYPE_ID: Yup.string().trim().required("Entity Type is Required"),
  LEGAL_ENTITY_ADDRESS: Yup.string()
    .trim()
    .required("Entity Address is Required"),
  RESPONSIBLE_DEPARTMENT: Yup.string()
    .trim()
    .required("Responsible Department is Required"),
  CONTRACT_DURATION: Yup.string()
    .trim()
    .required("Contract Duration is Required"),
  PRODUCT_SERVICE_PROVIDED: Yup.string()
    .trim()
    .required("Product Service Provided is Required"),
  COUNTRY_CODE: Yup.string().trim().required("Contry Code is Required"),
  START_DATE: Yup.string().trim().required("Start Date is Required"),
  END_DATE: Yup.string().trim().required("End Date is Required"),
  ACTIVE: Yup.string().trim().required("Active is Required"),
});

const entitlementColumns = [
  { field: "ENTITLEMENT_NAME", headerName: langConstant.ENTITLEMENT_NAME },
  { field: "ENTITLEMENT_PARENT", headerName: langConstant.ENTITLEMENT_PARENT },
  { field: "ENTITLEMENT_PATH", headerName: langConstant.ENTITLEMENT_PATH },
];

const sourceSystemColumns = [
  { field: "SOURCE_SYSTEM_ID", headerName: langConstant.SOURCE_SYSTEM_ID },
  { field: "SOURCE", headerName: langConstant.SOURCE },
  { field: "SOURCE_SYSTEM_NAME", headerName: langConstant.SOURCE_SYSTEM_NAME },
  {
    field: "DATA_SOURCE_NAMES_AND_TYPES",
    headerName: langConstant.DATA_SOURCE_NAMES_AND_TYPES,
  },
  { field: "FILE_FORMAT", headerName: langConstant.FILE_FORMAT },
  { field: "FREQUENCY", headerName: langConstant.FREQUENCY },
  { field: "DELIVERY_METHOD", headerName: langConstant.DELIVERY_METHOD },
  {
    field: "DEDICATED_BUCKET_FOLDER_NAME",
    headerName: langConstant.DEDICATED_BUCKET_FOLDER_NAME,
  },
  { field: "FILE_NAME_SYNTAX", headerName: langConstant.FILE_NAME_SYNTAX },
  { field: "SCHEDULING_PERIOD", headerName: langConstant.SCHEDULING_PERIOD },
];

const initialSourceSytemValues = {
  SOURCE_SYSTEM_NAME: "",
  FILE_FORMAT: "",
  FREQUENCY: "",
  DELIVERY_METHOD: "",
  DEDICATED_BUCKET_FOLDER_NAME: "",
  FILE_NAME_SYNTAX: "",
  SCHEDULING_PERIOD: "",
  SOURCE_SYSTEM_OWNER: "",
  SOURCE_SYSTEM_OWNER_EMAIL_ADDRESS: "",
  DATA_ATTRIBUTES_EXPECTED_IN_SOURCE_FILE: "",
};

const sourceSystemValidationSchema = Yup.object().shape({
  SOURCE_SYSTEM_NAME: Yup.string()
    .trim()
    .required("Source System Name is Required"),
  FILE_FORMAT: Yup.string().trim().required("File Format is Required"),
  DELIVERY_METHOD: Yup.string().trim().required("Delivery Method is Required"),

  DEDICATED_BUCKET_FOLDER_NAME: Yup.string()
    .trim()
    .required("Bucket Folder Name is Required"),
  FILE_NAME_SYNTAX: Yup.string().trim().required("FileName Syntax is Required"),
  SCHEDULING_PERIOD: Yup.string()
    .trim()
    .required("Scheduling Period is Required"),
  SOURCE_SYSTEM_OWNER: Yup.string()
    .trim()
    .required("Source System Owner is Required"),
  SOURCE_SYSTEM_OWNER_EMAIL_ADDRESS: Yup.string()
    .trim()
    .email("Owner Email Address is Required"),
});

const initialEntitlementValues = {
  ENTITLEMENT_NAME: "",
  ENTITLEMENT_LABEL: "",
  ENTITLEMENT_LABEL_LEVEL: 1,
  ENTITLEMENT_PARENT: "",
  ENTITLEMENT_PATH: "",
  ENTITLEMENT_TOOLTIP: "",
  CUSTOMER_DEFAULT: "",
  CUSTOMER_ID: "",
  ENTITLEMENT_ICON_PREVIEW: { active: "", inactive: "" },
  CREATE_DATE: moment().format("YYYY-MM-DD HH:mm:ss"),
};

const entitlementValidationSchema = Yup.object().shape({
  ENTITLEMENT_NAME: Yup.string()
    .trim()
    .required("Entitlement Name is Required"),
  ENTITLEMENT_LABEL: Yup.string()
    .trim()
    .required("Entitlement Label is Required"),
  ENTITLEMENT_PARENT: Yup.string().when("ENTITLEMENT_LABEL_LEVEL", {
    is: (ENTITLEMENT_LABEL_LEVEL) => ENTITLEMENT_LABEL_LEVEL == 2,
    then: Yup.string().trim().required("Entitlement Parent is Required"),
    otherwise: Yup.string().nullable(),
  }),
  ENTITLEMENT_TOOLTIP: Yup.string()
    .trim()
    .required("Product Service Provided is Required"),
  ENTITLEMENT_ICON_PREVIEW: Yup.object().shape({
    active: Yup.string().required("Active Icon is Required"),
    inactive: Yup.string().required("InActive Icon is Required"),
  }),
});

const labelLevelOptions = [
  { value: 1, label: "Parent" },
  { value: 2, label: "Child" },
];

const regulatorsColumns = [
  { field: "REGULATOR_NAME", headerName: langConstant.REGULATOR_NAME },
  { field: "COUNTRY_SHORT_NAME", headerName: langConstant.COUNTRY },
  {
    field: "ACTIVE",
    headerName: langConstant.ACTIVE,
    format: function formatData(value) {
      if (value["ACTIVE"]) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
  { field: "DOMAIN_NAME", headerName: langConstant.DOMAIN },
  { field: "APPLICABLE_FLAG", headerName: langConstant.APPLICABLE_FLAG },
];

const regulationsColumns = [
  { field: "REGULATION_SHORT_NAME", headerName: langConstant.REGULATION_NAME },
  { field: "REGULATOR_NAME", headerName: langConstant.REGULATOR_NAME },
  {
    field: "ACTIVE",
    headerName: langConstant.ACTIVE,
    format: function formatData(value) {
      if (value["ACTIVE"]) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
  { field: "DOMAIN_NAME", headerName: langConstant.DOMAIN },
  { field: "APPLICABLE_FLAG", headerName: langConstant.APPLICABLE_FLAG },
  { field: "EFFECTIVE_DATE", headerName: langConstant.EFFECTIVE_DATE },
  { field: "REGULATION_STATUS", headerName: langConstant.REGULATION_STATUS },
];

const regulatorsRegulationsColumns = [
  { field: "REGULATION_SHORT_NAME", headerName: langConstant.REGULATION_NAME },
  {
    field: "ACTIVE",
    headerName: langConstant.ACTIVE,
    format: function formatData(value) {
      if (value["ACTIVE"]) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
  { field: "DOMAIN_NAME", headerName: langConstant.DOMAIN },
  { field: "APPLICABLE_FLAG", headerName: langConstant.APPLICABLE_FLAG },
  { field: "EFFECTIVE_DATE", headerName: langConstant.EFFECTIVE_DATE },
  { field: "REGULATION_STATUS", headerName: langConstant.REGULATION_STATUS },
];

export default {
  tabList,
  columns,
  initialLegalEntityValues,
  legalEntityValidationSchema,
  initialEntitlementValues,
  entitlementValidationSchema,
  labelLevelOptions,
  entitlementColumns,
  sourceSystemColumns,
  regulatorsColumns,
  regulationsColumns,
  regulatorsRegulationsColumns,
};
