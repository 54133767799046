/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import regulationAction from "../actions";
import _ from "lodash";
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  DialogActions,
} from "@material-ui/core";
import ReturnRegulationButton from "./component/ReturnRegulationButton";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import SaveIcon from "@material-ui/icons/Save";
import DialogBox from "_components/DialogBox";
import langConstant from "_lang";
import rootStyles from "rootStyles";
import ReviewStep from "./component/ReviewStep";
import ApproveStep from "./component/ApproveStep";
import styles from "./styles";
import approveRegulationAction from "./actions";
import CustomButton from "_components/Layout/CustomButton";
import sessionService from "_services/session.service";
import { dbValueConstants } from "_constants";
import constants from "./constants";
import { FormatListNumberedRtlOutlined } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";

const ContinueButton = ({ label, onClick, list, status }) => {
  const disableButton = _.filter(list, {
    status: status,
  });
  return (
    <CustomButton
      variant="contained"
      color="primary"
      size="medium"
      disabled={disableButton.length != list.length}
      startIcon={
        label == langConstant.CONTINUE ? (
          <SubdirectoryArrowRightIcon />
        ) : (
          <CheckIcon />
        )
      }
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};
ContinueButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  list: PropTypes.array,
  status: PropTypes.string,
};
const ApproveRegulation = () => {
  const classes = styles();
  const rootClasses = rootStyles();
  const [returnDialog, setReturnDialog] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [filteredDraftList, setFilteredDraftList] = React.useState([]);

  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const draftList = useSelector(
    (state) => state.approveRegulationDetails.draft
  );
  const teamleaderID = useSelector(
    (state) => state.approveRegulationDetails.TL_ID
  );
  const managerID = useSelector(
    (state) => state.approveRegulationDetails.MANAGER_ID
  );
  const newRegulation = useSelector(
    (state) => state.authentication?.detailedInfo?.NEW_REGULATION
  );

  useEffect(() => {
    dispatch(regulationAction.getRegulationList(userID, customerID));
  }, []);

  useEffect(() => {
    if (draftList) {
      const filteredList = draftList.filter((item) => {
        return item.selected;
      });

      const listToSet = filteredList.map((item) => {
        // Making empty category null
        if (item.category == "") {
          item.category = null;
        }

        // Removing empty controls
        if (item.mappedControl.length == 1) {
          if (
            item.mappedControl[0].control == "" &&
            item.mappedControl[0].dataPoints == ""
          ) {
            item.mappedControl.pop();
          }
        }

        return item;
      });

      setFilteredDraftList(listToSet);
    }
  }, [draftList]);

  const userRoleId = sessionService.getUserRoleId();
  const roleBasedConstant =
    userRoleId == dbValueConstants.DB_MANAGER_ROLE_ID
      ? {
          assessmentFinalStatus: "SUBMITTED_TO_REGULATOR",
          submitButtonLabel: langConstant.APPROVE_REGULATION,
          finalStatusUpdateMessage:
            langConstant.ASSESSMENT_SUBMITTED_FOR_REVIEW_SUCCESSFULLY,
          questionReviewedStatus: "FINAL",
          assessmentRejectStatus: "PENDING_FOR_REVIEW",
          step2Label: langConstant.APPROVE_REGULATION,
          // returnToName: assessmentObj.TL_NAME,
        }
      : {
          assessmentFinalStatus: "FINAL_REVIEW",
          submitButtonLabel: langConstant.APPROVE_REGULATION,
          finalStatusUpdateMessage:
            langConstant.ASSESSMENT_SUBMITTED_FOR_REVIEW_SUCCESSFULLY,
          questionReviewedStatus: "FINAL",
          assessmentRejectStatus: "RETURNED",
          step2Label: langConstant.APPROVE_REGULATION,
          // returnToName: assessmentObj.ANALYST_NAME,
        };
  const history = useHistory();
  const { id } = useParams();

  const regulationList = useSelector((state) => state.regulationList);

  const currentRegulation = regulationList.filter((regulation) => {
    if (regulation.REGULATION_ID == id) {
      return true;
    } else {
      return false;
    }
  })[0];
  let regulationsDetails;
  if (currentRegulation) {
    regulationsDetails = {
      name: currentRegulation.REGULATION_NAME,
      initiator: currentRegulation.INITIATOR,
    };
  }

  const dispatch = useDispatch();
  const steps = [langConstant.REVIEW_QUESTIONS, roleBasedConstant.step2Label];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const goBack = () => {
    history.goBack();
  };

  const goToNewRegulation = () => {
    history.push("/new-regulation");
  };

  const onReturnAction = () => {
    dispatch(
      approveRegulationAction.updateRegulationStatus(
        id,
        "IN_PROGRESS",
        goToNewRegulation,
        regulationsDetails
      )
    );
  };

  const updateRegulationStatus = () => {
    const filePath = customerID.toString() + "/" + newRegulation + "/";
    dispatch(
      confirmationDialogActions.open({
        message: langConstant.APPROVE_REGULATION_CONFIRMATION_MESSAGE(
          currentRegulation.REGULATION_NAME
        ),
        onConfirm: () => {
          dispatch(
            approveRegulationAction.processDraft(
              id,
              draftList,
              teamleaderID,
              managerID,
              userID,
              customerID,
              filePath
            )
          );
          dispatch(
            approveRegulationAction.updateRegulationStatus(
              id,
              "AVAILABLE",
              goToNewRegulation
            )
          );
        },
        title: langConstant.APPROVE_REGULATION_CONFIRMATION,
        option1: langConstant.YES_PLEASE,
        option2: langConstant.NO_THANKS,
      })
    );
  };
  const selectQuestion = (e, item) => {
    let newItem = { ...item };
    newItem.selectedForReview = e.target.checked;
    dispatch(approveRegulationAction.updateSingleItemInDraft(newItem));
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <ReviewStep
            draftId={id}
            questionList={filteredDraftList}
            draftList={draftList}
            assessmentObj={null}
            roleBasedConstant={roleBasedConstant}
            selectQuestion={selectQuestion}
          />
        );

      default:
        return (
          <ApproveStep
            questionList={filteredDraftList}
            roleBasedConstant={roleBasedConstant}
            assessmentObj={null}
          />
        );
    }
  };

  useEffect(() => {
    dispatch(approveRegulationAction.getDraft(id, goBack));
    return dispatch({ type: constants.reviewAssessmentConstant_FAILURE });
  }, []);
  if (filteredDraftList.length == 0) {
    return <></>;
  }
  return (
    <Box>
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className="custom-stepper"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <Box mb={8}>{getStepContent(activeStep)}</Box>
          <Box className="fixed-action-bar" p={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item style={{ width: "80px" }}></Grid>
              <Grid item>
                <ContinueButton
                  label={
                    activeStep == 1
                      ? roleBasedConstant.submitButtonLabel
                      : langConstant.CONTINUE
                  }
                  list={filteredDraftList}
                  onClick={
                    activeStep == 1 ? updateRegulationStatus : handleNext
                  }
                  status={roleBasedConstant.questionReviewedStatus}
                />
              </Grid>
              {activeStep == 0 && (
                <Grid item>
                  <ReturnRegulationButton
                    classes={rootClasses.redButton}
                    label={langConstant.RETURN_REGULATION}
                    regulationId={id}
                    onClickReturn={() => setReturnDialog(true)}
                    roleBasedConstant={roleBasedConstant}
                  />
                  <DialogBox
                    title={langConstant.RETURN_REGULATION_CONFIRMATION}
                    open={returnDialog}
                    handleClose={() => setReturnDialog(false)}
                    maxWidth={"sm"}
                  >
                    <Typography
                      align="center"
                      gutterBottom
                      dangerouslySetInnerHTML={{
                        __html: langConstant.CONFIRM_REJECT_REGULATION_FN(
                          currentRegulation
                            ? currentRegulation.REGULATION_NAME
                            : ""
                        ),
                      }}
                    ></Typography>
                    <Box align="center">
                      <DialogActions
                        style={{
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button
                          onClick={onReturnAction}
                          color="primary"
                          size="medium"
                          autoFocus
                          style={{
                            marginBottom: "-20px",
                          }}
                        >
                          {langConstant.YES_PLEASE}
                        </Button>
                        <Button
                          onClick={() => setReturnDialog(false)}
                          color="primary"
                          size="medium"
                          style={{
                            marginBottom: "-20px",
                          }}
                        >
                          {langConstant.NO_THANKS}
                        </Button>
                      </DialogActions>
                    </Box>
                  </DialogBox>
                </Grid>
              )}
              {activeStep == 1 && (
                <Grid item>
                  <Button
                    color="primary"
                    size="small"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    {langConstant.GO_BACK}
                  </Button>
                </Grid>
              )}

              <Grid item></Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default ApproveRegulation;
