import React from "react";
import MasterTableauReport from "_components/MasterTableauReport"
import sessionService from "_services/session.service";

const ZerotrustAssessment = () => {
  const customerID = sessionService.getCustomerId()
  const userID = sessionService.getUserId()

  const parameters = {
    CustomerId: customerID,
    UserId: userID,
  }

  return <MasterTableauReport slug="INSIGHTS/ZERO_TRUST_ASSESSMENT" parameters={parameters}/>
};

export default ZerotrustAssessment;