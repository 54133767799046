import React, { useCallback, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";

const Stepper = () => {
  const steps = useSelector((state) => state.stepper.steps);
  const activeStep = useSelector((state) => state.stepper.activestep);
  const themeDetails = useSelector(getThemeDetails);

  return (
    <ul id="stepper" className={`stepper`}>
      {steps.length > 0 &&
        steps.map((step, index) => (
          <Tooltip
            key={index}
            placement="bottom"
            arrow
            title={
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginLeft: "5px",
                    fontSize: "12px",
                    color: "black",
                  }}
                >
                  {step.stepname}
                </div>
              </div>
            }
            componentpsrops={{
              tooltip: {
                sx: {
                  bgcolor: "common.white",
                  boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.3)",
                  "& .MuiTooltip-arrow": {
                    color: "common.white",
                  },
                },
              },
            }}
          >
            <li
              style={{
                backgroundColor:
                  index === activeStep
                    ? themeDetails?.ACTIONBUTTON_BGROUND_COLOUR
                    : "#d1d1d1",
              }}
              className="stepperItem"
            />
          </Tooltip>
        ))}
    </ul>
  );
};

export default Stepper;
