/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Checkbox } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import notificationsActions from "_actions/notifications.action";
import sessionService from "_services/session.service";
import { useDispatch } from "react-redux";

const ServiceNotification = ({
  notification,
  isSelected,
  setSelectedNotifications,
}) => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();

  let notificationTime = moment(notification.CREATE_DATE).format(
    "DD-MMM-YY h:mm:ss a"
  );

  let pastTime = moment(notificationTime, "DD-MMM-YY h:mm:ss a").fromNow();

  let ServiceNotificationData;

  try {
    ServiceNotificationData = JSON.parse(notification.NOTIFICATION_MESSAGE);
  } catch (error) {
    ServiceNotificationData = notification.NOTIFICATION_MESSAGE;
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "15px 10px 15px 10px",
        overflow: "hidden",
        borderBottom: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {notification.BOOKMARK_NOTIFICATION == "Y" ? (
          <BookmarkIcon
            style={{
              margin: "5px 15px 0 0",
              fontSize: "24px",
              color: "#FFC234",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(
                notificationsActions.updateNotification(
                  notification.MESSAGE_ID,
                  null,
                  "N",
                  0,
                  userID
                )
              );
            }}
          />
        ) : (
          <BookmarkBorderIcon
            style={{
              margin: "5px 15px 0 0",
              fontSize: "24px",
              color: "#FFC234",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(
                notificationsActions.updateNotification(
                  notification.MESSAGE_ID,
                  null,
                  "Y",
                  0,
                  userID
                )
              );
            }}
          />
        )}

        <Checkbox
          checked={isSelected}
          onClick={(e) => {
            e.target.checked
              ? setSelectedNotifications((prevNotifications) => [
                  ...prevNotifications,
                  notification,
                ])
              : setSelectedNotifications((prevNotifications) =>
                  prevNotifications.filter(
                    (item) => item.MESSAGE_ID !== notification.MESSAGE_ID
                  )
                );
          }}
          sx={{
            "&.Mui-checked": { color: "#124571" },
            display: "inline",
          }}
          //  size="small"
        />

        <Avatar
          alt={notification.NotificationHeading}
          src={"data:image/png;base64," + notification.NOTIFICATION_ICON}
          style={{
            marginRight: "5px",
            height: "36px",
            width: "36px",
            color: "#6783c7",
            backgroundColor: "#b4c4ed",
          }}
        ></Avatar>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            style={{
              //  color: "#444"
              color: notification.READ_NOTIFICATION == "N" ? "#444" : "#888",
              fontWeight:
                notification.READ_NOTIFICATION == "N" ? "bold" : "normal",
            }}
          >
            {/* {notification.NotificationHeading} */}
            {ServiceNotificationData["title"]}
          </Typography>

          <Typography
            variant="body2"
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "12px",
              color: notification.READ_NOTIFICATION == "N" ? "#444" : "#888",
              overflow: "hidden",
              fontWeight:
                notification.READ_NOTIFICATION == "N" ? "bold" : "normal",
            }}
          >
            {ServiceNotificationData["Notificaton Message"]}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="body1"
          style={{
            color: "#888",
            fontSize: "10px",
            width: "65px",
            textAlign: "right",
          }}
        >
          {pastTime && pastTime.replace("minutes", "mins")}
        </Typography>
      </Box>
    </Box>
  );
};

ServiceNotification.propTypes = {
  notification: PropTypes.object,
  isSelected: PropTypes.bool,
  setSelectedNotifications: PropTypes.func,
};

export default ServiceNotification;
