import React, { useEffect, useState } from 'react';
import langConstant from "_lang";
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import Select from 'react-select';
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    insBoxLR:{
        display:"flex",
        flexWrap:"wrap",
        alignItems:"center",
        width:"500px",
        maxWidth:"100%",
        marginBottom:"15px"
    },
    comParaWrapper:{
        width:"500px",
        maxWidth:"100%",
    },
    insBoxLRLabel:{
        width:"220px",
        fontWeight:"500",
    },
    insBoxLRField:{
        width:"100%",
    },
    insBoxLRField50:{
        width:"calc(100% - 220px)",
    },
    insBoxLRFieldFile:{
        border:"1px solid rgba(0, 0, 0, 0.23)",
        borderRadius:"5px",
        padding:'6px 10px',
    },
    comParaTitle:{
        width:"100%",
    },
    policyCompPageWrapper:{
       // minHeight:"100vh",
    },
    insBoxLRError:{
        color:"red",
        marginTop:"4px",
        display:"block",
        width:"100%",
    },
    distanceNote:{
        background:"#ebedef",
        padding: "10px",
        fontSize: "12px",
        marginTop:"10px;",
        fontWeight: "500",
        marginLeft: "220px",

    },
    borderNone:{
        border:"none",
    },
}));

const CompareFileModal = ({ documentFormik, comparisonFormik, documentCompareFormik, setSelectedBasePolicy, setSelectedTargetPolicy }) => {
    const classes = useStyles();

    const [baseFile, setBaseFile] = useState(null);
    const [targetFile, setTargetFile] = useState(null);

    const policies = useSelector((state)=>state.policyReducer.policies)
    const selectedPolicies = useSelector((state)=>state.policyReducer.selectedPolicies)

    useEffect(() => {
        if(selectedPolicies.length === 2) {
            const basePolicy = policies.find((item)=> item.POLICY_ID === selectedPolicies[0])
            setBaseFile(basePolicy)
            setSelectedBasePolicy({id: selectedPolicies[0], name: basePolicy.POLICY_FILE_NAME})
            documentCompareFormik.setFieldValue("base_document", basePolicy.POLICY_NAME)
            documentCompareFormik.setFieldValue("base_document_name", basePolicy.POLICY_NAME)
            documentCompareFormik.setFieldValue("base_document_filename", basePolicy.POLICY_FILE_NAME)
            const targetPolicy = policies.find((item)=> item.POLICY_ID === selectedPolicies[1])
            setTargetFile(targetPolicy)
            setSelectedTargetPolicy({id: selectedPolicies[1], name: targetPolicy.POLICY_FILE_NAME})
            documentCompareFormik.setFieldValue("target_document", targetPolicy.POLICY_NAME)
            documentCompareFormik.setFieldValue("target_document_name", targetPolicy.POLICY_NAME)
            documentCompareFormik.setFieldValue("target_document_filename", targetPolicy.POLICY_FILE_NAME)
            documentFormik.handleReset()
        }
    },[])

    const comparisonType = [
        {value: "sentence", label: "Sentence"},
        {value: "paragraph", label: "Paragraph"}
    ]

    const distanceType = [
        {value: "cosine", label: "Cosine"},
        {value: "jaccard", label: "Jaccard"},
        {value: "euclidean", label: "Euclidean"},
    ]

    const distanceDescriptionData = {
        cosine: langConstant.COSINE,
        jaccard: langConstant.JACCARD,
        euclidean: langConstant.EUCLIDEAN
    }

    const [ distanceDescription, setDistanceDescription] = useState(null)

    const marks = [{value: 0,label: "0"},{value: 25,label: "25"},{value: 50,label: "50"},{value: 75, label: "75"},{value: 100, label: "100"}];

    return (
        <Grid className={classes.policyCompPageWrapper}>
            <Grid container>
                <Grid item xs={6}>
                    {!baseFile && 
                        <div className={classes.insBoxLR}>
                            <Typography className={classes.insBoxLRLabel}>{langConstant.BASE_DOCUMENT}</Typography>
                            <div className={classes.insBoxLRField50}>
                            <input 
                                className={classes.insBoxLRField +" "+ classes.insBoxLRFieldFile} 
                                accept=".doc,.docx,.pdf" 
                                type="file" 
                                name="base_document"
                                onChange={(event) => {
                                    documentFormik.setFieldValue("base_document", event.target.files[0])
                                    documentCompareFormik.setFieldValue("base_document", event.target.files[0])
                                    documentFormik.setFieldValue("base_document_filename", event.target.files[0].name)
                                    documentCompareFormik.setFieldValue("base_document_filename", event.target.files[0].name)
                                }}
                            />
                            {documentFormik.errors && documentFormik.errors.base_document &&
                                <span className={classes.insBoxLRError}>{documentFormik.errors.base_document}</span>
                            }
                            {documentCompareFormik.errors && documentCompareFormik.errors.base_document &&
                                <span className={classes.insBoxLRError}>{documentCompareFormik.errors.base_document}</span>
                            }
                            </div>
                        </div>
                    }
                    <div className={classes.insBoxLR}>
                        <Typography className={classes.insBoxLRLabel}>{langConstant.BASE_DOCUMENT_NAME}</Typography>
                        <div className={classes.insBoxLRField50}>
                        <TextField 
                            className={classes.insBoxLRField} 
                            variant="outlined" 
                            inputProps={{style: {padding:8}}}
                            name="base_document_name"
                            onChange={(e) => {
                                documentFormik.setFieldValue("base_document_name", e.target.value)
                                documentCompareFormik.setFieldValue("base_document_name", e.target.value)
                            }}
                            InputProps={{
                                classes : {notchedOutline: targetFile ? classes.borderNone : ""},
                            }}
                            value={documentCompareFormik.values.base_document_name}
                        />
                        {selectedPolicies.length === 0 && documentFormik.errors && documentFormik.errors.base_document_name &&
                            <span className={classes.insBoxLRError}>{documentFormik.errors.base_document_name}</span>
                        }
                        {selectedPolicies.length === 0 && documentCompareFormik.errors && documentCompareFormik.errors.base_document_name &&
                            <span className={classes.insBoxLRError}>{documentCompareFormik.errors.base_document_name}</span>
                        }
                        </div>
                    </div>
                    <div className={classes.insBoxLR}>
                        <Typography className={classes.insBoxLRLabel}>{langConstant.BASE_DOCUMENT_FILENAME}</Typography>
                        <div className={classes.insBoxLRField50}>
                        <Typography>
                            {documentCompareFormik.values.base_document_filename}
                        </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    {!targetFile && 
                        <div className={classes.insBoxLR}>
                            <Typography className={classes.insBoxLRLabel}>{langConstant.TARGET_DOCUMENT}</Typography>
                            <div className={classes.insBoxLRField50}>
                            <input 
                                className={classes.insBoxLRField +" "+ classes.insBoxLRFieldFile} 
                                accept=".doc,.docx,.pdf"
                                type="file"
                                name="target_document" 
                                onChange={(event) => {
                                    documentFormik.setFieldValue("target_document", event.target.files[0])
                                    documentCompareFormik.setFieldValue("target_document", event.target.files[0])
                                    documentFormik.setFieldValue("target_document_filename", event.target.files[0].name)
                                    documentCompareFormik.setFieldValue("target_document_filename", event.target.files[0].name)
                                }}
                            />
                            {documentFormik.errors && documentFormik.errors.target_document &&
                                <span className={classes.insBoxLRError}>{documentFormik.errors.target_document}</span>
                            }
                            {documentCompareFormik.errors && documentCompareFormik.errors.target_document &&
                                <span className={classes.insBoxLRError}>{documentCompareFormik.errors.target_document}</span>
                            }
                            </div>
                        </div>   
                    }
                    <div className={classes.insBoxLR}>
                        <Typography className={classes.insBoxLRLabel}>{langConstant.TARGET_DOCUMENT_NAME}</Typography>
                        <div className={classes.insBoxLRField50}>
                        <TextField 
                            className={classes.insBoxLRField} 
                            variant="outlined" 
                            inputProps={{style: {padding:8}}} 
                            name="target_document_name"
                            onChange={(e) => {
                                documentFormik.setFieldValue("target_document_name", e.target.value)
                                documentCompareFormik.setFieldValue("target_document_name", e.target.value)
                            }}
                            InputProps={{
                                classes : {notchedOutline: targetFile ? classes.borderNone : ""},
                            }}
                            value={documentCompareFormik.values.target_document_name}
                        />
                        {selectedPolicies.length === 0 && documentFormik.errors && documentFormik.errors.target_document_name &&
                            <span className={classes.insBoxLRError}>{documentFormik.errors.target_document_name}</span>
                        }
                        {selectedPolicies.length === 0 && documentCompareFormik.errors && documentCompareFormik.errors.target_document_name &&
                            <span className={classes.insBoxLRError}>{documentCompareFormik.errors.target_document_name}</span>
                        }
                        </div>
                    </div>
                    <div className={classes.insBoxLR}>
                        <Typography className={classes.insBoxLRLabel}>{langConstant.TARGET_DOCUMENT_FILENAME}</Typography>
                        <div className={classes.insBoxLRField50}>
                        <Typography>
                            {documentCompareFormik.values.target_document_filename}
                        </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <h2 className={classes.comParaTitle}>{langConstant.COMPARISON_PARAMETERS}</h2>
                <Grid item xs={12} md={6}>
           
                <div className={classes.comParaWrapper}>
                    <div className={classes.insBoxLR}>
                        <Typography className={classes.insBoxLRLabel}>{langConstant.COMPARISON_NAME}</Typography>
                        <div className={classes.insBoxLRField50}>
                        <TextField 
                            className={classes.insBoxLRField} 
                            inputProps={{style: {padding:8}}} 
                            variant='outlined' 
                            name="comparison_name"
                            onChange={(e) => {
                                comparisonFormik.setFieldValue("comparison_name", e.target.value)
                                documentCompareFormik.setFieldValue("comparison_name", e.target.value)
                            }}
                            value={comparisonFormik.values.comparison_name}
                        />
                        {comparisonFormik.errors && comparisonFormik.errors.comparison_name && 
                            <span className={classes.insBoxLRError}>{comparisonFormik.errors.comparison_name}</span>
                        }
                        {documentCompareFormik.errors && documentCompareFormik.errors.comparison_name && 
                            <span className={classes.insBoxLRError}>{documentCompareFormik.errors.comparison_name}</span>
                        }
                        </div>
                    </div>
                    <div className={classes.insBoxLR}>
                        <Typography className={classes.insBoxLRLabel}>{langConstant.COMPARISON_TYPE}</Typography>
                        <div className={classes.insBoxLRField50}>
                        <Select 
                            className={classes.insBoxLRField} 
                            inputProps={{style: {padding:8}}} 
                            options={comparisonType}
                            name="comparison_type"
                            onChange={(type) => {
                                comparisonFormik.setFieldValue("comparison_type",type.value)
                                documentCompareFormik.setFieldValue("comparison_type",type.value)
                            }}
                            value={comparisonType.find((type) => type.value === comparisonFormik.values.comparison_type)}
                        />
                        {comparisonFormik.errors && comparisonFormik.errors.comparison_type && 
                            <span className={classes.insBoxLRError}>{comparisonFormik.errors.comparison_type}</span>
                        }
                        {documentCompareFormik.errors && documentCompareFormik.errors.comparison_type && 
                            <span className={classes.insBoxLRError}>{documentCompareFormik.errors.comparison_type}</span>
                        }
                        </div>
                    </div>
                    <div className={classes.insBoxLR} style={{marginTop:"50px",paddingBottom:"40px"}}>
                        <Typography className={classes.insBoxLRLabel}>{langConstant.THRESHOLD_SETTINGS}</Typography>
                        <div className={classes.insBoxLRField50}>
                            <Grid container>
                                <Slider
                                    track="inverted"
                                    value={comparisonFormik.values.threshold_settings}
                                    step={5}
                                    marks={marks}
                                    onChange={(e) => {
                                        comparisonFormik.setFieldValue("threshold_settings", e.target.value)
                                        documentCompareFormik.setFieldValue("threshold_settings", e.target.value)
                                    }}
                                    valueLabelDisplay="on"
                                />
                            </Grid>
                        </div>
                    </div>
                </div>
                </Grid>

                <Grid item xs={12} md={6}>
                <div className={classes.insBoxLR}>
                        <Typography className={classes.insBoxLRLabel}>{langConstant.DISTANCE_CALCULATOR}</Typography>
                        <div className={classes.insBoxLRField50}>
                        <Select 
                            className={classes.insBoxLRField} 
                            inputProps={{style: {padding:8}}} 
                            options={distanceType}
                            name="distance_calculator"
                            onChange={(distance) => {
                                comparisonFormik.setFieldValue("distance_calculator", distance.value)
                                documentCompareFormik.setFieldValue("distance_calculator", distance.value)
                                setDistanceDescription(distanceDescriptionData[distance.value])
                            }}
                            value={distanceType.find((distance) => distance.value === comparisonFormik.values.distance_calculator)}
                        />
                        {comparisonFormik.errors && comparisonFormik.errors.distance_calculator && 
                            <span className={classes.insBoxLRError}>{comparisonFormik.errors.distance_calculator}</span>
                        }
                        {documentCompareFormik.errors && documentCompareFormik.errors.distance_calculator && 
                            <span className={classes.insBoxLRError}>{documentCompareFormik.errors.distance_calculator}</span>
                        }
                        </div>
                        {distanceDescription && 
                            <Typography className={classes.distanceNote}>
                                {distanceDescription}
                            </Typography>
                        }
                    </div>
                </Grid>

            </Grid>
        </Grid>
    )
}

CompareFileModal.propTypes = {
    documentFormik: PropTypes.any,
    comparisonFormik: PropTypes.any,
    documentCompareFormik: PropTypes.any,
    setSelectedBasePolicy: PropTypes.func,
    setSelectedTargetPolicy: PropTypes.func,
}

export default CompareFileModal;