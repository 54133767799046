import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  tableRow: {
    padding: "12px 10px 12px",
  },
  outerTableRow: {
    padding: "6px 10px 2px",
  },
  tableCell: {
    padding: "8px 10px !important",
  },
  outerTableCell: {
    padding: "5px 10px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100px",
  },
}));

export default useStyles;
