// OP#2994 - UJA10 - UI Development : Manage Evidence Types - Part 1 only
// pop-up component for Evidence-types

import React, { Fragment, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  makeStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import classNames from "classnames";
import CloseIcon from "@mui/icons-material/Close";
import langConstant from "_lang";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import SaveIcon from "@mui/icons-material/Save";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { PutEvidenceType } from "../../services";
import sessionService from "_services/session.service";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { CircularProgress } from "@mui/material";

function addPrefixToKeys(obj, prefix) {
  const modifiedObject = {};

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const newKey = prefix + key;
      modifiedObject[newKey] = obj[key];
    }
  }

  return modifiedObject;
}

const ROwComp = ({ data, onChange, isLast, isChecked }) => {
  const usemyStyles = makeStyles({
    colorPrimary: {
      background: "#EBEBEB",
    },
    barColorPrimary: {
      background: "red",
    },
    filedTitle: {
      padding: "0 5px",
    },
    wordBreak: {
      wordBreak: "break-all",
    },
  });

  const mynewclasses = usemyStyles();

  const getcolor = (value) => {
    if (value >= 75 && value < 100) {
      return "success";
    }

    if (value > 51 && value < 75) {
      return "warning";
    }

    if (value <= 50) {
      return "error";
    }
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <Checkbox
            placeholder="Select"
            color="success"
            onChange={(e) => onChange(e, data)}
            checked={isChecked}
            size="small"
            id={data?.value}
          />
        </Grid>

        <Grid item xs={4} className={mynewclasses.wordBreak}>
          <Typography
            component="label"
            htmlFor={data?.value}
            className={mynewclasses.filedTitle}
          >
            {data?.value}
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <Typography className={mynewclasses.filedTitle}>
            {data?.count}
          </Typography>
        </Grid>

        {data?.count >= 0 && (
          <Grid item xs={5}>
            <LinearProgress
              variant="determinate"
              value={data?.count}
              style={{
                height: "15px",
                backgroundColor: "#EBEBEB",
                opacity: "0.8",
              }}
              color={getcolor(data?.count)}
            />
          </Grid>
        )}
      </Grid>
      {!isLast && <Divider sx={{ borderBottomWidth: 1 }} />}
    </>
  );
};

ROwComp.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  isLast: PropTypes.bool,
  isChecked: PropTypes.bool,
};

const useStyles = makeStyles(() => ({
  title: { display: "flex", alignItems: "center", padding: ".75rem" },
  justifybetween: {
    justifyContent: "space-between",
    display: "flex",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  filedTitle: {
    padding: "0 5px",
  },
  noteFiledTitle: {
    padding: "20px 5px",
  },
  AddressGrid: {
    marginBottom: "20px",
    alignItems: "center",
  },
  padding: {
    padding: "10px 30px",
  },
  cursorpointer: {
    cursor: "pointer",
  },
  wordBreak: {
    wordBreak: "break-all",
  },
  myprogressPink: {
    background: "red",
    height: "50px",
  },
  colorPrimary: {
    background: "#EBEBEB",
  },
  barColorPrimary: {
    background: "red",
  },
  btnContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    alignItems: "center",
  },
}));

const ExtractedKeywords = ({
  extractedKeywordModal,
  getEvidenceTypeListByAPI,
}) => {
  const dispatch = useDispatch();
  const userId = sessionService.getUserId();
  const myclasses = useStyles();
  const selectedEvidence = extractedKeywordModal?.data?.values;
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const keywordsList = extractedKeywordModal?.data?.extractedKeywords
    ?.keywords_frequency?.length
    ? extractedKeywordModal?.data?.extractedKeywords?.keywords_frequency?.map(
        (item) => {
          return { value: item[0], count: item[1] };
        }
      )
    : extractedKeywordModal?.data?.extractedKeywords?.keywords?.[0]
        ?.split(",")
        ?.map((item) => {
          return { value: item, count: 0 };
        });

  const onSelect = (e, item) => {
    let isChecked = selectedKeywords?.some((i) => i?.value === item?.value);
    let arr = [...selectedKeywords];
    if (isChecked) arr = arr?.filter((i) => i?.value !== item?.value);
    else arr?.push(item);
    setSelectedKeywords(arr);
  };

  const showAlert = (message, type = "error") =>
    dispatch(alertActions[type](message?.toString()));

  const onSubmit = async () => {
    setIsSubmitting(true);
    const payload = {
      ...addPrefixToKeys(selectedEvidence?.evidenceType, "i_"),
      i_EVIDENCE_KEYWORDS: selectedKeywords?.map((i) => i?.value)?.join(";"),
      i_USER_ID: userId,
    };
    await PutEvidenceType(payload)
      .then((response) => {
        if (response?.status !== 200)
          throw new Error("Unable to save keywords");
        showAlert("Keywords successfully added", "success");
        setTimeout(() => {
          getEvidenceTypeListByAPI?.();
          extractedKeywordModal?.closeModal();
        }, 100);
      })
      .catch((error) => {
        console.log(error);
        showAlert(error?.toString());
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Dialog
      open={extractedKeywordModal?.isOpen}
      maxWidth={"md"}
      fullWidth
      keepMounted
      onClose={extractedKeywordModal?.closeModal}
    >
      <div className={myclasses.padding}>
        <DialogTitle disableTypography className={myclasses.title}>
          <Typography style={{ flex: 1 }} variant="h6" align="center">
            EXTRACTED KEYWORDS
          </Typography>
          <IconButton
            style={{ position: "absolute", right: 4 }}
            aria-label="close"
            onClick={extractedKeywordModal?.closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Grid container justify={"space-around"}>
              <Grid item>
                <Typography className={myclasses.filedTitle}>
                  {langConstant.EVIDENCE_TYPE}
                </Typography>
                <Typography className={myclasses.filedTitle}>
                  <strong>
                    {selectedEvidence?.evidenceType?.EVIDENCE_SHORTNAME}
                  </strong>
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={myclasses.filedTitle}>
                  {langConstant.SAMPLE_FILE_UPLOADED}
                </Typography>

                <Typography className={myclasses.filedTitle}>
                  <strong>{selectedEvidence?.file?.name}</strong>
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={myclasses.filedTitle}>
                  {langConstant.KEYWORDS_EXTRACTED}
                </Typography>

                <Typography className={myclasses.filedTitle}>
                  <strong>{keywordsList?.length}</strong>
                </Typography>
              </Grid>
            </Grid>

            <Typography className={myclasses.noteFiledTitle}>
              {langConstant.EXTRACTED_KEYWORDS_NOTE}
            </Typography>

            <PerfectScrollbar>
              <Box maxHeight="50vh" p={2}>
                <ROwComp
                  data={{ value: "Select All", count: "Count" }}
                  onChange={(e) =>
                    setSelectedKeywords(e?.target?.checked ? keywordsList : [])
                  }
                  isChecked={Boolean(
                    selectedKeywords?.length === keywordsList?.length
                  )}
                />
                {keywordsList
                  ?.sort((a, b) => b?.count - a?.count)
                  ?.map((row, i) => {
                    return (
                      <Fragment key={`${i}${row.value}`}>
                        <ROwComp
                          isChecked={selectedKeywords?.some(
                            (i) => i?.value === row?.value
                          )}
                          data={row}
                          isLast={Boolean(keywordsList?.length - 1 === i)}
                          onChange={onSelect}
                        />
                      </Fragment>
                    );
                  })}
              </Box>
            </PerfectScrollbar>

            <Grid
              container
              className={myclasses.btnContainer}
              justify="center"
              spacing={4}
            >
              <Grid item xs={5}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  startIcon={
                    isSubmitting ? <CircularProgress size={25} /> : <SaveIcon />
                  }
                  onClick={onSubmit}
                  disabled={Boolean(!selectedKeywords?.length || isSubmitting)}
                >
                  ADD TO KEYWORD DICTIONARY
                </Button>
              </Grid>

              <Grid item xs={5}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={extractedKeywordModal?.closeModal}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </div>
    </Dialog>
  );
};

ExtractedKeywords.propTypes = {
  extractedKeywordModal: PropTypes.object,
  getEvidenceTypeListByAPI: PropTypes.func,
};

export default ExtractedKeywords;
