import React from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import MessageIcon from '@mui/icons-material/Message';
import InfoIcon from '@mui/icons-material/Info';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import langConstant from "_lang";
import policyDecisionServices from "../../../../../PolicyExplorer/services";
import sessionService from "_services/session.service";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';


const useStyles = makeStyles(() => ({
    contantContaxMenu:{
        padding: "5px 0px 0px 0px",
        '& .MuiListItemIcon-root':{
            minWidth:'unset !important',
        }
    }
}))

const ContextMenu = ({
    anchorEl, 
    open, 
    handleMenuClose, 
    comparisonID, 
    decisionSentence, 
    setDecisionScore, 
    decisionScore, 
    setTextComparedSentence, 
    textComparedSentence, 
    setComparisonStatus,
    addNoteModal,
    addActionModal
}) => {

    const classes = useStyles();
    const userID = sessionService.getUserId();
    const dispatch = useDispatch()

    const applyDecision = async(decision, comment) => {
        const data = {
            i_POLICYCOMPARISONID: comparisonID,
            i_POLICY_COMPARISON_DETAILS_ID: decisionSentence.comparisonDetailId,
            i_DECISION_TYPE_ID: decision,
            i_USER_ID: userID,
            i_DECISION_COMMENT: comment
        }
        const decisionObj = {
            1: "accepted",
            2: "rejected",
            3: "pending",
            4: "discard",
            null: "not_processed"
        }
        dispatch(loadingActions.start())
        const response = await policyDecisionServices.putPolicyComparisonDecision(data)
        dispatch(loadingActions.end())
        if(response.status === 200){
            setDecisionScore((prevState) => (
                {...prevState, 
                    [decisionObj[decisionSentence.decisionTypeId]]: decisionScore[decisionObj[decisionSentence.decisionTypeId]] -1 ,
                    [decisionObj[decision]]: decisionScore[decisionObj[decision]] +1 ,
                }
            ))
            let tempTextComparedSentence = [...textComparedSentence[decisionSentence.linkId]]
            const comparedSentenceIndex = tempTextComparedSentence.findIndex(item => item.POLICY_COMPARISON_DETAILS_ID == decisionSentence.comparisonDetailId)
            tempTextComparedSentence[comparedSentenceIndex]['DECISION_TYPE_ID'] = decision
            setTextComparedSentence((prevState) => ({...prevState, [decisionSentence.linkId]: tempTextComparedSentence}))
            const scoreResponse = await policyDecisionServices.getComparisonScore(comparisonID)
            if(scoreResponse['status'] === 200) {
                setComparisonStatus((prevState) => ({...prevState, PROGRESS: scoreResponse['#result-set-2'][0]['PROGRESS']}))
            } else {
                if(scoreResponse.data && scoreResponse.data.message)
                {
                  dispatch(
                      alertActions.error(
                        scoreResponse.data.message.map((item, index) => { return <div key={index}>{item}</div> })
                      )
                  );
                } else {
                  dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG))
                }
            }
        } else {
            if(response.data && response.data.message)
            {
              dispatch(
                  alertActions.error(
                    response.data.message.map((item, index) => { return <div key={index}>{item}</div> })
                  )
              );
            } else {
              dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG))
            }
        }
    }

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
            },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuList divider={true} style={{textAlign: "center", justifyContent: "space-between", display: "flex", padding: "0px 0px"}} className={classes.contantContaxMenu}>
                <Tooltip title={langConstant.ACCEPTED_SMALL}>
                    <MenuItem disabled={decisionSentence['decisionTypeId'] == 1 ? true : false}>
                        <ListItemIcon onClick={() => applyDecision(1, "Accepted")}><CheckCircleIcon fontSize='small' color="success"/></ListItemIcon>
                    </MenuItem>
                </Tooltip>
                <Tooltip title={langConstant.REJECTED}>
                    <MenuItem disabled={decisionSentence['decisionTypeId'] == 2 ? true : false}>
                        <ListItemIcon onClick={() => applyDecision(2, "Rejected")}><CancelIcon fontSize='small' style={{color: "#af3939"}}/></ListItemIcon>
                    </MenuItem>
                </Tooltip>
                <Tooltip title={langConstant.PENDING_CLARIFICATION}>
                    <MenuItem disabled={decisionSentence['decisionTypeId'] == 3 ? true : false}>
                        <ListItemIcon onClick={() => applyDecision(3, "Pending Clarification")}><InfoIcon fontSize='small' style={{color: "#b7913c"}}/></ListItemIcon>
                    </MenuItem>
                </Tooltip>
                <Tooltip title={langConstant.DISCARD}>
                    <MenuItem disabled={decisionSentence['decisionTypeId'] == 4 ? true : false}>
                        <ListItemIcon onClick={() => applyDecision(4, "Discard")}><DeleteIcon fontSize='small' style={{color: "#ff0909"}}/></ListItemIcon>
                    </MenuItem>
                </Tooltip>
            </MenuList>
            <Divider />
            <MenuItem divider={true} onClick={addActionModal?.openModal}>
                <ListItemIcon>
                    <MessageIcon fontSize='small'/>
                </ListItemIcon>
                Add Action
            </MenuItem>
            <MenuItem onClick={addNoteModal?.openModal}>
                <ListItemIcon>
                    <ContentPasteIcon fontSize='small'/>
                </ListItemIcon>
                Add Note
            </MenuItem>
        </Menu>
    )
}

ContextMenu.propTypes = {
    anchorEl: PropTypes.any,
    open: PropTypes.bool,
    handleMenuClose: PropTypes.func,
    comparisonID: PropTypes.string,
    decisionSentence: PropTypes.object,
    setDecisionScore: PropTypes.func,
    decisionScore: PropTypes.object,
    textComparedSentence: PropTypes.object,
    setTextComparedSentence: PropTypes.func,
    setComparisonStatus: PropTypes.func,
    addNoteModal:PropTypes.object,
    addActionModal:PropTypes.object
}

export default ContextMenu;