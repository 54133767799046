/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Box,
  Typography,
} from "@material-ui/core";

import langConstant from "_lang";
import rootStyles from "rootStyles";
import SingleQuestion from "../SingleQuestion";
import AnchorSideNavigation from "_components/AnchorSideNavigation";

const ApproveAssessment = ({ questionList}) => {
  const rootClasses = rootStyles();
  const [listByCategory, setListByCategory] = useState({});
  const [categoryAnchor, setCategoryAnchor] = useState([]);

  const printSingleQuestion = (data) => {
    return data.map((item) => {
        return (
          <SingleQuestion
            key={item.id}
            item={item}
          />
        );
    });
  };
  const updateAnchor = (list) => {
    const groupByCategory = {};
    const anchor = [];
    const tempKey = {};
    list.forEach((item) => {
      item.CISO_CONTROL_NAME = item.CISO_CONTROL_NAME == null ? langConstant.UNCATEGORIZED : item.CISO_CONTROL_NAME
        if (groupByCategory[item.CISO_CONTROL_NAME]) {
          groupByCategory[item.CISO_CONTROL_NAME].push(item);
        } else {
          groupByCategory[item.CISO_CONTROL_NAME] = [];
          groupByCategory[item.CISO_CONTROL_NAME].push(item);}

        if (tempKey[item.CISO_CONTROL_NAME] == undefined) {
          tempKey[item.CISO_CONTROL_NAME] = "";
          anchor.push({ title: item.CISO_CONTROL_NAME, id: "_" + item.id });
        }
    });
    const sortedAnchor = _.sortBy(anchor, "title");

    const sortedGroupByCategory = {};
    _(groupByCategory)
      .keys()
      .sort()
      .each(function (key) {
        sortedGroupByCategory[key] = groupByCategory[key];
      });
      const anchorToSet = sortedAnchor.map((anchor) => {
        if (anchor.title == null || anchor.title == "") {
          return { title: langConstant.UNCATEGORIZED, id: anchor.id };
        } else {
          return anchor;
        }
      });
      if (
        sortedAnchor[sortedAnchor.length - 1].title == null ||
        sortedAnchor[sortedAnchor.length - 1].title == ""
      ) {
        sortedAnchor[sortedAnchor.length - 1].title = langConstant.UNCATEGORIZED;
      }
    setListByCategory(sortedGroupByCategory);
    setCategoryAnchor(anchorToSet);
  };
  useEffect(() => {
      updateAnchor(questionList);
  }, [questionList]);
  return (
    <>
      {categoryAnchor.length > 1 && (
        <AnchorSideNavigation anchors={categoryAnchor} currentAnchor={2} />
      )}
      <Box
        mt={2}
        style={categoryAnchor.length > 1 ? { paddingRight: "200px" } : null}
      >
        {Object.keys(listByCategory).length > 0
          ? Object.keys(listByCategory).map((key, parentIndex) => {
              {
                return listByCategory[key].length > 0 ? (
                  <Box key={parentIndex}>
                      <Typography
                        className={rootClasses.catTitle}
                        component="h2"
                        id={"_" + listByCategory[key][0].id}
                      >
                        {key}
                      </Typography>
                    {printSingleQuestion(listByCategory[key])}
                  </Box>
                ) : null;
              }
            })
          : null}
      </Box>
    </>
  );
};
ApproveAssessment.propTypes = {
  questionList: PropTypes.array
};
export default ApproveAssessment;
