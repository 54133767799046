import React, { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { ROW } from "./constants";
import DropZone from "./dropzone";
import Column from "./column";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import useStyles from "../styles";
import { useDispatch, useSelector } from "react-redux";
import assessmentAction from "containers/cyber-assessment/MyAssessments/actions";
import sessionService from "_services/session.service";
import Select from "react-select";
import { setRegulationData } from "../actions";
import langConstant from "_lang";
import { getThemeDetails } from "containers/LoginPage/reducers";
import TuneIcon from "@material-ui/icons/Tune";
import PanelFilterPopUp from "./PanelFilterPopUp";
import Button from "@material-ui/core/Button";

const Row = ({ data, components, handleDrop, path }) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });

  const ref = useRef(null);
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const regulations = useSelector((state) => state.assessmentList.dataList);
  const [regulationsOptions, setRegulationsOptions] = useState([]);
  const [selectedRegulation, setSelectedRegulation] = useState(null);
  const [panelFilterPopUp, setPanelFilterPopUp] = useState(false);

  useEffect(() => {
    dispatch(assessmentAction.getAssessmentList(userID));
  }, []);

  useEffect(() => {
    let unique = [];
    regulations.forEach((item) => {
      unique.push({
        value: item["REGULATION_ID"] + "-" + item["SUBMISSION_ID"],
        label:
          item["REGULATION_SHORT_NAME"] + " ( " + item["SUBMISSION_ID"] + " ) ",
        ...item,
      });
    });
    // const unique = [
    //   regulations.map((item) => [
    //     item["REGULATION_ID"],
    //     {
    //       value: item["REGULATION_ID"],
    //       label: item["REGULATION_SHORT_NAME"],
    //       ...item,
    //     },
    //   ]),
    // ];
    setRegulationsOptions(unique);
  }, [regulations]);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ROW,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderColumn = (column, currentPath) => {
    return (
      <Column
        key={column.id}
        data={column}
        components={components}
        handleDrop={handleDrop}
        path={currentPath}
      />
    );
  };

  return (
    <Card
      id="elementToCapture"
      sx={{
        my: 1,
        backgroundColor: "#F0F4F8",
        border: "none",
        overflow: "auto",
        scrollbarWidth: "none",
        height: "calc(100% - 30px)",
      }}
      variant="outlined"
    >
      <Grid
        container
        sx={{ padding: "10px 20px 0" }}
        justify="space-between"
        spacing={2}
      >
        <Grid item md={10} sm={8}>
          <Select
            className={classes.profileMDBasicSelect}
            options={regulationsOptions}
            placeholder={langConstant.SELECT_REGULATION}
            value={selectedRegulation}
            onChange={(reg) => {
              setSelectedRegulation(reg);
              dispatch(setRegulationData(reg));
            }}
          />
        </Grid>
        <Grid item md={1} sm={2} sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => {
              setSelectedRegulation(null);
              dispatch(setRegulationData(null));
            }}
            variant="contained"
            color="primary"
          >
            {langConstant.RESET_ALL}
          </Button>
        </Grid>
        <Grid item md={1} sm={2} textAlign="right">
          <IconButton
            className={classes.iconButton}
            onClick={() => setPanelFilterPopUp(true)}
          >
            <Tooltip title={langConstant.SET_FILTERS}>
              <TuneIcon style={{ color: "white" }} />
            </Tooltip>
          </IconButton>
          {panelFilterPopUp && (
            <PanelFilterPopUp
              panelFilterPopUp={panelFilterPopUp}
              setPanelFilterPopUp={setPanelFilterPopUp}
            />
          )}
        </Grid>
      </Grid>
      <Box className={classes.columns}>
        {data.children.map((column, index) => {
          const currentPath = `${path}-${index}`;
          return (
            <React.Fragment key={column.id}>
              <DropZone
                data={{
                  path: currentPath,
                  childrenCount: data.children.length,
                }}
                onDrop={handleDrop}
                className={classes.horizontalDrag}
                isHorizontal={true}
              />
              {renderColumn(column, currentPath)}
            </React.Fragment>
          );
        })}
        <DropZone
          data={{
            path: `${path}-${data.children.length}`,
            childrenCount: data.children.length,
          }}
          onDrop={handleDrop}
          className={classes.horizontalDrag}
          isHorizontal={true}
          isLast
        />
      </Box>
    </Card>
  );
};

Row.propTypes = {
  data: PropTypes.any,
  components: PropTypes.any,
  handleDrop: PropTypes.any,
  path: PropTypes.any,
};

export default Row;
