import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppPagination from "_components/AppPagination";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import Select from "react-select";
import { getThemeDetails } from "containers/LoginPage/reducers";

const SplittedDataList = ({ splittedDataList }) => {
  const themeDetails = useSelector(getThemeDetails);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [isAllAccordionOpen, setIsAllAccordionOpen] = useState(false);
  const [expandedList, setExpandedList] = useState([]);
  const [pageOptions, setPageOptions] = useState([]);
  const [filterData, setFilterData] = useState({ text: "", pageNo: null });
  const [filteredSplittedDataList, setFilteredSplittedDataList] = useState([]);

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  useEffect(() => {
    if (splittedDataList.length) {
      setFilteredSplittedDataList(splittedDataList);
      const pagesCount = calculatePageCount(splittedDataList);
      setPageOptions(
        Array.from({ length: pagesCount }, (_, index) => ({
          value: index + 1,
          label: index + 1,
        }))
      );
      setPageCount(pagesCount);
    }
  }, [splittedDataList]);

  useEffect(() => {
    updateExpandAllList(isAllAccordionOpen);
  }, [page]);

  const updateExpandedList = (splittedID) => {
    if (expandedList.includes(splittedID)) {
      const tempExpandedList = [...expandedList];
      const index = tempExpandedList.indexOf(splittedID);
      if (index > -1) {
        tempExpandedList.splice(index, 1);
        setExpandedList(tempExpandedList);
        setIsAllAccordionOpen(false);
      }
    } else {
      setExpandedList((prevState) => [...prevState, splittedID]);
    }
  };

  const updateExpandAllList = (isChecked) => {
    if (filteredSplittedDataList.length && isChecked) {
      const tempPageWisedata = filteredSplittedDataList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      const tempExpandedList = [];
      tempPageWisedata.forEach((element, index) =>
        tempExpandedList.push(index)
      );
      setExpandedList(tempExpandedList);
    } else {
      setExpandedList([]);
    }
  };

  const validateFilterFields = () => {
    if (filterData.text) {
      let tempData = [];
      splittedDataList.forEach((item) => {
        if (item.toLowerCase().includes(filterData.text.toLowerCase())) {
          tempData.push(item);
        }
      });
      setFilteredSplittedDataList(tempData);
      const pagesCount = calculatePageCount(tempData);
      if (page > pagesCount) {
        setPage(pagesCount - 1);
      }
      setPageCount(pagesCount);
      setPageOptions(
        Array.from({ length: pagesCount }, (_, index) => ({
          value: index + 1,
          label: index + 1,
        }))
      );
    } else {
      setFilteredSplittedDataList(splittedDataList);
      const pagesCount = calculatePageCount(splittedDataList);
      setPageCount(pagesCount);
      if (page > pagesCount) {
        setPage(pagesCount - 1);
      }
      setPageOptions(
        Array.from({ length: pagesCount }, (_, index) => ({
          value: index + 1,
          label: index + 1,
        }))
      );
    }
  };

  return (
    <Grid>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item md={6} xs={12}>
          <Checkbox
            checked={isAllAccordionOpen}
            onClick={(e) => {
              setIsAllAccordionOpen(e.target.checked);
              updateExpandAllList(e.target.checked);
            }}
            sx={{ "&.Mui-checked": { color: "#124571" }, display: "inline" }}
            size="small"
          />
          <span style={{ fontWeight: "bold" }}>Expand All</span>
        </Grid>
        {pageCount > 1 && (
          <Grid item md={6} xs={12} className={classes.contentRight}>
            <Box my={2}>
              <Grid container justify="flex-end">
                <AppPagination
                  colSpan={3}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ marginBottom: "20px" }}>
        <Grid item md={8} xs={8} sx={{ paddingRight: "20px" }}>
          <TextField
            variant="outlined"
            style={{ width: "100%" }}
            onChange={(e) => {
              setFilterData((prevState) => ({
                ...prevState,
                text: e.target.value,
              }));
            }}
            placeholder="Enter Text"
            InputProps={{ classes: { input: classes.inputField } }}
          />
        </Grid>

        <Grid item md={3} xs={3} sx={{ paddingRight: "20px" }}>
          <Select
            options={pageOptions}
            value={pageOptions.find((item) => item.value == filterData.pageNo)}
            onChange={(value) => {
              setPage(parseInt(value.value) - 1);
            }}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item md={1} xs={1}>
          <IconButton
            className={classes.iconButton}
            onClick={validateFilterFields}
          >
            <Tooltip title={"Search"}>
              <SearchIcon
                style={{
                  backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                  color: "white",
                }}
              />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
      <Box className={classes.accordionWrapper}>
        {(rowsPerPage > 0
          ? filteredSplittedDataList.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : filteredSplittedDataList
        ).map((item, index) => {
          return (
            <Accordion
              key={index}
              expanded={
                expandedList.length > 0 ? expandedList.includes(index) : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => updateExpandedList(index)}
              >
                <Typography>Split {index + 1 + page * rowsPerPage}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.accordionText}>
                  {item}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Grid>
  );
};

SplittedDataList.propTypes = {
  splittedDataList: PropTypes.array,
};

export default SplittedDataList;
