/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React from "react";
import moment from "moment";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import { constants } from "./constants.js";
import sessionService from "_services/session.service";

const processRfiDetails = (rfiId, rfiDataEntries, rfiDataEntriesValues) => {
  const attributesValueArray = rfiDataEntriesValues.map((attribute) => ({
    RFI_RESPONSE_JSON: JSON.parse(attribute.RFI_RESPONSE_JSON),
    RFI_ITEM_KEY: attribute.RFI_ITEM_KEY,
  }));

  return {
    dataAttributesWithValues: getDataAttributesValues(
      rfiDataEntries,
      attributesValueArray
    ),
    narrative: getRfiNarrative(rfiId, attributesValueArray),
  };
};

const getDataAttributesValues = (rfiDataEntries, attributesValueArray) => {
  return rfiDataEntries.map((rfiDataEntry) => {
    const foundAttributeValue = _.find(attributesValueArray, (attributeValue) =>
      _.isMatch(attributeValue.RFI_RESPONSE_JSON, {
        DATA_ASSET_ENTITY_NAME: rfiDataEntry.DATA_ASSET_ENTITY_NAME,
        DATA_ASSET_ATTRIBUTE_NAME: rfiDataEntry.DATA_ASSET_ATTRIBUTE_NAME,
      })
    );

    return foundAttributeValue
      ? {
          ...rfiDataEntry,
          SOURCE_DATA_POINT_VALUE:
            foundAttributeValue.RFI_RESPONSE_JSON.SOURCE_DATA_POINT_VALUE,
          RFI_ITEM_KEY: foundAttributeValue.RFI_ITEM_KEY,
          I_RFI_RESPONSE_RECORD_TYPE: "Attribute",
        }
      : {
          ...rfiDataEntry,
          SOURCE_DATA_POINT_VALUE: "",
          RFI_ITEM_KEY: uuidv4(),
          I_RFI_RESPONSE_RECORD_TYPE: "Attribute",
        };
  });
};

const getRfiNarrative = (rfiId, attributesValueArray) => {
  const foundNarrative = _.find(
    attributesValueArray,
    (attributeValue) => attributeValue.RFI_RESPONSE_JSON?.NARRATIVE
  );

  return foundNarrative
    ? {
        RFI_ID: rfiId,
        I_RFI_RESPONSE_RECORD_TYPE: "Narrative",
        NARRATIVE: foundNarrative.RFI_RESPONSE_JSON.NARRATIVE,
        RFI_ITEM_KEY: foundNarrative.RFI_ITEM_KEY,
        I_USER_ID: sessionService.getUserId(),
      }
    : {
        RFI_ID: rfiId,
        RFI_ITEM_KEY: uuidv4(),
        I_RFI_RESPONSE_RECORD_TYPE: "Narrative",
        NARRATIVE: "",
        I_USER_ID: sessionService.getUserId(),
      };
};

const getRfisForUserAssessment = (userID, assessmentId) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRFIsForUserAssessment", {
        i_USER_ID: userID,
        i_ASSESSMENT_ID: assessmentId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#result-set-1"]) {
            dispatch({
              type: constants.RFI_FOR_USER_ASSESSMENT_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch({ type: constants.RFI_FOR_USER_ASSESSMENT_FAILURE });
            dispatch(alertActions.error(constants.ERROR_IN_GETTING_RFIS));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const clearStateRfiData = () => {
  return (dispatch) => {
    dispatch({
      type: constants.RFI_STATE_DATA_CLEAR,
    });
  };
};

const updateRfiStatus = (assessmentId, questionId, rfiStatus , RFI_ID) => {
  const rfiResolvedDate = moment().format("YYYY-MM-DD HH:mm:ss");

  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateRFI", {
        i_ASSESSMENT_ID: assessmentId,
        i_QUESTION_ID: questionId,
        i_RFI_ID : RFI_ID ,
        i_RFI_DUE_DATE: null,
        i_ACTIVE: null,
        i_RFI_STATUS: rfiStatus,
        i_RFI_RESOLVED_DATE: rfiResolvedDate,
      })
      .then(apiService.handleResponse)
      .then(
        dispatch({
          type: constants.RFI_STATUS_UPDATE_SUCCESS,
          data: { QUESTION_ID: questionId, RFI_STATUS: rfiStatus },
        }),
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getRfiDataAttributes = (rfiId) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRFIDetails", {
        i_RFI_ID: rfiId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (
            response &&
            response["#result-set-1"] &&
            response["#result-set-2"] &&
            response["#result-set-3"]
          ) {
            dispatch({
              type: constants.RFI_DATA_ATTRIBUTES_SUCCESS,
              data: {
                rfiDescriptiveDetails: response["#result-set-1"][0],
                ...processRfiDetails(
                  rfiId,
                  response["#result-set-2"],
                  response["#result-set-3"]
                ),
              },
            });
          } else {
            dispatch({ type: constants.RFI_DATA_ATTRIBUTES_FAILURE });
            dispatch(
              alertActions.error(constants.RFI_DATA_ATTRIBUTES_NOT_FOUND)
            );
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const handleDataEntryChange = (attributeType, itemKey, value) => {
  return (dispatch) => {
    dispatch({
      type: constants.RFI_DATA_ENTRY_UPDATE,
      data: {
        ATTRIBUTE_TYPE: attributeType,
        RFI_ITEM_KEY: itemKey,
        VALUE: value,
      },
    });
  };
};

const updateRfi = (rfiNarrative, rfiDataAttributes) => {
  const userId = sessionService.getUserId();

  let updateArray = rfiDataAttributes
    ? rfiDataAttributes.map((rfiAttributeData) => {
        return {
          i_RFI_ID: rfiAttributeData.RFI_ID,
          i_RFI_ITEM: rfiAttributeData.RFI_ITEM_KEY,
          I_RFI_RESPONSE_RECORD_TYPE:
            rfiAttributeData.I_RFI_RESPONSE_RECORD_TYPE,
          i_RFI_RESPONSE_JSON: JSON.stringify({
            DATA_ASSET_ENTITY_NAME: rfiAttributeData.DATA_ASSET_ENTITY_NAME,
            SOURCE_DATA_POINT_VALUE: rfiAttributeData.SOURCE_DATA_POINT_VALUE,
            DATA_ASSET_ATTRIBUTE_NAME:
              rfiAttributeData.DATA_ASSET_ATTRIBUTE_NAME,
          }),
          I_USER_ID: userId,
        };
      })
    : [];

  updateArray.push({
    i_RFI_ID: rfiNarrative.RFI_ID,
    i_RFI_ITEM: rfiNarrative.RFI_ITEM_KEY,
    I_RFI_RESPONSE_RECORD_TYPE: rfiNarrative.I_RFI_RESPONSE_RECORD_TYPE,
    i_RFI_RESPONSE_JSON: JSON.stringify({
      NARRATIVE: rfiNarrative.NARRATIVE,
    }),
    I_USER_ID: userId,
  });

  return (dispatch) => {
    dispatch(loadingActions.start());
    Promise.all(
      updateArray.map((updateItem) => {
        return apiService
          .apiCall("dbapi")
          .send("/v1/api/proc/DRegulativDB/PutRFIResponse", updateItem)
          .then(apiService.handleResponse);
      })
    )
      .then((responses) => {
        dispatch(loadingActions.end());
        dispatch(alertActions.success("RFI updated successfully"));
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(
            alertActions.error(
              error.response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      });
  };
};

const getRfisSummaryForConfirmation = (userId, assessmentId) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/getRFIsForAssessmentConfirmation", {
        i_USER_ID: userId,
        i_ASSESSMENT_ID: assessmentId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#result-set-1"]) {
            dispatch({
              type: constants.RFI_FOR_CONFIRMATION_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch({ type: constants.RFI_FOR_CONFIRMATION_FAILURE });
            dispatch(alertActions.error(constants.ERROR_IN_GETTING_RFIS));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateRfiRemarksCount = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: constants.RFI_REMARK_UPDATE_SUCCESS,
      data: {
        questionId: questionId,
      },
    });
  };
};

export default {
  getRfisForUserAssessment,
  updateRfiStatus,
  getRfiDataAttributes,
  handleDataEntryChange,
  updateRfi,
  getRfisSummaryForConfirmation,
  updateRfiRemarksCount,
  clearStateRfiData
};
