import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile"; // Import AttachFileIcon
import IconButton from "@mui/material/IconButton";

const TextEditor = ({ onChangeHandler, data, onCancelClick, identifier }) => {
  const wrapperStyle = {
    border: "1px solid #969696",
    height: "100%",
  };
  const editorStyle = {
    height: identifier === 2 ? "calc(100% - 50px)" : "calc(150%)",
    // lineHeight: "25%"
  };
  const contentBlock = htmlToDraft(data === null ? "" : data);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    onChangeHandler(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const handleAttachFile = () => {
    // Trigger the file input click
    const fileInput = document.createElement("input");
    fileInput.setAttribute("type", "file");
    fileInput.setAttribute("multiple", true); // Allow multiple file selection
    fileInput.style.display = "none";

    fileInput.addEventListener("change", (e) => {
      const files = Array.from(e.target.files);
      setSelectedFiles(files);
      // Process the selected files (upload or do other actions)
      console.log("Selected files:", files);
    });

    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  };

  const CustomButton = ({ editorState, onChange }) => {
    return (
      <div>
        <IconButton disabled onClick={handleAttachFile}>
          <AttachFileIcon color="primary" />
        </IconButton>
        <IconButton onClick={onCancelClick}>
          <CloseIcon color="error" sx={{ fontSize: "26px" }} />
        </IconButton>
      </div>
    );
  };

  CustomButton.propTypes = {
    editorState: PropTypes.object,
    onChange: PropTypes.func,
  };

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="wrapper-class"
      wrapperStyle={wrapperStyle}
      editorStyle={editorStyle}
      editorClassName="demo-editor"
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "list",
          "textAlign",
          "colorPicker",
          "link",
          "history",
        ],
        attachment: { onClick: handleAttachFile }, // Add attachment option to the toolbar
      }}
      toolbarCustomButtons={[<CustomButton key={1} />]}
    >
      {/* Display selected files within the editor content */}
      <div>
        {selectedFiles.map((file, index) => (
          <div key={index}>{file.name}</div>
        ))}
      </div>
    </Editor>
  );
};

TextEditor.propTypes = {
  onChangeHandler: PropTypes.func,
  onCancelClick: PropTypes.func,
  data: PropTypes.string,
  identifier: PropTypes.number,
};

export default TextEditor;
