import React, { useEffect, useState } from "react";
import DialogBox from "_components/DialogBox";
import langConstant from "_lang";
import PropTypes from "prop-types";
import EvidenceServices from "../services";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import AppPagination from "_components/AppPagination";
import moment from "moment";
import FilterDropdown from "_components/FilterDropdown";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
}));

const ApproversList = ({ show, setShow, evidenceLogId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toggleDialog = () => setShow(false);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const statusOptions = [
    { value: "Accepted", name: "Accepted" },
    { value: "Pending Clarification", name: "Pending Clarification" },
    { value: "Rejected", name: "Rejected" },
  ];

  const [approversList, setApproversList] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterBy, setFilterBy] = useState({ STATUS: "" });
  const [filteredRows, setFilteredRows] = useState([]);

  const returnClassName = (status) => {
    if (status === "Rejected") {
      return "error";
    } else if (status === "Accepted") {
      return "success";
    } else if (status === "Pending Clarification") {
      return "warning";
    } else {
      return "info";
    }
  };

  const columns = [
    {
      field: "EVIDENCE_LOG_ID",
      headerName: "Evidence Log Id",
      minWidth: 135,
    },
    {
      field: "DECISION_DESCRIPTION",
      headerName: "Status",
      format: function formatData(value) {
        return (
          <Alert severity={returnClassName(value["DECISION_DESCRIPTION"])}>
            {value["DECISION_DESCRIPTION"]}
          </Alert>
        );
      },
    },
    {
      field: "NAME",
      headerName: "Evidence Approver Name",
      format: (value) => value["FIRST_NAME"] + " " + value["LAST_NAME"],
      minWidth: 350,
    },
    {
      field: "APPROVAL_DATE",
      headerName: "Decision Date",
      format: (value) =>
        value["APPROVAL_DATE"]
          ? moment(value["APPROVAL_DATE"]).format("DD-MMM-YY")
          : "",
      minWidth: 120,
    },
  ];

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetFilter = () => {
    setFilterBy({ STATUS: "" });
    setFilteredRows(approversList);
    setPageCount(calculatePageCount(approversList));
    setPage(0);
  };

  const onFilterChange = (fieldName, e) => {
    const filterValue = e.target.value;
    const filterObj = { ...filterBy, [fieldName]: filterValue };
    let updatedRows = [...approversList];

    if (filterObj["STATUS"]) {
      updatedRows = updatedRows.filter(
        (item) => item["DECISION_DESCRIPTION"] === filterObj["STATUS"]
      );
    }
    setFilterBy(filterObj);
    setFilteredRows(updatedRows);
    setPageCount(calculatePageCount(updatedRows));
    setPage(0);
  };

  useEffect(() => {
    (async () => {
      const payload = {
        i_EVIDENCE_LOG_ID: evidenceLogId,
      };
      const response = await EvidenceServices.getEvidenceApprovals(payload);
      if (response.status == 200) {
        setApproversList([...response["#result-set-1"]]);
        setPageCount(calculatePageCount(response["#result-set-1"]));
        setFilteredRows([...response["#result-set-1"]]);
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    })();
  }, []);

  return (
    <DialogBox
      maxWidth={"lg"}
      title={langConstant.APPROVERS}
      open={show}
      handleClose={toggleDialog}
      style={{ paddingTop: "0px" }}
    >
      <Box mb={2} mt={3}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={10}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FilterDropdown
                  options={statusOptions}
                  label={langConstant.FILTER_BY_EVIDENCE_STATUS}
                  fieldName={"STATUS"}
                  value={filterBy["STATUS"]}
                  onChange={onFilterChange}
                  icon={true}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                {" "}
                <Box mx={1} style={{ marginTop: "10px" }}>
                  {filterBy["STATUS"] && (
                    <span onClick={resetFilter} className="linkIcon">
                      Reset All
                    </span>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Box>
      <TableContainer
        component={Paper}
        style={{
          border: "1px solid rgba(0,0,0,0.1)",
          borderBottomStyle: "none",
          borderTopStyle: "solid",
          borderRightStyle: "solid",
          borderLeftStyle: "solid",
        }}
      >
        <Table className="data-table" aria-label="Regulation List">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={
                    (item.sticky && classes.stickyColl) + " " + classes.heading
                  }
                  style={{ minWidth: item.minWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, index) => (
              <TableRow key={index + row[columns[0].field]}>
                {columns.map((column, index) => {
                  const value = row[column.field];

                  return (
                    <TableCell
                      component="td"
                      scope="row"
                      key={"column_" + index + row[columns[0].field]}
                      className={column.sticky && classes.stickyColl}
                      style={{
                        wordBreak: "break-word",
                        wordWrap: "break-word",
                      }}
                    >
                      {column.format ? column.format(row) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {filteredRows.length == 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      )}
    </DialogBox>
  );
};

ApproversList.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  evidenceLogId: PropTypes.number,
};

export default ApproversList;
