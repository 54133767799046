// OP#2801 UJL3 - UI Dev - Manage User Profile
// file stands for crop user avatar image (user avatar pop up)

import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import { Avatar, Grid, Tooltip, Button } from "@material-ui/core";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { makeStyles } from "@material-ui/core";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { alertActions, loadingActions } from "_actions";
import { useDispatch, useSelector } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import { virusCheckerService } from "_helpers/utils";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles(() => ({
  profileUploadwrapper: {
    padding: "20px 20px",
  },
  profileUploadInner: {
    width: "300px",
    marginRight: "10px",
    maxWidth: "100%",
    border: ({ actionButtonColor }) => `1px dashed ${actionButtonColor}`,
    backgroundColor: "rgb(143 197 255 / 10%)",
    padding: "25px 15px",
    margin: "0px auto",
    borderRadius: "6px",
    marginLeft: "0px",
    position: "relative",
  },
  profileUploadCSinput: {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    width: "100%",
    height: "100%",
    opacity: "0.00000000000000001",
    zIndex: "10",
  },
  profileUploadCSBTN: {
    display: "inline-block",
    width: "auto",
    padding: "9px 20px",
    backgroundColor: ({ actionButtonColor }) => actionButtonColor,
    color: "#fff",
    fontSize: "14px",
    fontWeight: "400",
    borderRadius: "6px",
  },
  profileUploadCSwrap: {
    textAlign: "center",
  },
  profileUploadtext: {
    fontSize: "14px",
    marginBottom: "0px",
  },
  profileUploadCropPreviewWrapper: {
    width: "300px",
    marginLeft: "10px",
    maxWidth: "100%",
    margin: "0px auto",
    textAlign: "center",
    backgroundColor: "#ededed",
    border: "1px solid #cfcfcf",
    borderRadius: "10px",
    // marginTop:"30px",
    // marginBottom:"30px",
    paddingBottom: "20px",
  },
  profileUploadCropPreviewTitle: {
    color: "#000",
    fontSize: "18px",
    textAlign: "center",
  },
  profileUploadCropPreviewImg: {
    borderRadius: "50%",
    border: "1px solid #d7d7d7",
    width: "120px",
    height: "120px",
    margin: "0px auto",
  },
  profileUploadSIconWrap: {
    textAlign: "center",
  },
  profileUploadSIcon: {
    width: "40px !important",
    height: "40px !important",
    color: ({ actionButtonColor }) => actionButtonColor,
  },
  profileUploadCSFilename: {
    display: "inline-block",
    fontSize: "14px",
    color: ({ actionButtonColor }) => actionButtonColor,
    backgroundColor: "#d2e8ff",
    padding: "7px 15px",
    borderRadius: "7px",
    marginTop: "10px",
  },
  profileUploadCRWrap: {
    display: "flex",
    justifyContent: "center",
    width: "500px",
    margin: "0px auto",
    maxWidth: "100%",
  },
}));

const UploadPhoto = ({ show, setShow, updateImage }) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const [crop, setCrop] = useState({
    unit: "px",
    width: 200,
    height: 60,
  });
  const [imageToCrop, setImageToCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(null);
  const [cropImageBlob, setCropImageBlob] = useState(null);
  const [isVirusFile, setIsVirusFile] = useState(false);
  const imageRef = useRef(null);

  const dispatch = useDispatch();
  const isVirusCheckingAvailable = useSelector(
    (state) => state.authentication?.detailedInfo?.VIRUS_CHECKER
  );

  const handleClose = () => {
    setShow(false);
  };

  const onSelectedFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (isVirusCheckingAvailable == 1) {
        const fData = new FormData();
        fData.append("file", event.target.files[0]);
        dispatch(loadingActions.start());
        dispatch(
          loadingActions.setLoaderText("Checking Virus In Uploaded File")
        );
        const response = await virusCheckerService(fData);
        dispatch(loadingActions.setLoaderText(null));
        dispatch(loadingActions.end());
        if (response.status == 200) {
          if (response.data && response.data.hasVirus) {
            setIsVirusFile(true);
            dispatch(alertActions.success(response.data.message));
          } else {
            setIsVirusFile(false);
            dispatch(alertActions.success(response.data.message));
            const reader = new FileReader();
            reader.addEventListener("load", () =>
              setImageToCrop(reader.result)
            );
            reader.readAsDataURL(e.target.files[0]);
            setSelectedFile(e.target.files[0]);
          }
        } else {
          dispatch(alertActions.error("Virus Checker Service Failed"));
        }
      } else {
        const reader = new FileReader();
        reader.addEventListener("load", () => setImageToCrop(reader.result));
        reader.readAsDataURL(e.target.files[0]);
        setSelectedFile(e.target.files[0]);
      }
    }
  };

  const cropImage = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef.current,
        crop,
        selectedFile.name
      );
      setCroppedImage(croppedImage);
    }
  };

  const getCroppedImage = (sourceImage, cropConfig, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");
    //
    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is Empty"));
          return;
        }
        blob.name = fileName;
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const imageBlob = reader.result.split(",")[1];
          setCropImageBlob(imageBlob);
        };
        const croppedImageUrl = window.URL.createObjectURL(blob);
        resolve(croppedImageUrl);
      }, "image/jpeg");
    });
  };

  const updateProfileImage = async () => {
    updateImage(cropImageBlob);
  };

  return (
    <Dialog open={show} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
      <Grid className={classes.profileUploadwrapper}>
        <div className={classes.profileUploadCRWrap}>
          <div className={classes.profileUploadInner}>
            <input
              className={classes.profileUploadCSinput}
              type="file"
              accept="image/*"
              onChange={onSelectedFile}
            />
            <div className={classes.profileUploadCSwrap}>
              <div className={classes.profileUploadSIconWrap}>
                <CloudUploadIcon className={classes.profileUploadSIcon} />
              </div>
              <Button variant="contained" color="primary">
                Upload Picture
              </Button>
              {selectedFile ? (
                <p className={classes.profileUploadtext}>Uploaded File</p>
              ) : (
                <p className={classes.profileUploadtext}>
                  Upload your file here...
                </p>
              )}
              {selectedFile && (
                <>
                  <span className={classes.profileUploadCSFilename}>
                    {selectedFile?.name}
                  </span>
                  {isVirusCheckingAvailable == 1 && (
                    <>
                      {isVirusFile ? (
                        <Tooltip title="Virus Found In File" placement="right">
                          <CoronavirusIcon color="error" />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Virus Not Found In File"
                          placement="right"
                        >
                          <VerifiedIcon color="success" />
                        </Tooltip>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {croppedImage && (
            <div className={classes.profileUploadCropPreviewWrapper}>
              <h2 className={classes.profileUploadCropPreviewTitle}>
                Picture Preview
              </h2>
              <img alt="image" src={croppedImage} />
            </div>
          )}
        </div>
      </Grid>
      {imageToCrop && (
        <>
          <ReactCrop
            crop={crop}
            ruleOfThirds
            style={{ width: "500px", margin: "0px auto", marginBottom: "25px" }}
            onComplete={(crop) => cropImage(crop)}
            onChange={(crop) => setCrop(crop)}
            minHeight={175}
            minWidth={175}
            maxHeight={175}
            maxWidth={175}
          >
            <img alt="image" ref={imageRef} src={imageToCrop} />
          </ReactCrop>
        </>
      )}
      {croppedImage && (
        <Button
          type="button"
          color="primary"
          variant="contained"
          style={{
            width: "150px",
            margin: "0px auto",
            marginBottom: "30px",
          }}
          onClick={updateProfileImage}
        >
          UPDATE
        </Button>
      )}
    </Dialog>
  );
};

UploadPhoto.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  updateImage: PropTypes.func,
};

export default UploadPhoto;
