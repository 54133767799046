import { Box, Checkbox, Divider, Typography } from "@mui/material";
import Spinner from "_components/Spinner";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NoDataCard from "_components/MasterAssessmentComp/component/DashboardPanel/components/Graphs/NoDataCard";
import { EditableFilenameRow } from "./EditableFilenameRow";
import { InsertDriveFileTwoTone } from "@mui/icons-material";
import { GetDocumentsForRegulation } from "./services";
import sessionService from "_services/session.service";
import { useModal } from "_hooks";
import BatchUploadDialog from "./BatchUploadDialog";
import { Button } from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { uniqBy } from "lodash";
import { VariableSizeList } from "react-window";
import { FilterMenu } from "./FilterMenu";
import ArticleIcon from "@mui/icons-material/Article";
import AutoSizer from "react-virtualized-auto-sizer";
import { Row, Item, innerElementType } from "./DraggableRow";
import uploadDocuments from "./services";
import { alertActions } from "_actions";
import { useWindowResize } from "./userWindowResize";
import { v4 as uuidv4 } from "uuid";
import FileTable from "./FileTable";

const styles = {
  docWrapper: {
    display: "flex",
    gap: 2,
    flexDirection: "column",
    border: "1px solid #0000001f",
    borderRadius: 1,
    flex: 1,
    p: 1,
    overflowY: "hidden",
  },
  contentWrapper: { display: "flex", gap: 2, flex: "auto", p: 1.5 },
  scrollbleListWrapper: {
    display: "flex",
    gap: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    overflow: "auto",
    height: 450,
  },
};

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "white",
});

const droppableIds = {
  FIRST: "1",
  SECOND: "2",
};

const getFilterOptions = (list = [], key = "") => {
  if (!list?.length || !key) return;
  list = list?.filter((i) => i?.[key]);
  return (
    uniqBy(list, key)?.map((item) => ({
      name: item?.[key],
      value: item?.[key]?.trim()?.toLowerCase(),
    })) ?? []
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const UploadDocuments = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const regulationDetails = useSelector(
    (state) => state.manageQuestions.regulationDetail
  );
  const customerID = sessionService.getCustomerId();
  const uploadFilesModal = useModal();
  const [filesForUpload, setFilesForUpload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requiredFiles, setRequiredFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [isPolicyFilter, setIsPolicyFilter] = useState(true);
  const [activeFilters, setActiveFilters] = useState();

  const listRef = useRef({});
  const sizeMap = useRef({});
  const rowHeights = useRef({});

  const setSize = React.useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);
  const getSize = (index) => sizeMap?.current?.[index] || 50;
  const [windowWidth] = useWindowResize();

  const filteredRegulationDocs = useMemo(() => {
    // filter all flaged items
  }, [isPolicyFilter]);

  const filterOptions = useMemo(() => {
    setFilteredFiles(requiredFiles);
    return {
      article: getFilterOptions(requiredFiles, "ARTICLE") ?? [],
      section: getFilterOptions(requiredFiles, "SECTION") ?? [],
    };
  }, [requiredFiles]);

  const fetchCustomerRegulationPolicyUploaded = async () => {
    const response = await uploadDocuments.getCustomerRegulationPolicyUploaded(
      customerID,
      id
    );
    if (response?.status === 200) {
      setFilesForUpload(
        response["#result-set-1"]?.map((item, index) => ({
          ...item,
          uuid: uuidv4(),
          no: index + 1,
          children: [],
        }))
      );
    } else {
      dispatch(alertActions.error("Something went wrong"));
    }
    return response;
  };

  useEffect(() => {
    dispatch(
      GetDocumentsForRegulation(id, customerID, setRequiredFiles, setIsLoading)
    );
    fetchCustomerRegulationPolicyUploaded();
  }, []);

  function onDragEnd(result) {
    const source = result.source;
    const destination = result.destination;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    if (sInd === dInd) {
      return;
    } else {
      if (droppableIds.FIRST === dInd) {
        return;
      }

      if (droppableIds.FIRST === sInd) {
        // push the element into list for ltr drop
        const sourceElement = requiredFiles?.find(
          (item) => item.uuid === result.draggableId
        );
        const destinationElement = filesForUpload?.find(
          (item) => item.uuid === dInd
        );

        Object.defineProperty(sourceElement, "location", {
          value: "right",
          writable: true,
        });
        setFilteredFiles(
          filteredFiles?.filter((i) => i?.uuid !== result.draggableId)
        );
        destinationElement.children.push(sourceElement);
      } else {
        // remove and push the element into list for rtr drop
        const sourceParentElement = filesForUpload?.find(
          (item) => item.uuid === sInd
        );
        const sourceElement = requiredFiles?.find(
          (item) => item.uuid === result.draggableId
        );
        const destinationElement = filesForUpload?.find(
          (item) => item.uuid === dInd
        );
        sourceParentElement.children = sourceParentElement.children.filter(
          (item) => item.uuid !== result.draggableId
        );

        Object.defineProperty(sourceElement, "location", {
          value: "right",
          writable: true,
        });
        setFilteredFiles(
          filteredFiles?.filter((i) => i?.uuid !== result.draggableId)
        );
        destinationElement.children.push(sourceElement);
      }
    }
  }

  const onDeleteMapping = (params) => {};

  const onUpdateMappings = (params) => {};

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {uploadFilesModal?.isOpen && (
        <BatchUploadDialog
          modal={uploadFilesModal}
          setFilesForUpload={setFilesForUpload}
          filesForUpload={filesForUpload}
        />
      )}
      <Box p={1.5} display="flex" flex="auto">
        <Box sx={{ m: 0, p: 0, flex: 1, color: "#9d9d9d" }}>
          Upload Documents for{" "}
          <span
            style={{
              fontStyle: "italic",
              textDecoration: "underline",
              color: "#000",
            }}
          >
            {regulationDetails?.REGULATION_NAME || "Regulation Name"}
          </span>
        </Box>
        <Button
          style={{ margin: "auto" }}
          variant="contained"
          color="primary"
          onClick={uploadFilesModal?.openModal}
        >
          Upload
        </Button>
      </Box>

      <Box sx={styles.contentWrapper}>
        <Box sx={styles.docWrapper}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography mr={2}>Documents Required</Typography>
            <Divider flexItem orientation="vertical" />
            <Box display="flex" gap={0.25}>
              <FilterMenu
                dataList={requiredFiles}
                tooltipTitle="Apply Article Filter"
                filterOptions={filterOptions?.article}
                setDataList={setFilteredFiles}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                icon={<ArticleIcon />}
                dataVariable="ARTICLE"
                filesForUpload={filesForUpload}
              />
              <FilterMenu
                dataList={requiredFiles}
                tooltipTitle="Apply Section Filter"
                filterOptions={filterOptions?.section}
                setDataList={setFilteredFiles}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                dataVariable="SECTION"
                filesForUpload={filesForUpload}
              />
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box alignSelf="flex-end" flex={1}>
              <Checkbox
                id="checkbox-filter"
                checked={isPolicyFilter}
                onChange={() => setIsPolicyFilter(!isPolicyFilter)}
              />
              <Typography
                fontSize={12}
                htmlFor="checkbox-filter"
                component="label"
              >
                Show only Policy, Procedures, Processes, Controls
              </Typography>
            </Box>
          </Box>

          <Divider flexItem />
          {isLoading ? (
            <Spinner />
          ) : filteredFiles?.length > 0 ? (
            <>
              <Box
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  display: "inline-flex",
                  gap: 2,
                  alignItems: "center",
                  p: 0.5,
                  px: 1,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>No.</Typography>
                <Typography
                  sx={{
                    flex: 1,
                    fontWeight: "bold",
                  }}
                >
                  Document Recommendation ({filteredFiles?.length} Records)
                </Typography>
              </Box>
              <Droppable
                droppableId={droppableIds.FIRST}
                mode="virtual"
                renderClone={(provided, snapshot, rubric) => (
                  <Item
                    provided={provided}
                    isDragging={snapshot.isDragging}
                    item={requiredFiles?.find(
                      (item) => item?.uuid === rubric.draggableId
                    )}
                  />
                )}
              >
                {(provided, snapshot) => (
                  <AutoSizer style={getListStyle(snapshot.isDraggingOver)}>
                    {({ height, width }) => (
                      <VariableSizeList
                        ref={listRef}
                        height={height}
                        itemCount={filteredFiles?.length}
                        itemSize={getSize}
                        width={width}
                        itemData={{
                          items: filteredFiles,
                          setSize: setSize,
                          windowWidth: windowWidth,
                        }}
                        outerRef={provided.innerRef}
                        innerElementType={innerElementType}
                      >
                        {Row}
                      </VariableSizeList>
                    )}
                  </AutoSizer>
                )}
              </Droppable>
            </>
          ) : (
            <Typography>No Documents Found</Typography>
          )}
        </Box>
        <Box sx={styles.docWrapper}>
          <Typography>Documents Uploaded</Typography>
          {/* <Box
            sx={{
              border: "1px solid #0000001f",
              borderRadius: 1,
              display: "inline-flex",
              gap: 2,
              alignItems: "center",
              p: 0.5,
              px: 1,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>No.</Typography>

            <Typography
              sx={{
                flex: 1,
                fontWeight: "bold",
              }}
            >
              Document Name
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>Size</Typography>
            <Typography sx={{ fontWeight: "bold" }}>Docs Mapped</Typography>
            <Typography sx={{ fontWeight: "bold" }}>Recommendations</Typography>
          </Box> */}

          <Box sx={styles.scrollbleListWrapper} background="#F0F4F8">
            {filesForUpload?.length ? (
              // filesForUpload?.map((item, index) => (
              //   <Droppable
              //     key={item?.uuid}
              //     droppableId={item.uuid}
              //     renderClone={(provided, snapshot, rubric) => {
              //       return (
              //         <Item
              //           provided={provided}
              //           isDragging={snapshot.isDragging}
              //           item={requiredFiles?.find(
              //             (item) => item?.uuid === rubric.draggableId
              //           )}
              //         />
              //       );
              //     }}
              //   >
              //     {(provided, snapshot) => (
              //       <Box
              //         ref={provided.innerRef}
              //         {...provided.droppableProps}
              //         style={getListStyle(snapshot.isDraggingOver)}
              //       >
              //         <EditableFilenameRow
              //           file={Object.assign(item, { id: `${index + 1}.` })}
              //           setFilteredFiles={setFilteredFiles}
              //         />
              //         {provided.placeholder}
              //       </Box>
              //     )}
              //   </Droppable>
              // ))
              <FileTable
                files={filesForUpload}
                setFilteredFiles={setFilteredFiles}
                requiredFiles={requiredFiles}
              />
            ) : (
              <NoDataCard
                Icon={InsertDriveFileTwoTone}
                message="No files selected"
                textColor="gray"
                iconProps={{ sx: { fontSize: 10 } }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ p: 1.5, justifyContent: "flex-start", display: "flex", gap: 2 }}
      >
        <Button variant="contained" color="primary">
          Initiate Matching
        </Button>
        <Button disabled variant="contained" color="primary">
          Save Mappings
        </Button>
      </Box>
    </DragDropContext>
  );
};

export default UploadDocuments;
