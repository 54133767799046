// OP#2801 UJL3 - UI Dev - Manage User Profile
// file stands for handling editProfile and photoEdit

import React, { useEffect, useState } from "react";
import EditProfile from "./component/EditProfile";
import PhotoEdit from "./component/PhotoEdit";
import userServices from "./services";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "../../../_actions";
import langConstant from "_lang";
import PropTypes from "prop-types";

const MyProfilePopup = ({
  show,
  setShow,
  user_ID,
  isTeamView,
  twoFADetail,
  setTwoFADetail,
  notificationPreferences,
  setNotificationPreferences,
}) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [domainData, setDomainData] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [profileDialog, setProfileDialog] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const domainList = domainData.map((domain) => {
    return {
      value: domain.REGULATORY_DOMAIN_ID,
      label: domain.REGULATION_DOMAIN,
    };
  });

  useEffect(() => {
    (async () => {
      dispatch(loadingActions.start());
      const response = await userServices.getTeamUserProfileInfo(user_ID);
      dispatch(loadingActions.end());
      if (response) {
        if (response.status === 200) {
          setNotificationPreferences(response["#result-set-3"]);
          setTwoFADetail(response["#result-set-4"]);
          if (response["#result-set-1"].length) {
            setUserData(response["#result-set-1"][0]);

            if (response["#result-set-5"].length) {
              const selectedDomainIdList = [];
              const userSelectedDomainsLabel = [];
              const selectedUserDomains = response["#result-set-5"];
              selectedUserDomains.forEach((selectedDomain) => {
                selectedDomainIdList.push(selectedDomain.REGULATORY_DOMAIN_ID);
                userSelectedDomainsLabel.push(selectedDomain.REGULATION_DOMAIN);
              });

              setUserData((prevState) => ({
                ...prevState,
                REGULATION_DOMAIN: userSelectedDomainsLabel.join(", "),
                REGULATORY_DOMAIN_ID: selectedDomainIdList,
              }));
            }

            response["#result-set-1"][0].AVATAR_IMAGE
              ? setUserProfile(
                  "data:image/png;base64," +
                    window.atob(response["#result-set-1"][0].AVATAR_IMAGE)
                )
              : setUserProfile(null);
            if (response["#result-set-2"].length) {
              setCountryData(
                response["#result-set-2"].map((country) => {
                  return {
                    value: country.COUNTRY_CODE,
                    label: country.COUNTRY_SHORT_NAME,
                  };
                })
              );
            }
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
          dispatch(loadingActions.start());
          const domainResponse = await userServices.getDomainList();
          dispatch(loadingActions.end());
          if (domainResponse) {
            if (domainResponse && domainResponse["#result-set-1"]) {
              setDomainData(domainResponse["#result-set-1"]);
            }
          } else {
            if (domainResponse.data && domainResponse.data.message) {
              dispatch(
                alertActions.error(
                  domainResponse.data.message.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })
                )
              );
            } else {
              dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
            }
          }
        } else {
          if (response.data && response.data.message) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        }
      }
    })();
  }, [user_ID]);

  return (
    <>
      {show && Object.keys(userData).length > 0 && (
        <EditProfile
          userData={userData}
          show={show}
          setShow={setShow}
          profilePic={userProfile}
          setProfileDialog={setProfileDialog}
          countryData={countryData}
          setUserData={setUserData}
          user_ID={user_ID}
          isTeamView={isTeamView}
          domainList={domainList}
          notificationPreferences={notificationPreferences}
          twoFADetail={twoFADetail}
          setNotificationPreferences={setNotificationPreferences}
          setTwoFADetail={setTwoFADetail}
        />
      )}
      {profileDialog && Object.keys(userData).length > 0 && (
        <PhotoEdit
          show={profileDialog}
          setShow={setProfileDialog}
          setUserProfile={setUserProfile}
          user_ID={user_ID}
        />
      )}
    </>
  );
};

MyProfilePopup.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  user_ID: PropTypes.number,
  isTeamView: PropTypes.bool,
  twoFADetail: PropTypes.array,
  setTwoFADetail: PropTypes.func,
  notificationPreferences: PropTypes.array,
  setNotificationPreferences: PropTypes.func,
};

export default MyProfilePopup;
