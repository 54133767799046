import {
  Button,
  Box,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import PropTypes from "prop-types";
import Select from "react-select";
import OptionComponent from "./OptionComponent";
import AppPagination from "_components/AppPagination";
import langConstant from "_lang";
import SelectPageRange from "_components/SelectPageRange";
import { scrollToTop } from "_helpers/utils";
import SearchIcon from "@material-ui/icons/Search";
import PolicyTextComponent from "./PolicyTextComponent";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileNamePopUp from "./fileNamePopUp";
import { putPolicyComparison } from "../../../PolicyExplorer/component/ExecuteComparisonPopUp/services";
import sessionService from "_services/session.service";
import moment from "moment";
import comparisonRecipieService from "../services";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import DocumentDrawer from "./documentDrawer";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

const InsightTable = ({
  basePolicyText,
  targetPolicyText,
  comparisonInfo,
  setBasePolicyText,
  documentDetails,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedBaseText, setSelectedBaseText] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filters, setFilters] = useState({ baseText: "" });
  const [isDetailedView, setIsDetailedView] = useState(false);
  const [isNamePopUp, setIsNamePopUp] = useState(false);
  const [documentDrawer, setDocumentDrawer] = useState(false);

  const calculatePageCount = (basePolicyText) => {
    return basePolicyText.length > 0
      ? Math.ceil(Math.min(basePolicyText.length / rowsPerPage))
      : 0;
  };
  const [pageCount, setPageCount] = useState(
    calculatePageCount(basePolicyText)
  );

  const handleChangePage = (event) => {
    setPage(event);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredRows.length > 0
        ? Math.round(Math.ceil(filteredRows.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  useEffect(() => {
    if (basePolicyText.length > 0) {
      let filteredBaseText = [...basePolicyText];
      if (filters.baseText) {
        filteredBaseText = filteredBaseText.filter((text) =>
          text.NAME.toLowerCase().includes(filters.baseText.toLowerCase())
        );
      }
      setFilteredRows(filteredBaseText);
      setPageCount(calculatePageCount(filteredBaseText));
    }
  }, [basePolicyText, filters, page]);

  const resetFilter = () => {
    setFilters({ baseText: "" });
  };

  const columns = [
    { label: "BASE DOCUMENT" },
    { label: "" },
    { label: "TARGET DOCUMENT" },
  ];

  const updateSelectedComparison = (selectedItem, text) => {
    setSelectedBaseText({
      ...selectedItem,
      selectedComparison: [...text],
      isSelectedUpdated: true,
    });
    let tempBasePolicyText = [...basePolicyText];
    const textIndex = tempBasePolicyText.findIndex(
      (item) =>
        item.POLICY_EXTRACTION_DETAILS_ID ===
        selectedItem.POLICY_EXTRACTION_DETAILS_ID
    );
    if (textIndex != -1) {
      tempBasePolicyText[textIndex]["isSelectedUpdated"] = true;
      tempBasePolicyText[textIndex]["selectedComparison"] = [...text];
      setBasePolicyText(tempBasePolicyText);
    }
  };

  const saveNewComparisonRecipie = async (values) => {
    const comparisonMetaData = comparisonInfo.COMPARISON_META_DATA
      ? JSON.parse(window.atob(comparisonInfo.COMPARISON_META_DATA))
      : "";
    const data = {
      basePolicyId: comparisonInfo.BASE_POLICY_ID,
      targetPolicyId: comparisonInfo.TARGET_POLICY_ID,
      comparisonName: values.name.trim(),
      userID: userID,
      currentDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      comparisonMetaData: { language: comparisonMetaData?.language },
      customerID: customerID,
    };
    let selectedComparisonId = [];
    basePolicyText.forEach((element) => {
      element.selectedComparison.forEach((comparison) => {
        comparison.selectedIDs.forEach((selectedId) => {
          if (!selectedComparisonId.includes(selectedId)) {
            selectedComparisonId.push(selectedId);
          }
        });
      });
    });
    let selectedComparisonString = selectedComparisonId.join(",");
    dispatch(loadingActions.start());
    const response = await putPolicyComparison(data);
    dispatch(loadingActions.end());
    if (response.status == 200) {
      const recipiePayload = {
        oldComparisonId: id,
        newComparisonId: response["#result-set-1"][0]["new_id"],
        selectedComparisonId: selectedComparisonString,
      };
      dispatch(loadingActions.start());
      const recipieResponse =
        await comparisonRecipieService.putPolicyComparisionReceipes(
          recipiePayload
        );
      dispatch(loadingActions.end());
      if (recipieResponse.status == 200) {
        history.push("/policy-comparison-analysis");
      } else {
        if (recipieResponse.data && recipieResponse.data.message) {
          dispatch(
            alertActions.error(
              recipieResponse.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  const getCount = () => {
    var total = 0;
    filteredRows.forEach((item) => {
      item.selectedComparison.forEach((comparison) => {
        total += comparison.recordCount;
      });
    });
    return total;
  };

  return (
    <Grid style={{ paddingTop: "20px" }}>
      <Button
        className={classes.docScannerBtn}
        onClick={() => setDocumentDrawer(!documentDrawer)}
      >
        <DocumentScannerIcon />
      </Button>
      <Typography className={classes.pageHeader}>
        {langConstant.COMPARISON_RECIPE}
      </Typography>
      <Grid container style={{ alignItems: "center", padding: "15px 0" }}>
        <Grid item md={4} xs={12}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            id="searchText"
            value={filters.baseText}
            name="searchText"
            placeholder={langConstant.SEARCH_BASE_POLICY_HEADINGS}
            onChange={(e) =>
              setFilters((prevState) => ({
                ...prevState,
                baseText: e.target.value,
              }))
            }
            InputProps={{
              autoComplete: "off",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={4} xs={12} container justify="flex-start">
          <Box mx={1} mt={1}>
            {filters["baseText"] && (
              <span onClick={resetFilter} className="linkIcon">
                {langConstant.RESET_ALL}
              </span>
            )}
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box my={2}>
            <Grid container justify="flex-end">
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "30px",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                }}
              >
                {getCount()}
              </span>
              {filteredRows.length > 5 && (
                <SelectPageRange
                  selectedRange={rowsPerPage}
                  handleChange={handleSelectPageRange}
                />
              )}
              {pageCount > 1 && (
                <AppPagination
                  colSpan={3}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className="data-table">
          <TableHead>
            <TableRow>
              {columns.map((column, columnIndex) => {
                return (
                  <TableCell
                    key={columnIndex}
                    component="th"
                    scope="row"
                    className={classes.stickyColl}
                    style={{ textAlign: "center" }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ textAlign: "center", width: "50%" }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", paddingBottom: "10px" }}
                    >
                      {item["NAME"]}
                    </Typography>
                    {selectedBaseText &&
                      isDetailedView &&
                      item.POLICY_EXTRACTION_DETAILS_ID ===
                        selectedBaseText?.POLICY_EXTRACTION_DETAILS_ID && (
                        <PolicyTextComponent
                          policyType="base"
                          selectedBaseText={selectedBaseText}
                          policyText={basePolicyText}
                        />
                      )}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    className={classes.eyeIcon}
                  >
                    {isDetailedView &&
                    selectedBaseText.POLICY_EXTRACTION_DETAILS_ID ===
                      item.POLICY_EXTRACTION_DETAILS_ID ? (
                      <VisibilityOffIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedBaseText(null);
                          setIsDetailedView(false);
                        }}
                      />
                    ) : (
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedBaseText(item);
                          setIsDetailedView(true);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ textAlign: "center", width: "50%" }}
                  >
                    <Select
                      options={item.options}
                      className={classes.selctPadding}
                      isMulti
                      components={{ Option: OptionComponent }}
                      closeMenuOnSelect={false}
                      close
                      hideSelectedOptions={false}
                      allowSelectAll={true}
                      onFocus={() => setSelectedBaseText(item)}
                      value={
                        selectedBaseText?.POLICY_EXTRACTION_DETAILS_ID ===
                        item["POLICY_EXTRACTION_DETAILS_ID"]
                          ? selectedBaseText.selectedComparison
                          : item.selectedComparison
                      }
                      onChange={(text) => updateSelectedComparison(item, text)}
                      menuPortalTarget={document.body}
                    />
                    {selectedBaseText &&
                      isDetailedView &&
                      item.POLICY_EXTRACTION_DETAILS_ID ===
                        selectedBaseText?.POLICY_EXTRACTION_DETAILS_ID && (
                        <PolicyTextComponent
                          policyType="target"
                          selectedBaseText={selectedBaseText}
                          policyText={targetPolicyText}
                        />
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justify="flex-end" style={{ marginTop: "20px" }}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          style={{ width: "150px" }}
          onClick={() => setIsNamePopUp(true)}
        >
          {langConstant.SAVE}
        </Button>
      </Grid>
      {documentDrawer && (
        <DocumentDrawer
          show={documentDrawer}
          setShow={setDocumentDrawer}
          documentDetails={documentDetails}
        />
      )}
      {isNamePopUp && (
        <FileNamePopUp
          isOpen={isNamePopUp}
          setIsOpen={setIsNamePopUp}
          saveNewComparisonRecipie={saveNewComparisonRecipie}
        />
      )}
    </Grid>
  );
};

InsightTable.propTypes = {
  basePolicyText: PropTypes.array.isRequired,
  targetPolicyText: PropTypes.array.isRequired,
  comparisonInfo: PropTypes.object.isRequired,
  setBasePolicyText: PropTypes.func.isRequired,
  documentDetails: PropTypes.object.isRequired,
};

export default InsightTable;
