/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import TableauReport from "tableau-react";
import TableauServices from "_services/tableau.service";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { alertActions } from "_actions";

// const options = {
//     height: 1000,
//     width: 1050,
//     hideTabs: false,
//     // All other vizCreate options are supported here, too
//     // They are listed here: https://onlinehelp.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#ref_head_9
//   };

//   const filters = {
//     Colors: ["Blue", "Red"],
//     Sizes: ["Small", "Medium"],
//   };

//   const parameters = {
//     Param1: "Value",
//     Param2: "Other Value",
//   };

const MasterTableauReport = ({ slug, parameters = {} }) => {
  const dispatch = useDispatch();
  const [keysData, setKeysData] = useState(null);
  const [DefaultUrl, setDefaultUrl] = useState(null);
  const [tableauParameters, setTableauParameters] = useState({});
  const username = useSelector(
    (state) => state.authentication.detailedInfo?.USER_NAME
  );
  const reportingUrl = useSelector(
    (state) => state?.authentication?.detailedInfo?.REPORTING_URL
  );
  const TableauDefaultObjArray = useSelector(
    (state) => state?.authentication?.detailedInfo?.TABLEAU_DEFAULT_DASHBOARDS
  );

  const options = {
    height: 800,
    width: "100%",
  };

  useEffect(() => {
    if (TableauDefaultObjArray?.length > 0) {
      let tempTableauParameters = {};
      const Urlobj = TableauDefaultObjArray.find(
        (el) => el.ENTITLEMENT_NAME.trim() === slug
      );
      if (Urlobj !== undefined && Urlobj.DASHBOARD_URL) {
        setDefaultUrl(Urlobj["DASHBOARD_URL"]);
        if (Urlobj.Parameter) {
          if (Urlobj.Parameter.includes("@USER_ID")) {
            tempTableauParameters["UserId"] = parameters?.UserId;
          }
          if (Urlobj.Parameter.includes("@CUSTOMER_ID")) {
            tempTableauParameters["CustomerId"] = parameters?.CustomerId;
          }
        }
        setTableauParameters(tempTableauParameters);
      } else {
        dispatch(
          alertActions.error(
            "Tableau Default Url Error, URL = " + Urlobj?.DASHBOARD_URL
          )
        );
      }
    }
  }, [TableauDefaultObjArray, slug]);

  useEffect(() => {
    if (username) {
      dispatch(TableauServices.getTableauToken(setKeysData));
    }
  }, [username]);

  return (
    <Grid>
      {keysData && DefaultUrl !== null && (
        <TableauReport
          url={reportingUrl + DefaultUrl}
          token={keysData}
          options={options}
          parameters={tableauParameters}
          query="?:embed=yes&:comments=no&:toolbar=no&:refresh=yes"
        />
      )}
    </Grid>
  );
};

MasterTableauReport.propTypes = {
  slug: PropTypes.string,
  parameters: PropTypes.object,
};

export default MasterTableauReport;
