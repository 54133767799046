import { AuditConstants } from "./constants"

const INITIAL_STATE = {
    AuditAssessmentDetails: [],
    ObservationDetails: [],
    TrustScoreForAllQuestion: []
}

export default function auditReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AuditConstants.SET_AUDIT_QUESTIONS:
            return {
                ...state,
                AuditAssessmentDetails: action.payload
            };
        case AuditConstants.SET_AUDIT_OBSERVATIONS:
            return {
                ...state,
                ObservationDetails: action.payload
            };
        case AuditConstants.SET_AUDTI_TRUST_SCORE_ALL_QUESTIONS:
            return {
                ...state,
                TrustScoreForAllQuestion: action.payload,
            };
        case AuditConstants.AUDIT_CLEANUP:
            return {
                ...state,
                ObservationDetails: [],
                AuditAssessmentDetails: [],
                TrustScoreForAllQuestion: []
            };
        default:
            return state;
    }
}