import { AuditActionConstants } from "./constants";

const initialState = { actionList: [], actionListDetails: [], userList: [] };

export default function auditActionReducer(state = initialState, action) {
  switch (action.type) {
    case AuditActionConstants.SET_AUDIT_ACTION_LIST:
      return {
        ...state,
        actionList: action.payload,
      };
    case AuditActionConstants.SET_ACTION_LIST_DETAILS:
      return {
        ...state,
        actionListDetails: action.payload,
      };
    case AuditActionConstants.SET_USERS_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    case AuditActionConstants.AUDIT_ACTIONS_CLEANUP:
      return initialState;
    default:
      return state;
  }
}
