import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { IconButton, Tooltip } from "@mui/material";
import langConstant from "_lang";
import EditIcon from "@mui/icons-material/Edit";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useDispatch, useSelector } from "react-redux";
import { baseTextObj, categoryWiseData } from "../utils";
import * as yup from "yup";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import useStyles from "../../styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { secondStepHelperText } from "../utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  decreaseActiveStep,
  increaseActiveStep,
} from "_components/Stepper/action";
import { updateBackView, updateProcessedView } from "../../actions";
import StepperServices from "../../services";
import sessionService from "_services/session.service";
import { alertActions, loadingActions } from "_actions";
import CompareFileView from "./compareFileView";
import { CircularProgress } from "@mui/material";
import HelperTextComponent from "_components/HelpText";

const ExtractedContent = ({
  predictionFromProcessed,
  closeDocumentUploadPopUp,
  setCurrentStep,
  compareFileDialog,
  setCompareFileDialog,
  setPredictionFromProcessed,
  setCurrentUrl,
  isProgressView,
  setProgressLogsData,
  setIsProgressDialog,
  setIsProgressView,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user_ID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const predictedTextData = useSelector(
    (state) => state.uploadPolicyReducer.predictedText
  );
  const startPage = useSelector(
    (state) => state.uploadPolicyReducer.step1.pages_extraction_start
  );
  const endPage = useSelector(
    (state) => state.uploadPolicyReducer.step1.pages_extraction_end
  );
  const documentInfo = useSelector(
    (state) => state.uploadPolicyReducer.documentInfo
  );
  const step1FormData = useSelector((state) => state.uploadPolicyReducer.step1);
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [groupByCategory, setGroupByCategory] = useState({
    ...categoryWiseData,
  });
  const [categoryOptions, setCategoryOptions] = useState({
    ...categoryWiseData,
  });
  const [predictionFormData, setPredictionFormData] = useState({
    "Page-header": { ...baseTextObj },
    "Page-footer": { ...baseTextObj },
    Text: { ...baseTextObj },
    "Section-header": { ...baseTextObj, subCategory: [] },
  });

  const commanRowValidation = {
    text: yup.string().required("required"),
    pageNo: yup
      .number()
      .required("required")
      .positive("Number must be greater than 0"),
  };

  const validationSchema = yup.object({
    Text: yup.object().shape({ ...commanRowValidation }),
    "Section-header": yup.object().shape(
      {
        text: yup
          .string()
          .ensure()
          .when("pageNo", {
            is: (pageNo) => pageNo,
            then: yup.string().required("required"),
          }),
        pageNo: yup
          .string()
          .ensure()
          .when("text", {
            is: (text) => text,
            then: yup
              .string()
              .required("required")
              .test(
                "Is positive?",
                "Number must be greater than 0",
                (value) => parseInt(value) > 0
              ),
          }),
        subCategory: yup.array().of(
          yup.object().shape({
            ...commanRowValidation,
          })
        ),
      },
      [["text", "pageNo"]]
    ),
  });

  const formField = ["Page-footer", "Page-header", "Section-header", "Text"];

  useEffect(() => {
    let predictedText = [];
    if (predictionFromProcessed) {
      dispatch(updateProcessedView(true));
      predictedText = [...predictedTextData.processed];
    } else {
      dispatch(updateProcessedView(false));
      predictedText = [...predictedTextData.original];
    }
    if (predictedText.length > 0) {
      let tempCategoryWiseData = {
        "Page-footer": [],
        "Page-header": [],
        "Section-header": [],
        Text: [],
      };
      let tempCategoryOptions = {
        "Page-footer": [],
        "Page-header": [],
        "Section-header": [],
        Text: [],
      };
      predictedText.forEach((element) => {
        if (tempCategoryWiseData[element[1]]) {
          tempCategoryWiseData[element[1]].push(element);
        }
        if (tempCategoryOptions[element[1]]) {
          tempCategoryOptions[element[1]].push({
            label: element[0],
            value: element[0],
          });
        }
      });
      setPredictionFormData({
        "Page-header":
          tempCategoryWiseData["Page-header"].length > 0
            ? {
                text: tempCategoryWiseData["Page-header"][0][0],
                pageNo: tempCategoryWiseData["Page-header"][0][2],
                isEdit: false,
                selectedText: tempCategoryWiseData["Page-header"][0][0],
              }
            : { ...baseTextObj },
        "Page-footer":
          tempCategoryWiseData["Page-footer"].length > 0
            ? {
                text: tempCategoryWiseData["Page-footer"][0][0],
                pageNo: tempCategoryWiseData["Page-footer"][0][2],
                isEdit: false,
                selectedText: tempCategoryWiseData["Page-footer"][0][0],
              }
            : { ...baseTextObj },
        Text:
          tempCategoryWiseData["Text"].length > 0
            ? {
                text: tempCategoryWiseData["Text"][0][0],
                pageNo: tempCategoryWiseData["Text"][0][2],
                isEdit: false,
                selectedText: tempCategoryWiseData["Text"][0][0],
              }
            : { ...baseTextObj },
        "Section-header":
          tempCategoryWiseData["Section-header"].length > 0
            ? {
                text: tempCategoryWiseData["Section-header"][0][0],
                pageNo: tempCategoryWiseData["Section-header"][0][2],
                isEdit: false,
                selectedText: tempCategoryWiseData["Section-header"][0][0],
                subCategory: [],
              }
            : { ...baseTextObj, subCategory: [] },
      });
      setGroupByCategory(tempCategoryWiseData);
      setCategoryOptions(tempCategoryOptions);
    }
  }, [predictionFromProcessed, predictedTextData]);

  const updateUserProgress = async (policyId) => {
    const userProgressResponse = await StepperServices.getUserProgress(
      policyId
    );
    setProgressLogsData(userProgressResponse["#result-set-1"]);
  };

  const SubmitData = async (values) => {
    values["policyId"] = documentInfo.policyId;
    values["alias"] = step1FormData.document_name;
    values["filename"] = predictionFromProcessed
      ? documentInfo.processed.name
      : documentInfo.original.name;
    values["documentLanguage"] = JSON.stringify(
      step1FormData.document_language
    );
    values["startPage"] = step1FormData.pages_extraction_start;
    values["endPage"] = step1FormData.pages_extraction_end;
    values["page-footer"] = JSON.stringify(values["Page-footer"]);
    values["page-header"] = JSON.stringify(values["Page-header"]);
    values["section-header"] = JSON.stringify(values["Section-header"]);
    values["text"] = JSON.stringify(values["Text"]);
    values["userId"] = user_ID;
    values["customerID"] = customerID;
    values["storageType"] = docStorageType.toLowerCase();
    values["storePreProcessingIP"] = docStorageIP;
    values["storePreProcessingURL"] = docStorageURL;
    setProgressLogsData([]);
    const userProgressTimer = setInterval(async function () {
      await updateUserProgress(documentInfo.policyId);
    }, 5000);
    setIsProgressDialog(true);
    setIsProgressView(true);
    const response = await StepperServices.getExtraction(values);
    clearInterval(userProgressTimer);
    setIsProgressView(false);
    setIsProgressDialog(false);
    if (response.status) {
      await updateUserProgress(documentInfo.policyId);
      if (response.status == 200) {
        dispatch(alertActions.success("Extraction Successfully Done"));
        setCurrentStep(3);
        dispatch(updateBackView(false));
        dispatch(increaseActiveStep());
      } else {
        if (response.message) {
          dispatch(alertActions.error(response.message));
        }
      }
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  const onBackToStep1 = () => {
    setCurrentStep(1);
    dispatch(decreaseActiveStep());
    dispatch(updateBackView(true));
  };

  return (
    <>
      <Grid className={classes.predictionwrapper}>
        <Typography className={classes.textWrapper}>
          {langConstant.SAMPLE_TEXT_FROM_PAGES} {startPage} - {endPage}
        </Typography>
        <Grid>
          <Formik
            initialValues={predictionFormData}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={SubmitData}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Grid className={classes.predictionWrap}>
                  {Object.keys(predictionFormData).map((field, index) => {
                    return (
                      <>
                        {formField.includes(field) && (
                          <Grid key={index}>
                            <Grid
                              container
                              className={classes.wrapGrid}
                              style={{
                                borderBottom:
                                  values["Section-header"].subCategory.length ==
                                    0 && field == "Section-header"
                                    ? "none"
                                    : "1px solid #8683834d",
                              }}
                            >
                              <Grid item md={2} xs={3}>
                                <Typography style={{ display: "inline" }}>
                                  {field}
                                </Typography>
                                <HelperTextComponent
                                  id={secondStepHelperText[field]["id"]}
                                  textData={secondStepHelperText[field]["text"]}
                                />
                              </Grid>
                              <Grid
                                item
                                md={7}
                                xs={5}
                                style={{ padding: "0 10px" }}
                              >
                                <Field name={`${field}.text`}>
                                  {() => (
                                    <TextField
                                      className={classes.profileInputWrapper}
                                      disabled={!values[field].edit}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `${field}.text`,
                                          e.target.value
                                        );
                                        if (
                                          field == "Section-header" &&
                                          e.target.value == ""
                                        ) {
                                          setFieldValue(
                                            `Section-header.subCategory`,
                                            []
                                          );
                                        }
                                      }}
                                      variant="outlined"
                                      InputProps={{
                                        classes: {
                                          input: classes.profileField,
                                          notchedOutline:
                                            values[field].edit ||
                                            categoryOptions[field].length == 0
                                              ? ""
                                              : classes.borderNone,
                                        },
                                      }}
                                      value={values[field].text}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage name={`${field}.text`}>
                                  {(msg) => (
                                    <Typography style={{ color: "red" }}>
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>
                                {categoryOptions[field].length > 0 && (
                                  <Select
                                    className={classes.profileDropdown}
                                    options={categoryOptions[field]}
                                    placeholder={"Select Text Value"}
                                    isDisabled={!values[field].edit}
                                    value={categoryOptions[field].find(
                                      (item) =>
                                        item.value ==
                                        values[field]["selectedText"]
                                    )}
                                    styles={{
                                      menu: (style) => ({
                                        ...style,
                                        zIndex: "5000000",
                                        position: "relative",
                                      }),
                                    }}
                                    onChange={(label) => {
                                      setFieldValue(
                                        `${field}.text`,
                                        label.value
                                      );
                                      setFieldValue(
                                        `${field}.selectedText`,
                                        label.value
                                      );
                                      const findText = groupByCategory[
                                        field
                                      ].find((item) => item[0] == label.value);
                                      setFieldValue(
                                        `${field}.pageNo`,
                                        findText[2]
                                      );
                                    }}
                                    menuPortalTarget={document.body}
                                  />
                                )}
                              </Grid>
                              <Grid
                                item
                                md={1}
                                xs={2}
                                className={classes.wrapGrid}
                              >
                                <Field
                                  style={{ width: "100%" }}
                                  name={`${field}.pageNo`}
                                >
                                  {() => (
                                    <TextField
                                      type="number"
                                      className={classes.profileInputWrapper}
                                      value={values[field].pageNo}
                                      disabled={!values[field].edit}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `${field}.pageNo`,
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                      InputProps={{
                                        classes: {
                                          input: classes.profileField,
                                          notchedOutline:
                                            values[field].edit ||
                                            categoryOptions[field].length == 0
                                              ? ""
                                              : classes.borderNone,
                                        },
                                      }}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage name={`${field}.pageNo`}>
                                  {(msg) => (
                                    <Typography style={{ color: "red" }}>
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>
                              </Grid>
                              <Grid
                                item
                                md={2}
                                xs={2}
                                className={classes.wrapGrid}
                              >
                                {!values[field].edit ? (
                                  <Tooltip title={langConstant.EDIT}>
                                    <IconButton
                                      aria-label={langConstant.EDIT}
                                      color="success"
                                      size="medium"
                                    >
                                      <EditIcon
                                        onClick={() =>
                                          setFieldValue(field + ".edit", true)
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={langConstant.RESET}>
                                    <IconButton
                                      aria-label={langConstant.RESET}
                                      color="error"
                                      size="medium"
                                    >
                                      <RestartAltIcon
                                        onClick={() => {
                                          const initialData =
                                            groupByCategory[field].length > 0
                                              ? groupByCategory[field][0]
                                              : { ...baseTextObj };
                                          setFieldValue(
                                            `${field}.text`,
                                            initialData[0]
                                          );
                                          setFieldValue(
                                            `${field}.selectedText`,
                                            initialData[0]
                                          );
                                          setFieldValue(
                                            `${field}.pageNo`,
                                            initialData[2]
                                          );
                                          setFieldValue(`${field}.edit`, false);
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {field === "Section-header" &&
                                  values["Section-header"]["text"] &&
                                  values["Section-header"].subCategory
                                    .length === 0 && (
                                    <Tooltip
                                      title={langConstant.ADD_SUB_CATEGORY}
                                    >
                                      <IconButton
                                        aria-label={
                                          langConstant.ADD_SUB_CATEGORY
                                        }
                                        color="primary"
                                        size="medium"
                                      >
                                        <AddCircleIcon
                                          onClick={() =>
                                            setFieldValue(
                                              "Section-header.subCategory",
                                              [
                                                {
                                                  id: 0,
                                                  text: "",
                                                  pageNo: "",
                                                  selectedText: "",
                                                },
                                              ]
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </Grid>
                            </Grid>
                            {field === "Section-header" && (
                              <FieldArray
                                name={`Section-header.subCategory`}
                                render={({ remove, push }) => (
                                  <>
                                    {values["Section-header"].subCategory.map(
                                      (subCat, subCatIndex) => (
                                        <Grid
                                          container
                                          key={subCatIndex}
                                          className={classes.subCategoryWrap}
                                        >
                                          <Grid
                                            item
                                            md={7}
                                            xs={6}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <SubdirectoryArrowRightIcon
                                              className={classes.iconArrow}
                                              fontSize="medium"
                                            />
                                            <Grid
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                                padding: "0 15px",
                                              }}
                                            >
                                              <Field
                                                name={`Section-header.subCategory.${subCatIndex}.text`}
                                              >
                                                {() => (
                                                  <TextField
                                                    className={
                                                      classes.subCategoryInputWrapper
                                                    }
                                                    type="text"
                                                    value={subCat.text}
                                                    onChange={(e) =>
                                                      setFieldValue(
                                                        `Section-header.subCategory.${subCatIndex}.text`,
                                                        e.target.value
                                                      )
                                                    }
                                                    variant="outlined"
                                                    InputProps={{
                                                      classes: {
                                                        input:
                                                          classes.subCategoryInput,
                                                      },
                                                    }}
                                                  />
                                                )}
                                              </Field>
                                              <ErrorMessage
                                                name={`Section-header.subCategory.${subCatIndex}.text`}
                                              >
                                                {(msg) => (
                                                  <Typography
                                                    style={{ color: "red" }}
                                                  >
                                                    {msg}
                                                  </Typography>
                                                )}
                                              </ErrorMessage>
                                              {categoryOptions[field].length >
                                                0 && (
                                                <Select
                                                  className={
                                                    classes.profileDropdown
                                                  }
                                                  options={
                                                    categoryOptions[field]
                                                  }
                                                  placeholder={
                                                    "Select Text Value"
                                                  }
                                                  value={categoryOptions[
                                                    field
                                                  ].find(
                                                    (item) =>
                                                      item.value ==
                                                      values[field][
                                                        "subCategory"
                                                      ][subCatIndex][
                                                        "selectedText"
                                                      ]
                                                  )}
                                                  onChange={(label) => {
                                                    setFieldValue(
                                                      `Section-header.subCategory.${subCatIndex}.text`,
                                                      label.value
                                                    );
                                                    const findText =
                                                      groupByCategory[
                                                        field
                                                      ].find(
                                                        (item) =>
                                                          item[0] == label.value
                                                      );
                                                    setFieldValue(
                                                      `Section-header.subCategory.${subCatIndex}.pageNo`,
                                                      findText[2]
                                                    );
                                                  }}
                                                  menuPortalTarget={
                                                    document.body
                                                  }
                                                />
                                              )}
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            item
                                            md={2}
                                            xs={2}
                                            className={classes.wrapGrid}
                                          >
                                            <Field
                                              name={`Section-header.subCategory.${subCatIndex}.pageNo`}
                                            >
                                              {() => (
                                                <TextField
                                                  className={
                                                    classes.subCategoryInputWrapper
                                                  }
                                                  type="number"
                                                  value={subCat.pageNo}
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `Section-header.subCategory.${subCatIndex}.pageNo`,
                                                      e.target.value
                                                    )
                                                  }
                                                  variant="outlined"
                                                  InputProps={{
                                                    classes: {
                                                      input:
                                                        classes.profileField,
                                                    },
                                                  }}
                                                />
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`Section-header.subCategory.${subCatIndex}.pageNo`}
                                            >
                                              {(msg) => (
                                                <Typography
                                                  style={{ color: "red" }}
                                                >
                                                  {msg}
                                                </Typography>
                                              )}
                                            </ErrorMessage>
                                          </Grid>
                                          <Grid
                                            item
                                            md={2}
                                            xs={2}
                                            className={classes.wrapGridFlex}
                                            style={{ justifyContent: "center" }}
                                          >
                                            <Typography
                                              style={{ padding: "0 10px" }}
                                            >
                                              {" "}
                                              {langConstant.LEVEL}:{" "}
                                              {subCatIndex + 2}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            md={1}
                                            xs={2}
                                            className={classes.wrapGridFlex}
                                          >
                                            <Tooltip title={langConstant.ADD}>
                                              <IconButton
                                                aria-label={langConstant.ADD}
                                                color="primary"
                                                size="medium"
                                              >
                                                <AddCircleIcon
                                                  className={classes.iconWrap}
                                                  onClick={() =>
                                                    push({
                                                      id:
                                                        values["Section-header"]
                                                          .subCategory[
                                                          values[
                                                            "Section-header"
                                                          ].subCategory.length -
                                                            1
                                                        ]["id"] + 1,
                                                      text: "",
                                                      pageNo: "",
                                                    })
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              title={langConstant.REMOVE}
                                            >
                                              <IconButton
                                                aria-label={langConstant.REMOVE}
                                                color="error"
                                                size="medium"
                                              >
                                                <RemoveCircleIcon
                                                  className={classes.iconWrap}
                                                  onClick={() =>
                                                    remove(subCatIndex)
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>
                                        </Grid>
                                      )
                                    )}
                                  </>
                                )}
                              />
                            )}
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid className={classes.predictionBtnWrapper}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => onBackToStep1()}
                    disabled={isProgressView}
                  >
                    {langConstant.BACK}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    endIcon={
                      isProgressView ? (
                        <CircularProgress
                          style={{
                            width: "25px",
                            height: "25px",
                            color: "white",
                          }}
                        />
                      ) : (
                        <ArrowForwardIcon />
                      )
                    }
                    disabled={isProgressView}
                  >
                    {langConstant.NEXT_CAPS}
                  </Button>
                  <Button
                    type="button"
                    variant="text"
                    onClick={closeDocumentUploadPopUp}
                    color="primary"
                    disabled={isProgressView}
                  >
                    {langConstant.CANCEL}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      {compareFileDialog && (
        <CompareFileView
          show={compareFileDialog}
          setShow={setCompareFileDialog}
          setPredictionFromProcessed={setPredictionFromProcessed}
          setCurrentUrl={setCurrentUrl}
          setPredictionFormData={setPredictionFormData}
          setCategoryOptions={setCategoryOptions}
          setGroupByCategory={setGroupByCategory}
        />
      )}
    </>
  );
};

ExtractedContent.propTypes = {
  predictionFromProcessed: PropTypes.bool,
  closeDocumentUploadPopUp: PropTypes.func,
  setCurrentStep: PropTypes.func,
  compareFileDialog: PropTypes.bool,
  setCompareFileDialog: PropTypes.func,
  setPredictionFromProcessed: PropTypes.func,
  setCurrentUrl: PropTypes.func,
  isProgressView: PropTypes.bool,
  setProgressLogsData: PropTypes.func,
  setIsProgressDialog: PropTypes.func,
  setIsProgressView: PropTypes.func,
};

export default ExtractedContent;
