import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  FormLabel,
  IconButton,
} from "@material-ui/core";
import React, { useEffect } from "react";
import propTypes from "prop-types";
import { Close } from "@mui/icons-material";
import { commonDialogStyles } from "_components/ActionList/styles";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import { GetTeamDetails, PutActions } from "../../services";
import { ACTION_STATUS_LIST, priorities, linkTypes } from "../../constants";
import { OptionLabelTemplate } from "_components/ActionList/OptionLabelTemplate";
import { actionValidation } from "_components/ActionList/contants";
import { AddActionForm } from "_components/ActionList/AddActionForm";

const validationSchema = actionValidation.addAction;

const Label = ({ label, htmlFor, ...rest }) => (
  <FormLabel required htmlFor={htmlFor} {...rest}>
    {label}
  </FormLabel>
);

const getAttachedItem = (actionList) => {
  const { ACTIONS_LIST_LINK_ID, ACTIONS_LIST_TYPE } = actionList;
  if (!ACTIONS_LIST_LINK_ID || !ACTIONS_LIST_TYPE) return null;
  const mapping = {
    [linkTypes[0]]: "i_SUBMISSION_ID",
    [linkTypes[1]]: "i_POLICY_ID",
    [linkTypes[2]]: "i_POLICYCOMPARISON_ID",
    [linkTypes[3]]: "i_REGULATION_ID",
  };
  return {
    [mapping[ACTIONS_LIST_TYPE]]: ACTIONS_LIST_LINK_ID,
  };
};

export const AddActionDialog = ({
  addActionModal,
  successCallback,
  actionList,
  isViewMode,
}) => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId(),
    customerID = sessionService.getCustomerId();
  const dialogStyles = commonDialogStyles();
  const userList = useSelector((state) => state?.auditActions?.userList);
  const assignedTo = userList?.find(
    (user) => user?.USER_ID == actionList?.ASSIGNED_TO
  );

  const intialValues = {
    actionShortDesc:
      (isViewMode && actionList?.ACTIONS_SHORT_DESCRIPTION) || "",
    actionFullDesc: (isViewMode && actionList?.ACTIONS_FULL_DESCRIPTION) || "",
    assignedTo:
      (isViewMode && {
        value: assignedTo?.USER_ID,
        label: (
          <OptionLabelTemplate
            user={assignedTo}
            isCurrentUser={assignedTo?.USER_ID == userID}
          />
        ),
        payload: assignedTo,
      }) ||
      null,
    // assignedBy: "",
    priority:
      (isViewMode &&
        priorities.find(
          (p) => p[0] == actionList?.ACTIONS_LIST_PRIORITY?.[0]
        )) ||
      priorities[0],
    status:
      ACTION_STATUS_LIST?.find(
        (type) => type?.value == actionList?.ACTIONS_STATUS
      )?.value || ACTION_STATUS_LIST[0].value,
    closureDate:
      (isViewMode &&
        actionList?.CLOSURE_DATE &&
        new Date(actionList?.CLOSURE_DATE).toLocaleDateString("en-CA")) ||
      "",
    dueDate:
      (isViewMode &&
        actionList?.DUE_DATE &&
        new Date(actionList?.DUE_DATE).toLocaleDateString("en-CA")) ||
      "",
  };

  const onSubmit = async (values, actions) => {
    actions?.setSubmitting(true);
    const payload = {
      i_ACTIONS_SHORT_DESCRIPTION: values?.actionShortDesc,
      i_ACTIONS_FULL_DESCRIPTION: values?.actionFullDesc,
      i_ASSIGNED_TO: values?.assignedTo?.value,
      i_ASSIGNED_BY: userID,
      i_ACTIONS_PRIORITY: values?.priority,
      i_ACTIONS_STATUS: values?.status,
      i_DUE_DATE: values?.dueDate,
      i_CLOSURE_DATE: values?.closureDate,
      i_ACTIONS_ID: null,
      i_SUBMISSION_ID: null,
      i_QUESTION_ID: null,
      i_RFI_ID: null,
      i_POLICY_ID: null,
      i_POLICYCOMPARISON_ID: null,
      i_POLICY_COMPARISON_DETAILS_ID: null,
      i_ACTIONS_LIST_ID: actionList?.ACTIONS_LIST_ID,
      ...getAttachedItem(actionList),
    };
    await PutActions(payload)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(alertActions.success("Action successfully added"));
          setTimeout(() => {
            successCallback?.();
            addActionModal?.closeModal();
          }, 300);
        } else {
          dispatch(
            alertActions.error(
              response?.data?.message?.map((item, index) => {
                return <span key={index}>{item}</span>;
              }) || "Something went wrong"
            )
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(alertActions.error(err?.toString() || "Something went wrong"));
      })
      .finally(() => actions?.setSubmitting(false));
  };

  // if user list is not availlable fetch users
  useEffect(() => {
    if (!addActionModal?.isOpen || userList?.length) return;
    dispatch(GetTeamDetails(userID));
  }, [addActionModal?.isOpen]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={addActionModal?.closeModal}
      open={addActionModal?.isOpen}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography className={dialogStyles.title}>
        <Typography style={{ flex: 1 }} variant="h6" align="center">
          {isViewMode ? "VIEW" : "ADD NEW"} ACTION
        </Typography>
        <IconButton
          style={{ position: "absolute", right: 4 }}
          aria-label="close"
          onClick={addActionModal?.closeModal}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <AddActionForm
          intialValues={intialValues}
          onClose={addActionModal?.closeModal}
          onSubmit={onSubmit}
          userList={userList}
          validationSchema={validationSchema}
          isViewMode={isViewMode}
        />
      </DialogContent>
    </Dialog>
  );
};

AddActionDialog.propTypes = {
  addActionModal: propTypes.object,
  actionList: propTypes.object,
  successCallback: propTypes.func,
  isViewMode: propTypes.bool,
};
Label.propTypes = {
  label: propTypes.string,
  htmlFor: propTypes.string,
};
