/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import IconsStates from "./../IconsStates";
import Tooltip from "@material-ui/core/Tooltip";
import * as Icons from "@mui/icons-material";
import styles from "./styles";
import { getThemeDetails } from "containers/LoginPage/reducers";

const NavigationItem = (props) => {
  const themeDetails = useSelector(getThemeDetails);
  const themeColor = themeDetails?.MENUBAR_BGROUND_COLOUR;
  const fontColor = themeDetails?.FONT_COLOUR_DARK;
  const classes = styles({ themeColor, fontColor });
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState({});
  const [currentPath, setCurrentPath] = useState("");
  const [currentMenu, setCurrentMenu] = useState("");
  const [currentSubMenu, setCurrentSubMenu] = useState("");
  const [NavigationArray, setNavigationArray] = useState([]);
  const history = useHistory();
  const userData = useSelector((state) => state.authentication.userData);
  const NavigationDetails = useSelector(
    (state) => state.authentication?.detailedInfo?.NAVIGATION_DETAILS
  );

  const [navigationData, setNavigationData] = useState([]);

  const openSubmenu = (slug, menu) => {
    setMenuOpen({
      ...menuOpen,
      [slug]: !menuOpen[slug],
    });
    setCurrentMenu(menu);
    setCurrentSubMenu("");
  };
  const closeAllSubmenu = () => {
    setMenuOpen({});
  };
  //const currentPath = history && history.location && history.location.pathname ? history.location.pathname : ''

  const goToPage = (path, menu, subMenu) => {
    history.push({ pathname: path, state: { entitlement: subMenu } });
    setCurrentMenu(menu);
    setCurrentSubMenu(subMenu);
  };
  useEffect(() => {
    const pathList = location.pathname.split("/");
    if (pathList.length > 1) {
      setCurrentPath("/" + pathList[1]);
    } else {
      setCurrentPath("/" + pathList[0]);
    }
  }, [location.pathname]);
  useEffect(() => {
    if (props.isSidebarOpen == false) {
      closeAllSubmenu();
    }
  }, [props.isSidebarOpen]);
  //icon:{ inactive:{HomeOutlinedIcon}, active:{HomeRoundedIcon}, type:"inactive"},

  useEffect(() => {
    if (NavigationDetails) {
      let newNavItems = [];
      const parentNavigationDetails = NavigationDetails.filter(
        (item) => item.ENTITLEMENT_LABEL_LEVEL == "1" && item.ENTITLEMENT_PATH
      );
      const childNavigationDetails = NavigationDetails.filter(
        (item) => item.ENTITLEMENT_LABEL_LEVEL == "2" && item.ENTITLEMENT_PATH
      );

      parentNavigationDetails.forEach((element) => {
        if (element.ENTITLEMENT_LABEL_LEVEL == "1") {
          const entitlementIcons = element.ENTITLEMENT_ICON
            ? JSON.parse(element.ENTITLEMENT_ICON)
            : null;
          const activeIconComponent = Icons[entitlementIcons["active"].trim()];
          const inActiveIconComponent =
            Icons[entitlementIcons["inactive"].trim()];
          const tempElement = {
            child: [],
            icon: {
              active: entitlementIcons ? activeIconComponent : "",
              inactive: entitlementIcons ? inActiveIconComponent : "",
              type: "inactive",
            },
            path: element.ENTITLEMENT_PATH,
            role: "",
            name: element.ENTITLEMENT_NAME,
            slug: element.ENTITLEMENT_NAME,
            subPath: [element.ENTITLEMENT_PATH],
            title: element.ENTITLEMENT_LABEL,
            tooltip: element.ENTITLEMENT_TOOLTIP,
            order: element.ENTITLEMENT_NAME,
          };
          newNavItems.push(tempElement);
        }
      });
      newNavItems = newNavItems.sort((a, b) => {
        return parseInt(a.order) - parseInt(b.order);
      });
      childNavigationDetails.forEach((element) => {
        if (
          element.ENTITLEMENT_LABEL_LEVEL == "2" &&
          element.ENTITLEMENT_PARENT
        ) {
          const entitlementIcons = element.ENTITLEMENT_ICON
            ? JSON.parse(element.ENTITLEMENT_ICON)
            : null;
          const activeIconComponent = Icons[entitlementIcons["active"].trim()];
          const inActiveIconComponent =
            Icons[entitlementIcons["inactive"].trim()];
          const tempIndex = newNavItems.findIndex(
            (item) => item.name === element.ENTITLEMENT_PARENT
          );
          newNavItems[tempIndex] = {
            ...newNavItems[tempIndex],
            subPath: [
              ...newNavItems[tempIndex].subPath,
              element.ENTITLEMENT_PATH,
            ],
            child: [
              ...newNavItems[tempIndex].child,
              {
                title: element.ENTITLEMENT_LABEL,
                tooltip: element.ENTITLEMENT_TOOLTIP,
                path: element.ENTITLEMENT_PATH,
                role: "",
                slug: element.ENTITLEMENT_NAME,
                name: element.ENTITLEMENT_NAME,
                icon: {
                  active: entitlementIcons ? activeIconComponent : "",
                  inactive: entitlementIcons ? inActiveIconComponent : "",
                  type: "inactive",
                },
              },
            ],
          };
        }
      });

      setNavigationData(newNavItems);

      // const NavItems = [...RoleBasedNavigation].map((nav) => {
      //   let parentTitle = NavigationDetails.find((el) => el.ENTITLEMENT_NAME === nav.slug);
      //   if (nav.child.length === 0) {
      //     return { ...nav, title: parentTitle.ENTITLEMENT_LABEL, tooltip: parentTitle ? parentTitle.ENTITLEMENT_TOOLTIP : "" }
      //   } else {
      //     let tempchild = [];
      //     [...nav.child].map((child) => {
      //       let childTitle = NavigationDetails.find((ele) => ele.ENTITLEMENT_NAME === child.slug);
      //       let tempdata = { ...child, title: childTitle ? childTitle.ENTITLEMENT_LABEL : child.title, tooltip: childTitle ? childTitle.ENTITLEMENT_TOOLTIP : "" };
      //       tempchild.push(tempdata);
      //     });
      //     return { ...nav, title: parentTitle.ENTITLEMENT_LABEL, child: tempchild, tooltip: parentTitle ? parentTitle.ENTITLEMENT_TOOLTIP : "" }
      //   }
      // });
      // setNavigationArray(NavItems)
    }
  }, [NavigationDetails]);

  return (
    <React.Fragment>
      <List
        key="_nav"
        style={{
          paddingBottom: "100px",
        }}
      >
        {navigationData.length > 0 &&
          navigationData.map((item, index) => {
            if (
              userData &&
              userData.allowedNavItems &&
              userData.allowedNavItems.length > 0 &&
              userData.allowedNavItems.indexOf(item.slug) > -1
            ) {
              return (
                <React.Fragment key={item.path + "_" + index + "_parent"}>
                  <Tooltip
                    title={
                      <div
                        style={{
                          textAlign: "center",
                          marginLeft: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {item.tooltip}
                      </div>
                    }
                    placement="right"
                  >
                    <ListItem
                      className={classes.listItem}
                      button
                      key={item.path + "_" + index}
                      onClick={
                        item.child.length > 0
                          ? () => {
                              openSubmenu(item.path + "_" + index, item.slug);
                            }
                          : () => {
                              goToPage(item.path, item.slug, "");
                            }
                      }
                    >
                      <ListItemIcon
                        className={
                          classes.listItemIcon +
                          " " +
                          (item.subPath.includes(currentPath)
                            ? classes.activeColor
                            : "")
                        }
                      >
                        <IconsStates
                          active={item.icon.active}
                          inactive={item.icon.inactive}
                          type={
                            item.subPath.includes(currentPath)
                              ? "active"
                              : "inactive"
                          }
                          className={classes.listIcon}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={
                          (classes.listItemText,
                          item.subPath.includes(currentPath)
                            ? classes.listItemBold + " " + classes.activeColor
                            : classes.listItemBold)
                        }
                        primary={item.title}
                        primaryTypographyProps={{
                          className: classes.noWrap,
                        }}
                      />
                      {item.child.length > 0 &&
                        (menuOpen[item.path + "_" + index] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        ))}
                    </ListItem>
                  </Tooltip>
                  {item.child.length > 0 && (
                    <Collapse
                      in={menuOpen[item.path + "_" + index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        key={
                          item.path +
                          "_" +
                          index +
                          "_child" +
                          item.title.replace(" ", "")
                        }
                        component="div"
                        disablePadding
                      >
                        {item.child.map((childItem, index) => {
                          if (
                            userData &&
                            userData.allowedNavItems &&
                            userData.allowedNavItems.length > 0 &&
                            userData.allowedNavItems.indexOf(childItem.slug) >
                              -1
                          ) {
                            return (
                              <Tooltip
                                title={
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginLeft: "5px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {childItem.tooltip}
                                  </div>
                                }
                                placement="right"
                                key={childItem.path + "_" + index + "_child"}
                              >
                                <ListItem
                                  button
                                  className={classes.nested}
                                  onClick={() => {
                                    goToPage(
                                      childItem.path,
                                      item.slug,
                                      childItem.slug
                                    );
                                  }}
                                >
                                  <ListItemIcon
                                    className={
                                      classes.listItemIcon +
                                      " " +
                                      (currentPath == childItem.path
                                        ? classes.activeColor
                                        : "")
                                    }
                                  >
                                    <IconsStates
                                      active={childItem.icon.active}
                                      inactive={childItem.icon.inactive}
                                      type={
                                        currentPath == childItem.path
                                          ? "active"
                                          : "inactive"
                                      }
                                      className={classes.listIcon}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    className={
                                      (classes.listItemText,
                                      currentPath == childItem.path
                                        ? classes.activeColor +
                                          " " +
                                          classes.listItemBold
                                        : "")
                                    }
                                    primary={childItem.title}
                                    primaryTypographyProps={{
                                      className: classes.noWrap,
                                    }}
                                  />
                                </ListItem>
                              </Tooltip>
                            );
                          }
                        })}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              );
            }
          })}
      </List>
    </React.Fragment>
  );
};
NavigationItem.propTypes = {
  isSidebarOpen: PropTypes.bool,
};
export default NavigationItem;
