import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AdminUtils from "../../utils";
import AdminServices from "../../services";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import { useDispatch } from "react-redux";
import { scrollToTop } from "_helpers/utils";
import langConstant from "_lang";
import { alertActions } from "_actions";

const RegulationForRegulator = ({
  selectedRegulator,
  customerID,
  customerRegulationsList,
  setCustomerRegulationsList,
}) => {
  const [regulationsList, setRegulationsList] = useState(null);
  const [selectedRegulationsList, setSelectedRegulationsList] = useState([]);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    dispatch(
      AdminServices.getRegulationsFromRegulator(
        selectedRegulator.REGULATOR_ID,
        setRegulationsList,
        calculatePageCount
      )
    );
  }, [selectedRegulator]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      regulationsList?.length > 0
        ? Math.round(Math.ceil(regulationsList.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const updateRegulationsToCustomers = () => {
    if (selectedRegulationsList.length > 0) {
      dispatch(
        AdminServices.putCustomerRegulationRegulators(
          selectedRegulator,
          customerID,
          selectedRegulationsList,
          regulationsList,
          setCustomerRegulationsList
        )
      );
    } else {
      dispatch(alertActions.error("Please Select Regulations"));
    }
  };

  return (
    <Grid style={{ marginTop: "15px" }}>
      <TableContainer component={Paper}>
        <Table className="data-table" aria-label="Regulator List">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {AdminUtils.regulatorsRegulationsColumns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {regulationsList &&
              (rowsPerPage > 0
                ? regulationsList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : regulationsList
              ).map((row, index) => {
                return (
                  <TableRow
                    key={index + row[AdminUtils.regulationsColumns[0].field]}
                  >
                    <TableCell>
                      <Checkbox
                        checked={
                          selectedRegulationsList.includes(row.REGULATION_ID) ||
                          customerRegulationsList.some(
                            (regulation) =>
                              regulation.REGULATION_ID == row.REGULATION_ID
                          )
                        }
                        disabled={customerRegulationsList.some(
                          (regulation) =>
                            regulation.REGULATION_ID == row.REGULATION_ID
                        )}
                        onClick={() => {
                          if (
                            selectedRegulationsList.includes(row.REGULATION_ID)
                          ) {
                            setSelectedRegulationsList(
                              selectedRegulationsList.filter(
                                (regulation) => regulation !== row.REGULATION_ID
                              )
                            );
                          } else {
                            setSelectedRegulationsList((prevState) => [
                              ...prevState,
                              row.REGULATION_ID,
                            ]);
                          }
                        }}
                      />
                    </TableCell>
                    {AdminUtils.regulatorsRegulationsColumns.map(
                      (column, index) => {
                        const value = row[column.field];
                        return (
                          <TableCell
                            component="td"
                            scope="row"
                            key={
                              "column_" +
                              index +
                              row[
                                AdminUtils.regulatorsRegulationsColumns[0].field
                              ]
                            }
                            style={{
                              wordBreak: "break-word",
                              wordWrap: "break-word",
                            }}
                          >
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                );
              })}
            {regulationsList?.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={AdminUtils.regulatorsRegulationsColumns.length + 1}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid container justify="flex-end">
          <SelectPageRange
            selectedRange={rowsPerPage}
            handleChange={handleSelectPageRange}
          />
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={updateRegulationsToCustomers}
      >
        {langConstant.ASSIGN_REGULATIONS}
      </Button>
    </Grid>
  );
};

RegulationForRegulator.propTypes = {
  selectedRegulator: PropTypes.object,
  customerID: PropTypes.number,
  customerRegulationsList: PropTypes.oneOfType([() => null, PropTypes.array]),
  setCustomerRegulationsList: PropTypes.func,
};

export default RegulationForRegulator;
