import React from "react";
import { Box, Typography } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

const styles = {
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const Spinner = ({message}) => {
  return (
    <Box sx={styles.spinnerContainer}>
      <CircularProgress />
      {message && <Typography>{message}</Typography>}
    </Box>
  );
};

Spinner.propTypes = {
    message: PropTypes.string,
}

export default Spinner;