import { Box, Typography } from "@mui/material";
import { applyGroupByColumn } from "containers/ComplianceCenter/utils";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NoDataCard from "_components/MasterAssessmentComp/component/DashboardPanel/components/Graphs/NoDataCard";
import { useSelector } from "react-redux";

const GraphView = ({ rows, panelID }) => {
  const [graphData, setGraphData] = useState(null);
  const expandedPanelID = useSelector(
    (state) => state.complianceReducer.expandedPanelID
  );

  useEffect(() => {
    if (rows && rows.length > 0) {
      const responseData = applyGroupByColumn(
        rows,
        "ACTIONS_LIST_NAME",
        "ACTIONS_LIST_ID"
      );
      let tempHeadersData = [];
      responseData["headers"].forEach((item) => {
        tempHeadersData.push({
          ...item,
          isOpen: false,
          rows: 5,
          hasMore:
            responseData["values"][item.value] &&
            responseData["values"][item.value].length > 5
              ? true
              : false,
        });
      });
      setGraphData(responseData["graphData"]);
    }
  }, [rows]);

  return (
    <Box>
      {graphData && graphData.length > 0 ? (
        <ResponsiveContainer
          width="99%"
          height="99%"
          aspect={expandedPanelID == panelID ? 3 : 1}
        >
          <BarChart
            data={graphData}
            layout="vertical"
            margin={{
              top: 10,
              right: 20,
              left: 20,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              style={{
                fontSize: expandedPanelID == panelID ? "12px" : "10px",
                fontWeight: "bold",
              }}
            />
            <YAxis
              dataKey="ACTION_LIST"
              type="category"
              style={{
                fontSize: expandedPanelID == panelID ? "12px" : "10px",
                fontWeight: "bold",
              }}
            />
            <Tooltip />
            <Legend />
            <Bar
              barSize={20}
              dataKey={"IN_PROGRESS"}
              fill="#eb4034"
              opacity={0.8}
            />
            <Bar barSize={20} dataKey={"TO_DO"} fill="#f5e105" opacity={0.8} />
            <Bar barSize={20} dataKey={"DONE"} fill="#0e591b" opacity={0.8} />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <NoDataCard />
      )}
    </Box>
  );
};

GraphView.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  panelID: PropTypes.string,
};

export default GraphView;
