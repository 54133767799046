import React from "react";
import { Box, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";
import langConstant from "_lang";
import propTypes from "prop-types";

const useStyles = makeStyles(() => ({
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
  },
  menuIcon: {
    color: "#fff",
    fontSize: "18px",
    margin: "0 auto",
  },
}));

export const ActionIcon = ({ onClick }) => {
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const themeDetails = useSelector(getThemeDetails);
  return (
    <Tooltip title={langConstant.ACTIONS} placement="right">
      <IconButton
        className={classes.actionWrap}
        style={{
          backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
          margin: "0 auto",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <MenuIcon
          style={{ color: "#fff", fontSize: "18px", margin: "0 auto" }}
        />
      </IconButton>
    </Tooltip>
  );
};

ActionIcon.propTypes = {
  onClick: propTypes.func.isRequired,
};
