import { Grid } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import DocumentClassification from "./components/DocumentClassification";
import OrgManagement from "./components/OrgManagement";
import SanctionKeywords from "./components/SanctionKeywords";
import QuestionClassification from "./components/QuestionClassification";
import useStyles from "./styles";

const Classifications = ({ selectedCustomer }) => {
  const classes = useStyles();
  return (
    <Grid>
      <Grid style={{ marginBottom: "20px" }}>
        <OrgManagement selectedCustomer={selectedCustomer} />
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <DocumentClassification selectedCustomer={selectedCustomer} />
        </Grid>
        <Grid item md={4} xs={12}>
          <QuestionClassification selectedCustomer={selectedCustomer} />
        </Grid>
        <Grid item md={4} xs={12}>
          <SanctionKeywords selectedCustomer={selectedCustomer} />
        </Grid>
      </Grid>
    </Grid>
  );
};

Classifications.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default Classifications;
