import React, { useState, useEffect } from 'react';

import PropTypes from "prop-types";
import {Box, Fab, Grid, Tooltip, Select, MenuItem, Button} from "@material-ui/core";
import {MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { AccessAlarm, } from '@material-ui/icons';
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import MailIcon from "@material-ui/icons/Mail";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

import langConstant from "../../_lang";
import { constants } from "../../_constants/calendar.constants";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    verticalCenter: {
        alignItems: 'center',
        display: 'flex'
    },
    input: {
        "& input.MuiInputBase-input.Mui-disabled": {
            color: "gray"
        }
    },
    select: {
        '& .MuiSelect-select.Mui-disabled': {
            color: 'gray',
            width: '25vh'
          },
         '& .MuiSelect-select.MuiSelect-select': {
            width: '25vh'
         }
    },
    shortText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const PopupOnCalendar = withStyles(styles)((props) => {
    const { currentEntry, classes, updateCalendarEntry } = props;
    const [entryData, setEntryData] = useState({ v_entry_id: 0, v_user_id: 0, v_priority: '', v_duedate: '', v_status: '', canEdit: true });


    const getRFIInfo = (ENTRY_TYPE, STATUS) => {
        if (ENTRY_TYPE === constants.RFI) {
            if (STATUS == constants.RFI_SENT) {
                return "#7BC0FF";
            } else if (STATUS == constants.RFI_PENDING) {
                return "#FFF89C";
            } else if (STATUS == constants.RFI_OVERDUE) {
                return "#FFBCBC";
            } else if (STATUS == constants.RFI_RESOLVED) {
                return "#AFF3BB";
            }
        }
    }
    const getStatus = (status) => {
        return langConstant[status];
    }
    const closeModal = () => {
        setEntryData({ ...entryData, canEdit: true });
        props.updateCalendarEntry();
    }
    useEffect(() => {
        setEntryData({
            ...entryData, v_entry_id: currentEntry.ENTRY_ID, v_priority: currentEntry.PRIORITY,
            v_duedate: currentEntry.ENTRY_START_DATETIME, v_status: currentEntry.ENTRY_TYPE === constants.RFI ? currentEntry.STATUS : null, canEdit: true
        });
    }, [currentEntry])
    return (
        <>
            <Dialog aria-labelledby="customized-dialog-title"
                fullWidth={true}
                maxWidth={'xs'}
                open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={closeModal}>
                    <Box>
                        <Grid container>
                            <Grid item xs={2} md={2} lg={2} className={classes.verticalCenter} style={{ textAlign: 'left' }}>

                                {currentEntry.ENTRY_TYPE === constants.RFI ?
                                    (<Box>
                                        {currentEntry.STATUS == constants.RFI_SENT ?
                                            (
                                                <Fab size="small" style={{ backgroundColor: `${getRFIInfo(currentEntry.ENTRY_TYPE, currentEntry.STATUS)}` }}>

                                                    <MailIcon
                                                        style={{
                                                            fontSize: 20,
                                                            color: "#105BA1",
                                                        }}
                                                    />
                                                </Fab>) : currentEntry.STATUS == constants.RFI_RESOLVED ?
                                                (
                                                    <Fab size="small" style={{ backgroundColor: `${getRFIInfo(currentEntry.ENTRY_TYPE, currentEntry.STATUS)}` }}>
                                                        <CheckOutlinedIcon
                                                            style={{
                                                                fontSize: 20,
                                                                color: "#1a751a",
                                                            }}
                                                        />
                                                    </Fab>) :
                                                currentEntry.STATUS == constants.RFI_PENDING ? (
                                                    <Fab size="small" style={{ backgroundColor: `${getRFIInfo(currentEntry.ENTRY_TYPE, currentEntry.STATUS)}` }}>

                                                        <HelpOutlineIcon
                                                            style={{
                                                                fontSize: 20,
                                                                color: "#895514",
                                                            }}
                                                        />
                                                    </Fab>) : currentEntry.STATUS == constants.RFI_OVERDUE ? (
                                                        <Fab size="small"
                                                            style={{ backgroundColor: `${getRFIInfo(currentEntry.ENTRY_TYPE, currentEntry.STATUS)}` }}>

                                                            <ReportProblemIcon
                                                                style={{
                                                                    fontSize: 20,
                                                                    color: "#d62000",
                                                                }}
                                                            />
                                                        </Fab>) :
                                                    ('')

                                        }
                                    </Box>) : (currentEntry.PRIORITY === constants.HIGH ? (
                                        <Fab size="small" style={{ color: 'red', backgroundColor: '#ffbcbc', borderColor: '#ffbcbc' }}>
                                            {currentEntry.PRIORITY}
                                        </Fab>
                                    ) : currentEntry.PRIORITY === constants.MEDIUM ? (
                                        <Fab size="small" style={{ color: 'white', backgroundColor: 'orange', borderColor: 'orange' }} >
                                            {currentEntry.PRIORITY}
                                        </Fab>
                                    ) : currentEntry.PRIORITY === constants.LOW ? (
                                        <Fab size="small" style={{ color: 'green', backgroundColor: '#aff3bb', borderColor: '#aff3bb' }} >
                                            {currentEntry.PRIORITY}
                                        </Fab>
                                    ) : (
                                        ''
                                    ))}
                            </Grid>
                            <Grid item xs={10} md={10} lg={9} className={classes.verticalCenter} style={{ justifyContent: 'left' }}>
                                <Tooltip title={`${currentEntry.ENTRY_DESCRIPTION} ${currentEntry.LEGAL_ENTITY_NAME}`} placement="top">
                                    <Typography className={classes.shortText} style={{ fontSize: '1.2rem', color: 'black', lineHeight: '1.2', width: '48vh', fontWeightn: '500' }}>
                                        <strong>{`${currentEntry.ENTRY_DESCRIPTION} ${currentEntry.LEGAL_ENTITY_NAME}`}</strong>
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    {currentEntry.ENTRY_TYPE !== constants.RFI && <Grid container>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <Typography variant="body2" style={{ color: "black", textAlign: 'left' }}>
                                PRIORITY
                            </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.verticalCenter} style={{ justifyContent: 'left' }}>
                            <Typography variant="body2" style={{ color: "black", textAlign: 'left' }}>
                                <Select
                                    className={classes.select}
                                    disabled={entryData.canEdit}
                                    value={entryData.v_priority}
                                    onChange={(val) => {
                                        setEntryData({ ...entryData, v_priority: val.target.value });
                                    }}
                                >
                                    <MenuItem value={constants.HIGH}>High</MenuItem>
                                    <MenuItem value={constants.MEDIUM}>Medium</MenuItem>
                                    <MenuItem value={constants.LOW}>Low</MenuItem>

                                </Select>
                            </Typography>
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <Typography variant="body2" style={{ color: "red", textAlign: 'left', marginTop: '3px' }}>
                                <AccessAlarm />
                            </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.verticalCenter} style={{ justifyContent: 'left' }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker value={entryData.v_duedate} onChange={(val) => {
                                    setEntryData({ ...entryData, v_duedate: val });
                                }} disabled={entryData.canEdit} className={classes.input} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    {currentEntry.ENTRY_TYPE === constants.RFI && <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                            <Typography variant="body2" style={{ color: "black" }}>
                                {getStatus(currentEntry.STATUS)}
                            </Typography>
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: '0.5rem' }}>
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                            <Typography variant="body2" style={{ color: "black" }}>
                                Assigned By
                            </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.verticalCenter} style={{ justifyContent: 'left' }}>
                            <Typography variant="body2" style={{ color: "black", textAlign: 'left' }}>
                                {currentEntry.ASSIGNED_BY}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {entryData.canEdit ? (<Button autoFocus color="primary" onClick={() => {
                        setEntryData({ ...entryData, canEdit: false })
                    }}>
                        Edit
                    </Button>) :
                        (<Button autoFocus color="primary" onClick={() => { updateCalendarEntry(entryData) }}>
                            Save
                        </Button>)}
                </DialogActions>
            </Dialog>
        </>
    )
})
PopupOnCalendar.propTypes = {
    open: PropTypes.bool,
    updateCalendarEntry: PropTypes.func,
    currentEntry: PropTypes.object
};
export default PopupOnCalendar