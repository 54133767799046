// OP#3052 - UJC5.1 - Assign Assessment - Extension Features
// step1 screen for the assign assessment journey

import React, { useEffect, useState, useCallback } from "react";
import { Typography, Box, Button, Grid, InputAdornment, makeStyles, Tooltip } from '@material-ui/core';
import TextField from "@mui/material/TextField";
import { Formik, Field, ErrorMessage, Form } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from "@mui/material/ListSubheader";
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GetRegulationByUser, GetCustomerLegalEntityByUser, GetQuestionsForRegulation } from "../../services"
import sessionService from "_services/session.service";
import { Fragment } from "react";
import CircularProgress from '@mui/material/CircularProgress'
import _ from "lodash";
import * as yup from "yup";
import { alertActions } from "_actions";
import { useDispatch } from "react-redux";
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import langConstant from "_lang";


const useStyles = makeStyles(() => ({
  width100: {
    width: '100%',
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px"
  },
  bottomGridContainer: {
    marginTop: "20px",
    height: "calc(100vh - 230px)",
    maxHeight: "calc(100vh - 230px)",
  },
  btnWidth: {
    width: "170px"
  },
  inputWrapperField: {
    width: "80%"
  },
  inputField: {
    color: "#000 !important",
    padding: "8px 14px !important"
  },  
}));


const Step1 = ({
  setActivePage,
  setFinalQuestionsArray,
  finalQuestionsArray,
  EntityAndRegulationData,
  setEntityAndRegulationData,
  mode,
  setMode,
  FinalDomainAndQuestionList,
  setFinalDomainAndQuestionList,
  setSelectedDomainKeywords,
  SelectedDomainKeywords
}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const [RegulationOptions, setRegulationOptions] = useState([]);
  const [LegalEntityOptions, setLegalEntityOptions] = useState([]);
  const [DomainAndQuestionList, setDomainAndQuestionList] = useState({ DomainList: [], QuestionList: [] });
  const [filteredQuestionList, setFilteredQuestionList] = useState([]);
  const [filteredDomainList, setFilteredDomainList] = useState([]);
  const [DomainKeywords, setDomainKeywords] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionSearchText, setQuestionSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    LEGAL_ENTITY: yup.array().min(1, "required").required("required"),
    REGULATION: yup.object().nullable().required("required"),
    ASSESSMENT_TAG: yup.string().required("required")
      .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only blankspaces'),
  });


  const InitialData = {
    DOMAIN_SEARCH: "",
    LEGAL_ENTITY: [],
    REGULATION: {},
    ASSESSMENT_TAG: "",
  }

  const [formInitialData, setFormInitialData] = useState(InitialData);

  const getRegualtionData = async () => {

    const data = await GetRegulationByUser(userID);
    if (data["#result-set-1"]) {
      setRegulationOptions(data["#result-set-1"])
    }
  }

  const GetLegalEntityByUser = async () => {
    const data = await GetCustomerLegalEntityByUser(userID);
    if (data["#result-set-1"]) {
      setLegalEntityOptions(data["#result-set-1"])
    }
  }

  const questionFilterFunc = (DomainNames, questions) => {

    const temp = []
    DomainNames.map((el) => {
      let questionsOfDomain = questions.filter((ques) => ques.REGULATOR_CONTROL_GROUP.toLowerCase().trim() === el.toLowerCase().trim());
      temp.push({ group: el, questions: questionsOfDomain })
    });
    setFilteredQuestionList(temp);

  }

  useEffect(() => {
    getRegualtionData();
    GetLegalEntityByUser();
  }, []);

  useEffect(() => {
    if (mode === "backAdd") {
      const data = {
        DOMAIN_SEARCH: "",
        LEGAL_ENTITY: EntityAndRegulationData.entity,
        REGULATION: EntityAndRegulationData.regulation,
        ASSESSMENT_TAG: EntityAndRegulationData.assessmentTag,
      }
      setFormInitialData(data);
      setSelectedQuestions([...finalQuestionsArray]);
      const questionList = [...FinalDomainAndQuestionList.QuestionList];
      const domainList = [...FinalDomainAndQuestionList.DomainList];

      setDomainAndQuestionList({ QuestionList: questionList, DomainList: domainList });
      setFilteredDomainList(domainList);

      if (SelectedDomainKeywords.length === 0) {
        const DomainNames = domainList.map((el) => el.REGULATOR_CONTROL_GROUP);
        questionFilterFunc(DomainNames, questionList);

      } else {
        questionFilterFunc(SelectedDomainKeywords, questionList);
        setDomainKeywords(SelectedDomainKeywords)
      }

    }

  }, [])



  const RegulationFilter = async (e, val) => {
    if (val !== null) {
      setIsLoading(true);
      setDomainAndQuestionList({ DomainList: [], QuestionList: [] });
      setFilteredQuestionList([]);
      setFilteredDomainList([]);
      setDomainKeywords([]);
      setSelectedQuestions([]);
      setQuestionSearchText("");
      setFinalQuestionsArray([]);

      const data = await GetQuestionsForRegulation(val.REGULATION_ID);
      setDomainAndQuestionList({ QuestionList: data["#result-set-1"], DomainList: data["#result-set-2"] });
      setFilteredDomainList(data["#result-set-2"])
      const DomainNames = data["#result-set-2"].map((el) => el.REGULATOR_CONTROL_GROUP);
      const questions = data["#result-set-1"];
      questionFilterFunc(DomainNames, questions)

      setIsLoading(false);
    }
  }

  const QuestionSearchfilterFunc = (identifier, data) => {
    const Questions = DomainAndQuestionList.QuestionList.filter((el) => el.QUESTION_TEXT.toLowerCase().trim().includes((questionSearchText).toLowerCase().trim()));
    let temp = []
    if (identifier === true) {

      if (data.length === 0) {

        Questions.map((el) => {
          if (!temp.includes(el.REGULATOR_CONTROL_GROUP)) {
            temp.push(el.REGULATOR_CONTROL_GROUP)
          }
        });
      } else {
        temp = data
      }

    } else {
      if (DomainKeywords.length === 0) {

        Questions.map((el) => {
          if (!temp.includes(el.REGULATOR_CONTROL_GROUP)) {
            temp.push(el.REGULATOR_CONTROL_GROUP)
          }
        });
      } else {
        temp = DomainKeywords
      }

    }

    questionFilterFunc(temp, Questions)
  }

  useEffect(() => {
    if (questionSearchText.length > 0) {
      QuestionSearchfilterFunc(false)
    } else {
      if (mode !== "backAdd") {
        QuestionSearchfilterFunc(false)
      }
    }
  }, [questionSearchText]);

  const DomainListClick = (Domain) => {
    const currentIndex = DomainKeywords.indexOf(Domain.REGULATOR_CONTROL_GROUP);
    const newDomain = [...DomainKeywords];

    if (currentIndex === -1) {
      newDomain.push(Domain.REGULATOR_CONTROL_GROUP);
    } else {
      newDomain.splice(currentIndex, 1);
      const data = [...selectedQuestions].filter((el) => el.REGULATOR_CONTROL_GROUP.toLowerCase().trim() !== Domain.REGULATOR_CONTROL_GROUP.toLowerCase().trim());
      setSelectedQuestions(data);

      const data2 = [...finalQuestionsArray].filter((el) => el.REGULATOR_CONTROL_GROUP.toLowerCase().trim() !== Domain.REGULATOR_CONTROL_GROUP.toLowerCase().trim());
      setFinalQuestionsArray(data2);
    }

    let DomainName;

    if (newDomain.length === 0) {
      const Domains = DomainAndQuestionList.DomainList.map((el) => el.REGULATOR_CONTROL_GROUP);
      DomainName = Domains;

    } else {
      DomainName = newDomain;
    }

    setDomainKeywords(newDomain);

    if (questionSearchText.length > 0) {
      QuestionSearchfilterFunc(true, newDomain)
    } else {
      questionFilterFunc(DomainName, DomainAndQuestionList.QuestionList)

    }


  }

  const QuestionListClick = (question) => {
    const bool = selectedQuestions.some((ele) => ele.QUESTION_ID === question.QUESTION_ID);
    let newSelectedQuestions = [...selectedQuestions];

    if (bool === false) {
      newSelectedQuestions.push(question)
    } else {
      newSelectedQuestions = newSelectedQuestions.filter((element) => element.QUESTION_ID.toLowerCase().trim() !== question.QUESTION_ID.toLowerCase().trim())

    }
    setSelectedQuestions(newSelectedQuestions)
  }

  const DomainList = () => {
    return (
      <List dense className={classes.width100}>
        {filteredDomainList.sort((a, b) => a.REGULATOR_CONTROL_GROUP.trim().localeCompare(b.REGULATOR_CONTROL_GROUP.trim())).map((el) => {
          const labelId = `checkbox-list-secondary-label-${el.REGULATOR_CONTROL_GROUP}`;
          return (
            <ListItem
              key={el.REGULATOR_CONTROL_GROUP}
              onClick={(e) => { DomainListClick(el) }}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onClick={(e) => { e.preventDefault() }}
                  checked={DomainKeywords.indexOf(el.REGULATOR_CONTROL_GROUP) !== -1}
                  inputProps={{ 'aria-labelledby': labelId }}
                  disableRipple
                />
              }
              disablePadding
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <ListItemButton style={{ paddingLeft: "10px" }}>
                <ListItemText id={labelId} primary={<span style={{ fontWeight: "500" }}>{el.REGULATOR_CONTROL_GROUP}</span>} />
              </ListItemButton>
            </ListItem>
          );
        })}

        {
          filteredDomainList.length === 0 && (
            <ListItem disablePadding style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <ListItemButton style={{ textAlign: "center" }}>
                <ListItemText id={"nodata"} primary={langConstant.NO_RECORD_FOUND} />
              </ListItemButton>
            </ListItem>
          )
        }
      </List>
    )
  }

  const QuestionList = () => {
    return (

      <List
        dense
        sx={{ width: "100%", bgcolor: "background.paper" }}
      >
        {filteredQuestionList.sort((a, b) => a.group.trim().localeCompare(b.group.trim())).map((el) => {
          return (
            <Fragment key={`${el.group}-Frag`}>
              <ListSubheader disableSticky style={{ zIndex: 0 }}><span style={{ fontWeight: "800" }}>{el.group}</span></ListSubheader>
              {el.questions.map((ques) => {
                const labelId = `checkbox-list-secondary-label-${ques.QUESTION_ID}`;
                return (
                  <ListItem
                    key={ques.QUESTION_ID}
                    onClick={(e) => { QuestionListClick(ques) }}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onClick={(e) => { e.preventDefault() }}
                        checked={selectedQuestions.some((ele) => ele.QUESTION_ID === ques.QUESTION_ID)}
                        inputProps={{ "aria-labelledby": labelId }}
                        disableRipple
                      />
                    }
                    disablePadding
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <ListItemButton>
                      <ListItemText
                        id={labelId}
                        primary={ques.QUESTION_ID}
                        style={{ maxWidth: "100px", minWidth: "100px", marginRight: "10px" }}
                      />
                      <ListItemText
                        id={labelId}
                        primary={ques.QUESTION_TEXT}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </Fragment>
          );
        })}

        {
          filteredQuestionList.length === 0 && (
            <ListItem disablePadding style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <ListItemButton style={{ textAlign: "center" }}>
                <ListItemText id={"nodata"} primary={langConstant.NO_RECORD_FOUND} />
              </ListItemButton>
            </ListItem>
          )
        }
      </List>
    )
  }

  const QuestionSelectAll = () => {

    if (DomainKeywords.length === 0) {

      if (questionSearchText.length > 0) {
        const data = [...filteredQuestionList];
        const temp = []
        data.forEach((el) => {
          el.questions.map((que) => {
            temp.push(que)
          })
        });

        setSelectedQuestions((prev) => [...prev, ...temp])

      } else {
        setSelectedQuestions(DomainAndQuestionList.QuestionList)
      }

    } else {

      if (questionSearchText.length > 0) {
        const data = [...filteredQuestionList];
        const temp = []
        data.forEach((el) => {
          el.questions.map((que) => {
            temp.push(que)
          })
        });

        setSelectedQuestions((prev) => [...prev, ...temp])

      } else {
        const data = [...DomainAndQuestionList.QuestionList].filter((el) => [...DomainKeywords].find((remove) => el.REGULATOR_CONTROL_GROUP.toLowerCase().trim() === remove.toLowerCase().trim()));
        setSelectedQuestions(data);
      }

    }

  }

  const DomainSelectAll = (values) => {
    if (values.DOMAIN_SEARCH.length > 0) {
      const Domain = filteredDomainList.map((el) => el.REGULATOR_CONTROL_GROUP);
      const finalset = new Set([
        ...DomainKeywords,
        ...Domain
      ]);
      const finalArray = [...finalset];
      setDomainKeywords(finalArray);
      questionFilterFunc(finalArray, DomainAndQuestionList.QuestionList);
    } else {
      const Domains = DomainAndQuestionList.DomainList.map((el) => el.REGULATOR_CONTROL_GROUP);
      setDomainKeywords(Domains);
      questionFilterFunc(Domains, DomainAndQuestionList.QuestionList);
    }
  }

  const IncludeAndExclude = (identifier) => {

    if (identifier === "include") {

      setFinalQuestionsArray(selectedQuestions);
      dispatch(alertActions.success("Selected questions have been included"));

    } else {
      const data = [...DomainAndQuestionList.QuestionList];
      const removeArr = [...selectedQuestions];
      const finalarr = data.filter((el) => !removeArr.find((remove) => el.QUESTION_ID.toLowerCase().trim() === remove.QUESTION_ID.toLowerCase().trim()));
      setFinalQuestionsArray(finalarr);
      dispatch(alertActions.success("Selected questions have been excluded"));
    }

  }

  const SubmitData = (values, { resetForm }) => {
    setEntityAndRegulationData({ entity: values.LEGAL_ENTITY, regulation: values.REGULATION, assessmentTag: values.ASSESSMENT_TAG })
    setActivePage(1);
    setMode("");
    setFinalDomainAndQuestionList(DomainAndQuestionList);
    setSelectedDomainKeywords(DomainKeywords);
  }

  const renderOption = (props, option, selected, identifier) => (
    <li
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
      key={identifier === true ? `${option.REGULATION_SHORT_NAME}-${option.REGULATION_ID}` : `${option.LEGAL_ENTITY_NAME}-${option.LEGAL_ENTITY_ID}`}
    >
      {
        identifier === true ? option.REGULATION_SHORT_NAME : option.LEGAL_ENTITY_NAME
      }
      <Checkbox checked={selected} />
    </li>

  )

  const renderTags = (value) => {
    const numTags = value.length;

    return (
      <div style={{ display: "flex", width: "70%" }}>
        <div
          style={{
            width: "90%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
        >
          {value
            .slice(0, 2)
            .map((option, _) => option.LEGAL_ENTITY_NAME)
            .join(", ")}
        </div>
        <div>{numTags > 2 && ` +${numTags - 2}`}</div>
      </div>
    );
  }

  const DomainDebouncedChangeHandler = useCallback(_.debounce((e, setFieldValue) => {
    setFieldValue("DOMAIN_SEARCH", e.target.value);
    const Domains = DomainAndQuestionList.DomainList.filter((el) => el.REGULATOR_CONTROL_GROUP.toLowerCase().trim().includes((e.target.value).toLowerCase().trim()));
    setFilteredDomainList(Domains);
  }, 200));

  const QuestionDebouncedChangeHandler = useCallback(_.debounce((e) => {
    setQuestionSearchText(e.target.value);
  }, 200));

  return (
    <Box spacing={1}>
      <Formik
        validateOnChange={true}
        initialValues={formInitialData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={SubmitData}
      >
        {({ values, setFieldValue, resetForm, touched, errors }) => (
          <Form>
            <Grid container={true} justify="flex-start">
              <Grid item={true} xs={4}>
                <Grid item={true} xs={3} md={9}>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography className={classes.filedTitle}>
                      Control Owners
                    </Typography>
                    <ErrorMessage name="LEGAL_ENTITY">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Box>
                  <Field name="LEGAL_ENTITY" >
                    {({ field: { value } }) => (
                      <Autocomplete
                        size="small"
                        className={`${classes.margintop}`}
                        autoHighlight
                        openOnFocus
                        multiple
                        id="checkboxes-tags-demo"
                        options={LegalEntityOptions}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.LEGAL_ENTITY_NAME}
                        renderTags={(value) => { return renderTags(value) }}
                        renderOption={(props, option, { selected }) => { return renderOption(props, option, selected, false) }}
                        onChange={(e, val) => { 
                          setFieldValue("LEGAL_ENTITY", val);
                        }}
                        isOptionEqualToValue={(option, value) => option.LEGAL_ENTITY_NAME === value.LEGAL_ENTITY_NAME}
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search"
                            error={touched.LEGAL_ENTITY && Boolean(errors.LEGAL_ENTITY)}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <React.Fragment>

                                  {
                                    LegalEntityOptions.length === value.length
                                      ? (
                                        <Tooltip title={"De-Select-All"} placement="top">
                                          <DeselectIcon color="inherit" size={20} style={{ cursor: "pointer", marginRight: "5px" }} onClick={(e) => { e.stopPropagation(); setFieldValue("LEGAL_ENTITY", []) }} />
                                        </Tooltip>
                                      )
                                      : (
                                        <Tooltip title={"Select-All"} placement="top">
                                          <SelectAllIcon color="inherit" size={20} style={{ cursor: "pointer", marginRight: "5px" }} onClick={(e) => { e.stopPropagation(); setFieldValue("LEGAL_ENTITY", [...LegalEntityOptions]) }} />
                                        </Tooltip>
                                      )
                                  }
                                  {params.InputProps.startAdornment}
                                </React.Fragment>
                              )
                            }}
                          />)}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid item={true} xs={4}>
                <Typography className={classes.filedTitle}>
                  {langConstant.REGULATION}
                </Typography>
                <Field name="REGULATION">
                  {({ field: { value } }) => (
                    <Autocomplete
                      size="small"
                      className={`${classes.margintop}`}
                      autoHighlight
                      openOnFocus
                      id="checkboxes-tags-demo"
                      options={RegulationOptions}
                      style={{width: "80%"}}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.REGULATION_SHORT_NAME ? option.REGULATION_SHORT_NAME : ""}
                      renderOption={(props, option, { selected }) => { return renderOption(props, option, selected, true) }}
                      renderInput={(params) => <TextField {...params} placeholder="Search" />}
                      // error={touched.REGULATION && Boolean(errors.REGULATION)}
                      onChange={(e, val) => {
                        setFieldValue("REGULATION", val); 
                        RegulationFilter(e, val);
                      }}
                      isOptionEqualToValue={(option, value) => option.REGULATION_SHORT_NAME === value.REGULATION_SHORT_NAME}
                      value={value}
                    />
                  )}
                </Field>
                <ErrorMessage name="REGULATION">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                  )}
                </ErrorMessage>
              </Grid>
              <Grid item={true} xs={4}>
                <Typography className={classes.filedTitle}>
                  {langConstant.ASSESSMENT_TAG}
                </Typography>
                <Field name="ASSESSMENT_TAG">
                  {() => (
                    <TextField
                      className={classes.inputWrapperField}
                      value={values['ASSESSMENT_TAG']}
                      placeholder={langConstant.ASSESSMENT_TAG}
                      onChange={(e) => setFieldValue("ASSESSMENT_TAG", e.target.value)}
                      variant="outlined"
                      InputProps={{
                          // classes: { input: classes.inputWrapperField },
                          inputProps: { className: classes.inputField}
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="ASSESSMENT_TAG">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
            <Grid container justify="space-between" className={classes.bottomGridContainer}>
              <Grid item xs={3} md={3} style={{ height: "90%" }}>
                <Typography className={classes.filedTitle} >Domain</Typography>
                <Card variant="outlined" style={{ height: "90%" }}>
                  <Grid container style={{ padding: "10px" }} justify="space-between">
                    <Grid item xs={5} md={8} >
                      <Field name="DOMAIN_SEARCH" >
                        {({ field: { value } }) => (
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            placeholder="Search"
                            onChange={(e) => { DomainDebouncedChangeHandler(e, setFieldValue) }}
                            InputProps={{
                              autoComplete: "off",
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item >
                      {
                        (DomainKeywords.length === filteredDomainList.length) && (filteredDomainList.length !== 0) ? (
                          <Button color="primary" style={{ height: "40px" }} onClick={() => { setDomainKeywords([]); setSelectedQuestions([]); }}>
                            De-select ALL
                          </Button>

                        ) : (
                          <Button color="primary" style={{ height: "40px" }} onClick={() => { DomainSelectAll(values); }}>
                            Select ALL
                          </Button>

                        )
                      }

                    </Grid>
                  </Grid>
                  <Divider variant="middle" />
                  {
                    isLoading === true ? (
                      <div style={{ display: 'flex', justifyContent: 'center', height: "inherit", alignItems: "center" }}>
                        <CircularProgress />
                      </div>

                    ) : (
                      <CardContent style={{ padding: "0px", height: "90%" }}>
                        <PerfectScrollbar >
                          {DomainList()}
                        </PerfectScrollbar>
                      </CardContent>

                    )
                  }
                </Card>
              </Grid>
              <Grid item xs={8} md={8} style={{ height: "90%" }}>
                <Typography className={classes.filedTitle}>Questions</Typography>
                <Card variant="outlined" style={{ height: "90%" }}>
                  <Grid container style={{ padding: "10px" }} justify="space-between">
                    <Grid item xs={5} md={9} >
                      <Field name="QUESTION_SEARCH" >
                        {() => (
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="searchText"
                            name="searchText"
                            placeholder="Search"
                            onChange={(e) => { QuestionDebouncedChangeHandler(e) }}
                            InputProps={{
                              autoComplete: "off",
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item >
                      {
                        (selectedQuestions.length === filteredQuestionList.map((el) => el.questions).flat().length) && (filteredQuestionList.length !== 0) ? (
                          <Button color="primary" style={{ height: "40px" }} onClick={() => { setSelectedQuestions([]) }}>
                            De-select ALL
                          </Button>

                        ) : (
                          <Button color="primary" style={{ height: "40px" }} onClick={() => { QuestionSelectAll() }}>
                            Select ALL
                          </Button>
                        )
                      }
                    </Grid>
                  </Grid>
                  <Divider variant="middle" />
                  {
                    isLoading === true ? (
                      <div style={{ display: 'flex', justifyContent: 'center', height: "inherit", alignItems: "center" }}>
                        <CircularProgress />
                      </div>
                    ) : (
                      <CardContent style={{ padding: "0px", height: "90%" }}>
                        <PerfectScrollbar >
                          {QuestionList()}
                        </PerfectScrollbar>
                      </CardContent>
                    )
                  }
                </Card>
              </Grid>
              <Grid container justify="flex-end">
                <Grid container justify="space-between" style={{ width: "40%" }}>
                  <Button color="primary" className={classes.btnWidth} variant="outlined" onClick={() => { IncludeAndExclude("include") }} disabled={selectedQuestions.length === 0} startIcon={<AddCircleIcon style={{ fontSize: "30px" }} />}>
                    {langConstant.INCLUDE_CAPS}
                  </Button>
                  <Button color="primary" className={classes.btnWidth} variant="outlined" onClick={() => { IncludeAndExclude("exclude") }} disabled={selectedQuestions.length === 0 || DomainAndQuestionList.QuestionList.length === selectedQuestions.length} startIcon={<RemoveCircleIcon style={{ fontSize: "30px" }} />}>
                    {langConstant.EXCLUDE_CAPS}
                  </Button>
                  <Button color="primary" className={classes.btnWidth} variant="contained" type="submit" disabled={finalQuestionsArray.length === 0} startIcon={<ArrowForwardIcon style={{ fontSize: "30px" }} />}>
                    {langConstant.NEXT_CAPS}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box >)
}

Step1.propTypes = {
  setActivePage: PropTypes.func,
  setFinalQuestionsArray: PropTypes.func,
  finalQuestionsArray: PropTypes.array,
  EntityAndRegulationData: PropTypes.object,
  setEntityAndRegulationData: PropTypes.func,
  mode: PropTypes.string,
  setMode: PropTypes.func,
  FinalDomainAndQuestionList: PropTypes.object,
  setFinalDomainAndQuestionList: PropTypes.func,
  setSelectedDomainKeywords: PropTypes.func,
  SelectedDomainKeywords: PropTypes.array
}




export default Step1