/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import questionMappingAction from "../actions";
import MappingControls from "./MappingControls";

import Truncate from "../../../../../_components/Truncate";
const SelectedQuestionForMapping = (props) => {
  const dispatch = useDispatch();
  const [selectedMatch, setSelectedMatch] = useState(
    props.questionData.selectedMatch
  );
  const isMultiMatch =
    props.questionData.matches && props.questionData.matches.length > 0
      ? true
      : false;
  const highestRating = isMultiMatch
    ? props.questionData.matches.reduce(
        (max, p) => (p.rating > max ? p.rating : max),
        props.questionData.matches[0].rating
      )
    : null;
  useEffect(() => {
    setSelectedMatch(props.questionData.selectedMatch);
  }, [props.questionData.selectedMatch]);
  const updateMetaData = (metaData) => {
    let item = props.questionData;
    item.mappedControl = metaData.mappedControl;
    item.narratives = metaData.narratives;
    item.selectedMatch = isMultiMatch ? selectedMatch : "";
    if (item.matches && item.matches.length > 0) {
      let matches = [...item.matches];
      const index = selectedMatch.replace(/^\D+/g, "");
      matches[index].mappedControl = metaData.mappedControl;
      matches[index].narratives = metaData.narratives;
      item.matches = [...matches];
    }
    dispatch(questionMappingAction.updateSingleItemInDraft(item));
  };
  const updateMatch = (e) => {
    let data = props.questionData.matches[e.target.value.replace(/^\D+/g, "")];
    let item = props.questionData;
    item.mappedControl = data.mappedControl;
    item.narratives = data.narratives ? data.narratives : "";
    item.selectedMatch = e.target.value;
    dispatch(questionMappingAction.updateSingleItemInDraft(item));

    setSelectedMatch(e.target.value);
  };

  return (
    <Box>
      <Grid container wrap="nowrap" spacing={1}>
        <Grid item>
          <Typography color="textSecondary" variant="h6" gutterBottom>
            <strong>{props.questionData.ref} -</strong>
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h6">{props.questionData.sentence}</Typography>
        </Grid>
      </Grid>
      {props.questionData.info ? (
        <Box className="info-box">
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <InfoIcon color="inherit" />
            </Grid>
            <Grid item>
              <Truncate content={props.questionData.info} wordCount={50} />
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <Box mt={2}>
        {props.questionData.matches && props.questionData.matches.length > 0 ? (
          <>
            <Box>SELECT RELEVANT MATCHING QUESTION</Box>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={selectedMatch}
              onChange={(e) => updateMatch(e)}
            >
              {props.questionData.matches.map((item, index) => {
                return (
                  <Box key={index}>
                    <Box className={"option-selection-box "}>
                      <Grid
                        container
                        spacing={0}
                        justify="space-between"
                        alignItems="stretch"
                      >
                        <Grid item>
                          <FormControlLabel
                            value={"m" + index}
                            control={<Radio />}
                            label={item.question}
                          />
                        </Grid>
                        <Grid item>
                          <Box
                            className={
                              "rating " +
                              (highestRating == item.rating ? "highest" : null)
                            }
                          >
                            {item.rating}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    {selectedMatch == "m" + index && (
                      <Box className={isMultiMatch ? "border-box" : null}>
                        <MappingControls
                          metaData={item}
                          updateMetaData={(metaData) => {
                            updateMetaData(metaData);
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </RadioGroup>
          </>
        ) : (
          <MappingControls
            metaData={{
              mappedControl: props.questionData.mappedControl,
              narratives: props.questionData.narratives,
            }}
            updateMetaData={(metaData) => {
              updateMetaData(metaData);
            }}
          />
        )}
      </Box>
    </Box>
  );
};
SelectedQuestionForMapping.propTypes = {
  questionData: PropTypes.object,
};
export default SelectedQuestionForMapping;
