import React from "react";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ClassificationServices from "../services";
import { useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import DialogBox from "_components/DialogBox";
import { Form, Formik, Field } from "formik";
import ClassificationUtils from "../utils";
import FormTextField from "_components/FormTextField";
import useStyles from "../styles";
import FormDateField from "_components/FormDateField";
import Select from "react-select";
import CountryDropdown from "_components/CountryListDropDown";

const OrgDialog = ({
  open,
  setOpen,
  selectedCustomer,
  selectedOrg,
  setSelectedOrg,
  orgManagementData,
  setOrgManagementData,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setSelectedOrg(null);
  };

  const [CISOControls, setCISOControls] = useState([]);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(
        ClassificationServices.getCustomerCISOControls(
          selectedCustomer.value,
          setCISOControls
        )
      );
    }
  }, [selectedCustomer]);

  const onSubmit = (values) => {
    dispatch(
      ClassificationServices.putORGUnit(
        selectedCustomer.value,
        values,
        orgManagementData,
        setOrgManagementData,
        handleClose
      )
    );
  };

  return (
    <DialogBox
      maxWidth={"md"}
      open={open}
      handleClose={handleClose}
      title={langConstant.ORGANIZATION_MANAGEMENT}
    >
      <Formik
        initialValues={
          selectedOrg ? selectedOrg : ClassificationUtils.orgUnitsInitialValues
        }
        validationSchema={ClassificationUtils.orgUnitsValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Grid container>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.ORG_UNIT_NAME}
                  value={values.ORG_UNIT_NAME}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="ORG_UNIT_NAME"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.ORG_UNIT_PURPOSE}
                  value={values.ORG_UNIT_PURPOSE}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="ORG_UNIT_PURPOSE"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.ORG_UNIT_LEVEL}
                  value={values.ORG_UNIT_LEVEL}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="ORG_UNIT_LEVEL"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.ORG_UNIT_OWNER}
                  value={values.ORG_UNIT_OWNER}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="ORG_UNIT_OWNER"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.ORG_UNIT_COST_CENTER}
                  value={values.ORG_UNIT_COST_CENTER}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="ORG_UNIT_COST_CENTER"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.CISO_CONTROLER}
                </Typography>
                <Grid style={{ width: "100%" }}>
                  <Field
                    style={{ width: "100%", margin: "10px" }}
                    name="CISO_CONTROL_ID"
                  >
                    {() => (
                      <Select
                        className={classes.classificationSelectForm}
                        options={CISOControls}
                        value={CISOControls.find(
                          (type) => type.value == values["CISO_CONTROL_ID"]
                        )}
                        placeholder={"CISO_CONTROL_ID"}
                        onChange={(type) =>
                          setFieldValue("CISO_CONTROL_ID", type.value)
                        }
                        menuPortalTarget={document.body}
                      />
                    )}
                  </Field>
                  {errors && errors["CISO_CONTROL_ID"] && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors["CISO_CONTROL_ID"]}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.PARENT_ORGANIZATION}
                  value={values.PARENT_ORG_UNIT_ID}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="PARENT_ORG_UNIT_ID"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <FormTextField
                  fieldLabel={langConstant.ORG_UNIT_REFERENCE}
                  value={values.ORG_UNIT_REFERENCE}
                  type="text"
                  setFieldValue={setFieldValue}
                  fieldName="ORG_UNIT_REFERENCE"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.COUNTRY}
                </Typography>
                <Field name="HOME_COUNTRY_ISO_CODE">
                  {() => (
                    <Grid style={{ width: "80%" }}>
                      <CountryDropdown
                        value={values.HOME_COUNTRY_ISO_CODE}
                        onChange={(e) =>
                          setFieldValue("HOME_COUNTRY_ISO_CODE", e.value)
                        }
                        placeholder="Select Country"
                      />
                    </Grid>
                  )}
                </Field>
                {errors && errors["HOME_COUNTRY_ISO_CODE"] && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors["HOME_COUNTRY_ISO_CODE"]}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.ACTIVE}
                </Typography>
                <Field style={{ width: "100%" }} name="ACTIVE">
                  {() => (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={JSON.parse(values.ACTIVE)}
                      onChange={(e) => setFieldValue("ACTIVE", e.target.value)}
                      style={{
                        display: "inline",
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  )}
                </Field>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.START_DATE}
                </Typography>
                <FormDateField
                  value={values.START_DATE}
                  setFieldValue={setFieldValue}
                  fieldName="START_DATE"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.END_DATE}
                </Typography>
                <FormDateField
                  value={values.END_DATE}
                  setFieldValue={setFieldValue}
                  fieldName="END_DATE"
                  errors={errors}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={classes.formFieldLabel}>
                  {langConstant.UPDATE_DATE}
                </Typography>
                <FormDateField
                  value={values.UPDATE_DATE}
                  setFieldValue={setFieldValue}
                  fieldName="UPDATE_DATE"
                  errors={errors}
                />
              </Grid>
            </Grid>
            <Grid style={{ textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                {langConstant.UPDATE}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogBox>
  );
};

OrgDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
  selectedOrg: PropTypes.oneOfType([() => null, PropTypes.object]),
  setSelectedOrg: PropTypes.func,
  orgManagementData: PropTypes.oneOfType([() => null, PropTypes.array]),
  setOrgManagementData: PropTypes.func,
};

export default OrgDialog;
