/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Box } from "@material-ui/core";

import AppDataTable from "./components/AppDataTable";
import assessmentAction from "./actions";
import moment from "moment";
import sessionService from "../../../_services/session.service";
import { resetStepper } from "_components/Stepper/action";
import { MyAssessmentCleanUpFunc } from "./NewInitiateMyAssessment/actions";
import ProgressBarLarge from "_components/ProgressBarLarge";
import { getRouteBasedTitle } from "_helpers/utils";

const MyAssessments = () => {
  //REGULATION ID REGULATION NAME REGULATOR COUNTRY STATUS UPLOAD DATE
  const columns = [
    { field: "SUBMISSION_ID", headerName: "SUBMISSION ID" },
    {
      field: "REGULATION_SHORT_NAME",
      headerName: "REGULATION SHORT NAME",
    },
    { field: "REGULATION_NAME", headerName: "REGULATION NAME" },
    {
      field: "DATE_ASSIGNED",
      headerName: "ASSIGNED DATE",
      format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
    },
    {
      field: "REGULATOR_SUBMISSION_DATE",
      headerName: "SUBMISSION DATE",
      format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
    },
    {
      field: "REGULATION_COMPLETION_DATE",
      headerName: "COMPLETION DATE",
      format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
    },
    { field: "LEGAL_ENTITY_NAME", headerName: "CONTROL OWNER" },
    { field: "ASSIGNED_BY", headerName: "ASSIGNED BY" },
    { field: "Status_label", headerName: "STATUS" },
    {
      field: "COMPLETED",
      headerName: "PROGRESS",
      minWidth: 220,
      // eslint-disable-next-line react/display-name
      format: (value, row) => {
        const completionPercentage =
          (Number(value || 0) / Number(row?.control_count)) * 100;
        return (
          <ProgressBarLarge
            percentage={completionPercentage?.toFixed(2) || 0}
            size="small"
          />
        );
      },
    },
    { field: "control_count", headerName: "CONTROL COUNT", minWidth: 100 },
    // {
    //   field: "LAST_UPDATED_DATE",
    //   headerName: "MODIFIED DATE",
    //   minWidth: 130,
    //   format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
    // },
    { field: "action", headerName: "ACTIONS", sticky: true },
  ];

  const dispatch = useDispatch();
  const rows = useSelector((state) => state.assessmentList.dataList);

  const userID = sessionService.getUserId();

  useEffect(() => {
    dispatch(assessmentAction.getAssessmentList(userID));
    dispatch(resetStepper());
    dispatch(MyAssessmentCleanUpFunc());
  }, []);
  return (
    <Box style={{ height: "100vh" }}>
      <AppDataTable rows={rows} columns={columns} className="data-table" />
    </Box>
  );
};

export default MyAssessments;
