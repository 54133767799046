import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  IconButton,
  InputAdornment,
  Tooltip,
  TextField,
} from "@material-ui/core";
import AddBoxIcon from "@mui/icons-material/AddBox";
import useStyles from "./styles";
import { scrollToTop } from "_helpers/utils";
import AdminServices from "./services";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import AdminUtils from "./utils";
import langConstant from "_lang";
import EditIcon from "@mui/icons-material/Edit";
import TeamForm from "./TeamForm";
import SearchIcon from "@material-ui/icons/Search";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { countriesListActions } from "_actions";
import { minHeight } from "@material-ui/system";

const TeamsTable = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const themeDetails = useSelector(getThemeDetails);
  const countries = useSelector((state) => state.countriesList);

  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const [teamsData, setTeamsData] = useState(null);
  const [filteredTeamsData, setFilteredTeamsData] = useState(null);
  const [filteredText, setFilteredText] = useState("");
  const [showTeamForm, setShowTeamForm] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (teamsData && teamsData.length > 0) {
      applyFilter();
    }
  }, [teamsData]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredTeamsData?.length > 0
        ? Math.round(Math.ceil(filteredTeamsData.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleClose = () => {
    setShowTeamForm(false);
    setSelectedTeam(null);
  };

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(countriesListActions.getCountriesList());
      dispatch(
        AdminServices.getCustomerTeamListForAdmin(
          selectedCustomer.value,
          setTeamsData,
          setFilteredTeamsData
        )
      );
    }
  }, [selectedCustomer]);

  const applyFilter = () => {
    let tempTeamsData = [...teamsData];
    if (filteredText !== "") {
      const filteredTextLowercase = filteredText.toLowerCase();
      tempTeamsData = tempTeamsData.filter(
        (user) =>
          user.TEAM_NAME?.toLowerCase().includes(filteredTextLowercase) ||
          user.PARENT_TEAM_NAME?.toLowerCase().includes(
            filteredTextLowercase
          ) ||
          user.DEPARTMENT_NAME?.toLowerCase().includes(filteredTextLowercase)
      );
      setFilteredTeamsData(tempTeamsData);
      setPageCount(calculatePageCount(tempTeamsData));
    } else {
      setFilteredTeamsData(tempTeamsData);
      setPageCount(calculatePageCount(tempTeamsData));
    }
  };

  return (
    <Grid>
      <Grid container>
        <Grid
          item
          md={6}
          xs={12}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography style={{ fontWeight: "bold", marginRight: "10px" }}>
            {langConstant.TEAMS}
          </Typography>
          {selectedCustomer && selectedCustomer.value && (
            <>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                id="searchText"
                value={filteredText}
                name="searchText"
                placeholder={langConstant.ENTER_KEYWORDS_TO_SEARCH_USERS}
                onChange={(e) => setFilteredText(e.target.value)}
                InputProps={{
                  autoComplete: "off",
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ marginRight: "10px", width: "50%" }}
              />
              <IconButton
                color={"primary"}
                className={classes.iconButton}
                onClick={applyFilter}
              >
                <Tooltip title={langConstant.SEARCH}>
                  <SearchIcon style={{ color: "white" }} />
                </Tooltip>
              </IconButton>
            </>
          )}
        </Grid>
        <Grid item md={6} xs={12} style={{ textAlign: "right" }}>
          {selectedCustomer && selectedCustomer.value && (
            <Grid style={{ alignItems: "center" }}>
              <Box my={2}>
                <Grid
                  container
                  justify="flex-end"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <SelectPageRange
                    selectedRange={rowsPerPage}
                    handleChange={handleSelectPageRange}
                  />
                  {pageCount > 1 && (
                    <AppPagination
                      colSpan={3}
                      count={pageCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      size="small"
                    />
                  )}
                  <IconButton
                    type="button"
                    color="primary"
                    onClick={() => setShowTeamForm(true)}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid>
        <TableContainer component={Paper}>
          <Table className="data-table" aria-label="Entitlement List">
            <TableHead>
              <TableRow>
                {AdminUtils.teamsColumns.map((item, index) => (
                  <TableCell
                    component="th"
                    scope="row"
                    key={"column_" + index + item.field}
                  >
                    {item.headerName}
                  </TableCell>
                ))}
                <TableCell>{langConstant.ACTIONS}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTeamsData ? (
                <React.Fragment>
                  {(rowsPerPage > 0
                    ? filteredTeamsData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredTeamsData
                  ).map((row, index) => {
                    const country = countries.find(
                      (country) => country.COUNTRY_CODE == row["COUNTRY_CODE"]
                    );
                    return (
                      <TableRow
                        key={index + row[AdminUtils.teamsColumns[0].field]}
                      >
                        {AdminUtils.teamsColumns.map((column, index) => {
                          const value = row[column.field];
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              key={
                                "column_" +
                                index +
                                row[AdminUtils.teamsColumns[0].field]
                              }
                              style={{
                                wordBreak: "break-word",
                                wordWrap: "break-word",
                              }}
                            >
                              {column.format
                                ? column.format(row, country)
                                : value}
                            </TableCell>
                          );
                        })}
                        <TableCell>
                          <EditIcon
                            fontSize="small"
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => {
                              setShowTeamForm(true);
                              setSelectedTeam(row);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {filteredTeamsData && filteredTeamsData.length == 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={AdminUtils.teamsColumns.length + 1}
                      >
                        {langConstant.NO_RECORD_FOUND}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ) : (
                <TableRow>
                  <TableCell colSpan={AdminUtils.teamsColumns.length + 1}>
                    {selectedCustomer && selectedCustomer.value ? (
                      <CircularProgress />
                    ) : (
                      <Typography
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          minHeight: "100px",
                          display: " flex",
                          alignItems: " center",
                          justifyContent: "center",
                        }}
                      >
                        {langConstant.PLEASE_SELECT_CUSTOMER_FIRST}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {showTeamForm && (
          <TeamForm
            show={showTeamForm}
            handleClose={handleClose}
            selectedTeam={selectedTeam}
            teamsData={teamsData}
            setTeamsData={setTeamsData}
            selectedCustomer={selectedCustomer}
          />
        )}
      </Grid>
    </Grid>
  );
};

TeamsTable.propTypes = {
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default TeamsTable;
