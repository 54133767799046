// OP#2994 - UJA10 - UI Development : Manage Evidence Types - Part 1 only
// pop-up component for Evidence-types

import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Drawer,
  DialogTitle,
  Box,
  Typography,
  Grid,
  Switch,
  TextField,
  Button,
  Tooltip,
} from "@material-ui/core";
import Select from "react-select";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage, Field, Form, Formik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import langConstant from "_lang";
import { styled } from "@mui/material/styles";
import ChipInput from "material-ui-chip-input";
import SaveIcon from "@mui/icons-material/Save";
import { PutEvidenceType } from "../../services";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { alertActions } from "_actions";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import sessionService from "_services/session.service";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles(() => ({
  borderNone: {
    border: "none",
  },
  width100: {
    width: "100%",
  },
  inputProfileField: {
    padding: "5px",
    color: "#000000",
  },
  filedTitle: {
    // color: "#a9a8a8",
    // marginBottom: "5px",
    // fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "20px",
  },
  profileMDCloseBtn: {
    top: "9px",
    right: "14px",
    width: "18px",
    height: "18px",
    position: "absolute",
  },
  profileMDCloseFirst: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  profileMDCloseSecond: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%) rotate(-45deg)",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
  },
  regulatorsTopHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "9px",
  },
  regulatorsTopHeaderEdit: {
    "& > div": {
      width: "100%",
    },
    "& input": {
      height: "30px",
      padding: "5px 13px",
    },
  },
  regulatorstatusWrapper: {
    "& > .MuiSwitch-root": {
      position: "relative",
      left: "-10px",
    },
  },
  PersonTitle: {
    color: "#000000",
    marginBottom: "5px",
    fontWeight: "500",
  },
  KeyTitle: {
    color: "#a9a8a8",
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "5px",
  },
  filedTitleIcon: {
    display: "flex",
    fontWeight: "500",
    "& svg": {
      marginRight: "6px",
      position: "relative",
      top: "-1px",
    },
  },
  wordBreak: {
    wordBreak: "break-all",
  },

  drawer: {
    zIndex: "1253 !important",
  },
  containerDIV: {
    width: 500,
    padding: 10,
  },
  cursorpointer: {
    cursor: "pointer",
  },
  justifybetween: {
    justifyContent: "space-between",
    display: "flex",
  },
  chipInput: {
    borderRadius: "5px",
    border: "1px solid #cbcbcb",
    padding: "5px 7px 0px 7px",
    width: "100%",
    // maxHeight: "200px",
    maxHeight: "20vh",
    overflowY: "auto",
    scrollBehavior: "smooth",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  marginleft: {
    marginLeft: "20px",
  },
}));

const AddEditForm = ({
  AddEditModal,
  setAddEditModal,
  domainOptions,
  classificationOptions,
  userID,
  setevidenceList,
  evidenceList,
}) => {
  const classes = useStyles();
  const [domainoptions, setdomainoptions] = useState();
  const [classyOptions, setclassyoptions] = useState();
  const [keyState, setKeyState] = useState(Date.now());
  const dispatch = useDispatch();

  const customerID = sessionService.getCustomerId();

  useEffect(() => {
    let options = [];
    domainOptions.map((item) => {
      options.push({
        value: item.REGULATORY_DOMAIN_ID,
        label: item.REGULATION_DOMAIN,
      });
    });
    setdomainoptions(options);
  }, [domainOptions]);

  useEffect(() => {
    let options = [];
    classificationOptions.map((item) => {
      options.push({
        value: item.INFO_CLASSIFICATION_ID,
        label: item.DESCRIPTION,
      });
    });
    setclassyoptions(options);
  }, [classificationOptions]);

  const expiryPeriodicityOptions = [
    { value: "1d", label: langConstant.ONE_DAY },
    { value: "5d", label: langConstant.FIVE_DAY },
    { value: "10d", label: langConstant.TEN_DAY },
    { value: "20d", label: langConstant.TWENTY_DAY },
    { value: "1m", label: langConstant.ONE_MONTH },
    { value: "3m", label: langConstant.THREE_MONTH },
    { value: "6m", label: langConstant.SIX_MONTH },
    { value: "18m", label: langConstant.EIGHTEEN_MONTH },
    { value: "1y", label: langConstant.ONE_YEAR },
    { value: "2y", label: langConstant.TWO_YEAR },
  ];

  const InitialData = {
    ACTIVE: true,
    EVIDENCE_SHORTNAME: "",
    EVIDENCE_DESCRIPTION: "",
    EVIDENCE_SOURCE: "",
    OWNER: "",
    CLASSIFICATION: "",
    REGULATORY_DOMAIN: "",
    EVIDENCE_KEYWORDS: [],
    EXPIRY_PERIODICITY: "1m",
  };

  const [formInitialData, setFormInitialData] = useState(InitialData);

  useEffect(() => {
    if (AddEditModal.mode === "edit" || AddEditModal.mode === "view") {
      setFormInitialData({
        ACTIVE: AddEditModal.data.ACTIVE,
        EVIDENCE_SHORTNAME: AddEditModal?.data?.EVIDENCE_SHORTNAME || "",
        EVIDENCE_DESCRIPTION: AddEditModal?.data?.EVIDENCE_DESCRIPTION || "",
        EVIDENCE_SOURCE: AddEditModal?.data?.EVIDENCE_SOURCE || "",
        OWNER: AddEditModal?.data?.OWNER || "",
        CLASSIFICATION: AddEditModal?.data?.DESCRIPTION || "",
        REGULATORY_DOMAIN: AddEditModal.data.REGULATORY_DOMAIN_ID,
        EVIDENCE_KEYWORDS: AddEditModal.data.EVIDENCE_KEYWORDS
          ? AddEditModal.data.EVIDENCE_KEYWORDS.split(";")
          : [],
        EXPIRY_PERIODICITY: AddEditModal?.data?.EXPIRY_PERIODICITY || "",
      });
    }

    if (AddEditModal.mode === "add") {
      setFormInitialData({ ...InitialData });
    }
  }, [AddEditModal.mode]);

  const validationSchema = Yup.object().shape({
    EVIDENCE_SHORTNAME: Yup.string()
      .nullable()
      .required("Evidence Short-Name is Required"),
    EVIDENCE_DESCRIPTION: Yup.string()
      .nullable()
      .required("Evidence Description is Required"),
    EVIDENCE_SOURCE: Yup.string().nullable(),
    OWNER: Yup.string().nullable(),
    CLASSIFICATION: Yup.string().required(
      "Evidence Classification is Required"
    ),
    REGULATORY_DOMAIN: Yup.number().required("Regulatory Domain is Required"),
    EVIDENCE_KEYWORDS: Yup.array()
      .min(1, "Evidence Keywords are Required")
      .nullable()
      .required("Evidence Keywords are Required"),
    EXPIRY_PERIODICITY: Yup.string()
      .nullable()
      .required("Expiry Periodicity is Required"),
  });

  const SubmitData = async (values, { resetForm }) => {
    const date = Date.now();
    setKeyState(date);
    const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

    const DataObj = {
      i_EVIDENCE_TYPE_ID:
        AddEditModal.mode === "add" ? null : AddEditModal.data.EVIDENCE_TYPE_ID,
      i_USER_ID: userID,
      i_EVIDENCE_SHORTNAME: values.EVIDENCE_SHORTNAME,
      i_EVIDENCE_DESCRIPTION: values.EVIDENCE_DESCRIPTION,
      i_EVIDENCE_KEYWORDS: values.EVIDENCE_KEYWORDS.join(";"),
      i_REGULATORY_DOMAIN_ID: values.REGULATORY_DOMAIN,
      i_OWNER: values.OWNER,
      i_EVIDENCE_SOURCE: values.EVIDENCE_SOURCE,
      i_DESCRIPTION: values.CLASSIFICATION,
      i_ACTIVE: values.ACTIVE,
      i_START_DATE: currentDate,
      i_END_DATE: currentDate,

      i_EVIDENCE_TEMPLATE: "",
      i_EVIDENCE_CONTACT: "",
      i_EVIDENCE_URL_TO_SOURCE: "",
      i_EXPIRY_PERIODICITY: values.EXPIRY_PERIODICITY,
      i_CUSTOMER_ID: customerID,
    };

    const data = await PutEvidenceType(DataObj);

    const AddObj = {
      EVIDENCE_TYPE_ID:
        AddEditModal.mode === "add"
          ? data.id
          : AddEditModal.data.EVIDENCE_TYPE_ID,
      USER_ID: userID,
      EVIDENCE_SHORTNAME: values.EVIDENCE_SHORTNAME,
      EVIDENCE_DESCRIPTION: values.EVIDENCE_DESCRIPTION,
      EVIDENCE_KEYWORDS: values.EVIDENCE_KEYWORDS.join(";"),
      REGULATORY_DOMAIN_ID: values.REGULATORY_DOMAIN,
      OWNER: values.OWNER,
      EVIDENCE_SOURCE: values.EVIDENCE_SOURCE,
      DESCRIPTION: values.CLASSIFICATION,
      ACTIVE: values.ACTIVE,
      START_DATE: currentDate,
      END_DATE: currentDate,

      EVIDENCE_TEMPLATE: null,
      EVIDENCE_CONTACT: null,
      EVIDENCE_URL_TO_SOURCE: null,
      INFO_CLASSIFICATION: null,
      CREATE_DATE: null,
      DISPLAY: null,
    };

    if (data.error) {
      dispatch(alertActions.error(data.error));
    } else {
      if (AddEditModal.mode === "add") {
        dispatch(alertActions.success("Evidence added successfully"));
        setevidenceList((prev) => [...prev, { ...AddObj }]);
      }

      if (AddEditModal.mode === "edit") {
        dispatch(alertActions.success("Evidence edited successfully"));
        const updatedEvidenceList = [...evidenceList];
        const index = updatedEvidenceList.findIndex(
          (x) => x.EVIDENCE_TYPE_ID === AddEditModal.data.EVIDENCE_TYPE_ID
        );
        const EditObj = {
          EVIDENCE_TYPE_ID: AddEditModal.data.EVIDENCE_TYPE_ID,
          USER_ID: AddEditModal.data.USER_ID,
          EVIDENCE_SHORTNAME: values.EVIDENCE_SHORTNAME,
          EVIDENCE_DESCRIPTION: values.EVIDENCE_DESCRIPTION,
          EVIDENCE_KEYWORDS: values.EVIDENCE_KEYWORDS.join(";"),
          REGULATORY_DOMAIN_ID: values.REGULATORY_DOMAIN,
          OWNER: values.OWNER,
          EVIDENCE_SOURCE: values.EVIDENCE_SOURCE,
          DESCRIPTION: values.CLASSIFICATION,
          ACTIVE: values.ACTIVE,
          START_DATE: AddEditModal.data.START_DATE,
          END_DATE: AddEditModal.data.END_DATE,
          EVIDENCE_TEMPLATE: AddEditModal.data.EVIDENCE_TEMPLATE,
          EVIDENCE_CONTACT: AddEditModal.data.EVIDENCE_CONTACT,
          EVIDENCE_URL_TO_SOURCE: AddEditModal.data.EVIDENCE_URL_TO_SOURCE,
          INFO_CLASSIFICATION: AddEditModal.data.INFO_CLASSIFICATION,
          CREATE_DATE: AddEditModal.data.CREATE_DATE,
          DISPLAY: AddEditModal.data.DISPLAY,
        };

        if (index !== -1) {
          updatedEvidenceList[index] = EditObj;
          setevidenceList(updatedEvidenceList);
        }
      }
    }

    setAddEditModal({ visible: false, mode: "", data: {} });
    resetForm({
      ACTIVE: false,
      EVIDENCE_SHORTNAME: "",
      EVIDENCE_DESCRIPTION: "",
      EVIDENCE_SOURCE: "",
      OWNER: "",
      CLASSIFICATION: null,
      REGULATORY_DOMAIN: null,
      EVIDENCE_KEYWORDS: [],
    });
  };

  return (
    <Drawer
      anchor={"right"}
      open={AddEditModal.visible}
      // open={true}
      onClose={() => {
        setAddEditModal({ visible: false, mode: "", data: {} });
      }}
      size={"xl"}
      className={classes.drawer}
      PaperProps={{
        sx: { width: 500 },
      }}
    >
      <div className={classes.containerDIV}>
        <Formik
          enableReinitialize={true}
          initialValues={formInitialData}
          validationSchema={validationSchema}
          onSubmit={SubmitData}
        >
          {({ values, setFieldValue, resetForm, errors }) => (
            <Form>
              <DialogTitle>
                <div className={classes.justifybetween}>
                  <div
                    className={classNames(
                      classes.justifybetween,
                      classes.alignItemsCenter
                    )}
                  >
                    <CloseIcon
                      className={classes.cursorpointer}
                      onClick={() => {
                        setAddEditModal({ visible: false, mode: "", data: {} });
                        setFormInitialData(InitialData);
                      }}
                    />
                    <span className={classes.marginleft}>
                      {AddEditModal.mode === "add"
                        ? "ADD"
                        : AddEditModal.mode === "edit"
                        ? "EDIT"
                        : "VIEW"}{" "}
                      EVIDENCE TYPE
                    </span>
                  </div>
                  {AddEditModal.mode === "view" && (
                    <Button
                      type="button"
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        setAddEditModal((prev) => ({
                          ...prev,
                          mode: "edit",
                        }));
                      }}
                      startIcon={<EditIcon />}
                    >
                      edit
                    </Button>
                  )}
                  {AddEditModal.mode === "edit" && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="medium"
                      startIcon={<SaveIcon />}
                    >
                      save
                    </Button>
                  )}
                </div>
              </DialogTitle>
              <Box style={{ paddingLeft: "20px" }}>
                <Grid
                  className={classNames(
                    classes.regulatorstatusWrapper,
                    classes.AddressGrid
                  )}
                  style={{ marginTop: "20px" }}
                >
                  <div
                    className={classNames(
                      classes.justifybetween,
                      classes.alignItemsCenter
                    )}
                  >
                    <div>
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={values.ACTIVE}
                        onClick={() => setFieldValue("ACTIVE", !values.ACTIVE)}
                        disabled={AddEditModal.mode === "view"}
                      />
                      <span style={{ color: values.ACTIVE ? "#33cf4d" : "" }}>
                        {values.ACTIVE ? "ACTIVE" : "INACTIVE"}
                      </span>
                    </div>

                    {AddEditModal.mode !== "add" && (
                      <Typography
                        className={classes.filedTitle}
                        style={{ marginRight: 30 }}
                      >
                        {langConstant.EVIDENCE_TYPE_ID} :{" "}
                        {AddEditModal.data.EVIDENCE_TYPE_ID}
                      </Typography>
                    )}
                  </div>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.EVIDENCE_SHORT_NAME}
                    </Typography>

                    <Field name="EVIDENCE_SHORTNAME">
                      {({ field: { value }, form: { errors } }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={value}
                          disabled={AddEditModal.mode === "view"}
                          className={classes.width100}
                          onChange={(e) =>
                            setFieldValue("EVIDENCE_SHORTNAME", e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage name="EVIDENCE_SHORTNAME">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.EVIDENCE_DESCRIPTION}
                    </Typography>

                    <Field name="EVIDENCE_DESCRIPTION">
                      {({ field: { value } }) => (
                        <TextField
                          multiline
                          rowsMax={4}
                          value={value}
                          disabled={AddEditModal.mode === "view"}
                          className={classes.width100}
                          onChange={(e) =>
                            setFieldValue(
                              "EVIDENCE_DESCRIPTION",
                              e.target.value
                            )
                          }
                          variant="outlined"
                        />
                      )}
                    </Field>
                    <ErrorMessage name="EVIDENCE_DESCRIPTION">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.EVIDENCE_SOURCE}
                    </Typography>

                    <Field name="EVIDENCE_SOURCE">
                      {({ field: { value } }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={value}
                          disabled={AddEditModal.mode === "view"}
                          className={classes.width100}
                          onChange={(e) =>
                            setFieldValue("EVIDENCE_SOURCE", e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage name="EVIDENCE_SOURCE">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.OWNER}
                    </Typography>

                    <Field name="OWNER">
                      {({ field: { value } }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={value}
                          disabled={AddEditModal.mode === "view"}
                          className={classes.width100}
                          onChange={(e) =>
                            setFieldValue("OWNER", e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage name="OWNER">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.CLASSIFICATION}
                    </Typography>
                    <Field name="CLASSIFICATION">
                      {({ field: { value } }) => (
                        <Select
                          placeholder="Select"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          options={classyOptions}
                          value={
                            classyOptions.find(
                              (classification) => classification.label === value
                            ) || value
                          }
                          onChange={(classification) =>
                            setFieldValue(
                              "CLASSIFICATION",
                              classification.label
                            )
                          }
                          isDisabled={AddEditModal.mode === "view"}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="CLASSIFICATION">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.REGULATORY_DOMAIN}
                    </Typography>
                    <Field name="REGULATORY_DOMAIN">
                      {({ field: { value } }) => (
                        <Select
                          placeholder="Select"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          options={domainoptions}
                          value={
                            domainoptions.find(
                              (domain) => domain.value === value
                            ) || value
                          }
                          onChange={(domain) =>
                            setFieldValue("REGULATORY_DOMAIN", domain.value)
                          }
                          isDisabled={AddEditModal.mode === "view"}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="REGULATORY_DOMAIN">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.EXPIRY_PERIODICITY}
                    </Typography>
                    <Field name="EXPIRY_PERIODICITY">
                      {({ field: { value } }) => (
                        <Select
                          placeholder="Select"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          options={expiryPeriodicityOptions}
                          value={expiryPeriodicityOptions.find(
                            (period) => period.value === value
                          )}
                          onChange={(period) =>
                            setFieldValue("EXPIRY_PERIODICITY", period.value)
                          }
                          isDisabled={AddEditModal.mode === "view"}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="EXPIRY_PERIODICITY">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.KEYWORD_DICTIONARY}
                    </Typography>
                    <Field name="EVIDENCE_KEYWORDS" key={keyState}>
                      {({ field: { value } }) => (
                        <Tooltip
                          placement="left"
                          title={
                            AddEditModal.mode === "view" ? (
                              ""
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <InfoIcon />
                                </div>
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginLeft: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  Type Keyword and Press Enter
                                </div>
                              </div>
                            )
                          }
                        >
                          <ChipInput
                            className={classes.chipInput}
                            disableUnderline={true}
                            defaultValue={value}
                            onChange={(chips) => {
                              setFieldValue("EVIDENCE_KEYWORDS", chips);
                            }}
                            disabled={AddEditModal.mode === "view"}
                          />
                        </Tooltip>
                      )}
                    </Field>

                    <ErrorMessage name="EVIDENCE_KEYWORDS">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                {AddEditModal.mode === "add" && (
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="medium"
                      startIcon={<SaveIcon />}
                    >
                      Add
                    </Button>
                  </Grid>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </Drawer>
  );
};

AddEditForm.propTypes = {
  AddEditModal: PropTypes.object,
  setAddEditModal: PropTypes.func,
  domainOptions: PropTypes.array,
  classificationOptions: PropTypes.array,
  userID: PropTypes.string,
  setevidenceList: PropTypes.func,
  evidenceList: PropTypes.array,
};

export default AddEditForm;
