/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import langConstant from "_lang";
import rootStyles from "../../../../../rootStyles";
import { controlGroupsListActions } from "../../../../../_actions";
import { replaceAll } from "_helpers/utils";
const SelectedQuestionForMapping = (props) => {
  const rootClasses = rootStyles();

  const initialControlMappingObj = { control: "", dataPoints: "" };
  const controlGroupsList = useSelector((state) => state.controlGroupsList);

  const dispatch = useDispatch();
  const [controlMappingArray, setControlMappingArray] = useState([
    initialControlMappingObj,
  ]);
  const [narratives, setNarratives] = useState("");
  const [controlGroupsListState, setControlGroupsListState] = useState({
    control: [],
    dataPoints: [],
  });
  const [addMoreClicked, setAddMoreClicked] = useState(false);

  useEffect(() => {
    dispatch(controlGroupsListActions.getControlGroupsList());
  }, []);
  useEffect(() => {
    setControlGroupsListState(controlGroupsList);
  }, [controlGroupsList]);

  useEffect(() => {
    const controlData =
      props.metaData && props.metaData.mappedControl.length > 0
        ? props.metaData.mappedControl
        : [initialControlMappingObj];
    setControlMappingArray(controlData);
    const remaining = [...controlData];
    remaining.pop();
    updateControlGroupsLocalState(remaining);
    setNarratives(
      props.metaData && props.metaData.narratives
        ? props.metaData.narratives
        : ""
    );
  }, [props.metaData]);

  const updateSelection = (key, e, index) => {
    const newArray = controlMappingArray.map((item, itemIndex) => {
      if (index == itemIndex) {
        if (key == "control") {
          const controlItem = controlGroupsList[key].filter((item) => {
            return item.CISO_CONTROL_NAME == e.target.value;
          })[0];

          const CISO_CONTROL_ID = controlItem
            ? controlItem.CISO_CONTROL_ID
            : "";

          return {
            ...item,
            [key]: e.target.value,
            control_id: CISO_CONTROL_ID,
          };
        }

        if (key == "dataPoints") {
          const dataPointsItem = controlGroupsList[key].filter((item) => {
            return item.DATA_ASSET_ATTRIBUTE_NAME == e.target.value;
          })[0];

          const ENTITY_NAME = dataPointsItem
            ? dataPointsItem.DATA_ASSET_ENTITY_NAME
            : "";
          const SYSTEM_ID = dataPointsItem
            ? dataPointsItem.SOURCE_SYSTEM_ID
            : "";

          return {
            ...item,
            [key]: e.target.value,
            entity_name: ENTITY_NAME,
            system_id: SYSTEM_ID,
          };
        }
      }
      return { ...item };
    });
    setControlMappingArray(newArray);

    if (key == "dataPoints") {
      const newNarratives = narratives.concat("<" + e.target.value + ">");
      setNarratives(newNarratives);
      updateMetaData({ narratives: newNarratives, mappedControl: newArray });
    } else {
      updateMetaData({ narratives, mappedControl: newArray });
    }
  };
  const deleteControlMapping = (index, obj) => {
    const newArray = controlMappingArray.filter((item, itemIndex) => {
      return index != itemIndex;
    });
    const newNarratives = replaceAll(
      narratives,
      "<" + obj.dataPoints + ">",
      ""
    );

    setNarratives(newNarratives);
    setControlMappingArray(newArray);
    const popArray = [...newArray];
    popArray.pop();
    updateControlGroupsLocalState(popArray);
    props.updateMetaData({
      narratives: newNarratives,
      mappedControl: newArray,
    });
  };
  const addMoreMetaData = () => {
    const blankValue = controlMappingArray.filter((item) => {
      const empty = Object.entries(item).filter((k, v) => k[1] == "");
      return empty.length > 0;
    });
    if (blankValue.length == 0) {
      setControlMappingArray([
        ...controlMappingArray,
        initialControlMappingObj,
      ]);
      updateControlGroupsLocalState(controlMappingArray);
      setAddMoreClicked(false);
    } else {
      setAddMoreClicked(true);
    }
  };

  const updateControlGroupsLocalState = (array) => {
    if (array.length > 0) {
      const control = controlGroupsList.control.filter((item) => {
        return !_.find(array, { control: item.CISO_CONTROL_NAME });
      });
      const dataPoints = controlGroupsList.dataPoints.filter((item) => {
        return !_.find(array, { dataPoints: item.DATA_ASSET_ATTRIBUTE_NAME });
      });
      setControlGroupsListState({ control: control, dataPoints: dataPoints });
    } else {
      setControlGroupsListState(controlGroupsList);
    }
  };
  const updateMetaData = ({ narratives, mappedControl }) => {
    props.updateMetaData({ narratives, mappedControl });
  };
  return (
    <>
      <Box style={{ maxWidth: 600 }} my={3}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <strong>METADATA</strong>
          </Grid>
          <Grid item xs={6}>
            <span
              onClick={() => {
                addMoreMetaData();
              }}
              className="linkIcon"
            >
              <AddCircleIcon style={{ marginRight: "5px" }} /> ADD MORE METADATA
            </span>
          </Grid>
        </Grid>
        {controlMappingArray.map((item, index) => {
          return (
            <Grid container spacing={1} key={index}>
              <Grid item xs={5}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  select
                  id="controls"
                  label=""
                  name="controls"
                  disabled={index < controlMappingArray.length - 1}
                  SelectProps={{
                    style: { fontSize: 14 },
                    displayEmpty: true,
                  }}
                  value={item.control}
                  onChange={(e) => {
                    updateSelection("control", e, index);
                  }}
                  error={item.control == "" && addMoreClicked}
                  helperText={
                    item.control == "" &&
                    addMoreClicked &&
                    langConstant.ERROR_CONTROLS_IS_REQUIRED
                  }
                >
                  <MenuItem key={1} value="">
                    {langConstant.SELECT_CONTROLS}
                  </MenuItem>
                  {(index < controlMappingArray.length - 1
                    ? controlGroupsList && controlGroupsList.control
                    : controlGroupsListState && controlGroupsListState.control
                  ).map((option) => (
                    <MenuItem
                      key={option.CISO_CONTROL_NAME}
                      value={option.CISO_CONTROL_NAME}
                    >
                      {option.CISO_CONTROL_NAME}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  select
                  id="dataPoints"
                  label=""
                  name="dataPoints"
                  disabled={index < controlMappingArray.length - 1}
                  SelectProps={{
                    displayEmpty: true,
                    style: { fontSize: 14 },
                  }}
                  value={item.dataPoints}
                  onChange={(e) => {
                    updateSelection("dataPoints", e, index);
                  }}
                  error={item.dataPoints == "" && addMoreClicked}
                  helperText={
                    item.dataPoints == "" &&
                    addMoreClicked &&
                    langConstant.ERROR_DATAPOINTS_IS_REQUIRED
                  }
                >
                  <MenuItem key={1} value="">
                    {langConstant.SELECT_DATAPOINTS}
                  </MenuItem>
                  {(index < controlMappingArray.length - 1
                    ? controlGroupsList && controlGroupsList.dataPoints
                    : controlGroupsListState &&
                      controlGroupsListState.dataPoints
                  ).map((option) => (
                    <MenuItem
                      key={option.DATA_ASSET_ATTRIBUTE_NAME}
                      value={option.DATA_ASSET_ATTRIBUTE_NAME}
                    >
                      {option.DATA_ASSET_ATTRIBUTE_NAME}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                {index >= 0 && (
                  <IconButton
                    className={rootClasses.redText}
                    style={{ marginTop: "-5px" }}
                    color="secondary"
                    size="medium"
                    aria-label="delete"
                    onClick={() => {
                      deleteControlMapping(index, item);
                      //dispatch(confirmationDialogActions.open({ message: 'Are you sure you want to remove the question?', onConfirm: () => { props.deselectQuestion(item.id, false) } }))
                    }}
                  >
                    <DeleteIcon fontSize="inherit" color="inherit" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Box>
      <p>
        <strong>ADD NARRATIVES</strong>
      </p>
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        id="searchText"
        label=""
        name="searchText"
        multiline
        placeholder=""
        value={narratives}
        rows={4}
        rowsMax={Infinity}
        onChange={(e) => setNarratives(e.target.value)}
        onBlur={() => {
          updateMetaData({ narratives, mappedControl: controlMappingArray });
        }}
      />
    </>
  );
};
SelectedQuestionForMapping.propTypes = {
  metaData: PropTypes.object,
  updateMetaData: PropTypes.func,
};
export default SelectedQuestionForMapping;
