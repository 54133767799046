import React, { useEffect, useState } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
import AdminUtils from "./utils";
import { Field, Form, Formik } from "formik";
import useStyles from "./styles";
import FormTextField from "_components/FormTextField";
import langConstant from "_lang";
import FormDateField from "_components/FormDateField";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { countriesListActions } from "_actions";
import CloseIcon from "@mui/icons-material/Close";
import AdminServices from "../../services";
import TeamService from "./services";

const TeamForm = ({
  show,
  handleClose,
  selectedTeam,
  teamsData,
  setTeamsData,
  selectedCustomer,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countriesList);

  const [countryOptions, setCountryOptions] = useState([]);
  const [legalEntityData, setLegalEntityData] = useState([]);

  const initialValues = selectedTeam
    ? selectedTeam
    : AdminUtils.teamInitialData;

  useEffect(() => {
    dispatch(
      AdminServices.getCustomerLegalEntitiesForAdmin(
        selectedCustomer.value,
        null,
        setLegalEntityData
      )
    );
  }, []);

  useEffect(() => {
    if (countries.length == 0) {
      dispatch(countriesListActions.getCountriesList());
    } else {
      const tempOptions = [];
      countries.forEach((element) => {
        tempOptions.push({
          value: element.COUNTRY_CODE,
          label: element.COUNTRY_NAME,
        });
      });
      setCountryOptions(tempOptions);
    }
  }, [countries]);

  const onSubmit = (values) => {
    dispatch(
      TeamService.putTeamByAdmin(values, teamsData, setTeamsData, handleClose)
    );
  };

  return (
    <Drawer anchor={"right"} open={show} onClose={handleClose}>
      <Grid style={{ width: "450px", padding: "30px 15px" }}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "5px",
            borderBottom: "2px solid black",
            marginBottom: "5px",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {initialValues.TEAM_ID ? "EDIT TEAM" : "ADD TEAM"}
          </Typography>
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </Grid>
        <Grid>
          <Formik
            initialValues={initialValues}
            validationSchema={AdminUtils.teamValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange={false}
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <Grid container>
                  <Grid
                    className={classes.formFieldWrapper}
                    item
                    md={12}
                    xs={12}
                  >
                    <FormTextField
                      fieldLabel={"Team Name"}
                      value={values.TEAM_NAME}
                      type="text"
                      setFieldValue={setFieldValue}
                      fieldName="TEAM_NAME"
                      errors={errors}
                      display={"block"}
                      labelClass={classes.formLabel}
                    />
                  </Grid>
                  <Grid
                    className={classes.formFieldWrapper}
                    item
                    md={12}
                    xs={12}
                  >
                    <FormTextField
                      fieldLabel={"EMAIL"}
                      value={values.EMAIL}
                      type="email"
                      setFieldValue={setFieldValue}
                      fieldName="EMAIL"
                      errors={errors}
                      display={"block"}
                      labelClass={classes.formLabel}
                    />
                  </Grid>
                  <Grid
                    className={classes.formFieldWrapper}
                    item
                    md={12}
                    xs={12}
                  >
                    <FormTextField
                      fieldLabel={"DEPARTMENT NAME"}
                      value={values.DEPARTMENT_NAME}
                      type="text"
                      setFieldValue={setFieldValue}
                      fieldName="DEPARTMENT_NAME"
                      errors={errors}
                      display={"block"}
                      labelClass={classes.formLabel}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.formFieldWrapper}
                  >
                    <Typography className={classes.formLabel}>
                      {langConstant.PARENT_TEAM_NAME}
                    </Typography>
                    <Field name="TEAM_PARENT_ID">
                      {({ field: { value } }) => (
                        <Select
                          className={classes.select100Width}
                          value={teamsData.find((team) => team.value == value)}
                          options={
                            values["TEAM_ID"]
                              ? teamsData.filter(
                                  (team) => team.value != values["TEAM_ID"]
                                )
                              : teamsData
                          }
                          onChange={(team) =>
                            setFieldValue("TEAM_PARENT_ID", team.value)
                          }
                          placeholder={langConstant.SELECT_PARENT_TEAM}
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Field>
                    {errors && errors["TEAM_PARENT_ID"] && (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        {errors["TEAM_PARENT_ID"]}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    className={classes.formFieldWrapper}
                    item
                    md={12}
                    xs={12}
                  >
                    <FormTextField
                      fieldLabel={"CITY"}
                      value={values.CITY}
                      type="text"
                      setFieldValue={setFieldValue}
                      fieldName="CITY"
                      errors={errors}
                      display={"block"}
                      labelClass={classes.formLabel}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.formFieldWrapper}
                  >
                    <Typography className={classes.formLabel}>
                      {langConstant.COUNTRY}
                    </Typography>
                    <Field name="COUNTRY_CODE">
                      {({ field: { value } }) => (
                        <Select
                          className={classes.select100Width}
                          value={countryOptions.find(
                            (country) => country.value == value
                          )}
                          options={countryOptions}
                          onChange={(country) =>
                            setFieldValue("COUNTRY_CODE", country.value)
                          }
                          placeholder={langConstant.SELECT_COUNTRY}
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Field>
                    {errors && errors["COUNTRY_CODE"] && (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        {errors["COUNTRY_CODE"]}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.formFieldWrapper}
                  >
                    <Typography className={classes.formLabel}>
                      {langConstant.LEGAL_ENTITY}
                    </Typography>
                    <Field name="LEGAL_ENTITY_ID">
                      {({ field: { value } }) => (
                        <Select
                          className={classes.select100Width}
                          value={legalEntityData.find(
                            (entity) => entity.value == value
                          )}
                          options={legalEntityData}
                          onChange={(country) =>
                            setFieldValue("LEGAL_ENTITY_ID", country.value)
                          }
                          placeholder={langConstant.SELECT_LEGAL_ENTITY}
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Field>
                    {errors && errors["LEGAL_ENTITY_ID"] && (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        {errors["LEGAL_ENTITY_ID"]}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    className={classes.formFieldWrapper}
                    item
                    md={12}
                    xs={12}
                  >
                    <FormTextField
                      fieldLabel={"ANNUAL BUDGET"}
                      value={values.ANNUAL_BUDGET}
                      type="number"
                      setFieldValue={setFieldValue}
                      fieldName="ANNUAL_BUDGET"
                      errors={errors}
                      labelClass={classes.formLabel}
                    />
                  </Grid>
                  <Grid
                    className={classes.formFieldWrapper}
                    item
                    md={12}
                    xs={12}
                  >
                    <Typography className={classes.formLabel}>
                      {langConstant.ACTIVE}
                    </Typography>
                    <Field style={{ width: "100%" }} name="ACTIVE">
                      {() => (
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={JSON.parse(values["ACTIVE"])}
                          onChange={(e) =>
                            setFieldValue("ACTIVE", e.target.value)
                          }
                          style={{
                            display: "inline",
                          }}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="YES"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.formFieldWrapper}
                  >
                    <Typography className={classes.formLabel}>
                      {langConstant.START_DATE}
                    </Typography>
                    <FormDateField
                      value={values.START_DATE}
                      setFieldValue={setFieldValue}
                      fieldName="START_DATE"
                      errors={errors}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.formFieldWrapper}
                  >
                    <Typography className={classes.formLabel}>
                      {langConstant.END_DATE}
                    </Typography>
                    <FormDateField
                      value={values.END_DATE}
                      setFieldValue={setFieldValue}
                      fieldName="END_DATE"
                      errors={errors}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ textAlign: "right" }}>
                  <Button type="submit" variant="contained" color="primary">
                    {values.TEAM_ID
                      ? langConstant.UPDATE_BUTTON
                      : langConstant.CREATE_BUTTON}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Drawer>
  );
};

TeamForm.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedTeam: PropTypes.func,
  teamsData: PropTypes.array,
  setTeamsData: PropTypes.func,
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default TeamForm;
