import { Box, Tooltip } from "@material-ui/core";
import React from "react";
import langConstant from "_lang";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const rowData = [
    {
        header: langConstant.POLICY_NAME, 
        value: "POLICY_NAME", 
        editAvailable: true, 
        type: "input", 
        key: "POLICY_NAME", 
        isRequired: true,
        requiredMessage: "Name is Required"
    },
    {
        header: "Extraction Status", 
        value: "EXTRACTION_STATUS", 
        format: function formatData(value) {
            return <Box>
                {value.Extraction_Status === "success" ? 
                    <Tooltip title={langConstant.SUCCESS} placement="top"><CheckCircleIcon color='success'/></Tooltip> : 
                    <Tooltip title={value.Extraction_Status === 'unsuccessful' ? langConstant.FAILED : langConstant.INCOMPLETE} placement="top">
                        <CancelIcon sx={{ color: value.Extraction_Status === 'unsuccessful' ? '#c13636' : '#d48d31' }}/>
                    </Tooltip>
                }
            </Box> 
        },
        editAvailable: false,
    },
    {header: langConstant.POLICY_LANGUAGE, value: "NATIONAL_LANGUAGE", editAvailable: false, isRequired: true},
    {header: langConstant.DOCUMENT_OWNER, value: "DOCUMENT_OWNER_DATA", editAvailable: true, type: "select", key: "DOCUMENT_OWNER", isMulti: false, isRequired: false},
    {header: langConstant.DOCUMENT_TYPES, value: "DOCUMENT_TYPE_DATA", editAvailable: true, type: "select", key: "DOCUMENT_TYPE", isMulti: true, isRequired: false},
    {header: langConstant.DOCUMENT_TAGS, value: "DOCUMENT_TAGS", editAvailable: true, type: "input", key: "DOCUMENT_TAGS", isRequired: false}
]
