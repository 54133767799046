// OP#2883 - UJA11 & UJA12 - UI Development : Manage Regulators & Regulations List
// services for Regulators

import React from "react";
import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import langConstant from "_lang";
import moment from "moment";

const getSuppliersAndSpocsList = (userID, setSupplierList) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetSuppliersAndSpocs", {
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());

          if (response && response["#result-set-1"]) {
            setSupplierList(response["#result-set-1"]);
          } else {
            dispatch(alertActions.error(langConstant.SUPPLIERS_LIST_NOT_FOUND));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const putNewSupplier = (
  data,
  customerId,
  setInitialContactDetails,
  setNewSupplier,
  rows,
  setSupplierList,
  setAnchorMenuEl
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutNewSupplier", {
        i_CUSTOMER_ID: customerId,
        i_SUPPLIER_ID: data.SUPPLIER_ID,
        i_SUPPLIER_NAME: data.SUPPLIER_NAME,
        i_Supp_CUSTOMER_ID: data.Supplier_Customer_ID
          ? data.Supplier_Customer_ID
          : null,
        i_SUPPLIER_RISK_RATING: data.SUPPLIER_RISK_RATING,
        i_SUPPLIER_PRODUCT_SERVICE_PROVIDED:
          data.SUPPLIER_PRODUCT_SERVICE_PROVIDED,
        i_SUPPLIER_CATEGORY: data.SUPPLIER_CATEGORY,
        i_SUPPLIER_CONTRACT_DURATION: data.SUPPLIER_CONTRACT_DURATION,
        i_CONTRACT_STATUS: data.CONTRACT_STATUS,
        i_SUPPLIER_ADDRESS: data.SUPPLIER_ADDRESS,
        i_COUNTRY_CODE: data.COUNTRY_CODE,
        i_RESPONSIBLE_DEPARTMENT: data.RESPONSIBLE_DEPARTMENT,
        i_START_DATE: data.START_DATE
          ? data.START_DATE
          : moment().format("YYYY-MM-DD HH:mm:ss"),
        i_END_DATE: data.END_DATE ? data.END_DATE : null,
        i_ACTIVE: data.ACTIVE ? 1 : 0,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (
            response["#result-set-1"] &&
            response["#result-set-1"].length > 0
          ) {
            if (data.SUPPLIER_ID == null) {
              setAnchorMenuEl(null);

              dispatch(
                alertActions.success(
                  langConstant.NEW_SUPPLIER_SUCCESSFULLY_ADDED
                )
              );

              setInitialContactDetails((prevState) => ({
                ...prevState,
                SUPPLIER_ID: response["#result-set-1"][0]["UNID"],
              }));
              setNewSupplier(response["#result-set-1"][0]);

              const tempList = [...rows];
              tempList.push(response["#result-set-1"][0]);
              setSupplierList(tempList);
            } else {
              setAnchorMenuEl(null);

              dispatch(
                alertActions.success(langConstant.SUPPLIER_SUCCESSFULLY_UPDATED)
              );
              const tempSupplierList = [];
              rows.forEach((item) => {
                if (item.SUPPLIER_ID == data.SUPPLIER_ID) {
                  const tempSupplier = {
                    ...item,
                    SUPPLIER_NAME: data.SUPPLIER_NAME,
                    SUPPLIER_ADDRESS: data.SUPPLIER_ADDRESS,
                    SUPPLIER_RISK_RATING: data.SUPPLIER_RISK_RATING,
                    SUPPLIER_CATEGORY: data.SUPPLIER_CATEGORY,
                    SUPPLIER_CONTRACT_DURATION: data.SUPPLIER_CONTRACT_DURATION,
                    SUPPLIER_PRODUCT_SERVICE_PROVIDED:
                      data.SUPPLIER_PRODUCT_SERVICE_PROVIDED,
                    RESPONSIBLE_DEPARTMENT: data.RESPONSIBLE_DEPARTMENT,
                    CONTRACT_STATUS: data.CONTRACT_STATUS,
                    COUNTRY_CODE: data.COUNTRY_CODE,
                    ACTIVE: data.ACTIVE ? 1 : 0,
                  };
                  tempSupplierList.push(tempSupplier);
                } else {
                  tempSupplierList.push(item);
                }
              });
              setSupplierList(tempSupplierList);
            }
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const putNewSupplierSPOC = (
  data,
  rows,
  newSupplier,
  setNewSupplier,
  setPersonDataForPlatformLogin,
  keyPersonData,
  setKeyPersonData,
  setSupplierList
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutNewSupplierSPOC", {
        i_USER_ID: data.USER_ID ? data.USER_ID : null,
        i_SUPPLIER_ID: data.SUPPLIER_ID,
        i_CONTACT_NAME: data.CONTACT_NAME.trim().replace(/\s+/g, " "),
        i_SUPPLIER_CONTACT_ID: data.SUPPLIER_CONTACT_ID,
        i_SPOC_EMAIL: data.EMAIL_ADDRESS,
        i_SPOC_DEPARTMENT: data.DEPARTMENT,
        i_START_DATE: data.START_DATE
          ? data.START_DATE
          : moment().format("YYYY-MM-DD HH:mm:ss"),
        i_END_DATE: data.END_DATE ? data.END_DATE : null,
        i_ROLE_ID: data.ROLE,
        i_PHONE_NUMBER: data.PHONE_NUMBER,

        //  i_MODIFIED_DATE: data.MODIFIED_DATE,
        i_ACTIVE: data.USER_ACTIVE ? data.USER_ACTIVE : 1,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            if (data.SUPPLIER_CONTACT_ID == null) {
              dispatch(
                alertActions.success(
                  langConstant.SUPPLIER_CONTACT_SUCCESSFULLY_ADDED
                )
              );

              const item = response["#result-set-1"][0];
              const tempKeyPersons = [...keyPersonData];

              const newContact = {
                SUPPLIER_ID: item.SUPPLIER_ID?.toString().trim(),
                CONTACT_NAME: `${item.SPOC_FIRSTNAME?.toString().trim()}   ${item.SPOC_LASTNAME?.toString().trim()}`,
                ROLE: item.ROLE_ID?.toString().trim(),
                ROLE_NAME: item.ROLE_NAME?.toString().trim(),
                EMAIL_ADDRESS: item.SPOC_EMAIL?.toString().trim(),
                DEPARTMENT: item.SPOC_DEPARTMENT?.toString().trim(),
                PHONE_NUMBER: item.PHONE_NUMBER?.toString().trim(),
                USER_ACTIVE: item.USER_ACTIVE?.toString().trim(),
                SUPPLIER_CONTACT_ID:
                  item.SUPPLIER_CONTACT_ID?.toString().trim(),
                USER_ID: item.USER_ID?.toString().trim(),
                START_DATE: item.START_DATE
                  ? item.START_DATE.toString().trim()
                  : moment().format("YYYY-MM-DD HH:mm:ss"),
                END_DATE: item.END_DATE?.toString().trim(),
              };
              tempKeyPersons.push(newContact);

              setKeyPersonData(tempKeyPersons);
            } else {
              const item = response["#result-set-1"][0];
              const tempKeyPersons = [];

              keyPersonData.forEach((contact) => {
                if (contact.SUPPLIER_CONTACT_ID == data.SUPPLIER_CONTACT_ID) {
                  const updatedContact = {
                    SUPPLIER_ID: item.SUPPLIER_ID?.toString().trim(),
                    CONTACT_NAME: `${item.SPOC_FIRSTNAME?.toString().trim()}   ${item.SPOC_LASTNAME?.toString().trim()}`,
                    ROLE: item.ROLE_ID?.toString().trim(),
                    ROLE_NAME: item.ROLE_NAME?.toString().trim(),
                    EMAIL_ADDRESS: item.SPOC_EMAIL?.toString().trim(),
                    DEPARTMENT: item.SPOC_DEPARTMENT?.toString().trim(),
                    PHONE_NUMBER: item.PHONE_NUMBER?.toString().trim(),
                    USER_ACTIVE: item.USER_ACTIVE?.toString().trim(),
                    SUPPLIER_CONTACT_ID:
                      item.SUPPLIER_CONTACT_ID?.toString().trim(),
                    USER_ID: item.USER_ID?.toString().trim(),
                    START_DATE: item.START_DATE?.toString().trim(),
                    END_DATE: item.END_DATE?.toString().trim(),
                  };
                  tempKeyPersons.push(updatedContact);
                } else {
                  tempKeyPersons.push(contact);
                }
              });

              setKeyPersonData(tempKeyPersons);

              dispatch(
                alertActions.success(
                  langConstant.SUPPLIER_CONTACT_SUCCESSFULLY_UPDATED
                )
              );
            }

            setPersonDataForPlatformLogin((prevState) => ({
              ...prevState,
              SUPPLIER_CONTACT_ID:
                response["#result-set-1"][0]["SUPPLIER_CONTACT_ID"],
            }));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getRegulatorKeyPersons = (regulatorId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRegulatorKeyPersons", {
      i_REGULATOR_ID: regulatorId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getCustomerLegalEntities = (
  customerID,
  userID = null,
  setLegalEntityData
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerLegalEntities", {
        i_CUSTOMER_ID: customerID,
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setLegalEntityData(
            response["#result-set-1"].map((item) => {
              return {
                value: item.LEGAL_ENTITY_ID,
                label: item.LEGAL_ENTITY_NAME,
                ...item,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getSupplierSPOCUserDetails = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetSupplierSPOCUserDetails", {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putSupplierTeam = (data, teamOptions, setTeamOptions) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutSupplierTeam", {
        i_TEAM_NAME: data.TEAM_NAME,
        i_LEGAL_ENTITY_ID: data.LEGAL_ENTITY_ID,
        i_CITY: data.CITY,
        i_COUNTRY_CODE: data.COUNTRY_CODE,
        i_START_DATE: moment().format("YYYY-MM-DD HH:mm:ss"),
        i_ACTIVE: 1,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(
            alertActions.success(langConstant.NEW_TEAM_ADDED_SUCCESSFULLY)
          );
          if (response["#result-set-1"]) {
            const tempTeamList = [...teamOptions];
            tempTeamList.push({
              value: response["#result-set-1"][0]["TEAM_ID"],
              label: response["#result-set-1"][0]["TEAM_NAME"],
            });
            setTeamOptions(tempTeamList);
          }
        },
        (error) => {
          dispatch(
            alertActions.error(error?.toString() || "Something went wrong")
          );
        }
      );
  };
};
const getCheckUsername = (customerId, userName) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCheckUsername", {
      i_CUSTOMER_ID: customerId,
      i_USER_NAME: userName,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          if (
            response["#result-set-1"] &&
            response["#result-set-1"].length > 0
          ) {
            if (response["#result-set-1"][0]["RESULT"] == "FALSE") {
              return {
                userAllowed: true,
                serviceSuccess: true,
                status: 200,
              };
            } else {
              return {
                userAllowed: false,
                serviceSuccess: true,
                status: 200,
              };
            }
          } else {
            return {
              userAllowed: false,
              serviceSuccess: true,
              status: 200,
            };
          }
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putNewSupplierUser = (userData) => {
  const nameParts = userData.CONTACT_NAME.split(" ");
  const lastname = nameParts.pop();
  const firstname = nameParts.join(" ");
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutNewSupplierUser", {
        i_USERNAME: userData.USERNAME,
        i_PASSWORD: userData.PASSWORD ? userData.PASSWORD : null,
        i_FIRST_NAME: firstname.trim(),
        i_LAST_NAME: lastname.trim(),
        i_EMAIL_ADDRESS: userData.EMAIL_ADDRESS,
        i_PHONE_NUMBER: userData.PHONE_NUMBER,
        i_COUNTRY_CODE: userData.COUNTRY_CODE,
        i_CUSTOMER_ID: userData.CUSTOMER_ID,
        i_ENTITLEMENT_ID: userData.ENTITLEMENT,
        i_ROLE_ID: userData.ROLE,
        i_TEAM_ID: userData.TEAM,
        i_DATA_CLASSIFICATION_ID: userData.DATA_CLASSIFICATION,
        i_SUPPLIER_CONTACT_ID: userData.SUPPLIER_CONTACT_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            dispatch(
              alertActions.success(
                langConstant.PLATFORM_LOGIN_CREATED_SUCCESSFULLY
              )
            );
            return;
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              alertActions.error(error?.toString() || "Something went wrong")
            );
          }
        }
      );
  };
};

export default {
  putNewSupplierSPOC,
  getSupplierSPOCUserDetails,
  getCheckUsername,
  getRegulatorKeyPersons,
  putNewSupplier,
  putNewSupplierUser,
  putSupplierTeam,
  getCustomerLegalEntities,
  getSuppliersAndSpocsList,
};
