import { Card, Grid, Typography, Box } from "@mui/material";
import React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import PropTypes from "prop-types";
import useStyles from "../Panel9/styles";
import langConstant from "_lang";
import { groupBy, map } from "lodash";
import SupplierUtils from "./utils";

const GraphView = ({ expandedPanelID, block, row1, row2 }) => {
  const classes = useStyles();

  const row1GroupByCategory = groupBy(row1, "SUPPLIER_CATEGORY");
  const result1 = map(row1GroupByCategory, (value, key) => ({
    name: key,
    value: value.length,
  }));

  const row2GroupByCategory = groupBy(row1, "CONTRACT_STATUS");
  const result2 = map(row2GroupByCategory, (value, key) => ({
    name: key,
    value: value.length,
  }));

  const row3GroupByCategory = groupBy(row2, "STATUS");
  const result3 = map(row3GroupByCategory, (value, key) => ({
    name: SupplierUtils.statusTitles[key],
    value: value.length,
  }));

  const colors = ["#ffc000", "#ed7d31", "#00b050", "#ff0000", "#800080"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontSize: "20px", fontWeight: "bold" }}
      >
        {value}
      </text>
    );
  };

  return (
    <Card
      id="elementToCapture"
      sx={{
        my: 1,
        backgroundColor: expandedPanelID == block.id ? "#F0F4F8" : "",
        border: "none",
        overflow: "auto",
        scrollbarWidth: "none",
        height: "100%",
        alignItems: "center",
        display: "flex",
        margin: "auto",
      }}
      variant="outlined"
    >
      {expandedPanelID == block.id ? (
        <Grid container spacing={2} gap={2} sx={{ height: "90%", ml: 2 }}>
          <Grid
            item
            xs={3.8}
            style={{
              backgroundColor: "#fff",
              borderRadius: "4px",
            }}
          >
            <Typography className={classes.chartLabel}>
              {langConstant.SUPPLIERS_BY_CATEGORY}
            </Typography>
            <ResponsiveContainer width="100%" height="100%" aspect={1}>
              <PieChart width={400} height={400}>
                <Legend
                  payload={result1.map((item, index) => ({
                    id: index,
                    type: "square",
                    value: `${item.name}`,
                    color: colors[index],
                  }))}
                />
                <Pie
                  data={result1}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                  label={renderCustomizedLabel}
                >
                  {result1.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid
            item
            xs={3.8}
            style={{
              backgroundColor: "#fff",
              borderRadius: "4px",
            }}
          >
            <Typography className={classes.chartLabel}>
              {langConstant.SUPPLIER_STATUS}
            </Typography>
            <ResponsiveContainer aspect={1}>
              <PieChart width={400} height={400}>
                <Legend
                  payload={result2.map((item, index) => ({
                    id: index,
                    type: "square",
                    value: `${item.name}`,
                    color: colors[index],
                  }))}
                />
                <Pie
                  data={result2}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                  label={renderCustomizedLabel}
                >
                  {result2.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid
            item
            xs={3.8}
            style={{
              backgroundColor: "#fff",
              borderRadius: "4px",
            }}
          >
            <Typography className={classes.chartLabel}>
              {langConstant.THIRD_PARTY_RISK_STATUS}
            </Typography>
            <ResponsiveContainer aspect={1}>
              <PieChart width={400} height={400}>
                <Legend
                  payload={result3.map((item, index) => ({
                    id: index,
                    type: "square",
                    value: `${item.name}`,
                    color: colors[index],
                  }))}
                />
                <Pie
                  data={result3}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                  label={renderCustomizedLabel}
                >
                  {result3.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      ) : (
        <Box
          style={{
            backgroundColor: "#fff",
            borderRadius: "4px",
          }}
          width="100%"
        >
          <Typography className={classes.chartLabel}>
            {langConstant.THIRD_PARTY_RISK_STATUS}
          </Typography>
          <ResponsiveContainer width="100%" height="100%" aspect={1}>
            <PieChart width={400} height={400}>
              <Legend
                payload={result3.map((item, index) => ({
                  id: index,
                  type: "square",
                  value: `${item.name}`,
                  color: colors[index],
                }))}
              />
              <Pie
                data={result3}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={renderCustomizedLabel}
              >
                {result3.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Card>
  );
};

GraphView.propTypes = {
  expandedPanelID: PropTypes.string,
  block: PropTypes.object,
  row1: PropTypes.array,
  row2: PropTypes.array,
};

export default GraphView;
