import { Box, Grid, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import Select from 'react-select';
import PropTypes from "prop-types";
import useStyles from "./styles";
import { padding } from "@mui/system";


const SplitterSummaryForm = ({dbNames, embeddingData, initialValues, setInitialValues, formRef}) => {

    const classes = useStyles();

    const validationSchema = Yup.object().shape({
        CHUNK_SIZE: Yup.number().min(500, "Minimum 500 is Required").max(5000, "Maximum 5000 is Required")
            .required("Chunk Size is Required"),
        CHUNK_OVERLAP: Yup.number().min(0, "Minimum 0 is Required").max(100, "Maximum 100 is Required")
            .required("Chunk Overlap is Required"),
        DATABASE: Yup.string()
            .required("Database is Required"),
        DATABASE_COLLECTION: Yup.string()
            .required("Database Collection is Required"),
        EMBEDDING: Yup.string()
            .required("Embedding is Required"),
    });


    return (
        <Box sx={{ paddingLeft : '20px' }}>
            <Formik
                validateOnChange={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                innerRef={formRef}
            >
                {({ setFieldValue, touched, errors }) => (
                    <Form  >
                        <Grid container>
                            <Grid item md={4} xs={12} >
                                <Grid sx={{ paddingRight : '20px', marginBottom: '20px' }}>
                                    <Typography sx={padding} >Chunk Size</Typography>
                                    <Field name="CHUNK_SIZE">
                                        {({ field: { value } }) => (
                                            <TextField
                                                variant="outlined"
                                                type="number"
                                                style={{ width: "100%" }}
                                                onChange={(e) => { 
                                                    setFieldValue("CHUNK_SIZE", e.target.value) 
                                                    setInitialValues((prevState) => ({...prevState, "CHUNK_SIZE": e.target.value}))
                                                }}
                                                InputProps={{classes: { input: classes.inputField }}}
                                                placeholder="Enter Chunk Size"
                                                value={value}
                                                error={touched.CHUNK_SIZE && Boolean(errors.CHUNK_SIZE)}
                                            />
                                        )}
                                    </Field>
                                    {errors && errors?.CHUNK_SIZE && <Typography className={classes.errorMessage}>{errors.CHUNK_SIZE}</Typography>}
                                </Grid>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Grid sx={{ marginBottom: '20px', paddingRight : '20px'}}>
                                    <Typography>Chunk Overlap</Typography>
                                    <Field name="CHUNK_OVERLAP">
                                        {({ field: { value } }) => (
                                            <TextField
                                                variant="outlined"
                                                type="number"
                                                style={{ width: "100%" }}
                                                onChange={(e) => { 
                                                    setFieldValue("CHUNK_OVERLAP", e.target.value) 
                                                    setInitialValues((prevState) => ({...prevState, "CHUNK_OVERLAP": e.target.value}))
                                                }}
                                                InputProps={{classes: { input: classes.inputField }}}
                                                placeholder="Enter Overlap"
                                                value={value}
                                                error={touched.CHUNK_OVERLAP && Boolean(errors.CHUNK_OVERLAP)}
                                            />
                                        )}
                                    </Field>
                                    {errors && errors?.CHUNK_OVERLAP && <Typography className={classes.errorMessage}>{errors.CHUNK_OVERLAP}</Typography>}
                                </Grid>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography>Embedding</Typography>
                                <Field name="EMBEDDING">
                                    {({ field: { value } }) => (
                                        <Select
                                            options={embeddingData}
                                            value={embeddingData.find((embedding) => embedding.value == value)}
                                            onChange={(embedding) => {
                                                setFieldValue("EMBEDDING", embedding.value)
                                                setInitialValues((prevState) => ({...prevState, "EMBEDDING": embedding.value}))
                                            }}
                                            menuPortalTarget={document.body}
                                        />
                                    )}
                                </Field>
                                {errors && errors?.EMBEDDING && <Typography className={classes.errorMessage}>{errors.EMBEDDING}</Typography>}
                            </Grid>
                            <Box item md={6} xs={12} sx={{ paddingRight : '20px' , width: '50%' , marginBottom: '20px' }}>
                                <Typography>Select Database</Typography>
                                <Field name="DATABASE">
                                    {({ field: { value } }) => (
                                        <Select
                                            options={dbNames}
                                            value={dbNames.find((db) => db.value == value)}
                                            onChange={(db) => {
                                                setFieldValue( "DATABASE", db.value)
                                                setInitialValues((prevState) => ({...prevState, "DATABASE": db.value}))
                                            }}
                                            menuPortalTarget={document.body}
                                        />
                                    )}
                                </Field>
                                {errors && errors?.DATABASE && <Typography className={classes.errorMessage}>{errors.DATABASE}</Typography>}
                            </Box>
                            <Box sx={{ marginBottom: '20px'}}> 
                                <Typography>Database Collection</Typography>
                                <Field name="DATABASE_COLLECTION">
                                    {({ field: { value } }) => (
                                        <TextField
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            onChange={(e) => { 
                                                setFieldValue("DATABASE_COLLECTION", e.target.value) 
                                                setInitialValues((prevState) => ({...prevState, "DATABASE_COLLECTION": e.target.value}))
                                            }}
                                            InputProps={{classes: { input: classes.inputField }}}
                                            placeholder="Enter Database Collection"
                                            value={value}
                                            error={touched.DATABASE_COLLECTION && Boolean(errors.DATABASE_COLLECTION)}
                                        />
                                    )}
                                </Field>
                                {errors && errors?.DATABASE_COLLECTION && <Typography className={classes.errorMessage}>{errors.DATABASE_COLLECTION}</Typography>}
                            </Box>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

SplitterSummaryForm.propTypes = {
    dbNames: PropTypes.array,
    embeddingData: PropTypes.array, 
    initialValues: PropTypes.object,
    setInitialValues: PropTypes.func,
    formRef: PropTypes.any,
}


export default SplitterSummaryForm;