import React from "react";
import langConstant from "_lang";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";

const usersColumns = [
  { field: "USER_ID", headerName: langConstant.ID },
  { field: "USER_NAME", headerName: langConstant.USERNAME },
  { field: "ROLE_NAME", headerName: langConstant.ROLE_NAME },
  { field: "EMAIL_ADDRESS", headerName: langConstant.EMAIL_ADDRESS },
  { field: "DEPARTMENT", headerName: langConstant.DEPARTMENT },
  {
    field: "2FA_ENABLED",
    headerName: langConstant.TWOFA_ENABLED,
    format: function formatData(value) {
      if (value["2FA_ENABLED"]) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
  { field: "2FA_TYPE", headerName: langConstant.TWOFA_TYPE },
  {
    field: "LOCATION",
    headerName: langConstant.COUNTRY,
    format: function formatData(value, country) {
      if (value["LOCATION"] && country && country.COUNTRY_NAME) {
        return country.COUNTRY_NAME;
      } else {
        return "No Data";
      }
    },
  },
];

const teamsColumns = [
  { field: "TEAM_ID", headerName: langConstant.ID },
  { field: "TEAM_NAME", headerName: langConstant.NAME },
  { field: "PARENT_TEAM_NAME", headerName: langConstant.PARENT_TEAM_NAME },
  { field: "DEPARTMENT_NAME", headerName: langConstant.DEPARTMENT },
  { field: "ANNUAL_BUDGET", headerName: langConstant.ANNUAL_BUDGET },
  { field: "CITY", headerName: langConstant.CITY },
  {
    field: "COUNTRY_CODE",
    headerName: langConstant.COUNTRY,
    format: function formatData(value, country) {
      if (value["COUNTRY_CODE"] && country && country.COUNTRY_NAME) {
        return country.COUNTRY_NAME;
      } else {
        return "No Data";
      }
    },
  },
  {
    field: "ACTIVE",
    headerName: langConstant.ACTIVE,
    format: function formatData(value) {
      if (value["ACTIVE"]) {
        return <TaskAltIcon color="success" />;
      } else {
        return <HighlightOffIcon color="error" />;
      }
    },
  },
];

const teamInitialData = {
  TEAM_ID: "",
  TEAM_NAME: "",
  TEAM_PARENT_ID: "",
  PARENT_TEAM_NAME: "",
  LEGAL_ENTITY_ID: "",
  EMAIL: "",
  DEPARTMENT_NAME: "",
  CITY: "",
  COUNTRY_CODE: "",
  ANNUAL_BUDGET: 0,
  ACTIVE: true,
  START_DATE: "",
  END_DATE: "",
};

const teamValidationSchema = Yup.object().shape({
  TEAM_NAME: Yup.string().trim().required("Team Name is Required"),
  LEGAL_ENTITY_ID: Yup.string().trim().required("Legal Entity is Required"),
  EMAIL: Yup.string()
    .email("Invalid Email Address")
    .required("Email is Required"),
  DEPARTMENT_NAME: Yup.string().trim().required("Department Name is Required"),
  CITY: Yup.string().trim().required("City is Required"),
  COUNTRY_CODE: Yup.string().trim().required("Country is Required"),
  ANNUAL_BUDGET: Yup.number().required("Annual Budget is Required"),
});

export default {
  usersColumns,
  teamsColumns,
  teamInitialData,
  teamValidationSchema,
};
