import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import { getRandomHexColor } from "../../utils";
import propTypes from "prop-types";
import moment from "moment";

const formatMonthYear = (value) => {
  const [month, year] = value.split("-");
  const formattedMonthYear = moment(`${year}-${month}`, "YYYY-MM").format(
    "MMMM-YYYY"
  );
  return formattedMonthYear;
};

const EvidenceLoaded = ({ data }) => {
  const policyList = Array.from(
    new Set(data?.map((item) => item?.EVIDENCE_SHORTNAME))
  );

  // Group data by MONTH_YEAR
  const groupedData = data?.reduce((acc, item) => {
    const existingGroup = acc?.find(
      (group) => group?.MONTH_YEAR === item?.MONTH_YEAR
    );

    if (existingGroup) {
      existingGroup[item?.EVIDENCE_SHORTNAME] = item?.UPLOADED_BY_COUNT;
    } else {
      const newGroup = {
        MONTH_YEAR: item?.MONTH_YEAR,
        [item?.EVIDENCE_SHORTNAME]: item?.UPLOADED_BY_COUNT,
      };
      acc?.push(newGroup);
    }

    return acc;
  }, []);

  return (
    <ResponsiveContainer>
      <BarChart
        data={groupedData}
        margin={{
          top: 10,
          right: 20,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="MONTH_YEAR" tickFormatter={formatMonthYear} />
        <YAxis />
        <Tooltip />
        <Legend iconType="circle" />

        {policyList?.map((item, index) => (
          <Bar
            barSize={60}
            key={index}
            dataKey={item}
            stackId="a"
            fill={getRandomHexColor()}
            opacity={0.8}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

EvidenceLoaded.propTypes = {
  data: propTypes.array,
};

export default EvidenceLoaded;
