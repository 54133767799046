import { Switch, Typography, Button } from "@material-ui/core";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import useStyles from "./styles";
import Select from "react-select";
import langConstant from "_lang";
import PropTypes from "prop-types";
import ManageTeamDropdown from "_components/ManageTeamDropDown";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import UserServices from "../services";

const SecurityFormComponent = ({
  setCurrentStep,
  setUserData,
  userData,
  mode,
  handleClose,
  selectedCustomer,
}) => {
  const classes = useStyles();
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  // const [enable2FA, setEnable2FA] = useState(userData.isEnabled)
  // const [authenticationType, setAuthenticationType] = useState(userData.authenticationType)
  const authenticationOptions = [
    { value: "EMAIL OTP", label: "2FA - OTP code via Email" },
    { value: "QR", label: "2FA - OTP code via QR code" },
    // {value: 2, label: "2FA - Microsoft Authenticator"},
    // {value: 3, label: "2FA - Authy"},
    // {value: 4, label: "2FA - Google Authenticator"},
  ];

  const goToPrevMenu = () => {
    setCurrentStep(1);
  };

  const goToNextMenu = async () => {
    if (mode === "admin") {
      dispatch(loadingActions.start());
      const putUserRes = await UserServices.putUser(
        userData.basic_information,
        userData.team,
        userData.USER_ID
      );
      if (putUserRes.status == 200) {
        const res = await UserServices.update2FA(
          userData.USER_ID
            ? userData.USER_ID
            : putUserRes["#result-set-1"][0]["USERID"],
          userData.security.isEnabled,
          userData.security.authenticationType,
          ""
        );
        if (res.status !== 200) {
          if (res.data && res.data.message) {
            dispatch(
              alertActions.error(
                res.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        } else {
          handleClose();
        }
      } else {
        if (putUserRes.data && putUserRes.data.message) {
          dispatch(
            alertActions.error(
              putUserRes.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
      dispatch(loadingActions.end());
    } else {
      if (userData.team) {
        setCurrentStep(3);
      } else {
        setIsError(true);
      }
    }
  };

  return (
    <Grid className={classes.notificationWrapper}>
      <Grid>
        <Typography
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "15px" }}
        >
          {langConstant.SECURITY_AND_TEAMS}
        </Typography>
      </Grid>
      <Grid container className={classes.formFieldWrapper}>
        <Grid item md={9} xs={8}>
          <Typography className={classes.userFormLabel}>
            {langConstant.ENABLE_2FA_AUTHENTICATION}
          </Typography>
        </Grid>
        <Grid item md={3} xs={4}>
          <Switch
            className={classes.profileMDTopHedaerToggleBtn}
            checked={userData.security.isEnabled}
            onClick={(event) =>
              setUserData((prevState) => ({
                ...prevState,
                security: {
                  ...prevState.security,
                  isEnabled: event.target.checked,
                },
              }))
            }
          />
        </Grid>
      </Grid>
      <Grid container className={classes.formFieldWrapper}>
        <Grid item md={4} xs={5}>
          <Typography className={classes.userFormLabel}>
            {langConstant.AUTHENTICATION_TYPE}
          </Typography>
        </Grid>
        <Grid item md={8} xs={7}>
          <Select
            className={classes.profileMDBasicSelect}
            options={authenticationOptions}
            placeholder="Select Authentication Type"
            value={authenticationOptions.find(
              (auth) => auth.value === userData.security.authenticationType
            )}
            onChange={(auth) =>
              setUserData((prevState) => ({
                ...prevState,
                security: {
                  ...prevState.security,
                  authenticationType: auth.value,
                },
              }))
            }
            isDisabled={!userData.security.isEnabled}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.formFieldWrapper}>
        <Grid item md={4} xs={5}>
          <Typography className={classes.userFormLabel}>
            {langConstant.ASSIGN_TEAM}
          </Typography>
        </Grid>
        <Grid item md={8} xs={7}>
          <ManageTeamDropdown
            value={userData.team}
            placeholder="Select Team"
            onChange={(e) =>
              setUserData((prevState) => ({ ...prevState, team: e.value }))
            }
            customerID={selectedCustomer.value}
          />
          {isError && (
            <Typography
              style={{ color: "red", fontSize: "13px", fontWeight: "bold" }}
            >
              Please Assign Team To User
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.profileButtonWrapper}>
        <Button
          color="primary"
          onClick={goToPrevMenu}
          style={{ marginRight: "10px" }}
          variant="outlined"
        >
          {langConstant.BACK}
        </Button>
        <Button color="primary" onClick={goToNextMenu} variant="contained">
          {mode === "admin" ? langConstant.SAVE : langConstant.NEXT_CAPS}
        </Button>
      </Grid>
    </Grid>
  );
};

SecurityFormComponent.propTypes = {
  setCurrentStep: PropTypes.func,
  userData: PropTypes.object,
  setUserData: PropTypes.func,
  mode: PropTypes.string,
  handleClose: PropTypes.func,
  selectedCustomer: PropTypes.object,
};

export default SecurityFormComponent;
