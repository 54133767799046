/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Box,
  Grid,
  Divider,
  Typography,
} from "@material-ui/core";

import langConstant from "_lang";
import rootStyles from "rootStyles";
import CommentsDialog from "./CommentsDialog";
import EvidenceLocker from "_components/EvidenceLocker/index";
const SingleQuestion = ({ item }) => {
  const rootClasses = rootStyles();
  return (
    <Box style={{marginLeft: '12px'}}>
            <Grid container spacing={1} className={rootClasses.flexNoWrap}>
              <Grid item style={{ minWidth: "130px" }}>
                <Typography color="textSecondary" variant="body1" >
                  {item.refId} -
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" >
                  <strong>{item.question}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}  className={rootClasses.flexNoWrap} style={{display: 'flex', alignItems: 'center'}}>
              <Grid item style={{ minWidth: "130px" }}>
                <Typography color="textSecondary" variant="body2" >
                  {langConstant.ANSWER}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body2"  dangerouslySetInnerHTML={{ __html: item.currentAnswer }}>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} className={rootClasses.flexNoWrap}>
              <Grid item md={12} xs={12} flex>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <CommentsDialog
                      question_ID={item.QUESTION_ID}
                      submission_ID={item.SUBMISSION_ID}
                      remarks={item.NUM_OF_COMMENTS ? item.NUM_OF_COMMENTS : 0}
                    />
                  </Grid>
                  <Grid item>
                    <EvidenceLocker
                      questionId={item.QUESTION_ID}
                      submissionId ={item.SUBMISSION_ID}
                      button={true}
                      attachmentsCount={item.NUM_OF_EVIDENCE ? item.NUM_OF_EVIDENCE : 0}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
              <Grid item xs={12} style={{ padding: 0 }}>
                <Box my={2}>
                  <Divider />
                </Box>
              </Grid>
          </Box>
  );
};
SingleQuestion.propTypes = {
  item: PropTypes.object,
};
export default React.memo(SingleQuestion);