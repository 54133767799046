import React from "react";
import { alertActions } from "_actions";
import apiService from "_services/api.service";
import { groupBy } from "lodash";

const getEvidenceStatusForSubmission = (
  setEvidenceStatus,
  setLoading,
  submissionID
) => {
  return (dispatch) => {
    setLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetEvidenceStatusForSubmission", {
        i_SUBMISSION_ID: submissionID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setEvidenceStatus(response);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
};

const getAssessments = (setAssessments, setLoading, submissionID, userID) => {
  return (dispatch) => {
    setLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetAssessments", {
        i_SUBMISSION_ID: submissionID,
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setAssessments(response);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
};

const getRFIStatusForSubmission = (setRfiStatus, setLoading, submissionID) => {
  return (dispatch) => {
    setLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRFIStatusForSubmission", {
        i_ASSESSMENT_ID: submissionID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let result = [];
          const RFI_LABEL = {
            RFI_PENDING: "Pending",
            RFI_RESOLVED: "Resolved",
            RFI_OVERDUE: "Overdue",
            RFI_SENT: "Sent",
          };

          let RFIStatusData = {
            RFI_PENDING: {
              RFI_STATUS: "RFI_PENDING",
              RFI_COUNT: 0,
              RFI_LABEL: "Pending",
            },
            RFI_RESOLVED: {
              RFI_STATUS: "RFI_RESOLVED",
              RFI_COUNT: 0,
              RFI_LABEL: "Resolved",
            },
            RFI_OVERDUE: {
              RFI_STATUS: "RFI_OVERDUE",
              RFI_COUNT: 0,
              RFI_LABEL: "Overdue",
            },
            RFI_SENT: {
              RFI_STATUS: "RFI_SENT",
              RFI_COUNT: 0,
              RFI_LABEL: "Sent",
            },
          };
          response["#result-set-1"].forEach((rfiData) => {
            RFIStatusData[rfiData["RFI_STATUS"]]["RFI_COUNT"] =
              RFIStatusData[rfiData["RFI_STATUS"]]["RFI_COUNT"] +
              rfiData["RFI_COUNT"];
          });
          Object.keys(RFIStatusData).forEach((rfi) =>
            result.push(RFIStatusData[rfi])
          );
          setRfiStatus(result);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
};

export default {
  getEvidenceStatusForSubmission,
  getAssessments,
  getRFIStatusForSubmission,
};
