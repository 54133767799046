import { QUESTION_STATUS_TYPES } from "_components/MasterAssessmentComp/utils/constants";

export const getQuestionBackground = (status) => {
  switch (status) {
    case QUESTION_STATUS_TYPES.DRAFT:
      return "rgba(255, 252, 210, 1)";

    case QUESTION_STATUS_TYPES.PENDING_DATA:
      return "rgba(255, 248, 156, 1)";

    case QUESTION_STATUS_TYPES.READY_FOR_REVIEW:
      return "rgba(175, 243, 187, 0.7)";

    case QUESTION_STATUS_TYPES.FINAL:
      return "rgba(175, 243, 187, 1)";

    case QUESTION_STATUS_TYPES.COMPLETED_AND_LOCKED:
    case QUESTION_STATUS_TYPES.COMPLETED:
      return "rgba(175, 243, 187, 1)";

    case QUESTION_STATUS_TYPES.RFI_SENT:
      return "rgba(123, 192, 255, 1)";

    case QUESTION_STATUS_TYPES.RFI_PENDING:
      return "rgba(255, 248, 156, 1)";

    case QUESTION_STATUS_TYPES.RFI_OVERDUE:
      return "rgba(255, 188, 188, 1)";

    case QUESTION_STATUS_TYPES.RFI_RESOLVED:
      return "rgba(175, 243, 187, 0.8)";

    case QUESTION_STATUS_TYPES.SENT_BACK:
      return "rgba(255, 188, 188, 1)";

    default:
      return "#fff";
  }
};

export const getRandomNum = (range = 20) => Math.random() * range;

export const getRandomHexColor = () => {
  let letters = "0123456789ABCDEF",
    color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getShortName = (name, splitBy = " ", joinBy = "") => {
  return name
    ?.split(splitBy)
    ?.map((i) => i?.[0]?.toUpperCase())
    ?.join(joinBy);
};
