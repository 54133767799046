import { StepperConstants } from "./constants";

export const setSteps = (data) => ({
    type: StepperConstants.SET_STEPS,
    payload: data,
});

export const setActiveStep = (data) => ({
    type: StepperConstants.SET_ACTIVE_STEP,
    payload: data,
});

export const increaseActiveStep = () => ({ type: StepperConstants.INCREASE_STEPPER_STEP });

export const decreaseActiveStep = () => ({ type: StepperConstants.DECREASE_STEPPER_STEP });


export const resetStepper = () => ({ type: StepperConstants.RESET_STEPPER })


