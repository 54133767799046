import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
const useStyles = makeStyles(() => ({
  timeline: {
    position: " sticky",
    right: 0,
    maxWidth: 200,
    fontSize: 12,
    zIndex: 1,
    height: "55vh",
    overflow: "auto",
    top: 82,
    float: "right",
  },

  timelineItem: {
    minHeight: "auto",
    "&::before": {
      display: "none",
    },
  },
  timelineDot: {
    margin: 0,
  },
  timelineContent: {
    paddingTop: 0,
    paddingBottom: 15,
    marginTop: "-1px",
    cursor: "pointer",
  },
}));
const AnchorSideNavigation = ({ anchors = [] }) => {
  const classes = useStyles();
  const [currentAnchor, setCurrentAnchor] = useState(0);
  const handleClick = (id, index) => {
    const element = document.getElementById(id);
    var top = element.offsetTop;
    window.scrollTo(0, top - 100);
    setCurrentAnchor(index);
  };
  useEffect(() => {
    setCurrentAnchor(0);
  }, [anchors]);

  return (
    <Timeline className={classes.timeline}>
      {anchors.map((item, index) => {
        return (
          <TimelineItem key={item.id} className={classes.timelineItem}>
            <TimelineSeparator>
              <TimelineDot
                className={classes.timelineDot}
                style={{
                  background: currentAnchor >= index ? "#47DD9C" : "#919191",
                }}
              />
              {index < anchors.length - 1 && (
                <TimelineConnector
                  style={{
                    background: currentAnchor > index ? "#47DD9C" : "#919191",
                  }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <Box
                onClick={() => {
                  handleClick(item.id, index);
                }}
              >
                {item.title}
              </Box>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
AnchorSideNavigation.propTypes = {
  anchors: PropTypes.array,
};

export default AnchorSideNavigation;
