import React from "react";
import { Typography, Button, Grid, Box, makeStyles } from "@material-ui/core";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Formik, Field, ErrorMessage, Form } from "formik";
import DialogBox from "_components/DialogBox";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import AutocompleteEmail from "../../../../cyber-assessment/ManageAssessments/NewAssignAssessment/components/Step2/components/AutocompleteForEmail";
import langConstant from "_lang";
import * as yup from "yup";

const useStyles = makeStyles(() => ({
  width100: {
    width: "100%",
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "15px",
  },
}));

const EmailPopUp = ({ open, setOpen, values, customerName }) => {
  const classes = useStyles();

  const initialFormData = {
    EMAIL_TO: langConstant.LICENCE_REQUEST_EMAIL_TO,
    EMAIL_SUBJECT: langConstant.LICENCE_RENEWAL_REQUEST,
    EMAIL_BODY: values
      ? `
    CustomerID:     ${values.CUSTOMER_ID}
    Customer Name:  ${customerName}
    Expiry Date:    ${
      values.LICENCE_EXPIRY_DATE.split("T")[0] +
      " " +
      values.LICENCE_EXPIRY_DATE.split("T")[1].split(".")[0]
    }
    Licence Type:   ${values.LICENCE_TYPE}
    `
      : "",
  };

  const handleClose = () => {
    setOpen(false);
  };

 

  const validationSchema = yup.object({
    EMAIL_TO: yup.string().required("required"),
    EMAIL_SUBJECT: yup.string().required("required"),
    EMAIL_BODY: yup.string().required("required"),
  });

  const SubmitEmailData = (values, { resetForm }) => {
    //setEditEmailBox({ visible: false, data: values });
  };

  return (
    <DialogBox fullWidth maxWidth="md" open={open} onClose={() => handleClose}>
      <Formik
        validateOnChange={true}
        initialValues={initialFormData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={SubmitEmailData}
      >
        {({ values, setFieldValue, resetForm, touched, errors }) => (
          <Form>
            <DialogContent>
              <Box>
                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center" }}
                >
                  <Grid item md={2}>
                    <Typography>To :</Typography>
                  </Grid>
                  <Grid md={10} item>
                    <Field name="EMAIL_TO">
                      {({ field: { value } }) => (
                        <TextField
                          fullWidth
                          value={value}
                          className={classes.width100}
                          onChange={(e) =>
                            setFieldValue("EMAIL_TO", e.target.value)
                          }
                          variant="outlined"
                          size="small"
                          placeholder="Subject"
                          error={touched.EMAIL_TO && Boolean(errors.EMAIL_TO)}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="EMAIL_TO">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center" }}
                >
                  <Grid item md={2}>
                    <Typography>Cc :</Typography>
                  </Grid>

                  <Grid item md={10}>
                    <Field name="EMAIL_CC">
                      {({ field: { value } }) => (
                        <AutocompleteEmail
                          value={value}
                          onChangeValue={(val) => {
                            setFieldValue("EMAIL_CC", val);
                          }}
                          placeholder="Cc"
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center" }}
                >
                  <Grid item md={2}>
                    <Typography>Bcc :</Typography>
                  </Grid>

                  <Grid item md={10}>
                    <Field name="EMAIL_BCC">
                      {({ field: { value } }) => (
                        <AutocompleteEmail
                          value={value}
                          onChangeValue={(val) => {
                            setFieldValue("EMAIL_BCC", val);
                          }}
                          placeholder="Bcc"
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center" }}
                >
                  <Grid item md={2}>
                    <Typography>Subject :</Typography>
                  </Grid>
                  <Grid md={10} item>
                    <Field name="EMAIL_SUBJECT">
                      {({ field: { value } }) => (
                        <TextField
                          fullWidth
                          value={value}
                          className={classes.width100}
                          onChange={(e) =>
                            setFieldValue("EMAIL_SUBJECT", e.target.value)
                          }
                          variant="outlined"
                          size="small"
                          placeholder="Subject"
                          error={
                            touched.EMAIL_SUBJECT &&
                            Boolean(errors.EMAIL_SUBJECT)
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage name="EMAIL_SUBJECT">
                      {(msg) => (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center" }}
                >
                  <Field name="EMAIL_BODY">
                    {({ field: { value }, form: { errors } }) => (
                      <TextField
                        size="small"
                        multiline
                        fullWidth
                        variant="outlined"
                        rows={7}
                        style={{ marginTop: "10px" }}
                        onChange={(e) =>
                          setFieldValue("EMAIL_BODY", e.target.value)
                        }
                        value={value}
                        placeholder="Email Body"
                        error={touched.EMAIL_BODY && Boolean(errors.EMAIL_BODY)}
                      />
                      // <RichTextEditor data={"hello"} onSaveClick={saveAnswerFunction} />
                    )}
                  </Field>
                  <ErrorMessage name="EMAIL_BODY">
                    {(msg) => (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button color="primary" type="submit">
                SEND
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogBox>
  );
};

EmailPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  values: PropTypes.object,
  customerName: PropTypes.string,
};

export default EmailPopUp;
