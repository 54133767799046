/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BuildIcon from "@mui/icons-material/Build";
import langConstant from "_lang";
import { formatComparisonOperator } from "_helpers/utils";
import RulesBuilder from "_components/RulesBuilder";
import dropdownOptions from "_components/RulesBuilder/constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CreateRule = ({ questionResponses, questionRuleLogic, qid, dataPoints }) => {
  const [value, setValue] = React.useState(0);
  const [showRuleBuilder, setShowRuleBuilder] = useState(false);
  const handleCloseRuleBuilder = () => {
    setShowRuleBuilder(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatQuestionRule = (unformattedRule) => {
    if (unformattedRule.parent.length === 0)
      return (
        <span>
          {langConstant.SET} {unformattedRule.option}{" "}
          {langConstant.IF_NONE_OF_THE_CONDITIONS_ARE_MET}
          {"."}
        </span>
      );

    return unformattedRule.parent.map((rulesItem, index) => {
      return (
        <>
          <span key={index}>
            {rulesItem.child.length === 0 ? (
              <span>{langConstant.NO_AUTOMATION_DEFINED_FOR_QUESTION}</span>
            ) : (
              rulesItem.child.map(
                ({ attribute, comparisonOperator, compareValue }, index) => {
                  return (
                    <>
                      <span key={index}>
                        {attribute}{" "}
                        {formatComparisonOperator(
                          comparisonOperator,
                          dropdownOptions.comparisonOperator
                        )}{" "}
                        {compareValue}{" "}
                        {rulesItem.child.length - 1 !== index &&
                          ` ${rulesItem.childLogicalOperator} `}
                      </span>
                    </>
                  );
                }
              )
            )}
          </span>
          {index !== unformattedRule.parent.length - 1 && (
            <span>{` ${unformattedRule.parentLogicalOperator} `}</span>
          )}
        </>
      );
    });
  };

  return (
    <Box style={{ width: "100%" }}>
      <Typography
        style={{
          color: "#707070",
          marginBottom: "5px",
          fontWeight: "700",
          padding: "0 5px"
        }}
      >
        {langConstant.CREATE_RULE}
      </Typography>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {questionResponses.map((response, index) => {
            return (
              <Tab
                key={index}
                label={response.RESPONSE_OPTION}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>

      <Box style={{ paddingLeft: "10px", marginTop: "20px" }}>
        {questionRuleLogic.length != 0
          ? questionRuleLogic.map((rule, index) => {
            return (
              <TabPanel key={index} value={value} index={index}>
                {formatQuestionRule(rule)}
              </TabPanel>
            );
          })
          : langConstant.NO_AUTOMATION_DEFINED_FOR_QUESTION}
      </Box>

      <Button
        onClick={() => {
          setShowRuleBuilder(true);
        }}
        variant="text"
        size="medium"
        style={{ marginTop: "20px", backgroundColor: "#fff", color: questionResponses?.length && dataPoints?.length ? "#007BFF" : 'gray' }}
        startIcon={<BuildIcon />}
        disabled={questionResponses?.length === 0 || dataPoints?.length === 0}
      >
        {langConstant.OPEN_RULES_BUILDER}
      </Button>

      <Box>
        <div>

          {
            questionResponses && questionResponses?.length === 0 && (
              <div>
                <Typography style={{ color: "red", fontSize: "12px" }}>Question response required for Rule Builder</Typography>
              </div>

            )
          }

          {
            dataPoints && dataPoints?.length === 0 && (
              <div>
                <Typography style={{ color: "red", fontSize: "12px" }}>Data Assets required for Rule Builder</Typography>
              </div>

            )
          }


        </div>
      </Box>



      <RulesBuilder
        show={showRuleBuilder}
        handleCloseRuleBuilder={handleCloseRuleBuilder}
        questionID={qid}
      />
    </Box>
  );
};

CreateRule.propTypes = {
  qid: PropTypes.string,
  questionResponses: PropTypes.array,
  questionRuleLogic: PropTypes.string,
  dataPoints: PropTypes.array
};
export default CreateRule;
