import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { GetActionListForAudit } from "./services";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "./components/DataTable";

const AuditActions = () => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const actionList = useSelector((state) => state?.auditActions?.actionList);

  const columns = [
    { field: "ACTIONS_LIST_ID", headerName: "ACTION LIST ID" },
    { field: "ACTIONS_LIST_LINK_ID", headerName: "LINK ID" },
    { field: "ACTIONS_LIST_NAME", headerName: "ACTION LIST NAME" },
    { field: "ACTIONS_LIST_PRIORITY", headerName: "PRIORITY" },
    { field: "LIST_STATUS", headerName: "STATUS" },
    {
      field: "ACTIONS_LIST_TYPE",
      headerName: "ACTIONS LIST TYPE",
      // format: (value) => value || "-",
    },
    { field: "TOTAL_ACTIONS", headerName: "NO. OF AC" },
    // {
    //   field: "DATE_ASSIGNED",
    //   headerName: "ASSIGNED DATE",
    //   format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
    //   minWidth: 130,
    // },
    { field: "action", headerName: "ACTIONS", minWidth: 115, sticky: true },
  ];

  useEffect(() => {
    dispatch(GetActionListForAudit(userID));
  }, []);

  return (
    <Box>
      <DataTable columns={columns} rows={actionList} className="data-table" />
    </Box>
  );
};

export default AuditActions;
