import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import propTypes from "prop-types";
import { convertColorToRGBA } from "globalMuiStyles";

const LabelIcon = ({
  label,
  count = 0,
  icon,
  color,
  customStyles = {},
  labelClass,
  countClass,
}) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{ ...customStyles }}
      flex="1"
    >
      <Tooltip title={label}>
        <Avatar
          variant="rounded"
          sx={{
            color,
            backgroundColor: convertColorToRGBA(color, 0.0),
            width: "100%",
            flex: 1,
            height: "inherit",
            padding: "4px 0 0 0",
          }}
        >
          {icon}
        </Avatar>
        <Box
          sx={{
            display: "flex",
            placeContent: "center",
            flexDirection: "column",
          }}
          flex="1"
          textAlign="center"
        >
          <Typography className={labelClass}>{label}</Typography>
          <Typography className={countClass}>
            <strong>{count}</strong>
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

LabelIcon.propTypes = {
  label: propTypes.string,
  count: propTypes.number,
  icon: propTypes.element,
  color: propTypes.string,
  customStyles: propTypes.object,
  labelClass: propTypes.object,
  countClass: propTypes.object,
};

export default LabelIcon;
