import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import langConstant from "_lang";
import useStyles from "../../styles";
import CloseIcon from "@mui/icons-material/Close";

const RegulationEditPopUp = ({
  show,
  setShow,
  selectedRegulation,
  setSelectedRegulation,
  updateRegulationMapping,
}) => {
  const handleClose = () => {
    setShow(false);
    setSelectedRegulation(null);
  };
  const classes = useStyles();
  return (
    <Dialog open={show} maxWidth={"md"} fullWidth={true} onClose={handleClose}>
      <Grid style={{ padding: "20px 30px" }}>
        <Grid className={classes.dialogTitleWrapper}>
          <Typography className={classes.dialogTitleLabel}>
            {langConstant.UPDATE_REGULATION_DETAILS}
          </Typography>
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </Grid>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Typography className={classes.formLabel}>
              {langConstant.REGULATION_NAME}
            </Typography>
            <Typography>{selectedRegulation.REGULATION_SHORT_NAME}</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.formLabel}>
              {langConstant.REGULATION_STATUS}
            </Typography>
            <Typography>{selectedRegulation.REGULATION_STATUS}</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.formLabel}>
              {langConstant.DOMAIN}
            </Typography>
            <Typography>{selectedRegulation.DOMAIN_NAME}</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.formLabel}>
              {langConstant.REGULATOR_NAME}
            </Typography>
            <Typography>{selectedRegulation.REGULATOR_NAME}</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.formLabel}>
              {langConstant.APPLICABLE_FLAG}
            </Typography>
            <Typography>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedRegulation.APPLICABLE_FLAG}
                onChange={(e) =>
                  setSelectedRegulation((prevState) => ({
                    ...prevState,
                    APPLICABLE_FLAG: e.target.value,
                  }))
                }
                style={{
                  display: "inline",
                }}
              >
                <FormControlLabel
                  value={"TRUE"}
                  control={<Radio />}
                  label="TRUE"
                />
                <FormControlLabel
                  value={"FALSE"}
                  control={<Radio />}
                  label="FALSE"
                />
              </RadioGroup>
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className={classes.formLabel}>
              {langConstant.ACTIVE}
            </Typography>
            <Typography>
              <Switch
                checked={selectedRegulation.ACTIVE == "1" ? true : false}
                onChange={(e) =>
                  setSelectedRegulation((prevState) => ({
                    ...prevState,
                    ACTIVE: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.btnWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={updateRegulationMapping}
          >
            {langConstant.UPDATE}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

RegulationEditPopUp.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  selectedRegulation: PropTypes.object,
  setSelectedRegulation: PropTypes.func,
  updateRegulationMapping: PropTypes.func,
};

export default RegulationEditPopUp;
