import * as Yup from "yup";
import PolicyServices from "../../services";

export const initialTitles = { id: 0, text: "", page_no: "" }

export const initialDocumentInfo = {
    document: "",
    document_name: "",
    document_type: [],
    document_owner: "",
    document_language: {value: "EN", label:"ENGLISH"},
    document_extension: "",
    document_tags: "",
}

export const newInitialDocumentInfo = {
    document: "",
    document_name: "",
    pages_extraction_start: "",
    pages_extraction_end: "",
    document_type: [],
    document_owner: "",
    document_language: {value: "EN", label:"ENGLISH"},
    document_extension: "",
    document_tags: "",
    is_pre_proccesing: false,
    upload_only: false,
}

export const initialDocumentContentInfo = {
    cleanDocument: false,
    pages_extraction_start: "",
    pages_extraction_end: "",
    normal_text: { text: "", page_no: "" },
    titles_list: [initialTitles],
    headers_list: { text: "", page_no: "" },
    footers_list: { text: "", page_no: "" },
}

export const commanValidationSchema = {
    document_name: Yup.string().trim().required("Document Name Required"),
    pages_extraction_start: Yup.number().required("Page Number Required").test(
        'Is positive?', 
        'Number must be greater than 0', 
        (value) => value > 0
      ),
    pages_extraction_end: Yup.number().required("Page Number Required").test(
        'Is positive?', 
        'Number must be greater than 0', 
        (value) => value > 0
      ),
    normal_text: Yup.object().shape({
        text: Yup.string().required("Normal Text Required"),
        page_no: Yup.string().required("Page Number Required").test(
            'Is positive?', 
            'Number must be greater than 0', 
            (value) => value > 0
        ),
    }),
    titles_list: Yup.array()
    .min(1)
    .of(
        Yup.object().shape({
        text: Yup.string().required("Title Required"),
        page_no: Yup.number().required("Page Number Required").test(
            'Is positive?', 
            'Number must be greater than 0', 
            (value) => value > 0
          ),
        })
    ),
    headers_list: Yup.object().shape({
        text: Yup.string().when("page_no", (page_no) => {
            if(page_no){
                return Yup.string().required("Header Required")
            }
        }),
        page_no: Yup.string().when("text", (text) => {
            if(text){
                return Yup.string().required("Page Number Required").test(
                    'Is positive?', 
                    'Number must be greater than 0', 
                    (value) => value > 0
                )
            }
        }), 
    },[['text', 'page_no']]),
    footers_list: Yup.object().shape({
        text: Yup.string().when("page_no", (page_no) => {
            if(page_no){
                return Yup.string().required("Footer Required")
            }
        }),
        page_no: Yup.string().when("text", (text) => {
            if(text){
                return Yup.string().required("Page Number Required").test(
                    'Is positive?', 
                    'Number must be greater than 0', 
                    (value) => value > 0
                )
            }
        }), 
    },[['text', 'page_no']]),
}

export const validationSchema = Yup.object().shape({
    document: Yup.mixed().required("Please Select The Document"),
    ...commanValidationSchema
});

export const reRunValidationSchema = Yup.object().shape({
    ...commanValidationSchema
});

export const uploadOnlyValidationSchema = Yup.object().shape({
    document: Yup.mixed().required("Please Select The Document"),
    document_name: Yup.string().trim().required("Document Name Required"),
});

export const newUploadOnlyValidationSchema = Yup.object().shape({
    document: Yup.mixed().required("Please Select The Document"),
    document_name: Yup.string().trim().required("Document Name Required"),  
    pages_extraction_start: Yup.number().positive('Number must be greater than 0'),
    pages_extraction_end: Yup.number().positive('Number must be greater than 0'),
});

export const newValidationSchema = Yup.object().shape({
    document: Yup.mixed().required("Please Select The Document"),
    document_name: Yup.string().trim().required("Document Name Required"),
    pages_extraction_start: Yup.number().required("Page Number Required").test(
        'Is positive?', 
        'Number must be greater than 0', 
        (value) => value > 0
      ),
    pages_extraction_end: Yup.number().required("Page Number Required").test(
        'Is positive?', 
        'Number must be greater than 0', 
        (value) => value > 0
      ),
});

export const putUserErrorLogs = async (errorData) => {
    await PolicyServices.putUserErrorMessage(errorData)
}

export const putErrorLogs = async (errorData) => {
    await PolicyServices.putErrorLog({TECHNICAL_ERROR_MESSAGE: errorData['TECHNICAL_ERROR_MESSAGE'], URL: errorData['URL']})
}
