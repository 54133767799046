import React, { useState } from "react";
import {
  ArrowForwardIos,
  Assignment,
  Cancel,
  MoreVert as ActionIcon,
  ListAlt,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import propTypes from "prop-types";
import {
  ACTION_STATUS_LIST,
  listOfActions,
} from "containers/cyber-assessment/AuditActions/constants";
import { AddClosureDateDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/AddClosureDateDialog";
import { AddNoteDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/AddNoteDialog";
import { SetPriorityDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/SetPriorityDialog";
import { SetStatusDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/SetStatusDialog";
import { AssignListDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/AssignListDialog";
import NoDataCard from "./DashboardPanel/components/Graphs/NoDataCard";

const priorityChipStyles = {
    M: { backgroundColor: "#fff6c8", color: "#c3a306" },
    H: { backgroundColor: "#ffdddd", color: "#ff3131" },
    L: { backgroundColor: "#dfffe1", color: "#4fcd00" },
  },
  statusBadgeStyles = {
    [ACTION_STATUS_LIST[0].value]: {
      backgroundColor: "#7bc0ff",
      border: "#7bc0ff",
    },
    [ACTION_STATUS_LIST[1].value]: {
      backgroundColor: "#ffae6b",
      border: "#ffae6b",
    },
    [ACTION_STATUS_LIST[2].value]: {
      backgroundColor: "#4fcd00",
      border: "#4fcd00",
    },
  };

const truncate = (string, limit = 100) => {
  return string?.length > limit ? string?.slice(0, limit) + "..." : string;
};

const getPriorityChipLabel = (priority) => {
  switch (priority) {
    case "H":
      return "High";
    case "M":
      return "Medium";
    case "L":
      return "Low";
    default:
      return "-";
  }
};

const getStatusBadge = (status) => {
  let listStatus = ACTION_STATUS_LIST.find((i) => i?.value == status);
  if (!listStatus) return "";
  return (
    <Chip
      size="small"
      style={{ color: "#fff", ...statusBadgeStyles[listStatus.value] }}
      label={listStatus?.label}
    />
  );
};

const ActionCard = ({ action, onMenuItemClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      p=".5rem"
      key={action?.ACTIONS_ID}
      display="flex"
      flex="auto"
      flexDirection="column"
      border="1px solid #0000001a"
      borderRadius="4px"
    >
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {listOfActions.slice(1, listOfActions?.length).map((actionItem) => {
          const Icon = actionItem?.icon;
          return (
            <MenuItem
              key={actionItem.label}
              onClick={() => {
                handleClose();
                onMenuItemClick(actionItem.label, action);
              }}
              disabled={actionItem?.isDisabled}
            >
              <Box display="flex" justifyItems="center" style={{ gap: 16 }}>
                <Icon />
                <Typography style={{ alignSelf: "center" }} variant="body2">
                  {actionItem.label}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
      <Box display="flex" flex="auto" gap={1}>
        <IconButton size="small" aria-label="action" onClick={handleClick}>
          <ActionIcon fontSize="small" />
        </IconButton>
        <Assignment style={{ alignSelf: "center" }} color="primary" />
        <Tooltip title={action?.ACTIONS_SHORT_DESCRIPTION}>
          <Typography
            style={{
              alignSelf: "center",
              textTransform: "capitalize",
            }}
            flex={1}
            fontSize="14px"
            onClick={() => setIsOpen(!isOpen)}
          >
            {truncate(action?.ACTIONS_SHORT_DESCRIPTION, 40)}
          </Typography>
        </Tooltip>
        {action?.ACTIONS_PRIORITY && (
          <Chip
            label={getPriorityChipLabel(action?.ACTIONS_PRIORITY)}
            size="small"
            style={{
              fontSize: 10,
              alignSelf: "center",
              padding: "0rem .25rem",
              ...priorityChipStyles[action?.ACTIONS_PRIORITY],
            }}
          />
        )}
        <IconButton onClick={() => setIsOpen(!isOpen)} size="small">
          <ArrowForwardIos
            fontSize="small"
            style={{
              transition: "all ease-in-out 0.3s",
              transform: `rotate(${isOpen ? "90" : "0"}deg)`,
            }}
          />
        </IconButton>
      </Box>
      {isOpen && (
        <Box
          mt={1}
          py={1}
          borderTop="1px solid #0000001a"
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Box display="flex" gap={2}>
            <Typography variant="caption" color="InfoText">
              Description -
            </Typography>
            <Typography flex={1} variant="caption" color="GrayText">
              {action?.ACTIONS_FULL_DESCRIPTION}
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Box flex={1} display="flex" gap={1}>
              <Typography variant="caption" color="InfoText">
                Assigned To -
              </Typography>
              <Typography
                flex={1}
                variant="caption"
                color="GrayText"
              >{`${action?.ASSIGN_TO_FIRST_NAME} ${action?.ASSIGN_TO_LAST_NAME}`}</Typography>
            </Box>
            <Box display="flex" flex={1} gap={2}>
              <Typography variant="caption" color="InfoText">
                Assigned By -
              </Typography>
              <Typography
                flex={1}
                variant="caption"
                color="GrayText"
              >{`${action?.ASSIGN_BY_FIRST_NAME} ${action?.ASSIGN_BY_LAST_NAME}`}</Typography>
            </Box>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Box flex={1} display="flex" gap={1}>
              <Typography variant="caption" color="InfoText">
                Due Date -
              </Typography>
              <Typography flex={1} variant="caption" color="GrayText">
                {action?.DUE_DATE
                  ? new Date(action?.DUE_DATE).toLocaleDateString()
                  : "-"}
              </Typography>
            </Box>
            <Box display="flex" flex={1} gap={2} alignItems="center">
              <Typography variant="caption" color="InfoText">
                Status -
              </Typography>
              {getStatusBadge(action?.ACTIONS_STATUS)}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const ActionPanel = ({ actions, isLoading, getActions }) => {
  const [currentDialog, setCurrentDialog] = useState(null);

  const modal = {
    closeModal: () => setCurrentDialog(null),
    isOpen: Boolean(currentDialog?.type !== null),
  };

  const onMenuItemClick = (type, action) => {
    setCurrentDialog({ type, action });
  };

  const _renderDialog = () => {
    switch (currentDialog?.type) {
      case listOfActions[1].label:
        return (
          <AssignListDialog
            modal={modal}
            submitCallback={getActions}
            {...currentDialog}
          />
        );
      case listOfActions[2].label:
        return (
          <SetStatusDialog
            modal={modal}
            submitCallback={getActions}
            {...currentDialog}
          />
        );
      case listOfActions[3].label:
        return (
          <SetPriorityDialog
            modal={modal}
            submitCallback={getActions}
            {...currentDialog}
          />
        );
      // case listOfActions[3].label:
      //   return <CommentsDialog modal={modal} action={action} />;
      case listOfActions[4].label:
        return <AddNoteDialog modal={modal} {...currentDialog} />;
      case listOfActions[5].label:
        return (
          <AddClosureDateDialog
            modal={modal}
            submitCallback={getActions}
            {...currentDialog}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <React.Fragment>
      {_renderDialog()}
      <Box display="flex" gap={1} flexDirection="column">
        {isLoading ? (
          <Box
            alignItems="center"
            margin="auto"
            minHeight="6rem"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {actions?.length ? (
              actions?.map((action) => (
                <ActionCard
                  key={action?.ACTIONS_ID}
                  action={action}
                  onMenuItemClick={onMenuItemClick}
                />
              ))
            ) : (
              <NoDataCard message="No actions available" Icon={ListAlt} />
            )}
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

ActionPanel.propTypes = {
  actions: propTypes.array,
  isLoading: propTypes.bool,
  getActions: propTypes.func,
};

ActionCard.propTypes = {
  action: propTypes.object,
  handleClick: propTypes.func,
  anchorEl: propTypes.any,
  handleClose: propTypes.func,
  onMenuItemClick: propTypes.func,
};
