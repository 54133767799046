import * as yup from "yup";
const actionValidation = {
  addAction: yup.object({
    actionShortDesc: yup
      .string()
      .required("Short description is required field")
      .max(100, "Short description can't be more then 100 character"),
    actionFullDesc: yup
      .string()
      .required("Description is required field")
      .max(1000, "Action Description can't be more then 1000 character"),
    assignedTo: yup.mixed().required(),
    // assignedBy: yup.string().required(),
    priority: yup.string().required(),
    status: yup.string().required(),
    closureDate: yup.string(),
    dueDate: yup.string().required(),
  }),
  addActionList: yup.object({
    actionListName: yup.string().required(),
    priority: yup.string().required(),
    linkType: yup.string().required(),
    linkedItem: yup.mixed().required(),
    status: yup.string().required(),
  }),
};

export { actionValidation };
